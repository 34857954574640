import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { FloatConverter, InputConverter } from 'src/app/shared/common/components/input-converter.decorator';

@Directive({
    selector: '[minValue]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MinValidatorDirective, multi: true }]
})
export class MinValidatorDirective implements Validator, OnChanges {
    @Input()
    @InputConverter(FloatConverter)
    minValue: number;

    private _onValidatorChange = () => {};

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.minValue) {
            this._onValidatorChange();
        }
    }

    registerOnValidatorChange?(fn: () => void): void {
        this._onValidatorChange = fn;
    }

    validate(control: AbstractControl): ValidationErrors {
        if (
            this.minValue !== null &&
            this.minValue !== undefined &&
            control.value &&
            parseFloat(control.value) < this.minValue
        ) {
            return { min: { minimum: this.minValue } };
        }
        return null;
    }
}
