import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { FeatureFilter } from '../feature-filter/feature-filter';

@Injectable({
    providedIn: 'root'
})
export class FeatureFilterStreamService {
    private _activeFilterStream = new BehaviorSubject(new FeatureFilter());

    public activeFilterStream = this._activeFilterStream.asObservable();

    get activeFilter(): FeatureFilter {
        return this._activeFilterStream.getValue();
    }

    set activeFilter(value: FeatureFilter) {
        this._activeFilterStream.next(value);
    }
}
