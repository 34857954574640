import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { Actor } from 'src/app/shared/user/actor';
import { User } from 'src/app/shared/user/user';

@Component({
    selector: 'user-filter',
    templateUrl: './user-filter.component.html'
})
export class UserFilterComponent implements OnInit, OnDestroy {
    // required
    @Input() placeholder: string;
    @Input() selectedUsers: Array<User | Actor> = [];
    @Output() selectedUsersChange = new EventEmitter<Array<User | Actor>>();
    @Input() allUsers: Array<User | Actor> = [];

    // optional
    @Input() name: string = 'tags';
    @Input() inputClass: string = 'user-filter-input';

    // chips / autocomplete init
    @Input() selectable = false;
    @Input() removable = true;

    public userCtrl = new FormControl('');
    private destroyed = new Subject<void>();
    public filteredUsers: Array<User | Actor> = [];
    @ViewChild('userInput', { static: false }) userInput: ElementRef<HTMLInputElement>;

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    ngOnInit(): void {
        this.initFilteredUserOptions();
    }

    public addUser(user: User): void {
        this.selectedUsers.push(user);
        this.selectedUsersChange.emit(this.selectedUsers);
        this.resetUserCtrl();
    }

    public removeUser(removeUser: User | Actor): void {
        const index = this.selectedUsers.indexOf(removeUser);
        this.selectedUsers.splice(index, 1);
        this.selectedUsersChange.emit(this.selectedUsers);
    }

    public resetUserCtrl(): void {
        this.userInput.nativeElement.value = '';
        this.userCtrl.patchValue('');
    }

    private initFilteredUserOptions(): void {
        this.userCtrl.valueChanges
            .pipe(takeUntil(this.destroyed), debounceTime(150), distinctUntilChanged())
            .subscribe(val => (this.filteredUsers = this.filterUsers(val)));
    }

    private filterUsers(searchVal: string): Array<User | Actor> {
        const searchText = searchVal.toLowerCase();
        if (!searchText) {
            return [];
        }
        return this.allUsers.filter(
            user => user.name.toLowerCase().includes(searchText) && !this.selectedUsers.find(u => u.id === user.id)
        );
    }
}
