import { transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import {
    centerDialogCloseTransition,
    centerDialogEnterTransition
} from 'src/app/shared/common/animations/gsp-animations';
import { MenuService } from 'src/app/shared/common/layout/menu.service';
import { MapWorkspacePermissionType } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace-permission';

@Component({
    selector: 'map-layout',
    templateUrl: './map-layout.component.html',
    animations: [
        trigger('routeAnimations', [
            transition('* <=> centerDialog', [centerDialogEnterTransition, centerDialogCloseTransition])
        ])
    ]
})
export class MapLayoutComponent implements OnInit, OnDestroy {
    //exposing enum to template
    public MapWorkspacePermissionType = MapWorkspacePermissionType;
    private destroyed$ = new Subject<void>();
    public openMenu = false;

    constructor(private menuService: MenuService) {}

    prepareRoute(outlet: RouterOutlet): any {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
    }

    ngOnInit(): void {
        this.menuService.activeMenusStream.pipe(takeUntil(this.destroyed$)).subscribe(activeMenuCodes => {
            this.openMenu = !!activeMenuCodes.length;
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(null);
    }
}
