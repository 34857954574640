import { MapWorkspacePermissionType } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace-permission';

export enum MapMenuCode {
    LAYERS = 'layers',
    TASKS = 'tasks',
    FILTERS = 'filters'
}

export class MapMenuItem {
    id: number;
    name: string;
    iconClass: string;
    state?: string;
    menuCode: MapMenuCode;
    selected: boolean;
    highlight: boolean;
    displayName: string;
    group: number;
    visibleMode?: MapWorkspacePermissionType;
    menuClass?: string;
}

export const mapMenuList: MapMenuItem[] = [
    {
        id: 1,
        name: 'Layer',
        iconClass: 'icon_line_layers',
        state: 'mapViewer/mainmenu/layers',
        menuCode: MapMenuCode.LAYERS,
        selected: false,
        highlight: false,
        displayName: 'TC.Common.Lower_Layers',
        group: 1
    },
    {
        id: 2,
        name: 'Task',
        iconClass: 'icon_solid_todo',
        state: 'mapViewer.tasks',
        menuCode: MapMenuCode.TASKS,
        selected: false,
        highlight: false,
        displayName: 'TC.Todos',
        group: 1,
        visibleMode: MapWorkspacePermissionType.FULL_ACCESS
    },
    {
        id: 3,
        name: 'Filter',
        iconClass: 'icon_solid_filter',
        menuClass: 'filter-menu',
        menuCode: MapMenuCode.FILTERS,
        selected: false,
        highlight: false,
        displayName: 'TC.Common.Filters',
        group: 2
    }
];
