import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GspNumberType } from 'src/app/shared/common/components/gsp-number/gsp-number.component';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { CurrentUserStreamService } from 'src/app/shared/common/current-user/current-user-stream.service';
import { DistanceUnitsExpanded, UnitSystem } from 'src/app/shared/common/utility/accuracy-utils';
import { GeometryTypes } from 'src/app/shared/common/utility/geometry-utils';
import { TemplateTypeId } from 'src/app/shared/map-data-services/layer/template-lite';
import { AutoFieldModelType } from 'src/app/shared/template-services/field';
import { Template } from 'src/app/shared/template-services/template';
import { TemplateService } from 'src/app/shared/template-services/template.service';

import { FeatureFlagName } from 'src/app/shared/common/feature-flag/feature-flag';
import { FeatureFlagStreamService } from 'src/app/shared/common/feature-flag/feature-flag-stream.service';
import { DistanceUnit, Domains } from '../template/domains';

enum NumericFieldTypes {
    TIME,
    DISTANCE,
    POSITIONS,
    REQUIRED_ACCURACY
}

@Component({
    selector: 'template-panel',
    templateUrl: './template-panel.component.html'
})
export class TemplatePanelComponent implements OnInit {
    @Input()
    public get template(): Template {
        return this._template;
    }
    public set template(v: Template) {
        this._template = v;

        this._template.review.thumbnailFieldId = this._template.review.thumbnailFieldId || '';
        this._template.review.line1FieldId = this._template.review.line1FieldId || '';
        this._template.review.line2FieldId = this._template.review.line2FieldId || '';
        this.templateId = this._template.id || this.route.snapshot.params.templateId;
    }

    @Input()
    templateEditorConfig: { tabs: { fields: { fieldsGroup: any[]; enableFieldConditions: { [key: string]: any } } } };

    @ViewChild('templatenameelement', { static: true })
    nameElement: ElementRef;

    get accuracyLimitAsNumber(): number {
        return parseFloat(this.accuracyLimit);
    }

    accuracyLimit: string;
    accuracyShortUnit: string;
    availableColors$: Promise<{ id: number; color: string }[]>;
    distanceUnits: DistanceUnit[];
    readonly geoTypes = Domains.getGeometryTypes();
    isTimeLogging = true;

    isMetricOrg: boolean;
    minimumAccuracyRequired: number;
    system: string;
    templateId: string;

    // Feature flagging. To be removed
    public digitizeEnabled: boolean;
    public loggingBehaviorEnabled: boolean;

    // Expose to template
    public UnitSystem = UnitSystem;
    public TemplateTypeId = TemplateTypeId;
    public GspNumberType = GspNumberType;
    public GeometryTypes = GeometryTypes;
    public NumericFieldTypes = NumericFieldTypes;

    private readonly feetToMetersConv = 0.3048;
    private _template: Template;

    constructor(
        private currentUserStream: CurrentUserStreamService,
        private templateService: TemplateService,
        private projectStream: ProjectStreamService,
        private featureFlagStream: FeatureFlagStreamService,
        private route: ActivatedRoute
    ) {
        this.loggingBehaviorEnabled = featureFlagStream.flagEnabled(FeatureFlagName.LOGGING_BEHAVIOR);
        this.digitizeEnabled = featureFlagStream.flagEnabled(FeatureFlagName.DIGITIZE);
    }

    ngOnInit(): void {
        this.projectStream.getProjectWithUpdatedSettings().then(currentProject => {
            const projUnit = currentProject.settings?.unitSettings?.unitsystem || UnitSystem.METRIC;
            this.isMetricOrg = projUnit === UnitSystem.METRIC;
            if (this.isMetricOrg === true) {
                this.accuracyShortUnit = 'TC.Common.Units.M';
                this.system = UnitSystem.METRIC;
                this.minimumAccuracyRequired = 3;
                this.accuracyLimit = this.template.accuracyLimit.toString();
            } else {
                this.accuracyShortUnit = 'TC.Common.Units.Ft';
                this.system = UnitSystem.IMPERIAL;
                this.minimumAccuracyRequired = 10;
                this.accuracyLimit = (this.template.accuracyLimit / this.feetToMetersConv).toFixed(2);
            }

            this.template.loggingRate.distance.unit =
                this.template.loggingRate.distance.unit ||
                (this.isMetricOrg ? DistanceUnitsExpanded.METERS : DistanceUnitsExpanded.FEET);
        });

        this.availableColors$ = this.templateService.getAvailableTemplateColors();
        this.nameElement.nativeElement.focus();

        this.isTimeLogging = this.template.loggingRate.time.active;

        // Hide imperial units for Japanese users
        this.distanceUnits = Domains.getDistanceUnits().filter(unit =>
            this.currentUserStream.currentUser.locale === 'ja'
                ? unit.name === DistanceUnitsExpanded.METERS || unit.name === this.template.loggingRate.distance.unit
                : unit.name === DistanceUnitsExpanded.METERS || unit.name === DistanceUnitsExpanded.FEET
        );
    }

    geometryTypeSelected(selectedGeometryType: string): void {
        if (selectedGeometryType === GeometryTypes.LINE) {
            this.template.filterFieldsByType([
                AutoFieldModelType.GeometryArea,
                AutoFieldModelType.XPosition,
                AutoFieldModelType.YPosition,
                AutoFieldModelType.ZPosition
            ]);
        } else if (selectedGeometryType === GeometryTypes.AREA) {
            this.template.filterFieldsByType([
                AutoFieldModelType.GeometryLength,
                AutoFieldModelType.XPosition,
                AutoFieldModelType.YPosition,
                AutoFieldModelType.ZPosition
            ]);
        } else {
            this.template.filterFieldsByType([AutoFieldModelType.GeometryArea, AutoFieldModelType.GeometryLength]);
        }
    }

    validateField(field: NumericFieldTypes): void {
        const loggingRate = this.template.loggingRate;

        switch (field) {
            case NumericFieldTypes.TIME:
                loggingRate.time.value = this.handleNumberInput(loggingRate.time.value?.toString(), 1, 9999, 1);
                break;

            case NumericFieldTypes.DISTANCE:
                loggingRate.distance.value = this.handleNumberInput(loggingRate.distance.value?.toString(), 1, 9999, 1);
                break;

            case NumericFieldTypes.POSITIONS:
                this.template.vertexNumberOfPositions = this.handleNumberInput(
                    this.template.vertexNumberOfPositions?.toString(),
                    0,
                    3600
                );
                break;

            case NumericFieldTypes.REQUIRED_ACCURACY: {
                let parsedValue = this.accuracyLimit && this.accuracyLimit !== '' ? parseFloat(this.accuracyLimit) : 0;

                if (parsedValue < 0) {
                    parsedValue = parsedValue * -1;
                }

                if (parsedValue === 0) {
                    this.accuracyLimit = '0';
                } else {
                    this.accuracyLimit = parsedValue.toFixed(2);
                }

                this.template.accuracyLimit =
                    this.isMetricOrg === true ? parsedValue : parsedValue * this.feetToMetersConv;
                break;
            }
        }
    }

    handleNumberInput(input: string, minValue: number, maxValue: number, defaultValue?: number): number {
        const val = input === '' ? '' : Number(input);

        if (!defaultValue && input === '0') {
            return null;
        }
        if (val === 0 || (typeof val === 'number' && !isNaN(val))) {
            return val < minValue ? minValue : val > maxValue ? maxValue : val;
        }
    }

    setRepeatFieldsAutomatically(value: boolean): void {
        this.template.repeatFieldsAutomatically = value;
    }

    setLogPositionsAutomatically(value: boolean): void {
        this.template.logPositionsAutomatically = value;
    }

    enableTimeLogging(value: boolean) {
        const loggingRate = this.template.loggingRate;
        this.isTimeLogging = value;

        loggingRate.time.active = value;
        loggingRate.distance.active = !value;

        if (!loggingRate.time.value && !this.isTimeLogging) {
            loggingRate.time.value = 1;
        }
        if (!loggingRate.distance.value && this.isTimeLogging) {
            loggingRate.distance.value = 1;
        }
    }
}
