import { Component } from '@angular/core';

@Component({
    selector: 'positioning',
    templateUrl: './positioning.component.html',
})
export class PositioningComponent {
    modalIsShowing = false;

    isModalShowing(value: boolean): void {
        this.modalIsShowing = value;
    }
}
