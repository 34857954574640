import 'src/app/shared/leaflet-extensions/leafletMarkerCluster/DistanceGrid.js';
import 'src/app/shared/leaflet-extensions/leafletMarkerCluster/MarkerCluster.js';
import 'src/app/shared/leaflet-extensions/leafletMarkerCluster/MarkerCluster.QuickHull.js';
import 'src/app/shared/leaflet-extensions/leafletMarkerCluster/MarkerCluster.Spiderfier.js';
import 'src/app/shared/leaflet-extensions/leafletMarkerCluster/MarkerClusterGroup.js';
import 'src/app/shared/leaflet-extensions/leafletMarkerCluster/MarkerClusterGroup.Refresh.js';
import 'src/app/shared/leaflet-extensions/leafletMarkerCluster/MarkerClusterGroup.Spiderfier.js';
import 'src/app/shared/leaflet-extensions/leafletMarkerCluster/MarkerOpacity.js';

import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import * as _ from 'lodash-es';
import { GeometryUtils } from 'src/app/shared/common/utility/geometry-utils';
import { CachedFeatureService } from 'src/app/shared/map-data-services/feature/cached-feature.service';
import { Feature } from 'src/app/shared/map-data-services/feature/feature';

import { FeatureMapLayer } from './feature-map-layer';
import { FeatureMapLayerCacheService } from './feature-map-layer-cache.service';
import { FeatureMapLayersService } from './feature-map-layers.service';

const markerClusterGroupOptions = {
    spiderfyOnMaxZoom: false,
    showCoverageOnHover: false,
    zoomToBoundsOnClick: true,
    disableClusteringAtZoom: 18,

    minClusterCount: 10,
    maxClusterRadius: 80, // fixed radius gives best results

    polygonOptions: {},
    chunkDelay: 500
};

@Injectable({
    providedIn: 'root'
})
export class ClusterLayerService {
    constructor(
        private featureMapLayersService: FeatureMapLayersService,
        private featureMapLayerCacheService: FeatureMapLayerCacheService,
        private cachedFeatureService: CachedFeatureService
    ) {}

    extendLeaflet() {
        this._extendLeaflet(this.featureMapLayersService, this.featureMapLayerCacheService, this.cachedFeatureService);
    }

    _extendLeaflet(
        featureMapLayersService: FeatureMapLayersService,
        featureMapLayerCacheService: FeatureMapLayerCacheService,
        cachedFeatureService: CachedFeatureService
    ) {
        (L as any).ClusterLayer = L.LayerGroup.extend({
            map: null,
            layerOrTaskId: null,
            layerColorKey: null,
            markerClusterOrLayerGroup: L.LayerGroup,

            initialize: function (
                layerOrTaskId: string,
                layerColorKey: string,
                zIndex: number,
                enableClustering = true
            ) {
                let clusterLayerThis = this;

                clusterLayerThis.layerOrTaskId = layerOrTaskId || null;
                clusterLayerThis.layerColorKey = layerColorKey || null;

                clusterLayerThis.clusterLayerFeatureMapLayerIds = []; // feature map layers managed by the clusterlayer

                // --------------------------
                // Clustering
                let options: any = {
                    clusterNonPoints: {
                        enabled: false /* leave it to server */
                    }
                };

                clusterLayerThis.markerClusterOrLayerGroup = enableClustering
                    ? new L.MarkerClusterGroup(
                          _.extend({}, markerClusterGroupOptions, options, {
                              zIndex: zIndex,
                              iconCreateFunction: (cluster: L.MarkerCluster) =>
                                  clusterLayerThis.createIcon(cluster, clusterLayerThis.layerColorKey)
                          })
                      )
                    : new L.LayerGroup();

                clusterLayerThis.zoom = -1;

                // --------------------------
            },

            onAdd(map: L.Map): void {
                this.map = map;

                map.addLayer(this.markerClusterOrLayerGroup);

                this.map.on('zoomend', () => this.onHideServerClusterCoverage());
            },

            onRemove(map: L.Map): void {
                if (this.map) {
                    this.map.off('zoomend', () => this.onHideServerClusterCoverage());

                    featureMapLayerCacheService.removeAllFeatureMapLayersForLayer(this.layerOrTaskId);
                    this.markerClusterOrLayerGroup.clearLayers();
                    map.removeLayer(this.markerClusterOrLayerGroup);

                    this.map = null;
                }
            },

            setZIndex(zIndex: number): void {
                this.markerClusterOrLayerGroup.setZIndex(zIndex);
            },

            clearLayers(): void {
                featureMapLayerCacheService.removeAllFeatureMapLayersForLayer(this.layer.id);
                this.markerClusterOrLayerGroup.clearLayers();
                this.clusterLayerFeatureMapLayerIds = [];
            },

            addLayers(featureMapLayers: FeatureMapLayer[]): void {
                if (this.markerClusterOrLayerGroup instanceof L.MarkerClusterGroup) {
                    this.markerClusterOrLayerGroup.addLayers(featureMapLayers, {
                        chunkedLoading: true
                    });
                } else {
                    // L.LayerGroup
                    featureMapLayers.forEach(featureMapLayer => {
                        this.markerClusterOrLayerGroup.addLayer(featureMapLayer);
                    });
                }
                this.clusterLayerFeatureMapLayerIds = _.uniq(
                    _.concat(
                        this.clusterLayerFeatureMapLayerIds,
                        featureMapLayers.map(fml => (fml as any)._leaflet_id)
                    )
                );
            },

            removeLayers(featureMapLayers: FeatureMapLayer[]): void {
                if (this.markerClusterOrLayerGroup instanceof L.MarkerClusterGroup) {
                    this.markerClusterOrLayerGroup.removeLayers(featureMapLayers);
                } else {
                    // L.LayerGroup
                    featureMapLayers.forEach(featureMapLayer => {
                        this.markerClusterOrLayerGroup.removeLayer(featureMapLayer);
                    });
                }
                this.clusterLayerFeatureMapLayerIds = _.uniq(
                    _.difference(
                        this.clusterLayerFeatureMapLayerIds,
                        featureMapLayers.map(fml => (fml as any)._leaflet_id)
                    )
                );
            },

            hasLayer(featureMapLayer: FeatureMapLayer): boolean {
                return this.clusterLayerFeatureMapLayerIds.indexOf((featureMapLayer as any)._leaflet_id) >= 0;
            },

            createIcon(cluster: L.MarkerCluster, layerColorKey: string): L.DivIcon {
                let count = 0;

                if (cluster.getAllChildMarkers) {
                    // markercluster plugin call
                    let children = cluster.getAllChildMarkers();
                    // eslint-disable-next-line @typescript-eslint/prefer-for-of
                    for (let i = 0; i < children.length; i++) {
                        if (children[i]['cluster_count' as keyof L.Marker]) {
                            count += children[i]['cluster_count' as keyof L.Marker] as number;
                        } else {
                            count++;
                        }
                    }
                } else if (cluster['cluster_count' as keyof L.MarkerCluster]) {
                    // custom call
                    count = cluster['cluster_count' as keyof L.MarkerCluster] as number;
                }

                return this.createMarkerClusterIcon(count, layerColorKey);
            },

            // Note: includes point features, and server clusters of any features (which have geometry === 'Point')
            isClusterable: (feature: Feature): boolean =>
                feature.geometry && GeometryUtils.getGeometryType(feature.geometry.type) === 'Point',

            createOrUpdateMarkers(feature: Feature, layerId: string, layerColorKey: string): FeatureMapLayer {
                let featureMapLayer: any;

                // Check for cluster
                if (feature.metadata && feature.metadata.cluster && feature.metadata.cluster_count) {
                    featureMapLayer = this.createMarkerClusterMapLayer(feature, layerColorKey);
                } else {
                    feature = cachedFeatureService.updateFromCacheFeature(feature);
                    featureMapLayersService.setLayerSymbologyProperties(feature, this.layerColorKey);
                    featureMapLayer = featureMapLayersService.createFeatureMapLayer(feature);
                }

                // ! Note: Can get duplicates if zoomed out far enough to see the same part of the world twice.
                // ! Addressed for now by limiting zoom level.
                featureMapLayerCacheService.addOrUpdateFeatureMapLayer(feature, featureMapLayer, layerId);
                featureMapLayer.feature = feature;

                return featureMapLayer;
            },

            onShowServerClusterCoverage(layerContext: any, e: L.LeafletEvent): void {
                let featureMapLayer = e.target;
                let map = layerContext.map;

                if (this.shownPolygon) {
                    map.removeLayer(this.shownPolygon);
                }
                if (featureMapLayer.serverCluster && featureMapLayer.cluster_hull) {
                    this.shownPolygon = L.polygon(
                        GeometryUtils.getLatLngs(featureMapLayer.cluster_hull),
                        markerClusterGroupOptions.polygonOptions
                    );
                    map.addLayer(this.shownPolygon);
                }
            },

            onHideServerClusterCoverage(): void {
                let map = this.map;
                if (map && this.shownPolygon) {
                    map.removeLayer(this.shownPolygon);
                }
                this.shownPolygon = null;
            },

            createMarkerClusterMapLayer(feature: Feature, layerColorKey: string): L.Marker<any> {
                if (
                    !feature ||
                    !feature.geometry ||
                    !feature.geometry.coordinates ||
                    feature.geometry.type !== 'Point'
                ) {
                    return null;
                }

                let coordinates = feature.geometry.coordinates;
                let featureMapLayer = new L.Marker(L.latLng(coordinates[1], coordinates[0]), {
                    icon: this.createMarkerClusterIcon(feature.metadata.cluster_count, /* '990000' */ layerColorKey)
                });

                // propagate cluster properties onto the maplayer
                _.extend(featureMapLayer, {
                    serverCluster: true,
                    cluster_count: feature.metadata.cluster_count,
                    cluster_hull: feature.metadata.cluster_hull
                });

                // add/remove event handlers
                featureMapLayer.on('add', () => {
                    if (markerClusterGroupOptions.zoomToBoundsOnClick) {
                        featureMapLayer.on('click', e => this.onZoomToServerCluster(e));
                    }
                    if (markerClusterGroupOptions.showCoverageOnHover) {
                        featureMapLayer.on('mouseover', e => this.onShowServerClusterCoverage(this, e));
                        featureMapLayer.on('mouseout', this.onHideServerClusterCoverage, this);
                    }
                });

                featureMapLayer.on('remove', () => {
                    if (markerClusterGroupOptions.zoomToBoundsOnClick) {
                        featureMapLayer.off('click', e => this.onZoomToServerCluster(e));
                    }
                    if (markerClusterGroupOptions.showCoverageOnHover) {
                        featureMapLayer.off('mouseover', e => this.onShowServerClusterCoverage(e));
                        featureMapLayer.off('mouseout', e => this.onHideServerClusterCoverage());
                    }
                });

                return featureMapLayer;
            },

            createMarkerClusterIcon: (count: number, layerColorKey: string): L.DivIcon => {
                let size = count < 10000 ? 40 : count < 100000 ? 50 : 60;
                let sizeClass = 'marker-cluster-' + (count < 10000 ? 'small' : count < 100000 ? 'medium' : 'large');
                return new L.DivIcon({
                    className: 'marker-cluster ' + sizeClass,
                    html:
                        '<div class="marker-cluster-outer" style="background-color: #' +
                        layerColorKey +
                        '"></div><div class="marker-cluster-inner" style="background-color: #' +
                        layerColorKey +
                        '"><span>' +
                        count +
                        '</span></div>',
                    iconSize: new L.Point(size, size)
                });
            },

            // --------------
            // server-side cluster event handlers

            onZoomToServerCluster(e: { target: any }): void {
                // handle zoom to server cluster
                let featureMapLayer = e.target as FeatureMapLayer;

                if ((featureMapLayer as any).serverCluster && (featureMapLayer as any).cluster_hull) {
                    this.map.fitBounds(GeometryUtils.getBounds((featureMapLayer as any).cluster_hull));
                }
            }
        });
    }
}
