import * as L from 'leaflet';
import * as _ from 'lodash-es';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { TemplatedFeatureMetadataProperty } from 'src/app/feature/map-viewer/side-panel/feature-panel/feature-fields/templated-feature';
import { PostProcessingStatus } from 'src/app/feature/post-processing/options/post-processing-options.component';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';

import { FieldType, FieldTypeOption } from '../../template-services/field';
import { ArrayUtils } from '../utility/array-utils';
import { DateUtils } from '../utility/date-utils';
import { GeneralUtils } from '../utility/general-utils';
import { StringUtils } from '../utility/string-utils';
import { AttrValues, FeatureFilter } from './feature-filter';

export enum Op {
    EQ = 'EQ',
    IN = 'IN',
    OVERLAPS = 'OVERLAPS',
    NOTEXISTS = 'NOTEXISTS',
    EXISTS = 'EXISTS',
    LT = 'LT',
    LTEQ = 'LTEQ',
    GT = 'GT',
    GTEQ = 'GTEQ',
    NE = 'NE',
    LIKE = 'LIKE',
    CONTAINED_IN = 'CONTAINED_IN',
    NOTIN = 'NOTIN',
    NOTCONTAINED_IN = 'NOTCONTAINED_IN',
    NOTLIKE = 'NOTLIKE'
}

//   TODO:  THIS NEEDS SOME SERIOUS REFACTORING IT IS NEAR UNREADABLE

// Full feature filter - all parameters required by the API
export class FullFeatureFilter {
    userIds: string[];
    fromDate: Date;
    toDate: Date;
    unassignedUser = false; // set true via "Imported" checkbox
    isExported = false; // set to ["exported"] via "Exported" checkbox
    isNotExported = false; // set true via "Not Exported" checkbox
    isPostProcessingDone = false;
    isPostProcessingPending = false;
    isPostProcessingFailed = false;
    isPostProcessingUnprocessed = false;
    selectedFeatureIds: string[];
    fromTimestamp: Date;
    toTimestamp: Date;
    bounds: L.LatLngBounds | L.Polygon;
    workspaceId: string;
    layers: Layer[];
    taskFeatures: { todoIds: string[]; featureIds: string[] };
    geometryType: string;

    ids: string[];
    selectedUsersInfo: string[] = []; // To support filtering features migrated from insphere
    selectedLayer: string;
    selectedField: string;
    selectedFieldTypeOption: FieldTypeOption = null;
    attrValues: AttrValues;
    availableCustomTags: string[];
    customTags: string[];
    noCustomTags: boolean;

    // Model/Constructor
    constructor(featureFilter: FeatureFilter = null) {
        this.userIds = featureFilter ? featureFilter.userIds : null;
        this.fromDate = featureFilter ? featureFilter.fromDate : null;
        this.toDate = featureFilter ? featureFilter.toDate : null;
        this.unassignedUser = featureFilter ? featureFilter.unassignedUser : false;
        this.isExported = featureFilter ? featureFilter.isExported : false;
        this.isNotExported = featureFilter ? featureFilter.isNotExported : false;
        this.isPostProcessingDone = featureFilter ? featureFilter.isPostProcessingDone : false;
        this.isPostProcessingPending = featureFilter ? featureFilter.isPostProcessingPending : false;
        this.isPostProcessingFailed = featureFilter ? featureFilter.isPostProcessingFailed : false;
        this.isPostProcessingUnprocessed = featureFilter ? featureFilter.isPostProcessingUnprocessed : false;
        this.selectedLayer = featureFilter ? featureFilter.selectedLayer : null;
        this.selectedField = featureFilter ? featureFilter.selectedField : null;
        this.selectedFieldTypeOption = featureFilter ? featureFilter.selectedFieldTypeOption : null;
        this.attrValues = featureFilter ? featureFilter.attrValues : null;
        this.availableCustomTags = featureFilter ? featureFilter.availableCustomTags : null;
        this.customTags = featureFilter ? featureFilter.customTags : null;
        this.noCustomTags = featureFilter ? featureFilter.noCustomTags : false;

        // selectedFeatureIds is not passed through via the FeatureFilter
        this.selectedFeatureIds = [];

        // Note: fromTimestamp and toTimestamp are both of type Date (at 0:00 local time) - need to add whole day for toTimestamp
        this.fromTimestamp = featureFilter && featureFilter.fromDate ? featureFilter.fromDate : null; // inclusive i.e. >=
        this.toTimestamp = featureFilter && featureFilter.toDate ? DateUtils.addDays(featureFilter.toDate, 1) : null; // exclusive i.e. <

        this.selectedUsersInfo =
            featureFilter && featureFilter.selectedUsersInfo ? featureFilter.selectedUsersInfo : [];

        this.bounds = null;
        this.workspaceId = null;
        this.layers = null;
        this.geometryType = null;
        this.taskFeatures = { todoIds: [], featureIds: [] };
    }

    private buildSingleOrArrayValue(value: any): any {
        return ArrayUtils.isArray(value) && value.length === 1 ? value[0] : value;
    }

    filter(operator: Op = Op.EQ, field: string, value?: string | boolean | Array<any>) {
        if (operator === Op.EQ && Array.isArray(value)) {
            operator = Op.IN;
        }
        return {
            operator: operator,
            field: field,
            operand: this.buildSingleOrArrayValue(value)
        };
    }

    buildFilterRequest(): any[] {
        let filterRequest: any[] = []; // clauses added at this level are ANDed together

        if (this.bounds) {
            filterRequest.push(this.filter(Op.OVERLAPS, 'geometry', this.boundsToWktBounds(this.bounds)));
        }

        this.layers = this.layers || [];

        if (this.layers.length) {
            let layersFilterRequest: { or: any[] } = {
                or: []
            };

            // Layers in file viewer mode and getDistinctFeatureValues request
            const isFileViewer = _.filter(
                this.layers,
                layer =>
                    (layer.id && layer.id.indexOf('imported_') > -1) ||
                    (layer.id === null && layer.geoLayerType === null)
            );

            if (isFileViewer && isFileViewer.length) {
                let fileViewerLayersFilterRequest: { and: any[] } = {
                    and: []
                };
                let fileVersionIds: string[] = [];
                let sourceLayerNames: string[] = [];
                this.layers.forEach(layer => {
                    fileVersionIds.push(layer.fileVersionId);
                    if (!GeneralUtils.isNullUndefinedOrNaN(layer.sourceLayerName)) {
                        sourceLayerNames.push(layer.sourceLayerName);
                    }
                });

                // One geo file can contain multiple layers so include file version id and source layer name
                fileViewerLayersFilterRequest.and.push({
                    operator: Op.EQ,
                    field: `metadata.${TemplatedFeatureMetadataProperty.FILE_CONNECT_VERSION_ID}`,
                    operand: this.buildSingleOrArrayValue(fileVersionIds)
                });

                // source layer is required because a single file upload can have multiple layers
                // and this filter is required to toggle them on/off to view the data in them.
                if (sourceLayerNames && sourceLayerNames.length) {
                    fileViewerLayersFilterRequest.and.push({
                        operator: Op.EQ,
                        field: `metadata.${TemplatedFeatureMetadataProperty.FILE_SOURCE_LAYER}`,
                        operand: this.buildSingleOrArrayValue(sourceLayerNames)
                    });
                }
                layersFilterRequest.or.push(fileViewerLayersFilterRequest);
            } else {
                const layerIds = this.selectedLayer
                    ? this.layers.filter(layer => layer.id === this.selectedLayer).map(layer => layer.id)
                    : this.layers.map(layer => layer.id);

                layersFilterRequest.or.push({
                    operator: Op.EQ,
                    field: `metadata.${TemplatedFeatureMetadataProperty.COMMON_LAYER_ID}`,
                    operand: this.buildSingleOrArrayValue(layerIds)
                });
            }

            // optimize the layers sub-expression, then add it
            if (layersFilterRequest.or && layersFilterRequest.or.length === 1) {
                layersFilterRequest = layersFilterRequest.or[0]; // no need for "or" of single value
            }

            filterRequest.push(layersFilterRequest);
        }

        if (this.workspaceId) {
            const workspaceIdRequest: { or: any[] } = {
                or: []
            };
            workspaceIdRequest.or.push(
                this.filter(
                    Op.EQ,
                    `metadata.${TemplatedFeatureMetadataProperty.COLLECTION_WORKSPACE_ID}`,
                    this.workspaceId
                )
            );
            workspaceIdRequest.or.push(
                this.filter(Op.EQ, `metadata.${TemplatedFeatureMetadataProperty.WORKSPACE_ID}`, this.workspaceId)
            );
            filterRequest.push(workspaceIdRequest);
        }

        if (this.fromTimestamp) {
            // inclusive
            filterRequest.push(
                this.filter(
                    Op.GTEQ,
                    `metadata.${TemplatedFeatureMetadataProperty.COLLECTION_UTC}`,
                    DateUtils.utcDateFormat(this.fromTimestamp)
                )
            );
        }
        if (this.toTimestamp) {
            // exclusive
            filterRequest.push(
                this.filter(
                    Op.LTEQ,
                    `metadata.${TemplatedFeatureMetadataProperty.COLLECTION_UTC}`,
                    DateUtils.utcDateFormat(this.toTimestamp)
                )
            );
        }

        if (this.userIds && this.userIds.length) {
            const userModifiedByOrCollectedByRequest: { or: any[] } = {
                or: []
            };

            userModifiedByOrCollectedByRequest.or.push(
                this.filter(Op.EQ, `metadata.${TemplatedFeatureMetadataProperty.COLLECTION_UPDATED_BY}`, this.userIds)
            );
            if (this.selectedUsersInfo && this.selectedUsersInfo.length) {
                // Filtering data collected in insphere system
                userModifiedByOrCollectedByRequest.or.push({
                    operator: Op.EQ,
                    field: `metadata.${TemplatedFeatureMetadataProperty.COLLECTION_USER_INFO}`,
                    operand: this.buildSingleOrArrayValue(this.selectedUsersInfo)
                });
            }

            filterRequest.push(userModifiedByOrCollectedByRequest);
        }

        if (this.selectedLayer && this.selectedFieldTypeOption && this.attrValues) {
            const propertyName = this.selectedFieldTypeOption.name;
            const filterType = this.selectedFieldTypeOption.filterType;
            const multipleSelect =
                Array.isArray(this.attrValues) && this.attrValues.every(val => typeof val === 'string');

            switch (filterType) {
                case FieldType.YesNo:
                    this.attrValues = this.attrValues === 'null' ? null : this.attrValues === 'true' ? true : false;
                    filterRequest.push(this.filter(Op.EQ, `properties.${propertyName}`, this.attrValues));
                    break;
                case FieldType.Number:
                    filterRequest.push(this.filter(Op.LIKE, `properties.${propertyName}`, this.attrValues as string));
                    break;
                case FieldType.Text:
                    const attrValuesSafeString = StringUtils.escapeSql(String(this.attrValues));

                    if (propertyName.includes('choice')) {
                        const isSelectedOther = attrValuesSafeString === 'Other';
                        const codedChoiceOrChoiceList = this.selectedFieldTypeOption.codedChoiceOrChoiceList as any;
                        const isCodedChoiceList = codedChoiceOrChoiceList[0].hasOwnProperty('code');

                        // returns all choices except 'Other' = [string, string, ...]
                        const allChoicesList = isCodedChoiceList
                            ? codedChoiceOrChoiceList
                                  .filter((choice: { code: any }) => choice.code !== 'Other')
                                  .map((choice: { code: any }) => choice.code)
                            : codedChoiceOrChoiceList
                                  .filter((choice: { text: any }) => choice.text !== 'Other')
                                  .map((choice: { text: any }) => choice.text);

                        // checks if there is a selected value from coded choice / choice list
                        const selectedChoices = ArrayUtils.isArray(this.attrValues)
                            ? (this.attrValues as string[])
                            : [this.attrValues];

                        // requirement for choiceQuery to limit the number of choices to be queried
                        const unselectedChoices: string[] = allChoicesList.filter(
                            (choice: string) => !selectedChoices.includes(choice)
                        );

                        const likeOrNotIn = isSelectedOther ? Op.NOTIN : Op.LIKE;

                        let choiceFilterRequest: { and?: any[]; or?: any[] } = {
                            or: [],
                            and: []
                        };

                        // only do the query if selected choice(s) exist
                        if (selectedChoices?.length) {
                            filterRequest.push(
                                this.choiceQuery(
                                    likeOrNotIn,
                                    allChoicesList,
                                    choiceFilterRequest,
                                    propertyName,
                                    unselectedChoices,
                                    selectedChoices as string[]
                                )
                            );
                        }
                    } else {
                        // text search bar - not choice or coded_choice
                        filterRequest.push(
                            this.filter(Op.LIKE, `properties.${propertyName}`, attrValuesSafeString as string)
                        );
                    }

                    break;
                case FieldType.Date:
                    const attrValuesDateObj = this.attrValues as { from: string | Date; to: string | Date };

                    // check all from and to dates are valid and enforce conversion to moment utc format
                    attrValuesDateObj.from = DateUtils.isValidDate(attrValuesDateObj.from)
                        ? DateUtils.momentUtcDateFormat(new Date(attrValuesDateObj.from))
                        : null;
                    attrValuesDateObj.to = DateUtils.isValidDate(attrValuesDateObj.to)
                        ? DateUtils.momentUtcDateFormat(new Date(attrValuesDateObj.to))
                        : null;

                    // Add query per each date if from / to exist
                    if (typeof this.attrValues === 'object' && attrValuesDateObj?.from) {
                        filterRequest.push(this.filter(Op.GTEQ, `properties.${propertyName}`, attrValuesDateObj.from));
                    }

                    if (typeof this.attrValues === 'object' && attrValuesDateObj?.to) {
                        const singleDateCondition = DateUtils.isSameDay(attrValuesDateObj.from, attrValuesDateObj.to)
                            ? DateUtils.startOfNextDay(attrValuesDateObj.to, true)
                            : attrValuesDateObj.to;

                        const LessThanEqualsOrNot = DateUtils.isSameDay(attrValuesDateObj.from, attrValuesDateObj.to)
                            ? Op.LT
                            : Op.LTEQ;

                        filterRequest.push(
                            this.filter(
                                LessThanEqualsOrNot,
                                `properties.${propertyName}`,
                                singleDateCondition as string
                            )
                        );
                    }
                    break;
            }
        }

        // both unchecked => show all irrespective of tags
        // only isExported checked => show exported only
        // only isNotExported checked => show features yet to be exported
        // both checked => same as both not checked

        // aggregate tags filters with OR operator between them

        const tagsFilterRequest: { or: any[] } = {
            or: []
        };

        if (this.isExported && !this.isNotExported) {
            tagsFilterRequest.or.push(this.filter(Op.EXISTS, 'tags', 'exported'));
        } else if (!this.isExported && this.isNotExported) {
            tagsFilterRequest.or.push(this.filter(Op.NOTEXISTS, 'tags', 'exported'));
        }

        // AND operation for custom tags filters aggregation

        const customTagsFilterRequest: { and: any[] } = {
            and: []
        };

        this.customTags?.forEach(tag => {
            customTagsFilterRequest.and.push(this.filter(Op.EXISTS, 'tags', tag));
        });

        if (this.noCustomTags) {
            this.availableCustomTags.forEach(tag => {
                customTagsFilterRequest.and.push(this.filter(Op.NOTEXISTS, 'tags', tag));
            });
        }

        if (this.unassignedUser) {
            const importedDataFilterRequest: { or: any[] } = {
                or: []
            };
            // data from old fs
            importedDataFilterRequest.or.push(
                this.filter(Op.EXISTS, `metadata.${TemplatedFeatureMetadataProperty.FILE_CONNECT_FILE_ID}`)
            );

            // data from new fs
            importedDataFilterRequest.or.push(
                this.filter(Op.EXISTS, `metadata.${TemplatedFeatureMetadataProperty.IMPORT_ID}`)
            );
            tagsFilterRequest.or.push(importedDataFilterRequest);
        }

        // aggregate post processing filters with or operator between them

        const postProcessingDataFilterRequest: { or: any[] } = {
            or: []
        };

        if (this.isPostProcessingDone) {
            postProcessingDataFilterRequest.or.push(
                this.filter(
                    Op.EQ,
                    `metadata.${TemplatedFeatureMetadataProperty.POST_PROCESSED_STATUS}`,
                    PostProcessingStatus.PROCESSED
                )
            );
        }

        if (this.isPostProcessingPending) {
            postProcessingDataFilterRequest.or.push(
                this.filter(
                    Op.EQ,
                    `metadata.${TemplatedFeatureMetadataProperty.POST_PROCESSED_STATUS}`,
                    PostProcessingStatus.PENDING
                )
            );
        }

        if (this.isPostProcessingFailed) {
            postProcessingDataFilterRequest.or.push(
                this.filter(
                    Op.EQ,
                    `metadata.${TemplatedFeatureMetadataProperty.POST_PROCESSED_STATUS}`,
                    PostProcessingStatus.FAILED
                )
            );
        }

        if (this.isPostProcessingUnprocessed) {
            postProcessingDataFilterRequest.or.push(
                this.filter(
                    Op.EQ,
                    `metadata.${TemplatedFeatureMetadataProperty.POST_PROCESSED_STATUS}`,
                    PostProcessingStatus.UNPROCESSED
                )
            );
        }

        // combining tags filter and post processing filters with and operator between them to get structire like
        // ( (tag filter1 || tag filter2 || ...) && (pp filter1 || pp filter2 || ...) )

        if (tagsFilterRequest.or.length && postProcessingDataFilterRequest.or.length) {
            const combinedTagsAndPostProcessingFilterRequest: { and: any[] } = {
                and: []
            };

            combinedTagsAndPostProcessingFilterRequest.and.push(tagsFilterRequest);
            combinedTagsAndPostProcessingFilterRequest.and.push(postProcessingDataFilterRequest);

            filterRequest.push(combinedTagsAndPostProcessingFilterRequest);
        } else if (tagsFilterRequest.or.length) {
            filterRequest.push(tagsFilterRequest);
        } else if (postProcessingDataFilterRequest.or.length) {
            filterRequest.push(postProcessingDataFilterRequest);
        }

        if (customTagsFilterRequest.and.length) {
            filterRequest.push(customTagsFilterRequest);
        }

        if (this.geometryType) {
            // exclusive
            filterRequest.push(
                this.filter(Op.EQ, `metadata.${TemplatedFeatureMetadataProperty.GEOMETRY_TYPE}`, this.geometryType)
            );
        }

        if (
            (this.taskFeatures.featureIds && this.taskFeatures.featureIds.length) ||
            (this.taskFeatures.todoIds && this.taskFeatures.todoIds.length)
        ) {
            let todosAndOrFeaturesRequest: { or: any[]; and: any[] } = {
                or: [],
                and: []
            };

            if (this.taskFeatures.featureIds && this.taskFeatures.featureIds.length) {
                todosAndOrFeaturesRequest.or.push(this.filter(Op.EQ, 'id', this.taskFeatures.featureIds));
            }

            if (this.taskFeatures.todoIds && this.taskFeatures.todoIds.length) {
                todosAndOrFeaturesRequest.or.push(
                    this.filter(
                        Op.EQ,
                        `metadata.${TemplatedFeatureMetadataProperty.COLLECTION_TODO_ID}`,
                        this.taskFeatures.todoIds
                    )
                );
            }

            todosAndOrFeaturesRequest = {
                or: todosAndOrFeaturesRequest.or,
                and: undefined
            };

            if (todosAndOrFeaturesRequest.or && todosAndOrFeaturesRequest.or.length === 1) {
                todosAndOrFeaturesRequest = todosAndOrFeaturesRequest.or[0]; // no need for "or" of single value
            }
            filterRequest.push(todosAndOrFeaturesRequest);
        }

        if (!this.layers.length && this.selectedFeatureIds && this.selectedFeatureIds.length) {
            filterRequest.push(this.filter(Op.EQ, 'id', this.selectedFeatureIds));
        }

        return filterRequest;
    }

    generateSummary($translate: TranslationService): string {
        let summary = ' ';
        let fromDate: string;
        let toDate: string;

        // TODO: check why $translate instant doesn't work with interpolateParams
        if (this.layers && this.layers.length) {
            if (this.layers.length === 1) {
                summary =
                    summary +
                    $translate.instant('from') +
                    ' ' +
                    this.layers.length +
                    ' ' +
                    $translate.instant('TC.Common.Layer') +
                    ' ';
            } else {
                summary =
                    summary +
                    $translate.instant('from') +
                    ' ' +
                    this.layers.length +
                    ' ' +
                    $translate.instant('TC.Common.Lower_Layers') +
                    ' ';
            }
        }

        if (this.fromDate && this.toDate) {
            fromDate = DateUtils.formatDateFromDateTime(this.fromDate, 'shortDate', false);
            toDate = DateUtils.formatDateFromDateTime(this.toDate, 'shortDate', false);
            summary =
                summary +
                $translate.instant('TC.Common.Collected') +
                ' ' +
                $translate.instant('from') +
                ' ' +
                fromDate +
                ' ' +
                $translate.instant('to') +
                ' ' +
                toDate +
                ' ';
        }

        if (this.fromDate && !this.toDate) {
            fromDate = DateUtils.formatDateFromDateTime(this.fromDate, 'shortDate', false);
            summary =
                summary +
                $translate.instant('TC.Common.Collected') +
                $translate.instant('TC.Common.Before') +
                ' ' +
                fromDate +
                ' ';
        }

        if (!this.fromDate && this.toDate) {
            toDate = DateUtils.formatDateFromDateTime(this.toDate, 'shortDate', false);
            summary =
                summary +
                $translate.instant('TC.Common.Collected') +
                $translate.instant('TC.Common.After') +
                ' ' +
                toDate +
                ' ';
        }

        return summary;
    }

    // -------------------

    boundsToLatLngArrayBounds(bounds: L.LatLngBounds | L.Polygon): L.LatLng[] {
        if (bounds instanceof L.LatLngBounds) {
            const latLngBounds = bounds as L.LatLngBounds;
            // Convert to polygon
            return [
                // NB: returned anti-clockwise
                new L.LatLng(latLngBounds.getSouthWest().lat, latLngBounds.getSouthWest().lng),
                new L.LatLng(latLngBounds.getSouthWest().lat, latLngBounds.getNorthEast().lng),
                new L.LatLng(latLngBounds.getNorthEast().lat, latLngBounds.getNorthEast().lng),
                new L.LatLng(latLngBounds.getNorthEast().lat, latLngBounds.getSouthWest().lng),
                new L.LatLng(latLngBounds.getSouthWest().lat, latLngBounds.getSouthWest().lng)
            ];
        } else if (bounds instanceof L.Polygon) {
            const polygonBounds = bounds as L.Polygon;
            let latLngs: L.LatLng[] =
                polygonBounds.getLatLngs().length > 0 ? (polygonBounds.getLatLngs()[0] as L.LatLng[]) : []; // use just the first ring
            return this.normalizeLatLngArrayBounds(latLngs);
        } else {
            return [];
        }
    }

    // ensure polygon bounds are counter-clockwise (Queries expect it to be counter-clock-wise)
    normalizeLatLngArrayBounds(polygonBounds: L.LatLng[]): L.LatLng[] {
        let signedArea = 0;
        let point1 = polygonBounds[polygonBounds.length - 1];
        polygonBounds.forEach(point2 => {
            signedArea += point1.lng * point2.lat - point2.lng * point1.lat;
            point1 = point2;
        });
        const normalizedPolygonBounds = polygonBounds.slice(); // shallow copy
        if (signedArea < 0) {
            // clockwise
            normalizedPolygonBounds.reverse(); // make anti-clockwise
        }
        return normalizedPolygonBounds;
    }

    boundsToWktBounds(bounds: L.LatLngBounds | L.Polygon<any>): string {
        const polygonBounds = this.boundsToLatLngArrayBounds(bounds);
        let concatPoint = '';
        let firstPoint = '';
        let latestPoint = '';
        // assumption is that polygon is provided in anti-clockwise direction.
        polygonBounds.forEach((point, key) => {
            latestPoint = point.lng + ' ' + point.lat;
            if (key === 0) {
                firstPoint = latestPoint;
            } else {
                concatPoint += ',';
            }
            concatPoint += latestPoint;
        });
        // For polygon, last point should be same of first point with accurate decimal points.
        if (firstPoint !== latestPoint) {
            concatPoint += ',' + firstPoint;
        }
        return 'POLYGON((' + concatPoint + '))';
    }

    // choiceQuery can return a single or multiple queries
    choiceQuery(
        operation: any,
        allChoicesList: string[],
        choiceFilterRequest: { and?: any[]; or?: any[] },
        propertyName: string,
        unselectedChoices: string[],
        selectedChoices: string[]
    ): any {
        if (selectedChoices.includes('Other')) {
            allChoicesList = allChoicesList.map(choice => StringUtils.escapeSql(choice));
            // ticked ON  - 'Other' AND atleast 1 of choices
            choiceFilterRequest.and.push(this.filter(Op.NOTIN, `properties.${propertyName}`, allChoicesList));
            selectedChoices
                .filter(choice => choice !== 'Other')
                .forEach((pattern: string) => {
                    choiceFilterRequest.and.push(
                        this.filter(operation, `properties.${propertyName}`, StringUtils.escapeSql(pattern))
                    );
                });
        } else {
            unselectedChoices = unselectedChoices
                ?.filter(choice => choice !== 'Other')
                .map(choice => StringUtils.escapeSql(choice));
            // ticked OFF 'Other'
            choiceFilterRequest.or.push(this.filter(Op.NOTIN, `properties.${propertyName}`, unselectedChoices));
            selectedChoices.forEach((pattern: string) => {
                choiceFilterRequest.and.push(
                    this.filter(operation, `properties.${propertyName}`, StringUtils.escapeSql(pattern))
                );
            });
        }

        return choiceFilterRequest;
    }
}
