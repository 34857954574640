<div class="feature-field">
    <label class="feature-label" [ngClass]="{ disabled: field.value == null }">
        {{ field.displayName }}
        <span *ngIf="field.required">*</span>
    </label>
    <div class="feature-data" *ngIf="!editMode">
        <ul class="choice-list">
            <ng-container *ngFor="let option of field.options">
                <li *ngIf="radioOptions[option.description]?.selected">
                    <i class="icon-24-dark-radio-selected"></i>
                    <label title="{{ option.description }}">{{ option.description }}</label>
                </li>
            </ng-container>
        </ul>
    </div>
    <div class="editable-control" *ngIf="editMode">
        <ng-container *ngFor="let option of field.options; index as i">
            <div class="choice">
                <gsp-radio
                    id="{{ field.name }}-{{ i }}"
                    name="{{ field.name }}-{{ i }}"
                    [(ngModel)]="radioOptions[option.description].selected"
                    (click)="toggleOption(option.description)"
                    [radioValue]="true"
                    radioLabelClass="radio-label-wrap"
                    radioInputClass="radio-input"
                    radioButtonClass="dark-radio-button"
                    class="ng-pristine ng-valid"
                    [valueText]="option.description"
                >
                </gsp-radio>
            </div>
        </ng-container>
    </div>
</div>
