export interface GeoWorkspaceRelationships {
    realtimeCorrectionIds?: string[];
    layerIds?: string[];
    dataUpdateJobIds?: string[];
    mapCacheIds?: string[];
    userIds?: string[];
}

export interface GeoWorkspaceRelationshipsRequest {
    realtimeCorrectionIds?: string[];
    layerIds?: string[];
    dataUpdateJobIds?: string[];
    mapCacheIds?: string[];
}

export enum RelationshipType {
    LAYER_TO_WORKSPACE = 'LayerAttachedToWorkspace',
    MAP_CACHE_TO_WORKSPACE = 'MapCacheAttachedToWorkspace'
}
