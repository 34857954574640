import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { GspNumberType } from 'src/app/shared/common/components/gsp-number/gsp-number.component';
import { UnitConversionService } from 'src/app/shared/common/utility/unit-conversion.service';
import { FieldType } from 'src/app/shared/template-services/field';
import { FieldErrors } from 'src/app/shared/template-services/field/field-errors';

import { NumberFeatureField } from '../feature-field';

@Component({
    templateUrl: './number-field.component.html',
    selector: 'number-field'
})
export class NumberFieldComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input()
    field: NumberFeatureField;

    @Input()
    editMode: boolean;

    displayUnit: string;
    displayUnitHtml: string;
    error_messages: string[];
    isNumberInRange: boolean;

    // exposing enum to template
    public GspNumberType = GspNumberType;

    private destroyed = new Subject<void>();

    @ViewChild('numberInput')
    numberInput: NgModel;

    constructor(private unitConversionService: UnitConversionService) {}

    ngOnInit(): void {
        this.displayUnit = '';

        this.field.minimum = _.isNumber(this.field.minimum) ? this.field.minimum : -999999999999999;
        this.field.maximum = _.isNumber(this.field.maximum) ? this.field.maximum : 999999999999999;

        this.displayUnit =
            this.field.type === FieldType.Angle || this.field.numberType === FieldType.Angle
                ? this.field.angleUnit
                : this.field.distanceUnit;

        if (this.displayUnit) {
            this.displayUnitHtml = this.unitConversionService.convertUnitToHtml(this.displayUnit.toLowerCase());
        }

        if (isNaN(this.field.value)) {
            this.field.value = null;
        }
    }

    ngAfterViewInit(): void {
        this.numberInput.statusChanges.pipe(takeUntil(this.destroyed), delay(0)).subscribe(() => {
            this.checkValidationErrors();
        });
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    private checkValidationErrors(): void {
        this.error_messages = [];
        if (!this.numberInput.control.valid) {
            this.field.hasError = true;
            if (this.numberInput.control.errors?.max) {
                this.addErrorMessage(FieldErrors.errorMessages.max.message);
            } else if (this.numberInput.control.errors?.min) {
                this.addErrorMessage(FieldErrors.errorMessages.min.message);
            }
            if (this.numberInput.control.errors?.decimal) {
                this.addErrorMessage(FieldErrors.errorMessages.decimal.message);
            }
        } else {
            this.field.hasError = false;
        }
    }

    private addErrorMessage(errorMessage: string) {
        if (!this.error_messages.includes(errorMessage)) {
            this.error_messages.push(errorMessage);
        }
    }
}
