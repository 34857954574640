<div class="app-loading" *ngIf="loading"></div>
<gsp-confirmation-popup
    *ngIf="!loading && !showErrorModal"
    [heading]="'MapViewer.Template.DiscardDraft.Header'"
    (cancel)="closeModal()"
    [confirmationButtonText]="'TC.Common.Discard'"
    [confirmAction]="discardDraftChangesAction"
    [message]="'MapViewer.Template.DiscardDraft.Description'"
    id="workspace-delete"
>
</gsp-confirmation-popup>
<gsp-popup
    *ngIf="!loading && showErrorModal"
    [heading]="'MapViewer.Template.DiscardDraft.Header'"
    [size]="ModalSize.ExtraSmall"
    [showClose]="true"
    (closed)="closeModal()"
    [showDone]="true"
    [doneBtnTitle]="'TC.Common.OK' | translate"
    (done)="closeModal()"
>
    <ng-container transclude-popup-content>
        <div>
            <p translate>MapViewer.Template.DiscardDraft.NoChanges</p>
        </div>
    </ng-container>
</gsp-popup>
