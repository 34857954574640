import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';

export interface FileViewerSettings {
    projectId: string;
    lastViewedConnectFileVersionIds: string[];
    forceUpdate: boolean;
    isFileViewer?: boolean;
    workspaceId?: string;
    region?: string;
}
@Component({
    selector: 'load-file-viewer',
    template: `
        <div class="container">
            <div class="app-loading" *ngIf="loading"></div>
        </div>
    `
})
export class LoadFileviewerComponent implements OnInit {
    public projectId: string = null;
    public connectFileVersionIdsList: string = null;
    public connectFileVersionIds: string[] = [];
    public region: string = null;

    public loading = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private projectStream: ProjectStreamService,
        private mapWorkspacesStore: MapWorkspacesStoreService
    ) {}

    public ngOnInit(): void {
        this.projectId = this.activatedRoute.snapshot.params.projectId || null;
        this.connectFileVersionIdsList = this.activatedRoute.snapshot.params.connectFileVersionIds || null;
        this.connectFileVersionIds = this.connectFileVersionIdsList ? this.connectFileVersionIdsList.split(',') : [];
        this.region = this.activatedRoute.snapshot.queryParams.region || null;
        this.loading = true;

        this.projectStream.initCurrentProject(this.projectId).then(project => {
            this.mapWorkspacesStore
                .getDefaultOrCreateMapWorkspace(project.id, true, project.isChanged)
                .then(workspace => {
                    let settings: FileViewerSettings = {
                        projectId: project.id,
                        lastViewedConnectFileVersionIds: this.connectFileVersionIds,
                        forceUpdate: true
                    };
                    sessionStorage.setItem('fileViewerSettings', JSON.stringify(settings));

                    this.router.navigate(['fileViewerImporter'], {
                        queryParams: {
                            projectId: workspace.projectId,
                            workspaceId: workspace.id,
                            region: this.region
                        },
                        skipLocationChange: true
                    });
                });
        });
    }
}
