import { Component, Input } from '@angular/core';
import { Field } from 'src/app/shared/template-services/field';
import { YesNoCondition } from 'src/app/shared/template-services/rule/yes-no-condition';

@Component({
    selector: 'yes-no-rule',
    templateUrl: './yes-no-rule.component.html'
})
export class YesNoRuleComponent {
    private _condition: YesNoCondition;
    conditionPair: { name: string; value: string }[];

    @Input()
    public get condition(): YesNoCondition {
        return this._condition;
    }
    public set condition(v: YesNoCondition) {
        this._condition = v;
        this.conditionPair = this.condition.getConditionPair();
    }

    @Input()
    field: Field;

    constructor() {}
}
