import { Injectable } from '@angular/core';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { GspLoggerService } from 'src/app/log-handler.service';
import { FeatureService } from 'src/app/shared/map-data-services/feature/feature.service';

import { ProjectStreamService } from '../current-project/project-stream.service';
import { FeatureSet } from './feature-set';

@Injectable({
    providedIn: 'root'
})
export class FeatureSetFactoryService {
    constructor(
        private messaging: MessagingService,
        private projectStream: ProjectStreamService,
        private featureService: FeatureService,
        private translate: TranslationService,
        private logger: GspLoggerService
    ) {}

    createFeatureSet(name: string, hideNotifications: boolean = false): FeatureSet {
        return new FeatureSet(
            this.messaging,
            this.projectStream,
            this.featureService,
            name,
            hideNotifications,
            this.translate,
            this.logger
        );
    }
}
