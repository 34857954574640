import * as _ from 'lodash-es';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';

import { Condition, ConditionDisplayInfo, ConditionOperator } from './condition';
import { DateSubCondition } from './date-subcondition';
import { RuleErrors } from './rule-errors';

export class DateCondition extends Condition {
    // TODO: ng1 didnt extend condition...why?

    private dateConditionsText: ConditionDisplayInfo[] = [
        {
            name: 'TC.Common.IsAnswered',
            value: ConditionOperator.IS_ANSWERED,
            iconClass: 'null',
            sideBarSign: 'Is answered'
        }
    ];

    subConditions: DateSubCondition[];
    multipleDateConditionsText: ConditionDisplayInfo[];
    connectingOperators: { name: string; value: string }[] = [];

    constructor(uuid: string, type: string, dateRuleSubCondition?: DateSubCondition[]) {
        super(uuid, type);

        // Present all options for existing rules
        if (dateRuleSubCondition) {
            if (dateRuleSubCondition[0].operator !== ConditionOperator.IS_ANSWERED) {
                this.dateConditionsText = [
                    {
                        name: 'TC.Common.IsAnswered',
                        value: ConditionOperator.IS_ANSWERED,
                        iconClass: 'null',
                        sideBarSign: 'Is answered'
                    },
                    {
                        name: 'TC.Common.IsEqualTo',
                        value: ConditionOperator.IS_EQUAL_TO,
                        iconClass: 'null',
                        sideBarSign: 'Is equal to'
                    },
                    {
                        name: 'TC.Common.IsNotEqual',
                        value: ConditionOperator.IS_NOT_EQUAL_TO,
                        iconClass: 'null',
                        sideBarSign: 'Is not equal to'
                    },
                    {
                        name: 'TC.Common.IsEarlierThan',
                        value: ConditionOperator.IS_EARLIER_THAN,
                        iconClass: 'null',
                        sideBarSign: 'Is earlier than'
                    },
                    {
                        name: 'TC.Common.IsLaterThan',
                        value: ConditionOperator.IS_LATER_THAN,
                        iconClass: 'null',
                        sideBarSign: 'Is later than'
                    }
                ];
            }
        }

        this.multipleDateConditionsText = CloneUtils.cloneDeep(this.dateConditionsText);
        this.multipleDateConditionsText.splice(0, 1);

        // Present all options for existing rules
        if (this.dateConditionsText.length > 1) {
            this.connectingOperators = [
                {
                    name: '',
                    value: ConditionOperator.NONE
                },
                {
                    name: 'TC.Common.And',
                    value: ConditionOperator.AND
                },
                {
                    name: 'TC.Common.Or',
                    value: ConditionOperator.OR
                }
            ];
        } else {
            this.connectingOperators = [
                {
                    name: '',
                    value: ConditionOperator.NONE
                }
            ];
        }

        this.subConditions = dateRuleSubCondition
            ? this.getNewSubConditions(dateRuleSubCondition)
            : [new DateSubCondition(ConditionOperator.NONE, ConditionOperator.IS_ANSWERED)];
    }

    // Get the condition text which is displayed in sidebar.
    getConditionText(translator: TranslationService): string {
        let result = '';
        let conditionPairObj = this.getConditionPair();
        _.each(this.subConditions, subCondition => {
            let selectedCondition = conditionPairObj.filter(condition => condition.value === subCondition.operator);
            let operator = '';
            if (selectedCondition.length > 0) {
                operator = selectedCondition[0].sideBarSign;
            }
            result += operator + ' ';
            if (subCondition.operator !== ConditionOperator.IS_ANSWERED) {
                let exp = subCondition.daysAgoComparisonValue;
                if (subCondition.daysAgoComparisonValue) {
                    exp = subCondition.daysAgoComparisonValue + ' days ago ';
                }
                result += exp;
            }
            if (subCondition.dummyConnectingOperator !== ConditionOperator.NONE) {
                result += subCondition.dummyConnectingOperator + ' ';
            }
        });
        return result;
    }

    getErrorMessagesNo(): number[] {
        let sourceLevelErrorMessagesNo: number[] = [];
        if (this.errors.labelErrors && this.errors.labelErrors.length > 0) {
            sourceLevelErrorMessagesNo.push(3);
        }
        return sourceLevelErrorMessagesNo;
    }

    // Returns the list of operators used for this condition.
    getConditionPair(): ConditionDisplayInfo[] {
        if (this.subConditions.length > 1) {
            return this.multipleDateConditionsText;
        }
        return this.dateConditionsText;
    }

    // Returns the connecting operator used
    getConnectingOperators(): {
        name: string;
        value: string;
    }[] {
        return this.connectingOperators;
    }

    addSubCondition(
        connectingOperator = ConditionOperator.NONE,
        operator?: ConditionOperator,
        comparisonValue: string = null,
        dummyConnectingOperator = ConditionOperator.NONE,
        daysAgoComparisonValue: string = null
    ): void {
        this.subConditions.push(
            new DateSubCondition(
                connectingOperator,
                operator,
                comparisonValue,
                dummyConnectingOperator,
                daysAgoComparisonValue
            )
        );
    }

    addOrRemoveSubCondition(connectingOperator: ConditionOperator, index: number): void {
        if (connectingOperator !== ConditionOperator.NONE) {
            if (index === this.subConditions.length - 1) {
                this.addSubCondition(connectingOperator);
            } else if (this.subConditions[index + 1]) {
                this.subConditions[index + 1].connectingOperator = connectingOperator;
            }
        } else {
            this.removeSubCondition(index + 1, this.subConditions.length - index);
        }
        this.conditionValidation(this);
    }

    conditionValidation(condition: DateCondition): void {
        condition.errors.labelErrors = [];
        condition.subConditions.forEach(subCondition => {
            if (subCondition.operator === ConditionOperator.IS_ANSWERED) {
                condition.errors.labelErrors = [];
            }
            // added string typecast to suppress condition always return true/false ts lint error
            if (
                subCondition.operator === ConditionOperator.NONE ||
                (subCondition.operator !== (ConditionOperator.NONE as string) &&
                    subCondition.operator !== ConditionOperator.IS_ANSWERED &&
                    subCondition.daysAgoComparisonValue !== undefined &&
                    (subCondition.daysAgoComparisonValue === null ||
                        (subCondition.daysAgoComparisonValue.hasOwnProperty('length') &&
                            subCondition.daysAgoComparisonValue.length === 0))) ||
                (typeof subCondition.daysAgoComparisonValue !== 'undefined' &&
                    subCondition.daysAgoComparisonValue !== null &&
                    subCondition.operator === (ConditionOperator.NONE as string) &&
                    (subCondition.operator as string) !== ConditionOperator.IS_ANSWERED) ||
                (subCondition.operator !== (ConditionOperator.NONE as string) &&
                    subCondition.operator !== ConditionOperator.IS_ANSWERED &&
                    subCondition.daysAgoComparisonValue === undefined)
            ) {
                if (condition.errors.labelErrors.indexOf(RuleErrors.errorMessages[3]) === -1) {
                    condition.errors.labelErrors.push(RuleErrors.errorMessages[3]);
                }
            }
        });
    }

    // Return the formatted object with methods from JSON.
    getNewSubConditions(subConditions: DateSubCondition[]): DateSubCondition[] {
        let subConditionResult: DateSubCondition[] = [];
        subConditions.forEach((subCondition, index) => {
            if (index === subConditions.length - 1) {
                subConditionResult.push(
                    new DateSubCondition(
                        subCondition.connectingOperator,
                        subCondition.operator,
                        subCondition.comparisonValue,
                        ConditionOperator.NONE,
                        subCondition.daysAgoComparisonValue
                    )
                );
            } else {
                subConditionResult.push(
                    new DateSubCondition(
                        subCondition.connectingOperator,
                        subCondition.operator,
                        subCondition.comparisonValue,
                        subConditions[index + 1].connectingOperator,
                        subCondition.daysAgoComparisonValue
                    )
                );
            }
        });
        return subConditionResult;
    }

    removeSubCondition(index: number, length: number): void {
        length = length ? length : 1;
        this.subConditions.splice(index, length);
    }
}
