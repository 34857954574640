import { Component, Input } from '@angular/core';

import { GeometryTypes } from '../../utility/geometry-utils';

@Component({
    templateUrl: './gsp-geom-icon.component.html',
    selector: 'geom-icon'
})
export class GeometryIconComponent {
    private _geoType: string;
    private _color: string;
    private _path: string;

    @Input()
    public set geoType(type: string) {
        if (type && type === GeometryTypes.AREA) {
            this._geoType = 'poly';
        } else {
            this._geoType = type;
        }
    }

    public get geoType(): string {
        return this._geoType || 'Point';
    }

    @Input()
    public set color(color: string) {
        this._color = color;
    }

    public get color(): string {
        return this._color || '#000000';
    }

    @Input()
    public set path(path: string) {
        this._path = path;
    }

    public get path(): string {
        return this._path ? this._path : 'sym_32-geom-';
    }

    public get svg(): string {
        return this.path + this.geoType.toLowerCase();
    }
}
