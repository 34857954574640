import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ActionToastComponent } from './action-toast.component';

@NgModule({
    declarations: [ActionToastComponent],
    imports: [CommonModule],
    providers: [],
    exports: [ActionToastComponent]
})
export class MessagingModule {}
