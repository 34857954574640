<div class="map-layout-container">
    <div id="wizard-template-editor">
        <header>
            <section class="title">
                <h2 translate="TC.Common.CreateTemplate"></h2>
                <div class="layer-detail">
                    <geom-icon
                        path="sym_32-layer-"
                        [color]="template?.geometryColorHexRGB"
                        [geoType]="template?.geometryType"
                    >
                    </geom-icon>
                    <h4>{{ template?.name }}</h4>
                </div>
            </section>
            <section class="button-set">
                <gsp-button
                    class="cancel-button"
                    [type]="ButtonType.Default"
                    (action)="cancelChanges()"
                    [disabled]="disableCreate"
                    text="{{ 'cancel' | translate }}"
                    id="cancel-new-template"
                ></gsp-button>
                <gsp-button
                    class="next-button"
                    [type]="ButtonType.Primary"
                    (action)="moveToTemplateEditor()"
                    [disabled]="disableCreate"
                    text="{{ 'create' | translate }}"
                    id="create-new-template"
                ></gsp-button>
            </section>
        </header>
        <section class="field-values" #fieldValues>
            <h4 *ngIf="template && !template.fields.length" translate="TC.Common.NoFields"></h4>
            <div class="instructions">
                <div class="instruction-title">{{ 'MapViewer_TemplateWizard_Title' | translate }}</div>
                <div>{{ 'MapViewer_TemplateWizard_Description' | translate }}</div>
                <div>{{ 'MapViewer_TemplateWizard_Field_Name' | translate }}</div>
                <div>{{ 'MapViewer_TemplateWizard_Field_Type' | translate }}</div>
            </div>
            <loading *ngIf="loading" class="loading-align-center width-full"></loading>
            <div class="fields-label" *ngIf="!loading">
                <div>{{ 'MapViewer_Generic_FieldName' | translate }}</div>
                <div>{{ 'MapViewer_Generic_FieldType' | translate }}</div>
                <div class="sample">{{ 'MapViewer_Generic_SampleData' | translate }}</div>
            </div>
            <custom-scroll *ngIf="!loading" scrollDirection="horizontal">
                <div class="fields" *ngIf="template">
                    <div *ngFor="let field of template.fields">
                        <individual-field
                            [field]="field"
                            *ngIf="field.type !== LayoutFieldType.PageHeader && field.type !== LayoutFieldType.Group"
                            [focusElement]="focus[field.tabOrder]"
                            [lastField]="field.tabOrder < totalFields ? false : true"
                            [geometryType]="template.geometryType"
                            (setFieldFocus)="setFocus($event)"
                            (setChoiceFields)="populateChoiceValues($event.toFocus, $event.selected)"
                            (replaceEntireField)="replaceEntireField($event.toFocus, $event.selected)"
                            [fieldValues]="fieldValues"
                        >
                        </individual-field>
                        <div class="group-container" *ngIf="field.type === LayoutFieldType.Group">
                        <individual-field
                            [field]="subField"
                            *ngFor="let subField of field.fields"
                            [focusElement]="focus[subField.tabOrder]"
                            [geometryType]="template.geometryType"
                            (setFieldFocus)="setFocus($event)"
                            (setChoiceFields)="populateChoiceValues($event.toFocus, $event.selected)"
                            (replaceEntireField)="replaceEntireField($event.toFocus, $event.selected)"
                            [fieldValues]="fieldValues"
                        >
                        </individual-field>
                        </div>
                    </div>
                </div>
            </custom-scroll>      
        </section>
    </div>
</div>
<div class="modal-popup" *ngIf="showTemplateWizardCancelConfirmation">
    <gsp-confirmation-popup
        [heading]="'TC.MetadataEditor.DiscardChangesWithQuestion'"
        [message]="'MapViewer.TemplateWizard.DiscardConfirmation.Description'"
        [confirmAction]="moveToWorkspaceAction"
        (cancel)="cancelWorkspaceNavigation()"
        [confirmationButtonText]="'TC.Common.Discard'"
        id="cancel-template-wizard-popup"
    ></gsp-confirmation-popup>
</div>
