import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash-es';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { LayersStore } from 'src/app/shared/common/current-layers/layers-store.service';
import { StylesStore } from 'src/app/shared/common/current-layers/styles-store.service';
import { TemplatesStoreService } from 'src/app/shared/common/current-templates/templates-store.service';
import { TCFileService } from 'src/app/shared/common/files/TCFile.service';
import { LoaderStreamService } from 'src/app/shared/common/loader/loader-stream.service';
import { Application } from 'src/app/shared/map-data-services/application';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';
import { LayerService } from 'src/app/shared/map-data-services/layer/layer.service';
import { TemplateTypeId } from 'src/app/shared/map-data-services/layer/template-lite';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { Style } from 'src/app/shared/map-data-services/styles/style';
import { CurrentEditTemplateStream } from 'src/app/shared/template-services/current-edit-template-stream.service';
import { FieldsStoreService } from 'src/app/shared/template-services/field/fields-store.service';
import { Template } from 'src/app/shared/template-services/template';

import { TEMPLATE_EDITOR_CONFIG } from './template-editor-config';
import { TemplateRouteData } from './template-editor-route-resolver.service';
import { TemplateStoreForComponent } from './template/template-store-for-component.service';

export interface LinkDetails {
    id: string;
    name: string;
    color: string;
    label: string[];
    items: Partial<LinkDetails>[];
    allLinkedWorkspaces?: Partial<LinkDetails>[];
}

@Component({
    selector: 'template-editor-page',
    templateUrl: './template-editor-page.component.html'
})
export class TemplateEditorPageComponent implements OnInit {
    application: Application = {
        name: 'Connect',
        category: 'mapViewer'
    };
    currentProject: TemplateRouteData;
    editorReady: boolean;
    failureMessage = 'TC.Common.UnableToPublishTemplate';
    linkDetails: any[] = [];
    linkedWorkspaces: MapWorkspace[] = [];
    successMessage = 'TC.Common.TemplatePublished';
    templateDetails: Template = {} as Template;
    constructor(
        private fieldsStore: FieldsStoreService,
        private router: Router,
        private route: ActivatedRoute,
        private loaderStreamService: LoaderStreamService,
        private layerService: LayerService,
        private templatesStore: TemplatesStoreService,
        private layersStore: LayersStore,
        private messaging: MessagingService,
        @Inject(TEMPLATE_EDITOR_CONFIG)
        public templateEditorConfig: {
            tabs: { fields: { fieldsGroup: any[]; enableFieldConditions: { [key: string]: any } } };
        },
        private templatesStoreForComponent: TemplateStoreForComponent,
        private tcFileService: TCFileService,
        private translate: TranslationService,
        private stylesStore: StylesStore,
        private currentEditTemplateStream: CurrentEditTemplateStream
    ) {}

    createLayerFromTemplate(
        projectId: string,
        mapWorkspaceId: string,
        template: Template,
        style: Style
    ): Promise<Layer> {
        return this.layersStore.createLayer(
            projectId,
            {
                layerName: template.name,
                templateSeriesId: template.seriesId,
                templateId: template.id,
                styleId: style.id,
                layerType: 'TemplateLayer'
            },
            mapWorkspaceId
        );
    }

    private goBackToMap(): void {
        this.router.navigate(['mapViewer'], {
            queryParams: {
                templateId: null,
                layerId: null
            },
            queryParamsHandling: 'merge'
        });
    }

    ngOnInit(): void {
        this.currentProject = this.route.snapshot.data.currentProjectWorkspace; // comes from resolver

        // load sensors when opening
        this.fieldsStore.loadSensorsAndExcludeFieldPropsDict();

        if (
            this.currentProject.templateId === TemplateTypeId.DIRECT_TEMPLATE &&
            !this.currentEditTemplateStream.getValue()
        ) {
            this.layerService
                .getTemplateByLayerId(this.currentProject.projectId, this.currentProject.layerId)
                .then(t => this.templatesStore.setCurrentEditedTemplate(t));
        } else if (
            this.currentProject.templateId !== TemplateTypeId.NEW_FORM &&
            this.currentProject.templateId !== TemplateTypeId.DIRECT_TEMPLATE
        ) {
            this.templatesStore
                .determineLayersbyTemplateId(this.currentProject.projectId, this.currentProject.templateId)
                .then((templateWithUpdatedDetails: Template) => {
                    this.templateDetails = templateWithUpdatedDetails;
                    this.updateLinkedWorkspaces();
                });
        } else {
            this.editorReady = true;
        }
    }

    async saveForm(
        action: string,
        template: Template,
        publishedStatus: boolean,
        templateBeforeSave: Template
    ): Promise<void> {
        try {
            this.successMessage = publishedStatus ? 'TC.Common.TemplatePublished' : 'TC.Common.TemplateSavedSuccess';
            this.failureMessage = publishedStatus
                ? 'TC.Common.UnableToPublishTemplate'
                : 'TC.Common.UnableToSaveTemplate';
            template.linkedWorkspaces = this.linkedWorkspaces;
            this.templatesStore.addOrUpdateTemplate(template);
            (template['currentPublishStatus' as keyof Template] as boolean) = publishedStatus;
            if (action === 'create') {
                const style = await this.templatesStore.createOrUpdateStyle(template);
                if (this.currentProject.layerId) {
                    let layer = this.layersStore.getMapWorkspaceLayerById(this.currentProject.layerId);
                    await this.updateLayerFromTemplate(this.currentProject.projectId, layer, template, style);
                } else {
                    await this.createLayerFromTemplate(
                        this.currentProject.projectId,
                        this.currentProject.workspaceId,
                        template,
                        style
                    );
                }
            } else {
                // Remove the cached layers by mapworkspace Id in case of template publication of layers with linked template
                if (this.linkDetails.length) {
                    this.linkDetails.forEach(link => {
                        link.items.forEach((workspace: { id: string }) => {
                            this.layersStore.removeLayersIndexByMapWorkspace(workspace.id);
                        });
                    });
                }
                if (publishedStatus || (!publishedStatus && !templateBeforeSave.lastPublishedVersion)) {
                    const style = await this.templatesStore.createOrUpdateStyle(template);
                    await Promise.all(
                        this.templateDetails.layersWithWorkspaces.map(layer =>
                            this.updateLayerFromTemplate(this.currentProject.projectId, layer, template, style)
                        )
                    );
                } else {
                    await Promise.all(
                        this.templateDetails.layersWithWorkspaces.map(layer =>
                            this.updateLayerFromDraftTemplate(this.currentProject.projectId, layer, template)
                        )
                    );
                }
            }
            this.templatesStoreForComponent.templateEditorSaveSuccessStream.next(true);
            this.goBackToMap();
        } catch (e) {
            this.templatesStoreForComponent.templateEditorSaveSuccessStream.next(false);
        }
    }

    showError(customMessage: string): void {
        this.messaging.showError(this.translate.instant(customMessage || this.failureMessage));
    }

    showSuccess(): void {
        this.messaging.showSuccess(this.translate.instant(this.successMessage));
    }

    stopLoader(): void {
        this.loaderStreamService.isLoading$.next(false);
    }

    updateLayerFromDraftTemplate(projectId: string, layer: Layer, template: Template): Promise<Layer> {
        if (!template.lastPublishedVersion) {
            layer.layerName = template.name;
        }
        const layerDto = this.layersStore.constructLayerFromTemplate(template, layer);
        return this.layersStore.updateLayer(projectId, layer.id, layerDto);
    }

    updateLayerFromTemplate(projectId: string, layer: Layer, template: Template, style: Style): Promise<Layer> {
        // only update layer name and style until first publish of template (template version will become >1 after first publish)
        if (!template.lastPublishedVersion || template.lastPublishedVersion === 1) {
            layer.styleId = style.id;
            layer.layerName = template.name;
        }
        const layerDto = this.layersStore.constructLayerFromTemplate(template, layer);
        return this.layersStore.updateLayer(projectId, layer.id, layerDto);
    }

    private updateLinkedWorkspaces(): void {
        this.linkedWorkspaces = [];
        const tmpLinkedDetails: LinkDetails[] = [];
        let processedLinkedLayersCount = this.templateDetails.layersWithWorkspaces.length;
        this.templateDetails.layersWithWorkspaces.forEach(async layerWithWorkspace => {
            const layerStyle = await this.stylesStore.getStyle(
                this.currentProject.projectId,
                layerWithWorkspace.styleId
            );
            const color = layerStyle.name.substr(layerStyle.name.indexOf('#'));
            this.tcFileService.getWorkspaceFiles(layerWithWorkspace.workspaces).then(files => {
                layerWithWorkspace.workspaces = layerWithWorkspace.workspaces.filter(
                    (workspace, index) => !!files[index]
                );
                this.linkedWorkspaces = this.linkedWorkspaces.concat(layerWithWorkspace.workspaces);
                this.linkedWorkspaces = _.uniqBy(this.linkedWorkspaces, 'id');
                this.templatesStoreForComponent.setLinkedWorkspaces(this.linkedWorkspaces);
                tmpLinkedDetails.push({
                    id: layerWithWorkspace.id,
                    name: layerWithWorkspace.layerName,
                    color,
                    label: ['TC.Common.Layer', 'TC.Common.Lower_Layers'],
                    items: layerWithWorkspace.workspaces.map(workspace => ({
                        id: workspace.id,
                        name: workspace.name,
                        label: ['TCS.Workspace', 'TCS.Workspaces']
                    }))
                });
                processedLinkedLayersCount = processedLinkedLayersCount - 1;
                if (processedLinkedLayersCount === 0) {
                    let allWorkspaces: Partial<LinkDetails>[] = [];
                    tmpLinkedDetails.forEach(layer => {
                        allWorkspaces = allWorkspaces.concat(layer.items);
                    });
                    tmpLinkedDetails[0].allLinkedWorkspaces = _.uniqBy(allWorkspaces, 'id');
                    this.linkDetails = tmpLinkedDetails;
                    this.editorReady = true;
                }
            });
        });
    }
}
