import { Component, Input } from '@angular/core';
import { Template } from 'src/app/shared/template-services/template';

@Component({
    template: ''
})
export class FieldBaseComponent {
    @Input()
    field: any;

    @Input()
    template: Template;
}
