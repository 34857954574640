import { HttpClient } from '@angular/common/http';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { lastValueFrom, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BaseMapMode } from 'src/app/feature/map-viewer/base-maps/base-map-mode';
import { MapService } from 'src/app/feature/map-viewer/map.service';
import { KeyCodes } from 'src/app/shared/common/key-codes';

import { EnvironmentService } from '../../environment/environment.service';
import { GeneralUtils } from '../../utility/general-utils';

export enum GeoCodeResultType {
    COUNTRY = 0,
    STATE = 1,
    COUNTY = 2,
    CITY = 3,
    ZIP = 4,
    SPLC = 5,
    STREET = 6,
    ROUTENUMBER = 7,
    ROUTEALPHA = 8,
    POI = 9,
    POISTREET = 10,
    FULLPOSTCODE = 11,
    POITYPE = 12,
    CROSSSTREET = 13,
    LATLON = 14,
    CUSTOMPLACE = 15,
    NONE = 16
}

class PlaceSuggestion {
    address: {
        city: string;
        country: string;
        countryFullName: string;
        county: string;
        state: string;
        stateName: string;
        streetAddress: string;
        zip: number;
    };
    mapView: {
        lat: string;
        lon: string;
    };
    resultType: number;
    shortString: string;
}
class GeocodeResponse {
    locations: PlaceSuggestion[];
}

@Component({
    selector: 'search-place',
    templateUrl: './search-place.component.html'
})
export class SearchPlaceComponent implements OnInit {
    @Input()
    public map: L.Map;

    @Input()
    public showMarker = false;

    public searchText: string; // the place search string
    public places: PlaceSuggestion[]; // resulting matching places
    public currentIndex = 0;
    public selectedLocation: PlaceSuggestion = null;
    public searchChanged$: Subject<string> = new Subject<string>();
    public originalSearchText = '';

    public marker: L.Marker;
    public basemapMode: BaseMapMode;

    @HostListener('click', ['$event.target'])
    onclick(target: HTMLElement) {
        if (target['classList'].contains('search-box')) {
            this.places = [];
        }
    }

    constructor(
        private http: HttpClient,
        private mapService: MapService,
        private environmentService: EnvironmentService
    ) {}

    ngOnInit(): void {
        this.basemapMode = this.mapService.getBaseMapMode();

        this.searchChanged$.pipe(debounceTime(500)).subscribe((searchText: string) => {
            this.searchForPlacesByName(searchText);
        });
    }

    public async searchForPlacesByName(location: string): Promise<void> {
        this.originalSearchText = null;
        this.selectedLocation = null;
        if (location && location.length > 2) {
            const path =
                this.environmentService.apiUrl + '/trimbledata/services/geocode?query=' + location + '&maxResults=6';
            const res = await lastValueFrom(this.http.get<GeocodeResponse>(path));
            this.places = res.locations;
        } else {
            this.places = [];
        }
    }

    public locatePlaceInMap(place: PlaceSuggestion): void {
        this.places = [];
        this.searchText = place.shortString;
        let location = new L.LatLng(parseFloat(place.mapView.lat), parseFloat(place.mapView.lon));
        if (this.showMarker) {
            this.addMarker(location);
        }
        this.map.setView(location, this.determineZoomLevel(place.resultType));
    }

    public addMarker(location: L.LatLng): void {
        if (this.marker) {
            this.marker.setLatLng(location);
        } else {
            this.marker = L.marker(location).addTo(this.map);
        }
    }

    public handleKeyEvents(event: KeyboardEvent): void {
        const key = event.key;
        if (key === KeyCodes.ENTER) {
            // ENTER key
            if (GeneralUtils.isNullUndefinedOrNaN(this.selectedLocation)) {
                if (this.places.length) {
                    this.locatePlaceInMap(this.places[0]);
                }
            } else {
                this.locatePlaceInMap(this.selectedLocation);
            }
        } else if (key === KeyCodes.UP_ARROW && this.places.length !== 0) {
            // up arrow key
            this.currentIndex -= 1;
            if (!this.originalSearchText) {
                this.originalSearchText = this.searchText;
            }
            if (this.currentIndex === 0) {
                // restore search text when back at top of list
                this.searchText = this.originalSearchText;
                this.selectedLocation = null;
            } else {
                if (this.currentIndex < 0) {
                    // loop to end of list
                    this.currentIndex = this.places.length;
                }
                // set search text to place label (strip out any match highlight)
                this.selectedLocation = this.places[this.currentIndex - 1];
                this.searchText = this.selectedLocation.shortString.replace(/<span>/g, '').replace(/<\/span>/g, '');
            }
        } else if (key === KeyCodes.DOWN_ARROW && this.places.length !== 0) {
            // down arrow key
            this.currentIndex += 1;
            if (!this.originalSearchText) {
                this.originalSearchText = this.searchText;
            }
            if (this.currentIndex > this.places.length) {
                this.currentIndex = 0; // loop back to start of list
            }
            if (this.currentIndex === 0) {
                // restore search text when back at top of list
                this.searchText = this.originalSearchText;
                this.selectedLocation = null;
            } else {
                // set search text to place label (strip out any match highlight)
                this.selectedLocation = this.places[this.currentIndex - 1];
                this.searchText = this.selectedLocation.shortString.replace(/<span>/g, '').replace(/<\/span>/g, '');
            }
        }
    }

    private determineZoomLevel(resultType: GeoCodeResultType): number {
        let zoomLevel: number;

        switch (resultType) {
            case GeoCodeResultType.COUNTRY:
                zoomLevel = 5;
                break;
            case GeoCodeResultType.STATE:
                zoomLevel = 8;
                break;
            case GeoCodeResultType.COUNTY:
            case GeoCodeResultType.CITY:
            case GeoCodeResultType.ZIP:
                zoomLevel = 13;
                break;
            default:
                zoomLevel = this.basemapMode.maxZoom;
                break;
        }
        return zoomLevel;
    }
}
