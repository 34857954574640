import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { Field } from 'src/app/shared/template-services/field';
import { Template } from 'src/app/shared/template-services/template';

@Component({
    selector: 'field-buttons',
    templateUrl: './field-buttons.component.html'
})
export class FieldButtonsComponent {
    @Input()
    field: Field;

    @Input()
    isSelected: boolean;

    @Input()
    template: Template;

    @Output()
    toggleField = new EventEmitter<void>();

    @Output()
    duplicateField = new EventEmitter<void>();

    // expose ButtonType enum to template
    public ButtonType = ButtonType;

    trashField(field: Field): void {
        this.template.removeField(field);
    }
}
