<gsp-popup
    [heading]="'MapViewer.Workspace.CropImage.Header' | translate"
    [size]="ModalSize.FluidLarge"
    id="image-cropper-popup"
    [showDone]="true"
    (done)="selectCroppedImage()"
    [doneBtnTitle]="'MapViewer.Workspace.CropImage.Button' | translate"
    [disableDone]="!noErrorsFound"
    [showClose]="true"
    (closed)="closeCropper()"
    [showCancel]="true"
    (cancel)="closeCropper()"
>
    <ng-container transclude-popup-content>
        <custom-scroll>
            <input
                #fileInput
                [hidden]="true"
                id="image-cropper-input"
                type="file"
                accept="image/jpeg, image/jpg, image/png, image/bmp, image/gif"
                (change)="fileChangeEvent($event)"
            />

            <p *ngIf="noErrorsFound">{{ 'MapViewer.Workspace.CropImage.Message' | translate }}</p>

            <gsp-notification [type]="NotificationType.Error" *ngIf="!noErrorsFound && !invalidImageSize">
                <div [innerHTML]="'INVALID_EXTENSION_IMAGE' | translate"></div>
            </gsp-notification>
            <gsp-notification [type]="NotificationType.Error" *ngIf="!noErrorsFound && invalidImageSize">
                <div translate [translateParams]="" [innerHTML]="translatedInvalidImageSizeMessage()"></div>
            </gsp-notification>

            <div class="browse-again-wrapper" *ngIf="!noErrorsFound">
                <button
                    type="button"
                    id="reselectFileButton"
                    class="button-main button button-tooltip button-primary button-icon-text"
                    (click)="clickFileInput()"
                    [hidden]="noErrorsFound"
                >
                    {{ 'MapViewer.Workspace.CropImage.SelectImageFile' | translate }}
                </button>
            </div>

            <div class="crop-wrapper" [hidden]="!noErrorsFound">
                <image-cropper
                    [imageChangedEvent]="imageChangedEvent"
                    [maintainAspectRatio]="true"
                    [containWithinAspectRatio]="false"
                    [imageQuality]="100"
                    [aspectRatio]="aspectRatio"
                    [resizeToWidth]="defaultWidth"
                    [resizeToHeight]="defaultHeight"
                    [alignImage]="'center'"
                    format="jpeg"
                    (imageCropped)="imageCropped($event)"
                ></image-cropper>
            </div>
        </custom-scroll>
    </ng-container>
</gsp-popup>
