<div class="map-layout-container">
    <template-editor
        class="height-full"
        [application]="application"
        [templateEditorConfig]="templateEditorConfig"
        [currentProjectDetails]="currentProject"
        [templateId]="currentProject.templateId"
        (afterLoaded)="stopLoader()"
        [linkDetails]="linkDetails"
        [enableButtons]="editorReady"
        (saveForm)="saveForm($event.action, $event.template, $event.publishedStatus, $event.templateBeforeSave)"
        (showSuccess)="showSuccess()"
        (showError)="showError($event)"
    ></template-editor>

    <div class="centre-dialog">
        <router-outlet #outlet="outlet"></router-outlet>
    </div>
</div>
