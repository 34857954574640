import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { authGuard } from 'src/app/core/routing/route-guards/auth-guard.service';

import { ExportDownloaderComponent } from './export-downloader.component';

export const exportDownloader = (
    url: UrlSegment[]
): { consumed: UrlSegment[]; posParams: { projectId: UrlSegment; exportId: UrlSegment } } => {
    if (
        url.length &&
        url.length === 6 &&
        url[0].path === 'mapViewer' &&
        url[3].path === 'export' &&
        url[5].path === 'download'
    ) {
        return {
            consumed: url,
            posParams: {
                projectId: url[2],
                exportId: url[4]
            }
        };
    }
    return null;
};

const routes: Routes = [
    {
        // Catch the old export download - mapViewer/project/:projectId/export/:exportId/download
        matcher: exportDownloader,
        component: ExportDownloaderComponent,
        canActivate: [authGuard]
    },
    {
        path: '',
        canActivate: [authGuard],
        children: [
            {
                path: 'download',
                component: ExportDownloaderComponent
            }
        ]
    }
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class ExportDownloaderRoutingModule {}
