<gsp-wizard
    [progressionSteps]="steps"
    [currentStep]="currentStep$ | async"
    class="import-files-mapviewer"
    [size]="ModalSize.Small"
    id="import-file-mapviewer-wizard"
>
    <gsp-wizard-header
        [heading]="'TCS.Import.ImportFile'"
        (closed)="exit()"
        transclude-wizard-header
    ></gsp-wizard-header>
    <gsp-wizard-footer (goBack)="backward()" (goForward)="forward()" transclude-wizard-footer></gsp-wizard-footer>
</gsp-wizard>
