import { Component, Input, OnInit } from '@angular/core';

import { CodedChoiceFeatureField } from '../feature-field';

@Component({
    templateUrl: './coded-choice-field.component.html',
    selector: 'coded-choice-field'
})
export class CodedChoiceFieldComponent implements OnInit {
    @Input()
    field: CodedChoiceFeatureField;

    @Input()
    editMode: boolean;

    public radioOptions: { [description: string]: { selected: boolean } } = {};

    constructor() {}

    ngOnInit(): void {
        this.field.options.forEach(option => {
            this.radioOptions[option.description] = { selected: false };
        });
        if (this.field.value) {
            const matchingDescription = this.field.options.find(
                option => option.code === this.field.value
            )?.description;

            if (matchingDescription) {
                this.radioOptions[matchingDescription].selected = true;
            }
        }
    }

    public toggleOption(description: string): void {
        Object.keys(this.radioOptions).forEach(radioOption => {
            if (radioOption !== description) {
                this.radioOptions[radioOption].selected = false;
            } else {
                this.field.value = this.field.options.find(option => option.description === description).code;
            }
        });
    }
}
