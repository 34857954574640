<section
    class="add-menu"
    *checkVisibility="{ visibleModes: [MapWorkspacePermissionType.FULL_ACCESS] }"
    (click)="addContextMenu.onContextMenu($event)"
>
    <ul class="list-content">
        <li class="layer-add-icon">
            <span class="btn-circle"><i class="icon_line_white_plus i32"></i></span>
        </li>
        <div *ngIf="showAddDataTip" class="tooltip-content show bottom data-tip">
            {{ 'TCS.Mapviewer.AddMenu.AddDataTip' | translate }}
        </div>
    </ul>

    <gsp-context-menu #addContextMenu [items]="contextMenuItems"></gsp-context-menu>
</section>
