import { MapWorkspacePermissionType } from './map-workspace-permission';

export interface PermissionDTO {
    defaultPermission: MapWorkspacePermissionType;
    permissions: {
        id: string;
        type: PermissionType;
        name: string;
        permission: MapWorkspacePermissionType;
    }[];
}

export enum PermissionType {
    USER = 'USER',
    GROUP = 'GROUP'
}

export const permissionPriority = {
    [MapWorkspacePermissionType.FULL_ACCESS]: 0,
    [MapWorkspacePermissionType.READ]: 1,
    [MapWorkspacePermissionType.NO_ACCESS]: 2,
    [MapWorkspacePermissionType.NO_FILE_ACCESS]: 3,
    [MapWorkspacePermissionType.FILE_VIEW_MODE]: 4
};

export class Permission {
    // DTO properties
    defaultPermission: MapWorkspacePermissionType = null;
    permissions: { id: string; type: PermissionType; name: string; permission: MapWorkspacePermissionType }[] = [];

    // Added properties
    effectivePermission: MapWorkspacePermissionType; // effective permission for current user
}
