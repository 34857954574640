import { AfterViewInit, Directive, ElementRef, HostBinding, HostListener, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
    selector: '[ng-auto-expand]'
})
export class AutoExpandDirective implements AfterViewInit {
    @HostBinding('style.overflow')
    public overflow = 'hidden';

    constructor(private elementRef: ElementRef, private renderer: Renderer2, private router: Router) {}

    // resizing the texarea based on the lines of content in it on opening the editing text area
    @HostListener('input')
    resize(): void {
        const additionalMargins = this.router.url.includes('workspace/new') ? 90 : 20;

        // Reset textarea height to auto that correctly calculate the new height
        this.renderer.setStyle(this.elementRef.nativeElement, 'height', 'auto');
        this.renderer.setStyle(
            this.elementRef.nativeElement,
            'height',
            this.elementRef.nativeElement.scrollHeight + additionalMargins + 'px'
        );
    }

    ngAfterViewInit(): void {
        this.resize();
    }
}
