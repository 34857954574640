import { Component, Input } from '@angular/core';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { Field } from 'src/app/shared/template-services/field';
import { ConditionOperator } from 'src/app/shared/template-services/rule/condition';
import { OtherCondition } from 'src/app/shared/template-services/rule/other-condition';

@Component({
    selector: 'default-rule',
    templateUrl: './default-rule.component.html'
})
export class DefaultRuleComponent {
    private _condition: OtherCondition;
    conditionText: string;

    @Input()
    public get condition(): OtherCondition {
        return this._condition;
    }
    public set condition(v: OtherCondition) {
        this._condition = v;
        this.condition.setOperator(ConditionOperator.IS_ANSWERED);
        this.conditionText = this.condition.getConditionText(this.translate);
    }

    @Input()
    field: Field;

    constructor(private translate: TranslationService) {}
}
