import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalSize } from 'src/app/shared/common/modal-sizes';

import { ButtonType } from '../buttons/button';

/**
 * GSP-MODAL
 * Use this component if you just want a modal that you plug anything into.
 * Note: This component is very simple - doesn't give any controls that gsp-popup does.
 */

@Component({
    selector: 'gsp-modal',
    templateUrl: './gsp-modal.component.html'
})
export class GspModalComponent {
    @Input()
    size: ModalSize = ModalSize.ExtraSmall;

    @Output()
    closed: EventEmitter<boolean> = new EventEmitter();

    @Input()
    heading = '';

    // expose enum to template
    public ModalSize = ModalSize;
    public ButtonType = ButtonType;

    public callExit(): void {
        this.closed.emit(true);
    }
}
