<div class="file-status fileviewer" id="import-files-status">
    <ng-container *ngIf="numProcessedFiles !== importFiles.length">
        <loading></loading>
    </ng-container>
    <ng-container *ngIf="numProcessedFiles === importFiles.length">
        <ng-container *ngIf="importedFilesThatSucceeded.length === importFiles.length">
            <gsp-notification [type]="NoticationType.Success" id="file-processed-success">
                <p class="center-text">{{ 'MapViewer.ImportStatusDialog.FileProcessedSuccessMessage' | translate }}</p>
            </gsp-notification>
        </ng-container>

        <ng-container *ngIf="importedFilesThatSucceeded.length !== importFiles.length">
            <gsp-notification
                [type]="NoticationType.Success"
                *ngIf="importedFilesThatSucceeded.length"
                id="file-processed-partial-success"
            >
                <p
                    class="center-text"
                    translate
                    [translateParams]="{ filesProcessed: importedFilesThatSucceeded.length }"
                >MapViewer.ImportStatusDialog.FilesProcessedWithCount</p>
            </gsp-notification>

            <gsp-notification
                [type]="NoticationType.Warning"
                class="mt-2"
                *ngIf="importedFilesWithWarnings.length"
                id="file-processed-partial-warning"
            >
                <p class="center-text font-title">
                    {{ 'MapViewer.ImportStatusDialog.FileProcessedWarning' | translate }}
                </p>
                <div class="import-errors-summary">
                    <ng-container *ngFor="let file of importedFilesWithWarnings">
                        <div class="file-summary">
                            <div class="error-messages">
                                <div *ngFor="let error of file.errorMessages">{{ error }}</div>
                            </div>
                            <gsp-button
                                (action)="copy(file.requestId)"
                                [type]="ButtonType.Icon"
                                iconClass="i24 icon_content_copy"
                                [buttonTooltip]="'MapViewer.Imports.CopyRequestId' | translate"
                            ></gsp-button>
                        </div>
                    </ng-container>
                </div>
            </gsp-notification>

            <gsp-notification
                [type]="NoticationType.Warning"
                class="mt-2"
                *ngIf="importedFilesThatAreIncomplete.length"
                id="file-processed-incomplete"
            >
                <p class="center-text">{{ 'MapViewer.ImportStatusDialog.FilesBeingProcessed' | translate }}</p>
            </gsp-notification>

            <gsp-notification
                [type]="NoticationType.Critical"
                class="mt-2"
                *ngIf="importedFilesWithErrors.length"
                id="file-processed-error"
            >
                <p class="center-text font-title">
                    {{ 'MapViewer.ImportStatusDialog.FileProcessingError' | translate }}
                </p>
                <div class="import-errors-summary">
                    <ng-container *ngFor="let file of importedFilesWithErrors">
                        <div class="file-summary">
                            <div class="error-messages">
                                <div *ngFor="let error of file.errorMessages">{{ error }}</div>
                            </div>
                            <gsp-button
                                (action)="copy(file.requestId)"
                                [type]="ButtonType.Icon"
                                iconClass="i24 icon_content_copy"
                                [buttonTooltip]="'MapViewer.Imports.CopyRequestId' | translate"
                                id="file-error-copy"
                            ></gsp-button>
                        </div>
                    </ng-container>
                </div>
            </gsp-notification>
        </ng-container>

        <ng-container *ngIf="importedFilesWithErrors.length === importFiles.length">
            <p class="exit-message">{{ 'MapViewer.ImportStatusDialog.CloseWindowMessage' | translate }}</p>
        </ng-container>
    </ng-container>
</div>
