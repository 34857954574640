import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { SelectionCriteria } from 'src/app/shared/common/current-features/selection-criteria';

@Injectable({
    providedIn: 'root'
})
export class MapSelectionStreamService {
    stream = new ReplaySubject<SelectionCriteria>(1);
}
