<div *ngIf="showUserTab && user" class="user-tab-container">
    <div class="user-tab-scroll-container">
        <div class="user-tab-item">
            <div class="user-item-content">
                <div class="user-item-thumbnail">
                    <img *ngIf="user.thumbnail; else noUserThumbnail" id="user-thumbnail" [src]="user.thumbnail" />
                    <ng-template #noUserThumbnail>
                        <i class="modus-icons">person_account</i>
                    </ng-template>
                </div>
                <div class="user-item-name">{{ user.name }}</div>
                <div class="user-item-email">{{ user.email }}</div>
            </div>
        </div>
        <div class="spacer"></div>
        <div class="user-tab-panel-button">
            <div class="user-signout" (click)="signOutUser()">Sign Out</div>
        </div>
    </div>
</div>
