import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MainMenuStreamService } from 'src/app/feature/map-viewer/main-menu/main-menu-stream.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { GspWizardService } from 'src/app/shared/common/components/gsp-wizard/gsp-wizard.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { TCFile } from 'src/app/shared/common/files/TCFile';
import { LoaderStreamService } from 'src/app/shared/common/loader/loader-stream.service';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { DuplicateWorkspaceDTO, DuplicationMethod } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace.types';

import { MapMenuCode } from '../../map-viewer/map-menus/map-menu-list';
import { WorkspaceService } from '../workspace.service';

@Component({
    selector: 'workspace-template-options',
    templateUrl: './workspace-template-options.component.html'
})
export class WorkspaceTemplateOptionsComponent implements OnInit, OnDestroy {
    // expose ButtonType enum to template
    public ButtonType = ButtonType;
    public modifyWorkspace: MapWorkspace;
    public sourceWorkspace: MapWorkspace;
    public useSameCoordinateAsSource: boolean;
    public setFolder: TCFile;
    public shouldDuplicateTemplate = true;

    public uiState = {
        backButton: { text: 'MapViewer.Generic.Previous', enabled: true, visible: true },
        forwardButton: { text: 'RESOLVED', enabled: true, visible: true },
        closeButton: { text: '', enabled: true, visible: true },
        cancelButton: { text: 'cancel', enabled: true, visible: true }
    };
    private readonly destroyed = new Subject<void>();

    constructor(
        private router: Router,
        private wizardService: GspWizardService,
        private workspaceCreationService: WorkspaceService,
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private projectStream: ProjectStreamService,
        private mainMenuStream: MainMenuStreamService,
        private loaderStream: LoaderStreamService
    ) {}

    ngOnInit(): void {
        this.wizardService.setUI(this.uiState);
        this.modifyWorkspace = CloneUtils.cloneDeep(this.workspaceCreationService.modifyWorkspace$?.getValue());
        this.sourceWorkspace = CloneUtils.cloneDeep(this.workspaceCreationService.sourceWorkspace$?.getValue());
        this.useSameCoordinateAsSource = this.workspaceCreationService.useSourcePropertiesForDuplicate$?.getValue();
        this.setFolder = CloneUtils.cloneDeep(this.workspaceCreationService.setDestinationFolder$?.getValue());
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    setShouldDuplicateTemplate(shouldDuplicateTemplate: boolean): void {
        this.shouldDuplicateTemplate = shouldDuplicateTemplate;
    }

    public onForward(): Promise<void> {
        return this.duplicateWorkspace();
    }

    public async duplicateWorkspace(): Promise<void> {
        this.loaderStream.isLoading$.next(true);

        let workspace = await this.mapWorkspacesStore.duplicateWorkspace(this.getDuplicateWorkspaceDTO());

        if (workspace) {
            this.clearAndNavigateToWorkspace(workspace);
        } else {
            this.loaderStream.isLoading$.next(false);
        }
    }

    public getDuplicateWorkspaceDTO(): DuplicateWorkspaceDTO {
        let duplicateWorkspaceDTO: DuplicateWorkspaceDTO = {
            sourceWorkspaceId: this.sourceWorkspace.id,
            name: this.modifyWorkspace.name,
            description: this.modifyWorkspace.description,
            use_Source_CoordinationSystem: this.useSameCoordinateAsSource,
            duplicationMethod: this.shouldDuplicateTemplate
                ? DuplicationMethod.DuplicateTemplates
                : DuplicationMethod.LinkTemplates,
            coordinateSystem: this.modifyWorkspace.toDTO().coordinateSystem,
            bounds: this.modifyWorkspace.bounds,
            enablePostProcessing: this.useSameCoordinateAsSource
                ? this.sourceWorkspace.postProcessingOptions?.enabled
                : this.modifyWorkspace.postProcessingOptions?.enabled,
            connectFolderId: this.setFolder?.id
        };

        return duplicateWorkspaceDTO;
    }

    private clearAndNavigateToWorkspace(workspace: MapWorkspace): Promise<boolean> {
        this.workspaceCreationService.clearWorkspace();
        return this.router.navigate(['mapViewer', { outlets: { centerDialog: null, mainMenu: null } }], {
            queryParams: {
                projectId: workspace.projectId,
                workspaceId: workspace.id,
                activeMenu: MapMenuCode.LAYERS
            }
        });
    }
}
