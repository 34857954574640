import { Feature } from 'src/app/shared/map-data-services/feature/feature';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';

export class TodoSelectionCriteria {
    constructor(public todoIds: string[], public featureIds: string[]) {}
}

export class GeometryTypeSelectionCriteria {
    constructor(public layers: Layer[], public geometryType: string) {}
}

export type SelectionCriteria =
    | L.LatLngBounds // a leaflet rectangle bounds
    | L.Polygon // a leaflet polygon bounds
    | Feature[] // an array of feature SpatialItems
    | Feature // a single feature SpatialItem
    | string[] // an array of feature ids
    | string // a single feature id
    | TodoSelectionCriteria // a list of todo ids and feature ids
    | GeometryTypeSelectionCriteria // a list of layers and the geometry type of features to select (e.g. 'none')
    | null
    | undefined; // null or undefined (= empty)
