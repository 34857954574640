import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';
import { TemplateStatus } from 'src/app/shared/map-data-services/layer/template-lite';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { CurrentEditTemplateStream } from 'src/app/shared/template-services/current-edit-template-stream.service';
import { Field } from 'src/app/shared/template-services/field';
import { Template } from 'src/app/shared/template-services/template';
import { TemplateService } from 'src/app/shared/template-services/template.service';

import { TemplateRouteData } from '../template-editor-route-resolver.service';

export enum TemplateErrorCodes {
    UNAUTHORIZED = 'UNAUTHORIZED ',
    VALIDATION_ERROR = 'VALIDATION_ERROR',
    CONFLICT_ERROR = 'CONFLICT_ERROR',
    RESOURCE_NOT_FOUND_ERROR = 'RESOURCE_NOT_FOUND_ERROR'
}

@Injectable({
    providedIn: 'root'
})
export class TemplateStoreForComponent {
    templatesCache: { [key: string]: Template } = {};
    currentProjectId: string = null;
    linkedWorkspaces: MapWorkspace[] = [];

    templateEditorCloseStream = new Subject<boolean>();
    discardChangesStream = new Subject<boolean>();
    templateEditorSaveSuccessStream = new Subject<boolean>();

    constructor(
        private templateService: TemplateService,
        private currentEditTemplateStream: CurrentEditTemplateStream
    ) {}

    setLinkedWorkspaces(workspaces: MapWorkspace[]): void {
        this.linkedWorkspaces = workspaces;
    }

    getLinkedWorkspaces(): MapWorkspace[] {
        return this.linkedWorkspaces;
    }

    setCurrentProjectDetails(projectDetails: TemplateRouteData): void {
        this.currentProjectId = projectDetails.projectId ? projectDetails.projectId : null;
    }

    setCurrentEditedTemplate(template: Template): void {
        this.currentEditTemplateStream.next(template);
    }

    getTemplateDeltas(
        currentProjectId: string,
        template: Template,
        lastPublishedVersion?: number
    ): Promise<{ archived: Field[]; unPublished: Field[] }> {
        return template.seriesId
            ? this.templateService.getTemplateDeltas(template.seriesId, currentProjectId, lastPublishedVersion)
            : Promise.resolve(null);
    }

    async getTemplateWithPublicationStatus(
        projectId: string,
        workspaceId: string,
        templateId: string,
        publishStatusRequired: number
    ): Promise<Template> {
        const template = await this.getTemplateById(projectId, templateId);
        // if the template is a published template, then its the latest published version
        if (template.status === TemplateStatus.PUBLISHED) {
            template.lastPublishedVersion = template.version;
        }
        return template.lastPublishedVersion ? template : this.getLastPublishedTemplateVersionId(template);
    }

    async getLastPublishedTemplateVersionId(template: Template): Promise<Template> {
        const templates = await this.templateService.getAllPublishedTemplates(this.currentProjectId, template.seriesId);
        if (templates.length) {
            template.lastPublishedVersion = templates[templates.length - 1].version;
        }
        return template;
    }

    getTemplateById(projectId: string, templateId: string): Promise<Template> {
        let template = this.templatesCache && this.templatesCache[templateId];
        return template
            ? Promise.resolve(template)
            : this.templateService.getTemplateById(projectId, templateId).then(newTemplate => {
                  template = newTemplate;
                  this.templatesCache[template.id] = template;
                  return template;
              });
    }

    async createTemplate(template: Template, isPublishAction: boolean, workspaceId: string): Promise<Template> {
        try {
            if (isPublishAction) {
                template.status = TemplateStatus.PUBLISHED;
            } else {
                template.status = TemplateStatus.DRAFT;
            }
            const createdTemplate = await this.templateService.createTemplate(this.currentProjectId, template.toDTO());
            createdTemplate.attachedToWorkspaces = [];
            createdTemplate.attachedToLayers = [];
            createdTemplate.layerAttachedWorkspaces = [];
            createdTemplate.linkedWorkspaces = [];
            // if the current template is published template, then it is the latest published version
            if (isPublishAction) {
                createdTemplate.lastPublishedVersion = createdTemplate.version;
            }
            this.templatesCache[createdTemplate.id] = createdTemplate;
            return createdTemplate;
        } catch (error) {
            throw error;
        }
    }

    async updateTemplate(template: Template, isPublishAction: boolean): Promise<Template> {
        try {
            let tmpTemplate: Template = CloneUtils.cloneDeep(template);
            tmpTemplate.linkedWorkspaces = this.getLinkedWorkspaces();
            if (isPublishAction) {
                template.status = TemplateStatus.PUBLISHED;
            } else {
                template.status = TemplateStatus.DRAFT;
            }
            const updatedTemplate = await this.templateService.updateTemplate(
                this.currentProjectId,
                template.toDTO(),
                template.id
            );
            updatedTemplate.attachedToWorkspaces = tmpTemplate.attachedToWorkspaces; // copy these to the new update instance
            updatedTemplate.attachedToLayers = tmpTemplate.attachedToLayers;
            updatedTemplate.linkedWorkspaces = tmpTemplate.linkedWorkspaces || [];
            // if the current template is published template, then it is the latest published version
            if (isPublishAction) {
                updatedTemplate.lastPublishedVersion = updatedTemplate.version;
                this.templatesCache[updatedTemplate.id] = updatedTemplate;
                return updatedTemplate;
            } else {
                const updatedTemplateWithLatestVersion = await this.getLastPublishedTemplateVersionId(updatedTemplate);
                this.templatesCache[updatedTemplate.id] = updatedTemplateWithLatestVersion;
                return updatedTemplate;
            }
        } catch (error) {
            throw error;
        }
    }
}
