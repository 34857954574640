import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: '[maxAbsolute]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MaxAbsoluteValidatorDirective, multi: true }]
})
export class MaxAbsoluteValidatorDirective implements Validator, OnChanges {
    @Input('maxAbsolute') config: { enabled?: boolean; errorTag?: 'maxAbsolute'; max?: number };

    private _onValidatorChange = () => {};

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.config) {
            this._onValidatorChange();
        }
    }

    registerOnValidatorChange?(fn: () => void): void {
        this._onValidatorChange = fn;
    }

    validate(control: AbstractControl): ValidationErrors {
        let enabled = this.config.enabled !== undefined ? this.config.enabled : true;
        let tag = this.config.errorTag !== undefined ? this.config.errorTag : 'autoIncTntLimit';
        let max = this.config.max !== undefined ? this.config.max : 2147483647;

        if (enabled && control.value && !this.checkAutoIncrementIntLimit(control.value, max)) {
            return { [tag]: true };
        }
        return null;
    }

    checkAutoIncrementIntLimit(value: string, max: number): boolean {
        let regex = /\d+/g;
        if (value) {
            let numberMatch = value.toString().match(regex); // using .toString() since the default value being set is a number
            if (numberMatch) {
                let numba = numberMatch.pop();
                return parseInt(numba, 10) <= max;
            }
        }

        return true;
    }
}
