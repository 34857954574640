import { ErrorHandler, Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from 'src/environments/environment';
import { version } from 'src/environments/version';

@Injectable()
export class GspLoggerService {
    constructor() {
        if (environment.sendLogsToDatadog) {
            datadogLogs.init({
                clientToken: 'pub01b66fb100e3eb3530c99ad330e5771b',
                site: 'datadoghq.com',
                service: 'map-viewer',
                forwardErrorsToLogs: true,
                sampleRate: 100,
                env: environment.name,
                version: version
            });
        }
    }

    public debug(message: string) {
        datadogLogs.logger.debug(message);
        // eslint-disable-next-line no-console
        console.debug(message);
    }

    public error(message: string) {
        datadogLogs.logger.error(message);
        console.error(message);
    }

    public info(message: string) {
        datadogLogs.logger.info(message);
        // eslint-disable-next-line no-console
        console.info(message);
    }

    public warn(message: string) {
        datadogLogs.logger.warn(message);
        console.warn(message);
    }
}

// Global error handler
@Injectable()
export class GspErrorHandler extends ErrorHandler {
    constructor() {
        super();
    }

    handleError(error: any) {
        console.error(error);
        datadogRum.addError(error);
    }
}
