import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { CurrentUserStreamService } from 'src/app/shared/common/current-user/current-user-stream.service';
import { License } from 'src/app/shared/user/license';

@Component({
    selector: 'workspace-error',
    templateUrl: './workspace-error.component.html'
})
export class WorkspaceErrorComponent {
    // expose ButtonType enum to template
    public ButtonType = ButtonType;

    public loading = true;
    private license: License = null;

    constructor(currentUserStream: CurrentUserStreamService, private router: Router) {
        currentUserStream.getUserLicenseDetail().then(license => {
            this.license = license;
            this.loading = false;
        });
    }

    public closeErrorPanel(): void {
        this.router.navigate(['mapViewer', { outlets: { centerDialog: null } }], {
            queryParamsHandling: 'preserve'
        });
    }

    public upgradeTC(): void {
        window.location.href = this.license.upgradeLink;
    }
}
