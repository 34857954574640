export const StringConverter = (value: any): string => {
    if (value === null || value === undefined || typeof value === 'string') {
        return value;
    }

    return value.toString();
};

export const BooleanConverter = (value: any): boolean => {
    if (value === null || value === undefined || typeof value === 'boolean') {
        return value;
    }

    return value.toString() === 'true';
};

export const IntConverter = (value: any): number => {
    if (value === null || value === undefined || typeof value === 'number') {
        return value;
    }

    return parseInt(value.toString(), 10);
};

export const FloatConverter = (value: any): number => {
    if (value === null || value === undefined || typeof value === 'number') {
        return value;
    }

    return parseFloat(value.toString());
};

export const DateConverter = (value: any): Date => {
    if (value === null || value === undefined || value instanceof Date) {
        return value;
    }

    return new Date(value);
};

export const InputConverter = (converter: (value: any) => any): ((target: object, key: string) => void) => (
    target: object,
    key: string
) => {
    let definition = Object.getOwnPropertyDescriptor(target, key);
    if (definition) {
        Object.defineProperty(target, key, {
            get: definition.get,
            set: newValue => {
                definition.set(converter(newValue));
            },
            enumerable: true,
            configurable: true
        });
    } else {
        Object.defineProperty(target, key, {
            get: function() {
                return this['__' + key];
            },
            set: function(newValue) {
                this['__' + key] = converter(newValue);
            },
            enumerable: true,
            configurable: true
        });
    }
};
