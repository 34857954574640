import { formatNumber } from '@angular/common';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

import { KeyCodes } from './key-codes';

/**
 * Converts an input to a localized number input that is
 * formatted and only accepts valid numeric keys.
 */

// Adapted from http://www.anicehumble.com/2013/07/seamless-numeric-localization-with-angularjs.html

@Directive({
    selector: '[numeric]'
})
export class NumericDirective {
    constructor(private elementRef: ElementRef) {}

    @Input()
    public decimal: number | string;

    @Input()
    public digitGroup = false;

    @HostListener('blur', ['$event'])
    blurHandler(): void {
        if (this.digitGroup) {
            const value: string = this.elementRef.nativeElement.value.toString();
            if (!isNaN(parseInt(value, 10))) {
                if (value.match(/\./)) {
                    const [int, fraction] = value.split('.');
                    if (int === '-0') {
                        // Angular formatter swaps this to '0' so just use value
                        this.elementRef.nativeElement.value = value;
                    } else {
                        // Angular formatter limits string to 3 decimal places so only format integer portion
                        this.elementRef.nativeElement.value = this.getFormattedString(int) + '.' + fraction;
                    }
                } else {
                    this.elementRef.nativeElement.value = this.getFormattedString(value);
                }
            } else if (value.startsWith('.') || value.startsWith('-.')) {
                const [start, end] = value.split('.');
                this.elementRef.nativeElement.value = start + '0.' + end;
            } else {
                this.elementRef.nativeElement.value = '';
            }
        }
        // reset the caret to start of input for firefox
        setTimeout(() => {
            this.setCaretPosition(this.elementRef.nativeElement, 0);
        });
    }

    @HostListener('keypress', ['$event'])
    keyPressHandler(e: KeyboardEvent) {
        if (isNaN(parseInt(e.key, 10)) && e.key !== KeyCodes.DOT && e.key !== KeyCodes.HYPHEN) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        if (e.key === KeyCodes.DOT && Number(this.decimal) === 0) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        const caretPos = this.elementRef.nativeElement.selectionStart;
        const newInputText = this.insert(this.elementRef.nativeElement.value, caretPos, e.key);

        if (
            isNaN(Number(newInputText.replace(/\,/g, ''))) &&
            newInputText !== '-' &&
            newInputText !== '-.' &&
            newInputText !== '.'
        ) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
    }

    getFormattedString(input: string): string {
        // TODO: PS: replace set locale with dynamic value
        return formatNumber(parseFloat(input.replace(/\,/g, '')), 'en-US');
    }

    // http://stackoverflow.com/questions/512528/set-cursor-position-in-html-textbox
    setCaretPosition(elem: any, caretPos: number): void {
        if (elem != null) {
            elem.setSelectionRange(caretPos, caretPos);
        }
    }

    insert(str: string, index: number, value: string): string {
        return str.substr(0, index) + value + str.substr(index);
    }

    replaceAll(string: string, stringToFind: string, stringToReplace: string): string {
        if (stringToFind === stringToReplace) {
            return string;
        }

        let temp = string;
        let index = temp.indexOf(stringToFind);
        while (index !== -1) {
            temp = temp.replace(stringToFind, stringToReplace);
            index = temp.indexOf(stringToFind);
        }
        return temp;
    }

    // http://stackoverflow.com/questions/10454518/javascript-how-to-retrieve-the-number-of-decimals-of-a-string-number
    theDecimalPlaces(num: number): number {
        let match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
        if (!match) {
            return 0;
        }
        return Math.max(
            0,
            // Number of digits right of decimal point.
            (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0)
        ); // Adjust for scientific notation.
    }
}
