<div class="menu-hamburger">
    <section
        class="nav-menu"
        *checkVisibility="{
            visibleModes: [
                MapWorkspacePermissionType.READ,
                MapWorkspacePermissionType.FULL_ACCESS,
                MapWorkspacePermissionType.NO_ACCESS
            ]
        }"
        title="{{ 'TC.Common.MapWorkspaces' | translate }}"
        tooltipLocation="right"
        name="openMainMenuButton"
    >
        <gsp-button
            [type]="ButtonType.Icon"
            prevent-tab-event
            iconClass="icon_line_menu i32"
            (action)="openMainMenu()"
        ></gsp-button>
    </section>
</div>
