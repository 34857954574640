import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { CurrentEntitlementService } from './current-entitlement.service';
import { Entitlement, EntitlementSKUs } from './entitlement';

@Injectable({
    providedIn: 'root'
})
export class CurrentEntitlementStreamService {
    currentEntitlements: BehaviorSubject<Entitlement[]> = new BehaviorSubject(null);

    constructor(private currentEntitlementService: CurrentEntitlementService) {}

    public async initEntitlements(): Promise<void> {
        const entitlements = await this.currentEntitlementService.getCurrentEntitlements();
        this.currentEntitlements.next(entitlements);
    }

    public hasEntitlement(sku: EntitlementSKUs): boolean {
        return !!this.currentEntitlements.getValue()?.find(entitlement => entitlement.sku === sku);
    }

    public hasAnyOfTheseEntitlements(skus: EntitlementSKUs[]): boolean {
        return this.currentEntitlements.getValue()?.some(entitlement => skus.includes(entitlement.sku));
    }
}
