import { Injectable, Type } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';

export interface OnForward {
    onForward: () => Promise<void>;
}
export interface OnBackward {
    onBackward: () => Promise<void>;
}

export interface OnCancel {
    onCancel: () => void;
}

export interface OnClose {
    onClose: () => void;
}

export interface WizardNavigation extends OnForward, OnBackward, OnCancel, OnClose {}

export interface WizardUIButtonState {
    text: string;
    enabled: boolean;
    visible: boolean;
}

export interface WizardUIState {
    backButton: WizardUIButtonState;
    forwardButton: WizardUIButtonState;
    cancelButton: WizardUIButtonState;
    closeButton: WizardUIButtonState;
}

const defaultUIState = {
    backButton: { text: 'Common.Back', enabled: true, visible: true },
    forwardButton: { text: 'TC.Common.Next', enabled: true, visible: true },
    cancelButton: { text: 'cancel', enabled: true, visible: true },
    closeButton: { text: '', enabled: true, visible: true }
};

@Injectable()
export class GspWizardService {
    public steps: Type<any>[] = [];
    public currentStep$: Observable<Type<any>>;
    public uiState$: Observable<WizardUIState>;
    public currentStepIsBusy$: Observable<boolean>;
    public offlineGnssMode$: Observable<boolean>;
    private _currentStep$: BehaviorSubject<Type<any>> = new BehaviorSubject(null);

    private navigationHandler: WizardNavigation;
    private _uiState$: BehaviorSubject<WizardUIState>;
    private _currentStepIsBusy$: BehaviorSubject<boolean>;
    private _offlineGnssMode$: BehaviorSubject<boolean>;

    constructor() {
        this.currentStep$ = this._currentStep$.asObservable();
        this._uiState$ = new BehaviorSubject(cloneDeep(defaultUIState));
        this.uiState$ = this._uiState$.asObservable();
        this._currentStepIsBusy$ = new BehaviorSubject(false);
        this.currentStepIsBusy$ = this._currentStepIsBusy$.asObservable();
        this._offlineGnssMode$ = new BehaviorSubject(false);
        this.offlineGnssMode$ = this._offlineGnssMode$.asObservable();
    }

    public setSteps(steps: Type<any>[]): void {
        this.steps = steps;
        this._currentStep$.next(steps[0]);
    }

    public setNavigationHandler(step: WizardNavigation): void {
        this.navigationHandler = step;
    }

    public setOfflineGnssMode(isEnabled: boolean): void {
        this._offlineGnssMode$.next(isEnabled);
    }

    public moveTo(step: Type<any>): void {
        this._currentStep$.next(step);
    }

    public async moveForward(): Promise<void> {
        if (!this.navigationHandler) {
            throw new Error('You must set the current step component instance on the wizard service.');
        }

        if (this.navigationHandler.onForward) {
            this._currentStepIsBusy$.next(true);
            try {
                await this.navigationHandler.onForward();
            } finally {
                this._currentStepIsBusy$.next(false);
            }
        }

        if (this.isOnLastStep) {
            return;
        }

        this.moveTo(this.steps[this.currentStepIndex + 1]);
    }

    public async moveBackward(): Promise<void> {
        if (!this.navigationHandler) {
            throw new Error('You must set the current step component instance on the wizard service.');
        }

        if (this.navigationHandler.onBackward) {
            await this.navigationHandler.onBackward();
        }

        if (this.isOnFirstStep) {
            return;
        }

        this.moveTo(this.steps[this.currentStepIndex - 1]);
    }

    public onClose(): void {
        if (!this.navigationHandler) {
            throw new Error('You must set the current step component instance on the wizard service.');
        }

        if (this.navigationHandler.onClose) {
            this.navigationHandler.onClose();
        }
    }

    public onCancel(): void {
        if (!this.navigationHandler) {
            throw new Error('You must set the current step component instance on the wizard service.');
        }

        if (this.navigationHandler.onCancel) {
            this.navigationHandler.onCancel();
        }
    }

    private get currentStepIndex(): number {
        const index = this.steps.findIndex(s => s === this._currentStep$.value);
        if (index === -1) {
            return;
        }
        return index;
    }

    private get isOnFirstStep(): boolean {
        return this.currentStepIndex === 0;
    }

    private get isOnLastStep(): boolean {
        return this.currentStepIndex === this.steps.length - 1;
    }

    public setUI(state: WizardUIState): void {
        this._uiState$.next(state);
    }

    public reset(): void {
        this._uiState$.next(cloneDeep(defaultUIState));
    }
}
