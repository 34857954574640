import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import * as _ from 'lodash-es';
import { Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, first, skipWhile, switchMap, take, takeUntil } from 'rxjs/operators';
import { ActiveFeatureStreamsService } from 'src/app/shared/common/current-features/active-feature-streams.service';
import { FeatureFilterStreamService } from 'src/app/shared/common/current-features/feature-filter-stream.service';
import { FeaturesStore } from 'src/app/shared/common/current-features/features-store.service';
import { FeaturesStreamsService } from 'src/app/shared/common/current-features/features-streams.service';
import {
    GeoJsonFeatureType,
    GeoJsonFeaturesStreamService,
    GeoJsonLayer
} from 'src/app/shared/common/current-features/geoJson-features-stream.service';
import { TaskFeaturesStreamsService } from 'src/app/shared/common/current-features/task-features-streams.service';
import { LayersStore } from 'src/app/shared/common/current-layers/layers-store.service';
import { LayersStreams } from 'src/app/shared/common/current-layers/layers-streams.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { TasksStore } from 'src/app/shared/common/current-tasks/tasks-store.service';
import { TasksStreamsService } from 'src/app/shared/common/current-tasks/tasks-streams.service';
import { CurrentUserStreamService } from 'src/app/shared/common/current-user/current-user-stream.service';
import { FeatureFilter } from 'src/app/shared/common/feature-filter/feature-filter';
import { MenuService } from 'src/app/shared/common/layout/menu.service';
import { LoaderStreamService } from 'src/app/shared/common/loader/loader-stream.service';
import { Task } from 'src/app/shared/common/task/task';
import { ArrayUtils } from 'src/app/shared/common/utility/array-utils';
import { GeometryTypes, GeometryUtils } from 'src/app/shared/common/utility/geometry-utils';
import { CachedFeatureService } from 'src/app/shared/map-data-services/feature/cached-feature.service';
import { ActiveFeature, Feature } from 'src/app/shared/map-data-services/feature/feature';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { UserSettingsStreamService } from 'src/app/shared/user/user-settings-stream.service';

import { GeneralUtils } from 'src/app/shared/common/utility/general-utils';
import { MapContentMonitor } from '../common/map-content-monitor.service';
import { MapPanAndZoomStream } from '../common/map-pan-zoom-stream.service';
import { SelectionToolIdUtils, SelectionToolStream } from '../common/selection-tool-stream.service';
import { MapMenuCode } from '../map-menus/map-menu-list';
import { MapService } from '../map.service';
import { SidePanelStreamsService } from '../side-panel/side-panel-streams.service';
import { SidePanelName } from '../side-panel/side-panel.component';
import { FeatureMapLayerCacheService } from './feature-map-layers/feature-map-layer-cache.service';
import { FeatureMapLayersService } from './feature-map-layers/feature-map-layers.service';

@Component({
    templateUrl: './map-container.component.html',
    selector: 'map-container'
})
export class MapContainerComponent implements OnInit, OnDestroy {
    @Input()
    id: string;

    @ViewChild('leafletContainer', { static: true })
    containerElement: ElementRef;

    private destroyed = new Subject<void>();

    private projectId: string = null;

    private sortedMapWorkspaceLayers: Layer[] = [];
    private layerIdToLayer: { [layerId: string]: Layer } = {};
    private defaultView: {
        zoom: number;
        center: {
            lat: number;
            lng: number;
        };
    } = null;
    public map: L.Map = null;

    private mapLayerGroup: L.LayerGroup = null; // container for all layers' map layers and for the single taskMapLayer
    private allTasksId = '$AllTasks$'; // dummy id for the tasks layer
    private taskMapLayer: L.ClusterLayer = null; // the single map layer for all currently visible task features
    private layerIdToMapLayer: { [layerId: string]: L.TileClusterLayer } = {}; // map from layer id to a layer's map layer
    private inVisibleTasksFeatureIds: string[] = []; // the ids of all features in visible tasks
    private visibleTaskIdsByFeatureId: { [featureId: string]: string[] } = {}; // mapping from feature id to all its visible tasks

    private geoJsonLayers: { [type: string]: { [groupId: string]: L.GeoJSON } } = {};

    private mapContentMonitorClientId: number;
    private baseMapProviderId: string;
    private baseMapModeId: string;

    // current selection tool
    public currentMode: string; // single, rectangle, polygon
    public currentAction: string; // new, append, remove

    taskShowHideActionQueue: (() => Promise<void>)[] = []; // For queing task show/hide actions for preventing race condition

    changeMapWorkspaceStream = new Subject<MapWorkspace>();

    // TODO LATER: Look how many dependencies there are!  Feels like this class should be split.
    constructor(
        private mapService: MapService,
        private selectionToolStream: SelectionToolStream,
        private menuService: MenuService,
        private mapContentMonitor: MapContentMonitor,
        private mapPanAndZoomStream: MapPanAndZoomStream,
        private projectStream: ProjectStreamService,
        private layersStreams: LayersStreams,
        private tasksStreams: TasksStreamsService,
        private tasksStore: TasksStore,
        private taskFeaturesStreams: TaskFeaturesStreamsService,
        private featureFilterStream: FeatureFilterStreamService,
        private activeFeatureStreams: ActiveFeatureStreamsService,
        private featuresStreams: FeaturesStreamsService,
        private featuresStore: FeaturesStore,
        private geoJsonFeaturesStream: GeoJsonFeaturesStreamService,
        private featureMapLayersService: FeatureMapLayersService,
        private featureMapLayerCacheService: FeatureMapLayerCacheService,
        private cachedFeatureService: CachedFeatureService,
        private layersStore: LayersStore,
        private sidePanelStreams: SidePanelStreamsService,
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private userSettingsStream: UserSettingsStreamService,
        private currentUserStream: CurrentUserStreamService,
        private loaderStreamService: LoaderStreamService
    ) {
        projectStream.currentProjectStream.pipe(takeUntil(this.destroyed)).subscribe(project => {
            this.projectId = project ? project.id : undefined;
        });

        selectionToolStream.selectionToolSelectedStream.pipe(takeUntil(this.destroyed)).subscribe(selectionToolId => {
            if (selectionToolId) {
                this.currentMode = SelectionToolIdUtils.getModeFromSelectionToolId(selectionToolId);
                this.currentAction = SelectionToolIdUtils.getActionFromSelectionToolId(selectionToolId);
            }
        });

        // converting the visibleLayersStream to a subject with distinct non null values so that we can use it below
        const distinctVisibleLayerStream = new Subject<Layer[]>();
        this.layersStreams.visibleLayersStream
            .pipe(
                skipWhile(val => !val.length),
                distinctUntilChanged((x, y) => _.isEqual(x, y)),
                takeUntil(this.destroyed)
            )
            .subscribe(val => {
                distinctVisibleLayerStream.next(val);
            });

        // deferring the updation of layer cache until visible layer stream has value
        this.changeMapWorkspaceStream
            .pipe(
                switchMap(() => distinctVisibleLayerStream.pipe(take(1))),
                takeUntil(this.destroyed)
            )
            .subscribe(() => {
                // changing workspace reloads the layers which all have new timestamps, so busting all cached tiles
                this.mapContentMonitor.mapRefreshed();
            });
    }

    ngOnInit(): void {
        this.currentUserStream.stream.subscribe(currentUser => {
            if (currentUser) {
                this.initialize();
            }
        });
    }

    ngOnDestroy(): void {
        this.mapContentMonitor.stopMonitoring(this.mapContentMonitorClientId);
        if (this.map && _.isFunction(this.map.off)) {
            this.map.off('resize', () => this.invalidateSize());
            this.map.off('moveend', () => this.updateUserSettings());
        }
        this.destroyed.next(null);
        this.destroyed.complete();
    }

    // Save the base map provider;
    private initialize(): void {
        this.loaderStreamService.isLoading$.next(false);
        this.mapService.intializeBaseMapProvider();
        let map = this.mapService.intializeMap(
            this.containerElement.nativeElement,
            this.baseMapProviderId,
            this.baseMapModeId
        );
        this.map = map;
        this.mapService.mapReadyStreams[this.containerElement.nativeElement.id].next(true);
        this.applyWorkspaceSettings();
        this.mapLayerGroup = L.layerGroup().addTo(this.map);

        combineLatest([
            this.mapWorkspacesStore.currentMapWorkspaceStream.pipe(distinctUntilChanged(GeneralUtils.isIdEqual)),
            this.mapService.baseMapReadyStream.pipe(first((isMapReady: boolean) => isMapReady))
        ])
            .pipe(takeUntil(this.destroyed))
            .subscribe(([mapWorkspace, isMapReady]: [MapWorkspace, boolean]) => {
                if (isMapReady) {
                    this.changeWorkspace(mapWorkspace);

                    this.layersStore.loadMapWorkspaceLayers(mapWorkspace).then(layers => {
                        if (
                            layers &&
                            layers.length &&
                            (mapWorkspace.isFileViewer || mapWorkspace.isPubliclySharedMapWorkspace)
                        ) {
                            let bounds = this.calculateAllLayersBounds(layers);
                            if (bounds) {
                                this.mapPanAndZoomStream.zoomToBounds(bounds, [360, 60], [340, 10], {
                                    maxZoom: this.mapService.selectedBaseMapMode.maxZoom
                                });
                            }
                        }
                    });
                }
            });

        this.loadFeaturesLayersAndFilters();

        this.map.on('resize', () => this.invalidateSize());
        this.map.on('moveend', () => this.updateUserSettings());
    }

    // ---------------------
    // INITIALISE FOR CHANGE OF WORKSPACE

    private changeWorkspace(workspace?: MapWorkspace): void {
        if (workspace && workspace.id && !workspace.isFileViewer) {
            this.mapContentMonitorClientId = this.mapContentMonitor.startMonitoring(workspace.id);

            this.changeMapWorkspaceStream.next(workspace);
        }

        this.featuresStreams.clearSelectedFeatures();

        // Clear all geojson features for map cache type
        this.mapLayerGroup.clearLayers();
        this.clearAllGeoJsonFeatures(GeoJsonFeatureType.MAP_CACHE);
        this.sidePanelStreams.closeAllSidePanels();

        this.resetTaskMapLayer();

        if (workspace && workspace.id) {
            this.applyWorkspaceSettings();
            this.loaderStreamService.isLoading$.next(false);
            this.invalidateSize();
        }
    }

    private resetTaskMapLayer(): void {
        // the single map layer for all visible task features - note: clustering disabled for this layer

        this.inVisibleTasksFeatureIds = [];
        if (this.taskMapLayer) {
            this.mapLayerGroup.removeLayer(this.taskMapLayer);
        }

        this.taskMapLayer = new L.ClusterLayer(this.allTasksId, null, this.getZIndex(this.allTasksId), false);
        this.mapLayerGroup.addLayer(this.taskMapLayer);
    }

    private applyWorkspaceSettings(): void {
        let currentMapWorkspaceSettings = this.userSettingsStream.getCurrentMapWorkspaceSettings();

        this.baseMapProviderId =
            currentMapWorkspaceSettings && currentMapWorkspaceSettings['mapProvider']
                ? currentMapWorkspaceSettings['mapProvider']
                : 'trimble';
        this.baseMapModeId =
            currentMapWorkspaceSettings && currentMapWorkspaceSettings['mapMode']
                ? currentMapWorkspaceSettings['mapMode']
                : 'map';
        this.mapService.setBaseMapMode(this.baseMapProviderId, this.baseMapModeId);

        if (currentMapWorkspaceSettings && currentMapWorkspaceSettings.setView) {
            this.defaultView = {
                zoom: currentMapWorkspaceSettings['zoom'],
                center: {
                    lat: currentMapWorkspaceSettings['center'].lat,
                    lng: currentMapWorkspaceSettings['center'].lng
                }
            };

            let zoom = this.defaultView.zoom > 18 ? 18 : this.defaultView.zoom;
            this.map.setView(L.latLng(this.defaultView.center.lat, this.defaultView.center.lng), zoom);
        } else {
            const currentWorkspace = this.mapWorkspacesStore.getCurrentMapWorkspace();
            if (currentWorkspace && currentWorkspace.hasBounds()) {
                const sw = new L.LatLng(currentWorkspace.bounds.latitudeSouth, currentWorkspace.bounds.longitudeWest);
                const ne = new L.LatLng(currentWorkspace.bounds.latitudeNorth, currentWorkspace.bounds.longitudeEast);

                let bounds = new L.LatLngBounds(sw, ne);
                this.map.fitBounds(bounds);
            }
        }

        if (currentMapWorkspaceSettings && currentMapWorkspaceSettings.filters) {
            const filters = currentMapWorkspaceSettings.filters;
            this.featureFilterStream.activeFilter = FeatureFilter.fromDTO(filters);
        } else {
            this.featureFilterStream.activeFilter = new FeatureFilter();
        }
    }

    private invalidateSize(): void {
        this.map.invalidateSize();
    }

    private updateUserSettings(): void {
        const workspace = this.mapWorkspacesStore.getCurrentMapWorkspace();
        if (workspace && !workspace.isFileViewer) {
            const currentMapWorkspaceSettings = this.userSettingsStream.getCurrentMapWorkspaceSettings();

            if (currentMapWorkspaceSettings) {
                currentMapWorkspaceSettings.setView = true;
                currentMapWorkspaceSettings.zoom = this.map.getZoom();
                currentMapWorkspaceSettings.center.lat = this.map.getCenter().lat;
                currentMapWorkspaceSettings.center.lng = this.map.getCenter().lng;
            }

            if (
                this.currentUserStream.currentUser &&
                this.projectStream.getCurrentProject() &&
                currentMapWorkspaceSettings
            ) {
                this.userSettingsStream.updateCurrentWorkspaceSettings(currentMapWorkspaceSettings);
            }
        }
    }

    private loadFeaturesLayersAndFilters(): void {
        this.featureFilterStream.activeFilterStream
            .pipe(takeUntil(this.destroyed))
            .subscribe(() => this.changeFilters());

        this.layersStreams.mapWorkspaceLayersStream
            .pipe(takeUntil(this.destroyed))
            .subscribe(x => this.updateMapWorkspaceLayers(x));
        this.layersStreams.deferVisibleLayersStream.pipe(takeUntil(this.destroyed)).subscribe(x => this.showLayers(x));
        this.layersStreams.deferHideLayersStream.pipe(takeUntil(this.destroyed)).subscribe(x => this.hideLayers(x));
        this.layersStore.refreshMapWorkspaceLayersStreams
            .pipe(takeUntil(this.destroyed))
            .subscribe(x => this.updateLayersFeatures(x));

        this.tasksStreams.deferVisibleTasksStream.pipe(takeUntil(this.destroyed)).subscribe(x => this.showTasks(x));
        this.tasksStreams.deferHideTasksStream.pipe(takeUntil(this.destroyed)).subscribe(x => this.hideTasks(x));
        this.tasksStore.refreshMapWorkspaceTasksStreams
            .pipe(takeUntil(this.destroyed))
            .subscribe(x => this.updateTasksFeatures(x));
        this.taskFeaturesStreams.showDataLayersStream
            .pipe(takeUntil(this.destroyed), distinctUntilChanged())
            .subscribe(x => this.showDataLayerFeatures(x));
        this.taskFeaturesStreams.showTaskFeaturesStreams.pipe(takeUntil(this.destroyed)).subscribe(x => {
            this.showOrAddTaskFeatures(x);
        });
        this.taskFeaturesStreams.hideTaskFeaturesStreams
            .pipe(takeUntil(this.destroyed))
            .subscribe(x => this.hideOrRemoveTaskFeatures(x));

        this.featuresStreams.selectedFeaturesStream
            .pipe(takeUntil(this.destroyed), distinctUntilChanged(_.isEqual))
            .subscribe(x => this.openOrCloseFeatureSidePanel(x));

        // Pan and zoom the map active feature, zoom only if feature selected from feature panel
        this.activeFeatureStreams.activeFeatureStream
            .pipe(takeUntil(this.destroyed), distinctUntilChanged(GeneralUtils.isIdEqual))
            .subscribe(x => this.panAndCenterToActiveFeature(x));
        this.featuresStore.removedFeatureStream
            .pipe(takeUntil(this.destroyed))
            .subscribe(x => this.removeFeatureFromMap(x));
        this.menuService.activeMenusStream.pipe(takeUntil(this.destroyed)).subscribe(() => this.activeMenuSelected());
        this.geoJsonFeaturesStream.geoJsonFeaturesStream
            .pipe(takeUntil(this.destroyed))
            .subscribe((geoJsonLayer: GeoJsonLayer) => this.showOrHideGeoJsonFeatures(geoJsonLayer));

        this.mapPanAndZoomStream.mapPanAndZoomStream.subscribe(panOrZoomAction => {
            this.mapPanAndZoomStream.doPanOrZoom(this.map, panOrZoomAction);
        });
    }

    // -----------------------------------------------------
    // CHANGE OF LAYERS/FEATURES/FILTER HANDLERS

    private updateMapWorkspaceLayers(mapWorkspaceLayers: Layer[]): void {
        this.sortedMapWorkspaceLayers = mapWorkspaceLayers;
        this.layerIdToLayer = {};

        if (this.sortedMapWorkspaceLayers) {
            // reset zIndexes for all layers
            this.sortedMapWorkspaceLayers.forEach(layer => {
                let mapLayer = this.layerIdToMapLayer[layer.id];
                if (mapLayer) {
                    mapLayer.setZIndex(this.getZIndex(layer.id));
                }
            });

            this.sortedMapWorkspaceLayers.forEach(layer => {
                this.layerIdToLayer[layer.id] = layer;
            });
        }
    }

    private getZIndex(layerOrTaskId: string): number {
        return (
            this.sortedMapWorkspaceLayers.length -
            1 -
            _.findIndex(this.sortedMapWorkspaceLayers, layer2 => layerOrTaskId === layer2.id)
        );
    }

    private updateLayersFeatures(layers: Layer[]): void {
        if (layers && layers.length) {
            layers.forEach(layer => {
                if (layer.visible) {
                    this.showLayers([layer]);
                }
            });
        }
    }

    private updateTasksFeatures(tasks: Task[]): void {
        if (tasks.length) {
            this.addTaskShowHideActionQueue(this.showTasks.bind(this, [tasks], true));
        }
    }

    private changeFilters(/*filters*/): void {
        let tasks = this.tasksStore.mapWorkspaceTasksStream.getValue() || [];

        tasks.forEach(task => {
            // Hide all already visible tasks
            if (task.taskFeatures) {
                this.addTaskShowHideActionQueue(this.hideTasks.bind(this, [task]));
            }

            // Show only new visible tasks
            if (task.visible) {
                this.addTaskShowHideActionQueue(this.showTasks.bind(this, [task], true));
            }
        });

        this.cachedFeatureService.getMapFeaturesInTask().forEach(feature => {
            feature.inVisibleTask = false;
            this.cachedFeatureService.addOrUpdateFeature(feature);
        });

        if (this.taskFeaturesStreams.showDataLayersStream.getValue()) {
            this.layersStore.mapWorkspaceLayersStream.getValue().forEach(layer => {
                if (layer.visible) {
                    this.showLayers([layer]);
                }
            });
        }
    }

    // To handle task show/hide actions in a queue to prevent race condition when changeFilters() is applied

    addTaskShowHideActionQueue(actionFn: () => Promise<void>): void {
        this.taskShowHideActionQueue.push(actionFn);
        if (this.taskShowHideActionQueue.length === 1) {
            this.execTaskShowHideActionQueue();
        }
    }

    execTaskShowHideActionQueue(): void {
        if (this.taskShowHideActionQueue.length) {
            let actionFn = this.taskShowHideActionQueue[0];
            actionFn().then(() => {
                this.taskShowHideActionQueue.shift();
                this.execTaskShowHideActionQueue();
            });
        }
    }

    // -----------------------------------------------------
    // SHOW/HIDE LAYERS/TASKS

    private showLayers(layers: Layer[]): void {
        let currentMapWorkspace = this.mapWorkspacesStore.getCurrentMapWorkspace();
        const selectedLayer = this.featureFilterStream.activeFilter.selectedLayer;

        // exclude visible task features from the map layers
        let excludedFeatureIdsByLayerId: { [key: string]: string[] } = {};
        this.inVisibleTasksFeatureIds.forEach(featureId => {
            let feature = this.cachedFeatureService.getFeature(featureId);
            if (feature && feature.layerId) {
                if (!excludedFeatureIdsByLayerId[feature.layerId]) {
                    excludedFeatureIdsByLayerId[feature.layerId] = [];
                }
                excludedFeatureIdsByLayerId[feature.layerId].push(featureId);
            }
        });

        layers.forEach(layer => {
            if (
                currentMapWorkspace?.id === layer.workspaceId &&
                layer.geometryType !== GeometryTypes.NONE &&
                layer.bounds
            ) {
                let mapLayer = this.layerIdToMapLayer[layer.id];
                if (mapLayer) {
                    this.mapLayerGroup.removeLayer(mapLayer);
                }

                // If there is a selected layer and it's not the current layer, skip building tileCluster
                if (selectedLayer && layer.id !== selectedLayer) {
                    return;
                }

                mapLayer = new L.TileClusterLayer(
                    this.map,
                    this.projectId,
                    this.mapWorkspacesStore.getCurrentMapWorkspace().id,
                    layer,
                    this.getZIndex(layer.id),
                    this.featureFilterStream.activeFilter,
                    excludedFeatureIdsByLayerId[layer.id]
                );

                this.mapLayerGroup.addLayer(mapLayer);
                this.layerIdToMapLayer[layer.id] = mapLayer;
            }
        });
    }

    private hideLayers(layers: Layer[]): void {
        layers.forEach(layer => {
            let mapLayer = this.layerIdToMapLayer[layer.id];
            if (mapLayer) {
                this.mapLayerGroup.removeLayer(mapLayer);
                delete this.layerIdToMapLayer[layer.id];
            }
        });
    }

    private showTasks(tasks: Task[], noZoom = false): Promise<void> {
        // return immediately without showing tasks if layer menu is active
        if (this.layersMenuIsActive()) {
            return Promise.resolve();
        } else {
            return new Promise(resolve => {
                tasks.forEach(task => {
                    // var tmpTaskLayer = featureMapLayers.taskIdToMapLayer[task.id];
                    this.tasksStreams.setTaskAsLoading(task);
                    this.taskFeaturesStreams.getTaskBounds(task, noZoom).then(bounds => {
                        if (bounds) {
                            let mapBounds = this.map.getBounds();
                            if (!mapBounds.contains(bounds)) {
                                bounds = mapBounds.extend(bounds);
                                let basemapMode = this.mapService.selectedBaseMapMode;
                                this.mapPanAndZoomStream.zoomToBounds(bounds, [360, 60], [340, 10], {
                                    maxZoom: basemapMode.maxZoom
                                });
                            }
                        }

                        this.taskFeaturesStreams.getFeaturesByTask(task).then(taskFeatures => {
                            // track visible task ids for task features
                            task.taskFeatures = taskFeatures;
                            task.taskFeatures.forEach(taskFeature => {
                                if (!this.visibleTaskIdsByFeatureId[taskFeature.id]) {
                                    this.visibleTaskIdsByFeatureId[taskFeature.id] = [];
                                }
                                if (this.visibleTaskIdsByFeatureId[taskFeature.id].indexOf(task.id) < 0) {
                                    this.visibleTaskIdsByFeatureId[taskFeature.id].push(task.id);
                                }
                            });

                            const visibleTasks = this.tasksStreams.visibleTasksStream.getValue();
                            if (visibleTasks.includes(task)) {
                                this.taskFeaturesStreams.showTaskFeaturesStreams.next(task.taskFeatures);
                            }

                            this.tasksStreams.setTaskAsLoaded(task);
                            resolve();
                        });
                    });
                });
                if (tasks.length === 0) {
                    resolve();
                }
            });
        }
    }

    private hideTasks(tasks: Task[]): Promise<void> {
        tasks.forEach(task => {
            // track visible task ids for task features
            task.taskFeatures.forEach(taskFeature => {
                if (this.visibleTaskIdsByFeatureId[taskFeature.id]) {
                    let index = this.visibleTaskIdsByFeatureId[taskFeature.id].indexOf(task.id);
                    if (index >= 0) {
                        this.visibleTaskIdsByFeatureId[taskFeature.id].splice(index, 1);
                    }
                    if (this.visibleTaskIdsByFeatureId[taskFeature.id].length === 0) {
                        delete this.visibleTaskIdsByFeatureId[taskFeature.id];
                    }
                }
            });

            this.taskFeaturesStreams.hideTaskFeaturesStreams.next(task.taskFeatures);
        });
        return Promise.resolve();
    }

    // -----------------------------------------------------
    // MAP MENU
    private activeMenuSelected(): void {
        if (this.tasksMenuIsActive()) {
            // In task page
            this.selectionToolStream.revertDrawingMode();
            this.featuresStreams.modifyTasks(this.tasksStreams.visibleTasksStream.getValue());
        } else if (this.layersMenuIsActive()) {
            // it is layers tab
            this.featuresStreams.modifyTasks([]); // setting empty selected taskFeatures on menu change
            let visibleLayers = this.layersStreams.visibleLayersStream.getValue();
            this.showLayers(visibleLayers);
            this.featuresStreams.modifyFilterLayers(visibleLayers);
        }
    }

    private layersMenuIsActive(): boolean {
        return this.activeMenuCodes().includes(MapMenuCode.LAYERS);
    }

    private tasksMenuIsActive(): boolean {
        return this.activeMenuCodes().includes(MapMenuCode.TASKS);
    }

    private activeMenuCodes(): MapMenuCode[] {
        return this.menuService.activeMenusStream.getValue() || [];
    }

    // ---------------------
    // TASKS (TODO) PANEL

    // show data layer features based on button while in the Tasks menu
    private showDataLayerFeatures(show: boolean): void {
        if (this.tasksMenuIsActive()) {
            let visibleLayers = this.layersStreams.visibleLayersStream.getValue() || [];
            if (show) {
                this.showLayers(visibleLayers);
                this.featuresStreams.modifyFilterLayers(visibleLayers);
            } else {
                // Remove all data features
                this.hideLayers(visibleLayers);
                this.featuresStreams.modifyFilterLayers([]);
            }
        }
    }

    private showOrAddTaskFeatures(features: Feature[]): void {
        features = features || [];
        features.forEach(feature => {
            feature = this.cachedFeatureService.updateFromCacheFeature(feature);
            feature.inVisibleTask = true;
            this.cachedFeatureService.addOrUpdateFeature(feature);

            let mapLayer = this.layerIdToMapLayer[feature.layerId];
            let featureMapLayer = this.featureMapLayerCacheService.getFeatureMapLayer(feature.id);

            if (!featureMapLayer || !this.taskMapLayer.hasLayer(featureMapLayer)) {
                // featureMapLayer will be excluded from map Layer...
                if (mapLayer) {
                    mapLayer.addExcludedFeature(feature);
                }

                // always recreate the feature map layer as some (e.g. from the geoJson tiles) are not suitable for use of the task layer
                featureMapLayer = this.featureMapLayersService.createFeatureMapLayer(feature);
                if (!featureMapLayer) {
                    return;
                }
                // ... and placed on taskMapLayer
                this.taskMapLayer.addLayers([featureMapLayer]);

                this.featureMapLayersService.updateFeatureMapLayer(feature, featureMapLayer);
                if (this.inVisibleTasksFeatureIds.indexOf(feature.id) < 0) {
                    this.inVisibleTasksFeatureIds.push(feature.id);
                }

                this.featureMapLayerCacheService.addOrUpdateFeatureMapLayer(feature, featureMapLayer, this.allTasksId);
            }
        });
    }

    private hideOrRemoveTaskFeatures(features: Feature[]): void {
        features.forEach(feature => {
            let featureMapLayer = this.featureMapLayerCacheService.getFeatureMapLayer(feature.id);
            feature = this.cachedFeatureService.updateFromCacheFeature(feature);
            let visibleTaskIds = this.visibleTaskIdsByFeatureId[feature.id] || [];

            if (featureMapLayer && visibleTaskIds.length === 0) {
                feature.inVisibleTask = false;
                this.featureMapLayersService.updateFeatureMapLayer(feature, featureMapLayer);

                // feature map layer will be removed from taskMapLayer...
                this.taskMapLayer.removeLayers([featureMapLayer]);
                let index2 = this.inVisibleTasksFeatureIds.indexOf(feature.id);
                if (index2 >= 0) {
                    this.inVisibleTasksFeatureIds.splice(index2, 1);
                }
                this.featureMapLayerCacheService.addOrUpdateFeatureMapLayer(feature, featureMapLayer, feature.layerId);
                this.cachedFeatureService.addOrUpdateFeature(feature);

                // ...and no longer excluded from map layer
                let mapLayer = this.layerIdToMapLayer[feature.layerId];
                if (mapLayer) {
                    mapLayer.removeExcludedFeature(feature);
                }
            }
        });
    }

    // ---------------------
    // GEOJSON FEATURES FROM MAPCACHE

    private showOrHideGeoJsonFeatures(geoJsonLayer: GeoJsonLayer): void {
        if (geoJsonLayer) {
            if (!this.geoJsonLayers[geoJsonLayer.type]) {
                this.geoJsonLayers[geoJsonLayer.type] = {};
            }
            if (this.geoJsonLayers[geoJsonLayer.type][geoJsonLayer.groupId]) {
                this.map.removeLayer(this.geoJsonLayers[geoJsonLayer.type][geoJsonLayer.groupId]);
                this.geoJsonLayers[geoJsonLayer.type] = ArrayUtils.removeItem(
                    this.geoJsonLayers[geoJsonLayer.type],
                    geoJsonLayer.groupId
                );
            }
            if (geoJsonLayer.geoJson) {
                this.geoJsonLayers[geoJsonLayer.type][geoJsonLayer.groupId] = L.geoJSON(geoJsonLayer.geoJson, {
                    style: (feature: any): L.PathOptions =>
                        this.featureMapLayersService.getFeatureStyle(feature as Feature)
                });

                this.geoJsonLayers[geoJsonLayer.type][geoJsonLayer.groupId].addTo(this.map);

                if (geoJsonLayer.focusOnMap) {
                    let bounds: L.LatLngBounds;

                    if (geoJsonLayer.type === GeoJsonFeatureType.MAP_CACHE) {
                        // Donnt extend the bounds
                        bounds = this.geoJsonLayers[geoJsonLayer.type][geoJsonLayer.groupId].getBounds();
                    } else {
                        Object.keys(this.geoJsonLayers[geoJsonLayer.type]).forEach(layerId => {
                            if (!bounds) {
                                bounds = this.geoJsonLayers[geoJsonLayer.type][layerId].getBounds();
                            } else {
                                bounds.extend(this.geoJsonLayers[geoJsonLayer.type][layerId].getBounds());
                            }
                        });
                    }

                    this.map.fitBounds(bounds);
                }
            }
        }
    }

    private clearAllGeoJsonFeatures(type: GeoJsonFeatureType): void {
        if (this.geoJsonLayers[type]) {
            Object.keys(this.geoJsonLayers[type]).forEach(groupId => {
                this.map.removeLayer(this.geoJsonLayers[type][groupId]);
                this.geoJsonLayers[type] = ArrayUtils.removeItem(this.geoJsonLayers[type], groupId);
            });
        }
    }

    // ---------------------
    // FEATURE SIDE PANEL

    private openOrCloseFeatureSidePanel(selectedFeatures: Feature[]): void {
        const workspace = this.mapWorkspacesStore.getCurrentMapWorkspace();
        if (
            workspace &&
            !workspace.isPubliclySharedMapWorkspace &&
            !workspace.isFileViewer &&
            this.layersMenuIsActive() &&
            this.userSettingsStream.getCurrentProjectSettings().previousMapWorkspace !== workspace.id
        ) {
            const selectedFeatureIds = selectedFeatures.map(feature => feature.id);
            const currentMapWorkspaceSettings = this.userSettingsStream.getCurrentMapWorkspaceSettings();
            currentMapWorkspaceSettings.selectedFeatureIds = selectedFeatureIds;
            this.userSettingsStream.updateCurrentWorkspaceSettings(currentMapWorkspaceSettings);
        }

        if (selectedFeatures.length) {
            this.sidePanelStreams.closeSidePanel(SidePanelName.EXISTING_TEMPLATE);
            this.sidePanelStreams.closeSidePanel(SidePanelName.EXISTING_LAYER);
            this.sidePanelStreams.openSidePanel(SidePanelName.SELECTED_FEATURES);
        } else {
            this.sidePanelStreams.closeSidePanel(SidePanelName.SELECTED_FEATURES);
        }
    }

    private panAndCenterToActiveFeature(currentFeature: ActiveFeature): void {
        if (!currentFeature?.geometry?.coordinates || !currentFeature.selectedFromFeaturePanel) return;

        const bounds = GeometryUtils.getBounds(currentFeature.geometry);
        const maxZoom = Math.max(this.map.getZoom(), 18);
        this.map.fitBounds(bounds, { maxZoom });
    }

    private calculateAllLayersBounds(layers: Layer[]): L.LatLngBounds {
        let bounds: L.LatLngBounds = null;
        layers.forEach(layer => {
            if (layer.bounds && layer.bounds.isValid) {
                if (bounds == null) {
                    bounds = layer.bounds;
                } else {
                    bounds.extend(layer.bounds);
                }
            }
        });
        return bounds;
    }

    // ---------------------
    // FEATURE DELETE (From Feature side panel)

    private removeFeatureFromMap(feature: Feature): void {
        if (feature) {
            this.layersStore.mapWorkspaceLayersStream.getValue().forEach(layer => {
                if (layer.id === feature.layerId) {
                    layer.updateCacheTimeStamp();
                }
            });

            let featureMapLayer = this.featureMapLayerCacheService.getFeatureMapLayer(feature.id);

            let mapLayer = this.layerIdToMapLayer[feature.layerId];
            if (mapLayer && featureMapLayer && mapLayer.hasLayerForFeature(feature, featureMapLayer)) {
                if (feature.geometry.type === 'Point') {
                    mapLayer.removeLayers([featureMapLayer]);
                } else {
                    mapLayer.tileLayer.geojsonLayer.removeLayer(featureMapLayer);
                }
            }

            if (this.taskMapLayer && featureMapLayer && this.taskMapLayer.hasLayer(featureMapLayer)) {
                this.taskMapLayer.removeLayers([featureMapLayer]);
            }

            this.featureMapLayerCacheService.removeFeatureMapLayer(feature.id, feature.layerId);
        }
    }

    // ---------------------
}
