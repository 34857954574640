import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ModalSize } from '../../modal-sizes';
import { ButtonType } from '../buttons/button';

@Component({
    selector: 'gsp-confirmation-popup',
    templateUrl: './gsp-confirmation-popup.component.html'
})
export class GspConfirmationPopupComponent {
    @Input()
    public heading = '';

    @Input()
    public message = '';

    @Input()
    public confirmationButtonText = 'TC.Common.Delete';

    @Input()
    public confirmAction: () => PromiseLike<any>;

    @Output()
    public cancel = new EventEmitter();

    @Input()
    public isPermanentAction = false;

    @Input()
    public id = '';

    // expose ButtonType enum to template
    public ButtonType = ButtonType;
    public ModalSize = ModalSize;

    public onConfirmClick(): void {
        this.confirmAction();
    }

    public onCancelClick(): void {
        this.cancel.emit();
    }
}
