import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { BaseMapMode } from 'src/app/feature/map-viewer/base-maps/base-map-mode';
import { BaseMapProvider } from 'src/app/feature/map-viewer/base-maps/base-map-provider';
import { MapService } from 'src/app/feature/map-viewer/map.service';
import { ContextMenuActions } from 'src/app/shared/common/components/context-menu/gsp-context-menu.component';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { UserSettingsStreamService } from 'src/app/shared/user/user-settings-stream.service';

@Component({
    templateUrl: './map-provider.component.html',
    selector: 'map-provider-list'
})
export class MapProviderListComponent implements OnInit, OnDestroy {
    public contextMenuItems: ContextMenuActions[] = null;
    public baseMapProviders: BaseMapProvider[];
    public selectedBaseMapProvider: BaseMapProvider;
    public selectedBaseMapMode: BaseMapMode;
    private destroyed = new Subject<void>();
    constructor(
        private mapService: MapService,
        private userSettingsStream: UserSettingsStreamService,
        private translate: TranslationService,
        private mapWorkspaceStore: MapWorkspacesStoreService
    ) {}

    ngOnInit(): void {
        this.mapService.basemapChangedStream.pipe(takeUntil(this.destroyed)).subscribe(() => {
            this.baseMapProviders = this.mapService.baseMapProviders;
            this.selectedBaseMapProvider = this.mapService.selectedBaseMapProvider;
            this.selectedBaseMapMode = this.mapService.selectedBaseMapMode;
            this.contextMenuItems = this.getContextMenuItems();
        });
    }

    public changeBaseMap(baseMapProvider: BaseMapProvider, baseMapMode: BaseMapMode): void {
        const currentMapWorkspace = this.mapWorkspaceStore.getCurrentMapWorkspace();
        const currentMapWorkspaceSettings = this.userSettingsStream.getCurrentMapWorkspaceSettings();
        if (
            currentMapWorkspace &&
            currentMapWorkspaceSettings &&
            !currentMapWorkspace.isPubliclySharedMapWorkspace &&
            !currentMapWorkspace.isFileViewer
        ) {
            currentMapWorkspaceSettings.mapProvider = baseMapProvider.id;
            currentMapWorkspaceSettings.mapMode = baseMapMode.id;
            this.userSettingsStream.updateCurrentWorkspaceSettings(currentMapWorkspaceSettings);
        }
        this.mapService.setBaseMapMode(baseMapProvider.id, baseMapMode.id);
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    private getContextMenuItems(): ContextMenuActions[] {
        const items: ContextMenuActions[] = [];
        this.selectedBaseMapProvider.baseMapModes.forEach(mode => {
            let item = {
                name: this.translate.instant(mode.label),
                id: `map-provider-${mode.name}`,
                divider: false,
                title: false,
                visible: () => true,
                image: mode.imageUrl,
                itemClass: 'map-preview-item',
                labelClass: 'map-preview-label',
                execute: () => this.changeBaseMap(this.selectedBaseMapProvider, mode)
            };
            items.push(item);
        });
        return items;
    }
}
