import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ForbiddenFormsService } from '../../map-data-services/forbidden-forms/forbidden-forms.service';
import { ForbiddenFormsDTO } from '../../types/forbidden-forms';
import { User, UserRole } from '../../user/user';
import { CurrentRegionStreamService } from '../current-region/region-stream.service';
import { CurrentUserStreamService } from '../current-user/current-user-stream.service';

@Injectable({
    providedIn: 'root'
})
export class ForbiddenFormsStreamService {
    public forbiddenFormsStream = new BehaviorSubject<ForbiddenFormsDTO>(null);

    constructor(
        private currentUserStream: CurrentUserStreamService,
        private regionStream: CurrentRegionStreamService,
        private forbiddenFormsService: ForbiddenFormsService
    ) {
        currentUserStream.currentUserWithRoleStream.pipe(filter(Boolean)).subscribe((userInfo: User) => {
            if (userInfo.role === UserRole.ADMIN) {
                this.loadForbiddenForms();
            }
        });
    }

    public loadForbiddenForms = async (): Promise<void> => {
        const regionInfo = this.regionStream.getRegionInfo();
        const forbiddenForms = await this.forbiddenFormsService.getForbiddenForms(
            regionInfo.region,
            regionInfo.projectId
        );
        this.forbiddenFormsStream.next(forbiddenForms);
    };
}
