import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TranslationService {
    constructor(private translate: TranslateService) {}

    /**
     * Sets the default language to use as a fallback
     */
    setDefaultLang(lang: string): void {
        this.translate.setDefaultLang(lang);
    }

    /**
     * Changes the lang currently used
     */
    use(lang: string): Observable<any> {
        return this.translate.use(lang);
    }

    /**
     * Returns a translation instantly from the internal state of loaded translation.
     * All rules regarding the current language, the preferred language of even fallback languages will be used except any promise handling.
     */
    instant(key: string | Array<string>, interpolateParams?: object): string | any {
        if (key === null || key === undefined || key === '') {
            return '';
        }

        return this.translate.instant(key, interpolateParams);
    }
}
