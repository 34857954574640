import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { GspTextType } from 'src/app/shared/common/components/gsp-text/gsp-text.component';
import { MapCache } from 'src/app/shared/common/mapCache/map-cache';
import { MapCacheStreamService } from 'src/app/shared/common/mapCache/map-cache-stream.service';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';

@Component({
    templateUrl: './edit-offline-basemap.component.html',
    selector: 'edit-offline-basemap'
})
export class EditOfflineBasemapComponent implements OnDestroy {
    public mapCache: MapCache = null;
    public originalMapCache: MapCache = null;
    // expose ButtonType enum to template
    public ButtonType = ButtonType;
    public GspTextType = GspTextType;
    private destroyed = new Subject<void>();

    constructor(
        private mapCacheStream: MapCacheStreamService,
        private router: Router,
        private translate: TranslationService
    ) {
        mapCacheStream.currentEditMapCacheStream.pipe(takeUntil(this.destroyed)).subscribe(editedMapCache => {
            this.originalMapCache = CloneUtils.cloneDeep(editedMapCache);
            this.mapCache = editedMapCache;
        });
    }

    public ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    public getErrorText(errors: object): string {
        const isRequiredError = _.filter([errors], 'required');
        if (isRequiredError.length) {
            return this.translate.instant('RequiredError');
        }
        return null;
    }

    private saveMapCache(): Promise<void> {
        return this.mapCacheStream
            .renameMapCache(this.mapCache, this.originalMapCache.name)
            .then(() => {
                this.router.navigate(['mapViewer'], { queryParamsHandling: 'preserve' });
            })
            .catch(() => {
                this.mapCache.name = this.originalMapCache.name;
            });
    }

    public closeEditPanel(): void {
        this.mapCache.name = this.originalMapCache.name;
        this.router.navigate(['mapViewer'], { queryParamsHandling: 'preserve' });
    }

    public save = (): Promise<void> => this.saveMapCache();
}
