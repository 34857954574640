import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { NotificationType } from 'src/app/shared/common/components/gsp-notification/gsp-notification.component';
import { ModalSize } from 'src/app/shared/common/modal-sizes';
import {
    CoordinateSystemComponent,
    CoordinateSystemComponentType,
    GLOBAL_GEOID_ID,
    MapWorkspace,
    coordinateSystemComponentUnits
} from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { MapWorkspaceService } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace.service';
import { CoordinateSystemComponentUnit } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace.types';

@Component({
    selector: 'workspace-coordinate-system-details',
    templateUrl: './workspace-coordinate-system-details.component.html'
})
export class WorkspaceCoordinateSystemDetailsComponent implements OnInit, OnDestroy {
    @Input()
    public workspace: MapWorkspace;
    @Output()
    public workspaceChange: EventEmitter<MapWorkspace> = new EventEmitter();

    @Input()
    public subtitle: '';

    @Input()
    public canEditHeight = false;

    @Input()
    public warningMessageOnHeightChange = '';

    @Output()
    public isLoading: EventEmitter<boolean> = new EventEmitter(false);

    public ModalSize = ModalSize;
    public NotificationType = NotificationType;

    public datum: CoordinateSystemComponent = null;
    public zoneGroup: CoordinateSystemComponent = null;
    public zone: CoordinateSystemComponent = null;
    public geoid: CoordinateSystemComponent = null;
    public horizontalUnit: CoordinateSystemComponentUnit = null;
    public verticalUnit: CoordinateSystemComponentUnit = null;
    public loading = false;
    public heightOptions: { id: string; displayName: string }[];
    public selectedHeightOption: string = null;
    private destroyed = new Subject<void>();

    constructor(private mapWorkspaceService: MapWorkspaceService, private translate: TranslationService) {}

    public async ngOnInit(): Promise<void> {
        this.setLoading(true);
        this.horizontalUnit = _.find(
            coordinateSystemComponentUnits,
            unit => unit.id === this.workspace.coordinateSystem.horizontalUnit
        );
        this.verticalUnit = _.find(
            coordinateSystemComponentUnits,
            unit => unit.id === this.workspace.coordinateSystem.verticalUnit
        );
        const promises: Promise<CoordinateSystemComponent>[] = [
            this.mapWorkspaceService
                .getComponentById(CoordinateSystemComponentType.DATUM, this.workspace.coordinateSystem.datumComponentId)
                .then(datum => (this.datum = datum))
        ];

        if (!this.workspace.noProjection()) {
            promises.push(
                this.mapWorkspaceService
                    .getComponentById(
                        CoordinateSystemComponentType.ZONE_GROUP,
                        this.workspace.coordinateSystem.zoneGroupComponentId
                    )
                    .then(zg => (this.zoneGroup = zg))
            );
            promises.push(
                this.mapWorkspaceService
                    .getComponentById(
                        CoordinateSystemComponentType.ZONE,
                        this.workspace.coordinateSystem.zoneComponentId
                    )
                    .then(zone => (this.zone = zone))
            );
        }

        if (this.workspace.coordinateSystem.geoidComponentId) {
            promises.push(
                this.mapWorkspaceService
                    .getComponentById(
                        CoordinateSystemComponentType.GEOID,
                        this.workspace.coordinateSystem.geoidComponentId
                    )
                    .then(geoid => (this.geoid = geoid))
            );
        }

        await Promise.all(promises);
        if (this.canEditHeight) {
            await this.populateHeightOptions();
        }
        this.setLoading(false);
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    public onHeightChange(height: string): void {
        this.workspace.coordinateSystem.geoidComponentId = height || null;
        // zoneComponentID|datumComponentID|geoidComponentID|horizontalUnit|verticalUnit
        const coordSystemParts = this.workspace.coordinateSystem.cscmResourceId.split('|');
        coordSystemParts[2] = height;
        this.workspace.coordinateSystem.cscmResourceId = coordSystemParts.join('|');
        this.workspaceChange.emit(this.workspace);
    }

    private setLoading(isLoading: boolean): void {
        this.loading = isLoading;
        this.isLoading.emit(isLoading);
    }

    private async populateHeightOptions(): Promise<void> {
        this.selectedHeightOption = this.geoid?.componentID || '';
        const globalGeoid =
            this.geoid?.componentID != GLOBAL_GEOID_ID
                ? await this.mapWorkspaceService.getComponentById(CoordinateSystemComponentType.GEOID, GLOBAL_GEOID_ID)
                : this.geoid;
        const noGeoidDisplayName = this.translate.instant('MapViewer.Workspace.CoordinateSystem.AboveSeaLevel.Option');
        this.heightOptions = [
            {
                id: this.selectedHeightOption,
                displayName: this.geoid?.displayName || noGeoidDisplayName
            }
        ];
        if (this.selectedHeightOption != GLOBAL_GEOID_ID) {
            this.heightOptions.push({
                id: GLOBAL_GEOID_ID,
                displayName: globalGeoid.displayName
            });
        }
        if (!!this.selectedHeightOption) {
            this.heightOptions.push({
                id: '',
                displayName: noGeoidDisplayName
            });
        }
    }
}
