import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    CurrentTabStreamService,
    TemplateEditorTab,
    TemplateEditorTabId,
} from 'src/app/feature/template-editor/tabs-list/current-tab-stream.service';
import { AutoFieldModelType, Field } from 'src/app/shared/template-services/field';
import { Template } from 'src/app/shared/template-services/template';

@Component({
    selector: 'template-update',
    templateUrl: './template-update.component.html'
})
export class TemplateUpdateComponent implements OnDestroy, OnInit {
    private static autofields = [
        AutoFieldModelType.CollectedBy,
        AutoFieldModelType.CreationDateTime,
        AutoFieldModelType.UpdateDateTime,
        AutoFieldModelType.EstimatedAccuracy,
        AutoFieldModelType.DeviceType,
        AutoFieldModelType.DeviceID,
        AutoFieldModelType.CorrectionStatus,
        AutoFieldModelType.CorrectionSource,
        AutoFieldModelType.GeometryCaptureType,
        AutoFieldModelType.PDOP,
        AutoFieldModelType.HDOP,
        AutoFieldModelType.Todo,
        AutoFieldModelType.EstimatedVerticalPrecision,
        AutoFieldModelType.Workspace,
        AutoFieldModelType.Increment,
        AutoFieldModelType.GeometryArea,
        AutoFieldModelType.GeometryLength,
        AutoFieldModelType.XPosition,
        AutoFieldModelType.YPosition,
        AutoFieldModelType.ZPosition
    ];

    private destroyed = new Subject<void>();

    @Input()
    field: Field;

    @Input()
    isSelected: boolean;

    @Input()
    template: Template;

    isAutoField: boolean;
    currentTab: TemplateEditorTab;

    // exposing enum to template
    public TemplateEditorTabId = TemplateEditorTabId;

    constructor(private currentTabStream: CurrentTabStreamService) {}

    ngOnInit(): void {
        this.currentTabStream.currentTab$.pipe(takeUntil(this.destroyed)).subscribe(currentTab => {
            this.currentTab = currentTab;
        });
        this.isAutoField = TemplateUpdateComponent.autofields.indexOf(this.field.type as AutoFieldModelType) !== -1;
    }

    toggleRequiredForUpdate(): void {
        if (this.currentTab.id !== TemplateEditorTabId.RULE) {
            this.template.updateDataUpdateFields(this.field, 'requiredForUpdate', 'readOnlyForUpdate');
        }
    }

    toggleReadOnlyForUpdate(): void {
        if (this.currentTab.id !== TemplateEditorTabId.RULE) {
            this.template.updateDataUpdateFields(this.field, 'readOnlyForUpdate', 'requiredForUpdate');
        }
    }

    toggleVisibleForSitevision(): void {
        if (this.currentTab.id !== TemplateEditorTabId.RULE) {
            this.template.updateDataUpdateFields(this.field, 'visibleForSitevision', null);
        }
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }
}
