import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { FeaturesStreamsService } from 'src/app/shared/common/current-features/features-streams.service';
import { SelectionCriteria } from 'src/app/shared/common/current-features/selection-criteria';
import { Feature } from 'src/app/shared/map-data-services/feature/feature';

import { ChartPanelStreamService } from '../common/chart-panel-stream.service';
import { SelectionToolIdUtils, SelectionToolStream as SelectionToolStreams } from '../common/selection-tool-stream.service';
import {
    MapDrawActionSource,
    MapDrawActionsStreamService,
} from '../map-container/map-draw-actions/map-draw-action-stream.service';
import { SelectionAction } from '../toolbar/mapToolbar/selectionTools/selection-tool.component';
import { MapSelectionStreamService } from './selection-stream.service';

@Component({
    selector: 'map-selection',
    template: ''
})
export class MapSelectionComponent implements OnInit, OnDestroy {
    @Input()
    map: L.Map;

    private readonly destroyed = new Subject<void>();

    constructor(
        private mapActionsStream: MapDrawActionsStreamService,
        private selectionToolStreams: SelectionToolStreams,
        private featuresStreams: FeaturesStreamsService,
        private selectionStream: MapSelectionStreamService,
        private chartPanelStreamService: ChartPanelStreamService,
        private messaging: MessagingService,
        private translate: TranslationService
    ) {}

    ngOnInit(): void {
        this.mapActionsStream.mapDrawActionResultStream
            .pipe(takeUntil(this.destroyed))
            .subscribe(mapDrawActionResult => {
                if (
                    mapDrawActionResult &&
                    mapDrawActionResult.source === MapDrawActionSource.SELECTION_TOOL &&
                    mapDrawActionResult.result
                ) {
                    let bounds: L.LatLngBounds | L.Polygon = mapDrawActionResult.result;
                    this.updateFeatureStream(bounds);
                }
            });

        this.selectionStream.stream.pipe(takeUntil(this.destroyed)).subscribe(selectionCriteria =>
            this.updateFeatureStream(selectionCriteria, SelectionAction.NEW, (features: Feature[]) => {
                if (!features?.length) {
                    this.messaging.showSuccess(this.translate.instant('TC.Common.NoFormsInLayer'));
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    private updateFeatureStream(
        bounds: SelectionCriteria,
        selectionAction?: SelectionAction,
        callback?: (features: Feature[]) => void
    ): void {
        selectionAction =
            selectionAction ||
            SelectionToolIdUtils.getActionFromSelectionToolId(
                this.selectionToolStreams.selectionToolSelectedStream.getValue()
            );
        this.chartPanelStreamService.previousSelectedFeatures = null;
        switch (selectionAction) {
            case SelectionAction.NEW:
                this.featuresStreams.replaceOnSelectedFeatures(bounds, callback);
                break;
            case SelectionAction.APPEND:
                this.featuresStreams.addToSelectedFeatures(bounds, callback);
                break;
            case SelectionAction.REMOVE:
                this.featuresStreams.removeFromSelectedFeatures(bounds, callback);
                break;
        }
    }
}
