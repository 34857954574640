<loading *ngIf="isLoadingRecentExports" class="loading-align-center"></loading>
<div class="recent-exports">
    <div class="flex7-new" *ngIf="!exportsData?.length && !isLoadingRecentExports">
        <li class="recent-exports-message">{{ 'TC.Export.NoRecentExports' | translate }}</li>
    </div>
    <div class="flex7-new" *ngIf="exportsData?.length">
        <custom-scroll>
            <ng-container *ngFor="let export of exportsData">
                <div class="recent-exports-item">
                    <div class="left-container">
                        <span class="recent-exports-item-first-row">
                            <h4 class="sub-heading font-title" title="{{ export.name }}">{{ export.name }}</h4>
                            <span
                                class="recent-exports-item-timeanduser"
                                title="{{ export.createdUtc + '.000Z' | date: 'd MMM yy h:mm a' }}
                                        {{ 'TC.Common.By' | translate }} {{ export.createdBy }}"
                            >
                                {{ export.createdUtc + '.000Z' | date: 'd MMM yy h:mm a' }}
                                {{ 'TC.Common.By' | translate }} {{ export.createdBy }}
                            </span>
                        </span>

                        <span class="recent-exports-item-second-row">
                            <span class="recent-exports-item-info">
                                <b>{{ 'status' | translate }}</b> :
                                <span [ngClass]="{ 'complete-status': export.status === 'Complete' }">
                                    {{
                                        (export.status === 'Complete'
                                            ? recentExportStatus['Complete']
                                            : recentExportStatus['InProgress']
                                        ) | translate
                                    }}
                                </span>
                            </span>
                            <span class="recent-exports-item-info">
                                <b>{{ 'TC.Common.Format' | translate }} </b>:
                                {{ reverseFileTypesDict[export.exportFormat] }}
                            </span>
                            <ng-container *ngIf="export.status === 'Complete'">
                                <span class="recent-exports-item-info">
                                    <b>{{ 'TC.Common.Features' | translate }} </b>: {{ export.featuresCount }}
                                </span>
                                <span class="recent-exports-item-info">
                                    <b>{{ 'TC.Common.Size' | translate }} </b>:
                                    {{ formatFileSize(export.fileSizeinBytes) }}
                                </span>
                            </ng-container>
                        </span>
                    </div>
                    <div class="right-container" *ngIf="!isDownloadDisabled(export.status)">
                        <div class="recent-exports-item-downloadbutton">
                            <gsp-button
                                buttonTooltip="{{ 'TC.Export.DownloadExportTooltip' | translate }}"
                                [asyncAction]="downloadButtonAction.bind(this, [export.id])"
                                [type]="ButtonType.Primary"
                                text="{{ 'FileTree.Download' | translate }}"
                                [iconClass]=""
                            ></gsp-button>
                        </div>
                        <div class="recent-exports-item-validuntil-text">
                            <i>
                                {{ 'TC.Export.ValidUntil' | translate }}
                                {{ export.downloadExpireDate + '.000Z' | date: 'd MMM yy h:mm a' }}
                            </i>
                        </div>
                    </div>
                </div>
            </ng-container>
        </custom-scroll>
    </div>
</div>
