<div class="announcement-banner-container flex-column width-full" #announcementBanner>
    <div class="announcement-banner" *ngFor="let announcement of announcements">
        <div class="announcement">
            <div class="announcement-content">
                {{ announcement.announcementContent }}
            </div>
            <div *ngIf="announcement.announcementLink" class="announcement-link">
                <a (click)="goToLink(announcement.announcementLink)"
                    >{{ 'MapViewer.AnnouncementBanner.AnnouncementLink.Placeholder' | translate }} ></a
                >
            </div>
        </div>
        <div class="close-notification">
            <gsp-button
                [type]="ButtonType.Icon"
                (action)="dismissAnnouncement(announcement.announcementId)"
                [iconClass]="'icon_line_cancel_white_24 i24'"
            ></gsp-button>
        </div>
    </div>
</div>
