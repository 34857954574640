import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { CurrentUserStreamService } from 'src/app/shared/common/current-user/current-user-stream.service';
import { CBSListService } from 'src/app/shared/map-data-services/cbs-list/cbs-list.service';
import {
    CoordinateSystemComponentType,
    MapWorkspace,
    WGS1984_DATUM_COMPONENT_ID,
} from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { MapWorkspacePermissionType } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace-permission';
import { MapWorkspaceService } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace.service';
import { UserRole } from 'src/app/shared/user/user';

export enum PostProcessingStatus {
    UNKNOWN = 'Unknown',
    UNPROCESSED = 'Unprocessed',
    PENDING = 'Pending',
    PROCESSED = 'Processed',
    FAILED = 'Failed'
}

@Component({
    selector: 'post-processing-options',
    templateUrl: './post-processing-options.component.html'
})
export class PostProcessingOptionsComponent implements OnInit, OnDestroy {
    // expose to template
    public MapWorkspacePermissionType = MapWorkspacePermissionType;
    public ButtonType = ButtonType;

    @Input()
    public cannotEnableMessageKey = 'MapViewer.OfflineGNSS.CannotEnable';

    @Input()
    public disableForLockedWorkspaces = false;

    @Input()
    public enablePopup = false;

    @Input()
    public infoMessage = '';

    @Input()
    public toggleLabel = '';

    @Input()
    public offlineGNSSDescription: '';

    @Input()
    public workspace: MapWorkspace = null;

    @Input()
    public updateInProgress = false;

    @Output()
    public toggled = new EventEmitter<boolean>();

    @Output()
    public popupDone = new EventEmitter<void>();

    @Output()
    private initialized = new EventEmitter<void>();

    public accessPointsAvailable = false;
    public currentUserIsAdmin = false;
    public loading = true;
    public popupHeading: string;
    public popupMessage: string;
    public popupButton: string;
    private destroyed$: Subject<void> = new Subject<void>();
    public showMoreInfo = false;

    constructor(
        private currentUserStream: CurrentUserStreamService,
        private cbsListService: CBSListService,
        private mapWorkspaceService: MapWorkspaceService
    ) {}

    async ngOnInit(): Promise<void> {
        this.loading = true;

        this.currentUserStream.currentUserWithRoleStream.pipe(takeUntil(this.destroyed$)).subscribe(async user => {
            if (user) {
                this.currentUserIsAdmin = user.role === UserRole.ADMIN;
            }
        });

        try {
            if (!this.workspace.hasBounds()) {
                this.popupHeading = 'MapViewer.OfflineGNSS.CannotEnable';
                this.popupMessage = 'MapViewer.OfflineGNSS.LeadToSetWorkspaceLocation';
                this.popupButton = 'MapViewer.Generic.SetWorkspaceLocation';
            } else {
                if (!this.workspace.postProcessingOptions?.locked) {
                    this.popupHeading = 'MapViewer.OfflineGNSS.CannotEnable';
                    this.popupMessage = 'MapViewer.OfflineGNSS.CannotEnableExplanation';
                    const { datumComponentId, localReferenceFrameComponentId } = this.workspace.coordinateSystem;
                    const datum = await this.mapWorkspaceService.getComponentById(
                        CoordinateSystemComponentType.DATUM,
                        localReferenceFrameComponentId || datumComponentId
                    );
                    const { latitudeNorth, latitudeSouth, longitudeEast, longitudeWest } = this.workspace.bounds;
                    const latitude = (latitudeNorth + latitudeSouth) / 2;
                    const longitude = (longitudeEast + longitudeWest) / 2;
                    // Don't provide referenceFrame if datum is WGS84 as there are very few base stations
                    // with reference positions in WGS84.
                    const accessPoints = await this.cbsListService.getAccessPoints(
                        latitude,
                        longitude,
                        datum.componentID !== WGS1984_DATUM_COMPONENT_ID ? datum.displayName : null
                    );

                    this.accessPointsAvailable = !!accessPoints.length;
                } else {
                    this.popupHeading = 'MapViewer.OfflineGNSS.LockedHeading';
                    this.popupMessage = 'MapViewer.OfflineGNSS.LockedExplanation';
                }
                this.popupButton = 'MapViewer.Generic.GotIt';
            }
        } catch (err) {
            throw err;
        } finally {
            this.loading = false;
            this.initialized.emit();
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next(null);
    }

    public closePopup = () => (this.showMoreInfo = false);
    public openPopup = () => (this.showMoreInfo = true);
    public onDone(): void {
        this.closePopup();
        this.popupDone.emit();
    }
}
