import { Component, OnInit } from '@angular/core';
import { GspWizardService, OnForward } from 'src/app/shared/common/components/gsp-wizard/gsp-wizard.service';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';

import { WorkspaceService } from '../workspace.service';

@Component({
    selector: 'workspace-creation-name',
    templateUrl: './workspace-creation-name.component.html'
})
export class WorkspaceCreationNameComponent implements OnInit, OnForward {
    public modifyWorkspace: MapWorkspace;
    public uiState = {
        backButton: { text: '', enabled: false, visible: false },
        forwardButton: { text: 'TC.Common.Next', enabled: false, visible: true },
        closeButton: { text: '', enabled: true, visible: true },
        cancelButton: { text: 'cancel', enabled: true, visible: true }
    };

    constructor(private workspaceCreationService: WorkspaceService, private wizardService: GspWizardService) {}

    ngOnInit(): void {
        this.wizardService.setUI(this.uiState);
        this.modifyWorkspace = this.workspaceCreationService.modifyWorkspace$.getValue();
    }

    public onValidationChange(isValid: boolean): void {
        this.uiState.forwardButton.enabled = isValid ? true : false;
        this.wizardService.setUI(this.uiState);
    }

    public onForward(): Promise<void> {
        return new Promise<void>(resolve => {
            this.workspaceCreationService.modifyWorkspace$.next(this.modifyWorkspace);
            resolve();
        });
    }
}
