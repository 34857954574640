<div class="upload-status" *ngIf="inProgressCount > 0" [ngStyle]="{ height: showScroll ? '188px' : 'auto' }">
    <div class="active-upload-title">
        <h2>{{ 'MapViewer.UploadStatus.FIlesProcessingMessage' | translate }}</h2>
    </div>
    <custom-scroll *ngIf="showScroll; else fileList">
        <ng-container *ngTemplateOutlet="fileList"></ng-container>
    </custom-scroll>
</div>

<ng-template #fileList>
    <ul class="upload-file-list">
        <li class="text-overflow" title="{{ file.value.fileName }}" *ngFor="let file of inProgressFiles | keyvalue">
            {{ file.value.fileName }}

            <div class="file-icon-and-progress-bar">
                <i
                    *ngIf="isFileFailed(file.value.status)"
                    class="icon i16 icon_line_x"
                    (click)="removeFileFromList(file.key)"
                ></i>
                <mat-progress-bar
                    *ngIf="isFileFailed(file.value.status); else fileInProgress"
                    class="progress-error"
                    mode="determinate"
                    value="100"
                ></mat-progress-bar>
            </div>
        </li>
    </ul>
</ng-template>

<ng-template #fileInProgress>
    <mat-progress-bar class="progress-success" mode="indeterminate"> </mat-progress-bar>
</ng-template>
