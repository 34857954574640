<div class="password-wrapper" [errorTooltip]="errorTooltipMsg">
    <input
        #inputRef="ngModel"
        [type]="isPasswordVisible ? 'text' : 'password'"
        id="{{ id }}"
        class="{{ inputClass }}"
        [ngClass]="inputNgClass"
        [name]="name"
        [placeholder]="placeholder"
        [title]="title"
        [(ngModel)]="value"
        (ngModelChange)="valueChange.emit(value)"
        [required]="required"
        [gsp-focus]="focusElement"
    />
    <i
        class="i24 visibility-toggle"
        [ngClass]="{ icon_line_visibility_show: !isPasswordVisible, icon_line_visibility_hide: isPasswordVisible }"
        (click)="togglePasswordVisibility()"
    ></i>
</div>
