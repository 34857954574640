import { ConditionOperator } from './condition';

export class DateSubCondition {
    constructor(
        public connectingOperator = ConditionOperator.NONE,
        public operator = ConditionOperator.NONE,
        public comparisonValue?: string,
        public dummyConnectingOperator = ConditionOperator.NONE,
        public daysAgoComparisonValue: string = null
    ) {}
}
