import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Rule } from 'src/app/shared/template-services/rule/rule';

@Injectable({
    providedIn: 'root'
})
export class RuleSelectionStreamService {
    ruleSelectionStream = new BehaviorSubject<Rule>(null);
}
