import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { ForbiddenFormsService } from 'src/app/shared/map-data-services/forbidden-forms/forbidden-forms.service';
import { User, UserRole } from 'src/app/shared/user/user';
import { UserService } from 'src/app/shared/user/user.service';

import { ForbiddenFormsStreamService } from '../../current-forbidden-forms/forbidden-forms-stream.service';
import { CurrentRegionStreamService, IRegionInfo } from '../../current-region/region-stream.service';
import { LoaderStreamService } from '../../loader/loader-stream.service';
import { ModalSize } from '../../modal-sizes';
import { ButtonType } from '../buttons/button';
import { ForbiddenFormsNotification } from '../gsp-notification-tab/gsp-notification-tab.component';

@Component({
    selector: 'gsp-forbidden-forms',
    templateUrl: './gsp-forbidden-forms.component.html'
})
export class GspForbiddenFormsComponent implements OnInit {
    @Input()
    notification: ForbiddenFormsNotification;

    @Output()
    closed = new EventEmitter();

    // exposing enum to template
    public ModalSize = ModalSize;
    public ButtonType = ButtonType;

    public isLoading = false;

    private currentRegionInfo: IRegionInfo;

    public userInfo: User;

    constructor(
        private regionStreamService: CurrentRegionStreamService,
        private userService: UserService,
        private forbiddenFormsService: ForbiddenFormsService,
        private loadingStream: LoaderStreamService,
        private messagingService: MessagingService,
        private translationService: TranslationService,
        private forbiddenFormsStream: ForbiddenFormsStreamService
    ) {}

    ngOnInit(): void {
        this.currentRegionInfo = this.regionStreamService.getRegionInfo();
        // this.userService
        //     .getConnectUser(this.notification.userId)
        //     .then(userInfo => {
        //         this.userInfo = userInfo;
        //     })
        //     .finally(() => {
        //         this.isLoading = false;
        //     });
    }

    closePanel(): void {
        this.closed.emit();
    }

    importForms(): void {
        this.loadingStream.isLoading$.next(true);
        this.forbiddenFormsService
            .acceptForbiddenForms(
                this.currentRegionInfo.region,
                this.currentRegionInfo.projectId,
                this.notification.userId
            )
            .then(() => {
                this.messagingService.showInfo(
                    this.translationService.instant('MapViewer.ForbiddenForms.Actions.ImportForms.Success')
                );
            })
            .catch(e => {
                this.messagingService.showError(
                    this.translationService.instant('MapViewer.ForbiddenForms.Actions.ImportForms.Error')
                );
            })
            .finally(async () => {
                await this.forbiddenFormsStream.loadForbiddenForms();
                this.loadingStream.isLoading$.next(false);
                this.closePanel();
            });
    }

    exportForms(): void {
        this.loadingStream.isLoading$.next(true);
        this.forbiddenFormsService
            .exportForbiddenForms(
                this.currentRegionInfo.region,
                this.currentRegionInfo.projectId,
                this.notification.userId
            )
            .then(() => {
                this.messagingService.showInfo(
                    this.translationService.instant('MapViewer.ForbiddenForms.Actions.ExportForms.Success')
                );
            })
            .catch(e => {
                this.messagingService.showError(
                    this.translationService.instant('MapViewer.ForbiddenForms.Actions.ExportForms.Error')
                );
            })
            .finally(() => {
                this.loadingStream.isLoading$.next(false);
                this.closePanel();
            });
    }

    reInviteUser(): void {
        this.loadingStream.isLoading$.next(true);
        this.userService
            .inviteUserToProject(this.currentRegionInfo.projectId, this.userInfo.email, UserRole.USER)
            .then(() => {
                this.messagingService.showInfo(
                    this.translationService.instant('MapViewer.ForbiddenForms.Actions.ReInviteUser.Success')
                );
                this.importForms();
            })
            .catch(e => {
                this.messagingService.showError(
                    this.translationService.instant('MapViewer.ForbiddenForms.Actions.ReInviteUser.Error')
                );
            })
            .finally(() => {
                this.loadingStream.isLoading$.next(false);
                this.closePanel();
            });
    }

    rejectForms(): void {
        this.loadingStream.isLoading$.next(true);
        this.forbiddenFormsService
            .rejectForbiddenForms(
                this.currentRegionInfo.region,
                this.currentRegionInfo.projectId,
                this.notification.userId
            )
            .then(() => {
                this.messagingService.showInfo(
                    this.translationService.instant('MapViewer.ForbiddenForms.Actions.DeleteForms.Success')
                );
            })
            .catch(e => {
                this.messagingService.showError(
                    this.translationService.instant('MapViewer.ForbiddenForms.Actions.DeleteForms.Error')
                );
            })
            .finally(async () => {
                await this.forbiddenFormsStream.loadForbiddenForms();
                this.loadingStream.isLoading$.next(false);
                this.closePanel();
            });
    }
}
