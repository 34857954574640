import { Injectable } from '@angular/core';
import { shareReplay } from 'rxjs/operators';

import { RtcStore } from './rtc-store.service';

@Injectable({
    providedIn: 'root'
})
export class RtcStreamService {
    // --------------------------------------
    public rtcListStream = this.rtcStore.rtcStream.pipe(shareReplay(1));

    public rtcListLoadingStream = this.rtcStore.rtcLoadingStream.pipe(shareReplay(1));

    // --------------------------------------
    constructor(private rtcStore: RtcStore) {}
}
