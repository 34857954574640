import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslationModule } from 'src/app/core/translation/translation.module';
import { CommonModule as AppCommonModule } from 'src/app/shared/common/common.module';

import { ExportDownloaderRoutingModule } from './export-downloader-routing.module';
import { ExportDownloaderComponent } from './export-downloader.component';
import { ExportDownloaderService } from './export-downloader.service';

@NgModule({
    declarations: [ExportDownloaderComponent],
    exports: [ExportDownloaderComponent],
    imports: [CommonModule, AppCommonModule, TranslationModule, ExportDownloaderRoutingModule],
    providers: [ExportDownloaderService]
})
export class ExportDownloaderModule {}
