<div class="feature-field">
    <label class="feature-label" [ngClass]="{ disabled: field.value == null }">
        {{ field.displayName }}
        <span *ngIf="field.required">*</span>
    </label>
    <div class="feature-data" *ngIf="!editMode && displayYesNo(field.value)">
        <ul class="choice-list">
            <li>
                <i class="icon-24-dark-radio-selected"></i><label>{{ displayYesNo(field.value) | translate }}</label>
            </li>
        </ul>
    </div>
    <div class="editable-control" *ngIf="editMode">
        <!-- TODO: GIM: 30/5/2019 - we're not inside an iterator, but id's and names in ng1 had the suffix including  $index  ... check if we need to pass in an index @Input -->
        <div class="yes-no-field">
            <gsp-radio
                id="{{ field.name }}-1"
                [(ngModel)]="field.value"
                radioLabelClass="radio-label-wrap"
                radioInputClass="radio-input"
                radioButtonClass="dark-radio-button"
                name="{{ field.name }}"
                valueText="{{ 'none' | translate }}"
                [radioValue]="null"
            ></gsp-radio>
        </div>
        <div class="yes-no-field">
            <gsp-radio
                id="{{ field.name }}-2"
                [(ngModel)]="field.value"
                radioLabelClass="radio-label-wrap"
                radioInputClass="radio-input"
                radioButtonClass="dark-radio-button"
                name="{{ field.name }}"
                class="ng-pristine ng-valid"
                valueText="{{ 'yes' | translate }}"
                [radioValue]="true"
            ></gsp-radio>
        </div>
        <div class="yes-no-field">
            <gsp-radio
                id="{{ field.name }}-3"
                [(ngModel)]="field.value"
                radioLabelClass="radio-label-wrap"
                radioInputClass="radio-input"
                radioButtonClass="dark-radio-button"
                name="{{ field.name }}"
                valueText="{{ 'no' | translate }}"
                [radioValue]="false"
            ></gsp-radio>
        </div>
    </div>
</div>
