import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BooleanConverter, InputConverter } from 'src/app/shared/common/components/input-converter.decorator';
import { ValueAccessorBase } from 'src/app/shared/common/components/value-accessor-base';
import { SchemaFieldType } from 'src/app/shared/template-services/field/fieldDefinition';

@Component({
    selector: 'multilist-field',
    templateUrl: './multilist-field.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultilistFieldComponent),
            multi: true
        }
    ]
})
export class MultilistFieldComponent extends ValueAccessorBase<any> {
    @Input()
    items: any[];

    @Input()
    type: SchemaFieldType.RADIO | SchemaFieldType.DROPDOWN = SchemaFieldType.DROPDOWN;

    @Input()
    label: string;

    @Input()
    name: string;

    @Input()
    @InputConverter(BooleanConverter)
    isEditable = false;

    // exposing enum to template
    public SchemaFieldType = SchemaFieldType;

    constructor() {
        super();
    }
}
