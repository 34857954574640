<div class="field-name {{ extraClass }}">
    <input
        type="text"
        [name]="name + 'textInput'"
        gsp-focus
        class="gsp-form-input default"
        (click)="$event.stopPropagation()"
        [(ngModel)]="value"
        required
        *ngIf="isEditable && !locked"
    />
    <div class="field-name-label" [title]="value" *ngIf="!isEditable || locked">
        {{ value }}
    </div>
</div>
