import * as _ from 'lodash-es';
import { TranslationService } from 'src/app/core/translation/translation.service';

import { ChoiceValue, CodedChoiceValue } from '../field';
import { Condition, ConditionOperator } from './condition';

export interface ChoiceCondtionOption {
    name: string;
    value: string;
}

export class ChoiceCondition extends Condition {
    static readonly choiceConditionsTextSingle: ChoiceCondtionOption[] = [
        {
            name: 'TC.Common.IsAnswered',
            value: ConditionOperator.IS_ANSWERED
        },
        {
            name: 'TC.Common.ExactlyMatches',
            value: ConditionOperator.EXACTLY_MATCHES
        },
        {
            name: 'TC.Common.IsAnyOf',
            value: ConditionOperator.IS_HAS_ANY_OF
        },
        {
            name: 'TC.Common.IsNoneOf',
            value: ConditionOperator.IS_HAS_NONE_OF
        }
    ];

    // This is different from choiceCondtionTextSingle - please see diff on `names`
    static readonly choiceConditionsTextMultiple: ChoiceCondtionOption[] = [
        {
            name: 'TC.Common.IsAnswered',
            value: ConditionOperator.IS_ANSWERED
        },
        {
            name: 'TC.Common.ExactlyMatches',
            value: ConditionOperator.EXACTLY_MATCHES
        },
        {
            name: 'TC.Common.HasAnyOf',
            value: ConditionOperator.IS_HAS_ANY_OF
        },
        {
            name: 'TC.Common.HasNoneOf',
            value: ConditionOperator.IS_HAS_NONE_OF
        }
    ];

    // Invoked on a public function called getConditionPair() below
    static getConditionPair(multiple: boolean): ChoiceCondtionOption[] {
        return multiple ? ChoiceCondition.choiceConditionsTextMultiple : ChoiceCondition.choiceConditionsTextSingle;
    }

    choiceConditionsText: ChoiceCondtionOption[];
    multiple = true;
    currentChoiceFieldValues: ChoiceValue[] | CodedChoiceValue[];
    fieldAllowMultiSelect: boolean;

    constructor(
        uuid: string,
        type: string,
        public operator = ConditionOperator.IS_ANSWERED,
        public comparisonValues: string[] = [],
        public compareOther = false
    ) {
        super(uuid, type);
    }

    getErrorMessagesNo(): number[] {
        return [];
    }

    populateChoiceFromFields(
        choiceFieldValues: ChoiceValue[] | CodedChoiceValue[],
        ruleNo: number,
        forceElse: boolean
    ): void {
        choiceFieldValues.forEach((value, index) => {
            const valueText = (value as ChoiceValue).text;
            const valueCode = (value as CodedChoiceValue).code;

            if (value.choiceSelected && value.choiceSelected[ruleNo] !== undefined && !forceElse) {
                if (index === 0) {
                    this.comparisonValues = [];
                }
                if (value.choiceSelected[ruleNo]) {
                    if (valueText) {
                        this.comparisonValues.push(valueText);
                    } else {
                        this.comparisonValues.push(valueCode);
                    }
                }
            } else {
                if (value.choiceSelected === undefined) {
                    value.choiceSelected = [];
                }

                if (valueText) {
                    value.choiceSelected[ruleNo] = this.comparisonValues.indexOf(valueText) !== -1;
                } else {
                    value.choiceSelected[ruleNo] = this.comparisonValues.indexOf(valueCode) !== -1;
                }
            }
        });
    }

    // Add comparison text to the list of comparison values for this condition.
    addComparisonValue(comparisonText: string): void {
        if (this.comparisonValues.indexOf(comparisonText) === -1) {
            this.comparisonValues.push(comparisonText);
        }
    }

    // Remove comparison text from the list of comparison values for this condition.
    removeComparisonValue(comparisonText: string): void {
        this.comparisonValues.splice(this.comparisonValues.indexOf(comparisonText), 1);
    }

    // Decision to add or remove the comparison values from the list of comparison values.
    addOrRemoveValue(
        textOrCode: string,
        add: boolean,
        ruleNo: number,
        choiceFieldValues: ChoiceValue[] | CodedChoiceValue[],
        choiceField: boolean[]
    ): void {
        if (add) {
            this.resetOptionIncludingOther(ruleNo, choiceFieldValues);
            choiceField[ruleNo] = true;
            this.addComparisonValue(textOrCode);
        } else {
            choiceField[ruleNo] = false;
            this.removeComparisonValue(textOrCode);
        }
    }

    // Disable all the selected option (excluding others)
    resetOptions(ruleNo: number, choiceFieldValues: ChoiceValue[] | CodedChoiceValue[]): void {
        if (this.multiple || this.operator !== ConditionOperator.EXACTLY_MATCHES) {
            return;
        }
        this.comparisonValues = [];
        choiceFieldValues.forEach(value => {
            if (value.choiceSelected) {
                value.choiceSelected[ruleNo] = false;
            }
        });
    }

    // Disable all the selected option (including others)
    resetOptionIncludingOther(ruleNo: number, choiceFieldValues: ChoiceValue[] | CodedChoiceValue[]): void {
        if (!this.multiple && this.operator === ConditionOperator.EXACTLY_MATCHES) {
            this.compareOther = false;
        }
        this.resetOptions(ruleNo, choiceFieldValues);
    }

    // Get the condition text which is displayed in sidebar
    getConditionText(translator: TranslationService, value: string): string {
        value = value || this.operator;
        let conditionPair = _.find(
            this.getConditionPair(this.multiple),
            choiceCondition => choiceCondition.value === value
        );

        const isCodedChoice = this.currentChoiceFieldValues
            ? this.currentChoiceFieldValues[0].hasOwnProperty('code')
            : false;

        const choiceDescriptionEquivalent: string[] = [];

        if (isCodedChoice) {
            const codedChoiceDict = (this.currentChoiceFieldValues as CodedChoiceValue[]).reduce(
                (acc: { [code: string]: string }, codedChoice) => {
                    acc[codedChoice.code] = codedChoice.description;
                    return acc;
                },
                {}
            );
            this.comparisonValues.forEach(compVal => {
                choiceDescriptionEquivalent.push(codedChoiceDict[compVal]);
            });
        }

        let result: string;
        if (this.operator === ConditionOperator.IS_ANSWERED) {
            result = translator.instant(conditionPair.name);
        } else {
            result = isCodedChoice
                ? translator.instant(conditionPair.name) + ' ' + choiceDescriptionEquivalent.join(', ')
                : translator.instant(conditionPair.name) + ' ' + this.comparisonValues.join(', ');
            if (this.compareOther) {
                result += this.comparisonValues.length
                    ? this.operator === ConditionOperator.IS_HAS_NONE_OF
                        ? ' And NOT Other'
                        : ' Or Other'
                    : ' Other';
            }
        }
        return result;
    }

    // Returns the list of operators used for this condition.
    getConditionPair(allowMultiSelect?: boolean): ChoiceCondtionOption[] {
        this.multiple = allowMultiSelect;
        return ChoiceCondition.getConditionPair(this.multiple);
    }
}
