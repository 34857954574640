import { Type } from '@angular/core';
import { WorkspacePanelComponent } from 'src/app/feature/workspace/workspace-panel.component';

import { PositioningComponent } from './positioning.component';

export class MenuItem {
    id: number;
    name: string;
    displayName: string;
    iconClass: string;
    className: string;
    iconName: string;
    link: any[];
    component: Type<any>;
    externalLink: boolean;
    active: boolean;
}

export const mainMenuList: MenuItem[] = [
    {
        id: 1,
        name: 'Workspaces',
        displayName: 'TC.Common.MapWorkspaces',
        iconClass: 'icon_solid_space',
        className: 'spaces',
        iconName: 'TC.Common.Maps',
        link: ['mapViewer', { outlets: { mainMenu: 'workspaces' } }],
        component: WorkspacePanelComponent,
        externalLink: false,
        active: true
    },
    {
        id: 2,
        name: 'Positioning',
        displayName: 'TC.Common.Positioning',
        iconClass: 'icon_line_positioning',
        className: 'positioning',
        iconName: 'TC.Common.Positioning',
        link: ['mapViewer', { outlets: { mainMenu: 'positioning' } }],
        component: PositioningComponent,
        externalLink: false,
        active: false
    },
    {
        id: 3,
        name: 'Settings',
        displayName: 'Settings',
        className: 'settings',
        iconClass: 'icon_solid_settings i32',
        iconName: 'settings',
        link: null,
        component: null,
        externalLink: true,
        active: false
    }
];
