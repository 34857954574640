import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, skipWhile, switchMap } from 'rxjs';
import { FileViewerImportService } from 'src/app/feature/import/file-viewer/fileviewer-import.service';
import { FeatureMapLayersService } from 'src/app/feature/map-viewer/map-container/feature-map-layers/feature-map-layers.service';
import { CachedFeature, CachedFeatureService } from 'src/app/shared/map-data-services/feature/cached-feature.service';
import { ActiveFeature, Feature } from 'src/app/shared/map-data-services/feature/feature';
import { FeatureService } from 'src/app/shared/map-data-services/feature/feature.service';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';
import { LayerService } from 'src/app/shared/map-data-services/layer/layer.service';
import { StyleService } from 'src/app/shared/map-data-services/styles/style.service';
import { UserSettingsStreamService } from 'src/app/shared/user/user-settings-stream.service';

import { FeaturePanelUtil } from 'src/app/feature/map-viewer/side-panel/feature-panel/feature-panel.util';
import { LayersStore } from '../current-layers/layers-store.service';
import { LayersStreams } from '../current-layers/layers-streams.service';
import { ProjectStreamService } from '../current-project/project-stream.service';
import { TasksStreamsService } from '../current-tasks/tasks-streams.service';
import { FeatureFilter } from '../feature-filter/feature-filter';
import { FullFeatureFilter } from '../feature-filter/full-feature-filter';
import { Task } from '../task/task';
import { CloneUtils } from '../utility/clone-utils';
import { ActiveFeatureStreamsService } from './active-feature-streams.service';
import { FeatureFilterStreamService } from './feature-filter-stream.service';
import { FeatureSet } from './feature-set';
import { FeatureSetFactoryService } from './feature-set-factory.service';
import { SelectionCriteria } from './selection-criteria';

@Injectable({
    providedIn: 'root'
})
export class FeaturesStreamsService {
    private selectedFeatureSet: FeatureSet;
    private previousFeatureFilter: FeatureFilter = null;
    private previousSelectedFeatures: Feature[] = [];

    public selectedFeaturesStream = new BehaviorSubject<Feature[]>([]);

    constructor(
        featureSetFactory: FeatureSetFactoryService,
        private projectStream: ProjectStreamService,
        private layersStore: LayersStore,
        private layersStreams: LayersStreams,
        private tasksStreams: TasksStreamsService,
        private featureFilterStream: FeatureFilterStreamService,
        private featureService: FeatureService,
        private userSettingsStream: UserSettingsStreamService,
        private activeFeatureStreams: ActiveFeatureStreamsService,
        private featureMapLayersService: FeatureMapLayersService,
        private cachedFeatureService: CachedFeatureService,
        private layerService: LayerService,
        private styleService: StyleService,
        private fileViewerImportService: FileViewerImportService
    ) {
        this.selectedFeatureSet = featureSetFactory.createFeatureSet('selected features');
        this.subscribeToActiveFilterStream();
        this.subscribeToVisibleTasksStream();
        this.subscribeToVisibleLayersStream();
        this.subscribeToMapWorkspaceLayersLoadingStream();
        this.subscribeToCurrentFeaturesStream();
    }

    private subscribeToActiveFilterStream(): void {
        this.featureFilterStream.activeFilterStream.subscribe(featureFilter => {
            const filtersChanged = this.previousFeatureFilter !== featureFilter;
            if (filtersChanged) {
                this.selectedFeatureSet.modifyFilters(featureFilter);
                this.previousFeatureFilter = featureFilter;
            }
        });
    }

    private subscribeToVisibleTasksStream(): void {
        this.tasksStreams.visibleTasksStream.subscribe(visibleTasks => {
            this.selectedFeatureSet.modifyTasks(visibleTasks);
        });
    }

    private subscribeToVisibleLayersStream(): void {
        this.layersStreams.visibleLayersStream.subscribe(visibleLayers => {
            this.selectedFeatureSet.modifyFilterLayers(visibleLayers);
        });
    }

    private subscribeToMapWorkspaceLayersLoadingStream(): void {
        this.layersStore.mapWorkspaceLayersLoadingStream.pipe(filter(loading => !loading)).subscribe(() => {
            let visibleLayers = this.layersStreams.visibleLayersStream.getValue() || [];
            this.selectedFeatureSet.modifyFilterLayers(visibleLayers);

            const selectedFeatureIds = this.userSettingsStream.getCurrentMapWorkspaceSettings()?.selectedFeatureIds;
            if (selectedFeatureIds?.length) {
                let filter = new FullFeatureFilter();
                filter.taskFeatures.featureIds = selectedFeatureIds;
                const maxFeatures = filter.taskFeatures.featureIds?.length;
                this.featureService
                    .getFeatures(this.projectStream.getCurrentProject().id, filter, maxFeatures)
                    .then(features => this.selectedFeatureSet.replace(features));
            }
        });
    }

    private subscribeToCurrentFeaturesStream(): void {
        this.projectStream.currentProjectStream
            .pipe(
                skipWhile(project => !project),
                switchMap(() => this.selectedFeatureSet.currentFeaturesStream)
            )
            .subscribe(async selectedFeatures => {
                const layerKeyToMapWorkspaceLayer = this.layersStore.getLayerKeyToLayerMap();
                const currentProjectId = this.projectStream.getCurrentProject().id;

                await Promise.all(
                    selectedFeatures.map(feature =>
                        this.addLayerPropertiesToFeature(feature, layerKeyToMapWorkspaceLayer, currentProjectId)
                    )
                );

                selectedFeatures = FeaturePanelUtil.orderFeaturesInList(selectedFeatures);

                // Set active feature to first selected feature if feature was not part of the previous selection
                const previousActiveFeature = this.activeFeatureStreams.activeFeature;
                const activeFeature =
                    selectedFeatures.find(feature => feature.id === previousActiveFeature?.id) || selectedFeatures[0];

                // Update newly selected features
                selectedFeatures.forEach(feature => {
                    feature = this.cachedFeatureService.updateFromCacheFeature(feature);
                    feature.selected = true;
                    feature.active = feature === activeFeature;
                    this.updateFeatureAndLayerCaches(feature);
                });

                // Update previously selected features that are no longer selected
                const selectedFeatureIds = new Set(selectedFeatures.map(feature => feature.id));
                this.previousSelectedFeatures.forEach(feature => {
                    if (!selectedFeatureIds.has(feature.id)) {
                        feature.selected = false;
                        feature.active = false;
                        this.updateFeatureAndLayerCaches(feature);
                    }
                });

                this.previousSelectedFeatures = selectedFeatures;
                this.selectedFeaturesStream.next(selectedFeatures);
                this.setActiveFeature(CloneUtils.cloneDeep(activeFeature));
            });
    }

    private updateFeatureAndLayerCaches(feature: Feature): void {
        this.cachedFeatureService.addOrUpdateFeature(feature);
        this.featureMapLayersService.updateFeatureMapLayer(feature, null, false);
    }

    private async addLayerPropertiesToFeature(
        feature: Feature,
        layerKeyToMapWorkspaceLayer: { [key: string]: Layer },
        currentProjectId: string
    ): Promise<void> {
        feature.layerKey = feature.getLayerKey();
        if (feature.layerKey) {
            const layer = layerKeyToMapWorkspaceLayer[feature.layerKey];
            if (layer) {
                feature.addLayerProperties(layer);
            } else {
                await this.addLayerPropertiesFromService(feature, currentProjectId);
            }
        } else if (this.fileViewerImportService.fileViewerLayersStream.value.length) {
            this.addLayerPropertiesFromFileViewer(feature);
        }
    }

    private async addLayerPropertiesFromService(feature: Feature, currentProjectId: string): Promise<void> {
        const layer = await this.layerService.getLayerById(currentProjectId, feature.layerKey);
        const style = await this.styleService.getStyleById(currentProjectId, layer.styleId);
        layer.style = style;
        layer.color = layer.getLayerColor();
        feature.isLayerRemoved = true;
        feature.removedLayer = layer;
        feature.addLayerProperties(layer);
    }

    private addLayerPropertiesFromFileViewer(feature: Feature): void {
        const importedFileViewerLayers = this.fileViewerImportService.fileViewerLayersStream.value;
        const importedLayer = importedFileViewerLayers.find(
            layer =>
                layer.sourceLayerName === feature.metadata.file_sourceLayer &&
                layer.fileVersionId === feature.metadata.file_connectVersionId
        );
        if (importedLayer) {
            feature.addLayerProperties(importedLayer);
        }
    }

    public setActiveFeature(activeFeature: ActiveFeature, selectedFromFeaturePanel = false): void {
        if (this.activeFeatureStreams.activeFeature) {
            let previousActiveFeature = this.activeFeatureStreams.activeFeature;
            previousActiveFeature = this.cachedFeatureService.updateFromCacheFeature(
                previousActiveFeature as CachedFeature
            ); // TODO: remove need for this cast?
            previousActiveFeature.active = false;
            this.featureMapLayersService.updateFeatureMapLayer(previousActiveFeature, null, false);
        }
        if (activeFeature) {
            activeFeature.active = true;
            activeFeature.selectedFromFeaturePanel = selectedFromFeaturePanel;
            this.featureMapLayersService.updateFeatureMapLayer(activeFeature, null, false);
            this.activeFeatureStreams.activeFeature = activeFeature;
        }
    }

    public clearSelectedFeatures(callBack?: (features: Feature[]) => void): void {
        this.selectedFeatureSet.clear(callBack);
    }

    public refreshSelectedFeatures(callBack?: (features: Feature[]) => void): void {
        this.selectedFeatureSet.replace(
            this.previousSelectedFeatures.map(feature => feature.id),
            callBack
        );
    }

    public replaceOnSelectedFeatures(
        selectionCriteria: SelectionCriteria,
        callBack?: (features: Feature[]) => void
    ): void {
        this.selectedFeatureSet.replace(selectionCriteria, callBack);
    }

    public addToSelectedFeatures(selectionCriteria: SelectionCriteria, callBack?: (features: Feature[]) => void): void {
        this.selectedFeatureSet.add(selectionCriteria, callBack);
    }

    public removeFromSelectedFeatures(
        selectionCriteria: SelectionCriteria,
        callBack?: (features: Feature[]) => void
    ): void {
        this.selectedFeatureSet.remove(selectionCriteria, callBack);
    }

    public modifyAvailableLayers(layers: Layer[]): void {
        this.selectedFeatureSet.modifyAvailableLayers(layers);
    }

    public modifyFilterLayers(layers: Layer[]): void {
        this.selectedFeatureSet.modifyFilterLayers(layers);
    }

    public modifyTasks(tasks: Task[]): void {
        this.selectedFeatureSet.modifyTasks(tasks);
    }
}
