import 'src/app/shared/leaflet-extensions/leaflet-editable/leaflet-editable.js';

import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import * as L from 'leaflet';
import * as _ from 'lodash-es';
import { Subject, Subscription, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { SelectionToolStream } from 'src/app/feature/map-viewer/common/selection-tool-stream.service';
import { KeyCodes } from 'src/app/shared/common/key-codes';

import { SelectionMode, SelectionModeAction } from '../../toolbar/mapToolbar/selectionTools/selection-tool.component';
import {
    MapDrawAction,
    MapDrawActionResult,
    MapDrawActionSource,
    MapDrawActionsStreamService as MapDrawActionsStreamsService,
} from './map-draw-action-stream.service';

@Component({
    selector: 'map-actions',
    template: ''
})
export class MapActionsComponent implements OnInit, OnDestroy {
    constructor(
        private mapActionsStream: MapDrawActionsStreamsService,
        private selectionToolStream: SelectionToolStream,
        private $translate: TranslationService,
        private renderer: Renderer2
    ) {
        mapActionsStream.startMapDrawActionStream
            .pipe(takeUntil(this.destroyed))
            .subscribe(action => this.startMapDrawAction(action));
    }
    private readonly destroyed = new Subject<void>();

    private timerSubscription: Subscription;
    private delay = 200;
    private prevent = false;

    private mapDrawAction: MapDrawAction;
    private shape: L.Polygon<any>; // leaflet layer when drawing polygon/rectangle
    private tooltip: HTMLElement;
    private tooltipHtmlText = '';

    @Input()
    map: L.Map;

    public ngOnInit(): void {
        this.shape = null;
        this.tooltip = null;
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    startMapDrawAction(mapDrawAction: MapDrawAction): void {
        this.cancelMapAction();

        this.mapDrawAction = mapDrawAction;
        if (this.mapDrawAction) {
            if (this.mapDrawAction.mode === SelectionMode.SINGLE) {
                this.map.on('click', this.onSingleModeClick, this);
                this.map.on('dblclick', this.onSingleModeDblClick, this);
            } else {
                // rectangle or polygon
                this.startShape();
            }
        }
    }

    private startShape(): void {
        switch (this.mapDrawAction.mode) {
            case SelectionMode.RECTANGLE:
                // custom vertex markers which have no drag handles
                let CustomRectangleVertexMarker = (L.Editable as any).VertexMarker.extend({
                    options: {
                        draggable: true,
                        className: 'leaflet-vertex-icon' // remove drag handles by removing class leaflet-div-icon
                    }
                });
                this.map.editTools.options.vertexMarkerClass = CustomRectangleVertexMarker;

                this.shape = this.map.editTools.startRectangle(
                    null,
                    this.mapDrawAction.drawingOptions || {
                        color: '#646464',
                        fillColor: '#464646',
                        vertexMarkerClass: CustomRectangleVertexMarker
                    }
                );

                // mouseup ends drawing
                // needed for SHIFT-mouseup and ALT-mouseup to work.
                this.map.on('mouseup', this.onRectangleModeMouseUp, this);

                // tooltip
                this.addTooltip(this.$translate.instant('MapViewer.MapDrawActions.ClickAndDragToDrawRectangle'));
                this.shape.on('editable:drawing:mousedown', this.onRectangleModeEditableDrawingMouseDown, this);
                // still fires if mouse is out of bounds
                this.shape.on('editable:vertex:dragend', this.onRectanglePolygonModeEditableDrawingEnd, this);

                break;

            case SelectionMode.POLYGON:
                this.shape = this.map.editTools.startPolygon(
                    null,
                    this.mapDrawAction.drawingOptions || {
                        color: '#646464',
                        fillColor: '#464646'
                    }
                );

                // dblclick ends drawing
                // needed for SHIFT-dblclick and ALT-dblclick to work.
                this.map.on('dblclick', this.onPolygonModeDblClick, this);

                // tooltip
                this.addTooltip(this.$translate.instant('MapViewer.MapDrawActions.ClickToStartDrawingShape'));
                this.map.on('editable:drawing:click', this.onPolygonModeEditableDrawingClick, this);
                break;
        }

        if (this.shape) {
            // Escape and CTRL-C end drawing.  Note: keypress event is deprecated
            this.map.on('keydown', this.onRectanglePolygonModeKeyDown, this);

            // Right-click ends drawing and reverts to single-select
            this.map.on('contextmenu', this.onRectanglePolygonModeContextMenu, this);
            this.shape.on('editable:drawing:end', this.onRectanglePolygonModeEditableDrawingEnd, this);

            this.shape.on('editable:drawing:cancel', this.onRectanglePolygonModeEditableDrawingCancel, this);
        }
    }

    private endShape(): void {
        if (this.shape) {
            let mapDrawActionResult: MapDrawActionResult = this.mapDrawAction as MapDrawActionResult;
            mapDrawActionResult.result = null;
            if (this.mapDrawAction.mode === SelectionMode.POLYGON) {
                if (this.shape && (this.shape.getLatLngs()[0] as any).length > 2) {
                    mapDrawActionResult.result = this.shape;
                }
            } else if (this.mapDrawAction.mode === SelectionMode.RECTANGLE) {
                if (this.shape && this.shape.getBounds().isValid()) {
                    let bounds = this.shape.getBounds();
                    if (bounds.getNorth() > bounds.getSouth() && bounds.getWest() < bounds.getEast()) {
                        mapDrawActionResult.result = bounds;
                    }
                }
            }

            if (mapDrawActionResult.result !== null) {
                this.mapActionsStream.mapDrawActionResultStream.next(mapDrawActionResult);
            }
            this.clearShape();
        }
    }

    private cancelMapAction(): void {
        this.cancelShape();
        if (this.map) {
            this.map.off('click', this.onSingleModeClick, this);
            this.map.off('dblclick', this.onSingleModeDblClick, this);
        }
        this.mapDrawAction = null;
    }

    private cancelShape(): void {
        if (this.map) {
            this.map.editTools.stopDrawing();
        }
        this.clearShape();
    }

    private clearShape(): void {
        if (this.map) {
            this.map.off('mouseup', this.onRectangleModeMouseUp, this);
            this.map.off('dblclick', this.onPolygonModeDblClick, this);
            this.map.off('editable:drawing:click', this.onPolygonModeEditableDrawingClick, this);
            this.map.off('keydown', this.onRectanglePolygonModeKeyDown, this);
            this.map.off('contextmenu', this.onRectanglePolygonModeContextMenu, this);
            delete this.map.editTools.options.vertexMarkerClass; // restore vertices with drag handles
        }

        this.removeTooltip();

        if (this.shape) {
            this.shape.off('editable:drawing:mousedown', this.onRectangleModeEditableDrawingMouseDown, this);
            this.shape.off('editable:drawing:end', this.onRectanglePolygonModeEditableDrawingEnd, this);
            this.shape.off('editable:drawing:cancel', this.onRectanglePolygonModeEditableDrawingCancel, this);
            this.shape.remove();
            this.shape = null;
        }
    }

    // --------------

    private onSingleModeClick(event: L.LeafletEvent): void {
        // Prevent click event, when user click on double click event
        this.timerSubscription = timer(this.delay).subscribe(() => {
            if (!this.prevent) {
                let data = event as any;
                let mapDrawActionResult: MapDrawActionResult = this.mapDrawAction as MapDrawActionResult;
                mapDrawActionResult.result =
                    data.latlng && data.containerPoint ? this.buildPointBounds(data.containerPoint) : null;
                this.mapActionsStream.mapDrawActionResultStream.next(mapDrawActionResult);
            }
            this.prevent = false;
        });
    }

    private onSingleModeDblClick(): void {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
            this.timerSubscription = null;
        }
        this.prevent = true;
    }

    private onRectangleModeMouseUp(): void {
        this.map.editTools.commitDrawing();
    }

    private onRectangleModeEditableDrawingMouseDown(): void {
        this.updateTooltip(this.$translate.instant('MapViewer.MapDrawActions.ReleaseMouseToFinishDrawing'));
    }

    private onPolygonModeDblClick(): void {
        this.map.editTools.commitDrawing();
    }

    private onPolygonModeEditableDrawingClick(): void {
        let numberOfVertices = (this.shape.getLatLngs() as L.LatLng[][])[0].length + 1;
        if (numberOfVertices < 3) {
            this.updateTooltip(this.$translate.instant('MapViewer.MapDrawActions.ClickToContinueDrawingShape'));
        } else {
            this.updateTooltip(
                this.$translate.instant('MapViewer.MapDrawActions.DoubleClickOrClickTheFirstPointToCloseThisShape')
            );
        }
    }

    private onRectanglePolygonModeKeyDown(event: any): void {
        if (event && event.originalEvent instanceof KeyboardEvent) {
            let keyboardEvent = event.originalEvent as KeyboardEvent;
            if (
                keyboardEvent.key === KeyCodes.ESCAPE ||
                (keyboardEvent.ctrlKey && keyboardEvent.key.toLowerCase() === 'c')
            ) {
                this.cancelShape();
                // reset tool
                this.selectionToolStream.selectSelectionToolStream.next(SelectionModeAction.SINGLE_NEW);
            }
        }
    }

    private onRectanglePolygonModeContextMenu(event: any): void {
        this.cancelShape();
        this.selectionToolStream.selectSelectionToolStream.next(SelectionModeAction.SINGLE_NEW);
    }

    private onRectanglePolygonModeEditableDrawingEnd(event: L.LeafletEvent): void {
        const isLeftClick = _.isEqual(this.shape.getBounds().getNorthEast(), this.shape.getBounds().getSouthWest());
        this.endShape();
        if (this.mapDrawAction.source === MapDrawActionSource.SELECTION_TOOL) {
            this.startShape(); // allow more selection
        } else {
            // handle endShape function call on left click to continue offlineBaseMap
            if (isLeftClick) {
                this.startShape();
            } else {
                // reset tool in case of offlineBaseMap
                this.selectionToolStream.selectSelectionToolStream.next(SelectionModeAction.SINGLE_NEW);
            }
        }
    }

    private onRectanglePolygonModeEditableDrawingCancel(event: L.LeafletEvent): void {
        this.cancelShape();
        this.startShape(); // allow more selection
    }

    // --------------
    // tooltip

    private addTooltip(htmlText: string): void {
        this.tooltip = L.DomUtil.create('div', 'map-draw-action-tooltip', (this.map as any)._panes.popupPane);
        // hiding tooltip before user event
        this.renderer.setStyle(this.tooltip, 'display', 'none');
        this.map.on('mousemove', e => this.moveTooltip(e, htmlText));
    }

    private removeTooltip(): void {
        this.map.off('mousemove', e => e);
        if (this.tooltip) {
            (this.map as any)._panes.popupPane.removeChild(this.tooltip);
            this.tooltip = null;
        }
    }

    private moveTooltip(e: any, htmlText: string): void {
        if (this.tooltip) {
            this.renderer.setStyle(this.tooltip, 'display', 'block');
            let pos = this.map.latLngToLayerPoint(e.latlng);
            L.DomUtil.setPosition(this.tooltip, pos);
            this.updateTooltip(htmlText);
        }
    }

    private updateTooltip(htmlText: string): void {
        this.tooltipHtmlText = htmlText;
        if (this.tooltip) {
            this.tooltip.innerHTML = this.tooltipHtmlText;
        }
    }

    // -------------

    private buildPointBounds(coord: L.Coords): L.LatLngBounds {
        // creates a little rectangle around the
        // click point to use as a selection rectangle

        let bufferInPixels = 8;
        let bufferXOffset = 2;
        let bufferYOffset = 0;

        let pixelW = coord.x - bufferInPixels + bufferXOffset;
        let pixelS = coord.y + bufferInPixels + bufferYOffset;
        let pixelE = coord.x + bufferInPixels + bufferXOffset;
        let pixelN = coord.y - bufferInPixels + bufferYOffset;

        let latLongSW = this.map.containerPointToLatLng(L.point(pixelW, pixelS));
        let latLongNE = this.map.containerPointToLatLng(L.point(pixelE, pixelN));

        return L.latLngBounds(latLongNE, latLongSW);
    }
}

// --------------
