<div [hidden]="!isSelected">
    <gsp-confirmation-button
        confirmText="{{ 'TC.Common.DeleteField' | translate }}"
        buttonTooltip="{{ 'TC.Common.FieldPermanentlyDeleted' | translate }}"
        iconClass="icon_solid_trash i32"
        [type]="ButtonType.DefaultIcon"
        (action)="trashField(field); $event.stopPropagation()"
        [disabled]="field.locked"
    >
    </gsp-confirmation-button>

    <gsp-button
        (action)="toggleField.emit(); $event.stopPropagation()"
        buttonTooltip="{{ 'RESOLVED' | translate }}"
        iconClass="icon_line_tick_32 i32"
        [type]="ButtonType.Done"
    ></gsp-button>

    <gsp-button
        [hidden]="field.type !== 'Group'"
        buttonTooltip="{{ 'TC.Common.Status.Clone' | translate }}"
        iconClass="icon_solid_clipboard i32"
        [type]="ButtonType.DefaultIcon"
        (action)="duplicateField.emit()"
    ></gsp-button>
</div>
