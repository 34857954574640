import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';

@Directive({
    selector: '[workspaceName]',
    providers: [{ provide: NG_VALIDATORS, useExisting: WorkspaceNameValidatorDirective, multi: true }]
})
export class WorkspaceNameValidatorDirective implements Validator, OnChanges {
    @Input()
    workspaceName: string;

    @Input()
    checkDuplicateOnDirty = true;

    private _onValidatorChange = () => {};

    private editMapWorkspace = this.mapWorkspacesStore.editMapWorkspaceStream.getValue();

    constructor(private mapWorkspacesStore: MapWorkspacesStoreService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.maxDate) {
            this._onValidatorChange();
        }
    }

    registerOnValidatorChange?(fn: () => void): void {
        this._onValidatorChange = fn;
    }

    public hasSpecialCharacters(fileName: string): boolean {
        let specialChars = '?/<>*":\\';
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < specialChars.length; i++) {
            if (fileName.indexOf(specialChars[i]) > -1) {
                return true;
            }
        }
        return false;
    }

    validate(control: AbstractControl): ValidationErrors {
        const name = control.value as string;
        let isNameAlreadyExists = false;
        let nameWithoutWhitespaces = '';

        if (name) {
            if (name.trim().startsWith('.')) {
                return { invalidName: true };
            } else if (name.trim().endsWith('.')) {
                return { invalidName: true };
            } else if (name.length > 44) {
                return { exceedsMaxLength: true };
            } else if (this.hasSpecialCharacters(name)) {
                return { specialChars: true };
            } else {
                const formattedName = name.replace(/\s+$/g, '').toLowerCase();
                isNameAlreadyExists = this.mapWorkspacesStore.projectMapWorkspaces.some(
                    workspace =>
                        workspace.name.replace(/\s+$/g, '').toLowerCase() === formattedName &&
                        this.editMapWorkspace?.name.replace(/\s+$/g, '').toLowerCase() !== formattedName
                );
                // shouldn't consider just whitespaces to be a valid name
                nameWithoutWhitespaces = name.replace(/\s+/g, '');
                if (!nameWithoutWhitespaces) {
                    return { required: true };
                } else if (
                    isNameAlreadyExists &&
                    (!this.checkDuplicateOnDirty || (this.checkDuplicateOnDirty && control.dirty))
                ) {
                    return { notUnique: true };
                } else {
                    return null;
                }
            }
        } else {
            return { required: true };
        }
    }
}
