import * as _ from 'lodash-es';

import { GeneralUtils } from '../../common/utility/general-utils';
import { GeoTemplate } from '../../template-services/templateDTO';
import { GeoPutLayerRequest } from '../layer/layer';
import { Style } from '../styles/style';
import { MapWorkspacePermissionType } from './map-workspace-permission';
import {
    CoordinateSystem,
    CoordinateSystemComponentDTO,
    CoordinateSystemComponentUnit,
    CoordinateSystemUnits,
    GeoWorkspace,
    GeoWorkspacePostProcessingBody,
    GeoWorkspaceRequest,
    MapWorkspaceStatus,
    WorkspaceBounds,
    WorkspaceModel
} from './map-workspace.types';

export const LATLONG_SYSTEM_COMPONENT_ID = 'none';
export const WGS1984_DATUM_COMPONENT_ID = 'Trimble:1034';
export const GLOBAL_GEOID_ID = 'Trimble:972';
export const usCountyUnitsDict: { [id: string]: CoordinateSystemUnits[] } = {
    'Trimble:3053': [CoordinateSystemUnits.US_SURVEY_FOOT],
    'Trimble:2960': [CoordinateSystemUnits.METRE, CoordinateSystemUnits.US_SURVEY_FOOT],
    'Trimble:3085': [CoordinateSystemUnits.METRE, CoordinateSystemUnits.US_SURVEY_FOOT],
    'Trimble:4936': [CoordinateSystemUnits.US_SURVEY_FOOT],
    'Trimble:4356': [CoordinateSystemUnits.METRE, CoordinateSystemUnits.US_SURVEY_FOOT]
};
export const US_STATE_PLANE_COMPONENT_ID = 'Trimble:83';
const WORLDWIDE_UTM_COMPONENT_ID = 'Trimble:85';
const US_ITRF_TO_NAD83_COMPONENT_ID = 'Trimble:2678';

export const CANADA_DATUM_RESOURCE_URL =
    'https://natural-resources.canada.ca/maps-tools-and-publications/geodetic-reference-systems/canadian-spatial-reference-system-csrs/adopted-nad83csrs-epochs/17908';

export enum ConnectFileError {
    TC_FILE_NOT_EXIST = 'TC_FILE_NOT_EXIST',
    MAP_WORKSPACE_REMOVED = 'MAP_WORKSPACE_REMOVED',
    MAP_WORKSPACE_NOT_EXIST = 'MAP_WORKSPACE_NOT_EXIST',
    PERMISSION_DENIED = 'PERMISSION_DENIED'
}

export enum ConnectMapWorkspaceCreationError {
    MAP_WORKSPACE_ALREADY_EXIST = 'MAP_WORKSPACE_ALREADY_EXIST',
    TC_FILE_ALREADY_EXIST = 'TC_FILE_ALREADY_EXIST',
    TC_WORKSPACE_CREATION_FAILED = 'TC_WORKSPACE_CREATION_FAILED',
    TC_WORKSPACE_CREATION_FAILED_EXCEEDED_STORAGE_LIMIT = 'TC_WORKSPACE_CREATION_FAILED_EXCEEDED_STORAGE_LIMIT'
}

export const coordinateSystemComponentUnits: CoordinateSystemComponentUnit[] = [
    { displayName: 'TC.Common.Meters', id: CoordinateSystemUnits.METRE },
    { displayName: 'TC.Common.FeetUSSurvey', id: CoordinateSystemUnits.US_SURVEY_FOOT },
    { displayName: 'TC.Common.FeetInternational', id: CoordinateSystemUnits.INTERNATIONAL_FOOT }
];

export enum CoordinateSystemComponentType {
    DATUM = 'Datum',
    ZONE_GROUP = 'ZoneGroup',
    ZONE = 'Zone',
    GEOID = 'Geoid'
}

export interface ShareWorkspaceData {
    workspace: {
        name: string;
        description: string;
    };
    content: {
        layer: GeoPutLayerRequest;
        style: Style;
        template: GeoTemplate;
    }[];
}

export class CoordinateSystemComponent {
    name: string;
    displayName: string;
    componentID: string;
    type: CoordinateSystemComponentType;
    isGlobal: boolean;
    parentID: string;
    defaultDatumID?: string;
    defaultGeoidID?: string;
    isDynamic: boolean;
    localReferenceFrameComponentID: string;
    horizontalUnits?: CoordinateSystemUnits;
    verticalUnits?: CoordinateSystemUnits;

    static fromDTO(dto: CoordinateSystemComponentDTO): CoordinateSystemComponent {
        let component = new CoordinateSystemComponent();
        if (dto) {
            component.name = dto.name;
            component.displayName = dto.displayName;
            component.componentID = dto.componentID;
            component.type = dto.type;
            component.isGlobal = dto.extents ? dto.extents.isGlobal : false;
            component.parentID = dto.parentID;
            component.defaultDatumID = dto.defaultDatumID ? dto.defaultDatumID : null;
            component.defaultGeoidID = dto.defaultGeoidID ? dto.defaultGeoidID : null;
            component.isDynamic = dto.isDynamic;
            component.localReferenceFrameComponentID = dto.localReferenceFrameComponentID || null;
            component.horizontalUnits = dto.horizontalUnits || null;
            component.verticalUnits = dto.verticalUnits || null;
            return component;
        }

        return null;
    }
}

export class MapWorkspace {
    // DTO properties
    id: string = null;
    name: string = null;
    description = '';
    connectFileId: string = null;
    connectFileError: ConnectFileError = null;
    hasThumbnail = false;

    // Note: all customisation for MapViewer UI level only will be placed here
    workspaceModel?: WorkspaceModel = {
        isCustomThumbnailImage: false
    } as WorkspaceModel;

    isDeleted = false;
    relationships: {
        realtimeCorrectionIds: string[];
        layerIds: string[];
        dataUpdateJobIds: string[];
        mapCacheIds: string[];
        userIds: string[];
    } = {
        realtimeCorrectionIds: [],
        layerIds: [],
        dataUpdateJobIds: [],
        mapCacheIds: [],
        userIds: []
    };

    // Added properties
    projectId: string = null;
    connectFolderId: string = null;
    connectVersionId: string = null;
    isFileViewer = false;
    connectFileName: string = null;
    multipleFileView = false;
    connectFileIds: string[] = null;
    connectFileVersionIds: string[] = null;
    connectFileNames: string[] = null;
    options: any = null;
    isPubliclySharedMapWorkspace = false;
    connectFileLastAssimIds: string[];
    permission: MapWorkspacePermissionType;
    coordinateSystem: CoordinateSystem = new CoordinateSystem();
    bounds: WorkspaceBounds = new WorkspaceBounds();

    tectonicPlate: string = null;

    status: MapWorkspaceStatus = MapWorkspaceStatus.ACTIVE;

    postProcessingOptions = new GeoWorkspacePostProcessingBody(); // for setting post processing status of the workspace

    updatedUtc: Date;

    // Usage: new Mapworkspace({ ...attribute(s) you want to override })
    // Reference: https://stackoverflow.m/questions/14142071/typescript-and-field-initializers
    constructor(init?: Partial<MapWorkspace>) {
        Object.assign(this, init);
    }

    static fromDTO(dto: GeoWorkspace, projectId: string): MapWorkspace {
        const workspace = new MapWorkspace();

        // Create from API response DTO
        if (dto) {
            workspace.projectId = projectId; // Assign current project to all spatial workspaces

            // dto
            workspace.id = dto.id;
            workspace.name = dto.name;
            workspace.description = dto.description;
            workspace.connectFileId = dto.connectFileId;
            workspace.connectFileError = dto.connectFileError;
            workspace.hasThumbnail = dto.hasThumbnail;
            if (dto.workspaceModel && workspace.workspaceModel) {
                workspace.workspaceModel.isCustomThumbnailImage = dto.workspaceModel.isCustomThumbnailImage;
            }
            workspace.isDeleted = dto.isDeleted;
            workspace.status =
                dto.status === MapWorkspaceStatus.ARCHIVED ? MapWorkspaceStatus.ARCHIVED : MapWorkspaceStatus.ACTIVE;
            if (dto.relationships) {
                workspace.relationships.realtimeCorrectionIds = dto.relationships.realtimeCorrectionIds || [];
                workspace.relationships.layerIds = dto.relationships.layerIds || [];
                workspace.relationships.dataUpdateJobIds = dto.relationships.dataUpdateJobIds || [];
                workspace.relationships.mapCacheIds = dto.relationships.mapCacheIds || [];
            }

            // dto dervived
            workspace.connectFileName = dto.name;
            // fileviewer workspaces are hardcoded with the uuid value '55555555-5555-5555-5555-555555555555' in the API
            workspace.isFileViewer = dto.uuid === '55555555-5555-5555-5555-555555555555';

            if (dto.coordinateSystem) {
                workspace.coordinateSystem.datumComponentId = dto.coordinateSystem.datumComponentId;
                workspace.coordinateSystem.localReferenceFrameComponentId =
                    dto.coordinateSystem.localReferenceFrameComponentId || null;
                workspace.coordinateSystem.geoidComponentId = dto.coordinateSystem.geoidComponentId;
                workspace.coordinateSystem.horizontalUnit = dto.coordinateSystem.horizontalUnit;
                workspace.coordinateSystem.verticalUnit = dto.coordinateSystem.verticalUnit;
                workspace.coordinateSystem.zoneComponentId = dto.coordinateSystem.zoneComponentId;
                workspace.coordinateSystem.zoneGroupComponentId = dto.coordinateSystem.zoneGroupComponentId;
                workspace.coordinateSystem.cscmResourceId = dto.coordinateSystem.cscmResourceId;
                workspace.coordinateSystem.cscmLocalLLHResourceId = dto.coordinateSystem.cscmLocalLLHResourceId;

                // If the workspace is setup with a lat/long coordinate system.
                if (
                    workspace.hasDatumComponent() &&
                    GeneralUtils.isNullUndefinedOrNaN(workspace.coordinateSystem.zoneComponentId) &&
                    GeneralUtils.isNullUndefinedOrNaN(workspace.coordinateSystem.zoneGroupComponentId)
                ) {
                    workspace.coordinateSystem.zoneGroupComponentId = LATLONG_SYSTEM_COMPONENT_ID;
                    workspace.coordinateSystem.zoneComponentId = null;
                }
            }

            if (dto.postProcessingBody) {
                workspace.postProcessingOptions = {
                    ...workspace.postProcessingOptions,
                    ...dto.postProcessingBody
                };
            }

            if (dto.bounds) {
                workspace.bounds.latitudeNorth = dto.bounds.latitudeNorth;
                workspace.bounds.latitudeSouth = dto.bounds.latitudeSouth;
                workspace.bounds.longitudeEast = dto.bounds.longitudeEast;
                workspace.bounds.longitudeWest = dto.bounds.longitudeWest;
            }

            workspace.tectonicPlate = dto.tectonicPlate ? dto.tectonicPlate : null;

            workspace.updatedUtc = new Date(dto.updatedUtc);
        }

        return workspace;
    }

    static getWorkspaceNameValidationError(errors: object): string {
        let errorMessage = null;
        if (errors) {
            if (_.filter([errors], 'required').length) {
                errorMessage = 'RequiredError';
            } else if (_.filter([errors], 'invalidName').length) {
                errorMessage = 'MapViewer.Workspace.Validate.Name';
            } else if (_.filter([errors], 'exceedsMaxLength').length) {
                errorMessage = 'TCS.Workspace.Warn44CharsAllowed';
            } else if (_.filter([errors], 'specialChars').length) {
                errorMessage = 'TCS.Workspace.ErrorSpecChars';
            } else if (_.filter([errors], 'notUnique').length) {
                errorMessage = 'TCS.Workspace.WorkspaceNameAlreadyExist';
            }
        }
        return errorMessage;
    }

    hasBounds(): boolean {
        return (
            !GeneralUtils.isNullUndefinedOrNaN(this.bounds) &&
            !GeneralUtils.isNullUndefinedOrNaN(this.bounds.latitudeNorth)
        );
    }

    hasDatumComponent(): boolean {
        return (
            !GeneralUtils.isNullUndefinedOrNaN(this.coordinateSystem) &&
            !GeneralUtils.isNullUndefinedOrNaN(this.coordinateSystem.datumComponentId)
        );
    }

    noProjection(): boolean {
        return (
            !GeneralUtils.isNullUndefinedOrNaN(this.coordinateSystem) &&
            this.coordinateSystem.zoneGroupComponentId === LATLONG_SYSTEM_COMPONENT_ID
        );
    }

    getAllowedHorizontalUnits(): CoordinateSystemComponentUnit[] {
        return usCountyUnitsDict[this.coordinateSystem.zoneGroupComponentId]
            ? coordinateSystemComponentUnits.filter(unit =>
                  usCountyUnitsDict[this.coordinateSystem.zoneGroupComponentId].includes(unit.id)
              )
            : coordinateSystemComponentUnits;
    }

    getLocalCoordinateSystemId(): string {
        return this.coordinateSystem.cscmLocalLLHResourceId;
    }

    zoneGroupAllowsMultipleHorizontalUnits(): boolean {
        return [
            WORLDWIDE_UTM_COMPONENT_ID,
            US_ITRF_TO_NAD83_COMPONENT_ID,
            US_STATE_PLANE_COMPONENT_ID,
            ...Object.keys(usCountyUnitsDict).filter(id => usCountyUnitsDict[id].length > 1)
        ].includes(this.coordinateSystem.zoneGroupComponentId);
    }

    toDTO(): GeoWorkspaceRequest {
        if (this.noProjection()) {
            this.coordinateSystem.zoneGroupComponentId = null;
            this.coordinateSystem.zoneComponentId = null;
        }
        // to API request DTO
        return {
            id: this.id,
            name: this.name,
            projectId: this.projectId,
            description: this.description,
            connectFileId: this.connectFileId,
            connectFolderId: this.connectFolderId, // TODO: this property is not in the DTO
            tectonicPlate: this.tectonicPlate,
            coordinateSystem: this.coordinateSystem,
            postProcessingBody: this.postProcessingOptions,
            // Prevent setting bounds as empty object
            bounds:
                this.bounds && Object.values(this.bounds).some(val => !GeneralUtils.isNullUndefinedOrNaN(val))
                    ? this.bounds
                    : null,
            isViewer: this.isFileViewer
        };
    }

    toDTOWithStatus(): GeoWorkspaceRequest {
        const dto = this.toDTO();
        return { ...dto, status: this.status };
    }
}
