<ng-form #workspaceCoordinateSystemForm="ngForm" class="workspace-coordinate-system">
    <loading *ngIf="loading" style="width: 100%"></loading>
    <ng-container *ngIf="!loading">
        <div class="coordinate-system">
            <h4 class="sub-heading font-title">{{ 'MapViewer.CoordinateSystem.Setup' | translate }}</h4>
            <p>{{ 'MapViewer.Workspace.CoordinateSystem.Message' | translate }}</p>

            <div class="selection zonegroup">
                <div class="label">{{ 'MapViewer.Workspace.Projection.Label' | translate }}</div>
                <div class="input-container">
                    <gsp-dropdown
                        [options]="filteredZoneGroups"
                        [(ngModel)]="modifyWorkspace.coordinateSystem.zoneGroupComponentId"
                        (ngModelChange)="onZoneGroupChange()"
                        [idField]="'componentID'"
                        [textField]="'displayName'"
                        [addIcon]="false"
                        name="zoneGroup"
                        required
                        id="zoneGroup"
                    >
                    </gsp-dropdown>
                </div>
            </div>

            <div class="selection multi-selection zone" *ngIf="!modifyWorkspace.noProjection()">
                <div class="label">{{ 'MapViewer.Workspace.CoordinateSystem.Zone.Label' | translate }}</div>
                <div class="input-container">
                    <gsp-dropdown
                        [options]="filteredZones"
                        [(ngModel)]="modifyWorkspace.coordinateSystem.zoneComponentId"
                        [idField]="'componentID'"
                        [textField]="'displayName'"
                        [addIcon]="false"
                        (ngModelChange)="onZoneChange()"
                        name="zone"
                        required
                        id="zone"
                    >
                    </gsp-dropdown>

                    <ng-container *ngIf="!modifyWorkspace.noProjection()">
                        <div class="secondary-selection-title">
                            {{ 'MapViewer.Workspace.UnitSystem.Selection' | translate }}
                        </div>

                        <gsp-dropdown
                            [options]="horizontalUnits"
                            [(ngModel)]="modifyWorkspace.coordinateSystem.horizontalUnit"
                            [idField]="'id'"
                            [textField]="'displayName'"
                            [addIcon]="false"
                            [ngDisabled]="!multipleHorizontalUnitsAllowed"
                            name="horizontalUnit"
                            required
                            id="horizontalUnit"
                        >
                        </gsp-dropdown>
                    </ng-container>
                </div>
            </div>

            <gsp-notification
                *ngIf="modifyWorkspace.noProjection()"
                [type]="NotificationType.Info"
                class="notification"
            >
                <p>
                    {{ 'MapViewer.Workspace.CoordinateSystem.NoProjection' | translate }}
                </p>
            </gsp-notification>

            <div class="selection datum" [ngClass]="{ 'no-projection-datum': modifyWorkspace.noProjection() }">
                <div class="label">{{ 'TCS.Mapviewer.Rtc.Messages.Datum' | translate }}</div>
                <div class="input-container">
                    <gsp-dropdown
                        *ngIf="filteredDatums.length > 1"
                        [options]="filteredDatums"
                        [(ngModel)]="modifyWorkspace.coordinateSystem.datumComponentId"
                        (ngModelChange)="onDatumChange()"
                        [idField]="'componentID'"
                        [textField]="'displayName'"
                        [addIcon]="false"
                        name="datum"
                        required
                        id="datum"
                    >
                    </gsp-dropdown>

                    <div *ngIf="filteredDatums.length === 1">{{ filteredDatums[0].displayName }}</div>
                </div>
            </div>

            <gsp-notification
                *ngIf="isCanadianDatum(modifyWorkspace.coordinateSystem.datumComponentId)"
                [type]="NotificationType.Info"
                class="notification"
            >
                <div
                    [innerHTML]="
                        'MapViewer.CoordinateSystem.CanadaDatumMessage'
                            | translate: { resourceUrl: canadaDatumResourceUrl }
                    "
                ></div>
            </gsp-notification>

            <div class="selection">
                <gsp-checkbox
                    [(value)]="aboveSeaLevel"
                    [id]="'aboveSeaLevel'"
                    [label]="'MapViewer.Workspace.CoordinateSystem.AboveSeaLevel' | translate"
                ></gsp-checkbox>
            </div>

            <gsp-notification *ngIf="!aboveSeaLevel" [type]="NotificationType.Info" class="notification"
                ><div>
                    {{ 'MapViewer.Workspace.CoordinateSystem.AboveSeaLevel.Description' | translate }}
                </div></gsp-notification
            >

            <div
                class="selection multi-selection geoid"
                [ngClass]="{ 'no-projection-geoid': modifyWorkspace.noProjection() }"
            >
                <ng-container *ngIf="aboveSeaLevel">
                    <div class="label">{{ 'MapViewer.Workspace.CoordinateSystem.Geoid.Label' | translate }}</div>
                    <div class="input-container">
                        <gsp-dropdown
                            [options]="geoIds"
                            [(ngModel)]="modifyWorkspace.coordinateSystem.geoidComponentId"
                            [idField]="'componentID'"
                            [textField]="'displayName'"
                            [addIcon]="false"
                            [shouldDropup]="true"
                            name="geoId"
                            required
                            id="geoId"
                        >
                        </gsp-dropdown>

                        <div class="secondary-selection-title">
                            {{ 'MapViewer.Workspace.UnitSystem.Selection' | translate }}
                        </div>

                        <gsp-dropdown
                            [options]="verticalUnits"
                            [(ngModel)]="modifyWorkspace.coordinateSystem.verticalUnit"
                            [idField]="'id'"
                            [textField]="'displayName'"
                            [addIcon]="false"
                            name="verticalUnitAboveSeaLevel"
                            [shouldDropup]="true"
                            required
                            id="verticalUnitAboveSeaLevel"
                        >
                        </gsp-dropdown>
                    </div>
                </ng-container>

                <ng-container *ngIf="!aboveSeaLevel">
                    <div>
                        {{ 'MapViewer.Workspace.CoordinateSystem.StoreEllipsodialHeights' | translate }}
                        {{ 'MapViewer.Workspace.UnitSystem.Selection' | translate }}
                        &nbsp;
                    </div>

                    <gsp-dropdown
                        [options]="verticalUnits"
                        [(ngModel)]="modifyWorkspace.coordinateSystem.verticalUnit"
                        [idField]="'id'"
                        [textField]="'displayName'"
                        [addIcon]="false"
                        name="verticalUnit"
                        [shouldDropup]="true"
                        required
                        id="verticalUnit"
                    >
                    </gsp-dropdown>
                </ng-container>
            </div>
        </div>

        <div class="hint-text">
            {{ 'MapViewer.Workspace.CoordinateSystem.Description' | translate }}
        </div>
    </ng-container>
    <ng-container *ngIf="hasAnUnexpectedError">
        <gsp-popup
            class="workspace-details-panel"
            [heading]="'MapViewer.Generic.Something.Wrong' | translate"
            [size]="ModalSize.ExtraSmall"
            [showClose]="false"
            [showDone]="true"
            (done)="closeErrorPopup()"
            [doneBtnTitle]="'TC.Common.Close'"
            id="workspace-coordinates-error-popup"
        >
            <ng-container transclude-popup-content>
                <p>{{ 'MapViewer.Workspace.CoordinateSystem.Outage' | translate }}</p>
            </ng-container></gsp-popup
        >
    </ng-container>
</ng-form>
