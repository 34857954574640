import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import {
    ACCESS_TOKEN_KEY,
    AuthenticationService,
    CONNECT_LINK_KEY,
    TOKEN_REVOKED_KEY
} from 'src/app/core/authentication/authentication.service';
import { ACCESS_MODE_KEY, AccessMode } from 'src/app/core/authentication/share-token';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { HttpStatusCodes } from 'src/app/shared/common/http-status-codes';
import { Project } from 'src/app/shared/map-data-services/project/project';
import { ProjectService } from 'src/app/shared/map-data-services/project/project.service';
import { User, UserRole } from 'src/app/shared/user/user';
import { UserSettingsStreamService } from 'src/app/shared/user/user-settings-stream.service';
import { UserService } from 'src/app/shared/user/user.service';
import { environment } from 'src/environments/environment';

import { Actor } from '../../user/actor';
import { UserSettings } from '../../user/user-settings';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({
    providedIn: 'root'
})
export class ProjectStreamService {
    currentProjectStream = new BehaviorSubject<Project>(null);
    allProjectsStream = new BehaviorSubject<Project[]>([]);

    currentProject: Project = null;
    projectUsers: { [userId: string]: User } = {};
    currentProjectUserRoles: { [userId: string]: User } = {};

    private lastLoadedProjectIdForUsers: string = null;
    private lastLoadedProjectUsers: User[];

    constructor(
        private projectService: ProjectService,
        private userService: UserService,
        private userSettingsStream: UserSettingsStreamService,
        private messaging: MessagingService,
        private translate: TranslationService,
        private env: EnvironmentService,
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}

    public setCurrentProject(project: Project): void {
        if (sessionStorage.getItem(ACCESS_MODE_KEY) && sessionStorage.getItem(ACCESS_MODE_KEY) === AccessMode.SHARED) {
            project.isSharedProject = true;
        }
        if (!this.currentProject || (project.id && !_.isEqual(project, this.currentProject))) {
            this.currentProject = project;
            this.currentProjectStream.next(project);
        }
    }

    public initCurrentProjectInPublicSharedView(projectId: string): Promise<Project> {
        return new Promise((resolve, reject) => {
            if (this.currentProject && this.currentProject.isSharedProject) {
                // Don't do anything, just return the vvalue
                resolve(this.currentProject);
            }

            if (this.currentProject && this.currentProject.id === projectId) {
                if (!this.currentProject.settings) {
                    this.projectService.getProjectSettings(this.currentProject.id).then(settings => {
                        this.currentProject.settings = settings;

                        if (
                            sessionStorage.getItem(ACCESS_MODE_KEY) &&
                            sessionStorage.getItem(ACCESS_MODE_KEY) === AccessMode.SHARED
                        ) {
                            this.currentProject.isSharedProject = true;
                            this.currentProjectStream.next(this.currentProject);
                            return this.currentProject;
                        } else {
                            this.currentProjectStream.next(this.currentProject);
                            return resolve(this.currentProject);
                        }
                    });
                } else {
                    if (
                        sessionStorage.getItem(ACCESS_MODE_KEY) &&
                        sessionStorage.getItem(ACCESS_MODE_KEY) === AccessMode.SHARED
                    ) {
                        this.currentProject.isSharedProject = true;
                        this.currentProjectStream.next(this.currentProject);
                        return resolve(this.currentProject);
                    } else {
                        this.currentProjectStream.next(this.currentProject);
                        return resolve(this.currentProject);
                    }
                }
            } else {
                this.projectService
                    .getProjectById(projectId)
                    .then(project => {
                        project.isSharedProject = true;
                        this.currentProject = project;
                        this.currentProjectStream.next(project);
                        return resolve(project);
                    })
                    .catch(() => reject());
            }
        });
    }

    public async initCurrentProject(projectId: string, loadSettings = false): Promise<Project> {
        if (this.currentProject && this.currentProject.isSharedProject) {
            // Don't do anything, just return the value
            return this.currentProject;
        }

        if (!projectId) {
            if (!this.currentProject || !this.currentProject.id) {
                const key = ('current' + this.env.podLocation + 'ProjectId') as keyof UserSettings;
                const userSettings = this.userSettingsStream.getCurrentUserSettings();

                projectId = (userSettings[key] ? userSettings[key] : userSettings['currentProjectId']) as string;
            } else {
                projectId = this.currentProject.id;
            }
        }

        if (this.currentProject && this.currentProject.id === projectId) {
            if (loadSettings && !this.currentProject.settings) {
                const settings = await this.projectService.getProjectSettings(this.currentProject.id);
                this.currentProject.settings = settings;
            }
            this.setCurrentProject(this.currentProject);
            return this.currentProject;
        } else {
            const project = await this.createOrGetMapProject(projectId);
            if (project) {
                if (loadSettings) {
                    const settings = await this.projectService.getProjectSettings(project.id);
                    project.settings = settings;
                    this.setCurrentProject(project);
                } else {
                    const isChanged = !this.currentProject || this.currentProject.id !== project.id;
                    this.setCurrentProject(project);
                    project.isChanged = isChanged;
                }
                return project;
            }
        }
    }

    public getCurrentProject(): Project {
        return this.currentProjectStream.getValue();
    }

    public async getProjectWithUpdatedSettings(): Promise<Project> {
        const settings = await this.projectService.getProjectSettings(this.currentProject.id);
        this.currentProject.settings = settings;
        return this.currentProject;
    }

    public createOrGetMapProject(projectIdToGet: string): Promise<Project> {
        return this.projectService.getProjectById(projectIdToGet).then(
            project => project,
            error => {
                if (error.error && error.status !== HttpStatusCodes.FORBIDDEN) {
                    const toastr = this.messaging.showError(
                        error.error.message + '<br>' + this.translate.instant('TC.Common.ClickHereConnect'),
                        null,
                        { enableHtml: true }
                    );

                    toastr.onTap.subscribe(() => {
                        window.location.href = environment.connectMasterUrl;
                    });
                } else if (error.error && error.status === HttpStatusCodes.FORBIDDEN) {
                    // Token could have been revoked for connect but maybe not for map viewer
                    const existingAccessToken = sessionStorage.getItem(ACCESS_TOKEN_KEY);
                    if (existingAccessToken) {
                        if (
                            window.sessionStorage.getItem(CONNECT_LINK_KEY) &&
                            !window.sessionStorage.getItem(TOKEN_REVOKED_KEY)
                        ) {
                            this.authenticationService.revokeAccessToken().then(async revoked => {
                                if (revoked) {
                                    const initProjectAndWorkspace = window.sessionStorage.getItem(CONNECT_LINK_KEY);
                                    const { projectId, connectFolderId, connectFileId } =
                                        JSON.parse(initProjectAndWorkspace);
                                    const hashString = `#/maps/${projectId}/${connectFolderId}/${connectFileId}`;
                                    window.location.href = await this.authenticationService.getLoginUrl(
                                        encodeURIComponent(
                                            window.location.href.replace(window.location.hash, hashString)
                                        )
                                    );
                                } else {
                                    this.router.navigate(['error', '403']);
                                }
                            });
                        } else {
                            this.router.navigate(['error', '403']);
                        }
                    }
                } else if (error.status === HttpStatusCodes.NOT_FOUND) {
                    // to prevent continuous loading worm on navigation with wrong region for the project
                    this.router.navigate(['error', '404']);
                }
                return null;
            }
        );
    }

    public async getCurrentProjectUsers(): Promise<User[]> {
        if (this.currentProject.id !== this.lastLoadedProjectIdForUsers) {
            this.lastLoadedProjectIdForUsers = this.currentProject.id;
            this.lastLoadedProjectUsers = await this.userService
                .getProjectUsers(this.lastLoadedProjectIdForUsers, 0, null)
                .then(user => user.items);
        }

        return this.lastLoadedProjectUsers;
    }

    public async getProjectUser(userId: string): Promise<User> {
        if (userId) {
            if (this.projectUsers[userId]) {
                return Promise.resolve(this.projectUsers[userId]);
            }

            const user = await (this.userService.getProjectUser(this.currentProject.id, userId) as Promise<User>);
            this.projectUsers[userId] = user;
            return user;
        } else {
            return Promise.resolve(null);
        }
    }

    public loadAllProjects(): void {
        this.projectService.getProjects().then(projects => {
            this.allProjectsStream.next(projects);
        });
    }

    public getUserWithRolesForCurrentProject(userId: string, tiduuid?: string): Promise<User> {
        if (sessionStorage.getItem(ACCESS_MODE_KEY) && sessionStorage.getItem(ACCESS_MODE_KEY) === AccessMode.SHARED) {
            let user = new User();
            user.role = UserRole.USER;
            return Promise.resolve(user);
        }
        if (this.currentProjectUserRoles[userId] && this.currentProjectUserRoles[userId].role) {
            return Promise.resolve(this.currentProjectUserRoles[userId]);
        } else {
            return (this.userService.getProjectUser(this.currentProject.id, userId, tiduuid) as Promise<User>).then(
                user => {
                    this.currentProjectUserRoles[userId] = user;
                    this.projectUsers[userId] = user;
                    return user;
                }
            );
        }
    }

    public cacheProjectUsersAndGroups(currentProject: Project): void {
        Promise.all([
            this.projectService.getProjectGroups(currentProject.id, 0, 25),
            this.projectService.getProjectUsers(currentProject.id, 0, 25)
        ]).then(response => {
            sessionStorage.setItem('project-' + currentProject.id + '-groups', JSON.stringify(response[0]));
            if (response[0][0] && response[0][0].totalItems > 25) {
                const groupsRequest = [];
                for (let i = 25; i < response[0][0].totalItems; i = i + 25) {
                    groupsRequest.push(this.projectService.getProjectGroups(currentProject.id, i, 25));
                }
                Promise.all(groupsRequest).then(groupsResponse => {
                    let previousGroups: Actor[] = JSON.parse(
                        sessionStorage.getItem('project-' + currentProject.id + '-groups')
                    );
                    groupsResponse.forEach(pageGroups => {
                        previousGroups = previousGroups.concat(pageGroups);
                    });
                    sessionStorage.setItem('project-' + currentProject.id + '-groups', JSON.stringify(previousGroups));
                });
            }
            sessionStorage.setItem('project-' + currentProject.id + '-users', JSON.stringify(response[1]));
            if (response[1][0] && response[1][0].totalItems > 25) {
                const usersRequest = [];
                for (let j = 25; j < response[1][0].totalItems; j = j + 25) {
                    usersRequest.push(this.projectService.getProjectUsers(currentProject.id, j, 25));
                }
                Promise.all(usersRequest).then(usersResponse => {
                    let previousUsers: Actor[] = JSON.parse(
                        sessionStorage.getItem('project-' + currentProject.id + '-users')
                    );
                    usersResponse.forEach(pageUsers => {
                        previousUsers = previousUsers.concat(pageUsers);
                    });
                    sessionStorage.setItem('project-' + currentProject.id + '-users', JSON.stringify(previousUsers));
                });
            }
        });
    }

    public getProjectUsersFromCache() {
        return JSON.parse(sessionStorage.getItem('project-' + this.currentProject.id + '-users')) as User[];
    }
}
