<div class="color-picker-wrapper">
    <div
        class="color-picker-container {{ uniqueClass }}"
        (click)="isOpen = !isOpen; $event.stopPropagation()"
        *ngIf="colorPickerType === ColorPickerType.DROPDOWN"
    >
        <div class="tag-container" [ngStyle]="{ 'background-color': value }">
            <i class="i32 icon_line_arrow_down"></i>
        </div>
        <ul
            #list
            class="color-picker color-picker-dropdown"
            [ngClass]="{ hide: !isOpen, vertical: isVertical, horizontal: isHorizontal }"
            [ngStyle]="isStatic ? { width: colorPickerWidth, height: colorPickerHeight } : {}"
        >
            <li
                #listItems
                class="box"
                *ngFor="let color of colors"
                [ngStyle]="
                    isStatic
                        ? { 'background-color': color.hexCode, width: iconSize + 'px', height: iconSize + 'px' }
                        : { 'background-color': color.hexCode }
                "
                [ngClass]="{
                    hover: tempSelectedColor == color.hexCode,
                    selected: value?.toUpperCase() === color.hexCode
                }"
                (click)="selectColor($event, color.hexCode)"
            >
                <i [class]="getDropdownIconClass(color)">&nbsp;</i>
            </li>
        </ul>
    </div>
    <div class="color-picker-container {{ uniqueClass }}" *ngIf="colorPickerType === ColorPickerType.DEFAULT">
        <ul
            #list
            class="color-picker"
            [ngClass]="{ vertical: isVertical, horizontal: isHorizontal }"
            [ngStyle]="isStatic ? { width: colorPickerWidth, height: colorPickerHeight } : {}"
            (keydown)="keyboardListener($event)"
        >
            <li
                #listItems
                class="box"
                *ngFor="let color of colors"
                [ngStyle]="
                    isStatic
                        ? { 'background-color': color.hexCode, width: iconSize + 'px', height: iconSize + 'px' }
                        : { 'background-color': color.hexCode }
                "
                [ngClass]="{
                    hover: tempSelectedColor == color.hexCode,
                    selected: value?.toUpperCase() === color.hexCode
                }"
                (click)="selectColor($event, color.hexCode)"
            >
                <i class="i24" [ngClass]="{ icon_line_white_tick: value?.toUpperCase() === color.hexCode }">&nbsp;</i>
            </li>
        </ul>
    </div>
</div>
