<gsp-modal
    [size]="ModalSize.Large"
    [heading]="'MapViewer.RTC.ConfigDialog.Header'"
    (closed)="cancelRtc()"
    id="rtc-configuration-popup"
>
    <ng-container transclude-popup-content>
        <div class="rtc-configuration flex-row-new align-start">
            <custom-scroll autoHeightDisable="true" class="height-full">
                <div class="flex7-new rtc-configuration__options">
                    <p
                        class="mb-2"
                        [innerHTML]="
                            'MapViewer_RTC_Configuration_Description' | translate: { url: rtcConfigurationLink }
                        "
                    ></p>

                    <ng-form #rtcForm="ngForm">
                        <div class="rtc-configuration-form-group flex-row-new">
                            <div class="flex3-new py-0">
                                <h4>{{ 'Common.Name' | translate }}</h4>
                            </div>
                            <div class="flex9-new py-0">
                                <gsp-text
                                    #name="ngModel"
                                    [type]="GspTextType.GENERIC"
                                    id="name"
                                    name="name"
                                    [(ngModel)]="editingRtc.name"
                                    required="true"
                                    focusElement="true"
                                ></gsp-text>
                            </div>
                        </div>
                        <div class="rtc-configuration-form-group flex-row-new mb-0">
                            <div class="flex3-new py-0">
                                <h4>{{ 'TC.Common.Description' | translate }}</h4>
                            </div>
                            <div class="flex9-new py-0">
                                <gsp-text-area
                                    [(value)]="editingRtc.description"
                                    name="description"
                                    [placeholder]="'description' | translate"
                                ></gsp-text-area>
                            </div>
                        </div>
                        <div class="rtc-configuration-form-group flex-row-new mb-0">
                            <div class="flex3-new py-0">
                                <h4>{{ 'mapviewer.rtc.labels.source_type' | translate }}</h4>
                            </div>
                            <div class="flex9-new py-0">
                                <div class="flex-row-new">
                                    <div class="flex8-new">
                                        <gsp-dropdown
                                            name="source"
                                            [options]="sourceTypes"
                                            [(ngModel)]="editingRtc.sourceType1"
                                            (ngModelChange)="onSourceType1Change(editingRtc.sourceType1)"
                                            idField="id"
                                            textField="name"
                                            id="rtc-source-type"
                                            [ngDisabled]="originalRtc.isExistingRtc()"
                                        ></gsp-dropdown>
                                    </div>
                                    <div class="flex4-new">
                                        <gsp-button
                                            [type]="ButtonType.Icon"
                                            buttonTooltip="{{ 'MapViewer_RTC_Configure' | translate }}"
                                            text="{{ 'MapViewer_RTC_Configure' | translate }}"
                                            iconClass="i32 icon-settings"
                                            (action)="onConfigurationStart(editingRtc.sourceType1)"
                                            *ngIf="canConfigure(editingRtc.sourceType1)"
                                            id="configure-source-type"
                                        >
                                        </gsp-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="rtc-configuration-form-group flex-row-new">
                            <div class="flex3-new py-0"></div>
                            <div class="flex9-new py-0">
                                <ng-container *ngIf="editingRtc.sourceType1 === RtcSourceTypes.SBAS">
                                    <gsp-notification [type]="NotificationType.Info">
                                        <div
                                            class="messages"
                                            [innerHTML]="'MapViewer_RTC_SBAS_description' | translate"
                                        ></div>
                                    </gsp-notification>
                                </ng-container>

                                <ng-container *ngIf="editingRtc.sourceType1 === RtcSourceTypes.TRIMBLE_RTX">
                                    <gsp-notification [type]="NotificationType.Info">
                                        <div
                                            class="messages"
                                            [innerHTML]="
                                                'MapViewer_RTC_TrimbleRTX_Description'
                                                    | translate: { link: rtcTrimbleRTXInfoLink }
                                            "
                                        ></div>
                                    </gsp-notification>
                                </ng-container>

                                <ng-container *ngIf="editingRtc.sourceType1 === RtcSourceTypes.SATELLITE_RTX">
                                    <gsp-notification [type]="NotificationType.Info">
                                        <div
                                            class="messages"
                                            [innerHTML]="
                                                'MapViewer_RTC_SatelliteRTX_Description'
                                                    | translate: { link: rtcSatelliteRTXInfoLink }
                                            "
                                        ></div>
                                    </gsp-notification>
                                </ng-container>

                                <ng-container *ngIf="editingRtc.sourceType1 === RtcSourceTypes.INTERNET">
                                    <gsp-notification
                                        [type]="NotificationType.Warning"
                                        *ngIf="!isValidInternetConfig()"
                                    >
                                        <div class="messages">
                                            {{ 'MapViewer_RTC_Internet_NotConfigured' | translate }}
                                        </div>
                                    </gsp-notification>

                                    <gsp-notification [type]="NotificationType.Info">
                                        <div
                                            class="messages"
                                            [innerHTML]="
                                                'MapViewer_RTC_Internet_Description'
                                                    | translate: { link: rtcInternetInfoLink }
                                            "
                                        ></div>
                                    </gsp-notification>

                                    <gsp-notification
                                        [type]="NotificationType.Info"
                                        *ngIf="originalRtc.isExistingRtc()"
                                    >
                                        <div class="messages">
                                            {{ 'MapViewer_RTC_NonEditable_Sources' | translate }}
                                        </div>
                                    </gsp-notification>
                                </ng-container>

                                <ng-container *ngIf="editingRtc.sourceType1 === RtcSourceTypes.SERIAL">
                                    <gsp-notification [type]="NotificationType.Warning" *ngIf="!isValidSerialConfig()">
                                        <div class="messages">
                                            {{ 'MapViewer_RTC_Serial_NotConfigured' | translate }}
                                        </div>
                                    </gsp-notification>

                                    <gsp-notification [type]="NotificationType.Info">
                                        <div
                                            class="messages"
                                            [innerHTML]="'MapViewer_RTC_Serial_Description' | translate"
                                        ></div>
                                    </gsp-notification>

                                    <gsp-notification
                                        [type]="NotificationType.Info"
                                        *ngIf="originalRtc.isExistingRtc()"
                                    >
                                        <div class="messages">
                                            {{ 'MapViewer_RTC_NonEditable_Sources' | translate }}
                                        </div>
                                    </gsp-notification>
                                </ng-container>
                            </div>
                        </div>

                        <div
                            class="rtc-configuration-form-group flex-row-new mb-0"
                            *ngIf="editingRtc.sourceType1 !== RtcSourceTypes.SBAS"
                        >
                            <div class="flex3-new py-0">
                                <h4>{{ 'MapViewer_RTC_Fallback_Source' | translate }}</h4>
                            </div>
                            <div class="flex9-new py-0">
                                <gsp-dropdown
                                    name="secondarySource"
                                    [options]="secondarySourceTypes"
                                    [(ngModel)]="editingRtc.sourceType2IsSbas"
                                    textField="name"
                                    id="rtc-secondary-source"
                                    [ngDisabled]="editingRtc.isLegacyRtc()"
                                    [shouldDropup]="true"
                                ></gsp-dropdown>
                            </div>
                        </div>
                    </ng-form>
                </div>
            </custom-scroll>
            <div class="flex5-new rtc-configuration__workspaces">
                <h4 class="font-title">{{ 'MapViewer_RTC_AssignTo_Workspaces' | translate }}</h4>
                <p class="description">{{ 'MapViewer_RTC_Workspaces_Assignment_Description' | translate }}</p>
                <custom-scroll class="height-full">
                    <ul class="rtc-workspaces-content share-all">
                        <li class="item">
                            <gsp-checkbox
                                [(value)]="editingRtc.isGlobal"
                                [helpText]="'MapViewer_RTC_AllCompatibleWorkspaces_HelpText' | translate"
                                [id]="'workspaceAll'"
                                [label]="'TCS.Mapviewer.Rtc.Labels.AllMapWorkspaces' | translate"
                                (valueChange)="toggleAllCompatible()"
                            ></gsp-checkbox>
                        </li>
                    </ul>
                    <ul class="rtc-workspaces-content" [ngClass]="{ 'show-inactive': editingRtc.isGlobal }">
                        <li
                            class="rtc-workspaces-item"
                            [ngClass]="{
                                'show-inactive': !workspace.compatible || editingRtc.isGlobal
                            }"
                            *ngFor="let workspace of rtcWorkspaces; index as i; trackBy: indexTrack"
                        >
                            <gsp-checkbox
                                [disabled]="!workspace.compatible || editingRtc.isGlobal"
                                [hidden]="!workspace.workspace.id || workspace.isArchived"
                                [id]="'workspace' + workspace.workspace.id"
                                [label]="workspace.workspace.name"
                                [(value)]="workspace.assigned"
                            >
                                <span class="badge badge--hollow ml-1" *ngIf="!workspace.compatible">{{
                                    'MapViewer_RTC_Incompatible' | translate
                                }}</span>
                            </gsp-checkbox>
                        </li>
                    </ul>
                </custom-scroll>
            </div>
            <rtc-internet
                *ngIf="isInternetConfigurationInProgress"
                [originalRtc]="originalRtc"
                [editingRtc]="editingRtc"
                (closed)="onConfigurationDone(editingRtc.sourceType1, $event)"
            ></rtc-internet>

            <rtc-serial-port
                *ngIf="isSerialConfigurationInProgress"
                [originalRtc]="originalRtc"
                [editingRtc]="editingRtc"
                (closed)="onConfigurationDone(editingRtc.sourceType1, $event)"
            ></rtc-serial-port>
        </div>
    </ng-container>

    <ng-container transclude-popup-footer>
        <gsp-button
            [type]="ButtonType.Primary"
            (action)="saveRtc()"
            [disabled]="!canSave || isSaveInProgress"
            text="{{ 'TC.Common.Save' | translate }}"
            id="rtc-serial-save"
        >
        </gsp-button>
        <gsp-button
            [type]="ButtonType.Default"
            (action)="cancelRtc()"
            text="{{ 'TC.Common.Cancel' | translate }}"
            id="rtc-serial-cancel"
        ></gsp-button>
    </ng-container>
</gsp-modal>
