<div class="flex-row-new workspace-creation-name">
    <div class="flex9-new">
        <workspace-name
            [modifyWorkspace]="modifyWorkspace"
            (isNameValid)="onValidationChange($event)"
            [checkDuplicateOnDirty]="false"
        ></workspace-name>
    </div>

    <div class="flex3-new"></div>
</div>
