export class FieldErrors {
    static readonly errorMessages: { [key: string]: any } = {
        required: {
            message: 'TC.Common.FieldRequired'
        },
        maxlength: {
            message: 'TCS.TemplateEditor.Validate.MaxLength',
            placeHolder: ['maxLength']
        },
        max: {
            message: 'TCS.TemplateEditor.Validate.LessThanOrEqualTo',
            placeHolder: ['maximum'],
            errorSwitch: {
                required: ['minimum', 'maximum'],
                newError: 'minmax'
            }
        },
        maxDate: {
            message: 'TCS.TemplateEditor.Validate.LessThanOrEqualTo',
            placeHolder: ['maximum'],
            errorSwitch: {
                required: ['minimum', 'maximum'],
                newError: 'minmax'
            }
        },
        min: {
            message: 'TCS.TemplateEditor.Validate.GreaterThanEqualTo',
            placeHolder: ['minimum'],
            errorSwitch: {
                required: ['minimum', 'maximum'],
                newError: 'minmax'
            }
        },
        minDate: {
            message: 'TCS.TemplateEditor.Validate.GreaterThanEqualTo',
            placeHolder: ['minimum'],
            errorSwitch: {
                required: ['minimum', 'maximum'],
                newError: 'minmax'
            }
        },
        minmax: {
            message: 'TCS.TemplateEditor.Validate.NumberBetweenRangeError',
            placeHolder: ['minimum', 'maximum']
        },
        minimumEntries: {
            message: 'TC.Common.MustHaveChoice'
        },
        unique: {
            message: 'TC.Common.UniqueChoices'
        },
        uniqueCode: {
            message: 'TC.Common.UniqueCode'
        },
        decimal: {
            message: 'TC.Common.MaxDecimals'
        },
        pattern: {
            message: 'TCS.TextNotMatchPattern'
        },
        autoIncTntLimit: {
            message: 'TCS.Maps.AutoIncIntLimit'
        },
        emptyChoice: {
            message: 'TemplateEditor.Error.ChoiceEmpty'
        },
        emptyCode: {
            message: 'TemplateEditor.Error.CodeEmpty'
        }
    };
}
