<div class="main-menu" [hidden]="hideMenu" [ngClass]="{ 'ng-leave': closing }">
    <div class="main-menu-tabs">
        <a [href]="connectUrl" class="logo" target="_blank">
            <img class="connect-logo" src="assets/images/logo.svg" alt="Trimble Connect logo" />
        </a>
        <div class="content">
            <ul class="list-content">
                <li
                    class="list-item"
                    (click)="activateMenu(menu)"
                    [ngClass]="{ active: menu.active }"
                    *ngFor="let menu of menuList"
                >
                    <div class="nav-icon">
                        <i class="{{ menu.iconClass }} i32"></i>
                    </div>
                    <div class="list-item-title">
                        <span>{{ menu.iconName | translate }}</span>
                    </div>
                </li>
            </ul>
        </div>
        <div class="app-version">{{ version }}</div>
        <div class="copyright">&copy; 2024 Trimble Inc.</div>
    </div>

    <div class="first-panel" [@mainMenuSlide]="prepareRoute(outlet)" (@mainMenuSlide.start)="onAnimationEvent($event)">
        <header>
            <h2 translate="{{ selectedMenuTitle }}"></h2>
            <gsp-button
                [type]="ButtonType.Icon"
                class="close-button"
                (action)="close()"
                iconClass="icon_line_chevron_left i48"
            >
            </gsp-button>
        </header>

        <router-outlet name="mainMenu" #outlet="outlet"></router-outlet>
    </div>
    <div class="menu-overlay"></div>
</div>
