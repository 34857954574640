import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslationModule } from 'src/app/core/translation/translation.module';
import {
    PropertyIconsComponent,
} from 'src/app/feature/template-editor/template-composer/field-item/property-icons/property-icons.component';
import { CommonModule as AppCommonModule } from 'src/app/shared/common/common.module';

import { IndividualFieldComponent } from '../map-viewer/template-wizard/individual-field/individual-field.component';
import { TemplateWizardComponent } from '../map-viewer/template-wizard/template-wizard.component';
import { TemplateWizardService } from '../map-viewer/template-wizard/template-wizard.service';
import { DecodeURIPipe } from './components/decode-uri.pipe';
import { LabellerComponent } from './components/labeller/labeller.component';
import { RemoveDigitGroupPipe } from './components/remove-digit-group.pipe';
import { UpDownOrderComponent } from './components/up-down-order.component';
import { TemplateEditorComponent } from './editor/template-editor.component';
import { FieldPanelComponent } from './field-panel/field-panel.component';
import { LinksPanelComponent } from './links-panel/links-panel.component';
import { RulesPanelComponent } from './rules-panel/rules-panel.component';
import { SensorPopupComponent } from './sensor-popup/sensor-popup.component';
import { TabsListComponent } from './tabs-list/tabs-list.component';
import { ComposerHeaderComponent } from './template-composer/composer-header/composer-header.component';
import { ComposerWarningsComponent } from './template-composer/composer-warnings/composer-warnings.component';
import { FieldBaseComponent } from './template-composer/field-base.component';
import { FieldGroupComponent } from './template-composer/field-group/field-group.component';
import { FieldButtonsComponent } from './template-composer/field-item/field-buttons/field-buttons.component';
import { FieldIconComponent } from './template-composer/field-item/field-icon/field-icon.component';
import { FieldItemComponent } from './template-composer/field-item/field-item.component';
import { FieldNameComponent } from './template-composer/field-item/field-name/field-name.component';
import { FieldRequiredComponent } from './template-composer/field-item/field-required/field-required.component';
import { RuleButtonsComponent } from './template-composer/field-item/rule-buttons/rule-buttons.component';
import { TemplateUpdateComponent } from './template-composer/field-item/template-update/template-update.component';
import { FieldListComponent } from './template-composer/field-list/field-list.component';
import { FormFieldBaseComponent } from './template-composer/form-field-base.component';
import { ChoiceFieldComponent } from './template-composer/single-field/choice-field/choice-field.component';
import { ChoiceRuleComponent } from './template-composer/single-field/choice-rule/choice-rule.component';
import { CodedChoiceFieldComponent } from './template-composer/single-field/coded-choice-field/coded-choice-field.component';
import { DateFieldComponent } from './template-composer/single-field/date-field/date-field.component';
import { DateRangeFieldComponent } from './template-composer/single-field/date-range-field/date-range-field.component';
import { DateRuleComponent } from './template-composer/single-field/date-rule/date-rule.component';
import { DecimalPlacesValidatorDirective } from './template-composer/single-field/decimal-places.validator.directive';
import { DefaultRuleComponent } from './template-composer/single-field/default-rule/default-rule.component';
import { InformationFieldComponent } from './template-composer/single-field/information-field/information-field.component';
import { MaxAbsoluteValidatorDirective } from './template-composer/single-field/max-absolute-validator.directive';
import { MaxDateValidatorDirective } from './template-composer/single-field/max-date-validator.directive';
import { MaxValidatorDirective } from './template-composer/single-field/max-value-validator.directive';
import { MinDateValidatorDirective } from './template-composer/single-field/min-date-validator.directive';
import { MinValidatorDirective } from './template-composer/single-field/min-value.validator.directive';
import { MultilistFieldComponent } from './template-composer/single-field/multilist-field/multilist-field.component';
import { NumberFieldComponent } from './template-composer/single-field/number-field/number-field.component';
import { NumberRuleComponent } from './template-composer/single-field/number-rule/number-rule.component';
import { RangeFieldComponent } from './template-composer/single-field/range-field/range-field.component';
import { SingleFieldComponent } from './template-composer/single-field/single-field.component';
import {
    SingleSelectionFieldComponent,
} from './template-composer/single-field/single-selection-field/single-selection-field.component';
import { TextFieldComponent } from './template-composer/single-field/text-field/text-field.component';
import { TextRuleComponent } from './template-composer/single-field/text-rule/text-rule.component';
import { YesNoRuleComponent } from './template-composer/single-field/yes-no-rule/yes-no-rule.component';
import { TemplateComposerComponent } from './template-composer/template-composer.component';
import {
    TemplateSchemaLockedWarningComponent,
} from './template-composer/template-warnings/template-schema-locked-warning.component';
import { TEMPLATE_EDITOR_CONFIG, templateEditorConfig } from './template-editor-config';
import { TemplateEditorPageComponent } from './template-editor-page.component';
import { TemplateEditorRoutingModule } from './template-editor-routing.module';
import { TemplatePanelComponent } from './template-panel/template-panel.component';

@NgModule({
    declarations: [
        MaxAbsoluteValidatorDirective,
        MaxValidatorDirective,
        MinValidatorDirective,
        DecimalPlacesValidatorDirective,
        MaxDateValidatorDirective,
        MinDateValidatorDirective,
        TemplatePanelComponent,
        TemplateComposerComponent,
        FieldListComponent,
        SingleFieldComponent,
        ChoiceFieldComponent,
        CodedChoiceFieldComponent,
        ChoiceRuleComponent,
        LabellerComponent,
        ComposerWarningsComponent,
        ComposerHeaderComponent,
        FieldGroupComponent,
        FieldItemComponent,
        FieldButtonsComponent,
        FieldIconComponent,
        PropertyIconsComponent,
        FieldNameComponent,
        FieldRequiredComponent,
        RuleButtonsComponent,
        TemplateUpdateComponent,
        DateFieldComponent,
        DateRuleComponent,
        DefaultRuleComponent,
        InformationFieldComponent,
        MultilistFieldComponent,
        NumberRuleComponent,
        NumberFieldComponent,
        RangeFieldComponent,
        DateRangeFieldComponent,
        SingleSelectionFieldComponent,
        TextFieldComponent,
        TextRuleComponent,
        YesNoRuleComponent,
        SingleFieldComponent,
        FieldBaseComponent,
        FormFieldBaseComponent,
        LinksPanelComponent,
        FieldPanelComponent,
        RulesPanelComponent,
        TabsListComponent,
        TemplateEditorComponent,
        UpDownOrderComponent,
        TemplateEditorPageComponent,
        TemplateWizardComponent,
        IndividualFieldComponent,
        DecodeURIPipe,
        RemoveDigitGroupPipe,
        TemplateSchemaLockedWarningComponent,
        SensorPopupComponent
    ],
    imports: [
        DragDropModule,
        CommonModule,
        FormsModule,
        TranslationModule,
        AppCommonModule,
        TemplateEditorRoutingModule
    ],
    exports: [
        TemplateEditorRoutingModule,
        MinValidatorDirective,
        MaxValidatorDirective,
        DecimalPlacesValidatorDirective
    ],
    providers: [{ provide: TEMPLATE_EDITOR_CONFIG, useValue: templateEditorConfig }, TemplateWizardService]
})
export class TemplateEditorModule {}
