import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: '[noEmptyChoices]',
    providers: [{ provide: NG_VALIDATORS, useExisting: NoEmptyChoicesValidatorDirective, multi: true }]
})
export class NoEmptyChoicesValidatorDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors {
        if (control?.value) {
            // choice field
            if (control.value[0]?.hasOwnProperty('text')) {
                if (this.isEmptyChoiceField(control.value)) {
                    return { emptyChoice: true };
                }

                return null;
            }

            // coded choice field
            if (control.value[0]?.hasOwnProperty('code') && control.value[0]?.hasOwnProperty('description')) {
                const multiEmpty = [this.isEmptyCodeField(control.value), this.isEmptyCodedChoiceField(control.value)];

                let messageMatrix = { emptyCode: false, emptyChoice: false };

                messageMatrix.emptyCode = !!multiEmpty[0] ? true : false;
                messageMatrix.emptyChoice = !!multiEmpty[1] ? true : false;

                if (multiEmpty.some(prop => prop === true)) {
                    return this.existingMessageOnly(messageMatrix);
                }

                return null;
            }
        }

        return null;
    }

    private isEmptyCodedChoiceField(controlValue: any[]): boolean {
        return (
            controlValue[0]?.hasOwnProperty('description') &&
            !!controlValue.find((value: { description: string; default: boolean }) => !value?.description.trim())
        );
    }

    private isEmptyCodeField(controlValue: any[]): boolean {
        return (
            controlValue[0]?.hasOwnProperty('code') &&
            !!controlValue.find((value: { code: string; default: boolean }) => !value?.code.trim())
        );
    }

    private isEmptyChoiceField(controlValue: any[]): boolean {
        return (
            controlValue[0]?.hasOwnProperty('text') &&
            !!controlValue.find((value: { text: string; default: boolean }) => !value?.text.trim())
        );
    }

    private existingMessageOnly(obj: {}) {
        const objAsArray = Object.entries(obj);
        const allTruthyValues = objAsArray.filter(([key, value]) => value === true);
        return Object.fromEntries(allTruthyValues);
    }
}
