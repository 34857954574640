<div class="workspace-coordinate-system-details">
    <loading *ngIf="loading" class="loading-align-center"></loading>
    <div class="details" *ngIf="!loading">
        <h4 class="sub-heading font-title">
            {{ 'MapViewer.Workspace.CoordinateSystem.Label' | translate }}
        </h4>

        <div *ngIf="subtitle" class="mb-2">{{ subtitle }}</div>

        <ng-container>
            <div class="section">
                <div class="title">{{ 'MapViewer.Workspace.Projection.Label' | translate }}</div>
                <p *ngIf="!workspace.noProjection()">{{ zoneGroup?.displayName }}</p>
                <p *ngIf="workspace.noProjection()">{{ 'MapViewer.Generic.LatLng' | translate }}</p>
            </div>

            <div class="group" *ngIf="!workspace.noProjection()">
                <div class="item">
                    <div class="title">
                        {{ 'MapViewer.Workspace.CoordinateSystem.Zone.Label' | translate }}
                    </div>
                    <p>{{ zone?.displayName }}</p>
                </div>

                <div class="unit">
                    <div class="title">{{ 'TC.Common.Unit' | translate }}</div>
                    <p>{{ horizontalUnit?.displayName | translate }}</p>
                </div>
            </div>
        </ng-container>

        <div class="section">
            <div class="title">{{ 'TCS.Mapviewer.Rtc.Messages.Datum' | translate }}</div>
            <p>{{ datum?.displayName }}</p>
        </div>

        <div [ngClass]="{ group: !canEditHeight }">
            <div class="item" *ngIf="!canEditHeight">
                <div class="title">
                    {{
                        (geoid
                            ? 'MapViewer.Workspace.CoordinateSystem.Geoid.Label'
                            : 'TCS.ModelDefinitionEditor.SymbolFields.Height'
                        ) | translate
                    }}
                </div>
                <p>
                    {{
                        geoid
                            ? geoid.displayName
                            : ('MapViewer.Workspace.CoordinateSystem.AboveSeaLevel.Title' | translate)
                    }}
                </p>
            </div>
            <div class="height-selection-container" *ngIf="canEditHeight">
                <div class="title">
                    {{ 'MapViewer.Workspace.CoordinateSystem.Geoid.Label' | translate }}
                </div>
                <gsp-dropdown
                    name="heightSelection"
                    [options]="heightOptions"
                    [(ngModel)]="selectedHeightOption"
                    (ngModelChange)="onHeightChange($event)"
                    textField="displayName"
                    idField="id"
                    id="height-selection"
                ></gsp-dropdown>
            </div>

            <div class="unit">
                <div class="title">{{ 'TC.Common.Unit' | translate }}</div>
                <p>{{ verticalUnit?.displayName | translate }}</p>
            </div>

            <gsp-notification
                *ngIf="warningMessageOnHeightChange && geoid?.componentID !== selectedHeightOption"
                [type]="NotificationType.Warning"
            >
                <div [innerHTML]="warningMessageOnHeightChange"></div>
            </gsp-notification>
        </div>
    </div>
</div>
