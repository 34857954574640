import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';
import { UtilitiesService } from 'src/app/shared/common/utility/utilities.service';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { MapWorkspacePermissionType } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace-permission';

@Component({
    selector: 'workspace-detail',
    templateUrl: './workspace-detail.component.html'
})
export class WorkspaceDetailComponent implements OnInit, OnDestroy {
    public focused = false;
    public workspaceName = '';
    public currentWorkspaceBeforeSave: MapWorkspace;

    private isSaving = false;
    private readonly destroyed = new Subject<void>();

    // exposing enum to template
    public MapWorkspacePermissionType = MapWorkspacePermissionType;

    @ViewChild('workspaceDetailForm')
    public form: NgForm;

    @ViewChild('workspaceDetailFormWrapper')
    public workspaceDetailFormWrapper: ElementRef;

    constructor(
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private messaging: MessagingService,
        private utilities: UtilitiesService,
        private translate: TranslationService
    ) {}

    ngOnInit(): void {
        this.mapWorkspacesStore.currentMapWorkspaceStream
            .pipe(takeUntil(this.destroyed))
            .subscribe(currentWorkspace => {
                if (currentWorkspace) {
                    this.currentWorkspaceBeforeSave = CloneUtils.cloneDeep(currentWorkspace);
                    this.workspaceName = currentWorkspace.name;
                }
            });

        // update the currentWorkspace if the updated map workspace in the workspace panel is current workspace
        this.mapWorkspacesStore.projectMapWorkspacesStream.pipe(takeUntil(this.destroyed)).subscribe(workspaces => {
            if (workspaces && this.currentWorkspaceBeforeSave) {
                workspaces.forEach(workspace => {
                    if (workspace.id === this.currentWorkspaceBeforeSave.id) {
                        this.currentWorkspaceBeforeSave = CloneUtils.cloneDeep(workspace);
                        this.workspaceName = workspace.name;
                    }
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    public saveCurrentWorkspace(): void {
        this.focused = false;
        if (!this.workspaceName) {
            this.messaging.showError(this.translate.instant('MapViewer.Workspace.EmptyName'));
            this.workspaceName = this.currentWorkspaceBeforeSave.name;
            return;
        }
        if (!this.isSaving && this.workspaceName !== this.currentWorkspaceBeforeSave.name) {
            const errorMessage = this.getErrorText();
            if (errorMessage) {
                this.isSaving = true;
                this.resetForm();
                this.workspaceName = this.currentWorkspaceBeforeSave.name;
                this.messaging.showError(errorMessage);
                this.isSaving = false;
            } else {
                const updatedWorkspace = CloneUtils.cloneDeep(this.currentWorkspaceBeforeSave);
                updatedWorkspace.name = this.workspaceName;
                this.isSaving = true;
                this.resetForm();
                this.mapWorkspacesStore
                    .updateMapWorkspace(updatedWorkspace)
                    .then((result: { succeeded: boolean; message: string; updatedMapWorkspace: MapWorkspace }) => {
                        if (!result.succeeded) {
                            this.workspaceName = this.currentWorkspaceBeforeSave.name;
                            this.messaging.showError(result.message);
                        } else {
                            this.currentWorkspaceBeforeSave = CloneUtils.cloneDeep(result.updatedMapWorkspace);
                            this.workspaceName = this.currentWorkspaceBeforeSave.name;
                            this.messaging.showSuccess(result.message);
                        }
                        this.isSaving = false;
                    });
            }
        } else {
            this.workspaceName = this.currentWorkspaceBeforeSave.name;
        }
    }

    public goToTrimbleConnect(): void {
        let currentMapWorkspace = this.mapWorkspacesStore.getCurrentMapWorkspace();
        let folderUrl =
            this.utilities.getTCAppUrl() +
            'project/' +
            currentMapWorkspace.projectId +
            '/data/folder/' +
            currentMapWorkspace.connectFolderId;
        window.open('' + folderUrl, '_blank');
    }

    public onFocus(): void {
        this.focused = true;
    }

    public getErrorText(): string {
        const errors = this.form.controls['name'].errors;
        const errorMessage = MapWorkspace.getWorkspaceNameValidationError(errors);
        return errorMessage ? this.translate.instant(errorMessage) : null;
    }

    private resetForm(): void {
        this.workspaceDetailFormWrapper.nativeElement.children[0].children[0].setSelectionRange(0, 0);
        this.workspaceDetailFormWrapper.nativeElement.children[0].children[0].blur();
    }
}
