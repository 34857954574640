import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

export const oldRouteRedirectGuard: CanActivateFn = async (
    route: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot,
    router: Router = inject(Router)
) => {
    let tree = router.parseUrl('mapViewer');
    tree.queryParams = { workspaceId: route.params.workspaceId, projectId: route.params.projectId };

    return tree;
};
