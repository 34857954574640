export enum KeyCodes {
    BACKSPACE = 'Backspace',
    TAB = 'Tab',
    ENTER = 'Enter',
    SHIFT = 'Shift',
    CTRL = 'Control',
    ALT = 'Alt',
    PAUSE = 'Pause',
    CAPS_LOCK = 'CapsLock',
    ESCAPE = 'Escape',
    SPACE = ' ',
    PAGE_UP = 'PageUp',
    PAGE_DOWN = 'PageDown',
    END = 'End',
    HOME = 'Home',
    LEFT_ARROW = 'ArrowLeft',
    UP_ARROW = 'ArrowUp',
    RIGHT_ARROW = 'ArrowRight',
    DOWN_ARROW = 'ArrowDown',
    NUM_LOCK = 'NumLock',
    SCROLL_LOCK = 'ScrollLock',
    DOT = '.',
    HYPHEN = '-'
}
