import { Component, ElementRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, NgControl, NgModel } from '@angular/forms';
import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

import { ValueAccessorBase } from '../value-accessor-base';

export enum GspNumberType {
    GENERIC = 'GENERIC',
    DECIMAL = 'DECIMAL',
    PORT = 'PORT'
}

@Component({
    selector: 'gsp-number',
    templateUrl: './gsp-number.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: GspNumberComponent,
            multi: true
        },
        { provide: NG_VALIDATORS, useExisting: GspNumberComponent, multi: true }
    ]
})
export class GspNumberComponent extends ValueAccessorBase<any> implements OnInit, OnDestroy {
    @Input()
    type = GspNumberType.GENERIC;

    @Input()
    public id = 'number-input';

    @Input()
    public inputClass: string;

    @Input()
    public inputNgClass: object;

    @Input()
    public name = 'number-input';

    @Input()
    public placeholder = '';

    @Input()
    public title = '';

    @Output()
    public valueChange = new EventEmitter<Event>();

    @Output()
    public focusEvent = new EventEmitter<Event>();

    @Output()
    public blurEvent = new EventEmitter<Event>();

    @Output()
    public keyupEvent = new EventEmitter<Event>();

    @Output()
    public keydownEvent = new EventEmitter<Event>();

    @Input()
    public disabled = false;

    @Input()
    public readonly = false;

    @Input()
    public required = false;

    @Input()
    public errorTooltipMsg = '';

    @Input()
    public autoComplete = 'on';

    @Input()
    decimalPlaces = 0;

    @Input()
    digitGroup = false;

    @Input()
    min = -999999999999999;

    @Input()
    max = 999999999999999;

    @ViewChild('inputRef', { static: false })
    inputRef: NgModel;

    @ViewChild('inputRef', { read: ElementRef, static: false })
    inputHtmlRef: ElementRef<HTMLInputElement>;

    private destroyed = new Subject<void>();

    // exposing enum to template
    public GspNumberType = GspNumberType;

    constructor(private injector: Injector) {
        super();
    }

    public ngOnInit(): void {
        const model = this.injector.get(NgControl);
        const control = model.control;

        control.statusChanges.pipe(takeUntil(this.destroyed), delay(0)).subscribe(() => {
            if (!this.disabled) {
                this.inputRef.control.setErrors(control.valid ? null : { parentError: true });
            }
        });
    }

    public ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    validate() {
        return this.inputRef && this.inputRef.dirty ? this.inputRef.errors : null;
    }

    setFocus() {
        if (this.inputHtmlRef) {
            this.inputHtmlRef.nativeElement.focus();
        }
    }
}
