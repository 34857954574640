import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';

import { ContextMenuService } from '../../layout/context-menu.service';

export interface ContextMenuActions {
    name: string;
    title: boolean;
    id?: string;
    passive?: boolean;
    divider?: boolean;
    image?: string;
    itemClass?: string;
    labelClass?: string;
    iconClass?: string;
    visible($event: any): boolean;
    execute($event: any): any;
}

/**
 * gspContextMenu
 * This component should be used where context menus are required.
 *
 *
 * Example:
 * <gsp-context-menu
 *  #layersContextMenu
 *  [items]="contextMenuItems">
 * </gsp-context-menu>
 *
 * To open the context menu, call the onContextMenu() on the context menu identifier in
 * the component that has the menu.
 * <div (click)="layersContextMenu.onContextMenu($event)"><i class="icon-menu"></i></div>
 *
 * To open the context menu on right click, call the onContextMenu() using the contextMenu event
 * <div (contextmenu)="layersContextMenu.onContextMenu($event)"><i class="icon-menu"></i></div>
 */

@Component({
    selector: 'gsp-context-menu',
    templateUrl: './gsp-context-menu.component.html'
})
export class GspContextMenuComponent {
    @ViewChild(MatMenuTrigger) public trigger: MatMenuTrigger;
    @ViewChild(MatMenu, { static: true }) public menu: MatMenu;

    // By default, mat-menu has a backdrop which means to close one menu and open another requires
    // two clicks. To get around this, I'm disabling the backdrop and instead listening for any
    // clicks outside of the component here. This means you can switch menus with just one click.
    @HostListener('document:click', ['$event'])
    @HostListener('document:contextmenu', ['$event'])
    clickOut(event: MouseEvent) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            // Closes existing menu without opening new one if nothing is passed in.
            this.contextMenuService.toggleContextMenu();
        }
    }

    @Input() items: ContextMenuActions[] = [];

    @Input() menuClass = '';

    public position = { x: '0', y: '0' };
    public visibleItems: ContextMenuActions[] = [];

    constructor(private eRef: ElementRef, private contextMenuService: ContextMenuService) {}

    public onContextMenu(event: MouseEvent, data: any): void {
        // Show visible menu according to permission and exclude dividers
        this.visibleItems = this.items.filter(menuItem => menuItem.visible(data) && !menuItem.divider);
        this.menu.panelClass = this.menuClass;
        this.trigger.menuData = { item: data };

        event.preventDefault();
        event.stopPropagation();

        // Check the all visible items - if no visibleItems do not trigger popup
        if (this.visibleItems.length) {
            if (event.type === 'contextmenu') {
                // Display menu where user clicks
                this.position = { x: `${event.clientX}px`, y: `${event.clientY}px` };
            } else {
                // Anchor to element
                const target = (event.target as HTMLElement).getBoundingClientRect();
                this.position = { x: `${target.right}px`, y: `${target.bottom + (target.top - target.bottom) / 2}px` };
            }

            this.contextMenuService.toggleContextMenu(this.trigger);
        }
    }
}
