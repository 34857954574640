<div class="app-top-bar">
    <div class="partition" *ngIf="!showBackToMapViewer">
        <div class="trimble-connect-icon icon_trimble_connect"></div>
    </div>
    <div class="partition back-to-map-viewer" *ngIf="showBackToMapViewer" (click)="navigateToMapViewer()">
        <i class="i32 icon_line_chevron_left"></i>
        <h4>{{ 'MapViewer.AppTopBar.BackNavigation.Label' | translate }}</h4>
    </div>
    <div class="partition">
        <div class="workspace-details" *ngIf="currentWorkspace">
            <h3 title="{{ currentWorkspace.name }}" class="workspace-name">{{ currentWorkspace.name }}</h3>
            <span
                class="badge badge--primary ml-1 archive-badge"
                *ngIf="currentWorkspace.status === MapWorkspaceStatus.ARCHIVED"
                (click)="showArchiveWorkspaceInfo()"
            >
                <div class="archive-badge-content">
                    <span class="archive-label">{{ 'MapViewer.AppTopBar.WorkspaceStatus.Archived' | translate }}</span>
                    <span class="info-icon">
                        <i class="i16 icon-hint-info-white"></i>
                    </span>
                </div>
            </span>
            <span class="badge badge--secondary ml-1 filter-badge" *ngIf="isWorkspaceFilterActive && showFilterBadge">
                <div class="filter-badge-content">
                    {{ 'MapViewer.AppTopBar.WorkspaceStatus.FiltersOn' | translate }}
                </div>
            </span>
        </div>
    </div>
    <div class="partition">
        <div class="trimble-connect-icon app-top-bar-icons">
            <div
                *checkVisibility="{
                    visibleModes: [MapWorkspacePermissionType.READ, MapWorkspacePermissionType.FULL_ACCESS]
                }"
                class="tab-trigger activity-trigger-icon"
                [ngClass]="{ active: currentActiveIcon === ApplicationTopBarIcons.ACTIVITY_TAB }"
                (click)="toggleIconTabs(ApplicationTopBarIcons.ACTIVITY_TAB)"
                title="{{ 'MapViewer.Activity.Header' | translate }}"
            >
                <i
                    class="trigger-icon i24"
                    [ngClass]="{
                        'has-update': showActivityTabUpdate,
                        active: currentActiveIcon === ApplicationTopBarIcons.ACTIVITY_TAB
                    }"
                ></i>
            </div>
            <div
                *checkVisibility="{
                    visibleRoles: [UserRole.ADMIN]
                }"
                class="tab-trigger notification-tab-trigger-icon"
                [ngClass]="{ active: currentActiveIcon === ApplicationTopBarIcons.NOTIFICATION_TAB }"
                (click)="toggleIconTabs(ApplicationTopBarIcons.NOTIFICATION_TAB)"
                title="{{ 'TC.Common.Notifications' | translate }}"
            >
                <i
                    class="trigger-icon i24"
                    [ngClass]="{
                        'has-update': showNotificationTabUpdate,
                        active: currentActiveIcon === ApplicationTopBarIcons.NOTIFICATION_TAB
                    }"
                ></i>
            </div>
            <ng-container *ngIf="user?.tiduuid">
                <div
                    *checkVisibility="{
                        visibleRoles: [UserRole.ADMIN, UserRole.USER]
                    }"
                    class="tab-trigger user-tab-trigger-icon"
                    [ngClass]="{ active: currentActiveIcon === ApplicationTopBarIcons.USER_TAB }"
                    (click)="toggleIconTabs(ApplicationTopBarIcons.USER_TAB)"
                    title="{{ 'MapViewer.UserDetails.Header' | translate }}"
                >
                    <div class="profile-thumbnail-wrapper">
                        <img
                            *ngIf="user.thumbnail; else noUserThumbnail24"
                            id="user-thumbnail-i24"
                            [src]="user.thumbnail"
                        />
                        <ng-template #noUserThumbnail24>
                            <i
                                class="trigger-icon i24"
                                [ngClass]="{
                                    'has-update': showUserTabUpdate,
                                    active: currentActiveIcon === ApplicationTopBarIcons.USER_TAB
                                }"
                            ></i>
                        </ng-template>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<gsp-activity-tab
    *checkVisibility="{
        visibleModes: [MapWorkspacePermissionType.READ, MapWorkspacePermissionType.FULL_ACCESS]
    }"
    [showActivityTab]="currentActiveIcon === ApplicationTopBarIcons.ACTIVITY_TAB"
    (hideActivityTab)="toggleIconTabs(ApplicationTopBarIcons.ACTIVITY_TAB)"
    (activityUpdate)="toggleActivityTabUpdate()"
></gsp-activity-tab>
<gsp-notification-tab
    *checkVisibility="{
        visibleRoles: [UserRole.ADMIN]
    }"
    [showNotificationTab]="currentActiveIcon === ApplicationTopBarIcons.NOTIFICATION_TAB"
    (hideNotificationTab)="toggleIconTabs(ApplicationTopBarIcons.NOTIFICATION_TAB)"
    (notificationUpdate)="toggleNotificationTabUpdate()"
></gsp-notification-tab>
<gsp-user-tab
    *checkVisibility="{
        visibleRoles: [UserRole.ADMIN, UserRole.USER]
    }"
    [user]="user"
    [showUserTab]="currentActiveIcon === ApplicationTopBarIcons.USER_TAB"
    (hideUserTab)="toggleIconTabs(ApplicationTopBarIcons.USER_TAB)"
></gsp-user-tab>
<gsp-popup
    *ngIf="showArchiveWorkspaceInfoPopup"
    class="archive-workspace-info"
    [heading]="'MapViewer.Workspace.Section.ArchivedWorkspaces.Heading' | translate"
    [size]="ModalSize.Custom"
    [showClose]="true"
    (closed)="hideArchiveWorkspaceInfo()"
    [showDone]="true"
    [doneBtnTitle]="'MapViewer.Generic.GotIt' | translate"
    (done)="hideArchiveWorkspaceInfo()"
    id="archive-workspace-info"
>
    <ng-container transclude-popup-content>
        <div class="archive-workspace-info-message">
            <p>
                {{ 'MapViewer.AppTopBar.Info.ArchivedWorkspace.Description1' | translate }}
            </p>
            <p>
                {{ 'MapViewer.AppTopBar.Info.ArchivedWorkspace.Description2' | translate }}
            </p>
        </div>
    </ng-container>
</gsp-popup>
