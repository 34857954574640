import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CachedFeatureService } from 'src/app/shared/map-data-services/feature/cached-feature.service';
import { Feature, IFeature, IFeatureDTO } from 'src/app/shared/map-data-services/feature/feature';
import { FeatureService, IFeatureCollection } from 'src/app/shared/map-data-services/feature/feature.service';

import { MapWorkspacesStoreService } from '../current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from '../current-project/project-stream.service';
import { FeaturesStreamsService } from './features-streams.service';

@Injectable({
    providedIn: 'root'
})
export class FeaturesStore {
    removedFeatureStream = new BehaviorSubject<Feature>(null);

    constructor(
        private projectStream: ProjectStreamService,
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private featureService: FeatureService,
        private featuresStreamsService: FeaturesStreamsService,
        private cachedFeatureService: CachedFeatureService
    ) {}

    public async saveFeature(feature: IFeature): Promise<void> {
        const tmpFeature: IFeatureDTO = feature.toDTO();

        tmpFeature.metadata.collection_workspaceid = this.mapWorkspacesStore.getCurrentMapWorkspace().id;
        tmpFeature.metadata.collection_isNew = false;

        const featureCollection: IFeatureCollection = {
            type: 'FeatureCollection',
            features: [tmpFeature]
        };

        const patchedFeature = (
            await this.featureService.patchFeatureCollection(
                this.projectStream.getCurrentProject().id,
                featureCollection
            )
        ).features[0];

        const cachedFeature = this.cachedFeatureService.getFeature(feature.id);

        cachedFeature.editedOn = new Date().getTime();
        cachedFeature.metadata = patchedFeature.metadata;
        cachedFeature.properties = patchedFeature.properties;

        this.updateCachedFeature(feature);
    }

    public updateCachedFeature(feature: IFeature): void {
        const cachedFeature = this.cachedFeatureService.getFeature(feature.id);
        cachedFeature.tags = feature.tags;

        this.cachedFeatureService.addOrUpdateFeature(cachedFeature);

        const selectedFeatures = this.featuresStreamsService.selectedFeaturesStream.getValue();
        this.featuresStreamsService.selectedFeaturesStream.next(
            selectedFeatures.map(f => (f.id === feature.id ? cachedFeature : f))
        );
    }

    public async deleteFeature(feature: IFeature): Promise<void> {
        await this.featureService.deleteFeature(this.projectStream.getCurrentProject().id, feature.id);
        const tmpFeature = this.cachedFeatureService.getFeature(feature.id);
        tmpFeature.deleted = true;
        tmpFeature.inVisibleTask = false;
        tmpFeature.selected = false;
        tmpFeature.active = false;
        this.cachedFeatureService.addOrUpdateFeature(tmpFeature);
        this.removedFeatureStream.next(tmpFeature);
    }

    public async deleteAllFeature(features: Feature[]): Promise<void> {
        await this.featureService.batchDeleteFeatures(this.projectStream.getCurrentProject().id, features);
        features.forEach(feature => {
            const tmpFeature = this.cachedFeatureService.getFeature(feature.id);
            tmpFeature.deleted = true;
            tmpFeature.inVisibleTask = false;
            tmpFeature.selected = false;
            tmpFeature.active = false;
            this.cachedFeatureService.addOrUpdateFeature(tmpFeature);
            this.removedFeatureStream.next(tmpFeature);
        });
    }

    public async checkFeatureInSync(feature: IFeature): Promise<boolean> {
        // Checks whether any form updates in TerraFlex is pending to sync with MapViewer
        const latestFeature = await this.featureService.getFeatureById(
            this.projectStream.getCurrentProject().id,
            feature.id
        );

        return latestFeature.metadata.collection_utc === feature.metadata.collection_utc;
    }
}
