import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalSize } from 'src/app/shared/common/modal-sizes';

import { ButtonType } from '../buttons/button';

/**
 * GSP-POPUP
 * Use this component where you want a simple popup with some simple content in the body and
 * need a conditional save/cancel buttons in footer.
 */

@Component({
    selector: 'gsp-popup',
    templateUrl: './gsp-popup.component.html'
})
export class GspPopupComponent {
    @Input()
    size: ModalSize = ModalSize.ExtraSmall;

    @Input()
    showClose = false;

    @Input()
    showCancel = false;

    @Output()
    closed: EventEmitter<boolean> = new EventEmitter();

    @Input()
    showDone = false;

    @Output()
    done: EventEmitter<boolean> = new EventEmitter();

    @Input()
    doneBtnTitle = '';

    @Input()
    heading = '';

    @Input()
    disableDone = false;

    @Input()
    public id = '';

    @Input()
    customIcon = '';

    // expose enum to template
    public ModalSize = ModalSize;
    public ButtonType = ButtonType;

    public callExit(): void {
        this.closed.emit(true);
    }

    public callDone(): void {
        this.done.emit(true);
    }
}
