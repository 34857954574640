<div class="svg-icon">
    <ng-container [ngSwitch]="svg">
        <ng-container *ngSwitchCase="'sym_32-geom-line'">
            <svg x="0px" y="0px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                <path
                    [attr.fill]="color"
                    d="M40,26c-0.6,0-1.1,0.1-1.6,0.3l-4.8-4.8c0.2-0.5,0.3-1,0.3-1.6c0-2.2-1.8-4-4-4s-4,1.8-4,4c0,2.2,1.8,4,4,4
		c0.6,0,1.1-0.1,1.6-0.3l4.8,4.8c-0.2,0.5-0.3,1-0.3,1.6c0,0.3,0,0.6,0.1,0.9l-8,5.7C27.5,36.2,26.8,36,26,36c-2.2,0-4,1.8-4,4
		c0,2.2,1.8,4,4,4s4-1.8,4-4c0-0.3,0-0.6-0.1-0.9l8-5.7c0.6,0.4,1.3,0.6,2.1,0.6c2.2,0,4-1.8,4-4C44,27.8,42.2,26,40,26z"
                />
            </svg>
        </ng-container>
        <ng-container *ngSwitchCase="'sym_32-geom-point'">
            <svg x="0px" y="0px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" xml:space="preserve">
                <circle [attr.fill]="color" cx="32" cy="32" r="6" />
            </svg>
        </ng-container>
        <ng-container *ngSwitchCase="'sym_32-geom-poly'">
            <svg x="0px" y="0px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                <polygon opacity="0.5" [attr.fill]="color" points="31,21 45,29 35,41 21,41 21,29 		" />
                <path
                    [attr.fill]="color"
                    d="M46,24c-1,0-2,0.4-2.7,1l-7.4-4.3c0-0.2,0.1-0.5,0.1-0.7c0-2.2-1.8-4-4-4s-4,1.8-4,4c0,0.2,0,0.4,0.1,0.6
			l-6,3.9c-0.6-0.3-1.3-0.6-2-0.6c-2.2,0-4,1.8-4,4c0,1.5,0.8,2.8,2,3.4v7.1c-1.2,0.7-2,2-2,3.4c0,2.2,1.8,4,4,4
			c1.5,0,2.8-0.8,3.4-2h9.1c0.7,1.2,2,2,3.4,2c2.2,0,4-1.8,4-4c0-0.7-0.2-1.3-0.5-1.9l5.7-8.2C45.4,32,45.7,32,46,32
			c2.2,0,4-1.8,4-4C50,25.8,48.2,24,46,24z M36,38c-1.9,0-3.4,1.3-3.9,3h-8.3c-0.4-1.4-1.5-2.5-2.9-2.9v-6.3c1.7-0.4,3-2,3-3.9
			c0-0.4-0.1-0.7-0.2-1l5.8-3.8c0.7,0.5,1.5,0.8,2.4,0.8c0.9,0,1.7-0.3,2.3-0.8l7.7,4.5c0,0.1,0,0.2,0,0.3c0,0.8,0.3,1.6,0.7,2.2
			l-5.5,8C36.8,38.1,36.4,38,36,38z"
                />
            </svg>
        </ng-container>
        <ng-container *ngSwitchCase="'sym_32-geom-collection'">
            <svg x="0px" y="0px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                <circle fill-rule="evenodd" clip-rule="evenodd" [attr.fill]="color" cx="30.6" cy="20.4" r="3.4" />
                <path
                    [attr.fill]="color"
                    d="M46.3,29c-0.4,0.1-0.7,0.3-1,0.6l-4.5-2.2c0-0.4,0-0.8-0.1-1.2c-0.5-1.6-2.2-2.4-3.8-1.9
	c-1.6,0.5-2.4,2.2-1.9,3.8c0.5,1.6,2.2,2.4,3.8,1.9c0.4-0.1,0.7-0.3,1-0.6l4.5,2.2c0,0.4,0,0.8,0.1,1.2c0.1,0.2,0.2,0.4,0.3,0.6
	l-4.3,6c-0.5-0.1-1.1-0.1-1.7,0.1c-1.6,0.5-2.4,2.2-1.9,3.8c0.5,1.6,2.2,2.4,3.8,1.9s2.4-2.2,1.9-3.8c-0.1-0.2-0.2-0.4-0.3-0.6
	l4.3-6c0.5,0.1,1.1,0.1,1.7-0.1c1.6-0.5,2.4-2.2,1.9-3.8C49.6,29.4,47.9,28.5,46.3,29z"
                />
                <polygon
                    opacity="0.5"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    [attr.fill]="color"
                    points="25.7,29.6 36.2,35.6 28.7,44.6 17.5,44.6 17.5,35.6 "
                />
                <path
                    [attr.fill]="color"
                    d="M37,31.8c-0.8,0-1.5,0.3-2,0.8l-5.6-3.2c0-0.2,0.1-0.3,0.1-0.5c0-1.7-1.3-3-3-3s-3,1.3-3,3c0,0.2,0,0.3,0,0.5
	l-4.5,3c-0.4-0.3-1-0.4-1.5-0.4c-1.7,0-3.5,1.3-3.5,3c0,1.1,1,2.1,1,2.6v5.3c0,0.5-1,1.5-1,2.6c0,1.7,1.6,2.6,3.2,2.6
	c1.1,0,2.3-0.9,2.8-1.9h6.8c0.5,1,1.5,1.9,2.6,1.9c1.7,0,3-1.1,3-2.8c0-0.5-0.1-0.9-0.4-1.3l4.3-6.1c0.2,0,0.4,0.1,0.6,0.1
	c1.7,0,3-1.3,3-3C40,33.2,38.6,31.8,37,31.8z M29.5,42c-1.4,0-2.6,1-2.9,2h-6.2c-0.3-1-1.4-1.3-2.4-1.6v-4.7c1-0.3,2.4-1.5,2.4-2.9
	c0-0.3,0-0.5-0.1-0.8l4.4-2.8c0.5,0.4,1.1,0.6,1.8,0.6c0.7,0,1.3-0.2,1.8-0.6l5.8,3.4c0,0.1,0,0.1,0,0.2c0,0.6,0.2,1.2,0.5,1.7
	l-4.1,5.8C30.1,42.2,29.8,42,29.5,42z"
                />
            </svg>
        </ng-container>
        <ng-container *ngSwitchCase="'sym_32-layer'">
            <?xml version="1.0" encoding="utf-8"?>
            <!-- Generator: Adobe Illustrator 19.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
            <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 64 64"
                style="enable-background:new 0 0 64 64;"
                xml:space="preserve"
            >
                <style type="text/css">
                    .st0 {
                        opacity: 0.1;
                        fill: #363545;
                        enable-background: new;
                    }
                    .st1 {
                        opacity: 0.3;
                        fill: #363545;
                        enable-background: new;
                    }
                </style>
                <polygon class="st0" points="6,38 6,31.3 32,19 58,31.3 58,38 32,26 " />
                <polygon class="st1" points="6,31 6,37.7 32,50 58,37.7 58,31 32,43 " />
            </svg>
        </ng-container>
        <ng-container *ngSwitchCase="'sym_32-geom-none'">
            <svg x="0px" y="0px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                <path
                    [attr.fill]="'#363545'"
                    d="M28.5,26.5V25c0-1.1,0.2-2.1,0.7-2.8c0.5-0.8,1.3-1.5,2.4-2.2c0.9-0.6,1.6-1.1,2-1.6s0.6-1,0.6-1.6
		c0-0.5-0.2-0.9-0.6-1.1c-0.4-0.3-1-0.4-1.7-0.4c-1.7,0-3.7,0.6-6,1.8l-2.4-4.6c2.8-1.6,5.8-2.4,8.9-2.4c2.5,0,4.5,0.6,6,1.7
		c1.4,1.1,2.2,2.6,2.2,4.5c0,1.4-0.3,2.6-1,3.6c-0.6,1-1.7,2-3.1,2.9c-1.2,0.8-1.9,1.3-2.2,1.7s-0.5,0.8-0.5,1.3v0.9H28.5z
		 M27.8,32.4c0-1,0.3-1.8,0.9-2.4c0.6-0.6,1.5-0.9,2.7-0.9c1.1,0,2,0.3,2.6,0.9c0.6,0.6,0.9,1.4,0.9,2.4s-0.3,1.8-0.9,2.4
		c-0.6,0.6-1.5,0.9-2.6,0.9c-1.1,0-2-0.3-2.6-0.8C28.1,34.3,27.8,33.5,27.8,32.4z"
                />
            </svg>
        </ng-container>
        <ng-container *ngSwitchCase="'sym_32-layer-line'">
            <svg x="0px" y="0px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" xml:space="preserve">
                <polygon
                    opacity="0.1"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    [attr.fill]="'#363545'"
                    points="6,38 6,31.3 32,19 58,31.3 58,38 32,26 "
                />
                <polygon
                    opacity="0.3"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    [attr.fill]="'#363545'"
                    points="6,31 6,37.7 32,50 58,37.7 58,31 32,43 "
                />
                <path
                    [attr.fill]="color"
                    d="M42,22c-0.6,0-1.1,0.1-1.6,0.3l-4.8-4.8c0.2-0.5,0.3-1,0.3-1.6c0-2.2-1.8-4-4-4s-4,1.8-4,4c0,2.2,1.8,4,4,4
   c0.6,0,1.1-0.1,1.6-0.3l4.8,4.8c-0.2,0.5-0.3,1-0.3,1.6c0,0.3,0,0.6,0.1,0.9l-8,5.7C29.5,32.2,28.8,32,28,32c-2.2,0-4,1.8-4,4
   c0,2.2,1.8,4,4,4s4-1.8,4-4c0-0.3,0-0.6-0.1-0.9l8-5.7c0.6,0.4,1.3,0.6,2.1,0.6c2.2,0,4-1.8,4-4C46,23.8,44.2,22,42,22z"
                />
            </svg>
        </ng-container>
        <ng-container *ngSwitchCase="'sym_32-layer-point'">
            <svg x="0px" y="0px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" xml:space="preserve">
                <polygon
                    opacity="0.1"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    [attr.fill]="'#363545'"
                    points="6,38 6,31.3 32,19 58,31.3 58,38 32,26 "
                />
                <polygon
                    opacity="0.3"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    [attr.fill]="'#363545'"
                    points="6,31 6,37.7 32,50 58,37.7 58,31 32,43 "
                />
                <circle [attr.fill]="color" cx="32" cy="26" r="6" />
            </svg>
        </ng-container>
        <ng-container *ngSwitchCase="'sym_32-layer-poly'">
            <svg x="0px" y="0px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" xml:space="preserve">
                <polygon opacity="0.5" [attr.fill]="color" points="33,13 47,21 37,33 22,33 22,21 " />
                <polygon
                    opacity="0.1"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    [attr.fill]="'#363545'"
                    points="6,38 6,31.3 32,19 58,31.3 58,38 32,26 "
                />
                <polygon
                    opacity="0.3"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    [attr.fill]="'#363545'"
                    points="6,31 6,37.7 32,50 58,37.7 58,31 32,43 "
                />
                <path
                    [attr.fill]="color"
                    d="M48,16c-1,0-2,0.4-2.7,1l-7.4-4.3c0-0.2,0.1-0.5,0.1-0.7c0-2.2-1.8-4-4-4s-4,1.8-4,4c0,0.2,0,0.4,0.1,0.6
		l-6,3.9c-0.6-0.3-1.3-0.6-2-0.6c-2.2,0-4,1.8-4,4c0,1.5,0.8,2.8,2,3.4v7.1c-1.2,0.7-2,2-2,3.4c0,2.2,1.8,4,4,4c1.5,0,2.8-0.8,3.4-2
		h9.1c0.7,1.2,2,2,3.4,2c2.2,0,4-1.8,4-4c0-0.7-0.2-1.3-0.5-1.9l5.7-8.2C47.4,24,47.7,24,48,24c2.2,0,4-1.8,4-4
		C52,17.8,50.2,16,48,16z M38,30c-1.9,0-3.4,1.3-3.9,3h-8.3c-0.4-1.4-1.5-2.5-2.9-2.9v-6.3c1.7-0.4,3-2,3-3.9c0-0.4-0.1-0.7-0.2-1
		l5.8-3.8c0.7,0.5,1.5,0.8,2.4,0.8c0.9,0,1.7-0.3,2.3-0.8l7.7,4.5c0,0.1,0,0.2,0,0.3c0,0.8,0.3,1.6,0.7,2.2l-5.5,8
		C38.8,30.1,38.4,30,38,30z"
                />
            </svg>
        </ng-container>
        <ng-container *ngSwitchCase="'sym_32-layer-collection'">
            <svg x="0px" y="0px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" xml:space="preserve">
                <polygon
                    opacity="0.1"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    [attr.fill]="'#363545'"
                    points="6,38 6,31.3 32,19 58,31.3 58,38 32,26 "
                />
                <polygon
                    opacity="0.3"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    [attr.fill]="'#363545'"
                    points="6,31 6,37.7 32,50 58,37.7 58,31 32,43 "
                />
                <circle fill-rule="evenodd" clip-rule="evenodd" [attr.fill]="color" cx="34.6" cy="8.4" r="3.4" />
                <path
                    [attr.fill]="color"
                    d="M50.3,17c-0.4,0.1-0.7,0.3-1,0.6l-4.5-2.2c0-0.4,0-0.8-0.1-1.2c-0.5-1.6-2.2-2.4-3.8-1.9
	c-1.6,0.5-2.4,2.2-1.9,3.8c0.5,1.6,2.2,2.4,3.8,1.9c0.4-0.1,0.7-0.3,1-0.6l4.5,2.2c0,0.4,0,0.8,0.1,1.2c0.1,0.2,0.2,0.4,0.3,0.6
	l-4.3,6c-0.5-0.1-1.1-0.1-1.7,0.1c-1.6,0.5-2.4,2.2-1.9,3.8c0.5,1.6,2.2,2.4,3.8,1.9s2.4-2.2,1.9-3.8c-0.1-0.2-0.2-0.4-0.3-0.6
	l4.3-6c0.5,0.1,1.1,0.1,1.7-0.1c1.6-0.5,2.4-2.2,1.9-3.8C53.6,17.4,51.9,16.5,50.3,17z"
                />
                <polygon
                    opacity="0.5"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    [attr.fill]="color"
                    points="29.7,17.6 40.2,23.6 32.7,32.6 21.5,32.6 21.5,23.6 "
                />
                <path
                    [attr.fill]="color"
                    d="M41,19.8c-0.8,0-1.5,0.3-2,0.8l-5.6-3.2c0-0.2,0.1-0.3,0.1-0.5c0-1.7-1.3-3-3-3s-3,1.3-3,3c0,0.2,0,0.3,0,0.5
	l-4.5,3c-0.4-0.3-1-0.4-1.5-0.4c-1.7,0-3.5,1.3-3.5,3c0,1.1,1,2.1,1,2.6v5.3c0,0.5-1,1.5-1,2.6c0,1.7,1.6,2.6,3.2,2.6
	c1.1,0,2.3-0.9,2.8-1.9h6.8c0.5,1,1.5,1.9,2.6,1.9c1.7,0,3-1.1,3-2.8c0-0.5-0.1-0.9-0.4-1.3l4.3-6.1c0.2,0,0.4,0.1,0.6,0.1
	c1.7,0,3-1.3,3-3C44,21.2,42.6,19.8,41,19.8z M33.5,30c-1.4,0-2.6,1-2.9,2h-6.2c-0.3-1-1.4-1.3-2.4-1.6v-4.7c1-0.3,2.4-1.5,2.4-2.9
	c0-0.3,0-0.5-0.1-0.8l4.4-2.8c0.5,0.4,1.1,0.6,1.8,0.6c0.7,0,1.3-0.2,1.8-0.6l5.8,3.4c0,0.1,0,0.1,0,0.2c0,0.6,0.2,1.2,0.5,1.7
	l-4.1,5.8C34.1,30.2,33.8,30,33.5,30z"
                />
            </svg>
        </ng-container>
        <ng-container *ngSwitchCase="'sym_32-layer-none'">
            <svg x="0px" y="0px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                <polygon
                    opacity="0.1"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    [attr.fill]="'#363545'"
                    points="6,38 6,31.3 32,19 58,31.3 58,38 32,26 "
                />
                <polygon
                    opacity="0.3"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    [attr.fill]="'#363545'"
                    points="6,31 6,37.7 32,50 58,37.7 58,31 32,43 "
                />
                <path
                    [attr.fill]="'#363545'"
                    d="M28.5,26.5V25c0-1.1,0.2-2.1,0.7-2.8c0.5-0.8,1.3-1.5,2.4-2.2c0.9-0.6,1.6-1.1,2-1.6s0.6-1,0.6-1.6
		c0-0.5-0.2-0.9-0.6-1.1c-0.4-0.3-1-0.4-1.7-0.4c-1.7,0-3.7,0.6-6,1.8l-2.4-4.6c2.8-1.6,5.8-2.4,8.9-2.4c2.5,0,4.5,0.6,6,1.7
		c1.4,1.1,2.2,2.6,2.2,4.5c0,1.4-0.3,2.6-1,3.6c-0.6,1-1.7,2-3.1,2.9c-1.2,0.8-1.9,1.3-2.2,1.7s-0.5,0.8-0.5,1.3v0.9H28.5z
		 M27.8,32.4c0-1,0.3-1.8,0.9-2.4c0.6-0.6,1.5-0.9,2.7-0.9c1.1,0,2,0.3,2.6,0.9c0.6,0.6,0.9,1.4,0.9,2.4s-0.3,1.8-0.9,2.4
		c-0.6,0.6-1.5,0.9-2.6,0.9c-1.1,0-2-0.3-2.6-0.8C28.1,34.3,27.8,33.5,27.8,32.4z"
                />
            </svg>
        </ng-container>
    </ng-container>
</div>
