<div class="workspace-share-info">
    <loading *ngIf="isWorkspaceShareLoading" class="loading-align-center loading-share"></loading>

    <ng-container *ngIf="!isWorkspaceShareLoading">
        <h5>{{ sharedWorkspaceData?.workspace.name }}</h5>

        <div class="share-workspace-description">
            {{ 'TCS.Workspace.ShareWorkspaceDescription' | translate }}
        </div>
        <div class="workspace-share-lock">
            <gsp-checkbox
                [(value)]="lockSharedWorkspace"
                [id]="'lockSharedWorkspace'"
                [label]="'TCS.Workspace.ShareWorkspaceLock' | translate"
            ></gsp-checkbox>
            <div class="workspace-share-lock-description">
                {{ 'TCS.Workspace.ShareWorkspaceLockDescription' | translate }}
            </div>
        </div>
    </ng-container>
</div>
