import { Component, OnDestroy, OnInit, Type } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { GspWizardService } from 'src/app/shared/common/components/gsp-wizard/gsp-wizard.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { ModalSize } from 'src/app/shared/common/modal-sizes';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';

import { MainMenuStreamService } from '../../map-viewer/main-menu/main-menu-stream.service';
import { WorkspaceAreaComponent } from '../workspace-area/workspace-area.component';
import { WorkspaceCoordinateSystemComponent } from '../workspace-coordinate-system/workspace-coordinate-system.component';
import {
    WorkspaceOfflineGnssCorrectionsComponent,
} from '../workspace-offline-gnss-corrections/workspace-offline-gnss-corrections.component';
import { WorkspaceSetFolderComponent } from '../workspace-set-folder/workspace-set-folder.component';
import { WorkspaceCreationMode, WorkspaceService } from '../workspace.service';
import { WorkspaceDuplicationDetailsComponent } from './workspace-duplication-details.component';
import { WorkspaceTemplateOptionsComponent } from './workspace-template-options.component';

@Component({
    selector: 'workspace-duplication',
    templateUrl: './workspace-duplication.component.html'
})
export class WorkspaceDuplicationComponent implements OnInit, OnDestroy {
    // expose ButtonType enum to template
    public ButtonType = ButtonType;
    public ModalSize = ModalSize;

    private destroyed = new Subject();

    constructor(
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private router: Router,
        private mainMenuStream: MainMenuStreamService,
        private projectStream: ProjectStreamService,
        private wizardService: GspWizardService,
        private workspaceCreationService: WorkspaceService,
        private mapWorkspaceStore: MapWorkspacesStoreService
    ) {}

    ngOnInit(): void {
        let modifyWorkspace = new MapWorkspace();
        modifyWorkspace.projectId = this.projectStream.getCurrentProject().id;
        this.workspaceCreationService.modifyWorkspace$.next(modifyWorkspace);
        const sourceWorkspace = this.mapWorkspaceStore.duplicateMapWorkspaceStream.getValue();
        this.workspaceCreationService.workspaceCreationMode$.next(WorkspaceCreationMode.DUPLICATE);
        this.workspaceCreationService.sourceWorkspace$.next(sourceWorkspace);
        this.workspaceCreationService.useSourcePropertiesForDuplicate$
            .pipe(takeUntil(this.destroyed))
            .subscribe((useSourceProperties: boolean) => {
                if (useSourceProperties) {
                    this.wizardService.setSteps([
                        WorkspaceDuplicationDetailsComponent,
                        WorkspaceSetFolderComponent,
                        WorkspaceTemplateOptionsComponent
                    ]);
                } else {
                    this.wizardService.setSteps([
                        WorkspaceDuplicationDetailsComponent,
                        WorkspaceAreaComponent,
                        WorkspaceCoordinateSystemComponent,
                        WorkspaceOfflineGnssCorrectionsComponent,
                        WorkspaceSetFolderComponent,
                        WorkspaceTemplateOptionsComponent
                    ]);
                }
            });
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
        this.destroyed.complete();
    }

    public get steps(): Type<any>[] {
        return this.wizardService.steps;
    }

    public get currentStep$(): Observable<Type<any>> {
        return this.wizardService.currentStep$;
    }

    public backward(): void {
        try {
            this.wizardService.moveBackward().then(() => {
                this.wizardService.reset();
            });
        } catch (e) {
            throw e;
        }
    }

    public forward(): void {
        try {
            this.wizardService.moveForward().then(() => {
                this.wizardService.reset();
            });
        } catch (e) {
            throw e;
        }
    }

    public exit(): void {
        this.onClose();
    }

    public cancel(): void {
        this.onCancel();
    }

    public onClose(): void {
        this.workspaceCreationService.clearWorkspace();
        this.router
            .navigate(['mapViewer', { outlets: { centerDialog: null, mainMenu: 'workspaces' } }], {
                queryParamsHandling: 'preserve'
            })
            .then(() => {
                this.mainMenuStream.showMainMenu$.next(true);
            });
    }

    public onCancel(): void {
        this.mapWorkspacesStore
            .getDefaultOrCreateMapWorkspace(this.projectStream.getCurrentProject().id, false, false)
            .then(workspace => {
                this.mapWorkspacesStore.editMapWorkspaceStream.next(null);
                this.workspaceCreationService.clearWorkspace();
                this.router
                    .navigate(['/mapViewer', { outlets: { centerDialog: null, mainMenu: 'workspaces' } }], {
                        queryParams: {
                            projectId: this.projectStream.getCurrentProject().id,
                            workspaceId: workspace.id
                        }
                    })
                    .then(() => {
                        this.mainMenuStream.showMainMenu$.next(true);
                    });
            });
    }
}
