import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: '[minimumEntries]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MinimumEntriesValidatorDirective, multi: true }]
})
export class MinimumEntriesValidatorDirective implements Validator, OnChanges {
    @Input('minimumEntries') nStr: string;

    private _onValidatorChange = () => {};

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.minimumEntries) {
            this._onValidatorChange();
        }
    }

    registerOnValidatorChange?(fn: () => void): void {
        this._onValidatorChange = fn;
    }

    validate(control: AbstractControl): ValidationErrors {
        let n = parseInt(this.nStr, 10);
        if (control.value && control.value.length < n) {
            return { minimumEntries: { wanted: n, got: control.value.length } };
        }
        return null;
    }
}
