import { GeoLink } from 'src/app/shared/types/geo';

import { GeometryTypes } from '../../common/utility/geometry-utils';
import { BackgroundMapType } from '../layer/layer';
import { GeoWorkspaceRelationships } from '../relationship/relationship';
import { ConnectFileError, CoordinateSystemComponentType } from './map-workspace';

export class CoordinateSystemComponentUnit {
    displayName: string;
    id: CoordinateSystemUnits;
}
export class CoordinateSystem {
    zoneGroupComponentId: string;
    zoneComponentId: string;
    datumComponentId: string;
    geoidComponentId: string;
    horizontalUnit: CoordinateSystemUnits;
    verticalUnit: CoordinateSystemUnits;
    cscmResourceId: string;
    cscmLocalLLHResourceId: string;
    localReferenceFrameComponentId: string;
}

export class WorkspaceBounds {
    latitudeNorth: number;
    latitudeSouth: number;
    longitudeEast: number;
    longitudeWest: number;
}
export interface CoordinateSystemComponentDTO {
    type?: CoordinateSystemComponentType;
    ellipsoidID?: string;
    defaultStaticTransformationID?: string;
    isDynamic?: boolean;
    isWGS84?: boolean;
    componentID?: string;
    localReferenceFrameComponentID?: string;
    defaultDatumID?: string;
    defaultGeoidID?: string;
    parentID?: string;
    name?: string;
    displayName?: string;
    extents?: {
        minLat: number;
        minLon: number;
        maxLat: number;
        maxLon: number;
        areaID: string;
        isGlobal: boolean;
    };
    horizontalUnits?: CoordinateSystemUnits;
    verticalUnits?: CoordinateSystemUnits;
}

export enum CoordinateSystemUnits {
    METRE = 'Metre',
    INTERNATIONAL_FOOT = 'InternationalFoot',
    US_SURVEY_FOOT = 'USSurveyFoot',
    GERMAN_LEGAL_METRE = 'GermanLegalMetre',
    INTERNATIONAL_YARD = 'InternationalYard',
    INTERNATIONAL_CHAIN = 'InternationalChain'
}

export enum DuplicationMethod {
    DuplicateTemplates = 'DuplicateTemplates',
    LinkTemplates = 'LinkTemplates'
}
export interface DuplicateWorkspaceDTO {
    sourceWorkspaceId: string;
    name: string;
    description: string;
    use_Source_CoordinationSystem: boolean;
    duplicationMethod: DuplicationMethod;
    coordinateSystem: CoordinateSystem;
    bounds: WorkspaceBounds;
    enablePostProcessing: boolean;
    connectFolderId: string;
}

export enum MapWorkspaceStatus {
    ARCHIVED = 'Archived',
    ACTIVE = 'Active'
}

export interface GeoWorkspaces {
    links?: GeoLink[];
    total?: number;
    items?: GeoWorkspace[];
    eTag?: string;
}

export interface GeoWorkspace {
    name?: string;
    description?: string;
    connectFileId?: string;
    connectFileError?: ConnectFileError;
    uuid?: string;
    hasThumbnail?: boolean;
    workspaceModel?: {
        // Note: all customisation for MapViewer UI level only will be placed here
        isCustomThumbnailImage?: boolean;
    };
    backgroundMapType?: BackgroundMapType;
    isDeleted?: boolean;
    coordinateSystem?: GeoWorkspaceCoordinateSystem;
    bounds?: GeoWorkspaceBounds;
    tectonicPlate?: string;
    relationships?: GeoWorkspaceRelationships;
    layers?: GeoWorkspaceLayer;
    createdUtc?: Date;
    updatedUtc?: Date;
    publicationUpdatedUtc?: Date;
    status?: MapWorkspaceStatus;
    postProcessingBody?: GeoWorkspacePostProcessingBody;
    links?: GeoLink[];
    id?: string;
    eTag?: string;
}

export interface GeoWorkspaceCoordinateSystem {
    zoneGroupComponentId: string;
    zoneComponentId: string;
    datumComponentId: string;
    geoidComponentId: string;
    localReferenceFrameComponentId: string;
    horizontalUnit: CoordinateSystemUnits;
    verticalUnit: CoordinateSystemUnits;
    cscmResourceId: string;
    cscmLocalLLHResourceId: string;
}

export interface GeoWorkspaceBounds {
    latitudeNorth?: number;
    latitudeSouth?: number;
    longitudeEast?: number;
    longitudeWest?: number;
}

export interface GeoWorkspaceLayer {
    id?: string;
    name?: string;
    sourceName?: string;
    template?: GeoWorkspaceTemplate;
}

export class GeoWorkspacePostProcessingBody {
    enabled = false;
    useUserBaseFilesFolder = false;
    userBaseFilesFolderId: string = null;
    userBaseFilesAvailabilityTime: AvailabilityTime = {
        timeOfDay: '00:00',
        periodOfDay: PeriodOfDay.AM,
        timeZoneOffset: '00:00' // e.g. 01:00 for +1hr, -01:00 for -1hr
    };
    locked = false;
}

export interface AvailabilityTime {
    timeOfDay: string;
    periodOfDay: PeriodOfDay;
    timeZoneOffset: string;
}

export enum PeriodOfDay {
    AM = 'AM',
    PM = 'PM'
}

export interface GeoWorkspaceTemplate {
    id?: string;
    seriesId?: string;
    version?: number;
    isPublished?: boolean;
    templateName?: string;
    geometryType?: GeometryTypes.POINT | GeometryTypes.LINE | GeometryTypes.AREA;
    geometryColorHexRGB?: string;
    isLocked?: boolean;
}

export interface GeoWorkspaceRequest {
    name?: string;
    isViewer?: boolean;
    description?: string;
    connectFileId?: string;
    connectFolderId?: string;
    backgroundMapType?: BackgroundMapType;
    coordinateSystem?: GeoWorkspaceCoordinateSystem;
    bounds?: GeoWorkspaceBounds;
    postProcessingBody?: GeoWorkspacePostProcessingBody;
    tectonicPlate?: string;
    relationships?: GeoWorkspaceRelationshipsInfo;
    status?: MapWorkspaceStatus;
    id?: string;
    projectId?: string;
}

export interface GeoWorkspaceRelationshipsInfo {
    realtimeCorrectionIds?: string[];
    layerIds?: string[];
    dataUpdateJobIds?: string[];
    mapCacheIds?: string[];
}

export interface WorkspaceModel {
    isCustomThumbnailImage: boolean;
}
