import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import * as _ from 'lodash-es';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { CurrentRegionStreamService } from 'src/app/shared/common/current-region/region-stream.service';
import { CurrentUserStreamService } from 'src/app/shared/common/current-user/current-user-stream.service';
import { EnvironmentService, RegionConfig } from 'src/app/shared/common/environment/environment.service';
import { FeatureFlagStreamService } from 'src/app/shared/common/feature-flag/feature-flag-stream.service';
import { MapWorkspaceService } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace.service';

import { ACCESS_MODE_KEY, AccessMode, SHARED_MODE_KEY, SHARED_TOKEN_KEY } from '../../authentication/share-token';
import { ErrorPageErrorCodes } from '../../error-page/error-page.component';
import { FileViewerSettings } from '../../init/load-fileviewer.component';

export const sharedFileViewerGuardService: CanActivateFn = async (
    route: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot,
    currentRegionStream: CurrentRegionStreamService = inject(CurrentRegionStreamService),
    router: Router = inject(Router),
    currentUserStream: CurrentUserStreamService = inject(CurrentUserStreamService),
    projectStream: ProjectStreamService = inject(ProjectStreamService),
    mapWorkspaceService: MapWorkspaceService = inject(MapWorkspaceService),
    mapWorkspacesStore: MapWorkspacesStoreService = inject(MapWorkspacesStoreService),
    env: EnvironmentService = inject(EnvironmentService),
    featureFlagStream: FeatureFlagStreamService = inject(FeatureFlagStreamService)
) => {
    const shareToken = route.paramMap.get('shareToken');
    const currentRegion = route.queryParamMap.get('region');

    await currentRegionStream.setCurrentRegionConfig();
    const region = getRegion(currentRegion, env);
    if (!region) {
        router.navigate(['error', ErrorPageErrorCodes.INVALID_REGION]);
        Promise.resolve();
    }
    const shareTokenDetail = await currentRegionStream.initCurrentRegionSharedPublicMode(shareToken, currentRegion);
    if (shareTokenDetail) {
        sessionStorage.setItem('tcAccessToken', shareTokenDetail.accessToken);
        sessionStorage.setItem(SHARED_TOKEN_KEY, shareToken);
        sessionStorage.setItem(SHARED_MODE_KEY, shareTokenDetail.mode);
        sessionStorage.setItem(ACCESS_MODE_KEY, AccessMode.SHARED);
        const versionIds = shareTokenDetail.objects.map(tmp => tmp.id);
        const user = await currentUserStream.initRegisteredUser(shareTokenDetail.mode);
        if (user) {
            await featureFlagStream.initFlags();
            const project = await projectStream.initCurrentProjectInPublicSharedView(shareTokenDetail.projectId);
            const projectId = project.id;
            const workspace = await mapWorkspaceService.getMapWorkspaceByConnectFileId(projectId, versionIds[0], true);
            const settings: FileViewerSettings = {
                projectId,
                lastViewedConnectFileVersionIds: versionIds,
                forceUpdate: true,
                isFileViewer: workspace.isFileViewer,
                workspaceId: workspace.id,
                region: currentRegion
            };
            sessionStorage.setItem('fileViewerSettings', JSON.stringify(settings));
            await mapWorkspacesStore.initPubliclySharedCurrentMapWorkspace(projectId, workspace);
            return true;
        }
    }
};

const getRegion = (currentRegion: string, env: EnvironmentService): RegionConfig => {
    return _.find(env.regions, region => region.locationId === currentRegion);
};
