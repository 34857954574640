export class Style {
    name = '';
    image = '';
    lineColor = 0x03f03f;
    lineWeight = 5;
    lineStyle = 'SOLID';
    fillColor = 0x03f03f;
    fillOpacity = 2;
    id = '';

    constructor(style?: Style) {
        if (style) {
            this.setStyle(style);
        }
    }

    public setColor(color: string): void {
        let colorNumber = parseInt(color.replace('#', '0x'), 16);
        this.fillColor = colorNumber;
        this.lineColor = colorNumber;
    }

    public setName(name: string): void {
        this.name = name;
    }

    public setStyle(style: Style): void {
        this.name = style.name;
        this.image = style.image;
        this.lineColor = style.lineColor;
        this.lineWeight = style.lineWeight;
        this.lineStyle = style.lineStyle;
        this.fillColor = style.fillColor;
        this.fillOpacity = style.fillOpacity;
        this.id = style.id;
    }
}
