<div class="condition-edit">
    <div class="editor-field-row">
        <label translate [translateParams]="{ condition: '' }">TCS.TemplateEditor.Tf.Rules.Validate.ThisField</label>
    </div>
    <div class="editor-field-row">
        <gsp-dropdown
            name="condition"
            [options]="conditionPair"
            dropdownUniqueClass=""
            [(ngModel)]="condition.operator"
            idField="value"
            textField="name"
        ></gsp-dropdown>
    </div>
</div>
