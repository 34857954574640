import { Component, Input, OnChanges } from '@angular/core';
import { GspTextType } from 'src/app/shared/common/components/gsp-text/gsp-text.component';

import { TextFeatureField } from '../feature-field';

@Component({
    templateUrl: './text-field.component.html',
    selector: 'text-field'
})
export class TextFieldComponent implements OnChanges {
    @Input()
    field: TextFeatureField;

    @Input()
    editMode: boolean;

    // exposing enum to template
    public GspTextType = GspTextType;

    public isURL = false;

    ngOnChanges(): void {
        try {
            this.isURL = Boolean(new URL(this.field.value));
        } catch {
            this.isURL = false;
        }
    }
}
