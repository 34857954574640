<div *ngIf="showNotificationTab" class="notification-tab-container">
    <div class="notification-tab-scroll-container">
        <custom-scroll class=" height-full">
            <div class="notification-tab-content">
                <div
                    class="notification-tab-item"
                    *ngFor="let notification of forbiddenFormsNotifications"
                    (click)="handleNotificationClick(notification)"
                >
                    <div class="notification-item-content">
                        <div class="notification-item-header">
                            {{ 'MapViewer.NotificationTab.Notification.Header' | translate }}
                        </div>
                        <div class="notification-item-description">
                            <div
                                translate
                                [translateParams]="{
                                    formsCount: notification.summary.total
                                }"
                            >
                                MapViewer.ForbiddenForms.NotificationTab.Notification.Forms
                            </div>
                        </div>
                        <div class="notification-item-footer">
                            {{ notification.formattedTimeStamp }}
                        </div>
                    </div>
                    <div class="spacer"></div>
                </div>
                <div *ngIf="!forbiddenFormsNotifications.length" class="no-notification-placeholder">
                    {{ 'MapViewer.NotificationTab.Empty.Placeholder' | translate }}
                </div>
            </div>
        </custom-scroll>
    </div>
</div>

<gsp-forbidden-forms
    *ngIf="showForbiddenFormModal"
    [notification]="selectedNotification"
    (closed)="hideForbiddenFormModal()"
></gsp-forbidden-forms>
