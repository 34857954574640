import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { GspWizardService, OnClose, OnForward } from 'src/app/shared/common/components/gsp-wizard/gsp-wizard.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { Project } from 'src/app/shared/map-data-services/project/project';

import { ImportApiStatus, ImportFile, ImportStatus, ImportStatusDict } from '../../import-file';
import { ImportPollingStatusService, IntervalTime } from '../../import-polling-status.service';
import { ImportService } from '../../import.service';
import { NotificationType } from 'src/app/shared/common/components/gsp-notification/gsp-notification.component';

@Component({
    selector: 'import-status',
    templateUrl: './import-status.component.html'
})
export class ImportStatusMapViewerComponent implements OnInit, OnDestroy, OnForward, OnClose {
    public importFiles: ImportFile[];
    private inProgressImports: ImportStatusDict;
    // expose enum to template
    public ImportStatus = ImportStatus;
    public ButtonType = ButtonType;
    public NotificationType = NotificationType;

    private uiState = {
        forwardButton: { text: 'TC.Common.Close', enabled: false, visible: true },
        backButton: { text: '', enabled: false, visible: false },
        closeButton: { text: '', enabled: true, visible: false },
        cancelButton: { text: '', enabled: false, visible: false }
    };
    private currentMapWorkspace: MapWorkspace;
    private project: Project;
    private destroyed$: Subject<void> = new Subject<void>();

    private readonly mapImportApiStatusToImportStatus: { [key: string]: ImportStatus } = {
        [ImportApiStatus.COMPLETED]: ImportStatus.SUCCESS,
        [ImportApiStatus.FAILED]: ImportStatus.ERROR,
        [ImportApiStatus.SUBMITTED]: ImportStatus.PROCESSING,
        [ImportApiStatus.INPROGRESS]: ImportStatus.PROCESSING
    };

    constructor(
        private wizardService: GspWizardService,
        private importService: ImportService,
        private importPollingService: ImportPollingStatusService,
        private mapWorkspaceStore: MapWorkspacesStoreService,
        private projectStream: ProjectStreamService,
        private router: Router,
        private zone: NgZone
    ) {
        this.importService.importStream.pipe(takeUntil(this.destroyed$)).subscribe(files => (this.importFiles = files));
        this.importPollingService.importsState.pipe(takeUntil(this.destroyed$)).subscribe(imports => {
            this.inProgressImports = imports;

            // Update importFiles with new status
            this.importFiles.forEach(importFile => {
                const importStatus = this.inProgressImports[importFile.id]?.status;

                // Still show status in modal
                if (importStatus) {
                    importFile.status = this.mapImportApiStatusToImportStatus[importStatus];
                }
            });
        });
    }

    public ngOnInit(): void {
        this.currentMapWorkspace = this.mapWorkspaceStore.getCurrentMapWorkspace();
        this.project = this.projectStream.getCurrentProject();
        this.wizardService.setUI(this.uiState);
        this.importPollingService.updatePollInterval(IntervalTime.Default);
        this.importService
            .doImportFiles$(this.importFiles, this.currentMapWorkspace)
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
                next: value => {
                    // This is asynchronous that is running outside of Angular’s change detection zone.
                    // Therefore explicitly run a task outside of Angular’s zone.
                    this.zone.run(() => {
                        this.importService.updateFileStatus(value);
                    });
                },
                complete: () => {
                    this.uiState.forwardButton.enabled = true;
                    this.wizardService.setUI(this.uiState);
                }
            });
    }

    public ngOnDestroy(): void {
        this.destroyed$.next(null);
    }

    public copy(str: string): void {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    public async onForward(): Promise<void> {
        this.importPollingService.updatePollInterval(IntervalTime.ClosedModal);
        this.onClose();
    }

    public onClose(): void {
        this.router.navigate(['mapViewer', { outlets: { centerDialog: null } }], {
            queryParamsHandling: 'preserve'
        });
    }
}
