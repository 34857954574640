<gsp-popup [heading]="heading" [showClose]="true" (closed)="onCancelClick()" [size]="ModalSize.ExtraSmall">
    <ng-container transclude-popup-content>
        {{ message | translate }}
        <b *ngIf="isPermanentAction">{{ 'MapViewer_Generic_ConfirmationMessage' | translate }}</b>
    </ng-container>
    <ng-container transclude-popup-footer>
        <gsp-button
            [type]="ButtonType.Danger"
            [asyncAction]="confirmAction"
            text="{{ confirmationButtonText | translate }}"
            id="{{ id + '-confirm' }}"
        ></gsp-button>
        <gsp-button
            [type]="ButtonType.Default"
            (action)="onCancelClick()"
            text="{{ 'cancel' | translate }}"
            id="{{ id + '-cancel' }}"
        ></gsp-button>
    </ng-container>
</gsp-popup>
