<div id="map-controls" *ngIf="map">
    <!-- Todo: may need to refactor this html -->
    <selection-tool [map]="map" *checkVisibility="{ toolbarOption: 'hideSelectionTool', toolbarOptionValue: true }">
    </selection-tool>

    <collaboration-tools
        *checkVisibility="{ visibleModes: [MapWorkspacePermissionType.FULL_ACCESS, MapWorkspacePermissionType.READ] }"
        [map]="map"
    >
    </collaboration-tools>

    <zoom-buttons [map]="map" *checkVisibility="{ toolbarOption: 'hideZoomButton', toolbarOptionValue: true }">
    </zoom-buttons>

    <search-place
        [map]="map"
        *checkVisibility="{ toolbarOption: 'hideSearchBox', toolbarOptionValue: true }"
        [showMarker]="true"
    >
    </search-place>
</div>
