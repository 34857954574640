import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import {
    SelectionAction,
    SelectionMode,
    SelectionModeAction,
} from '../toolbar/mapToolbar/selectionTools/selection-tool.component';

export class SelectionToolIdUtils {
    public static getModeFromSelectionToolId(selectionToolId: SelectionModeAction): SelectionMode {
        if (!selectionToolId) {
            return null;
        }
        let selectedToolIdParts: string[] = ((selectionToolId as unknown) as string).split('_');
        return (selectedToolIdParts.length === 2 ? selectedToolIdParts[0] : null) as SelectionMode;
    }

    public static getActionFromSelectionToolId(selectionToolId: SelectionModeAction): SelectionAction {
        if (!selectionToolId) {
            return null;
        }
        let selectedToolIdParts: string[] = ((selectionToolId as unknown) as string).split('_');
        return (selectedToolIdParts.length === 2 ? selectedToolIdParts[1] : null) as SelectionAction;
    }
}

@Injectable({
    providedIn: 'root'
})
export class SelectionToolStream {
    public selectSelectionToolStream = new Subject<SelectionModeAction>();
    public selectionToolSelectedStream = new BehaviorSubject<SelectionModeAction>(null);

    public revertDrawingMode(): void {
        this.selectSelectionToolStream.next(SelectionModeAction.SINGLE_NEW);
    }
}
