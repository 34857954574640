import { Component, OnInit, Type } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { GspWizardService } from 'src/app/shared/common/components/gsp-wizard/gsp-wizard.service';
import { LayersStore } from 'src/app/shared/common/current-layers/layers-store.service';
import { StylesStore } from 'src/app/shared/common/current-layers/styles-store.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ModalSize } from 'src/app/shared/common/modal-sizes';
import { LayerType } from 'src/app/shared/map-data-services/layer/layer';
import { MapWorkspace, ShareWorkspaceData } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { Style } from 'src/app/shared/map-data-services/styles/style';
import { Template } from 'src/app/shared/template-services/template';
import { TemplateService } from 'src/app/shared/template-services/template.service';

import { MainMenuStreamService } from '../../map-viewer/main-menu/main-menu-stream.service';
import { WorkspaceShareDownloadComponent } from './workspace-share-download/workspace-share-download.component';
import { WorkspaceShareInfoComponent } from './workspace-share-info/workspace-share-info.component';
import { ShareWorkspaceStoreService } from './workspace-share-store.service';

@Component({
    selector: 'workspace-share',
    templateUrl: './workspace-share.component.html'
})
export class WorkspaceShareComponent implements OnInit {
    ModalSize = ModalSize;

    sharedWorkspaceData: ShareWorkspaceData;
    sharedWorkspace: MapWorkspace;

    constructor(
        private wizardService: GspWizardService,
        private router: Router,
        private messaging: MessagingService,
        private translate: TranslationService,
        private mainMenuStream: MainMenuStreamService,
        private layersStore: LayersStore,
        private stylesStore: StylesStore,
        private templateService: TemplateService,
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private shareWorkspaceStore: ShareWorkspaceStoreService
    ) {
        this.wizardService.setSteps([WorkspaceShareInfoComponent, WorkspaceShareDownloadComponent]);
    }

    async ngOnInit(): Promise<void> {
        this.shareWorkspaceStore.shareWorkspaceLoadingStream.next(true);
        this.shareWorkspaceStore.lockSharedWorkspaceStream.next(false);
        this.sharedWorkspace = this.mapWorkspacesStore.shareMapWorkspaceStream.getValue();

        // get layers
        const layers = (await this.layersStore.getLayersByMapWorkspace(this.sharedWorkspace)).filter(
            layer => layer.geoLayerType !== LayerType.IMPORTED_LAYER
        );
        const layerTemplateIds = layers.map(layer => `'${layer.templateId}'`);

        if (!layerTemplateIds.length) {
            this.messaging.showWarning(this.translate.instant('TCS.Workspace.ShareEmptyWorkspace'));
            this.closePanel();
        } else {
            const workspaceData = {
                name: this.sharedWorkspace.name,
                description: this.sharedWorkspace.description
            };

            // get templates
            const templateDict = (
                await this.templateService.getTemplatesByQuery(this.sharedWorkspace.projectId, layerTemplateIds)
            ).reduce((acc, template) => {
                acc[template.id] = template;
                return acc;
            }, {} as { [key: string]: Template });

            // get styles
            const styleDict: { [key: string]: Style } = {};
            const promises: Promise<Style>[] = [];
            layers.forEach(layer => {
                promises.push(
                    this.stylesStore
                        .getStyle(this.sharedWorkspace.projectId, layer.styleId)
                        .then(style => (styleDict[layer.styleId] = style))
                );
            });
            await Promise.all(promises);

            const workspaceContent = layers.reduce(
                (acc, layer) => {
                    acc.content.push({
                        layer: {
                            ...layer.toDTO(),
                            id: null,
                            fileVersionId: null,
                            styleId: null,
                            templateId: null,
                            templateSeriesId: null
                        },
                        style: styleDict[layer.styleId] ? { ...styleDict[layer.styleId], id: null } : null,
                        template: {
                            ...templateDict[layer.templateId].toDTO(),
                            id: null,
                            seriesId: null
                        }
                    });
                    return acc;
                },
                { workspace: workspaceData, content: [] }
            );

            this.shareWorkspaceStore.shareWorkspaceDataStream.next(workspaceContent);

            this.shareWorkspaceStore.shareWorkspaceLoadingStream.next(false);
        }
    }

    private closePanel(): void {
        this.router.navigate(['mapViewer', { outlets: { centerDialog: null } }], {
            queryParamsHandling: 'preserve'
        });
    }

    public get steps(): Type<any>[] {
        return this.wizardService.steps;
    }

    public get currentStep$(): Observable<Type<any>> {
        return this.wizardService.currentStep$;
    }

    public backward(): void {
        try {
            this.wizardService.moveBackward().then(() => {
                this.wizardService.reset();
            });
        } catch (e) {
            throw e;
        }
    }

    public forward(): void {
        try {
            this.wizardService.moveForward().then(() => {
                this.wizardService.reset();
            });
        } catch (e) {
            throw e;
        }
    }

    public cancel(): void {
        this.wizardService.onCancel();
        this.onClose();
    }

    public onClose(): void {
        this.router
            .navigate(['mapViewer', { outlets: { centerDialog: null, mainMenu: 'workspaces' } }], {
                queryParamsHandling: 'preserve'
            })
            .then(() => {
                this.mainMenuStream.showMainMenu$.next(true);
            });
    }
}
