import { GeoLink } from 'src/app/shared/types/geo';

import { AutoFieldModelType, CodedChoiceValue, FieldType, LayoutFieldType } from '../field';

export interface GeoFields {
    links?: GeoLink[];
    total?: number;
    items?: GeoField[];
    eTag?: string;
}

export interface GeoField {
    fieldType?: FieldType | LayoutFieldType | AutoFieldModelType;
    uuid?: string;
    applications?: GeoFieldApplication[];
    schema?: string;
    isDefault?: boolean;
    createdBy?: string;
    modifiedBy?: string;
    links?: GeoLink[];
    id?: string;
    eTag?: string;
}
export interface GeoFieldApplication {
    id?: string;
    fieldId?: string;
    category?: string;
    subcategory?: string;
    layoutSchema?: string;
    validationRule?: string;
    fieldTransformationRule?: string;
    fieldCategories?: string[];
}

export interface ListSchema {
    label: string;
    value: string;
}

export enum SchemaFieldType {
    TEXT = 'text',
    NUMBER = 'number',
    GENERAL = 'general',
    RANGE = 'range',
    DATE = 'date',
    RADIO = 'radio',
    MULTISELECT = 'multiselect',
    DROPDOWN = 'dropdown',
    BOOLEAN = 'boolean',
    ARRAY = 'array'
}

export interface SchemaField {
    id: string;
    label: string;
    type: string;
    value: string | number | boolean;
    items?: Array<ListSchema> | object;
    properties?: SchemaField[];
    required?: string[];
    isRequired?: boolean;
    attributeType?: string;
    optionType?: string;
    pattern?: RegExp;
    canCollapse?: boolean;
    options?: [];
    minimum?: number;
    maximum?: number;
    enabled?: boolean;
}

export interface Schema {
    properties: { [key: string]: SchemaField };
    required?: string[];
}

export interface LayoutSchema {
    collapsable?: boolean;
    hideRequired?: boolean;
    iconClass?: string;
    id: string;
    label: string;
    representedByFieldType?: string;
    representsMultipleFields?: boolean;
    type: string;
    rule?: any;
    properties: LayoutSchemaProperty[];
}

export interface LayoutSchemaProperty {
    default: boolean;
    id: string;
    infotext?: string;
    label: string;
    position: string;
    postfixLabel?: string;
    title?: string;
    type: string;
    items?: Array<ListSchema>;
}

export class SensorData {
    sensorName: string;
    messageDefinitions: SensorMessageDefinition[];
}

export interface SensorMessageDefinition {
    workflowName: string;
    id: string;
    mappingId: string;
    fieldDefinitions: SensorFieldDefinition[];
    prefix: string;
    suffix: string;
    separator: string;
}

export interface SensorFieldDefinition {
    mappingId: string;
    displayName: string;
    uuid: string;
    sourceUuid: string;
    fieldType: FieldType | LayoutFieldType | AutoFieldModelType;
    index: number;
    unitFieldIndex?: number;
    decimalPlaces?: number;
    codedValues?: CodedChoiceValue[];
    exclude?: string[];
}

export class FieldDefinitionApplication {
    category: string; // e.g. "Connect"
    subcategory: string; // e.g.  "mapViewer"
    layoutSchema: LayoutSchema;
    fieldTransformationRule: string;
    fieldCategories: string[];

    static fromDTO(dto: GeoFieldApplication): FieldDefinitionApplication {
        let fieldDefinitionApplication = new FieldDefinitionApplication();

        if (dto) {
            fieldDefinitionApplication.category = dto.category;
            fieldDefinitionApplication.subcategory = dto.subcategory;
            fieldDefinitionApplication.layoutSchema = dto.layoutSchema && JSON.parse(dto.layoutSchema);
            fieldDefinitionApplication.fieldTransformationRule = dto.fieldTransformationRule;
            fieldDefinitionApplication.fieldCategories = dto.fieldCategories;
        }
        return fieldDefinitionApplication;
    }
}

export class FieldDefinition {
    fieldType: FieldType | LayoutFieldType | AutoFieldModelType; // e.g. "Text", "General", "Length", "Angle", ...
    application: FieldDefinitionApplication;
    schema: Schema;

    static fromDTO(dto: GeoField, category: string): FieldDefinition {
        let fieldDefinition = new FieldDefinition();

        // --------------
        // Create from API response DTO
        // Note: only the DTO FieldDefinitionApplication matching the appName/appCategory is returned

        if (dto) {
            fieldDefinition.fieldType = dto.fieldType;
            fieldDefinition.application =
                dto.applications
                    .map(application => FieldDefinitionApplication.fromDTO(application))
                    .filter(application => application.category === category)[0] || null;
            fieldDefinition.schema = dto.schema && JSON.parse(dto.schema);
        }
        return fieldDefinition;
    }
}
