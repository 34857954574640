import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import * as _ from 'lodash-es';
import { ActiveToast } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { NotificationType } from 'src/app/shared/common/components/gsp-notification/gsp-notification.component';
import { FeaturesStreamsService } from 'src/app/shared/common/current-features/features-streams.service';
import { LayersStore } from 'src/app/shared/common/current-layers/layers-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { TasksStore } from 'src/app/shared/common/current-tasks/tasks-store.service';
import { TasksStreamsService } from 'src/app/shared/common/current-tasks/tasks-streams.service';
import { TemplatesStoreService } from 'src/app/shared/common/current-templates/templates-store.service';
import { CurrentUserStreamService } from 'src/app/shared/common/current-user/current-user-stream.service';
import { StatusService } from 'src/app/shared/common/task/status.service';
import { Task, TaskFeature, TaskStatus } from 'src/app/shared/common/task/task';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';
import { CachedFeatureService } from 'src/app/shared/map-data-services/feature/cached-feature.service';
import { Feature } from 'src/app/shared/map-data-services/feature/feature';
import { FeatureService } from 'src/app/shared/map-data-services/feature/feature.service';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';
import { PermissionType } from 'src/app/shared/map-data-services/mapWorkspace/permission';
import { Project } from 'src/app/shared/map-data-services/project/project';
import { Template } from 'src/app/shared/template-services/template';
import { Actor } from 'src/app/shared/user/actor';
import { User, UserRole } from 'src/app/shared/user/user';

import { SidePanelStreamsService } from '../side-panel-streams.service';
import { SidePanelName } from '../side-panel.component';

// class representing feature on the task detail panel
class TaskPanelLayer {
    templateSeriesId: string;
    layer: Layer;
    layerColor: string;
    isLayerRemoved: boolean;
    featureIds: string[];
}

@Component({
    selector: 'task-detail-panel',
    templateUrl: './task-detail-panel.component.html'
})
export class TaskDetailPanelComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input()
    taskId: string;
    // expose enums to template
    public ButtonType = ButtonType;
    public NotificationType = NotificationType;
    public PermissionType = PermissionType;

    private readonly destroyed = new Subject<void>();

    public activeToast: ActiveToast<any> = null;
    public currentProject: Project;
    public currentUserId: string;
    public task: Task = null;
    public numberOfNonTemplatedFeaturesExcluded = 0;
    public editMode = false;
    public disableEdit = true;
    public todoTitleRequired = false;
    public originalTask: Task = null;
    public saving = false;
    public newMode: boolean;
    public loading = true;
    public today = new Date();
    public allPotentialAssignees: User[] = [];
    public layerKeyToMapWorkspaceLayer: { [key: string]: Layer };
    public templatesBySeriesId: { [templateId: string]: Template } = {};
    public templateSeriesIdToTaskPanelLayer: { [key: string]: TaskPanelLayer } = {};
    public taskPanelLayers: TaskPanelLayer[] = [];
    public isRemoveFeatureInProgress = false;
    public modifyPermission = false;
    public currentProjectAssignees: Actor[] = [];

    constructor(
        private projectStream: ProjectStreamService,
        private currentUserStream: CurrentUserStreamService,
        private translate: TranslationService,
        private tasksStore: TasksStore,
        private statusService: StatusService,
        private featuresStreams: FeaturesStreamsService,
        private cachedFeatureService: CachedFeatureService,
        private layersStore: LayersStore,
        private templatesStore: TemplatesStoreService,
        private sidePanelStreams: SidePanelStreamsService,
        private messaging: MessagingService,
        private featureService: FeatureService,
        private tasksStreams: TasksStreamsService
    ) {}

    async ngOnInit(): Promise<void> {
        this.currentUserId = this.currentUserStream.currentUser.id;
        this.currentProjectAssignees = this.tasksStore.getAllAssignees();
        this.layerKeyToMapWorkspaceLayer = this.layersStore.getLayerKeyToLayerMap();
        this.projectStream.currentProjectStream
            .pipe(takeUntil(this.destroyed))
            .subscribe(project => (this.currentProject = project));
        this.newMode = !Boolean(this.taskId);
        this.task = this.taskId
            ? await this.tasksStore.getTaskById(this.taskId)
            : await this.tasksStore.createNewTask();

        if (this.task) {
            this.setModifyPermission();
            this.disableEdit = this.task.status === TaskStatus.CLOSED;
            if (this.newMode) {
                this.featuresStreams.selectedFeaturesStream.pipe(takeUntil(this.destroyed)).subscribe(features => {
                    this.setTaskFeaturesFromSelectedFeatures(features);
                    this.loadLayersForTaskFeatures();
                });
            } else {
                this.loadLayersForTaskFeatures();
            }
            this.subscribeToPendingLoadingTaskStream();
            this.originalTask = CloneUtils.cloneDeep(this.task);
        }
        this.loading = false;
    }

    ngAfterViewInit(): void {
        // close the task details pane on selecting new feature
        this.featuresStreams.selectedFeaturesStream.pipe(takeUntil(this.destroyed), skip(1)).subscribe(features => {
            if (features.length && !this.isRemoveFeatureInProgress) {
                if (this.editMode) {
                    this.cancelSave();
                }
                this.sidePanelStreams.closeSidePanel(SidePanelName.TASK_DETAIL);
            }
            this.isRemoveFeatureInProgress = false;
        });
    }

    ngOnDestroy(): void {
        // TODO: Test this
        if (this.activeToast && this.activeToast.toastId) {
            // this.toastPromise.then(toast => this.messaging.clear(toast));
            this.messaging.clear(this.activeToast.toastId);
            this.activeToast = null;
        }

        this.sidePanelStreams.deregisterOnCloseHandler(SidePanelName.TASK_DETAIL);

        this.destroyed.next(null);
    }

    private subscribeToPendingLoadingTaskStream(): void {
        // update task.taskFeatures on task feature visibility changes
        this.tasksStreams.pendingLoadingTaskStream.pipe(takeUntil(this.destroyed)).subscribe(pendingTaskObj => {
            if (pendingTaskObj && pendingTaskObj.loading) {
                if (this.task.id === pendingTaskObj.task.id) {
                    this.task = pendingTaskObj.task;
                }
            }
        });
    }

    public getErrorText(title: NgModel): string {
        if (title.touched) {
            const isRequiredError = _.filter([title.errors], 'required');

            if (isRequiredError && isRequiredError.length) {
                return this.translate.instant('Common.ValueRequired.Tooltip');
            }
        }

        return null;
    }

    public get exceedsLimit(): boolean {
        return this.task && this.task.features && this.task.features.length > 1000;
    }

    private setTaskFeaturesFromSelectedFeatures(features: Feature[]): void {
        let numberOfNonTemplatedFeaturesExcluded = 0;
        this.task.features = features
            // filter out features in layers which have no template
            .filter(feature => {
                let layer: Layer = null;
                if (feature.layerId) {
                    layer = this.layersStore.getMapWorkspaceLayerById(feature.layerId);
                }
                if (!layer || !layer.templateSeriesId) {
                    numberOfNonTemplatedFeaturesExcluded++;
                    return false;
                }
                return true;
            })
            .map(
                feature =>
                    ({
                        featureId: feature.id,
                        templateSeriesId: this.layersStore.getMapWorkspaceLayerById(feature.layerId).templateSeriesId,
                        status: 'NeedsUpdating'
                    } as TaskFeature)
            );

        this.numberOfNonTemplatedFeaturesExcluded = numberOfNonTemplatedFeaturesExcluded;
    }

    // ensure all layers, including those no longer in the workspace, are loaded
    // TODO: ideally should be working off task-features-stream or similar
    private loadLayersForTaskFeatures(): Promise<void> {
        let kayerKeyToLayerMap = this.layersStore.getLayerKeyToLayerMap(); // layers in current workspace
        let promises: Promise<void>[] = [];
        this.templateSeriesIdToTaskPanelLayer = {};
        this.task.features.forEach(taskFeature => {
            if (!this.templateSeriesIdToTaskPanelLayer[taskFeature.templateSeriesId]) {
                this.templateSeriesIdToTaskPanelLayer[taskFeature.templateSeriesId] = {
                    templateSeriesId: taskFeature.templateSeriesId,
                    layer: null,
                    layerColor: '#000000',
                    isLayerRemoved: false,
                    featureIds: []
                };
                // initially get the feature (from cache or API)
                let feature = this.cachedFeatureService.getFeature(taskFeature.featureId);
                let getFeaturePromise =
                    feature && !feature.deleted
                        ? Promise.resolve(feature)
                        : this.featureService.getFeatureById(this.currentProject.id, taskFeature.featureId);
                let loadLayerPromise = getFeaturePromise.then(feature2 => {
                    let layerKey = feature2.getLayerKey();
                    let isLayerRemoved = !Boolean(kayerKeyToLayerMap[layerKey]);
                    if (layerKey) {
                        let layerId = layerKey; // for templated layers, this is the layerId
                        // gets non-ws layers too
                        return this.layersStore.getMapWorkspaceLayerByIdPromise(layerId).then(layer => {
                            let taskPanelLayer = this.templateSeriesIdToTaskPanelLayer[taskFeature.templateSeriesId];
                            taskPanelLayer.layer = layer;
                            taskPanelLayer.layerColor = layer.getLayerColor();
                            taskPanelLayer.isLayerRemoved = isLayerRemoved;
                        });
                    } else {
                        Promise.resolve();
                    }
                });
                promises.push(loadLayerPromise);

                let loadTemplatePromise = this.templatesStore
                    .getTemplateBySeriesId(taskFeature.templateSeriesId)
                    .then(template => {
                        this.templatesBySeriesId[taskFeature.templateSeriesId] = template;
                    });
                promises.push(loadTemplatePromise);
            }
            this.templateSeriesIdToTaskPanelLayer[taskFeature.templateSeriesId].featureIds.push(taskFeature.featureId);
        });

        return Promise.all(promises).then(() => {
            this.taskPanelLayers = Object.values(this.templateSeriesIdToTaskPanelLayer);
        });
    }

    public getLayerName(layer: Layer): string {
        return layer ? layer.layerName : '(missing)';
    }

    public getTemplateGeometryType(templateSeriesId: string): string {
        let template = this.templatesBySeriesId[templateSeriesId];
        return template ? template.geometryType : 'None';
    }

    public removeFeatures(featureIds: string[]): void {
        this.featuresStreams.removeFromSelectedFeatures(featureIds);
        this.isRemoveFeatureInProgress = true;
    }

    public assigneesDisplayValue(): string {
        return this.task.assignees.length
            ? this.task.assignees.map(assignee => assignee.name).join(', ')
            : this.translate.instant('TC.Common.AllUsers');
    }

    public statusDisplayValue(): string {
        return this.statusService.labelToDisplayValue(this.task.status);
    }

    public close(): void {
        this.editMode = false;
        if (this.needsSave()) {
            this.save();
        } else {
            this.done();
        }
    }

    public cancelSave(): void {
        this.task = CloneUtils.cloneDeep(this.originalTask);
        this.todoTitleRequired = false;
        this.done();
    }

    public saveAction = (): Promise<Task> => this.save();
    private save(): Promise<Task> {
        return this.saveTask().then(this.done.bind(this));
    }

    public delete(): void {
        this.deleteTask().then(() => this.done());
    }

    public disableDelete = (): boolean => this.loading || this.saving;

    private async setModifyPermission(): Promise<void> {
        const user = await this.projectStream.getUserWithRolesForCurrentProject(this.currentUserId);
        this.modifyPermission = user.role === UserRole.ADMIN || this.task.createdBy === this.currentUserId;
    }

    private done(): void {
        if (!this.editMode) {
            this.sidePanelStreams.closeSidePanel(SidePanelName.TASK_DETAIL);
        }
        this.editMode = false;
    }

    private canSave(): boolean {
        if (!this.task.title) {
            this.todoTitleRequired = true;
        }
        return !this.todoTitleRequired;
    }

    private needsSave(): boolean {
        return (
            this.task.description !== this.originalTask.description ||
            this.task.priority !== this.originalTask.priority ||
            // TODO: GIM 28/5/2019 - check this does same as ng1's angular.equals
            !_.isEqual(this.task.dueDate, this.originalTask.dueDate) ||
            !_.isEqual(this.task.assignees, this.originalTask.assignees) ||
            !_.isEqual(this.task.features, this.originalTask.features)
        );
    }

    public canEdit(): boolean {
        return this.task.status !== TaskStatus.CLOSED && (this.editMode || this.newMode);
    }

    private saveTask(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this.canSave()) {
                this.saving = true;
                this.task.description =
                    this.task.description ||
                    this.translate.instant('TC.Common.CreatedOn') + ' ' + new Date().toLocaleDateString();
                this.tasksStore
                    .saveTask(this.task)
                    .then(
                        savedTask => {
                            this.messaging.showSuccess(
                                this.translate.instant(this.newMode ? 'TC.Common.ToDoCreated' : 'TC.Common.ToDoSaved')
                            );
                            this.originalTask = CloneUtils.cloneDeep(this.task);
                            resolve(Boolean(savedTask));
                        },
                        () => {
                            this.activeToast = this.messaging.showError(
                                this.translate.instant(
                                    this.newMode ? 'TC.Common.ErrorWhileCreating' : 'TC.Common.ErrorWhileUpdating'
                                )
                            );
                            reject(false);
                        }
                    )
                    .finally(() => {
                        this.saving = false;
                    });
            } else {
                reject('cannot save');
            }
        });
    }

    private deleteTask(): Promise<void> {
        this.saving = true;
        return this.tasksStore
            .deleteTask(this.task)
            .then(
                () => {
                    this.messaging.showSuccess(this.translate.instant('TC.Common.ToDoDeleted'));
                    this.originalTask = CloneUtils.cloneDeep(this.task);
                    // hide the selection of task features in the mapViewer after deletion if visible
                    if (this.task.visible) {
                        this.tasksStreams.deferHideTasksStream.next([this.task]);
                    }
                },
                () => {
                    this.activeToast = this.messaging.showError(this.translate.instant('TC.Common.ErrorWhileDeleting'));
                }
            )
            .finally(() => {
                this.saving = false;
            });
    }
}
