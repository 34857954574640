import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppMaterialModule } from 'src/app/app-material.module';
import { TranslationModule } from 'src/app/core/translation/translation.module';
import { CommonModule as AppCommonModule } from 'src/app/shared/common/common.module';

import { WorkspaceModule } from '../workspace/workspace.module';
import { ExportDataComponent } from './export.component';
import { ExportService } from './export.service';
import { RecentExportsComponent } from './recent-exports/recent-exports.component';

@NgModule({
    declarations: [ExportDataComponent, RecentExportsComponent],
    exports: [ExportDataComponent],
    imports: [CommonModule, AppCommonModule, FormsModule, TranslationModule, WorkspaceModule, AppMaterialModule],
    providers: [ExportService]
})
export class ExportModule {}
