import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

import { FeatureFlag } from './feature-flag';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagService {
    constructor(private http: HttpClient) {}

    public getFeatureFlags(): Promise<FeatureFlag[]> {
        return lastValueFrom(this.http.get(environment.featureFlagUrl + '/featureflags')) as Promise<FeatureFlag[]>;
    }
}
