export class MapWorkspacePermission {
    fileName = '';
    folderId = ''; // There is a null check scenario in the usage logic so, changing to empty string
    folderIds: string[] = [];
    fileIds: string[] = [];
    fileVersionIds: string[] = [];
    fileLastAssimIds: string[] = [];

    effectivePermission = MapWorkspacePermissionType.FULL_ACCESS;

    constructor(public mapWorkspaceId: string, public currentUserId: string) {}
}

export enum MapWorkspacePermissionType {
    FULL_ACCESS = 'FULL_ACCESS',
    READ = 'READ',
    NO_ACCESS = 'NO_ACCESS',
    NO_FILE_ACCESS = 'NO_FILE_ACCESS',
    FILE_VIEW_MODE = 'FILE_VIEW_MODE'
}
