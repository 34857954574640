import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { MapWorkspaceStreamsService } from 'src/app/shared/common/current-map-workspaces/map-workspace-streams.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';

import { Rtc } from './Rtc';
import { RtcService } from './rtc.service';

@Injectable({
    providedIn: 'root'
})
export class RtcStore {
    // --------------------------------------
    public rtcStream = new BehaviorSubject<Rtc[]>([]);

    public rtcLoadingStream = new BehaviorSubject<boolean>(false);

    private currentProjectId: string = this.projectStream.getCurrentProject()
        ? this.projectStream.getCurrentProject().id
        : null;
    private currentMapWorkspace: MapWorkspace;
    private currentRtcList: Rtc[] = [];

    private currentWorkspaceIds: string[] = [];

    // --------------------------------------

    constructor(
        private rtcService: RtcService,
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private mapWorkspaceStreams: MapWorkspaceStreamsService,
        private projectStream: ProjectStreamService
    ) {
        mapWorkspaceStreams.projectMapWorkspacesStream.subscribe(workspaces => {
            if (workspaces) {
                const newWorkspaceIds = workspaces.map(workspace => workspace.id);
                if (!_.isEqual(this.currentWorkspaceIds, newWorkspaceIds)) {
                    this.rtcStream.next([]);
                    this.currentRtcList = [];
                    this.currentWorkspaceIds = newWorkspaceIds;
                }
            }
        });

        mapWorkspacesStore.currentMapWorkspaceStream.pipe(distinctUntilChanged()).subscribe(workspace => {
            this.currentMapWorkspace = workspace as MapWorkspace;
        });
    }

    public async loadAllRtc(startIndex: number, pageSize: number): Promise<Rtc[]> {
        this.rtcLoadingStream.next(true);
        if (this.currentProjectId) {
            let rtcCacheList = this.rtcStream.getValue();
            if (rtcCacheList && rtcCacheList.length) {
                this.rtcStream.next(rtcCacheList);
                this.rtcLoadingStream.next(false);
                return new Promise(resolve => {
                    resolve(rtcCacheList);
                });
            } else {
                const rtcList = await this.rtcService.getRtcsPaged(this.currentProjectId, null, startIndex, pageSize);
                this.currentRtcList = this.currentRtcList.concat(rtcList.items);
                this.rtcStream.next(rtcList.items);
                this.rtcLoadingStream.next(false);
                return rtcList.items;
            }
        } else {
            return new Promise(resolve => {
                this.currentRtcList = [];
                this.rtcStream.next([]);
                this.rtcLoadingStream.next(false);
                resolve([]);
            });
        }
    }

    public async createRtc(newRtc: Rtc): Promise<Rtc> {
        this.rtcLoadingStream.next(true);
        this.currentRtcList = this.rtcStream.getValue();
        const rtc = await this.rtcService.createRtc(this.currentProjectId, this.currentMapWorkspace.id, newRtc);
        this.currentRtcList.unshift(rtc);
        this.rtcStream.next(this.currentRtcList);
        this.rtcLoadingStream.next(false);
        return rtc;
    }

    public async updateRtc(rtcToBeUpdated: Rtc): Promise<void> {
        this.currentRtcList = this.rtcStream.getValue();
        this.rtcLoadingStream.next(true);
        const updatedRtc = await this.rtcService.updateRtc(this.currentProjectId, rtcToBeUpdated.id, rtcToBeUpdated);
        let indexToBeUpdated = _.findIndex(this.currentRtcList, rtc => rtc.id === updatedRtc.id);
        if (indexToBeUpdated >= 0) {
            this.currentRtcList[indexToBeUpdated] = updatedRtc;
            rtcToBeUpdated = CloneUtils.cloneDeep(updatedRtc);
            this.rtcStream.next(this.currentRtcList);
            this.rtcLoadingStream.next(false);
        }
        Promise.resolve();
    }

    public deleteRtc(rtcToBeDeleted: Rtc): Promise<void> {
        this.rtcLoadingStream.next(true);
        return new Promise<void>(async (resolve, reject) => {
            this.currentRtcList = this.rtcStream.getValue();
            try {
                await this.rtcService.deleteRtc(this.currentProjectId, rtcToBeDeleted.id);
                let indexToBeDeleted = _.findIndex(this.currentRtcList, rtc => rtc.id === rtcToBeDeleted.id);
                this.currentRtcList.splice(indexToBeDeleted, 1);
                this.rtcStream.next(this.currentRtcList);
                this.rtcLoadingStream.next(false);
                resolve();
            } catch (e) {
                reject();
            }
        });
    }

    public getRtcById(rtcId: string): Promise<Rtc> {
        return new Promise<Rtc>(resolve => {
            let rtcPresentInList = _.find(this.currentRtcList, rtc => rtc.id === rtcId);
            if (rtcPresentInList) {
                resolve(rtcPresentInList);
            } else {
                this.rtcService.getRtc(this.currentProjectId, rtcId).then(rtc => {
                    resolve(rtc);
                });
            }
        });
    }

    public refreshRtcList(): void {
        this.rtcStream.next(this.rtcStream.getValue());
    }
}
