import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { CurrentUserStreamService } from 'src/app/shared/common/current-user/current-user-stream.service';
import { LoaderStreamService } from 'src/app/shared/common/loader/loader-stream.service';
import { User } from 'src/app/shared/user/user';

import { EnvironmentService } from './shared/common/environment/environment.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy {
    public readonly showBusy$: Observable<boolean> = this.loaderStream.isLoading$;
    private destroyed$ = new Subject<void>();
    public isAppNavigating = false;

    public isEnvServiceReadyStream$: Observable<boolean> = this.environmentService.serviceReadyStream.pipe(
        filter(ready => ready)
    );

    public isUserReadySream$: Observable<User> = this.currentUserStream.stream.pipe(filter(user => user !== null));

    constructor(
        private translate: TranslationService,
        private currentUserStream: CurrentUserStreamService,
        private router: Router,
        private loaderStream: LoaderStreamService,
        private environmentService: EnvironmentService
    ) {
        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('en');
        translate.use('en');

        currentUserStream.stream
            .pipe(takeUntil(this.destroyed$))
            .subscribe(user => user && this.translate.use(user.locale));
    }

    ngOnInit(): void {
        this.router.events.pipe(takeUntil(this.destroyed$)).subscribe(event => {
            switch (true) {
                case event instanceof NavigationStart:
                    // Avoid updating during change detection (ExpressionChangedAfterItHasBeenCheckedError)
                    setTimeout(() => {
                        this.isAppNavigating = true;
                    });
                    break;
                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError:
                    setTimeout(() => {
                        this.isAppNavigating = false;
                    });
                    break;
                default:
                    break;
            }
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(null);
    }
}
