import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { RuleSelectionStreamService } from 'src/app/feature/template-editor/rules-panel/rule-selection-stream.service';
import { FieldType } from 'src/app/shared/template-services/field';
import { ChoiceCondition } from 'src/app/shared/template-services/rule/choice-condition';
import { ConditionOperator } from 'src/app/shared/template-services/rule/condition';
import { Rule } from 'src/app/shared/template-services/rule/rule';

import { FieldBaseComponent } from '../../field-base.component';

@Component({
    selector: 'choice-rule',
    templateUrl: './choice-rule.component.html'
})
export class ChoiceRuleComponent extends FieldBaseComponent implements OnDestroy, OnChanges {
    @Input()
    condition: ChoiceCondition;

    get selectedRuleNo(): number {
        return this.template.rules.indexOf(this.selectedRule);
    }

    @Input()
    selectedRule: Rule;

    private destroyed = new Subject<void>();

    // exposing enum to template
    public ConditionOperator = ConditionOperator;
    public FieldType = FieldType;

    constructor(selectionservice: RuleSelectionStreamService) {
        super();

        // TODO LATER: GIM 25.6.19 - replace this with an async binding to selectedRule
        selectionservice.ruleSelectionStream
            .pipe(
                takeUntil(this.destroyed),
                filter(s => !!s)
            )
            .subscribe(selectedRule => {
                this.selectedRule = selectedRule;
            });
    }

    resetChoiceSelection(): void {
        this.condition.resetOptionIncludingOther(this.selectedRuleNo, this.condition.currentChoiceFieldValues);
    }

    ngOnChanges(): void {
        this.template.populateChoiceValues();
        this.condition.currentChoiceFieldValues = this.field.values;
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }
}
