import { User } from 'src/app/shared/user/user';

import { MapWorkspacePermissionType } from '../mapWorkspace/map-workspace-permission';

export interface ProjectDTO {
    id?: string;
    name?: string;
    rootId?: string;
    thumbnail?: string;
    address?: {
        text: string;
        geometry: string;
    };
    location?: string;
    lastVisitedOn?: Date;
    modifiedOn?: Date;
    createdOn?: Date;
    updatedOn?: Date;
    createdBy?: Partial<User>;
    modifiedBy?: Partial<User>;
    size?: number;
    filesCount?: number;
    foldersCount?: number;
    versionsCount?: number;
    usersCount?: number;
    description?: string;
    startDate?: Date;
    endDate?: Date;
    access?: MapWorkspacePermissionType;
}

export interface ProjectSettings {
    isProjectInviteRestricted: boolean;
    isTodosRestricted: boolean;
    notificationSettings: { [key: string]: any };
    syncSettings: { [key: string]: any };
    unitSettings: {
        angularunit: string;
        angularunitformat: string;
        areaunit: string;
        areaunitformat: string;
        lengthmeasurementunit: string;
        lengthmeasurementunitformat: string;
        lengthunit: string;
        lengthunitformat: string;
        unitsystem: string;
        volumeunit: string;
        volumeunitformat: string;
        weightunit: string;
        weightunitformat: string;
    };
}
export class Project {
    // DTO properties
    id: string = null;
    createdBy: User = null;
    rootId: string = null;
    isSharedProject = false;
    name: string = null;

    // Added properties

    // Managed by project-stream.service
    settings: ProjectSettings = null;
    isChanged = false;
    location: string = null;

    constructor() {}

    static fromDTO(dto: ProjectDTO): Project {
        const project = new Project();

        if (dto) {
            project.id = dto.id;
            project.name = dto.name;
            project.rootId = dto.rootId;
            project.createdBy = User.fromDTO(dto.createdBy);
            project.isSharedProject = false;
            project.location = dto.location;
        }

        return project;
    }

    toDTO(): ProjectDTO {
        // to API request DTO
        return {
            id: this.id,
            name: this.name,
            createdBy: this.createdBy
        };
    }
}
