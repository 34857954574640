import { Component, Input } from '@angular/core';
import { Template } from 'src/app/shared/template-services/template';

@Component({
    selector: 'links-panel',
    templateUrl: './links-panel.component.html'
})
export class LinksPanelComponent {
    @Input()
    template: Template;

    @Input()
    linkDetails: any[];

    constructor() {}
}
