<gsp-popup
    [heading]="'MapViewer.Template.LockedSchema.InfoBoxTitle' | translate"
    [size]="ModalSize.Medium"
    [showDone]="true"
    [doneBtnTitle]="'MapViewer.Generic.GotIt' | translate"
    [showCancel]="false"
    [showClose]="true"
    (done)="close()"
    (closed)="close()"
    class="locked-schema-popup"
>
    <ng-container transclude-popup-content>
        <div [innerHTML]="'MapViewer.Template.LockedSchema.InfoBoxContent' | translate"></div>
    </ng-container>
</gsp-popup>
