import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
    selector: '[action-toast-component]',
    template: `
        <button *ngIf="options.closeButton" (click)="remove()" class="toast-close-button" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
            {{ title }} <ng-container *ngIf="duplicatesCount">[{{ duplicatesCount + 1 }}]</ng-container>
        </div>
        <div *ngIf="message && options.enableHtml" role="alertdialog" aria-live="polite" [class]="options.messageClass">
            {{ message }}
        </div>
        <div
            *ngIf="message && !options.enableHtml"
            role="alertdialog"
            aria-live="polite"
            [class]="options.messageClass"
            [attr.aria-label]="message"
        >
            {{ message }}
        </div>
        <div *ngIf="options.progressBar">
            <div class="toast-progress" [style.width]="width + '%'"></div>
        </div>
        <button *ngIf="actionTitle" (click)="action()" class="toast-action-button" [attr.aria-label]="actionTitle">
            {{ actionTitle }}
        </button>
    `,
    animations: [
        trigger('flyInOut', [
            state('inactive', style({ opacity: 0 })),
            state('active', style({ opacity: 1 })),
            state('removed', style({ opacity: 0 })),
            transition('inactive => active', animate('{{ easeTime }}ms {{ easing }}')),
            transition('active => removed', animate('{{ easeTime }}ms {{ easing }}'))
        ])
    ],
    preserveWhitespaces: false
})
export class ActionToastComponent extends Toast {
    // constructor is only necessary when not using AoT
    constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
        super(toastrService, toastPackage);
    }

    get actionTitle(): string {
        return (this.options as any).actionTitle;
    }

    action(event: Event): boolean {
        event.stopPropagation();
        this.toastPackage.triggerAction();
        return false;
    }
}
