<div class="rtc-search-container">
    <p>{{ label | translate }}</p>
    <gsp-text
        #searchInputRef="ngModel"
        [type]="GspTextType.GENERIC"
        class="gsp-text-wrapper"
        [(ngModel)]="searchInput"
        placeholder="{{ 'TC.Common.Search' | translate }}"
        [id]="searchId"
    ></gsp-text>
</div>
