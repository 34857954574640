import { Component, Input, OnInit } from '@angular/core';
import { GeneralUtils } from 'src/app/shared/common/utility/general-utils';

import { YesNoFeatureField } from '../feature-field';

@Component({
    templateUrl: './yes-no-field.component.html',
    selector: 'yes-no-field'
})
export class YesNoFieldComponent implements OnInit {
    @Input()
    field: YesNoFeatureField;

    @Input()
    editMode: boolean;

    ngOnInit(): void {
        if (GeneralUtils.isNullUndefinedOrNaN(this.field.value)) {
            this.field.value = null;
        }
    }

    public displayYesNo(value: boolean): string {
        if (value === undefined || value === null) {
            return null;
        } else {
            return value.toString() === 'true' ? 'yes' : 'no';
        }
    }
}
