import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Pipe({ name: 'translate', pure: false })
export class TranslationPipe extends TranslatePipe implements PipeTransform, OnDestroy {
    transform(query: string, ...args: any[]): string {
        if (query === null || query === undefined || query === '') {
            return '';
        }
        return super.transform(query, ...args);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
