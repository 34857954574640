import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MapMenuCode } from 'src/app/feature/map-viewer/map-menus/map-menu-list';

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    activeMenusStream = new BehaviorSubject<MapMenuCode[]>([]);
}
