import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash-es';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { LayersStore } from 'src/app/shared/common/current-layers/layers-store.service';
import { LayersStreams } from 'src/app/shared/common/current-layers/layers-streams.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { TasksStore } from 'src/app/shared/common/current-tasks/tasks-store.service';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';

@Component({
    template: `
        <div id="remove-layer" class="layer-context-menu-item">
            {{ 'TCS.Mapviewer.LayerContextMenu.RemoveLayer' | translate }}
        </div>
    `,
    selector: 'remove-layer'
})
export class RemoveLayerComponent implements OnInit {
    @Input()
    layer: Layer;

    currentProjectId: string;
    currentWorkspaceId: string;

    constructor(
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private projectStream: ProjectStreamService,
        private messaging: MessagingService,
        private layersStore: LayersStore,
        private layersStreams: LayersStreams,
        private tasksStore: TasksStore,
        private translate: TranslationService
    ) {}

    ngOnInit(): void {
        // TODO LATER: GIM 6/6/2019 - move to constructor?
        this.currentWorkspaceId = this.mapWorkspacesStore.currentMapWorkspaceStream.getValue().id;
        this.currentProjectId = this.projectStream.currentProjectStream.getValue().id;
    }

    private hasActiveTasks(layer: Layer): boolean {
        let getActiveTaskByTemplateSeriesId = this.tasksStore.mapWorkspaceActiveTasksByTemplateSeriesIdStream.getValue();
        if (
            getActiveTaskByTemplateSeriesId &&
            getActiveTaskByTemplateSeriesId[layer.templateSeriesId] &&
            _.some(getActiveTaskByTemplateSeriesId[layer.templateSeriesId], task => !task.isClosed)
        ) {
            this.messaging.showWarning(this.translate.instant('TCS.Mapviewer.LayerPanel.TemplateHasActiveTask'));
            return true;
        } else {
            return false;
        }
    }

    public async removeLayer(layer: Layer): Promise<void> {
        if (!this.hasActiveTasks(layer)) {
            try {
                await this.layersStore.removeLayerFromMapWorkspace(layer);
                this.layersStreams.deferHideLayersStream.next([layer]);
                this.showSuccess(layer);
            } catch (e) {
                this.messaging.showError(this.translate.instant('TC.Common.ErrorWhileDeleting'));
            }
        }
    }

    private showSuccess(deletedlayer: Layer): void {
        let wid = this.currentWorkspaceId;
        let pid = this.currentProjectId;

        let toastr = this.messaging.showSuccess(
            this.translate.instant('TCS.Mapviewer.LayerPanel.LayerRemoved') +
                '<br>' +
                this.translate.instant('RESTORE') +
                ' ' +
                deletedlayer.layerName,
            null,
            { enableHtml: true }
        );

        toastr.onTap.subscribe(() => {
            // restore layer
            this.restoreLayer(pid, wid, deletedlayer);
        });
    }

    private async restoreLayer(currentProjectId: string, currentWorkspaceId: string, layer: Layer): Promise<Layer> {
        try {
            await this.layersStore.addLayerToMapWorkspace(currentProjectId, currentWorkspaceId, layer);
            this.messaging.showSuccess(this.translate.instant('TCS.Mapviewer.LayerPanel.RestoreSuccess'));
            return layer;
        } catch (e) {
            this.messaging.showError(this.translate.instant('TC.Common.ErrorWhileRestoring'));
            return await Promise.resolve(null);
        }
    }
}
