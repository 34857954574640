<div
  [style.background]="imageVisible && backgroundColor"
  #wrapper
>
  <img
    #sourceImage
    class="ngx-ic-source-image"
    role="presentation"
    *ngIf="safeImgDataUrl"
    [src]="safeImgDataUrl"
    [style.visibility]="imageVisible ? 'visible' : 'hidden'"
    [style.transform]="safeTransformStyle"
    [class.ngx-ic-draggable]="!disabled && allowMoveImage"
    [attr.alt]="imageAltText"
    (load)="imageLoadedInView()"
    (mousedown)="startMove($event, moveTypes.Drag)"
    (touchstart)="startMove($event, moveTypes.Drag)"
    (error)="loadImageError($event)"
  >
  <div
    class="ngx-ic-overlay"
    [style.width.px]="maxSize.width"
    [style.height.px]="maxSize.height"
    [style.margin-left]="alignImage === 'center' ? marginLeft : null"
  ></div>
  <div class="ngx-ic-cropper"
       *ngIf="imageVisible"
       [class.ngx-ic-round]="roundCropper"
       [attr.aria-label]="cropperFrameAriaLabel"
       [style.top.px]="cropper.y1"
       [style.left.px]="cropper.x1"
       [style.width.px]="cropper.x2 - cropper.x1"
       [style.height.px]="cropper.y2 - cropper.y1"
       [style.margin-left]="alignImage === 'center' ? marginLeft : null"
       [style.visibility]="imageVisible ? 'visible' : 'hidden'"
       (keydown)="keyboardAccess($event)"
       tabindex="0"
  >
    <div
      (mousedown)="startMove($event, moveTypes.Move)"
      (touchstart)="startMove($event, moveTypes.Move)"
      class="ngx-ic-move"
      role="presentation">
    </div>
    <ng-container *ngIf="!hideResizeSquares">
            <span class="ngx-ic-resize ngx-ic-topleft"
                  role="presentation"
                  (mousedown)="startMove($event, moveTypes.Resize, 'topleft')"
                  (touchstart)="startMove($event, moveTypes.Resize, 'topleft')">
                <span class="ngx-ic-square"></span>
            </span>
      <span class="ngx-ic-resize ngx-ic-top">
                <span class="ngx-ic-square"></span>
            </span>
      <span class="ngx-ic-resize ngx-ic-topright"
            role="presentation"
            (mousedown)="startMove($event, moveTypes.Resize, 'topright')"
            (touchstart)="startMove($event, moveTypes.Resize, 'topright')">
                <span class="ngx-ic-square"></span>
            </span>
      <span class="ngx-ic-resize ngx-ic-right">
                <span class="ngx-ic-square"></span>
            </span>
      <span class="ngx-ic-resize ngx-ic-bottomright"
            role="presentation"
            (mousedown)="startMove($event, moveTypes.Resize, 'bottomright')"
            (touchstart)="startMove($event, moveTypes.Resize, 'bottomright')">
                <span class="ngx-ic-square"></span>
            </span>
      <span class="ngx-ic-resize ngx-ic-bottom">
                <span class="ngx-ic-square"></span>
            </span>
      <span class="ngx-ic-resize ngx-ic-bottomleft"
            role="presentation"
            (mousedown)="startMove($event, moveTypes.Resize, 'bottomleft')"
            (touchstart)="startMove($event, moveTypes.Resize, 'bottomleft')">
                <span class="ngx-ic-square"></span>
            </span>
      <span class="ngx-ic-resize ngx-ic-left">
                <span class="ngx-ic-square"></span>
            </span>
      <span class="ngx-ic-resize-bar ngx-ic-top"
            role="presentation"
            (mousedown)="startMove($event, moveTypes.Resize, 'top')"
            (touchstart)="startMove($event, moveTypes.Resize, 'top')">
            </span>
      <span class="ngx-ic-resize-bar ngx-ic-right"
            role="presentation"
            (mousedown)="startMove($event, moveTypes.Resize, 'right')"
            (touchstart)="startMove($event, moveTypes.Resize, 'right')">
            </span>
      <span class="ngx-ic-resize-bar ngx-ic-bottom"
            role="presentation"
            (mousedown)="startMove($event, moveTypes.Resize, 'bottom')"
            (touchstart)="startMove($event, moveTypes.Resize, 'bottom')">
            </span>
      <span class="ngx-ic-resize-bar ngx-ic-left"
            role="presentation"
            (mousedown)="startMove($event, moveTypes.Resize, 'left')"
            (touchstart)="startMove($event, moveTypes.Resize, 'left')">
            </span>
    </ng-container>
  </div>
</div>
