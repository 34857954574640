import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import { Subject } from 'rxjs';

import { SelectionMode } from '../../toolbar/mapToolbar/selectionTools/selection-tool.component';

export enum MapDrawActionSource {
    SELECTION_TOOL = 'selectionTool',
    MAP_CACHE_CREATOR = 'mapCacheCreator'
}

export class MapDrawAction {
    source: MapDrawActionSource; // selectionTool, mapCacheCreator
    mode: SelectionMode; // single, rectangle, polygon
    drawingOptions: any; // leaflet options for drawing rectangle and polygon
    drawingCursor: string; // leaflet cursor while drawing rectangle and polygon
}

export class MapDrawActionResult extends MapDrawAction {
    result: L.Polygon | L.LatLngBounds; // the resulting drawn shape or bounds; null => cancelled
}

@Injectable({
    providedIn: 'root'
})
export class MapDrawActionsStreamService {
    startMapDrawActionStream = new Subject<MapDrawAction>();
    mapDrawActionResultStream = new Subject<MapDrawActionResult>();
}
