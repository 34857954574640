<div>
    <canvas
        baseChart
        [style.height.px]="chartHeight"
        [style.width.px]="chartWidth"
        [data]="data"
        [options]="options"
        [type]="chartType"
        [plugins]="plugins"
        (chartClick)="chartClick.emit($event)"
    >
    </canvas>
</div>
