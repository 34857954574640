import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { TCFile } from 'src/app/shared/common/files/TCFile';
import { MapWorkspace, ShareWorkspaceData } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';

import { EnvironmentService } from 'src/app/shared/common/environment/environment.service';
import { DateUtils } from 'src/app/shared/common/utility/date-utils';
import { MainMenuStreamService } from '../map-viewer/main-menu/main-menu-stream.service';

export class WorkspaceAreaOfInterest {
    public center: L.LatLng;
    public southWest: L.LatLng;
    public northEast: L.LatLng;
    public zoom: number;
}

export enum WorkspaceCreationMode {
    NEW = 'NEW',
    DUPLICATE = 'DUPLICATE'
}

@Injectable({
    providedIn: 'root'
})
export class WorkspaceService {
    constructor(
        private env: EnvironmentService,
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private projectStream: ProjectStreamService,
        private router: Router,
        private mainMenuStream: MainMenuStreamService
    ) {}

    public modifyWorkspace$: BehaviorSubject<MapWorkspace> = new BehaviorSubject(null);
    public workspaceAreaOfInterest$: BehaviorSubject<WorkspaceAreaOfInterest> = new BehaviorSubject(null);
    public uploadWorkspaceTemplate$: BehaviorSubject<ShareWorkspaceData> = new BehaviorSubject(null);

    public workspaceCreationMode$: BehaviorSubject<WorkspaceCreationMode> = new BehaviorSubject(
        WorkspaceCreationMode.NEW
    );
    public sourceWorkspace$: BehaviorSubject<MapWorkspace> = new BehaviorSubject(null);
    public setDestinationFolder$: BehaviorSubject<TCFile> = new BehaviorSubject(null);
    public useSourcePropertiesForDuplicate$: BehaviorSubject<boolean> = new BehaviorSubject(true);

    public clearWorkspace(): void {
        this.modifyWorkspace$.next(null);
        this.uploadWorkspaceTemplate$.next(null);
        this.workspaceAreaOfInterest$.next(null);
        this.sourceWorkspace$.next(null);
        this.workspaceCreationMode$.next(WorkspaceCreationMode.NEW);
        this.useSourcePropertiesForDuplicate$.next(true);
    }

    // Workspace Image related functions
    public getThumbnailUrl(workspace: MapWorkspace, areaImage = false): string {
        if (!workspace) return null;

        const path = `${this.env.apiUrl}/projects/${workspace.projectId}/spatialworkspaces/${workspace.id}/image`;
        const queryParams = `width=265&height=125&fetchAreaImage=${areaImage.toString()}`;
        const cacheBuster = `&updatedTime=${DateUtils.toTimestamp(workspace.updatedUtc)}`;
        const imageUrl = `${path}?${queryParams}${cacheBuster}`;

        return workspace.hasThumbnail ? imageUrl : null;
    }

    // For workspace creation wizard
    public workspaceCreationOnClose(): void {
        const currentProjectId = this.projectStream.getCurrentProject().id;
        this.mapWorkspacesStore.getDefaultOrCreateMapWorkspace(currentProjectId).then((workspace: MapWorkspace) => {
            this.mapWorkspacesStore.editMapWorkspaceStream.next(null);
            this.clearWorkspace();
            this.router
                .navigate(['/mapViewer', { outlets: { centerDialog: null, mainMenu: 'workspaces' } }], {
                    queryParams: {
                        projectId: currentProjectId,
                        workspaceId: workspace.id
                    }
                })
                .then(() => {
                    this.mainMenuStream.showMainMenu$.next(true);
                });
        });
    }
}
