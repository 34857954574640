<div class="export-downloader" id="export-downloader">
    <div class="component-loading" *ngIf="loading"></div>
    <div class="content" *ngIf="!loading">
        <div *ngIf="downloadIsAvailable" id="download-available">
            <h2 translate="TC.Common.DownloadAutomatically"></h2>
            <p
                class="download-text"
                translate="TCS.Export.Download.SuccessInstruction"
                translate-values="{'downloadUrl': 'javascript:void(0);'}"
                (click)="downloadExportedData()"
                id="start-download"
            ></p>
        </div>
        <div *ngIf="downloadHasError" id="download-error">
            <h2 translate="TC.Common.DownloadError"></h2>
            <p translate="TC.Common.TryAgainLater"></p>
        </div>

        <div *ngIf="downloadHasExpired" id="download-expired">
            <h2 translate="TC.Export.DownloadExpired"></h2>
        </div>

        <div *ngIf="permissionDenied" id="permission-denied">
            <h2 translate="TC.Common.NoPermission"></h2>
            <p translate="TC.Common.ContactAdmin"></p>
        </div>
    </div>
</div>
