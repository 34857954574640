import { Component, Input, OnInit } from '@angular/core';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { UnitConversionService } from 'src/app/shared/common/utility/unit-conversion.service';
import { AutoFieldModelType } from 'src/app/shared/template-services/field';

import { AutoFeatureField } from '../feature-field';

@Component({
    templateUrl: './auto-field.component.html',
    selector: 'auto-field'
})
export class AutoFieldComponent implements OnInit {
    @Input()
    field: AutoFeatureField;

    @Input()
    editMode: boolean;

    displayUnitHtml = '';

    // Exposing enum to template
    public AutoFieldModelType = AutoFieldModelType;

    constructor(
        private unitConversionService: UnitConversionService,
        private mapWorkspacesStore: MapWorkspacesStoreService
    ) {}

    ngOnInit(): void {
        const currentMapWorkspace = this.mapWorkspacesStore.getCurrentMapWorkspace();
        if (
            !currentMapWorkspace.noProjection() &&
            (this.field.modelType === AutoFieldModelType.XPosition ||
                this.field.modelType === AutoFieldModelType.YPosition)
        ) {
            this.field.unit = currentMapWorkspace.coordinateSystem.horizontalUnit;
        } else if (this.field.modelType === AutoFieldModelType.ZPosition) {
            this.field.unit = currentMapWorkspace.coordinateSystem.verticalUnit;
        }

        if (
            this.field.modelType === AutoFieldModelType.GeometryArea ||
            this.field.modelType === AutoFieldModelType.GeometryLength ||
            this.field.modelType === AutoFieldModelType.EstimatedAccuracy ||
            this.field.modelType === AutoFieldModelType.EstimatedVerticalPrecision ||
            this.field.modelType === AutoFieldModelType.XPosition ||
            this.field.modelType === AutoFieldModelType.YPosition ||
            this.field.modelType === AutoFieldModelType.ZPosition ||
            this.field.type === AutoFieldModelType.GeometryArea ||
            this.field.type === AutoFieldModelType.GeometryLength ||
            this.field.type === AutoFieldModelType.EstimatedAccuracy ||
            this.field.type === AutoFieldModelType.EstimatedVerticalPrecision ||
            this.field.type === AutoFieldModelType.XPosition ||
            this.field.type === AutoFieldModelType.YPosition ||
            this.field.type === AutoFieldModelType.ZPosition
        ) {
            this.displayUnitHtml = this.unitConversionService.convertUnitToHtml(this.field.unit?.toLowerCase());
        }
    }
}
