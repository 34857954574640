<div *ngIf="value" class="time-picker-wrapper">
    <div class="time-selection">
        <gsp-dropdown
            name="timeSelection"
            [options]="timeOptions"
            [ngDisabled]="disabled"
            [forceDefaultAlignment]="true"
            idField="id"
            textField="displayText"
            [(ngModel)]="value.timeOfDay"
            (ngModelChange)="ngModelChange.emit(value)"
        ></gsp-dropdown>
    </div>
    <div class="period-selection">
        <gsp-dropdown
            name="periodSelection"
            [options]="periodOptions"
            [ngDisabled]="disabled"
            [forceDefaultAlignment]="true"
            idField="id"
            textField="displayText"
            [(ngModel)]="value.periodOfDay"
            (ngModelChange)="ngModelChange.emit(value)"
        ></gsp-dropdown>
    </div>
    <div class="time-zone-selection">
        <gsp-dropdown
            name="timeZoneOffset"
            [options]="timeZoneOptions"
            [ngDisabled]="disabled"
            idField="id"
            textField="displayText"
            [(ngModel)]="value.timeZoneOffset"
            (ngModelChange)="ngModelChange.emit(value)"
        ></gsp-dropdown>
    </div>
</div>
