import { Component, forwardRef, Input } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';
import { BooleanConverter, InputConverter, IntConverter } from 'src/app/shared/common/components/input-converter.decorator';
import { ValueAccessorBase } from 'src/app/shared/common/components/value-accessor-base';

@Component({
    selector: 'range-field',
    templateUrl: './range-field.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RangeFieldComponent),
            multi: true
        }
    ],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class RangeFieldComponent extends ValueAccessorBase<{ lo: string; hi: string }> {
    @Input()
    @InputConverter(BooleanConverter)
    enabled = true;

    @Input()
    label: string;

    @Input()
    minimumFieldName: string;

    @Input()
    maximumFieldName: string;

    @Input()
    @InputConverter(IntConverter)
    decimalPlaces: number;

    @Input()
    @InputConverter(IntConverter)
    minimum: number;

    @Input()
    @InputConverter(IntConverter)
    maximum: number;

    @Input()
    minimumPlaceholder = '';

    @Input()
    maximumPlaceholder = '';

    get maxForMinField(): number {
        if (
            this.value.hi === null ||
            this.value.hi === undefined ||
            this.value.lo === null ||
            this.value.lo === undefined
        ) {
            return undefined;
        }
        let hi = parseFloat(this.value.hi);
        let lo = parseFloat(this.value.lo);

        return hi > lo ? hi : undefined;
    }
}
