import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    MAIN_MENU_ANIMATION_DURATION,
    mainMenuCloseAnimation,
    mainMenuOpenAnimation
} from 'src/app/shared/common/animations/gsp-animations';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { GeneralUtils } from 'src/app/shared/common/utility/general-utils';
import { environment } from 'src/environments/environment';
import { version } from 'src/environments/version';

import { mainMenuList, MenuItem } from './main-menu-list';
import { MainMenuStreamService } from './main-menu-stream.service';

// These matcher functions are required as we don't want any animation while switching between submenus in main menu
export const mainMenuSlideInMatcherFn = (fromState: string, toState: string): boolean =>
    GeneralUtils.isNullUndefinedOrNaN(fromState) && toState === 'mainMenu' ? true : false;

export const mainMenuSlideOutMatcherFn = (fromState: string, toState: string): boolean =>
    GeneralUtils.isNullUndefinedOrNaN(toState) && (fromState === 'mainMenu' || fromState === 'subMainMenu')
        ? true
        : false;
@Component({
    selector: 'main-menu',
    templateUrl: './main-menu.component.html',
    animations: [
        trigger('mainMenuSlide', [
            transition(mainMenuSlideInMatcherFn, useAnimation(mainMenuOpenAnimation)),
            transition(mainMenuSlideOutMatcherFn, useAnimation(mainMenuCloseAnimation))
        ])
    ]
})
export class MainMenuComponent implements OnInit, OnDestroy {
    public menuList: MenuItem[] = mainMenuList;
    public projectId: string;
    public connectUrl = environment.connectMasterUrl;
    public ButtonType = ButtonType;
    public hideMenu = true;
    public selectedMenuTitle = '';
    public closing = false; // to animate overlay and tabs which are in the main menu component, via css
    public version = version;

    @ViewChild(RouterOutlet, { static: true })
    outlet: RouterOutlet;

    private destroyed = new Subject<void>();

    constructor(
        private router: Router,
        private projectStream: ProjectStreamService,
        private mainMenuStreamService: MainMenuStreamService
    ) {
        this.projectId = projectStream.getCurrentProject().id;
        this.mainMenuStreamService.showMainMenu$
            .pipe(takeUntil(this.destroyed))
            .subscribe(show => (show ? this.activateMenu(this.menuList[0]) : this.closeMenu()));
    }

    public activateMenu(menu: MenuItem): void {
        this.hideMenu = false;
        if (menu.name === 'Settings') {
            let projectSettingsURL =
                environment.connectMasterUrl + '/tc/app#/project/' + this.projectId + '/settings/details';
            window.open(projectSettingsURL, '_blank');
        } else {
            this.selectedMenuTitle = menu.displayName;
            this.menuList.forEach(item => {
                item.active = item.name === menu.name;
            });
            this.router.navigate(menu.link, { queryParamsHandling: 'preserve' });
        }
    }

    public ngOnInit(): void {
        // Handle the case where user refreshes when main menu is active
        if (this.outlet && this.outlet.isActivated) {
            const activatedMenu: any = this.outlet.activatedRoute.component;
            const item = this.menuList.find(menuItem => menuItem.component.name === activatedMenu.name);
            this.activateMenu(item);
        }
    }

    public ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    public close(): void {
        this.mainMenuStreamService.showMainMenu$.next(false);
    }

    public prepareRoute(outlet: RouterOutlet): any {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
    }

    onAnimationEvent(event: any): void {
        if (mainMenuSlideOutMatcherFn(event.fromState, event.toState)) {
            this.closing = true;
            setTimeout(() => {
                this.closing = false;
                this.hideMenu = true;
            }, MAIN_MENU_ANIMATION_DURATION);
        }
    }

    private closeMenu(): void {
        this.router.navigate(['mapViewer', { outlets: { mainMenu: null } }], { queryParamsHandling: 'preserve' });
    }
}
