import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'gsp-text-area',
    templateUrl: './gsp-text-area.component.html'
})
export class GspTextAreaComponent {
    @Input()
    public maxLength: number;

    @Input()
    public placeholder: string;

    @Input()
    public value: string;

    @Output()
    public valueChange = new EventEmitter<string>();
}
