import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';

import { MapWorkspacesStoreService } from './map-workspaces-store.service';

@Injectable({
    providedIn: 'root'
})
export class MapWorkspaceStreamsService {
    // the current map workspace
    public currentMapWorkspaceStream: Observable<MapWorkspace>;

    // the list of workspaces in the current project, sorted by name
    public projectMapWorkspacesStream: Observable<MapWorkspace[]>;

    constructor(private mapWorkspacesStore: MapWorkspacesStoreService) {
        this.currentMapWorkspaceStream = this.mapWorkspacesStore.currentMapWorkspaceStream.pipe(shareReplay(1));

        // --------------------------------------
        // -- Project Map Workspaces

        this.projectMapWorkspacesStream = this.mapWorkspacesStore.projectMapWorkspacesStream.pipe(
            map(ws => this.sortMapWorkspaces(ws)),
            shareReplay(1)
        );
    }

    // --------------------------------------
    // map workspace sorting

    sortMapWorkspaces(mapWorkspaces: MapWorkspace[]): MapWorkspace[] {
        return mapWorkspaces ? mapWorkspaces.sort((mw1, mw2) => this.compareMapWorkspaces(mw1, mw2)) : [];
    }

    // Sorts map workspaces as follows:
    // - Alphabetically by name
    // - id
    compareMapWorkspaces(mw1: MapWorkspace, mw2: MapWorkspace): number {
        let result = mw1.name.localeCompare(mw2.name);
        return result !== 0 ? result : mw1.id < mw2.id ? -1 : mw1.id > mw2.id ? 1 : 0;
    }
}
