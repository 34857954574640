<div class="rule-action">
    <gsp-dropdown
        [options]="targetActionTexts"
        [(ngModel)]="target.action"
        idField="value"
        textField="name"
        *ngIf="target && !field.stopConditionedOrTargeted"
    ></gsp-dropdown>
    <span
        class="notification note-error"
        *ngIf="field.stopConditionedOrTargeted && (condition || target)"
        translate="TCS.TemplateEditor.Tf.Rules.InvalidTarget"
    ></span>

    <gsp-button
        [hidden]="
            !(
                !target &&
                !condition &&
                ruleToBeUsed &&
                ruleToBeUsed !== RuleType.TARGET_ONLY &&
                !field.stopConditionedOrTargeted
            )
        "
        (action)="setSource($event)"
        text="{{ 'TC.Common.Condition' | translate }}"
        [type]="ButtonType.Success"
        iconClass="icon_line_white_condition i24"
    >
    </gsp-button>
    <gsp-button
        [hidden]="!(!target && !condition && !field.stopConditionedOrTargeted && ruleToBeUsed)"
        (action)="setTarget($event)"
        text="{{ 'TC.Common.Target' | translate }}"
        [type]="ButtonType.Primary"
        iconClass="icon_line_white_target i24"
    >
    </gsp-button>
    <gsp-button
        [hidden]="!condition"
        [ngClass]="{ red: field.stopConditionedOrTargeted, 'has-condition': target || condition }"
        (action)="removeSource($event)"
        [type]="ButtonType.SuccessIcon"
        iconClass="icon_line_white_condition i24"
    >
        <i class="icon_solid_white_trash i24"></i>
    </gsp-button>
    <gsp-button
        [hidden]="!target"
        [ngClass]="{ red: field.stopConditionedOrTargeted }"
        (action)="removeTarget($event)"
        [type]="ButtonType.PrimaryIcon"
        iconClass="icon_line_white_target i24"
    >
        <i class="icon_solid_white_trash i24"></i>
    </gsp-button>
</div>
