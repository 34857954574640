export interface LicenseDTO {
    id?: string;
    orgName?: string;
    maxProjects?: number;
    maxStorage?: number;
    maxInvites?: number;
    mrp?: boolean;
    maxUsers?: number;
    maxForms?: number;
    upgradeLink: string;
    usedProjects?: number;
    usedStorage?: number;
}

export class License {
    id: string = null;
    maxInvites: number = null;
    maxProjects: number = null;
    maxStorage: number = null;
    orgName: string = null;
    upgradeLink: string = null;
    usedProjects: number = null;
    usedStorage: number = null;

    constructor() {}

    static fromDTO(dto: LicenseDTO): License {
        const license = new License();

        if (dto) {
            license.id = dto.id;
            license.maxInvites = dto.maxInvites;
            license.maxProjects = dto.maxProjects;
            license.maxStorage = dto.maxStorage;
            license.orgName = dto.orgName;
            license.upgradeLink = dto.upgradeLink;
            license.usedProjects = dto.usedProjects;
            license.usedStorage = dto.usedStorage;
        }
        return license;
    }
}
