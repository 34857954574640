<mat-chip-grid #chipList [(ngModel)]="selectedUsers" [name]="name" aria-label="ModifiedBy">
    <mat-chip-row
        *ngFor="let userChip of selectedUsers"
        [value]="userChip"
        [selectable]="selectable"
        [removable]="removable"
        [title]="userChip.name"
        (removed)="removeUser(userChip)"
    >
        {{ userChip.name }}
        <button matChipRemove class="remove-button" *ngIf="removable">&#x2716;</button>
    </mat-chip-row>
</mat-chip-grid>

<input
    [class]="inputClass"
    [placeholder]="placeholder"
    [attr.aria-label]="placeholder"
    #userInput
    [formControl]="userCtrl"
    [matAutocomplete]="auto"
    [matChipInputFor]="chipList"
    (focus)="resetUserCtrl()"
    (blur)="resetUserCtrl()"
/>

<mat-autocomplete #auto="matAutocomplete" [panelWidth]="250" (optionSelected)="addUser($event.option.value)">
    <mat-option *ngFor="let user of filteredUsers" [value]="user" (mousedown)="(false)">
        {{ user.name }}
    </mat-option>
</mat-autocomplete>
