import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { QueryParamsHandling, Router } from '@angular/router';
import * as _ from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';

import { Application } from '../../map-data-services/application';
import { FieldType } from '../field';
import { Template } from '../template';
import { FieldService } from './field.service';
import { FieldDefinition, SensorData } from './fieldDefinition';

@Injectable({
    providedIn: 'root'
})
export class FieldsStoreService {
    currentCategory: string = null;
    currentSubCategory: string = null;
    fieldDefinitionsByFieldType: { [fieldType: string]: FieldDefinition } = null;

    draggedSensorDataStream = new BehaviorSubject<CdkDragDrop<void>>(null);
    currentTemplate = new BehaviorSubject<Template>(null);
    currentApplication = new BehaviorSubject<Application>(null);
    supportedSensors: SensorData[] = [];
    sensorExcludedPropertiesDict: { [mappingId: string]: string[] };

    constructor(
        private fieldService: FieldService,
        private projectStream: ProjectStreamService,
        private router: Router
    ) {}

    public getFieldByType(fieldType: string, category: string, subCategory: string): Promise<FieldDefinition> {
        fieldType = fieldType === FieldType.Number ? FieldType.General : fieldType;

        return new Promise<FieldDefinition>((resolve, reject) => {
            if (this.fieldDefinitionsByFieldType && this.fieldDefinitionsByFieldType[fieldType]) {
                resolve(this.fieldDefinitionsByFieldType[fieldType]);
            } else {
                this.getFieldsMap(category, subCategory).then(fieldsMap => {
                    resolve(fieldsMap[fieldType]);
                });
            }
        });
    }

    public getFieldsMap(category: string, subCategory: string): Promise<{ [fieldType: string]: FieldDefinition }> {
        let projectId = this.projectStream.getCurrentProject().id;
        if (this.currentCategory !== category || this.currentSubCategory !== subCategory) {
            this.fieldDefinitionsByFieldType = null;
        }
        this.currentCategory = category;
        this.currentSubCategory = subCategory;
        return this.fieldDefinitionsByFieldType
            ? Promise.resolve(this.fieldDefinitionsByFieldType)
            : this.fieldService.getFields(projectId, category, subCategory).then(fields => {
                  this.fieldDefinitionsByFieldType = {};
                  fields.forEach(field => {
                      this.fieldDefinitionsByFieldType[field.fieldType] = field;
                  });
                  return this.fieldDefinitionsByFieldType;
              });
    }

    public getFields(category: string, subCategory: string): Promise<FieldDefinition[]> {
        return this.getFieldsMap(category, subCategory).then(fieldsMap => _.values(fieldsMap));
    }

    public openPopupTo(
        path: string = '',
        queryParams: {} = {},
        queryParamsHandling: QueryParamsHandling = 'preserve',
        skipLocationChange: boolean = true
    ): void {
        this.router.navigate([path], {
            queryParams,
            queryParamsHandling,
            skipLocationChange
        });
    }

    private getSupportedSensors(): Promise<SensorData[]> {
        return this.fieldService.getSensorsData();
    }

    // This will be loaded through template-editor-page.component
    public async loadSensorsAndExcludeFieldPropsDict(): Promise<void> {
        this.supportedSensors = await this.getSupportedSensors();
        this.searchByDictUsingMappingId(this.supportedSensors);
    }

    private searchByDictUsingMappingId(sensors: SensorData[]): any {
        // search through dictionary using mappingId
        this.sensorExcludedPropertiesDict = sensors.reduce((acc, sensor) => {
            sensor.messageDefinitions.forEach(messageDef => {
                messageDef.fieldDefinitions.forEach(fieldDef => {
                    acc[fieldDef.mappingId] = fieldDef.exclude;
                });
            });
            return acc;
        }, {} as { [mappingId: string]: string[] });
    }
}
