import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { MapMenuCode } from 'src/app/feature/map-viewer/map-menus/map-menu-list';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { CurrentRegionStreamService } from 'src/app/shared/common/current-region/region-stream.service';

@Component({
    selector: 'load-create-workspace',
    template: `
        <div class="container"><div class="app-loading" *ngIf="loading"></div></div>
    `
})
export class LoadCreateWorkspaceComponent implements OnInit {
    public projectId: string;
    public connectFileId: string;
    public connectFolderId: string;
    public region: string;
    public loading = false;

    constructor(
        private projectStream: ProjectStreamService,
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private messaging: MessagingService,
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslationService,
        private regionStream: CurrentRegionStreamService
    ) {
        this.projectId = this.route.snapshot.paramMap.get('projectId') || null;
        this.connectFileId =
            this.route.snapshot.paramMap.get('connectFileId') &&
            this.route.snapshot.paramMap.get('connectFileId') !== 'null'
                ? this.route.snapshot.paramMap.get('connectFileId')
                : null;
        this.connectFolderId =
            this.route.snapshot.paramMap.get('connectFolderId') &&
            this.route.snapshot.paramMap.get('connectFolderId') !== 'null'
                ? this.route.snapshot.paramMap.get('connectFolderId')
                : null;
        this.region =
            this.route.snapshot.queryParamMap.get('region') &&
            this.route.snapshot.queryParamMap.get('region') !== 'null'
                ? this.route.snapshot.queryParamMap.get('region')
                : this.regionStream.getRegionInfo().region || null;
    }

    ngOnInit(): void {
        this.loading = true;
        // If get project by id - if project is null, then fetch the first project -
        // if project is not exist, create a new project
        this.projectStream.initCurrentProject(this.projectId).then(project => {
            // If get workspace by connect file id - if connect file is null,
            // then create new workspace - if workspace is not exist, create a new workspace
            this.mapWorkspacesStore
                .getNextNewMapWorkspace(project.id, this.connectFileId, this.connectFolderId, project.isChanged)
                .then(nextMapWorkspace => {
                    if (!nextMapWorkspace) {
                        this.messaging.showError(this.translate.instant('TCS.Workspace.ErrorLoading'));
                        this.router.navigate(['maps']);
                    } else if (nextMapWorkspace && !nextMapWorkspace.id) {
                        // TODO: GIM 12/6/19 decide if we're supposed to change activeMenu here or just leave as is via merge
                        this.router.navigate(['mapViewer', { outlets: { centerDialog: 'workspace/new' } }], {
                            queryParams: { projectId: project.id, workspaceId: -1 },
                            queryParamsHandling: 'merge',
                            skipLocationChange: true
                        });
                    } else {
                        this.router.navigate(['mapViewer'], {
                            queryParams: {
                                projectId: nextMapWorkspace.projectId,
                                workspaceId: nextMapWorkspace.id,
                                activeMenus: MapMenuCode.LAYERS,
                                region: this.region
                            }
                        });
                    }
                });
        });
    }
}
