import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash-es';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Application } from 'src/app/shared/map-data-services/application';
import { Rule } from 'src/app/shared/template-services/rule/rule';
import { Template } from 'src/app/shared/template-services/template';

import { RuleSelectionStreamService } from '../rules-panel/rule-selection-stream.service';
import { LinkDetails } from '../template-editor-page.component';
import { CurrentTabStreamService, TemplateEditorTabId } from './current-tab-stream.service';

@Component({
    selector: 'tabs-list',
    templateUrl: './tabs-list.component.html'
})
export class TabsListComponent implements OnInit, OnDestroy {
    private destroyed = new Subject<void>();

    @Input()
    template: Template;

    private _linkDetails: LinkDetails[] = [];

    @Input()
    public get linkDetails(): LinkDetails[] {
        return this._linkDetails;
    }
    public set linkDetails(v: LinkDetails[]) {
        this._linkDetails = v;

        let consolidatedItems = v ? v.map(layerDetail => layerDetail.items)[0] : [];

        this.secondaryUniqLinkDetails = _.uniqBy(consolidatedItems, 'id');
    }

    @Input()
    currentProjectId: string;

    @Input()
    templateEditorConfig: { tabs: { [key: string]: unknown } | ArrayLike<unknown> };

    @Input()
    application: Application;

    tabsList: any[] = [];
    selectedTab: any;
    selectedRule: Rule;
    secondaryUniqLinkDetails: any[] = [];

    // exposing enum to template
    public TemplateEditorTabId = TemplateEditorTabId;

    constructor(
        private currentTabStream: CurrentTabStreamService,
        private ruleSelectionStream: RuleSelectionStreamService
    ) {}

    ngOnInit(): void {
        this.tabsList = Object.values(this.templateEditorConfig.tabs).map((tab: any) => ({
            id: tab.id,
            name: tab.label,
            iconClass: tab.iconClass
        }));

        combineLatest([this.currentTabStream.currentTab$, this.ruleSelectionStream.ruleSelectionStream])
            .pipe(takeUntil(this.destroyed))
            .subscribe(([selectedTab, selectedRule]) => {
                if (selectedTab && selectedTab.id === TemplateEditorTabId.RULE) {
                    if ((this.selectedTab && selectedTab.id !== this.selectedTab.id) || !this.selectedTab) {
                        if (this.template.rules.length > 1) {
                            this.template.rules = this.template.rules.filter(
                                rule => rule.conditions.length || rule.targets.length
                            );

                            let filteredSelectedRule = this.template.rules.filter(
                                rule => selectedRule && rule.uuid === selectedRule.uuid
                            );

                            if (filteredSelectedRule.length <= 0) {
                                this.ruleSelectionStream.ruleSelectionStream.next(this.template.rules[0]);
                            }
                        }
                    }
                }
                this.selectedRule = selectedRule;
            });

        this.currentTabStream.currentTab$.pipe(takeUntil(this.destroyed)).subscribe(tab => (this.selectedTab = tab));

        this.currentTabStream.currentTab$.next(this.tabsList[0]);
    }

    activateTab(tab: any): void {
        this.currentTabStream.currentTab$.next(tab);
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }
}
