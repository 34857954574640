import { CdkDropList } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConnectedDropListService {
    private _allLists = new BehaviorSubject<CdkDropList[]>([]);

    allLists$: Observable<CdkDropList[]> = this._allLists.asObservable();

    public addList(list: CdkDropList): void {
        if (!this._allLists.value.includes(list)) {
            this._allLists.value.push(list);

            // the order dictates which list will be used when they overlap...important for parent-child
            this.sortList(this._allLists.value);

            this._allLists.next(this._allLists.value);
        }
    }

    sortList(list: CdkDropList[]): void {
        list.sort((a, b) => ((b.data && b.data.order) || 0) - ((a.data && a.data.order) || 0));
    }
}
