<div
    class="field-value"
    [ngClass]="{
        active: field.wizardStatus === TemplateWizardFieldStatus.ACTIVE,
        verified: field.wizardStatus === TemplateWizardFieldStatus.VERIFIED
    }"
>
    <div class="meta-data">
        <div class="meta-data-set flex1">
            <h4 class="meta-name" [title]="field.name">
                {{ field.name }}
            </h4>
            <div class="meta-type">
                {{ field.fieldTypeDisplayName | translate }}
            </div>
        </div>
        <div class="next-button">
            <gsp-button
                [type]="ButtonType.Primary"
                (action)="moveToNextField()"
                *ngIf="lastField"
                text="{{ 'RESOLVED' | translate }}"
            >
            </gsp-button>
            <gsp-button
                [type]="ButtonType.Primary"
                (action)="moveToNextField()"
                *ngIf="!lastField"
                text="{{ 'TC.Common.Next' | translate }}"
            ></gsp-button>
        </div>
    </div>
    <div class="field-set" (click)="setFocus(field, null)" #fieldSet>
        <div class="field-name-wrapper">
            <gsp-text
                [type]="GspTextType.GENERIC"
                inputClass="field-name"
                [(ngModel)]="field.displayName"
                [title]="field.displayName"
            ></gsp-text>
            <!--<div class="status-indicator"></div>-->
        </div>

        <gsp-dropdown
            name="fieldType"
            *ngIf="fieldTypes.length > 0"
            [options]="fieldTypes"
            [(ngModel)]="field.type"
            idField="name"
            textField="display"
            [addIcon]="true"
            class="field-type"
            (ngModelChange)="triggerOnDropdownSelect(field, field.type)"
        >
        </gsp-dropdown>

        <div class="field-type" *ngIf="fieldTypes.length <= 0">
            <div class="field-type-icon">
                <div class="icon-32-dark-field-{{ field.type.toLowerCase() }}"></div>
            </div>
            <div class="field-type-name">{{ field.type }}</div>
        </div>
    </div>

    <div class="sample-data" (click)="setFocus(field, null)">
        <custom-scroll autoHeightDisable="true" class="height-full">
            <div class="sample-data-list">
                <div
                    class="sample-data-item"
                    *ngFor="let sampleDatum of field.sampleData; trackBy: indexTrack"
                    title="{{ sampleDatum }}"
                >
                    {{ sampleDatum }}
                </div>
            </div>
        </custom-scroll>
    </div>
</div>
