<div class="map-title" #workspaceDetailFormWrapper>
    <ng-form
        #workspaceDetailForm="ngForm"
        *checkVisibility="{ visibleModes: [MapWorkspacePermissionType.FULL_ACCESS] }"
    >
        <input
            class="map-title-text"
            name="name"
            #name="ngModel"
            title="{{ workspaceName }}"
            [ngClass]="{ 'input-text': focused }"
            autocomplete="off"
            id="edit-workspace-name"
            type="text"
            maxlength="44"
            [(ngModel)]="workspaceName"
            (focus)="onFocus()"
            (keydown.enter)="saveCurrentWorkspace()"
            [disabled]="currentWorkspaceBeforeSave.isFileViewer"
            workspaceName
        />
    </ng-form>
    <div
        class="map-title-text"
        *checkVisibility="{
            visibleModes: [MapWorkspacePermissionType.READ, MapWorkspacePermissionType.FILE_VIEW_MODE]
        }"
        title="{{ workspaceName }}"
    >
        {{ workspaceName }}
    </div>
    <div class="folder" (click)="goToTrimbleConnect()" *ngIf="!currentWorkspaceBeforeSave.isPubliclySharedMapWorkspace">
        <i class="icon_solid_folder_closed i24" title="{{ 'TC.Common.GoToFolder' | translate }}"></i>
    </div>
</div>

<!-- THIS IS OLD STUFF< JUST LEAVING SO WE CAN REMVOE THE CSS
  <div class="map-title">
      <div class="content">
          <ul class="list-content">
              <li class="list-item">
                  <div class="layer-title" ng-click="$ctrl.editWorkspaceName = true" event-focus="click" event-focus-id="edit-workspace-name">
                      <h1 class="heading-text" ng-show="!$ctrl.editWorkspaceName" title="{{$ctrl.currentWorkspace.name}}">{{$ctrl.currentWorkspace.displayName}}</h1>
                    <form ng-submit="$ctrl.saveCurrentWorkspace()" ng-hide="!$ctrl.editWorkspaceName">
                      <input class="form-control" autocomplete="off" id="edit-workspace-name" type="text" ng-model="$ctrl.currentWorkspace.displayName" ng-blur="$ctrl.saveCurrentWorkspace()"/>
                    </form>
                  </div>
                  <div class="layer-view">
                      <i class="icon-32-dark-folder"></i>
                  </div>
              </li>
          </ul>
      </div>
  </div>-->
