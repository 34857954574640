import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { FeatureFlag, FeatureFlagName } from './feature-flag';
import { FeatureFlagService } from './feature-flag.service';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagStreamService {
    featureFlags: BehaviorSubject<FeatureFlag[]> = new BehaviorSubject(null);

    constructor(private featureFlagService: FeatureFlagService) {}

    public async initFlags(): Promise<void> {
        const flags = await this.featureFlagService.getFeatureFlags();
        this.featureFlags.next(flags);
    }

    public flagEnabled(flagName: FeatureFlagName): boolean {
        return !!this.featureFlags.getValue()?.find(flag => flag.name === flagName && flag.enabled);
    }
}
