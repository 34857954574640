import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GspWizardService } from 'src/app/shared/common/components/gsp-wizard/gsp-wizard.service';
import { ShareWorkspaceData } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';

import { ShareWorkspaceStoreService } from '../workspace-share-store.service';

@Component({
    selector: 'workspace-share-info',
    templateUrl: './workspace-share-info.component.html'
})
export class WorkspaceShareInfoComponent implements OnInit, OnDestroy {
    sharedWorkspaceData: ShareWorkspaceData;

    isWorkspaceShareLoading = true;

    private readonly destroyed = new Subject<void>();

    public uiState = {
        backButton: { text: '', enabled: false, visible: false },
        forwardButton: { text: 'TC.Common.Next', enabled: false, visible: true },
        closeButton: { text: '', enabled: false, visible: false },
        cancelButton: { text: 'TC.Common.Cancel', enabled: true, visible: true }
    };

    constructor(private wizardService: GspWizardService, private shareWorkspaceStore: ShareWorkspaceStoreService) {}

    ngOnInit(): void {
        this.shareWorkspaceStore.shareWorkspaceDataStream.pipe(takeUntil(this.destroyed)).subscribe(data => {
            this.sharedWorkspaceData = data;
        });

        this.shareWorkspaceStore.shareWorkspaceLoadingStream.pipe(takeUntil(this.destroyed)).subscribe(val => {
            this.isWorkspaceShareLoading = val;
            this.setUiState();
        });

        this.wizardService.setUI(this.uiState);
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    get lockSharedWorkspace(): boolean {
        return this.shareWorkspaceStore.lockSharedWorkspaceStream.getValue();
    }

    public set lockSharedWorkspace(value: boolean) {
        this.shareWorkspaceStore.lockSharedWorkspaceStream.next(value);
    }

    private setUiState() {
        this.uiState.forwardButton.enabled = !this.isWorkspaceShareLoading;
    }

    public onForward(): void {
        // lock/unlock templates based on checkbox
        this.sharedWorkspaceData.content.forEach(item => {
            item.template.template.lockedSchema = this.lockSharedWorkspace;
        });
        this.shareWorkspaceStore.shareWorkspaceDataStream.next(this.sharedWorkspaceData);
    }
}
