import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: '[decimalValidate]',
    providers: [{ provide: NG_VALIDATORS, useExisting: DecimalValidateDirective, multi: true }]
})
export class DecimalValidateDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors {
        if (control.value && control.value !== null && control.value !== undefined) {
            const decimalPattern = /^-?\d*\.?\d*$/;
            const isValid = decimalPattern.test(control.value);
            if (!isValid) {
                return { ['decimalInvalid']: true }; // return object if the validation is not passed.
            }
        }
        return null; // return null if validation is passed.
    }
}
