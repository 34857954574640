import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject, from, race } from 'rxjs';
import { concatMap, debounceTime, distinctUntilChanged, filter, map, skipUntil, take, takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { ChartPanelStreamService } from 'src/app/feature/map-viewer/common/chart-panel-stream.service';
import { TemplatedFeatureMetadataProperty } from 'src/app/feature/map-viewer/side-panel/feature-panel/feature-fields/templated-feature';
import { Feature } from 'src/app/shared/map-data-services/feature/feature';
import { FeatureService } from 'src/app/shared/map-data-services/feature/feature.service';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { UserSettingsStreamService } from 'src/app/shared/user/user-settings-stream.service';

import { FeaturesStreamsService } from '../../current-features/features-streams.service';
import { LayersStore } from '../../current-layers/layers-store.service';
import { MapWorkspacesStoreService } from '../../current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from '../../current-project/project-stream.service';
import { TasksStore } from '../../current-tasks/tasks-store.service';
import { FullFeatureFilter } from '../../feature-filter/full-feature-filter';
import { Task } from '../../task/task';
import { CloneUtils } from '../../utility/clone-utils';
import { GeneralUtils } from '../../utility/general-utils';
import { ActivityTabUtil } from './gsp-activity-tab.util';

export interface GspActivity {
    type: GspActivityType;
    data: GspFeatureActivityData | GspTaskActivityData;
}

export interface GspFeatureActivityData {
    features: Feature[];
    multiple?: boolean;
    userCount?: number;
    userName?: string;
}

export interface GspTaskActivityData {
    userName: string;
    updatedTime: Date;
    taskName: string;
}

export interface GspTimeline {
    displayText: string;
    startDate: Date;
    endDate: Date;
}

export enum GspActivityType {
    POST_PROCESSING_QUEUED = 'POST_PROCESSING_QUEUED',
    POST_PROCESSING_COMPLETE = 'POST_PROCESSING_COMPLETE',
    POST_PROCESSING_FAILED = 'POST_PROCESSING_FAILED',
    FEATURES_SYNCED = 'FEATURES_SYNCED',
    TASK_COMPLETED = 'TASK_COMPLETED'
}

export const reloadActivityTabStream = new Subject();

@Component({
    selector: 'gsp-activity-tab',
    templateUrl: './gsp-activity-tab.component.html'
})
export class GspActivityTabComponent implements OnInit, OnDestroy {
    private _showActivityTab = false;

    @Input()
    get showActivityTab() {
        return this._showActivityTab;
    }
    set showActivityTab(val: boolean) {
        this._showActivityTab = val;
        if (val) {
            this.workspaceLastActivityViewedMap[this.currentMapWorkspace.id] = new Date();
        }
    }

    @Output()
    hideActivityTab = new EventEmitter();

    @Output()
    activityUpdate = new EventEmitter();

    activityTimelines: { displayText: string; activities: GspActivity[]; showSelectAll: boolean }[] = [];

    currentDate = new Date();

    currentPendingTimelines: GspTimeline[] = [];

    mapWorkspaceTasks: Task[] = [];

    loading = false;

    destroyed$ = new Subject();

    mapWorkspacesLoaded$ = new Subject();

    // exposing enum to template
    GspActivityType = GspActivityType;

    private layerKeyToLayerMap: { [key: string]: Layer };

    private userLastLoggedIn: Date;

    private currentMapWorkspace: MapWorkspace;

    private workspaceLastActivityViewedMap: { [workspaceId: string]: Date } = {};

    constructor(
        private projectStream: ProjectStreamService,
        private mapWorkspaceStore: MapWorkspacesStoreService,
        private userSettingsStreamService: UserSettingsStreamService,
        private featureService: FeatureService,
        private featureStream: FeaturesStreamsService,
        private layerStore: LayersStore,
        private taskStore: TasksStore,
        private chartPanelStream: ChartPanelStreamService,
        private translate: TranslationService
    ) {}

    ngOnInit(): void {
        this.mapWorkspaceStore.currentMapWorkspaceStream
            .pipe(distinctUntilChanged(GeneralUtils.isIdEqual), takeUntil(this.destroyed$))
            .subscribe(async currentWorkspace => {
                if (currentWorkspace) {
                    this.loading = true;
                    this.currentMapWorkspace = currentWorkspace;
                    this.activityTimelines = [];
                    await this.layerStore.loadMapWorkspaceLayers(currentWorkspace);
                    this.mapWorkspacesLoaded$.next(null);
                    reloadActivityTabStream.next(null);
                }
            });

        this.taskStore.mapWorkspaceTasksStream.pipe(takeUntil(this.destroyed$)).subscribe(tasks => {
            this.mapWorkspaceTasks = tasks;
            reloadActivityTabStream.next(null);
        });

        reloadActivityTabStream
            .pipe(skipUntil(this.mapWorkspacesLoaded$), debounceTime(1000), takeUntil(this.destroyed$))
            .subscribe(() => {
                // refresh layer key map to get newly created layers
                this.layerKeyToLayerMap = this.layerStore.getLayerKeyToLayerMap();
                // use a generated hash to identify refresh request to prevent concurrent updates
                this.reloadActivityTab();
            });

        // use the first setting value as the it gets updated on workspace change
        this.userSettingsStreamService.lastLoggedInUserSettings$
            .pipe(
                filter(v => !GeneralUtils.isNullUndefinedOrNaN(v)),
                take(1),
                takeUntil(this.destroyed$)
            )
            .subscribe(userSettings => {
                if (userSettings) {
                    this.userLastLoggedIn = new Date(userSettings.lastLoggedIn);
                }
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(null);
        this.destroyed$.complete();
    }

    private getTimelines(): GspTimeline[] {
        return [
            {
                displayText: 'MapViewer.Activity.Timeline.Today.Label',
                startDate: ActivityTabUtil.getTodayStartTimestamp(),
                endDate: ActivityTabUtil.getTodayCurrentTimestamp()
            },
            {
                displayText: 'MapViewer.Activity.Timeline.Yesterday.Label',
                startDate: ActivityTabUtil.getYesterdayStartTimestamp(),
                endDate: ActivityTabUtil.getTodayStartTimestamp()
            },
            {
                displayText: 'MapViewer.Activity.Timeline.ThisWeek.Label',
                startDate: ActivityTabUtil.getThisWeekStartTimestamp(),
                endDate: ActivityTabUtil.getTodayCurrentTimestamp()
            },
            {
                displayText: 'MapViewer.Activity.Timeline.LastWeek.Label',
                startDate: ActivityTabUtil.getLastWeekStartTimestamp(),
                endDate: ActivityTabUtil.getThisWeekStartTimestamp()
            },
            {
                displayText: 'MapViewer.Activity.Timeline.ThisMonth.Label',
                startDate: ActivityTabUtil.getThisMonthStartTimestamp(),
                endDate: ActivityTabUtil.getTodayCurrentTimestamp()
            }
        ];
    }

    public loadTimelineActivities(): void {
        const activityFound = new Subject<void>();
        from(CloneUtils.cloneDeep(this.currentPendingTimelines))
            .pipe(
                concatMap(() => this.loadNextTimeline()),
                takeUntil(race(reloadActivityTabStream, this.destroyed$, activityFound))
            )
            .subscribe(activities => {
                if (activities.length) {
                    activityFound.next(null);
                }
            });
    }

    private loadNextTimeline(): Observable<GspActivity[]> {
        const timeline = this.currentPendingTimelines.shift();
        this.loading = true;
        return this.featureService
            .getFeaturesWithPostProcessingStatusForActivityTimeline(
                this.projectStream.getCurrentProject().id,
                this.mapWorkspaceStore.currentMapWorkspaceStream.getValue().id,
                timeline.startDate,
                timeline.endDate
            )
            .pipe(
                map(features => {
                    if (features.length) {
                        this.checkFeatureActivityForNotification(features);
                    }
                    const users = this.projectStream.getProjectUsersFromCache();
                    const featureActivities = ActivityTabUtil.constructActivitiesForTimeline(
                        features,
                        this.layerKeyToLayerMap,
                        timeline.startDate,
                        timeline.endDate,
                        users,
                        this.translate
                    );
                    const taskActivities = this.updateTasksCompletionInActivityTimeline(timeline);
                    const activities = [...featureActivities, ...taskActivities];
                    this.activityTimelines.push({
                        displayText: timeline.displayText,
                        activities,
                        showSelectAll: featureActivities.length > 1
                    });
                    this.loading = false;
                    return activities;
                })
            );
    }

    updateTasksCompletionInActivityTimeline(activityTimeline: GspTimeline): GspActivity[] {
        const projectUsers = this.projectStream.getProjectUsersFromCache();
        const taskActivities: GspTaskActivityData[] = [];
        this.mapWorkspaceTasks.forEach(task => {
            if (task.returnedJobs.length) {
                let completedData = [];
                completedData = task.returnedJobs.map(job => {
                    const user = projectUsers.find(tmpUser => tmpUser.id === job.userId);
                    return {
                        userName: user ? user.name : this.translate.instant('MapViewer_Generic_RemovedUser'),
                        updatedTime: new Date(job.returnedUtc),
                        taskName: task.title
                    };
                });
                taskActivities.push(...completedData);
            }
        });
        const taskActivitiesForTimeline = taskActivities.filter(
            taskActivity =>
                taskActivity.updatedTime >= activityTimeline.startDate &&
                taskActivity.updatedTime <= activityTimeline.endDate
        );
        if (taskActivitiesForTimeline.length) {
            this.checkTaskActivityForNotification(taskActivitiesForTimeline);
        }
        return taskActivitiesForTimeline.map(taskActivity => ({
            type: GspActivityType.TASK_COMPLETED,
            data: taskActivity
        }));
    }

    public openFeaturesInFeaturePanel(features: Feature[], activityType?: GspActivityType): void {
        const featureFilter = new FullFeatureFilter();
        featureFilter.selectedFeatureIds = features.map(feature => feature.id);
        this.hideActivityTab.emit(null);
        this.featureStream.clearSelectedFeatures();
        this.featureService.getFeatures(this.projectStream.getCurrentProject().id, featureFilter).then(newFeatures => {
            this.featureStream.addToSelectedFeatures(newFeatures);
            // open accuracy panel for GspActivityType.POST_PROCESSING_COMPLETE activity
            if (activityType && activityType === GspActivityType.POST_PROCESSING_COMPLETE) {
                setTimeout(() => (this.chartPanelStream.isAccuracyPanelDisplayed = true), 200); // To account for sidepanel animation
            }
        });
    }

    public reloadActivityTab(): void {
        this.activityTimelines = [];
        this.currentPendingTimelines = this.getTimelines();
        this.loadTimelineActivities();
    }

    public selectAllActivity(activities: GspActivity[]): void {
        const consolidatedFeatures: Feature[] = activities
            .filter(activity => activity.type !== GspActivityType.TASK_COMPLETED)
            .reduce((result: Feature[], activity: GspActivity) => {
                if (result.length) {
                    return [...result, ...(activity.data as any).features];
                } else {
                    return (activity.data as any).features;
                }
            }, []);

        // If all the activities are of the type GspActivityType.POST_PROCESSING_COMPLETE, we can set it as that to open the accuracy panel
        const shouldOpenAccuracyPanel = activities.every(
            (activity: GspActivity) => activity.type === GspActivityType.POST_PROCESSING_COMPLETE
        );

        if (shouldOpenAccuracyPanel) {
            this.openFeaturesInFeaturePanel(consolidatedFeatures, GspActivityType.POST_PROCESSING_COMPLETE);
        } else {
            this.openFeaturesInFeaturePanel(consolidatedFeatures);
        }
    }

    private checkFeatureActivityForNotification = (features: Feature[]) => {
        // sort features in by collection sync date from latest to oldest
        const sortedFeatures = features.sort((a, b) =>
            a.metadata[TemplatedFeatureMetadataProperty.COLLECTION_SYNC_DATE] <
            b.metadata[TemplatedFeatureMetadataProperty.COLLECTION_SYNC_DATE]
                ? 1
                : -1
        );
        const latestFeature = sortedFeatures[0];
        this.compareActivityDateAndShowNewBadge(
            new Date(latestFeature.metadata[TemplatedFeatureMetadataProperty.COLLECTION_SYNC_DATE])
        );
    };

    private checkTaskActivityForNotification = (taskActivities: GspTaskActivityData[]) => {
        const sortedTaskActivities = taskActivities.sort((a, b) => (a.updatedTime < b.updatedTime ? 1 : -1));
        const latestTask = sortedTaskActivities[0];
        this.compareActivityDateAndShowNewBadge(latestTask.updatedTime);
    };

    private compareActivityDateAndShowNewBadge = (activityDate: Date) => {
        const lastViewedDateForWorkspace =
            this.workspaceLastActivityViewedMap[this.currentMapWorkspace.id] || this.userLastLoggedIn;
        if (lastViewedDateForWorkspace && lastViewedDateForWorkspace < activityDate) {
            this.activityUpdate.emit();
        }
        // update workspaceLastActivityViewedMap in case of reload with activity tab open
        if (this.showActivityTab) {
            this.workspaceLastActivityViewedMap[this.currentMapWorkspace.id] = new Date();
        }
    };
}
