import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, throttleTime } from 'rxjs/operators';

import { TemplateService } from '../../template-services/template.service';
import { LayersStore } from '../current-layers/layers-store.service';
import { MapWorkspaceStreamsService } from '../current-map-workspaces/map-workspace-streams.service';
import { ProjectStreamService } from '../current-project/project-stream.service';

export enum GeometryType {
    Point = 0,
    Line = 1,
    Area = 2
}

export interface MinifiedTemplate {
    name: string;
    geometryType: string;
    geometryColorHexRGB: string;
}

export interface MinifiedWorkspace {
    id: string;
    name: string;
    description: string;
}

export interface TemplateWorkspaceMapping {
    id: string;
    seriesId: string;
    version: number;
    template: MinifiedTemplate;
    workspaces: MinifiedWorkspace[];
}

@Injectable({
    providedIn: 'root'
})
export class ProjectTemplateStreams {
    // true if project templates are current loading
    public projectTemplateLoadingStream = new BehaviorSubject<boolean>(false);

    // the list of all templates in the current project
    private projectTemplatesStream = new BehaviorSubject<TemplateWorkspaceMapping[]>([]);

    // the list of all templates in the current project filtered by template name
    public projectTemplatesFilteredByNameStream: Observable<TemplateWorkspaceMapping[]>;

    // the list of all templates in the current project filtered by template workspace name
    public projectTemplatesFilteredByWorkspaceNameStream: Observable<TemplateWorkspaceMapping[]>;

    // the list of filtered templates for template library screen
    public templateLibraryStream: Observable<TemplateWorkspaceMapping[]>;

    private searchTemplateNameStream = new BehaviorSubject<string>(null);

    private searchTemplateByWorkspaceNameStream = new BehaviorSubject<string>(null);

    constructor(
        private projectStream: ProjectStreamService,
        private mapWorkspaceStreams: MapWorkspaceStreamsService,
        private templateService: TemplateService,
        private layerStore: LayersStore
    ) {
        combineLatest([
            this.projectStream.currentProjectStream,
            this.layerStore.changedMapWorkspaceLayerStream, // to refresh the stream on template creation/updation
            this.mapWorkspaceStreams.projectMapWorkspacesStream // to refresh the stream on workspace rename/archive/unarchive
        ])
            .pipe(distinctUntilChanged(_.isEqual), throttleTime(2000))
            .subscribe(([project, changedMapWorkspaceLayer, workspaces]) => {
                if (project && project.id) {
                    this.projectTemplateLoadingStream.next(true);
                    this.projectTemplatesStream.next([]);
                    this.templateService
                        .getTemplatesWorkspacesMappingList(project.id)
                        .then((templateMappings: TemplateWorkspaceMapping[]) => {
                            // grouping the templatesMapping by seriesId with unique workspace mappings
                            let uniqueTemplatesGroupingObj: { [key: string]: TemplateWorkspaceMapping } = {};
                            templateMappings.forEach(templateMapping => {
                                if (uniqueTemplatesGroupingObj[templateMapping.seriesId]) {
                                    uniqueTemplatesGroupingObj[templateMapping.seriesId].workspaces = _.uniqBy(
                                        [
                                            ...uniqueTemplatesGroupingObj[templateMapping.seriesId].workspaces,
                                            ...templateMapping.workspaces
                                        ],
                                        'id'
                                    );
                                } else {
                                    uniqueTemplatesGroupingObj[templateMapping.seriesId] = templateMapping;
                                }
                            });
                            templateMappings = Object.values(uniqueTemplatesGroupingObj);
                            this.projectTemplatesStream.next(templateMappings);
                            this.projectTemplateLoadingStream.next(false);
                        });
                }
            });

        this.projectTemplatesFilteredByNameStream = this.projectTemplatesStream.pipe(
            map(templateMappings => this.getTemplatesFilteredByName(templateMappings)),
            distinctUntilChanged(),
            shareReplay(1)
        );

        this.projectTemplatesFilteredByWorkspaceNameStream = this.projectTemplatesStream.pipe(
            map(templateMappings => this.getTemplatesFilteredByWorkspaceName(templateMappings)),
            distinctUntilChanged(),
            shareReplay(1)
        );

        combineLatest([
            this.searchTemplateNameStream.pipe(distinctUntilChanged()),
            this.searchTemplateByWorkspaceNameStream.pipe(distinctUntilChanged())
        ]).subscribe(() => {
            this.projectTemplatesStream.next(this.projectTemplatesStream.getValue());
        });

        this.templateLibraryStream = combineLatest([
            this.projectTemplatesFilteredByNameStream.pipe(distinctUntilChanged()),
            this.projectTemplatesFilteredByWorkspaceNameStream.pipe(distinctUntilChanged())
        ]).pipe(
            map(([templateNameResult, workspaceNameResult]) =>
                _.union(templateNameResult, workspaceNameResult).sort(this.sortTemplateByGeomTypeAndName)
            ),
            distinctUntilChanged()
        );
    }

    public setSearchTemplateName(templateName: string): void {
        this.searchTemplateNameStream.next(templateName);
    }

    private getTemplatesFilteredByName(templateMappings: TemplateWorkspaceMapping[]): TemplateWorkspaceMapping[] {
        let templateName = this.searchTemplateNameStream.getValue();
        if (templateName === null) {
            return [];
        }
        return templateMappings.filter(
            (templateMapping: TemplateWorkspaceMapping) =>
                templateMapping.template.name.toString().toLowerCase().indexOf(templateName.toLowerCase()) !== -1
        );
    }

    public setSearchTemplateByWorkspaceName(workspaceName: string): void {
        this.searchTemplateByWorkspaceNameStream.next(workspaceName);
    }

    private getTemplatesFilteredByWorkspaceName(
        templateMappings: TemplateWorkspaceMapping[]
    ): TemplateWorkspaceMapping[] {
        let workspaceName = this.searchTemplateByWorkspaceNameStream.getValue();
        if (workspaceName === null) {
            return [];
        }
        return templateMappings.filter(templateMapping =>
            templateMapping.workspaces.some(
                workspace => workspace.name.toString().toLowerCase().indexOf(workspaceName.toLowerCase()) !== -1
            )
        );
    }

    private sortTemplateByGeomTypeAndName(a: TemplateWorkspaceMapping, b: TemplateWorkspaceMapping): number {
        // using solution to sort by multiple fields from here:
        // https://stackoverflow.com/questions/6129952/javascript-sort-array-by-two-fields

        const aGeomType = GeometryType[a.template.geometryType as any];
        const bGeomType = GeometryType[b.template.geometryType as any];
        const aName = a.template.name.toLowerCase();
        const bName = b.template.name.toLowerCase();
        if (aGeomType === bGeomType) {
            return aName < bName ? -1 : aName > bName ? 1 : 0;
        } else {
            return aGeomType < bGeomType ? -1 : 1;
        }
    }
}
