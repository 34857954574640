import { formatDate } from '@angular/common';
import moment from 'moment';

export class DateUtils {
    static utcDateFormat(input: Date): string {
        if (input == null) {
            return '';
        }

        let d = new Date(input);

        const pad = (n: number) => (n < 10 ? '0' + n : n);

        const padYear = (n: number) => (n < 1000 ? '0' + n : n);

        return (
            padYear(d.getUTCFullYear()) +
            '-' +
            pad(d.getUTCMonth() + 1) +
            '-' +
            pad(d.getUTCDate()) +
            'T' +
            pad(d.getUTCHours()) +
            ':' +
            pad(d.getUTCMinutes()) +
            ':' +
            pad(d.getUTCSeconds()) +
            '.000000Z'
        );
    }

    static formatDateFromDateTime(input: Date | string, format: string, isUtc: boolean): string {
        if (input == null) {
            return '';
        }

        const d = new Date(input);

        if (d && d.toString() !== 'Invalid Date') {
            return this.toUser(d, format, isUtc);
        } else {
            return input as string;
        }
    }

    static formatDateFromDate(input: string, format: string): string {
        if (input) {
            return this.toUser(this.getDate(input), format, false);
        }
        return '';
    }

    static toUser(date: Date, format: string, isUtc: boolean): string {
        const locale = 'en-US'; // seems irrelevant since we use custom formats

        switch (format.toLowerCase()) {
            case 'short':
            default:
                return formatDate(date, 'd MMM yy', locale, isUtc ? 'UTC' : null);
            case 'medium':
                return formatDate(date, 'd MMM yyyy', locale, isUtc ? 'UTC' : null);
            case 'long':
                return formatDate(date, 'd MMMM yyyy', locale, isUtc ? 'UTC' : null);
            case 'shortdatetime':
                return formatDate(date, 'd MMM yy HH:mm a', locale, isUtc ? 'UTC' : null);
            case 'longdatetime':
                return formatDate(date, 'd MMMM yyyy HH:mm a', locale, isUtc ? 'UTC' : null);
            case 'time':
                return formatDate(date, 'HH:mm a', locale, isUtc ? 'UTC' : null);
        }
    }

    static forceUtc(date: Date | string): Date {
        if (!date) {
            return null;
        }

        let d = new Date(date);
        return new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000);
    }

    static forceLocal(date: Date | string): Date {
        if (!date) {
            return null;
        }

        let d = new Date(date);
        return new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
    }

    static getDate(date: string | Date): Date {
        if (typeof date === 'object') {
            return date;
        }

        // date can be formatted as a Date object but actually be string so check
        // if length is greater than the maximum ISOString length - 27
        if ((date as string).length > 27) {
            return new Date(date);
        }

        // TF dates come in one of these formats as strings - 2020-06-05T00:00:00, 2020-06-09T14:59:41.0744958.
        // There is no timezone component so date is interpretted by pure string manipulation.
        const dateString = date.toString().split('T')[0];
        const dateParts = dateString.split('-');
        return new Date(parseInt(dateParts[0], 10), parseInt(dateParts[1], 10) - 1, parseInt(dateParts[2], 10));
    }

    static isoStringGreaterThanComparator(val1: string, val2: string): boolean {
        // Returns true if val1 is greater than val2
        if (!val1) return false;
        if (!val2) return true;
        return new Date(val1) > new Date(val2);
    }

    static momentUtcDateFormat(input: Date | string): string {
        if (input == null) {
            return '';
        }

        return moment(input).utc().format(this.defaultDateUtcFormat());
    }

    static startOfDay(date: string | Date, utcFormat: boolean = false): Date | string {
        return utcFormat
            ? this.momentUtcDateFormat(moment(date).startOf('day').toDate())
            : moment(date).startOf('day').toDate();
    }

    static endOfDay(date: string | Date, utcFormat: boolean = false): Date | string {
        return utcFormat
            ? this.momentUtcDateFormat(moment(date).endOf('day').toDate())
            : moment(date).endOf('day').toDate();
    }

    static startOfNextDay(date: string | Date, utcFormat: boolean = false): Date | string {
        return utcFormat
            ? this.momentUtcDateFormat(moment(date).add(1, 'days').startOf('day').toDate())
            : moment(date).add(1, 'days').startOf('day').toDate();
    }

    static isSameDay(date1: string | Date, date2: string | Date): boolean {
        return moment(date1).isSame(date2, 'day');
    }

    static isValidDate(date: string | Date): boolean {
        return moment(date).isValid();
    }

    private static defaultDateUtcFormat() {
        return 'YYYY-MM-DDTHH:mm:ss.SSSSSSSZ';
    }

    static addDays(date: Date, days: number): Date {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    // timestamp (e.g. 1591728000000)
    static toTimestamp(str: string | Date): number {
        return new Date(str).getTime();
    }
}
