export enum SerialPortParities {
    NONE = 'None',
    EVEN = 'Even',
    ODD = 'Odd'
}
export class RtcOptions {
    static sourceTypes: { id: string; name: string }[] = [
        { id: 'TrimbleRTX', name: 'TCS.Mapviewer.Rtc.Opt.RtxInternet' },
        { id: 'SatelliteRTX', name: 'TCS.Mapviewer.Rtc.Opt.RxtSatellite' },
        { id: 'Internet', name: 'TC.Common.Internet' },
        { id: 'Serial', name: 'TC.Common.SerialPort' },
        { id: 'Sbas', name: 'TCS.Mapviewer.Rtc.Opt.Sbas' }
    ];

    static secondarySourceTypes: { id: boolean; name: string }[] = [
        { id: true, name: 'TCS.Mapviewer.Rtc.Opt.Sbas' },
        { id: false, name: 'None' }
    ];

    static baseTypes: { id: string; name: string }[] = [
        { id: 'SingleBase', name: 'TCS.Mapviewer.Rtc.Opt.SingleBase' },
        { id: 'Vrs', name: 'TCS.Mapviewer.Rtc.Opt.Vrs' }
    ];

    static internetServerTypes: { id: string; name: string }[] = [
        { id: 'Ntrip', name: 'TCS.Mapviewer.Rtc.Opt.Ntrip' },
        { id: 'Direct', name: 'TC.Common.Direct' }
    ];

    static unitSystems: { id: string; name: string }[] = [
        { id: '1', name: 'TC.Common.US' },
        { id: '0', name: 'TC.Common.Metric' }
    ];

    static comPorts: { id: string; name: string }[] = [
        { id: 'COM0', name: 'TCS.Mapviewer.Rtc.Opt.Com0' },
        { id: 'COM1', name: 'TCS.Mapviewer.Rtc.Opt.Com1' },
        { id: 'COM2', name: 'TCS.Mapviewer.Rtc.Opt.Com2' },
        { id: 'COM3', name: 'TCS.Mapviewer.Rtc.Opt.Com3' },
        { id: 'COM4', name: 'TCS.Mapviewer.Rtc.Opt.Com4' },
        { id: 'COM5', name: 'TCS.Mapviewer.Rtc.Opt.Com5' },
        { id: 'COM6', name: 'TCS.Mapviewer.Rtc.Opt.Com6' },
        { id: 'COM7', name: 'TCS.Mapviewer.Rtc.Opt.Com7' },
        { id: 'COM8', name: 'TCS.Mapviewer.Rtc.Opt.Com8' },
        { id: 'COM9', name: 'TCS.Mapviewer.Rtc.Opt.Com9' },
        { id: 'COM10', name: 'TCS.Mapviewer.Rtc.Opt.Com10' }
    ];

    static baudRates: { id: number; name: string }[] = [
        { id: 300, name: '300' },
        { id: 600, name: '600' },
        { id: 1200, name: '1200' },
        { id: 1800, name: '1800' },
        { id: 2400, name: '2400' },
        { id: 4800, name: '4800' },
        { id: 9600, name: '9600' },
        { id: 19200, name: '19200' },
        { id: 38400, name: '38400' },
        { id: 57600, name: '57600' },
        { id: 115200, name: '115200' }
    ];

    static dataBits: { id: number; name: string }[] = [{ id: 7, name: '7' }, { id: 8, name: '8' }];

    static stopBits: { id: number; name: string }[] = [{ id: 1, name: '1' }, { id: 2, name: '2' }];

    static parities: { id: string; name: string }[] = [
        { id: SerialPortParities.NONE, name: 'TC.Common.None' },
        { id: SerialPortParities.EVEN, name: 'TC.Common.Even' },
        { id: SerialPortParities.ODD, name: 'TC.Common.Odd' }
    ];
}

export enum RtcSourceTypes {
    NONE = 'None',
    INTERNET = 'Internet',
    SERIAL = 'Serial',
    SBAS = 'Sbas',
    TRIMBLE_RTX = 'TrimbleRTX',
    SATELLITE_RTX = 'SatelliteRTX'
}

export enum RtcBaseType {
    SINGLEBASE = 'SingleBase',
    VRS = 'Vrs',
    AUTO = 'Auto'
}

export enum InternetServerTypes {
    NTRIP = 'Ntrip',
    DIRECT = 'Direct',
    RTX = 'Rtx'
}

export enum DataListTypes {
    NTRIP = 'ntrip',
    DATUM = 'datums'
}
