import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
    imports: [MatExpansionModule, MatProgressBarModule, MatTabsModule, MatMenuModule, MatDividerModule],
    exports: [MatExpansionModule, MatProgressBarModule, MatTabsModule, MatMenuModule, MatDividerModule]
})
export class AppMaterialModule {}
