<div id="scanned-file-wizard">
    <p class="description mb-2" *ngIf="scannedFileDescription">
        {{ scannedFileDescription }}
    </p>
    <div class="file-status" id="import-files-status">
        <div class="import-file-info">
            <div class="status">
                <i class="icon icon-24-success"></i>
                <h5 class="font-bold success-dialog">
                    {{ 'MapViewer.ImportStatusDialog.SuccessScannedFile' | translate }}
                </h5>
            </div>
        </div>
        <div class="file-scanned-contents">
            <p>Contains:</p>
            <ul>
                <li>
                    {{ 'MapViewer.ImportDialog.WorkspaceNameLabel' | translate }} &#8212;
                    {{ uploadedWorkspace.workspace.name }}
                </li>
                <li>
                    {{ uploadedWorkspace.content.length }}
                    {{
                        (uploadedWorkspace.content.length === 1 ? 'TC.Common.Template' : 'TC.Common.Template.Plural')
                            | translate
                    }}
                </li>
            </ul>
        </div>
    </div>
</div>
