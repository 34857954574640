import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeDigitGroup', pure: true })
export class RemoveDigitGroupPipe implements PipeTransform {
    constructor() {}

    transform(value: any): number | null {
        if (value == null || value === '' || value !== value) {
            return null;
        }

        return parseInt(value.toString().replace(/\D/g, ''), 10);
    }
}
