import { AfterViewInit, Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
    selector: '[gsp-focus]'
})
export class GspFocusDirective implements AfterViewInit {
    constructor(private elementRef: ElementRef) {}

    @Input('gsp-focus')
    applyDirective = true;

    @Input()
    preventScroll = false;

    ngAfterViewInit(): void {
        if (this.applyDirective) {
            setTimeout(() => {
                this.elementRef.nativeElement.focus({ preventScroll: this.preventScroll });
                // setting the selection caret position to the end for long rtc name focus bug in firefox
                this.setCaretPosition(this.elementRef.nativeElement, 1000);
            }, 100);
        }
    }

    public setCaretPosition(elem: any, caretPos: number): void {
        if (elem != null) {
            if (elem.createTextRange) {
                let range = elem.createTextRange();
                range.move('character', caretPos);
                range.select();
            } else {
                if (elem.selectionStart) {
                    elem.focus();
                    elem.setSelectionRange(caretPos, caretPos);
                } else {
                    elem.focus();
                }
            }
        }
    }
}

@Directive({
    selector: '[gsp-focus-on-expression]'
})
export class GspFocusOnExpressionDirective implements AfterViewInit, OnChanges {
    @Input()
    expression: string;

    @Input()
    value: string | string[];

    constructor(private elementRef: ElementRef) {}

    ngAfterViewInit(): void {
        this.setFocus();
    }

    ngOnChanges(): void {
        this.setFocus();
    }

    setFocus() {
        if (Array.isArray(this.value) && this.value.indexOf(this.expression) !== -1) {
            setTimeout(() => {
                this.elementRef.nativeElement.focus();
            }, 100);
        }
    }
}
