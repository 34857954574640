import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppMaterialModule } from 'src/app/app-material.module';
import { TranslationModule } from 'src/app/core/translation/translation.module';
import { CommonModule as AppCommonModule } from 'src/app/shared/common/common.module';

import { ImageCropperModule } from 'ngx-image-cropper';
import { FileViewerImportService } from '../import/file-viewer/fileviewer-import.service';
import { ImportModule } from '../import/import.module';
import { ImportService } from '../import/import.service';
import { PostProcessingModule } from '../post-processing/post-processing.module';
import { WorkspaceArchiveComponent } from './workspace-archive/workspace-archive.component';
import { WorkspaceAreaComponent } from './workspace-area/workspace-area.component';
import { WorkspaceCoordinateSystemDetailsComponent } from './workspace-coordinate-system-details/workspace-coordinate-system-details.component';
import { WorkspaceCoordinateSystemComponent } from './workspace-coordinate-system/workspace-coordinate-system.component';
import { WorkspaceBrowseFileComponent } from './workspace-creation/workspace-browse-file/workspace-browse-file.component';
import { WorkspaceCreationNameComponent } from './workspace-creation/workspace-creation-name.component';
import { WorkspaceCreationComponent } from './workspace-creation/workspace-creation.component';
import { WorkspaceScannedFileComponent } from './workspace-creation/workspace-scanned-file/workspace-scanned-file.component';
import { WorkspaceDeleteComponent } from './workspace-delete/workspace-delete.component';
import { WorkspaceDetailsComponent } from './workspace-details/workspace-details.component';
import { WorkspaceDuplicationDetailsComponent } from './workspace-duplication/workspace-duplication-details.component';
import { WorkspaceDuplicationComponent } from './workspace-duplication/workspace-duplication.component';
import { WorkspaceTemplateOptionsComponent } from './workspace-duplication/workspace-template-options.component';
import { WorkspaceErrorComponent } from './workspace-error/workspace-error.component';
import { WorkspaceListComponent } from './workspace-list/workspace-list.component';
import { WorkspaceLocationComponent } from './workspace-location/workspace-location.component';
import { WorkspaceNameValidatorDirective } from './workspace-name-validator.directive';
import { WorkspaceNameComponent } from './workspace-name/workspace-name.component';
import {
    WorkspaceOfflineGnssCorrectionsComponent,
} from './workspace-offline-gnss-corrections/workspace-offline-gnss-corrections.component';
import { WorkspacePanelComponent } from './workspace-panel.component';
import { WorkspaceSetFolderComponent } from './workspace-set-folder/workspace-set-folder.component';
import {
    WorkspaceShareDownloadComponent,
} from './workspace-share/workspace-share-download/workspace-share-download.component';
import { WorkspaceShareInfoComponent } from './workspace-share/workspace-share-info/workspace-share-info.component';
import { WorkspaceShareComponent } from './workspace-share/workspace-share.component';
import { WorkspaceUnarchiveComponent } from './workspace-unarchive/workspace-unarchive.component';

@NgModule({
    declarations: [
        WorkspaceDeleteComponent,
        WorkspaceDetailsComponent,
        WorkspaceErrorComponent,
        WorkspacePanelComponent,
        WorkspaceCreationComponent,
        WorkspaceBrowseFileComponent,
        WorkspaceScannedFileComponent,
        WorkspaceNameComponent,
        WorkspaceAreaComponent,
        WorkspaceCoordinateSystemComponent,
        WorkspaceDuplicationComponent,
        WorkspaceTemplateOptionsComponent,
        WorkspaceCoordinateSystemDetailsComponent,
        WorkspaceNameValidatorDirective,
        WorkspaceDuplicationDetailsComponent,
        WorkspaceCreationNameComponent,
        WorkspaceArchiveComponent,
        WorkspaceUnarchiveComponent,
        WorkspaceListComponent,
        WorkspaceLocationComponent,
        WorkspaceOfflineGnssCorrectionsComponent,
        WorkspaceShareComponent,
        WorkspaceShareDownloadComponent,
        WorkspaceShareInfoComponent,
        WorkspaceSetFolderComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        TranslationModule,
        AppCommonModule,
        AppMaterialModule,
        PostProcessingModule,
        ImportModule,
        ImageCropperModule
    ],
    exports: [
        WorkspaceDeleteComponent,
        WorkspaceDetailsComponent,
        WorkspaceErrorComponent,
        WorkspacePanelComponent,
        WorkspaceCreationComponent,
        WorkspaceBrowseFileComponent,
        WorkspaceScannedFileComponent,
        WorkspaceCoordinateSystemComponent,
        WorkspaceDuplicationComponent,
        WorkspaceTemplateOptionsComponent,
        WorkspaceCoordinateSystemDetailsComponent,
        WorkspaceNameValidatorDirective,
        WorkspaceDuplicationDetailsComponent,
        WorkspaceCreationNameComponent,
        WorkspaceArchiveComponent,
        WorkspaceUnarchiveComponent,
        WorkspaceListComponent,
        WorkspaceOfflineGnssCorrectionsComponent,
        WorkspaceSetFolderComponent
    ],
    providers: [ImportService, FileViewerImportService]
})
export class WorkspaceModule {}
