<gsp-popup
    [heading]="'MapViewer.ForbiddenForms.Header.Label' | translate"
    [size]="ModalSize.Medium"
    [showClose]="true"
    (closed)="closePanel()"
    id="forbidden-form-popup"
>
    <ng-container transclude-popup-content>
        <div class="forbidden-form-popup-content">
            <div class="loading-wrapper" *ngIf="isLoading; else forbiddenFormContent"><div class="loading"></div></div>
            <ng-template #forbiddenFormContent>
                <div class="forbidden-form-description">
                    {{ 'MapViewer.ForbiddenForms.Description' | translate }}
                </div>
                <div class="spacer"></div>
                <div class="forbidden-form-section">
                    <div class="forbidden-form-section-header">
                        {{ 'MapViewer.ForbiddenForms.Actions.ImportForms.Header' | translate }}
                    </div>
                    <div class="forbidden-form-section-description">
                        {{ 'MapViewer.ForbiddenForms.Actions.ImportForms.Description' | translate }}
                    </div>
                    <div class="forbidden-form-section-actions">
                        <gsp-button
                            (action)="importForms()"
                            [type]="ButtonType.Primary"
                            [text]="'MapViewer.ForbiddenForms.Actions.ImportForms.Button.Label' | translate"
                            id="forbidden-form-import-action"
                        ></gsp-button>
                    </div>
                </div>
                <div class="spacer"></div>
                <div class="forbidden-form-section">
                    <div class="forbidden-form-section-header">
                        {{ 'MapViewer.ForbiddenForms.Actions.ExportForms.Header' | translate }}
                    </div>
                    <div class="forbidden-form-section-description">
                        {{ 'MapViewer.ForbiddenForms.Actions.ExportForms.Description' | translate }}
                    </div>
                    <div class="forbidden-form-section-actions">
                        <gsp-button
                            (action)="exportForms()"
                            [type]="ButtonType.Primary"
                            [text]="'MapViewer.ForbiddenForms.Actions.ExportForms.Button.Label' | translate"
                            id="forbidden-form-export-action"
                        ></gsp-button>
                    </div>
                </div>
                <!-- <div class="spacer" *ngIf="userInfo"></div>
                <div class="forbidden-form-section" *ngIf="userInfo">
                    <div class="forbidden-form-section-header">
                        {{ 'MapViewer.ForbiddenForms.Actions.ReInviteUser.Header' | translate }}
                    </div>
                    <div class="forbidden-form-section-description">
                        {{ 'MapViewer.ForbiddenForms.Actions.ReInviteUser.Description' | translate }}
                    </div>
                    <div class="forbidden-form-section-actions">
                        <gsp-button
                            (action)="reInviteUser()"
                            [type]="ButtonType.Primary"
                            [text]="'MapViewer.ForbiddenForms.Actions.ReInviteUser.Button.Label' | translate"
                            id="forbidden-form-import-action"
                        ></gsp-button>
                    </div>
                </div> -->
                <div class="spacer"></div>
                <div class="forbidden-form-section">
                    <div class="forbidden-form-section-header">
                        {{ 'MapViewer.ForbiddenForms.Actions.DeleteForms.Header' | translate }}
                    </div>
                    <div class="forbidden-form-section-description">
                        {{ 'MapViewer.ForbiddenForms.Actions.DeleteForms.Description' | translate }}
                    </div>
                    <div class="forbidden-form-section-actions">
                        <gsp-button
                            (action)="rejectForms()"
                            [type]="ButtonType.Danger"
                            [text]="'TC.Common.Reject' | translate"
                            id="forbidden-form-reject-action"
                        ></gsp-button>
                    </div>
                </div>
            </ng-template>
        </div>
    </ng-container>
</gsp-popup>
