import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { GspLoggerService } from 'src/app/log-handler.service';
import { LayersStreams } from 'src/app/shared/common/current-layers/layers-streams.service';
import { EnvironmentService } from 'src/app/shared/common/environment/environment.service';
import { FeatureFilter } from 'src/app/shared/common/feature-filter/feature-filter';
import { ThrottledHttpService } from 'src/app/shared/common/utility/throttled-http.service';
import { CachedFeatureService } from 'src/app/shared/map-data-services/feature/cached-feature.service';
import { Feature } from 'src/app/shared/map-data-services/feature/feature';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';

import { FullFeatureFilter } from 'src/app/shared/common/feature-filter/full-feature-filter';
import { ClusterLayerService } from './cluster-layer.service';
import { FeatureMapLayer } from './feature-map-layer';
import { FeatureMapLayerCacheService } from './feature-map-layer-cache.service';
import { FeatureMapLayersService } from './feature-map-layers.service';

const tileLayerOptions = {
    clipTiles: true,
    noWrap: true, // needed to ensure the same tile is not loaded twice resulting in duplicate features and clusters
    minZoom: 0,
    maxZoom: 21,

    // If changing the tile size from the default of 256, you need to communicate the tileSize to the tiling service.
    // See tileSize query string param.
    tileSize: 256,
    zoomOffset: 0
};

@Injectable({
    providedIn: 'root'
})
export class TileClusterLayerService {
    constructor(
        cluster: ClusterLayerService,
        private env: EnvironmentService,
        private throttledHttp: ThrottledHttpService,
        private featureMapLayersService: FeatureMapLayersService,
        private featureMapLayerCacheService: FeatureMapLayerCacheService,
        private cachedFeatureService: CachedFeatureService,
        private layersStreams: LayersStreams,
        private logger: GspLoggerService
    ) {
        if (!L.ClusterLayer) {
            cluster.extendLeaflet();
        }
    }

    // This function needs to be called before L.TileClusterLayer can be used
    extendLeaflet(): void {
        this._extendLeaflet(
            this.env,
            this.throttledHttp,
            this.featureMapLayersService,
            this.featureMapLayerCacheService,
            this.cachedFeatureService,
            this.layersStreams,
            this.logger
        );
    }

    _extendLeaflet(
        env: EnvironmentService,
        throttledHttp: ThrottledHttpService,
        featureMapLayersService: FeatureMapLayersService,
        featureMapLayerCacheService: FeatureMapLayerCacheService,
        cachedFeatureService: CachedFeatureService,
        layersStreams: LayersStreams,
        log: GspLoggerService
    ): void {
        (L as any).TileClusterLayer = L.ClusterLayer.extend({
            initialize: function (
                map: L.Map,
                projectId: string,
                mapWorkspaceId: string,
                layerModel: Layer,
                zIndex: number,
                applyFilter: FeatureFilter,
                excludedFeatureIds: string[]
            ) {
                (L.ClusterLayer.prototype as any).initialize.call(
                    this,
                    layerModel.id,
                    layerModel.getLayerColorKey() || null,
                    zIndex,
                    true // enableClustering
                );

                this.stop = new Subject<void>();

                let tileClusterLayerThis = this;

                // features managed by the tileclusterlayer (excludes those managed by base clusterLayer)
                tileClusterLayerThis.tileClusterLayerFeatureIds = [];

                tileClusterLayerThis.layer = layerModel;

                let prefixPath = '/projects/' + projectId;
                let tilePath = env.tilesApiUrl + prefixPath + '/tiles/{z}/{x}/{y}';

                // features that must be excluded from the map layer (e.g. they are displayed in the tasks layer)
                tileClusterLayerThis.excludedFeatureIds = excludedFeatureIds || [];
                // track the features that are the map layer response (i.e. not in a server cluster) but are excluded
                // so that we add then when they are unexcluded
                tileClusterLayerThis.existingButExcludedFeatureIdsAtThisZoomLevel = [];

                // Filter
                let filter = new FullFeatureFilter(applyFilter);
                filter.layers = [layerModel];
                // other filters carried across from applyFilter
                let filterRequest = filter.buildFilterRequest();

                tilePath += '?filter=' + JSON.stringify(filterRequest);
                if (layerModel.cacheTimeStamp !== undefined) {
                    tilePath += '&timestamp={timeStamp}';
                }

                this.tileLayer = new (L.TileLayer as any).GeoJSON(
                    tilePath,
                    _.extend({}, tileLayerOptions, {
                        subdomains: [],
                        zIndex: zIndex,
                        bounds: layerModel.bounds,
                        timeStamp: layerModel.cacheTimeStamp,
                        unique: (feature: Feature) => feature.id
                    }),
                    {
                        // NOTE: The following option functions are listed in the order they are called

                        // 1. Filter features from the tile - redirect clusterable features off to the markerClusterGroup
                        filter: (feature: Feature): boolean => {
                            let existingFeatureMapLayer = featureMapLayerCacheService.getFeatureMapLayer(feature.id);
                            let existingFeature = cachedFeatureService.getFeature(feature.id);
                            if (existingFeature && existingFeature.deleted) {
                                return false;
                            }
                            if (tileClusterLayerThis.isClusterable(feature)) {
                                if (
                                    !existingFeatureMapLayer ||
                                    !tileClusterLayerThis.markerClusterOrLayerGroup.hasLayer(existingFeatureMapLayer)
                                ) {
                                    // only create new feature map layer if not excluded
                                    if (tileClusterLayerThis.excludedFeatureIds.indexOf(feature.id) < 0) {
                                        feature.layerId = layerModel.id;
                                        let featureMapLayer = L.ClusterLayer.prototype.createOrUpdateMarkers.call(
                                            tileClusterLayerThis,
                                            feature,
                                            layerModel.id,
                                            tileClusterLayerThis.layerColorKey
                                        );
                                        tileClusterLayerThis.pendingFeatureMapLayers.push(featureMapLayer);
                                    } else {
                                        if (
                                            tileClusterLayerThis.existingButExcludedFeatureIdsAtThisZoomLevel.indexOf(
                                                feature.id
                                            ) < 0
                                        ) {
                                            tileClusterLayerThis.existingButExcludedFeatureIdsAtThisZoomLevel.push(
                                                feature.id
                                            );
                                        }
                                    }
                                }
                                return false;
                            } else {
                                // don't allow excluded features to be added
                                if (tileClusterLayerThis.excludedFeatureIds.indexOf(feature.id) >= 0) {
                                    return false;
                                }

                                // Note: only manages features not sent to the clusterLayer
                                if (tileClusterLayerThis.tileClusterLayerFeatureIds.indexOf(feature.id) < 0) {
                                    tileClusterLayerThis.tileClusterLayerFeatureIds.push(feature.id);
                                }

                                return true;
                            }
                        },

                        // 2. Convert from GeoJSON coords to WGS84
                        coordsToLatLng: (coords: any): any => {
                            let crs: any;
                            let epsg = '4326'; // todo - get from layer
                            switch (epsg) {
                                case '3857':
                                    crs = L.CRS.EPSG3857;
                                    crs.unproject = function (point: any) {
                                        // don't ask!
                                        let earthRadius = 6378137;
                                        let normalizedPoint = point.divideBy(earthRadius);
                                        return this.projection.unproject(normalizedPoint);
                                    };
                                    break;
                                case '4326':
                                    crs = L.CRS.EPSG4326;
                                    crs.unproject = function (point: any) {
                                        return this.projection.unproject(point);
                                    };
                                    break;
                                default:
                                    log.warn('Unsupported EPSG ' + epsg);
                                    break;
                            }
                            return crs.unproject(new L.Point(coords[0], coords[1]));
                        },

                        // 3. Generally unused (because points are clustered),
                        // except when dealing with points as part of a GeometryCollection
                        /*pointToLayer: function(feature) {
                          // Note: for GeometryCollection features we only get the "sub"-feature with no id and symbology properties.
                          //  So we utilize the saved currentFeature to provide this info.
                          feature = cachedFeatureService.updateFromCacheFeature(feature);
                          featureMapLayersService.setLayerSymbologyProperties(feature, tileClusterLayerThis.layerColorKey);
                          var childMapLayer = featureMapLayersService.createFeatureMapLayer(feature);
                          var existingFeatureMapLayer = featureMapLayerCacheService.getFeatureMapLayer(feature.id);
                          var mapLayer = featureMapLayersService.mergeChildToParentFeatureMapLayer(existingFeatureMapLayer, childMapLayer);
                          if (mapLayer) {
                            featureMapLayerCacheService.addOrUpdateFeatureMapLayer(feature, mapLayer, layerModel);
                          }
                          return childMapLayer;
                        },*/

                        // 4. Determine line or polygon style or the feature
                        style: (feature: Feature): L.PathOptions => {
                            // feature is always an "incomplete" feature (id, geometry) - We need to get selection status,
                            //  etc. from full feature in feature cache and from the layer
                            featureMapLayersService.setLayerSymbologyProperties(
                                feature,
                                tileClusterLayerThis.layerColorKey
                            );
                            return featureMapLayersService.getFeatureStyle(feature);
                        },

                        // 5. Track each feature added via the tile
                        onEachFeature: (feature: Feature, featureMapLayer: FeatureMapLayer): void => {
                            feature.layerId = layerModel.id;
                            // Note: this may be just one part of a clipped feature
                            let existingFeatureMapLayer = featureMapLayerCacheService.getFeatureMapLayer(feature.id);

                            if (existingFeatureMapLayer) {
                                featureMapLayer = featureMapLayersService.mergeFeatureMapLayers(
                                    existingFeatureMapLayer,
                                    featureMapLayer
                                ) as FeatureMapLayer;
                            }
                            if (featureMapLayer) {
                                feature = cachedFeatureService.updateFromCacheFeature(feature);
                                featureMapLayersService.updateFeatureMapLayer(feature, featureMapLayer, false);
                            }
                        }
                    },
                    (req: string) => throttledHttp.queueRequest(req)
                );

                tileClusterLayerThis.zoom = -1;
                tileClusterLayerThis.pendingFeatureMapLayers = [];

                // event handlers
                map.on('zoom', this.onZoom.bind(tileClusterLayerThis));
                map.on('zoomend', this.onZoomEnd.bind(tileClusterLayerThis));
                tileClusterLayerThis.tileLayer.on('loading', this.onLayerLoading.bind(tileClusterLayerThis));
                tileClusterLayerThis.tileLayer.on('load', this.onLayerLoaded.bind(tileClusterLayerThis));
            },

            isClusterable: (feature: Feature): boolean => L.ClusterLayer.prototype.isClusterable(feature),

            addExcludedFeature(feature: Feature): void {
                if (this.excludedFeatureIds.indexOf(feature.id) < 0) {
                    this.excludedFeatureIds.push(feature.id);
                    this.handleExcludedFeature(feature);
                }
            },

            removeExcludedFeature(feature: Feature): void {
                let index = this.excludedFeatureIds.indexOf(feature.id);
                if (index >= 0) {
                    this.excludedFeatureIds.splice(index, 1);
                    this.handleExcludedFeature(feature);
                }
            },

            handleExcludedFeature(feature: Feature): void {
                // Note: works for both clustered and non-clustered features on layer
                let isClusterable: boolean = this.isClusterable(feature);
                if (feature) {
                    let existingFeatureMapLayer = featureMapLayerCacheService.getFeatureMapLayer(feature.id);
                    if (existingFeatureMapLayer) {
                        if (
                            // is excluded but exists...
                            this.excludedFeatureIds.indexOf(feature.id) >= 0 &&
                            this.hasLayerForFeature(feature, existingFeatureMapLayer)
                        ) {
                            // ...so remove
                            this.removeLayerForFeature(feature, existingFeatureMapLayer);
                            if (isClusterable) {
                                if (this.existingButExcludedFeatureIdsAtThisZoomLevel.indexOf(feature.id) < 0) {
                                    this.existingButExcludedFeatureIdsAtThisZoomLevel.push(feature.id);
                                }
                            }
                        } else if (
                            // is NOT excluded but doesn't exist...
                            this.excludedFeatureIds.indexOf(feature.id) < 0 &&
                            !this.hasLayerForFeature(feature, existingFeatureMapLayer)
                        ) {
                            if (isClusterable) {
                                let index = this.existingButExcludedFeatureIdsAtThisZoomLevel.indexOf(feature.id);
                                if (index >= 0) {
                                    // ...and was existing at this zoom level ...so add
                                    this.existingButExcludedFeatureIdsAtThisZoomLevel.splice(index, 1);
                                    this.addLayerForFeature(feature, existingFeatureMapLayer);
                                }
                            } else {
                                this.addLayerForFeature(feature, existingFeatureMapLayer);
                            }
                        }
                    }
                }
            },

            hasLayerForFeature(feature: Feature, featureMapLayer: FeatureMapLayer): boolean {
                if (this.isClusterable(feature)) {
                    return L.ClusterLayer.prototype.hasLayer.call(this, featureMapLayer);
                } else {
                    return this.tileClusterLayerFeatureIds.indexOf(feature.id) >= 0;
                }
            },

            addLayerForFeature(feature: Feature, featureMapLayer: FeatureMapLayer): void {
                if (this.isClusterable(feature)) {
                    L.ClusterLayer.prototype.addLayers.call(this, [featureMapLayer]);
                } else {
                    this.tileLayer.addLayerForFeature(feature.id, featureMapLayer);
                    if (this.tileClusterLayerFeatureIds.indexOf(feature.id) < 0) {
                        this.tileClusterLayerFeatureIds.push(feature.id);
                    }
                }
            },

            removeLayerForFeature(feature: Feature, featureMapLayer: FeatureMapLayer): void {
                if (this.isClusterable(feature)) {
                    L.ClusterLayer.prototype.removeLayers.call(this, [featureMapLayer]);
                } else {
                    this.tileLayer.removeLayerForFeature(feature.id);
                    let index = this.tileClusterLayerFeatureIds.indexOf(feature.id);
                    if (index >= 0) {
                        this.tileClusterLayerFeatureIds.splice(index, 1);
                    }
                }
            },

            onAdd(map: L.Map): void {
                L.ClusterLayer.prototype.onAdd.call(this, map);
                map.addLayer(this.tileLayer);
            },

            onRemove(map: L.Map): void {
                map.removeLayer(this.tileLayer);
                L.ClusterLayer.prototype.onRemove.call(this, map);
                this.clearLayer();
            },

            setZIndex(zIndex: number): void {
                L.ClusterLayer.prototype.setZIndex.call(this, zIndex);

                this.tileLayer.setZIndex(zIndex);
            },

            redraw(): void {
                this.clearLayer();
                this.tileLayer.redraw();
            },

            onZoom(event: { target: any }): void {
                // The entire layer is reloaded whenever the zoom changes
                let newZoom = event.target.getZoom();
                if (this.zoom !== newZoom) {
                    this.zoom = newZoom;
                }
            },

            onZoomEnd(event: { target: any }): void {
                this.clearLayer();
            },

            onLayerLoading(event: { target: any }): void {
                layersStreams.setLayerAsLoading(this.layer);
                this.addPendingFeatureMapLayers();
            },

            onLayerLoaded(/*event*/): void {
                this.cancelTimer();
                this.addPendingFeatureMapLayers();
            },

            clearLayer(): void {
                this.cancelTimer();
                L.ClusterLayer.prototype.clearLayers.call(this);
                this.pendingFeatureMapLayers = [];
                this.existingButExcludedFeatureIdsAtThisZoomLevel = [];
                this.tileClusterLayerFeatureIds = [];
            },

            cancelTimer(): void {
                this.stop.next(null);
                layersStreams.setLayerAsLoaded(this.layer);
            },

            addPendingFeatureMapLayers(): void {
                let featureMapLayers: FeatureMapLayer[] = this.pendingFeatureMapLayers.slice(); // make shallow copy
                this.pendingFeatureMapLayers = [];
                L.ClusterLayer.prototype.addLayers.call(this, featureMapLayers);
            }
        });
    }
}
