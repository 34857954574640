import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { TCFile } from 'src/app/shared/common/files/TCFile';
import { ArchiveTagDetails, Tag, TCFileService, TCObjectType } from 'src/app/shared/common/files/TCFile.service';
import { LoaderStreamService } from 'src/app/shared/common/loader/loader-stream.service';
import { ModalSize } from 'src/app/shared/common/modal-sizes';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { MapWorkspaceStatus } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace.types';

import { MainMenuStreamService } from '../../map-viewer/main-menu/main-menu-stream.service';

@Component({
    selector: 'workspace-unarchive',
    templateUrl: './workspace-unarchive.component.html'
})
export class WorkspaceUnarchiveComponent implements OnInit {
    ModalSize = ModalSize;

    archivedWorkspace: MapWorkspace = null;
    moveWorkspace = false;
    moveFolderId: string;

    constructor(
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private router: Router,
        private mainMenuStream: MainMenuStreamService,
        private loading: LoaderStreamService,
        private messaging: MessagingService,
        private tcFileService: TCFileService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.archivedWorkspace = this.mapWorkspacesStore.archiveEditMapWorkspaceStream.getValue();
    }

    closePanel(): void {
        this.router
            .navigate(['mapViewer', { outlets: { centerDialog: null, mainMenu: 'workspaces' } }], {
                queryParamsHandling: 'preserve'
            })
            .then(() => {
                this.mainMenuStream.showMainMenu$.next(true);
            });
    }

    unarchiveWorkspace(): void {
        this.loading.isLoading$.next(true);
        if (this.moveWorkspace && this.moveFolderId) {
            this.moveWorkspaceFile()
                .then(() => {
                    this.updateMapWorkspaceStatus().then(() => {
                        this.updateTagForWorkspace()
                            .catch(() => {
                                this.messaging.showError(
                                    this.translate.instant('MapViewer.ArchiveWorkspace.TagError.Label')
                                );
                            })
                            .finally(() => {
                                this.loading.isLoading$.next(false);
                                this.closePanel();
                            });
                    });
                })
                .catch(() => {
                    this.loading.isLoading$.next(false);
                    this.messaging.showError(this.translate.instant('MapViewer.ArchiveWorkspace.MoveError.Label'));
                });
        } else {
            this.updateMapWorkspaceStatus().then(() => {
                this.updateTagForWorkspace()
                    .catch(() => {
                        this.messaging.showError(this.translate.instant('MapViewer.ArchiveWorkspace.TagError.Label'));
                    })
                    .finally(() => {
                        this.loading.isLoading$.next(false);
                        this.closePanel();
                    });
            });
        }
    }

    moveWorkspaceFile(): Promise<TCFile> {
        const fileProperties = {
            parentId: this.moveFolderId
        };
        return this.tcFileService.updateFile(this.archivedWorkspace.connectFileId, fileProperties);
    }

    async updateMapWorkspaceStatus(): Promise<void | {
        succeeded: boolean;
        message: string;
        updatedMapWorkspace: MapWorkspace;
    }> {
        this.archivedWorkspace.status = MapWorkspaceStatus.ACTIVE;
        const result = await this.mapWorkspacesStore.updateMapWorkspace(this.archivedWorkspace, true);
        if (!result.succeeded) {
            this.messaging.showError(this.translate.instant('MapViewer.UnarchiveWorkspace.UnarchiveError.Label'));
        } else {
            this.messaging.showSuccess(this.translate.instant('MapViewer.UnrchiveWorkspace.UnrchiveSuccess.Label'));
            this.mapWorkspacesStore.updateMapWorkspaceCache(result.updatedMapWorkspace);
        }
    }

    async updateTagForWorkspace(): Promise<Tag> {
        const tag = await this.tcFileService.getTagByLabel(this.archivedWorkspace.projectId, ArchiveTagDetails.LABEL);
        const tagObjects = await this.tcFileService.getObjectsForTag(tag.id);
        const isFileTagged = tagObjects.find(tagObject => tagObject.id === this.archivedWorkspace.connectFileId);
        if (isFileTagged) {
            return this.tcFileService.removeObjectFromTag(
                tag.id,
                this.archivedWorkspace.connectFileId,
                TCObjectType.FILE
            );
        }
    }

    setFolder(folder: TCFile): void {
        if (folder) {
            this.moveFolderId = folder.id;
        } else {
            this.moveFolderId = null;
        }
    }
}
