import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslationModule } from 'src/app/core/translation/translation.module';
import { CommonModule as AppCommonModule } from 'src/app/shared/common/common.module';

import { ErrorPageRoutingModule } from './error-page-routing.module';
import { ErrorPageComponent } from './error-page.component';

@NgModule({
    declarations: [ErrorPageComponent],
    imports: [CommonModule, TranslationModule, AppCommonModule, ErrorPageRoutingModule],
    exports: [ErrorPageComponent]
})
export class ErrorPageModule {}
