import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as L from 'leaflet';

import { MapService } from '../../map-viewer/map.service';

@Component({
    selector: 'workspace-location',
    templateUrl: './workspace-location.component.html'
})
export class WorkspaceLocationComponent implements OnInit {
    @Input()
    public map: L.Map = null;
    @Output()
    private mapChange = new EventEmitter<L.Map>();

    @Output()
    private initialized = new EventEmitter<void>();

    @Output()
    private layersLoaded = new EventEmitter<boolean>(false);

    @ViewChild('workspaceMiniMap', { static: true })
    mapElement: ElementRef;

    public marker: L.Marker;

    private attributionControl: L.Control.Attribution;
    private miniMapMaxZoom = 16;
    private baseMapProviderName = 'trimble';
    private baseMapModeName = 'map';

    constructor(private mapService: MapService) {}

    ngOnInit(): void {
        this.mapService.intializeBaseMapProvider();
        let map = this.mapService.intializeMap(
            this.mapElement.nativeElement,
            this.baseMapProviderName,
            this.baseMapModeName,
            {
                attributionControl: false,
                maxZoom: this.miniMapMaxZoom // Trimble map max zoom
            }
        );
        this.map = map;
        this.mapService.mapReadyStreams['workspace-mini-map'].next(true);
        this.map.on('mouseover', () => {
            this.attributionControl = L.control.attribution({ prefix: false });
            this.map.addControl(this.attributionControl);
        });

        this.map.on('mouseout', () => {
            this.map.removeControl(this.attributionControl);
        });

        this.map.on('resize', () => {
            this.map.invalidateSize();
        });

        this.map.on('move', () => {
            if (this.marker) {
                this.marker.setLatLng(this.map.getCenter());
            } else {
                this.marker = L.marker(this.map.getCenter()).addTo(this.map);
            }
        });

        // enable the forward button only after all the tiles has been loaded
        this.map.eachLayer(layer => {
            if (layer instanceof L.TileLayer) {
                layer.on('loading', () => {
                    this.layersLoaded.emit(false);
                });
                layer.on('load', () => {
                    this.layersLoaded.emit(true);
                });
            }
        });

        this.mapChange.emit(this.map);
        this.initialized.emit();
    }
}
