import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: '[emailValidate]',
    providers: [{ provide: NG_VALIDATORS, useExisting: EmailValidateDirective, multi: true }]
})
export class EmailValidateDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors {
        const value = control?.value;
        if (value) {
            const emailPattern =
                /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
            const isValid = emailPattern.test(value);

            if (!isValid) {
                return { ['emailInvalid']: true }; // return object if the validation is not passed.
            }
        }
        return null; // return null if validation is passed.
    }
}
