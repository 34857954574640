import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { GspImageCropperService } from 'src/app/shared/common/components/gsp-image-cropper/gsp-image-cropper.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { LoaderStreamService } from 'src/app/shared/common/loader/loader-stream.service';
import { ModalSize } from 'src/app/shared/common/modal-sizes';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';
import { DateUtils } from 'src/app/shared/common/utility/date-utils';
import { FileUtils } from 'src/app/shared/common/utility/file-utils';
import { GeneralUtils } from 'src/app/shared/common/utility/general-utils';
import { GeometryUtils } from 'src/app/shared/common/utility/geometry-utils';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { MapWorkspaceService } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace.service';
import { UserSettingsStreamService } from 'src/app/shared/user/user-settings-stream.service';
import { WorkspaceService } from '../workspace.service';

@Component({
    selector: 'workspace-details',
    templateUrl: './workspace-details.component.html'
})
export class WorkspaceDetailsComponent implements OnDestroy, OnInit, AfterViewChecked {
    // expose enum to template
    public ButtonType = ButtonType;
    public ModalSize = ModalSize;

    public isFormValid = true;
    public saveInProgress = false;
    public modifyWorkspace: MapWorkspace;
    public sourceWorkspace: MapWorkspace;
    public map: L.Map = null;
    public canEditBounds = false;
    public editingBounds = false;
    public croppedImage: string = null;
    public workspaceAreaOrImage: string = null;
    public loadImgComponentLoaded = true;

    private _isFormValid = true;
    private readonly destroyed = new Subject<void>();

    constructor(
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private workspaceService: WorkspaceService,
        private mapWorkspaceService: MapWorkspaceService,
        private messaging: MessagingService,
        private router: Router,
        private route: ActivatedRoute,
        private loadingService: LoaderStreamService,
        private translate: TranslationService,
        private userSettingsStream: UserSettingsStreamService,
        private imageCropperService: GspImageCropperService
    ) {}

    ngOnInit(): void {
        const workspace = this.mapWorkspacesStore.editMapWorkspaceStream.getValue();

        if (!workspace) {
            this.closeEditPanel();
            return;
        }

        this.imageCropperService.imageCroppedEvent$.subscribe(imageEvent => {
            this.croppedImage = imageEvent?.objectUrl;
        });

        this.modifyWorkspace = CloneUtils.cloneDeep(workspace);
        this.sourceWorkspace = CloneUtils.cloneDeep(this.mapWorkspacesStore.getMapWorkspaceCache(workspace.id));

        // checks "set workspace bounds" button visibility
        this.canEditBounds = this.boundsHasNoValues(this.modifyWorkspace);
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    ngAfterViewChecked(): void {
        // Workaround to fix the expressionChangedAfterViewHasBeenChecked error
        // Use setTimeout because it can defer the code to another
        // Javascript Virtual Machine turn. So at that time, view will be built again.

        // Avoid this workaround if possible.
        setTimeout(() => {
            this.isFormValid = this._isFormValid;
        }, 0);
    }

    public async saveWorkspace(): Promise<void> {
        this.saveInProgress = true;
        this.loadingService.isLoading$.next(true);
        const result = await this.mapWorkspacesStore.updateMapWorkspace(this.modifyWorkspace);
        this.saveInProgress = false;
        if (!result.succeeded) {
            this.messaging.showError(result.message);
        } else {
            this.messaging.showSuccess(result.message);
            this.sourceWorkspace.name = result.updatedMapWorkspace.name;
            this.sourceWorkspace.description = result.updatedMapWorkspace.description;
            this.sourceWorkspace.bounds = result.updatedMapWorkspace.bounds;
            this.sourceWorkspace.workspaceModel.isCustomThumbnailImage =
                this.modifyWorkspace.workspaceModel.isCustomThumbnailImage;

            if (!this.canEditBounds) await this.updateWorkspaceImage();

            this.mapWorkspacesStore.updateMapWorkspaceCache(this.sourceWorkspace);
        }

        this.closeEditPanel();
    }

    public closeEditPanel(): void {
        this.loadingService.isLoading$.next(false);
        this.mapWorkspacesStore.editMapWorkspaceStream.next(null);
        this.imageCropperService.clearCroppedImageCache();
        const searchMainMenu = 'mainMenu:positioning';
        if (this.router && this.router.url && this.router.url.includes(searchMainMenu)) {
            this.redirectMainMenuOutlet('positioning');
            return;
        }
        this.redirectMainMenuOutlet();
    }

    public redirectMainMenuOutlet(mode: string = 'workspaces'): void {
        this.router.navigate(['mapViewer', { outlets: { centerDialog: null, mainMenu: mode } }], {
            queryParamsHandling: 'preserve'
        });
    }

    private getThumbnailUrl(workspace: MapWorkspace): string {
        return this.workspaceService.getThumbnailUrl(workspace);
    }

    private getAreaThumbnailUrl(workspace: MapWorkspace): string {
        return this.workspaceService.getThumbnailUrl(workspace, true);
    }

    public onMapInitialized(): void {
        const { center, zoom } = this.userSettingsStream.getCurrentMapWorkspaceSettings();
        this.map.setView(center, zoom);
    }

    public onValidationChange(isValid: boolean): void {
        this._isFormValid = isValid;
    }

    public showSuccess(): void {
        this.messaging.showSuccess(this.translate.instant('TCS.Workspace.WorkspaceLocationSet'));
    }

    public toggleMap(): void {
        this.editingBounds = !this.editingBounds;
    }

    public updateBounds(): void {
        const bounds: L.LatLngBounds = this.map.getBounds();
        this.modifyWorkspace.bounds = GeometryUtils.setWorkspaceBoundsFromMap(bounds);
        this.toggleMap();
        this.handleToggleWorkspaceImage(false);
        this.showSuccess();
    }

    // Custom Image Conditions
    private workspaceImageCropped(): boolean {
        return this.modifyWorkspace?.workspaceModel.isCustomThumbnailImage && !!this.croppedImage;
    }

    private noImageCropped(): boolean {
        return this.modifyWorkspace?.workspaceModel.isCustomThumbnailImage && !this.croppedImage;
    }

    public noCustomImageAvailable(): boolean {
        return (
            !this.sourceWorkspace?.workspaceModel.isCustomThumbnailImage &&
            this.modifyWorkspace?.workspaceModel.isCustomThumbnailImage &&
            !this.croppedImage
        );
    }

    public openImageCropper(): void {
        this.mapWorkspacesStore.editMapWorkspaceStream.next(this.modifyWorkspace);

        const workspaceParams = this.route.snapshot.queryParams;
        this.router.navigate(['mapViewer', { outlets: { centerDialog: 'workspace/imagecropper' } }], {
            queryParamsHandling: 'preserve',
            skipLocationChange: true,
            queryParams: { ...workspaceParams }
        });
    }

    // handler for workspace image switch
    public handleToggleWorkspaceImage(isCustomImageEnabled?: boolean) {
        if (!isCustomImageEnabled) {
            this.imageCropperService.clearCroppedImageCache();
        }

        // retrigger load-img when is toggled
        this.loadImgComponentLoaded = false;
        setTimeout(() => {
            this.loadImgComponentLoaded = true;
        }, 0);
    }

    // check if there is custom image and cropped image
    public croppedImageOrWorkspaceArea(): string {
        if (!this.modifyWorkspace.hasThumbnail || this.noCustomImageAvailable()) {
            return null;
        }

        if (this.workspaceImageCropped()) {
            return this.croppedImage;
        }

        return !this.modifyWorkspace.workspaceModel.isCustomThumbnailImage
            ? this.getAreaThumbnailUrl(this.modifyWorkspace)
            : this.getThumbnailUrl(this.modifyWorkspace);
    }

    public getWorkspaceAreaOrImageLabel(workspace: MapWorkspace): string {
        return !this.boundsHasNoValues(workspace) &&
            (workspace.workspaceModel?.isCustomThumbnailImage || this.croppedImage)
            ? this.translate.instant('MapViewer.Workspace.CustomImage.Label')
            : this.translate.instant('MapViewer.Workspace.Bounds.Label');
    }

    private boundsHasNoValues(workspace: MapWorkspace): boolean {
        return (
            !workspace.bounds || !Object.values(workspace.bounds).some(val => !GeneralUtils.isNullUndefinedOrNaN(val))
        );
    }

    private async updateWorkspaceImage(): Promise<void> {
        // update image cache
        this.sourceWorkspace.updatedUtc = DateUtils.forceUtc(new Date());

        // determine if we need to update the workspace image / area
        await this.updateOrRemoveWorkspaceImage(this.modifyWorkspace.workspaceModel.isCustomThumbnailImage);
    }

    private async updateOrRemoveWorkspaceImage(customImage: boolean): Promise<void> {
        let imageFile = null;

        if (!customImage) {
            // Area Image
            await this.mapWorkspaceService.updateWorkspaceImage(this.sourceWorkspace, null, true);
        } else {
            // Custom Image
            if (this.workspaceImageCropped()) {
                imageFile = await FileUtils.urlToFile(this.croppedImage, 'workspace-image.jpeg');
                await this.mapWorkspaceService.updateWorkspaceImage(this.sourceWorkspace, imageFile);
            }
        }
    }
}
