import * as L from 'leaflet';
import * as _ from 'lodash-es';
import { ActiveToast } from 'ngx-toastr';
import { BehaviorSubject, defer, identity, Observable, Subject } from 'rxjs';
import { concatMap, distinctUntilChanged, tap } from 'rxjs/operators';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { GspLoggerService } from 'src/app/log-handler.service';
import { Feature } from 'src/app/shared/map-data-services/feature/feature';
import { FeatureService } from 'src/app/shared/map-data-services/feature/feature.service';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';

import { ProjectStreamService } from '../current-project/project-stream.service';
import { FeatureFilter } from '../feature-filter/feature-filter';
import { FullFeatureFilter } from '../feature-filter/full-feature-filter';
import { Task } from '../task/task';
import { ArrayUtils } from '../utility/array-utils';
import { CloneUtils } from '../utility/clone-utils';
import { StringUtils } from '../utility/string-utils';
import { GeometryTypeSelectionCriteria, SelectionCriteria, TodoSelectionCriteria } from './selection-criteria';

/**
 * A FeatureSet is used to managed a set of features; e.g. selected features, visible features, etc.
 *
 */
export class FeatureSet {
    // a subject and derived stream for the current feature set
    currentFeaturesStream = new BehaviorSubject<Feature[]>([]);

    featureFilter = new FeatureFilter();

    currentFeatures: Feature[] = [];
    currentFilterLayers: Layer[] = [];
    activeToast: ActiveToast<any> = null;
    selectedFeatureIds: string[] = [];

    // The actions queue takes a deferred observable which contains a method that returns a promise.
    // It ensures that each action is executed before the next one is started, as the
    // the result (i.e. currentFeatures and currentFilterLayers) must feed from one action to the next.
    actionsQueue = new Subject<Observable<any[]>>();

    constructor(
        private messaging: MessagingService,
        private projectStream: ProjectStreamService,
        private featureService: FeatureService,
        private name: string, // To help differentiate instances while debugging
        private hideNotifications: boolean,
        private translate: TranslationService,
        private logger: GspLoggerService
    ) {
        // Debugging - change to true for logging.
        const debugging = false;
        if (debugging) {
            this.currentFeaturesStream.subscribe(features => {
                this.logger.debug('this._current = ' + this.name + ' ' + features.length + ' features');
            });
        }

        this.actionsQueue
            .pipe(
                concatMap(identity),
                distinctUntilChanged(_.isEqual),
                tap((features: Feature[]) => (this.currentFeatures = features))
            )
            .subscribe(this.currentFeaturesStream);
        // above subscribe re-broadcasts via the subject ref: https://medium.com/@luukgruijs/understanding-rxjs-subjects-339428a1815b
    }

    // currently selected features must be in available layers (= map workspace layers)
    public modifyAvailableLayers(layers: Layer[]): void {
        const modifyAvailableLayersAction = () => {
            const availableLayerIds = layers.map(layer => layer.id);
            const availableFeatures = this.currentFeatures.filter(
                feature => availableLayerIds.indexOf(feature.layerId) > -1
            );

            return Promise.resolve(availableFeatures);
        };

        this.actionsQueue.next(defer(modifyAvailableLayersAction));
    }

    // set layers filter (note: we no longer remove features which are in non-visible layers)
    public modifyFilterLayers(layers: Layer[]): void {
        const modifyFilterLayersAction = () => {
            this.currentFilterLayers = layers;
            // Added for fixing bug for updating selected feature color on layerColor change
            const layerColorsObj: { [key: string]: string } = {};
            layers.forEach(layer => {
                layerColorsObj[layer.id] = layer.color;
            });
            const currentFeatures = this.currentFeatures.map(feature => {
                const featureCopy = _.cloneDeep(feature);
                featureCopy.colorKey = layerColorsObj[featureCopy.layerId];
                return featureCopy;
            });
            return Promise.resolve(currentFeatures);
        };
        this.actionsQueue.next(defer(modifyFilterLayersAction));
    }

    // replace selection
    public replace(selectionCriteria: SelectionCriteria, callBack?: (features: Feature[]) => void): void {
        const replaceFeaturesAction = (): Promise<any> =>
            this.getFeatures(selectionCriteria, this.currentFilterLayers)
                .then(
                    newFeatures =>
                        // The result of replace is simply the selected features
                        newFeatures,
                    () =>
                        // On error return empty set
                        []
                )
                .then(features => {
                    if (callBack) {
                        callBack(features);
                    }
                    return features;
                });
        this.actionsQueue.next(defer(replaceFeaturesAction));
    }

    // add to selection
    public add(
        selectionCriteria: SelectionCriteria,
        callBack?: (features: Feature[], selectionCriteriaFeatures: Feature[]) => void
    ): void {
        const addFeaturesAction = (): Promise<any> => {
            let selectionCriteriaFeatures: Feature[] = [];
            return this.getFeatures(selectionCriteria, this.currentFilterLayers)
                .then(
                    newFeatures => {
                        selectionCriteriaFeatures = newFeatures;
                        // The result of add is the current features with the new selected ones merged
                        const selectedFeatures = CloneUtils.cloneDeep(this.currentFeatures);
                        const selectedFeaturesIds = selectedFeatures.map(feature => feature.id);

                        newFeatures.forEach(newFeature => {
                            const index = selectedFeaturesIds.indexOf(newFeature.id);
                            if (index >= 0) {
                                selectedFeatures[index] = newFeature;
                            } else {
                                selectedFeatures.push(newFeature);
                            }
                        });

                        return selectedFeatures;
                    },
                    () =>
                        // On error return empty set
                        []
                )
                .then(features => {
                    if (callBack) {
                        callBack(features, selectionCriteriaFeatures);
                    }
                    return features;
                });
        };
        this.actionsQueue.next(defer(addFeaturesAction));
    }

    // remove from selection
    public remove(
        selectionCriteria: SelectionCriteria,
        callBack?: (features: Feature[], selectionCriteriaFeatureIds: string[]) => void
    ): void {
        const removeFeaturesAction = (): Promise<any> => {
            let selectionCriteriaFeatureIds: string[] = [];
            return this.getFeatureIds(selectionCriteria, this.currentFilterLayers)
                .then(
                    doomedIds => {
                        selectionCriteriaFeatureIds = doomedIds;
                        // The result of remove is the current features with the selected ones filtered out
                        const remainingFeatures = this.currentFeatures.filter(f => doomedIds.indexOf(f.id) === -1);
                        return remainingFeatures;
                    },
                    () =>
                        // On error return empty set
                        []
                )
                .then(features => {
                    if (callBack) {
                        callBack(features, selectionCriteriaFeatureIds);
                    }
                    return features;
                });
        };
        this.actionsQueue.next(defer(removeFeaturesAction));
    }

    public modifyFilters(newFeatureFilter: FeatureFilter): void {
        this.featureFilter = newFeatureFilter;
    }

    public modifyTasks(tasks: Task[]): void {
        this.selectedFeatureIds = [];
        tasks.forEach(task => {
            task.features = task.features || [];
            const featureIds = task.features.map(feature => feature.featureId);
            this.selectedFeatureIds = this.selectedFeatureIds.concat(featureIds);
        });
    }

    private getFeatures(selectionCriteria: SelectionCriteria, filterLayers: Layer[]): Promise<Feature[]> {
        return this.getFeaturesOrFeatureIds(selectionCriteria, filterLayers, false) as Promise<Feature[]>;
    }

    private getFeatureIds(selectionCriteria: SelectionCriteria, filterLayers: Layer[]): Promise<string[]> {
        return this.getFeaturesOrFeatureIds(selectionCriteria, filterLayers, true) as Promise<string[]>;
    }

    private getFeaturesOrFeatureIds(
        selectionCriteria: SelectionCriteria,
        filterLayers: Layer[],
        returnFeatureIds: boolean
    ): Promise<string[] | Feature[]> {
        if (selectionCriteria != null) {
            this.showNotification();
        }

        const conditionallyConvertFeaturesToFeatureIds = (features: Feature[]): string[] | Feature[] =>
            returnFeatureIds ? features.map(feature => feature.id) : features;

        let promise: Promise<any>;

        if (this.isEmpty(selectionCriteria)) {
            promise = this.getResultFrom([]);
        } else if (this.isBounds(selectionCriteria)) {
            promise = this.getFeaturesByBounds(filterLayers, selectionCriteria as L.LatLngBounds | L.Polygon).then(
                conditionallyConvertFeaturesToFeatureIds
            );
        } else if (this.isArrayOfFeatures(selectionCriteria)) {
            promise = this.getResultFrom(selectionCriteria).then(conditionallyConvertFeaturesToFeatureIds);
        } else if (this.isFeature(selectionCriteria)) {
            promise = this.getResultFrom([selectionCriteria]).then(conditionallyConvertFeaturesToFeatureIds);
        } else if (this.isArrayOfFeatureIds(selectionCriteria)) {
            promise = returnFeatureIds
                ? this.getResultFrom(selectionCriteria)
                : this.getFeaturesByFeatureIds(filterLayers, selectionCriteria as string[]);
        } else if (this.isFeatureId(selectionCriteria)) {
            promise = returnFeatureIds
                ? this.getResultFrom([selectionCriteria])
                : this.getFeaturesByFeatureIds(filterLayers, [selectionCriteria as string]);
        } else if (selectionCriteria instanceof TodoSelectionCriteria) {
            promise = this.getFeaturesByTodo(filterLayers, selectionCriteria as TodoSelectionCriteria).then(
                conditionallyConvertFeaturesToFeatureIds
            );
        } else if (selectionCriteria instanceof GeometryTypeSelectionCriteria) {
            promise = this.getFeaturesByGeometryType(selectionCriteria as GeometryTypeSelectionCriteria).then(
                conditionallyConvertFeaturesToFeatureIds
            );
        } else {
            // Any other value is effectively "empty"
            promise = this.getResultFrom([]);
        }

        return promise.then(
            result => {
                this.clearNotification();
                return result;
            },
            () => []
        );
    }

    private isEmpty(selectionCriteria: SelectionCriteria): boolean {
        return selectionCriteria === null || selectionCriteria === undefined;
    }

    private isBounds(selectionCriteria: SelectionCriteria): boolean {
        return selectionCriteria instanceof L.LatLngBounds || selectionCriteria instanceof L.Polygon;
    }

    private isArrayOfFeatures(selectionCriteria: SelectionCriteria): boolean {
        return (
            ArrayUtils.isArray(selectionCriteria) &&
            ((selectionCriteria as any).length === 0 || (selectionCriteria as any)[0] instanceof Feature)
        );
    }

    private isArrayOfFeatureIds(selectionCriteria: SelectionCriteria): boolean {
        return (
            ArrayUtils.isArray(selectionCriteria) &&
            ((selectionCriteria as any).length === 0 || StringUtils.isString((selectionCriteria as any)[0]))
        );
    }

    private isFeature(selectionCriteria: SelectionCriteria): boolean {
        return selectionCriteria instanceof Feature;
    }

    private isFeatureId(selectionCriteria: SelectionCriteria): boolean {
        return StringUtils.isString(selectionCriteria);
    }

    private getFeaturesByBounds(filterLayers: Layer[], bounds: L.LatLngBounds | L.Polygon): Promise<Feature[]> {
        return this.getFeaturesByBoundsAndOrFeatureIdsAndOrTodoIdsAndOrGeometryType(
            filterLayers,
            bounds,
            null,
            null,
            null
        );
    }

    private getFeaturesByFeatureIds(filterLayers: Layer[], featureIds: string[]): Promise<Feature[]> {
        this.selectedFeatureIds = featureIds;
        return this.getFeaturesByBoundsAndOrFeatureIdsAndOrTodoIdsAndOrGeometryType(null, null, null, null, null);
    }

    private getFeaturesByTodo(filterLayers: Layer[], todoSelectionCriteria: TodoSelectionCriteria): Promise<Feature[]> {
        return this.getFeaturesByBoundsAndOrFeatureIdsAndOrTodoIdsAndOrGeometryType(
            filterLayers,
            null,
            todoSelectionCriteria.featureIds,
            todoSelectionCriteria.todoIds,
            null
        );
    }

    private getFeaturesByGeometryType(
        geometryTypeSelectionCriteria: GeometryTypeSelectionCriteria
    ): Promise<Feature[]> {
        return this.getFeaturesByBoundsAndOrFeatureIdsAndOrTodoIdsAndOrGeometryType(
            geometryTypeSelectionCriteria.layers,
            null,
            null,
            null,
            geometryTypeSelectionCriteria.geometryType
        );
    }

    private async getFeaturesByBoundsAndOrFeatureIdsAndOrTodoIdsAndOrGeometryType(
        filterLayers: Layer[],
        bounds: L.LatLngBounds | L.Polygon,
        featureIds: string[],
        todoIds: string[],
        geometryType: string
    ): Promise<Feature[]> {
        const promises: Promise<Feature[]>[] = [];

        const project = this.projectStream.currentProjectStream.getValue();
        filterLayers = filterLayers || [];
        todoIds = todoIds || [];

        if (!project || (filterLayers.length === 0 && todoIds.length === 0 && this.selectedFeatureIds.length === 0)) {
            return Promise.resolve([]);
        }

        let fullFeatureFilter: FullFeatureFilter;

        fullFeatureFilter = this.buildFullFeatureFilter(filterLayers, bounds, featureIds, todoIds, geometryType);
        fullFeatureFilter.layers = fullFeatureFilter.layers || [];
        fullFeatureFilter.taskFeatures.todoIds = fullFeatureFilter.taskFeatures.todoIds || [];

        if (
            fullFeatureFilter.layers.length ||
            fullFeatureFilter.taskFeatures.todoIds.length ||
            fullFeatureFilter.selectedFeatureIds.length
        ) {
            // TODO: If a pagesize is not specified, it defaults to 500.
            promises.push(
                this.featureService.getFeatures(project.id, fullFeatureFilter, 10000).then(
                    features => features,
                    () => []
                )
            );
        }

        const layerFeatures = await Promise.all(promises);
        let allFeatures: Feature[] = [];
        layerFeatures.forEach(features => {
            allFeatures = allFeatures.concat(features);
        });
        return allFeatures;
    }

    private buildFullFeatureFilter(
        layers: Layer[],
        bounds: L.LatLngBounds | L.Polygon,
        featureIds: string[],
        todoIds: string[],
        geometryType: string
    ): FullFeatureFilter {
        let fullFeatureFilter = new FullFeatureFilter(this.featureFilter);

        if (layers) {
            fullFeatureFilter.layers = layers;
        }

        if (bounds) {
            fullFeatureFilter.bounds = bounds;
        }

        if (featureIds) {
            fullFeatureFilter.taskFeatures.featureIds = featureIds;
            fullFeatureFilter.layers = [];
        }

        if (todoIds.length) {
            fullFeatureFilter.taskFeatures.todoIds = todoIds;
            fullFeatureFilter.layers = [];
        }

        if (geometryType) {
            fullFeatureFilter.geometryType = geometryType;
        }

        if (this.selectedFeatureIds.length) {
            fullFeatureFilter.selectedFeatureIds = this.selectedFeatureIds;
        }
        return fullFeatureFilter;
    }

    // return promise that resolves to the input item
    private getResultFrom(input: any): Promise<any> {
        return Promise.resolve(input);
    }

    showNotification(): void {
        if (!this.hideNotifications && !this.activeToast) {
            this.activeToast = this.messaging.showInfo(this.translate.instant('TC.Common.UpdatingSelection'), null, {
                positionClass: 'toast-top-right'
            });
        }
    }

    clearNotification(): void {
        if (this.activeToast && this.activeToast.toastId) {
            this.messaging.clear(this.activeToast.toastId);
            this.activeToast = null;
        }
    }

    // clear entire selection
    clear(callBack?: (features: Feature[]) => void): void {
        this.replace(null, callBack);
    }
}
