import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { EnvironmentService } from 'src/app/shared/common/environment/environment.service';

@Injectable({
    providedIn: 'root'
})
export class ExportDownloaderService {
    constructor(private http: HttpClient, private env: EnvironmentService) {}

    getExport(projectId: string, jobId: string): Promise<{ status: number; downloadLink: string }> {
        return lastValueFrom(
            this.http.get<string>(this.env.apiUrl + '/projects/' + projectId + '/formexports/' + jobId + '/download', {
                headers: {
                    accept: 'application/json'
                },
                observe: 'response'
            })
        ).then(response => ({ status: response.status, downloadLink: response.body }));
    }
}
