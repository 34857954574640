import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ImportApiStatus, ImportStatusDict } from 'src/app/feature/import/import-file';
import { ImportPollingStatusService } from 'src/app/feature/import/import-polling-status.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { Project } from 'src/app/shared/map-data-services/project/project';

@Component({
    templateUrl: './upload-status.component.html',
    selector: 'upload-status'
})
export class MapViewerUploadStatusComponent implements OnInit, OnDestroy {
    public inProgressFiles: ImportStatusDict = {};
    public inProgressCount = 0;
    public showScroll = false;
    private destroyed = new Subject<void>();
    private project: Project;
    private currentMapWorkspace: MapWorkspace;

    constructor(
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private projectStream: ProjectStreamService,
        private importPollingStatusService: ImportPollingStatusService
    ) {}

    ngOnInit(): void {
        this.project = this.projectStream.getCurrentProject();
        this.currentMapWorkspace = this.mapWorkspacesStore.getCurrentMapWorkspace();

        this.importPollingStatusService.startImportPolling(
            this.project.id,
            this.currentMapWorkspace.id,
            this.destroyed
        );

        // watch all importState
        this.importPollingStatusService.importsState.subscribe((importState: ImportStatusDict) => {
            this.inProgressFiles = { ...importState };
            this.inProgressCount = Object.keys(this.inProgressFiles).length;
            this.showScroll = this.inProgressCount > 4 ? true : false;
        });
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    public isFileFailed(status: string): boolean {
        return status === ImportApiStatus.FAILED;
    }

    public isFileInProgress(status: string): boolean {
        return status === ImportApiStatus.INPROGRESS || status === ImportApiStatus.SUBMITTED;
    }

    public removeFileFromList(importId: string): void {
        this.importPollingStatusService.removeFromImportState(importId);
    }
}
