<header>
    <div class="template-header-title">
        <input
            #textinput
            class="input-text"
            [title]="template.name"
            type="text"
            ondrop="return false;"
            [(ngModel)]="template.name"
            [hidden]="!focused"
            (blur)="focused = false"
            maxlength="100"
        />
        <div class="template-title-text" [hidden]="focused" (click)="changeInputState()" [title]="template.name">
            {{ template.name }}
        </div>
    </div>
    <div class="template-header-buttons">
        <gsp-button
            [type]="ButtonType.Default"
            [disabled]="savingInProgress"
            (action)="closeTemplateEditor()"
            text="{{ 'cancel' | translate }}"
            id="cancel-template"
        >
        </gsp-button>
        <gsp-button
            [type]="ButtonType.Default"
            [asyncAction]="saveDraftAction"
            [disabled]="saveDisabled || savingInProgress"
            text="{{ 'save' | translate }}"
            id="save-template"
        >
        </gsp-button>
        <gsp-confirmation-button
            [type]="ButtonType.Primary"
            confirmText="{{ 'TC.Common.RepublishTemplate' | translate }}"
            [asyncAction]="publishAction"
            [disabled]="publishDisabled || savingInProgress"
            text="{{ 'TC.Common.Publish' | translate }}"
            *ngIf="template.lastPublishedVersion"
            id="republish-template"
        >
        </gsp-confirmation-button>

        <gsp-button
            [type]="ButtonType.Primary"
            [asyncAction]="publishAction"
            [disabled]="publishDisabled"
            text="{{ 'TC.Common.Publish' | translate }}"
            *ngIf="!template.lastPublishedVersion"
            id="publish-template"
        >
        </gsp-button>
    </div>
</header>
