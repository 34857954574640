import { RuleErrors } from './rule-errors';

export class Target {
    private errors: { commonErrorNos: number[] } = {
        commonErrorNos: []
    };

    private type: string;

    constructor(public targetUuid = '', type: string, public action = 'EnableTarget') {
        this.type = this.getFieldOrGroup(type);
    }

    // Check whether filed is group or not.(this is done because target can accept only Field/Group as type.
    getFieldOrGroup(type: string): string {
        return type === 'Group' ? 'Group' : 'Field';
    }

    // Get the target text which is displayed in sidebar.
    getActionTexts(): { name: string; value: string }[] {
        let actionTexts = [
            {
                name: 'TC.Common.Enable',
                value: 'EnableTarget'
            },
            {
                name: 'TC.Common.Disable',
                value: 'DisableTarget'
            }
        ];

        return actionTexts;
    }

    // returns the error message pertaining to this target.
    getErrorMessages(): string[] {
        let targetLevelErrorMessages: string[] = [];
        if (this.errors.commonErrorNos) {
            this.errors.commonErrorNos.forEach(commonErrorNo => {
                targetLevelErrorMessages.push(RuleErrors.errorMessages[commonErrorNo]);
            });
        }
        return targetLevelErrorMessages;
    }

    // Adds common error message Number to this target
    addCommonErrorMessageNo(errorNo: number): void {
        if (this.errors.commonErrorNos.indexOf(errorNo)) {
            this.errors.commonErrorNos.push(errorNo);
        }
    }

    // Removes common error message Number to this target
    removeCommonErrorMessageNo(errorNo: number): void {
        if (this.errors.commonErrorNos && this.errors.commonErrorNos.length) {
            this.errors.commonErrorNos.splice(this.errors.commonErrorNos.indexOf(errorNo));
        }
    }

    // Check whether the target has common error.
    isCommonError(): number {
        return this.errors.commonErrorNos && this.errors.commonErrorNos.length;
    }
}
