import { Color } from 'chartjs-plugin-datalabels/types/options';
import { GeometryTypes } from 'src/app/shared/common/utility/geometry-utils';
import { AutoFieldModelType, FieldType, LayoutFieldType } from 'src/app/shared/template-services/field';

import { TemplateEditorTabId } from './tabs-list/current-tab-stream.service';

export const TEMPLATE_EDITOR_CONFIG = 'template.editor.config';

export const templateEditorConfig = {
    tabs: {
        detail: {
            id: TemplateEditorTabId.DETAIL,
            label: 'TC.Common.Template',
            iconClass: 'icon_line_template',
            properties: [
                {
                    id: 'details',
                    label: 'TC.Common.TemplateDetails',
                    type: 'text',
                    maximum: 200
                },
                {
                    id: 'preview',
                    type: 'preview',
                    label: 'TC.Common.Preview'
                },
                {
                    id: 'geometrySettings',
                    type: 'group',
                    label: 'TC.Common.Geometry',
                    properties: [
                        {
                            id: 'type',
                            type: 'multiselect',
                            label: 'type',
                            default: 'Point',
                            items: [
                                {
                                    label: 'TC.Common.Point',
                                    value: 'Point',
                                    icoName: 'icon_solid_point'
                                },
                                {
                                    label: 'TC.Common.Line',
                                    value: 'Line',
                                    icoName: 'icon_solid_line'
                                },
                                {
                                    label: 'Common.Area',
                                    value: 'Area',
                                    icoName: 'icon_solid_poly'
                                }
                            ]
                        },
                        {
                            id: 'color',
                            type: 'colorPicker',
                            label: 'TC.Common.Color',
                            colorGrid: [[], [], []] as Color[][]
                        },
                        {
                            id: 'mustCollect',
                            type: 'boolean',
                            label: 'TCS.TemplateEditor.Tf.Tpl.MustCollectLabel'
                        }
                    ]
                },
                {
                    id: 'advanced',
                    type: 'group',
                    label: 'TCW_Advanced',
                    info: '<<ID>> of Language JSON',
                    properties: [
                        {
                            id: 'accuracy',
                            type: 'group',
                            label: 'TCS.TemplateEditor.Tf.Tpl.AccuraryTitle',
                            properties: [
                                {
                                    id: 'requiredAccuracy',
                                    label: 'TC.Common.RequiredAccuracy',
                                    type: 'text',
                                    minimum: 0,
                                    secondaryLabel: 'TC.Common.Units.Ft'
                                }
                            ]
                        },
                        {
                            id: 'templateVariants',
                            type: 'group',
                            label: 'TC.Common.TemplateVariants',
                            properties: [
                                {
                                    id: 'activateUpdate',
                                    label: 'TC.Common.ActivateUpdateOptions',
                                    type: 'boolean'
                                },
                                {
                                    id: 'enableSitevisionOptions',
                                    label: 'TCS.ModelDefinitionEditor.enableSitevisionOptions',
                                    type: 'boolean'
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        fields: {
            id: TemplateEditorTabId.FIELDS,
            label: 'TC.Common.Fields',
            iconClass: 'icon_line_fields',
            enableFieldConditions: {
                GeometryLength: {
                    condition: {
                        field: 'geometryType',
                        value: GeometryTypes.LINE
                    }
                },
                GeometryArea: {
                    condition: {
                        field: 'geometryType',
                        value: GeometryTypes.AREA
                    }
                },
                XYZ: {
                    condition: {
                        field: 'geometryType',
                        value: GeometryTypes.POINT
                    }
                }
            },
            fieldsGroup: [
                {
                    id: 'data',
                    label: 'data',
                    fields: [
                        FieldType.Text,
                        FieldType.Number,
                        FieldType.General,
                        FieldType.Length,
                        FieldType.Angle,
                        FieldType.Choice,
                        FieldType.CodedChoice,
                        FieldType.YesNo,
                        FieldType.Date,
                        FieldType.Barcode,
                        FieldType.Sensor
                    ]
                },
                {
                    id: 'images',
                    label: 'TC.Common.Images',
                    fields: [FieldType.Image, FieldType.Signature]
                },
                {
                    id: 'layout',
                    label: 'TC.Common.Layout',
                    fields: [LayoutFieldType.Group, LayoutFieldType.PageHeader]
                },
                {
                    id: 'autofields',
                    label: 'TC.Common.AutoFields',
                    fields: [
                        AutoFieldModelType.CollectedBy,
                        AutoFieldModelType.CreationDateTime,
                        AutoFieldModelType.UpdateDateTime,
                        AutoFieldModelType.EstimatedAccuracy,
                        AutoFieldModelType.DeviceType,
                        AutoFieldModelType.DeviceID,
                        AutoFieldModelType.CorrectionStatus,
                        AutoFieldModelType.CorrectionSource,
                        AutoFieldModelType.GeometryCaptureType,
                        AutoFieldModelType.PDOP,
                        AutoFieldModelType.HDOP,
                        AutoFieldModelType.GeometryLength,
                        AutoFieldModelType.GeometryArea,
                        AutoFieldModelType.Todo,
                        AutoFieldModelType.EstimatedVerticalPrecision,
                        AutoFieldModelType.Workspace,
                        AutoFieldModelType.Increment,
                        AutoFieldModelType.XYZ
                    ]
                }
            ]
        },
        rule: {
            id: TemplateEditorTabId.RULE,
            label: 'TC.Common.Rules',
            iconClass: 'icon_line_rules'
        },
        link: {
            id: TemplateEditorTabId.LINK,
            label: 'TC.Common.Links',
            iconClass: 'icon_line_link'
        }
    }
};
