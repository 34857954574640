import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { BehaviorSubject, combineLatest, interval, Subject } from 'rxjs';
import { distinctUntilChanged, first, takeUntil } from 'rxjs/operators';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { MapMenuCode } from 'src/app/feature/map-viewer/map-menus/map-menu-list';
import { MapService } from 'src/app/feature/map-viewer/map.service';
import { MenuService } from 'src/app/shared/common/layout/menu.service';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { Actor } from 'src/app/shared/user/actor';
import { v4 as uuidv4 } from 'uuid';

import { MapWorkspacePermissionType } from '../../map-data-services/mapWorkspace/map-workspace-permission';
import { CheckVisibilityService } from '../components/check-visibility.service';
import { MapWorkspacesStoreService } from '../current-map-workspaces/map-workspaces-store.service';
import { CurrentUserStreamService } from '../current-user/current-user-stream.service';
import { Task, TaskDTOStatus } from '../task/task';
import { TaskService } from '../task/task.service';

@Injectable({
    providedIn: 'root'
})
export class TasksStore {
    mapWorkspaceTasksLoadingStream = new BehaviorSubject<string[]>([]);
    mapWorkspaceTasksLoadedStream = new BehaviorSubject<boolean>(false);
    mapWorkspaceTasksStream = new BehaviorSubject<Task[]>([]);
    mapWorkspaceActiveTasksStream = new BehaviorSubject<Task[]>([]);
    mapWorkspaceClosedTasksStream = new BehaviorSubject<Task[]>([]);
    mapWorkspaceActiveTasksByTemplateSeriesIdStream = new BehaviorSubject<{ [templateId: string]: Task[] }>(null);

    refreshMapWorkspaceTasksStreams = new BehaviorSubject<Task[]>([]);

    savedVisibleTaskIds: string[];

    constructor(
        private mapService: MapService,
        private menuService: MenuService,
        private taskService: TaskService,
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private currentUserStream: CurrentUserStreamService,
        private checkVisibilityService: CheckVisibilityService,
        private messaging: MessagingService
    ) {
        combineLatest([
            mapWorkspacesStore.currentMapWorkspaceStream.pipe(distinctUntilChanged()),
            mapService.baseMapReadyStream.pipe(first((isMapReady: boolean) => isMapReady))
        ]).subscribe(([currentMapWorkspace, isMapReady]: [MapWorkspace, any]) => {
            if (
                isMapReady &&
                currentMapWorkspace &&
                !this.checkVisibilityService.restrictAccess(
                    [MapWorkspacePermissionType.FULL_ACCESS],
                    currentMapWorkspace
                )
            ) {
                this.loadCurrentWorkspaceTasks(currentMapWorkspace);
            }
        });

        combineLatest([this.mapWorkspaceActiveTasksStream, this.mapWorkspaceClosedTasksStream]).subscribe(
            ([activeTasks, closedtasks]: [Task[], Task[]]) => {
                const combinedTasks = activeTasks.concat(closedtasks);
                this.mapWorkspaceTasksStream.next(combinedTasks);
            }
        );

        // loading the current workspace tasks on opening todo menu
        menuService.activeMenusStream.pipe().subscribe(menus => {
            const currentMapWorkspace = mapWorkspacesStore.currentMapWorkspaceStream.getValue();
            if (
                menus.includes(MapMenuCode.TASKS) &&
                currentMapWorkspace &&
                !this.checkVisibilityService.restrictAccess(
                    [MapWorkspacePermissionType.FULL_ACCESS],
                    currentMapWorkspace
                )
            ) {
                this.loadCurrentWorkspaceTasks(currentMapWorkspace);
            }
        });
    }

    public async loadCurrentWorkspaceTasks(workspace: MapWorkspace): Promise<void> {
        this.saveVisibleTasks();

        this.mapWorkspaceTasksStream.next([]);

        if (workspace && workspace.id) {
            await Promise.all([
                this.loadCurrentWorkspaceTasksBySearch(TaskDTOStatus.ACTIVE, ''),
                this.loadCurrentWorkspaceTasksBySearch(TaskDTOStatus.CLOSED, '')
            ]);
        }
    }

    public async loadCurrentWorkspaceTasksBySearch(status: TaskDTOStatus, searchInput: string): Promise<void> {
        const loadingId = uuidv4();
        this.mapWorkspaceTasksLoadingStream.next([...this.mapWorkspaceTasksLoadingStream.getValue(), loadingId]);
        const currentMapWorkspace = this.mapWorkspacesStore.currentMapWorkspaceStream.getValue();
        try {
            const tasks = await this.taskService.getTasksBySearch(
                currentMapWorkspace.projectId,
                currentMapWorkspace.id,
                status,
                searchInput
            );
            return this.updateTasks(tasks, status, loadingId);
        } catch {
            return this.updateTasks([], status, loadingId);
        }
    }

    private updateTasks(tasks: Task[], status: TaskDTOStatus, loadingId: string): void {
        let mapWorkspaceTasks: Task[];
        mapWorkspaceTasks = tasks;
        this.restoreVisibleTasks(mapWorkspaceTasks);
        if (status === TaskDTOStatus.ACTIVE) {
            this.mapWorkspaceActiveTasksStream.next(mapWorkspaceTasks);
        } else if (status === TaskDTOStatus.CLOSED) {
            this.mapWorkspaceClosedTasksStream.next(mapWorkspaceTasks);
        } else {
            this.mapWorkspaceTasksStream.next(mapWorkspaceTasks);
        }
        this.mapWorkspaceActiveTasksByTemplateSeriesIdStream.next(this.groupTasksByTemplateId(mapWorkspaceTasks));
        this.mapWorkspaceTasksLoadingStream.next(
            this.mapWorkspaceTasksLoadingStream.getValue().filter(id => id !== loadingId)
        );
        this.mapWorkspaceTasksLoadedStream.next(true);
    }

    public isTasksLoaded(): Promise<boolean> {
        const timeout = 500;
        const stop = new Subject<boolean>();

        return new Promise((resolve, reject) => {
            interval(timeout)
                .pipe(takeUntil(stop))
                .subscribe(() => {
                    if (this.mapWorkspaceTasksLoadedStream.getValue()) {
                        resolve(true);
                        stop.next(true);
                    }
                });
        });
    }

    private saveVisibleTasks(): void {
        const tasks = this.mapWorkspaceTasksStream.getValue();

        this.savedVisibleTaskIds = tasks.filter(task => task.visible).map(task => task.id);
    }

    private restoreVisibleTasks(tasks: Task[]): void {
        tasks.forEach(task => {
            task.visible = this.savedVisibleTaskIds.indexOf(task.id) >= 0;
        });
    }

    public refreshMapWorkspaceTasks(): Promise<void> {
        const tasks = this.mapWorkspaceTasksStream.getValue();
        const visibleTasks = tasks.filter(task => task.visible);
        this.refreshMapWorkspaceTasksStreams.next(visibleTasks);
        return this.loadCurrentWorkspaceTasks(this.mapWorkspacesStore.getCurrentMapWorkspace());
    }

    public getTaskById(id: string): Promise<Task> {
        return this.taskService.getTaskById(this.mapWorkspacesStore.getCurrentMapWorkspace().projectId, id);
    }

    public createNewTask(): Promise<Task> {
        return Promise.resolve(new Task());
    }

    public async saveTask(task: Task): Promise<Task> {
        const t = await this.taskService.createOrUpdateTask(
            this.mapWorkspacesStore.getCurrentMapWorkspace().projectId,
            this.mapWorkspacesStore.getCurrentMapWorkspace(),
            task
        );
        return this.updateOrRemoveTaskInStore(t, false);
    }

    public closeTask(task: Task): Promise<Task> {
        return this.taskService
            .closeTask(
                this.mapWorkspacesStore.getCurrentMapWorkspace().projectId,
                this.currentUserStream.currentUser,
                task
            )
            .then(
                tsk => {
                    tsk.visible = false;
                    return this.updateOrRemoveTaskInStore(tsk, false);
                },
                error => {
                    this.messaging.showWarning(error.data.message || error.data);
                    return task;
                }
            );
    }

    public reopenTask(task: Task): Promise<Task> {
        return this.taskService.reopenTask(this.mapWorkspacesStore.getCurrentMapWorkspace().projectId, task).then(
            tsk => {
                tsk.visible = true;
                return this.updateOrRemoveTaskInStore(tsk, false);
            },
            error => {
                let errorMsg = error.data.message ? error.data.message : error.data;
                this.messaging.showWarning(errorMsg);
                return task;
            }
        );
    }

    public async deleteTask(task: Task): Promise<Task> {
        await this.taskService.deleteTask(this.mapWorkspacesStore.getCurrentMapWorkspace().projectId, task);
        return this.updateOrRemoveTaskInStore(task, true);
    }

    private updateOrRemoveTaskInStore(task: Task, remove: boolean): Task {
        let mapWorkspaceTasks = this.mapWorkspaceTasksStream.getValue();
        let updateTaskIndex = _.findIndex(mapWorkspaceTasks, mapWorkspaceTask => mapWorkspaceTask.id === task.id);
        if (updateTaskIndex > -1) {
            mapWorkspaceTasks.splice(updateTaskIndex, 1);
        }
        if (!remove) {
            mapWorkspaceTasks.unshift(task);
        }
        this.mapWorkspaceTasksStream.next(mapWorkspaceTasks);
        this.mapWorkspaceActiveTasksByTemplateSeriesIdStream.next(this.groupTasksByTemplateId(mapWorkspaceTasks));
        return task;
    }

    public getAllAssignees(): Actor[] {
        const projectId = this.mapWorkspacesStore.getCurrentMapWorkspace().projectId;
        const tmpGroups = JSON.parse(sessionStorage.getItem(`project-${projectId}-groups`) || '[]') as Actor[];
        const tmpUsers = JSON.parse(sessionStorage.getItem(`project-${projectId}-users`) || '[]') as Actor[];

        return [...tmpUsers, ...tmpGroups];
    }

    private groupTasksByTemplateId(tasks: Task[]): { [templateId: string]: Task[] } {
        let activeTaskByTemplateId: { [templateId: string]: Task[] } = {};
        tasks.forEach(task => {
            let featureGroup = _.groupBy(task.features, 'templateSeriesId');
            Object.keys(featureGroup).forEach(templateId => {
                if (activeTaskByTemplateId[templateId]) {
                    activeTaskByTemplateId[templateId].push(task);
                } else {
                    activeTaskByTemplateId[templateId] = [task];
                }
            });
        });
        return activeTaskByTemplateId;
    }
}
