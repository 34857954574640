import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AvailabilityTime, PeriodOfDay } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace.types';

import { ValueAccessorBase } from '../value-accessor-base';

@Component({
    selector: 'gsp-time-picker',
    templateUrl: './gsp-time-picker.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GspTimePickerComponent),
            multi: true
        }
    ]
})
export class GspTimePickerComponent extends ValueAccessorBase<AvailabilityTime> {
    @Input()
    disabled = false;

    @Output()
    ngModelChange = new EventEmitter<AvailabilityTime>();

    public timeOptions = [
        { id: '00:00', displayText: '00:00' },
        { id: '00:30', displayText: '00:30' },
        { id: '01:00', displayText: '01:00' },
        { id: '01:30', displayText: '01:30' },
        { id: '02:00', displayText: '02:00' },
        { id: '02:30', displayText: '02:30' },
        { id: '03:00', displayText: '03:00' },
        { id: '03:30', displayText: '03:30' },
        { id: '04:00', displayText: '04:00' },
        { id: '04:30', displayText: '04:30' },
        { id: '05:00', displayText: '05:00' },
        { id: '05:30', displayText: '05:30' },
        { id: '06:00', displayText: '06:00' },
        { id: '06:30', displayText: '06:30' },
        { id: '07:00', displayText: '07:00' },
        { id: '07:30', displayText: '07:30' },
        { id: '08:00', displayText: '08:00' },
        { id: '08:30', displayText: '08:30' },
        { id: '09:00', displayText: '09:00' },
        { id: '09:30', displayText: '09:30' },
        { id: '10:00', displayText: '10:00' },
        { id: '10:30', displayText: '10:30' },
        { id: '11:00', displayText: '11:00' },
        { id: '11:30', displayText: '11:30' },
        { id: '12:00', displayText: '12:00' },
        { id: '12:30', displayText: '12:30' }
    ];

    public periodOptions = [
        { id: PeriodOfDay.AM, displayText: 'AM' },
        { id: PeriodOfDay.PM, displayText: 'PM' }
    ];

    public timeZoneOptions = [
        {
            id: '-12:00',
            displayText: '(UTC-12:00) International Date Line West'
        },
        {
            id: '-11:00',
            displayText: '(UTC-11:00) Coordinated Universal Time-11'
        },
        {
            id: '-10:00',
            displayText: '(UTC-10:00) Hawaii'
        },
        {
            id: '-09:00',
            displayText: '(UTC-09:00) Alaska'
        },
        {
            id: '-08:00',
            displayText: '(UTC-08:00) Baja California, Pacific Time (US and Canada)'
        },
        {
            id: '-07:00',
            displayText: '(UTC-07:00) Chihuahua, La Paz, Mazatlan, Arizona, Mountain Time (US and Canada)'
        },
        {
            id: '-06:00',
            displayText:
                '(UTC-06:00) Central America, Central Time (US and Canada), Saskatchewan, Guadalajara, Mexico City, Monterey'
        },
        {
            id: '-05:00',
            displayText: '(UTC-05:00) Bogota, Lima, Quito, Indiana (East), Eastern Time (US and Canada)'
        },
        {
            id: '-04:30',
            displayText: '(UTC-04:30) Caracas'
        },
        {
            id: '-04:00',
            displayText:
                '(UTC-04:00) Atlantic Time (Canada), Asuncion, Georgetown, La Paz, Manaus, San Juan, Cuiaba, Santiago'
        },
        {
            id: '-03:30',
            displayText: '(UTC-03:30) Newfoundland'
        },
        {
            id: '-03:00',
            displayText: '(UTC-03:00) Brasilia, Greenland, Cayenne, Fortaleza, Buenos Aires, Montevideo'
        },
        {
            id: '-02:00',
            displayText: '(UTC-02:00) Coordinated Universal Time-2'
        },
        {
            id: '-01:00',
            displayText: '(UTC-01:00) Cape Verde, Azores'
        },
        {
            id: '00:00',
            displayText:
                '(UTC+00:00) Coordinated Universal Time, Casablanca, Monrovia, Reykjavik, Dublin, Edinburgh, Lisbon, London'
        },
        {
            id: '01:00',
            displayText: `(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna,
            Brussels, Copenhagen, Madrid, Paris, West Central Africa, Belgrade, Bratislava,
            Budapest, Ljubljana, Prague, Sarajevo, Skopje, Warsaw, Zagreb, Windhoek`
        },
        {
            id: '02:00',
            displayText: `(UTC+02:00) Athens, Bucharest, Istanbul, Helsinki, Kiev, Riga,
            Sofia, Tallinn, Vilnius, Cairo, Damascus, Amman, Harare, Pretoria, Jerusalem, Beirut`
        },
        {
            id: '03:00',
            displayText: '(UTC+03:00) Baghdad, Minsk, Kuwait, Riyadh, Nairobi'
        },
        {
            id: '03:30',
            displayText: '(UTC+03:30) Tehran'
        },
        {
            id: '04:00',
            displayText:
                '(UTC+04:00) Moscow, St. Petersburg, Volgograd, Tbilisi, Yerevan, Abu Dhabi, Muscat, Baku, Port Louis'
        },
        {
            id: '04:30',
            displayText: '(UTC+04:30) Kabul'
        },
        {
            id: '05:00',
            displayText: '(UTC+05:00) Tashkent, Islamabad, Karachi'
        },
        {
            id: '05:30',
            displayText: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi, Sri Jayewardenepura Kotte'
        },
        {
            id: '05:45',
            displayText: '(UTC+05:45) Kathmandu'
        },
        {
            id: '06:00',
            displayText: '(UTC+06:00) Astana, Dhaka, Yekaterinburg'
        },
        {
            id: '06:30',
            displayText: '(UTC+06:30) Yangon'
        },
        {
            id: '07:00',
            displayText: '(UTC+07:00) Bangkok, Hanoi, Jakarta, Novosibirsk'
        },
        {
            id: '08:00',
            displayText: `(UTC+08:00) Krasnoyarsk, Ulaanbaatar, Beijing, Chongqing, Hong Kong, Urumqi,
            Perth, Kuala Lumpur, Singapore, Taipei`
        },
        {
            id: '09:00',
            displayText: '(UTC+09:00) Irkutsk, Seoul, Osaka, Sapporo, Tokyo'
        },
        {
            id: '09:30',
            displayText: '(UTC+09:30) Darwin, Adelaide'
        },
        {
            id: '10:00',
            displayText: '(UTC+10:00) Hobart, Yakutsk, Brisbane, Guam, Port Moresby, Canberra, Melbourne, Sydney'
        },
        {
            id: '11:00',
            displayText: '(UTC+11:00) Vladivostok, Solomon Islands, New Caledonia'
        },
        {
            id: '12:00',
            displayText:
                '(UTC+12:00) Coordinated Universal Time+12, Fiji, Marshall Islands, Magadan, Auckland, Wellington'
        },
        {
            id: '13:00',
            displayText: "(UTC+13:00) Nuku'alofa, Samoa"
        }
    ];

    constructor() {
        super();
    }
}
