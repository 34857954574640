import { Component, Input, OnChanges } from '@angular/core';

@Component({
    templateUrl: './feature-with-no-template.component.html',
    selector: 'feature-with-no-template'
})
export class FeatureWithNoTemplateComponent implements OnChanges {
    @Input()
    properties: { [key: string]: any };

    isURLDict: { [key: string]: boolean } = {};

    ngOnChanges(): void {
        Object.keys(this.properties).forEach(key => {
            try {
                this.isURLDict[key] = Boolean(new URL(this.properties[key]));
            } catch {
                this.isURLDict[key] = false;
            }
        });
    }
}
