import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { GspWizardService, OnForward } from 'src/app/shared/common/components/gsp-wizard/gsp-wizard.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { CurrentUserStreamService } from 'src/app/shared/common/current-user/current-user-stream.service';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';

import { MainMenuStreamService } from '../../map-viewer/main-menu/main-menu-stream.service';
import { WorkspaceService } from '../workspace.service';

@Component({
    selector: 'workspace-offline-gnss-corrections',
    templateUrl: './workspace-offline-gnss-corrections.component.html'
})
export class WorkspaceOfflineGnssCorrectionsComponent implements OnInit, OnDestroy, OnForward {
    ButtonType = ButtonType;
    modifyWorkspace: MapWorkspace;
    sourceWorkspace: MapWorkspace;
    useSameCoordinateAsSource: boolean;
    shouldDuplicateTemplate = true;
    uiState = {
        backButton: { text: 'MapViewer.Generic.Previous', enabled: true, visible: true },
        forwardButton: { text: 'TC.Common.Next', enabled: true, visible: true },
        closeButton: { text: '', enabled: true, visible: true },
        cancelButton: { text: 'cancel', enabled: true, visible: true }
    };
    loading: boolean;
    workspace: MapWorkspace = null;
    popupHeading: string;
    popupMessage: string;
    popupButton: string;
    currentWorkspace: MapWorkspace;
    accessPointsAvailable: boolean;
    toggleLabel: string;
    offlineGNSSDescription: string;
    cannotEnableMessageKey: string;
    private destroyed = new Subject();

    constructor(
        private currentUserStream: CurrentUserStreamService,
        private wizardService: GspWizardService,
        private workspaceService: WorkspaceService,
        private mapWorkspaceStore: MapWorkspacesStoreService,
        private mainMenuStream: MainMenuStreamService,
        private projectStream: ProjectStreamService,
        private router: Router,
        private translate: TranslationService
    ) {}

    ngOnInit(): void {
        // Promise fix this error when detecting changes on parent component from child component : https://angular.io/errors/NG0100/
        Promise.resolve().then(() => {
            // A workaround for removing whitepsace - please check mockup on TCMAPS-3566
            this.wizardService.setOfflineGnssMode(true);
        });
        this.wizardService.setUI(this.uiState);
        this.setOfflineGNSSDescription();
        this.setToggleLabel();
        this.setCannotEnableMessageKey();

        this.modifyWorkspace = CloneUtils.cloneDeep(this.workspaceService.modifyWorkspace$?.getValue());
        this.sourceWorkspace = CloneUtils.cloneDeep(this.workspaceService.sourceWorkspace$?.getValue());
    }

    ngOnDestroy() {
        // set back to default value after displaying component
        this.wizardService.setOfflineGnssMode(false);
        this.destroyed.next(null);
    }

    public onToggled(enabled: boolean): void {
        this.modifyWorkspace.postProcessingOptions.enabled = enabled;
        this.setToggleLabel();
    }

    public onForward(): Promise<void> {
        return new Promise<void>(resolve => {
            this.workspaceService.modifyWorkspace$.next(this.modifyWorkspace);
            resolve();
        });
    }

    public setToggleLabel(): string {
        return (this.toggleLabel = this.modifyWorkspace?.postProcessingOptions?.enabled
            ? this.translate.instant('MapViewer.OfflineGNSS.Wizard.DisableLabel')
            : this.translate.instant('MapViewer.OfflineGNSS.Wizard.EnableLabel'));
    }

    private setOfflineGNSSDescription(): void {
        this.offlineGNSSDescription = this.translate.instant('MapViewer.OfflineGNSS.Wizard.Description');
    }

    private setCannotEnableMessageKey(): void {
        this.cannotEnableMessageKey = this.translate.instant('MapViewer.OfflineGNSS.Wizard.CannotEnable');
    }
}
