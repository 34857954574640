import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { EnvironmentService } from 'src/app/shared/common/environment/environment.service';

import { GeoLayer } from '../layer/layer';
import { GeoWorkspace, GeoWorkspaces } from '../mapWorkspace/map-workspace.types';

@Injectable({
    providedIn: 'root'
})
export class RelationshipService {
    constructor(private http: HttpClient, private env: EnvironmentService) {}

    public attachToMapWorkspace(
        projectId: string,
        workspaceId: string,
        relationshipProperties: { [key: string]: string[] }
    ): Promise<GeoWorkspace> {
        return lastValueFrom(
            this.http.patch<GeoWorkspace>(
                this.env.apiUrl + '/projects/' + projectId + '/spatialWorkspaces/' + workspaceId + '/relationships',
                relationshipProperties
            )
        ).then(
            response => response,
            async error => {
                if (error.status === 412) {
                    await lastValueFrom(
                        this.http.get(this.env.apiUrl + '/projects/' + projectId + '/spatialWorkspaces/' + workspaceId)
                    );
                    return lastValueFrom(
                        this.http.patch<GeoWorkspace>(
                            this.env.apiUrl +
                                '/projects/' +
                                projectId +
                                '/spatialWorkspaces/' +
                                workspaceId +
                                '/relationships',
                            relationshipProperties
                        )
                    );
                }
            }
        );
    }

    public detachFromMapWorkspace(
        projectId: string,
        workspaceId: string,
        relationshipProperties: any
    ): Promise<GeoWorkspaces> {
        return lastValueFrom(
            this.http.delete(
                this.env.apiUrl +
                    '/projects/' +
                    projectId +
                    '/spatialWorkspaces/' +
                    workspaceId +
                    '/relationships?relationshipType=' +
                    relationshipProperties.relationshipType +
                    '&childId=' +
                    relationshipProperties.childId
            )
        );
    }

    public createLayerWorkspaceRelationship(
        projectId: string,
        relationshipProperties: { [key: string]: string | boolean }
    ): Promise<GeoLayer> {
        return lastValueFrom(
            this.http.post<GeoLayer>(
                this.env.apiUrl + '/projects/' + projectId + '/layers/relationships',
                relationshipProperties
            )
        );
    }

    public updateLayerWorkspaceRelationship(
        projectId: string,
        relationshipProperties: { [key: string]: string | boolean }
    ): Promise<GeoLayer> {
        return lastValueFrom(
            this.http.patch<GeoLayer>(
                this.env.apiUrl + '/projects/' + projectId + '/layers/relationships',
                relationshipProperties
            )
        );
    }
}
