<div class="panel-controls">
    <div class="panel-buttons">
        <gsp-button
            [type]="ButtonType.TextIcon"
            [asyncAction]="defaultCreateMethod"
            id="createMapWorkspaceButton"
            iconClass="i32 icon_line_plus"
            [text]="'TCS.Workspace.Create' | translate"
        ></gsp-button>
        <gsp-button
            *ngIf="currentUserIsAdmin"
            [type]="ButtonType.TextIcon"
            [asyncAction]="createFromFileMethod"
            id="createMapWorkspaceFromFileButton"
            iconClass="i32 icon_solid_paper_plus"
            [text]="'TC.Common.CreateFromTemplate' | translate"
        ></gsp-button>
    </div>

    <gsp-text
        [type]="GspTextType.GENERIC"
        [id]="'search-workspace'"
        inputClass="default"
        (keyupEvent)="triggerScrollToTop(workspaceFilter)"
        placeholder="{{ 'TCS.SearchMapWorkspace' | translate }}"
        [(ngModel)]="workspaceFilter"
        (ngModelChange)="filterWorkspaces()"
    ></gsp-text>
</div>

<mat-tab-group>
    <mat-tab label="{{ 'TC.Common.Active' | translate }}">
        <workspace-list
            [contextMenuItems]="contextMenuItems"
            [currentWorkspace]="currentWorkspace"
            [loading]="loading"
            [noWorkspacesMessage]="'TCS.Workspace.NoWorkspaceFound' | translate"
            [scrollToTop]="scrollToTop"
            [workspaces]="filteredWorkspaces"
            (changeWorkspace)="changeWorkspace($event)"
        ></workspace-list>
    </mat-tab>
    <mat-tab label="{{ 'MapViewer.AppTopBar.WorkspaceStatus.Archived' | translate }}">
        <workspace-list
            [contextMenuItems]="contextMenuItems"
            [currentWorkspace]="currentWorkspace"
            [loading]="loading"
            [noWorkspacesMessage]="'TCS.Workspace.NoArchivedWorkspaces' | translate"
            [scrollToTop]="scrollToTop"
            [workspaces]="archivedWorkspaces"
            (changeWorkspace)="changeWorkspace($event)"
        ></workspace-list>
    </mat-tab>
</mat-tab-group>
