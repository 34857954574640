<div class="chkbox-div">
    <input
        [(ngModel)]="value"
        [disabled]="disabled"
        (ngModelChange)="valueChange.emit(value)"
        class="chkbox"
        [ngClass]="{ disabled: disabled }"
        id="{{ id }}"
        type="checkbox"
    /><label class="text-overflow" for="{{ id }}" title="{{ label }}"
        >{{ label }}<gsp-help-text *ngIf="helpText" [text]="helpText"></gsp-help-text
    ></label>
    <ng-content></ng-content>
</div>
