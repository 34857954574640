<nav>
    <ul>
        <li
            class="{{ menu.menuClass }}"
            title="{{ menu.displayName | capitalize: false }}"
            tooltipLocation="right"
            [ngClass]="{
                active: menu.selected,
                open: menu.selected,
                'active-filter': highlightFilter
            }"
            *ngFor="let menu of getGroupMenuItems(1)"
        >
            <ng-container *checkVisibility="{ visibleModes: [MapWorkspacePermissionType.FULL_ACCESS] }">
                <gsp-button
                    [type]="ButtonType.Icon"
                    id="{{ menu.id }}"
                    *ngIf="menu.visibleMode === MapWorkspacePermissionType.FULL_ACCESS"
                    iconClass="{{ menu.iconClass }} i32"
                    (action)="activateMenu(menu)"
                ></gsp-button>
            </ng-container>
            <gsp-button
                [type]="ButtonType.Icon"
                id="{{ menu.id }}"
                *ngIf="menu.visibleMode !== MapWorkspacePermissionType.FULL_ACCESS"
                iconClass="{{ menu.iconClass }} i32"
                (action)="activateMenu(menu)"
            ></gsp-button>
        </li>
    </ul>

    <ul>
        <li
            class="{{ menu.menuClass }}"
            title="{{ menu.displayName | translate }}"
            tooltipLocation="right"
            [ngClass]="{
                active: menu.selected,
                open: menu.selected,
                'active-filter': highlightFilter
            }"
            *ngFor="let menu of getGroupMenuItems(2)"
        >
            <gsp-button
                id="{{ menu.id }}"
                [type]="ButtonType.Icon"
                iconClass="{{ menu.iconClass }} i32"
                (action)="activateMenu(menu)"
            ></gsp-button>
        </li>
    </ul>
</nav>
