<div
    class="update-options-icons"
    *ngIf="template.useSeparateUpdateWorkflow || template.enableSitevisionOptions"
    [ngClass]="{
        active: isSelected,
        'disable-icons':
            field.pageHeaderState &&
            (field.pageHeaderState.readOnlyForUpdate == true || field.pageHeaderState.requiredForUpdate == true),
        'rules-tab': currentTab.id === TemplateEditorTabId.RULE
    }"
>
    <div
        class="field-required inactive"
        *ngIf="!isSelected && field.requiredForUpdate && !isAutoField && template.useSeparateUpdateWorkflow"
    >
        <i
            [ngClass]="{
                'icon-24-terraflex-required':
                    field.requiredForUpdate == true && currentTab.id !== TemplateEditorTabId.RULE,
                'icon-24-terraflex-not-required':
                    field.requiredForUpdate !== true || currentTab.id === TemplateEditorTabId.RULE
            }"
            class="i24 button-tooltip"
            (click)="toggleRequiredForUpdate()"
            [title]="'TC.Common.RequredForDataUpdate' | translate"
        >
            &nbsp;</i
        >
    </div>
    <div
        class="field-read-only inactive"
        *ngIf="!isSelected && field.readOnlyForUpdate && !isAutoField && template.useSeparateUpdateWorkflow"
    >
        <i
            [ngClass]="{
                'icon-24-terraflex-read-only':
                    field.readOnlyForUpdate == true && currentTab.id !== TemplateEditorTabId.RULE,
                'icon-24-terraflex-editable':
                    field.readOnlyForUpdate !== true || currentTab.id === TemplateEditorTabId.RULE
            }"
            class="i24 button-tooltip"
            (click)="toggleReadOnlyForUpdate()"
            [title]="'TC.Common.ReadOnlyUpdate' | translate"
        >
            &nbsp;</i
        >
    </div>
    <div
        class="field-sitevision-visible"
        *ngIf="
            !isSelected &&
            field.visibleForSitevision &&
            currentTab.id !== TemplateEditorTabId.RULE &&
            template.enableSitevisionOptions
        "
    >
        <i
            [ngClass]="{
                'icon-24-sitevision-visible': field.visibleForSitevision && currentTab.id !== TemplateEditorTabId.RULE,
                'icon-24-sitevision-hidden': !field.visibleForSitevision && currentTab.id !== TemplateEditorTabId.RULE
            }"
            class="i24 button-tooltip"
            (click)="toggleVisibleForSitevision()"
            title="{{ 'TCS.ModelDefinitionEditor.sitevisionVisibility' | translate }}"
        >
            &nbsp;</i
        >
    </div>
    <div class="field-required" *ngIf="isSelected && !isAutoField && template.useSeparateUpdateWorkflow">
        <i
            [ngClass]="{
                'icon-24-terraflex-required':
                    field.requiredForUpdate == true && currentTab.id !== TemplateEditorTabId.RULE,
                'icon-24-terraflex-not-required':
                    field.requiredForUpdate !== true || currentTab.id === TemplateEditorTabId.RULE
            }"
            class="i24 button-tooltip"
            (click)="toggleRequiredForUpdate()"
            [title]="'TC.Common.RequredForDataUpdate' | translate"
        >
            &nbsp;</i
        >
    </div>
    <div class="field-read-only" *ngIf="isSelected && !isAutoField && template.useSeparateUpdateWorkflow">
        <i
            [ngClass]="{
                'icon-24-terraflex-read-only':
                    field.readOnlyForUpdate == true && currentTab.id !== TemplateEditorTabId.RULE,
                'icon-24-terraflex-editable':
                    field.readOnlyForUpdate !== true || currentTab.id === TemplateEditorTabId.RULE
            }"
            class="i24 button-tooltip"
            (click)="toggleReadOnlyForUpdate()"
            [title]="'TC.Common.ReadOnlyUpdate' | translate"
        >
            &nbsp;</i
        >
    </div>
    <div class="field-sitevision-visible" *ngIf="isSelected && !isAutoField && template.enableSitevisionOptions">
        <i
            [ngClass]="{
                'icon-24-sitevision-visible': field.visibleForSitevision && currentTab.id !== TemplateEditorTabId.RULE,
                'icon-24-sitevision-hidden': !field.visibleForSitevision && currentTab.id !== TemplateEditorTabId.RULE
            }"
            class="i24 button-tooltip"
            (click)="toggleVisibleForSitevision()"
            title="{{ 'TCS.ModelDefinitionEditor.sitevisionVisibility' | translate }}"
        >
            &nbsp;</i
        >
    </div>
</div>
