import { Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Template } from 'src/app/shared/template-services/template';

import { RuleErrorsStreamService } from '../../rules-panel/rule-errors-stream.service';

@Component({
    selector: 'composer-warnings',
    templateUrl: './composer-warnings.component.html'
})
export class ComposerWarningsComponent implements OnDestroy {
    private destroyed = new Subject<void>();

    @Input()
    template: Template;

    rulesErrorMessages: any[] = [];

    constructor(ruleErrorsStream: RuleErrorsStreamService) {
        ruleErrorsStream.ruleErrorsStream.pipe(takeUntil(this.destroyed)).subscribe(errors => {
            this.rulesErrorMessages = errors;
        });
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }
}
