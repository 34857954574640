import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import getPkce from 'oauth-pkce';
import { lastValueFrom } from 'rxjs';
import { EnvironmentService } from 'src/app/shared/common/environment/environment.service';
import { GeneralUtils } from 'src/app/shared/common/utility/general-utils';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';

import { GspLoggerService } from 'src/app/log-handler.service';
import { LoaderStreamService } from 'src/app/shared/common/loader/loader-stream.service';
import { MessagingService } from '../messaging/messaging.service';
import { TranslationService } from '../translation/translation.service';
import { AuthenticationUtil } from './authentication.util';
import { IdentityService } from './identity.service';
import { ShareToken } from './share-token';

export const ACCESS_TOKEN_KEY = 'tpass-access-token';
export const CONNECT_LINK_KEY = 'init-connect-link';
export const CODE_VERIFIER_KEY = 'code-verifier';
export const TOKEN_REVOKED_KEY = 'token-revoked';
export const LAST_TID_REDIRECT_KEY = 'last-tid-redirect-utc';

export interface TokenResponse {
    token_type: string;
    expires_in: number;
    refresh_token: string;
    id_token: string;
    access_token: string;
    scope?: string;
}

export enum OAuthGrantType {
    AUTHORIZATION_CODE = 'authorization_code',
    REFRESH_TOKEN = 'refresh_token'
}

@Injectable()
export class AuthenticationService {
    private codeVerifier: string;
    private refreshToken: string;
    private tokenExpiryDate: number;
    private connectWebUrl = environment.connectWebUrl;
    private errorOnLogout: Error = null;

    constructor(
        private http: HttpClient,
        private environmentService: EnvironmentService,
        private identityService: IdentityService,
        private loaderStreamService: LoaderStreamService,
        private messagingService: MessagingService,
        private translate: TranslationService,
        private gspLoggerService: GspLoggerService
    ) {
        window.addEventListener('storage', event => {
            if (event.key === 'logout') {
                this.revokeTokensAndLogoutConnect();
            }
        });
    }

    public login(accessToken: string, expiryDate?: number, refreshToken?: string): void {
        sessionStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
        if (expiryDate && refreshToken) {
            this.tokenExpiryDate = expiryDate;
            this.refreshToken = refreshToken;
        }
    }

    public logout(): void {
        // show loader in app-root level
        this.loaderStreamService.isLoading$.next(true);

        // check internet connection
        if (!navigator.onLine) {
            this.loaderStreamService.isLoading$.next(false);
            this.messagingService.showError(this.translate.instant('TC.Online.LogOut.LogoutDeniedMessage'));
            return;
        }

        this.revokeTokensAndLogoutConnect();

        // trigger logout event on other tabs too - see event listener in constructor
        localStorage.setItem('logout', 'logout');
        localStorage.removeItem('logout');
    }

    private revokeTokensAndLogoutConnect(): void {
        // make sure to revoke access token in Maps API level
        this.revokeAccessToken()
            .then(() => {
                // clear all storage - to prevent any further access
                this.clearSessionAndTokens();
                this.errorOnLogout = null;

                // logout using connect - to prevent refresh token usage
                this.logoutConnect();
            })
            .catch(error => {
                // catch error and log to datadog
                this.errorOnLogout = error;
                this.loaderStreamService.isLoading$.next(false);
                this.gspLoggerService.error(error.message);
                this.messagingService.showError(this.translate.instant('TC.Online.LogOut.MapsAPIError'));
            });
    }

    private logoutConnect(): void {
        window.location.href = `${this.connectWebUrl}/logout`;
    }

    public clearSessionAndTokens(): void {
        sessionStorage.clear();
        this.refreshToken = null;
        this.codeVerifier = null;
        this.tokenExpiryDate = null;
    }

    public async getLoginUrl(state?: string): Promise<string> {
        const { verifier, challenge } = await new Promise<{ verifier: string; challenge: string }>(resolve => {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            getPkce(43, (error, { verifier, challenge }) => {
                if (error) {
                    throw error;
                }
                resolve({ verifier, challenge });
            });
        });
        window.sessionStorage.setItem(CODE_VERIFIER_KEY, verifier);
        if (!state) {
            state = encodeURIComponent(window.location.href);
        }
        window.sessionStorage.setItem(LAST_TID_REDIRECT_KEY, String(Date.now()));
        let url =
            this.identityService.identityUrls.authorization_endpoint +
            '?scope=openid ' +
            environment.mapsApplicationName +
            ' ' +
            environment.processingOrchestratorApplicationName +
            '&state=' +
            state +
            '&response_type=code&redirect_uri=' +
            environment.redirectURI +
            '&client_id=' +
            environment.consumerKey +
            '&code_challenge=' +
            challenge +
            '&code_challenge_method=S256' +
            '&nonce=' +
            uuidv4();

        if (!!window['Cypress' as any]) {
            url = url + '&identity_provider=trimble_automation';
        }

        return url;
    }

    public async getTokensFromAuthCode(code: string): Promise<TokenResponse> {
        const { verifier, challenge } = await new Promise<{ verifier: string; challenge: string }>(resolve => {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            getPkce(43, (error, { verifier, challenge }) => {
                if (error) {
                    throw error;
                }
                resolve({ verifier, challenge });
            });
        });
        const currentVerifier = window.sessionStorage.getItem(CODE_VERIFIER_KEY);
        window.sessionStorage.removeItem(CODE_VERIFIER_KEY);
        this.codeVerifier = verifier;
        const params: { [key: string]: string } = {
            grant_type: OAuthGrantType.AUTHORIZATION_CODE,
            code,
            tenantDomain: 'Trimble.com',
            redirect_uri: environment.redirectURI,
            client_id: environment.consumerKey,
            code_verifier: currentVerifier,
            code_challenge: challenge,
            code_challenge_method: 'S256'
        };
        const formBody = AuthenticationUtil.constructUrlEncodedParams(params);
        return this.getAuthTokens(formBody);
    }

    public async refreshTokens(): Promise<TokenResponse> {
        const { verifier, challenge } = await new Promise<{ verifier: string; challenge: string }>(resolve => {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            getPkce(43, (error, { verifier, challenge }) => {
                if (error) {
                    throw error;
                }
                resolve({ verifier, challenge });
            });
        });
        const params: { [key: string]: string } = {
            grant_type: OAuthGrantType.REFRESH_TOKEN,
            refresh_token: this.refreshToken,
            client_id: environment.consumerKey,
            code_verifier: this.codeVerifier,
            code_challenge: challenge,
            code_challenge_method: 'S256'
        };
        const formBody = AuthenticationUtil.constructUrlEncodedParams(params);
        this.codeVerifier = verifier;
        return this.getAuthTokens(formBody);
    }

    private getAuthTokens(body: string): Promise<TokenResponse> {
        return lastValueFrom(
            this.http.post(this.identityService.identityUrls.token_endpoint, body, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    NoIntercept: 'true'
                }
            })
        ) as Promise<TokenResponse>;
    }

    public shouldRefreshTokens(): boolean {
        return !!(
            this.refreshToken &&
            this.codeVerifier &&
            this.tokenExpiryDate &&
            this.tokenExpiryDate - Date.now() < 60000
        );
    }

    public getTCAccessTokenUsingSToken(url: string, shareToken: string): Promise<ShareToken> {
        return lastValueFrom(this.http.get(url + '/shares/token/' + shareToken)) as Promise<ShareToken>;
    }

    public isAuthenticated(): boolean {
        const accessToken = sessionStorage.getItem(ACCESS_TOKEN_KEY);
        return GeneralUtils.isNullUndefinedOrNaN(accessToken) ? false : true;
    }

    public async revokeAccessToken(): Promise<boolean> {
        // True if successful, false if failed
        const token = window.sessionStorage.getItem(ACCESS_TOKEN_KEY);
        window.sessionStorage.removeItem(ACCESS_TOKEN_KEY);
        return lastValueFrom(this.http.post(this.environmentService.apiUrl + '/auth/revoke?token=' + token, null)).then(
            revoked => {
                if (revoked) {
                    window.sessionStorage.setItem(TOKEN_REVOKED_KEY, 'true');
                }
                return revoked;
            }
        ) as Promise<boolean>;
    }
}
