export const Coordinates: { [key: string]: { latitude: number; longitude: number; zoom: number } } = {
    northAmerica: {
        latitude: 36.4566360115962,
        longitude: -98.78906250000001,
        zoom: 3
    },
    europe: {
        latitude: 52.37559917665913,
        longitude: 9.492187500000002,
        zoom: 4
    },
    asia: {
        latitude: 12.726084296948196,
        longitude: 100.50483,
        zoom: 4
    },
    australia: {
        latitude: -24.846565348219745,
        longitude: 133.24218750000003,
        zoom: 3
    }
};
