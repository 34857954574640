<modus-file-dropzone
    #fileDropzone
    *ngIf="showDropzone"
    [dropzoneHeight]="height"
    [dropzoneWidth]="width"
    [label]="fileDropLabel"
    [multiple]="allowMultipleFiles"
    [description]="fileDropDescription"
    [maxFileCount]="maxFileCount"
    [attr.accept-file-types]="acceptedFileTypes"
    (files)="fileDropped($event)"
>
</modus-file-dropzone>
