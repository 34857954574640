import { animate, animation, query, style, useAnimation } from '@angular/animations';

export const MAIN_MENU_ANIMATION_DURATION = 300;

export const sidePanelTransitionInAnimation = animation([
    style({
        transform: 'translateX(100%)'
    }),
    animate(200)
]);

export const sidePanelTransitionOutAnimation = animation([animate(200, style({ transform: 'translateX(100%)' }))]);

export const centerDialogOpenAnimation = animation([
    style({ opacity: 0 }),
    animate('500ms ease-in', style({ opacity: 1 }))
]);

export const centerDialogCloseAnimation = animation([
    style({ opacity: 1 }),
    animate('500ms ease-out', style({ opacity: 0 }))
]);

export const centerDialogEnterTransition = query(':enter > * > *', [useAnimation(centerDialogOpenAnimation)], {
    optional: true
});

export const centerDialogCloseTransition = query(':leave > * > *', [useAnimation(centerDialogCloseAnimation)], {
    optional: true
});

export const mainMenuOpenAnimation = animation([
    style({
        transform: 'translateX(-100%)'
    }),
    animate(MAIN_MENU_ANIMATION_DURATION)
]);

export const mainMenuCloseAnimation = animation([
    animate(MAIN_MENU_ANIMATION_DURATION),
    style({
        transform: 'translateX(-100%)'
    })
]);
