import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import { ActiveFeatureStreamsService } from 'src/app/shared/common/current-features/active-feature-streams.service';
import { MenuService } from 'src/app/shared/common/layout/menu.service';
import { Feature } from 'src/app/shared/map-data-services/feature/feature';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';

import { Path } from 'leaflet';
import { CachedFeatureService } from 'src/app/shared/map-data-services/feature/cached-feature.service';
import { MapMenuCode } from '../../map-menus/map-menu-list';
import { FeatureMapLayer } from './feature-map-layer';
import { FeatureMapLayerCacheService } from './feature-map-layer-cache.service';

class SymbologyProperties {
    colorKey: string;
    active: boolean;
    selected: boolean;
    inVisibleTask: boolean;
    updated: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class FeatureMapLayersService {
    constructor(
        private featureMapLayerCacheService: FeatureMapLayerCacheService,
        private activeFeatureStreams: ActiveFeatureStreamsService,
        private menuService: MenuService,
        private cachedFeatureService: CachedFeatureService
    ) {}

    createFeatureMapLayer(feature: Feature): FeatureMapLayer {
        if (!feature) {
            return null;
        }
        // If feature is in data tab, then dont show completed or incompleted status
        feature.inVisibleTask = this.menuService.activeMenusStream.getValue().includes(MapMenuCode.LAYERS)
            ? false
            : feature.inVisibleTask;
        let symbologyProperties = this.getFeatureSymbologyProperties(feature);
        return this.createFeatureMapLayerFromGeometry(feature.geometry, symbologyProperties);
    }

    private createFeatureMapLayerFromGeometry(
        geometry: any,
        symbologyProperties: SymbologyProperties
    ): FeatureMapLayer {
        if (!geometry) {
            return null;
        }

        let geoType = geometry.type;

        if (geoType !== 'GeometryCollection' && !geometry.coordinates) {
            return null;
        }
        let coordinates: any[] = geometry.coordinates;

        switch (geoType) {
            case 'Point':
                let point = this.createFeatureMapLayerFromLatLngs(
                    symbologyProperties,
                    'Point',
                    L.latLng(coordinates[1], coordinates[0])
                );
                return point;

            case 'MultiPoint':
            case 'Curve':
                // wmsCoordinates is an Array[items][latlong]
                let points = new L.FeatureGroup();
                coordinates.forEach((item: any) => {
                    let point2 = this.createFeatureMapLayerFromLatLngs(
                        symbologyProperties,
                        'Point',
                        L.latLng(item[1], item[0])
                    );
                    if (point2) {
                        points.addLayer(point2);
                    }
                });
                return points as FeatureMapLayer;

            case 'LineString':
            case 'LinearRing':
                // wmsCoordinates is an Array[vertices][latlong]
                let lineLatLngs = coordinates.map((ogcCoordinate: any) => L.latLng(ogcCoordinate[1], ogcCoordinate[0]));
                let linePath = this.createFeatureMapLayerFromLatLngs(symbologyProperties, 'Line', lineLatLngs);
                return linePath;

            case 'MultiLineString':
                // wmsCoordinates is an Array[items][vertices][latlong]
                let linePaths = new L.FeatureGroup();
                coordinates.forEach((item: any) => {
                    let lineLatLngs2 = item.map((vertex: any) => L.latLng(vertex[1], vertex[0]));
                    let linePath2 = this.createFeatureMapLayerFromLatLngs(symbologyProperties, 'Line', lineLatLngs2);
                    if (linePath2) {
                        linePaths.addLayer(linePath2);
                    }
                });
                return linePaths as FeatureMapLayer;

            case 'Polygon':
                // wmsCoordinates is an Array[part][vertices][latlong] - first part is outer bounds;
                // other parts are internal holes (NB: currently we show only outer bounds)
                let polygonLatLngs:
                    | L.LatLngExpression
                    | L.LatLngExpression[]
                    | L.LatLngExpression[][]
                    | L.LatLngExpression[][][] = [];
                coordinates.forEach((polyCoords: any) => {
                    let latLngs = polyCoords.map((ogcCoordinate: any) => L.latLng(ogcCoordinate[1], ogcCoordinate[0]));
                    (polygonLatLngs as L.LatLngExpression[]).push(latLngs);
                });

                if (polygonLatLngs.length === 1) {
                    polygonLatLngs = polygonLatLngs[0];
                }

                let polygon = this.createFeatureMapLayerFromLatLngs(symbologyProperties, 'Polygon', polygonLatLngs);
                return polygon;

            case 'MultiPolygon':
                // wmsCoordinates is an Array[items][part][vertices][latlong]
                let polygons = new L.FeatureGroup();
                coordinates.forEach((item: any) => {
                    let polygonLatLngs2 = item[0].map((vertex: any) => L.latLng(vertex[1], vertex[0]));
                    let polygon2 = this.createFeatureMapLayerFromLatLngs(
                        symbologyProperties,
                        'Polygon',
                        polygonLatLngs2
                    );
                    if (polygon2) {
                        polygons.addLayer(polygon2);
                    }
                });
                return polygons as FeatureMapLayer;

            case 'GeometryCollection':
                // geometry collection
                if (!geometry.geometries) {
                    return null;
                }
                let featureGroup = new L.FeatureGroup();
                geometry.geometries.forEach((geometry2: any) => {
                    featureGroup.addLayer(this.createFeatureMapLayerFromGeometry(geometry2, symbologyProperties));
                });
                return featureGroup as FeatureMapLayer;
        }
    }

    private createFeatureMapLayerFromLatLngs(
        symbologyProperties: SymbologyProperties,
        geoType: string,
        latLngs: L.LatLngExpression | L.LatLngExpression[] | L.LatLngExpression[][] | L.LatLngExpression[][][]
    ): FeatureMapLayer {
        switch (geoType) {
            case 'Point':
                return new L.Marker(
                    latLngs as L.LatLngExpression,
                    this.getMarkerOptions(symbologyProperties)
                ) as FeatureMapLayer;
            case 'Line':
            case 'LineString':
                return new L.Polyline(
                    latLngs as L.LatLngExpression[],
                    this.getPolylineStyleOptions(symbologyProperties)
                ) as FeatureMapLayer;
            case 'Polygon':
                return new L.Polygon(
                    latLngs as L.LatLngExpression[][],
                    this.getPolygonStyleOptions(symbologyProperties)
                ) as FeatureMapLayer;
            default:
                return null;
        }
    }

    updateFeatureMapLayer(feature: Feature, featureMapLayer: FeatureMapLayer, checkActiveFeature = true): void {
        featureMapLayer = featureMapLayer || this.featureMapLayerCacheService.getFeatureMapLayer(feature.id);
        if (featureMapLayer && (featureMapLayer instanceof Path || featureMapLayer instanceof L.FeatureGroup)) {
            // this will set style for all child lines and polygons
            featureMapLayer.setStyle(this.getFeatureStyle(feature));
        }
        if (featureMapLayer && featureMapLayer instanceof L.Marker) {
            let markerOptions = this.getMarkerOptions(feature as SymbologyProperties);
            featureMapLayer.setIcon(markerOptions.icon);
            featureMapLayer.setZIndexOffset(markerOptions.zIndexOffset);
        }
        if (featureMapLayer && featureMapLayer instanceof L.LayerGroup) {
            featureMapLayer.eachLayer((childMapLayer: FeatureMapLayer) => {
                // set style for each point child
                this.updateFeatureMapLayer(feature, childMapLayer, checkActiveFeature);
            });
        }
        this.featureMapLayerCacheService.addOrUpdateFeatureMapLayer(feature, featureMapLayer, feature.layerId);
        if (checkActiveFeature) {
            let activeFeature = this.activeFeatureStreams.activeFeature;
            if (activeFeature && activeFeature.id === feature.id) {
                // updating the feature cache
                this.cachedFeatureService.addOrUpdateFeature(feature);
                this.activeFeatureStreams.activeFeature = activeFeature;
            }
        }
    }

    mergeChildToParentFeatureMapLayer(
        existingFeatureMapLayer: FeatureMapLayer,
        childFeatureMapLayer: FeatureMapLayer
    ): L.FeatureGroup<any> {
        // merge a child map layer (always a point from a GeometryCollection) into its parent map layer (arises from vector tiling)
        let featureGroup: L.FeatureGroup = null;
        if (childFeatureMapLayer) {
            featureGroup = new L.FeatureGroup();
            featureGroup.addLayer(childFeatureMapLayer);
        }
        return this.mergeFeatureMapLayers(existingFeatureMapLayer, featureGroup);
    }

    public mergeFeatureMapLayers(
        existingFeatureMapLayer: FeatureMapLayer,
        featureMapLayer: FeatureMapLayer
    ): L.FeatureGroup {
        // merge two feature map layers for the same feature into one.
        // Arises from the same feature, split over multiple tiles, each tile generating a map layer.
        existingFeatureMapLayer = existingFeatureMapLayer ? existingFeatureMapLayer : null;

        // flatten to a single-level array of map layers
        let flattenedFeatureMapLayer = this.flattenMapLayers(featureMapLayer);
        if (!existingFeatureMapLayer) {
            existingFeatureMapLayer = new L.FeatureGroup() as FeatureMapLayer;
        } else if (!(existingFeatureMapLayer instanceof L.FeatureGroup)) {
            // discrepancy in structure - simply replace
            existingFeatureMapLayer = new L.FeatureGroup() as FeatureMapLayer;
        }
        // add new map layers to existing FeatureGroup
        flattenedFeatureMapLayer.forEach(mapLayer => {
            (existingFeatureMapLayer as L.FeatureGroup).addLayer(mapLayer);
        });
        return existingFeatureMapLayer as L.FeatureGroup;
    }

    private flattenMapLayers(mapLayer: L.Layer): L.Layer[] {
        // reduce multi-level mapLayers to a single array of layers
        if (!(mapLayer instanceof L.LayerGroup)) {
            return [mapLayer];
        }
        return (mapLayer as L.LayerGroup)
            .getLayers()
            .reduce(
                (prevMapLayers: L.Layer[], currentMapLayers: L.Layer) =>
                    prevMapLayers.concat(this.flattenMapLayers(currentMapLayers)),
                []
            );
    }

    public getFeatureStyle(feature: Feature): L.PathOptions {
        if (!feature || !feature.geometry) {
            return {};
        }
        let symbologyProperties = this.getFeatureSymbologyProperties(feature);
        return this.getFeatureStyleFromGeometry(symbologyProperties, feature.geometry);
    }

    private getFeatureStyleFromGeometry(symbologyProperties: SymbologyProperties, geometry: any): L.PathOptions {
        if (!geometry) {
            return {};
        }
        let geoType = geometry.type;
        switch (geoType) {
            case 'Point':
            case 'MultiPoint':
            case 'Curve':
            default:
                return {};

            case 'LineString':
            case 'LinearRing':
            case 'MultiLineString':
                return this.getPolylineStyleOptions(symbologyProperties);

            case 'Polygon':
            case 'MultiPolygon':
                return this.getPolygonStyleOptions(symbologyProperties);

            case 'GeometryCollection':
                // this will cover both lines and polygons and is ignored for points
                return this.getPolygonStyleOptions(symbologyProperties);
        }
    }

    private getMarkerOptions(symbologyProperties: SymbologyProperties): L.MarkerOptions {
        let classes = ['marker'];
        let html =
            '<div class="marker-background"><div class="marker-inner" style="background-color: #' +
            symbologyProperties.colorKey +
            '"></div></div>';
        classes.push(symbologyProperties.active ? 'active' : symbologyProperties.selected ? 'selected' : 'unselected');
        if (symbologyProperties.inVisibleTask) {
            classes.push(symbologyProperties.updated ? 'updated' : 'in-task');
            html =
                '<div class="marker-task" style="border-color: #' + symbologyProperties.colorKey + ';"></div>' + html;
        }
        return {
            icon: L.divIcon({
                className: classes.join(' '),
                html: html,
                iconSize: null // explicitly set to null or you will get the default 12x12 size
            }),
            interactive: false, // feature click treated like ordinary map click
            zIndexOffset: symbologyProperties.active ? 2003 : symbologyProperties.selected ? 1003 : 3
        };
    }

    private getPolylineStyleOptions(symbologyProperties: SymbologyProperties): L.PathOptions {
        return {
            interactive: false, // feature click treated like ordinary map click
            color: '#' + symbologyProperties.colorKey,
            opacity: 1.0,
            weight: symbologyProperties.active ? 5 : symbologyProperties.selected ? 3 : 1,
            dashArray: !symbologyProperties.inVisibleTask ? '0,0' : !symbologyProperties.updated ? '7,10' : '0,0'
        };
    }

    private getPolygonStyleOptions(symbologyProperties: SymbologyProperties): L.PolylineOptions {
        return {
            interactive: false, // feature click treated like ordinary map click
            fillColor: '#' + symbologyProperties.colorKey,
            color: '#' + symbologyProperties.colorKey,
            opacity: 1,
            fillOpacity: symbologyProperties.active ? 0.8 : symbologyProperties.selected ? 0.5 : 0.2,
            weight: symbologyProperties.active ? 5 : symbologyProperties.selected ? 3 : 1,
            dashArray: !symbologyProperties.inVisibleTask ? '0,0' : !symbologyProperties.updated ? '7,10' : '0,0'
        };
    }

    private getFeatureSymbologyProperties(feature: Feature): SymbologyProperties {
        let properties: SymbologyProperties = {
            colorKey: feature.colorKey,
            selected: Boolean(feature.selected),
            active: Boolean(feature.active),
            inVisibleTask: Boolean(feature.inVisibleTask),
            updated: Boolean(feature.updated)
        };
        return properties;
    }

    public setLayerSymbologyProperties(feature: Feature, layerOrColorKey: Layer | string): void {
        let colorKey: string;
        if (layerOrColorKey instanceof Layer) {
            let layer = layerOrColorKey;
            colorKey = layer.getLayerColorKey();
        } else {
            colorKey = layerOrColorKey;
        }
        feature.colorKey = colorKey ? colorKey : '990000';
    }
}
