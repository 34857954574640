import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'gsp-toggle',
    templateUrl: './gsp-toggle.component.html'
})
export class GspToggleComponent {
    @Input() on: boolean;
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onChange: EventEmitter<boolean> = new EventEmitter();
    @Input() label: string;
    @Input() disabled = false;

    onClick(): void {
        this.on = !this.on;
        this.onChange.emit(this.on);
    }
}
