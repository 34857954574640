<div
    class="gsp-context-menu-trigger"
    [matMenuTriggerFor]="menu"
    [style.left]="position.x"
    [style.top]="position.y"
></div>
<mat-menu #menu="matMenu" [hasBackdrop]="false">
    <ng-container *ngFor="let item of visibleItems">
        <div *ngIf="item.image" [ngClass]="item.itemClass" (click)="item.execute(trigger.menuData)" mat-menu-item>
            <img [src]="item.image" alt="" />
            <div [ngClass]="item.labelClass" [id]="item.id">{{ item.name }}</div>
        </div>
        <div
            *ngIf="!item.title && !item.divider && !item.image"
            (click)="item.execute(trigger.menuData)"
            [ngClass]="item.labelClass"
            [id]="item.id"
            mat-menu-item
        >
            <span *ngIf="item.iconClass"><i [ngClass]="item.iconClass"></i></span>
            {{ item.name }}
        </div>
        <mat-divider *ngIf="item.divider"></mat-divider>
        <h5 *ngIf="item.title" class="menu-title" (click)="$event.stopPropagation()">
            <strong>{{ item.name }}</strong>
        </h5>
    </ng-container>
</mat-menu>
