import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SelectionToolIdUtils, SelectionToolStream } from 'src/app/feature/map-viewer/common/selection-tool-stream.service';
import {
    MapDrawAction,
    MapDrawActionSource,
    MapDrawActionsStreamService,
} from 'src/app/feature/map-viewer/map-container/map-draw-actions/map-draw-action-stream.service';
import { KeyCodes } from 'src/app/shared/common/key-codes';
import { KeyEventService } from 'src/app/shared/common/utility/key-event.service';
import { StringUtils } from 'src/app/shared/common/utility/string-utils';

export enum SelectionAction {
    NEW = 'new',
    APPEND = 'append',
    REMOVE = 'remove'
}

export enum SelectionMode {
    SINGLE = 'single',
    RECTANGLE = 'rectangle',
    POLYGON = 'polygon'
}

export enum SelectionModeAction {
    SINGLE_NEW = SelectionMode.SINGLE + '_' + SelectionAction.NEW,
    RECTANGLE_NEW = SelectionMode.RECTANGLE + '_' + SelectionAction.NEW,
    POLYGON_NEW = SelectionMode.POLYGON + '_' + SelectionAction.NEW,
    SINGLE_APPEND = SelectionMode.SINGLE + '_' + SelectionAction.APPEND,
    RECTANGLE_APPEND = SelectionMode.RECTANGLE + '_' + SelectionAction.APPEND,
    POLYGON_APPEND = SelectionMode.POLYGON + '_' + SelectionAction.APPEND,
    SINGLE_REMOVE = SelectionMode.SINGLE + '_' + SelectionAction.REMOVE,
    RECTANGLE_REMOVE = SelectionMode.RECTANGLE + '_' + SelectionAction.REMOVE,
    POLYGON_REMOVE = SelectionMode.POLYGON + '_' + SelectionAction.REMOVE
}

@Component({
    selector: 'selection-tool',
    templateUrl: './selection-tool.component.html'
})
export class SelectionToolComponent implements OnInit, OnDestroy {
    @Input()
    public map: L.Map;
    public currentMapId = '#map';
    public open = false;
    public currentSelectionToolId: SelectionModeAction; // 'single_new', 'rectangle_new', 'polygon_new', ...
    public currentMode: SelectionMode; // single, rectangle, polygon
    public currentAction: SelectionAction; // new, append, remove
    public highlightSelectionTool = true;

    public openMenuIconClass = 'icon_line_arrow_down';
    public closeMenuIconClass = 'icon_line_arrow_up';

    private readonly destroyed = new Subject<void>();

    // exposing enum to template
    public SelectionMode = SelectionMode;
    public SelectionAction = SelectionAction;
    public SelectionModeAction = SelectionModeAction;

    @HostListener('document:mouseup', ['$event'])
    @HostListener('document:click', ['$event'])
    onClickOut($event: { target: { className: any } }) {
        const targetClassName = $event.target.className;
        if (
            StringUtils.isString(targetClassName) &&
            !StringUtils.contains(targetClassName, this.openMenuIconClass) &&
            !StringUtils.contains(targetClassName, this.closeMenuIconClass)
        ) {
            this.open = false;
        }
    }

    constructor(
        private selectionToolStream: SelectionToolStream,
        private mapActionsStream: MapDrawActionsStreamService,
        private keyEventService: KeyEventService
    ) {}

    public ngOnInit(): void {
        this.keyEventService
            .getObserver(this.currentMapId)
            .pipe(takeUntil(this.destroyed))
            .subscribe((keyEvent: KeyboardEvent) => {
                this.keyEventAction(keyEvent);
            });

        this.mapActionsStream.startMapDrawActionStream
            .pipe(takeUntil(this.destroyed))
            .subscribe((mapDrawAction: MapDrawAction) => {
                // ensure selection tool highlight is disabled while any other map draw in progress
                if (mapDrawAction.source !== MapDrawActionSource.SELECTION_TOOL) {
                    // set rectangle crosshair if offlineBaseMaps creation
                    if (mapDrawAction.source === MapDrawActionSource.MAP_CACHE_CREATOR) {
                        this.selectionToolStream.selectionToolSelectedStream.next(SelectionModeAction.RECTANGLE_NEW);
                        this.setFocusToMap();
                    }
                    // another tool is drawing
                    this.highlightSelectionTool = false;
                    this.currentAction = SelectionAction.NEW;
                } else {
                    // just in case
                    this.highlightSelectionTool = true;
                }
            });

        this.mapActionsStream.mapDrawActionResultStream.pipe(takeUntil(this.destroyed)).subscribe(() => {
            // tool finished drawing
            this.highlightSelectionTool = true;

            // after drawing complete, the map looses focus, so set the focus back to the map
            this.setFocusToMap();
        });

        this.setSelectionTool(SelectionModeAction.SINGLE_NEW);

        this.selectionToolStream.selectSelectionToolStream
            .pipe(takeUntil(this.destroyed))
            .subscribe((selectionToolId: SelectionModeAction) => {
                this.setSelectionTool(selectionToolId);
            });
    }

    public ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    public get menuIconClass(): string {
        return this.open ? this.closeMenuIconClass : this.openMenuIconClass;
    }

    public openMenu(): void {
        this.open = !this.open;
    }

    private keyEventAction(keyEvent: KeyboardEvent): void {
        keyEvent.preventDefault();
        keyEvent.stopPropagation();
        if (keyEvent.type === 'keydown') {
            if (keyEvent.key === KeyCodes.SHIFT) {
                this.setSelectionTool(
                    (this.currentMode + '_' + SelectionAction.APPEND) as unknown as SelectionModeAction
                );
            } else if (keyEvent.key === KeyCodes.ALT) {
                this.setSelectionTool(
                    (this.currentMode + '_' + SelectionAction.REMOVE) as unknown as SelectionModeAction
                );
            }
        } else {
            if (keyEvent.key === KeyCodes.SHIFT) {
                this.setSelectionTool((this.currentMode + '_' + SelectionAction.NEW) as unknown as SelectionModeAction);
            } else if (keyEvent.key === KeyCodes.ALT) {
                this.setSelectionTool((this.currentMode + '_' + SelectionAction.NEW) as unknown as SelectionModeAction);
            }
        }
    }

    public onSetSelectionTool($event: UIEvent, selectionToolId: SelectionModeAction): void {
        $event.stopPropagation();
        this.currentSelectionToolId = selectionToolId;
        this.setSelectionTool(selectionToolId);
    }

    private setSelectionTool(selectionToolId: SelectionModeAction): void {
        if (selectionToolId) {
            this.currentSelectionToolId = selectionToolId;
            this.currentMode = SelectionToolIdUtils.getModeFromSelectionToolId(selectionToolId);
            this.currentAction = SelectionToolIdUtils.getActionFromSelectionToolId(selectionToolId);
            this.selectionToolStream.selectionToolSelectedStream.next(selectionToolId);
            this.mapActionsStream.startMapDrawActionStream.next({
                source: MapDrawActionSource.SELECTION_TOOL,
                mode: this.currentMode,
                drawingOptions: null,
                drawingCursor: ''
            });
            this.open = false;
        }

        // after clicking on the select tool, the map looses focus, so set the focus back to the map
        this.setFocusToMap();

        // NOTE: 30/08/2019: There is an issue in Chrome where the cursor does not change until the mouse is moved.
        // This only occurs when the developer tools is open.
    }

    private setFocusToMap(): void {
        if (document.activeElement.nodeName !== 'INPUT') {
            let element: HTMLElement = document.querySelector(this.currentMapId);
            if (element) {
                element.focus();
            }
        }
    }
}
