import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { EnvironmentService } from '../../environment/environment.service';

export const TRANOS_TOKEN = 'tranosToken';

export enum GspAnnouncementStatus {
    DISMISSED = 'Dismissed',
    REMIND_ME_LATER = 'RemindMeLater'
}

export interface GspAnnouncement {
    id: string;
    name: string;
    type: string;
    properties: { [key: string]: any };
    content: string;
    url: string;
}

export interface GspAnnouncementTracking {
    status: GspAnnouncementStatus;
}

export interface GspAnnouncementResponse {
    announcement: GspAnnouncement;
    tracking?: GspAnnouncementTracking;
}

@Injectable({
    providedIn: 'root'
})
export class GspAnnouncementBannerService {
    constructor(private http: HttpClient, private environmentService: EnvironmentService) {}

    public async getAnnouncements(condition: object = null): Promise<GspAnnouncementResponse[]> {
        let path = this.environmentService.apiUrl + '/announcements';
        if (condition) {
            const conditionString = btoa(JSON.stringify(condition));
            if (conditionString) {
                path += '?conditions=' + conditionString;
            }
        }
        try {
            const res = await lastValueFrom(this.http.get<{ items: GspAnnouncementResponse[] }>(path));
            return res.items;
        } catch (e) {
            return [];
        }
    }

    public createAnnouncementTracking(
        announcementId: string,
        status: GspAnnouncementStatus
    ): Promise<GspAnnouncementTracking> {
        const path = this.environmentService.apiUrl + '/announcements/tracking';
        const requestObj = {
            announcementId: announcementId,
            status: status
        };
        return lastValueFrom(this.http.post<GspAnnouncementTracking>(path, requestObj));
    }

    public updateAnnouncementTracking(
        announcementId: string,
        status: GspAnnouncementStatus
    ): Promise<GspAnnouncementTracking> {
        const path = this.environmentService.apiUrl + '/announcements/tracking';
        const requestObj = {
            announcementId: announcementId,
            status: status
        };
        return lastValueFrom(this.http.patch<GspAnnouncementTracking>(path, requestObj));
    }
}
