<div class="feature-field">
    <label class="feature-label" [ngClass]="{ disabled: field.value == null }">
        {{ field.displayName }}
        <span *ngIf="field.required">*</span>
    </label>

    <div class="feature-data" *ngIf="!editMode">
        <ul class="choice-list">
            <ng-container *ngFor="let option of field.options">
                <li *ngIf="isSelected(option)">
                    <i class="icon-24-dark-check-selected" *ngIf="field.allowMultiSelect === true"></i>
                    <i class="icon-24-dark-radio-selected" *ngIf="field.allowMultiSelect === false"></i>
                    <label title="{{ option }}">{{ option }}</label>
                </li>
            </ng-container>
            <li *ngIf="isOtherOptionSelected && !!otherOption.length">
                <i class="icon-24-dark-check-selected" *ngIf="field.allowMultiSelect === true"></i>
                <i class="icon-24-dark-radio-selected" *ngIf="field.allowMultiSelect === false"></i>
                <label title="{{ otherOption }}">{{ otherOption }}</label>
            </li>
        </ul>
    </div>

    <div class="editable-control" *ngIf="editMode && field.allowMultiSelect === true">
        <ng-container *ngFor="let option of field.options; index as i">
            <div class="choice">
                <gsp-checkbox
                    [id]="field.name + '-' + i"
                    [label]="option"
                    [value]="isSelected(option)"
                    (valueChange)="toggleOption(option)"
                ></gsp-checkbox>
            </div>
        </ng-container>
        <div class="choice" [hidden]="!field.allowNonDomainValues">
            <gsp-checkbox
                [id]="field.name + '-other'"
                [label]="'TCW_Others' | translate"
                [value]="isOtherOptionSelected"
                (valueChange)="toggleOtherOptionSelected()"
            ></gsp-checkbox>
        </div>
        <div class="choice">
            <input
                class="gsp-form-input"
                [(ngModel)]="otherOption"
                *ngIf="isOtherOptionSelected"
                maxlength="{{ field.maxLength }}"
            />
        </div>
    </div>

    <div class="editable-control" *ngIf="editMode && field.allowMultiSelect === false">
        <ng-container *ngFor="let option of field.options; index as i">
            <div class="choice">
                <gsp-radio
                    id="{{ field.name }}-{{ i }}"
                    name="{{ field.name }}-{{ i }}"
                    [ngModel]="isSelected(option)"
                    (click)="toggleOption(option)"
                    [radioValue]="true"
                    radioLabelClass="radio-label-wrap"
                    radioInputClass="radio-input"
                    radioButtonClass="dark-radio-button"
                    class="ng-pristine ng-valid"
                    [valueText]="option"
                >
                </gsp-radio>
            </div>
        </ng-container>
        <div class="choice" [hidden]="!field.allowNonDomainValues">
            <gsp-radio
                id="{{ field.name }}-other"
                name="{{ field.name }}-other"
                [ngModel]="isOtherOptionSelected"
                (click)="toggleOtherOptionSelected()"
                [radioValue]="true"
                radioLabelClass="radio-label-wrap"
                radioInputClass="radio-input"
                radioButtonClass="dark-radio-button"
                class="ng-pristine ng-valid"
                valueText="{{ 'TCW_Others' | translate }}"
            >
            </gsp-radio>
        </div>
        <div class="choice">
            <input
                class="gsp-form-input"
                [(ngModel)]="otherOption"
                *ngIf="isOtherOptionSelected"
                maxlength="{{ field.maxLength }}"
            />
        </div>
    </div>
</div>
