import { Component, OnInit } from '@angular/core';
import { GspWizardService } from 'src/app/shared/common/components/gsp-wizard/gsp-wizard.service';
import { TCFile } from 'src/app/shared/common/files/TCFile';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';

import { WorkspaceService } from '../workspace.service';

@Component({
    selector: 'workspace-set-folder',
    templateUrl: './workspace-set-folder.component.html'
})
export class WorkspaceSetFolderComponent implements OnInit {
    constructor(private workspaceCreationService: WorkspaceService, private wizardService: GspWizardService) {}

    public sourceWorkspace: MapWorkspace;
    public uiState = {
        backButton: { text: 'MapViewer.Generic.Previous', enabled: true, visible: true },
        forwardButton: { text: 'TC.Common.Next', enabled: false, visible: true },
        closeButton: { text: '', enabled: true, visible: true },
        cancelButton: { text: 'TC.Common.Cancel', enabled: true, visible: true }
    };

    ngOnInit(): void {
        this.wizardService.setUI(this.uiState);
        this.sourceWorkspace = CloneUtils.cloneDeep(this.workspaceCreationService.sourceWorkspace$.getValue());
    }

    public validateSelectedFolder(selectedFolder: boolean): void {
        this.uiState.forwardButton.enabled = selectedFolder;
        this.wizardService.setUI(this.uiState);
    }

    public setFolder(folder: TCFile): void {
        this.workspaceCreationService.setDestinationFolder$.next(folder);
        this.validateSelectedFolder(!!folder);
    }
}
