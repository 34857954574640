<div class="modal-footer">
    <gsp-button
        [type]="ButtonType.Primary"
        (action)="onForward()"
        [showBusyIndicator]="isBusy$ | async"
        [disabled]="!(state$ | async).forwardButton.enabled || (isBusy$ | async)"
        [hidden]="!(state$ | async).forwardButton.visible"
        [text]="(state$ | async).forwardButton.text | translate"
        id="wizard-forward"
    ></gsp-button>

    <gsp-button
        [type]="ButtonType.Default"
        (action)="onBackward()"
        [disabled]="!(state$ | async).backButton.enabled || (isBusy$ | async)"
        [hidden]="!(state$ | async).backButton.visible"
        [text]="(state$ | async).backButton.text | translate"
        id="wizard-backward"
    ></gsp-button>
    <gsp-button
        [type]="ButtonType.Default"
        (action)="onCancel()"
        [disabled]="!(state$ | async).cancelButton.enabled"
        [hidden]="!(state$ | async).cancelButton.visible"
        [text]="(state$ | async).cancelButton.text | translate"
        id="wizard-cancel"
    ></gsp-button>
</div>
