import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { Task } from '../task/task';
import { TasksStore } from './tasks-store.service';

@Injectable({
    providedIn: 'root'
})
export class TasksStreamsService {
    // --------------
    // Map Workspace tasks streams

    visibleTasksStream = new BehaviorSubject<Task[]>([]);
    private selectedTasksStream = new BehaviorSubject<Task[]>([]);
    loadTaskStream = new ReplaySubject<Task>(1);
    pendingLoadingTaskStream = new BehaviorSubject<{ task: Task; loading: boolean }>(null);
    deferVisibleTasksStream = new BehaviorSubject<Task[]>([]);
    deferHideTasksStream = new BehaviorSubject<Task[]>([]);
    mapWorkspaceTasksStream: BehaviorSubject<Task[]>;

    constructor(private tasksStore: TasksStore) {
        // ! GIM 20/5/2019 - This line used to have a shareReplay on it, but the underlying
        // ! observable is a BehaviourSubject so not applicable
        this.mapWorkspaceTasksStream = tasksStore.mapWorkspaceTasksStream;
    }

    public setVisibleTasks(tasks: Task[]): void {
        this.visibleTasksStream.next(tasks);
    }

    public setSelectedTasks(tasks: Task[]): void {
        this.selectedTasksStream.next(tasks);
    }

    public setSelectedTodos(todoIds: string[]): void {
        this.tasksStore.isTasksLoaded().then(() => {
            this.tasksStore.mapWorkspaceTasksStream.getValue().forEach(task => {
                if (todoIds.indexOf(task.todoId) !== -1) {
                    this.loadTaskStream.next(task);
                }
            });
        });
    }

    public setTaskAsLoading(task: Task): void {
        this.pendingLoadingTaskStream.next({ task: task, loading: true });
    }

    public setTaskAsLoaded(task: Task): void {
        this.pendingLoadingTaskStream.next({ task: task, loading: false });
    }
}
