import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { lastValueFrom, Subject, timer } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { MapService } from 'src/app/feature/map-viewer/map.service';
import { MapWorkspacePermissionType } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace-permission';

@Component({
    selector: 'map-toolbar',
    templateUrl: './map-toolbar.component.html'
})
export class MapToolbarComponent implements OnInit, OnDestroy {
    @Input()
    public linkToMap: string;

    public map: L.Map;
    private readonly destroyed = new Subject<void>();

    // expose enum to template
    public MapWorkspacePermissionType = MapWorkspacePermissionType;

    constructor(private mapService: MapService) {}

    ngOnInit(): void {
        this.checkIfMapIsReady();
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    private checkIfMapIsReady() {
        if (!!this.mapService.mapReadyStreams[this.linkToMap]) {
            this.mapService.mapReadyStreams[this.linkToMap]
                .pipe(
                    takeUntil(this.destroyed),
                    first((isMapReady: boolean) => isMapReady)
                )
                .subscribe(() => {
                    this.map = this.mapService.maps[this.linkToMap];
                });
        } else {
            lastValueFrom(timer(1000)).then(() => this.checkIfMapIsReady());
        }
    }
}
