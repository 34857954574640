import { Component, forwardRef, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR, NgControl, NgForm, NgModel } from '@angular/forms';
import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { GspNumberType } from 'src/app/shared/common/components/gsp-number/gsp-number.component';
import {
    BooleanConverter,
    FloatConverter,
    InputConverter,
} from 'src/app/shared/common/components/input-converter.decorator';
import { ValueAccessorBase } from 'src/app/shared/common/components/value-accessor-base';

@Component({
    selector: 'number-field',
    templateUrl: './number-field.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumberFieldComponent),
            multi: true
        }
    ],
    // this line allows the ngModels to be associated with the form from the parent component
    // ref: https://medium.com/@john_oerter/angular-nested-forms-and-validation-844ea05d4063
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class NumberFieldComponent extends ValueAccessorBase<string> implements OnInit, OnDestroy {
    @Input()
    @InputConverter(BooleanConverter)
    enabled = true;

    @Input()
    name: string;

    @Input()
    label: string;

    @Input()
    postfixLabel: string;

    @Input()
    placeholder = '';

    @Input()
    @InputConverter(FloatConverter)
    max: number;

    @Input()
    decimalPlaces: number;

    @Input()
    propRequired: boolean; // to denote the required field highlight on first load for incrementRepeatValue property

    @ViewChild('textInput', { static: true })
    textField: NgModel;

    // using textValue to remove digit grouping before setting the value to prevent property maximum validation issues
    get textValue(): string {
        return this.value;
    }

    set textValue(value: string) {
        this.value = value ? value.toString().replace(/[^\d.-]/g, '') : value;
    }

    constructor(private injector: Injector) {
        super();
    }

    private destroyed = new Subject<void>();

    // exposing enum to template
    public GspNumberType = GspNumberType;

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    ngOnInit(): void {
        const model = this.injector.get(NgControl);
        const control = model.control;

        control.statusChanges.pipe(takeUntil(this.destroyed), delay(0)).subscribe(() => {
            this.textField.control.setErrors(control.valid ? null : { parentError: true });
        });
    }
}
