import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { TranslationService } from 'src/app/core/translation/translation.service';

import { TaskStatus } from './task';

@Injectable({
    providedIn: 'root'
})
export class StatusService {
    private statuses: { label: TaskStatus; displayValue: string }[];
    // explicitly typing to any as exact typing cannot be imported through 'lodash-es'
    private statusLabelToDisplayValue: any;

    constructor(translate: TranslationService) {
        this.statuses = [
            { label: TaskStatus.NEW, displayValue: translate.instant('NEW') },
            { label: TaskStatus.IN_PROGRESS, displayValue: translate.instant('IN_PROGRESS') },
            { label: TaskStatus.BLOCKED, displayValue: translate.instant('TC.Common.Status.Waiting') },
            { label: TaskStatus.RESOLVED, displayValue: translate.instant('RESOLVED') },
            { label: TaskStatus.CLOSED, displayValue: translate.instant('CLOSED') }
        ];

        this.statusLabelToDisplayValue = _.keyBy(this.statuses, 'label');
    }

    getStatuses(): { label: TaskStatus; displayValue: string }[] {
        return this.statuses;
    }

    labelToDisplayValue(label: TaskStatus): string {
        return this.statusLabelToDisplayValue[label] ? this.statusLabelToDisplayValue[label].displayValue : null;
    }
}
