<div>
    <post-processing-options
        *ngIf="workspace"
        [disableForLockedWorkspaces]="true"
        [enablePopup]="true"
        [toggleLabel]="toggleLabel"
        [workspace]="workspace"
        [updateInProgress]="updateInProgress"
        (initialized)="onPostProcessingOptionsInit()"
        (popupDone)="onPopupDone()"
        (toggled)="onToggled()"
    ></post-processing-options>

    <div *ngIf="!loading && hasEntitlement" class="custom-base-station-body">
        <h4 class="font-title">{{ 'MapViewer.EEP.BaseStation.Header' | translate }}</h4>

        <gsp-dropdown
            name="baseStationOption"
            [options]="baseStationOptions"
            [ngDisabled]="
                workspace.postProcessingOptions.locked || !workspace.postProcessingOptions.enabled || updateInProgress
            "
            idField="id"
            textField="displayText"
            [(ngModel)]="workspace.postProcessingOptions.useUserBaseFilesFolder"
            (ngModelChange)="updateWorkspace()"
        ></gsp-dropdown>

        <div *ngIf="workspace.postProcessingOptions.useUserBaseFilesFolder" class="custom-base-station-settings">
            <section class="base-file-location-section">
                <div class="section-title">
                    <h5 class="section-label">{{ 'MapViewer.EEP.BaseStation.Custom.Location' | translate }}</h5>
                    <gsp-help-text
                        [text]="'MapViewer.EEP.BaseStation.Custom.Location.Hint' | translate"
                    ></gsp-help-text>
                </div>
                <div class="folder-selection">
                    <div
                        class="selected-folder-details"
                        [ngClass]="{
                            disabled:
                                workspace.postProcessingOptions.locked ||
                                !workspace.postProcessingOptions.enabled ||
                                updateInProgress
                        }"
                    >
                        {{
                            currentFolderDetails
                                ? currentFolderDetails.name
                                : ('MapViewer.EEP.BaseStation.Custom.Location.None' | translate)
                        }}
                    </div>
                    <div
                        class="folder-selector-handle"
                        [ngClass]="{
                            disabled:
                                workspace.postProcessingOptions.locked ||
                                !workspace.postProcessingOptions.enabled ||
                                updateInProgress
                        }"
                        (click)="toggleFolderSelection()"
                    >
                        {{ 'MapViewer.EEP.BaseStation.Custom.Location.Handle' | translate }}
                    </div>
                </div>
            </section>

            <section class="processing-time-selection-section">
                <div class="section-title">
                    <h5 class="section-label">{{ 'MapViewer.EEP.BaseStation.Custom.Time.Header' | translate }}</h5>
                    <gsp-help-text [text]="'MapViewer.EEP.BaseStation.Custom.Time.Hint' | translate"></gsp-help-text>
                </div>
                <div class="time-selection-section">
                    <gsp-time-picker
                        class="width-full"
                        [disabled]="
                            workspace.postProcessingOptions.locked ||
                            !workspace.postProcessingOptions.enabled ||
                            updateInProgress
                        "
                        [(ngModel)]="workspace.postProcessingOptions.userBaseFilesAvailabilityTime"
                        (ngModelChange)="updateWorkspace()"
                    ></gsp-time-picker>
                </div>
            </section>

            <section class="processing-info-section">
                <gsp-notification
                    [type]="NotificationType.Info"
                    [heading]="'MapViewer.EEP.BaseStation.Custom.Tips.Header' | translate"
                >
                    <ul class="processing-tips">
                        <li>{{ supportedFileFormatsLabel }} {{ supportedFileFormats }}</li>
                        <li>{{ refFrameRequiredLabel }} {{ refFrameRequired }}</li>
                        <li>{{ ensureBaseStationWithinArea }}</li>
                    </ul>
                </gsp-notification>
            </section>
        </div>
    </div>
    <gsp-popup
        *ngIf="showFolderSelection"
        [heading]="'MapViewer.EEP.BaseStation.Custom.Location.Selection.Header' | translate"
        [size]="ModalSize.Small"
        [showClose]="true"
        (closed)="toggleFolderSelection()"
        id="custom-basemap-folder-selection-popup"
    >
        <ng-container transclude-popup-content>
            <div class="custom-basemap-folder-selection-popup-content">
                <div class="folder-selection-header">
                    {{ 'MapViewer.EEP.BaseStation.Custom.Location.Selection.Description' | translate }}
                </div>
                <div class="folder-selection-section-wrapper">
                    <folder-selection
                        [selectedWorkspace]="workspace"
                        (selectedFolder)="setFolder($event)"
                        class="folder-selection-component-wrapper"
                    ></folder-selection>
                </div>
            </div>
        </ng-container>
        <ng-container transclude-popup-footer>
            <gsp-button
                [asyncAction]="folderConfirmAction"
                [type]="ButtonType.Primary"
                text="{{ 'TCW.Common.Confirm' | translate }}"
            ></gsp-button>
            <gsp-button
                (action)="toggleFolderSelection()"
                [type]="ButtonType.Default"
                text="{{ 'TC.Common.Cancel' | translate }}"
            ></gsp-button>
        </ng-container>
    </gsp-popup>
</div>

<div class="footer" *ngIf="!workspace.postProcessingOptions.useUserBaseFilesFolder">
    <img src="assets/images/svg/Trimble-Early-Experience-program.svg" alt="" />
    <div class="description">
        <p>
            {{ 'MapViewer.OfflineGNSS.Description' | translate }}
            <a
                class="default-link default-link-light"
                href="https://help.trimblegeospatial.com/TerraFlex/offline-gnss-corrections.htm"
                target="_blank"
                >{{ 'MapViewer.EEP.Links.Anchor' | translate }}</a
            >.
            <br />
            <br />
            {{ 'MapViewer.OfflineGNSS.Footer' | translate }}
        </p>
    </div>
</div>
