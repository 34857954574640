import { TranslationService } from 'src/app/core/translation/translation.service';

import { GeometryTypes } from '../../common/utility/geometry-utils';
import { MapWorkspace } from '../mapWorkspace/map-workspace';
import { GeoLayerTemplate } from './layer';

export enum TemplateStatus {
    DRAFT = 'Draft',
    PUBLISHED = 'Published'
}

export enum TemplateTypeId {
    NEW_FORM = 'new-form',
    DIRECT_TEMPLATE = 'direct-template',
    FROM_LAYER = 'from-layer'
}

// Light version of template (as returned nested in Layer).  Omits fields, rules, etc.
export class TemplateLite {
    // DTO properties
    id: string = null;
    seriesId: string = null;
    version: number = null;
    name: string = null;
    geometryType = GeometryTypes.POINT;
    geometryColorHexRGB = '#000000';

    // Added properties

    // Added properties (Workspace specific - updated by getTemplateByLayer())
    lastPublishedVersion: number = null;

    // Managed by add-existing-template.component
    attachedToWorkspaces: MapWorkspace[] = [];

    status: TemplateStatus = TemplateStatus.DRAFT;

    constructor() {}

    static fromDTO(templateDto: GeoLayerTemplate, $translate?: TranslationService): TemplateLite {
        const template = new TemplateLite();

        if (templateDto) {
            template.id = templateDto.id;
            template.seriesId = templateDto.seriesId;
            template.version = templateDto.version;
            template.name = templateDto.templateName;
            template.geometryType = templateDto.geometryType;
            template.geometryColorHexRGB = templateDto.geometryColorHexRGB;
            template.status = templateDto.isPublished ? TemplateStatus.PUBLISHED : TemplateStatus.DRAFT;
        }

        return template;
    }
}
