import { Component, forwardRef, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR, NgControl, NgForm, NgModel } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BooleanConverter, InputConverter, IntConverter } from 'src/app/shared/common/components/input-converter.decorator';
import { ValueAccessorBase } from 'src/app/shared/common/components/value-accessor-base';

@Component({
    selector: 'text-field',
    templateUrl: './text-field.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextFieldComponent),
            multi: true
        }
    ],
    // this line allows the ngModels to be associated with the form from the parent component
    // ref: https://medium.com/@john_oerter/angular-nested-forms-and-validation-844ea05d4063
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class TextFieldComponent extends ValueAccessorBase<string> implements OnInit, OnDestroy {
    @Input()
    @InputConverter(BooleanConverter)
    enabled: boolean;

    @Input()
    label: string;

    @Input()
    postfixLabel: string;

    @Input()
    warning: string;

    @Input()
    name: string;

    @Input()
    @InputConverter(IntConverter)
    maxlength: number;

    @Input()
    placeholder: string;

    @ViewChild('textInput', { static: true })
    textField: NgModel;

    constructor(private injector: Injector) {
        super();
    }

    private destroyed = new Subject<void>();
    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    ngOnInit(): void {
        const model = this.injector.get(NgControl);
        const control = model.control;

        control.statusChanges.pipe(takeUntil(this.destroyed)).subscribe((status: string) => {
            this.textField.control.setErrors(control.valid ? null : { parentError: true });
        });
    }
}
