<label *ngIf="value">
    <span *ngIf="label" [ngClass]="{ 'disabled-field': !enabled }">{{ label }}</span>
    <date-field
        class="date-range-minimum"
        [name]="minimumFieldName"
        [(ngModel)]="value.lo"
        [enabled]="enabled"
        [placeholder]="minimumPlaceholder"
        [maxDate]="maxForMinField"
        [minDate]="minimum"
    >
    </date-field>
    <date-field
        class="date-range-maximum"
        [name]="maximumFieldName"
        [(ngModel)]="value.hi"
        [enabled]="enabled"
        [placeholder]="maximumPlaceholder"
        [maxDate]="maximum"
        [minDate]="value.lo"
    >
    </date-field>
</label>
