<ng-form #workspaceNameForm="ngForm" class="workspace-edit-form">
    <div class="workspace-edit-form-group">
        <div class="workspace-edit-form-group-label" translate="name"></div>
        <div class="workspace-edit-form-group-input">
            <gsp-text
                #workspaceEditName="ngModel"
                [type]="GspTextType.GENERIC"
                [id]="'workspaceEditName'"
                [name]="'workspaceEditName'"
                [checkDuplicateOnDirty]="checkDuplicateOnDirty"
                placeholder="{{ 'TCS.Workspace.EnterMapName' | translate }}"
                [(ngModel)]="modifyWorkspace.name"
                length="44"
                errorTooltipMsg="{{ getErrorText(workspaceEditName.errors) }}"
                focusElement="true"
                workspaceName
            ></gsp-text>
        </div>
    </div>

    <div class="workspace-edit-form-group mt-2">
        <div class="workspace-edit-form-group-label" translate="description"></div>
        <div class="workspace-edit-form-group-input">
            <gsp-text-area
                [(value)]="modifyWorkspace.description"
                name="workspaceEditDescription"
                [placeholder]="'TCS.Workspace.EnterMapDescription' | translate"
            ></gsp-text-area>
        </div>
    </div>
</ng-form>
