export class StringUtils {
    static isString(s: any): boolean {
        return typeof s === 'string';
    }

    static contains(s: string, substring: string): boolean {
        return s.indexOf(substring) > -1;
    }

    // convert all symbols to sql escape format - sqlstring
    static escapeSql(unsafe: string): string {
        let safeSqlString = JSON.stringify(unsafe);
        safeSqlString = safeSqlString.substring(1, safeSqlString.length - 1);

        // more escapes condition where:
        // replace 2 single quotes from 1 single quote
        safeSqlString = safeSqlString.match(/'/g) ? safeSqlString.replace(/'/g, "''") : safeSqlString;

        // remove all emojis
        safeSqlString = this.removeEmoji(safeSqlString);

        // prevents cut off the query - all reserved characters based on RFC 3986
        if (this.containsReservedUrlCharacters(safeSqlString)) {
            safeSqlString = encodeURIComponent(safeSqlString);

            // additional backslashes for \, ", ' in encodeURIComponent format
            safeSqlString = safeSqlString.replace(/%5C%5C/g, '%5C%5C%5C%5C');
            safeSqlString = safeSqlString.replace(/%5C%22/g, '%5C%5C%5C%22');
            safeSqlString = safeSqlString.replace(/%5C''/g, '%5C%5C%27%27');
        }

        return safeSqlString;
    }

    static removeEmoji(str: string) {
        return str.replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '');
    }

    static containsEncodedComponents(string: string) {
        return decodeURI(string) !== decodeURIComponent(string);
    }

    static containsReservedUrlCharacters(string: string) {
        return string.match(/[!*();:@&=+$,/?#[\]{}^|`%]/g);
    }

    static cleanupWhitespace(s: string): string {
        return s.replace(/\s+$/g, '').trim();
    }
}
