<div class="flex-row-new workspace-duplication-details">
    <div class="flex7-new duplicate-workspace-details">
        <h4 class="sub-heading font-title">{{ 'Common.Details' | translate }}</h4>
        <workspace-name [modifyWorkspace]="modifyWorkspace" (isNameValid)="onValidationChange($event)"></workspace-name>

        <div class="form-element pt-1">
            <gsp-checkbox
                [(value)]="useSameCoordinateAsSource"
                [id]="'useSameCoordinateAsSource'"
                [label]="'TCS.Workspace.Duplicate.useSameCoordinateAsSource' | translate"
                (valueChange)="setDuplicateWorkspaceOption()"
            ></gsp-checkbox>
        </div>
    </div>

    <div class="flex5-new duplicate-workspace-coordinate-system">
        <workspace-coordinate-system-details [workspace]="sourceWorkspace"></workspace-coordinate-system-details>
    </div>
</div>
