import { User } from '../../user/user';
import { TCFileStatus } from './TCFile.service';

export interface TCFileDTO {
    id: string;
    name: string;
    type: string;
    versionId: string;
    parentId: string;
    permission: string; // e.g. FULL_ACCESS
    modifiedBy: Partial<User>;
    projectId: string;
    thumbnailUrl: string;
    modifiedOn: Date;
    path: {
        id: string;
        name: string;
        versionId: string;
    }[];
    status: TCFileStatus;
}
export class TCFile {
    // DTO properties
    id: string = null;
    name: string = null;
    type: string = null;
    versionId: string = null;
    parentId: string = null;
    permission: string = null; // e.g. FULL_ACCESS
    modifiedBy: string = null;
    projectId: string = null;
    thumbnailUrl: string = null;
    modifiedOn: Date = null;
    path: {
        id: string;
        name: string;
        versionId: string;
    }[] = [];
    status: TCFileStatus = null; // e.g. DONE

    // Added properties
    hasChildren = false;
    contentStorageObjectId: string = null;

    // Static methods
    static fromDTO(dto: TCFileDTO, projectId: string): TCFile {
        let tcFileData = new TCFile();
        tcFileData.projectId = projectId;
        // --------------
        // Create from API response DTO
        if (dto) {
            tcFileData.id = dto.id;
            tcFileData.name = dto.name;
            tcFileData.parentId = dto.parentId;
            tcFileData.projectId = dto.projectId;
            tcFileData.versionId = dto.versionId;
            tcFileData.permission = dto.permission;
            tcFileData.modifiedOn = dto.modifiedOn;
            tcFileData.modifiedBy = dto.modifiedBy.firstName + ' ' + dto.modifiedBy.lastName;
            tcFileData.hasChildren = false;
            tcFileData.type = dto.type;
            tcFileData.thumbnailUrl = dto.thumbnailUrl && dto.thumbnailUrl.length ? dto.thumbnailUrl[0] : null;
            tcFileData.path = dto.path;
            tcFileData.status = dto.status;
        }
        return tcFileData;
    }
}
