import { Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ValueAccessorBase } from './value-accessor-base';

@Component({
    template: `
        <div [class]="radioLabelClass">
            <input
                [id]="'radio-' + id"
                type="radio"
                [value]="radioValue"
                [name]="name"
                [(ngModel)]="value"
                [disabled]="disabled"
                [class]="radioInputClass"
            />
            <div [class]="'radiobtn ' + radioButtonClass"></div>
            <label [hidden]="!valueText" [for]="'radio-' + id">{{ valueText }}</label>
        </div>
    `,
    selector: 'gsp-radio',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: GspRadioComponent,
            multi: true
        }
    ]
})
export class GspRadioComponent extends ValueAccessorBase<any> {
    @Input()
    disabled: boolean;

    @Input()
    id: string;

    @Input()
    name: string;

    @Input()
    radioValue: string;

    @Input()
    valueText: string;

    @Input()
    radioLabelClass = 'radio map-radio-label';

    @Input()
    radioInputClass = 'map-button-radio';

    @Input()
    radioButtonClass = 'map-button';
}
