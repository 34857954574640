import { Component, forwardRef, Input } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';
import { BooleanConverter, InputConverter } from 'src/app/shared/common/components/input-converter.decorator';
import { ValueAccessorBase } from 'src/app/shared/common/components/value-accessor-base';

@Component({
    selector: 'date-range-field',
    templateUrl: './date-range-field.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateRangeFieldComponent),
            multi: true
        }
    ],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class DateRangeFieldComponent extends ValueAccessorBase<{ lo: string; hi: string }> {
    @Input()
    @InputConverter(BooleanConverter)
    enabled = true;

    @Input()
    label: string;

    @Input()
    name: string;

    @Input()
    minimum: Date;

    @Input()
    maximum: Date;

    @Input()
    minimumFieldName: string;

    @Input()
    maximumFieldName: string;

    @Input()
    minimumPlaceholder = '';

    @Input()
    maximumPlaceholder = '';

    get maxForMinField(): any {
        if (
            this.value.hi === null ||
            this.value.hi === undefined ||
            this.value.lo === null ||
            this.value.lo === undefined
        ) {
            return undefined;
        }

        return this.value.hi > this.value.lo ? this.value.hi : undefined;
    }
}
