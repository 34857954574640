import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { EnvironmentService } from 'src/app/shared/common/environment/environment.service';
import { Template } from 'src/app/shared/template-services/template';

import { GeoTemplate } from '../../template-services/templateDTO';
import { MapWorkspace } from '../mapWorkspace/map-workspace';
import { GeoLayer, GeoLayers, Layer, LayerType } from './layer';

@Injectable({
    providedIn: 'root'
})
export class LayerService {
    constructor(
        private http: HttpClient,
        private envService: EnvironmentService,
        private translate: TranslationService
    ) {}

    getLayers(projectId: string, workspaceId: string): Promise<Layer[]> {
        const path = '/projects/' + projectId + '/layers';

        const options: object = {
            headers: {
                Range: 'items=0-'
            }
        };

        if (workspaceId) {
            Object.assign(options, {
                params: {
                    'query.workspaceId': workspaceId,
                    'query.sortBy': 'LayerName ASC',
                    'query.layerTypes': [LayerType.IMPORTED_LAYER, LayerType.TEMPLATE_LAYER]
                }
            });
        }

        return lastValueFrom(this.http.get<GeoLayers>(this.envService.apiUrl + path, options)).then(
            response =>
                (response.items &&
                    response.items.map(item => {
                        let layer = Layer.fromDTO(item);
                        layer.workspaceId = workspaceId || null;
                        layer.projectId = projectId || null;
                        layer.isLocked = layer.getLockStatus(workspaceId);
                        return layer;
                    })) ||
                []
        );
    }

    // Later we will remove getLayers functiona
    getLayersWithPagination(
        projectId: string,
        workspaceId: string,
        searchLayerName: string,
        startIndex: number = 0,
        pageSize: number,
        workspace: MapWorkspace
    ): Observable<{ items: Layer[]; total: number; status?: number; responseForWorkspaceId?: string }> {
        const path = '/projects/' + projectId + '/layers';

        const endIndex = pageSize ? (startIndex + (pageSize - 1)).toString() : '';

        const params = {
            sortBy: 'LayerName ASC'
        };

        if (workspaceId) {
            Object.assign(params, { 'query.workspaceId': workspaceId });
        }

        if (searchLayerName) {
            Object.assign(params, { 'query.name': searchLayerName });
        }

        if (workspace) {
            Object.assign(params, { 'query.datumComponentId': workspace.coordinateSystem.datumComponentId });
        }

        // ! It's important to type opts to object so typescript compiler finds right http.get overload
        const options: object = {
            headers: {
                ['Range']: 'items=' + startIndex + '-' + endIndex
            },
            params: params,
            observe: 'response',
            responseType: 'json'
        };

        return this.http.get<HttpResponse<GeoLayers>>(this.envService.apiUrl + path, options).pipe(
            map(response => {
                let layers = response.body.items.map(item => {
                    let layer = Layer.fromDTO(item);
                    layer.workspaceId = workspaceId || null;
                    layer.projectId = projectId || null;
                    layer.isLocked = layer.getLockStatus(workspaceId);
                    return layer;
                });
                return {
                    total: response.body.total,
                    status: response.status,
                    items: layers,
                    responseForWorkspaceId: workspace.id
                };
            })
        );
    }

    // ? GIM 17/5/2019 - Note that the status above is treated inconsistently to the status below ?

    getImportLayerStatus(projectId: string, layer: Layer): Promise<Layer> {
        let fileStatusPath = '/files/' + layer.fileVersionId + '/status';

        return lastValueFrom(
            this.http.get<any>(this.envService.connectApiUrl + fileStatusPath, {
                params: { versionId: layer.fileVersionId }
            })
        ).then(
            response => {
                if (response.status > -1 && response.status < 100) {
                    return layer;
                } else {
                    return this.getLayerById(projectId, layer.id).then(lyr => lyr);
                }
            },
            () => layer
        );
    }

    getLayerById(projectId: string, id: string): Promise<Layer> {
        let path = '/projects/' + projectId + '/layers/' + id;
        return lastValueFrom(this.http.get<GeoLayer>(this.envService.apiUrl + path)).then(response => {
            let layer = Layer.fromDTO(response);
            layer.projectId = projectId;
            return layer;
        });
    }

    getLayersByFileId(
        projectId: string,
        fileId?: string,
        fileVersionId?: string,
        workspaceId?: string
    ): Promise<Layer[]> {
        let path = '/projects/' + projectId + '/layers';
        let queryString = '?';

        if (workspaceId) {
            queryString += '&query.workspaceId=' + workspaceId;
        }

        if (fileVersionId) {
            queryString += '&query.fileVersionId=' + fileVersionId;
        }
        if (fileId) {
            queryString += '&query.fileId=' + fileId;
        }
        queryString = queryString.replace('?&', '?');

        return lastValueFrom(this.http.get<GeoLayers>(this.envService.apiUrl + path + queryString)).then(response =>
            response.items.map(item => {
                let layer = Layer.fromDTO(item);
                layer.projectId = projectId;
                layer.isLocked = layer.getLockStatus(workspaceId);
                return layer;
            })
        );
    }

    getLayersByMapWorkspace(mapWorkspace: MapWorkspace): Promise<Layer[]> {
        if (!mapWorkspace.isFileViewer) {
            return this.getLayers(mapWorkspace.projectId, mapWorkspace.id);
        } else {
            return this.getLayersByFileId(
                mapWorkspace.projectId,
                mapWorkspace.connectFileIds[0],
                mapWorkspace.connectFileLastAssimIds[0],
                mapWorkspace.id
            );
        }
    }

    createLayer(projectId: string, layerProperties: any): Promise<Layer> {
        let path = '/projects/' + projectId + '/layers?';

        return lastValueFrom(this.http.post<GeoLayer>(this.envService.apiUrl + path, layerProperties)).then(
            response => {
                let layer = Layer.fromDTO(response);
                layer.projectId = projectId;
                layer.updateCacheTimeStamp();
                return layer;
            }
        );
    }

    updateLayer(projectId: string, layerId: string, layerProperties: any): Promise<Layer> {
        let path = '/projects/' + projectId + '/layers/' + layerId;

        return lastValueFrom(this.http.put<GeoLayer>(this.envService.apiUrl + path, layerProperties)).then(response => {
            let layer = Layer.fromDTO(response);
            layer.projectId = projectId;
            return layer;
        });
    }

    deleteLayer(projectId: string, layerId: string): Promise<void> {
        let path = '/projects/' + projectId + '/layers/' + layerId;
        return lastValueFrom(this.http.delete<void>(this.envService.apiUrl + path));
    }

    getLayersByTemplateId(projectId: string, templateId: string): Promise<Layer[]> {
        let path = '/projects/' + projectId + '/layers?query.templateId=' + templateId;

        return lastValueFrom(this.http.get<GeoLayers>(this.envService.apiUrl + path)).then(
            response => response && response.items.map(layer => Layer.fromDTO(layer))
        );
    }

    getTemplateByLayerId(projectId: string, layerId: string): Promise<Template> {
        // Note: returns Template, not (TemplateLite)
        let path = '/projects/' + projectId + '/layers/' + layerId + '/template';

        return lastValueFrom(this.http.get<GeoTemplate>(this.envService.apiUrl + path)).then(response =>
            Template.fromDTO({ template: response }, this.translate)
        );
    }
}
