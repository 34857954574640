import { Subject } from 'rxjs';
import { TranslationService } from 'src/app/core/translation/translation.service';

import { CodedChoiceOption } from 'src/app/feature/map-viewer/side-panel/feature-panel/feature-fields/feature-field';
import { GeneralUtils } from '../common/utility/general-utils';
import { FieldDefinitionApplication, Schema } from './field/fieldDefinition';

export enum FieldType {
    Image = 'Image',
    Signature = 'Signature',
    Date = 'Date',
    General = 'General',
    Number = 'Number',
    Length = 'Length',
    Angle = 'Angle',
    Text = 'Text',
    Autofield = 'Autofield',
    Choice = 'Choice',
    CodedChoice = 'CodedChoice',
    Barcode = 'Barcode',
    YesNo = 'YesNo',
    Sensor = 'Sensor'
}

export interface FieldTypeOption {
    uuid: string;
    label: string;
    autoField?: string;
    filterType: string;
    name: string;
    geometryType: string;
    codedChoiceOrChoiceList?: [{ code: string; description: string } | { text: string }];
    decimalPlaces?: number;
    allowMultiSelect: boolean;
}

export enum LayoutFieldType {
    Group = 'Group',
    PageHeader = 'PageHeader'
}

export enum AutoFieldModelType {
    CollectedBy = 'CollectedBy',
    CreationDateTime = 'CreationDateTime',
    UpdateDateTime = 'UpdateDateTime',
    EstimatedAccuracy = 'EstimatedAccuracy',
    DeviceType = 'DeviceType',
    DeviceID = 'DeviceID',
    CorrectionStatus = 'CorrectionStatus',
    CorrectionSource = 'CorrectionSource',
    GeometryCaptureType = 'GeometryCaptureType',
    PDOP = 'PDOP',
    HDOP = 'HDOP',
    GeometryLength = 'GeometryLength',
    GeometryArea = 'GeometryArea',
    Todo = 'Todo',
    EstimatedVerticalPrecision = 'EstimatedVerticalPrecision',
    Workspace = 'Workspace',
    Increment = 'Increment',
    XYZ = 'XYZ',
    XPosition = 'XPosition',
    YPosition = 'YPosition',
    ZPosition = 'ZPosition'
}

export enum FieldStatus {
    NEW = 'New',
    SAVED = 'Saved'
}

// base class
export class Field {
    DTOFields: string[];

    id: string;
    uuid: string;
    type: FieldType | LayoutFieldType | AutoFieldModelType;
    displayName: string;
    name: string;
    groupId: string;
    required: boolean;
    readOnly: boolean;
    ordinal: number;
    requiredForUpdate: boolean;
    readOnlyForUpdate: boolean;
    visibleForSitevision: boolean;
    repeatField: boolean;
    incrementRepeatField: boolean;
    incrementRepeatValue: number;
    isVisible?: boolean;

    status: FieldStatus;
    stopConditionedOrTargeted: number;
    conditionRuleErrors: number;
    targetRuleErrors: number;

    incompleteRule: boolean;
    conditioned: boolean;
    targeted: boolean;

    target: string; // TODO: MY used in removeTarget

    conditionErrors = false;
    targetErrors = false;

    locked = false;
    fields?: Field[];
    defaultValue?: string;
    sensorMappingId?: string;
    scale?: number;
    values?: CodedChoiceValue[] | ChoiceValue[];
    options?: string[] | CodedChoiceOption[];

    fieldUpdatedStream$ = new Subject<void>();

    constructor(
        translate: TranslationService,
        field: any,
        schema?: Schema,
        fieldDefApplication?: FieldDefinitionApplication
    ) {
        this.DTOFields = [
            'uuid',
            'type',
            'displayName',
            'name',
            'groupId',
            'required',
            'readOnly',
            'ordinal',
            'requiredForUpdate',
            'readOnlyForUpdate',
            'repeatField',
            'incrementRepeatValue'
        ];

        this.uuid = field.uuid || '';
        this.type = field.type || field.schema.id || FieldType.Text;
        this.displayName = field.displayName || translate.instant('TC.Common.Text');
        this.name = field.name || this.displayName.replace(/\s/g, '').toLowerCase();
        this.groupId = field.groupId || '';
        this.required = field.required || false;
        this.readOnly = field.readOnly || false;
        this.ordinal = field.ordinal;
        this.requiredForUpdate = field.requiredForUpdate || false;
        this.readOnlyForUpdate = field.readOnlyForUpdate || false;
        this.visibleForSitevision = field.visibleForSitevision || false;

        if (this.type === LayoutFieldType.Group) {
            let groupField = this as unknown as GroupField;
            groupField.fields = field.fields || groupField.fields || [];
        }

        if (schema && schema.properties) {
            Object.keys(schema.properties).forEach((propertyKey: keyof Field) => {
                (this[propertyKey] as any) = field[propertyKey] !== undefined ? field[propertyKey] : this[propertyKey];
                this.DTOFields.push(propertyKey);
            });
        } else {
            Object.keys(field).forEach((attributeName: keyof Field) => {
                (this[attributeName] as any) =
                    field[attributeName] !== undefined ? field[attributeName] : this[attributeName];
                this.DTOFields.push(attributeName);
            });
        }

        // Add default values for header properties
        if (fieldDefApplication?.layoutSchema?.properties) {
            fieldDefApplication?.layoutSchema?.properties.forEach(prop => {
                if (prop.position === 'header' && this[prop.id as keyof Field] === undefined) {
                    (this[prop.id as keyof Field] as any) = prop.default;
                }
            });
        }

        // only for terraflex existing fields
        if (field.numberType) {
            this.type = field.numberType !== FieldType.General ? field.numberType : field.type;
            let numberField = this as unknown as NumberField;
            delete numberField.numberType;
        }
        if (field.modelType) {
            this.type = field.modelType;
            let autoField = this as unknown as AutoField;
            delete autoField.modelType;
        }

        // extra properties
        this.status = field.status || FieldStatus.SAVED;

        // setting incrementRepeatField to true if incrementRepeatValue has value
        this.incrementRepeatField = field.incrementRepeatField = !GeneralUtils.isNullUndefinedOrNaN(
            field.incrementRepeatValue
        );
    }

    setStopConditionedOrTargeted(increment: boolean): void {
        if (this.stopConditionedOrTargeted === undefined) {
            this.stopConditionedOrTargeted = 0;
        }
        if (increment) {
            this.stopConditionedOrTargeted++;
        } else {
            this.stopConditionedOrTargeted--;
        }
        if (this.stopConditionedOrTargeted < 0) {
            this.stopConditionedOrTargeted = 0;
        }
    }

    toDTO(): Field {
        let field: any = {};
        let DTOContext = this;

        this.DTOFields.forEach((DTOField: keyof Field) => {
            field[DTOField] = DTOContext[DTOField];
        });

        if (Object.values(AutoFieldModelType).includes(field.type)) {
            field.modelType = field.type;
            field.type = FieldType.Autofield;
        }
        let numberType = [FieldType.Number, FieldType.Length, FieldType.Angle, FieldType.General];
        if (numberType.indexOf(field.type) !== -1) {
            if (field.type === FieldType.Number) {
                field.numberType = FieldType.General;
            } else {
                if (field.type === FieldType.Angle) {
                    field.angleUnit = 'Degrees';
                }
                field.numberType = field.type;
                field.type = FieldType.Number;
            }
        }

        // Added for handling null in allowMultipleImages property
        if (field.type === FieldType.Image) {
            if (field.allowMultipleImages === null) {
                field.allowMultipleImages = false;
            }
        }

        if (field.type === FieldType.Choice || field.type === FieldType.CodedChoice) {
            // Added for handling empty allowMultiSelect property
            if (GeneralUtils.isNullUndefinedOrNaN(field.allowMultiSelect) && field.type === FieldType.Choice) {
                field.allowMultiSelect = false;
            }

            // copy values property over
            field['values'] = DTOContext['values' as keyof Field];
        }

        // setting incrementRepeatValue to null if incrementRepeatField is false
        if (!this.incrementRepeatField) {
            field.incrementRepeatValue = null;
        }

        return field;
    }
}

// type is Text
export class TextField extends Field {
    value: string;
    length: number;
}

// type is Barcode
export class BarcodeField extends Field {
    value: string;
    length: number;
}

// type is Date
export class DateField extends Field {
    value: Date;
    minimum?: Date;
    maximum?: Date;
}

// type is Number, Angle, or Length
export class NumberField extends Field {
    value: number;
    scale: number;
    minimum?: number;
    maximum?: number;
    numberType?: string;
    angleUnit?: string;
    distanceUnit?: string;
}

// type is Choice
export class ChoiceField extends Field {
    allowMultiSelect: boolean;
    allowNonDomainValues: boolean;
    length: number;
    values: ChoiceValue[];
}

export interface ChoiceValue {
    text: string;
    default: boolean;
    choiceSelected?: boolean[];
}

// type is CodedChoice
export class CodedChoiceField extends Field {
    values: CodedChoiceValue[];
}

export interface CodedChoiceValue {
    code: string;
    description: string;
    default: boolean;
    choiceSelected?: boolean[];
}

// type is Image or Signature
export class ImageSignatureField extends Field {
    value: string[];
}

// type is YesNo
export class YesNoField extends Field {
    value: boolean;
}

// type is isGroupAutoField(field) (i.e. GeometryLength, GeometryArea, ...)
export class AutoField extends Field {
    modelType: AutoFieldModelType;
    unit: string; // degrees, meters, kilometers, ...
    isUTC: boolean; // for modelType === 'CreationDateTime' or 'UpdateDateTime'
    value: string | number;
}

// type is PageHeader
export class PageHeaderField extends Field {
    pageCount?: number;
}

// type is Group
export class GroupField extends Field {
    fields: Field[];
}
