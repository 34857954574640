import { GeneralUtils } from 'src/app/shared/common/utility/general-utils';
import {
    CoordinateSystemComponent,
    MapWorkspace,
    WGS1984_DATUM_COMPONENT_ID
} from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { GeoLink } from 'src/app/shared/types/geo';

import { Ntrip } from './Ntrip';
import { InternetServerTypes, RtcBaseType, RtcSourceTypes, SerialPortParities } from './rtc-options';

export class RtcDatum {
    datumName = '';
    datumType = '';
    datumSystemId = '';
}

export interface GeoRealtimeCorrections {
    links?: GeoLink[];
    total?: number;
    items?: GeoRealtimeCorrection[];
    eTag?: string;
}

export interface GeoRealtimeCorrection {
    name?: string;
    description?: string;
    createdUtc?: Date;
    updatedUtc?: Date;
    sourceType1?: RtcSourceTypes;
    sourceType2IsSbas?: boolean;
    baseType?: RtcBaseType;
    datumSystemId?: number;
    datumComponentId?: string;
    internetServerType?: InternetServerTypes;
    internetUrl?: string;
    internetPort?: number;
    internetUsername?: string;
    internetPassword?: string;
    ntripSettingsString?: string;
    serialPortComPort?: string;
    serialPortBaudRate?: number;
    serialPortDataBits?: number;
    serialPortStopBits?: number;
    serialPortParity?: SerialPortParities;
    isGlobal?: boolean;
    workspaceIds?: string[];
    excludedWorkspaceIds?: string[];
    links?: GeoLink[];
    id?: string;
    eTag?: string;
}

export interface GeoPostRealtimeCorrectionRequest {
    realtimeCorrection?: RealtimeCorrectionBody;
    isGlobal?: boolean;
    workspaceIds?: string[];
    excludedWorkspaceIds?: string[];
}

interface RealtimeCorrectionBody {
    name?: string;
    description?: string;
    sourceType1?: RtcSourceTypes;
    sourceType2IsSbas?: boolean;
    baseType?: RtcBaseType;
    datumSystemId?: number;
    datumComponentId?: string;
    internetServerType?: InternetServerTypes;
    internetUrl: string;
    internetPort: number;
    internetUsername?: string;
    internetPassword?: string;
    ntripSettingsString?: string;
    serialPortComPort?: string;
    serialPortBaudRate?: number;
    serialPortDataBits?: number;
    serialPortStopBits?: number;
    serialPortParity?: SerialPortParities;
}

export class Rtc {
    // DTO properties
    id = '';
    name = '';
    description = '';
    createdUtc: Date = new Date();
    lastModifiedUtc: Date = new Date();
    sourceType1: RtcSourceTypes = RtcSourceTypes.SBAS;
    sourceType2IsSbas = true;
    baseType: RtcBaseType = RtcBaseType.SINGLEBASE;
    datumSystemId: number;
    datumComponentId: string;
    internetServerType: InternetServerTypes = InternetServerTypes.RTX;
    internetUrl: string = null;
    internetPort: number = null;
    authByFieldUser = false;
    internetUsername = '';
    internetPassword = '';
    ntripSettingsString = '';
    serialPortComPort = '';
    serialPortBaudRate = 0;
    serialPortDataBits = 0;
    serialPortStopBits = 0;
    serialPortParity = SerialPortParities.NONE;
    isGlobal = true;
    workspaceIds: string[] = [];
    excludedWorkspaceIds: string[] = [];

    // added properties
    isCompatible = false;
    correctionDatum: CoordinateSystemComponent = null;
    ntrip: Ntrip = null;
    isAssignedToCurrentWorkspace?: boolean;

    constructor() {}

    static fromDTO(dto: GeoRealtimeCorrection): Rtc {
        const rtc = new Rtc();

        // --------------
        // Create from API response DTO
        if (dto) {
            rtc.name = dto.name;
            rtc.description = dto.description;
            rtc.createdUtc = dto.createdUtc;
            rtc.sourceType1 = dto.sourceType1;
            rtc.sourceType2IsSbas = dto.sourceType2IsSbas;
            rtc.baseType = dto.baseType;
            rtc.datumSystemId = dto.datumSystemId;
            rtc.datumComponentId = dto.datumComponentId;
            rtc.internetServerType = dto.internetServerType;
            rtc.internetUrl = dto.internetUrl;
            rtc.internetPort = dto.internetPort;
            rtc.authByFieldUser = !dto.internetUsername && !dto.internetPassword;
            rtc.internetUsername = dto.internetUsername;
            rtc.internetPassword = dto.internetPassword;
            rtc.ntripSettingsString = dto.ntripSettingsString || '';
            rtc.serialPortComPort = dto.serialPortComPort;
            rtc.serialPortBaudRate = dto.serialPortBaudRate;
            rtc.serialPortDataBits = dto.serialPortDataBits;
            rtc.serialPortStopBits = dto.serialPortStopBits;
            rtc.serialPortParity = dto.serialPortParity;
            rtc.workspaceIds = dto.workspaceIds || [];
            rtc.excludedWorkspaceIds = dto.excludedWorkspaceIds || [];
            rtc.id = dto.id;
            rtc.isGlobal = dto.isGlobal;

            rtc.ntrip = Ntrip.fromSettingsString(rtc.ntripSettingsString);
        }

        return rtc;
    }

    public setName(name: string): void {
        this.name = name;
    }

    public toDefaultRtcDTO(): void {
        this.internetServerType = InternetServerTypes.RTX;
    }

    public toRtcInternetDTO(): void {
        this.internetServerType =
            this.internetServerType !== InternetServerTypes.RTX ? this.internetServerType : InternetServerTypes.NTRIP;
        this.baseType = this.baseType || RtcBaseType.SINGLEBASE;

        // Setting irrelevent fields to null
        this.serialPortComPort =
            this.serialPortBaudRate =
            this.serialPortDataBits =
            this.serialPortStopBits =
            this.serialPortParity =
                null;
    }

    public toRtcSerialDTO(): void {
        this.baseType = this.baseType || RtcBaseType.SINGLEBASE;
        this.serialPortComPort = this.serialPortComPort || 'COM1';
        this.serialPortBaudRate = this.serialPortBaudRate || 9600;
        this.serialPortDataBits = this.serialPortDataBits || 8;
        this.serialPortStopBits = this.serialPortStopBits || 1;
        this.serialPortParity = this.serialPortParity || SerialPortParities.NONE;

        // Setting irrelevent fields to null
        this.internetUrl = this.internetPort = this.internetUsername = this.internetPassword = null;
    }

    public isLegacyRtc(): boolean {
        // In old/legacy rtcs, datum is captured in datumSystemId property which is a number
        return !GeneralUtils.isNullUndefinedOrNaN(this.datumSystemId);
    }

    public isPostCsSupportRtc(): boolean {
        // In new post cs support rtcs, datum is captured in datumComponentId property which is a string
        return !GeneralUtils.isNullUndefinedOrEmpty(this.datumComponentId);
    }

    public isExistingRtc(): boolean {
        return this.sourceType1 === RtcSourceTypes.INTERNET || this.sourceType1 === RtcSourceTypes.SERIAL;
    }

    public isCompatibleWithWorkspace(workspace: MapWorkspace): boolean {
        // Not a workspace
        if (GeneralUtils.isNullUndefinedOrNaN(workspace.id)) {
            return false;
        }

        switch (this.sourceType1) {
            case RtcSourceTypes.SBAS:
            case RtcSourceTypes.TRIMBLE_RTX:
            case RtcSourceTypes.SATELLITE_RTX:
                return true;
            case RtcSourceTypes.INTERNET:
            case RtcSourceTypes.SERIAL:
                if (this.isPostCsSupportRtc()) {
                    // if the rtc has a datumComponentId - handle new post cs support RTC
                    // favour localReferenceFrameComponentId over datumComponentId
                    const workspaceDatumId =
                        workspace.coordinateSystem.localReferenceFrameComponentId ||
                        workspace.coordinateSystem.datumComponentId;
                    return (
                        workspaceDatumId === this.datumComponentId || workspaceDatumId === WGS1984_DATUM_COMPONENT_ID
                    );
                } else {
                    // if the rtc has no datumComponentId - handle legacy RTC
                    if (workspace.coordinateSystem.datumComponentId === WGS1984_DATUM_COMPONENT_ID) {
                        return true;
                    } else {
                        return false;
                    }
                }
        }
    }

    private setSourceType2IsSbas(sourceType2IsSbas: boolean, sourceType1: string): boolean {
        return sourceType1 === RtcSourceTypes.SBAS ? false : sourceType2IsSbas;
    }

    private setNtripSettingsString(ntrip: Ntrip, sourceType1: string, internetServerType: string): string {
        return sourceType1 === RtcSourceTypes.INTERNET && internetServerType === InternetServerTypes.NTRIP
            ? ntrip.ntripServerSettings
            : null;
    }

    private setWorkspaceIds(isGlobal: boolean, workspaceIds: string[]): string[] {
        if (isGlobal) {
            return [];
        } else {
            return workspaceIds;
        }
    }

    public toDto(): GeoPostRealtimeCorrectionRequest {
        return {
            realtimeCorrection: {
                name: this.name,
                description: this.description,
                sourceType1: this.sourceType1,
                sourceType2IsSbas: this.setSourceType2IsSbas(this.sourceType2IsSbas, this.sourceType1),
                baseType: this.baseType,
                datumSystemId: this.datumSystemId,
                datumComponentId: this.datumComponentId,
                internetServerType: this.internetServerType,
                internetUrl: this.internetUrl,
                internetPort: this.internetPort,
                internetUsername: this.internetUsername,
                internetPassword: this.internetPassword,
                ntripSettingsString: this.setNtripSettingsString(this.ntrip, this.sourceType1, this.internetServerType),
                serialPortComPort: this.serialPortComPort,
                serialPortBaudRate: this.serialPortBaudRate,
                serialPortDataBits: this.serialPortDataBits,
                serialPortStopBits: this.serialPortStopBits,
                serialPortParity: this.serialPortParity
            },
            workspaceIds: this.setWorkspaceIds(this.isGlobal, this.workspaceIds),
            excludedWorkspaceIds: this.excludedWorkspaceIds,
            isGlobal: this.isGlobal
        };
    }
}
