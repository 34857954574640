import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { Ntrip, NtripAuthentication } from 'src/app/feature/rtc/Ntrip';
import { Rtc, RtcDatum } from 'src/app/feature/rtc/Rtc';
import { DataListTypes, InternetServerTypes, RtcOptions } from 'src/app/feature/rtc/rtc-options';
import { RtcService } from 'src/app/feature/rtc/rtc.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { NotificationType } from 'src/app/shared/common/components/gsp-notification/gsp-notification.component';
import { GspNumberType } from 'src/app/shared/common/components/gsp-number/gsp-number.component';
import { GspTextType } from 'src/app/shared/common/components/gsp-text/gsp-text.component';
import { ModalSize } from 'src/app/shared/common/modal-sizes';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';
import { GeneralUtils } from 'src/app/shared/common/utility/general-utils';
import { CoordinateSystemComponent } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';

@Component({
    selector: 'rtc-internet',
    templateUrl: './rtc-internet.component.html'
})
export class RtcInternetComponent implements OnInit {
    @Input() editingRtc: Rtc;
    @Input() originalRtc: Rtc;

    @Output()
    closed = new EventEmitter<{ rtc: Rtc; isValid: boolean }>(null);

    @ViewChild('rtcEditForm', { static: true })
    public rtcEditForm: NgForm;

    public selectedNtrip: Ntrip = null;
    public showNtrip = false;
    public showDatums = false;
    public datumSetByNtrip: boolean;
    public ntripList: Ntrip[] = [];
    public filteredNtripList: Ntrip[];
    public datums: CoordinateSystemComponent[] = [];
    public filteredDatums: CoordinateSystemComponent[];
    public clearSearch = new Subject<void>();

    // expose enums to template
    public ButtonType = ButtonType;
    public InternetServerTypes = InternetServerTypes;
    public DataListTypes = DataListTypes;
    public ModalSize = ModalSize;
    public rtcDatum: RtcDatum = null;
    public ntripError: string = null;
    public ntripIsLoading = false;
    public datumIsLoading = true;
    public datumError = false;
    public NotificationType = NotificationType;
    public NtripAuthentication = NtripAuthentication;
    public GspTextType = GspTextType;
    public GspNumberType = GspNumberType;

    // RTC options
    public sourceTypes = RtcOptions.sourceTypes;
    public baseTypes = RtcOptions.baseTypes;
    public internetServerTypes = RtcOptions.internetServerTypes;
    public secondarySourceTypes = RtcOptions.secondarySourceTypes;

    private originalSelectedRtc: Rtc = null;

    constructor(private rtcService: RtcService, private translate: TranslationService) {}

    public ngOnInit(): void {
        const promises: Promise<void>[] = [];
        promises.push(
            this.rtcService.getDatumComponents().then(datums => {
                // filtering to remove dynamic datums and sorting datums alphabetically
                this.datums = _.sortBy(
                    datums.filter(datum => !datum.isDynamic && !this.rtcService.isIncompatible(datum.componentID)),
                    datum => datum.name.toLowerCase()
                );
                this.filteredDatums = _.cloneDeep(this.datums);
            })
        );

        this.originalSelectedRtc = CloneUtils.cloneDeep(this.editingRtc);
        this.editingRtc.toRtcInternetDTO();
        this.selectedNtrip = Ntrip.fromSettingsString(this.editingRtc.ntripSettingsString);

        if (this.editingRtc.isLegacyRtc()) {
            promises.push(
                this.rtcService.getDatumBySystemId(this.editingRtc.datumSystemId).then(datum => {
                    this.rtcDatum = datum;
                })
            );
        }

        if (this.editingRtc.isPostCsSupportRtc()) {
            promises.push(
                this.rtcService.getDatumByComponentId(this.editingRtc.datumComponentId).then(datum => {
                    this.editingRtc.correctionDatum = datum;
                })
            );
        }

        Promise.all(promises)
            .then(() => {
                this.datumIsLoading = false;
            })
            .catch(() => {
                this.datumError = true;
            });
    }

    // ----------------- NTRIP SOURCE ------------------------

    public get ntripRequiresAuthentication(): boolean {
        return (
            this.editingRtc.ntrip &&
            this.editingRtc.ntrip.authentication !== NtripAuthentication.No_AUTHENTICATION &&
            !GeneralUtils.isNullUndefinedOrNaN(this.editingRtc.ntrip.authentication)
        );
    }

    public loadNtrip(): void {
        this.showNtrip = false;
        this.showDatums = false;
        this.clearSearch.next(null);
        this.ntripIsLoading = true;
        this.ntripError = null;
        this.editingRtc.internetPort = GeneralUtils.isNullUndefinedOrNaN(this.editingRtc.internetPort)
            ? null
            : this.editingRtc.internetPort;
        if (this.editingRtc.internetUrl) {
            this.rtcService.getNtripSettings(this.editingRtc.internetUrl, this.editingRtc.internetPort).then(
                ntripList => {
                    if (ntripList.length) {
                        this.showNtrip = true;
                        this.ntripList = ntripList;
                        this.filteredNtripList = _.cloneDeep(this.ntripList);
                    } else {
                        this.showNtrip = false;
                        this.ntripError = this.translate.instant('TCS.Mapviewer.Rtc.Messages.NtripWrongSettings');
                    }
                    this.ntripIsLoading = false;
                },
                () => {
                    this.showNtrip = false;
                    this.ntripIsLoading = false;
                    this.ntripError = this.translate.instant('TCS.Mapviewer.Rtc.Messages.NtripWrongSettings');
                }
            );
        } else {
            this.ntripIsLoading = false;
            this.showNtrip = false;
            this.ntripError = this.translate.instant('TCS.Mapviewer.Rtc.Messages.NtripWrongSettings');
        }
    }

    public selectNtrip(ntrip: Ntrip): void {
        this.editingRtc.ntripSettingsString = ntrip.ntripServerSettings;
        this.editingRtc.ntrip = Ntrip.fromSettingsString(ntrip.ntripServerSettings);
        this.showNtrip = false;
        if (this.editingRtc.ntrip.referenceFrame && !this.datumIsLoading) {
            this.selectDatumFromReferenceFrame();
        } else {
            if (this.datumSetByNtrip) {
                this.resetDatum();
            }
            this.datumSetByNtrip = false;
        }
    }

    public getErrorText(field: NgModel): string {
        if (field.name === 'url') {
            const isRequiredError = _.filter([field.errors], 'required');
            const isUrlInvalid = _.filter([field.errors], 'urlInvalid');

            if (isRequiredError && isRequiredError.length) {
                this.ntripList = [];
                return this.translate.instant('RequiredError');
            }

            if (isUrlInvalid && isUrlInvalid.length) {
                this.resetNtripSource();
                return this.translate.instant('TC.Common.InvalidURL');
            }
        }

        if (field.name === 'port' && field.value) {
            const isPortInvalid = _.filter([field.errors], 'portInvalid');
            if (isPortInvalid && isPortInvalid.length) {
                return this.translate.instant('TCS.Mapviewer.Rtc.Messages.PortError');
            }
        }

        if (field.name === 'username' || field.name === 'password') {
            const isRequiredError = _.filter([field.errors], 'required');

            if (isRequiredError && isRequiredError.length) {
                return this.translate.instant('RequiredError');
            }
        }

        return null;
    }

    public resetNtripSource(): void {
        this.editingRtc.ntripSettingsString = '';
        this.editingRtc.ntrip = null;
        this.ntripList = [];
        this.editingRtc.internetUsername = null;
        this.editingRtc.internetPassword = null;
        this.editingRtc.authByFieldUser = false;
        if (this.datumSetByNtrip) {
            this.resetDatum();
        }
    }

    public setAuthByFieldUser(value: boolean): void {
        if (value) {
            this.editingRtc.internetUsername = null;
            this.editingRtc.internetPassword = null;
        }
    }

    // https://github.com/angular/angular/issues/23657
    // Due to this error, required property on internet username/pwd fields can't be set and validated as part of form validation.
    public isNtripAuthValid(): boolean {
        if (this.ntripRequiresAuthentication) {
            return this.editingRtc.authByFieldUser
                ? true
                : !GeneralUtils.isNullUndefinedOrNaN(this.editingRtc.internetUsername) &&
                      !GeneralUtils.isNullUndefinedOrNaN(this.editingRtc.internetPassword);
        } else {
            return true;
        }
    }

    // --------------------- DATUM ---------------------------

    public loadDatums(): void {
        this.showNtrip = false;
        this.clearSearch.next(null);
        this.showDatums = true;
    }

    public selectDatum(datum: CoordinateSystemComponent): void {
        this.editingRtc.datumComponentId = datum.componentID;
        this.showDatums = false;
    }

    public resetDatum(): void {
        this.editingRtc.datumComponentId = null;
    }

    public getDatum(datumComponentId: string): CoordinateSystemComponent {
        return this.datums.find(datum => datum.componentID === datumComponentId);
    }

    // --------------------- OTHER ---------------------------

    public save(): void {
        // update editingRtc and then close
        this.closed.emit({ rtc: this.editingRtc, isValid: this.canSave() });
    }

    public cancel(): void {
        this.closed.emit({ rtc: this.originalSelectedRtc, isValid: this.canSave() });
    }

    public indexTrack(index: number, item: any): number {
        return index;
    }

    public canSave(): boolean {
        if (this.editingRtc.internetServerType === InternetServerTypes.NTRIP) {
            // The only thing that should be allowed to be saved in non editable mode is the ntrip source credentials.
            if (this.originalRtc.isExistingRtc()) {
                return this.isNtripAuthValid();
            } else {
                return (
                    this.editingRtc.ntripSettingsString !== '' &&
                    this.rtcEditForm &&
                    this.rtcEditForm.valid &&
                    this.editingRtc.isPostCsSupportRtc() &&
                    this.isNtripAuthValid()
                );
            }
        } else {
            if (this.originalRtc.isExistingRtc()) {
                return this.ntripRequiresAuthentication ? true : false;
            } else {
                return this.rtcEditForm && this.rtcEditForm.valid && this.editingRtc.isPostCsSupportRtc();
            }
        }
    }

    public updateFilteredItems(items: CoordinateSystemComponent[] | Ntrip[]): void {
        if (!items.length) {
            this.filteredNtripList = [];
            this.filteredDatums = [];
        } else if (items[0] instanceof CoordinateSystemComponent) {
            this.filteredDatums = items as CoordinateSystemComponent[];
        } else {
            this.filteredNtripList = items as Ntrip[];
        }
    }

    private async selectDatumFromReferenceFrame(): Promise<void> {
        try {
            this.datumIsLoading = true;
            const datum = await this.rtcService.getComponentByAliasId(
                this.editingRtc.ntrip.constructAliasIdFromReferenceFrame()
            );
            this.selectDatum(datum);
            this.datumSetByNtrip = true;
        } catch (error) {
            // should have been logged in http interceptor
            if (this.datumSetByNtrip) {
                this.resetDatum();
            }
            this.datumSetByNtrip = false;
        } finally {
            this.datumIsLoading = false;
        }
    }
}
