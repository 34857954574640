<div class="panel-description" [innerHTML]="'MapViewer_RTC_description' | translate"></div>
<div
    class="panel-controls"
    *checkVisibility="{
        visibleRoles: [UserRole.ADMIN]
    }"
>
    <gsp-button
        [type]="ButtonType.TextIcon"
        buttonTooltip="{{ 'TC.Common.Add' | translate }}"
        (action)="create()"
        text="{{ 'TC.Common.Create' | translate }}"
        iconClass="i32 icon_line_plus"
        id="create-rtc"
    >
    </gsp-button>
</div>

<div class="rtc rtc-section">
    <div class="rtc__list flex-container pb-0">
        <div class="rtc__list-item rtc__list-item--header flex-row-new" *ngIf="rtcList.length > 0">
            <div class="rtc__list-item-heading flex7-new" translate="details"></div>
            <div class="rtc__list-item-heading flex2-new ml-2" translate="TC.Common.Sources"></div>
            <div class="rtc__list-item-heading flex3-new col-right"></div>
        </div>
    </div>
    <custom-scroll *ngIf="rtcList.length > 0">
        <div class="rtc__list flex-container">
            <div
                class="rtc__list-item flex-row-new"
                *ngFor="let rtc of rtcList"
                [ngClass]="{ 'rtc__list-item--disabled': !rtc.isCompatible }"
            >
                <div class="flex7-new">
                    <div class="rtc__list-item-title flex-row-new no-gutters">
                        <div class="rtc-name" title="{{ rtc.name }}">{{ rtc.name }}</div>
                        <span
                            class="badge badge--primary ml-1"
                            *ngIf="rtc.isAssignedToCurrentWorkspace && rtc.isCompatible"
                        >
                            {{ 'MapViewer_RTC_Assigned_Workspace' | translate }}
                        </span>
                        <span class="badge badge--hollow ml-1" *ngIf="!rtc.isCompatible">
                            {{ 'MapViewer_RTC_Incompatible' | translate }}
                        </span>
                    </div>
                    <div class="rtc__list-item-description mt-1" title="{{ rtc.description }}">
                        {{ rtc.description }}
                    </div>
                </div>
                <div></div>
                <div class="flex2-new">
                    <div class="flex-column">
                        <div class="rtc-title" translate>{{ getSourceType1(rtc.sourceType1).name }}</div>
                        <div class="rtc-title" translate>{{ getSourceType2(rtc.sourceType2IsSbas) }}</div>
                    </div>
                </div>
                <div class="flex3-new rtc__list-item-controls">
                    <div
                        *checkVisibility="{
                            visibleRoles: [UserRole.ADMIN]
                        }"
                    >
                        <gsp-button
                            [disabled]="!rtc.isCompatible"
                            [type]="ButtonType.Icon"
                            buttonTooltip="{{ 'MapViewer_RTC_AssignTo_Workspace' | translate }}"
                            (action)="assignRtc(rtc)"
                            iconClass="icon_assign i24"
                            *ngIf="!isRtcAssignedToCurrentWorkspace(rtc)"
                            class="rtc-link"
                            id="assign-rtc"
                        ></gsp-button>

                        <gsp-button
                            [disabled]="!rtc.isCompatible"
                            [type]="ButtonType.Icon"
                            buttonTooltip="{{ 'MapViewer_RTC_UnassignFrom_Workspace' | translate }}"
                            (action)="unassignRtc(rtc)"
                            iconClass="icon_unassign i24"
                            *ngIf="isRtcAssignedToCurrentWorkspace(rtc)"
                            class="rtc-link"
                            id="unassign-rtc"
                        ></gsp-button>

                        <gsp-button
                            [type]="ButtonType.Icon"
                            buttonTooltip="{{ 'TC.Common.Edit' | translate }}"
                            (action)="editRtc(rtc, $event)"
                            iconClass="icon_create i24"
                            id="rtc-edit"
                        ></gsp-button>

                        <gsp-button
                            [type]="ButtonType.Icon"
                            buttonTooltip="{{ 'Common.Delete' | translate }}"
                            (action)="showRtcDelete(rtc)"
                            iconClass="icon_solid_trash i24"
                            id="rtc-delete"
                        ></gsp-button>
                    </div>
                </div>
            </div>
        </div>
    </custom-scroll>
    <div class="rtc__list--empty" *ngIf="rtcList.length === 0 && !pageLoading">
        <img src="assets/images/SVGs-24scale/icon_line_positioning_24-48-96.svg" alt="" />
        <div class="font-title mb-1">{{ 'MapViewer_RTC_Data_Accuracy_Message' | translate }}</div>
        <p [innerHTML]="'MapViewer_RTC_GetStarted_Message' | translate"></p>
    </div>

    <div *ngIf="pageLoading">
        <loading class="loading-align-center"></loading>
    </div>
</div>

<rtc-configuration
    [originalRtc]="originalRtc"
    [editingRtc]="editingRtc"
    *ngIf="showRtcEditOptions"
    [isEditMode]="isEditMode"
    (closed)="closeFullscreenRtc()"
></rtc-configuration>

<rtc-delete *ngIf="isDeleteConfirmationOpen" [selectedRtc]="rtcInEdit" (closed)="hideRtcDelete()"></rtc-delete>
