<div class="modal-popup">
    <div class="modal-content workspace-edit">
        <ng-form #editOfflineBasemap="ngForm">
            <header class="modal-header">
                <h2 class="modal-title">
                    <span>{{ 'TCS.Mapviewer.OfflineBasemapContextMenu.RenameOfflinebasemap' | translate }}</span>
                </h2>
                <gsp-button
                    (action)="closeEditPanel()"
                    [type]="ButtonType.Icon"
                    iconClass="icon-ModelClose"
                ></gsp-button>
            </header>

            <div class="modal-body">
                <div class="modal-group">
                    <div class="form-element">
                        <div class="option-label" translate="name"></div>
                        <div class="input-container">
                            <gsp-text
                                #mapCacheName="ngModel"
                                [type]="GspTextType.GENERIC"
                                name="mapCacheName"
                                placeholder="{{ 'TCS.Mapviewer.MapCache.EnterMapcacheName' | translate }}"
                                [(ngModel)]="mapCache.name"
                                required="true"
                                maxlength="44"
                                errorTooltipMsg="{{ getErrorText(mapCacheName.errors) }}"
                                focusElement="true"
                            ></gsp-text>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <gsp-button
                    [type]="ButtonType.Primary"
                    [asyncAction]="save"
                    [disabled]="!mapCache.name || originalMapCache.name == mapCache.name"
                    text="{{ 'save' | translate }}"
                >
                </gsp-button>
                <gsp-button
                    [type]="ButtonType.Default"
                    (action)="closeEditPanel()"
                    text="{{ 'cancel' | translate }}"
                ></gsp-button>
            </div>
        </ng-form>
    </div>
</div>
