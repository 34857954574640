<gsp-popup
    [heading]="'MapViewer.ArchiveWorkspace.Heading' | translate"
    [size]="ModalSize.Small"
    [showClose]="true"
    (closed)="closePanel()"
    [showDone]="true"
    (done)="archiveWorkspace()"
    [doneBtnTitle]="'MapViewer.ArchiveWorkspace.DoneButton.Label' | translate"
    [showCancel]="true"
    (cancel)="closePanel()"
    [disableDone]="moveWorkspace && !moveFolderId"
    id="workspace-archive-popup"
>
    <ng-container transclude-popup-content>
        <div class="workspace-archive">
            <div class="archive-decription">
                {{ 'MapViewer.ArchiveWorkspace.Description' | translate }}
            </div>
            <div class="workspace-move-option">
                <gsp-checkbox
                    [(value)]="moveWorkspace"
                    [id]="'moveWorkspace'"
                    [label]="'MapViewer.ArchiveWorkspace.MoveOption.Label' | translate"
                ></gsp-checkbox>
            </div>
            <div class="archive-workspace-move-wrapper">
                <div class="archive-icon" *ngIf="!moveWorkspace">
                    <i class="icon_solid_archive i96"></i>
                </div>
                <folder-selection
                    [selectedWorkspace]="activeWorkspace"
                    [isArchiveWorkspace]="true"
                    (selectedFolder)="setFolder($event)"
                    class="folder-selection-component-wrapper"
                    *ngIf="moveWorkspace"
                ></folder-selection>
            </div>
        </div>
    </ng-container>
</gsp-popup>
