<h5>{{ 'TCS.Workspace.DownloadWorkspaceFile' | translate }}</h5>
<div class="share-workspace-instructions">
    <ul>
        <li>{{ 'TCS.Workspace.ShareWorkspaceInstructions.1' | translate }}</li>
        <li>{{ 'TCS.Workspace.ShareWorkspaceInstructions.2' | translate }}</li>
        <li>{{ 'TCS.Workspace.ShareWorkspaceInstructions.3' | translate }}</li>
        <li>{{ 'TCS.Workspace.ShareWorkspaceInstructions.4' | translate }}</li>
    </ul>
</div>
<div class="share-workspace-downloadbutton">
    <gsp-button
        buttonTooltip="{{ 'TCS.Workspace.DownloadWorkspace' | translate }} {{ sharedWorkspaceData.workspace.name }}"
        (click)="downloadWorkspace()"
        [type]="ButtonType.Primary"
        text="{{ 'FileTree.Download' | translate }}"
        [iconClass]=""
    ></gsp-button>
</div>
