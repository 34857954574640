import { CommonModule as AngularCommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgChartsModule } from 'ng2-charts';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AppMaterialModule } from 'src/app/app-material.module';
import { TranslationModule } from 'src/app/core/translation/translation.module';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AutoExpandDirective } from './components/auto-expand-textbox.directive';
import { GspButtonComponent } from './components/buttons/gsp-button.component';
import { GspConfirmationButtonComponent } from './components/buttons/gsp-confirmation-button.component';
import { CapitalizePipe } from './components/capitalize.pipe';
import { CheckVisibilityDirective } from './components/check-visibility.directive';
import { CheckVisibilityService } from './components/check-visibility.service';
import { ChipFilterComponent } from './components/chip-filter/chip-filter.component';
import { ColorPickerComponent } from './components/colorPicker/color-picker.component';
import { ComboColorPickerComponent } from './components/comboColorPicker/combo-color-picker.component';
import { GspContextMenuComponent } from './components/context-menu/gsp-context-menu.component';
import { CustomScrollbarComponent } from './components/custom-scrollbar.component';
import { DatePickerComponent } from './components/date-picker.component';
import { DonutComponent } from './components/donut/donut.component';
import { ErrorTooltipDirective } from './components/error-tooltip.directive';
import { FolderSelectionComponent } from './components/folder-selection/folder-selection.component';
import { FormatDatePipe, FormatDateTimePipe } from './components/format-date.pipe';
import { GeometryIconComponent } from './components/geometry-icon/gsp-geom-icon.component';
import { GspActivityTabComponent } from './components/gsp-activity-tab/gsp-activity-tab.component';
import { GspAnnouncementBannerComponent } from './components/gsp-announcement-banner/gsp-announcement-banner.component';
import { GspAnnouncementBannerService } from './components/gsp-announcement-banner/gsp-announcement-banner.service';
import { GspApplicationTopBarComponent } from './components/gsp-app-top-bar/gsp-app-top-bar.component';
import { GspChartComponent } from './components/gsp-chart/gsp-chart.component';
import { GspCheckboxComponent } from './components/gsp-checkbox/gsp-checkbox.component';
import { GspDropDownComponent } from './components/gsp-dropdown/gsp-dropdown.component';
import { GspFileDropComponent } from './components/gsp-file-drop/gsp-file-drop.component';
import { GspFocusDirective, GspFocusOnExpressionDirective } from './components/gsp-focus.directive';
import { GspForbiddenFormsComponent } from './components/gsp-forbidden-forms/gsp-forbidden-forms.component';
import { GspHelpTextComponent } from './components/gsp-help-text.component';
import { GspImageCropperComponent } from './components/gsp-image-cropper/gsp-image-cropper.component';
import { GspConfirmationPopupComponent } from './components/gsp-modals/gsp-confirmation-popup.component';
import { GspModalComponent } from './components/gsp-modals/gsp-modal.component';
import { GspPopupComponent } from './components/gsp-modals/gsp-popup.component';
import { GspNotificationTabComponent } from './components/gsp-notification-tab/gsp-notification-tab.component';
import { GspNotificationComponent } from './components/gsp-notification/gsp-notification.component';
import { GspNumberComponent } from './components/gsp-number/gsp-number.component';
import { GspPasswordComponent } from './components/gsp-password/gsp-password.component';
import { GspRadioComponent } from './components/gsp-radio.component';
import { GspTextAreaComponent } from './components/gsp-text-area/gsp-text-area.component';
import { GspTextComponent } from './components/gsp-text/gsp-text.component';
import { GspTimePickerComponent } from './components/gsp-time-picker/gsp-time-picker.component';
import { GspToggleComponent } from './components/gsp-toggle/gsp-toggle.component';
import { GspUserTabComponent } from './components/gsp-user-tab/gsp-user-tab.component';
import { GspWizardFooterComponent } from './components/gsp-wizard/gsp-wizard-footer.component';
import { GspWizardHeaderComponent } from './components/gsp-wizard/gsp-wizard-header.component';
import { GspWizardComponent } from './components/gsp-wizard/gsp-wizard.component';
import { GspWizardService } from './components/gsp-wizard/gsp-wizard.service';
import { LoadImageComponent } from './components/load-image.component';
import { LoadingComponent } from './components/loading.component';
import { PreventTabEventDirective } from './components/prevent-tab-event.directive';
import { RxScrollDirective } from './components/rx-scroll.directive';
import { SearchDropDownComponent } from './components/searchDropdown/search-dropdown.component';
import { SearchPlaceComponent } from './components/searchPlace/search-place.component';
import { TooltipDirective } from './components/tooltip.directive';
import { TrustHtmlPipe } from './components/trust-html.pipe';
import { MaxLengthValidatorDirective } from './components/validators/max-length-validator.directive';
import { MinimumEntriesValidatorDirective } from './components/validators/minimum-entries-validator.directive';
import { NoEmptyChoicesValidatorDirective } from './components/validators/no-empty-choices.directive';
import { UniqueValuesOnlyValidatorDirective } from './components/validators/unique-values-only-validator.directive';
import { ContextMenuService } from './layout/context-menu.service';
import { MapCacheStreamService } from './mapCache/map-cache-stream.service';
import { MapCacheService } from './mapCache/map-cache.service';
import { NumericDirective } from './numeric-input.directive';
import { DecimalValidateDirective } from './validators/decimal.validator.directive';
import { EmailValidateDirective } from './validators/email.validator.directive';
import { PortValidateDirective } from './validators/port.validator.directive';
import { UrlValidateDirective } from './validators/url.validator.directive';

@NgModule({
    declarations: [
        DonutComponent,
        GeometryIconComponent,
        GspTextComponent,
        GspRadioComponent,
        LoadImageComponent,
        LoadingComponent,
        PreventTabEventDirective,
        GspButtonComponent,
        GspConfirmationButtonComponent,
        RxScrollDirective,
        FormatDateTimePipe,
        FormatDatePipe,
        DatePickerComponent,
        TooltipDirective,
        CheckVisibilityDirective,
        CapitalizePipe,
        TrustHtmlPipe,
        GspDropDownComponent,
        GspFileDropComponent,
        CustomScrollbarComponent,
        ColorPickerComponent,
        ComboColorPickerComponent,
        MinimumEntriesValidatorDirective,
        UniqueValuesOnlyValidatorDirective,
        MaxLengthValidatorDirective,
        ErrorTooltipDirective,
        SearchDropDownComponent,
        GspContextMenuComponent,
        GspFocusDirective,
        GspFocusOnExpressionDirective,
        GspConfirmationPopupComponent,
        GspPopupComponent,
        GspWizardComponent,
        GspWizardFooterComponent,
        GspWizardHeaderComponent,
        GspNotificationComponent,
        GspAnnouncementBannerComponent,
        GspApplicationTopBarComponent,
        SearchPlaceComponent,
        NumericDirective,
        DecimalValidateDirective,
        GspHelpTextComponent,
        UrlValidateDirective,
        PortValidateDirective,
        EmailValidateDirective,
        GspModalComponent,
        GspToggleComponent,
        GspActivityTabComponent,
        GspChartComponent,
        NoEmptyChoicesValidatorDirective,
        GspNumberComponent,
        GspCheckboxComponent,
        GspTextAreaComponent,
        GspPasswordComponent,
        GspTimePickerComponent,
        FolderSelectionComponent,
        AutoExpandDirective,
        GspNotificationTabComponent,
        GspForbiddenFormsComponent,
        GspImageCropperComponent,
        GspUserTabComponent,
        ChipFilterComponent
    ],
    imports: [
        AngularCommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        TranslationModule,
        NgScrollbarModule,
        AppMaterialModule,
        NgChartsModule,
        ImageCropperModule,
        MatChipsModule,
        MatAutocompleteModule
    ],
    exports: [
        GspDropDownComponent,
        GspTextComponent,
        GspButtonComponent,
        GspConfirmationButtonComponent,
        GeometryIconComponent,
        TrustHtmlPipe,
        GspRadioComponent,
        DonutComponent,
        LoadImageComponent,
        LoadingComponent,
        PreventTabEventDirective,
        RxScrollDirective,
        FormatDateTimePipe,
        FormatDatePipe,
        DatePickerComponent,
        TooltipDirective,
        CheckVisibilityDirective,
        CapitalizePipe,
        GspFileDropComponent,
        CustomScrollbarComponent,
        ColorPickerComponent,
        ComboColorPickerComponent,
        MinimumEntriesValidatorDirective,
        UniqueValuesOnlyValidatorDirective,
        MaxLengthValidatorDirective,
        ErrorTooltipDirective,
        SearchDropDownComponent,
        GspContextMenuComponent,
        GspFocusDirective,
        GspFocusOnExpressionDirective,
        GspConfirmationPopupComponent,
        GspPopupComponent,
        GspWizardComponent,
        GspWizardHeaderComponent,
        GspWizardFooterComponent,
        GspNotificationComponent,
        SearchPlaceComponent,
        GspHelpTextComponent,
        UrlValidateDirective,
        PortValidateDirective,
        EmailValidateDirective,
        GspModalComponent,
        GspAnnouncementBannerComponent,
        GspToggleComponent,
        GspApplicationTopBarComponent,
        NumericDirective,
        DecimalValidateDirective,
        GspChartComponent,
        NoEmptyChoicesValidatorDirective,
        GspNumberComponent,
        GspCheckboxComponent,
        GspTextAreaComponent,
        GspPasswordComponent,
        GspTimePickerComponent,
        FolderSelectionComponent,
        AutoExpandDirective,
        GspImageCropperComponent,
        ChipFilterComponent,
        GspFileDropComponent
    ],
    providers: [
        MapCacheService,
        MapCacheStreamService,
        GspWizardService,
        CheckVisibilityService,
        GspAnnouncementBannerService,
        ContextMenuService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CommonModule {}
