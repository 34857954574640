<span class="button-confirm-wrapper">
    <span class="confirm-box" [hidden]="!showConfirmation">
        <gsp-button
            [type]="ButtonType.Danger"
            (action)="onConfirmClick($event)"
            [showBusyIndicator]="showBusy"
            [disabled]="showBusy"
            [text]="confirmText"
            [id]="getbuttonId('confirm')"
        >
        </gsp-button>
        <gsp-button
            [type]="ButtonType.Default"
            (action)="onCancelClick($event)"
            [text]="'cancel' | translate"
            [id]="getbuttonId('cancel')"
        >
        </gsp-button>
    </span>

    <gsp-button
        [text]="text"
        [type]="type"
        [iconClass]="iconClass"
        (action)="onClick($event)"
        [buttonTooltip]="buttonTooltip"
        [disabled]="disabled"
        [showBusyIndicator]="showBusy"
        [id]="getbuttonId('confirmation')"
    ></gsp-button>
</span>
