import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { Style } from 'src/app/shared/map-data-services/styles/style';
import { StyleService } from 'src/app/shared/map-data-services/styles/style.service';

@Injectable({
    providedIn: 'root'
})
export class StylesStore {
    constructor(private styleService: StyleService) {}

    // Note: Styles are not automatically loaded on project or workspace change.
    // Rather they are loaded via the stylesStore
    // We also can't load just the style for the current workspace, so load them for the whole project instead

    // -------------------------------
    // Project Styles

    stylesCache: { [projectId: string]: { [styleId: string]: Style } } = {};

    public loadProjectStyles(projectId: string): Promise<{ [styleId: string]: Style }> {
        return this.stylesCache[projectId] && this.stylesCache[projectId].length
            ? Promise.resolve(this.stylesCache[projectId])
            : this.styleService.getStyles(projectId).then(styles => {
                  this.stylesCache[projectId] = {};
                  styles.forEach(style => {
                      this.stylesCache[projectId][style.id] = style;
                  });
                  return this.stylesCache[projectId];
              });
    }

    public getStyle(projectId: string, styleId: string): Promise<Style> {
        if (!this.stylesCache || !this.stylesCache[projectId] || !this.stylesCache[projectId][styleId]) {
            if (styleId !== undefined) {
                return this.styleService.getStyleById(projectId, styleId);
            } else {
                return Promise.resolve(null);
            }
        } else {
            return Promise.resolve(this.stylesCache[projectId][styleId]);
        }
    }

    public getStyleByName(projectId: string, styleName: string): Style {
        return _.find(this.stylesCache[projectId], style => style.name === styleName);
    }

    public async createStyle(projectId: string, styleProperties: any): Promise<Style> {
        const style = await this.styleService.createStyle(projectId, styleProperties);
        if (!!this.stylesCache[projectId]) {
            this.stylesCache[projectId][style.id] = style;
        } else {
            this.stylesCache[projectId] = { [style.id]: style };
        }
        return style;
    }

    public async updateStyle(projectId: string, styleId: string, styleProperties: any): Promise<Style> {
        const style = await this.styleService.updateStyle(projectId, styleId, styleProperties);
        this.stylesCache[projectId][style.id] = style;
        return style;
    }

    public getStyles(projectId: string): { [styleId: string]: Style } {
        return this.stylesCache[projectId];
    }

    public getOrCreateStyleByColor(projectId: string, color: string): Promise<Style> {
        return new Promise<Style>((resolve, reject) => {
            let style = this.getStyleByName(projectId, 'style' + color);
            if (style) {
                resolve(style);
            } else {
                let newStyle = new Style();
                if (color) {
                    newStyle.setColor(color);
                }
                newStyle.setName('style' + color);

                this.createStyle(projectId, newStyle).then(style2 => resolve(style2));
            }
        });
    }
}
