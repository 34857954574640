import { Injectable } from '@angular/core';
import { Feature } from 'src/app/shared/map-data-services/feature/feature';

import { FeatureMapLayer } from './feature-map-layer';

// provides a cache of leaflet map layers used for features on the map
// - maps from feature id to feature map layers (array), for all features currently displayed on map (including client-side clustered)
// - (Note: There is also the cachedFeatureService which deals only with selected and task features)

@Injectable({
    providedIn: 'root'
})
export class FeatureMapLayerCacheService {
    // featureId to feature map layers cache

    private featureIdToMapLayer: { [featureId: string]: FeatureMapLayer } = {};
    private layerFeatureIds: { [layerId: string]: string[] } = {};
    private featureIdToLayerId: { [featureId: string]: string } = {};

    public getFeatureMapLayer(featureId: string): FeatureMapLayer {
        return this.featureIdToMapLayer[featureId];
    }

    public getLayerId(featureId: string): string {
        return this.featureIdToLayerId[featureId];
    }

    public addOrUpdateFeatureMapLayer(feature: Feature, mapLayer: FeatureMapLayer, layerId: string = null): void {
        let featureId = feature.id;

        // Note: layerId will not be the feature.layerId when feature is in a visible task
        layerId = layerId || this.featureIdToLayerId[featureId] || feature.layerId;

        if (!this.featureIdToMapLayer[featureId]) {
            if (!this.layerFeatureIds[layerId]) {
                this.layerFeatureIds[layerId] = [];
            }
            this.layerFeatureIds[layerId].push(featureId);
        }
        this.featureIdToMapLayer[featureId] = mapLayer;

        let oldLayerId = this.featureIdToLayerId[featureId];
        if (oldLayerId && oldLayerId !== layerId && this.layerFeatureIds[oldLayerId]) {
            let index = this.layerFeatureIds[oldLayerId].indexOf(featureId);
            if (index >= 0) {
                this.layerFeatureIds[oldLayerId].splice(index, 1);
            }
        }
        this.featureIdToLayerId[featureId] = layerId;
    }

    public removeFeatureMapLayer(featureId: string, layerId: string): void {
        delete this.featureIdToMapLayer[featureId];
        delete this.featureIdToLayerId[featureId];
        if (this.layerFeatureIds[layerId]) {
            let index = this.layerFeatureIds[layerId].indexOf(featureId);
            if (index >= 0) {
                this.layerFeatureIds[layerId] = this.layerFeatureIds[layerId].splice(index, 1); // remove element
            }
        }
    }

    public removeAllFeatureMapLayersForLayer(layerId: string): void {
        if (this.layerFeatureIds[layerId]) {
            this.layerFeatureIds[layerId].forEach(featureId => {
                delete this.featureIdToMapLayer[featureId];
                delete this.featureIdToLayerId[featureId];
            });
            delete this.layerFeatureIds[layerId];
        }
    }
}
