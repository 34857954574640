import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from 'src/environments/environment';
import { version } from 'src/environments/version';

@Injectable({ providedIn: 'root' })
export class RealUserMonitoringService {
    public init(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (environment.enableRum) {
                datadogRum.init({
                    applicationId: '85853a10-337f-457c-8936-2d189f02f9e0',
                    clientToken: 'pub01b66fb100e3eb3530c99ad330e5771b',
                    site: 'datadoghq.com',
                    service: 'map-viewer',
                    env: environment.name,
                    version: version,
                    sampleRate: 100,
                    trackInteractions: true,
                    defaultPrivacyLevel: 'mask'
                });
                datadogRum.startSessionReplayRecording();
            }
            resolve();
        });
    }
}
