import { Pipe, PipeTransform } from '@angular/core';

import { DateUtils } from '../utility/date-utils';

@Pipe({ name: 'formatDateTime', pure: true })
export class FormatDateTimePipe implements PipeTransform {
    constructor() {}

    transform(value: any, format = 'mediumDate', isUtc: boolean = false): string | null {
        if (value == null || value === '' || value !== value) {
            return null;
        }

        return DateUtils.formatDateFromDateTime(value, format, isUtc);
    }
}

@Pipe({ name: 'formatDate', pure: true })
export class FormatDatePipe implements PipeTransform {
    constructor() {}

    transform(value: any, format = 'mediumDate'): string | null {
        if (value == null || value === '' || value !== value) {
            return null;
        }

        return DateUtils.formatDateFromDate(value, format);
    }
}
