import { GeometryTypes, GeometryUtils } from 'src/app/shared/common/utility/geometry-utils';

import { Layer } from '../layer/layer';

export interface IFeatureDTO {
    id: string;
    geometry?: IFeatureGeometry;
    type?: string;
    tags?: string[];
    metadata: { [key: string]: any };
    properties: { [key: string]: any };
    crs?: NamedCRS;
}

export interface IFeatureGeometry {
    type: GeometryTypes;
    coordinates?: number[];
}

export interface NamedCRS {
    properties: { name: string };
    type: string;
}

export interface IFeature extends IFeatureDTO {
    toDTO(): IFeatureDTO;
}

export class Feature implements IFeature {
    // DTO properties
    id: string = null;
    geometry: IFeatureGeometry = null; // based on global crs
    geometryType: GeometryTypes = null;
    type = 'Feature';
    tags: string[] = [];
    properties: { [key: string]: any } = {};

    metadata: { [key: string]: any } = {};
    // standard metadata
    // "collection_timestamp": "2016-03-09T13:12:25.090754",
    // "collection_original_utc": "2016-03-09T13:12:25.090754",
    // collection_utc
    // "collection_todoid": "",
    // "collection_templateid": "yY_m3WUZ2YM",
    // "collection_workspaceid": "9OEHI8qzCak",
    // "projectId": "qDqHbaxmVAE",
    // "userId": null,
    // "createdUtc": "2016-03-09T04:49:55.1813331Z",
    // "updatedUtc": "2016-03-09T04:49:55.1813331Z",
    // "deleted": false,
    // "transactionId": "f5a3c7ab-b4dc-484e-8578-7ffab6e72c12"
    // "collection_isNew": true

    // Added properties
    selected = false; // managed by featureStreams
    active = false; // managed by featureStreams
    inVisibleTask = false; // managed by mapContainerComponent
    updated = true; // managed by taskFeatureStreams
    isLayerRemoved = false; // indicates layer for feature has been removed // managed by taskFeatureStreams
    removedLayer: Layer = null; // managed by taskFeatureStreams
    attributionText = '';

    // Added layer properties
    // - added by FeatureSet after getting features from the API using addLayerProperties() below
    layerId: string = null;
    layerName: string = null;
    colorKey: string = null; // Note: In the form 0x990000
    layerKey: string = null;
    collectedTemplateId: string = null;
    templateId: string = null;
    templateSeriesId: string = null;

    crs: NamedCRS = null;
    localGeometry: IFeatureGeometry = null; // based on local crs
    localCRS: NamedCRS = null;

    // Model/Constructor
    constructor() {}

    static fromDTO(dto: IFeatureDTO): Feature {
        const feature = new Feature();
        // --------------
        // Create from API response DTO
        if (dto) {
            feature.id = dto.id;
            feature.properties = dto.properties;
            feature.metadata = dto.metadata;
            feature.tags = dto.tags || [];
            feature.geometry = dto.geometry;
            feature.crs = dto.crs;
            let computedColor: string = null;
            if (dto.metadata && dto.metadata.file_sourceLayerStyle) {
                let tmpStyle = JSON.parse(JSON.stringify(dto.metadata.file_sourceLayerStyle).replace(/\\r\\n/g, ''));
                tmpStyle = typeof tmpStyle === 'object' ? tmpStyle : JSON.parse(tmpStyle);
                computedColor = tmpStyle.color;
            }
            if (dto.metadata && dto.metadata.collection_templateid) {
                feature.collectedTemplateId = dto.metadata.collection_templateid;
            }
            feature.colorKey = computedColor ? computedColor.substr(1) : null;
            if (dto.geometry && dto.geometry.type) {
                feature.geometryType = GeometryUtils.getGeometryType(dto.geometry.type);
            }
        }

        return feature;
    }

    public toDTO(): IFeatureDTO {
        // to API request DTO
        return {
            id: this.id,
            metadata: this.metadata,
            properties: this.properties,
            tags: this.tags,
            // Must use local crs and coords whenever modifying geometry: https://jira.trimble.tools/browse/TCMAPS-3086
            geometry: this.localGeometry,
            crs: this.localCRS
        };
    }

    public addLayerProperties(layer: Layer): void {
        this.layerId = layer.id;
        this.layerName = layer.layerName || 'No layer';
        this.colorKey = layer.getLayerColorKey() || this.colorKey;
        this.geometry = this.geometry ? this.geometry : { type: layer.geometryType };
        this.templateId = layer ? layer.templateId : null;
        this.templateSeriesId = layer ? layer.templateSeriesId || null : null;
    }

    public getLayerKey(): string {
        if (this.metadata) {
            if (this.metadata.common_layerId) {
                return this.metadata.common_layerId;
            }
            if (this.metadata.collection_templateSeriesId) {
                return this.metadata.collection_templateSeriesId;
            }
            if (this.metadata.file_connectFileId) {
                if (this.metadata.file_sourceLayer) {
                    return (
                        this.metadata.file_connectFileId +
                        '|' +
                        this.metadata.file_connectVersionId +
                        '|' +
                        this.metadata.file_sourceLayer
                    );
                }
                return this.metadata.file_connectFileId + '|' + this.metadata.file_connectVersionId;
            }
        }
        return null;
    }

    public addLocalGeomAndCRS(localProperties: Partial<Feature>): void {
        this.localGeometry = localProperties.geometry;
        this.localCRS = localProperties.crs;
    }
}

export interface ActiveFeature extends Feature {
    selectedFromFeaturePanel?: boolean;
}
