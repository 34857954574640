import * as _ from 'lodash-es';
import { TranslationService } from 'src/app/core/translation/translation.service';

import { Condition, ConditionDisplayInfo, ConditionOperator } from './condition';

export class TextCondition extends Condition {
    static readonly textConditionsText: ConditionDisplayInfo[] = [
        {
            name: 'TC.Common.IsAnswered',
            value: ConditionOperator.IS_ANSWERED,
            iconClass: 'icon_line_yesno'
        },
        {
            name: 'TC.Common.IsEqualTo',
            value: ConditionOperator.IS_EQUAL_TO,
            iconClass: 'icon_line_equal'
        },
        {
            name: 'TC.Common.IsNotEqual',
            value: ConditionOperator.IS_NOT_EQUAL_TO,
            iconClass: 'icon_line_notEqual'
        }
    ];

    constructor(uuid: string, type: string, public operator = ConditionOperator.IS_ANSWERED, public comparisonValues: string[] = []) {
        super(uuid, type);
    }

    // Add comparison values to this condition.
    addComparisonValue(comparisonText: string): void {
        this.comparisonValues.push(comparisonText);
    }

    // Returns the list of operators used for this condition.
    getConditionPair(): ConditionDisplayInfo[] {
        return TextCondition.textConditionsText;
    }

    // Get the condition text which is displayed in sidebar.
    getConditionText(translator: TranslationService, value: string): string {
        value = value || this.operator;
        let conditionPair = _.find(TextCondition.textConditionsText, textCondition => textCondition.value === value);
        if (conditionPair.value === ConditionOperator.IS_ANSWERED) {
            return translator.instant(conditionPair.name);
        } else if (conditionPair.value === ConditionOperator.IS_EQUAL_TO) {
            return translator.instant(conditionPair.name) + ' ' + this.comparisonValues.join(' Or ');
        }
        return translator.instant(conditionPair.name) + ' ' + this.comparisonValues.join(' And ');
    }

    // Get the error message for this condition
    getErrorMessagesNo(): number[] {
        let sourceLevelErrorMessagesNo: number[] = [];
        if (this.errors.labelErrors?.indexOf(true) !== -1) {
            sourceLevelErrorMessagesNo.push(0);
        }
        if (this.errors.labelEmpty) {
            sourceLevelErrorMessagesNo.push(3);
        }
        return sourceLevelErrorMessagesNo;
    }
}
