<label [ngClass]="{ 'disabled-field': !enabled }">
    <span *ngIf="label">{{ label }}</span>
    <input
        #textInput="ngModel"
        type="text"
        class="gsp-form-input"
        [ngClass]="{
            small: maxlength <= 3,
            medium: maxlength > 3 && maxlength <= 11,
            large: maxlength > 11
        }"
        [name]="name + '_text'"
        [placeholder]="placeholder"
        [(ngModel)]="value"
        [disabled]="!enabled"
    />
    &nbsp;
    <span *ngIf="postfixLabel">{{ postfixLabel }}</span>
</label>
<label>
    <div><span class="warning">&nbsp;</span></div>
    <div>
        <span *ngIf="warning.length" class="warning">{{ warning }}</span>
    </div>
</label>
