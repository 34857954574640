import { Style } from '../styles/style';

export const TrimbleColorPalette = {
    Red: '#D00A09',
    Orange: '#FD8204',
    Yellow: '#FFD600',
    LimeGreen: '#64AD22',
    ForestGreen: '#01863E',
    Teal: '#028986',
    RoyalBlue: '#0967AD',
    NavyBlue: '#3939AC',

    // extended
    Purple: '#773899',
    Pink: '#BD0760',

    Dark: {
        Red: '#990000',
        Orange: '#D64B02',
        Yellow: '#FFBE00',
        LimeGreen: '#367314',
        ForestGreen: '#004E18',
        Teal: '#01534F',
        RoyalBlue: '#083061',
        NavyBlue: '#1A1A52',

        // extended
        Purple: '#431860',
        Pink: '#77042C'
    },

    Bright: {
        Red: '#FF2722',
        Orange: '#FFB22E',
        Yellow: '#FFFA00',
        LimeGreen: '#B4FF00',
        ForestGreen: '#09C72E',
        Teal: '#0BDDD9',
        RoyalBlue: '#2B9FF6',
        NavyBlue: '#4747FF',

        // extended
        Purple: '#AA46E5',
        Pink: '#FF2D96'
    }
};

export class LayerStyleGenerator {
    constructor() {}

    // Model/Constructor
    static getStyles(n: number): Style[] {
        let styles: Style[] = [];
        let suportedColorSequences = [
            TrimbleColorPalette.Red,
            TrimbleColorPalette.LimeGreen,
            TrimbleColorPalette.RoyalBlue,
            TrimbleColorPalette.Yellow,
            TrimbleColorPalette.Teal,
            TrimbleColorPalette.Purple,
            TrimbleColorPalette.Orange,
            TrimbleColorPalette.ForestGreen,
            TrimbleColorPalette.Pink,
            TrimbleColorPalette.NavyBlue,
            TrimbleColorPalette.Dark.Red,
            TrimbleColorPalette.Dark.Orange,
            TrimbleColorPalette.Dark.Yellow,
            TrimbleColorPalette.Dark.LimeGreen,
            TrimbleColorPalette.Dark.ForestGreen,
            TrimbleColorPalette.Dark.Teal,
            TrimbleColorPalette.Dark.RoyalBlue,
            TrimbleColorPalette.Dark.NavyBlue,
            TrimbleColorPalette.Dark.Purple,
            TrimbleColorPalette.Dark.Pink,
            TrimbleColorPalette.Bright.Red,
            TrimbleColorPalette.Bright.Orange,
            TrimbleColorPalette.Bright.Yellow,
            TrimbleColorPalette.Bright.LimeGreen,
            TrimbleColorPalette.Bright.ForestGreen,
            TrimbleColorPalette.Bright.Teal,
            TrimbleColorPalette.Bright.RoyalBlue,
            TrimbleColorPalette.Bright.NavyBlue,
            TrimbleColorPalette.Bright.Purple,
            TrimbleColorPalette.Bright.Pink
        ];

        const colors = suportedColorSequences
            .map(x => ({ x, r: Math.random() }))
            .sort((a, b) => a.r - b.r)
            .map(a => a.x)
            .slice(0, n);

        colors.forEach(color => {
            let style = new Style();
            style.name = 'style' + color;
            style.fillColor = parseInt(color.replace('#', '').replace('0x', ''), 16);
            style.fillOpacity = 2;
            style.lineColor = parseInt(color.replace('#', '').replace('0x', ''), 16);
            style.lineStyle = 'SOLID';
            style.lineWeight = 5;
            style.image = '';

            styles.push(style);
        });
        return styles;
    }

    static getStyle(color: string): Style {
        let style = new Style();
        style.name = 'style' + color;
        style.fillColor = parseInt(color.replace('#', '').replace('0x', ''), 16);
        style.fillOpacity = 2;
        style.lineColor = parseInt(color.replace('#', '').replace('0x', ''), 16);
        style.lineStyle = 'SOLID';
        style.lineWeight = 5;
        style.image = '';
        return style;
    }
}
