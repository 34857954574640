import { Component, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FieldDefinition } from 'src/app/shared/template-services/field/fieldDefinition';

import { FieldBaseComponent } from './field-base.component';

@Component({
    template: ''
})
export class FormFieldBaseComponent extends FieldBaseComponent {
    @Input()
    form: NgForm;

    @Input()
    fieldMeta: FieldDefinition;

    @Input()
    errorDetails: { [key: string]: any };
}
