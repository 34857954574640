import { Feature } from 'src/app/shared/map-data-services/feature/feature';
import { GeoLink } from 'src/app/shared/types/geo';
import { Actor } from 'src/app/shared/user/actor';

import { PermissionType } from '../../map-data-services/mapWorkspace/permission';
import { User } from '../../user/user';

export interface GeoDataUpdateJobs {
    links?: GeoLink[];
    total?: number;
    items?: GeoDataUpdateJob[];
    eTag?: string;
}

export enum TaskStatus {
    NEW = 'NEW',
    IN_PROGRESS = 'IN_PROGRESS',
    RESOLVED = 'RESOLVED',
    CLOSED = 'CLOSED',
    BLOCKED = 'BLOCKED'
}

export enum FormStatus {
    NEEDS_UPDATING = 'NeedsUpdating',
    UPDATED = 'Updated',
    NEW = 'New'
}
export interface GeoDataUpdateJob {
    uuid?: string;
    todoId?: string;
    title?: string;
    description?: string;
    status?: TaskStatus;
    priority?: TaskPriority;
    dueDate?: string;
    percentageComplete?: number;
    forms?: GeoDataUpdateForm[];
    isCompleted?: boolean;
    workspaceIds?: string[];
    todo?: object;
    assignees?: GeoDataUpdateJobAssignee[];
    returnedJobs?: GeoReturnedDataUpdateJob[];
    links?: GeoLink[];
    id?: string;
    eTag?: string;
}

export interface GeoDataUpdateForm {
    formId?: string;
    templateSeriesId?: string;
    status?: FormStatus;
}

export interface GeoDataUpdateJobAssignee {
    type?: string;
    id?: string;
    name?: string;
}

export interface GeoReturnedDataUpdateJob {
    id?: string;
    userId?: string;
    returnedUtc?: string;
}

export interface GeoPutAndPostDataUpdateJobRequest {
    forms: GeoDataUpdateForm[];
    name?: string;
    description?: string;
    percentageComplete?: number;
    totalFeatures?: number;
    totalCollectedFeatures?: number;
    id?: string;
    todoId?: string;
}
export interface ToDoDTO {
    id: string;
    type: string;
    title: string;
    label: string;
    description: string;
    dueDate: Date;
    percentComplete: number;
    status: string;
    priority: string;
    projectId: string;
    createdOn: Date;
    createdBy: Partial<User>;
    modifiedOn: Date;
    modifiedBy: Partial<User>;
    assignees?: Actor[];
}
export class TaskFeature {
    formId?: string;
    featureId: string;
    status: FormStatus;
    templateSeriesId: string;
}

// task status indicators for the search API query parameter
export enum TaskDTOStatus {
    ACTIVE = 'ActiveJobs',
    CLOSED = 'CompletedJobs'
}

export enum TaskPriority {
    CRITICAL = 'CRITICAL',
    HIGH = 'HIGH',
    NORMAL = 'NORMAL',
    LOW = 'LOW'
}

export class Task {
    // task DTO properties
    id: string = null;
    todoId: string = null;
    features: TaskFeature[] = [];
    totalFeatures = 0;
    totalCollectedFeatures = 0;
    percentageComplete = 0;

    // - todo DTO properties
    label: string = null;
    name: string = null;
    // NOTE: Not currently supported until implemented in Connect TODOs
    description: string = null;
    priority = TaskPriority.NORMAL;
    status = TaskStatus.NEW;
    dueDate: Date = null;
    assignees: {
        type: PermissionType;
        id: string;
        name: string;
    }[] = [];
    createdOn: Date = null;
    modifiedOn: Date = null;
    createdBy: string = null;

    // Added properties
    isClosed = false;
    selected = false;
    visible = false;
    type: string = null;
    returnedJobs: {
        id?: string;
        userId?: string;
        returnedUtc?: string;
    }[] = [];
    isTodoCompletedByUnassignedUser = false;
    unassignedCompletedUser: Actor[] = [];
    title: string = null;

    taskFeatures: Feature[] = []; // managed by map-container.component

    constructor() {}

    static fromDTOandTodo(dto: GeoDataUpdateJob, todo: ToDoDTO): Task {
        const task = new Task();

        // -- -- -- -- -- -- --
        // Create from API response DTO
        if (dto) {
            task.id = dto.id;
            task.todoId = dto.todoId;
            task.features = dto.forms.map(form => {
                const { templateSeriesId, status, formId } = form;
                return { templateSeriesId, status, formId, featureId: formId };
            });
            task.percentageComplete = dto.percentageComplete;
            task.totalFeatures = task.features.length;
            task.totalCollectedFeatures = task.features.filter(feature => feature.status === 'Updated').length;
            task.returnedJobs = dto.returnedJobs || [];
            task.title = dto.title;
            task.description = dto.description;
            task.status = dto.status;
            task.priority = dto.priority;
        }

        // -- -- -- -- -- -- --
        // - todo properties
        if (todo) {
            task.label = todo.label;
            task.dueDate = todo.dueDate ? new Date(todo.dueDate) : null;
            task.isClosed =
                todo.status !== TaskStatus.IN_PROGRESS &&
                (dto.isCompleted || todo.status === TaskStatus.CLOSED || todo.status === TaskStatus.RESOLVED);
            task.createdBy = todo.createdBy.id;
            if (todo.assignees) {
                const completedUserIds = task.returnedJobs.map(job => job.userId);

                task.assignees = todo.assignees.map(assignee => {
                    let tmpActor = Actor.fromDTO(assignee, assignee.type);
                    tmpActor.isTodoCompleted = completedUserIds.indexOf(assignee.id) > -1;
                    return tmpActor;
                });
            } else if (task.returnedJobs.length > 0) {
                task.isTodoCompletedByUnassignedUser = true;
            }
            task.createdOn = new Date(todo.createdOn);
            task.modifiedOn = new Date(todo.modifiedOn);
        }

        return task;
    }

    toDTO(): GeoPutAndPostDataUpdateJobRequest {
        // to API request DTO
        return {
            id: this.id,
            todoId: this.todoId,
            forms: this.features,
            name: this.name,
            description: this.description,
            percentageComplete: this.percentageComplete,
            totalFeatures: this.totalFeatures,
            totalCollectedFeatures: this.totalCollectedFeatures
        };
    }
}
