<div class="workspace-template-options">
    <post-processing-options
        *ngIf="modifyWorkspace"
        [offlineGNSSDescription]="offlineGNSSDescription"
        [cannotEnableMessageKey]="cannotEnableMessageKey"
        [toggleLabel]="toggleLabel"
        [workspace]="modifyWorkspace"
        (toggled)="onToggled($event)"
    ></post-processing-options>
</div>
