import { TemplateLite } from 'src/app/shared/map-data-services/layer/template-lite';
import { GeoLink } from 'src/app/shared/types/geo';

import { GeometryTypes } from '../../common/utility/geometry-utils';
import { MapWorkspace } from '../mapWorkspace/map-workspace';
import { MapWorkspacePermissionType } from '../mapWorkspace/map-workspace-permission';
import { MapWorkspaceStatus } from '../mapWorkspace/map-workspace.types';
import { Style } from '../styles/style';

export enum LayerType {
    IMPORTED_LAYER = 'ImportedLayer',
    TEMPLATE_LAYER = 'TemplateLayer'
}

export enum BackgroundMapType {
    TRIMBLE_STREET = 'TrimbleStreet',
    TRIMBLE_SATELLITE = 'TrimbleSatellite',
    OSM = 'OSM',
    GOOGLE_STREET = 'GoogleStreet',
    GOOGLE_SATELLITE = 'GoogleSatellite',
    ALK = 'ALK'
}
export interface GeoLayers {
    links?: GeoLink[];
    total?: number;
    items?: GeoLayer[];
    eTag?: string;
}

export interface GeoLayer {
    layerName?: string;
    template?: GeoLayerTemplate;
    styleId?: string;
    style?: Style;
    layerType?: LayerType;
    fileVersionId?: string;
    sourceLayerName?: string;
    layerCreationStatus?: string;
    templatePublications?: GeoLayerTemplatePublication[];
    workspaces?: GeoLayerWorkspace[];
    isLockedInWorkspaces?: string[];
    links?: GeoLink[];
    id?: string;
    eTag?: string;
}

export interface GeoLayerTemplate {
    id?: string;
    seriesId?: string;
    version?: number;
    templateName?: string;
    geometryType?: GeometryTypes.POINT | GeometryTypes.LINE | GeometryTypes.AREA;
    geometryColorHexRGB?: string;
    isPublished?: boolean;
}

export interface GeoLayerTemplatePublication {
    id?: string;
    workspaceId?: string;
    templateSeriesId?: string;
}

export interface GeoLayerWorkspace {
    id?: string;
    name?: string;
    description?: string;
    connectFileId?: string;
    uuid?: string;
    hasThumbnail?: boolean;
    backgroundMapType?: BackgroundMapType;
    isDeleted?: boolean;
    crs?: string;
    status?: MapWorkspaceStatus;
}

export interface GeoPutLayerRequest {
    layerName: string;
    templateId: string;
    styleId: string;
    layerType: LayerType;
    fileVersionId?: string;
    id?: string;
    sourceLayerName?: string;
    templateSeriesId?: string;
    isInitialLayer?: boolean;
    ruleSymbols?: any[];
}
export class Layer {
    // DTO properties
    id: string = null;
    layerName: string = null;
    sourceLayerName: string = null;
    templateId: string = null;
    templateSeriesId: string = null;
    templateVersionId = 0;
    styleId: string = null;
    geoLayerType: LayerType = null;
    fileVersionId: string = null;
    isInitialLayer = true;
    workspaces: MapWorkspace[] = [];
    template: TemplateLite;
    templateName: string;
    ruleSymbols: any[] = [];

    // -------------------------------
    // Added properties

    // ----
    // Workspace permissions - Managed by layers-store.service
    allDerivedPropertiesLoaded = false; // true when all the following derived proeprties have been loaded

    // ----
    // Workspace permissions - Managed by layers-store.service
    workspacePermission: MapWorkspacePermissionType;

    // ----
    // Layer geometry, style, template - Managed by layers-store.service
    geometryType = GeometryTypes.NONE; // propagated from the template (if any) in layersStore
    style: Style = null; // filled in by the LayerStore based on styleId
    color = '#EE4455'; // Note: In the form '#990000' // propagated from the template (if any) in layersStore

    templateColor: string;
    templatePublishStatus = false;
    isLocked = false; // boolean for whether the layer is locked for the current workspace
    isLockedInWorkspaces: string[] = []; // array of workspaceId's in which the layer is locked

    isLinkedLayer = false;
    allGeometryStyleTemplatePropertiesLoaded = false;

    // ----
    // Bounds of features within layer - Managed by layers-store.service
    bounds: L.LatLngBounds = null;
    boundsPropertyLoaded: boolean;

    // ----
    // Non-spatial feature count within layer - Managed by layers-store.service
    nonSpatialCount = 0;

    // ----
    // Added by layers-store.service
    isLayerFromFile = false; // a layer determined from distinct values of 'metadata.file_sourceLayer' in the current workspace/project

    // ----
    // Added by layers-store.service
    workspaceId: string = null;
    projectId: string = null;

    // ----
    // Cache layer contents buster - Managed by layers-store.service
    cacheTimeStamp: string = null; // DateTime

    // ----
    // Layer change status - Managed by layers-store.service
    isUpdating = false;
    isRemoved = false;
    layerIsBeingDuplicated = false;

    // ----
    // Managed by add-existing-layer.component
    selected = false; // true if layer selected in add-existing-layer.component

    // ----
    // Managed by layer-list.component and layers-store.service
    visible = false;

    // ----
    // Model/Constructor
    static fromDTO(dto: GeoLayer): Layer {
        const layer = new Layer();

        // --------------
        // Create from API response DTO
        if (dto) {
            layer.id = dto.id;
            layer.layerName = dto.layerName;
            layer.sourceLayerName = dto.sourceLayerName;
            layer.templateId = dto.template && dto.template.id;
            layer.templateSeriesId = dto.template && dto.template.seriesId;
            layer.templateVersionId = dto.template && dto.template.version;
            layer.style = dto.style;
            layer.styleId = dto.styleId;
            layer.geoLayerType = dto.layerType;
            layer.fileVersionId = dto.fileVersionId;
            // filtering out associated deleted workspaces from layer
            layer.workspaces = dto.workspaces
                ? (dto.workspaces.filter(workspace => !workspace.isDeleted) as MapWorkspace[])
                : [];
            layer.template = dto.template ? TemplateLite.fromDTO(dto.template) : null;
            layer.templateName = layer.template ? layer.template.name : null;
            layer.ruleSymbols = [];
            layer.isLocked = false;
            layer.isLockedInWorkspaces = dto.isLockedInWorkspaces || [];
        }

        return layer;
    }

    toDTO(): GeoPutLayerRequest {
        // to API request DTO
        return {
            id: this.id,
            layerName: this.layerName,
            sourceLayerName: this.sourceLayerName,
            templateId: this.templateId,
            templateSeriesId: this.templateSeriesId,
            styleId: this.styleId,
            layerType: this.geoLayerType,
            fileVersionId: this.fileVersionId,
            isInitialLayer: this.isInitialLayer,
            ruleSymbols: this.ruleSymbols.map(ruleSymbol => {
                if (!ruleSymbol.symbolId) {
                    ruleSymbol.symbolId = ruleSymbol.symbol.id;
                    delete ruleSymbol.symbol;
                }
                return ruleSymbol;
            })
        };
    }

    getLayerColor(): string {
        return '#' + this.getLayerColorKey();
    }

    // colorKey is color without the leading '#'
    getLayerColorKey(): string {
        return this.getColorKey(this.style ? this.style.fillColor : undefined);
    }

    getColorKey(color: number) {
        color = color === undefined ? 0x404040 /* dark grey */ : color;
        return ('00000' + color.toString(16).toUpperCase()).slice(-6);
    }

    // Used by the text filter
    matchesText(textCriteria: string): boolean {
        if (textCriteria === '') {
            return true; // no filter means it matches
        }
        if (!this.layerName) {
            return false;
        }
        if (this.layerName.toLowerCase().indexOf(textCriteria) !== -1) {
            return true;
        }
        return false;
    }

    updateCacheTimeStamp(timestamp?: string): void {
        this.cacheTimeStamp = timestamp;
    }

    // generates the set of keys that can be used to map to a layer (in the layerStore)
    getLayerKeys(): string[] {
        const keys: string[] = [];
        if (this.templateSeriesId) {
            keys.push(this.templateSeriesId);
        }
        if (this.id) {
            keys.push(this.id);
        }
        if (this.fileVersionId) {
            if (this.sourceLayerName) {
                keys.push(this.fileVersionId + '|' + this.fileVersionId + '|' + this.sourceLayerName);
            }
            keys.push(this.fileVersionId + '|' + this.fileVersionId);
        }
        return keys.length ? keys : null;
    }

    getLockStatus(workspaceId: string): boolean {
        return this.isLockedInWorkspaces.indexOf(workspaceId) !== -1;
    }
}
