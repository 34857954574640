<section class="existing-layers side-panel">
    <header>
        <h2 class="layers-heading" translate="TC.Common.LayerLibrary"></h2>
        <gsp-button (action)="closeSidePanel()" [type]="ButtonType.Icon" iconClass="i24 icon_line_cancel"></gsp-button>
    </header>
    <section class="search">
        <input
            type="text"
            [formControl]="searchByNameControl"
            placeholder="{{ 'TCS.Mapviewer.AddMenu.SearchLayersPlaceholder' | translate }}"
            class="gsp-form-input"
        />
    </section>

    <section class="datum-filter-info" *ngIf="workspaceFilterDatumInfo">
        <gsp-notification class="info">
            <div>
                {{ workspaceFilterDatumInfo }}
            </div>
        </gsp-notification>
    </section>

    <section class="spacer" *ngIf="!workspaceFilterDatumInfo"></section>

    <section class="content add-layer-list">
        <custom-scroll>
            <ul class="list-content">
                <li
                    class="no-hover"
                    *ngIf="!layerLibraryLayers.length && !loading"
                    translate="TCS.Mapviewer.AddMenu.NoLayersFound"
                ></li>
                <li
                    *ngFor="let spatialItem of layerLibraryLayers; let i = index"
                    (click)="select(spatialItem)"
                    [ngClass]="{ selected: spatialItem.selected }"
                >
                    <div class="layer-sym">
                        <geom-icon
                            path="sym_32-layer-"
                            [color]="spatialItem.color"
                            [geoType]="spatialItem.geometryType"
                        ></geom-icon>
                    </div>
                    <div class="layer-title">
                        <span title="{{ spatialItem.layerName }}">{{ spatialItem.layerName }}</span>
                        <!--Loading relationships-->
                        <div
                            class="relationship"
                            title="{{ workspace.name }}"
                            *ngFor="let workspace of spatialItem.workspaces"
                        >
                            {{ workspace.name }}
                        </div>
                        <!--Loading relationships end-->
                    </div>
                    <div class="layer-edit" *ngIf="spatialItem.templateId">
                        <!-- TODO: GIM replace ui-sref -->
                        <gsp-button
                            [type]="ButtonType.Icon"
                            (action)="editSpatialItem(spatialItem)"
                            iconClass="icon_line_template i24"
                        >
                        </gsp-button>
                    </div>
                </li>
            </ul>
            <div *ngIf="loading">
                <loading *ngIf="layerLibraryLayers.length"></loading>
                <loading *ngIf="!layerLibraryLayers.length" class="loading-align-center"></loading>
            </div>
        </custom-scroll>
    </section>

    <footer>
        <div class="footer-action-buttons">
            <gsp-button
                [type]="ButtonType.Default"
                (action)="closeSidePanel()"
                text="{{ 'cancel' | translate }}"
            ></gsp-button>
            <gsp-button
                [type]="ButtonType.Primary"
                (action)="addToCurrentWorkspace()"
                [disabled]="!selectedLayers.length"
                text="{{ 'TC.Common.Use' | translate }}"
            >
            </gsp-button>
        </div>
    </footer>
</section>
