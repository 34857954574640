<ng-container [ngSwitch]="errorCode">
    <ng-container *ngSwitchCase="HttpErrorCodes.UNAUTHORISED">
        <div class="over-map loading-no-access">
            <h3 data-cy="unauthorized">{{ 'MapViewer_Generic_Unauthorized_Error' | translate }}</h3>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="HttpErrorCodes.FORBIDDEN">
        <div class="over-map loading-no-access">
            <h3>
                {{ 'MapViewer_Generic_Forbidden_Error' | translate }}
                <a href="{{ connectUrl }}">{{ 'MapViewer_Generic_HomePage' | translate | lowercase }}</a
                >.
            </h3>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="ErrorPageErrorCodes.USER_NOT_IN_PROJECT">
        <div class="over-map loading-no-access">
            <h3>
                {{ 'MapViewer_Download_User_Not_In_Project_Error' | translate }}
            </h3>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="HttpErrorCodes.NOT_FOUND">
        <div class="over-map loading-no-access">
            <h3 data-cy="no_projects">
                {{ 'noProjects' | translate }}
            </h3>
            <p
                data-cy="visit_connect"
                [innerHTML]="'MapViewer_Generic_NoProject' | translate: { connectAppUrl: connectUrl }"
            ></p>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="HttpErrorCodes.INTERNAL_ERROR">
        <div class="over-map loading-no-access">
            <h3>{{ 'MapViewer_Generic_Server_Error' | translate }}</h3>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="HttpErrorCodes.SERVER_UNAVAILABLE">
        <div class="over-map loading-no-access">
            <h3>{{ 'MapViewer_Generic_Server_Error' | translate }}</h3>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="ErrorPageErrorCodes.INVALID_VERSION_ID">
        <div class="over-map loading-no-access">
            <h3>{{ 'MapViewer_InvalidVersionId_Error' | translate }}</h3>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="ErrorPageErrorCodes.INVALID_REGION">
        <div class="over-map loading-no-access">
            <h3>{{ 'MapViewer_InvalidRegion_Error' | translate }}</h3>
        </div>
    </ng-container>
</ng-container>
