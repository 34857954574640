<main>
    <ng-form #masterForm="ngForm" class="master-form height-full">
        <composer-header
            [showErrors]="!isMasterFormValid"
            (saveForm)="
                saveFormDummy($event.action, $event.template, $event.publishedStatus, $event.templateBeforeSave)
            "
            [template]="template"
            (afterLoaded)="afterLoaded.emit()"
            [templateId]="templateId"
            [currentWorkspaceId]="currentWorkspaceId"
            (showCancelConfirmation)="showCancelConfirmation.emit()"
            [enableButtons]="enableButtons"
            (showSuccess)="showSuccess.emit()"
            (showError)="showError.emit($event)"
        ></composer-header>
        <custom-scroll class="field-list-container max-width-100 cdk-drag-boundary">
            <section>
                <section class="info-message" *ngIf="showLinkedText(linkDetails) || template.lockedSchema">
                    <div
                        *ngIf="showLinkedText(linkDetails)"
                        translate
                        [translateParams]="{
                            layerCount: layerCount,
                            workspaceCount: workspaceCount,
                            layerLabel: layerLabel,
                            mapWorkspaceLabel: mapWorkspaceLabel
                        }"
                    >
                        TCS.TemplateEditor.Tf.Links.DisplayMessage
                    </div>
                    <div *ngIf="template.lockedSchema">
                        {{ 'MapViewer.Template.SchemaLocked' | translate }}
                        <a class="info-hyperlink" (click)="showMoreInfo()">{{
                            'MapViewer.AnnouncementBanner.AnnouncementLink.Placeholder' | translate
                        }}</a>
                    </div>
                </section>

                <composer-warnings [template]="template"></composer-warnings>
                <field-list
                    [templateEditorConfig]="templateEditorConfig"
                    [currentProjectDetails]="currentProjectDetails"
                    [fields]="template.fields"
                    [template]="template"
                    [currentTab]="currentTab$ | async"
                    [selectedField]="selectedField"
                    (fieldSelected)="selectField($event)"
                    [application]="application"
                ></field-list>
            </section>
        </custom-scroll>
    </ng-form>
</main>
