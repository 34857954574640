import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import * as _ from 'lodash-es';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import {
    AllValidationErrors,
    getFormValidationErrors
} from 'src/app/shared/common/components/validators/get-form-validation-errors';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';
import { DateUtils } from 'src/app/shared/common/utility/date-utils';
import { Application } from 'src/app/shared/map-data-services/application';
import { AutoFieldModelType, Field, FieldStatus, LayoutFieldType } from 'src/app/shared/template-services/field';
import { FieldErrors } from 'src/app/shared/template-services/field/field-errors';
import { FieldService } from 'src/app/shared/template-services/field/field.service';
import {
    FieldDefinition,
    LayoutSchema,
    LayoutSchemaProperty
} from 'src/app/shared/template-services/field/fieldDefinition';
import { FieldsStoreService } from 'src/app/shared/template-services/field/fields-store.service';
import { Condition } from 'src/app/shared/template-services/rule/condition';
import { Rule, RuleType } from 'src/app/shared/template-services/rule/rule';
import { Target } from 'src/app/shared/template-services/rule/target';
import { Template } from 'src/app/shared/template-services/template';
import { TemplateComposerValidityStreamService } from 'src/app/shared/template-services/template-composer-validity-stream.service';

import { RuleSelectionStreamService } from '../../rules-panel/rule-selection-stream.service';
import { RuleService } from '../../rules-panel/rule.service';
import {
    CurrentTabStreamService,
    TemplateEditorTab,
    TemplateEditorTabId
} from '../../tabs-list/current-tab-stream.service';
import { TemplateRouteData } from '../../template-editor-route-resolver.service';

@Component({
    selector: 'field-item',
    templateUrl: './field-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
    // Change detection will not run for this component if inputs are mutated
    // Used with cdr.markForCheck() to manually trigger change detection when changes occur outside template
    // https://jira.trimble.tools/browse/TCMAPS-3149
})
export class FieldItemComponent implements OnInit, OnDestroy {
    private destroyed = new Subject<void>();
    @Input()
    template: Template;

    @Input()
    field: Field;

    @Input()
    selectedField: Field;

    @Output()
    fieldSelected = new EventEmitter<Field>();

    @Input()
    currentTab: TemplateEditorTab;

    @Output()
    dropEvent = new EventEmitter<void>();

    @Input()
    coords: number;

    @Input()
    currentProjectDetails: TemplateRouteData;

    @Input()
    templateEditorConfig: {
        tabs: { fields: { fieldsGroup: any[]; enableFieldConditions: { [key: string]: any } }; rule: any };
    };

    @Input()
    application: Application;

    @Output()
    ruleChanged = new EventEmitter<void>();

    @Output()
    duplicateField = new EventEmitter<Field>();

    properties: any[];
    expanded = false;
    selectedRule: Rule;
    condition: Condition;
    target: Target;
    fieldMeta: FieldDefinition;
    layoutSchema: LayoutSchema;
    propertiesOnHeader: LayoutSchemaProperty[];
    ruleToBeUsed: any;
    validationErrors: AllValidationErrors[];
    showError = false;

    // expose ButtonType enum to template
    public ButtonType = ButtonType;
    public LayoutFieldType = LayoutFieldType;
    public AutoFieldModelType = AutoFieldModelType;
    public TemplateEditorTabId = TemplateEditorTabId;
    public RuleType = RuleType;

    @ViewChild('fieldForm', { static: true })
    fieldGroup: NgForm;

    @ViewChildren(NgModel)
    models: QueryList<NgModel>;

    constructor(
        private ruleSelectionStream: RuleSelectionStreamService,
        private currentTabStream: CurrentTabStreamService,
        private fieldsStore: FieldsStoreService,
        private fieldService: FieldService,
        private translate: TranslationService,
        private templateComposerValidity: TemplateComposerValidityStreamService,
        private ruleService: RuleService,
        private cdr: ChangeDetectorRef
    ) {}

    get isSelected(): boolean {
        return this.selectedField && this.field && this.selectedField.uuid === this.field.uuid;
    }

    ngOnInit(): void {
        this.fieldsStore
            .getFieldByType(this.field.type, this.application.name, this.application.category)
            .then(fieldWithSchema => {
                if (fieldWithSchema) {
                    this.fieldMeta = CloneUtils.cloneDeep(fieldWithSchema);
                    this.layoutSchema = fieldWithSchema.application && fieldWithSchema.application.layoutSchema;
                    this.propertiesOnHeader =
                        this.layoutSchema &&
                        this.layoutSchema.properties &&
                        this.layoutSchema.properties.filter(property => property.position === 'header');

                    this.properties = this.layoutSchema && this.layoutSchema.properties;

                    this.ruleToBeUsed = this.layoutSchema.rule;
                    this.cdr.markForCheck();
                }
            });

        this.ruleSelectionStream.ruleSelectionStream.pipe(takeUntil(this.destroyed)).subscribe(selectedRule => {
            if (selectedRule) {
                this.selectedRule = selectedRule;
                this.condition = selectedRule.getCondition(this.field.uuid);
                this.target = selectedRule.getTarget(this.field.uuid);
                this.cdr.markForCheck();
            }
        });

        // Sensor fields are added as a group so we want to keep the group highlighted, not the last
        // field within the group.
        if (this.field.status === FieldStatus.NEW && !this.field.sensorMappingId) {
            this.toggleField(this.field, true);
        }

        this.fieldGroup.statusChanges.pipe(takeUntil(this.destroyed)).subscribe(validity => {
            this.templateComposerValidity.setTemplateFieldValidity({
                fieldId: this.field.name,
                validity: validity
            });
            // show field error icon and message based on field validity
            this.showError = this.fieldGroup.invalid;
            let errors = this.checkValidationErrors();
            if (!_.isEqual(this.validationErrors, errors)) {
                this.validationErrors = errors;
            }
            this.cdr.markForCheck();
        });

        merge(this.fieldService.updateAllFieldsStream$, this.field.fieldUpdatedStream$)
            .pipe(takeUntil(this.destroyed))
            .subscribe(() => {
                this.cdr.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
        this.templateComposerValidity.removeFieldFromValidityObj(this.field.name);
    }

    findPropertyOnPath(properties: any[], path: string[]): any {
        let id = path.splice(0, 1)[0];

        let prop = properties.find(p => p.id === id);

        if (path.length === 0 || prop === undefined) {
            // Added to handle uuid prefix for choice field values
            if (path.length !== 0) {
                return this.findPropertyOnPath(properties, path);
            }
            return prop;
        }

        if (!prop.properties) {
            return undefined;
        }

        return this.findPropertyOnPath(prop.properties, path);
    }

    checkValidationErrors(): AllValidationErrors[] {
        // ! WIP: Currently covers all except ranges

        if (!this.fieldGroup) {
            return [];
        }
        return getFormValidationErrors(this.fieldGroup.control.controls)
            .map(err => {
                let prop =
                    err.controlName === 'fieldName'
                        ? {}
                        : this.findPropertyOnPath(this.properties, err.controlName.split('.'));

                if (!prop) {
                    return null;
                }

                return Object.assign(err, {
                    property: prop, // TODO: REMOVE TEMP
                    id: prop.id, // TODO: REMOVE IF UNUSED AT END OF PORT
                    type: prop.type, // TODO: REMOVE IF UNUSED AT END OF PORT

                    message: FieldErrors.errorMessages[err.errorName].message,
                    translateParams: {
                        fieldLabel: this.translate.instant(prop.label || 'TCS.TemplateEditor.Validate.ThisField'),
                        maxLength: err.errorName === 'maxlength' ? err.errorValue.requiredLength : undefined,
                        maximum: ['max', 'minmax'].includes(err.errorName)
                            ? err.errorValue.maximum
                            : err.errorName === 'maxDate'
                            ? DateUtils.formatDateFromDateTime(err.errorValue.maximum, 'medium', false)
                            : undefined,
                        minimum: ['min', 'minmax'].includes(err.errorName)
                            ? err.errorValue.minimum
                            : err.errorName === 'minDate'
                            ? DateUtils.formatDateFromDateTime(err.errorValue.minimum, 'medium', false)
                            : undefined
                    }
                });
            })
            .filter(e => e !== null);
    }

    toggleField(field: Field, openField: boolean): void {
        if (openField && !this.isSelected) {
            this.fieldSelected.emit(field);
        } else if (!openField && this.isSelected) {
            this.fieldSelected.emit(null);
        }
    }

    get hasConditionErrors(): boolean {
        return (
            !!this.field.conditionRuleErrors ||
            (this.field.conditioned && !!this.field.stopConditionedOrTargeted) ||
            this.field.incompleteRule ||
            (this.condition && !!this.condition.getErrorMessages().length)
        );
    }

    get hasTargetErrors(): boolean {
        return (
            !!this.field.targetRuleErrors ||
            (this.field.target && !!this.field.stopConditionedOrTargeted) ||
            this.field.incompleteRule ||
            (this.target && !!this.target.getErrorMessages().length)
        );
    }

    goToRule(): void {
        // If new rule isn't created, there must already be a blank rule
        let newRule = this.template.createNewRule() || this.template.rules[0];
        this.template.populateChoiceValues(true);
        this.ruleSelectionStream.ruleSelectionStream.next(newRule);
        this.condition = this.template.setSource(this.field, newRule, this.layoutSchema.rule);
        const ruleTab = this.templateEditorConfig.tabs[TemplateEditorTabId.RULE];
        this.currentTabStream.currentTab$.next({
            id: ruleTab.id,
            name: ruleTab.label,
            iconClass: ruleTab.iconClass
        });
    }

    removeRule(selectedRule: Rule): void {
        this.ruleService.removeRule(selectedRule, this.template);
    }
}
