import { Injectable } from '@angular/core';
import { CheckVisibilityService } from 'src/app/shared/common/components/check-visibility.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { CurrentUserStreamService } from 'src/app/shared/common/current-user/current-user-stream.service';
import { GeneralUtils } from 'src/app/shared/common/utility/general-utils';
import { GeometryTypes } from 'src/app/shared/common/utility/geometry-utils';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';
import { MapWorkspacePermissionType } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace-permission';

@Injectable({ providedIn: 'root' })
export class LayerListService {
    currentUserIsAdmin: boolean;
    layers: Layer[];
    loadingLayerIds: string[];
    templateLayers: Layer[];
    constructor(
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private projectStream: ProjectStreamService,
        private currentUserStream: CurrentUserStreamService,
        private checkVisibilityService: CheckVisibilityService
    ) {
        projectStream.getUserWithRolesForCurrentProject(currentUserStream.currentUser.id).then(userWithRole => {
            this.currentUserIsAdmin = userWithRole.role === 'ADMIN' ? true : false;
        });
    }

    public restrictAccess(visibleModes: MapWorkspacePermissionType[]): boolean {
        return this.checkVisibilityService.restrictAccess(
            visibleModes,
            this.mapWorkspacesStore.getCurrentMapWorkspace()
        );
    }

    public canViewLayer(layer: Layer): boolean {
        return layer.visible || layer.nonSpatialCount > 0;
    }

    public canCreateTemplateFromLayer(layer: Layer): boolean {
        return !layer.templateId && layer.geometryType !== 'Collection';
    }

    public canEditTemplate(layer: Layer): boolean {
        return !GeneralUtils.isNullUndefinedOrNaN(layer.templateId);
    }

    public canLockLayer(layer: Layer): boolean {
        const currentMapWorkspace = this.mapWorkspacesStore.getCurrentMapWorkspace();
        return (
            !currentMapWorkspace.isFileViewer &&
            Boolean(layer.templateId) &&
            (layer.templatePublishStatus || (!layer.templatePublishStatus && layer.templateVersionId !== 1))
        );
    }

    public canEditLayer(layer: Layer): boolean {
        let currentMapWorkspace = this.mapWorkspacesStore.getCurrentMapWorkspace();
        return (
            ((layer.templateId && layer.templatePublishStatus) ||
                (layer.geoLayerType === 'ImportedLayer' && !currentMapWorkspace.isFileViewer)) &&
            (currentMapWorkspace.permission === MapWorkspacePermissionType.FULL_ACCESS || this.currentUserIsAdmin) &&
            layer.geometryType !== GeometryTypes.NONE
        );
    }

    public canZoomToExtent(layer: Layer): boolean {
        return layer.visible;
    }

    public canDiscardDraftChanges(layer: Layer): boolean {
        return (
            !this.restrictAccess([MapWorkspacePermissionType.FULL_ACCESS]) &&
            !!layer.templateId &&
            !layer.templatePublishStatus &&
            layer.templateVersionId > 1
        );
    }
}
