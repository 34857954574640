export class AuthenticationUtil {
    static getQueryParams(): { [key: string]: string } {
        const queryString = window.location.href.substr(window.location.href.indexOf('?') + 1);
        const queryParamsArr = queryString.split('&') || [];
        const queryParams = queryParamsArr.reduce(
            (acc, param) => {
                const tmpParam = param.split('=');
                acc[tmpParam[0]] = tmpParam[1];
                return acc;
            },
            {} as { [key: string]: string }
        );
        return queryParams;
    }

    static constructUrlEncodedParams(params: { [key: string]: any }): string {
        const encodedPairs: string[] = [];
        Object.keys(params).forEach(key => {
            const encodedKey = encodeURIComponent(key);
            const encodedValue = encodeURIComponent(params[key]);
            encodedPairs.push(encodedKey + '=' + encodedValue);
        });
        return encodedPairs.join('&');
    }
}
