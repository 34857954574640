import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { FloatConverter, InputConverter } from 'src/app/shared/common/components/input-converter.decorator';

@Directive({
    selector: '[maxValue]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MaxValidatorDirective, multi: true }]
})
export class MaxValidatorDirective implements Validator, OnChanges {
    @Input()
    @InputConverter(FloatConverter)
    maxValue: number;

    private _onValidatorChange = () => {};

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.maxValue) {
            this._onValidatorChange();
        }
    }

    registerOnValidatorChange?(fn: () => void): void {
        this._onValidatorChange = fn;
    }

    validate(control: AbstractControl): ValidationErrors {
        if (
            this.maxValue !== null &&
            this.maxValue !== undefined &&
            control.value &&
            parseFloat(control.value) > this.maxValue
        ) {
            return { max: { maximum: this.maxValue } };
        }
        return null;
    }
}
