<div [ngClass]="{ 'field-edit-group': editMode }" class="feature-field-group">
    <div class="page-header-tab-container" *ngIf="pageHeaderTitles.length > 1">
        <custom-scroll scrollDirection="horizontal" [triggerScrollToLeft]="scrollToLeft">
            <ul class="nav nav-tabs">
                <li
                    *ngFor="let pageHeader of pageHeaderTitles"
                    [ngClass]="{ active: pageHeader === activeTab, 'empty-page-header': pageHeader === '' }"
                    (click)="setActiveTab(pageHeader)"
                    title="{{ tabFields[pageHeader].displayName }}"
                >
                    <div class="menu-sub-heading">
                        {{ tabFields[pageHeader].displayName }}
                    </div>
                </li>
            </ul>
        </custom-scroll>
    </div>
    <div *ngIf="tabFields[activeTab]">
        <div class="page-content" [ngClass]="{ 'empty-page-header': !activeTab }">
            <h2 class="feature-page-title" *ngIf="activeTab">
                <h4 title="{{ tabFields[activeTab].displayName }}">
                    {{ tabFields[activeTab].displayName }}
                </h4>
                <div class="page-count">
                    {{ tabFields[activeTab].pageCount }} {{ 'TC.Common.Of' | translate }} {{ totalPages }}
                </div>
            </h2>
        </div>
        <div class="feature-page" *ngFor="let field of tabFields[activeTab].fields">
            <div class="group-content" *ngIf="field.type === LayoutFieldType.Group">
                <h3 class="feature-page-title">{{ field.displayName }}</h3>
                <div class="field-content" *ngFor="let fieldInGroup of field.fields">
                    <text-field
                        class="feature-field-container"
                        [field]="fieldInGroup"
                        *ngIf="fieldInGroup.type === FieldType.Text || fieldInGroup.type === FieldType.Barcode"
                        [editMode]="editMode"
                    ></text-field>
                    <date-field
                        class="feature-field-container"
                        [field]="fieldInGroup"
                        *ngIf="fieldInGroup.type === FieldType.Date"
                        [editMode]="editMode"
                    ></date-field>
                    <number-field
                        class="feature-field-container"
                        [field]="fieldInGroup"
                        *ngIf="
                            fieldInGroup.type === FieldType.Number ||
                            fieldInGroup.type === FieldType.Angle ||
                            fieldInGroup.type === FieldType.Length
                        "
                        [editMode]="editMode"
                    ></number-field>
                    <choice-field
                        class="feature-field-container"
                        [field]="fieldInGroup"
                        *ngIf="fieldInGroup.type === FieldType.Choice"
                        [editMode]="editMode"
                    >
                    </choice-field>
                    <coded-choice-field
                        class="feature-field-container"
                        [field]="fieldInGroup"
                        *ngIf="fieldInGroup.type === FieldType.CodedChoice"
                        [editMode]="editMode"
                    >
                    </coded-choice-field>
                    <image-field
                        class="feature-field-container"
                        [field]="fieldInGroup"
                        *ngIf="fieldInGroup.type === FieldType.Image || fieldInGroup.type === FieldType.Signature"
                        [editMode]="editMode"
                    ></image-field>
                    <yes-no-field
                        class="feature-field-container"
                        [field]="fieldInGroup"
                        *ngIf="fieldInGroup.type === FieldType.YesNo"
                        [editMode]="editMode"
                    >
                    </yes-no-field>
                    <auto-field
                        class="feature-field-container"
                        [field]="fieldInGroup"
                        *ngIf="isGroupAutoField(fieldInGroup)"
                        [editMode]="editMode"
                    ></auto-field>
                    <div
                        class="summary-button"
                        *ngIf="
                            (fieldInGroup.type === FieldType.Choice || fieldInGroup.type === FieldType.CodedChoice) &&
                            !editMode
                        "
                        (click)="toggleSummaryPanel(fieldInGroup)"
                    >
                        <i
                            [ngClass]="
                                (summaryPanelActiveMap$ | async)[fieldInGroup.uuid]
                                    ? 'icon-summary-active i24'
                                    : 'icon-summary i24'
                            "
                        ></i>
                    </div>
                </div>
            </div>
            <div
                class="field-content"
                *ngIf="field.type !== LayoutFieldType.Group && field.type !== LayoutFieldType.PageHeader"
            >
                <text-field
                    class="feature-field-container"
                    [field]="field"
                    *ngIf="field.type === FieldType.Text || field.type === FieldType.Barcode"
                    [editMode]="editMode"
                ></text-field>
                <date-field [field]="field" *ngIf="field.type === FieldType.Date" [editMode]="editMode"></date-field>
                <number-field
                    class="feature-field-container"
                    [field]="field"
                    *ngIf="
                        field.type === FieldType.Number ||
                        field.type === FieldType.Angle ||
                        field.type === FieldType.Length
                    "
                    [editMode]="editMode"
                ></number-field>
                <choice-field
                    class="feature-field-container"
                    [field]="field"
                    *ngIf="field.type === FieldType.Choice"
                    [editMode]="editMode"
                ></choice-field>
                <coded-choice-field
                    class="feature-field-container"
                    [field]="field"
                    *ngIf="field.type === FieldType.CodedChoice"
                    [editMode]="editMode"
                ></coded-choice-field>
                <image-field
                    class="feature-field-container"
                    [field]="field"
                    *ngIf="field.type === FieldType.Image || field.type === FieldType.Signature"
                    [editMode]="editMode"
                ></image-field>
                <yes-no-field
                    class="feature-field-container"
                    [field]="field"
                    *ngIf="field.type === FieldType.YesNo"
                    [editMode]="editMode"
                ></yes-no-field>
                <auto-field
                    class="feature-field-container"
                    [field]="field"
                    *ngIf="field.type === FieldType.Autofield"
                    [editMode]="editMode"
                ></auto-field>
                <div
                    class="summary-button"
                    *ngIf="(field.type === FieldType.Choice || field.type === FieldType.CodedChoice) && !editMode"
                    (click)="toggleSummaryPanel(field)"
                >
                    <i
                        [ngClass]="
                            (summaryPanelActiveMap$ | async)[field.uuid] ? 'icon-summary-active i24' : 'icon-summary i24'
                        "
                    ></i>
                </div>
            </div>
        </div>
    </div>
</div>
