import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { Rtc } from 'src/app/feature/rtc/Rtc';
import { RtcOptions, RtcSourceTypes } from 'src/app/feature/rtc/rtc-options';
import { RtcStore } from 'src/app/feature/rtc/rtc-store.service';
import { RtcStreamService } from 'src/app/feature/rtc/rtc-stream.service';
import { centerDialogCloseTransition, centerDialogEnterTransition } from 'src/app/shared/common/animations/gsp-animations';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ModalSize } from 'src/app/shared/common/modal-sizes';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';
import { GeneralUtils } from 'src/app/shared/common/utility/general-utils';
import { UtilitiesService } from 'src/app/shared/common/utility/utilities.service';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { UserRole } from 'src/app/shared/user/user';

@Component({
    selector: 'rtc-panel',
    animations: [
        trigger('inOutAnimation', [
            transition(':enter', useAnimation(centerDialogEnterTransition)),
            transition(':leave', useAnimation(centerDialogCloseTransition))
        ])
    ],
    templateUrl: './rtc-panel.component.html'
})
export class RtcPanelComponent implements OnInit, OnDestroy {
    @Output()
    isModalShowing: EventEmitter<boolean> = new EventEmitter();

    public ModalSize = ModalSize;
    public startIndex = 0;
    public sourceTypes: { id: string; name: string }[] = RtcOptions.sourceTypes;
    public secondarySourceTypes: { id: boolean; name: string }[] = RtcOptions.secondarySourceTypes;
    public rtcList: Rtc[] = [];
    public pageLoading = true;
    public editingRtc: Rtc = null;
    public originalRtc: Rtc = null;
    public isEditMode = false;
    public showRtcEditOptions = false;
    public rtcInEdit: Rtc = null;
    public isDeleteConfirmationOpen = false;

    // expose enums to template
    public ButtonType = ButtonType;
    public RtcSourceTypes = RtcSourceTypes;
    public UserRole = UserRole;

    private currentWorkspace: MapWorkspace;

    private readonly destroyed = new Subject<void>();

    constructor(
        private rtcStore: RtcStore,
        private rtcStream: RtcStreamService,
        private translate: TranslationService,
        private messaging: MessagingService,
        private mapworkspacesStore: MapWorkspacesStoreService
    ) {}

    async ngOnInit(): Promise<void> {
        this.currentWorkspace = this.mapworkspacesStore.getCurrentMapWorkspace();
        await this.rtcStore.loadAllRtc(this.startIndex, null);
        this.rtcStream.rtcListStream.pipe(takeUntil(this.destroyed)).subscribe(rtcList => {
            if (rtcList) {
                if (!_.isEqual(this.rtcList, rtcList)) {
                    this.rtcList = CloneUtils.cloneDeep(rtcList);
                    this.rtcList = this.setRtcWorkspacesCompatibility(this.rtcList);
                    this.rtcList = this.sortRtcs(this.rtcList);
                }
            }
        });

        this.rtcStream.rtcListLoadingStream.pipe(takeUntil(this.destroyed)).subscribe(loading => {
            this.pageLoading = loading;
        });
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    public create(): void {
        let newRtc = new Rtc();
        const currentRtcs = this.rtcStore.rtcStream.getValue();
        let rtcNameList = currentRtcs.map(rtc => rtc.name);
        let rtcName = 'New Real-time Config';
        let newName = UtilitiesService.getNewName(rtcName, rtcNameList);
        newRtc.setName(newName);
        this.setSelectedRtc(newRtc);
        this.isEditMode = false;
        this.showRtcEditOptions = true;
        this.isModalShowing.emit(true);
    }

    public editRtc(rtc: Rtc, event: MouseEvent): void {
        event.stopPropagation();
        this.setSelectedRtc(rtc);
        this.isEditMode = true;
        this.showRtcEditOptions = true;
        this.isModalShowing.emit(true);
    }

    public setSelectedRtc(rtc: Rtc): void {
        this.editingRtc = CloneUtils.cloneDeep(rtc);
        this.originalRtc = CloneUtils.cloneDeep(rtc);
    }

    public getSourceType1(sourceType1: string): { id: string; name: string } {
        return _.find(this.sourceTypes, sourceType => sourceType.id === sourceType1);
    }

    public getSourceType2(sourceType2: boolean): string {
        const source = _.find(this.secondarySourceTypes, sourceType => sourceType.id === sourceType2);

        return source.name !== 'None' ? source.name : null;
    }

    public closeFullscreenRtc(): void {
        this.showRtcEditOptions = false;
        this.isModalShowing.emit(false);
    }

    public isRtcAssignedToCurrentWorkspace(rtc: Rtc): boolean {
        if (rtc.isGlobal) {
            // If global RTC, it is assigned to a workspace as long as it's not
            // in excluded workspaces list.
            return !rtc.excludedWorkspaceIds.includes(this.currentWorkspace.id);
        } else {
            return rtc.workspaceIds.includes(this.currentWorkspace.id);
        }
    }

    public unassignRtc(rtc: Rtc): void {
        const currentWorkspaceId = this.mapworkspacesStore.getCurrentMapWorkspace().id;
        if (rtc.isGlobal) {
            // If global RTC, add it to excluded workspaces to unassign.
            rtc.excludedWorkspaceIds.push(currentWorkspaceId);
        } else {
            _.remove(rtc.workspaceIds, id => id === currentWorkspaceId);
        }

        this.rtcStore
            .updateRtc(rtc)
            .then(() => {
                this.messaging.showSuccess(
                    this.translate.instant('MapViewer_RTC_Unassigned_Success', { name: rtc.name })
                );
            })
            .catch(() => {
                this.messaging.showError(this.translate.instant('MapViewer_RT_Unassigned_Failure', { name: rtc.name }));
            });
    }

    public assignRtc(rtc: Rtc): void {
        const currentWorkspaceId = this.mapworkspacesStore.getCurrentMapWorkspace().id;
        if (rtc.isGlobal) {
            // If global RTC, remove it frome excluded workspaces if its been previously excluded.
            const index = rtc.excludedWorkspaceIds.indexOf(currentWorkspaceId);
            if (!GeneralUtils.isNullUndefinedOrNaN(index)) {
                rtc.excludedWorkspaceIds.splice(index);
            }
        } else {
            rtc.workspaceIds.push(currentWorkspaceId);
        }

        this.rtcStore
            .updateRtc(rtc)
            .then(() => {
                this.messaging.showSuccess(
                    this.translate.instant('MapViewer_RTC_Assigned_Success', { name: rtc.name })
                );
            })
            .catch(() => {
                this.messaging.showError(this.translate.instant('MapViewer_RTC_Assigned_Failure', { name: rtc.name }));
            });
    }

    public setRtcWorkspacesCompatibility(rtcList: Rtc[]): Rtc[] {
        rtcList.forEach(rtc => {
            rtc.isCompatible = rtc.isCompatibleWithWorkspace(this.currentWorkspace);
            rtc.isAssignedToCurrentWorkspace = this.isRtcAssignedToCurrentWorkspace(rtc);
        });
        return rtcList;
    }

    public showRtcDelete(rtc: Rtc): void {
        this.rtcInEdit = rtc;
        this.isDeleteConfirmationOpen = true;
        this.isModalShowing.emit(true);
    }

    public hideRtcDelete(): void {
        this.rtcInEdit = null;
        this.isDeleteConfirmationOpen = false;
        this.isModalShowing.emit(false);
    }

    public sortRtcs(rtcList: Rtc[]): Rtc[] {
        const compatibleRtcs = rtcList.filter(rtc => rtc.isCompatible);
        const incompatibleRtcs = rtcList.filter(rtc => !rtc.isCompatible);

        const sortedCompatibleRtcs = compatibleRtcs.sort(this.compareRtcs);
        const sortedInCompatibleRtcs = incompatibleRtcs.sort(this.compareRtcs);

        return sortedCompatibleRtcs.concat(sortedInCompatibleRtcs);
    }

    private compareRtcs = (rtc1: Rtc, rtc2: Rtc): number => {
        if (rtc1.isAssignedToCurrentWorkspace && !rtc2.isAssignedToCurrentWorkspace) {
            return -1;
        }
        if (rtc2.isAssignedToCurrentWorkspace && !rtc1.isAssignedToCurrentWorkspace) {
            return 1;
        }
        return rtc1.name.localeCompare(rtc2.name);
        // eslint-disable-next-line @typescript-eslint/semi, @typescript-eslint/member-delimiter-style
    };
}
