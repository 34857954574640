import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';

import { Rtc } from '../Rtc';
import { RtcStore } from '../rtc-store.service';

@Component({
    selector: 'rtc-delete',
    templateUrl: './rtc-delete.component.html'
})
export class RtcDeleteComponent implements OnInit {
    public deleteConfirmationMessage = '';

    @Input()
    selectedRtc: Rtc;

    @Output()
    closed = new EventEmitter();

    constructor(
        private rtcStore: RtcStore,
        private mapworkspacesStore: MapWorkspacesStoreService,
        private translate: TranslationService,
        private messaging: MessagingService
    ) {}

    ngOnInit(): void {
        const workspaceCount = this.getMappedWorkspacesCount(this.selectedRtc);

        if (workspaceCount > 0) {
            this.deleteConfirmationMessage = this.translate.instant(
                'MapViewer_RTC_Delete_Confirmation_Message_Non_Global',
                {
                    rtcName: this.selectedRtc.name,
                    workspaceCount
                }
            );
        } else {
            this.deleteConfirmationMessage = this.translate.instant(
                'MapViewer_RTC_Delete_Confirmation_Message_Non_Global_No_Mapping',
                {
                    rtcName: this.selectedRtc.name
                }
            );
        }
    }

    private getMappedWorkspacesCount(rtc: Rtc): number {
        if (rtc.isGlobal) {
            let projectMapWorkspaces = this.mapworkspacesStore.projectMapWorkspaces;
            return rtc.excludedWorkspaceIds.length
                ? projectMapWorkspaces.length - rtc.excludedWorkspaceIds.length
                : projectMapWorkspaces.length;
        } else {
            return rtc.workspaceIds.length;
        }
    }

    public deleteRtcAction = () => () => this.deleteRtc();

    private deleteRtc(): Promise<void> {
        return this.rtcStore.deleteRtc(this.selectedRtc).then(
            () => {
                this.messaging.showSuccess(
                    this.translate.instant('TCS.Mapviewer.Rtc.Messages.RtcDeletionSuccess', {
                        rtcName: this.selectedRtc.name
                    })
                );
                this.closePopup();
            },
            () => {
                this.messaging.showError(
                    this.translate.instant('TCS.Mapviewer.Rtc.Messages.RtcDeletionFailure', {
                        rtcName: this.selectedRtc.name
                    })
                );
            }
        );
    }

    public closePopup(): void {
        this.closed.emit();
    }
}
