import { Component, Input } from '@angular/core';
import { Field } from 'src/app/shared/template-services/field';

@Component({
    selector: 'field-required',
    templateUrl: './field-required.component.html'
})
export class FieldRequiredComponent {
    @Input()
    field: Field;
}
