import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LayersStore } from 'src/app/shared/common/current-layers/layers-store.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { CurrentUserStreamService } from 'src/app/shared/common/current-user/current-user-stream.service';
import { LoaderStreamService } from 'src/app/shared/common/loader/loader-stream.service';
import { ModalSize } from 'src/app/shared/common/modal-sizes';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { MapWorkspacePermissionType } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace-permission';
import { UserRole } from 'src/app/shared/user/user';

@Component({
    selector: 'workspace-delete',
    templateUrl: './workspace-delete.component.html'
})
export class WorkspaceDeleteComponent implements OnInit, OnDestroy {
    private readonly destroyed = new Subject<void>();

    public deletingMapWorkspace: MapWorkspace;
    private deleteThisWorkspaceId: string;

    public isWorkspaceDeletable = false;
    public loading = true;

    public ModalSize = ModalSize;

    constructor(
        private projectStream: ProjectStreamService,
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private layersStore: LayersStore,
        private router: Router,
        private loaderStream: LoaderStreamService,
        private currentUserStream: CurrentUserStreamService
    ) {}

    ngOnInit(): void {
        this.mapWorkspacesStore.deleteMapWorkspaceStream.pipe(takeUntil(this.destroyed)).subscribe(async workspace => {
            if (workspace && workspace.id && !workspace.isDeleted) {
                const currentUserId = this.currentUserStream.currentUser.id;
                const user = await this.projectStream.getUserWithRolesForCurrentProject(currentUserId);
                this.deletingMapWorkspace = workspace;
                this.deleteThisWorkspaceId = workspace.id;
                this.mapWorkspacesStore
                    .getMapWorkspacePermission(this.deletingMapWorkspace)
                    .then(permission => {
                        if (
                            user.role === UserRole.ADMIN ||
                            permission.effectivePermission === MapWorkspacePermissionType.FULL_ACCESS
                        ) {
                            this.isWorkspaceDeletable = true;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        });
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    public deleteWorkspaceAction = async (): Promise<void> => {
        const result = await this.deleteWorkspace();
        this.closeEditPanel(result.workspaceId, result.workspaceProjectId);
        this.loaderStream.isLoading$.next(false);
        // eslint-disable-next-line @typescript-eslint/semi, @typescript-eslint/member-delimiter-style
    };

    private async deleteWorkspace(
        hideNotification = false
    ): Promise<{ workspaceId: string; workspaceProjectId: string }> {
        this.loaderStream.isLoading$.next(true);
        // TODO: GIM 12/6/19 - also review why we're using flags on the workspace to talk to layers store.
        try {
            const nextMapWorkspace = await this.mapWorkspacesStore.deleteMapWorkspace(
                this.deleteThisWorkspaceId,
                hideNotification
            );
            let nextMapWorkspaceId: string = null;
            let nextMapWorkspaceProjectId: string = null;
            if (nextMapWorkspace && nextMapWorkspace.id) {
                (this.deletingMapWorkspace as any).isDeleted = true;
                (this.deletingMapWorkspace as any).isCurrentMapWorkspace =
                    this.mapWorkspacesStore.getCurrentMapWorkspace() &&
                    this.mapWorkspacesStore.getCurrentMapWorkspace().id === this.deletingMapWorkspace.id;
                this.mapWorkspacesStore.deleteMapWorkspaceStream.next(this.deletingMapWorkspace);
                this.layersStore.updateLayersInCacheForRemovedMapWorkspace(this.deletingMapWorkspace);
                nextMapWorkspaceId = nextMapWorkspace.id;
                nextMapWorkspaceProjectId = nextMapWorkspace.projectId;
                return { workspaceId: nextMapWorkspaceId, workspaceProjectId: nextMapWorkspaceProjectId };
            } else if (nextMapWorkspace == null) {
                nextMapWorkspaceId = '-1';
                nextMapWorkspaceProjectId = this.projectStream.getCurrentProject().id;
                return { workspaceId: nextMapWorkspaceId, workspaceProjectId: nextMapWorkspaceProjectId };
            } else {
                // TODO: GIM 12/6/19 - check if we need to force a reload here
                nextMapWorkspaceId = this.deleteThisWorkspaceId;
                nextMapWorkspaceProjectId = null;
                return { workspaceId: nextMapWorkspaceId, workspaceProjectId: nextMapWorkspaceProjectId };
            }
        } catch (currentMapWorkspace) {
            return { workspaceId: currentMapWorkspace.id, workspaceProjectId: currentMapWorkspace.projectId };
        }
    }

    public close(): void {
        this.router.navigate(
            [
                'mapViewer',
                {
                    outlets: { centerDialog: null }
                }
            ],
            {
                queryParamsHandling: 'preserve'
            }
        );
    }

    private closeEditPanel(workspaceId: string, projectId: string): void {
        this.mapWorkspacesStore.deleteMapWorkspaceStream.next(null);

        this.router.navigate(
            [
                'mapViewer',
                {
                    outlets: { centerDialog: null }
                }
            ],
            {
                queryParams: { workspaceId: workspaceId, projectId: projectId },
                queryParamsHandling: 'merge'
            }
        );
    }
}
