import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslationModule } from 'src/app/core/translation/translation.module';
import { CommonModule as AppCommonModule } from 'src/app/shared/common/common.module';

import { RtcConfigurationComponent } from './rtc-configuration/rtc-configuration.component';
import { RtcDeleteComponent } from './rtc-delete/rtc-delete.component';
import { RtcInternetComponent } from './rtc-internet/rtc-internet.component';
import { RtcPanelComponent } from './rtc-panel/rtc-panel.component';
import { RtcSearchComponent } from './rtc-search/rtc-search.component';
import { RtcSerialPortComponent } from './rtc-serial-port/rtc-serial-port.component';

@NgModule({
    declarations: [
        RtcInternetComponent,
        RtcPanelComponent,
        RtcSerialPortComponent,
        RtcConfigurationComponent,
        RtcDeleteComponent,
        RtcSearchComponent
    ],
    imports: [CommonModule, FormsModule, TranslationModule, AppCommonModule],
    exports: [RtcInternetComponent, RtcPanelComponent, RtcSerialPortComponent, RtcConfigurationComponent],
    providers: []
})
export class RtcModule {}
