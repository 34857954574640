<div class="feature-field">
    <label class="feature-label" [ngClass]="{ disabled: field.value == null }">
        {{ field.displayName }} <span *ngIf="field.required">*</span>
    </label>
    <div class="feature-data" *ngIf="!editMode && field.value">
        {{ field.value | formatDate: 'short' }}
    </div>
    <div class="editable-control date-wrapper" *ngIf="editMode">
        <div class="date-picker-wrapper">
            <date-picker
                [(ngModel)]="field.value"
                [minDate]="field.minimum"
                [maxDate]="field.maximum"
                [forceUTC]="true"
            ></date-picker>
        </div>
    </div>
</div>
