<div [hidden]="editChoiceInTextMode !== false">
    <div class="editor-field-row">
        <gsp-button
            [type]="ButtonType.Default"
            (action)="toggleTextEditor()"
            class="edit-as-text-button"
            text="{{ 'TC.Common.EditAsText' | translate }}"
            *ngIf="!locked"
        ></gsp-button>
    </div>
    <div class="editor-field-row editor-field-choice" *ngFor="let choice of value; index as i" #fieldChoices>
        <div class="code-description-wrapper">
            <input
                [name]="'code-' + i"
                class="gsp-form-input default code"
                [ngClass]="{ 'disabled-txt': locked }"
                type="text"
                [(ngModel)]="choice.code"
                (ngModelChange)="signalChanged()"
                [disabled]="locked"
                required
                maxlength="{{ maxLength }}"
                pattern=".*\S+.*"
            />

            <input
                [name]="'description-' + i"
                class="gsp-form-input default description"
                [ngClass]="{ 'disabled-txt': locked }"
                type="text"
                [(ngModel)]="choice.description"
                (ngModelChange)="signalChanged()"
                [disabled]="locked"
                required
                pattern=".*\S+.*"
            />
        </div>
        <div>
            <gsp-checkbox
                *ngIf="showDefaultProp"
                [(value)]="choice.default"
                [disabled]="!enableDefaultProp"
                [id]="name + 'choice' + i"
                [label]="'TC.Common.Default' | translate"
                [ngClass]="{ 'disabled-field': !enableDefaultProp }"
                (valueChange)="resetPreviousDefaultChoice(choice)"
            ></gsp-checkbox>
        </div>
        <div>
            <up-down-order
                [item]="choice"
                [itemList]="value"
                (highlightFieldChoice)="highlightFieldChoice($event)"
            ></up-down-order>
        </div>
        <div>
            <gsp-button
                [type]="ButtonType.Icon"
                (action)="removeChoice(choice)"
                class="remove-choice-button"
                iconClass="i24 icon_line_cancel"
                *ngIf="!locked"
            ></gsp-button>
        </div>
    </div>

    <div class="editor-field-row">
        <gsp-button
            [type]="ButtonType.Default"
            (action)="addNewChoice()"
            class="add-new-choice-button"
            text="{{ 'TC.Common.AddChoice' | translate }}"
            *ngIf="!locked"
        ></gsp-button>
    </div>
</div>
<div class="edit-text" [hidden]="!editChoiceInTextMode">
    <div class="editor-field-row">
        <div class="description-metadata mb10" translate="TCS.TemplateEditor.Tf.Fields.EditAsTextDescr"></div>
    </div>

    <div class="editor-field-row">
        <gsp-text-area name="editChoiceList" [(value)]="choiceEditingListAsString"></gsp-text-area>
    </div>
    <div class="editor-field-row">
        <gsp-button
            [type]="ButtonType.Default"
            (action)="toggleTextEditor()"
            text="{{ 'cancel' | translate }}"
        ></gsp-button>
        <gsp-button
            [type]="ButtonType.Primary"
            (action)="saveChoiceInTextMode()"
            text="{{ 'save' | translate }}"
        ></gsp-button>
    </div>
</div>
