<gsp-modal
    [size]="ModalSize.Xlarge"
    [heading]="'MapViewer.RTC.SerialDialog.Header'"
    (closed)="cancel()"
    class="rtc-configuration__serial"
    id="rtc-serial-configuration"
>
    <div transclude-popup-content class="flex-row-new">
        <section class="rtc-edit-form flex5-new pt-0 pr-0">
            <custom-scroll>
                <div class="rtc-edit-form-wrapper">
                    <ng-form #rtcEditForm="ngForm">
                        <div class="rtc-edit-form-group">
                            <h5 translate="TCS.Mapviewer.Rtc.Labels.BaseType"></h5>
                            <gsp-dropdown
                                name="baseType"
                                [options]="baseTypes"
                                [sort]="false"
                                [(ngModel)]="editingRtc.baseType"
                                textField="name"
                                id="rtc-serial-base-type"
                                [ngDisabled]="editingRtc.isLegacyRtc() || originalRtc.isExistingRtc()"
                            ></gsp-dropdown>
                        </div>
                        <div class="rtc-edit-form-group">
                            <h5 translate="TCS.Mapviewer.Rtc.Labels.ComPort"></h5>
                            <gsp-dropdown
                                name="comPort"
                                [options]="comPorts"
                                [sort]="false"
                                [(ngModel)]="editingRtc.serialPortComPort"
                                textField="name"
                                id="rtc-serial-com-port"
                                [ngDisabled]="editingRtc.isLegacyRtc() || originalRtc.isExistingRtc()"
                            ></gsp-dropdown>
                        </div>
                        <div class="rtc-edit-form-group">
                            <h5>{{ 'TCS.Mapviewer.Rtc.Labels.BaudRate' | translate | capitalize }}</h5>
                            <gsp-dropdown
                                name="baud"
                                [options]="baudRates"
                                [sort]="false"
                                [(ngModel)]="editingRtc.serialPortBaudRate"
                                textField="name"
                                id="rtc-serial-baud-rate"
                                [ngDisabled]="editingRtc.isLegacyRtc() || originalRtc.isExistingRtc()"
                            ></gsp-dropdown>
                        </div>
                        <div class="rtc-edit-form-group">
                            <h5>{{ 'TCS.Mapviewer.Rtc.Labels.DataBits' | translate | capitalize }}</h5>
                            <gsp-dropdown
                                name="databits"
                                [options]="dataBits"
                                [sort]="false"
                                [(ngModel)]="editingRtc.serialPortDataBits"
                                textField="name"
                                id="rtc-serial-data-bits"
                                [ngDisabled]="editingRtc.isLegacyRtc() || originalRtc.isExistingRtc()"
                            ></gsp-dropdown>
                        </div>
                        <div class="rtc-edit-form-group">
                            <h5>{{ 'TCS.Mapviewer.Rtc.Labels.StopBits' | translate | capitalize }}</h5>
                            <gsp-dropdown
                                name="stopbits"
                                [options]="stopBits"
                                [sort]="false"
                                [(ngModel)]="editingRtc.serialPortStopBits"
                                textField="name"
                                id="rtc-serial-stop-bits"
                                [ngDisabled]="editingRtc.isLegacyRtc() || originalRtc.isExistingRtc()"
                            ></gsp-dropdown>
                        </div>
                        <div class="rtc-edit-form-group">
                            <h5>{{ 'TCS.Mapviewer.Rtc.Labels.Parity' | translate | capitalize }}</h5>
                            <gsp-dropdown
                                name="parity"
                                [options]="parities"
                                [sort]="false"
                                [(ngModel)]="editingRtc.serialPortParity"
                                textField="name"
                                id="rtc-serial-parity"
                                [ngDisabled]="editingRtc.isLegacyRtc() || originalRtc.isExistingRtc()"
                            ></gsp-dropdown>
                        </div>

                        <div class="rtc-edit-form-group">
                            <h5>{{ 'TCS.Mapviewer.Rtc.Messages.Datum' | translate | capitalize }}</h5>
                            <loading *ngIf="datumIsLoading"></loading>
                            <gsp-button
                                *ngIf="!originalRtc.isExistingRtc() && !editingRtc.datumComponentId && !datumIsLoading"
                                [type]="ButtonType.Default"
                                (action)="loadDatums()"
                                text="{{ 'MapViewer.RTC.Select.Datum' | translate }}"
                                id="rtc-load-datums"
                            >
                            </gsp-button>

                            <ng-container *ngIf="!datumIsLoading && !showDatums">
                                <div class="selected-datum" *ngIf="editingRtc.isLegacyRtc()">
                                    <div class="flex-row-new no-gutters">
                                        <h5 class="font-title flex5-new">
                                            {{ rtcDatum?.datumName }}
                                        </h5>
                                        <div class="flex5-new">
                                            <div
                                                translate="TCS.Mapviewer.Rtc.Messages.Datum"
                                                title="{{ 'TCS.Mapviewer.Rtc.Messages.Datum' | translate }}"
                                                class="font-title"
                                            ></div>
                                            <div>{{ rtcDatum?.datumType }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="selected-datum"
                                    *ngIf="!originalRtc.isLegacyRtc() && originalRtc.isExistingRtc()"
                                >
                                    <div class="flex-row-new no-gutters">
                                        <h5 class="font-title flex5-new">
                                            {{ this.editingRtc.correctionDatum?.displayName }}
                                        </h5>
                                    </div>
                                </div>

                                <!-- new RTC  -->
                                <div
                                    class="selected-datum"
                                    *ngIf="!originalRtc.isExistingRtc() && editingRtc.isPostCsSupportRtc()"
                                >
                                    <div class="flex-row-new no-gutters">
                                        <h5 class="font-title flex11-new">
                                            {{ getDatum(this.editingRtc.datumComponentId).displayName }}
                                        </h5>

                                        <gsp-button
                                            class="flex1-new"
                                            [type]="ButtonType.Icon"
                                            [iconClass]="'i24 icon_line_cancel'"
                                            (action)="resetDatum()"
                                            id="reset-datum"
                                        ></gsp-button>
                                    </div>
                                </div>
                            </ng-container>

                            <gsp-notification [type]="NotificationType.Error" class="mt-1 mb-1" *ngIf="datumError">
                                <div>
                                    {{ 'MapViewer.RTC.DatumsLoadingError' | translate }}
                                    {{ 'MapViewer.Workspace.CoordinateSystem.Outage' | translate }}
                                </div>
                            </gsp-notification>
                        </div>
                    </ng-form>
                </div>
            </custom-scroll>
        </section>

        <section class="rtc-data-list rtc-data-list--empty flex7-new pt-0" [hidden]="showDatums">
            <div class="positioning-placeholder-image">
                <img src="assets/images/SVGs-24scale/icon_line_positioning_24-48-96.svg" alt="" />
            </div>
        </section>

        <section class="rtc-data-list flex7-new pt-0" [hidden]="!showDatums">
            <div>
                <h5 class="font-title">{{ 'MapViewer.RTC.Select.Datum' | translate }}</h5>
                <rtc-search
                    [items]="datums"
                    [keyToFilterBy]="'displayName'"
                    [label]="'MapViewer.RTC.Select.Datum.OrTap'"
                    [clearSearch$]="clearSearch.asObservable()"
                    [searchId]="'datum-search'"
                    (filteredItems)="updateFilteredDatums($event)"
                >
                </rtc-search>
            </div>

            <div class="rtc-data-list-items">
                <custom-scroll>
                    <div class="ntrip-list">
                        <ul
                            class="ntrip-list-items"
                            *ngFor="let item of filteredDatums"
                            [ngClass]="{ 'selected-active': editingRtc.datumComponentId === item.componentID }"
                            (click)="selectDatum(item)"
                        >
                            <li class="flex-row-new no-gutters datum-item-line">
                                <div>{{ item.displayName }}</div>
                            </li>
                        </ul>
                    </div>
                </custom-scroll>
            </div>
        </section>
    </div>
    <ng-container transclude-popup-footer>
        <gsp-button
            [type]="ButtonType.Primary"
            (action)="save()"
            [disabled]="!canSave() || editingRtc.isLegacyRtc()"
            text="{{ 'TC.Common.Save' | translate }}"
            id="rtc-serial-save"
        >
        </gsp-button>
        <gsp-button
            [type]="ButtonType.Default"
            (action)="cancel()"
            text="{{ 'TC.Common.Cancel' | translate }}"
            id="rtc-serial-cancel"
        ></gsp-button>
    </ng-container>
</gsp-modal>
