import { Component } from '@angular/core';
import { MapWorkspacePermissionType } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace-permission';

@Component({
    selector: 'top-toolbar',
    templateUrl: './top-toolbar.component.html'
})
export class TopToolbarComponent {
    public map = 'map';

    // exposing enum to template
    public MapWorkspacePermissionType = MapWorkspacePermissionType;
}
