import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SidePanelStreamsService } from 'src/app/feature/map-viewer/side-panel/side-panel-streams.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';

import { SidePanelName } from '../../../side-panel/side-panel.component';
import { collaborationToolList, CollaborationToolListItem } from './collaboration-tool-list';

@Component({
    selector: 'collaboration-tools',
    templateUrl: './collaboration-tools.component.html'
})
export class CollaborationToolsComponent {
    @Input()
    public map: L.Map;
    // expose ButtonType enum to template
    public ButtonType = ButtonType;
    public collaborationToolList: CollaborationToolListItem[];

    constructor(private sidePanelStreams: SidePanelStreamsService, private router: Router) {
        this.collaborationToolList = collaborationToolList;
    }

    public selectTool(selectedTool: CollaborationToolListItem): boolean {
        this.collaborationToolList.forEach(tool => {
            tool.selected = selectedTool.id === tool.id;
        });
        switch (selectedTool.name) {
            case 'todo':
                this.sidePanelStreams.closeSidePanel(SidePanelName.EXISTING_TEMPLATE);
                this.sidePanelStreams.closeSidePanel(SidePanelName.EXISTING_LAYER);
                this.sidePanelStreams.closeAndReopenSidePanel(SidePanelName.TASK_DETAIL, { taskId: null });
                break;
            case 'export':
                // $state.go('mapViewer.export');
                this.router.navigate(['mapViewer', { outlets: { centerDialog: 'export' } }], {
                    queryParamsHandling: 'preserve'
                });
                break;
        }
        return true;
    }
}
