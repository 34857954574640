import { Component, OnDestroy, OnInit } from '@angular/core';
import { QueryParamsHandling, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { MainMenuStreamService } from 'src/app/feature/map-viewer/main-menu/main-menu-stream.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { ContextMenuActions } from 'src/app/shared/common/components/context-menu/gsp-context-menu.component';
import { GspTextType } from 'src/app/shared/common/components/gsp-text/gsp-text.component';
import { MapWorkspaceStreamsService } from 'src/app/shared/common/current-map-workspaces/map-workspace-streams.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { CurrentUserStreamService } from 'src/app/shared/common/current-user/current-user-stream.service';
import { LoaderStreamService } from 'src/app/shared/common/loader/loader-stream.service';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { MapWorkspaceStatus } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace.types';

@Component({
    selector: 'workspace-panel',
    templateUrl: './workspace-panel.component.html'
})
export class WorkspacePanelComponent implements OnInit, OnDestroy {
    // expose ButtonType enum to template
    public ButtonType = ButtonType;
    public GspTextType = GspTextType;
    public contextMenuItems: ContextMenuActions[] = [
        {
            name: this.translate.instant('Common.Details'),
            id: 'details-workspace',
            labelClass: 'workspace-context-menu-item',
            iconClass: 'icon-hint-info i16',
            divider: false,
            title: false,
            execute: ($event: { item: MapWorkspace }) => this.showWorkspaceDetail($event.item),
            visible: () => true
        },
        {
            name: this.translate.instant('TC.Common.Duplicate'),
            id: 'duplicate-workspace',
            labelClass: 'workspace-context-menu-item',
            iconClass: 'icon_duplicate_template i16',
            divider: false,
            title: false,
            execute: ($event: { item: MapWorkspace }) => this.duplicateWorkspace($event.item),
            visible: () => this.currentUserIsAdmin
        },
        {
            name: this.translate.instant('MapViewer.Workspace.ContextMenu.Archive'),
            id: 'archive-workspace',
            labelClass: 'workspace-context-menu-item',
            iconClass: 'icon_solid_archive i16',
            divider: false,
            title: false,
            execute: ($event: { item: MapWorkspace }) => this.archiveWorkspace($event.item),
            visible: ($event: MapWorkspace) => this.currentUserIsAdmin && $event.status === MapWorkspaceStatus.ACTIVE
        },
        {
            name: this.translate.instant('MapViewer.Workspace.ContextMenu.Unarchive'),
            id: 'unarchive-workspace',
            labelClass: 'workspace-context-menu-item',
            iconClass: 'icon_solid_unarchive i16',
            divider: false,
            title: false,
            execute: ($event: { item: MapWorkspace }) => this.unarchiveWorkspace($event.item),
            visible: ($event: MapWorkspace) => this.currentUserIsAdmin && $event.status === MapWorkspaceStatus.ARCHIVED
        },
        {
            name: this.translate.instant('TCW_Remove_Association'),
            id: 'remove-workspace',
            labelClass: 'workspace-context-menu-item',
            iconClass: 'icon_solid_trash i16',
            divider: false,
            title: false,
            execute: ($event: { item: MapWorkspace }) => this.deleteWorkspace($event.item),
            visible: () => true
        },
        {
            name: this.translate.instant('TC.Common.Export'),
            id: 'export-workspace',
            labelClass: 'workspace-context-menu-item',
            iconClass: 'icon_line_cloud_down i16',
            divider: false,
            title: false,
            execute: ($event: { item: MapWorkspace }) => this.showExportPanel($event.item),
            visible: () => true
        },
        {
            name: this.translate.instant('TCS.Workspace.Share'),
            id: 'share-workspace',
            labelClass: 'workspace-context-menu-item',
            iconClass: 'icon_line_download i16',
            divider: false,
            title: false,
            execute: ($event: { item: MapWorkspace }) => this.shareWorkspace($event.item),
            visible: () => this.currentUserIsAdmin
        }
    ];

    private readonly destroyed = new Subject<void>();

    public currentWorkspace: MapWorkspace = null;
    public workspaces: MapWorkspace[] = [];
    public workspaceFilter: string = null;
    public filteredWorkspaces: MapWorkspace[] = [];
    public archivedWorkspaces: MapWorkspace[] = [];
    public creating = false;
    public loading = true;
    public scrollToTop = false;
    public currentUserIsAdmin = false;

    private toastrWorkspaceLoading: Promise<number>;

    constructor(
        private projectStream: ProjectStreamService,
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private mainMenuStream: MainMenuStreamService,
        private messaging: MessagingService,
        private router: Router,
        private loaderStreamService: LoaderStreamService,
        private translate: TranslationService,
        private mapWorkspaceStreams: MapWorkspaceStreamsService,
        private currentUserStream: CurrentUserStreamService
    ) {}

    async ngOnInit(): Promise<void> {
        const user = await this.projectStream.getUserWithRolesForCurrentProject(this.currentUserStream.currentUser.id);
        this.currentUserIsAdmin = user.role === 'ADMIN';
        this.initSubscriptions();
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    private initSubscriptions(): void {
        this.mapWorkspaceStreams.currentMapWorkspaceStream.pipe(takeUntil(this.destroyed)).subscribe(workspace => {
            this.currentWorkspace = workspace;
        });

        this.mapWorkspaceStreams.projectMapWorkspacesStream.pipe(takeUntil(this.destroyed)).subscribe(workspaces => {
            if (workspaces) {
                this.workspaces = workspaces.filter(workspace => this.isNotViewer(workspace));
                this.filterWorkspaces();

                if (this.toastrWorkspaceLoading) {
                    this.toastrWorkspaceLoading.then(toast => {
                        this.messaging.clear(toast);
                    });
                }
            }
            this.loading = false;
        });
    }

    public isNotViewer(workspace: MapWorkspace): boolean {
        return !workspace.isFileViewer;
    }

    public filterWorkspaces(): void {
        if (!this.workspaceFilter || this.workspaceFilter === '') {
            this.filteredWorkspaces = this.workspaces;
        } else {
            let filter = this.workspaceFilter.toLowerCase();
            this.filteredWorkspaces = this.workspaces.filter(workspace =>
                workspace.name.toLowerCase().includes(filter)
            );
        }
        this.archivedWorkspaces = this.filteredWorkspaces.filter(
            workspace => workspace.status === MapWorkspaceStatus.ARCHIVED
        );
        this.filteredWorkspaces = this.filteredWorkspaces.filter(
            workspace => workspace.status === MapWorkspaceStatus.ACTIVE
        );
    }

    triggerScrollToTop(inputValue: string): void {
        this.scrollToTop = inputValue.length ? true : false;
    }

    public defaultCreateMethod = () => this.createMapWorkspace();
    public createFromFileMethod = () => this.createMapWorkspace(true);

    public async createMapWorkspace(fromFile: boolean = false): Promise<void> {
        if (this.creating === false) {
            const workspace = await this.mapWorkspacesStore.getNextNewMapWorkspace(
                this.projectStream.getCurrentProject().id,
                null,
                null,
                false
            );
            this.creating = false;
            this.showWorkspaceDetail(workspace, true, fromFile);
        } else {
            return Promise.resolve();
        }
    }

    public changeWorkspace(workspace: MapWorkspace): void {
        this.currentWorkspace = workspace;
        if (
            !this.mapWorkspacesStore.getCurrentMapWorkspace() ||
            workspace.id !== this.mapWorkspacesStore.getCurrentMapWorkspace().id
        ) {
            this.loaderStreamService.isLoading$.next(true);
            this.router.navigate(['mapViewer', { outlets: { mainMenu: null } }], {
                queryParams: {
                    projectId: workspace.projectId,
                    workspaceId: workspace.id
                },
                queryParamsHandling: 'merge'
            });
        } else {
            this.mainMenuStream.showMainMenu$.next(false);
        }
        this.messaging.clear();
    }

    public showWorkspaceDetail(
        workspace: MapWorkspace,
        newWorkspace: boolean = false,
        fromFile: boolean = false
    ): void {
        if (!fromFile) {
            this.mapWorkspacesStore.editMapWorkspaceStream.next(workspace); // TODO: - use @Input - not stream
            const isEditWorkspace = !newWorkspace
                ? this.openMapViewerPopupTo('workspace/details')
                : this.openMapViewerPopupTo('workspace/new');
        } else {
            this.openMapViewerPopupTo('workspace/new/fromFile');
        }
    }

    public showExportPanel(workspace: MapWorkspace): void {
        // Note: 'projectId/mapWorkspaceId' identify the overiding workspace...
        // whereas 'projectId/workspaceId' is the current workspace
        this.openMapViewerPopupTo('export', { mapWorkspaceId: workspace.id }, 'merge');
    }

    public deleteWorkspace(workspace: MapWorkspace): void {
        this.mapWorkspacesStore.deleteMapWorkspaceStream.next(workspace); // TODO: use @Input - not stream
        this.openMapViewerPopupTo('workspace/delete');
    }

    public duplicateWorkspace(workspace: MapWorkspace): void {
        this.mapWorkspacesStore.duplicateMapWorkspaceStream.next(workspace);
        this.openMapViewerPopupTo('workspace/duplicate', { workspaceId: workspace.id });
    }

    public archiveWorkspace(workspace: MapWorkspace): void {
        this.mapWorkspacesStore.archiveEditMapWorkspaceStream.next(workspace);
        this.openMapViewerPopupTo('workspace/archive', { workspaceId: workspace.id });
    }

    public unarchiveWorkspace(workspace: MapWorkspace): void {
        this.mapWorkspacesStore.archiveEditMapWorkspaceStream.next(workspace);
        this.openMapViewerPopupTo('workspace/unarchive', { workspaceId: workspace.id });
    }

    public shareWorkspace(workspace: MapWorkspace): void {
        this.mapWorkspacesStore.shareMapWorkspaceStream.next(workspace);
        this.openMapViewerPopupTo('workspace/share');
    }

    private openMapViewerPopupTo(
        centerDialog: string = '',
        queryParams: {} = {},
        queryParamsHandling: QueryParamsHandling = 'preserve',
        skipLocationChange: boolean = true
    ): void {
        this.router.navigate(['mapViewer', { outlets: { centerDialog } }], {
            queryParams,
            queryParamsHandling,
            skipLocationChange
        });
    }
}
