import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface RegionConfig {
    locationId: string;
    mapsAPIUri: string;
    featuresAPIUri: string;
    connectAPIUri: string;
    connectAPIMasterUri: string;
    connectWebUrl: string;
    coordinatesAPIUri: string;
    tilesUri: string;
    connectDomain: string;
    mapsDomain: string;
    processingOrchestratorUri: string;
}

@Injectable({
    providedIn: 'root'
})
export class EnvironmentService {
    public serviceReadyStream = new BehaviorSubject<boolean>(false);

    public region = 'northAmerica';
    private _regions: { [key: string]: RegionConfig } = {
        northAmerica: {
            locationId: '',
            mapsAPIUri: '',
            featuresAPIUri: '',
            connectAPIUri: '',
            connectAPIMasterUri: '',
            connectWebUrl: '',
            coordinatesAPIUri: '',
            tilesUri: '',
            connectDomain: '',
            mapsDomain: '',
            processingOrchestratorUri: ''
        }
    };

    public get regions() {
        return this._regions;
    }

    public set regions(val: { [key: string]: RegionConfig }) {
        this._regions = val;
        this.serviceReadyStream.next(true);
    }

    public get connectAPIMasterUri(): string {
        return this.regions[this.region].connectAPIMasterUri.replace(/\/$/g, '');
    }

    public get connectApiUrl(): string {
        return this.regions[this.region].connectAPIUri.replace(/\/$/g, '');
    }

    public get apiUrl(): string {
        return this.regions[this.region].mapsAPIUri.replace(/\/$/g, '');
    }

    public get podLocation(): string {
        return this.regions[this.region].locationId.replace(/\/$/g, '');
    }

    public get featureApiUrl(): string {
        return this.regions[this.region].featuresAPIUri.replace(/\/$/g, '');
    }

    public get coordinatesApiUrl(): string {
        return this.regions[this.region].coordinatesAPIUri.replace(/\/$/g, '');
    }

    public get tilesApiUrl(): string {
        return this.regions[this.region].tilesUri.replace(/\/$/g, '');
    }

    public get connectDomain(): string {
        return this.regions[this.region].connectDomain.replace(/\/$/g, '');
    }

    public get mapsDomain(): string {
        return this.regions[this.region].mapsDomain.replace(/\/$/g, '');
    }

    public get processingOrchestratorUrl(): string {
        return this.regions[this.region].processingOrchestratorUri.replace(/\/$/g, '');
    }

    public get connectWebUrl(): string {
        return this.regions[this.region].connectWebUrl.replace(/\/$/g, '');
    }

    public getConnectDomainFromUrl(url: string): string {
        const hostName = new URL(url).hostname;
        const appName = hostName.split('.')[0];
        const appNameIndex = hostName.indexOf(appName);
        return hostName.substr(appNameIndex + appName.length + 1);
    }

    public getMapsDomainFromUrl(url: string): string {
        const mapsHostName = new URL(url).hostname;
        return mapsHostName.substr(mapsHostName.indexOf('maps'));
    }
}
