export class NumberUtils {
    static toPrecisedNumber(value: any, hasDecimal?: boolean): string {
        const isInvalidValue = /[^0-9,.-]|\.{2,}|^[^a-zA-Z0-9]+$/.test(value) || !value;

        if (isInvalidValue) {
            return null;
        }

        if (/^0\d+/.test(value)) {
            // clean up starting 0 decimal (e.g. 0023123123, 001231.555) and allow float start with 0. (0.123123, 0.5)
            value = value.toString().replace(/^0+(?=\d)/, '');
        }

        value = value.toString().replace(/,/g, '');
        value = hasDecimal && String(value).includes('.') ? String(value) : String(value.split('.')[0]);

        return String(value);
    }

    static isInt(n: any) {
        return Number(n) === n && n % 1 === 0;
    }

    static isFloat(n: any) {
        return Number(n) === n && n % 1 !== 0;
    }
}
