<gsp-wizard
    [progressionSteps]="steps"
    [currentStep]="currentStep$ | async"
    [size]="ModalSize.Medium"
    [showProgress]="false"
    id="workspace-share-wizard"
>
    <gsp-wizard-header [heading]="'TCS.Workspace.Share'" transclude-wizard-header></gsp-wizard-header>

    <gsp-wizard-footer
        (goBack)="backward()"
        (goForward)="forward()"
        (cancel)="cancel()"
        transclude-wizard-footer
    ></gsp-wizard-footer>
</gsp-wizard>
