import { Component, EventEmitter, Output } from '@angular/core';

import { ButtonType } from '../buttons/button';
import { GspWizardService } from './gsp-wizard.service';

@Component({
    selector: 'gsp-wizard-footer',
    templateUrl: './gsp-wizard-footer.component.html'
})
export class GspWizardFooterComponent {
    @Output() goBack = new EventEmitter<void>();
    @Output() goForward = new EventEmitter<void>();
    @Output() cancel = new EventEmitter<void>();

    public state$ = this.wizardService.uiState$;
    public isBusy$ = this.wizardService.currentStepIsBusy$;

    // expose enum to template
    public ButtonType = ButtonType;

    constructor(private wizardService: GspWizardService) {}

    public onBackward(): void {
        this.goBack.emit();
    }

    public onForward(): void {
        this.goForward.emit();
    }

    public onCancel(): void {
        this.cancel.emit();
    }
}
