<div class="modal-popup" id="workspace-error">
    <div class="modal-content workspace-edit">
        <header class="modal-header">
            <h2 class="modal-title"><span translate="upgradeAccount"></span></h2>
            <gsp-button
                (action)="closeErrorPanel()"
                [type]="ButtonType.Icon"
                iconClass="icon-ModelClose"
                id="workspace-error-close"
            ></gsp-button>
        </header>

        <div class="modal-body">
            <p translate="INSUFFICIENT_STORAGE"></p>
        </div>

        <div class="modal-footer">
            <gsp-button
                [type]="ButtonType.Done"
                [disabled]="loading"
                (action)="upgradeTC()"
                text="{{ 'UPGRADE' | translate }}"
                id="upgrade-tc-license"
            ></gsp-button>
            <gsp-button
                [type]="ButtonType.Default"
                (action)="closeErrorPanel()"
                text="{{ 'cancel' | translate }}"
                id="workspace-error-cancel"
            ></gsp-button>
        </div>
    </div>
</div>
