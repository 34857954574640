import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface AccessPointDTO {
    name: string;
    accessPointId: number;
    stationCode: string;
    capabilities: string;
    distance: number;
    fileSpanHours: number;
    obsUrlTemplateStyle: number;
    obsUrlTemplate: string;
    navUrlTemplateStyle: number;
    navUrlTemplate: string;
    referencePosition: {
        latitude: number;
        longitude: number;
        height: number;
        referenceFrame: string;
        epoch: string;
        datumComponentId: string;
        velocityEast: number;
        velocityNorth: number;
        velocityUp: number;
        isAPC: boolean;
    };
    receiverType: string;
    integrityIndex: number;
    uploadDelay: number;
    trustProviderReferencePosition: boolean;
    integrityDetails: [
        {
            name: string;
            accessPointID: number;
            distance: number;
            capabilitiesString: string;
            observationRate: number;
            capabilitiesPenalty: string;
            observationPenalty: string;
            availabilityPenalty: string;
            qualityPenalty: string;
            availabilityMeasure: number;
            qualityMeasure: number;
            integrityIndex: number;
        }
    ];
}

@Injectable({ providedIn: 'root' })
export class CBSListService {
    private url = environment.cbsListUrl;

    constructor(private http: HttpClient) {}

    getAccessPoints(lat: number, long: number, referenceFrame?: string): Promise<AccessPointDTO[]> {
        let urlWithParams =
            `${this.url}/api/accesspoints?` +
            `latitude=${lat}&` +
            `longitude=${long}&` +
            `numberRequired=1&` +
            `roverFileCapabilities=G R E J C L1 L2`;

        if (referenceFrame) {
            urlWithParams = `${urlWithParams}&referenceFrame=${referenceFrame}`;
        }

        return lastValueFrom(this.http.get<AccessPointDTO[]>(urlWithParams));
    }
}
