import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { InputConverter, IntConverter } from 'src/app/shared/common/components/input-converter.decorator';

@Directive({
    selector: '[decimalPlaces]',
    providers: [{ provide: NG_VALIDATORS, useExisting: DecimalPlacesValidatorDirective, multi: true }]
})
export class DecimalPlacesValidatorDirective implements Validator, OnChanges {
    @Input()
    @InputConverter(IntConverter)
    decimalPlaces: number;

    private _onValidatorChange = () => {};

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.decimalPlaces) {
            this._onValidatorChange();
        }
    }

    registerOnValidatorChange?(fn: () => void): void {
        this._onValidatorChange = fn;
    }

    validate(control: AbstractControl): ValidationErrors {
        if (
            this.decimalPlaces !== null &&
            this.decimalPlaces !== undefined &&
            control.value &&
            this.decimalPlacesInValue(control.value) > this.decimalPlaces
        ) {
            return { decimal: { places: this.decimalPlaces } };
        }

        return null;
    }

    decimalPlacesInValue(num: number): number {
        let match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
        if (!match) {
            return 0;
        }
        return Math.max(
            0,
            // Number of digits right of decimal point.
            (match[1] ? match[1].length : 0) -
                // Adjust for scientific notation.
                (match[2] ? +match[2] : 0)
        );
    }
}
