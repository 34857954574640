<div *ngIf="!template" class="app-loading"></div>
<div id="page-template-editor" [ngClass]="{ rule: (currentTab$ | async)?.id === TemplateEditorTabId.RULE }">
    <tabs-list
        [application]="application"
        [template]="template"
        *ngIf="template"
        [currentProjectId]="currentProjectId"
        [linkDetails]="linkDetails"
        [templateEditorConfig]="templateEditorConfig"
    ></tabs-list>
    <div class="editorPanel">
        <template-composer
            [application]="application"
            [templateEditorConfig]="templateEditorConfig"
            [currentProjectDetails]="currentProjectDetails"
            (showSuccess)="showSuccess.emit()"
            (showError)="showError.emit($event)"
            [currentProjectId]="currentProjectId"
            [currentWorkspaceId]="currentWorkspaceId"
            [template]="template"
            *ngIf="template"
            (afterLoaded)="afterLoaded.emit()"
            [linkDetails]="linkDetails"
            [templateId]="templateId"
            (showCancelConfirmation)="onShowCancelConfirmation()"
            [enableButtons]="enableButtons"
            (saveForm)="
                saveFormDummy($event.action, $event.template, $event.publishedStatus, $event.templateBeforeSave)
            "
        ></template-composer>
    </div>
</div>
<div class="modal-popup" *ngIf="showCancelConfirmation">
    <gsp-confirmation-popup
        [heading]="'TC.MetadataEditor.DiscardChangesWithQuestion'"
        [message]="'MapViewer.TemplateWizard.DiscardConfirmation.Description'"
        [confirmAction]="onPopupConfirm"
        (cancel)="onPopupCancel()"
        [confirmationButtonText]="'TC.Common.Discard'"
        id="cancel-template-wizard-popup"
    ></gsp-confirmation-popup>
</div>
