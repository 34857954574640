import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ForbiddenFormsStreamService } from 'src/app/shared/common/current-forbidden-forms/forbidden-forms-stream.service';
import {
    ForbiddenFormsDTO,
    ForbiddenFormsReason,
    ForbiddenFormsState,
    ForbiddenFormsSummary,
} from 'src/app/shared/types/forbidden-forms';

import { GspNotificationTabUtil } from './gsp-notification-tab.util';

export interface ForbiddenFormsNotification {
    userId: string;
    workspaceId: string;
    reason: ForbiddenFormsReason;
    state: ForbiddenFormsState;
    summary: ForbiddenFormsSummary;
    formattedTimeStamp: string;
}

@Component({
    selector: 'gsp-notification-tab',
    templateUrl: './gsp-notification-tab.component.html'
})
export class GspNotificationTabComponent implements OnInit, OnDestroy {
    @Input()
    showNotificationTab: boolean;

    @Output()
    hideNotificationTab = new EventEmitter();

    @Output()
    notificationUpdate = new EventEmitter();

    public forbiddenFormsNotifications: ForbiddenFormsNotification[] = [];

    public selectedNotification: ForbiddenFormsNotification;
    public showForbiddenFormModal = false;

    private destroyed = new Subject();

    constructor(private forbiddenFormsStream: ForbiddenFormsStreamService) {}

    ngOnInit(): void {
        this.forbiddenFormsStream.forbiddenFormsStream.pipe(takeUntil(this.destroyed)).subscribe(forbiddenFormsDTO => {
            if (forbiddenFormsDTO) {
                this.updateNotifications(forbiddenFormsDTO);
            }
        });
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    updateNotifications(forbiddenFormsDTO: ForbiddenFormsDTO): void {
        this.forbiddenFormsNotifications = GspNotificationTabUtil.getFormNotificationsFromDTO(forbiddenFormsDTO);
        if (this.forbiddenFormsNotifications.length) {
            this.notificationUpdate.emit();
        }
    }

    handleNotificationClick(notification: ForbiddenFormsNotification): void {
        this.selectedNotification = notification;
        this.showForbiddenFormModal = true;
        this.hideNotificationTab.emit();
    }

    hideForbiddenFormModal() {
        this.showForbiddenFormModal = false;
    }
}
