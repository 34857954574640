import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as L from 'leaflet';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/core/translation/translation.service';
import {
    MapDrawActionResult,
    MapDrawActionSource,
    MapDrawActionsStreamService,
} from 'src/app/feature/map-viewer/map-container/map-draw-actions/map-draw-action-stream.service';
import { SidePanelStreamsService } from 'src/app/feature/map-viewer/side-panel/side-panel-streams.service';
import { ContextMenuActions } from 'src/app/shared/common/components/context-menu/gsp-context-menu.component';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { BaseMapModeMapCacheStyleMap, MapCacheStyle } from 'src/app/shared/common/mapCache/map-cache';
import { MapCacheStreamService } from 'src/app/shared/common/mapCache/map-cache-stream.service';
import { TemplateTypeId } from 'src/app/shared/map-data-services/layer/template-lite';
import { MapWorkspacePermissionType } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace-permission';

import { MapService } from '../../../map.service';
import { SidePanelName } from '../../../side-panel/side-panel.component';
import { SelectionMode } from '../../../toolbar/mapToolbar/selectionTools/selection-tool.component';

@Component({
    templateUrl: './add-menu.component.html',
    selector: 'add-menu'
})
export class AddMenuComponent implements OnDestroy {
    @Input()
    showAddDataTip = false;

    private selectedMapCacheStyle: MapCacheStyle;

    private readonly destroyed = new Subject<void>();

    public contextMenuItems: ContextMenuActions[] = [
        {
            name: this.translate.instant('TC.Common.Add'),
            execute: () => null,
            title: true,
            visible: () => true
        },
        {
            name: this.translate.instant('TC.Common.NewTemplate'),
            id: 'add-new-template',
            labelClass: 'add-layer-context-menu-item',
            execute: () => this.goToTemplateEditor(),
            title: false,
            visible: () => true
        },
        {
            name: this.translate.instant('TC.Common.ExistingTemplate'),
            id: 'add-existing-template',
            labelClass: 'add-layer-context-menu-item',
            execute: () => this.showPanel(SidePanelName.EXISTING_TEMPLATE),
            title: false,
            visible: () => true
        },
        {
            name: null,
            execute: () => null,
            divider: true,
            title: false,
            visible: () => true
        },
        {
            name: this.translate.instant('TCS.MapViewer.AddMenu.AddData'),
            execute: () => null,
            title: true,
            visible: () => true
        },
        {
            name: this.translate.instant('TC.Common.ImportFromFile'),
            id: 'import-from-file',
            labelClass: 'add-layer-context-menu-item',
            execute: () => this.goToImport(),
            title: false,
            visible: () => true
        },
        {
            name: this.translate.instant('TCS.Mapviewer.LinkToExistingLayer'),
            id: 'link-existing-layer',
            labelClass: 'add-layer-context-menu-item',
            execute: () => this.showPanel(SidePanelName.EXISTING_LAYER),
            title: false,
            visible: () => true
        },
        {
            name: null,
            execute: () => null,
            divider: true,
            title: false,
            visible: () => true
        },
        {
            name: this.translate.instant('TCS.Mapviewer.AddMenu.CreateOfflineBasemap'),
            id: 'create-offline-basemap',
            labelClass: 'add-layer-context-menu-item',
            execute: () => this.drawMapCache(),
            title: false,
            visible: () => true
        }
    ];

    // exposing enum to template
    public MapWorkspacePermissionType = MapWorkspacePermissionType;

    constructor(
        private sidePanelStreams: SidePanelStreamsService,
        private mapCacheStream: MapCacheStreamService,
        private mapActionsStream: MapDrawActionsStreamService,
        private mapService: MapService,
        private projectStream: ProjectStreamService,
        private router: Router,
        private translate: TranslationService
    ) {
        mapActionsStream.mapDrawActionResultStream
            .pipe(
                takeUntil(this.destroyed),
                filter(
                    mapDrawActionResult =>
                        mapDrawActionResult.source === MapDrawActionSource.MAP_CACHE_CREATOR &&
                        mapDrawActionResult.result !== null
                )
            )
            .subscribe((mapDrawActionResult: MapDrawActionResult) => {
                this.createMapCache(mapDrawActionResult.result as L.LatLngBounds);
            });

        mapService.basemapChangedStream.pipe(takeUntil(this.destroyed)).subscribe(() => {
            const selectedBaseMapMode = mapService.selectedBaseMapMode;
            if (BaseMapModeMapCacheStyleMap[selectedBaseMapMode.id]) {
                this.selectedMapCacheStyle = BaseMapModeMapCacheStyleMap[selectedBaseMapMode.id];
            }
        });
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    showPanel(name: SidePanelName): void {
        switch (name) {
            case SidePanelName.EXISTING_LAYER:
                this.sidePanelStreams.closeSidePanel(SidePanelName.EXISTING_TEMPLATE);
                break;
            case SidePanelName.EXISTING_TEMPLATE:
                this.sidePanelStreams.closeSidePanel(SidePanelName.EXISTING_LAYER);
                break;
        }
        this.sidePanelStreams.openSidePanel(name);
    }

    // ----------------------------------
    // OFFLINE BASEMAP (MAP CACHE) CREATION

    private drawMapCache(): void {
        this.mapActionsStream.startMapDrawActionStream.next({
            source: MapDrawActionSource.MAP_CACHE_CREATOR,
            mode: SelectionMode.RECTANGLE,
            drawingOptions: {
                color: '#282828'
            },
            drawingCursor: ''
        });
    }

    private createMapCache(mapCacheBounds: L.LatLngBounds): void {
        this.mapCacheStream.createMapCache(
            this.projectStream.getCurrentProject().id,
            mapCacheBounds,
            this.selectedMapCacheStyle
        );
    }

    // ----------------------------------

    public goToImport(): void {
        this.router.navigate(['mapViewer', { outlets: { centerDialog: 'import' } }], {
            queryParamsHandling: 'preserve'
        });
    }

    public goToTemplateEditor(): void {
        this.router.navigate(['template/editor'], {
            queryParams: { templateId: TemplateTypeId.NEW_FORM, layerId: null },
            queryParamsHandling: 'merge'
        });
    }
}
