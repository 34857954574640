<gsp-popup
    [heading]="'TCS.Workspace.Detail' | translate"
    [size]="ModalSize.Wide"
    [showClose]="true"
    (closed)="closeEditPanel()"
    [showDone]="true"
    (done)="saveWorkspace()"
    [doneBtnTitle]="'save' | translate"
    [showCancel]="true"
    (cancel)="closeEditPanel()"
    [disableDone]="!isFormValid || saveInProgress || noCustomImageAvailable()"
    id="workspace-details-popup"
>
    <ng-container transclude-popup-content>
        <div class="flex-row-new workspace-edit-panel">
            <custom-scroll class="height-full">
                <div class="flex4-new">
                    <h4 class="sub-heading font-title">{{ 'Common.Details' | translate }}</h4>
                    <workspace-name
                        [modifyWorkspace]="modifyWorkspace"
                        (isNameValid)="onValidationChange($event)"
                    ></workspace-name>
                </div>
            </custom-scroll>

            <div class="flex4-new pr-0">
                <workspace-coordinate-system-details
                    [workspace]="modifyWorkspace"
                ></workspace-coordinate-system-details>
            </div>

            <div class="flex4-new pr-0">
                <h4 class="sub-heading font-title">
                    {{ getWorkspaceAreaOrImageLabel(modifyWorkspace) }}
                </h4>
                <div class="thumbnail" [hidden]="editingBounds">
                    <load-img
                        *ngIf="loadImgComponentLoaded"
                        fallbackImageUrl="assets/images/workspace-thumbnail.jpg"
                        [imageUrl]="croppedImageOrWorkspaceArea()"
                        [showOnCondition]="modifyWorkspace.hasThumbnail"
                        [authenticate]="true"
                        id="workspace-thumbnail"
                    ></load-img>
                    <div class="button-switches">
                        <gsp-toggle
                            class="toggle-workspace-image"
                            [ngClass]="{ disabled: canEditBounds }"
                            [(on)]="modifyWorkspace.workspaceModel.isCustomThumbnailImage"
                            [label]="'MapViewer.Workspace.CustomImage.Label' | translate"
                            [disabled]="canEditBounds"
                            (onChange)="handleToggleWorkspaceImage($event)"
                        ></gsp-toggle>
                        <gsp-button
                            *ngIf="modifyWorkspace.workspaceModel.isCustomThumbnailImage"
                            [type]="ButtonType.Primary"
                            (action)="openImageCropper()"
                            text="{{
                                croppedImage || !noCustomImageAvailable()
                                    ? ('MapViewer.Workspace.CustomImage.ChangeButton' | translate)
                                    : ('MapViewer.Workspace.CustomImage.UploadButton' | translate)
                            }}"
                            [id]="'open-image-cropper'"
                        ></gsp-button>
                        <gsp-button
                            *ngIf="canEditBounds"
                            [type]="ButtonType.Primary"
                            (action)="toggleMap()"
                            text="{{ 'MapViewer.Generic.SetWorkspaceLocation' | translate }}"
                            [id]="'show-workspace-area'"
                        ></gsp-button>
                    </div>
                </div>

                <div class="workspace-mini-map-container" *ngIf="editingBounds">
                    <workspace-location
                        class="workspace-mini-map"
                        [(map)]="map"
                        (initialized)="onMapInitialized()"
                    ></workspace-location>
                    <div class="button-row">
                        <gsp-button
                            [type]="ButtonType.Primary"
                            (action)="updateBounds()"
                            text="{{ 'TC.Common.Apply' | translate }}"
                            [id]="'set-workspace-bounds'"
                        ></gsp-button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</gsp-popup>
