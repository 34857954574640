<div
    class="modal-popup"
    [ngClass]="{
        'extra-small-modal': size === ModalSize.ExtraSmall,
        'small-modal': size === ModalSize.Small,
        'medium-modal': size === ModalSize.Medium,
        'large-modal': size === ModalSize.Large,
        'xlarge-modal': size === ModalSize.Xlarge,
        'xxlarge-modal': size === ModalSize.XXlarge,
        'wide-modal': size === ModalSize.Wide,
        'custom-modal': size === ModalSize.Custom,
        'fluid-modal': size === ModalSize.Fluid,
        'fluid-large-modal': size === ModalSize.FluidLarge
    }"
>
    <div class="modal-content">
        <div class="modal-header" [class]="{ 'with-bottom-border': !!customIcon }">
            <h2 class="modal-title" *ngIf="heading && !customIcon">
                <span>{{ heading | translate }}</span>
            </h2>
            <h3 class="modal-title with-custom-icon" *ngIf="heading && !!customIcon">
                <div class="field-icon">
                    <i class="{{ customIcon }} i32"></i>
                </div>
                <div class="field-name">
                    <span>{{ heading | translate }}</span>
                </div>
            </h3>
            <gsp-button
                *ngIf="showClose"
                (action)="callExit()"
                [type]="ButtonType.Icon"
                iconClass="icon-ModelClose"
                id="{{ id + '-close' }}"
            ></gsp-button>
        </div>
        <div class="modal-body">
            <ng-content select="[transclude-popup-content]"></ng-content>
        </div>
        <div class="modal-footer">
            <ng-content select="[transclude-popup-footer]"></ng-content>
            <gsp-button
                [hidden]="!showDone"
                (action)="callDone()"
                [type]="ButtonType.Primary"
                [disabled]="disableDone"
                [text]="doneBtnTitle | translate"
                id="{{ id + '-done' }}"
            ></gsp-button>
            <gsp-button
                [hidden]="!showCancel"
                (action)="callExit()"
                [type]="ButtonType.Default"
                [text]="'TC.Common.Cancel' | translate"
                id="{{ id + '-cancel' }}"
            ></gsp-button>
        </div>
    </div>
</div>
