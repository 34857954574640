import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Feature } from '../../map-data-services/feature/feature';

export enum GeoJsonFeatureType {
    MAP_CACHE = 'mapCache',
    TEMPLATE = 'Template',
    LAYER = 'Layer'
}

export class GeoJsonLayer {
    constructor(
        public type: GeoJsonFeatureType,
        public groupId: string,
        public geoJson: any,
        public focusOnMap: boolean
    ) {}
}

@Injectable({
    providedIn: 'root'
})
export class GeoJsonFeaturesStreamService {
    geoJsonFeaturesStream = new BehaviorSubject<GeoJsonLayer>(null);

    // ---------------
    // It is specially for mapcache, that will get geoJson from map cache and will be subscribe in map container
    // Type will be 'mapCache' or 'Template' or 'Layer'
    // GroupId will be mapCache Id, template id , layer id
    // List of features for the mapCache or template or layer

    displayGeoJsonFeatures(
        type: GeoJsonFeatureType,
        groupId: string,
        geoJsonFeatures: Partial<Feature>,
        focusOnMap = true
    ): void {
        this.geoJsonFeaturesStream.next(new GeoJsonLayer(type, groupId, geoJsonFeatures, focusOnMap));
    }
}
