export interface FeatureFlag {
    name: FeatureFlagName;
    enabled: boolean;
    createdBy: string;
    updatedBy: string;
    createdUtc: string;
    updatedUtc: string;
}

export enum FeatureFlagName {
    SENSORS = 'SENSORS',
    DIGITIZE = 'DIGITIZE',
    LOGGING_BEHAVIOR = 'LOGGING_BEHAVIOR',
    HECTARE = 'HECTARE'
}
