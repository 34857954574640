<custom-scroll [triggerScrollToTop]="scrollToTop" class="workspaces">    
    <div class="workspaces-wrapper">
        <div class="no-hover" *ngIf="loading">
            <loading class="loading-align-center"></loading>
        </div>
        <ul class="workspace-list" *ngIf="!loading">
            <li class="workspace-list-message" *ngIf="workspaces?.length === 0">
                {{ noWorkspacesMessage }}
            </li>
            <li
                class="workspace-list-item"
                [ngClass]="{ active: workspace.id == currentWorkspace.id }"
                *ngFor="let workspace of workspaces"
            >
                <div (click)="changeWorkspace.emit(workspace)">
                    <div class="workspace-select-wrapper">
                        <span class="workspace-select">
                            <i class="icon_line_white_tick i32 selected-tick"></i>
                        </span>
                        <span
                            class="workspace-archived-badge badge badge--primary ml-1"
                            *ngIf="workspace.status === MapWorkspaceStatus.ARCHIVED"
                        >
                            {{ 'MapViewer.AppTopBar.WorkspaceStatus.Archived' | translate }}
                        </span>
                    </div>
                    <div class="workspace-thumbnail">
                        <load-img
                            fallbackImageUrl="assets/images/workspace-thumbnail.jpg"
                            [imageUrl]="getThumbnailUrl(workspace)"
                            [showOnCondition]="workspace.hasThumbnail"
                            [authenticate]="true"
                        ></load-img>
                    </div>
                </div>

                <div class="no-gutters flex-row-new">
                    <div class="tile-details flex10-new" (click)="changeWorkspace.emit(workspace)">
                        <h2 class="workspace-list-title" title="{{ workspace.name }}">
                            {{ workspace.name }}
                        </h2>
                        <p class="workspace-list-desc" title="{{ workspace.description }}">
                            {{ workspace.description }}
                        </p>
                    </div>
                    <div class="flex2-new pl-0 pr-0">
                        <gsp-button
                            [type]="ButtonType.Icon"
                            (action)="openContextMenu($event, workspace)"
                            iconClass="i32 icon_line_menu_dots"
                            prevent-tab-event
                            id="workspace-context-menu"
                        ></gsp-button>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <gsp-context-menu #workspaceContextMenu [items]="contextMenuItems"> </gsp-context-menu>
</custom-scroll>
