import { Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from 'src/app/shared/common/utility/string-utils';

@Pipe({
    name: 'decodeURI'
})
export class DecodeURIPipe implements PipeTransform {
    transform(input: any): any {
        if (!StringUtils.isString(input)) {
            return input;
        }

        return decodeURI(input);
    }
}
