import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Subject } from 'rxjs';
import { UtilitiesService } from 'src/app/shared/common/utility/utilities.service';

import { EnvironmentService } from '../../common/environment/environment.service';
import { FeatureFlagName } from '../../common/feature-flag/feature-flag';
import { FeatureFlagStreamService } from '../../common/feature-flag/feature-flag-stream.service';
import { AutoFieldModelType, FieldType, LayoutFieldType } from '../field';
import { FieldDefinition, GeoFields, SensorData } from './fieldDefinition';

@Injectable({
    providedIn: 'root'
})
export class FieldService {
    translateToTCString: { [key: string]: string } = {
        'default_label.fields.angle': 'TC.Common.Angle',
        'default_label.fields.code': 'TC.Common.NewCode',
        'default_label.fields.codedchoice': 'TC.Common.CodedChoice',
        'default_label.fields.choice': 'TC.Common.Choice',
        'default_label.fields.collectedby': 'TC.Common.Collector',
        'default_label.fields.correction_source': 'TCS.DefaultLabel.Fields.Correctionsource',
        'default_label.fields.correction_status': 'TCS.DefaultLabel.Fields.Correctionstatus',
        'default_label.fields.correctionsource': 'TCS.DefaultLabel.Fields.Correctionsource',
        'default_label.fields.correctionstatus': 'TCS.DefaultLabel.Fields.Correctionstatus',
        'default_label.fields.creationdatetime': 'TC.Common.CreatedOn',
        'default_label.fields.date': 'date',
        'default_label.fields.device_id': 'TC.Common.DeviceID',
        'default_label.fields.deviceid': 'TC.Common.DeviceID',
        'default_label.fields.devicetype': 'TC.Common.Device',
        'default_label.fields.estimated_accuracy': 'TCS.DefaultLabel.Fields.Estimatedaccuracy',
        'default_label.fields.estimatedaccuracy': 'TCS.DefaultLabel.Fields.Estimatedaccuracy',
        'default_label.fields.geometry_capture_type': 'TCS.DefaultLabel.Fields.Geometrycapturetype',
        'default_label.fields.geometry_length': 'TCS.DefaultLabel.Fields.Geometrylength',
        'default_label.fields.geometrycapturetype': 'TCS.DefaultLabel.Fields.Geometrycapturetype',
        'default_label.fields.geometrylength': 'TCS.DefaultLabel.Fields.Geometrylength',
        'default_label.fields.group': 'GROUP',
        'default_label.fields.hdop': 'TCS.DefaultLabel.Fields.Hdop',
        'default_label.fields.length': 'TC.Common.Length',
        'default_label.fields.number': 'TC.Common.Number',
        'default_label.fields.options': 'TC.Common.NewChoice',
        'default_label.fields.pdop': 'TCS.DefaultLabel.Fields.Pdop',
        'default_label.fields.sensor': 'TCS.Sensor',
        'default_label.fields.signature': 'TC.Common.Signature',
        'default_label.fields.text': 'TC.Common.Text',
        'default_label.fields.updatedatetime': 'TC.Common.Updated',
        'default_label.fields.yes_no': 'TC.Common.YesSlashNo',
        'default_label.fields.pageheader': 'TC.Common.PageHeader',
        'common.image': 'image',
        'templateEditor.tf.fields.date_range': 'TCS.TemplateEditor.Tf.Fields.DateRange',
        'templateEditor.tf.fields.decimal_places': 'TCS.TemplateEditor.Tf.Fields.DecimalPlaces',
        'templateEditor.tf.fields.default_date': 'TCS.TemplateEditor.Tf.Fields.DefaultDate',
        'templateEditor.tf.fields.default_text': 'TCS.TemplateEditor.Tf.Fields.DefaultText',
        'templateEditor.tf.fields.default_to_curr_date': 'TCS.TemplateEditor.Tf.Fields.DefaultToCurrentDate',
        'templateEditor.tf.fields.default_value': 'TCS.TemplateEditor.Tf.Fields.DefaultValue',
        'templateEditor.tf.fields.max_length': 'TC.Common.MaximumLength',
        'templateEditor.tf.fields.maximum': 'TC.Common.Maximum',
        'templateEditor.tf.fields.minimum': 'TC.Common.Minimum',
        'templateEditor.tf.fields.degrees': 'TC.Common.Degrees',
        'templateEditor.tf.fields.range': 'TC.Common.Range',
        'templateEditor.tf.fields.unit': 'TC.Common.Unit',
        'templateEditor.tf.fields.show_other_options': 'TCS.TemplateEditor.Fields.ShowOtherOptions',
        'templateEditor.tf.fields.multiple_choice_question': 'TCS.TemplateEditor.Fields.MultipleChoiceQuestion',
        'templateEditor.tf.assets.centimeters': 'TC.Common.Centimeters',
        'templateEditor.tf.assets.device_time': 'TC.Common.DeviceTime',
        'templateEditor.tf.assets.feet': 'TC.Common.Feet',
        'templateEditor.tf.assets.inches': 'TC.Common.Inches',
        'templateEditor.tf.assets.kilometers': 'TC.Common.Units.KmExpanded',
        'templateEditor.tf.assets.meters': 'TC.Common.Meters',
        'templateEditor.tf.assets.miles': 'TC.Common.Units.MilesExpanded',
        'templateEditor.tf.assets.utc': 'TC.Common.UTC',
        'templateEditor.tf.assets.yards': 'TC.Common.Yards',
        'templateEditor.tf.fields.todo': 'TODO',
        'templateEditor.tf.fields.Workspace': 'TCS.Workspace',
        'templateEditor.tf.fields.Barcode': 'TCS.Barcode',
        'templateEditor.tf.fields.information': 'TCS.BarcodeFallABackText',
        'templateEditor.tf.fields.EstimatedVerticalPrecision': 'TCS.DefaultLabel.Fields.EstimatedVerticalAccuracy',
        'common.none': 'TC.Common.None',
        'common.yes': 'yes',
        'common.no': 'no',
        'common.visible': 'TC.Common.Visible',
        'templateEditor.tf.fields.allow_multiple_images': 'TCS.TemplateEditor.Tf.Fields.AllowMultipleImages',
        'common.capital_from': 'TC.Common.From',
        'common.capital_to': 'TC.Common.To',
        'common.enter_date': 'TC.Common.SelectDate',
        'common.area': 'Common.Area',
        'common.increment': 'TC.Common.Increment',
        'common.decrement': 'TC.Common.Decrement',
        'templateEditor.tf.fields.StartValue': 'TCS.StartValue',
        'templateEditor.tf.fields.AutoIncrement': 'TCS.UniqueId',
        'templateEditor.tf.fields.atleastOnedigitWarning': 'TCS.AtleastOnedigitWarning',
        'templateEditor.tf.fields.textNotMatchPattern': 'TCS.TextNotMatchPattern',
        'templateEditor.tf.fields.atleastOnedigitInfo': 'TCS.AtleastOnedigitInfo'
    };

    public updateAllFieldsStream$ = new Subject();

    constructor(
        private utilities: UtilitiesService,
        private http: HttpClient,
        private env: EnvironmentService,
        private featureFlagStream: FeatureFlagStreamService
    ) {}

    public getFields(projectId: string, category: string, subCategory: string): Promise<FieldDefinition[]> {
        let fieldPath = '/fields';
        fieldPath +=
            '?' +
            this.utilities.buildQueryFromObject({
                'request.Category': category,
                'request.Subcategory': subCategory
            });
        return lastValueFrom(this.http.get<GeoFields>(this.env.apiUrl + fieldPath)).then(response => {
            let dtoFields = response.items;
            const fieldTypesToFilterOut: (FieldType | LayoutFieldType | AutoFieldModelType)[] = [];
            if (!this.featureFlagStream.flagEnabled(FeatureFlagName.SENSORS)) {
                fieldTypesToFilterOut.push(FieldType.Sensor);
            }
            dtoFields = dtoFields.filter(field => !fieldTypesToFilterOut.includes(field.fieldType));

            return dtoFields.map(item => {
                let field: FieldDefinition = FieldDefinition.fromDTO(item, category);
                field = this.adjustFieldAndApplyTranslations(field);
                return field;
            });
        });
    }

    // do some massaging of the field definition
    private adjustFieldAndApplyTranslations(field: FieldDefinition): FieldDefinition {
        let tmpFieldLabel: string = field.application.layoutSchema.label;
        if (tmpFieldLabel) {
            // translate label
            field.application.layoutSchema.label = this.translateToTCString[tmpFieldLabel] || tmpFieldLabel;

            // increment field
            if (field.application.layoutSchema.properties) {
                if (field.fieldType === AutoFieldModelType.Increment) {
                    field.application.layoutSchema.hideRequired = true;
                    field.application.layoutSchema.properties.push({
                        id: 'isVisible',
                        type: 'boolean',
                        default: false,
                        label: 'common.visible',
                        position: 'header'
                    });
                }

                // translate title, label, infoText
                field.application.layoutSchema.properties.forEach((tmpProp: { [key: string]: any }) => {
                    let tmpPropLabel = tmpProp.label;
                    let tmpPropPostFixLabel = tmpProp.postfixLabel;
                    let tmpPropTitle = tmpProp.title;
                    let tmpPropInfoText = tmpProp.infotext;
                    tmpProp.label = this.translateToTCString[tmpPropLabel] || tmpPropLabel;
                    tmpProp.postfixLabel = this.translateToTCString[tmpPropPostFixLabel] || tmpPropPostFixLabel;
                    tmpProp.title = this.translateToTCString[tmpPropTitle] || tmpPropTitle;
                    tmpProp.infotext = this.translateToTCString[tmpPropInfoText] || tmpPropInfoText;
                    if (tmpProp.items && tmpProp.items.length) {
                        tmpProp.items.forEach((tmpItem: any) => {
                            tmpItem.label = this.translateToTCString[tmpItem.label] || tmpItem.label;
                        });
                    }
                    if (tmpProp.properties && tmpProp.properties.length) {
                        tmpProp.properties.forEach((tmpSubProp: any) => {
                            tmpSubProp.title = this.translateToTCString[tmpSubProp.title] || tmpSubProp.title;
                            tmpSubProp.label = this.translateToTCString[tmpSubProp.label] || tmpSubProp.label;
                            tmpSubProp.infotext = this.translateToTCString[tmpSubProp.infotext] || tmpSubProp.infotext;
                        });
                    }
                    if (tmpProp.populatedValues && tmpProp.populatedValues.text) {
                        tmpProp.populatedValues.text =
                            this.translateToTCString[tmpProp.populatedValues.text] || tmpProp.populatedValues.text;
                    }
                    if (tmpProp.populatedValues?.code && tmpProp.populatedValues?.description) {
                        tmpProp.populatedValues.code =
                            this.translateToTCString[tmpProp.populatedValues.code] || tmpProp.populatedValues.code;
                        tmpProp.populatedValues.description =
                            this.translateToTCString[tmpProp.populatedValues.description] ||
                            tmpProp.populatedValues.description;
                    }
                });
            }
        }

        return field;
    }

    public async getSensorsData(): Promise<SensorData[]> {
        const sensorsPath = '/sensors';
        const sensors = await lastValueFrom(this.http.get<SensorData[]>(this.env.apiUrl + sensorsPath));
        sensors.forEach(sensor =>
            sensor.messageDefinitions.forEach(
                messageDefinition =>
                    (messageDefinition.fieldDefinitions = messageDefinition.fieldDefinitions.filter(
                        fieldDef => !!fieldDef.fieldType
                    ))
            )
        );
        return sensors;
    }
}
