import { Component, Input, OnInit } from '@angular/core';
import { timer } from 'rxjs';

import { FormFieldBaseComponent } from '../../form-field-base.component';

@Component({
    selector: 'information-field',
    templateUrl: './information-field.component.html'
})
export class InformationFieldComponent extends FormFieldBaseComponent implements OnInit {
    @Input()
    information: string;

    @Input()
    displaySeconds: number;

    constructor() {
        super();
    }

    ngOnInit(): void {
        timer(this.displaySeconds, 0).subscribe(() => (this.information = null));
    }
}
