import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel, ValidationErrors } from '@angular/forms';

import { ValueAccessorBase } from '../value-accessor-base';

@Component({
    selector: 'gsp-password',
    templateUrl: './gsp-password.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: GspPasswordComponent,
            multi: true
        },
        { provide: NG_VALIDATORS, useExisting: GspPasswordComponent, multi: true }
    ]
})
export class GspPasswordComponent extends ValueAccessorBase<any> {
    @Input()
    public id = 'password-input';

    @Input()
    public inputClass: string;

    @Input()
    public inputNgClass: object;

    @Input()
    public name = 'password-input';

    @Input()
    public placeholder = '';

    @Input()
    public title = '';

    @Output()
    public valueChange = new EventEmitter<Event>();

    @Input()
    public required = false;

    @Input()
    public errorTooltipMsg = '';

    @Input()
    public focusElement = false;

    @ViewChild('inputRef')
    inputRef: NgModel;

    public isPasswordVisible = false;

    public validate(): ValidationErrors {
        return this.inputRef && this.inputRef.dirty ? this.inputRef.errors : null;
    }

    public togglePasswordVisibility(): void {
        this.isPasswordVisible = !this.isPasswordVisible;
    }
}
