export enum ButtonType {
    Default = 0,
    Primary = 1,
    Danger = 2,
    Link = 3,
    Done = 4,
    Success = 5,
    Icon = 6,
    DefaultIcon = 7,
    PrimaryIcon = 8,
    SuccessIcon = 9,
    TextIcon = 10
}

export class Button {
    static buttonClass(type: ButtonType): string {
        switch (type) {
            case ButtonType.Default:
                return 'button-default';
            case ButtonType.Primary:
                return 'button-primary';
            case ButtonType.Danger:
                return 'button-danger';
            case ButtonType.Link:
                return 'button-default';
            case ButtonType.Done:
                return 'button-done';
            case ButtonType.Success:
                return 'button-success';
            case ButtonType.Icon:
                return 'button-icon button-light';
            case ButtonType.DefaultIcon:
                return 'button-icon button-default';
            case ButtonType.PrimaryIcon:
                return 'button-icon button-primary';
            case ButtonType.SuccessIcon:
                return 'button-icon button-success';
            case ButtonType.TextIcon:
                return 'button-icon button-light';
            default:
                return 'button-default';
        }
    }
}
