import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { filter as rxjsFilter, take, takeUntil } from 'rxjs/operators';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { ContextMenuActions } from 'src/app/shared/common/components/context-menu/gsp-context-menu.component';
import { NotificationType } from 'src/app/shared/common/components/gsp-notification/gsp-notification.component';
import { GspTextType } from 'src/app/shared/common/components/gsp-text/gsp-text.component';
import { CurrentEntitlementStreamService } from 'src/app/shared/common/current-entitlement/current-entitlement-stream.service';
import { authorizedCadExports, EntitlementSKUs } from 'src/app/shared/common/current-entitlement/entitlement';
import { FeatureFilterStreamService } from 'src/app/shared/common/current-features/feature-filter-stream.service';
import { FeaturesStreamsService } from 'src/app/shared/common/current-features/features-streams.service';
import { LayersStore } from 'src/app/shared/common/current-layers/layers-store.service';
import { LayersStreams } from 'src/app/shared/common/current-layers/layers-streams.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { CurrentUserStreamService } from 'src/app/shared/common/current-user/current-user-stream.service';
import { FullFeatureFilter } from 'src/app/shared/common/feature-filter/full-feature-filter';
import { TCFile } from 'src/app/shared/common/files/TCFile';
import { TCFileService } from 'src/app/shared/common/files/TCFile.service';
import { ModalSize } from 'src/app/shared/common/modal-sizes';
import {
    ExportPollDetails,
    ExportPollingStatusService,
    PollingType
} from 'src/app/shared/common/polling/export-polling-status.service';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';
import { GeneralUtils } from 'src/app/shared/common/utility/general-utils';
import { Feature } from 'src/app/shared/map-data-services/feature/feature';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { MapWorkspaceService } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace.service';
import { TemplateService } from 'src/app/shared/template-services/template.service';
import { ExportSettings, UserMapWorkspaceSetting } from 'src/app/shared/user/user-map-workspace-settings';
import { UserSettingsStreamService } from 'src/app/shared/user/user-settings-stream.service';

import { Export, FileType, filterOptions, FilterType, imageTypes } from './export';
import { ExportService } from './export.service';

@Component({
    selector: 'export-data',
    templateUrl: './export.component.html'
})
export class ExportDataComponent implements OnInit, OnDestroy {
    @ViewChild('exportForm')
    public exportForm: NgForm;

    public currentExport: Export = new Export();
    public exportingAll = false; // true when called from workspace list Export; false otherwise
    public formatOptions: { id: FileType; displayName: string }[] = [];
    public imageOptions: { id: boolean; displayName: string }[] = imageTypes;
    public filterOptions: { id: number; displayName: string; description: string }[] = filterOptions;
    public contextMenuItems: ContextMenuActions[] = [];
    // expose enum to template
    public ButtonType = ButtonType;
    public FilterType = FilterType;
    public ModalSize = ModalSize;
    public NotificationType = NotificationType;
    public GspTextType = GspTextType;

    public selectedFeatureIds: string[];
    public selectedFeaturesWithTemplates: Feature[];
    public selectedLayersWithTemplates: Layer[];
    public PDFTemplates: TCFile[] = [];
    public PDFExportError = '';
    public selectedPDFFileName: string = null;
    public isBusyGettingPDFExportAttributes = false;
    public names: { id: string; displayName: string }[] = [];
    public exportMapWorkspace: MapWorkspace;
    public isBusySettingupTheExport = true;
    public canEditHeight = false;
    public hasAuthorizedCadEntitlement = false;
    public availableFeatureTags: string[] = [];
    public restrictedTags: string[] = ['exported', 'No tags'];

    private readonly destroyed = new Subject<void>();

    private exportMapWorkspaceId: string;
    private visibleLayers: Layer[];
    private connectFolderContents: { [folderId: string]: TCFile[] } = {};
    private currentMapWorkspaceLayers: Layer[];
    private exportingMapWorkspaceLayers: Layer[];
    private workspaceSettings: UserMapWorkspaceSetting = null;
    private workspaceCoordinateSystemDetailsAreLoading = true;

    public showCancelDone = true;

    constructor(
        private exportService: ExportService,
        private exportPollingStatusService: ExportPollingStatusService,
        private currentUserStream: CurrentUserStreamService,
        private mapWorkspaceService: MapWorkspaceService,
        private mapWorkspaceStore: MapWorkspacesStoreService,
        private projectStream: ProjectStreamService,
        private featuresStreams: FeaturesStreamsService,
        private featureFilterStream: FeatureFilterStreamService,
        private layersStore: LayersStore,
        private layersStreams: LayersStreams,
        private messaging: MessagingService,
        private translate: TranslationService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private tcFileService: TCFileService,
        private templateService: TemplateService,
        private userSettingsStreamService: UserSettingsStreamService,
        private currentEntitlementStreamService: CurrentEntitlementStreamService
    ) {}

    ngOnInit(): void {
        const currentMapWorkspace = this.mapWorkspaceStore.getCurrentMapWorkspace();

        this.layersStreams.mapWorkspaceLayersStream.pipe(takeUntil(this.destroyed)).subscribe(mapWorkspaceLayers => {
            this.currentMapWorkspaceLayers = mapWorkspaceLayers;
        });

        this.layersStore.mapWorkspaceLayersLoadingStream
            .pipe(
                rxjsFilter(val => !val),
                take(1)
            )
            .subscribe(() => {
                this.visibleLayers = _.filter(this.layersStreams.visibleLayersStream.getValue(), layer => true);
                this.featuresStreams.selectedFeaturesStream.pipe(takeUntil(this.destroyed)).subscribe(features => {
                    this.selectedFeatureIds = features.map(selectedFeature => selectedFeature.id);
                    this.setAvailableFiltersAndDefaultScope();
                });
                this.createExportJob(currentMapWorkspace);
                this.availableFeatureTags = this.mapWorkspaceStore.workspaceFeatureTagsStream.getValue();
            });

        // Required for PDF type export
        this.selectedLayersWithTemplates = this.visibleLayers?.filter(
            layer => !GeneralUtils.isNullUndefinedOrNaN(layer.templateId)
        );

        // Required for PDF type export
        const selectedFeaturesWithTemplates = this.featuresStreams.selectedFeaturesStream
            .getValue()
            .filter(feature => !GeneralUtils.isNullUndefinedOrNaN(feature.templateId));

        this.selectedFeaturesWithTemplates = _.uniqBy(selectedFeaturesWithTemplates, 'templateId');
        this.hasAuthorizedCadEntitlement = this.currentEntitlementStreamService.hasAnyOfTheseEntitlements(
            authorizedCadExports as EntitlementSKUs[]
        );
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    public getExportFilterOptionInfo(option: number): { id: number; displayName: string; description: string } {
        return _.find(this.filterOptions, filter => filter.id === option);
    }

    public getExportFormatOptionInfo(option: string): { id: FileType; displayName: string } {
        return _.find(this.formatOptions, format => format.id === this.selectedFormat);
    }

    public get summary(): string {
        if (this.selectedFormat) {
            const selectedExportFormat = this.getExportFormatOptionInfo(this.selectedFormat);
            let summaryStr = '';
            switch (this.currentExport.exportScope) {
                case FilterType.CURRENTWORKSPACE:
                case FilterType.OTHERWORKSPACE:
                    summaryStr = this.translate.instant('TCS.Export.ExportAllWorkspace', {
                        format: selectedExportFormat.displayName
                    });
                    break;
                case FilterType.SELECTION:
                    summaryStr =
                        this.selectedFeatureIds.length > 1
                            ? this.translate.instant('TCS.Export.FormsSelectedExport', {
                                  count: this.selectedFeatureIds.length
                              })
                            : this.translate.instant('TCS.Export.FormSelectedExport', {
                                  count: this.selectedFeatureIds.length
                              });

                    break;
                case FilterType.VISIBLE:
                    const layerSummary = this.currentExport.query.generateSummary(this.translate);
                    summaryStr = this.translate.instant('TC.Common.ExportingData') + ' ' + layerSummary + ' ';
                    break;
            }

            return (
                summaryStr +
                ' ' +
                this.translate.instant('TC.Common.Lower_To') +
                ' ' +
                this.translate.instant(selectedExportFormat.displayName) +
                ', ' +
                (this.currentExport.imagesAsHyperlinks
                    ? this.translate.instant('MapViewer_Export_Images_Hyperlinks')
                    : this.translate.instant('MapViewer_Export_Images_Files'))
            );
        } else {
            return '';
        }
    }

    public set selectedFormat(format: FileType) {
        this.currentExport.exportFormat = format;

        // Reset PDF export job fields
        if (!this.isPDFExport) {
            this.currentExport.pdfExportNameFieldName = null;
            this.currentExport.pdfTemplateConnectFileId = null;
        } else {
            const currentMapWorkspace = this.mapWorkspaceStore.getCurrentMapWorkspace();
            const currentProject = this.projectStream.currentProjectStream.getValue();
            if (this.connectFolderContents[currentMapWorkspace.connectFolderId]) {
                this.PDFTemplates = this.connectFolderContents[currentMapWorkspace.connectFolderId];
                this.selectedPDFTemplate = this.PDFTemplates.length > 0 ? this.PDFTemplates[0].id : null;
            } else {
                this.isBusyGettingPDFExportAttributes = true;
                this.tcFileService
                    .getFolderItems(currentMapWorkspace.connectFolderId, currentProject.id, false)
                    .then(files => {
                        this.PDFTemplates = files.filter(file => file.name.indexOf('.docx') > -1);
                        if (this.PDFTemplates && this.PDFTemplates.length) {
                            this.selectedPDFTemplate = this.PDFTemplates.length > 0 ? this.PDFTemplates[0].id : null;
                        }
                        this.isBusyGettingPDFExportAttributes = false;
                    });
            }
        }
    }

    public get selectedFormat(): FileType {
        return this.currentExport.exportFormat;
    }

    public set selectedPDFTemplate(fileId: string) {
        this.currentExport.pdfTemplateConnectFileId = fileId;
        const templateSeriesId =
            this.currentExport.exportScope === FilterType.SELECTION
                ? this.selectedFeaturesWithTemplates[0].templateSeriesId
                : this.selectedLayersWithTemplates[0].templateSeriesId;
        this.isBusyGettingPDFExportAttributes = true;
        this.templateService
            .getTemplateFields(this.projectStream.getCurrentProject().id, templateSeriesId)
            .then((fields: any[]) => {
                if (fields.length) {
                    this.names = fields.map((field: any) => ({ id: field.name, displayName: field.displayName }));
                    this.currentExport.pdfExportNameFieldName = this.names[0].id;
                }
                this.isBusyGettingPDFExportAttributes = false;
            });
    }

    public get selectedPDFTemplate(): string {
        return this.currentExport.pdfTemplateConnectFileId;
    }

    public get isPDFExport(): boolean {
        return this.currentExport.exportFormat === FileType.PDF;
    }

    public isCadFormatSelected(): boolean {
        return [FileType.DWG, FileType.DXF].includes(this.selectedFormat);
    }

    public get hasPDFTemplateForPDFExport(): boolean {
        const hasTemplatesForPDFExport = this.PDFTemplates && this.PDFTemplates.length > 0;
        if (!hasTemplatesForPDFExport) {
            this.PDFExportError = this.translate.instant('MapViewer_Export_PDF_NoTemplates');
            return false;
        } else {
            this.PDFExportError = '';
            return true;
        }
    }

    public get canExportToPDF(): boolean {
        // skip checks if there is no pdf templates
        if (!this.hasPDFTemplateForPDFExport) {
            return false;
        }
        switch (this.currentExport.exportScope) {
            case FilterType.SELECTION:
                const selectedfeaturesFromMultipleLayers =
                    this.selectedFeaturesWithTemplates.length && this.selectedFeaturesWithTemplates.length === 1;
                this.PDFExportError = !selectedfeaturesFromMultipleLayers
                    ? this.translate.instant('MapViewer_Export_PDF_Forms_Requirement') +
                      ' ' +
                      this.translate.instant('MapViewer_Export_PDF_Information')
                    : '';
                return selectedfeaturesFromMultipleLayers;
            case FilterType.VISIBLE:
            case FilterType.CURRENTWORKSPACE:
                const hasOnlyOneVisibleLayer = this.visibleLayers.length && this.visibleLayers.length < 2;
                const hasALayerWithTemplate =
                    this.selectedLayersWithTemplates.length && this.selectedLayersWithTemplates.length === 1;

                if (this.visibleLayers.length) {
                    if (!hasOnlyOneVisibleLayer) {
                        this.PDFExportError =
                            this.translate.instant('MapViewer_Export_PDF_Layers_Requirement') +
                            ' ' +
                            this.translate.instant('MapViewer_Export_PDF_Information');
                    }

                    if (hasOnlyOneVisibleLayer && !hasALayerWithTemplate) {
                        this.PDFExportError =
                            this.translate.instant('MapViewer_Export_PDF_No_Templates') +
                            ' ' +
                            this.translate.instant('MapViewer_Export_PDF_Information');
                    }
                } else {
                    this.PDFExportError =
                        this.translate.instant('MapViewer_Export_PDF_No_Layers') +
                        ' ' +
                        this.translate.instant('MapViewer_Export_PDF_Information');
                }

                return hasOnlyOneVisibleLayer && hasALayerWithTemplate;
            default:
                this.PDFExportError = '';
                return false;
        }
    }

    public set selectedFilter(filter: number) {
        this.currentExport.exportScope = filter;
        this.currentExport.query = new FullFeatureFilter();

        switch (this.currentExport.exportScope) {
            case FilterType.SELECTION:
                this.currentExport.query.selectedFeatureIds = this.selectedFeatureIds;
                break;
            case FilterType.VISIBLE:
                this.currentExport.query = new FullFeatureFilter(this.featureFilterStream.activeFilter);
                this.currentExport.query.layers = this.visibleLayers;
                break;
            case FilterType.OTHERWORKSPACE:
                this.currentExport.query.layers = this.exportingMapWorkspaceLayers;
                break;
            case FilterType.CURRENTWORKSPACE:
            default:
                this.currentExport.query.layers = this.currentMapWorkspaceLayers;
                break;
        }
    }

    public get selectedFilter(): number {
        return this.currentExport.exportScope;
    }

    public getErrorText(errors: object): string {
        const isRequiredError = _.filter([errors], 'required');
        const isInvalidEmailError = _.filter([errors], 'emailInvalid');

        if (isRequiredError.length) {
            return this.translate.instant('RequiredError');
        }

        if (isInvalidEmailError.length) {
            return this.translate.instant('TC.Team.InvalidEmail');
        }
    }

    public closePanel(): void {
        this.router.navigate(['mapViewer', { outlets: { centerDialog: null } }], {
            queryParamsHandling: 'merge',
            queryParams: {
                mapWorkspaceId: null // removes this query parameter
            }
        });
    }

    exportButtonAction = () => this.startExport();

    private startExport(): Promise<void> {
        const currentProject = this.projectStream.currentProjectStream.getValue();
        this.currentExport.exportSRS = this.exportMapWorkspace.coordinateSystem.cscmResourceId;

        return this.exportService
            .createExport(currentProject.id, this.currentExport)
            .then(response => {
                // updating the workspace export settings on successful export except for PDF export
                if (this.currentExport.exportFormat !== FileType.PDF) {
                    this.workspaceSettings.export = {
                        exportFormat: this.currentExport.exportFormat,
                        imagesAsHyperlinks: this.currentExport.imagesAsHyperlinks,
                        markAsExported: this.currentExport.markAsExported,
                        exportAllFormAttributes: this.currentExport.exportAllFormAttributes
                    };
                    this.userSettingsStreamService.updateWorkspaceSettings(this.workspaceSettings);
                }
                this.router
                    .navigate(['mapViewer', { outlets: { centerDialog: null } }], {
                        queryParamsHandling: 'merge',
                        queryParams: {
                            mapWorkspaceId: null // removes this query parameter
                        }
                    })
                    .then(() => {
                        const toastrObject = this.messaging.showInfo(
                            this.translate.instant('export.complete_message', {
                                fileName: this.currentExport.name
                            }),
                            null,
                            { closeButton: true, disableTimeOut: true, tapToDismiss: true }
                        );
                        const message1 = this.translate.instant('TC.Export.ExportFile', {
                            fileName: this.currentExport.name
                        });
                        const message2 = this.translate.instant('TC.Common.Complete');
                        const exportPollObject: ExportPollDetails = {
                            projectId: currentProject.id,
                            workspaceId: this.exportMapWorkspace.id,
                            id: response.id,
                            toastId: toastrObject.toastId,
                            message: message1 + ' ' + message2.toLowerCase()
                        };
                        this.exportPollingStatusService.addPollingDetailsToSessionStorage(exportPollObject);
                        this.exportPollingStatusService.startPolling(
                            PollingType.EXPORT,
                            currentProject.id,
                            response.id,
                            toastrObject.toastId,
                            exportPollObject.message
                        );
                    });
            })
            .catch(() => {
                this.messaging.showError(this.translate.instant('TC.Common.ExportFailed'));
            });
    }

    public get disableCadExport(): boolean {
        return !this.hasAuthorizedCadEntitlement && this.isCadFormatSelected();
    }

    public get disableExport(): boolean {
        return (
            this.disableCadExport ||
            (this.exportForm && !this.exportForm.form.valid) ||
            (this.isPDFExport && !this.canExportToPDF) ||
            (this.isPDFExport && !this.hasPDFTemplateForPDFExport) ||
            this.isBusyGettingPDFExportAttributes ||
            this.isBusySettingupTheExport ||
            this.workspaceCoordinateSystemDetailsAreLoading
        );
    }

    public onWorkspaceCoordinateSystemDetailsLoading(areLoading: boolean) {
        this.workspaceCoordinateSystemDetailsAreLoading = areLoading;
    }

    private setAvailableFiltersAndDefaultScope(): void {
        if (this.exportingAll) {
            this.currentExport.exportFilters = [this.filterOptions[FilterType.OTHERWORKSPACE]];
            this.selectedFilter = FilterType.OTHERWORKSPACE;
        } else {
            // Exporting selected workspace data (e.g. when called from collaboration tools Export)
            const hasVisibleData =
                this.featureFilterStream.activeFilter.hasActiveFilters() ||
                (this.visibleLayers && this.visibleLayers.length)
                    ? true
                    : false;
            const hasSelection = this.selectedFeatureIds && this.selectedFeatureIds.length ? true : false;

            // Order of this matter so do not change it. Priority order from top -> Selection, visible and Workspace
            this.currentExport.exportFilters = [this.filterOptions[FilterType.CURRENTWORKSPACE]];
            this.selectedFilter = FilterType.CURRENTWORKSPACE;

            if (hasVisibleData) {
                this.currentExport.exportFilters.push(this.filterOptions[FilterType.VISIBLE]);
                this.selectedFilter = FilterType.VISIBLE;
            }

            if (hasSelection) {
                this.currentExport.exportFilters.push(this.filterOptions[FilterType.SELECTION]);
                this.selectedFilter = FilterType.SELECTION;
            }
        }
    }

    private async setupExportJob(currentMapWorkspace: MapWorkspace): Promise<void> {
        // Find if export is for current workspace or other workspace
        const mapWorkspaceId = this.activatedRoute.snapshot.queryParamMap.get('mapWorkspaceId');
        this.exportMapWorkspaceId = mapWorkspaceId || currentMapWorkspace.id;
        this.exportingAll = Boolean(mapWorkspaceId);

        if (this.exportMapWorkspaceId === currentMapWorkspace.id) {
            this.exportMapWorkspace = CloneUtils.cloneDeep(currentMapWorkspace);
        } else {
            this.exportMapWorkspace = await this.mapWorkspaceService.getMapWorkspaceById(
                currentMapWorkspace.projectId,
                this.exportMapWorkspaceId
            );
        }

        this.canEditHeight = !!this.exportMapWorkspace.coordinateSystem.geoidComponentId;
        this.exportingMapWorkspaceLayers = await this.layersStore.getLayersByMapWorkspace(this.exportMapWorkspace);
        this.workspaceSettings = await this.userSettingsStreamService.getWorkspaceSettings(this.exportMapWorkspaceId);
        this.formatOptions = this.currentExport.supportedFormatTypes(
            this.currentEntitlementStreamService.hasEntitlement(EntitlementSKUs.PDF_EXPORT),
            this.currentEntitlementStreamService.hasEntitlement(EntitlementSKUs.CAD_EXPORT)
        );
    }

    // Create export data job with default options
    private async createExportJob(currentMapWorkspace: MapWorkspace): Promise<void> {
        await this.setupExportJob(currentMapWorkspace);
        // Encountered cases where current workspace is not known.
        const exportingWorkspaceLayers = this.exportingMapWorkspaceLayers;
        const currentWorkspaceLayers = this.currentMapWorkspaceLayers;
        if (
            this.exportingAll &&
            (!exportingWorkspaceLayers || (exportingWorkspaceLayers && !exportingWorkspaceLayers.length))
        ) {
            this.messaging.showWarning(this.translate.instant('TCS.ExportEmptyWorkspace'));
            this.closePanel();
        } else if (
            !this.exportingAll &&
            (!currentWorkspaceLayers || (currentWorkspaceLayers && !currentWorkspaceLayers.length))
        ) {
            this.messaging.showWarning(this.translate.instant('TCS.ExportEmptyWorkspace'));
            this.closePanel();
        } else {
            this.isBusySettingupTheExport = false;
            this.currentExport.name =
                this.exportMapWorkspace.name + '_' + formatDate(new Date(), 'dd-MMM-yy_hh-mm', 'en-US', null);
            this.currentExport.emailAddress = this.currentUserStream.currentUser.email;
            this.currentExport.workspaceId = this.exportMapWorkspace.id;
            this.setAvailableFiltersAndDefaultScope();
            const lastExportSettings = this.getLastExportSettings(this.workspaceSettings);
            if (lastExportSettings) {
                this.selectedFormat = this.workspaceSettings.export.exportFormat;
                this.currentExport.imagesAsHyperlinks = this.workspaceSettings.export.imagesAsHyperlinks;
                this.currentExport.markAsExported = this.workspaceSettings.export.markAsExported;
                this.currentExport.exportAllFormAttributes = this.workspaceSettings.export.exportAllFormAttributes;
            } else {
                this.selectedFormat = this.formatOptions[0].id;
            }
        }
    }

    // Have to check if last used format is still valid
    private getLastExportSettings(settings: UserMapWorkspaceSetting): ExportSettings {
        if (settings?.export) {
            const isValidFormat = this.formatOptions.find(option => option.id === settings.export.exportFormat);

            return isValidFormat ? settings.export : null;
        }
        return null;
    }

    selectedTab(tabChangeEvent: MatTabChangeEvent) {
        if (tabChangeEvent.tab.textLabel === 'Export') {
            this.showCancelDone = true;
        } else {
            this.showCancelDone = false;
        }
    }

    addFormTag(tag: string): void {
        this.currentExport.tags = [...this.currentExport.tags, tag];
    }

    removeFormTag(tag: string): void {
        this.currentExport.tags = this.currentExport.tags.filter(removedTag => removedTag !== tag);
    }
}
