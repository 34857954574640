import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';

import { ValueAccessorBase } from '../value-accessor-base';

export enum GspTextType {
    GENERIC = 'GENERIC',
    URL = 'URL',
    EMAIL = 'EMAIL'
}

@Component({
    selector: 'gsp-text',
    templateUrl: './gsp-text.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: GspTextComponent,
            multi: true
        },
        { provide: NG_VALIDATORS, useExisting: GspTextComponent, multi: true }
    ]
})
export class GspTextComponent extends ValueAccessorBase<any> {
    @Input()
    public type = GspTextType.GENERIC;

    @Input()
    public id = 'text-input';

    @Input()
    public inputClass: string;

    @Input()
    public inputNgClass: object;

    @Input()
    public name = 'text-input';

    @Input()
    public placeholder = '';

    @Input()
    public title = '';

    @Output()
    public valueChange = new EventEmitter<Event>();

    @Output()
    public focusEvent = new EventEmitter<Event>();

    @Output()
    public blurEvent = new EventEmitter<Event>();

    @Output()
    public keyupEvent = new EventEmitter<Event>();

    @Output()
    public keydownEvent = new EventEmitter<Event>();

    @Input()
    public disabled = false;

    @Input()
    public readonly = false;

    @Input()
    public required = false;

    @Input()
    public length = 1024;

    @Input()
    public errorTooltipMsg = '';

    @Input()
    public focusElement = false;

    @Input()
    preventScroll = false;

    @ViewChild('inputRef', { static: false })
    inputRef: NgModel;

    // exposing enum to template
    public GspTextType = GspTextType;

    validate() {
        return this.inputRef && this.inputRef.dirty ? this.inputRef.errors : null;
    }
}
