import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpStatusCodes } from 'src/app/shared/common/http-status-codes';
import { environment } from 'src/environments/environment';

export enum ErrorPageErrorCodes {
    USER_NOT_IN_PROJECT = 'USER_NOT_IN_PROJECT',
    INVALID_VERSION_ID = 'INVALID_VERSION_ID',
    INVALID_REGION = 'INVALID_REGION'
}

export enum HttpErrorCodes {
    UNAUTHORISED = HttpStatusCodes.UNAUTHORISED,
    FORBIDDEN = HttpStatusCodes.FORBIDDEN,
    NOT_FOUND = HttpStatusCodes.NOT_FOUND,
    INTERNAL_ERROR = HttpStatusCodes.INTERNAL_ERROR,
    SERVER_UNAVAILABLE = HttpStatusCodes.SERVER_UNAVAILABLE
}

export type MapViewerErrorCodes = ErrorPageErrorCodes | HttpErrorCodes;

@Component({
    selector: 'error-page',
    templateUrl: './error-page.component.html'
})
export class ErrorPageComponent implements OnDestroy {
    public errorCode: MapViewerErrorCodes = HttpErrorCodes.SERVER_UNAVAILABLE;
    public connectUrl = environment.connectMasterUrl;
    private destroyed = new Subject<void>();

    // Exposing enum to template
    public ErrorPageErrorCodes = ErrorPageErrorCodes;
    public HttpErrorCodes = HttpErrorCodes;

    constructor(private activatedRoute: ActivatedRoute) {
        this.activatedRoute.params.pipe(takeUntil(this.destroyed)).subscribe(params => {
            if (params.errorStatus) {
                this.setErrorCode(params.errorStatus);
            }
        });
    }

    public ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    public setErrorCode(errorStatus: MapViewerErrorCodes): void {
        // check number using regex since params.errorStatus is always a string
        const isHttpErrorCode = /^\d+$/.test(errorStatus as string);

        if (isHttpErrorCode) {
            this.errorCode = Number(errorStatus) as HttpErrorCodes;
        } else {
            this.errorCode = errorStatus as ErrorPageErrorCodes;
        }
    }
}
