import { PermissionType } from '../map-data-services/mapWorkspace/permission';
import { UserDTO } from './user';

export class Actor {
    id: string = null;
    actorId: string = null;
    firstName: string = null;
    lastName: string = null;
    label: string = null;
    isTodoCompleted = false;
    totalItems = 1;
    name: string;

    constructor(public type: PermissionType) {}

    static fromDTO(dto: Partial<UserDTO>, type: PermissionType): Actor {
        const actor = new Actor(type);

        if (dto) {
            actor.id = dto.id;
            actor.actorId = type + ':' + dto.id;
            actor.firstName = dto.firstName;
            actor.lastName = dto.lastName;
            if (type === PermissionType.USER) {
                actor.name = dto.firstName + ' ' + dto.lastName;
            } else {
                actor.name = dto.name;
            }
            actor.label = dto.email;
        }

        return actor;
    }
}
