import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslationModule } from 'src/app/core/translation/translation.module';
import { CommonModule as AppCommonModule } from 'src/app/shared/common/common.module';

import { PostProcessingOptionsComponent } from './options/post-processing-options.component';
import { PostProcessingTabComponent } from './post-processing-tab/post-processing-tab.component';

@NgModule({
    declarations: [PostProcessingOptionsComponent, PostProcessingTabComponent],
    imports: [TranslationModule, AppCommonModule, FormsModule, CommonModule],
    exports: [PostProcessingOptionsComponent, PostProcessingTabComponent]
})
export class PostProcessingModule {}
