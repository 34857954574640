import * as _ from 'lodash-es';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { PostProcessingStatus } from 'src/app/feature/post-processing/options/post-processing-options.component';
import { Feature } from 'src/app/shared/map-data-services/feature/feature';
import { EventSummaryType } from 'src/app/shared/map-data-services/feature/feature.service';

import { PanelFeature } from './feature-panel.component';
import { DateUtils } from 'src/app/shared/common/utility/date-utils';

export class FeaturePanelUtil {
    static getFeatureCorrectionStatus(feature: Feature): string {
        switch (feature.metadata['processing_postprocessedStatus']) {
            case PostProcessingStatus.PENDING:
                return 'MapViewer.EasyPP.QueuedForProcessing';
            case PostProcessingStatus.PROCESSED:
                return 'MapViewer.ImportStatusDialog.ProcessedMessage';
            case PostProcessingStatus.FAILED:
                return 'MapViewer.EasyPP.FailedProcessing';
            default:
                return 'MapViewer.EasyPP.NotProcessed';
        }
    }

    static getUpdatedPostProcessedFeature(
        feature: Feature,
        itemOverwriting: string,
        itemBeingOverwritten: string
    ): Feature {
        let { properties, metadata } = feature;

        // Geometry stored in metadata is always based on local crs
        const geometry = feature.localGeometry;

        // Explicitly set missing fields to null so they get overwritten
        ['correctionsource', 'correctionstatus', 'estimatedaccuracy', 'hdop', 'pdop'].forEach(key => {
            if (_.isUndefined(properties[key])) {
                properties[key] = null;
            }
        });

        // Create new metadata item
        const allMetadataKeys = Object.keys(metadata);
        const newMetadataItem: { [key: string]: any } = allMetadataKeys.reduce((acc, key) => {
            if (metadata[itemOverwriting].metadata.hasOwnProperty(key)) {
                acc[key] = metadata[key];
            }
            return acc;
        }, {} as { [key: string]: any });
        metadata[itemBeingOverwritten] = {
            geometry,
            properties,
            metadata: newMetadataItem
        };

        // Write new metadata
        feature.metadata = { ...metadata, ...metadata[itemOverwriting].metadata };
        feature.localGeometry = metadata[itemOverwriting].geometry;
        feature.properties = metadata[itemOverwriting].properties;
        feature.metadata[itemOverwriting] = {};

        return feature;
    }

    static constructInfoMessage(feature: PanelFeature, translateService: TranslationService): string {
        if (feature.postProcessingEvents) {
            const { summaryevents } = feature.postProcessingEvents;
            const latestEvent = summaryevents[summaryevents.length - 1];
            switch (latestEvent.eventsummarytype) {
                case EventSummaryType.DELAYED:
                    return translateService.instant('MapViewer.EasyPP.EventSummary.Delayed', {
                        retryDate: new Date(latestEvent.nextactivityexpectedtimestamp).toLocaleString()
                    });
                case EventSummaryType.PREPARING:
                    return translateService.instant('MapViewer.EasyPP.EventSummary.Preparing');
                case EventSummaryType.AWAITING_BASE_DATA:
                    if (latestEvent.additionalinfo && latestEvent.nextactivityexpectedtimestamp) {
                        return translateService.instant('MapViewer.EasyPP.EventSummary.AwaitingBaseDataWithParams', {
                            baseProvider: latestEvent.additionalinfo,
                            expectedDate: new Date(latestEvent.nextactivityexpectedtimestamp).toLocaleString()
                        });
                    }
                    return translateService.instant('MapViewer.EasyPP.EventSummary.AwaitingBaseData');
                case EventSummaryType.PROCESSING:
                    return translateService.instant('MapViewer.EasyPP.EventSummary.Processing');
                // This shouldn't be hit as we only get the event summary when postProcessedStatus is Pending
                case EventSummaryType.COMPLETED:
                    return '';
            }
        }

        return '';
    }

    static orderFeaturesInList(features: Feature[]): Feature[] {
        // Features should be sorted first by geometry type, then by layer.
        // They should be ordered by collection date at each level so that the geometry type containing
        // the most recent collection date appears at the top of the list and the layer containing the most recent collection
        // date appears first in the list of layers of that geometry type.

        // Sort features by date, by that feature's layer, and by that layer's geometry type
        const featureByDateDict: { [geomType: string]: { [layerId: string]: Feature[] } } = features.reduce(
            (acc, feature) => {
                if (!acc[feature.geometryType]) {
                    acc[feature.geometryType] = {};
                }
                if (!acc[feature.geometryType][feature.layerId]) {
                    acc[feature.geometryType][feature.layerId] = [];
                }
                const index = acc[feature.geometryType][feature.layerId].findIndex(existingFeature =>
                    DateUtils.isoStringGreaterThanComparator(
                        feature.metadata.collection_utc,
                        existingFeature.metadata.collection_utc
                    )
                );
                index !== -1
                    ? acc[feature.geometryType][feature.layerId].splice(index, 0, feature)
                    : acc[feature.geometryType][feature.layerId].push(feature);
                return acc;
            },
            {} as { [geomType: string]: { [layerId: string]: Feature[] } }
        );

        // Sort layers by feature collection date and flatten
        const layerByDateDict: { [geomType: string]: Feature[] } = Object.keys(featureByDateDict).reduce(
            (acc, geomType) => {
                const layerOrder = Object.keys(featureByDateDict[geomType]).sort((layerA, layerB) => {
                    return DateUtils.isoStringGreaterThanComparator(
                        featureByDateDict[geomType][layerA][0].metadata.collection_utc,
                        featureByDateDict[geomType][layerB][0].metadata.collection_utc
                    )
                        ? -1
                        : 1;
                });
                acc[geomType] = layerOrder.map(layerId => featureByDateDict[geomType][layerId]).flat();
                return acc;
            },
            {} as { [geomType: string]: Feature[] }
        );

        // Sort geometry types by feature collection date and flatten
        return Object.keys(layerByDateDict)
            .sort((geomA, geomB) => {
                return DateUtils.isoStringGreaterThanComparator(
                    layerByDateDict[geomA][0].metadata.collection_utc,
                    layerByDateDict[geomB][0].metadata.collection_utc
                )
                    ? -1
                    : 1;
            })
            .map(geomType => layerByDateDict[geomType])
            .flat();
    }
}
