import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Button, ButtonType } from './button';

/**
 * gspConfirmationButton
 * For simplifying the adding of confirmationsbuttons across the app.
 * Can be used for a click action followed by a confirmation prompt, with or with a 'busy' option
 * to disable while action completes.
 *
 * Can only be used as an element in order to prevent problems with nested buttons when using the double
 * confirmation option.
 *
 * <gsp-confirmation-button
 *      buttonTooltip="{{'DeleteYourCurrentLocation_ID' | translate}}"
 *      (action)="deleteLocation()"
 *      [asyncAction]="deleteLocation()" - use this if you want to wait on the response
 *      [type]="ButtonType.Default" - for other options: see button.ts
 *      text="{{'buttonText' | translate}}"
 *      confirmText="{{'confirmationButtonText' | translate}}"
 *      [iconClass]="'icon-32-dark-field-image'"
 *      [showBusyIndicator]="true" - use asynAction which must return a promise if set to true
 * </gsp-confirmation-button>
 */

@Component({
    selector: 'gsp-confirmation-button',
    templateUrl: './gsp-confirmation-button.component.html'
})
export class GspConfirmationButtonComponent {
    @Input()
    public confirmText: string = null;

    @Input()
    public text = '';

    @Input()
    public iconClass = '';

    @Input()
    public buttonTooltip = '';

    // alternative to click handler which show's busy indicator
    @Input()
    public asyncAction: () => PromiseLike<any>;

    @Input()
    public set type(type: ButtonType) {
        this._type = type;
        this.buttonCssClass = Button.buttonClass(type);
    }
    public get type(): ButtonType {
        return this._type;
    }

    @Input() disabled = false;
    @Output() action = new EventEmitter();

    @Input()
    public id: string;

    public showBusy = false;
    private _type = ButtonType.Default;
    public buttonCssClass = Button.buttonClass(this._type);
    public busyCssClass = '';
    public showConfirmation = false;
    public ButtonType = ButtonType;

    public onConfirmClick($event: MouseEvent): void {
        this.showConfirmation = false;
        if (this.asyncAction) {
            this.showBusy = true;
            this.asyncAction().then(() => (this.showBusy = false));
        } else {
            this.action.emit($event);
        }
    }

    public onClick($event: MouseEvent): void {
        $event.stopPropagation();
        // When confirmation is required but not displaying the confirmation button yet
        if (!this.showConfirmation) {
            this.expand();
        } else {
            if (this.asyncAction) {
                this.showBusy = true;
                this.asyncAction().then(() => (this.showBusy = false));
            } else {
                this.action.emit($event);
            }
        }
    }

    public onCancelClick($event: MouseEvent): void {
        this.showConfirmation = false;
        $event.stopPropagation();
    }

    private expand(): void {
        this.showConfirmation = true;
    }

    getbuttonId(buttonType: string): string {
        if (this.id) {
            return this.id + '-' + buttonType;
        } else {
            return '';
        }
    }
}
