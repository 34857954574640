import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { MenuService } from 'src/app/shared/common/layout/menu.service';
import { MapWorkspacePermissionType } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace-permission';

@Component({
    selector: 'public-shared-viewer',
    templateUrl: './public-shared-viewer.component.html'
})
export class PublicSharedViewerComponent implements OnInit, OnDestroy {
    // exposing enum to template
    public MapWorkspacePermissionType = MapWorkspacePermissionType;
    private destroyed$ = new Subject<void>();
    public openMenu = false;

    constructor(private menuService: MenuService) {}

    ngOnInit(): void {
        this.menuService.activeMenusStream.pipe(takeUntil(this.destroyed$)).subscribe(activeMenuCodes => {
            this.openMenu = !!activeMenuCodes.length;
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(null);
    }
}
