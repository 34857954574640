<gsp-wizard
    [progressionSteps]="defaultSteps"
    [currentStep]="currentStep"
    [size]="ModalSize.Medium"
    [showProgress]="showProgress"
    id="workspace-creation-wizard"
    [ngClass]="{ 'workspace-create-from-file-wizard': fromFile }"
>
    <gsp-wizard-header [heading]="heading" (closed)="exit()" transclude-wizard-header></gsp-wizard-header>
    <gsp-wizard-footer
        (goBack)="backward()"
        (goForward)="forward()"
        (cancel)="cancel()"
        transclude-wizard-footer
    ></gsp-wizard-footer>
</gsp-wizard>
