import { Component, EventEmitter, Input, Output } from '@angular/core';

/*
  move things up and down in a list
*/

export enum Position {
    TOP = 'top',
    BOTTOM = 'bottom',
    UP = 'up',
    DOWN = 'down'
}

@Component({
    selector: 'up-down-order',
    templateUrl: './up-down-order.component.html'
})
export class UpDownOrderComponent {
    @Input()
    item: any;

    @Input()
    itemList: any[] = [];

    @Input()
    disabled = false;

    @Output()
    highlightFieldChoice = new EventEmitter<number>();

    Position = Position;

    getIndex(item: any): number {
        return this.itemList.findIndex(individualItem => {
            {
                // choice field
                if (individualItem?.text) {
                    return individualItem.text === item.text;
                }

                // coded choice field
                if (individualItem?.description && individualItem?.code) {
                    return individualItem.description === item.description && individualItem.code === item.code;
                }
            }
        });
    }

    isFirst(item: any): boolean {
        return this.getIndex(item) === 0;
    }

    isLast(item: any): boolean {
        return this.getIndex(item) === this.itemList.length - 1;
    }

    bringTo(position: Position = Position.BOTTOM, choiceField: any): void {
        const currentIndex = this.getIndex(choiceField);
        this.itemList.splice(currentIndex, 1);
        let newIndex: number;
        switch (position) {
            case Position.TOP:
                this.itemList.unshift(choiceField);
                newIndex = 0;
                break;
            case Position.BOTTOM:
                this.itemList.push(choiceField);
                newIndex = this.itemList.length - 1;
                break;
            case Position.UP:
                newIndex = currentIndex - 1;
                this.itemList.splice(newIndex, 0, choiceField);
                break;
            case Position.DOWN:
                newIndex = currentIndex + 1;
                this.itemList.splice(newIndex, 0, choiceField);
                break;
        }

        setTimeout(() => {
            this.highlightFieldChoice.emit(newIndex);
        });
    }
}
