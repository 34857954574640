export enum NtripAuthentication {
    No_AUTHENTICATION = 'N'
}

export class Ntrip {
    ntripServerSettings: string;
    source: string;
    ntripId: string;
    details: string;
    format: string;
    system: string;
    country: string;
    network: string;
    carrier: string;
    generator: string;
    solution: string;
    fee: string;
    bitRate: string;
    nmea: string;
    authentication: string;
    referenceFrame: string;

    static fromSettingsString(settingsString: string): Ntrip {
        let settingsStringSplit = settingsString.split(';');
        const ntrip = new Ntrip();
        ntrip.ntripServerSettings = settingsString;
        ntrip.source = settingsStringSplit[1] || ''; // or serve
        ntrip.ntripId = settingsStringSplit[2] || '';
        ntrip.details = settingsStringSplit[4] || '';
        ntrip.format = settingsStringSplit[3] || '';
        ntrip.system = settingsStringSplit[6] || '';
        ntrip.country = settingsStringSplit[8] || '';
        ntrip.network = settingsStringSplit[7] || '';
        ntrip.carrier = settingsStringSplit[5] || '';
        ntrip.generator = settingsStringSplit[13] || '';
        ntrip.solution = settingsStringSplit[12] || ''; // NB: not displayed in UI
        ntrip.fee = settingsStringSplit[16] || '';
        ntrip.bitRate = settingsStringSplit[17] || '';
        ntrip.nmea = settingsStringSplit[11] || ''; // NB: not displayed in UI
        ntrip.authentication = settingsStringSplit[15] || '';
        ntrip.referenceFrame = settingsStringSplit[18] || '';
        return ntrip;
    }

    public constructAliasIdFromReferenceFrame(): string {
        let networkString = this.network.replace(/\ /g, '');
        if (networkString.toLowerCase().includes('vrsnow')) {
            // Different variations of VRSNow exist, however all the aliasIds are prefixed with 'VRSNow'
            networkString = 'VRSNow';
        }
        // cannot use JSON.parse here as it can strip decimals
        const referenceFrameString = escape(this.referenceFrame.split('{"REF":')[1].slice(0, -1));

        return `${networkString}${encodeURIComponent(':')}${referenceFrameString}`;
    }
}
