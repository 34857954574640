<div class="gsp-toggle-container">
    <input class="toggle-input" type="checkbox" />
    <button
        class="toggle-btn"
        [disabled]="disabled"
        [ngClass]="{ 'toggle-btn-on': on, 'toggle-btn-off': !on }"
        [attr.aria-expanded]="on"
        (click)="onClick()"
    ></button>
    <label *ngIf="!!label">{{ label }}</label>
</div>
