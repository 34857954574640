import { FieldTypeOption } from '../../template-services/field';

export type AttrValues =
    | string
    | boolean
    | number
    | { from: string | Date; to: string | Date }
    | string[]
    | { [key: string]: boolean };

export class FeatureFilter {
    userIds: string[] = [];
    fromDate: Date = null;
    toDate: Date = null;
    unassignedUser = false; // set true via "Imported" checkbox
    selectedUsersInfo: string[] = []; // To support filtering features migrated from insphere
    isExported = false;
    isNotExported = false;
    isPostProcessingDone = false;
    isPostProcessingPending = false;
    isPostProcessingFailed = false;
    isPostProcessingUnprocessed = false;
    selectedLayer: string = null;
    selectedField: string = null;
    selectedFieldTypeOption: FieldTypeOption = null;
    attrValues: AttrValues;
    availableCustomTags: string[] = [];
    customTags: string[] = [];
    noCustomTags: boolean = false;

    // Basic feature filter - that which contains the properties set in the filter panel

    constructor() {}

    static fromDTO(dto: any): FeatureFilter {
        dto = dto || {};

        const filter = new FeatureFilter();

        filter.userIds = dto.userIds ? dto.userIds : [];
        filter.fromDate = dto.fromDate ? dto.fromDate : null;
        filter.toDate = dto.toDate ? dto.toDate : null;
        filter.unassignedUser = dto.unassignedUser ? dto.unassignedUser : false;
        if (dto.tagsList && dto.tagsList.length) {
            filter.isExported = true;
        } else if (dto.isExported) {
            filter.isExported = true;
        } else {
            filter.isExported = false;
        }

        if (dto.hasNoTags) {
            filter.isNotExported = true;
        } else if (dto.isNotExported) {
            filter.isNotExported = true;
        } else {
            filter.isNotExported = false;
        }
        filter.selectedUsersInfo = dto.selectedUsersInfo ? dto.selectedUsersInfo : [];
        filter.isPostProcessingDone = dto.isPostProcessingDone || false;
        filter.isPostProcessingPending = dto.isPostProcessingPending || false;
        filter.isPostProcessingFailed = dto.isPostProcessingFailed || false;
        filter.isPostProcessingUnprocessed = dto.isPostProcessingUnprocessed || false;
        filter.selectedLayer = dto?.selectedLayer || null;
        filter.selectedField = dto?.selectedField || null;
        filter.selectedFieldTypeOption = dto?.selectedFieldTypeOption || null;
        filter.attrValues = dto?.attrValues || null;
        filter.customTags = dto?.customTags || [];
        filter.noCustomTags = dto?.noCustomTags || false;

        return filter;
    }

    hasActiveFilters(): boolean {
        return (
            (this.userIds && this.userIds.length > 0) ||
            !!this.fromDate ||
            !!this.toDate ||
            this.unassignedUser ||
            this.isExported ||
            this.isNotExported ||
            this.isPostProcessingDone ||
            this.isPostProcessingPending ||
            this.isPostProcessingFailed ||
            this.isPostProcessingUnprocessed ||
            !!this.selectedLayer ||
            !!this.selectedField ||
            !!this.selectedFieldTypeOption ||
            !!this.attrValues ||
            !!this.customTags.length ||
            !!this.noCustomTags
        );
    }
}
