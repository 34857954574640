import { ForbiddenFormsDTO, ForbiddenFormsReason, ForbiddenFormsState } from 'src/app/shared/types/forbidden-forms';

import { ForbiddenFormsNotification } from './gsp-notification-tab.component';

export class GspNotificationTabUtil {
    static getFormNotificationsFromDTO(dto: ForbiddenFormsDTO): ForbiddenFormsNotification[] {
        const forbiddenFormsNotifications: ForbiddenFormsNotification[] = [];
        const forbiddenFormsData = dto.summary;
        for (const userId in forbiddenFormsData) {
            if (Object.prototype.hasOwnProperty.call(forbiddenFormsData, userId)) {
                const workspaceObj = forbiddenFormsData[userId];
                for (const workspaceId in workspaceObj) {
                    if (Object.prototype.hasOwnProperty.call(workspaceObj, workspaceId)) {
                        const reasonObj = workspaceObj[workspaceId];
                        for (const reason in reasonObj) {
                            if (Object.prototype.hasOwnProperty.call(reasonObj, reason)) {
                                const stateObj = reasonObj[reason as ForbiddenFormsReason];
                                for (const state in stateObj) {
                                    if (Object.prototype.hasOwnProperty.call(stateObj, state)) {
                                        const summary = stateObj[state as ForbiddenFormsState];
                                        forbiddenFormsNotifications.push({
                                            userId,
                                            workspaceId,
                                            reason: reason as ForbiddenFormsReason,
                                            state: state as ForbiddenFormsState,
                                            summary,
                                            formattedTimeStamp: this.getFormattedTime(new Date(summary.totimestamputc))
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return forbiddenFormsNotifications;
    }

    static getFormattedTime(dateTime: Date): string {
        return dateTime.toLocaleDateString('en-us', { weekday: 'long', hour: 'numeric', minute: 'numeric' });
    }
}
