import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { RelationshipService } from 'src/app/shared/map-data-services/relationship/relationship.service';

import { Feature } from '../../map-data-services/feature/feature';
import { RelationshipType } from '../../map-data-services/relationship/relationship';
import { GeoJsonFeaturesStreamService, GeoJsonFeatureType } from '../current-features/geoJson-features-stream.service';
import { MapWorkspaceStreamsService } from '../current-map-workspaces/map-workspace-streams.service';
import { ProjectStreamService } from '../current-project/project-stream.service';
import { UtilitiesService } from '../utility/utilities.service';
import { MapCache, MapCacheStyle } from './map-cache';
import { MapCacheService } from './map-cache.service';

export const MapCacheStyleGeneratedNameMap = {
    [MapCacheStyle.STREET_VIEW]: 'Offline Street basemap',
    [MapCacheStyle.SATELLITE_VIEW]: 'Offline Satellite basemap'
};

@Injectable({
    providedIn: 'root'
})
export class MapCacheStreamService {
    // --------------------------------------

    public currentEditMapCacheStream = new BehaviorSubject<MapCache>(null);
    public currentWorkSpaceMapCaches = new BehaviorSubject<MapCache[]>([]);
    private projectId = this.projectStreamService.getCurrentProject().id;
    private mapCacheList: MapCache[] = [];
    private currentWorkspace: MapWorkspace;
    private mapcacheNames: string[] = [];

    // --------------------------------------

    constructor(
        private mapCacheService: MapCacheService,
        private relationshipService: RelationshipService,
        private mapWorkspaceStreamsService: MapWorkspaceStreamsService,
        private projectStreamService: ProjectStreamService,
        private translate: TranslationService,
        private geoJsonFeaturesStreamService: GeoJsonFeaturesStreamService,
        private messaging: MessagingService
    ) {
        mapWorkspaceStreamsService.currentMapWorkspaceStream
            .pipe(distinctUntilChanged())
            .subscribe((workspace: MapWorkspace) => this.loadMapCache(workspace));
    }

    private loadMapCache(workSpace: MapWorkspace): void {
        this.mapcacheNames = [];
        if (workSpace && workSpace.id) {
            this.currentWorkspace = workSpace;
            this.mapCacheService.getMapCacheList(this.projectId, this.currentWorkspace.id).then(mapCaches => {
                this.mapCacheList = mapCaches || [];
                this.mapCacheList.forEach(mapCache2 => {
                    mapCache2.selected = false;
                    mapCache2.visible = false;
                    mapCache2.mapWorkspaceId = this.currentWorkspace.id;
                    this.mapcacheNames.push(mapCache2.name);
                });
                this.currentWorkSpaceMapCaches.next(this.mapCacheList);
            });
        } else {
            this.currentWorkSpaceMapCaches.next([]);
        }
    }

    public createMapCache(projectId: string, mapCacheBounds: L.LatLngBounds, baseMapStyle: MapCacheStyle): void {
        if (mapCacheBounds) {
            // TODO: Should translate? (original was not)
            // ($translate.instant('TCS.OfflineBaseMap.Title') == 'TCS.OfflineBaseMap.Title') ?
            // "Offline basemap" : $translate.instant('TCS.OfflineBaseMap.Title');
            let offlineBaseMapName = MapCacheStyleGeneratedNameMap[baseMapStyle];
            let newName = UtilitiesService.getNewName(offlineBaseMapName, this.mapcacheNames);
            this.mapcacheNames.push(newName);
            this.mapCacheService
                .saveMapCacheList(projectId, newName, mapCacheBounds, this.getZoomLevel(mapCacheBounds), baseMapStyle)
                .then(
                    mapCache2 => {
                        this.addMapCacheToWorkspace(projectId, this.currentWorkspace.id, mapCache2);
                        let mapCacheJson: Partial<Feature> = JSON.parse(mapCache2.featureJSON);
                        mapCacheJson.colorKey = '363545';
                        mapCacheJson.properties = {};
                        this.geoJsonFeaturesStreamService.displayGeoJsonFeatures(
                            GeoJsonFeatureType.MAP_CACHE,
                            mapCache2.id,
                            mapCacheJson
                        );
                        this.messaging.showSuccess(this.translate.instant('MapViewer.OfflineBasemap.CreateSucess'));
                    },
                    () => {
                        this.messaging.showError(this.translate.instant('TCW_Error'));
                    }
                );
        }
    }

    private async addMapCacheToWorkspace(
        projectId: string,
        workspaceId: string,
        mapCache: MapCache
    ): Promise<MapCache> {
        if (mapCache) {
            let relationshipProperties = {
                mapCacheIds: [mapCache.id]
            };
            await this.relationshipService.attachToMapWorkspace(projectId, workspaceId, relationshipProperties);
            mapCache.selected = true;
            mapCache.visible = true;
            mapCache.mapWorkspaceId = this.currentWorkspace.id;
            this.mapCacheList.push(mapCache);
            this.mapCacheList.sort((mapCache1, mapCache2) => mapCache1.name.localeCompare(mapCache2.name));
            this.currentWorkspace.relationships.mapCacheIds.push(mapCache.id);
            this.currentWorkSpaceMapCaches.next(this.mapCacheList);
            return mapCache;
        }
    }

    public async restoreMapCache(mapCache: MapCache): Promise<MapCache> {
        const mapCache2 = await this.addMapCacheToWorkspace(this.projectId, this.currentWorkspace.id, mapCache);
        let mapCacheJson: Partial<Feature> = JSON.parse(mapCache2.featureJSON);
        mapCacheJson.colorKey = '363545';
        mapCacheJson.properties = {};
        this.geoJsonFeaturesStreamService.displayGeoJsonFeatures(
            GeoJsonFeatureType.MAP_CACHE,
            mapCache2.id,
            mapCacheJson
        );
        return mapCache2;
    }

    public removeMapCacheFromWorkspace(mapCache: MapCache): Promise<MapCache[]> {
        return new Promise(resolve => {
            let relationshipProperty = {
                childId: mapCache.id,
                relationshipType: RelationshipType.MAP_CACHE_TO_WORKSPACE
            };
            this.relationshipService
                .detachFromMapWorkspace(this.projectId, this.currentWorkspace.id, relationshipProperty)
                .then(() => {
                    let currentMapCacheList = this.mapCacheList.filter(cache => cache.id !== mapCache.id);
                    this.mapCacheList = currentMapCacheList;
                    this.currentWorkSpaceMapCaches.next(this.mapCacheList);
                    let findIndex = this.currentWorkspace.relationships.mapCacheIds.findIndex(
                        tmpMapCacheId => tmpMapCacheId === mapCache.id
                    );
                    this.currentWorkspace.relationships.mapCacheIds.splice(findIndex, 1);
                    // mapWorkspaceService.editMapWorkspace(projectId, currentWorkspace.id, currentWorkspace);
                    resolve(this.mapCacheList);
                });
        });
    }

    public renameMapCache(mapCache: MapCache, oldMapName: string): Promise<void> {
        return this.mapCacheService
            .patchMapCache(this.projectId, mapCache.id, {
                name: mapCache.name
            })
            .then(
                () => {
                    this.mapcacheNames[this.mapcacheNames.indexOf(oldMapName)] = mapCache.name;
                },
                () => {
                    this.messaging.showError(this.translate.instant('TCW_Error'));
                }
            );
    }

    private getZoomLevel(mapCacheBounds: L.LatLngBounds): string {
        let cacheLevelData = [
            {
                min: 0,
                max: 50,
                level: '0-19'
            },
            {
                min: 50,
                max: 250,
                level: '0-18'
            },
            {
                min: 250,
                max: 1000,
                level: '0-17'
            },
            {
                min: 1000,
                max: 4000,
                level: '0-16'
            },
            {
                min: 4000,
                max: 15000,
                level: '0-15'
            },
            {
                min: 15000,
                max: 60000,
                level: '0-14'
            },
            {
                min: 60000,
                max: 200000,
                level: '0-13'
            },
            {
                min: 200000,
                max: 650000,
                level: '0-12'
            },
            {
                min: 650000,
                max: 22000000,
                level: '0-11'
            },
            {
                min: 2000000,
                max: 5000000,
                level: '0-10'
            },
            {
                min: 5000000,
                max: 15000000,
                level: '0-9'
            },
            {
                min: 15000000,
                max: 45000000,
                level: '0-8'
            },
            {
                min: 45000000,
                max: 100000000,
                level: '0-7'
            },
            {
                min: 100000000,
                max: 200000000,
                level: '0-6'
            },
            {
                min: 200000000,
                level: '0-5'
            }
        ];
        let area = this.calculateArea(mapCacheBounds);
        let zoomLevel = '0-19';
        cacheLevelData.forEach(cacheLevelDatum => {
            if (cacheLevelDatum.max) {
                if (area > cacheLevelDatum.min && area <= cacheLevelDatum.max) {
                    zoomLevel = cacheLevelDatum.level;
                    return;
                }
            } else {
                if (area > cacheLevelDatum.min) {
                    zoomLevel = cacheLevelDatum.level;
                    return;
                }
            }
        });
        return zoomLevel;
    }

    private calculateArea(mapCacheBounds: L.LatLngBounds) {
        if (mapCacheBounds) {
            // https://github.com/Leaflet/Leaflet/issues/5212
            // The return value of L.Polygon.getLatLngs() which Rectangle extends, is in fact, an array of one element.
            let area = this.geodesicArea(L.rectangle(mapCacheBounds).getLatLngs()[0] as L.LatLngLiteral[]);
            return area / 1000000; // divided by 1000000 to ensure it is in km2.
        }
    }

    // extracted and modified from https://github.com/Leaflet/Leaflet.draw/blob/develop/src/ext/GeometryUtil.js
    private geodesicArea(latLngs: L.LatLngLiteral[]): number {
        let pointsCount = latLngs.length;
        let area = 0.0;
        let d2r = Math.PI / 180;
        let p1: L.LatLngLiteral;
        let p2: L.LatLngLiteral;

        if (pointsCount > 2) {
            for (let i = 0; i < pointsCount; i++) {
                p1 = latLngs[i];
                p2 = latLngs[(i + 1) % pointsCount];
                area += (p2.lng - p1.lng) * d2r * (2 + Math.sin(p1.lat * d2r) + Math.sin(p2.lat * d2r));
            }
            area = (area * 6378137.0 * 6378137.0) / 2.0;
        }
        return Math.abs(area);
    }
}
