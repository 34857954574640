import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';
import { UserSettingsStreamService } from 'src/app/shared/user/user-settings-stream.service';

import { GeometryTypes } from '../utility/geometry-utils';
import { LayersStore } from './layers-store.service';

@Injectable({
    providedIn: 'root'
})
export class LayersStreams {
    // the list of all layers in the current workspace
    currentMapWorkspaceLayers: Layer[] = [];
    // the list of all layers in the current workspace, sorted by
    mapWorkspaceLayersStream: Observable<Layer[]>;

    // the list of currently visible layers on the map
    visibleLayersStream = new BehaviorSubject<Layer[]>([]);
    // a list of layers that require their content to become visible on the map
    deferVisibleLayersStream = new BehaviorSubject<Layer[]>([]);
    // a list of layers that require their content to become hidden from the map
    deferHideLayersStream = new BehaviorSubject<Layer[]>([]);

    // a layer whose content is to be loaded (loading ==  true) or has just loaded (loading == false)
    pendingLoadingLayerStream = new BehaviorSubject<{ layer: Layer; loading: boolean }>(null);
    // a list of layers whose content is currently loading on the map
    loadingLayersStream: Observable<Layer[]>;

    currentEditLayerStream = new BehaviorSubject<Layer>(null);

    constructor(private layersStore: LayersStore, private userSettingsStream: UserSettingsStreamService) {
        // --------------
        // Map Workspace layers streams

        this.mapWorkspaceLayersStream = this.layersStore.mapWorkspaceLayersStream.pipe(shareReplay(1));

        this.mapWorkspaceLayersStream.subscribe(mapWorkspaceLayers => {
            this.currentMapWorkspaceLayers = mapWorkspaceLayers;
        });

        this.layersStore.refreshMapWorkspaceLayersStreams.subscribe(() => {
            // Refresh the visible layer also
            let visibleLayers = layersStore.mapWorkspaceLayersStream.getValue().filter(layer => layer.visible);

            this.visibleLayersStream.next(visibleLayers);
        });

        let loadingLayers: Layer[] = [];
        this.loadingLayersStream = combineLatest([this.visibleLayersStream, this.pendingLoadingLayerStream]).pipe(
            map(([visibleLayers, pendingLoadingLayer]) => {
                let loadingLayerId =
                    pendingLoadingLayer && pendingLoadingLayer.loading ? pendingLoadingLayer.layer.id : undefined;
                let loadedLayerId =
                    pendingLoadingLayer && !pendingLoadingLayer.loading ? pendingLoadingLayer.layer.id : undefined;

                let loadingLayersIds = loadingLayers.map(layer => layer.id);

                loadingLayers = visibleLayers.filter(
                    layer =>
                        (loadingLayerId && layer.id === loadingLayerId) ||
                        ((!loadedLayerId || layer.id !== loadedLayerId) && loadingLayersIds.indexOf(layer.id) >= 0)
                );

                return loadingLayers;
            })
        );
    }

    setVisibleLayers(layers: Layer[], updateUserSetting: boolean): void {
        let visibleLayersIds = layers.map(layer => layer.id);

        if (updateUserSetting) {
            const currentMapWorkspaceSettings = this.userSettingsStream.getCurrentMapWorkspaceSettings();
            if (currentMapWorkspaceSettings) {
                currentMapWorkspaceSettings.visibleLayerIds = visibleLayersIds;
                this.userSettingsStream.updateCurrentWorkspaceSettings(currentMapWorkspaceSettings);
            }
        }
        this.visibleLayersStream.next(layers);
    }

    setLayerAsLoading(layer: Layer): void {
        this.pendingLoadingLayerStream.next({
            layer,
            loading: true
        });
    }

    setLayerAsLoaded(layer: Layer): void {
        this.pendingLoadingLayerStream.next({
            layer,
            loading: false
        });
    }

    // layer sorting
    sortLayers(layers: Layer[]): Layer[] {
        return layers && layers.length ? layers.sort((l1, l2) => this.compareLayers(l1, l2)) : [];
    }

    // Sorts layers as follows:
    // - Geometry type: Non-specified, Point, Line, then area
    // - Alphabetically by title
    // - Layer id
    compareLayers(l1: Layer, l2: Layer): number {
        let l1GeometryType = this.getLayerGeometryType(l1);
        let l2GeometryType = this.getLayerGeometryType(l2);
        if (l1GeometryType < l2GeometryType) {
            return -1;
        } else if (l1GeometryType > l2GeometryType) {
            return 1;
        } else {
            let result = l1.layerName.localeCompare(l2.layerName);
            return result !== 0 ? result : l1.id < l2.id ? -1 : l1.id > l2.id ? 1 : 0;
        }
    }

    getLayerGeometryType(layer: Layer): number {
        const layerGeometryTypeOrder = [
            GeometryTypes.NONE,
            GeometryTypes.POINT,
            GeometryTypes.LINE,
            GeometryTypes.AREA
        ];

        if (layer.geometryType) {
            return layerGeometryTypeOrder.indexOf(layer.geometryType);
        }
        return -1;
    }
}
