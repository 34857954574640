import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Application } from 'src/app/shared/map-data-services/application';
import { Field } from 'src/app/shared/template-services/field';

import { TemplateEditorTab } from '../../tabs-list/current-tab-stream.service';
import { TemplateRouteData } from '../../template-editor-route-resolver.service';
import { FormFieldBaseComponent } from '../form-field-base.component';

@Component({
    selector: 'field-group',
    templateUrl: './field-group.component.html'
})
export class FieldGroupComponent extends FormFieldBaseComponent implements OnInit {
    @Input()
    parentIndex: number;

    @Input()
    application: Application;

    @Input()
    currentProjectDetails: TemplateRouteData;

    @Input()
    selectedField: Field;

    @Input()
    templateEditorConfig: { tabs: { fields: { fieldsGroup: any[]; enableFieldConditions: { [key: string]: any } } } };

    @Input()
    currentTab: TemplateEditorTab;

    @Output()
    fieldSelected = new EventEmitter<Field>();

    @ViewChild('groupField', { static: true })
    groupField: NgForm;

    ngOnInit(): void {
        this.form = this.groupField;
    }
}
