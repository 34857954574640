export class ArrayUtils {
    static isArray(arr: any): boolean {
        return Array.isArray(arr);
    }

    // Miscellaneous array utilities

    static groupBy(arr: any[], groupBy: string): { [key: string]: any } {
        const newArr: { [key: string]: any } = {};
        arr.forEach(element => {
            const newKey = element.groupBy || element[groupBy];
            newArr[newKey] = newArr[newKey] ? newArr[newKey] : [];
            newArr[newKey].push(element);
        });
        return newArr;
    }

    static keyBy(arr: any[], keyBy: string): { [key: string]: any } {
        const newArr: { [key: string]: any } = {};
        arr.forEach(element => {
            const newKey = element.keyBy || element[keyBy];
            newArr[newKey] = element;
        });
        return newArr;
    }

    static removeItem(arr: any, key: string): any {
        delete arr[key];
        return arr;
    }

    static getKeyValues(arr: any[], key: string): any[] {
        return arr.map(element => element[key]);
    }
}
