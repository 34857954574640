import { Component, Input } from '@angular/core';

export interface ICircle {
    x: number;
    y: number;
    radius: number;
}

export interface IRectangle {
    x: number;
    y: number;
    width: number;
    height: number;
}
@Component({
    selector: 'donut',
    templateUrl: './donut.component.html'
})
export class DonutComponent {
    @Input()
    size = 100;

    @Input()
    index = '';

    @Input()
    emptyColor = '#D0D0D7';

    @Input()
    okColor = '#5E9331';

    @Input()
    alertPercent = 70;

    @Input()
    alertColor = '#ffbe00';

    @Input()
    warningPercent = 80;

    @Input()
    warningColor = '#ff8b00';

    @Input()
    dangerPercent = 90;

    @Input()
    dangerColor = '#C81922';

    private _percent: number = null;
    public outerCircle: ICircle = { x: 0, y: 0, radius: 0 };
    public innerCircle: ICircle = { x: 0, y: 0, radius: 0 };
    public rectangle: IRectangle = { x: 0, y: 0, width: 0, height: 0 };

    public maskId = '';
    public mask = '';

    public pathColor = '';
    public pathToDraw = '';

    @Input()
    public set percent(percentage: number) {
        this._percent = percentage;
        // primary wedge
        let unit = (Math.PI * 2) / 100;
        let startangle = 0;

        let endangle = percentage * unit - 0.001;
        let x1 = this.size / 2 + (this.size / 2) * Math.sin(startangle);
        let y1 = this.size / 2 - (this.size / 2) * Math.cos(startangle);
        let x2 = this.size / 2 + (this.size / 2) * Math.sin(endangle);
        let y2 = this.size / 2 - (this.size / 2) * Math.cos(endangle);
        let big = 0;
        if (endangle - startangle > Math.PI) {
            big = 1;
        }
        let d =
            'M ' +
            this.size / 2 +
            ',' +
            this.size / 2 + // Start at circle center
            ' L ' +
            x1 +
            ',' +
            y1 + // Draw line to (x1,y1)
            ' A ' +
            this.size / 2 +
            ',' +
            this.size / 2 + // Draw an arc of radius r
            ' 0 ' +
            big +
            ' 1 ' + // Arc details...
            x2 +
            ',' +
            y2 + // Arc goes to to (x2,y2)
            ' Z'; // Close path back to (cx,cy)

        this.pathToDraw = d;

        let fillColor = this.okColor;
        if (percentage > this.alertPercent) {
            fillColor = this.alertColor;
        }
        if (percentage > this.warningPercent) {
            fillColor = this.warningColor;
        }
        if (percentage > this.dangerPercent) {
            fillColor = this.dangerColor;
        }

        this.pathColor = fillColor;
        this.pieChart(this.size);
    }
    public get percent(): number {
        return this._percent;
    }

    private pieChart(size: number): void {
        this.outerCircle.x = size / 2;
        this.outerCircle.y = size / 2;
        this.outerCircle.radius = size / 2;

        this.innerCircle.x = size / 2;
        this.innerCircle.y = size / 2;
        this.innerCircle.radius = size * 0.36;

        // Background rectangle
        this.rectangle.x = 0;
        this.rectangle.y = 0;
        this.rectangle.width = size;
        this.rectangle.height = size;

        this.maskId = 'clipPath' + this.index;
        this.mask = 'url(#clipPath' + this.index + ')';
    }
}
