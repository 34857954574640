<div *ngIf="showActivityTab" class="activity-tab-container">
    <div class="activity-tab-scroll-container">
        <custom-scroll class="height-full">
            <div class="activity-tab-content">
                <div class="activity-tab-header">
                    <h2>{{ 'MapViewer.Activity.Header' | translate }}</h2>
                </div>
                <div class="activity-timeline" *ngFor="let activityTimeline of activityTimelines">
                    <div class="activity-timeline-header">
                        <span class="activity-timeline-label">
                            {{ activityTimeline.displayText | translate }}
                        </span>
                        <hr class="activity-timeline-spacer" />
                        <span
                            *ngIf="activityTimeline.showSelectAll"
                            class="activity-timeline-select-handle"
                            (click)="selectAllActivity(activityTimeline.activities)"
                        >
                            {{ 'TC.Common.SelectAll' | translate }}
                        </span>
                    </div>
                    <div
                        class="activity-item active"
                        *ngFor="let activity of activityTimeline.activities"
                        (click)="openFeaturesInFeaturePanel(activity.data.features, activity.type)"
                    >
                        <div class="activity-wrapper" *ngIf="activity.type === GspActivityType.POST_PROCESSING_QUEUED">
                            <i class="activity-icon i24 icon-time"></i>
                            <span class="activity-description">
                                {{
                                    'MapViewer.Activity.QueuedWithCount'
                                        | translate: { count: activity.data.features.length }
                                }}
                            </span>
                        </div>
                        <div
                            class="activity-wrapper"
                            *ngIf="activity.type === GspActivityType.POST_PROCESSING_COMPLETE"
                        >
                            <i class="activity-icon i24 icon-tick-in-circle"></i>
                            <span class="activity-description">
                                {{
                                    'MapViewer.Activity.ProcessedWithCount'
                                        | translate: { count: activity.data.features.length }
                                }}
                            </span>
                        </div>
                        <div class="activity-wrapper" *ngIf="activity.type === GspActivityType.POST_PROCESSING_FAILED">
                            <i class="activity-icon i24 icon-alert-circle-solid"></i>
                            <span class="activity-description">
                                {{
                                    'MapViewer.Activity.FailedWithCount'
                                        | translate: { count: activity.data.features.length }
                                }}
                            </span>
                        </div>
                        <div class="activity-wrapper" *ngIf="activity.type === GspActivityType.FEATURES_SYNCED">
                            <i class="activity-icon i24 icon-upload-solid"></i>
                            <span class="activity-description" *ngIf="!activity.data.multiple">
                                {{
                                    'MapViewer.Activity.UploadedWithCount'
                                        | translate
                                            : { userName: activity.data.userName, count: activity.data.features.length }
                                }}
                            </span>
                            <span class="activity-description" *ngIf="activity.data.multiple">
                                {{
                                    'MapViewer.Activity.UploadedWithCountMultiple'
                                        | translate
                                            : {
                                                  userName: activity.data.userName,
                                                  userCount: activity.data.userCount - 1,
                                                  count: activity.data.features.length
                                              }
                                }}
                            </span>
                        </div>
                    </div>
                    <div class="activity-item" *ngFor="let activity of activityTimeline.activities">
                        <div class="activity-wrapper" *ngIf="activity.type === GspActivityType.TASK_COMPLETED">
                            <i class="activity-icon i24 icon-tick-in-circle"></i>
                            <span class="activity-description">
                                {{
                                    'MapViewer.Activity.TaskCompleted'
                                        | translate
                                            : { userName: activity.data.userName, taskName: activity.data.taskName }
                                }}
                            </span>
                        </div>
                    </div>
                    <div class="activity-item" *ngIf="!activityTimeline.activities.length">
                        <div class="activity-wrapper">
                            <span class="activity-description">
                                {{ 'MapViewer.Activity.Empty' | translate }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="currentPendingTimelines.length || loading" class="activity-loader-wrapper">
                <hr />
                <div class="activity-loader" (click)="loadTimelineActivities()">
                    <div *ngIf="!loading" class="activity-loader-icon">
                        <i class="i16 icon-rotate-left-alt-white"></i>
                    </div>
                    <span *ngIf="!loading" class="activity-loader-description">
                        {{ 'MapViewer.Activity.LoadLabel' | translate }}
                    </span>
                    <div *ngIf="loading" class="activity-tab-loading-icon loading"></div>
                </div>
            </div>
        </custom-scroll>
    </div>
</div>
