import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChartData, ChartOptions, ChartType, Plugin } from 'chart.js';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subject, combineLatest, takeUntil, timestamp } from 'rxjs';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { ActiveFeatureStreamsService } from 'src/app/shared/common/current-features/active-feature-streams.service';
import { FeaturesStreamsService } from 'src/app/shared/common/current-features/features-streams.service';
import { TemplatesStoreService } from 'src/app/shared/common/current-templates/templates-store.service';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';
import { Feature } from 'src/app/shared/map-data-services/feature/feature';
import { Field, FieldType, LayoutFieldType } from 'src/app/shared/template-services/field';
import { FieldService } from 'src/app/shared/template-services/field/field.service';

import { ChartClickEvent } from 'src/app/shared/common/components/gsp-chart/gsp-chart';
import { GeneralUtils } from 'src/app/shared/common/utility/general-utils';
import { ChartPanelStreamService } from '../../common/chart-panel-stream.service';
import { CodedChoiceOption, FeatureField, GroupFeatureField } from '../feature-panel/feature-fields/feature-field';
import { TemplatedFeature } from '../feature-panel/feature-fields/templated-feature';

@Component({
    selector: 'field-summary-panel',
    templateUrl: './field-summary-panel.component.html'
})
export class FieldSummaryPanelComponent implements OnInit, OnDestroy {
    field: Field;
    translatedFieldType: string;
    selectedFeaturesCount: number;

    isSummaryPanelDisplayed$: Observable<boolean>;
    selectedFeatures$: BehaviorSubject<Feature[]>;

    activeFeature: Feature;
    previousSelectedFeatures: Feature[];
    activeFeatureChoiceSelections: string[];

    currentLabel: string;
    previousTimestamp: number;

    private readonly destroyed = new Subject<void>();
    updateChartStream = new Subject<void>();
    featureFieldValuesDict: { [featureId: string]: FeatureField } = {};
    chartSelectionActive = false;

    chartType: ChartType;

    chartData: ChartData<'pie'> = {
        datasets: [
            {
                data: [],
                hoverBackgroundColor: '#005f9e'
            }
        ]
    };

    chartOptions: ChartOptions<'pie'> = {
        layout: {
            padding: {
                top: 40,
                right: 20,
                bottom: 20,
                left: 20
            }
        },
        plugins: {
            legend: { display: false },
            datalabels: { display: false },
            tooltip: { enabled: true }
        },
        onHover: (event, chartElement) => {
            (event.native.target as HTMLElement).style.cursor = chartElement[0] ? 'pointer' : 'default';
        }
    };

    plugins: Plugin[] = [
        {
            id: 'sliceThicknessPlugin',
            beforeDraw: chart => {
                chart.getDatasetMeta(0).data.forEach((slice: any, index: number) => {
                    const label = chart.data.labels[index] as string;

                    if (this.activeFeatureChoiceSelections.flat().includes(label)) {
                        slice.outerRadius = 110;
                    }
                });
            }
        }
    ];

    constructor(
        private chartPanelStreamService: ChartPanelStreamService,
        private featuresStreamsService: FeaturesStreamsService,
        private activeFeatureStreamsService: ActiveFeatureStreamsService,
        private templatesStore: TemplatesStoreService,
        private fieldService: FieldService,
        private translate: TranslationService
    ) {
        this.isSummaryPanelDisplayed$ = this.chartPanelStreamService.isFieldSummaryPanelDisplayedStream;
        this.selectedFeatures$ = this.featuresStreamsService.selectedFeaturesStream;
    }

    async ngOnInit() {
        let previousLayerId: string;
        let previousSelectedField: Field;
        let previousTemplateVersion: string;

        combineLatest([
            this.chartPanelStreamService.fieldSummaryPanelSelectedField,
            this.activeFeatureStreamsService.activeFeatureStream,
            this.selectedFeatures$.pipe(timestamp())
        ])
            .pipe(takeUntil(this.destroyed))
            .subscribe(async ([selectedField, activeFeature, selectedFeaturesTimestamped]) => {
                // Close panel when:
                // A - While chartSelection active, new feature selection does not have activeFeature
                // B - Switching to feature in different layer
                // C - No feature selected
                const selectedFeatures = selectedFeaturesTimestamped.value;
                const selectedFeaturesTimestamp = selectedFeaturesTimestamped.timestamp;
                if (
                    (this.chartSelectionActive &&
                        !selectedFeatures.find(feature => feature.id === activeFeature?.id)) ||
                    activeFeature?.layerId !== previousLayerId ||
                    !selectedFeatures.length
                ) {
                    this.chartPanelStreamService.isSummaryPanelDisplayed = false;
                    this.chartPanelStreamService.toggleSummaryPanel(selectedField?.uuid, true);
                }

                // Remove chart selection when switching to different field, closing chart panel or changing selection
                if (
                    !selectedFeatures.length ||
                    selectedField?.id !== previousSelectedField?.id ||
                    (this.previousSelectedFeatures &&
                        !_.isEqual(this.previousSelectedFeatures, selectedFeatures) &&
                        selectedFeaturesTimestamp !== this.previousTimestamp)
                ) {
                    this.previousSelectedFeatures = null;
                }

                this.previousTimestamp = selectedFeaturesTimestamp;
                previousLayerId = activeFeature ? activeFeature.layerId : null;

                if (selectedField && activeFeature) {
                    this.field = selectedField;
                    previousSelectedField = selectedField;
                    this.activeFeature = activeFeature;

                    if (previousTemplateVersion !== activeFeature.collectedTemplateId) {
                        // If field name was edited in a newer TemplateVersion
                        const templatedFeature = await this.getTemplatedFeature(activeFeature);
                        templatedFeature.fields.forEach(field => {
                            if (field.uuid === selectedField.uuid) {
                                this.field.displayName = field.displayName;
                            }
                        });
                    }
                    previousTemplateVersion = activeFeature.collectedTemplateId;

                    this.translatedFieldType = this.translate.instant(
                        this.fieldService.translateToTCString[`default_label.fields.${this.field.type.toLowerCase()}`]
                    );

                    await this.prepareFieldData(selectedFeatures);
                }

                this.updateChartStream.next(null);
            });
    }

    private async prepareFieldData(selectedFeatures: Feature[]) {
        const selectedFeaturesInSameLayer = selectedFeatures.filter(
            feature =>
                feature.layerId === this.activeFeature.layerId &&
                feature.templateSeriesId === this.activeFeature.templateSeriesId
        );
        this.selectedFeaturesCount = selectedFeaturesInSameLayer.length;

        await this.setupFieldValues(selectedFeaturesInSameLayer);

        switch (this.field.type) {
            // TODO TCMAPS-3911: Add support for other field types
            case FieldType.Choice:
            case FieldType.CodedChoice: {
                this.chartType = 'pie';

                let chartLabels: string[];
                if (this.field.type === FieldType.Choice) {
                    chartLabels = CloneUtils.cloneDeep(this.field.options) as string[];
                } else if (this.field.type === FieldType.CodedChoice) {
                    chartLabels = (this.field.options as CodedChoiceOption[]).map(option => option.description);
                }

                let allSelections: string[] = Object.values(this.featureFieldValuesDict)
                    .map(field => (field.value && field.value.length > 0 ? field.value : 'noselection'))
                    .flat();
                let labelCounts: { [key: string]: number } = {};

                this.activeFeatureChoiceSelections = Object.entries(this.featureFieldValuesDict)
                    .filter(([featureId, field]) => featureId === this.activeFeature.id)
                    .map(([featureId, field]) => field.value);

                allSelections.forEach(selection => {
                    if (
                        (this.field.options as string[]).includes(selection) ||
                        (this.field.options as CodedChoiceOption[]).some(option => option.description === selection)
                    ) {
                        labelCounts[selection] = (labelCounts[selection] || 0) + 1;
                    } else if (selection === 'noselection') {
                        const noSelectionLabel = 'No Selection';
                        if (!chartLabels.includes(noSelectionLabel)) {
                            chartLabels.push(noSelectionLabel);
                        }
                        labelCounts[noSelectionLabel] = (labelCounts[noSelectionLabel] || 0) + 1;
                    } else if (this.field.type === FieldType.Choice) {
                        const otherLabel = 'Other';
                        if (!chartLabels.includes(otherLabel)) {
                            chartLabels.push(otherLabel);
                        }
                        labelCounts[otherLabel] = (labelCounts[otherLabel] || 0) + 1;
                    }
                });

                const filteredLabels = chartLabels.filter(label => labelCounts[label] > 0);
                this.chartData.datasets[0].data = filteredLabels.map(label => labelCounts[label]);
                this.chartData.labels = filteredLabels.map(label => {
                    if (label === 'Other') {
                        return this.translate.instant('TC.Common.Others');
                    } else if (label === 'No Selection') {
                        return this.translate.instant('TC.Common.NoSelection');
                    }
                    return label;
                });

                break;
            }
        }
    }

    private async setupFieldValues(selectedFeaturesInSameLayer: Feature[]) {
        this.featureFieldValuesDict = {};

        await Promise.all(
            selectedFeaturesInSameLayer.map(async feature => {
                const templatedFeature = await this.getTemplatedFeature(feature);

                let matchingField: FeatureField = null;

                templatedFeature.fields.some(field => {
                    if (field.type === LayoutFieldType.Group) {
                        const innerField = (field as GroupFeatureField).fields.find(
                            inner => inner.name === this.field.name
                        );
                        if (innerField) {
                            matchingField = innerField;
                        }
                    } else if (field.name === this.field.name) {
                        matchingField = field;
                    }
                });
                if (matchingField) {
                    if (this.field.type === FieldType.CodedChoice) {
                        // Change value from code to description
                        matchingField.value =
                            (matchingField.options as CodedChoiceOption[]).find(
                                option => option.code === matchingField.value
                            )?.description ?? matchingField.value;
                    }
                    this.featureFieldValuesDict[feature.id] = matchingField;
                }
            })
        );
    }

    private async getTemplatedFeature(feature: Feature): Promise<TemplatedFeature> {
        const template = await this.templatesStore.getTemplateById(feature.collectedTemplateId);
        return new TemplatedFeature(feature, template);
    }

    public onChartClick(data: ChartClickEvent): void {
        const index: number = data.active[0]?.index;
        const choiceSelection = this.chartData.labels[index];

        this.currentLabel = choiceSelection as string;

        if (GeneralUtils.isNullUndefinedOrNaN(index)) {
            return;
        }
        const previousFeatures = this.selectedFeatures$.getValue();

        const newFeatures = previousFeatures.filter(feature => {
            const currentFeatureFields = this.featureFieldValuesDict[feature.id];
            if (currentFeatureFields) {
                return (
                    currentFeatureFields.value?.includes(choiceSelection) ||
                    (choiceSelection === 'Others' &&
                        currentFeatureFields.value?.some((value: string) => !this.chartData.labels.includes(value))) ||
                    (choiceSelection === 'No Selection' && !currentFeatureFields.value?.length)
                );
            }
        });

        if (newFeatures.length !== previousFeatures.length) {
            // Set activeFeature before selectedFeatures change, so that the exit condition "new featureSelection does not have activeFeature" is not met;
            // This happens when value is edited to a value that is not a part of currentSelection.
            this.featuresStreamsService.setActiveFeature(CloneUtils.cloneDeep(newFeatures[0]));
            this.featuresStreamsService.replaceOnSelectedFeatures(newFeatures);

            setTimeout(() => {
                this.previousSelectedFeatures = previousFeatures;
                this.chartSelectionActive = true;
            });
        }
    }

    public goBack() {
        this.chartSelectionActive = false;
        this.featuresStreamsService.replaceOnSelectedFeatures(this.previousSelectedFeatures);
        this.previousSelectedFeatures = null;
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }
}
