import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { EnvironmentService } from 'src/app/shared/common/environment/environment.service';
import {
    CoordinateSystemComponent,
    CoordinateSystemComponentType,
} from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { CoordinateSystemComponentDTO } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace.types';
import { GeoLink } from 'src/app/shared/types/geo';

import { Ntrip } from './Ntrip';
import { GeoRealtimeCorrection, GeoRealtimeCorrections, Rtc, RtcDatum } from './Rtc';
import { RtcSourceTypes } from './rtc-options';

@Injectable({
    providedIn: 'root'
})
export class RtcService {
    constructor(private http: HttpClient, private env: EnvironmentService) {}

    public async createRtc(projectId: string, id: string, data: Rtc): Promise<Rtc> {
        let rtcPath = this.env.apiUrl + '/projects/' + projectId + '/realtimecorrections';
        const response = await lastValueFrom(this.http.post<GeoRealtimeCorrection>(rtcPath, data.toDto()));
        return Rtc.fromDTO(response);
    }

    public async getRtcsPaged(
        projectId: string,
        workspaceId: string,
        startIndex: number,
        pageSize: number
    ): Promise<{ items: Rtc[]; links?: GeoLink[]; total?: number; eTag?: string }> {
        startIndex = startIndex || 0;
        let endIndex = '';
        if (pageSize) {
            endIndex = String(startIndex + (pageSize - 1));
        }
        let headers = {
            headers: {
                Range: 'items=' + startIndex + '-' + endIndex
            }
        };
        let rtcPath = this.env.apiUrl + '/projects/' + projectId + '/realtimecorrections?sortBy=Name';
        if (workspaceId) {
            rtcPath += '&request.workspaceId=' + workspaceId;
        }
        const response = await lastValueFrom(this.http.get<GeoRealtimeCorrections>(rtcPath, headers));
        return { ...response, items: response.items.map(item => Rtc.fromDTO(item)) };
    }

    public async updateRtc(projectId: string, id: string, rtc: Rtc): Promise<Rtc> {
        let rtcPath = this.env.apiUrl + '/projects/' + projectId + '/realtimecorrections/' + id;
        let data = rtc.toDto();
        if (data.realtimeCorrection.sourceType1 === RtcSourceTypes.SBAS) {
            delete data.realtimeCorrection.sourceType2IsSbas;
        }
        const response = await lastValueFrom(this.http.put<GeoRealtimeCorrection>(rtcPath, data));
        return Rtc.fromDTO(response);
    }

    public async deleteRtc(projectId: string, id: string): Promise<void> {
        let rtcPath = this.env.apiUrl + '/projects/' + projectId + '/realtimecorrections/' + id;
        await lastValueFrom(this.http.delete(rtcPath));
    }

    public async getRtc(projectId: string, id: string): Promise<Rtc> {
        let rtcPath = this.env.apiUrl + '/projects/' + projectId + '/realtimecorrections/' + id;
        const response = await lastValueFrom(this.http.get<GeoRealtimeCorrection>(rtcPath));
        return Rtc.fromDTO(response);
    }

    public async getDatumBySystemId(id: number): Promise<RtcDatum> {
        let path = this.env.coordinatesApiUrl + '/coordinatesystems/datums/' + id;
        const response = await lastValueFrom(this.http.get(path));
        return response as RtcDatum;
    }

    public async getDatumByComponentId(id: string): Promise<CoordinateSystemComponent> {
        let path = this.env.coordinatesApiUrl + '/coordinatesystemcomponents/' + id;
        const response = await lastValueFrom(
            this.http.get(path, { params: { componentType: CoordinateSystemComponentType.DATUM } })
        );
        return CoordinateSystemComponent.fromDTO(response);
    }

    public async getDatums(): Promise<RtcDatum[]> {
        let rtcPath = this.env.apiUrl + '/realtimecorrections/datums';
        const response = await lastValueFrom(this.http.get(rtcPath));
        return response as RtcDatum[];
    }

    public async getDatumComponents(): Promise<CoordinateSystemComponent[]> {
        const datumListPath = this.env.coordinatesApiUrl + '/coordinatesystemcomponents';

        const response = await lastValueFrom(
            this.http.get<CoordinateSystemComponentDTO[]>(datumListPath, {
                params: { componentType: CoordinateSystemComponentType.DATUM, componentStatus: 'Released' }
            })
        );
        return response.map(item => CoordinateSystemComponent.fromDTO(item));
    }

    public async getNtripSettings(internetUrl: string, internetPort: number): Promise<Ntrip[]> {
        let rtcPath = this.env.apiUrl + '/realtimecorrections/ntripsettings';
        const response = await lastValueFrom(
            this.http.get<{ items: Ntrip[] }>(rtcPath, {
                params: { internetUrl: internetUrl, internetPort: internetPort.toString() }
            })
        );
        return response.items as Ntrip[];
    }

    public async getComponentByAliasId(id: string): Promise<CoordinateSystemComponent> {
        const path = `${this.env.coordinatesApiUrl}/coordinatesystemcomponents/aliases/${id}`;
        const dto = await lastValueFrom(this.http.get<CoordinateSystemComponentDTO>(path));
        return CoordinateSystemComponent.fromDTO(dto);
    }

    public isIncompatible(datumId: string): boolean {
        // define here if you want to hide specific datum
        const datumIdList = ['Trimble:5004', 'Trimble:235', 'Trimble:234'];

        return datumIdList.includes(datumId);
    }
}
