/*

 Handles collision detection between convex and non-convex polygons
 and point vectors contained within a polygon

 Derived from http://jsfiddle.net/ARTsinn/dKLsb/
 Removed redundant code and wrapped as an Angular service

 */
// var PI2 = Math.PI * 2;

const max = Math.max;
const min = Math.min;
const sqrt = Math.sqrt;
const sin = Math.sin;
const cos = Math.cos;

export class Vector {
    // ---------------------
    // Vector (= Point)
    // ---------------------
    x: number;
    y: number;

    constructor(x: number = 0.0, y: number = 0.0) {
        this.set(x, y);
    }

    set(x: number, y: number): Vector {
        this.x = x || 0.0;
        this.y = y || 0.0;
        return this;
    }

    add(vector: Vector): Vector {
        this.x += vector.x;
        this.y += vector.y;
        return this;
    }

    scale(scalar: number): Vector {
        this.x *= scalar;
        this.y *= scalar;
        return this;
    }

    div(scalar: number): Vector {
        this.x /= scalar;
        this.y /= scalar;
        return this;
    }

    dot(vector: Vector): number {
        return this.x * vector.x + this.y * vector.y;
    }

    min(vector: Vector): number {
        this.x = min(this.x, vector.x);
        this.y = min(this.y, vector.y);
        return this.y;
    }

    max(vector: Vector): number {
        this.x = max(this.x, vector.x);
        this.y = max(this.y, vector.y);
        return this.y;
    }

    lt(vector: Vector): boolean {
        return this.x < vector.x || this.y < vector.y;
    }

    gt(vector: Vector): boolean {
        return this.x > vector.x || this.y > vector.y;
    }

    normalize(): void {
        const mag = sqrt(this.x * this.x + this.y * this.y);
        if (mag !== 0) {
            this.x /= mag;
            this.y /= mag;
        }
    }

    clone(): Vector {
        return new Vector(this.x, this.y);
    }
}

export class Edge {
    // ---------------------
    // Edge
    // ---------------------
    pointA: Vector;
    pointB: Vector;

    constructor(pointA: Vector, pointB: Vector) {
        this.pointA = pointA;
        this.pointB = pointB;
    }

    intersects(other: Edge, ray: boolean, excludeEndPoint: boolean): Vector | boolean {
        const dy1 = this.pointB.y - this.pointA.y;
        const dx1 = this.pointB.x - this.pointA.x;
        const dx2 = this.pointA.x - other.pointA.x;
        const dy2 = this.pointA.y - other.pointA.y;
        const dx3 = other.pointB.x - other.pointA.x;
        const dy3 = other.pointB.y - other.pointA.y;

        if (dy1 / dx1 !== dy3 / dx3) {
            // ray and edge not parallel
            const d = dx1 * dy3 - dy1 * dx3;
            if (d !== 0) {
                const r = (dy2 * dx3 - dx2 * dy3) / d;
                const s = (dy2 * dx1 - dx2 * dy1) / d;
                if (r >= 0 && (ray || r <= 1)) {
                    if (s >= 0 && (excludeEndPoint ? s < 1 : s <= 1)) {
                        return new Vector(this.pointA.x + r * dx1, this.pointA.y + r * dy1);
                    }
                }
            }
        }
        return false;
    }
}

// ---------------------
// Polygon
// ---------------------
export class Polygon {
    colliding = false;
    center = new Vector();
    bounds = {
        min: new Vector(),
        max: new Vector()
    };

    constructor(public vertices: any[] = [], public edges: any[] = []) {
        if (this.vertices.length) {
            this.computeCenter();
            this.computeBounds();
            this.computeEdges();
        }
    }

    translate(vector: Vector): number[] {
        const ref = this.vertices;
        const results: number[] = [];

        this.center.add(vector);
        this.bounds.min.add(vector);
        this.bounds.max.add(vector);

        for (let i = 0, len = ref.length; i < len; i++) {
            const vertex = ref[i];
            results.push(vertex.add(vector));
        }
        return results;
    }

    rotate(radians: number, pivot: Vector): number[] {
        const s = sin(radians);
        const c = cos(radians);
        const ref = this.vertices;
        const results: number[] = [];
        if (!pivot) {
            pivot = this.center;
        }

        for (let i = 0, len = ref.length; i < len; i++) {
            const vertex = ref[i];
            const dx = vertex.x - pivot.x;
            const dy = vertex.y - pivot.y;
            vertex.x = c * dx - s * dy + pivot.x;
            results.push((vertex.y = s * dx + c * dy + pivot.y));
        }
        return results;
    }

    computeCenter(): Vector {
        const ref = this.vertices;
        this.center.set(0, 0);

        for (let i = 0, len = ref.length; i < len; i++) {
            const vertex = ref[i];
            this.center.add(vertex);
        }
        return this.center.div(this.vertices.length);
    }

    computeBounds(): number[] {
        const ref = this.vertices;
        const results: number[] = [];
        this.bounds.min.set(Number.MAX_VALUE, Number.MAX_VALUE);
        this.bounds.max.set(-Number.MAX_VALUE, -Number.MAX_VALUE);

        for (let i = 0, len = ref.length; i < len; i++) {
            const vertex = ref[i];
            this.bounds.min.min(vertex);
            results.push(this.bounds.max.max(vertex));
        }
        return results;
    }

    computeEdges(): any[] {
        const ref = this.vertices;
        const results: any[] = [];
        this.edges.length = 0;

        for (let i = 0, len = ref.length; i < len; i++) {
            const vertex = ref[i];
            results.push(this.edges.push(new Edge(vertex, this.vertices[(i + 1) % this.vertices.length])));
        }
        return results;
    }

    contains(vector: Vector): boolean {
        if (vector.x > this.bounds.max.x || vector.x < this.bounds.min.x) {
            return false;
        }
        if (vector.y > this.bounds.max.y || vector.y < this.bounds.min.y) {
            return false;
        }

        const minX = (o: Vector) => o.x;
        const minY = (o: Vector) => o.y;

        const outside = new Vector(
            min.apply(Math, this.vertices.map(minX)) - 1,
            min.apply(Math, this.vertices.map(minY)) - 1
        );
        const ray = new Edge(vector, outside);
        let intersections = 0;
        const ref = this.edges;

        for (let i = 0, len = ref.length; i < len; i++) {
            const edge = ref[i];
            if (ray.intersects(edge, true, true /* exclude endpoint so only one edge intersection is counted */)) {
                ++intersections;
            }
        }
        return intersections % 2 !== 0;
    }

    collides(polygon: Polygon): boolean {
        const ref = this.edges;

        if (polygon.bounds.min.gt(this.bounds.max)) {
            return false;
        }
        if (polygon.bounds.max.lt(this.bounds.min)) {
            return false;
        }

        for (let i = 0, len = ref.length; i < len; i++) {
            const edge = ref[i];
            const ref2 = polygon.edges;
            for (let j = 0, len2 = ref2.length; j < len2; j++) {
                const other = ref2[j];
                if (edge.intersects(other)) {
                    return true;
                }
            }
        }
        return false;
    }

    wrap(bounds: { min: Vector; max: Vector }): number[] {
        const ox = this.bounds.max.x - this.bounds.min.x + (bounds.max.x - bounds.min.x);
        const oy = this.bounds.max.y - this.bounds.min.y + (bounds.max.y - bounds.min.y);

        if (this.bounds.max.x < bounds.min.x) {
            this.translate(new Vector(ox, 0));
        } else if (this.bounds.min.x > bounds.max.x) {
            this.translate(new Vector(-ox, 0));
        }

        if (this.bounds.max.y < bounds.min.y) {
            return this.translate(new Vector(0, oy));
        } else if (this.bounds.min.y > bounds.max.y) {
            return this.translate(new Vector(0, -oy));
        }
    }
}
