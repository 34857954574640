import { Component, Input, ViewChildren } from '@angular/core';
import { GspNumberType } from 'src/app/shared/common/components/gsp-number/gsp-number.component';
import { Field } from 'src/app/shared/template-services/field';
import { ConditionDisplayInfo, ConditionOperator, NameValue } from 'src/app/shared/template-services/rule/condition';
import { NumberCondition } from 'src/app/shared/template-services/rule/number-condition';

@Component({
    selector: 'number-rule',
    templateUrl: './number-rule.component.html'
})
export class NumberRuleComponent {
    private _condition: NumberCondition;
    connectingOperators: NameValue[];
    conditionPair: ConditionDisplayInfo[];

    @ViewChildren('ruleInputElement')
    ruleInput: any;

    @Input()
    public get condition(): NumberCondition {
        return this._condition;
    }
    public set condition(v: NumberCondition) {
        this._condition = v;
        this.connectingOperators = this._condition.getConnectingOperators();
        this.conditionPair = this._condition.getConditionPair();
    }

    @Input()
    field: Field;

    // exposing enum to template
    public ConditionOperator = ConditionOperator;
    public GspNumberType = GspNumberType;

    constructor() {}

    setFocusOnInput(i: number): void {
        setTimeout(() => this.ruleInput['_results'][i].setFocus(), 0);
    }

    refreshConditionPair(): void {
        this.conditionPair = this._condition.getConditionPair();
    }
}
