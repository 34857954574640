<div id="zoom-tool">
    <gsp-button
        [type]="ButtonType.Icon"
        [disabled]="currentZoom >= map.options.maxZoom"
        (action)="zoomDelta(1)"
        iconClass="i32 icon_line_plus"
    ></gsp-button>
    <gsp-button
        [type]="ButtonType.Icon"
        [disabled]="currentZoom <= map.options.minZoom"
        (action)="zoomDelta(-1)"
        iconClass="i32 icon_line_minus"
    ></gsp-button>
</div>
