<div class="styles-panel">
    <div class="styles-section">
        <h4 translate="TC.Common.Links"></h4>
        <div *ngFor="let firstLevel of linkDetails" class="linked-layer">
            <geom-icon
                path="sym_32-layer-"
                [hidden]="!firstLevel.name"
                [color]="firstLevel.color"
                [geoType]="template.geometryType"
            ></geom-icon>
            <div class="layer-desc">
                <div class="layer-name">{{ firstLevel.name }}</div>
                <div *ngFor="let secondLevel of firstLevel.items" class="workspace-name">
                    <div>{{ secondLevel.name }}</div>
                </div>
            </div>
        </div>
    </div>
</div>
