<div class="feature-field">
    <label class="feature-label" [ngClass]="{ disabled: field.value == null }">
        {{ field.displayName }} <span *ngIf="field.required">*</span>
    </label>
    <div class="feature-data" *ngIf="!editMode">
        <span>{{ field.value }}</span>
        <span [hidden]="field.value == null">{{ displayUnitHtml }}</span>
    </div>
    <div class="editable-control" [hidden]="!editMode">
        <span class="error-message" *ngFor="let error of error_messages">
            {{ error | translate: { fieldLabel: field.displayName, maximum: field.maximum, minimum: field.minimum } }}
        </span>
        <gsp-number
            #numberInput="ngModel"
            [type]="GspNumberType.GENERIC"
            [inputNgClass]="{ 'input-error': this.field.hasError }"
            [(ngModel)]="field.value"
            decimalPlaces="{{ field.scale }}"
            minValue="{{ field.minimum }}"
            maxValue="{{ field.maximum }}"
        ></gsp-number>
    </div>
</div>
