import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from 'src/app/shared/common/components/value-accessor-base';
import { KeyCodes } from 'src/app/shared/common/key-codes';

@Component({
    selector: 'labeller',
    templateUrl: './labeller.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => LabellerComponent)
        }
    ]
})
export class LabellerComponent extends ValueAccessorBase<string[]> implements OnInit {
    @Input()
    maxLength: string;

    @ViewChild('labelInput', { static: true })
    inputEl: ElementRef;

    @Input() labelErrors: boolean[] = [];
    @Output() labelErrorsChange: EventEmitter<boolean[]> = new EventEmitter<boolean[]>();

    @Input() labelEmpty: boolean;
    @Output() labelEmptyChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    newLabel: string;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.inputEl.nativeElement.focus();
    }

    focusInput(): void {
        this.inputEl.nativeElement.focus();
        this.labelErrors = this.value.map(modelValue => this.validateDefaultValueMaxLength(this.maxLength, modelValue));
        this.labelErrorsChange.emit(this.labelErrors);
    }

    validateDefaultValueMaxLength(max: string, defaultText: string): boolean {
        if (defaultText?.length > Number(max)) {
            return true;
        }
        return false;
    }

    addLabel(event: KeyboardEvent): void {
        if ((this.newLabel === undefined || this.newLabel === '') && (this.value && this.value.length === 0)) {
            this.labelEmpty = true;
            this.labelEmptyChange.emit(this.labelEmpty);
        }

        if (event) {
            if (event.key === KeyCodes.ENTER && this.newLabel !== '' && this.newLabel !== undefined) {
                this.value.push(this.newLabel);
                this.signalChanged(); // manually do this because the value ref hasn't changed
                this.labelErrors[this.value.length - 1] = this.validateDefaultValueMaxLength(
                    this.maxLength,
                    this.newLabel
                );
                this.labelErrorsChange.emit(this.labelErrors);
                this.newLabel = '';
            }
            this.labelEmpty = false;
            this.labelEmptyChange.emit(this.labelEmpty);
        } else if (this.newLabel !== '' && this.newLabel !== undefined) {
            this.value.push(this.newLabel);
            this.labelErrors[this.value.length - 1] = this.validateDefaultValueMaxLength(this.maxLength, this.newLabel);
            this.labelErrorsChange.emit(this.labelErrors);
            this.newLabel = '';
            this.labelEmpty = false;
            this.labelEmptyChange.emit(this.labelEmpty);
        }
    }

    removeLabel(index: number): void {
        this.value.splice(index, 1);
        this.signalChanged(); // manually do this because the value ref hasn't changed
        this.labelErrors.splice(index, 1);
        this.focusInput();
    }
}
