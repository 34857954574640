import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppMaterialModule } from 'src/app/app-material.module';
import { TranslationModule } from 'src/app/core/translation/translation.module';
import { CommonModule as AppCommonModule } from 'src/app/shared/common/common.module';

import { ImportFilesFileviewerComponent } from './file-viewer/fileviewer-import-files.component';
import { ImportStatusFileViewerComponent } from './file-viewer/fileviewer-import-status.component';
import { FileViewerImportService } from './file-viewer/fileviewer-import.service';
import { ImportService } from './import.service';
import { ImportFilesMapViewerComponent } from './map-viewer/import-files.component';
import { ImportStatusMapViewerComponent } from './map-viewer/import-status/import-status.component';
import { UploadComponent } from './map-viewer/upload/upload.component';

@NgModule({
    declarations: [
        ImportFilesMapViewerComponent,
        UploadComponent,
        ImportStatusMapViewerComponent,
        ImportStatusFileViewerComponent,
        ImportFilesFileviewerComponent
    ],
    exports: [ImportFilesMapViewerComponent, ImportStatusFileViewerComponent, UploadComponent],
    imports: [CommonModule, AppCommonModule, FormsModule, AppMaterialModule, TranslationModule],
    providers: [ImportService, FileViewerImportService, TranslateService]
})
export class ImportModule {}
