import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, interval } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ACCESS_MODE_KEY, AccessMode, SHARED_MODE_KEY, ShareMode } from 'src/app/core/authentication/share-token';
import { FileViewerSettings } from 'src/app/core/init/load-fileviewer.component';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { CurrentUserStreamService } from 'src/app/shared/common/current-user/current-user-stream.service';
import { HttpStatusCodes } from 'src/app/shared/common/http-status-codes';
import { UtilitiesService } from 'src/app/shared/common/utility/utilities.service';
import {
    ConnectFileError,
    ConnectMapWorkspaceCreationError,
    MapWorkspace,
    ShareWorkspaceData
} from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import {
    MapWorkspacePermission,
    MapWorkspacePermissionType
} from 'src/app/shared/map-data-services/mapWorkspace/map-workspace-permission';
import { MapWorkspaceService } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace.service';
import { UserSettingsStreamService } from 'src/app/shared/user/user-settings-stream.service';

import {
    DuplicateWorkspaceDTO,
    GeoWorkspacePostProcessingBody
} from '../../map-data-services/mapWorkspace/map-workspace.types';
import { UserRole } from '../../user/user';
import { UserMapWorkspaceSetting } from '../../user/user-map-workspace-settings';
import { UserSettingsService } from '../../user/user-settings.service';
import { FeatureFilterStreamService } from '../current-features/feature-filter-stream.service';
import { TCFileService } from '../files/TCFile.service';

@Injectable({
    providedIn: 'root'
})
export class MapWorkspacesStoreService {
    // --------------------------------------
    // -- Current Map Workspace

    // the currently selected map workspace
    public currentMapWorkspaceStream = new BehaviorSubject<MapWorkspace>(null);
    // true when workspaces for the current project are loading; false otherwise
    public projectMapWorkspacesLoadingStream = new BehaviorSubject(null);
    // the list of all workspaces in the current project
    public projectMapWorkspacesStream = new BehaviorSubject<MapWorkspace[]>(null);

    // --------------------------------------

    public editMapWorkspaceStream = new BehaviorSubject<MapWorkspace>(null);
    public duplicateMapWorkspaceStream = new BehaviorSubject<MapWorkspace>(null);
    public deleteMapWorkspaceStream = new BehaviorSubject<MapWorkspace>(null);
    public archiveEditMapWorkspaceStream = new BehaviorSubject<MapWorkspace>(null);
    public shareMapWorkspaceStream = new BehaviorSubject<MapWorkspace>(null);
    public uploadedWorkspaceDataStream = new BehaviorSubject<ShareWorkspaceData>(null);

    // --------------------------------------

    public projectMapWorkspaces: MapWorkspace[] = null;
    public projectId: string = null;
    public recentlyEditedMapWorkspaces: MapWorkspace[] = [];
    public workspaceFeatureTagsStream: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    private mapWorkspacesPermissionCache: { [key: string]: { loading: boolean; permission: string } } = {};

    constructor(
        private mapWorkspaceService: MapWorkspaceService,
        private currentUserStream: CurrentUserStreamService,
        private projectStream: ProjectStreamService,
        private messaging: MessagingService,
        private userSettingsStream: UserSettingsStreamService,
        private translate: TranslationService,
        private router: Router,
        private utilities: UtilitiesService,
        private tcFileService: TCFileService,
        private userSettingsService: UserSettingsService,
        private featureFilterStream: FeatureFilterStreamService
    ) {
        this.projectStream.currentProjectStream.pipe(distinctUntilChanged()).subscribe(project => {
            if (project) {
                this.projectId = project.id;
                if (this.projectMapWorkspacesLoadingStream.getValue() === null && project.isSharedProject === false) {
                    this.getDefaultOrCreateMapWorkspace(this.projectId, false, false);
                }
            }
        });
    }

    public setCurrentMapWorkspace(currentMapWorkspace: MapWorkspace): Promise<MapWorkspace> {
        let lastMapWorkspaceId = this.userSettingsStream.getCurrentProjectSettings().currentMapWorkspace;
        if (!currentMapWorkspace.isFileViewer && currentMapWorkspace.id !== lastMapWorkspaceId) {
            let currentUserProjectSettings = this.userSettingsStream.getCurrentProjectSettings();

            currentUserProjectSettings.currentMapWorkspace = currentMapWorkspace.id;
            currentUserProjectSettings.previousMapWorkspace = lastMapWorkspaceId;

            this.userSettingsStream.updateCurrentProjectSettings(currentUserProjectSettings);
        }
        currentMapWorkspace.projectId = this.projectStream.getCurrentProject()
            ? this.projectStream.getCurrentProject().id
            : currentMapWorkspace.projectId;

        return new Promise<MapWorkspace>((resolve, reject) => {
            if (
                (currentMapWorkspace.connectFileId || currentMapWorkspace.connectFileVersionIds) &&
                currentMapWorkspace.connectFileError !== ConnectFileError.TC_FILE_NOT_EXIST &&
                currentMapWorkspace.connectFileError !== ConnectFileError.PERMISSION_DENIED
            ) {
                if (!currentMapWorkspace.isPubliclySharedMapWorkspace) {
                    this.getMapWorkspacePermission(currentMapWorkspace).then(mapWorkspacePermission => {
                        if (!mapWorkspacePermission) {
                            this.setPermission(currentMapWorkspace, MapWorkspacePermissionType.NO_ACCESS).then(
                                permission => {
                                    currentMapWorkspace.permission = permission;
                                    this.currentMapWorkspaceStream.next(currentMapWorkspace);
                                    resolve(currentMapWorkspace);
                                }
                            );
                        } else {
                            currentMapWorkspace.connectFolderId = mapWorkspacePermission.folderId;
                            currentMapWorkspace.connectFileName = mapWorkspacePermission.fileName;
                            currentMapWorkspace.connectFileNames =
                                mapWorkspacePermission.fileName && mapWorkspacePermission.fileName.split(',');
                            currentMapWorkspace.connectFileIds = mapWorkspacePermission.fileIds;
                            currentMapWorkspace.connectFileVersionIds = mapWorkspacePermission.fileVersionIds;
                            currentMapWorkspace.connectFileLastAssimIds = mapWorkspacePermission.fileLastAssimIds;
                            currentMapWorkspace.permission = mapWorkspacePermission.effectivePermission;
                            this.setPermission(currentMapWorkspace, currentMapWorkspace.permission).then(permission => {
                                this.mapWorkspacesPermissionCache[currentMapWorkspace.id] = {
                                    loading: false,
                                    permission: permission
                                };
                                currentMapWorkspace.permission = permission;
                                this.currentMapWorkspaceStream.next(currentMapWorkspace);
                                this.setMapWorkspaceFeatureTags();
                                resolve(currentMapWorkspace);
                            });
                        }
                    });
                } else {
                    // TODO: clean this up conflicting with initPubliclySharedCurrentMapWorkspace()
                    this.setPermission(currentMapWorkspace, currentMapWorkspace.permission).then(permission => {
                        currentMapWorkspace.permission = permission;
                        this.currentMapWorkspaceStream.next(currentMapWorkspace);
                        resolve(currentMapWorkspace);
                    });
                }
            } else if (
                currentMapWorkspace.isFileViewer &&
                currentMapWorkspace.connectFileError === ConnectFileError.TC_FILE_NOT_EXIST
            ) {
                //(TCMAPS-4149)In FileViewer, spatialWorkspace/{id} is returning connectFileError: 'TC_FILE_NOT_EXIST'
                // This resulted in NO_ACCESS being set to non-admin users
                this.setPermission(currentMapWorkspace, MapWorkspacePermissionType.FILE_VIEW_MODE).then(permission => {
                    currentMapWorkspace.permission = permission;
                    this.currentMapWorkspaceStream.next(currentMapWorkspace);
                    resolve(currentMapWorkspace);
                });
            } else {
                this.setPermission(currentMapWorkspace, MapWorkspacePermissionType.NO_ACCESS).then(permission => {
                    currentMapWorkspace.permission = permission;
                    this.currentMapWorkspaceStream.next(currentMapWorkspace);
                    resolve(currentMapWorkspace);
                });
            }
        });
    }

    public async setPermission(
        mapWorkspace: MapWorkspace,
        permissionForMapWorkspace: MapWorkspacePermissionType
    ): Promise<MapWorkspacePermissionType> {
        let permission = MapWorkspacePermissionType.NO_ACCESS;
        let currentUserId = this.currentUserStream.currentUser.id;
        if (mapWorkspace.isPubliclySharedMapWorkspace) {
            return new Promise<MapWorkspacePermissionType>(async (resolve, reject) => {
                if (
                    permissionForMapWorkspace === MapWorkspacePermissionType.NO_ACCESS ||
                    permissionForMapWorkspace === MapWorkspacePermissionType.NO_FILE_ACCESS
                ) {
                    // Get file permission for shared files
                    const tmpPermission = await this.mapWorkspaceService.getTCFilePermission(
                        mapWorkspace.connectFileId || mapWorkspace.connectVersionId,
                        mapWorkspace.connectVersionId
                    );
                    return resolve(
                        tmpPermission === MapWorkspacePermissionType.NO_FILE_ACCESS
                            ? MapWorkspacePermissionType.FILE_VIEW_MODE
                            : MapWorkspacePermissionType.READ
                    );
                } else {
                    resolve(permissionForMapWorkspace);
                }
            });
        } else {
            const user = await this.projectStream.getUserWithRolesForCurrentProject(currentUserId);
            if (user.role === UserRole.ADMIN) {
                permission = MapWorkspacePermissionType.FULL_ACCESS;
            } else {
                permission = permissionForMapWorkspace;
            }
            if (mapWorkspace.isFileViewer) {
                permission =
                    permission === MapWorkspacePermissionType.NO_ACCESS
                        ? MapWorkspacePermissionType.NO_FILE_ACCESS
                        : MapWorkspacePermissionType.FILE_VIEW_MODE;
            }
            return permission;
        }
    }

    public getCurrentMapWorkspace(): MapWorkspace {
        return this.currentMapWorkspaceStream.getValue();
    }

    public initCurrentMapWorkspace(
        projectId: string,
        workspaceId: string,
        sharedWorkspace: MapWorkspace
    ): Promise<MapWorkspace> {
        return new Promise<MapWorkspace>((resolve, reject) => {
            if (!workspaceId || workspaceId === '-1') {
                resolve(this.setDefaultMapWorkspace(projectId));
            } else {
                this.userSettingsStream.getWorkspaceSettings(workspaceId).then(() => {
                    if (
                        this.currentMapWorkspaceStream.getValue() &&
                        !this.currentMapWorkspaceStream.getValue().isFileViewer &&
                        this.currentMapWorkspaceStream.getValue().id === workspaceId &&
                        sessionStorage.getItem(SHARED_MODE_KEY) !== ShareMode.PROJECT_USER
                    ) {
                        resolve(this.currentMapWorkspaceStream.getValue());
                    } else {
                        this.mapWorkspaceService
                            .getMapWorkspaceById(projectId, workspaceId)
                            .then(workspace => {
                                if (!workspace.isFileViewer) {
                                    if (workspace.connectFileError) {
                                        let tmpWorkspace = workspace;
                                        this.getMapWorkspaceForConnectFileError(workspace).then(updatedWorkspace => {
                                            let workspace2 =
                                                tmpWorkspace.connectFileError !== ConnectFileError.TC_FILE_NOT_EXIST
                                                    ? updatedWorkspace
                                                    : tmpWorkspace;
                                            this.setCurrentMapWorkspace(workspace2).then(workspace3 => {
                                                this.projectMapWorkspacesLoadingStream.next(true);
                                                resolve(workspace3);
                                            });
                                        });
                                    } else {
                                        this.setCurrentMapWorkspace(workspace).then(workspace2 => {
                                            this.projectMapWorkspacesLoadingStream.next(true);
                                            resolve(workspace2);
                                        });
                                    }
                                } else {
                                    let mapWorkspaceSettings = sessionStorage.getItem('fileViewerSettings')
                                        ? (JSON.parse(
                                              sessionStorage.getItem('fileViewerSettings')
                                          ) as FileViewerSettings)
                                        : this.userSettingsStream.getCurrentMapWorkspaceSettings();
                                    workspace.connectFileVersionIds =
                                        mapWorkspaceSettings.lastViewedConnectFileVersionIds;
                                    workspace.multipleFileView =
                                        mapWorkspaceSettings.lastViewedConnectFileVersionIds.length > 1 ? true : false;
                                    this.setCurrentMapWorkspace(workspace).then(workspace2 => {
                                        resolve(workspace2);
                                    });
                                }
                            })
                            .catch(() => {
                                resolve(this.setDefaultMapWorkspace(projectId));
                            });
                    }
                });
            }
        });
    }

    public initPubliclySharedCurrentMapWorkspace(
        projectId: string,
        sharedWorkspace: MapWorkspace
    ): Promise<MapWorkspacePermissionType> {
        return new Promise<MapWorkspacePermissionType>((resolve, reject) => {
            let workspace = sharedWorkspace;

            if (
                sessionStorage.getItem(ACCESS_MODE_KEY) &&
                sessionStorage.getItem(ACCESS_MODE_KEY) === AccessMode.SHARED
            ) {
                workspace.isPubliclySharedMapWorkspace = true;

                const permissionsPromise = this.mapWorkspaceService.getMapWorkspacePermission(
                    projectId,
                    workspace,
                    this.currentUserStream.currentUser
                );

                permissionsPromise.then((mapWorkspacePermission: MapWorkspacePermission) => {
                    workspace.name = mapWorkspacePermission.fileName;
                    workspace.connectFolderId = mapWorkspacePermission.folderId;
                    workspace.connectFileName = mapWorkspacePermission.fileName;
                    workspace.connectFileNames =
                        mapWorkspacePermission.fileName && mapWorkspacePermission.fileName.split(',');
                    workspace.connectFileIds = mapWorkspacePermission.fileIds;
                    workspace.connectFileVersionIds = mapWorkspacePermission.fileVersionIds;
                    workspace.connectFileLastAssimIds = mapWorkspacePermission.fileLastAssimIds;
                    workspace.permission = mapWorkspacePermission.effectivePermission;

                    this.setPermission(workspace, workspace.permission).then(workspace2 => {
                        this.currentMapWorkspaceStream.next(workspace);
                        this.setMapWorkspaceFeatureTags();
                        resolve(workspace2);
                    });
                });
            } else {
                reject();
            }
        });
    }

    // --------------------------------------
    // -- Project Map Workspaces

    public updateMapWorkspace(
        editedWorkspace: MapWorkspace,
        updatedWithStatus: boolean = false
    ): Promise<{ succeeded: boolean; message: string; updatedMapWorkspace: MapWorkspace }> {
        let indexOfDuplicateWorkspace = this.projectMapWorkspaces.findIndex(
            workspace => workspace && workspace.name === editedWorkspace.name && workspace.id !== editedWorkspace.id
        );

        return new Promise<{ succeeded: boolean; message: string; updatedMapWorkspace: MapWorkspace }>(
            (resolve, reject) => {
                if (indexOfDuplicateWorkspace === -1) {
                    this.mapWorkspaceService
                        .updateMapWorkspace(this.projectId, editedWorkspace.id, editedWorkspace, updatedWithStatus)
                        .then(updatedMapWorkspace => {
                            this.recentlyEditedMapWorkspaces.unshift(editedWorkspace);
                            let indexOfEditedWorkspace = this.projectMapWorkspaces.findIndex(
                                workspace => workspace && workspace.id === editedWorkspace.id
                            );
                            if (indexOfEditedWorkspace !== -1) {
                                if (editedWorkspace.id === this.currentMapWorkspaceStream.getValue().id) {
                                    this.utilities.setTitle(editedWorkspace.name, true);
                                    this.setCurrentMapWorkspace(updatedMapWorkspace);
                                }
                                this.projectMapWorkspaces[indexOfEditedWorkspace] = updatedMapWorkspace;
                                this.projectMapWorkspacesStream.next(this.projectMapWorkspaces);
                            }
                            resolve({
                                succeeded: true,
                                message: this.translate.instant('TCS.Workspace.WorkspaceSaved'),
                                updatedMapWorkspace: updatedMapWorkspace
                            });
                        })
                        .catch(() => {
                            resolve({
                                succeeded: false,
                                message: this.translate.instant('TC.Common.ErrorWhileUpdating'),
                                updatedMapWorkspace: null
                            });
                        });
                } else {
                    resolve({
                        succeeded: false,
                        message: this.translate.instant('TC.Common.NameAlreadyExists'),
                        updatedMapWorkspace: null
                    });
                }
            }
        );
    }

    public updateMapWorkspaceCache(updatedMapWorkspace: MapWorkspace): void {
        let editedWorkspaceIndex = this.projectMapWorkspaces.findIndex(
            workspace => workspace && workspace.id === updatedMapWorkspace.id
        );

        const isWorkspaceEdited = editedWorkspaceIndex !== -1;

        if (isWorkspaceEdited) {
            this.projectMapWorkspaces[editedWorkspaceIndex] = updatedMapWorkspace;
            this.projectMapWorkspacesStream.next(this.projectMapWorkspaces);
        }
    }

    public getMapWorkspaceCache(workspaceId: string): MapWorkspace {
        return this.projectMapWorkspaces.find(workspace => workspace.id === workspaceId);
    }

    public getMapWorkspaceForConnectFileError(workspace: MapWorkspace): Promise<MapWorkspace> {
        switch (workspace.connectFileError) {
            case ConnectFileError.TC_FILE_NOT_EXIST:
                return this.mapWorkspaceService.getTCFile(workspace.connectFileId).then(file => {
                    if (file && file.versionId) {
                        workspace.isPubliclySharedMapWorkspace = true;
                        workspace.connectFileError = null;
                        workspace.connectFileId = file.versionId;
                    } else {
                        // if the file does not exist in TC, delete the workspace and reload to show workspace deleted screen
                        this.deleteMapWorkspace(workspace.id).finally(() => {
                            window.location.reload();
                        });
                    }
                    return workspace;
                });
            case ConnectFileError.MAP_WORKSPACE_REMOVED:
                return this.restoreMapWorkspace(workspace.id, true);
            case ConnectFileError.MAP_WORKSPACE_NOT_EXIST:
                return this.createNewMapWorkspace(workspace, true);
            case ConnectFileError.PERMISSION_DENIED:
                return this.mapWorkspaceService.getTCFile(workspace.connectFileId).then(file => {
                    if (file && file.versionId) {
                        workspace.isPubliclySharedMapWorkspace = true;
                        workspace.connectFileError = null;
                        workspace.connectFileId = file.versionId;
                    }
                    return workspace;
                });
            default:
                return Promise.resolve(workspace);
        }
    }

    public getDefaultOrCreateMapWorkspace(
        projectId: string,
        isFileViewer = false,
        isChanged = false
    ): Promise<MapWorkspace> {
        this.projectMapWorkspaces = isChanged ? null : this.projectMapWorkspaces;
        this.projectMapWorkspacesLoadingStream.next(false);

        return new Promise<MapWorkspace>((resolve, reject) => {
            this.mapWorkspaceService.getMapWorkspaces(projectId, this.projectMapWorkspaces).then(items => {
                this.projectMapWorkspaces = items;
                let fileViewerIndex: number;
                let currentMapWorkspaceIndex: number;

                fileViewerIndex = this.projectMapWorkspaces.findIndex(workspace => workspace.isFileViewer === true);

                let currentProjectSetting = this.userSettingsStream.getCurrentProjectSettings();
                this.projectMapWorkspacesStream.next(this.projectMapWorkspaces);
                if (currentProjectSetting) {
                    currentMapWorkspaceIndex = this.projectMapWorkspaces.findIndex(
                        workspace => workspace.id === currentProjectSetting.currentMapWorkspace
                    );
                }
                if (!currentProjectSetting || currentMapWorkspaceIndex === -1) {
                    let firstMapWorkspaceIndex = this.projectMapWorkspaces.findIndex(
                        workspace => workspace.isFileViewer === false
                    );
                    currentMapWorkspaceIndex = firstMapWorkspaceIndex;
                }

                let searchIndex = isFileViewer ? fileViewerIndex : currentMapWorkspaceIndex;
                if (searchIndex > -1) {
                    this.projectMapWorkspaces[searchIndex].projectId = projectId;
                    let tmpSearchMapWorkspace = this.projectMapWorkspaces[searchIndex];
                    resolve(tmpSearchMapWorkspace);
                } else {
                    let newMapWorkspace: Partial<MapWorkspace> & { isViewer: boolean } = null;
                    if (isFileViewer) {
                        newMapWorkspace = {
                            name: 'FILEVIEWER',
                            description: 'Map workspace to view spatial file like shp, vce, gdb, zip, kml',
                            isViewer: true,
                            projectId: projectId
                        };
                        this.createNewMapWorkspace(newMapWorkspace, isFileViewer).then(
                            mapWorkspace => {
                                mapWorkspace.projectId = projectId;
                                resolve(mapWorkspace);
                            },
                            () => {
                                // Try to fetch file viewer if any
                                if (isFileViewer) {
                                    this.mapWorkspaceService.getFileViewerMW(projectId).then(mapWorkspace => {
                                        mapWorkspace.projectId = projectId;
                                        resolve(mapWorkspace);
                                    });
                                }
                            }
                        );
                    } else {
                        this.getNextNewMapWorkspace(projectId, null, null, false).then(mapWorkspace => {
                            mapWorkspace.projectId = projectId;
                            resolve(mapWorkspace);
                        });
                    }
                }
                this.syncWorkspacesNameWithConnect(this.projectMapWorkspaces);
            });
        });
    }

    // it return the next map workspace that should get loaded
    public async deleteMapWorkspace(id: string, hideNotification: boolean = false): Promise<MapWorkspace> {
        try {
            await this.mapWorkspaceService.deleteMapWorkspace(this.projectStream.getCurrentProject().id, id);
            let indexOfDeletedWorkspace = this.projectMapWorkspaces.findIndex(workspace => workspace.id === id);
            if (indexOfDeletedWorkspace !== -1) {
                delete this.projectMapWorkspaces[indexOfDeletedWorkspace];
                if (!hideNotification) {
                    this.messaging.showSuccess(this.translate.instant('TCS.Workspace.WorkspaceRemove'));
                }
                this.projectMapWorkspaces = this.projectMapWorkspaces.filter(
                    mapWorkspace => !mapWorkspace.isFileViewer
                );
                if (this.getCurrentMapWorkspace() && id === this.getCurrentMapWorkspace().id) {
                    if (this.projectMapWorkspaces.length && this.projectMapWorkspaces[0]) {
                        this.projectMapWorkspacesStream.next(this.projectMapWorkspaces);
                        return this.projectMapWorkspaces[0];
                    } else {
                        this.projectMapWorkspaces = [];
                        this.projectMapWorkspacesStream.next(this.projectMapWorkspaces);
                        return null;
                    }
                } else if (!this.projectMapWorkspaces.length) {
                    this.projectMapWorkspaces = [];
                    this.projectMapWorkspacesStream.next(this.projectMapWorkspaces);
                    return null;
                } else {
                    this.projectMapWorkspacesStream.next(this.projectMapWorkspaces);
                    return this.getCurrentMapWorkspace();
                }
            }
        } catch (error) {
            if (error.status === HttpStatusCodes.NOT_FOUND) {
                this.messaging.showError(this.translate.instant('TCS.Workspace.DeleteFileActiveTodos'));
            } else if (error.status === HttpStatusCodes.FORBIDDEN) {
                this.messaging.showError(this.translate.instant('TC.Common.ErrorWhileDeleting_no_access'));
            } else if (error.status === HttpStatusCodes.SERVER_UNAVAILABLE) {
                this.messaging.showError(this.translate.instant('TCS.Workspace.DeleteFileActiveTodos'));
            }
            throw this.getCurrentMapWorkspace();
        }
    }

    public async createNewMapWorkspace(workspaceObj: any, hideNotification: boolean = false): Promise<MapWorkspace> {
        let isEmpty = workspaceObj ? false : true;
        let createWorkspaceObject = new MapWorkspace();

        if (isEmpty) {
            let tmpNewWorkspace = await this.getNextNewMapWorkspace(workspaceObj.projectId, null, null, false);
            createWorkspaceObject.name =
                tmpNewWorkspace.name || this.translate.instant('TCS.Workspace.DefaultName').toUpperCase() + ' 1';
            createWorkspaceObject.description =
                tmpNewWorkspace.description || this.translate.instant('TCS.Workspace.DefaultDescription').toUpperCase();
        } else {
            createWorkspaceObject.name = workspaceObj.name;
            createWorkspaceObject.description = workspaceObj.description;
            createWorkspaceObject.connectFileId = workspaceObj.connectFileId;
            createWorkspaceObject.isFileViewer = workspaceObj.isViewer;
            createWorkspaceObject.connectFolderId = workspaceObj.connectFolderId;
            createWorkspaceObject.tectonicPlate = workspaceObj.tectonicPlate;
            createWorkspaceObject.coordinateSystem = workspaceObj.coordinateSystem;
            createWorkspaceObject.bounds = workspaceObj.bounds;
        }
        try {
            const workspace = await this.mapWorkspaceService.createMapWorkspace(
                this.currentUserStream.currentUser.id,
                workspaceObj.projectId,
                createWorkspaceObject.toDTO()
            );
            // force thumbnail rendering for new workspace
            workspace.hasThumbnail = true;
            if (!workspace) {
                this.messaging.showError(this.translate.instant('TC.Common.ErrorWhileUpdating'));
            } else {
                let workspace_create_msg = '';
                if (!this.projectMapWorkspaces.length) {
                    this.projectMapWorkspaces = [workspace];
                } else {
                    this.projectMapWorkspaces.push(workspace);
                }
                workspace_create_msg = this.translate.instant('TCS.Workspace.WorkspaceCreated');
                if (!hideNotification) {
                    this.messaging.showSuccess(workspace_create_msg);
                }
                this.projectMapWorkspacesStream.next(this.projectMapWorkspaces);
                return workspace;
            }
        } catch (error) {
            if (!hideNotification) {
                this.handleMapWorkspaceCreationError(error, createWorkspaceObject);
            }
            return Promise.reject(true);
        }
    }

    public async createNewMapWorkspaceFromFile(
        workspaceObj: any,
        uploadedWorkspace: ShareWorkspaceData
    ): Promise<MapWorkspace> {
        let createWorkspaceObject = {
            workspace: new MapWorkspace(),
            content: [{}]
        };

        createWorkspaceObject.workspace = new MapWorkspace({
            ...workspaceObj
        });

        createWorkspaceObject.content = uploadedWorkspace.content;

        const createWorkspaceDTO = {
            workspace: createWorkspaceObject.workspace.toDTO(),
            content: createWorkspaceObject.content
        };

        try {
            const workspace = await this.mapWorkspaceService.createMapWorkspaceFromFile(
                this.projectId,
                createWorkspaceDTO
            );
            // force thumbnail rendering for new workspace
            workspace.hasThumbnail = true;
            if (!workspace) {
                this.messaging.showError(this.translate.instant('TC.Common.ErrorWhileUpdating'));
            } else {
                let workspace_create_msg = '';
                if (!this.projectMapWorkspaces.length) {
                    this.projectMapWorkspaces = [workspace];
                } else {
                    this.projectMapWorkspaces.push(workspace);
                }
                workspace_create_msg = this.translate.instant('TCS.Workspace.WorkspaceCreated');

                this.messaging.showSuccess(workspace_create_msg);

                this.projectMapWorkspacesStream.next(this.projectMapWorkspaces);
                return workspace;
            }
        } catch (error) {
            this.handleMapWorkspaceCreationError(error, createWorkspaceObject, true);
            return Promise.reject(true);
        }
    }

    public async duplicateWorkspace(duplicateWorkspaceDTO: DuplicateWorkspaceDTO): Promise<MapWorkspace> {
        try {
            const workspace = await this.mapWorkspaceService.duplicateWorkspace(this.projectId, duplicateWorkspaceDTO);
            // force thumbnail rendering for duplicated workspace
            workspace.hasThumbnail = true;
            if (!this.projectMapWorkspaces.length) {
                this.projectMapWorkspaces = [workspace];
            } else {
                this.projectMapWorkspaces.push(workspace);
            }
            const settings = new UserMapWorkspaceSetting(workspace.id);
            settings.isFileViewer = false;
            settings.visibleLayerIds = workspace.relationships.layerIds;
            await this.userSettingsService.createUserMapWorkspaceSettings(settings.toDTO());
            this.messaging.showSuccess(this.translate.instant('TCS.Workspace.WorkspaceDuplicated'));
            this.projectMapWorkspacesStream.next(this.projectMapWorkspaces);
            return workspace;
        } catch (error) {
            this.handleMapWorkspaceCreationError(error, duplicateWorkspaceDTO, true);
            return await Promise.resolve(null);
        }
    }

    public getNextNewMapWorkspace(
        projectId: string,
        connectFileId: string,
        connectFolderId: string,
        isChanged: boolean = false
    ): Promise<MapWorkspace> {
        let nextMapWorkspace: MapWorkspace;
        this.projectMapWorkspaces = isChanged ? null : this.projectMapWorkspaces;
        return new Promise<MapWorkspace>((resolve, reject) => {
            this.mapWorkspaceService.getMapWorkspaces(projectId, this.projectMapWorkspaces).then(items => {
                this.projectMapWorkspaces = items;
                this.projectMapWorkspaces = this.projectMapWorkspaces.filter(
                    mapWorkspace => !mapWorkspace.isFileViewer
                );
                if (!this.projectMapWorkspacesStream.getValue()) {
                    this.projectMapWorkspacesStream.next(this.projectMapWorkspaces);
                }
                if (connectFileId) {
                    nextMapWorkspace = this.projectMapWorkspaces.find(
                        mapWorkspace => mapWorkspace.connectFileId === connectFileId
                    );
                    if (!nextMapWorkspace) {
                        this.mapWorkspaceService
                            .getMapWorkspaceByConnectFileId(projectId, connectFileId, false)
                            .then(mapWorkspace => {
                                if (mapWorkspace.connectFileError === ConnectFileError.MAP_WORKSPACE_REMOVED) {
                                    this.mapWorkspaceService
                                        .restoreMapWorkspace(projectId, mapWorkspace.id)
                                        .then(mapWorkspace2 => {
                                            this.projectMapWorkspaces.push(mapWorkspace2);
                                            this.projectMapWorkspacesStream.next(this.projectMapWorkspaces);
                                            resolve(mapWorkspace2);
                                        });
                                } else {
                                    resolve(mapWorkspace);
                                }
                            });
                    } else {
                        resolve(nextMapWorkspace);
                    }
                } else {
                    nextMapWorkspace = new MapWorkspace();
                    nextMapWorkspace.projectId = projectId;
                    nextMapWorkspace.name = '';
                    nextMapWorkspace.description = '';
                    nextMapWorkspace.connectFileId = connectFileId;
                    nextMapWorkspace.connectFolderId = connectFolderId;
                    this.editMapWorkspaceStream.next(nextMapWorkspace);
                    resolve(nextMapWorkspace);
                }
                this.syncWorkspacesNameWithConnect(this.projectMapWorkspaces);
            });
        });
    }

    public handleMapWorkspaceCreationError(error: any, errorWorkspace: any, isDuplicateWorkflow = false): void {
        if (error.status === 409) {
            if (
                error.error.errors[0].errorCode.trim() ===
                    ConnectMapWorkspaceCreationError.MAP_WORKSPACE_ALREADY_EXIST ||
                error.error.errors[0].errorCode.trim() === ConnectMapWorkspaceCreationError.TC_FILE_ALREADY_EXIST
            ) {
                this.messaging.showError(this.translate.instant('TCS.Workspace.WorkspaceNameAlreadyExist'));
            } else {
                this.messaging.showError(this.translate.instant('TC.Common.ErrorWhileUpdating'));
            }
        } else if (error.status === HttpStatusCodes.NOT_FOUND) {
            if (
                error.error.errors[0].errorCode.trim() === ConnectMapWorkspaceCreationError.TC_WORKSPACE_CREATION_FAILED
            ) {
                this.messaging.showError(this.translate.instant('TCS.Workspace.UnableCreateWorkspace'));
            }
        } else {
            this.messaging.showError(this.translate.instant('TC.Common.ErrorWhileCreating'));
        }

        if (error.status === HttpStatusCodes.FORBIDDEN) {
            if (
                error.error.errors &&
                error.error.errors[0].errorCode.trim() ===
                    ConnectMapWorkspaceCreationError.TC_WORKSPACE_CREATION_FAILED_EXCEEDED_STORAGE_LIMIT
            ) {
                this.router.navigate(['mapViewer', { outlets: { centerDialog: 'workspace/license/error' } }], {
                    queryParamsHandling: 'preserve',
                    skipLocationChange: true
                });
            }
        } else if (isDuplicateWorkflow === false) {
            this.editMapWorkspaceStream.next(
                MapWorkspace.fromDTO(errorWorkspace, this.projectStream.getCurrentProject().id)
            );

            this.router.navigate(['mapViewer', { outlets: { centerDialog: 'workspace/new' } }], {
                queryParamsHandling: 'preserve',
                skipLocationChange: true
            });
        }
    }

    public async restoreMapWorkspace(id: string, hideNotification: boolean = false): Promise<MapWorkspace> {
        const workspace = await this.mapWorkspaceService.restoreMapWorkspace(
            this.projectStream.getCurrentProject().id,
            id
        );
        if (!workspace && !hideNotification) {
            this.messaging.showInfo(this.translate.instant('TC.Common.ErrorWhileRestoring'));
        } else {
            if (this.projectMapWorkspaces && this.projectMapWorkspaces.length) {
                this.projectMapWorkspaces.push(workspace);
            } else {
                this.projectMapWorkspaces = [workspace];
            }
            workspace.projectId = this.projectStream.getCurrentProject().id;
            if (!hideNotification) {
                this.messaging.showSuccess(this.translate.instant('TCS.Workspace.WorkspaceRestored'));
            }
            this.projectMapWorkspacesStream.next(this.projectMapWorkspaces);
            return workspace;
        }
    }

    public loadCurrentMapWorkspaceId(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.userSettingsStream.currentProjectSettings$.subscribe(data => {
                if (data) {
                    resolve(data.currentMapWorkspace);
                }
            });
        });
    }

    // gets the potentialy cached permission string for the specified workspace
    public getPermissionForMapWorkspace(mapWorkspace: MapWorkspace): Promise<MapWorkspacePermissionType> {
        return new Promise<MapWorkspacePermissionType>((resolve, reject) => {
            if (this.mapWorkspacesPermissionCache[mapWorkspace.id]) {
                let output = interval(500);
                let subscribe = output.subscribe(() => {
                    if (!this.mapWorkspacesPermissionCache[mapWorkspace.id].loading) {
                        resolve(
                            this.mapWorkspacesPermissionCache[mapWorkspace.id].permission as MapWorkspacePermissionType
                        );
                        subscribe.unsubscribe();
                    }
                });
            } else {
                this.mapWorkspacesPermissionCache[mapWorkspace.id] = {
                    loading: true,
                    permission: null
                };

                this.getMapWorkspacePermission(mapWorkspace).then(mapWorkspacePermission => {
                    this.setPermission(
                        mapWorkspace,
                        mapWorkspacePermission && mapWorkspacePermission.effectivePermission
                    ).then(permission => {
                        this.mapWorkspacesPermissionCache[mapWorkspace.id] = {
                            loading: false,
                            permission: permission
                        };
                        resolve(permission);
                    });
                });
            }
        });
    }

    public async getMapWorkspacePermission(mapWorkspace: MapWorkspace): Promise<MapWorkspacePermission> {
        let mapWorkspacePermission = await this.mapWorkspaceService.getMapWorkspacePermission(
            mapWorkspace.projectId ? mapWorkspace.projectId : this.projectId,
            mapWorkspace,
            this.currentUserStream.currentUser
        );
        if (!mapWorkspacePermission.folderId) {
            mapWorkspacePermission = new MapWorkspacePermission(mapWorkspace.id, this.currentUserStream.currentUser.id);
            mapWorkspacePermission.effectivePermission = MapWorkspacePermissionType.NO_ACCESS;
        }
        return mapWorkspacePermission;
    }

    private syncWorkspacesNameWithConnect(mapWorkspaces: MapWorkspace[]): void {
        // check all the map workspace names with connect file names and update map workspace name if there is a mismatch
        mapWorkspaces = mapWorkspaces.filter(workspace => !workspace.isFileViewer);
        this.tcFileService.getWorkspaceFilesByExtension(this.projectStream.currentProject.id).then(results => {
            results.forEach(result => {
                if (result) {
                    const file = result.details;
                    const fileName = file.name.split('.tmap')[0];
                    const modifiedUtc = new Date(file.modifiedOn);
                    const mapWorkspace = mapWorkspaces.find(workspace => workspace.connectFileId === file.id);
                    if (mapWorkspace && fileName !== mapWorkspace.name && modifiedUtc > mapWorkspace.updatedUtc) {
                        mapWorkspace.name = fileName;
                        this.updateMapWorkspace(mapWorkspace);
                    }
                }
            });
        });
    }

    private setDefaultMapWorkspace(projectId: string): MapWorkspace {
        const defaultWorkspace = new MapWorkspace();
        defaultWorkspace.name = 'No map workspace';
        defaultWorkspace.projectId = projectId;
        defaultWorkspace.permission = MapWorkspacePermissionType.READ;
        defaultWorkspace.postProcessingOptions = new GeoWorkspacePostProcessingBody();

        this.currentMapWorkspaceStream.next(defaultWorkspace);
        return defaultWorkspace;
    }

    public async setMapWorkspaceFeatureTags() {
        const { layerIds } = this.currentMapWorkspaceStream.getValue()?.relationships;
        let tags = await this.mapWorkspaceService.getFeatureTagsByLayers(this.projectId, layerIds);
        tags = Array.isArray(tags) ? tags : [];

        const uniqueTags = [...new Set(tags.flatMap(tag => JSON.parse(tag)))];
        this.workspaceFeatureTagsStream.next(
            uniqueTags.filter(tag => tag !== 'exported').sort((a, b) => a.localeCompare(b))
        );
        // Remove custom tags from active filter if they are not in the current workspace on load
        const activeFilter = this.featureFilterStream.activeFilter;
        activeFilter.customTags = activeFilter.customTags.filter(tag => uniqueTags.includes(tag));
        this.featureFilterStream.activeFilter = activeFilter;
    }
}
