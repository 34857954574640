<nav class="tfx-editor-tabs optionsPanel">
    <div class="gsp-tabs optionsTabs">
        <section>
            <ng-container *ngFor="let tab of tabsList">
                <div
                    title="{{ tab.name | translate }}"
                    tooltipLocation="bottom"
                    (click)="activateTab(tab)"
                    [ngClass]="{ active: tab.id == selectedTab.id }"
                    *ngIf="
                        tab.id !== TemplateEditorTabId.LINK ||
                        linkDetails.length > 1 ||
                        secondaryUniqLinkDetails.length > 1
                    "
                >
                    <i class="{{ tab.iconClass }} i32"></i>
                    <span translate>{{ tab.name }}</span>
                </div>
            </ng-container>
        </section>
    </div>
    <div class="options" [ngSwitch]="selectedTab.id">
        <section class="height-full">
            <custom-scroll>
                <template-panel
                    [template]="template"
                    [templateEditorConfig]="templateEditorConfig"
                    *ngSwitchCase="TemplateEditorTabId.DETAIL"
                >
                </template-panel>
                <field-panel
                    [application]="application"
                    [templateEditorConfig]="templateEditorConfig"
                    [template]="template"
                    *ngSwitchCase="TemplateEditorTabId.FIELDS"
                ></field-panel>
                <rules-panel [template]="template" *ngSwitchCase="TemplateEditorTabId.RULE"></rules-panel>
                <ng-container *ngSwitchCase="TemplateEditorTabId.LINK">
                    <links-panel
                        [template]="template"
                        [linkDetails]="linkDetails"
                        *ngIf="linkDetails.length > 1 || secondaryUniqLinkDetails.length > 1"
                    ></links-panel>
                </ng-container>
            </custom-scroll>
        </section>
    </div>
</nav>
