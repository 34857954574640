import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: '[uniqueValuesOnly]',
    providers: [{ provide: NG_VALIDATORS, useExisting: UniqueValuesOnlyValidatorDirective, multi: true }]
})
export class UniqueValuesOnlyValidatorDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors {
        if (control?.value) {
            // choice field
            if (control.value[0]?.hasOwnProperty('text')) {
                if (this.hasDuplicate(control.value, 'text')) {
                    // shows message: choice values must be unique
                    return { unique: true };
                }

                return null;
            }

            // coded choice field
            if (control.value[0]?.hasOwnProperty('code') && control.value[0]?.hasOwnProperty('description')) {
                const multiDuplicate = [
                    this.hasDuplicate(control.value, 'code'),
                    this.hasDuplicate(control.value, 'description')
                ];

                let messageMatrix = { uniqueCode: false, unique: false };

                messageMatrix.uniqueCode = !!multiDuplicate[0] ? true : false;
                messageMatrix.unique = !!multiDuplicate[1] ? true : false;

                if (multiDuplicate.some(prop => prop === true)) {
                    return this.existingMessageOnly(messageMatrix);
                }

                return null;
            }

            return null;
        }

        return null;
    }

    private hasDuplicate(arr: any[], property: string) {
        const valueList = arr.map((val: any) => val[`${property}`]);
        const hasDuplicate = valueList.some((item: any, index: number) => valueList.indexOf(item) !== index);
        return hasDuplicate;
    }

    private existingMessageOnly(obj: {}) {
        const objAsArray = Object.entries(obj);
        const allTruthyValues = objAsArray.filter(([key, value]) => value === true);
        return Object.fromEntries(allTruthyValues);
    }
}
