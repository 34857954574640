import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MainMenuStreamService } from 'src/app/feature/map-viewer/main-menu/main-menu-stream.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { GspWizardService } from 'src/app/shared/common/components/gsp-wizard/gsp-wizard.service';
import { GeoLayer } from 'src/app/shared/map-data-services/layer/layer';
import { ShareWorkspaceData } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { GeoTemplate } from 'src/app/shared/template-services/templateDTO';

import { ShareWorkspaceStoreService } from '../workspace-share-store.service';

@Component({
    selector: 'workspace-share-download',
    templateUrl: './workspace-share-download.component.html'
})
export class WorkspaceShareDownloadComponent implements OnInit, OnDestroy {
    ButtonType = ButtonType;

    sharedWorkspaceData: ShareWorkspaceData;

    layers: GeoLayer[];
    layerTemplates: GeoTemplate[];

    private readonly destroyed = new Subject<void>();

    public uiState = {
        backButton: { text: 'MapViewer.Generic.Previous', enabled: true, visible: true },
        forwardButton: { text: 'TC.Common.Done', enabled: false, visible: true },
        closeButton: { text: '', enabled: false, visible: false },
        cancelButton: { text: 'TC.Common.Cancel', enabled: true, visible: true }
    };

    constructor(
        private wizardService: GspWizardService,
        private router: Router,
        private mainMenuStream: MainMenuStreamService,
        private shareWorkspaceStore: ShareWorkspaceStoreService
    ) {}

    ngOnInit(): void {
        this.wizardService.setUI(this.uiState);

        this.shareWorkspaceStore.shareWorkspaceDataStream.pipe(takeUntil(this.destroyed)).subscribe(data => {
            this.sharedWorkspaceData = data;
        });
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    public onForward(): void {
        this.router
            .navigate(['mapViewer', { outlets: { centerDialog: null, mainMenu: 'workspaces' } }], {
                queryParamsHandling: 'preserve'
            })
            .then(() => {
                this.mainMenuStream.showMainMenu$.next(true);
            });
    }

    public downloadWorkspace() {
        const downloadData = new Blob([JSON.stringify(this.sharedWorkspaceData)], { type: 'application/json' });
        saveAs(downloadData, `${this.sharedWorkspaceData.workspace.name}.wsdef`);
        this.uiState.forwardButton.enabled = true;
        this.wizardService.setUI(this.uiState);
    }
}
