import { MapWorkspacePermissionType } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace-permission';

export class CollaborationToolListItem {
    id: number;
    name: string;
    iconClass: string;
    selected: boolean;
    showName: string;
    visibility: object;
}

export const collaborationToolList: CollaborationToolListItem[] = [
    {
        id: 1,
        name: 'todo',
        iconClass: 'icon_solid_todo_add',
        selected: false,
        showName: 'TC.Todos',
        visibility: { visibleModes: [MapWorkspacePermissionType.FULL_ACCESS] }
    },
    {
        id: 2,
        name: 'export',
        iconClass: 'icon_line_cloud_down',
        selected: false,
        showName: 'TC.Common.Export',
        visibility: { visibleModes: [MapWorkspacePermissionType.FULL_ACCESS, MapWorkspacePermissionType.READ] }
    }
];
