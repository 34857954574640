import { Injectable } from '@angular/core';
import { TranslationService } from 'src/app/core/translation/translation.service';

@Injectable({
    providedIn: 'root'
})
export class UnitConversionService {
    private unitToTranslationStringDict: { [x: string]: string | string[] } = {
        degrees: '°',
        meters: 'TC.Common.Units.M',
        metre: 'TC.Common.Units.M',
        kilometers: 'TC.Common.Units.Km',
        miles: 'TC.Common.Units.Miles',
        centimeters: 'TC.Common.Units.Cm',
        inches: 'TC.Common.In',
        feet: 'TC.Common.Units.Ft',
        internationalfoot: 'TC.Common.Units.Ft',
        ussurveyfoot: 'TC.Common.Units.USFt',
        yards: 'TC.Common.Units.Yd',
        squaremeters: 'TC.Common.Units.M2',
        squarekilometers: 'TC.Common.Units.Km2',
        squarefeet: 'TC.Common.Units.Ft2',
        acres: 'TC.Common.Units.Acres',
        hectares: 'TC.Common.Units.Hectares'
    };

    constructor(private translate: TranslationService) {}

    convertUnitToHtml(unitToTranslate: string) {
        let returnHtml = '';
        let unit = unitToTranslate ? unitToTranslate.toLowerCase() : null;

        returnHtml =
            unit && this.unitToTranslationStringDict[unit]
                ? this.translate.instant(this.unitToTranslationStringDict[unit]).replace('\\u00b2', '&sup2;')
                : '';

        return returnHtml;
    }
}
