<div class="feature-field">
    <label class="feature-label" [ngClass]="{ disabled: field.value == null }">
        {{ field.displayName }} <span *ngIf="field.required">*</span>
    </label>
    <div class="feature-data" *ngIf="!editMode">
        <span *ngIf="!isURL">{{ field.value }}</span>
        <a *ngIf="isURL" [href]="field.value" target="_blank" class="link">{{ field.value }}</a>
    </div>
    <div class="editable-control" *ngIf="editMode">
        <gsp-text [type]="GspTextType.GENERIC" [(ngModel)]="field.value" [length]="field.maxLength"></gsp-text>
    </div>
</div>
