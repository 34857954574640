import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import * as _ from 'lodash-es';
import { Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { MapWorkspace } from '../../map-data-services/mapWorkspace/map-workspace';
import { MapWorkspacePermissionType } from '../../map-data-services/mapWorkspace/map-workspace-permission';
import { User, UserRole } from '../../user/user';
import { MapWorkspaceStreamsService } from '../current-map-workspaces/map-workspace-streams.service';
import { CurrentUserStreamService } from '../current-user/current-user-stream.service';
import { GeneralUtils } from '../utility/general-utils';
import { CheckVisibilityService } from './check-visibility.service';

export interface VisibilityConfig {
    visibleModes: MapWorkspacePermissionType[];
    visibleRoles: UserRole[];
    toolbarOption: string;
    toolbarOptionValue: boolean;
}

@Directive({ selector: '[checkVisibility]' })
export class CheckVisibilityDirective implements OnInit, OnDestroy {
    @Input('checkVisibility')
    public config: VisibilityConfig;

    private stop$ = new Subject();
    private isVisible = false;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
        private mapWorkspaceStreams: MapWorkspaceStreamsService,
        private currentUserStream: CurrentUserStreamService,
        private checkVisibilityService: CheckVisibilityService
    ) {}

    ngOnInit(): void {
        combineLatest([
            this.mapWorkspaceStreams.currentMapWorkspaceStream.pipe(distinctUntilChanged(GeneralUtils.isIdEqual)),
            this.currentUserStream.currentUserWithRoleStream
        ])
            .pipe(takeUntil(this.stop$))
            .subscribe(([mapWorkspace, userInfo]) => {
                if (mapWorkspace) {
                    // we are checking the visibleModes config first and only if they pass,
                    // we check for visibleRoles config as the visibleModes could be overriden by user role.
                    // If we check them separately a component configured as NO_ACCESS could be overriden if the user is ADMIN
                    if (
                        (this.config.visibleModes || this.config.toolbarOption) &&
                        (this.checkVisibleModes(this.config.visibleModes, mapWorkspace) ||
                            this.checkToolbarOptionsVisibility(mapWorkspace))
                    ) {
                        this.isVisible = false;
                    } else if (this.config.visibleRoles && this.checkVisibleRoles(this.config.visibleRoles, userInfo)) {
                        this.isVisible = false;
                    } else {
                        this.isVisible = true;
                    }
                }
                this.viewContainerRef.clear();
                if (this.isVisible) {
                    this.viewContainerRef.createEmbeddedView(this.templateRef);
                }
            });
    }

    ngOnDestroy(): void {
        this.stop$.next(null);
    }

    public checkToolbarOptionsVisibility(mapWorkspace: MapWorkspace): boolean {
        return (
            mapWorkspace.isFileViewer &&
            mapWorkspace.options &&
            this.config.toolbarOption &&
            _.get(mapWorkspace.options, this.config.toolbarOption) === this.config.toolbarOptionValue
        );
    }

    public checkVisibleModes(visibleModes: MapWorkspacePermissionType[], mapWorkspace: MapWorkspace): boolean {
        return this.checkVisibilityService.restrictAccess(visibleModes, mapWorkspace);
    }

    public checkVisibleRoles(visibleRoles: UserRole[], userInfo: User): boolean {
        return visibleRoles.indexOf(userInfo?.role) === -1;
    }
}
