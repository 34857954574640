<div class="condition-edit">
    <div class="editor-field-row">
        <label translate [translateParams]="{ condition: '' }">TCS.TemplateEditor.Tf.Rules.Validate.ThisField</label>
    </div>
    <div class="editor-field-row">
        <gsp-dropdown
            [options]="condition.getConditionPair(field.allowMultiSelect)"
            [(ngModel)]="condition.operator"
            (ngModelChange)="resetChoiceSelection()"
            idField="value"
            dropdownUniqueClass=""
            textField="name"
        ></gsp-dropdown>
    </div>
    <div
        [hidden]="condition.operator === ConditionOperator.IS_ANSWERED"
        class="editor-field-row"
        *ngFor="let choice of field.values; index as i"
    >
        <gsp-checkbox
            [(value)]="choice.choiceSelected[selectedRuleNo]"
            [id]="field.uuid + 'choiceCondition' + i"
            [label]="field.type === FieldType.Choice ? choice.text : choice.description"
            (valueChange)="
                condition.addOrRemoveValue(
                    field.type === FieldType.Choice ? choice.text : choice.code,
                    choice.choiceSelected[selectedRuleNo],
                    selectedRuleNo,
                    field.values,
                    choice.choiceSelected
                )
            "
        ></gsp-checkbox>
    </div>
    <div
        [hidden]="condition.operator === ConditionOperator.IS_ANSWERED"
        class="editor-field-row"
        *ngIf="field.allowNonDomainValues"
    >
        <gsp-checkbox
            [(value)]="condition.compareOther"
            [id]="field.uuid + 'other'"
            [label]="'TCW_OTHER' | translate"
            (valueChange)="condition.resetOptions(selectedRuleNo, field.values)"
        ></gsp-checkbox>
    </div>
</div>
