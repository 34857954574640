import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationUtil } from '../authentication.util';

@Component({
    template: ''
})
export class TIDLoginComponent implements OnInit {
    constructor(private router: Router) {}

    ngOnInit(): void {
        // Access token should have been saved in TIDAuthGuard so just redirect based on state
        const queryParams = AuthenticationUtil.getQueryParams();
        const state = decodeURIComponent(queryParams.state);
        this.router.navigateByUrl(state.split('#/')[1]);
    }
}
