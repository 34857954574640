/* eslint-disable */
//This code is 100% based on https://github.com/jawj/OverlappingMarkerSpiderfier-Leaflet
//Huge thanks to jawj for implementing it first to make my job easy :-)

L.MarkerClusterGroup.include({
    //The MarkerCluster currently spiderfied (if any)
    _spiderfied: null,

    _spiderfierOnAdd: function() {
        this._map.on('click', this._unspiderfyWrapper, this);

        if (this._map.options.zoomAnimation) {
            this._map.on('zoomstart', this._unspiderfyZoomStart, this);
        }
        //Browsers without zoomAnimation or a big zoom don't fire zoomstart
        this._map.on('zoomend', this._noanimationUnspiderfy, this);
    },

    _spiderfierOnRemove: function() {
        this._map.off('click', this._unspiderfyWrapper, this);
        this._map.off('zoomstart', this._unspiderfyZoomStart, this);
        this._map.off('zoomanim', this._unspiderfyZoomAnim, this);
        this._map.off('zoomend', this._noanimationUnspiderfy, this);

        //Ensure that markers are back where they should be
        // Use no animation to avoid a sticky leaflet-cluster-anim class on mapPane
        this._noanimationUnspiderfy();
    },

    //On zoom start we add a zoomanim handler so that we are guaranteed to be last (after markers are animated)
    //This means we can define the animation they do rather than Markers doing an animation to their actual location
    _unspiderfyZoomStart: function() {
        if (!this._map) {
            //May have been removed from the map by a zoomEnd handler
            return;
        }

        this._map.on('zoomanim', this._unspiderfyZoomAnim, this);
    },

    _unspiderfyZoomAnim: function(zoomDetails) {
        //Wait until the first zoomanim after the user has finished touch-zooming before running the animation
        if (L.DomUtil.hasClass(this._map._mapPane, 'leaflet-touching')) {
            return;
        }

        this._map.off('zoomanim', this._unspiderfyZoomAnim, this);
        this._unspiderfy(zoomDetails);
    },

    _unspiderfyWrapper: function() {
        /// <summary>_unspiderfy but passes no arguments</summary>
        this._unspiderfy();
    },

    _unspiderfy: function(zoomDetails) {
        if (this._spiderfied) {
            this._spiderfied.unspiderfy(zoomDetails);
        }
    },

    _noanimationUnspiderfy: function() {
        if (this._spiderfied) {
            this._spiderfied._noanimationUnspiderfy();
        }
    },

    //If the given layer is currently being spiderfied then we unspiderfy it so it isn't on the map anymore etc
    _unspiderfyLayer: function(layer) {
        if (layer._spiderLeg) {
            this._featureGroup.removeLayer(layer);

            if (layer.clusterShow) {
                layer.clusterShow();
            }
            //Position will be fixed up immediately in _animationUnspiderfy
            if (layer.setZIndexOffset) {
                layer.setZIndexOffset(0);
            }

            this._map.removeLayer(layer._spiderLeg);
            delete layer._spiderLeg;
        }
    }
});
