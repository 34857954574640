import * as _ from 'lodash-es';

export class GeneralUtils {
    static isNullUndefinedOrNaN(v: any): boolean {
        return _.isNull(v) || _.isUndefined(v) || v === '' || _.isNaN(v);
    }

    static isNullUndefinedOrEmpty(v: any): boolean {
        return _.isNull(v) || _.isUndefined(v) || (v && !v.length);
    }

    static isIdEqual = (a: any & { id: string }, b: any & { id: string }): boolean => a?.id === b?.id;
}
