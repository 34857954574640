import { GeoLink } from 'src/app/shared/types/geo';

import { License } from './license';

export enum UserStatus {
    ACTIVE = 'ACTIVE',
    REMOVED = 'REMOVED'
}

// Note: DTO may come from either the following (all slightly different):
//   /auth/currentuser
//   projects/{pid}/users
export interface UserDTO {
    active?: boolean;
    companyAssigned: boolean;
    createdOn?: Date;
    email?: string;
    excludeLicense?: boolean;
    firstName?: string;
    freeDomain?: boolean;
    id?: string;
    lastName?: string;
    name?: string;
    phone?: string;
    role?: { id: string; name?: string };
    thumbnail?: string;
    tiduuid?: string;
    trn?: string;
    locale?: string;
    hasImage?: boolean;
    // license?: UserLicense;
    status?: UserStatus;
}

export interface GeoUserProfiles {
    links?: GeoLink[];
    total?: number;
    items?: UserDTO[];
    eTag?: string;
}

export interface GroupUsersResponse {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    status?: string;
    skypeId?: string;
    linkedinId?: string;
}

export class User {
    id: string = null;
    firstName: string = null;
    lastName: string = null;
    name: string = null;
    email: string = null;
    role: UserRole = null; // 'USER' or 'ADMIN'
    locale = 'en-us'; // Default language is english
    license: License = null;
    tiduuid: string = null;
    thumbnail: string = null;

    constructor() {}

    static fromDTO(dto: UserDTO | Partial<User>): User {
        const user = new User();

        if (dto) {
            user.id = dto.id;
            user.firstName = dto.firstName;
            user.lastName = dto.lastName;
            user.name = dto.firstName + ' ' + dto.lastName;
            user.email = dto.email;
            user.role = User.getRole(dto.role);
            user.locale = User.getLangKey(dto.locale);
            user.tiduuid = dto.tiduuid;
            user.thumbnail = dto.thumbnail;
        }
        return user;
    }

    private static getRole(role: { id: string; name?: string } | UserRole): UserRole {
        if (!role) {
            return UserRole.USER;
        } else if (typeof role === 'string') {
            return role as UserRole;
        } else if (role.name && typeof role.name === 'string') {
            // Note: role is {id: string, name: string} where name is "Admin" or "User"
            return (role.name as string).toUpperCase() as UserRole;
        } else {
            return UserRole.USER;
        }
    }

    private static getLangKey(locale: string): string {
        let langKeyMap: { [key: string]: string } = {
            en: 'en',
            'en-us': 'en',
            'da-dk': 'da',
            'de-de': 'de',
            'es-es': 'es',
            'fi-fi': 'fi',
            'fr-fr': 'fr',
            'it-it': 'it',
            'ja-jp': 'ja',
            'ko-kr': 'ko',
            'nb-no': 'no',
            'nl-nl': 'nl',
            'pt-br': 'pt',
            'ru-ru': 'ru',
            'sv-se': 'sv',
            'zh-hans': 'zh-CHS',
            'zh-hant': 'zh-CHT',
            'cs-cz': 'cs'
        };

        return langKeyMap[locale?.toLowerCase()] || 'en';
    }
}

export enum UserRole {
    ADMIN = 'ADMIN',
    USER = 'USER'
}
