import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import { Observable, catchError, lastValueFrom, map, of } from 'rxjs';
import { TemplatedFeatureMetadataProperty } from 'src/app/feature/map-viewer/side-panel/feature-panel/feature-fields/templated-feature';
import { EnvironmentService } from 'src/app/shared/common/environment/environment.service';
import { FeatureFilter } from 'src/app/shared/common/feature-filter/feature-filter';
import { FullFeatureFilter, Op } from 'src/app/shared/common/feature-filter/full-feature-filter';

import { GeometryTypes, GeometryUtils } from '../../common/utility/geometry-utils';
import { StringUtils } from '../../common/utility/string-utils';
import { GeoLayer, Layer } from '../layer/layer';
import { Feature, IFeature, IFeatureDTO } from './feature';

export interface IFeatureCollection {
    type: 'FeatureCollection';
    features: IFeatureDTO[];
    crs?: {};
}
export interface PostProcessingEventSummary {
    id: string;
    summaryevents: {
        eventsummarytype: EventSummaryType;
        additionalinfo?: string;
        startedtimestamp: string;
        nextactivityexpectedtimestamp?: string;
    }[];
}

export interface FilterItem {
    operand?: string | string[];
    and?: any[];
    or?: any[];
    [key: string]: any; // Add index signature
}

export enum EventSummaryType {
    DELAYED = 0,
    PREPARING = 1, // Uploading/analyzing rover sessions, creating processing run
    AWAITING_BASE_DATA = 2, // Selecting base provider, awaiting/downloading base files - AdditionalInfo is base provider name
    PROCESSING = 3, // Differentially correcting/rebuilding
    COMPLETED = 4
}

@Injectable({
    providedIn: 'root'
})
export class FeatureService {
    constructor(private http: HttpClient, private environmentService: EnvironmentService) {}

    public async getFeatures(
        projectId: string,
        filter: FullFeatureFilter,
        maxFeatures: number = null
    ): Promise<Feature[]> {
        let featurePath = '/projects/' + projectId + '/features/query';
        let filterExpression: { filter: any[]; coordinateType: string; pageSize?: number } = {
            filter: filter.buildFilterRequest(),
            coordinateType: 'Global'
        };

        if (maxFeatures) {
            filterExpression['pageSize'] = maxFeatures;
        }

        // scans through if there is an encoded format in operands - ref: TCMAPS-4117
        for (let key in filterExpression.filter) {
            // first layer of scanning for 'operand' property
            if (
                filterExpression.filter[key].hasOwnProperty('operand') &&
                typeof filterExpression.filter[key].operand === 'string'
            ) {
                this.decodeOperandsInFilterArray(filterExpression.filter[key].operand);
            }

            // second layer of scanning for 'and' and 'or' properties
            if (filterExpression.filter[key].hasOwnProperty('and')) {
                this.decodeOperandsInFilterArray(filterExpression.filter[key]['and']);
            }
            if (filterExpression.filter[key].hasOwnProperty('or')) {
                this.decodeOperandsInFilterArray(filterExpression.filter[key]['or']);
            }
        }

        if (filterExpression.filter.length > 0) {
            const response = await lastValueFrom(
                this.http.put<{ features: IFeatureDTO[] }>(
                    this.environmentService.featureApiUrl + featurePath,
                    filterExpression
                )
            );
            if (!response || !response.features) {
                return [];
            } else {
                return response && response.features && response.features.map(item => Feature.fromDTO(item));
            }
        } else {
            // ! This line used to be: $q.when([]); (without the 'return') which looked wrong.
            // ! during port it was changed to return a value.
            return Promise.resolve([]);
        }
    }

    public async getLocalGeometryAndCRS(projectId: string, feature: Feature): Promise<void> {
        let featurePath = '/projects/' + projectId + '/features';
        const filter = new FullFeatureFilter();
        const response = await lastValueFrom(
            this.http.get<{ features: Partial<IFeatureDTO>[] }>(this.environmentService.featureApiUrl + featurePath, {
                params: {
                    coordinateType: 'Local',
                    filter: JSON.stringify([filter.filter(Op.EQ, 'id', feature.id)]),
                    fields: 'geometry,crs'
                }
            })
        );
        if (response?.features?.length) {
            feature.addLocalGeomAndCRS(response.features[0]);
        }
    }

    public async transformCoordinatesFromLocalCrsToWorkspaceCrs(
        sourceCoordinateSystemId: string,
        targetCoordinateSystemId: string,
        coordinates: any
    ): Promise<L.LatLng> {
        const res = await lastValueFrom(
            this.http.post<number[][]>(
                this.environmentService.coordinatesApiUrl +
                    '/transforms/coordinates?sourceCoordinateSystemId=' +
                    sourceCoordinateSystemId +
                    '&targetCoordinateSystemId=' +
                    targetCoordinateSystemId,
                [coordinates]
            )
        );
        if (!res || !res.length) {
            return null;
        } else {
            return L.latLng(res[0][0], res[0][1], res[0][2]);
        }
    }

    public getGeometryTypes(projectId: string, layerIds: string[]): Promise<{ [layerId: string]: GeometryTypes }> {
        const featurePath = `/projects/${projectId}/features/layers/layerGeomType`;

        return lastValueFrom(
            this.http.put<{ [layerId: string]: GeometryTypes }>(
                this.environmentService.featureApiUrl + featurePath,
                layerIds
            )
        );
    }

    public async getGeometryTypesFromFeatures(projectId: string, layer: Layer): Promise<GeometryTypes> {
        const featurePath = `/projects/${projectId}/features`;

        const filter = new FullFeatureFilter();
        filter.layers = [layer];
        const queryParameters = {
            filter: JSON.stringify(filter.buildFilterRequest()),
            size: '1',
            fields: `metadata.${TemplatedFeatureMetadataProperty.GEOMETRY_TYPE}`
        };

        const response = await lastValueFrom(
            this.http.get<{ features: IFeatureDTO[] }>(this.environmentService.featureApiUrl + featurePath, {
                params: queryParameters
            })
        );
        if (response && response.features && response.features[0]) {
            const geometryType = response.features[0].metadata.geometry_type;
            return GeometryUtils.getGeometryType(geometryType);
        } else {
            return null;
        }
    }

    public getNonSpatialDataCountForLayer(
        projectId: string,
        workspaceId: string,
        layer: Layer,
        filters: FeatureFilter
    ): Promise<number> {
        let featurePath = '/projects/' + projectId + '/features/aggregations';

        const queryParameters = {
            field: `metadata.${TemplatedFeatureMetadataProperty.GEOMETRY_TYPE}`,
            aggregation: 'Count'
        };

        if (workspaceId) {
            const filter = new FullFeatureFilter(filters);
            filter.layers = [layer];
            filter.geometryType = 'none';
            Object.assign(queryParameters, { filter: JSON.stringify(filter.buildFilterRequest()) });
        }

        return lastValueFrom(
            this.http.get<number>(this.environmentService.featureApiUrl + featurePath, { params: queryParameters })
        );
    }

    public async getFeatureById(projectId: string, featureId: string): Promise<Feature> {
        let featurePath = '/projects/' + projectId + '/features/' + featureId;

        const response = await lastValueFrom(
            this.http.get<{ features: IFeatureDTO[] }>(this.environmentService.featureApiUrl + featurePath, {
                params: { coordinateType: 'Global' }
            })
        );
        if (response && response.features) {
            return Feature.fromDTO(response.features[0]);
        } else {
            return new Feature();
        }
    }

    public patchFeatureCollection(
        projectId: string,
        feature: IFeatureCollection
    ): Promise<{ features: IFeatureDTO[] }> {
        const featurePath = '/projects/' + projectId + '/features?echo=true';
        return lastValueFrom(
            this.http.patch<{ features: IFeatureDTO[] }>(this.environmentService.featureApiUrl + featurePath, feature)
        );
    }

    public async updateFeatureMetadata(importId: string, layer: Layer, projectId: string): Promise<Feature> {
        const filter = new FullFeatureFilter();
        const filterExpression = filter.buildFilterRequest();
        filterExpression.push(filter.filter(Op.EQ, `metadata.${TemplatedFeatureMetadataProperty.IMPORT_ID}`, importId));
        filterExpression.push(
            filter.filter(Op.EQ, `metadata.${TemplatedFeatureMetadataProperty.FILE_SOURCE_LAYER}`, layer.layerName)
        );

        const featurePath = '/projects/' + projectId + '/features/metadata';
        const reqBody = {
            common_layerId: layer.id
        };

        const response = await lastValueFrom(
            this.http.patch<IFeatureDTO>(this.environmentService.featureApiUrl + featurePath, reqBody, {
                params: { filter: JSON.stringify(filterExpression) }
            })
        );
        return Feature.fromDTO(response);
    }

    public async saveFeature(projectId: string, feature: IFeature): Promise<Feature> {
        const featurePath = '/projects/' + projectId + '/features';
        const response = await lastValueFrom(
            this.http.patch<IFeatureDTO>(this.environmentService.featureApiUrl + featurePath, feature)
        );
        return Feature.fromDTO(response);
    }

    public async deleteFeature(projectId: string, featureId: string): Promise<void> {
        const featurePath = '/projects/' + projectId + '/features/' + featureId;
        await lastValueFrom(this.http.delete(this.environmentService.featureApiUrl + featurePath));
    }

    public batchDeleteFeatures(projectId: string, features: IFeature[]): Promise<object> {
        const reqBody = {
            features: features.map(feature => ({
                id: feature.id
            }))
        };
        const formPath = '/projects/' + projectId + '/features/_delete';
        return lastValueFrom(this.http.post(this.environmentService.featureApiUrl + formPath, reqBody));
    }

    public getLatestFeatureUpdatedUtc(projectId: string, visibleLayers: Layer[]): Promise<string> {
        let featurePath = '/projects/' + projectId + '/features/aggregations/maxLastModified';
        const requestBody = visibleLayers.map(layer => layer.id);
        return lastValueFrom(this.http.put<string>(this.environmentService.featureApiUrl + featurePath, requestBody));
    }

    public getDistinctFeatureValues(
        projectId: string,
        filter: FullFeatureFilter,
        featureName: string
    ): Promise<string[]> {
        let featurePath = '/projects/' + projectId + '/features/aggregations';

        const queryParameters = {
            field: featureName,
            aggregation: 'Distinct'
        };

        if (filter) {
            Object.assign(queryParameters, { filter: JSON.stringify(filter.buildFilterRequest()) });
        }

        return lastValueFrom(
            this.http.get<string[]>(this.environmentService.featureApiUrl + featurePath, { params: queryParameters })
        );
    }

    public async getBoundsOfFeatures(projectId: string, filter: FullFeatureFilter): Promise<L.LatLngBounds> {
        let featurePath = '/projects/' + projectId + '/features/aggregations';
        let queryBody: { [x: string]: { field: string; operator: string; filter: any[] } } = {};

        const aggregation = {
            field: 'geometry',
            operator: 'GeoBounds',
            filter: filter.buildFilterRequest()
        };

        queryBody['bounds'] = aggregation;

        const requestBody = { aggregations: queryBody };

        const response = await lastValueFrom(
            this.http.put<{ aggregations: any }>(this.environmentService.featureApiUrl + featurePath, requestBody)
        );

        const result = response.aggregations;
        if (!result || !result.bounds || !result.bounds.topLeft || !result.bounds.bottomRight) {
            return null;
        }
        return new L.LatLngBounds(
            new L.LatLng(result.bounds.bottomRight.lat, result.bounds.topLeft.lon),
            new L.LatLng(result.bounds.topLeft.lat, result.bounds.bottomRight.lon)
        );
    }

    public async getBulkBoundsOfLayers(projectId: string, layers: Layer[]): Promise<{ [key: string]: L.LatLngBounds }> {
        let featurePath = '/projects/' + projectId + '/features/aggregations';

        const bulkBody: { [x: string]: { field: string; operator: string; filter: any[] } } = {};

        layers.forEach(layer => {
            const filter = new FullFeatureFilter();
            filter.layers = [layer];

            const queryBody = {
                field: 'geometry',
                operator: 'GeoBounds',
                filter: filter.buildFilterRequest()
            };

            bulkBody[layer.id] = queryBody;
        });

        const requestBody = { aggregations: bulkBody };

        const response = await lastValueFrom(
            this.http.put<{ aggregations: any }>(this.environmentService.featureApiUrl + featurePath, requestBody)
        );
        const boundsMap: { [layerId: string]: any } = {};
        for (const layerId in response.aggregations) {
            if (response.aggregations[layerId]) {
                const bound = response.aggregations[layerId];
                let bbox = null;
                if (!bound || !bound.topLeft || !bound.bottomRight) {
                    bbox = null;
                } else {
                    bbox = new L.LatLngBounds(
                        new L.LatLng(bound.bottomRight.lat, bound.topLeft.lon),
                        new L.LatLng(bound.topLeft.lat, bound.bottomRight.lon)
                    );
                }

                boundsMap[layerId] = bbox;
            }
        }
        return boundsMap;
    }

    public async getBulkMaxUpdatedUtcOfLayers(
        projectId: string,
        layers: Layer[]
    ): Promise<{ [layerId: string]: string }> {
        let path = '/projects/' + projectId + '/layers/lastModified';
        const layerIds = layers.map(layer => layer.id);

        const response = await lastValueFrom(
            this.http.put<GeoLayer>(this.environmentService.featureApiUrl + path, layerIds)
        );
        const lastUpdatedUtcPerLayer: { [layerId: string]: any } = {};
        for (const layerId in response) {
            if (response[layerId as keyof GeoLayer]) {
                lastUpdatedUtcPerLayer[layerId] = response[layerId as keyof GeoLayer];
            }
        }
        return lastUpdatedUtcPerLayer;
    }

    public getFeaturesWithPostProcessingStatusForActivityTimeline(
        projectId: string,
        workspaceId: string,
        startTime: Date,
        endTime: Date
    ): Observable<Feature[]> {
        const featurePath = `/projects/${projectId}/features`;
        const filter = new FullFeatureFilter();
        filter.workspaceId = workspaceId;

        const filterExpression = filter.buildFilterRequest();
        filterExpression.push({
            or: [
                filter.filter(
                    Op.GTEQ,
                    `metadata.${TemplatedFeatureMetadataProperty.COLLECTION_SYNC_DATE}`,
                    startTime.toISOString()
                ),
                filter.filter(
                    Op.GTEQ,
                    `metadata.${TemplatedFeatureMetadataProperty.COLLECTION_GEOMETRY_UTC}`,
                    startTime.toISOString()
                )
            ]
        });
        filterExpression.push({
            or: [
                filter.filter(
                    Op.LTEQ,
                    `metadata.${TemplatedFeatureMetadataProperty.COLLECTION_SYNC_DATE}`,
                    endTime.toISOString()
                ),
                filter.filter(
                    Op.LTEQ,
                    `metadata.${TemplatedFeatureMetadataProperty.COLLECTION_GEOMETRY_UTC}`,
                    endTime.toISOString()
                )
            ]
        });

        const queryParameters = {
            filter: JSON.stringify(filterExpression),
            // eslint-disable-next-line max-len
            fields: `metadata.${TemplatedFeatureMetadataProperty.POST_PROCESSED_STATUS},metadata.${TemplatedFeatureMetadataProperty.COMMON_LAYER_ID},metadata.${TemplatedFeatureMetadataProperty.COLLECTION_SYNC_DATE},metadata.${TemplatedFeatureMetadataProperty.COLLECTION_UPDATED_BY},metadata.${TemplatedFeatureMetadataProperty.COLLECTION_GEOMETRY_UTC}`
        };

        return this.http
            .get<{ features: IFeatureDTO[] }>(this.environmentService.featureApiUrl + featurePath, {
                params: queryParameters
            })
            .pipe(
                map(response => (response.features ? response.features.map(feature => Feature.fromDTO(feature)) : [])),
                catchError(() => of([]))
            );
    }

    public getPostProcessingEventsForFeature(url: string): Promise<PostProcessingEventSummary> {
        // Response is cached for up to a minute
        return lastValueFrom(this.http.get<PostProcessingEventSummary>(url));
    }

    private decodeOperandsInFilterArray(filterArray: FilterItem[]) {
        if (!filterArray) {
            return;
        }
        // Helper function to decode a string operand
        const decodeOperand = (operand: string) => {
            return StringUtils.containsEncodedComponents(operand)
                ? JSON.parse(`"${decodeURIComponent(operand)}"`)
                : operand;
        };

        // Helper function to handle an operand property
        const handleOperand = (operand: string | string[]) => {
            if (typeof operand === 'string') {
                return decodeOperand(operand);
            } else if (Array.isArray(operand)) {
                return operand.map(decodeOperand);
            }
        };

        // Loop over each item in the filter array
        for (let item of filterArray) {
            // If the item has an 'operand' property, handle it
            if (item.hasOwnProperty('operand')) {
                item['operand'] = handleOperand(item['operand']);
            }

            // If the item has an 'and' or 'or' property, recursively decode the operands in its value
            ['and', 'or'].forEach(prop => {
                if (item.hasOwnProperty(prop)) {
                    this.decodeOperandsInFilterArray(item[prop]);
                }
            });
        }
    }

    public addFeatureTag(featureId: string, projectId: string, tag: string): Promise<boolean> {
        const path = `/projects/${projectId}/features/tags?tag=${tag}`;
        const filter = new FullFeatureFilter();

        return lastValueFrom(
            this.http.patch<boolean>(this.environmentService.featureApiUrl + path, {
                filter: [filter.filter(Op.EQ, 'id', featureId)]
            })
        );
    }

    public addBulkFeatureTags(featureIds: string[], projectId: string, tags: string[]): Promise<boolean> {
        const path = `/projects/${projectId}/features/tags?tag=${tags.join(',')}`;
        const filter = new FullFeatureFilter();

        return lastValueFrom(
            this.http.patch<boolean>(this.environmentService.featureApiUrl + path, {
                filter: [filter.filter(Op.IN, 'id', featureIds)]
            })
        );
    }

    public removeFeatureTag(featureId: string, projectId: string, tag: string): Promise<boolean> {
        const path = `/projects/${projectId}/features/tags`;
        const filter = new FullFeatureFilter();

        return lastValueFrom(
            this.http.delete<boolean>(this.environmentService.featureApiUrl + path, {
                params: {
                    filter: JSON.stringify([filter.filter(Op.EQ, 'id', featureId)]),
                    tag: tag
                }
            })
        );
    }
}
