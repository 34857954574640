import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { HttpStatusCodes } from 'src/app/shared/common/http-status-codes';
import { ExportPollingStatusService } from 'src/app/shared/common/polling/export-polling-status.service';
import { Project } from 'src/app/shared/map-data-services/project/project';

import { ExportDownloaderService } from '../../export-downloader/export-downloader.service';
import { FileType, GeoExportJob } from '../export';
import { ExportService } from '../export.service';

@Component({
    selector: 'recent-exports',
    templateUrl: './recent-exports.component.html'
})
export class RecentExportsComponent implements OnInit, OnDestroy {
    @Input()
    // differs from currentMapWorkspace.Id when export menu is opened from workspace panel
    exportMapWorkspaceId: string;

    public reverseFileTypesDict = Object.keys(FileType).reduce((acc, key) => {
        Object.assign(acc, { [FileType[key as keyof typeof FileType]]: key });
        return acc;
    }, {});

    currentProject: Project;
    exportsData: GeoExportJob[];

    recentExportStatus = { Complete: 'TC.Common.Complete', InProgress: 'TC.Common.Status.InProgress' };
    isLoadingRecentExports = true;
    ButtonType = ButtonType;

    private destroyed = new Subject<void>();

    constructor(
        private exportService: ExportService,
        private exportDownloader: ExportDownloaderService,
        private projectStreamService: ProjectStreamService,
        private exportPollingStatusService: ExportPollingStatusService
    ) {}

    ngOnInit(): void {
        this.currentProject = this.projectStreamService.getCurrentProject();
        this.getAllExports();

        this.exportPollingStatusService.exportCompleted$.pipe(takeUntil(this.destroyed)).subscribe(() => {
            this.getAllExports();
        });
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    getAllExports(): void {
        this.exportService.getValidExports(this.currentProject.id, this.exportMapWorkspaceId).then(data => {
            this.isLoadingRecentExports = false;
            this.exportsData = data.items;
            this.exportsData?.sort((a, b) => (new Date(b.createdUtc) > new Date(a.createdUtc) ? 1 : -1));
            this.exportsData?.forEach(exportJob =>
                this.exportPollingStatusService.checkStatus(exportJob, exportJob.id)
            );
        });
    }

    formatFileSize(bytes: number) {
        const index = bytes === 0 ? 0 : Math.floor(Math.log(bytes) / Math.log(1024));
        return Number((bytes / Math.pow(1024, index)).toFixed(2)) + ' ' + ['B', 'kB', 'MB', 'GB'][index];
    }

    isDownloadDisabled(status: string) {
        return status !== 'Complete';
    }

    downloadButtonAction = (exportId: string) => this.downloadExport(exportId);

    async downloadExport(exportId: string): Promise<void> {
        const response = await this.exportDownloader.getExport(this.currentProject.id, exportId);
        if (response.status === HttpStatusCodes.OK && response.downloadLink) {
            window.location.href = response.downloadLink;
        }
    }
}
