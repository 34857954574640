<div class="workspace-area">
    <div class="workspace-area-body">
        <h4 class="sub-heading font-title">{{ 'MapViewer.Workspace.Area.Subtitle' | translate }}</h4>
        <div class="workspace-mini-map">
            <workspace-location
                [(map)]="map"
                (initialized)="onMapInitialized()"
                (layersLoaded)="uiState.forwardButton.enabled = $event"
            ></workspace-location>
        </div>
    </div>
    <div class="hint-text">
        {{ 'MapViewer.Workspace.Area.Description' | translate }}
    </div>
</div>
