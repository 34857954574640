<div class="styles-panel">
    <div class="styles-section">
        <h4 translate="TC.MetadataEditor.TemplateName"></h4>
        <form name="templateNameForm">
            <label class="styles-item-group">
                <input
                    #templatenameelement
                    #templatename="ngModel"
                    name="templatename"
                    type="text"
                    ondrop="return false"
                    class="gsp-form-input default"
                    required="required"
                    [(ngModel)]="template.name"
                    maxlength="100"
                    id="templatename"
                />
            </label>
            <div *ngIf="templatename.errors">
                <div class="h5" *ngIf="templatename.errors.required" translate="TC.Common.FieldRequired"></div>
                <div
                    class="h5"
                    *ngIf="templatename.errors.maxlength"
                    translate="TCS.TemplateEditor.Validate.MaxLength"
                    translate-values="{'maxLength': 100}"
                ></div>
            </div>
        </form>
    </div>
    <div class="styles-section">
        <h4 translate="TCS.TemplateEditor.PreviewFields"></h4>
        <div class="preview-field">
            <div id="summary-preview-tile">
                <div
                    class="image-dropdown-container thumbnailFieldIdContainer"
                    [hidden]="template.availableThumbnails.length <= 1"
                >
                    <gsp-dropdown
                        name="thumbnailFieldId"
                        [options]="template.availableThumbnails"
                        [(ngModel)]="template.review.thumbnailFieldId"
                        idField="uuid"
                        textField="displayName"
                    >
                    </gsp-dropdown>
                </div>
                <div
                    class="thumbnail"
                    [ngClass]="{ 'add-thumbnail': template.review.thumbnailFieldId === '(none)' }"
                    [hidden]="template.availableThumbnails.length <= 1"
                >
                    <div class="add-thumbnail-icon" *ngIf="template.review.thumbnailFieldId === '(none)'"></div>
                </div>
                <div class="field-data">
                    <geom-icon
                        path="sym_32-geom-"
                        [color]="template.geometryColorHexRGB"
                        [geoType]="template.geometryType"
                        *ngIf="!template.lastPublishedVersion && templateId !== TemplateTypeId.DIRECT_TEMPLATE"
                        >1</geom-icon
                    >
                    <div class="preview-wrapper">
                        <gsp-dropdown
                            name="line1FieldId"
                            [options]="template.availableSummaries"
                            [(ngModel)]="template.review.line1FieldId"
                            idField="uuid"
                            textField="displayName"
                            class="summary-dropdown"
                        ></gsp-dropdown>
                        <gsp-dropdown
                            name="line2FieldId"
                            [options]="template.availableSummaries"
                            [(ngModel)]="template.review.line2FieldId"
                            idField="uuid"
                            textField="displayName"
                            class="summary-dropdown"
                        ></gsp-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end preview group -->
    <div class="styles-section">
        <h4 translate="TC.Common.Settings"></h4>
        <div class="sub-heading">{{ 'TCS.TemplateEditor.Geometry' | translate }}</div>
        <div class="styles-item-group">
            <label translate="type"></label>
            <div class="type-value">
                <gsp-dropdown
                    *ngIf="!template.lastPublishedVersion && templateId !== TemplateTypeId.DIRECT_TEMPLATE"
                    name="geometryType"
                    [options]="geoTypes"
                    [(ngModel)]="template.geometryType"
                    idField="name"
                    textField="label"
                    [addIcon]="true"
                    (ngModelChange)="geometryTypeSelected($event)"
                    [ngDisabled]="template.lockedSchema"
                ></gsp-dropdown>
                <div *ngIf="template.lastPublishedVersion || templateId === TemplateTypeId.DIRECT_TEMPLATE">
                    {{ template.geometryType }}
                </div>
            </div>
        </div>
        <div
            class="styles-item-group"
            *ngIf="!template.lastPublishedVersion && templateId !== TemplateTypeId.DIRECT_TEMPLATE"
        >
            <label translate="TC.Common.Color"></label>
            <gsp-color-picker
                name="color"
                [colorHexCodes]="availableColors$ | async"
                [(ngModel)]="template.geometryColorHexRGB"
                [iconSize]="32"
                alignBy="horizontal"
                [maxColumns]="5"
                sortBy="hue"
                groupBy="lightness"
                [groupLimit]="5"
            >
            </gsp-color-picker>
        </div>

        <ng-container>
            <div class="sub-heading" *ngIf="template.geometryType === GeometryTypes.POINT">
                {{ 'TCS.TemplateEditor.CustomLoggingRates.PositionAveraging' | translate }}
            </div>
            <div
                class="sub-heading"
                *ngIf="template.geometryType === GeometryTypes.LINE || template.geometryType === GeometryTypes.AREA"
            >
                {{ 'TCS.TemplateEditor.CustomLoggingRates.VertexAveraging' | translate }}
            </div>

            <div class="styles-item-group number-positions">
                <label
                    for="positions-value"
                    translate="TC.Common.CustomLoggingRates.NumberOfPositions"
                    class="text-overflow"
                ></label>
                <gsp-number
                    [type]="GspNumberType.GENERIC"
                    [(ngModel)]="template.vertexNumberOfPositions"
                    (keyup)="validateField(NumericFieldTypes.POSITIONS)"
                    decimalPlaces="0"
                    autoComplete="off"
                    id="positions-value"
                ></gsp-number>
            </div>

            <div *ngIf="template.geometryType === GeometryTypes.LINE || template.geometryType === GeometryTypes.AREA">
                <div class="sub-heading">
                    {{ 'TC.Common.CustomLoggingRates.LoggingRate' | translate }}
                </div>

                <div class="styles-item-group flex-column logging-rate">
                    <div class="style-item-row styles-item-group logging-rate-time">
                        <div class="radio-div">
                            <gsp-radio
                                id="loggingRateTime"
                                name="loggingRateTime"
                                [ngModel]="isTimeLogging"
                                (click)="enableTimeLogging(true)"
                                [radioValue]="true"
                                radioLabelClass="radio-label-wrap"
                                radioInputClass="radio-input"
                                radioButtonClass="dark-radio-button"
                                class="ng-pristine ng-valid"
                            >
                            </gsp-radio>
                        </div>
                        <label
                            for="logging-rate-time-value"
                            (click)="enableTimeLogging(true)"
                            translate="TC.Common.Time"
                        ></label>
                        <gsp-number
                            [type]="GspNumberType.GENERIC"
                            [inputNgClass]="{ 'gsp-form-input': true, 'show-inactive': !isTimeLogging }"
                            [(ngModel)]="template.loggingRate.time.value"
                            [disabled]="!isTimeLogging"
                            (keyup)="validateField(NumericFieldTypes.TIME)"
                            decimalPlaces="0"
                            autoComplete="off"
                        ></gsp-number>
                        {{ 'TC.Common.Seconds' | translate }}
                    </div>
                    <div class="style-item-row logging-rate-distance">
                        <div class="radio-div">
                            <gsp-radio
                                id="loggingRateDistance"
                                name="loggingRateDistance"
                                [ngModel]="isTimeLogging"
                                (click)="enableTimeLogging(false)"
                                [radioValue]="false"
                                radioLabelClass="radio-label-wrap"
                                radioInputClass="radio-input"
                                radioButtonClass="dark-radio-button"
                                class="ng-pristine ng-valid"
                            >
                            </gsp-radio>
                        </div>
                        <label
                            for="logging-rate-distance-value"
                            (click)="enableTimeLogging(false)"
                            class="text-overflow"
                            >{{ 'TC.Common.Distance' | translate }}</label
                        >
                        <gsp-number
                            [type]="GspNumberType.GENERIC"
                            [inputNgClass]="{ 'gsp-form-input': true, 'show-inactive': isTimeLogging }"
                            [(ngModel)]="template.loggingRate.distance.value"
                            [disabled]="isTimeLogging"
                            (keyup)="validateField(NumericFieldTypes.DISTANCE)"
                            decimalPlaces="0"
                            autoComplete="off"
                        ></gsp-number>
                        <gsp-dropdown
                            name="distanceUnits"
                            [options]="distanceUnits"
                            [(ngModel)]="template.loggingRate.distance.unit"
                            idField="name"
                            textField="label"
                            [ngDisabled]="isTimeLogging"
                            id="logging-rate-distance-unit"
                        ></gsp-dropdown>
                    </div>
                </div>
            </div>
        </ng-container>

        <div class="styles-item-group flex-column">
            <gsp-checkbox
                *ngIf="digitizeEnabled"
                [(value)]="template.allowDigitizedPositions"
                [id]="'allowDigitizedPositions'"
                [label]="'TCS.TemplateEditor.DigitizePositions' | translate"
            ></gsp-checkbox>
            <gsp-checkbox
                [(value)]="template.geometryCollectionRequired"
                [disabled]="template.lockedSchema"
                [id]="'geometryCollectionRequired'"
                [label]="'TCS.TemplateEditor.CollectionRequired' | translate"
            ></gsp-checkbox>
            <div *ngIf="template.geometryCollectionRequired">
                <gsp-notification class="info">
                    {{ 'TCS.TemplateEditor.Tf.Tpl.MustCollectTitle' | translate }}
                </gsp-notification>
            </div>
            <gsp-checkbox
                [(value)]="template.requireMinimumAccuracy"
                [disabled]="template.lockedSchema"
                [id]="'requireMinimumAccuracy'"
                [label]="'TCS.TemplateEditor.RequireMinimumAccuracy' | translate"
            ></gsp-checkbox>
        </div>

        <div *ngIf="template.requireMinimumAccuracy" class="styles-item-group min-accuracy">
            <span class="chkbox-div"
                ><gsp-number
                    [type]="GspNumberType.GENERIC"
                    id="accuracy"
                    inputClass="small"
                    name="accuracyLimit"
                    title="{{ accuracyLimit }}"
                    [(ngModel)]="accuracyLimit"
                    (blurEvent)="validateField(NumericFieldTypes.REQUIRED_ACCURACY)"
                    [disabled]="template.lockedSchema"
                    decimalPlaces="2"
                ></gsp-number
                >&nbsp;{{ accuracyShortUnit | translate }}</span
            >
            <gsp-checkbox
                [(value)]="template.allowOverrideForAccuracyLimit"
                [disabled]="template.lockedSchema"
                [id]="'allowOverrideForAccuracyLimit'"
                [label]="'TC.Common.AllowOverride' | translate"
            ></gsp-checkbox>
        </div>
        <div
            class="contextSpecific accuracy"
            *ngIf="
                template.requireMinimumAccuracy &&
                (!accuracyLimitAsNumber || accuracyLimitAsNumber < minimumAccuracyRequired)
            "
        >
            <i class="icon-32-dark-device"></i>
            <gsp-notification class="info"
                >{{
                    (system === UnitSystem.METRIC
                        ? 'TCS.TemplateEditor.Tf.Tpl.AccuraryLimitWarningForMetric'
                        : 'TCS.TemplateEditor.Tf.Tpl.AccuraryLimitWarningForImperial'
                    ) | translate
                }}&nbsp;<a
                    target="_blank"
                    href="https://geospatial.trimble.com/products-and-solutions/gis-data-collection-integration"
                    translate="TC.Common.MoreInfo"
                ></a
            ></gsp-notification>
        </div>
        <div
            *ngIf="template.geometryType === GeometryTypes.POINT && loggingBehaviorEnabled"
            class="logging-behavior-container"
        >
            <div class="sub-heading">{{ 'TCS.TemplateEditor.LoggingBehavior' | translate }}</div>
            <div class="styles-item-group flex-column">
                <div class="style-item-row">
                    <div class="radio-div">
                        <gsp-radio
                            id="logPositionsAutomatically"
                            name="logPositionsAutomatically"
                            [ngModel]="template.logPositionsAutomatically"
                            (click)="setLogPositionsAutomatically(true)"
                            [radioValue]="true"
                            radioLabelClass="radio-label-wrap"
                            radioInputClass="radio-input"
                            radioButtonClass="dark-radio-button"
                            class="ng-pristine ng-valid"
                        >
                        </gsp-radio>
                    </div>
                    <label
                        for="logPositionsAutomatically"
                        (click)="setLogPositionsAutomatically(true)"
                        class="text-overflow"
                        title="{{ 'TCS.TemplateEditor.LogPositionAutomatically' | translate }}"
                        >{{ 'TCS.TemplateEditor.LogPositionAutomatically' | translate }}</label
                    >
                </div>
                <div class="style-item-row">
                    <div class="radio-div">
                        <gsp-radio
                            id="logPositionsManually"
                            name="logPositionsManually"
                            [ngModel]="template.logPositionsAutomatically"
                            (click)="setLogPositionsAutomatically(false)"
                            [radioValue]="false"
                            radioLabelClass="radio-label-wrap"
                            radioInputClass="radio-input"
                            radioButtonClass="dark-radio-button"
                            class="ng-pristine ng-valid"
                        >
                        </gsp-radio>
                    </div>
                    <label
                        for="logPositionsManually"
                        (click)="setLogPositionsAutomatically(false)"
                        class="text-overflow"
                        title="{{ 'TCS.TemplateEditor.LogPositionManually' | translate }}"
                        >{{ 'TCS.TemplateEditor.LogPositionManually' | translate }}</label
                    >
                </div>
            </div>
        </div>
        <div class="sub-heading">{{ 'TCS.TemplateEditor.RepeatingFields' | translate }}</div>
        <div class="styles-item-group flex-column">
            <div class="style-item-row">
                <div class="radio-div">
                    <gsp-radio
                        id="repeatFieldsAutomatically"
                        name="repeatFieldsAutomatically"
                        [ngModel]="template.repeatFieldsAutomatically"
                        (click)="setRepeatFieldsAutomatically(true)"
                        [radioValue]="true"
                        radioLabelClass="radio-label-wrap"
                        radioInputClass="radio-input"
                        radioButtonClass="dark-radio-button"
                        class="ng-pristine ng-valid"
                    >
                    </gsp-radio>
                </div>
                <label
                    for="repeatFieldsAutomatically"
                    (click)="setRepeatFieldsAutomatically(true)"
                    class="text-overflow"
                    title="{{ 'TCS.TemplateEditor.RepeatAutomatically' | translate }}"
                    >{{ 'TCS.TemplateEditor.RepeatAutomatically' | translate }}</label
                >
            </div>
            <div class="style-item-row">
                <div class="radio-div">
                    <gsp-radio
                        id="askUserConfirmation"
                        name="askUserConfirmation"
                        [ngModel]="template.repeatFieldsAutomatically"
                        (click)="setRepeatFieldsAutomatically(false)"
                        [radioValue]="false"
                        radioLabelClass="radio-label-wrap"
                        radioInputClass="radio-input"
                        radioButtonClass="dark-radio-button"
                        class="ng-pristine ng-valid"
                    >
                    </gsp-radio>
                </div>
                <label
                    for="askUserConfirmation"
                    (click)="setRepeatFieldsAutomatically(false)"
                    class="text-overflow"
                    title="{{ 'TCS.TemplateEditor.AskUserConfirmation' | translate }}"
                    >{{ 'TCS.TemplateEditor.AskUserConfirmation' | translate }}</label
                >
            </div>
        </div>
        <div class="sub-heading" translate="TC.Common.TemplateVariants"></div>
        <div class="styles-item-group">
            <gsp-checkbox
                [(value)]="template.useSeparateUpdateWorkflow"
                [disabled]="template.lockedSchema"
                [id]="'useSeparateUpdateWorkflow'"
                [label]="'TCS.TemplateEditor.TemplateVariants.EnableFieldUpdate' | translate"
            ></gsp-checkbox>
        </div>

        <div class="contextSpecific update">
            <gsp-notification class="info" *ngIf="template.useSeparateUpdateWorkflow">
                <p translate="TCS.TemplateEditor.AdvancedInfo"></p>
                <p translate="TCS.TemplateEditor.TemplateVariants.EnableUpdateBehaviourDesc"></p>
                <div class="updateOptions">
                    <i class="i24 icon-24-terraflex-required"></i>
                    <span translate="TCS.TemplateEditor.TemplateVariants.MandatoryDesc"></span>
                </div>
                <div class="updateOptions">
                    <i class="i24 icon-24-terraflex-read-only"></i>
                    <span translate="TCS.TemplateEditor.TemplateVariants.ReadOnlyDesc"></span>
                </div>
            </gsp-notification>
        </div>
    </div>
</div>
