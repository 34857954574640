<i
    *ngIf="field.repeatField === true && field.incrementRepeatField !== true"
    class="icon_repeat_field i24"
    title="{{ 'TCS.TemplateEditor.PropertyIcons.RepeatField.Title' | translate }}"
></i>
<i
    *ngIf="field.repeatField === true && field.incrementRepeatField === true"
    class="icon_increment_repeat_field i24"
    title="{{ 'TCS.TemplateEditor.PropertyIcons.IncrementRepeatValue.Title' | translate }}"
></i>
