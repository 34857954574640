<ng-form #labellerform="ngForm">
    <div class="label-holder" (click)="focusInput()">
        <span class="individual-label" *ngFor="let label of value; index as i" [ngClass]="{ invalid: !labelErrors[i] }"
            ><span>{{ label }}</span
            ><span class="close-tag" (click)="removeLabel(i)"><i class="i24 icon_line_cancel"></i></span
        ></span>
        <input
            #labelInput
            gsp-focus
            type="text"
            class="gsp-form-input medium new-label"
            [(ngModel)]="newLabel"
            (keyup)="addLabel($event)"
            (blur)="addLabel(null)"
            name="labelinput"
        />
    </div>
</ng-form>
