import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ShareWorkspaceData } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';

@Injectable({
    providedIn: 'root'
})
export class ShareWorkspaceStoreService {
    public shareWorkspaceLoadingStream = new BehaviorSubject<boolean>(true);

    public lockSharedWorkspaceStream = new BehaviorSubject<boolean>(false);

    public shareWorkspaceDataStream = new BehaviorSubject<ShareWorkspaceData>(null);

    constructor() {}
}
