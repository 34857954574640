import { Component, HostBinding, Input } from '@angular/core';

export enum NotificationType {
    Info = 'info',
    Success = 'success',
    Warning = 'warning',
    Critical = 'critical',
    Misc = 'misc',
    Error = 'error'
}

@Component({
    selector: 'gsp-notification',
    templateUrl: './gsp-notification.component.html'
})
export class GspNotificationComponent {
    @Input()
    @HostBinding('class')
    public type: NotificationType = NotificationType.Info;

    @Input()
    public heading: string;

    @HostBinding('class.notification')
    protected showNotification = true;

    // expose enum to template
    public NotificationType = NotificationType;
}
