import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { GspWizardService } from 'src/app/shared/common/components/gsp-wizard/gsp-wizard.service';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';

@Component({
    selector: 'workspace-browse-file',
    templateUrl: './workspace-browse-file.component.html'
})
export class WorkspaceBrowseFileComponent implements OnInit {
    source: MapWorkspace;

    public uiState = {
        backButton: { text: '', enabled: false, visible: false },
        forwardButton: { text: '', enabled: false, visible: false },
        closeButton: { text: '', enabled: true, visible: true },
        cancelButton: { text: '', enabled: false, visible: false }
    };

    public uploadDescription = '';

    constructor(private wizardService: GspWizardService, private translate: TranslationService) {}

    ngOnInit(): void {
        this.wizardService.setUI(this.uiState);
        this.uploadDescription = this.translate.instant(
            'TCS.Workspace.CreateWorkspaceFromTemplateBrowseFileDescription'
        );
    }
}
