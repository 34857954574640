import { TranslationService } from 'src/app/core/translation/translation.service';

import { RuleErrors } from './rule-errors';

export interface ConditionDisplayInfo {
    name: string;
    value: string;
    iconClass?: string;
    sideBarSign?: string;
}

export interface NameValue {
    name: string;
    value: string;
}

export enum ConditionOperator {
    IS_ANSWERED = 'IsAnswered',
    IS_EQUAL_TO = 'IsEqualTo',
    IS_NOT_EQUAL_TO = 'IsNotEqualTo',
    IS_GREATER_THAN_OR_EQUAL_TO = 'IsGreaterThanOrEqualTo',
    IS_LESS_THAN_OR_EQUAL_TO = 'IsLessThanOrEqualTo',
    IS_GREATER_THAN = 'IsGreaterThan',
    IS_LESS_THAN = 'IsLessThan',
    IS_EARLIER_THAN = 'IsEarlierThan',
    IS_LATER_THAN = 'IsLaterThan',
    IS_YES = 'IsYes',
    IS_NO = 'IsNo',
    EXACTLY_MATCHES = 'ExactlyMatches',
    IS_HAS_ANY_OF = 'IsHasAnyOf',
    IS_HAS_NONE_OF = 'IsHasNoneOf',
    NONE = 'None',
    AND = 'And',
    OR = 'Or'
}

export abstract class Condition {
    errors: {
        commonErrorNos: number[];
        labelErrors: (string | boolean)[];
        labelEmpty: boolean;
    } = {
        commonErrorNos: [],
        labelErrors: [],
        labelEmpty: false
    };
    comparisonValues?: any[];

    constructor(public sourceUuid = '', public type = 'Text') {}

    abstract getErrorMessagesNo(): number[];
    abstract getConditionText(translator: TranslationService, value?: string): string;
    abstract getConditionPair(): ConditionDisplayInfo[];

    // Get condition level error messages.
    getErrorMessages(): string[] {
        let sourceLevelErrorMessages: string[] = [];

        let sourceLevelErrorMessagesNo = this.getErrorMessagesNo();

        sourceLevelErrorMessagesNo.forEach((sourceLevelMessageNo: number) => {
            sourceLevelErrorMessages.push(RuleErrors.errorMessages[sourceLevelMessageNo]);
        });

        if (this.errors.commonErrorNos) {
            this.errors.commonErrorNos.forEach(commonErrorNo => {
                sourceLevelErrorMessages.push(RuleErrors.errorMessages[commonErrorNo]);
            });
        }
        return sourceLevelErrorMessages;
    }

    // Returns whether any common error present pertaining to this condition.
    isCommonError(): boolean {
        return this.errors.commonErrorNos && this.errors.commonErrorNos.length > 0;
    }

    // Add the required common message number to the error list
    addCommonErrorMessageNo(errorNo: number): void {
        if (this.errors.commonErrorNos.indexOf(errorNo)) {
            this.errors.commonErrorNos.push(errorNo);
        }
    }

    // Remove the required common message number from the error list
    removeCommonErrorMessageNo(errorNo: number): void {
        if (this.errors.commonErrorNos && this.errors.commonErrorNos.length) {
            this.errors.commonErrorNos.splice(this.errors.commonErrorNos.indexOf(errorNo));
        }
    }
}
