import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalSize } from 'src/app/shared/common/modal-sizes';

@Component({
    selector: 'template-schema-locked-warning',
    templateUrl: './template-schema-locked-warning.component.html'
})
export class TemplateSchemaLockedWarningComponent {
    public ModalSize = ModalSize;

    constructor(private router: Router, private route: ActivatedRoute) {}

    public close(): void {
        this.router.navigate(['../../editor'], { relativeTo: this.route, queryParamsHandling: 'preserve' });
    }
}
