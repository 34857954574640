import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';

import { Button, ButtonType } from './button';

/**
 * gspButton
 * For simplifying the adding of buttons across the app. Can be used for a simple button with click action
 * or a click action followed by a confirmation prompt, with or with a 'busy' option to disable while action
 * completes.
 *
 * Can only be used as an element in order to prevent problems with nested buttons when using the double
 * confirmation option.
 *
 * <gsp-button
 *      buttonTooltip="{{'DeleteYourCurrentLocation_ID' | translate}}"
 *      (action)="deleteLocation()"
 *      [asyncAction]="deleteLocation()" - use this if you want to wait on the response
 *      [type]="ButtonType.Default" - for other options: see button.ts
 *      text="{{buttonText | translate}}"
 *      [iconClass]="'icon-32-dark-field-image'"
 *      [showBusyIndicator]="true" - use asyncAction which must return a promise if set to true
 * </gsp-button>
 */
@Component({
    selector: 'gsp-button',
    templateUrl: './gsp-button.component.html'
})
export class GspButtonComponent implements AfterViewInit {
    @Input()
    public text = '';

    @Input()
    public iconClass = '';

    @Input()
    public buttonTooltip = '';

    // alternative to click handler which show's busy indicator
    @Input()
    public asyncAction: () => PromiseLike<any>;

    @Input()
    public showBusyIndicator = false;

    @Input() disabled = false;
    @Output() action = new EventEmitter<MouseEvent>();

    @Input()
    public set type(type: ButtonType) {
        this._type = type;
        this.buttonCssClass = Button.buttonClass(type);
    }
    public get type(): ButtonType {
        return this._type;
    }

    public _id = '';
    @Input()
    public set id(id: string) {
        if (id) {
            this._id = id + '-button';
        }
    }
    public get id(): string {
        return this._id;
    }

    @ViewChild('button')
    buttonsElemRef: ElementRef;

    public showBusy = false;
    public busyCssClass = '';
    private _type = ButtonType.Default;
    public buttonCssClass = Button.buttonClass(this._type);

    // expose enum to template
    public ButtonType = ButtonType;

    constructor(private renderer: Renderer2) {}

    public onClick($event: MouseEvent): void {
        if (this.asyncAction && !this.showBusy) {
            this.showBusy = true;
            this.asyncAction().then(
                () => (this.showBusy = false),
                () => (this.showBusy = false)
            );
        } else {
            this.action.emit($event);
        }
    }

    ngAfterViewInit(): void {
        if (this._id) {
            this.renderer.setAttribute(this.buttonsElemRef.nativeElement, 'id', this._id);
        }
    }
}
