import { CdkDropList } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';
import { Application } from 'src/app/shared/map-data-services/application';
import { AutoFieldModelType, FieldType, LayoutFieldType } from 'src/app/shared/template-services/field';
import { FieldsStoreService } from 'src/app/shared/template-services/field/fields-store.service';
import { Rule } from 'src/app/shared/template-services/rule/rule';
import { Template } from 'src/app/shared/template-services/template';

import { ConnectedDropListService } from '../connected-drop-list.service';
import { RuleSelectionStreamService } from '../rules-panel/rule-selection-stream.service';

@Component({
    selector: 'field-panel',
    templateUrl: './field-panel.component.html'
})
export class FieldPanelComponent implements OnInit, OnDestroy {
    private destroyed = new Subject<void>();

    @Input()
    template: Template;

    @Input()
    templateEditorConfig: { tabs: { fields: { fieldsGroup: any[]; enableFieldConditions: { [key: string]: any } } } };

    @Input()
    application: Application;

    connectedLists$: Observable<CdkDropList[]>;

    @ViewChildren(CdkDropList)
    dropLists: QueryList<CdkDropList>;

    groupedFieldList: { [key: string]: any } = {};
    collapsed = true;
    selectedRule: Rule;
    fieldList: { [key: string]: any };

    constructor(
        private connectedDropLists: ConnectedDropListService,
        private ruleSelectionStream: RuleSelectionStreamService,
        private fieldsStore: FieldsStoreService,
        private translate: TranslationService
    ) {
        this.ruleSelectionStream.ruleSelectionStream.pipe(takeUntil(this.destroyed)).subscribe(selectedRule => {
            this.selectedRule = selectedRule;
        });

        this.connectedLists$ = this.connectedDropLists.allLists$;
    }

    ngOnInit(): void {
        this.fieldsStore.getFieldsMap(this.application.name, this.application.category).then(fieldsMap => {
            this.templateEditorConfig.tabs.fields.fieldsGroup.forEach(fieldGroup => {
                this.groupedFieldList[fieldGroup.id] = {};
                this.groupedFieldList[fieldGroup.id].collapsable = false;
                this.groupedFieldList[fieldGroup.id].groupName = fieldGroup.label;
                this.groupedFieldList[fieldGroup.id].items = {};

                fieldGroup.fields.map((fieldInGroup: string) => {
                    let selectedField = fieldsMap[fieldInGroup];
                    if (selectedField) {
                        let fieldDetail = {
                            type: selectedField.fieldType,
                            name: selectedField.application.layoutSchema?.label,
                            iconClass: selectedField.application.layoutSchema?.iconClass,
                            collapsable: selectedField.application.layoutSchema?.collapsable,
                            representsMultipleFields: selectedField.application.layoutSchema?.representsMultipleFields
                        };
                        this.groupedFieldList[fieldGroup.id].collapsable =
                            this.groupedFieldList[fieldGroup.id].collapsable ||
                            fieldsMap[fieldInGroup].application.layoutSchema?.collapsable;
                        this.groupedFieldList[fieldGroup.id].items[fieldInGroup] = fieldDetail;
                    }
                });
            });

            this.fieldList = CloneUtils.cloneDeep(this.groupedFieldList);
        });

        this.template.findIncompleteRule('Fields', this.selectedRule);
    }

    noEntry(): boolean {
        return false;
    }

    addFieldToTemplate(field: {
        type: FieldType | LayoutFieldType | AutoFieldModelType;
        subType: string;
        representsMultipleFields: boolean;
    }): void {
        if (!this.disableField(field)) {
            if (field.type === FieldType.Sensor) {
                this.fieldsStore.currentApplication.next(this.application);
                this.fieldsStore.currentTemplate.next(this.template);
                this.fieldsStore.draggedSensorDataStream.next(null);
                this.fieldsStore.openPopupTo('template/editor/sensor-options');
                return;
            }

            if (field.representsMultipleFields) {
                this.fieldsStore.getFieldsMap(this.application.name, this.application.category).then(fieldsMap => {
                    Object.values(fieldsMap).forEach(fieldDef => {
                        if (fieldDef.application.layoutSchema.representedByFieldType === field.type) {
                            this.template.insertNewField(
                                this.fieldsStore,
                                this.translate,
                                this.application,
                                fieldDef.fieldType,
                                null
                            );
                        }
                    });
                });
            } else {
                this.template.insertNewField(
                    this.fieldsStore,
                    this.translate,
                    this.application,
                    field.type,
                    field.subType
                );
            }
            if (field.type === LayoutFieldType.PageHeader) {
                this.checkAndInsertTopPageHeader();
            }
        }
    }

    toggleCollapse(): void {
        this.collapsed = !this.collapsed;
    }

    useFieldOrder(): number {
        // sort function for keyValue pipe.  returning 0 means it will use field order
        return 0;
    }

    checkAndInsertTopPageHeader(): void {
        if (this.template.fields.length && this.template.fields[0].type !== LayoutFieldType.PageHeader) {
            this.template.insertNewField(
                this.fieldsStore,
                this.translate,
                this.application,
                LayoutFieldType.PageHeader,
                LayoutFieldType.PageHeader,
                [0],
                null,
                1
            );
        }
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    includeField(field: { type: string; collapsable: any }): boolean {
        let enableFieldCondition = this.templateEditorConfig.tabs.fields.enableFieldConditions[field.type];
        return (
            (!field.collapsable || !this.collapsed) &&
            (!enableFieldCondition ||
                this.template[enableFieldCondition.condition.field as keyof Template] ===
                    enableFieldCondition.condition.value)
        );
    }

    disableField(field: { type: string; subType?: string }): boolean {
        if (this.template.lockedSchema) {
            // this will disable creation inside a nested fields from panel
            if (field.type === LayoutFieldType.PageHeader || field.type === LayoutFieldType.Group) {
                return false;
            }

            return true;
        }
        return false;
    }
}
