import 'src/app/shared/leaflet-extensions/leaflet-patches/draggable-without-shiftkey.js';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import * as _ from 'lodash-es';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { GspLoggerService } from 'src/app/log-handler.service';
import { EnvironmentService } from 'src/app/shared/common/environment/environment.service';

import { BaseMapMode } from './base-maps/base-map-mode';
import { BaseMapProvider, TrimbleBaseMapProvider } from './base-maps/base-map-provider';
import { TileClusterLayerService } from './map-container/feature-map-layers/tile-cluster-layer.service';

@Injectable({ providedIn: 'root' })
export class MapService {
    public baseMapProviders: BaseMapProvider[] = [];
    public selectedBaseMapProvider: BaseMapProvider = null;
    public selectedBaseMapMode: BaseMapMode;
    public maps: { [mapId: string]: L.Map } = {};
    public mapReadyStreams: { [mapId: string]: BehaviorSubject<boolean> } = {};
    public baseMapReadyStream = new BehaviorSubject<boolean>(false);
    public basemapChangedStream = new ReplaySubject<boolean>(1);

    constructor(
        tilelayer: TileClusterLayerService,
        private env: EnvironmentService,
        private http: HttpClient,
        private logger: GspLoggerService
    ) {
        if (!L.TileClusterLayer) {
            tilelayer.extendLeaflet();
        }
    }

    // -------------
    // BASE MAP PROVIDER INITIALIZATION

    public intializeBaseMapProvider(): BaseMapProvider[] {
        const baseMapProvider = new TrimbleBaseMapProvider();
        this.baseMapProviders = [baseMapProvider]; // only have one at the moment
        return this.baseMapProviders;
    }

    // -------------
    // MAP INITIALIZATION

    public intializeMap(
        element: HTMLElement,
        baseMapProviderId: string,
        baseMapModeId: string,
        options: L.MapOptions = {}
    ): L.Map {
        let strictBounds = new L.LatLngBounds(
            new L.LatLng(90, -720), // top left corner of map
            new L.LatLng(-90, 720) // bottom right corner
        );
        let defaultOption = {
            minZoom: 3,
            maxZoom: 21,
            zoomControl: false,
            maxBounds: strictBounds,
            worldCopyJump: true,
            inertiaDeceleration: 10000,
            inertiaMaxSpeed: 5000,
            boxZoom: false,
            doubleClickZoom: false,
            editable: true, // enables leaflet.editable mixin
            editOptions: {
                // options for leaflet.editable mixin (see map-draw-actions.component.ts)
                skipMiddleMarkers: true,
                lineGuideOptions: {
                    color: '#646464'
                },
                drawingCursor: ''
            }
        };
        // if map already exists for some reason remove it before initialising again
        // to ensure that event listeners on existing map are removed
        if (this.maps[element.id] && _.isFunction(this.maps[element.id].remove)) {
            this.maps[element.id].remove();
        }
        defaultOption = L.Util.extend(defaultOption, options);
        // Note: minZoom set to 3 to avoid repeated parts of world (which causes issue with duplicated features/clusters)
        this.maps[element.id] = L.map(element, defaultOption).setView([34.514478, -40.847168], 4);
        this.maps[element.id].attributionControl?.setPrefix(false);

        if (element.id === 'mini-map' || element.id === 'workspace-mini-map') {
            this.setMiniMap(this.maps[element.id], baseMapProviderId, baseMapModeId);
        }

        this.mapReadyStreams[element.id] = new BehaviorSubject<boolean>(false);

        return this.maps[element.id];
    }

    private setMiniMap(miniMap: L.Map, baseMapProviderId: string, baseMapModeId: string): void {
        let miniMapProvider = _.find(this.baseMapProviders, {
            id: baseMapProviderId
        });
        if (miniMapProvider) {
            let miniMapModes = miniMapProvider.baseMapModes;
            let tmpMiniMapMode = _.find(miniMapModes, {
                id: baseMapModeId
            });
            let miniMapMode = tmpMiniMapMode ? tmpMiniMapMode : miniMapModes[0];
            let thisMap = miniMap as L.Map & { baseMap: L.Layer };
            if (thisMap && thisMap.baseMap && miniMap.hasLayer(thisMap.baseMap)) {
                miniMap.removeLayer(thisMap.baseMap);
            }
            miniMapMode.attribution = '';
            thisMap.baseMap = miniMapProvider.createBaseMapLayer(miniMapMode);
            miniMap.addLayer(thisMap.baseMap);
        }
    }

    public getBaseMapMode(): BaseMapMode {
        return this.selectedBaseMapMode;
    }

    public setBaseMapMode(baseMapProviderId: string, baseMapModeId: string): void {
        let selectedBaseMapProviderId = this.selectedBaseMapProvider ? this.selectedBaseMapProvider.id : null;
        let selectedBaseMapModeId = this.selectedBaseMapMode ? this.selectedBaseMapMode.id : null;
        if (selectedBaseMapProviderId !== baseMapProviderId) {
            this.selectedBaseMapProvider = _.find(this.baseMapProviders, { id: baseMapProviderId });
            if (this.selectedBaseMapProvider) {
                this.changeBaseMap(baseMapModeId);
            }
        } else if (selectedBaseMapProviderId === baseMapProviderId && selectedBaseMapModeId !== baseMapModeId) {
            this.changeBaseMap(baseMapModeId);
        }
    }

    private changeBaseMap(baseMapModeId: string): void {
        let baseMapModes = this.selectedBaseMapProvider.baseMapModes;
        let tmpbaseMapMode = _.find(baseMapModes, {
            id: baseMapModeId
        });
        this.selectedBaseMapMode = tmpbaseMapMode ? tmpbaseMapMode : baseMapModes[0];
        Object.keys(this.maps).forEach(mapId => {
            if (mapId !== 'mini-map') {
                // Don't change the map provider for mini-map container
                this.baseMapReadyStream.next(true);
                //  add base map and remove existing base map
                let thisMap = this.maps[mapId] as L.Map & { baseMap: L.Layer };
                if (thisMap && thisMap.baseMap && thisMap.hasLayer(thisMap.baseMap)) {
                    this.maps[mapId].removeLayer(thisMap.baseMap);
                }
                thisMap.baseMap = this.selectedBaseMapProvider.createBaseMapLayer(this.selectedBaseMapMode);
                this.maps[mapId].addLayer(thisMap.baseMap);
            }
        });

        this.basemapChangedStream.next(true);
    }
}
