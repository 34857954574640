<div class="ng-scrollbar-wrapper" [ngAttr]="state">
  <div class="ng-scroll-viewport-wrapper"
       (resizeSensor)="update()"
       [sensorDebounce]="sensorDebounce"
       [sensorDisabled]="sensorDisabled">
    <div scrollViewport
         hideNativeScrollbar>
      <div>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!disabled">
    <scrollbar-x #scrollbarX
                 *ngIf="state.horizontalUsed"
                 [attr.scrollable]="state.isHorizontallyScrollable"
                 [attr.fit]="state.verticalUsed">
    </scrollbar-x>
    <scrollbar-y #scrollbarY
                 *ngIf="state.verticalUsed"
                 [attr.scrollable]="state.isVerticallyScrollable"
                 [attr.fit]="state.horizontalUsed">
    </scrollbar-y>
  </ng-container>
</div>

