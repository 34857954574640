import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ACCESS_MODE_KEY, AccessMode } from 'src/app/core/authentication/share-token';
import { EnvironmentService } from 'src/app/shared/common/environment/environment.service';

import { Style } from './style';

@Injectable({
    providedIn: 'root'
})
export class StyleService {
    constructor(private http: HttpClient, private env: EnvironmentService) {}

    public getStyles(projectId: string): Promise<Style[]> {
        let stylePath = this.env.apiUrl + '/projects/' + projectId + '/styles';

        return lastValueFrom(this.http.get<{ items: Style[] }>(stylePath)).then(response =>
            response.items.map(item => new Style(item))
        );
    }

    public getStyleById(projectId: string, id: string): Promise<Style> {
        let stylePath = this.env.apiUrl + '/projects/' + projectId + '/styles/' + id;
        return lastValueFrom(this.http.get<Style>(stylePath)).then(response => new Style(response));
    }

    public createStyle(projectId: string, data: any): Promise<Style> {
        let stylePath = this.env.apiUrl + '/projects/' + projectId + '/styles';
        if (sessionStorage.getItem(ACCESS_MODE_KEY) && sessionStorage.getItem(ACCESS_MODE_KEY) === AccessMode.SHARED) {
            data.id = '123';
            return Promise.resolve(new Style(data));
        }
        return lastValueFrom(this.http.post<Style>(stylePath, data)).then(response => new Style(response));
    }

    public updateStyle(projectId: string, id: string, data: any): Promise<Style> {
        let stylePath = this.env.apiUrl + '/projects/' + projectId + '/styles/' + id;
        return lastValueFrom(this.http.put<Style>(stylePath, data)).then(response => new Style(response));
    }
}
