import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { GspWizardService } from 'src/app/shared/common/components/gsp-wizard/gsp-wizard.service';
import { ShareWorkspaceData } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';

import { WorkspaceService } from '../../workspace.service';

@Component({
    selector: 'workspace-scanned-file',
    templateUrl: './workspace-scanned-file.component.html'
})
export class WorkspaceScannedFileComponent implements OnInit, OnDestroy {
    public scannedFileDescription = '';
    public uploadedWorkspace: ShareWorkspaceData;

    private uiState = {
        forwardButton: { text: 'TC.Common.Next', enabled: true, visible: true },
        backButton: { text: 'TC.Common.Back', enabled: false, visible: false },
        closeButton: { text: '', enabled: true, visible: true },
        cancelButton: { text: 'TC.Common.Cancel', enabled: true, visible: true }
    };

    private destroyed$: Subject<void> = new Subject<void>();

    constructor(
        private wizardService: GspWizardService,
        private translate: TranslateService,
        private workspaceCreationService: WorkspaceService
    ) {}

    public ngOnInit(): void {
        this.wizardService.setUI(this.uiState);
        this.scannedFileDescription = this.translate.instant(
            'TCS.Workspace.CreateWorkspaceFromTemplateBrowseFileDescription'
        );

        this.uploadedWorkspace = this.workspaceCreationService.uploadWorkspaceTemplate$.getValue();
    }

    public ngOnDestroy(): void {
        this.destroyed$.next(null);
    }
}
