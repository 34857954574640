<section class="editor-field-section">
    <div class="grouped-elements">
        <field-list
            [templateEditorConfig]="templateEditorConfig"
            noNestedGroups="true"
            [currentProjectDetails]="currentProjectDetails"
            [application]="application"
            [parentField]="field"
            [fields]="field.fields"
            [template]="template"
            [currentTab]="currentTab"
            [selectedField]="selectedField"
            (fieldSelected)="fieldSelected.emit($event)"
            [parentIndex]="parentIndex"
        ></field-list>
    </div>
</section>
