<div id="search-tool" class="search ng-scope">
    <input
        class="input-text search-box"
        id="search-box"
        (keyup)="handleKeyEvents($event)"
        type="text"
        placeholder="{{ 'TCS.Common.EnterLocation' | translate }}"
        (ngModelChange)="searchChanged$.next($event)"
        [(ngModel)]="searchText"
        autocomplete="none"
    />
    <ul class="places">
        <li
            *ngFor="let place of places"
            [ngClass]="{ active: place.shortString === (selectedLocation && selectedLocation.shortString) }"
            (click)="locatePlaceInMap(place)"
            title="{{ place.shortString }}"
            [innerHTML]="place.shortString"
        ></li>
    </ul>
</div>
