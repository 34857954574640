<header>
    <h2>{{ 'TODO' | translate }}</h2>

    <div [ngClass]="{ 'task-loading': loading }" *ngIf="activeTasks.length">
        <i
            title="{{ showDataLayerTitle | translate }}"
            class="task-view i32"
            [ngClass]="{
                icon_line_visibility_hide_others: showDataLayers,
                icon_line_visibility_show_all: !showDataLayers
            }"
            (click)="toggleShowDataLayers()"
        ></i>
    </div>
</header>

<div class="no-todo tip" *ngIf="!loading && !activeTasks.length && !closedTasks.length && !isTaskSearchActive">
    <div>
        <span>{{ 'TC.Common.Tip' | translate }}</span
        >:&nbsp;{{ 'TCS.Todo.Todo.NoTodoFound' | translate }}
    </div>
    <img src="assets/images/no-todo.png" alt="" />
</div>

<ul class="task-tabs" *ngIf="activeTasks.length || closedTasks.length || isTaskSearchActive">
    <li [ngClass]="{ 'active-tab': currentTab === TaskDTOStatus.ACTIVE }" (click)="setCurrentTab(TaskDTOStatus.ACTIVE)">
        <h3 id="tasks-active" translate="TC.Common.Active"></h3>
    </li>
    <li [ngClass]="{ 'active-tab': currentTab === TaskDTOStatus.CLOSED }" (click)="setCurrentTab(TaskDTOStatus.CLOSED)">
        <h3 id="tasks-closed" translate="CLOSED"></h3>
    </li>
</ul>

<div
    class="task-search"
    *ngIf="
        isTaskSearchActive ||
        (currentTab === TaskDTOStatus.ACTIVE && activeTasks.length) ||
        (currentTab === TaskDTOStatus.CLOSED && closedTasks.length)
    "
>
    <div class="task-search-bar">
        <input
            type="text"
            [formControl]="taskSearchControl"
            placeholder="{{
                currentTab === TaskDTOStatus.ACTIVE
                    ? ('MapViewer.Todo.Search.Active.Placeholder' | translate)
                    : ('MapViewer.Todo.Search.Closed.Placeholder' | translate)
            }}"
            class="gsp-form-input disabled-txt"
        />
        <div class="search-icon">
            <i class="icon_line_search_white i24"> </i>
        </div>
    </div>
</div>

<div class="scroll-wrapper">
    <custom-scroll>
        <ul class="list-content" *ngIf="currentTab === TaskDTOStatus.ACTIVE">
            <li class="no-hover" *ngIf="loading">
                <loading></loading>
            </li>
            <ng-container *ngIf="!loading">
                <div class="panel-notification" *ngIf="activeTasks.length > 99">
                    <gsp-notification [type]="NotificationType.Info">
                        {{ 'MapViewer.Todo.Search.LimitNotification' | translate }}
                    </gsp-notification>
                </div>

                <div class="panel-notification" *ngIf="isTodoVisibilityRestricted">
                    <gsp-notification [type]="NotificationType.Info">
                        {{ 'MapViewer.Todo.Visibility.Notification' | translate }}
                    </gsp-notification>
                </div>

                <li
                    (click)="setSelectedTask(task)"
                    *ngFor="let task of activeTasks"
                    [ngClass]="{ selected: task.selected, 'task-loading': task.loading }"
                    (contextmenu)="activeTaskContextMenu.onContextMenu($event, task)"
                    class="todo-item"
                >
                    <!-- div class="layer-sym"></div -->
                    <div class="task-edit">
                        <donut
                            [size]="32"
                            [alertColor]="'#5E9331'"
                            [warningColor]="'#5E9331'"
                            [dangerColor]="'#5E9331'"
                            [index]="TaskDTOStatus.ACTIVE + task.id"
                            [percent]="task.percentageComplete"
                        ></donut>
                    </div>
                    <div class="task-title">
                        <div class="heading" title="{{ task.title }}">{{ task.title }}</div>
                        <div class="description" title="{{ task.description }}">{{ task.description }}</div>
                    </div>
                    <div class="task-count">{{ task.totalCollectedFeatures }}/{{ task.totalFeatures }}</div>
                    <div
                        class="task-view"
                        (click)="toggleVisibleTask(task, $event)"
                        [ngClass]="{ hide_layer: !task.visible }"
                    >
                        <i
                            class="i24"
                            [ngClass]="{
                                icon_line_visibility_show: task.visible,
                                icon_line_visibility_hide: !task.visible
                            }"
                        ></i>
                    </div>
                </li>
            </ng-container>
        </ul>
        <gsp-context-menu #activeTaskContextMenu [items]="openTaskContextMenuItems"></gsp-context-menu>

        <ul class="list-content" *ngIf="currentTab === TaskDTOStatus.CLOSED">
            <li class="no-hover" *ngIf="loading">
                <loading></loading>
            </li>
            <ng-container *ngIf="!loading">
                <div class="panel-notification" *ngIf="closedTasks.length > 99">
                    <gsp-notification [type]="NotificationType.Info">
                        {{ 'MapViewer.Todo.Search.LimitNotification' | translate }}
                    </gsp-notification>
                </div>

                <div class="panel-notification" *ngIf="isTodoVisibilityRestricted">
                    <gsp-notification [type]="NotificationType.Info">
                        {{ 'MapViewer.Todo.Visibility.Notification' | translate }}
                    </gsp-notification>
                </div>

                <!-- <li class="no-hover" *ngIf="!loading && !closedTasks.length">{{ 'TCS.Todo.Todo.NoTodoFound' | translate}}
                                </li> -->

                <li
                    (click)="setSelectedTask(task)"
                    *ngFor="let task of closedTasks"
                    [ngClass]="{ selected: task.selected, 'task-loading': task.loading }"
                    (contextmenu)="closedTaskContextMenu.onContextMenu($event, task)"
                    class="todo-item"
                >
                    <!-- div class="layer-sym"></div -->
                    <div class="task-edit">
                        <donut
                            [size]="32"
                            [alertColor]="'#5E9331'"
                            [warningColor]="'#5E9331'"
                            [dangerColor]="'#5E9331'"
                            [index]="TaskDTOStatus.CLOSED + task.id"
                            [percent]="task.percentageComplete"
                        ></donut>
                    </div>
                    <div class="task-title">
                        <div class="heading" title="{{ task.title }}">{{ task.title }}</div>
                        <div class="description" title="{{ task.description }}">{{ task.description }}</div>
                    </div>
                    <div class="task-count">({{ task.totalCollectedFeatures }}/{{ task.totalFeatures }})</div>
                    <div
                        class="task-view"
                        (click)="toggleVisibleTask(task, $event)"
                        [ngClass]="{ hide_layer: !task.visible }"
                    >
                        <i
                            class="i24"
                            [ngClass]="{
                                icon_line_visibility_show: task.visible,
                                icon_line_visibility_hide: !task.visible
                            }"
                        ></i>
                    </div>
                </li>
            </ng-container>
        </ul>

        <gsp-context-menu #closedTaskContextMenu [items]="closedTaskContextMenuItems"></gsp-context-menu>
    </custom-scroll>
</div>
