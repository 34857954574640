import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AutoFieldModelType, Field, FieldType, LayoutFieldType } from 'src/app/shared/template-services/field';

import { ChartPanelStreamService } from '../../common/chart-panel-stream.service';

export interface FeaturePanelTab {
    name: string;
    displayName: string;
    fields: Field[];
}

@Component({
    templateUrl: './feature-with-template.component.html',
    selector: 'feature-with-template'
})
export class FeatureWithTemplateComponent implements OnChanges {
    @Input()
    fields: any[];

    @Input()
    totalPages: number;

    @Input()
    editMode: boolean;

    tabFields: { [name: string]: FeaturePanelTab } = {};
    activeTab = '';
    pageHeaderTitles: string[] = [];
    scrollToLeft = false;

    // exposing enum to template
    public FieldType = FieldType;
    public LayoutFieldType = LayoutFieldType;

    public summaryPanelActiveMap$: BehaviorSubject<{ [fieldId: string]: boolean }>;

    groupAutoFields = [
        FieldType.Autofield,
        AutoFieldModelType.GeometryLength,
        AutoFieldModelType.GeometryArea,
        AutoFieldModelType.CollectedBy,
        AutoFieldModelType.EstimatedAccuracy,
        AutoFieldModelType.CreationDateTime,
        AutoFieldModelType.UpdateDateTime,
        AutoFieldModelType.DeviceType,
        AutoFieldModelType.CorrectionSource,
        AutoFieldModelType.CorrectionStatus,
        AutoFieldModelType.DeviceID,
        AutoFieldModelType.GeometryCaptureType,
        AutoFieldModelType.PDOP,
        AutoFieldModelType.HDOP,
        AutoFieldModelType.Todo,
        AutoFieldModelType.EstimatedVerticalPrecision,
        AutoFieldModelType.Workspace,
        AutoFieldModelType.Increment,
        AutoFieldModelType.XPosition,
        AutoFieldModelType.YPosition,
        AutoFieldModelType.ZPosition
    ];

    constructor(private chartPanelStreamService: ChartPanelStreamService) {
        this.summaryPanelActiveMap$ = this.chartPanelStreamService.summaryPanelActiveMapStream;
    }

    isGroupAutoField(field: any): boolean {
        return this.groupAutoFields.indexOf(field.type) !== -1;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.editMode || changes.fields) {
            // refresh pageHeaderTabs to rerender the custom scroll component on fields change
            this.pageHeaderTitles = [];
            this.scrollToLeft = false;
            setTimeout(() => {
                this.setupTabsFromPageHeaders();
            });
            // resetting the scroll to left on active feature/fields change
            setTimeout(() => {
                this.scrollToLeft = true;
            });
        }
    }

    setupTabsFromPageHeaders(): void {
        this.tabFields = {};
        let tab: any = {};
        tab.name = '';
        tab.displayName = '*';
        tab.fields = [];
        for (let field of this.fields) {
            if (field.type === LayoutFieldType.PageHeader) {
                tab = field;
                tab.fields = [];
            } else {
                tab.fields.push(field);
            }
            this.tabFields[tab.name] = tab;
        }
        this.pageHeaderTitles = Object.keys(this.tabFields);
        if (this.pageHeaderTitles.length) {
            this.activeTab = this.pageHeaderTitles[0];
        }
    }

    setActiveTab(pageHeader: string): void {
        this.activeTab = pageHeader;
    }

    toggleSummaryPanel(field: Field): void {
        if (this.chartPanelStreamService.fieldSummaryPanelSelectedField.getValue()?.uuid !== field.uuid) {
            this.chartPanelStreamService.fieldSummaryPanelSelectedField.next(field);
            this.chartPanelStreamService.isSummaryPanelDisplayed = true;
        } else {
            this.chartPanelStreamService.isSummaryPanelDisplayed =
                !this.chartPanelStreamService.isSummaryPanelDisplayed;
        }

        this.chartPanelStreamService.toggleSummaryPanel(field.uuid);
    }
}
