import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { CurrentUserStreamService } from 'src/app/shared/common/current-user/current-user-stream.service';
import { MetricUnits, UnitSystem } from 'src/app/shared/common/utility/accuracy-utils';
import { ArrayUtils } from 'src/app/shared/common/utility/array-utils';
import { GeneralUtils } from 'src/app/shared/common/utility/general-utils';
import { AutoFieldModelType, Field, FieldStatus, FieldType } from 'src/app/shared/template-services/field';
import { LayoutSchemaProperty, SchemaFieldType } from 'src/app/shared/template-services/field/fieldDefinition';
import { FieldsStoreService } from 'src/app/shared/template-services/field/fields-store.service';
import { Condition } from 'src/app/shared/template-services/rule/condition';
import { Rule } from 'src/app/shared/template-services/rule/rule';

import { FeatureFlagName } from 'src/app/shared/common/feature-flag/feature-flag';
import { FeatureFlagStreamService } from 'src/app/shared/common/feature-flag/feature-flag-stream.service';
import { TemplateEditorTab, TemplateEditorTabId } from '../../tabs-list/current-tab-stream.service';
import { FormFieldBaseComponent } from '../form-field-base.component';

interface Range {
    hi: string;
    lo: string;
}

class BoundRange implements Range {
    constructor(private field: Field, private property: { properties: { id: string | number }[] }) {}

    public get lo(): string {
        return this.field[this.property.properties[0].id as keyof Field] === undefined
            ? null
            : (this.field[this.property.properties[0].id as keyof Field] as string);
    }
    public set lo(v: string) {
        (this.field[this.property.properties[0].id as keyof Field] as string) = v;
    }

    public get hi(): string {
        return this.field[this.property.properties[1].id as keyof Field] === undefined
            ? null
            : (this.field[this.property.properties[1].id as keyof Field] as string);
    }
    public set hi(v: string) {
        (this.field[this.property.properties[1].id as keyof Field] as string) = v;
    }
}

class RangeAdapter {
    private _value: Range;

    public get value(): Range {
        return this._value;
    }

    public set value(v: Range) {
        this._value.lo = v.lo;
        this._value.hi = v.hi;
    }

    constructor(field: Field, property: { properties: { id: string | number }[] }) {
        this._value = new BoundRange(field, property);
    }
}

@Component({
    selector: 'single-field',
    templateUrl: './single-field.component.html',
    // this line allows the ngModels to be associated with the form from the parent component
    // ref: https://medium.com/@john_oerter/angular-nested-forms-and-validation-844ea05d4063
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SingleFieldComponent extends FormFieldBaseComponent implements OnInit, OnChanges {
    static readonly systemExpression = /^system./;

    private adapterCache: { [key: string]: RangeAdapter } = {};

    @Input()
    condition: Condition;

    @Input()
    currentTab: TemplateEditorTab;

    @Input()
    selectedRule: Rule;

    @Input()
    currentProjectDetails: any;

    @Input()
    isSelected: boolean;

    showInformations: any[];
    formName: string;
    properties: any[];
    rule: any;
    errorMessage: { fieldLabel: string; label: string };

    // Exposing enum to template
    public FieldType = FieldType;
    public AutoFieldModelType = AutoFieldModelType;
    public TemplateEditorTabId = TemplateEditorTabId;
    public SchemaFieldType = SchemaFieldType;

    constructor(
        private translate: TranslationService,
        private fieldsStore: FieldsStoreService,
        private currentUserStream: CurrentUserStreamService,
        private featureFlagStream: FeatureFlagStreamService
    ) {
        super();

        this.errorDetails = {};
        this.showInformations = [];
    }

    ngOnInit(): void {
        this.formName = 'fieldForm_' + this.field.uuid.replace(/-/g, '');
        this.updateFromFieldMeta();

        if (this.properties) {
            this.properties.forEach(prop => (this.errorDetails[prop.id] = {}));
            this.setupDefaults(this.field, this.properties);
            this.setPropertyStates();
        }
    }

    // TODO GIM: Move this into business logic somewhere
    setupDefaults(field: Field, properties: any[]) {
        properties.forEach(prop => {
            if (
                [
                    SchemaFieldType.TEXT,
                    SchemaFieldType.BOOLEAN,
                    SchemaFieldType.NUMBER,
                    SchemaFieldType.GENERAL,
                    SchemaFieldType.DATE,
                    SchemaFieldType.RADIO,
                    SchemaFieldType.MULTISELECT
                ].includes(prop.type)
            ) {
                if (GeneralUtils.isNullUndefinedOrNaN(field[prop.id as keyof Field])) {
                    (field[prop.id as keyof Field] as any) = GeneralUtils.isNullUndefinedOrNaN(prop.default)
                        ? null
                        : prop.default;
                }
            }

            if (prop.type === SchemaFieldType.ARRAY) {
                if (!field[prop.id as keyof Field] || (!field[prop.id as keyof Field] as any).length) {
                    let choices = [];
                    let populatedValues = prop.populatedValues;
                    for (let choiceNo = 1; choiceNo <= populatedValues.length; choiceNo++) {
                        if (populatedValues?.text) {
                            // text exists inside populatedValue
                            choices.push({
                                text: this.translate.instant(populatedValues.text) + ' ' + choiceNo,
                                default: populatedValues.default === choiceNo ? true : false
                            });
                        }

                        if (populatedValues?.code && populatedValues?.description) {
                            // pre-populate code and description
                            choices.push({
                                code: `${this.translate.instant(populatedValues.code)} ${choiceNo}`,
                                description: `${this.translate.instant(populatedValues.description)} ${choiceNo}`,
                                default: populatedValues.default === choiceNo ? true : false
                            });
                        }
                    }
                    (field[prop.id as keyof Field] as any) = choices;
                }
            }
        });

        // Hide imperial units for Japanese users
        if (
            this.currentUserStream.currentUser.locale === 'ja' &&
            (field.type === FieldType.Length ||
                field.type === AutoFieldModelType.EstimatedAccuracy ||
                field.type === AutoFieldModelType.EstimatedVerticalPrecision ||
                field.type === AutoFieldModelType.GeometryLength ||
                field.type === AutoFieldModelType.GeometryArea)
        ) {
            properties.forEach((prop: LayoutSchemaProperty) => {
                if (prop.id === 'unit' || prop.id === 'distanceUnit') {
                    prop.items = prop.items.filter(
                        item =>
                            Object.values(MetricUnits).includes(item.value as MetricUnits) ||
                            field[prop.id as keyof Field] === item.value
                    );
                }
            });
        }

        if (
            field.type === AutoFieldModelType.GeometryArea &&
            !this.featureFlagStream.flagEnabled(FeatureFlagName.HECTARE)
        ) {
            const unitProperty = this.properties.find(prop => prop.id === 'unit');
            unitProperty.items = unitProperty.items.filter(
                (item: { label: string; value: string }) => item.value !== 'Hectares'
            );
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.fieldMeta && changes.fieldMeta.currentValue) {
            this.updateFromFieldMeta();
        }
    }

    getDynamicProperty(field: Field, properties: { [key: string]: any }, propName: string): any {
        return properties[propName] != null
            ? properties[propName].fieldName
                ? field[properties[propName].fieldName as keyof Field]
                : properties[propName]
            : null;
    }

    getRangeAdapter(property: { id: string; properties: { id: string | number }[] }): RangeAdapter {
        let adapter = this.adapterCache[property.id];
        if (!adapter) {
            adapter = new RangeAdapter(this.field, property);
            this.adapterCache[property.id] = adapter;
        }
        return adapter;
    }

    get multiListIsEditable(): boolean {
        if (this.field.status === FieldStatus.NEW) {
            return true;
        }

        if (this.field.status === FieldStatus.SAVED && this.template.lastPublishedVersion) {
            return this.template.editableFields.filter(field => field.uuid === this.field.uuid).length ? true : false;
        } else {
            return true;
        }
    }

    updateFromFieldMeta(): void {
        if (!this.fieldMeta) {
            return;
        }

        let layoutSchema = this.fieldMeta && this.fieldMeta.application && this.fieldMeta.application.layoutSchema;

        this.properties =
            layoutSchema &&
            layoutSchema.properties &&
            layoutSchema.properties.filter((property: any) => !property.position);

        this.showInformations = [];
        this.showInformations =
            layoutSchema &&
            layoutSchema.properties &&
            layoutSchema.properties.filter((property: any) => property.type === 'information');

        if (this.properties && JSON.stringify(this.properties).indexOf('system.') !== -1) {
            this.properties.forEach(property => {
                if (SingleFieldComponent.systemExpression.test(property.default)) {
                    let attributeName = property.default.replace('system.', '');
                    let propertyValue = this.currentProjectDetails;

                    attributeName.split('.').forEach((atttributeSplit: string) => {
                        propertyValue = propertyValue && propertyValue[atttributeSplit];
                    });

                    property.default =
                        property.mappings[property.default][propertyValue] ||
                        (property.mappings &&
                            property.mappings[property.default] &&
                            property.mappings[property.default][Object.keys(property.mappings[property.default])[0]]) ||
                        null;
                }
            });
        }

        if (
            this.properties &&
            JSON.stringify(this.properties).indexOf('"id":"unit"') !== -1 &&
            JSON.stringify(this.properties).indexOf('mappings') === -1
        ) {
            if (this.properties[0] && this.currentProjectDetails['project']['settings']['unitSettings']) {
                this.properties[0].default =
                    this.currentProjectDetails['project']['settings']['unitSettings'].unitsystem === UnitSystem.METRIC
                        ? 'Meters'
                        : 'Feet';
            }
        }

        this.rule = layoutSchema && layoutSchema.rule;
    }

    handlePropChange(property: any): void {
        // update property state on property value change
        this.setPropertyStates();
    }

    setPropertyStates(): void {
        this.properties.forEach(property => {
            // set visibility state of the property based on the condition field value, if any
            let isVisible = true;

            // this will hide elements mentioned from fieldsStoreService
            if (
                this.field?.sensorMappingId &&
                this.fieldsStore.sensorExcludedPropertiesDict[this.field.sensorMappingId]?.includes(property.id)
            ) {
                isVisible = false;
            }

            if (property.showOn && ArrayUtils.isArray(property.showOn)) {
                isVisible = property.showOn.reduce(
                    (returnVal: boolean, condition: any) =>
                        returnVal && this.field[condition.field] === condition.value,
                    true
                );
            } else if (property.showOn) {
                isVisible = this.field[property.showOn.field] === property.showOn.value;
            }
            // Resetting the hidden property except choice field max. length field
            if (!isVisible && this.notChoiceAndCodedChoice()) {
                this.field[property.id] = null;
            }
            property.isVisible = isVisible;

            // set enabled/disabled state of the property based on the condition field value, if any
            let isEnabled = true;
            if (property.enableOn && ArrayUtils.isArray(property.enableOn)) {
                isEnabled = property.enableOn.reduce(
                    (returnVal: boolean, condition: any) =>
                        returnVal && this.field[condition.field] === condition.value,
                    true
                );
            } else if (property.enableOn) {
                isEnabled = this.field[property.enableOn.field] === property.enableOn.value;
            }

            // Resetting the disabled property except choice field where the defaultValue is handled different
            if (!isEnabled && this.notChoiceAndCodedChoice()) {
                this.field[property.id] = null;
            }

            if (this.field.locked) {
                isEnabled =
                    property.id !== 'repeatField' &&
                    property.id !== 'incrementRepeatField' &&
                    property.id !== 'values' &&
                    property.id !== 'incrementRepeatValue'
                        ? false
                        : isEnabled;
            }

            property.isEnabled = isEnabled;
        });
    }

    notChoiceAndCodedChoice(): boolean {
        return this.field.type !== FieldType.Choice && this.field.type !== FieldType.CodedChoice;
    }
}
