import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'gsp-checkbox',
    templateUrl: './gsp-checkbox.component.html'
})
export class GspCheckboxComponent {
    @Input()
    public disabled = false;

    @Input()
    public helpText: string;

    @Input()
    public id = 'checkbox';

    @Input()
    public label: string;

    @Input()
    public value: boolean;

    @Output()
    public valueChange = new EventEmitter<boolean>();
}
