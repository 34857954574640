import { Component, Input } from '@angular/core';
import { Field } from 'src/app/shared/template-services/field';
import { ConditionDisplayInfo, ConditionOperator } from 'src/app/shared/template-services/rule/condition';
import { TextCondition } from 'src/app/shared/template-services/rule/text-condition';

@Component({
    selector: 'text-rule',
    templateUrl: './text-rule.component.html'
})
export class TextRuleComponent {
    private _condition: TextCondition;
    conditionPair: ConditionDisplayInfo[];

    @Input()
    public get condition(): TextCondition {
        return this._condition;
    }
    public set condition(v: TextCondition) {
        this._condition = v;
        this.conditionPair = this.condition.getConditionPair();
    }

    @Input()
    field: Field;

    // exposing enum to template
    public ConditionOperator = ConditionOperator;

    constructor() {}
}
