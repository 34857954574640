import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { EnvironmentService } from 'src/app/shared/common/environment/environment.service';

import { Field, LayoutFieldType } from './field';
import { Template } from './template';
import { GeoTemplate, GeoTemplateDeltas, GeoTemplates } from './templateDTO';

export interface AvailableColor {
    id: number;
    color: string;
}

@Injectable({
    providedIn: 'root'
})
export class TemplateService {
    private templateColors: { id: number; color: string }[] = null;

    constructor(private http: HttpClient, private env: EnvironmentService, private translate: TranslationService) {}

    getTemplates(projectId: string = null, workspaceId?: string): Promise<Template[]> {
        let prefixPath = '/projects/' + projectId;
        let templatePath = prefixPath + '/templates';

        if (workspaceId) {
            templatePath += '?projectId=' + projectId + '&workspaceId=' + workspaceId;
        }

        let headers = {
            headers: {
                Range: 'items=0-'
            }
        };

        return new Promise((resolve, reject) => {
            lastValueFrom(this.http.get<GeoTemplates>(this.env.apiUrl + templatePath, headers)).then(templates => {
                if (templates) {
                    let modeledTemplate = templates.items.map(template => Template.fromDTO(template, this.translate));
                    resolve(modeledTemplate);
                } else {
                    resolve([]);
                }
            });
        });
    }

    getTemplateById(projectId: string, templateId: string): Promise<Template> {
        let templatePath = '/projects/' + projectId + '/templates/' + templateId;
        return lastValueFrom(this.http.get<GeoTemplate>(this.env.apiUrl + templatePath)).then(response =>
            Template.fromDTO(response, this.translate)
        );
    }

    getTemplatesByQuery(projectId: string, templateIds: string[]): Promise<Template[]> {
        return lastValueFrom(
            this.http.get<GeoTemplates>(
                this.env.apiUrl + '/projects/' + projectId + '/templates/_query?filter=' + `$Id in (${templateIds})`
            )
        ).then(response => {
            let responseTemplate = response.items.map(responseItem => Template.fromDTO(responseItem, this.translate));
            return responseTemplate;
        });
    }

    getTemplateBySeriesId(projectId: string, templateSeriesId: string): Promise<Template[]> {
        let templatePath = '/projects/' + projectId + '/templates/series/' + templateSeriesId;
        return lastValueFrom(this.http.get<GeoTemplates>(this.env.apiUrl + templatePath)).then(response => {
            let responseTemplate = response.items.map(responseItem => Template.fromDTO(responseItem, this.translate));
            return responseTemplate;
        });
    }

    getLatestPublishedTemplateBySeriesId(projectId: string, templateSeriesId: string): Promise<Template> {
        let templatePath = `/projects/${projectId}/templates/series/latestpublishedtemplates?seriesIds=${templateSeriesId}`;
        return lastValueFrom(this.http.get<GeoTemplates>(this.env.apiUrl + templatePath)).then(response => {
            return response.items[0] ? Template.fromDTO(response.items[0], this.translate) : null;
        });
    }

    getTemplateFields(projectId: string, templateSeriesId: string): Promise<any[]> {
        let templatePath = '/projects/' + projectId + '/templates/series/' + templateSeriesId + '/latest';
        return lastValueFrom(this.http.get<GeoTemplate>(this.env.apiUrl + templatePath)).then(response => {
            if (response && response.template) {
                const fields = response.template['fields'].filter((field: any) => {
                    if (field.type !== LayoutFieldType.PageHeader && field.type !== LayoutFieldType.Group) {
                        return field;
                    }
                });
                return fields;
            }
            return [];
        });
    }

    getTemplatesWorkspacesMappingList(projectId: string): Promise<GeoTemplate[]> {
        let path = `/projects/${projectId}/templates/_query`;

        const params = {
            filter: "($IsPublished eq 'true') and ($WorkspaceStatus eq 'Active')",
            minified: 'true',
            bringLatestVersionOnly: 'true'
        };

        return lastValueFrom(this.http.get<GeoTemplates>(this.env.apiUrl + path, { params })).then(response => {
            if (response) {
                return response.items;
            }
        });
    }

    createTemplate(
        projectId: string,
        template: GeoTemplate & {
            hasActiveTasks: boolean;
        },
        layerId?: string
    ): Promise<Template> {
        let templatePath = '/projects/' + projectId + '/templates' + (layerId ? '?layerId=' + layerId : '');
        return new Promise((resolve, reject) => {
            lastValueFrom(this.http.post<GeoTemplate>(this.env.apiUrl + templatePath, template))
                .then(templateResponse => {
                    resolve(Template.fromDTO(templateResponse, this.translate));
                })
                .catch(e => reject(e));
        });
    }

    getAllPublishedTemplates(projectId: string, seriesId: string): Promise<Template[]> {
        let templatePath =
            '/projects/' +
            projectId +
            "/templates/_query?filter=($SeriesId EQ '" +
            seriesId +
            "') AND ($IsPublished EQ 'true')";
        return lastValueFrom(this.http.get<GeoTemplates>(this.env.apiUrl + templatePath)).then(response => {
            if (response) {
                response.items = response.items.map(item => Template.fromDTO(item, this.translate));
                return response.items as Template[];
            }
        });
    }

    // Get all the removed/archived fields from a template series - Used to make sure field names (identifier for fields in field app)
    // are unique across all versions of a template (not just the current version)
    getTemplateDeltas(
        templateSeriesId: string,
        projectId: string,
        lowerVersion?: number
    ): Promise<{ archived: Field[]; unPublished: Field[] }> {
        let path = '/projects/' + projectId + '/templates/deltas' + '?seriesId=' + templateSeriesId;
        let queryWithLowerVersion = lowerVersion ? '&lowerVersion=' + lowerVersion : '';
        let deltas: {
            archived: Field[];
            unPublished: Field[];
        } = {
            archived: [],
            unPublished: []
        };
        return lastValueFrom(this.http.get<GeoTemplateDeltas>(this.env.apiUrl + path + queryWithLowerVersion)).then(
            template => {
                if (template) {
                    deltas.archived = template.removals.map(field => new Field(this.translate, field));

                    deltas.unPublished = template.additions.map(field => new Field(this.translate, field));
                }
                return deltas;
            }
        );
    }

    updateTemplate(
        projectId: string,
        template: GeoTemplate & {
            hasActiveTasks: boolean;
        },
        id: string
    ): Promise<Template> {
        let templatePath = '/projects/' + projectId + '/templates/' + id;

        return new Promise((resolve, reject) => {
            lastValueFrom(this.http.put<GeoTemplate>(this.env.apiUrl + templatePath, template)).then(
                templateResponse => {
                    resolve(Template.fromDTO(templateResponse, this.translate));
                },
                error => {
                    reject(error);
                }
            );
        });
    }

    getAvailableTemplateColors(): Promise<AvailableColor[]> {
        return new Promise<AvailableColor[]>((resolve, reject) => {
            if (this.templateColors === null) {
                lastValueFrom(this.http.get<string[]>(this.env.apiUrl + '/styles/colors'))
                    .then(colors => {
                        this.templateColors = this.buildColorList(colors);
                        resolve(this.templateColors);
                    })
                    .catch(() => {
                        let colors = [
                            '#ff2722',
                            '#d00a09',
                            '#990000',
                            '#ffb22e',
                            '#fd8204',
                            '#d64b02',
                            '#fffa00',
                            '#ffd600',
                            '#ffbe00',
                            '#b4ff00',
                            '#64ad22',
                            '#367314',
                            '#09c72e',
                            '#01863e',
                            '#004e18',
                            '#0bddd9',
                            '#028986',
                            '#01534f',
                            '#2b9ff6',
                            '#0967ad',
                            '#083161',
                            '#4747ff',
                            '#3939ac',
                            '#1a1a52',
                            '#aa46e5',
                            '#773899',
                            '#431860',
                            '#ff2d96',
                            '#bd0760',
                            '#77042c'
                        ];

                        this.templateColors = this.buildColorList(colors);
                        resolve(this.templateColors);
                    });
            } else {
                resolve(this.templateColors);
            }
        });
    }

    private buildColorList(colors: string[]): { id: number; color: string }[] {
        return colors.map((color, i) => ({ id: i, color: color }));
    }
}
