<svg:svg [attr.width]="size" [attr.height]="size">
    <mask [attr.id]="maskId">
        <circle [attr.cx]="outerCircle.x" [attr.cy]="outerCircle.y" [attr.r]="outerCircle.radius" fill="white"></circle>
        <circle [attr.cx]="innerCircle.x" [attr.cy]="innerCircle.y" [attr.r]="innerCircle.radius" fill="black"></circle>
    </mask>
    <rect
        x="0"
        y="0"
        [attr.width]="rectangle.width"
        [attr.height]="rectangle.height"
        [attr.fill]="emptyColor"
        [attr.mask]="mask"
    ></rect>
    <path [attr.d]="pathToDraw" [attr.fill]="pathColor" [attr.mask]="mask"></path>
</svg:svg>
