import { HttpErrorResponse } from '@angular/common/http';
import { Component, DoCheck, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';
import { TemplateStatus, TemplateTypeId } from 'src/app/shared/map-data-services/layer/template-lite';
import { Field, FieldType } from 'src/app/shared/template-services/field';
import { Rule } from 'src/app/shared/template-services/rule/rule';
import { Template } from 'src/app/shared/template-services/template';

import { RuleErrorsStreamService } from '../../rules-panel/rule-errors-stream.service';
import { RuleSelectionStreamService } from '../../rules-panel/rule-selection-stream.service';
import { TemplateErrorCodes, TemplateStoreForComponent } from '../../template/template-store-for-component.service';

@Component({
    selector: 'composer-header',
    templateUrl: './composer-header.component.html'
})
export class ComposerHeaderComponent implements OnInit, OnDestroy, DoCheck {
    @Input()
    template: Template;

    @Input()
    templateId: string;

    @Input()
    currentWorkspaceId: string;

    @Input()
    enableButtons: boolean;

    @Output()
    showSuccess = new EventEmitter<void>();

    @Output()
    showError = new EventEmitter<string>();

    @Input()
    showErrors = false;

    @Output()
    afterLoaded = new EventEmitter<void>();

    @Output()
    beforeLoaded = new EventEmitter<void>();

    @Output()
    showCancelConfirmation = new EventEmitter<void>();

    @Output()
    saveForm = new EventEmitter<any>();

    @ViewChild('textinput', { static: true })
    inputElement: ElementRef;

    savingInProgress = false;
    workSpaceId: string;
    clonedTemplateInitial: Template;
    focused = false;

    destroyed = new Subject<void>();
    publishDisabled = true;
    saveDisabled = true;

    // expose ButtonType enum to template
    public ButtonType = ButtonType;
    public TemplateTypeId = TemplateTypeId;
    public TemplateStatus = TemplateStatus;

    constructor(
        private router: Router,
        private templatesStoreForComponent: TemplateStoreForComponent,
        private ruleSelectionStream: RuleSelectionStreamService,
        private ruleErrorsStream: RuleErrorsStreamService
    ) {}

    ngOnInit(): void {
        this.ruleErrorsStream.ruleErrorsStream.next([]);
        this.workSpaceId = this.currentWorkspaceId;
        this.clonedTemplateInitial = CloneUtils.cloneDeep(this.template);
        this.templatesStoreForComponent.templateEditorCloseStream.pipe(takeUntil(this.destroyed)).subscribe(() => {
            this.closeTemplateEditor();
        });
        this.templatesStoreForComponent.discardChangesStream.pipe(takeUntil(this.destroyed)).subscribe(() => {
            this.clearFormAndExit();
        });
    }

    ngDoCheck(): void {
        this.shouldDisableButtonsThrottled();
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
        this.destroyed.complete();
    }

    changeInputState(): void {
        this.focused = true;

        // TODO LATER: 24.6.19 check this works without a timeout$
        setTimeout(() => this.inputElement.nativeElement.focus(), 0);
    }

    public saveDraftAction = () => this.saveDraft();

    saveDraft(): Promise<void> {
        this.savingInProgress = true;
        return this.saveTemplate(false);
    }

    public publishAction = () => this.publish();

    public clearFormAndExitAction = () => this.clearFormAndExit();

    publish(): Promise<void> {
        this.savingInProgress = true;
        return this.saveTemplate(true);
    }

    clearFormAndExit(): Promise<boolean> {
        this.ruleSelectionStream.ruleSelectionStream.next(null);
        this.templatesStoreForComponent.setCurrentEditedTemplate(null);
        return this.router.navigate(['mapViewer'], {
            queryParams: {
                templateId: null,
                layerId: null
            },
            queryParamsHandling: 'merge'
        });
    }

    closeTemplateEditor(): void {
        if (this.templateChanged()) {
            this.showCancelConfirmation.emit();
        } else {
            this.clearFormAndExit();
        }
    }

    hasCircularReference(rule: Rule): boolean {
        let targetsChecked: any[] = [];
        let targetsToCheck = rule.targets.slice(0); // clone
        let circularReference = false;

        while (targetsToCheck.length > 0) {
            // Ensure none of the targets are the same as any of the rule sources
            rule.conditions.forEach(condition => {
                targetsToCheck.forEach(target => {
                    if (target.targetUuid === condition.sourceUuid) {
                        condition.addCommonErrorMessageNo(1);
                        target.addCommonErrorMessageNo(2);
                        circularReference = true;
                    }
                });
            }); // jshint ignore:line

            // Now for the current targets, find any rules for which they are sources and add the targets of such rule for analysis
            targetsChecked = targetsChecked.concat(targetsToCheck);
            let targetsToCheckCopy = targetsToCheck.slice(0); // clone
            targetsToCheck = [];
            targetsToCheckCopy.forEach(target => {
                this.template.rules.forEach(rule2 => {
                    if (rule2 !== rule) {
                        rule2.conditions.forEach(condition => {
                            if (condition.sourceUuid === target.targetUuid) {
                                rule2.targets.forEach(newTargetToCheck => {
                                    if (
                                        newTargetToCheck != null &&
                                        !targetsChecked.some(
                                            targetChecked => newTargetToCheck.targetUuid === targetChecked.targetUuid
                                        )
                                    ) {
                                        targetsToCheck.push(newTargetToCheck);
                                    }
                                });
                            }
                        });
                    }
                });
            }); // jshint ignore:line
        }

        return circularReference;
    }

    async saveTemplate(isPublishAction: boolean): Promise<void> {
        const templateBeforeSave = CloneUtils.cloneDeep(this.template);
        this.handleChoiceFields(templateBeforeSave);
        const unsubscribe = new Subject<void>();
        try {
            await new Promise<void>(async (resolve, reject) => {
                // Bulk of api calls are made in parent components based on the saveForm event emitter.
                // Hook into this stream so we know when these have completed
                this.templatesStoreForComponent.templateEditorSaveSuccessStream
                    .pipe(takeUntil(unsubscribe))
                    .subscribe(successful => (successful ? resolve() : reject(new Error())));
                try {
                    const template = !templateBeforeSave.id
                        ? await this.templatesStoreForComponent.createTemplate(
                              templateBeforeSave,
                              isPublishAction,
                              this.workSpaceId
                          )
                        : await this.templatesStoreForComponent.updateTemplate(templateBeforeSave, isPublishAction);
                    this.saveForm.emit({
                        action: !templateBeforeSave.id ? 'create' : 'update',
                        template,
                        publishedStatus: isPublishAction,
                        templateBeforeSave
                    });
                } catch (err) {
                    reject(err);
                }
            });

            this.showSuccess.emit();
            this.ruleSelectionStream.ruleSelectionStream.next(null);
            this.afterLoaded.emit();
            this.templatesStoreForComponent.setCurrentEditedTemplate(null);
        } catch (e) {
            const message = this.deconstructError(e);
            this.showError.emit(message);
            this.savingInProgress = false;
        } finally {
            unsubscribe.next();
        }
    }

    // Special case for Choice fields as they have 'showOn' property.
    // Api requires length to have a value.
    private handleChoiceFields(template: Template): void {
        template.fields.forEach((field: Field & { length: number }) => {
            if (field.type === FieldType.Choice) {
                if (!Number(field['length'])) {
                    // default value is 1000
                    field['length'] = 1000;
                }
            }
        });
    }

    private deconstructError(err: HttpErrorResponse): string {
        const { error } = err;
        if (error?.code === TemplateErrorCodes.VALIDATION_ERROR) {
            if (error.failure?.invalidFields?.length) {
                if (
                    !!error.failure.invalidFields.find((field: { name: string; message: string }) =>
                        field.message.toLowerCase().includes('duplicate')
                    )
                ) {
                    return 'TemplateEditor.Error.DuplicateFields';
                }
                return 'TemplateEditor.Error.InvalidFields';
            }
        }
        return '';
    }

    private hasRuleErrors(): boolean {
        let errorMessages: string[] = [];
        const ruleWithErrors = this.template.rules.reduce((acc, rule) => {
            rule.conditions.forEach(condition => {
                condition.removeCommonErrorMessageNo(1 /*removes error to condition*/);
            });
            rule.targets.forEach(target => {
                target.removeCommonErrorMessageNo(2 /*removes error to target*/);
            });

            if (
                rule.conditionRuleErrors ||
                rule.targetRuleErrors ||
                rule.getRuleLevelMessages().length ||
                rule.getRuleLevelErrorMessages().length ||
                this.hasCircularReference(rule)
            ) {
                errorMessages.push(...rule.getRuleLevelErrorMessages());
                acc.push(rule);
            }

            return acc;
        }, []);

        errorMessages = _.uniq(errorMessages);
        this.ruleErrorsStream.ruleErrorsStream.next(errorMessages.length ? errorMessages : []);

        return ruleWithErrors.length > 0;
    }

    private templateChanged(): boolean {
        let clonedTemplateFinal = CloneUtils.cloneDeep(this.template);
        return !_.isEqual(this.clonedTemplateInitial.toDTO(), clonedTemplateFinal.toDTO());
    }

    private shouldDisableButtons(): void {
        const primaryCriteriaMet =
            !this.enableButtons ||
            this.showErrors ||
            !this.template.name ||
            this.savingInProgress ||
            this.hasRuleErrors();

        if (primaryCriteriaMet) {
            this.saveDisabled = this.publishDisabled = primaryCriteriaMet;
        } else {
            const templateChanged = this.templateChanged();
            this.saveDisabled =
                !templateChanged && this.templateId !== 'new-form' && this.templateId !== 'direct-template';
            this.publishDisabled =
                !templateChanged && this.templateId !== 'new-form' && this.template.status === TemplateStatus.PUBLISHED;
        }
    }

    private shouldDisableButtonsThrottled = _.throttle(this.shouldDisableButtons, 1000);
}
