<section
    class="feature-panel"
    *checkVisibility="{
        visibleModes: [
            MapWorkspacePermissionType.FULL_ACCESS,
            MapWorkspacePermissionType.READ,
            MapWorkspacePermissionType.FILE_VIEW_MODE
        ]
    }"
>
    <feature-list
        class="feature-nav"
        [selectedFeatures]="selectedFeatures"
        [activePanelFeature]="activePanelFeature"
        [removeFromSelectionMode]="removeFromSelectionMode"
        (setCurrentFeature)="setCurrentFeature($event)"
        (showDeleteAllConfirmation)="showDeleteAllConfirmationAction()"
    ></feature-list>
    <loading class="loading-align-center" [hidden]="!loading"></loading>
    <div class="feature-main-panel" *ngIf="!loading">
        <header>
            <h2 title="{{ activePanelFeature.layerName }}">{{ activePanelFeature.layerName }}</h2>
            <gsp-button
                (action)="closeSidePanel('feature')"
                [type]="ButtonType.Icon"
                [iconClass]="'i24 icon_line_cancel'"
            >
            </gsp-button>
        </header>

        <section class="height-full">
            <custom-scroll>
                <span
                    class="feature-id-watch"
                    [attr.data-feature-id]="activePanelFeature.id"
                    [attr.data-edit-mode]="editMode"
                ></span>
                <div
                    class="metadata"
                    *ngIf="
                        activePanelFeature['metadata'][TemplatedFeatureMetadataProperty.COLLECTION_UPDATED_BY];
                        else importedFeature
                    "
                >
                    <div class="metadata-items">
                        <div [ngClass]="{ 'metadata-fields-compressed': showAccuracies }">
                            <label class="feature-label">{{ 'modified' | translate }}</label>
                            <p
                                title="{{ modifiedByUserDetails?.email }}"
                                [hidden]="!modifiedByUserDetails?.email"
                                [tooltipShowAlways]="true"
                            >
                                {{ modifiedByUserDetails?.firstName + ' ' + modifiedByUserDetails?.lastName }}
                            </p>
                            <p title="{{ 'TC.Common.UpdatedOn' | translate }}">
                                {{
                                    activePanelFeature['metadata'][TemplatedFeatureMetadataProperty.COLLECTION_UTC]
                                        | formatDateTime: 'shortDate':false
                                }}&nbsp;{{
                                    activePanelFeature['metadata'][TemplatedFeatureMetadataProperty.COLLECTION_UTC]
                                        | formatDateTime: 'time':false
                                }}
                            </p>
                            <ng-container
                                *ngIf="
                                    activePanelFeature['metadata'][
                                        TemplatedFeatureMetadataProperty.COLLECTION_WORKSPACE_ID
                                    ] && collectedWorkspace
                                "
                            >
                                <label class="feature-label">{{
                                    'MapViewer.FeaturePanel.CollectedWorkspaceLabel' | translate
                                }}</label>
                                <p title=" {{ collectedWorkspace }} ">{{ collectedWorkspace }}</p>
                            </ng-container>
                        </div>
                        <div class="accuracy-fields" *ngIf="showAccuracies">
                            <div
                                class="accuracy-field-horizontal"
                                title="{{ 'MapViewer.Features.Accuracy.Horizontal' | translate }}"
                                *ngIf="horizontalAccuracyValue"
                            >
                                <i class="icon_line_horizontal_accuracy i24"></i>
                                <p>{{ horizontalAccuracy }}</p>
                            </div>
                            <div
                                class="accuracy-field-vertical"
                                title="{{ 'MapViewer.Features.Accuracy.Vertical' | translate }}"
                                *ngIf="verticalAccuracyValue"
                            >
                                <i class="icon_line_vertical_accuracy i24"></i>
                                <p>{{ verticalAccuracy }}</p>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngTemplateOutlet="featureTagTemplate"></ng-container>
                    <div
                        *ngIf="
                            activePanelFeature['metadata'][TemplatedFeatureMetadataProperty.POST_PROCESSED_STATUS] &&
                            activePanelFeature['metadata'][TemplatedFeatureMetadataProperty.POST_PROCESSED_STATUS] !==
                                PostProcessingStatus.UNKNOWN &&
                            activePanelFeature['metadata'][TemplatedFeatureMetadataProperty.POST_PROCESSED_STATUS] !==
                                PostProcessingStatus.UNPROCESSED
                        "
                        class="post-processing-controls"
                        [ngClass]="{
                            'controls-active':
                                !activePanelFeature['metadata'][
                                    TemplatedFeatureMetadataProperty.POST_PROCESSED_STATUS
                                ] ||
                                activePanelFeature['metadata'][
                                    TemplatedFeatureMetadataProperty.POST_PROCESSED_STATUS
                                ] !== PostProcessingStatus.PENDING
                        }"
                    >
                        <div class="post-processing-provider">
                            <label class="feature-label">{{
                                'MapViewer.EasyPP.GNSSCorrectionStatus' | translate
                            }}</label>
                            <div class="post-processing-status">
                                <p title="{{ correctionStatus | translate }}">
                                    {{ correctionStatus | translate }}
                                </p>
                                <gsp-help-text
                                    *ngIf="postProcessingInfoMessage"
                                    [text]="postProcessingInfoMessage"
                                ></gsp-help-text>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #importedFeature>
                    <p class="metadata">{{ 'TCS.Mapviewer.Filters.Imported' | translate }}</p>
                    <div class="metadata">
                        <ng-container *ngTemplateOutlet="featureTagTemplate"></ng-container>
                    </div>
                </ng-template>
                <div
                    style="border-top: solid"
                    [ngStyle]="{
                        'border-top-width.px': !activePanelFeature.isLayerRemoved ? 6 : 0,
                        'border-top-color': '#' + activePanelFeature.colorKey
                    }"
                    *ngIf="activePanelFeature.geometry"
                >
                    <div
                        class="restore-layer"
                        [ngStyle]="{ 'background-color': '#' + activePanelFeature.colorKey }"
                        *ngIf="activePanelFeature.isLayerRemoved"
                    >
                        <div>{{ 'TCS.Mapviewer.Features.LayerRemoved' | translate }}</div>
                        <div class="restore" (click)="restoreLayer(activePanelFeature.removedLayer)">
                            {{ 'RESTORE' | translate }}
                        </div>
                    </div>
                    <mini-map baseMapProviderName="trimble" baseMapModeName="map" mapId="mini-map"> </mini-map>
                </div>
                <feature-with-no-template
                    *ngIf="activePanelFeature && !activePanelFeature.hasTemplate"
                    [properties]="activePanelFeature.properties"
                ></feature-with-no-template>
                <feature-with-template
                    [editMode]="editMode"
                    [fields]="activePanelFeature.fields"
                    [totalPages]="activePanelFeature.totalPages"
                    *ngIf="activePanelFeature && activePanelFeature.hasTemplate"
                >
                </feature-with-template>
            </custom-scroll>
        </section>

        <ng-template #featureTagTemplate>
            <div class="custom-tag-section" [ngClass]="{ 'no-tags-selected': !selectedFeatureTags?.length }">
                <label
                    class="feature-label"
                    *ngIf="!currentMapWorkspace?.isPubliclySharedMapWorkspace || selectedFeatureTags?.length"
                    >{{ 'TCW_Tags' | translate }}</label
                >
                <chip-filter
                    class="tag-input-alternative"
                    [editable]="!currentMapWorkspace?.isPubliclySharedMapWorkspace"
                    [inputClass]="'tag-input-form'"
                    [(selectedChips)]="selectedFeatureTags"
                    [allChips]="availableFeatureTags"
                    [restrictedChips]="reservedTags"
                    [name]="tags"
                    [placeholder]="'TC.Common.Search' | translate"
                    [addChipText]="'TC.Tags.Placeholder' | translate"
                    (addChipEvent)="addTag($event)"
                    (removeChipEvent)="removeTag($event)"
                ></chip-filter>
            </div>
        </ng-template>

        <footer>
            <div
                [hidden]="editMode !== false"
                *checkVisibility="{ visibleModes: [MapWorkspacePermissionType.FULL_ACCESS] }"
            >
                <gsp-confirmation-button
                    [type]="ButtonType.Default"
                    confirmText="{{ 'delete' | translate }}"
                    [asyncAction]="deleteAction"
                    (cancel)="cancelDelete()"
                    text="{{ 'delete' | translate }}"
                    id="delete-feature"
                >
                </gsp-confirmation-button>
                <gsp-button
                    *ngIf="activePanelFeature.fields"
                    [type]="ButtonType.Primary"
                    (action)="edit()"
                    text="{{ 'TCW_Edit' | translate }}"
                    id="edit-feature"
                ></gsp-button>
            </div>
            <div *ngIf="editMode">
                <gsp-button
                    [type]="ButtonType.Default"
                    (action)="cancel()"
                    text="{{ 'cancel' | translate }}"
                    id="cancel-edit-feature"
                ></gsp-button>
                <gsp-button
                    [type]="ButtonType.Primary"
                    [asyncAction]="saveAction"
                    [disabled]="hasError()"
                    text="{{ 'save' | translate }}"
                    id="save-feature"
                ></gsp-button>
            </div>
        </footer>
    </div>
</section>
<div class="modal-popup" *ngIf="showDeleteAllConfirmation">
    <gsp-confirmation-popup
        [heading]="'MapViewer_Generic_DeleteSelection'"
        [message]="'MapViewer_Forms_BulkDeleteMessage'"
        [confirmAction]="deleteSelectionAction"
        (cancel)="cancelDeleteSelection()"
        [isPermanentAction]="true"
        id="delete-selected-features-popup"
    ></gsp-confirmation-popup>
</div>
