import * as _ from 'lodash-es';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';
import { TemplateLite, TemplateStatus } from 'src/app/shared/map-data-services/layer/template-lite';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';

import { DistanceUnitsExpanded } from '../common/utility/accuracy-utils';
import { GeneralUtils } from '../common/utility/general-utils';
import { GeometryTypes } from '../common/utility/geometry-utils';
import { UtilitiesService } from '../common/utility/utilities.service';
import { Application } from '../map-data-services/application';
import {
    AutoFieldModelType,
    ChoiceField,
    ChoiceValue,
    CodedChoiceValue,
    Field,
    FieldStatus,
    FieldType,
    GroupField,
    LayoutFieldType,
} from './field';
import { SensorFieldDefinition, SensorMessageDefinition } from './field/fieldDefinition';
import { FieldsStoreService } from './field/fields-store.service';
import { ChoiceCondition } from './rule/choice-condition';
import { Condition } from './rule/condition';
import { Rule } from './rule/rule';
import { Target } from './rule/target';
import { GeoTemplate, LoggingMethod } from './templateDTO';
import { UUID } from './uuid';

class Review {
    line1FieldId: string = undefined;
    line2FieldId: string = undefined;
    thumbnailFieldId: string = undefined;
}

// Custom Logging Rate measures
interface Time {
    value: number;
    active: boolean;
}
interface Distance {
    value: number;
    unit?: DistanceUnitsExpanded;
    active: boolean;
}

export class Template extends TemplateLite {
    // DTO properties

    // ---------
    // inherited TemplateLite properties
    // id = '';
    // seriesId = '';
    // version: number = null;
    // name = '';
    // geometryType = 'Point';
    // geometryColorHexRGB = '#ff2222';

    // Added by layer-store service based on isLocked value of correspomdoing version of TemplatePublications
    // isLocked = false;
    // Added properties (Workspace specific - updated by getTemplateByLayer())
    // isPublished = false;
    // publishedId: string = null;
    // lastPublishedVersion: number = null;

    // Managed by add-existing-template.component
    // attachedToWorkspaces: MapWorkspace[] = [];
    // ---------

    hasActiveTasks = false;
    templateName = '';
    repeatFieldsAutomatically = true;
    logPositionsAutomatically = true;
    allowDigitizedPositions = true;
    geometryCollectionRequired = false;
    requireMinimumAccuracy = false;
    // Point/vertex averaging
    vertexNumberOfPositions: number;
    loggingRate: { time: Time; distance: Distance } = {
        time: {
            value: 1,
            active: true
        },
        distance: {
            value: 1,
            active: false
        }
    };
    // TODO: GIM 21/6/19 - changed accuracyLimit to be a number which means we'll potentiall
    // TODO: send back a zero where we would have sent back a '' in ng1.  Test it still works
    accuracyLimit = 0;
    allowOverrideForAccuracyLimit = false;
    fields: Field[] = [];
    groups: { displayName: string; uuid: string }[] = [];
    rules: Rule[] = [];
    review: Review = new Review();
    useSeparateUpdateWorkflow = false;
    enableSitevisionOptions = false;
    archivedFields: any[] = [];

    linkedWorkspaces: MapWorkspace[] = [];

    // Added properties
    attachedToWorkspaces: MapWorkspace[] = [];
    layerAttachedWorkspaces: MapWorkspace[] = [];
    attachedToLayers: Layer[] = [];
    layersWithWorkspaces: Layer[] = [];

    availableSummaries: Field[] = [
        {
            uuid: '',
            displayName: 'TC.Common.NoneParenthesis'
        } as Field
    ];

    availableThumbnails: Field[] = [
        {
            uuid: '',
            displayName: 'TC.Common.NoneParenthesis'
        } as Field
    ];

    editableFields: Field[];
    lockedSchema = false;

    static fromDTO(templateDto: GeoTemplate, $translate: TranslationService): Template {
        let template = new Template();

        // --------------
        // Create from API response DTO
        if (templateDto) {
            template.id = templateDto.id;
            template.seriesId = templateDto.seriesId;
            template.version = templateDto.version;

            // converting group details to nested fields
            this.createGroupWithNestedFields(templateDto.template);

            template.name = templateDto.template.name;
            template.templateName = templateDto.template.name;
            template.geometryType = templateDto.template.geometryType;
            template.geometryColorHexRGB = templateDto.template.geometryColorHexRGB;
            template.geometryCollectionRequired = templateDto.template.geometryCollectionRequired;
            template.allowDigitizedPositions = GeneralUtils.isNullUndefinedOrEmpty(
                templateDto.template.allowDigitizedPositions
            )
                ? true
                : templateDto.template.allowDigitizedPositions;
            template.repeatFieldsAutomatically = GeneralUtils.isNullUndefinedOrEmpty(
                templateDto.template.repeatFieldsAutomatically
            )
                ? true
                : templateDto.template.repeatFieldsAutomatically;
            template.logPositionsAutomatically = GeneralUtils.isNullUndefinedOrEmpty(
                templateDto.template.loggingMethod
            )
                ? true
                : templateDto.template.loggingMethod == LoggingMethod.Automatic ? true : false;
            template.requireMinimumAccuracy = templateDto.template.accuracyLimit !== 0 ? true : false;
            template.vertexNumberOfPositions = templateDto.template.vertexNumberOfPositions;
            template.loggingRate = templateDto.template.loggingRate || template.loggingRate;
            template.accuracyLimit = templateDto.template.accuracyLimit;
            template.allowOverrideForAccuracyLimit = templateDto.template.allowOverrideForAccuracyLimit;
            template.lockedSchema = templateDto.template.lockedSchema;
            template.fields = templateDto.template.fields.map((field: any) => {
                field.locked =
                    field.type !== LayoutFieldType.PageHeader && field.type !== LayoutFieldType.Group
                        ? templateDto.template.lockedSchema
                        : false;
                if (field.fields) {
                    field.fields = field.fields.map((subField: any) => {
                        subField.locked =
                            subField.type !== LayoutFieldType.PageHeader && subField.type !== LayoutFieldType.Group
                                ? templateDto.template.lockedSchema
                                : false;
                        return new Field($translate, subField);
                    });
                }
                return new Field($translate, field);
            });
            template.groups = templateDto.template.groups; // It doesn't have any manipulation value in UI. so NO seperate model
            template.rules = templateDto.template.rules
                ? templateDto.template.rules.map((rule: Rule) => new Rule(...Object.values(rule)))
                : [];
            template.review = templateDto.template.review;
            template.useSeparateUpdateWorkflow = templateDto.template.useSeparateUpdateWorkflow;
            template.enableSitevisionOptions = templateDto.template.enableSitevisionOptions;
            template.archivedFields = templateDto.template.archivedFields || [];
            template.editableFields = templateDto.template.editableFields || [];
            template.status = templateDto.status || TemplateStatus.DRAFT;

            Template.setReview(template);
        }
        return template;
    }

    // create fields by nesting fields inside group - structure modification
    static createGroupWithNestedFields(templateDetails: { [key: string]: any }): void {
        if (templateDetails) {
            let fieldsToBeRemoved: number[] = [];
            templateDetails.fields = templateDetails.fields || [];
            templateDetails.fields.forEach((field: Field, index: number) => {
                if (field.groupId) {
                    let group = _.find(templateDetails.fields, g => g.uuid === field.groupId);

                    if (!group) {
                        let newGroup = _.find(templateDetails.groups, g => g.uuid === field.groupId);
                        newGroup.type = LayoutFieldType.Group;
                        newGroup.fields = [field];
                        templateDetails.fields[index] = newGroup;
                    } else {
                        group.fields.push(field);
                        fieldsToBeRemoved.push(index);
                    }
                }
            });

            fieldsToBeRemoved.forEach((fieldIndex, index) => {
                templateDetails.fields.splice(fieldIndex - index, 1);
            });
            templateDetails.groups = [];
        }
    }

    // to set default review and available review fields
    static setReview(template: Template, actionField?: Field, action?: string): void {
        if (action === 'removed') {
            if (actionField.uuid === template.review.line1FieldId) {
                template.review.line1FieldId = '';
            }
            if (actionField.uuid === template.review.line2FieldId) {
                template.review.line2FieldId = '';
            }
        }

        template.availableSummaries = [
            {
                uuid: '',
                displayName: 'TC.Common.NoneParenthesis'
            } as Field
        ];

        template.availableThumbnails = [
            {
                uuid: '',
                displayName: 'TC.Common.NoneParenthesis'
            } as Field
        ];

        template.fields.forEach(field => {
            if (field.type === LayoutFieldType.Group) {
                let groupField = field as GroupField;
                if (groupField.fields) {
                    let groupSummaries = groupField.fields.filter(
                        subField => subField.type !== FieldType.Image && subField.type !== FieldType.Signature
                    );
                    let groupThumbnails = groupField.fields.filter(
                        subField => subField.type === FieldType.Image || subField.type === FieldType.Signature
                    );
                    template.availableSummaries = template.availableSummaries.concat(groupSummaries);
                    template.availableThumbnails = template.availableThumbnails.concat(groupThumbnails);
                }
            } else if (
                field.type !== FieldType.Image &&
                field.type !== FieldType.Signature &&
                field.type !== LayoutFieldType.PageHeader
            ) {
                template.availableSummaries.push(field);
            } else if (field.type === FieldType.Image || field.type === FieldType.Signature) {
                template.availableThumbnails.push(field);
            }
        });
        let fieldIsPresent: Field;

        if (!template.review) {
            template.review = new Review();
        }

        if (
            template.availableSummaries.length <= 3 &&
            template.availableSummaries[1] &&
            template.availableSummaries[1].status === FieldStatus.NEW &&
            template.availableSummaries[2] &&
            template.availableSummaries[2].status === FieldStatus.NEW
        ) {
            template.review.line1FieldId = template.availableSummaries[1] ? template.availableSummaries[1].uuid : '';

            template.review.line2FieldId = template.availableSummaries[2] ? template.availableSummaries[2].uuid : '';
        }

        if (
            !template.review.line1FieldId &&
            template.availableSummaries[1] &&
            template.availableSummaries[1].status === FieldStatus.NEW
        ) {
            let nextField1 = _.find(
                template.availableSummaries,
                (summary, index) => index > 0 && summary.uuid !== template.review.line2FieldId
            );

            template.review.line1FieldId = nextField1 ? nextField1.uuid : '';
        }

        if (
            !template.review.line2FieldId &&
            template.availableSummaries[2] &&
            template.availableSummaries[2].status === FieldStatus.NEW
        ) {
            let nextField2 = _.find(
                template.availableSummaries,
                (summary, index) => index > 0 && summary.uuid !== template.review.line1FieldId
            );
            template.review.line2FieldId = nextField2 ? nextField2.uuid : '';
        }

        if (template.availableThumbnails[1] && template.availableThumbnails[1].status === FieldStatus.NEW) {
            if (!template.review.thumbnailFieldId) {
                template.review.thumbnailFieldId = template.availableThumbnails[1]
                    ? template.availableThumbnails[1].uuid
                    : '';
            } else {
                fieldIsPresent = _.find(
                    template.availableThumbnails,
                    tumbnail => tumbnail.uuid === template.review.thumbnailFieldId
                );
                if (!fieldIsPresent) {
                    template.review.thumbnailFieldId = template.availableThumbnails[1]
                        ? template.availableThumbnails[1].uuid
                        : template.review.thumbnailFieldId;
                }
            }
        }
    }

    setName(name: string): void {
        this.name = name;
    }

    setColor(color: string): void {
        this.geometryColorHexRGB = color;
    }

    toDTO(): GeoTemplate & { hasActiveTasks: boolean } {
        // to API request DTO
        let groupsAndFields = this.seperateGroupAndField(this);
        return {
            id: this.id,
            hasActiveTasks: this.hasActiveTasks,
            template: {
                name: this.name,
                geometryType: this.geometryType,
                geometryColorHexRGB: this.geometryColorHexRGB,
                geometryCollectionRequired: this.geometryCollectionRequired,
                allowDigitizedPositions: this.allowDigitizedPositions,
                loggingRate:
                    this.geometryType === GeometryTypes.POINT
                        ? null
                        : {
                              ...this.loggingRate,
                              time: {
                                  ...this.loggingRate?.time,
                                  value: this.loggingRate?.time?.value || 1
                              },
                              distance: {
                                  ...this.loggingRate?.distance,
                                  value: this.loggingRate?.distance?.value || 1
                              }
                          },
                vertexNumberOfPositions: this.vertexNumberOfPositions,
                repeatFieldsAutomatically: this.repeatFieldsAutomatically,
                loggingMethod: this.logPositionsAutomatically == true ? LoggingMethod.Automatic : LoggingMethod.Manual,
                accuracyLimit: this.requireMinimumAccuracy ? this.accuracyLimit : 0,
                allowOverrideForAccuracyLimit:
                    this.requireMinimumAccuracy && this.accuracyLimit !== 0
                        ? this.allowOverrideForAccuracyLimit
                        : false,
                fields: groupsAndFields.fields.map(field => field.toDTO()),
                groups: groupsAndFields.groups,
                rules: this.rules.reduce((acc: Partial<Rule>[], rule: Rule) => {
                    if (rule.conditions.length > 0 || rule.targets.length > 0) {
                        acc.push(rule.toDTO());
                    }
                    return acc;
                }, []),
                review: this.getReviewDTO(this.review),
                useSeparateUpdateWorkflow: this.useSeparateUpdateWorkflow,
                enableSitevisionOptions: this.enableSitevisionOptions,
                status: this.status,
                lockedSchema: this.lockedSchema
            }
        };
    }

    // Rules

    createNewRule(): Rule {
        if (!this.rules.find(rule => !rule.conditions.length && !rule.targets.length)) {
            let newRule = new Rule();
            this.rules.unshift(newRule);
            return newRule;
        }
    }

    setSource(field: Field, rule: Rule, ruleType: string, duplicatedCondition?: Condition): Condition {
        rule.addCondition(field, ruleType, duplicatedCondition);
        if (field.type !== LayoutFieldType.PageHeader && field.type !== LayoutFieldType.Group) {
            this.disableGroupOrHeader(field, true);
        }
        field.conditioned = true;
        this.findIncompleteRule('Fields', rule);
        return rule.getCondition(field.uuid);
    }

    setTarget(field: Field, rule: Rule, duplicatedTarget?: Target): Target {
        rule.addTarget(field, 'Field', duplicatedTarget);
        if (field.type === LayoutFieldType.PageHeader || field.type === LayoutFieldType.Group) {
            this.disableGroupOrHeaderFields(field, true);
        } else {
            this.disableGroupOrHeader(field, true);
        }
        field.targeted = true;
        this.findIncompleteRule('Fields', rule);
        return rule.getTarget(field.uuid);
    }

    removeSource(field: Field, rule: Rule): { condition: Condition; rule?: Rule } {
        let previousStopTarget = field.stopConditionedOrTargeted;
        rule.removeCondition(field, this.rules.indexOf(rule));

        if (field.type !== LayoutFieldType.PageHeader && field.type !== LayoutFieldType.Group) {
            this.disableGroupOrHeader(field, false);
        }
        this.setRuleIndicatorForField(field);
        if (field.stopConditionedOrTargeted !== previousStopTarget) {
            field.conditionRuleErrors--;
        }
        this.findIncompleteRule('Fields', rule);
        const condition = rule.getCondition(field.uuid);
        if (this.shouldRemoveRule(rule)) {
            return { condition, rule };
        }
        return { condition };
    }

    removeTarget(field: Field, rule: Rule): { target: Target; rule?: Rule } {
        let previousStopTarget = field.stopConditionedOrTargeted;
        field.target = null;
        rule.removeTarget(field);
        if (field.type === LayoutFieldType.PageHeader || field.type === LayoutFieldType.Group) {
            this.disableGroupOrHeaderFields(field, false);
        } else {
            this.disableGroupOrHeader(field, false);
        }
        this.setRuleIndicatorForField(field);
        if (field.stopConditionedOrTargeted !== previousStopTarget) {
            field.targetRuleErrors--;
        }
        this.findIncompleteRule('Fields', rule);
        const target = rule.getTarget(field.uuid);
        if (this.shouldRemoveRule(rule)) {
            return { target, rule };
        }
        return { target };
    }

    shouldRemoveRule(selectedRule: Rule): boolean {
        return (
            !selectedRule.conditions.length &&
            !selectedRule.targets.length &&
            this.rules.length > 1 &&
            !!this.rules.find(rule => !rule.conditions.length && !rule.targets.length)
        );
    }

    // Fields

    async insertNewField(
        fieldsStore: FieldsStoreService,
        translate: TranslationService,
        application: Application,
        fieldType: FieldType | LayoutFieldType | AutoFieldModelType,
        fieldSubType: string,
        position?: number[],
        groupId?: string,
        sequenceIncreaseValue?: number,
        duplicateGroupField?: Field,
        duplicateChildField?: Field,
        sensorWorkflow?: SensorMessageDefinition,
        sensorChildField?: SensorFieldDefinition
    ): Promise<Field> {
        let fieldDisplayNameList: string[] = [];
        let fieldNameList: string[] = [];
        let templateFields: any[] = [];
        let templateContext = this;

        if (this.archivedFields.length) {
            templateFields = _.concat(this.archivedFields, this.fields);
        } else {
            templateFields = this.fields;
        }

        const pushFieldName = (field: Field) => {
            fieldDisplayNameList.push(field.displayName);
            fieldNameList.push(field.name);
        };

        templateFields.forEach(field => {
            if (field.type === LayoutFieldType.Group && field.fields) {
                field.fields.forEach((subField: any) => {
                    pushFieldName(subField);
                });
            }
            pushFieldName(field);
        });

        const selectedField = await fieldsStore.getFieldByType(fieldType, application.name, application.category);

        let fieldDisplayName;
        if (sensorChildField) {
            fieldDisplayName = translate.instant(sensorChildField.displayName);
        } else if (sensorWorkflow) {
            fieldDisplayName = translate.instant(sensorWorkflow.workflowName);
        } else {
            fieldDisplayName = translate.instant(selectedField?.application.layoutSchema.label);
        }

        let newDisplayName = UtilitiesService.getNewName(
            fieldDisplayName,
            fieldDisplayNameList,
            false,
            sequenceIncreaseValue
        );

        let newName = UtilitiesService.getNewName(
            fieldType.toLowerCase(),
            fieldNameList,
            true,
            sequenceIncreaseValue,
            false
        );

        let newProperties: Partial<Field> = {
            uuid: UUID.generate(),
            type: fieldType,
            displayName: newDisplayName,
            name: newName,
            status: FieldStatus.NEW
        };

        if (sensorChildField) {
            newProperties = {
                ...newProperties,
                sensorMappingId: sensorChildField.mappingId,
                scale: sensorChildField.decimalPlaces,
                values: sensorChildField.codedValues?.map(value => ({
                    ...value,
                    description: translate.instant(value.description)
                }))
            };
        }

        if (duplicateGroupField) {
            newProperties = {
                uuid: UUID.generate(),
                displayName: duplicateGroupField.displayName,
                name: newName,
                type: fieldType,
                status: FieldStatus.NEW
            };
        }

        if (duplicateChildField) {
            const newProp = duplicateChildField;
            newProperties = this.retainDetailsWithNewProps(newProp, fieldType, newName);
        }

        let newField = new Field(translate, newProperties, selectedField?.schema, selectedField.application);

        newField.groupId = groupId || null;

        if (position === undefined) {
            templateContext.fields.push(newField);
        } else if (position[1] !== undefined) {
            let groupField = templateContext.fields[position[0]] as GroupField;
            if (!groupField.fields) {
                groupField.fields = [];
            }
            groupField.fields.splice(position[1], 0, newField);
        } else {
            templateContext.fields.splice(position[0], 0, newField);
        }

        Template.setReview(templateContext);
        templateContext.updateDataUpdateFields(newField, 'requiredForUpdate', 'readOnlyForUpdate', true);
        templateContext.updateDataUpdateFields(newField, 'readOnlyForUpdate', 'requiredForUpdate', true);
        return newField;
    }

    repositionField(field: Field, positionToRemove: number[], positionToInsert: number[]): void {
        if (positionToRemove[1] !== undefined) {
            let groupField = this.fields[positionToRemove[0]] as GroupField;
            groupField.fields.splice(positionToRemove[1], 1);
        } else {
            this.fields.splice(positionToRemove[0], 1);
        }

        if (positionToInsert[1] !== undefined) {
            if (positionToRemove[1] === undefined && positionToRemove[0] < positionToInsert[0]) {
                // removed element affected group's position
                let groupField = this.fields[positionToInsert[0] - 1] as GroupField;
                groupField.fields.splice(positionToInsert[1], 0, field);
                field.groupId = groupField.uuid;
            } else {
                let groupField = this.fields[positionToInsert[0]] as GroupField;
                groupField.fields.splice(positionToInsert[1], 0, field);
                field.groupId = groupField.uuid;
            }
        } else {
            this.fields.splice(positionToInsert[0], 0, field);
            field.groupId = null;
        }

        Template.setReview(this, field, 'reposition');
        this.updateDataUpdateFields(field, 'requiredForUpdate', 'readOnlyForUpdate', true);
        this.updateDataUpdateFields(field, 'readOnlyForUpdate', 'requiredForUpdate', true);
    }

    removeField(fieldToBeRemoved: Field): void {
        if (fieldToBeRemoved.groupId) {
            let groupField = _.find(
                this.fields,
                field => fieldToBeRemoved.groupId && field.uuid === fieldToBeRemoved.groupId
            ) as GroupField;
            this.removeFieldFromFields(fieldToBeRemoved, groupField.fields);
        } else {
            this.removeFieldFromFields(fieldToBeRemoved, this.fields);
        }
        this.removeFieldFromRules(fieldToBeRemoved, this.rules);
        Template.setReview(this, fieldToBeRemoved, 'removed');
    }

    // TODO: MY - Unused
    isConditionOrTarget(field: Field): {
        conditioned: boolean;
        targeted: boolean;
    } {
        let fieldConditionedOrTargeted = {
            conditioned: false,
            targeted: false
        };
        this.rules.forEach(rule => {
            let conditioned = _.find(rule.conditions, condition => condition.sourceUuid === field.uuid);
            if (conditioned) {
                fieldConditionedOrTargeted.conditioned = true;
            }
            let targeted = _.find(rule.targets, target => target.targetUuid === field.uuid);
            if (targeted) {
                fieldConditionedOrTargeted.targeted = true;
            }
            if (fieldConditionedOrTargeted.conditioned && fieldConditionedOrTargeted.targeted) {
                return;
            }
        });
        return fieldConditionedOrTargeted;
    }

    disableGroupOrHeaderFields(field: Field, value: boolean): void {
        if (field.type === LayoutFieldType.Group) {
            let groupField = field as GroupField;
            groupField.fields.forEach(subField => {
                subField.setStopConditionedOrTargeted(value);
            });
        } else if (field.type === LayoutFieldType.PageHeader) {
            let fields = this.fields;
            let fieldIndex = _.findIndex(fields, subField => subField.uuid === field.uuid);

            for (
                let index = fieldIndex + 1;
                fields[index] && fields[index].type !== LayoutFieldType.PageHeader;
                index++
            ) {
                fields[index].setStopConditionedOrTargeted(value);
                if (fields[index].type === LayoutFieldType.Group) {
                    this.disableGroupOrHeaderFields(fields[index], value);
                }
            }
        }
    }

    disableGroupOrHeader(field: Field, value: boolean): void {
        let formFields = this.fields;
        if (field.groupId) {
            let group = _.find(formFields, formField => formField.uuid === field.groupId);
            group.setStopConditionedOrTargeted(value);
            this.disableGroupOrHeader(group, value);
        } else if (field.type !== LayoutFieldType.PageHeader) {
            let fieldIndex = _.findIndex(formFields, formField => formField.uuid === field.uuid);
            for (let index = fieldIndex - 1; index >= 0; index--) {
                if (formFields[index].type === LayoutFieldType.PageHeader) {
                    formFields[index].setStopConditionedOrTargeted(value);
                    break;
                }
            }
        }
    }

    setRuleIndicatorForField(field: Field): void {
        field.conditioned = field.targeted = false;
        this.rules.forEach(rule => {
            let conditioned = _.find(rule.conditions, condition => condition.sourceUuid === field.uuid);
            if (conditioned) {
                field.conditioned = true;
            }
            let targeted = _.find(rule.targets, target => target.targetUuid === field.uuid);
            if (targeted) {
                field.targeted = true;
            }
            if (field.conditioned && field.targeted) {
                return;
            }
        });
        field.conditionErrors = false;
        field.targetErrors = false;
    }

    assessRuleErrors(): void {
        let templateContext = this;
        this.fields.forEach(subField => {
            subField.conditionRuleErrors = 0;
            subField.targetRuleErrors = 0;
        });
        this.rules.forEach(rule => {
            rule.conditionRuleErrors = 0;
            rule.targetRuleErrors = 0;
        });
        this.rules.forEach(rule => {
            let targetIds = rule.targets.map(target => target.targetUuid);
            let conditionIds = rule.conditions.map(condition => condition.sourceUuid);
            let filteredFields = templateContext.fields.filter(
                formField =>
                    targetIds.indexOf(formField.uuid) !== -1 &&
                    (formField.type === LayoutFieldType.PageHeader || formField.type === LayoutFieldType.Group)
            );
            filteredFields.forEach(filteredField => {
                if (filteredField.type === LayoutFieldType.Group) {
                    let groupFilteredField = filteredField as GroupField;
                    groupFilteredField.fields.forEach(subField => {
                        if (targetIds.indexOf(subField.uuid) !== -1) {
                            subField.targetRuleErrors++;
                            rule.targetRuleErrors++;
                        }
                        if (conditionIds.indexOf(subField.uuid) !== -1) {
                            subField.conditionRuleErrors++;
                            rule.conditionRuleErrors++;
                        }
                    });
                } else if (filteredField.type === LayoutFieldType.PageHeader) {
                    const headerIndex = templateContext.fields.findIndex(field => field.uuid === filteredField.uuid);
                    for (
                        let i = headerIndex + 1;
                        templateContext.fields[i] && templateContext.fields[i].type !== LayoutFieldType.PageHeader;
                        i++
                    ) {
                        if (targetIds.indexOf(templateContext.fields[i].uuid) !== -1) {
                            templateContext.fields[i].targetRuleErrors++;
                            rule.targetRuleErrors++;
                        }
                        if (conditionIds.indexOf(templateContext.fields[i].uuid) !== -1) {
                            templateContext.fields[i].conditionRuleErrors++;
                            rule.conditionRuleErrors++;
                        }
                    }
                }
            });
        });
    }

    populateChoiceValues(forceElse: boolean = false): void {
        let templateContext = this;
        templateContext.rules.forEach((rule, index) => {
            if (rule.conditions !== undefined) {
                rule.conditions.forEach(condition => {
                    if (condition.type === FieldType.Choice) {
                        let selectedChoiceField: Field & {
                            values?: ChoiceValue[] | CodedChoiceValue[];
                        } = null;
                        templateContext.fields.some(formField => {
                            if (formField.type === LayoutFieldType.Group) {
                                let formGroupField = formField as GroupField;
                                formGroupField.fields.some(fieldInGroup => {
                                    if (fieldInGroup.uuid === condition.sourceUuid) {
                                        selectedChoiceField = fieldInGroup;
                                        return true;
                                    }
                                    return false;
                                });
                                if (selectedChoiceField) {
                                    return true;
                                }
                            }
                            if (formField.uuid === condition.sourceUuid) {
                                selectedChoiceField = formField;
                                return true;
                            }
                            return false;
                        });
                        (condition as ChoiceCondition).populateChoiceFromFields(
                            selectedChoiceField.values,
                            index,
                            forceElse
                        );
                    }
                });
            }
        });

        let ruleCount = templateContext.rules.length;

        templateContext.fields.forEach((formField: Field) => {
            if (formField.type === FieldType.Choice) {
                let choiceFormField = formField as ChoiceField;
                choiceFormField.values.forEach(formFieldValue => {
                    if (formFieldValue.choiceSelected) {
                        Object.keys(formFieldValue.choiceSelected).forEach(choiceSelected => {
                            let idx = parseInt(choiceSelected, 10);
                            if (
                                idx < 0 ||
                                idx >= ruleCount ||
                                !(this.rules[idx].conditions && this.rules[idx].conditions.length)
                            ) {
                                delete formFieldValue.choiceSelected[idx];
                            }
                        });
                    }
                });
            }
        });
    }

    assessFields(rule: Rule): void {
        let templateContext = this;
        this.fields.forEach(formField => {
            formField.stopConditionedOrTargeted = 0;
            if (formField.type === LayoutFieldType.Group) {
                let formGroupField = formField as GroupField;
                formGroupField.fields.forEach(subField => {
                    subField.stopConditionedOrTargeted = 0;
                });
            }
        });

        this.fields.forEach(formField => {
            let condition = _.find(rule.conditions, c => c.sourceUuid === formField.uuid);

            let target = _.find(rule.targets, t => t.targetUuid === formField.uuid);

            if (target && (formField.type === LayoutFieldType.PageHeader || formField.type === LayoutFieldType.Group)) {
                templateContext.disableGroupOrHeaderFields(formField, true);
            }
            if (!formField.stopConditionedOrTargeted) {
                if (condition || target) {
                    templateContext.disableGroupOrHeader(formField, true);
                }
                if (formField.type === LayoutFieldType.Group) {
                    let formGroupField = formField as GroupField;
                    formGroupField.fields.forEach(formSubField => {
                        let conditionInGroup = _.find(rule.conditions, c => c.sourceUuid === formSubField.uuid);
                        let targetInGroup = _.find(rule.targets, t => t.targetUuid === formSubField.uuid);

                        if (conditionInGroup || targetInGroup) {
                            templateContext.disableGroupOrHeader(formSubField, true);
                        }
                    });
                }
            }
        });
    }

    findIncompleteRule(tabName: string, selectedRule: Rule): void {
        this.fields.forEach(field => {
            field.incompleteRule = false;
        });
        this.rules.forEach(rule => {
            if (tabName === 'Fields' || rule.uuid !== selectedRule.uuid) {
                let ruleErrors = rule.isRuleErrors();
                if (ruleErrors.targetErrors) {
                    let targetIds = rule.targets.map(target => target.targetUuid);
                    let targetedFields = this.fields.filter(field => targetIds.indexOf(field.uuid) !== -1);
                    targetedFields.forEach(targetedField => {
                        targetedField.incompleteRule = true;
                    });
                }
                if (ruleErrors.conditionErrors) {
                    let conditionIds = rule.conditions.map(condition => condition.sourceUuid);
                    let conditionedFields = this.fields.filter(field => conditionIds.indexOf(field.uuid) !== -1);
                    conditionedFields.forEach(conditionedField => {
                        conditionedField.incompleteRule = true;
                    });
                }

                let incompleteConditions = rule.conditions.filter(condition => condition.getErrorMessages().length);

                let incompleteConditionIds = incompleteConditions.map(
                    incompleteCondition => (incompleteCondition as any).uuid
                );

                this.fields.forEach(field => {
                    if (incompleteConditionIds.indexOf(field.uuid) !== -1) {
                        field.incompleteRule = true;
                    }
                });
            }
        });
    }

    filterFieldsByType(fieldTypes: string[]): void {
        this.fields = this.fields.filter(field => !fieldTypes.includes(field.type));

        this.fields.forEach(field => {
            if (field.type === LayoutFieldType.Group) {
                let groupField = field as GroupField;
                if (groupField.fields) {
                    groupField.fields = groupField.fields.filter(subField => !fieldTypes.includes(subField.type));
                }
            }
        });
    }

    updateDataUpdateFields(
        fieldToBeUpdated: Field,
        fieldClicked: keyof Field,
        auxiliaryField: keyof Field,
        doNotFlip: boolean = false
    ): void {
        if (!doNotFlip) {
            (fieldToBeUpdated[fieldClicked] as boolean) = !fieldToBeUpdated[fieldClicked];
        }

        let clickedAction = fieldToBeUpdated[fieldClicked];
        if (clickedAction === true && (fieldToBeUpdated[auxiliaryField] as boolean) === true) {
            (fieldToBeUpdated[auxiliaryField] as boolean) = false;
        }
        if (fieldToBeUpdated.type === LayoutFieldType.PageHeader) {
            let pageHeaderElementIndex = _.findIndex(this.fields, field => field.uuid === fieldToBeUpdated.uuid);
            for (let fieldIndex = pageHeaderElementIndex + 1; fieldIndex < this.fields.length; fieldIndex++) {
                if (this.fields[fieldIndex].type === LayoutFieldType.PageHeader) {
                    return;
                }
                if (this.fields[fieldIndex].type === LayoutFieldType.Group) {
                    let groupField = this.fields[fieldIndex] as GroupField;
                    groupField.fields.forEach(subField => {
                        (subField[fieldClicked] as any) = clickedAction;
                        if (clickedAction === true) {
                            (subField[auxiliaryField] as boolean) = false;
                        }
                    });
                }
                (this.fields[fieldIndex][fieldClicked] as any) = clickedAction;
                if (clickedAction === true) {
                    (this.fields[fieldIndex][auxiliaryField] as boolean) = false;
                }
            }
        }

        if (fieldToBeUpdated.groupId) {
            let groupField = this.fields.find(field => field.uuid === fieldToBeUpdated.groupId) as GroupField;
            let primaryAction = groupField.fields && groupField.fields.every(field => field[fieldClicked]);
            let secondaryAction = groupField.fields && groupField.fields.every(field => field[auxiliaryField]);
            (groupField[fieldClicked] as boolean) = primaryAction;
            (groupField[auxiliaryField] as boolean) = secondaryAction;
            this.updateHeaderOptions(this.fields, groupField, fieldClicked, auxiliaryField);
        }

        if (fieldToBeUpdated.type === LayoutFieldType.Group) {
            let groupField = fieldToBeUpdated as GroupField;
            if (groupField.fields) {
                groupField.fields.map(subField => {
                    subField.requiredForUpdate = fieldToBeUpdated.requiredForUpdate;
                    subField.readOnlyForUpdate = fieldToBeUpdated.readOnlyForUpdate;
                });
            }
        }

        if (!fieldToBeUpdated.groupId && fieldToBeUpdated.type !== LayoutFieldType.PageHeader) {
            this.updateHeaderOptions(this.fields, fieldToBeUpdated, fieldClicked, auxiliaryField);
        }
    }

    // publics below:

    updateHeaderOptions(
        fields: Field[],
        fieldToBeEvaluated: Field,
        fieldClicked: keyof Field,
        auxiliaryField: keyof Field
    ): void {
        let pageHeadersWithIndices: { [index: number]: Field }[] = [];
        let fieldToBeEvaluatedIndex = 0;

        fields.forEach((field, index) => {
            if (field.type === LayoutFieldType.PageHeader) {
                let pageHeaderWithIndex: { [key: string]: Field } = {};
                pageHeaderWithIndex[index] = field;
                pageHeadersWithIndices.push(pageHeaderWithIndex);
            }
            if (field.uuid === fieldToBeEvaluated.uuid) {
                fieldToBeEvaluatedIndex = index;
            }
        });
        let startingIndex = -1;
        let endingIndex = -1;

        pageHeadersWithIndices.forEach(pageHeaderWithIndex => {
            let pageHeaderIndex = parseInt(Object.keys(pageHeaderWithIndex)[0], 10);
            if (pageHeaderIndex < fieldToBeEvaluatedIndex) {
                startingIndex = pageHeaderIndex;
            } else if (endingIndex === -1) {
                endingIndex = pageHeaderIndex;
            }
        });

        if (startingIndex !== -1) {
            let fieldsToBeChecked = fields.slice(startingIndex + 1, endingIndex === -1 ? fields.length : endingIndex);

            let primaryAction = fieldsToBeChecked.every(field => field[fieldClicked]);
            let secondaryAction = fieldsToBeChecked.every(field => field[auxiliaryField]);
            (fields[startingIndex][fieldClicked] as boolean) = primaryAction;
            (fields[startingIndex][auxiliaryField] as boolean) = secondaryAction;
        }
    }

    // Remove field from list of Fields
    removeFieldFromFields(fieldToBeRemoved: Field, fields: Field[]): void {
        let fieldIndex = _.findIndex(fields, subField => subField.uuid === fieldToBeRemoved.uuid);
        if (fieldIndex >= 0) {
            fields.splice(fieldIndex, 1);
        }
    }

    removeFieldFromRules(fieldToBeRemoved: Field, rules: Rule[]): void {
        let fieldDict: { [key: string]: Field } = {
            [fieldToBeRemoved.uuid]: fieldToBeRemoved
        };
        // Need to check for child fields if fieldToBeRemoved is a group
        if (fieldToBeRemoved.fields) {
            const childFieldDict = fieldToBeRemoved.fields.reduce((acc: { [key: string]: Field }, field) => {
                acc[field.uuid] = field;
                return acc;
            }, {});
            fieldDict = { ...fieldDict, ...childFieldDict };
        }
        rules.forEach(rule => {
            rule.conditions = rule.conditions.filter(condition => !fieldDict[condition.sourceUuid]);
            rule.targets = rule.targets.filter(target => !fieldDict[target.targetUuid]);
        });
    }

    getReviewDTO(review: Review): Review {
        let reviewDTO = new Review();

        if (review.line1FieldId !== undefined && review.line1FieldId !== '') {
            reviewDTO.line1FieldId = review.line1FieldId;
        }
        if (review.line2FieldId !== undefined && review.line2FieldId !== '') {
            reviewDTO.line2FieldId = review.line2FieldId;
        }
        if (review.thumbnailFieldId !== undefined && review.thumbnailFieldId !== '') {
            reviewDTO.thumbnailFieldId = review.thumbnailFieldId;
        }
        return reviewDTO;
    }

    // Separating group and field
    seperateGroupAndField(template: Template): { groups: { uuid: string; displayName: string }[]; fields: Field[] } {
        let groups: { uuid: string; displayName: string }[] = [];
        let fields: Field[] = [];

        template.fields.forEach(field => {
            if (field.type === LayoutFieldType.Group) {
                groups.push({
                    uuid: field.uuid,
                    displayName: field.displayName
                });
                fields = fields.concat((field as GroupField).fields);
            } else {
                fields.push(field);
            }
        });
        return {
            groups,
            fields
        };
    }

    retainDetailsWithNewProps(dupProps: Field, fieldType: string, newName: string): {} {
        // retain all child field details and overwrite the required field details
        return { ...dupProps, uuid: UUID.generate(), name: newName, type: fieldType, status: FieldStatus.NEW };
    }
}
