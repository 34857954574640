import { Component, ElementRef, forwardRef, Input, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { ValueAccessorBase } from 'src/app/shared/common/components/value-accessor-base';
import { UtilitiesService } from 'src/app/shared/common/utility/utilities.service';

@Component({
    selector: 'coded-choice-field',
    templateUrl: './coded-choice-field.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CodedChoiceFieldComponent),
            multi: true
        }
    ]
})
export class CodedChoiceFieldComponent extends ValueAccessorBase<any[]> implements OnDestroy {
    private destroyed = new Subject<void>();

    @ViewChildren('fieldChoices')
    fieldChoices: QueryList<ElementRef>;

    @Input()
    name: string;

    maxLength = 30;

    private _enableDefaultProp: boolean; // to disable the default value for change in value of repeatField property
    @Input()
    get enableDefaultProp(): boolean {
        return this._enableDefaultProp;
    }
    set enableDefaultProp(value: boolean) {
        this._enableDefaultProp = value;
        // reset the existing default choice before disabling it
        if (!this._enableDefaultProp && this.value) {
            this.resetPreviousDefaultChoice(null);
        }
    }

    @Input()
    locked = false;

    @Input()
    showDefaultProp = true;

    editChoiceInTextMode = false;
    choiceEditingListAsString: string;

    // expose ButtonType enum to template
    public ButtonType = ButtonType;

    constructor(private translate: TranslationService) {
        super();
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    toggleTextEditor(): void {
        this.editChoiceInTextMode = !this.editChoiceInTextMode;
        this.choiceEditingListAsString = this.value
            .reduce((accVal: string, currVal: any) => accVal + '\n' + currVal.code + '=' + currVal.description, '')
            .replace('\n', '');
    }

    addNewChoice(): void {
        let codeName = this.translate.instant('TC.Common.NewCode');
        let choiceName = this.translate.instant('TC.Common.NewChoice');
        let code = UtilitiesService.getNewName(
            codeName,
            this.value.map(field => field.code)
        );
        let description = UtilitiesService.getNewName(
            choiceName,
            this.value.map(field => field.description)
        );
        this.value.push({
            code,
            description,
            default: false
        });
        this.signalChanged();
    }

    removeChoice(choice: any): void {
        let indexToBeRemoved = this.value.indexOf(choice);
        if (indexToBeRemoved !== -1) {
            this.value.splice(indexToBeRemoved, 1);
        }
        this.signalChanged();
    }

    saveChoiceInTextMode(): void {
        this.editChoiceInTextMode = false;
        this.value = [];
        if (this.choiceEditingListAsString) {
            let newChoiceItemsInList = this.choiceEditingListAsString.split('\n').filter(s => s);
            newChoiceItemsInList.forEach((list, index) => {
                const code = list.split('=')[0];
                const description = list.split('=')[1];

                if (this.value[index] !== undefined && list) {
                    this.value[index].code = code.trim();
                    this.value[index].description = description.trim();
                } else {
                    this.value.push({
                        code: code.trim(),
                        description: description.trim(),
                        default: false
                    });
                }
            });
        }
        this.signalChanged();
    }

    resetPreviousDefaultChoice(chosen: any): void {
        this.value.forEach(choice => {
            if (choice !== chosen) {
                choice.default = false;
            }
        });
    }

    highlightFieldChoice(index: number): void {
        this.fieldChoices.forEach(field => field.nativeElement.classList.remove('active'));
        const currentField = this.fieldChoices.get(index).nativeElement;
        currentField.classList.add('active');

        setTimeout(() => {
            currentField.classList.remove('active');
        }, 1500);
    }
}
