import { Component, ElementRef, forwardRef, Input, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { BooleanConverter, InputConverter } from 'src/app/shared/common/components/input-converter.decorator';
import { ValueAccessorBase } from 'src/app/shared/common/components/value-accessor-base';
import { UtilitiesService } from 'src/app/shared/common/utility/utilities.service';

@Component({
    selector: 'choice-field',
    templateUrl: './choice-field.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ChoiceFieldComponent),
            multi: true
        }
    ]
})
export class ChoiceFieldComponent extends ValueAccessorBase<any[]> implements OnDestroy {
    private destroyed = new Subject<void>();

    @ViewChildren('fieldChoices')
    fieldChoices: QueryList<ElementRef>;

    @Input()
    name: string;

    private _enableDefaultProp: boolean; // to disable the default value for change in value of repeatField property
    @Input()
    get enableDefaultProp(): boolean {
        return this._enableDefaultProp;
    }
    set enableDefaultProp(value: boolean) {
        this._enableDefaultProp = value;
        // reset the existing default choice before disabling it
        if (!this._enableDefaultProp && this.value) {
            this.resetPreviousDefaultChoice(null);
        }
    }

    @Input()
    @InputConverter(BooleanConverter)
    showText: boolean;

    @Input()
    locked = true;

    editChoiceInTextMode = false;
    choiceEditingListAsString: string;

    // expose ButtonType enum to template
    public ButtonType = ButtonType;

    constructor(private translate: TranslationService) {
        super();
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    toggleTextEditor(): void {
        this.editChoiceInTextMode = !this.editChoiceInTextMode;
        this.choiceEditingListAsString = this.value
            .reduce((accVal: string, currVal: any) => accVal + '\n' + currVal.text, '')
            .replace('\n', '');
    }

    addNewChoice(): void {
        let choiceName = this.translate.instant('TC.Common.NewChoice');
        let text = UtilitiesService.getNewName(
            choiceName,
            this.value.map(field => field.text)
        );
        this.value.push({
            text: text,
            default: false
        });
        this.signalChanged();
    }

    removeChoice(choice: any): void {
        let indexToBeRemoved = this.value.indexOf(choice);
        if (indexToBeRemoved !== -1) {
            this.value.splice(indexToBeRemoved, 1);
        }
        this.signalChanged();
    }

    saveChoiceInTextMode(): void {
        this.editChoiceInTextMode = false;
        this.value = [];
        if (this.choiceEditingListAsString) {
            let newChoiceItemsInList = this.choiceEditingListAsString.split('\n').filter(s => s);
            for (let listIndex = 0; listIndex < newChoiceItemsInList.length; listIndex++) {
                if (this.value[listIndex] !== undefined && newChoiceItemsInList[listIndex]) {
                    this.value[listIndex].text = newChoiceItemsInList[listIndex].trim();
                } else {
                    this.value.push({
                        text: newChoiceItemsInList[listIndex].trim(),
                        default: false
                    });
                }
            }
        }
        this.signalChanged();
    }

    resetPreviousDefaultChoice(chosen: any): void {
        this.value.forEach(choice => {
            if (choice !== chosen) {
                choice.default = false;
            }
        });
    }

    highlightFieldChoice(index: number): void {
        this.fieldChoices.forEach(field => field.nativeElement.classList.remove('active'));
        const currentField = this.fieldChoices.get(index).nativeElement;
        currentField.classList.add('active');

        setTimeout(() => {
            currentField.classList.remove('active');
        }, 1500);
    }
}
