import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { EnvironmentService } from 'src/app/shared/common/environment/environment.service';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';

import { TCFile, TCFileDTO } from './TCFile';

export enum ArchiveTagDetails {
    LABEL = 'Archived',
    DESCRIPTION = 'Tag for Archived workspaces in Connect Maps'
}

export interface Tag {
    id: string;
    label: string;
    description: string;
}

export interface TCObject {
    id: string;
    objectType: TCObjectType;
    name: string;
    type: TCObjectType;
    parentId: string;
    versionId: string;
    projectId: string;
}

export enum TCObjectType {
    FILE = 'FILE',
    FOLDER = 'FOLDER',
    TODO = 'TODO'
}

export enum TCFileStatus {
    DONE = 'DONE'
}

export enum GeoFileExtension {
    SHAPE_FILE = 'shp',
    ZIP = 'zip',
    VCE = 'vce',
    GDB = 'gdb',
    KML = 'kml',
    KMZ = 'kmz'
}

export interface TCSearchResponse {
    type: TCObjectType;
    details: TCFile;
}

@Injectable({
    providedIn: 'root'
})
export class TCFileService {
    private geoExtension = Object.values(GeoFileExtension);

    constructor(private http: HttpClient, private environmentService: EnvironmentService) {}

    public getFolderItems(parentId: string, projectId: string, isGeoFile: boolean = true): Promise<TCFile[]> {
        let path = this.environmentService.connectApiUrl + '/folders/' + parentId + '/items?projectId=' + projectId;
        return new Promise(resolve => {
            lastValueFrom(this.http.get<TCFileDTO[]>(path)).then(
                response => {
                    let tcFiles: TCFile[] = [];
                    response.forEach(tcfile => {
                        if (
                            isGeoFile &&
                            ((this.geoExtension.includes(this.getFileExtension(tcfile.name) as GeoFileExtension) &&
                                tcfile.status === TCFileStatus.DONE) ||
                                tcfile.type === TCObjectType.FOLDER)
                        ) {
                            tcFiles.push(TCFile.fromDTO(tcfile, projectId));
                        } else if (!isGeoFile) {
                            tcFiles.push(TCFile.fromDTO(tcfile, projectId));
                        }
                    });
                    resolve(tcFiles);
                },
                () => {
                    resolve(null);
                }
            );
        });
    }

    public getFolderDetail(folderId: string, projectId: string): Promise<TCFile> {
        let path = this.environmentService.connectApiUrl + '/folders/' + folderId;
        return new Promise(resolve => {
            lastValueFrom(this.http.get<TCFileDTO>(path, { params: { projectId: projectId } })).then(
                response => {
                    let tcFile = TCFile.fromDTO(response, projectId);
                    resolve(tcFile);
                },
                () => {
                    resolve(null);
                }
            );
        });
    }

    public getFileDetail(connectVersionId: string, projectId: string): Promise<TCFileDTO> {
        let filePath = this.environmentService.apiUrl + '/projects/' + projectId + '/spatialfiles/' + connectVersionId;
        return new Promise(resolve => {
            lastValueFrom(this.http.get<TCFileDTO>(filePath)).then(
                response => {
                    resolve(response);
                },
                () => {
                    resolve(null);
                }
            );
        });
    }

    private getFileExtension(filename: string): string {
        let a = filename.split('.');
        if (a.length === 1 || (a[0] === '' && a.length === 2)) {
            return '';
        }
        return a.pop();
    }

    public getWorkspaceFile(fileId: string): Promise<TCFile> {
        let path = this.environmentService.connectApiUrl + '/files/' + fileId;
        return new Promise(resolve => {
            lastValueFrom(this.http.get<TCFileDTO>(path)).then(
                response => {
                    let tcFile = TCFile.fromDTO(response, response.projectId);
                    resolve(tcFile);
                },
                () => {
                    resolve(null);
                }
            );
        });
    }

    public getWorkspaceFiles(workspaces: MapWorkspace[]): Promise<TCFile[]> {
        let promises: Promise<TCFile>[] = [];
        workspaces.forEach(workspace => {
            promises.push(this.getWorkspaceFile(workspace.connectFileId));
        });
        return Promise.all(promises);
    }

    public getWorkspaceFilesByExtension(projectId: string): Promise<TCSearchResponse[]> {
        const path = `${this.environmentService.connectApiUrl}/search`;

        const pageSize = 100;

        const workspaceFiles: TCSearchResponse[] = [];

        const loadWorkspaceFiles = (pageIndex: number): Promise<TCSearchResponse[]> => {
            const options = {
                headers: {
                    Range: `items=${pageIndex}-${pageIndex + (pageSize - 1)}`
                },
                params: {
                    projectId,
                    query: '.tmap',
                    type: TCObjectType.FILE
                },
                observe: 'response'
            };
            return lastValueFrom(this.http.get<TCSearchResponse[]>(path, options as any)).then(
                (response: HttpResponse<TCSearchResponse[]>) => {
                    workspaceFiles.push(...response.body);
                    // the range string looks like this: "items 0-99/100"
                    const currentRange = response.headers.get('content-range');
                    const totalFiles = parseInt(currentRange.split('/')[1], 10);
                    if (totalFiles && workspaceFiles.length < totalFiles) {
                        // load workspace files from latest index
                        return loadWorkspaceFiles(workspaceFiles.length);
                    } else {
                        return workspaceFiles;
                    }
                }
            );
        };

        // load workspace files from starting index
        return loadWorkspaceFiles(0);
    }

    public updateFile(fileId: string, properties: Partial<TCFile>): Promise<TCFile> {
        const path = this.environmentService.connectApiUrl + '/files/' + fileId;
        return lastValueFrom(this.http.patch<TCFile>(path, properties));
    }

    public getTagByLabel(projectId: string, label: string): Promise<Tag> {
        const path = `${this.environmentService.connectApiUrl}/tags/label/${projectId}`;
        const params = {
            label
        };
        return lastValueFrom(this.http.get<Tag>(path, { params }));
    }

    public createTag(projectId: string, label: string, description: string): Promise<Tag> {
        const path = `${this.environmentService.connectApiUrl}/tags`;
        const params = {
            projectId,
            label,
            description
        };
        return lastValueFrom(this.http.post<Tag>(path, params));
    }

    public getObjectsForTag(tagId: string): Promise<TCObject[]> {
        const path = `${this.environmentService.connectApiUrl}/tags/${tagId}/objects`;
        return lastValueFrom(this.http.get<TCObject[]>(path));
    }

    public addObjectToTag(tagId: string, fileId: string, objectType: TCObjectType): Promise<TCObject> {
        const path = `${this.environmentService.connectApiUrl}/tags/${tagId}/objects`;
        const params = [
            {
                id: fileId,
                objectType
            }
        ];
        return lastValueFrom(this.http.post<TCObject>(path, params));
    }

    public removeObjectFromTag(tagId: string, fileId: string, objectType: TCObjectType): Promise<any> {
        const path = `${this.environmentService.connectApiUrl}/tags/${tagId}/objects`;
        const params = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            body: [
                {
                    id: fileId,
                    objectType
                }
            ]
        };
        return lastValueFrom(this.http.delete(path, params));
    }
}
