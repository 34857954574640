import { StringUtils } from 'src/app/shared/common/utility/string-utils';
import { Feature, IFeature, IFeatureDTO } from 'src/app/shared/map-data-services/feature/feature';
import {
    AutoField,
    AutoFieldModelType,
    BarcodeField,
    ChoiceField,
    CodedChoiceField,
    DateField,
    Field,
    FieldType,
    GroupField,
    LayoutFieldType,
    NumberField,
    TextField
} from 'src/app/shared/template-services/field';
import { Template } from 'src/app/shared/template-services/template';

import {
    AutoFeatureField,
    BarcodeFeatureField,
    ChoiceFeatureField,
    CodedChoiceFeatureField,
    DateFeatureField,
    FeatureField,
    GroupFeatureField,
    ImageSignatureFeatureField,
    NumberFeatureField,
    PageHeaderFeatureField,
    TextFeatureField,
    YesNoFeatureField
} from './feature-field';

export enum TemplatedFeatureMetadataProperty {
    COLLECTION_UPDATED_BY = 'collection_updatedBy',
    COLLECTION_COLLECTED_BY = 'collection_collectedBy',
    COLLECTION_UTC = 'collection_utc',
    COLLECTION_WORKSPACE_ID = 'collection_workspaceid',
    POST_PROCESSED_STATUS = 'processing_postprocessedStatus',
    COMMON_UNPROCESSED = 'common_unprocessed',
    COMMON_PROCESSED = 'common_processed',
    COMMON_LAYER_ID = 'common_layerId',
    COLLECTION_ORIGINAL_UTC = 'collection_original_utc',
    FILE_CONNECT_FILE_ID = 'file_connectFileId',
    FILE_CONNECT_VERSION_ID = 'file_connectVersionId',
    FILE_SOURCE_LAYER = 'file_sourceLayer',
    WORKSPACE_ID = 'workspaceId',
    IMPORT_ID = 'importId',
    COLLECTION_USER_INFO = 'collection_userinfo',
    GEOMETRY_TYPE = 'geometry_type',
    COLLECTION_TODO_ID = 'collection_todoid',
    COLLECTION_SYNC_DATE = 'collection_syncDate',
    COLLECTION_GEOMETRY_UTC = 'collection_geometry_utc'
}

// A feature instance, decorated with some template information, for display in the feature side-panel
export class TemplatedFeature implements IFeature {
    id: string;
    metadata: { [key: string]: any };
    properties: { [key: string]: any };
    colorKey: string;
    geometryType: string;
    geometry: any;
    tags: string[];
    type: string;
    layerName: string;
    isLayerRemoved: boolean;
    template_name: string;
    fields: FeatureField[];
    totalPages: number;

    fieldCount = 0;

    // Model/Constructor
    constructor(feature: Feature, template: Template) {
        this.metadata = feature.metadata;
        this.properties = feature.properties;
        this.id = feature.id;
        this.colorKey = feature.colorKey;
        this.geometryType = feature.geometryType;
        this.geometry = feature.geometry;
        this.type = 'Feature';
        this.layerName = feature.layerName;
        this.isLayerRemoved = feature.isLayerRemoved;

        this.template_name = template.name;

        let pageCount = 0;
        this.fields = [];
        let groupId: string = null;
        const groups: { [key: string]: { displayName: string; uuid: string } } = {};

        template.groups.forEach(group => (groups[group.uuid] = group));

        const templateFields: FeatureField[] = [];
        template.fields.forEach(field => {
            const featureField = this.createFeatureField(feature, field.toDTO());
            if (field.groupId) {
                if (groupId !== field.groupId) {
                    groupId = field.groupId;
                    templateFields.push({
                        type: LayoutFieldType.Group,
                        id: Number(field.groupId),
                        displayName: groups[field.groupId].displayName
                    } as FeatureField);
                }
            }

            templateFields.push(featureField);

            if (featureField.type === LayoutFieldType.PageHeader) {
                (featureField as PageHeaderFeatureField).pageCount = ++pageCount;
            }
        });
        this.fields = templateFields;
        this.totalPages = pageCount;
    }

    toDTO(): IFeatureDTO {
        // to API request DTO
        return {
            id: this.id,
            metadata: this.metadata,
            properties: this.properties,
            tags: this.tags
        };
    }

    createFeatureField(feature: Feature, templateField: Field): FeatureField {
        const fieldValue: any =
            undefined === typeof feature.properties[templateField.name]
                ? feature.properties[templateField.name.toLowerCase()]
                : feature.properties[templateField.name];

        const featureField: FeatureField = {
            id: this.fieldCount++,
            uuid: templateField.uuid,
            name: templateField.name,
            displayName: templateField.displayName,
            type: templateField.type,
            groupId: templateField.groupId,
            required: templateField.required,
            value: null,
            hasError: false
        };

        switch (templateField.type) {
            case LayoutFieldType.Group:
                let groupFeatureField = featureField as GroupFeatureField;
                let groupTemplateField = templateField as GroupField;
                groupFeatureField.fields = [];
                if (groupTemplateField.fields) {
                    groupTemplateField.fields.forEach(f => {
                        const f2 = this.createFeatureField(feature, f.toDTO());
                        groupFeatureField.fields.push(f2);
                    });
                }
                break;

            case FieldType.Image:
            case FieldType.Signature:
                let imageSignatureFeatureField = featureField as ImageSignatureFeatureField;
                if (fieldValue && StringUtils.isString(fieldValue)) {
                    const images: string[] = fieldValue.split('|');
                    imageSignatureFeatureField.value = images;
                }
                break;

            case FieldType.Date:
                let dateFeatureField = featureField as DateFeatureField;
                let dateTemplateField = templateField as DateField;
                dateFeatureField.value = fieldValue;
                dateFeatureField.minimum = dateTemplateField.minimum as Date;
                dateFeatureField.maximum = dateTemplateField.maximum as Date;
                break;

            case FieldType.Number:
            case FieldType.Length:
            case FieldType.Angle:
                let numberFeatureField = featureField as NumberFeatureField;
                let numberTemplateField = templateField as NumberField;
                numberFeatureField.value = fieldValue;
                numberFeatureField.scale = numberTemplateField.scale;
                numberFeatureField.minimum = numberTemplateField.minimum as number;
                numberFeatureField.maximum = numberTemplateField.maximum as number;
                numberFeatureField.numberType = numberTemplateField.numberType;
                numberFeatureField.angleUnit = numberTemplateField.angleUnit;
                numberFeatureField.distanceUnit = numberTemplateField.distanceUnit;
                break;

            case FieldType.Text:
                let textFeatureField = featureField as TextFeatureField;
                let textTemplateField = templateField as TextField;
                textFeatureField.value = fieldValue;
                textFeatureField.maxLength = textTemplateField.length;
                break;

            case FieldType.Autofield:
            case AutoFieldModelType.GeometryArea:
            case AutoFieldModelType.GeometryLength:
            case AutoFieldModelType.EstimatedVerticalPrecision:
            case AutoFieldModelType.EstimatedAccuracy:
            case AutoFieldModelType.XPosition:
            case AutoFieldModelType.YPosition:
            case AutoFieldModelType.ZPosition:
                let autoFeatureField = featureField as AutoFeatureField;
                let autoTemplateField = templateField as AutoField;
                autoFeatureField.modelType = autoTemplateField.modelType;
                autoFeatureField.value = fieldValue;
                autoFeatureField.displayValue = autofieldDisplayValueDict[fieldValue] || fieldValue;
                autoFeatureField.unit = autoTemplateField.unit;
                autoFeatureField.isUTC = false;
                break;

            case AutoFieldModelType.CreationDateTime:
            case AutoFieldModelType.UpdateDateTime:
                let autoFeatureField2 = featureField as AutoFeatureField;
                autoFeatureField2.value = fieldValue;
                autoFeatureField2.isUTC = false;
                autoFeatureField2.modelType = featureField.type;
                break;

            case FieldType.Choice:
                let choiceFeatureField = featureField as ChoiceFeatureField;
                let choiceTemplateField = templateField as ChoiceField;
                choiceFeatureField.value = fieldValue ? fieldValue.toString().split('|') : [];
                choiceFeatureField.options = choiceTemplateField.values.map(value => value.text); // no need for default flag (yet)
                choiceFeatureField.allowMultiSelect = choiceTemplateField.allowMultiSelect;
                choiceFeatureField.allowNonDomainValues = choiceTemplateField.allowNonDomainValues;
                choiceFeatureField.maxLength = choiceTemplateField.length;
                break;

            case FieldType.CodedChoice:
                let codedChoiceTemplateField = templateField as CodedChoiceField;
                let codedChoiceFeatureField = featureField as CodedChoiceFeatureField;
                codedChoiceFeatureField.value = fieldValue;
                codedChoiceFeatureField.options = codedChoiceTemplateField.values.map(value => ({
                    code: value.code,
                    description: value.description
                }));
                break;

            case FieldType.Barcode:
                let barcodeFeatureField = featureField as BarcodeFeatureField;
                let barcodeTemplateField = templateField as BarcodeField;
                barcodeFeatureField.value = fieldValue;
                barcodeFeatureField.maxLength = barcodeTemplateField.length;
                break;

            case FieldType.YesNo:
                let yesNoFeatureField = featureField as YesNoFeatureField;
                yesNoFeatureField.value = fieldValue;
                break;
        }

        return featureField;
    }
}

export const autofieldDisplayValueDict: { [key: string]: string } = {
    CodeCorrected: 'Code Corrected',
    RtxCodeCorrected: 'RTX Code Corrected',
    ProcessingCodeCorrected: 'Processing Code Corrected',
    RtxCarrierCorrected: 'RTX Carrier Corrected',
    CarrierCorrected: 'Carrier Corrected',
    ProcessingCarrierCorrected: 'Processing Carrier Corrected',
    Rtx: 'RTX'
};
