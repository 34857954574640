<div class="filter-panel-list flex-box">
    <div id="filter-panel">
        <header>
            <h2 translate="TC.Common.Filters"></h2>
            <div class="clear-filter" [hidden]="!showClearFilter">
                <gsp-button
                    title="{{ 'TCW_Clear_Filters' | translate }}"
                    tooltipLocation="top"
                    (action)="clearFilter()"
                    [type]="ButtonType.Icon"
                    iconClass="icon_line_clear_filter i32"
                ></gsp-button>
            </div>
        </header>
        <custom-scroll class="filter-group-container height-full">
            <section class="filter-group filter-accordion">
                <div class="filter-accordion-wrapper">
                    <section [ngClass]="{ open: isAccordionOpen[FilterGroup.DateModified] }">
                        <div class="filter-header" (click)="toggleAccordion(FilterGroup.DateModified)">
                            <i class="i32 icon_line_chevron_left"></i>
                            <h3 class="filter-group-title" translate="TC.Common.DateModified"></h3>
                        </div>
                        <div class="filter-contents">
                            <div class="date-wrapper">
                                <label>
                                    <div class="date-picker-wrapper">
                                        <date-picker
                                            id="date-modified-from"
                                            [(ngModel)]="fromDate"
                                            [maxDate]="toDate"
                                            (ngModelChange)="applyFilter()"
                                            placeholder="{{ 'TC.Common.From' | translate }}"
                                        ></date-picker>
                                    </div>
                                </label>

                                <label>
                                    <div class="date-picker-wrapper">
                                        <date-picker
                                            id="date-modified-to"
                                            [(ngModel)]="toDate"
                                            [minDate]="fromDate"
                                            (ngModelChange)="applyFilter()"
                                            placeholder="{{ 'TC.Common.To' | translate }}"
                                        ></date-picker>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </section>
                </div>
            </section>

            <section class="filter-group filter-accordion modified-by-filter" *ngIf="getModifiedByFilterVisibility()">
                <div class="filter-accordion-wrapper">
                    <section [ngClass]="{ open: isAccordionOpen[FilterGroup.ModifiedBy] }">
                        <div class="filter-header" (click)="toggleAccordion(FilterGroup.ModifiedBy)">
                            <i class="i32 icon_line_chevron_left"></i>
                            <h3 class="filter-group-title" translate="TC.Common.ModifiedBy"></h3>
                        </div>
                        <div class="filter-contents">
                            <user-filter
                                class="tag-input-alternative"
                                [(selectedUsers)]="selectedUsers"
                                [allUsers]="currentProjectUsers"
                                [placeholder]="'beginTypeToSearch' | translate"
                                (selectedUsersChange)="applyUserFilter($event)"
                            ></user-filter>
                        </div>
                    </section>
                </div>
            </section>

            <section class="filter-group filter-accordion">
                <div class="filter-accordion-wrapper">
                    <section
                        [ngClass]="{ disabled: isAttrValueDisabled, open: isAccordionOpen[FilterGroup.AttrValue] }"
                    >
                        <div class="filter-header" (click)="toggleAccordion(FilterGroup.AttrValue)">
                            <i class="i32 icon_line_chevron_left"></i>
                            <h3 class="filter-group-title" translate="TC.Common.AttributeValue"></h3>
                        </div>
                        <div class="filter-contents">
                            <gsp-dropdown
                                id="attr-value-select-layer"
                                class="attr-value-dropdown"
                                [options]="layersAvailable"
                                [(ngModel)]="selectedLayer"
                                [textField]="'layerName'"
                                [geomIcon]="true"
                                [overlapMenuOn]="true"
                                (ngModelChange)="onSelectLayerChange($event)"
                                [hideSearchBox]="true"
                                placeholder="{{ 'TC.Common.SelectLayerPlaceholder' | translate }}"
                            ></gsp-dropdown>
                            <gsp-dropdown
                                id="attr-value-select-field"
                                [ngDisabled]="!selectedLayer || !fieldsAvailable?.length"
                                class="attr-value-dropdown"
                                [options]="fieldsAvailable"
                                [(ngModel)]="selectedField"
                                [idField]="'uuid'"
                                [textField]="'label'"
                                [overlapMenuOn]="true"
                                [hideSearchBox]="true"
                                (ngModelChange)="onSelectFieldChange($event)"
                                placeholder="{{ 'TC.Common.SelectFieldPlaceholder' | translate }}"
                            ></gsp-dropdown>

                            <div *ngIf="selectedLayer && selectedField" class="filter-values">
                                <label>
                                    {{
                                        isMultipleChoiceField()
                                            ? ('TC.Common.ExactlyMatches' | translate) + ':'
                                            : ('TC.Common.ValuesWith' | translate)
                                    }}
                                </label>
                                <label *ngIf="selectedFieldType(FieldType.Text) && !isCodedChoiceOrChoiceField()">
                                    <gsp-text
                                        class="attr-value-text"
                                        name="attr-value-text"
                                        maxlength="30"
                                        [(ngModel)]="attrValues"
                                        (ngModelChange)="onAttrValueChangeDebounced($event)"
                                        placeholder="{{ 'TC.Common.EnterText' | translate }}"
                                    ></gsp-text>
                                </label>

                                <!-- Coded Choice / Choice -->
                                <div *ngIf="selectedFieldType(FieldType.Text) && isCodedChoiceOrChoiceField()">
                                    <ng-container *ngIf="isMultipleChoiceField(); else disallowedMultiSelect">
                                        <!-- applies only for choice field -->
                                        <gsp-checkbox
                                            *ngFor="let choice of getCodedChoiceOrChoiceList()"
                                            id="{{ 'filter-panel-multi-select-' + choice.index }}"
                                            name="{{ 'filter-panel-multi-select-' + choice.index }}"
                                            class="filter-panel-multi-select-choice-field"
                                            [(value)]="attrValuesMultipleSelect[choice?.text]"
                                            (valueChange)="onAttrValueChange($event)"
                                            [label]="choice?.text"
                                        ></gsp-checkbox>
                                    </ng-container>

                                    <ng-template #disallowedMultiSelect>
                                        <!-- list of all coded choices using gsp-radio -->
                                        <gsp-radio
                                            *ngFor="let choice of getCodedChoiceOrChoiceList()"
                                            id="{{ 'filter-panel-coded-choice-or-choice' + choice.index }}"
                                            [(ngModel)]="attrValues"
                                            (ngModelChange)="onAttrValueChange($event)"
                                            radioLabelClass="radio-label-wrap filter-panel-coded-choice-or-choice-label"
                                            radioInputClass="radio-input filter-panel-coded-choice-or-choice-input"
                                            radioButtonClass="dark-radio-button filter-panel-coded-choice-or-choice-button"
                                            name="filter-panel-coded-choice-choice-field"
                                            [radioValue]="choice?.code || choice?.text"
                                            [valueText]="choice?.description || choice?.text"
                                        ></gsp-radio>
                                    </ng-template>
                                </div>

                                <label *ngIf="selectedFieldType(FieldType.Number)">
                                    <gsp-number
                                        class="attr-value-number"
                                        name="attr-value-number"
                                        maxlength="30"
                                        [(ngModel)]="attrValues"
                                        (ngModelChange)="onAttrValueChangeDebounced($event)"
                                        placeholder="{{ 'TC.Common.EnterNumberWithoutPeriod' | translate }}"
                                        [decimalPlaces]="selectedFieldTypeOption.decimalPlaces"
                                    ></gsp-number>
                                </label>

                                <div *ngIf="selectedFieldType(FieldType.Date)">
                                    <label>
                                        <div class="date-picker-wrapper">
                                            <date-picker
                                                id="attr-value-date-from"
                                                [(ngModel)]="attrValuesFrom"
                                                [maxDate]="attrValuesTo"
                                                (ngModelChange)="onAttrValueChange($event)"
                                                placeholder="{{ 'TC.Common.From' | translate }}"
                                            ></date-picker>
                                        </div>
                                    </label>
                                    <label>
                                        <div class="date-picker-wrapper">
                                            <date-picker
                                                [ngClass]="{ 'disabled-field': !attrValuesFrom }"
                                                [enabled]="attrValuesFrom"
                                                id="attr-value-date-to"
                                                [(ngModel)]="attrValuesTo"
                                                [minDate]="attrValuesFrom"
                                                (ngModelChange)="onAttrValueChange($event)"
                                                placeholder="{{ 'TC.Common.To' | translate }}"
                                            ></date-picker>
                                        </div>
                                    </label>
                                </div>

                                <label *ngIf="selectedFieldType(FieldType.YesNo)">
                                    <div class="yes-no-field">
                                        <gsp-radio
                                            id="filter-panel-yesno-field-1"
                                            [(ngModel)]="attrValues"
                                            (ngModelChange)="onAttrValueChange($event)"
                                            radioLabelClass="radio-label-wrap"
                                            radioInputClass="radio-input"
                                            radioButtonClass="dark-radio-button"
                                            name="yesno-field"
                                            valueText="{{ 'none' | translate }}"
                                            [radioValue]="'null'"
                                        ></gsp-radio>
                                    </div>
                                    <div class="yes-no-field">
                                        <gsp-radio
                                            id="filter-panel-yesno-field-2"
                                            [(ngModel)]="attrValues"
                                            (ngModelChange)="onAttrValueChange($event)"
                                            radioLabelClass="radio-label-wrap"
                                            radioInputClass="radio-input"
                                            radioButtonClass="dark-radio-button"
                                            name="yesno-field"
                                            class="ng-pristine ng-valid"
                                            valueText="{{ 'yes' | translate }}"
                                            [radioValue]="'true'"
                                        ></gsp-radio>
                                    </div>
                                    <div class="yes-no-field">
                                        <gsp-radio
                                            id="filter-panel-yesno-field-3"
                                            [(ngModel)]="attrValues"
                                            (ngModelChange)="onAttrValueChange($event)"
                                            radioLabelClass="radio-label-wrap"
                                            radioInputClass="radio-input"
                                            radioButtonClass="dark-radio-button"
                                            name="yesno-field"
                                            valueText="{{ 'no' | translate }}"
                                            [radioValue]="'false'"
                                        ></gsp-radio>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </section>
                </div>
            </section>

            <section class="filter-group filter-accordion">
                <div class="filter-accordion-wrapper">
                    <section [ngClass]="{ open: isAccordionOpen[FilterGroup.Status] }">
                        <div class="filter-header" (click)="toggleAccordion(FilterGroup.Status)">
                            <i class="i32 icon_line_chevron_left"></i>
                            <h3 class="filter-group-title" translate="TC.Common.Status"></h3>
                        </div>
                        <div class="filter-contents">
                            <ul class="list-content">
                                <li class="list-item">
                                    <gsp-checkbox
                                        [(value)]="isExported"
                                        [id]="'exportedFeatures'"
                                        [label]="'TCS.Mapviewer.Filters.Exported' | translate"
                                        (valueChange)="applyFilter()"
                                    ></gsp-checkbox>
                                </li>
                                <li class="list-item">
                                    <gsp-checkbox
                                        [(value)]="isNotExported"
                                        [id]="'notExported'"
                                        [label]="'TCS.Mapviewer.Filters.NotExported' | translate"
                                        (valueChange)="applyFilter()"
                                    ></gsp-checkbox>
                                </li>
                                <li class="list-item">
                                    <gsp-checkbox
                                        [(value)]="unassignedUser"
                                        [id]="'unassigned'"
                                        [label]="'TCS.Mapviewer.Filters.Imported' | translate"
                                        (valueChange)="applyFilter()"
                                    ></gsp-checkbox>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>
            </section>

            <section class="filter-group filter-accordion">
                <div class="filter-accordion-wrapper">
                    <section
                        [ngClass]="{ disabled: !customTagsAvailable.length, open: isAccordionOpen[FilterGroup.Tags] }"
                    >
                        <div
                            class="filter-header"
                            (click)="customTagsAvailable.length ? toggleAccordion(FilterGroup.Tags) : null"
                        >
                            <i class="i32 icon_line_chevron_left"></i>
                            <h3 class="filter-group-title" translate="TCW_Tags"></h3>
                        </div>
                        <div class="filter-contents">
                            <ul class="list-content">
                                <li class="list-item" *ngFor="let tag of customTagsAvailable">
                                    <gsp-checkbox
                                        [(value)]="tagCheckBoxValues[tag]"
                                        [id]="'customFeatureTag-' + tag"
                                        [label]="tag"
                                        (valueChange)="selectCustomTags()"
                                    ></gsp-checkbox>
                                </li>
                                <li class="list-item">
                                    <gsp-checkbox
                                        [(value)]="noCustomTagsCheckbox"
                                        [id]="'customFeatureTag-noTags'"
                                        [label]="'TC.Tags.NoTags' | translate"
                                        (valueChange)="deselectCustomTags()"
                                    ></gsp-checkbox>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>
            </section>

            <section class="filter-group filter-accordion">
                <div class="filter-accordion-wrapper">
                    <section [ngClass]="{ open: isAccordionOpen[FilterGroup.OfflineGnssCorrectionStatus] }">
                        <div class="filter-header" (click)="toggleAccordion(FilterGroup.OfflineGnssCorrectionStatus)">
                            <i class="i32 icon_line_chevron_left"></i>
                            <h3 class="filter-group-title" translate="MapViewer.EasyPP.GNSSCorrectionStatus"></h3>
                        </div>
                        <div class="filter-contents">
                            <ul class="list-content">
                                <li class="list-item">
                                    <gsp-checkbox
                                        [(value)]="isPostProcessingUnprocessed"
                                        [id]="'postProcessingUnprocessed'"
                                        [label]="'MapViewer.EasyPP.NotProcessed' | translate"
                                        (valueChange)="applyFilter()"
                                    ></gsp-checkbox>
                                </li>
                                <li class="list-item">
                                    <gsp-checkbox
                                        [(value)]="isPostProcessingDone"
                                        [id]="'postProcessingDone'"
                                        [label]="'MapViewer.ImportStatusDialog.ProcessedMessage' | translate"
                                        (valueChange)="applyFilter()"
                                    ></gsp-checkbox>
                                </li>
                                <li class="list-item">
                                    <gsp-checkbox
                                        [(value)]="isPostProcessingPending"
                                        [id]="'postProcessingPending'"
                                        [label]="'MapViewer.EasyPP.QueuedForProcessing' | translate"
                                        (valueChange)="applyFilter()"
                                    ></gsp-checkbox>
                                </li>
                                <li class="list-item">
                                    <gsp-checkbox
                                        [(value)]="isPostProcessingFailed"
                                        [id]="'postProcessingFailed'"
                                        [label]="'MapViewer.EasyPP.FailedProcessing' | translate"
                                        (valueChange)="applyFilter()"
                                    ></gsp-checkbox>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>
            </section>
        </custom-scroll>
    </div>
</div>
