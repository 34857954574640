<div class="rules-panel">
    <div class="new-rule-button">
        <gsp-button
            [type]="ButtonType.Primary"
            (action)="createRule()"
            text="{{ 'TC.Common.CreateRule' | translate }} +"
        ></gsp-button>
    </div>

    <div
        class="sidebar-rule"
        *ngFor="let rule of rules"
        [ngClass]="{
            selected: rule.uuid == selectedRule?.uuid,
            'has-condition-errors':
                rule.conditionRuleErrors ||
                (rule.isRuleErrors().conditionErrors && !rule.selected) ||
                rule.getRuleLevelMessages().length,
            'has-target-errors': rule.targetRuleErrors || (rule.isRuleErrors().targetErrors && !rule.selected)
        }"
        (click)="selectRule(rule)"
        parent-height-class="tfx-editor-tabs"
    >
        <i
            class="trash-rule"
            (click)="trashRule(rule)"
            *ngIf="rules.length > 1 || (rule.conditions.length || rule.targets.length)"
        ></i>

        <div *ngIf="rule.conditions == undefined || rule.conditions.length < 1" class="condition">
            <div class="rule-title" *ngIf="rule.targets == undefined || rule.targets.length < 1">
                <span translate="TC.Common.BlankRule"></span>
            </div>
            <div class="rule-info">
                <strong class="placeholder" translate="TCS.TemplateEditor.Tf.Rules.SelectConditionField"></strong>
            </div>
            <div class="rule-condition-icon">
                <i *ngIf="rule.uuid == selectedRule?.uuid" class="icon-32-color-condition"></i>
            </div>
        </div>
        <div *ngFor="let condition of rule.conditions; index as i" class="condition">
            <div class="rule-info">
                <div title="{{ getField(condition.sourceUuid)?.displayName }}">
                    <strong *ngIf="i == 0" translate="TC.Common.If"></strong>
                    <strong *ngIf="i != 0" translate="TC.Common.Or"></strong>
                    {{ getField(condition.sourceUuid)?.displayName }}
                </div>
            </div>
            <div class="value">{{ condition.getConditionText(translate) }}</div>
            <div class="rule-condition-icon" *ngIf="i === rule.conditions.length - 1">
                <i class="icon-32-color-condition"></i>
            </div>
        </div>

        <div *ngIf="rule.getTargetsFilteredByAction(true).length" class="action">
            <div class="rule-target-icon">
                <i class="action-icon"></i>
            </div>
            <div class="targets rule-info">
                <div>
                    <strong translate="TC.Common.Then"></strong>&nbsp;<strong translate="TC.Common.Enable"></strong>
                    <ul>
                        <li
                            *ngFor="let target of rule.getTargetsFilteredByAction(true)"
                            class="may-overflow"
                            title="{{ getField(target.targetUuid)?.displayName }}"
                            >{{ getField(target.targetUuid)?.displayName }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div *ngIf="rule.getTargetsFilteredByAction(false).length" class="action">
            <div class="targets rule-info">
                <div>
                    <strong *ngIf="!rule.getTargetsFilteredByAction(true).length" translate="TC.Common.Then"></strong>
                    <strong *ngIf="rule.getTargetsFilteredByAction(true).length" translate="TC.Common.And"></strong><!--
                    -->&nbsp;<strong translate="TC.Common.Disable"></strong>
                    <ul>
                        <li 
                            *ngFor="let target of rule.getTargetsFilteredByAction(false)"
                            class="text-overflow"
                            title="{{ getField(target.targetUuid)?.displayName }}"
                            >{{ getField(target.targetUuid)?.displayName }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div *ngIf="rule.targets == undefined || rule.targets.length < 1" class="action">
            <div class="rule-target-icon">
                <i *ngIf="rule.uuid == selectedRule?.uuid" class="icon-32-color-target"></i>
            </div>
            <div class="rule-info targets">
                <strong class="placeholder" translate="TCS.TemplateEditor.Tf.Rules.SelectTargetField"></strong>
            </div>
        </div>
        <div *ngIf="rule.getRuleLevelMessages().length" class="rule-error">
            <ul>
                <li *ngFor="let er of rule.getRuleLevelMessages()">{{ er | translate }}</li>
            </ul>
        </div>
    </div>
</div>
