<ng-form #todoForm="ngForm" class="task-panel" [ngClass]="{ 'todo-edit-mode': newMode || editMode }">
    <header>
        <h2 class="title"><span translate="TODO"></span></h2>
        <gsp-button [type]="ButtonType.Icon" (action)="cancelSave()" [iconClass]="'i24 icon_line_cancel'"> </gsp-button>
    </header>

    <section class="height-full">
        <custom-scroll class="height-full">
            <div class="no-hover" *ngIf="loading">
                <loading class="loading-align-center"></loading>
            </div>
            <div *ngIf="!loading">
                <div class="task-content">
                    <div class="error-notification" *ngIf="exceedsLimit">
                        <p>{{ 'MapViewer_ToDos_ExceedsLimit' | translate }}</p>
                    </div>
                    <div class="input-group">
                        <label class="task-label">{{ 'title' | translate }}</label>
                        <div class="task-data" title="{{ task.title }}" *ngIf="!canEdit()">{{ task.title }}</div>
                        <input
                            *ngIf="canEdit()"
                            gsp-focus
                            preventScroll="true"
                            name="todoTitle"
                            [(ngModel)]="task.title"
                            (blur)="todoTitleRequired = !task.title"
                            placeholder="{{ 'TC.Common.NewTodo' | translate }}"
                            class="gsp-form-input"
                            [errorTooltip]="getErrorText(todoTitle)"
                            tooltipLocation="left"
                            required
                            #todoTitle="ngModel"
                        />
                    </div>
                    <div class="input-group">
                        <label class="task-label">{{ 'description' | translate }}</label>
                        <custom-scroll class="task-description-scroll">
                            <div class="task-data" *ngIf="!canEdit()">
                                {{ task.description }}
                            </div>
                        </custom-scroll>

                        <gsp-text-area
                            *ngIf="canEdit()"
                            name="todoDescription"
                            [(value)]="task.description"
                            placeholder="{{ 'TCS.DefaultLabel.TodoDescription' | translate }}"
                            [maxlength]="1024"
                        ></gsp-text-area>
                    </div>
                    <div class="input-group">
                        <label class="task-label">{{ 'status' | translate }}</label>
                        <div class="task-data">{{ statusDisplayValue() }}</div>
                    </div>
                    <div class="input-group todo-status">
                        <donut
                            [size]="32"
                            [alertColor]="'#5E9331'"
                            [warningColor]="'#5E9331'"
                            [dangerColor]="'#5E9331'"
                            [percent]="task.percentageComplete"
                        ></donut>
                        <div class="todo-status-value">
                            <span>{{ task.percentageComplete }}%</span><br />{{ 'TC.Common.Complete' | translate }}
                        </div>
                    </div>
                    <div class="input-group">
                        <label class="task-label">{{ 'TC.Common.AssignedUsers' | translate }}</label>
                        <!--<div  title="{{$ctrl.assigneesDisplayValue()}}" ng-if="!$ctrl.newMode && !$ctrl.editMode">{{$ctrl.assigneesDisplayValue()}}</div>-->
                        <!-- Code for TCWEB-1954  -->
                        <!-- <ul class="task-assignees">
                        <li>
                            <div class="icon"><i class="i24 icon_solid_user"></i></div>
                            <div class="name">Raman Babu</div>
                            <div class="status"><i class="i24 icon_line_tick_green"></i></div>
                        </li>
                        <li>
                            <div class="icon"><i class="i24 icon_solid_user"></i></div>
                            <div class="name">Kirithikaa Gurusamy Gurusamy</div>
                            <div class="status"><i class="i24 icon_line_tick_gray"></i></div>
                        </li>
                        <li>
                            <div class="icon"><i class="i24 icon_solid_user"></i></div>
                            <div class="name">Doug Ollivier</div>
                            <div class="status"><i class="i24 icon_line_tick_gray"></i></div>
                        </li>
                    </ul> -->
                        <!-- Code for TCWEB-1954  -->

                        <!-- TODO: GIM - work out why we generate multiple ul's rather than li's -->
                        <ng-container *ngFor="let assignee of task.assignees">
                            <ul class="task-assignees" *ngIf="!canEdit()">
                                <li title="{{ assignee.name }}" class="text-overflow">
                                    <div class="icon"><i class="i24 icon_solid_user"></i></div>
                                    <div class="name">{{ assignee.name }}</div>
                                    <div class="status">
                                        <i
                                            class="i24"
                                            [hidden]="assignee.type === PermissionType.GROUP"
                                            [ngClass]="{
                                                icon_line_tick_green: assignee.isTodoCompleted,
                                                icon_line_tick_gray: !assignee.isTodoCompleted
                                            }"
                                        ></i>
                                    </div>
                                </li>
                            </ul>
                        </ng-container>
                        <!-- TODO: GIM - work out why we generate multiple ul's rather than li's -->
                        <ng-container *ngFor="let assignee of task.unassignedCompletedUser">
                            <ul class="task-assignees" *ngIf="!canEdit() && task.isTodoCompletedByUnassignedUser">
                                <li title="{{ assignee.name }}" class="text-overflow">
                                    <div class="icon"><i class="i24 icon_solid_user"></i></div>
                                    <div class="name">{{ assignee.name }}</div>
                                    <div class="status">
                                        <i
                                            class="i24"
                                            [hidden]="assignee.type === PermissionType.GROUP"
                                            [ngClass]="{
                                                icon_line_tick_green: assignee.isTodoCompleted,
                                                icon_line_tick_gray: !assignee.isTodoCompleted
                                            }"
                                        ></i>
                                    </div>
                                </li>
                            </ul>
                        </ng-container>

                        <user-filter
                            *ngIf="canEdit()"
                            class="tag-input-alternative task-detail-panel"
                            [inputClass]="'tag-input-form'"
                            [(selectedUsers)]="task.assignees"
                            [allUsers]="currentProjectAssignees"
                            [name]="tags"
                            [placeholder]="'TC.Common.AllUsersBeginTypingToAdd' | translate"
                        ></user-filter>
                    </div>

                    <div class="input-group">
                        <label class="task-label" *ngIf="task.features.length">{{
                            'TC.Common.AttachedForms' | translate
                        }}</label>
                        <div *ngIf="!task.features.length">
                            {{ 'TCS.Todo.NoFeaturesAttached' | translate }}
                        </div>
                        <ng-container *ngIf="newMode && numberOfNonTemplatedFeaturesExcluded > 0">
                            <br />
                            <gsp-notification [type]="NotificationType.Warning">
                                <div>
                                    {{ 'TCS.Todo.Todo.NontemplatedFeaturesExcluded' | translate }}
                                </div>
                            </gsp-notification>
                        </ng-container>

                        <ul class="layers-list">
                            <ng-container *ngFor="let taskPanelLayer of taskPanelLayers">
                                <li *ngIf="taskPanelLayer.layer">
                                    <geom-icon
                                        path="sym_32-layer-"
                                        [color]="taskPanelLayer.layerColor"
                                        [geoType]="getTemplateGeometryType(taskPanelLayer.templateSeriesId)"
                                    ></geom-icon>
                                    <div class="layer-title" title="{{ getLayerName(taskPanelLayer.layer) }}">
                                        {{ getLayerName(taskPanelLayer.layer) }}
                                        <div *ngIf="taskPanelLayer.isLayerRemoved" class="layer-removed">
                                            {{ 'TCS.Mapviewer.Features.LayerRemoved' | translate }}
                                        </div>
                                    </div>
                                    <div
                                        class="feature-count"
                                        [ngStyle]="{ 'background-color': taskPanelLayer.layerColor }"
                                    >
                                        {{ taskPanelLayer.featureIds.length }}
                                    </div>
                                    <div *ngIf="newMode">
                                        <gsp-button
                                            (action)="removeFeatures(taskPanelLayer.featureIds)"
                                            [type]="ButtonType.Icon"
                                            iconClass="icon-cancel"
                                            class="remove-task-feature"
                                        >
                                        </gsp-button>
                                    </div>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
        </custom-scroll>
    </section>
    <div class="task-notification" *ngIf="task && task.features.length">
        <gsp-notification class="info">
            <div [innerHTML]="'TCS.Todo.Todo.RequiresSubscription' | translate"></div>
        </gsp-notification>
    </div>

    <footer>
        <div *ngIf="!newMode && !editMode && modifyPermission">
            <gsp-confirmation-button
                [type]="ButtonType.Default"
                text="{{ 'delete' | translate }}"
                confirmText="{{ 'delete' | translate }}"
                [disabled]="disableDelete()"
                (action)="delete()"
                id="delete-task"
            ></gsp-confirmation-button>
            <gsp-button
                [type]="ButtonType.Primary"
                (action)="editMode = true"
                title="{{ 'TCW_Edit' | translate }}"
                [disabled]="disableEdit"
                text="{{ 'TCW_Edit' | translate }}"
                id="edit-task"
            ></gsp-button>
        </div>
        <div *ngIf="newMode || editMode">
            <gsp-button
                [type]="ButtonType.Default"
                (action)="cancelSave()"
                text="{{ 'cancel' | translate }}"
                id="cancel-edit-task"
            ></gsp-button>
            <gsp-button
                [disabled]="!todoForm.valid || exceedsLimit"
                [type]="ButtonType.Primary"
                [asyncAction]="saveAction"
                text="{{ 'save' | translate }}"
                id="save-task"
            ></gsp-button>
        </div>
    </footer>
</ng-form>
