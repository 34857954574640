import { TranslationService } from 'src/app/core/translation/translation.service';
import {
    TemplatedFeatureMetadataProperty,
} from 'src/app/feature/map-viewer/side-panel/feature-panel/feature-fields/templated-feature';
import { PostProcessingStatus } from 'src/app/feature/post-processing/options/post-processing-options.component';
import { Feature } from 'src/app/shared/map-data-services/feature/feature';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';
import { User } from 'src/app/shared/user/user';

import { GspActivity, GspActivityType } from './gsp-activity-tab.component';

export class ActivityTabUtil {
    static getDatewithDifference(initialDate: Date, difference: number): Date {
        const newDate = new Date();
        newDate.setDate(initialDate.getDate() - difference);
        return newDate;
    }

    static getTodayCurrentTimestamp(): Date {
        const newDate = new Date();
        return newDate;
    }

    static getTodayStartTimestamp(): Date {
        const newDate = new Date();
        newDate.setHours(0, 0, 0, 0);
        return newDate;
    }

    static getYesterdayStartTimestamp(): Date {
        const newDate = this.getDatewithDifference(new Date(), 1);
        newDate.setHours(0, 0, 0, 0);
        return newDate;
    }

    static getThisWeekStartTimestamp(): Date {
        const newDate = new Date();
        const today = newDate.getDay();
        if (today !== 1) {
            newDate.setHours(-24 * (today - 1));
        }
        newDate.setHours(0, 0, 0, 0);
        return newDate;
    }

    static getLastWeekStartTimestamp(): Date {
        const newDate = this.getDatewithDifference(this.getThisWeekStartTimestamp(), 7);
        newDate.setHours(0, 0, 0, 0);
        return newDate;
    }

    static getThisMonthStartTimestamp(): Date {
        const newDate = new Date();
        const today = newDate.getDate();
        if (today !== 1) {
            newDate.setHours(-24 * (today - 1));
        }
        newDate.setHours(0, 0, 0, 0);
        return newDate;
    }

    static stripMilliseconds(timestamp: string): string {
        return timestamp.split('.')[0];
    }

    static constructActivitiesForTimeline(
        features: Feature[],
        layerKeyToLayerMap: { [key: string]: Layer },
        startDate: Date,
        endDate: Date,
        projectUsers: User[],
        translate: TranslationService
    ): GspActivity[] {
        const processedFeatures: Feature[] = [];
        const queuedFeatures: Feature[] = [];
        const failedFeatures: Feature[] = [];
        const syncedFeaturesPerUser: { [syncedUserId: string]: Feature[] } = {};
        features.forEach(feature => {
            // make sure layer hasn't been deleted
            if (layerKeyToLayerMap[feature.metadata[TemplatedFeatureMetadataProperty.COMMON_LAYER_ID]]) {
                const lastGeometryChange = new Date(
                    feature.metadata[TemplatedFeatureMetadataProperty.COLLECTION_GEOMETRY_UTC]
                );
                const syncDate = new Date(feature.metadata[TemplatedFeatureMetadataProperty.COLLECTION_SYNC_DATE]);

                if (lastGeometryChange > startDate && lastGeometryChange <= endDate) {
                    // segregate feature activity type and add data to the timeline
                    switch (feature.metadata[TemplatedFeatureMetadataProperty.POST_PROCESSED_STATUS]) {
                        case PostProcessingStatus.PROCESSED:
                            processedFeatures.push(feature);
                            break;
                        case PostProcessingStatus.PENDING:
                            queuedFeatures.push(feature);
                            break;
                        case PostProcessingStatus.FAILED:
                            failedFeatures.push(feature);
                            break;
                    }
                }

                if (syncDate > startDate && syncDate <= endDate) {
                    const syncedUserId = feature.metadata[TemplatedFeatureMetadataProperty.COLLECTION_UPDATED_BY];
                    if (!syncedFeaturesPerUser[syncedUserId]) {
                        syncedFeaturesPerUser[syncedUserId] = [];
                    }
                    syncedFeaturesPerUser[syncedUserId].push(feature);
                }
            }
        });

        const activities: GspActivity[] = [];
        if (processedFeatures.length) {
            activities.push({
                type: GspActivityType.POST_PROCESSING_COMPLETE,
                data: {
                    features: processedFeatures
                }
            });
        }
        if (queuedFeatures.length) {
            activities.push({
                type: GspActivityType.POST_PROCESSING_QUEUED,
                data: {
                    features: queuedFeatures
                }
            });
        }
        if (failedFeatures.length) {
            activities.push({
                type: GspActivityType.POST_PROCESSING_FAILED,
                data: {
                    features: failedFeatures
                }
            });
        }
        if (Object.keys(syncedFeaturesPerUser).length) {
            const activityData = ActivityTabUtil.getActivityDataForFeatureUserActivities(
                syncedFeaturesPerUser,
                projectUsers,
                translate
            );
            activityData.forEach(data => {
                activities.push({
                    type: GspActivityType.FEATURES_SYNCED,
                    data
                });
            });
        }
        return activities;
    }

    static getActivityDataForFeatureUserActivities(
        featuresPerUser: { [userId: string]: Feature[] },
        projectUsers: User[],
        translate: TranslationService
    ): { features: Feature[]; userName: string; multiple: boolean; userCount?: number }[] {
        const activityData: { features: Feature[]; userName: string; multiple: boolean; userCount?: number }[] = [];
        const userIds = Object.keys(featuresPerUser);
        if (userIds.length > 3) {
            let maxUserId = userIds[0];
            const totalFeatures: Feature[] = [];
            userIds.forEach(userId => {
                if (featuresPerUser[userId].length > featuresPerUser[maxUserId].length) {
                    maxUserId = userId;
                }
                totalFeatures.push(...featuresPerUser[userId]);
            });
            const syncedUser = projectUsers.find((user: User) => user.id === maxUserId);
            activityData.push({
                features: totalFeatures,
                userName: syncedUser ? syncedUser.name : translate.instant('MapViewer_Generic_RemovedUser'),
                multiple: true,
                userCount: userIds.length
            });
        } else {
            userIds.forEach(userId => {
                const syncedUser = projectUsers.find((user: User) => user.id === userId);
                activityData.push({
                    features: featuresPerUser[userId],
                    userName: syncedUser ? syncedUser.name : translate.instant('MapViewer_Generic_RemovedUser'),
                    multiple: false
                });
            });
        }
        return activityData;
    }
}
