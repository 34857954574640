import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService, CONNECT_LINK_KEY } from 'src/app/core/authentication/authentication.service';
import { CurrentEntitlementStreamService } from 'src/app/shared/common/current-entitlement/current-entitlement-stream.service';
import { CurrentRegionStreamService } from 'src/app/shared/common/current-region/region-stream.service';
import { CurrentUserStreamService } from 'src/app/shared/common/current-user/current-user-stream.service';
import { EnvironmentService } from 'src/app/shared/common/environment/environment.service';
import { FeatureFlagStreamService } from 'src/app/shared/common/feature-flag/feature-flag-stream.service';

import { ACCESS_MODE_KEY, AccessMode, SHARED_MODE_KEY } from '../../authentication/share-token';
import { ErrorPageErrorCodes } from '../../error-page/error-page.component';

export const authGuard: CanActivateFn = async (
    route: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot,
    authenticationService: AuthenticationService = inject(AuthenticationService),
    currentUserStream: CurrentUserStreamService = inject(CurrentUserStreamService),
    currentRegionStreamService: CurrentRegionStreamService = inject(CurrentRegionStreamService),
    currentEntitlementStreamService: CurrentEntitlementStreamService = inject(CurrentEntitlementStreamService),
    router: Router = inject(Router),
    env: EnvironmentService = inject(EnvironmentService),
    featureFlagStream: FeatureFlagStreamService = inject(FeatureFlagStreamService)
) => {
    const { data, params, queryParams } = route;
    const navigatingUrl = routerState.url;
    let projectId: string = null;
    const isExportDownloaderRoute = navigatingUrl.indexOf('export') > -1 && navigatingUrl.indexOf('download') > -1;
    if (isExportDownloaderRoute) {
        projectId = params.projectId;
    } else {
        // '/maps' route when loading a workspace from connect has projectId in url params.
        projectId = params.projectId || queryParams.projectId;
    }
    const connectFolderId: string = params.connectFolderId || queryParams.connectFolderId;
    const connectFileId: string = params.connectFileId || queryParams.connectFileId;
    const region: string = queryParams.region;
    if (connectFileId) {
        // Save initial link from connect in case of authorisation issues
        window.sessionStorage.setItem(
            CONNECT_LINK_KEY,
            JSON.stringify({
                projectId,
                connectFolderId,
                connectFileId
            })
        );
    }

    if (sessionStorage.getItem(ACCESS_MODE_KEY) === AccessMode.SHARED) {
        const shareMode = sessionStorage.getItem(SHARED_MODE_KEY);
        if (data && data.visibility && data.visibility.includes(shareMode)) {
            return true;
        }
    }

    if (authenticationService.isAuthenticated()) {
        try {
            if (!env.serviceReadyStream.getValue()) {
                await currentRegionStreamService.setCurrentRegionConfig();
            }
            await currentRegionStreamService.initCurrentRegion(projectId, region);
            if (currentUserStream.currentUser) {
                if (!currentEntitlementStreamService.currentEntitlements.getValue()) {
                    await currentEntitlementStreamService.initEntitlements();
                }
                if (!featureFlagStream.featureFlags.getValue()) {
                    await featureFlagStream.initFlags();
                }
                return true;
            } else {
                return false;
            }
        } catch (error) {
            router.navigate(['error', ErrorPageErrorCodes.INVALID_REGION]);
            return false;
        }
    } else {
        const loginUrl = await authenticationService.getLoginUrl();
        window.location.href = loginUrl;
        return false;
    }
};
