import { Directive, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription, timer } from 'rxjs';

/*
 * Error Tooltip usage
 * <input type="text" errorTooltip="{{'TC.Common.ErrorMsg' | translate}}" />
 */

@Directive({ selector: '[errorTooltip]' })
export class ErrorTooltipDirective implements OnInit, OnDestroy, OnChanges {
    @Input()
    public errorTooltip: string = null;

    @Input()
    public tooltipLocation = 'right';

    private loading: Subscription;
    private unload: Subscription;
    private location = 'top';
    private tooltipNode: { remove: () => void; classList: { remove: any } };
    private tooltipY: string;
    private tooltipX: string;
    private eventListenerHandlersArr: any = [];

    @HostListener('mouseenter')
    onmouseEnter(): void {
        if (this.errorTooltip) {
            this.showTooltip();
            this.setupListeners();
        }
    }

    @HostListener('mouseleave')
    onmouseleave(): void {
        this.hideTooltip();
    }

    constructor(private element: ElementRef, private render: Renderer2) {}

    ngOnInit(): void {
        this.location = this.tooltipLocation;
    }

    ngOnChanges(): void {
        this.unload?.unsubscribe();
        if (this.errorTooltip) {
            if (this.getElementVisiblity(this.element)) {
                this.showTooltip();
            }
            this.render.addClass(this.element.nativeElement, 'error-highlight');
            this.setupListeners();
        } else {
            this.hideTooltip();
            this.render.removeClass(this.element.nativeElement, 'error-highlight');
        }
    }

    ngOnDestroy(): void {
        this.hideTooltip();
        this.render.removeClass(this.element.nativeElement, 'error-highlight');
    }

    setupListeners(): void {
        this.eventListenerHandlersArr.push(
            this.render.listen(this.element.nativeElement, 'mouseenter', this.showTooltip.bind(this))
        );
        this.eventListenerHandlersArr.push(
            this.render.listen(this.element.nativeElement, 'mouseleave', this.hideTooltip.bind(this))
        );
        this.eventListenerHandlersArr.push(
            this.render.listen(this.element.nativeElement, 'click', this.hideTooltip.bind(this))
        );
        this.eventListenerHandlersArr.push(this.render.listen(document.body, 'mousedown', this.hideTooltip.bind(this)));
        this.eventListenerHandlersArr.push(this.render.listen(document.body, 'wheel', this.hideTooltip.bind(this)));
    }

    removeListeners(): void {
        this.eventListenerHandlersArr.map((eventListenerHandler: any) => {
            eventListenerHandler();
        });
        this.eventListenerHandlersArr = [];
    }

    getElementVisiblity(element: ElementRef): boolean {
        let visiblity = false;
        let scrollContainer = this.getParent(element.nativeElement, 'scroll-container');
        if (scrollContainer) {
            let scrollAreaBounds = scrollContainer.getBoundingClientRect();
            let elementAreaBounds = element.nativeElement.getBoundingClientRect();
            if (
                scrollAreaBounds.top <= elementAreaBounds.top &&
                scrollAreaBounds.bottom >= elementAreaBounds.bottom &&
                scrollAreaBounds.left <= elementAreaBounds.left &&
                scrollAreaBounds.right >= elementAreaBounds.right
            ) {
                visiblity = true;
            }
        } else {
            visiblity = true;
        }
        return visiblity;
    }

    getParent(element: HTMLElement, classSelector: string): HTMLElement {
        let parentElement: HTMLElement;
        let p = element.parentNode as HTMLElement;
        while (p) {
            let o = p;
            if (o && o.classList && o.classList.contains(classSelector)) {
                parentElement = o;
                break;
            }
            p = o.parentNode as HTMLElement;
        }
        return parentElement;
    }

    createTooltip(tooltipText: string): any {
        let tooltip = this.render.createElement('div');
        tooltip.innerText = tooltipText;
        this.render.addClass(tooltip, 'error-tooltip-content');
        return tooltip;
    }

    showTooltip(): void {
        if (this.tooltipNode) {
            this.tooltipNode.remove();
        }
        this.tooltipNode = this.createTooltip(this.errorTooltip);
        this.render.appendChild(this.tooltipNode, this.render.createText(''));
        this.render.appendChild(document.body, this.tooltipNode);
        this.loading = timer(0).subscribe(() => {
            this.render.addClass(this.tooltipNode, 'show');
            let position = this.getPosition(this.element.nativeElement, this.tooltipNode);
            this.render.addClass(this.tooltipNode, this.location);
            this.render.setStyle(this.tooltipNode, 'left', position.left);
            this.render.setStyle(this.tooltipNode, 'top', position.top);
        });
        // remove tooltip after 5 seconds - mostly to get rid of any left hanging around if
        // mouseleave event missed
        this.unload = timer(5000).subscribe(() => {
            if (this.tooltipNode) {
                this.render.removeChild(document.body, this.tooltipNode);
                this.removeListeners();
            }
        });
    }

    hideTooltip(): void {
        if (this.loading) {
            this.loading.unsubscribe();
        }
        if (this.unload) {
            this.unload.unsubscribe();
        }
        if (this.tooltipNode && this.tooltipNode.classList && this.tooltipNode.classList.remove) {
            this.render.removeClass(this.tooltipNode, 'show');
        }
        if (this.tooltipNode && this.tooltipNode.remove) {
            this.tooltipNode.remove();
        }
        this.removeListeners();
    }

    getPosition(parentElement: HTMLElement, tooltipElement: any): { left: string; top: string } {
        let parentBounds = parentElement.getBoundingClientRect();

        let x = parentBounds.left;
        let y = parentBounds.top;

        // Get size of browser
        let browserSize = {
            width: window.innerWidth,
            height: window.innerHeight
        };

        // Get size of parent element
        let parentSize = {
            width: parentElement.offsetWidth,
            height: parentElement.offsetHeight
        };

        // centre the tooltip against parent
        let tooltipCentre = tooltipElement.offsetWidth / 2;
        let containerCentre = parentElement.offsetWidth / 2;
        let offset = containerCentre - tooltipCentre;

        // Alter position based on context and browser size
        if (x + parentSize.width > browserSize.width) {
            x = x - (x + parentSize.width - browserSize.width);
        }
        if (y + parentSize.height > browserSize.height) {
            y = y - (y + parentSize.height - browserSize.height);
        }

        if (this.location === 'top' && y - tooltipElement.offsetHeight - 5 < 0) {
            this.location = 'bottom';
        }

        switch (this.location) {
            case 'right':
                this.tooltipX = parentBounds.right + 5 + 'px';
                this.tooltipY = y - tooltipElement.offsetHeight / 2 + 16 + 'px';
                break;
            case 'left':
                this.tooltipX = x - tooltipElement.offsetWidth + 'px';
                this.tooltipY = y - tooltipElement.offsetHeight / 2 + 20 + 'px';
                break;
            case 'bottom':
                this.tooltipX = x + offset + 'px';
                this.tooltipY = y + parentSize.height + 5 + 'px';
                break;
            case 'top':
                this.tooltipX = x + offset + 'px';
                this.tooltipY = y - tooltipElement.offsetHeight - 5 + 'px';
                break;
            default:
                this.tooltipX = x + offset + 'px';
                this.tooltipY = y - tooltipElement.offsetHeight - 5 + 'px';
        }

        return {
            left: this.tooltipX,
            top: this.tooltipY
        };
    }
}
