<section class="editor-field-section flex-column">
    <information-field
        *ngIf="showInformations && showInformations.length > 0 && isSelected"
        class="template-fields-info"
        [displaySeconds]="showInformations[0].displayseconds"
        [information]="showInformations[0].infotext | translate"
    ></information-field>
    <div
        class="properties-edit"
        [hidden]="!isSelected || currentTab.id === TemplateEditorTabId.RULE"
        *ngIf="properties && properties.length"
    >
        <div class="editor-field-row {{ property.id }}" *ngFor="let property of properties">
            <text-field
                *ngIf="property.type === SchemaFieldType.TEXT && property.isVisible"
                [label]="property.isLabelHidden ? undefined : (property.label | translate)"
                [postfixLabel]="property.postfixLabel | translate"
                [pattern]="property.pattern | decodeURI"
                [warning]="(property.pattern ? property.patternWarning : null) | translate"
                [enabled]="property.isEnabled"
                [required]="property.required"
                [placeholder]="property.placeholder | translate"
                [(ngModel)]="field[property.id]"
                [name]="property.id"
                [maxlength]="getDynamicProperty(field, property, 'maxLength') | removeDigitGroup"
                [maxAbsolute]="{
                    enabled: this.fieldMeta.fieldType === AutoFieldModelType.Increment,
                    errorTag: 'autoIncTntLimit',
                    max: 2147483647
                }"
                title="{{ property.isEnabled || field.locked ? '' : (property.disabledTitle | translate) }}"
                (change)="handlePropChange(property)"
            >
            </text-field>
            <number-field
                *ngIf="
                    (property.type === SchemaFieldType.NUMBER || property.type === SchemaFieldType.GENERAL) &&
                    property.isVisible
                "
                [name]="property.id"
                [label]="property.isLabelHidden ? undefined : (property.label | translate)"
                [enabled]="property.isEnabled"
                [decimalPlaces]="getDynamicProperty(field, property, 'decimalPlaces')"
                [minValue]="getDynamicProperty(field, property, 'minimum')"
                [maxValue]="getDynamicProperty(field, property, 'maximum')"
                [maxlength]="property.id.toLowerCase() === 'scale' ? 2 : 30"
                [(ngModel)]="field[property.id]"
                [required]="property.required"
                [propRequired]="property.required"
                [postfixLabel]="property.postfixLabel | translate"
                [placeholder]="property.placeholder | translate"
                title="{{ property.isEnabled || field.locked ? '' : (property.disabledTitle | translate) }}"
                (change)="handlePropChange(property)"
            >
            </number-field>
            <date-range-field
                *ngIf="
                    property.type === SchemaFieldType.RANGE &&
                    property.properties[0].type === SchemaFieldType.DATE &&
                    property.isVisible
                "
                [name]="property.id"
                [label]="property.isLabelHidden ? undefined : (property.label | translate)"
                [enabled]="property.isEnabled"
                [(ngModel)]="getRangeAdapter(property).value"
                [minimumFieldName]="property.id + '.' + property.properties[0].id"
                [maximumFieldName]="property.id + '.' + property.properties[1].id"
                [minimumPlaceholder]="property.properties[0].title | translate"
                [maximumPlaceholder]="property.properties[1].title | translate"
                [minimum]="getDynamicProperty(field, property, 'minimum')"
                [maximum]="getDynamicProperty(field, property, 'maximum')"
                (change)="handlePropChange(property)"
            >
            </date-range-field>
            <range-field
                *ngIf="
                    property.type === SchemaFieldType.RANGE &&
                    property.properties[0].type !== SchemaFieldType.DATE &&
                    property.isVisible
                "
                [minimumFieldName]="property.id + '.' + property.properties[0].id"
                [name]="property.id"
                [maximumFieldName]="property.id + '.' + property.properties[1].id"
                [minimumPlaceholder]="property.properties[0].placeholder | translate"
                [maximumPlaceholder]="property.properties[1].placeholder | translate"
                [label]="property.isLabelHidden ? undefined : (property.label | translate)"
                [decimalPlaces]="getDynamicProperty(field, property, 'decimalPlaces')"
                [enabled]="property.isEnabled"
                [(ngModel)]="getRangeAdapter(property).value"
                [minimum]="getDynamicProperty(field, property, 'minimum')"
                [maximum]="getDynamicProperty(field, property, 'maximum')"
                (change)="handlePropChange(property)"
            >
            </range-field>
            <multilist-field
                *ngIf="
                    property.type === SchemaFieldType.RADIO ||
                    (property.type === SchemaFieldType.MULTISELECT && property.isVisible)
                "
                [isEditable]="property.type === SchemaFieldType.RADIO ? property.isEnabled : multiListIsEditable"
                [type]="property.type === SchemaFieldType.RADIO ? SchemaFieldType.RADIO : SchemaFieldType.DROPDOWN"
                [name]="field.uuid + '.' + property.id"
                [(ngModel)]="field[property.id]"
                [label]="property.isLabelHidden ? undefined : (property.label | translate)"
                [items]="property.items"
                title="{{ property.isEnabled || field.locked ? '' : (property.disabledTitle | translate) }}"
                (change)="handlePropChange(property)"
            >
            </multilist-field>
            <single-selection-field
                *ngIf="property.type === SchemaFieldType.BOOLEAN && property.isVisible"
                [name]="field.uuid + '.' + property.id"
                [hintText]="property.hintText | translate"
                [visible]="property.isEnabled"
                [label]="property.isLabelHidden ? undefined : (property.label | translate)"
                [(ngModel)]="field[property.id]"
                (change)="handlePropChange(property)"
            >
            </single-selection-field>
            <date-field
                *ngIf="property.type === SchemaFieldType.DATE && property.isVisible"
                [enabled]="property.isEnabled"
                [(ngModel)]="field[property.id]"
                [name]="property.id"
                [label]="property.isLabelHidden ? undefined : (property.label | translate)"
                [placeholder]="property.title | translate"
                [required]="property.required"
                [minDate]="getDynamicProperty(field, property, 'minimum')"
                [maxDate]="getDynamicProperty(field, property, 'maximum')"
                title="{{ property.isEnabled || field.locked ? '' : (property.disabledTitle | translate) }}"
                (change)="handlePropChange(property)"
            >
            </date-field>
            <!-- ? This inconsistent ngModel binding smells wrong  -->
            <choice-field
                *ngIf="property.type === SchemaFieldType.ARRAY && property.isVisible && field.type === FieldType.Choice"
                [name]="field.uuid + '.' + property.id"
                [showText]="property.properties[0].type === SchemaFieldType.TEXT"
                [(ngModel)]="field.values"
                minimumEntries="1"
                uniqueValuesOnly
                noEmptyChoices
                [enableDefaultProp]="property.isEnabled"
                [locked]="field.locked"
                (change)="handlePropChange(property)"
            ></choice-field>
            <coded-choice-field
                *ngIf="
                    property.type === SchemaFieldType.ARRAY &&
                    property.isVisible &&
                    field.type === FieldType.CodedChoice
                "
                [name]="field.uuid + '.' + property.id"
                [(ngModel)]="field.values"
                minimumEntries="1"
                maxLengthValidator="30"
                uniqueValuesOnly
                noEmptyChoices
                [enableDefaultProp]="property.isEnabled"
                [showDefaultProp]="!field.sensorMappingId"
                [locked]="field.locked || !!field.sensorMappingId"
                (change)="handlePropChange(property)"
            ></coded-choice-field>
        </div>
    </div>
    <text-rule
        *ngIf="rule === FieldType.Text && condition && currentTab.id === TemplateEditorTabId.RULE"
        [condition]="condition"
        [field]="field"
    >
    </text-rule>
    <number-rule
        *ngIf="rule === FieldType.Number && condition && currentTab.id === TemplateEditorTabId.RULE"
        [condition]="condition"
        [field]="field"
    ></number-rule>
    <yes-no-rule
        *ngIf="rule === FieldType.YesNo && condition && currentTab.id === TemplateEditorTabId.RULE"
        [condition]="condition"
        [field]="field"
    ></yes-no-rule>
    <choice-rule
        *ngIf="rule === FieldType.Choice && condition && currentTab.id === TemplateEditorTabId.RULE"
        [condition]="condition"
        [field]="field"
        [template]="template"
        [selectedRule]="selectedRule"
    ></choice-rule>
    <date-rule
        *ngIf="rule === FieldType.Date && condition && currentTab.id === TemplateEditorTabId.RULE"
        [condition]="condition"
        [field]="field"
    >
    </date-rule>
    <default-rule
        *ngIf="rule === 'Default' && condition && currentTab.id === TemplateEditorTabId.RULE"
        [condition]="condition"
        [field]="field"
    ></default-rule>
</section>
