import { Component, Input, OnInit } from '@angular/core';

import { StringUtils } from 'src/app/shared/common/utility/string-utils';
import { ChoiceFeatureField } from '../feature-field';

@Component({
    templateUrl: './choice-field.component.html',
    selector: 'choice-field'
})
export class ChoiceFieldComponent implements OnInit {
    @Input()
    field: ChoiceFeatureField;

    @Input()
    editMode: boolean;

    public _isOptionSelected: { [option: string]: boolean };
    public _isOtherOptionSelected: boolean;
    public _otherOption: string;

    constructor() {}

    ngOnInit(): void {
        this.setFromFieldValue();
    }

    private setFromFieldValue(): void {
        let selected = this.field.value ? this.field.value : [];
        this._isOptionSelected = {};
        this.field.options.forEach(option => {
            this._isOptionSelected[option] = selected.indexOf(option) >= 0;
        });
        let selectedOthers = selected.filter(option => this._isOptionSelected[option] === undefined);
        this._isOtherOptionSelected = selectedOthers.length > 0;
        this._otherOption = selectedOthers.join('|'); // in case of multiple, join to single string
        if (this._otherOption.endsWith('|')) {
            this._otherOption = this._otherOption.substring(0, this._otherOption.length - 1);
        }
    }

    private setToFieldValue(): void {
        let selected = Object.keys(this._isOptionSelected).filter(option => this._isOptionSelected[option]);
        if (this._isOtherOptionSelected) {
            selected = selected.concat(this._otherOption.split('|'));
        }
        this.field.value = selected;
    }

    public isSelected(option: string): boolean {
        return this._isOptionSelected[option];
    }

    public get isOtherOptionSelected(): boolean {
        return this._isOtherOptionSelected;
    }

    public toggleOption(option: string): void {
        this.clearAllOptionsIfAllowMultiSelect();
        this._isOptionSelected[option] = !this._isOptionSelected[option];
        this.setToFieldValue();
    }

    public toggleOtherOptionSelected(): void {
        this.clearAllOptionsIfAllowMultiSelect();
        this._isOtherOptionSelected = !this._isOtherOptionSelected;
        this.setToFieldValue();
    }

    private clearAllOptionsIfAllowMultiSelect(): void {
        if (!this.field.allowMultiSelect) {
            Object.keys(this._isOptionSelected).forEach(option2 => {
                this._isOptionSelected[option2] = false;
            });
            this._isOtherOptionSelected = false;
        }
    }

    public get otherOption(): string {
        return this._isOtherOptionSelected ? this._otherOption : null;
    }

    public set otherOption(option: string) {
        option = StringUtils.cleanupWhitespace(option);
        this._otherOption = option;
        this.setToFieldValue();
    }
}
