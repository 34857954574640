import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from 'src/app/core/routing/route-guards/auth-guard.service';
import { EditLayerComponent } from 'src/app/feature/map-viewer/edit-layer/edit-layer.component';
import { WorkspaceDeleteComponent } from 'src/app/feature/workspace/workspace-delete/workspace-delete.component';
import { WorkspaceDetailsComponent } from 'src/app/feature/workspace/workspace-details/workspace-details.component';
import { WorkspaceErrorComponent } from 'src/app/feature/workspace/workspace-error/workspace-error.component';
import { WorkspacePanelComponent } from 'src/app/feature/workspace/workspace-panel.component';

import { GspImageCropperComponent } from 'src/app/shared/common/components/gsp-image-cropper/gsp-image-cropper.component';
import { ExportDataComponent } from '../export/export.component';
import { ImportFilesMapViewerComponent } from '../import/map-viewer/import-files.component';
import { WorkspaceArchiveComponent } from '../workspace/workspace-archive/workspace-archive.component';
import { WorkspaceCreationComponent } from '../workspace/workspace-creation/workspace-creation.component';
import { WorkspaceDuplicationComponent } from '../workspace/workspace-duplication/workspace-duplication.component';
import { WorkspaceShareComponent } from '../workspace/workspace-share/workspace-share.component';
import { WorkspaceUnarchiveComponent } from '../workspace/workspace-unarchive/workspace-unarchive.component';
import { DiscardDraftChangesComponent } from './discard-draft-changes/discard-draft-changes.component';
import { EditOfflineBasemapComponent } from './edit-offline-basemap/edit-offline-basemap.component';
import { PositioningComponent } from './main-menu/positioning.component';
import { MapLayoutComponent } from './map-layout.component';
import { mapRouteResolver } from './map-route-resolver.service';
import { oldRouteRedirectGuard } from './old-route-redirect.guard';

const routes: Routes = [
    {
        path: 'mapViewer', // /:projectId/:workspaceId',
        resolve: { mapData: mapRouteResolver },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        component: MapLayoutComponent,
        canActivate: [authGuard],
        data: {
            visibility: ['PUBLIC', 'REGISTERED_USERS']
        },
        children: [
            {
                path: 'workspaces',
                outlet: 'mainMenu',
                component: WorkspacePanelComponent,
                data: { animation: 'mainMenu' }
            },
            {
                path: 'positioning',
                outlet: 'mainMenu',
                component: PositioningComponent,
                data: { animation: 'subMainMenu' }
            },
            {
                path: 'import',
                outlet: 'centerDialog',
                component: ImportFilesMapViewerComponent,
                data: { animation: 'centerDialog' }
            },
            {
                path: 'export',
                outlet: 'centerDialog',
                component: ExportDataComponent,
                data: { animation: 'centerDialog' }
            },
            {
                path: 'workspace',
                outlet: 'centerDialog',
                data: { animation: 'centerDialog' },
                children: [
                    {
                        path: 'new',
                        component: WorkspaceCreationComponent
                    },
                    {
                        path: 'new/fromFile',
                        component: WorkspaceCreationComponent
                    },
                    {
                        path: 'details',
                        component: WorkspaceDetailsComponent
                    },
                    {
                        path: 'duplicate',
                        component: WorkspaceDuplicationComponent
                    },
                    {
                        path: 'delete',
                        component: WorkspaceDeleteComponent
                    },
                    {
                        path: 'archive',
                        component: WorkspaceArchiveComponent
                    },
                    {
                        path: 'unarchive',
                        component: WorkspaceUnarchiveComponent
                    },
                    {
                        path: 'share',
                        component: WorkspaceShareComponent
                    },
                    {
                        path: 'license/error',
                        component: WorkspaceErrorComponent
                    },
                    {
                        path: 'imagecropper',
                        component: GspImageCropperComponent
                    }
                ]
            },
            {
                path: 'layer',
                outlet: 'centerDialog',
                data: { animation: 'centerDialog' },
                children: [
                    {
                        path: 'edit',
                        component: EditLayerComponent
                    },
                    {
                        path: 'discardDraftChanges',
                        component: DiscardDraftChangesComponent
                    }
                ]
            },
            {
                path: 'offlinebasemap',
                outlet: 'centerDialog',
                data: { animation: 'centerDialog' },
                children: [
                    {
                        path: 'edit',
                        component: EditOfflineBasemapComponent
                    }
                ]
            },
            // Catch the old url form and redirect to parameter form
            {
                path: ':projectId/:workspaceId',
                children: [],
                canActivate: [oldRouteRedirectGuard]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MapViewerRoutingModule {}
