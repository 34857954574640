import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as _ from 'lodash-es';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';
import { Application } from 'src/app/shared/map-data-services/application';
import { TemplateTypeId } from 'src/app/shared/map-data-services/layer/template-lite';
import { CurrentEditTemplateStream } from 'src/app/shared/template-services/current-edit-template-stream.service';
import { GroupField, LayoutFieldType } from 'src/app/shared/template-services/field';
import { FieldService } from 'src/app/shared/template-services/field/field.service';
import { FieldsStoreService } from 'src/app/shared/template-services/field/fields-store.service';
import { Template } from 'src/app/shared/template-services/template';

import { CurrentTabStreamService, TemplateEditorTabId } from '../tabs-list/current-tab-stream.service';
import { LinkDetails } from '../template-editor-page.component';
import { TemplateRouteData } from '../template-editor-route-resolver.service';
import { TemplateStoreForComponent } from '../template/template-store-for-component.service';
import { GeometryTypes } from 'src/app/shared/common/utility/geometry-utils';

@Component({
    selector: 'template-editor',
    templateUrl: './template-editor.component.html'
})
export class TemplateEditorComponent implements OnInit, OnDestroy {
    private destroyed = new Subject<void>();

    @Input()
    application: Application;

    private _currentProjectDetails: TemplateRouteData;

    @Input()
    public get currentProjectDetails(): TemplateRouteData {
        return this._currentProjectDetails;
    }
    public set currentProjectDetails(v: TemplateRouteData) {
        this._currentProjectDetails = v;
        this.templatesStoreForComponent.setCurrentProjectDetails(v);
    }

    @Input()
    templateId: string;

    @Input()
    linkDetails: LinkDetails[];

    @Input()
    enableButtons: boolean;

    @Output()
    showSuccess = new EventEmitter<void>();

    @Output()
    showError = new EventEmitter<string>();

    @Input()
    templateEditorConfig: { tabs: { fields: { fieldsGroup: any[]; enableFieldConditions: { [key: string]: any } } } };

    @Output()
    afterLoaded = new EventEmitter<void>();

    @Output()
    beforeLoaded = new EventEmitter<void>();

    @Output()
    saveForm = new EventEmitter<any>();

    allDragDropLists: any[] = [];

    currentTab$: BehaviorSubject<any>;

    template: Template;

    showCancelConfirmation = false;

    // exposing enum to template
    public TemplateEditorTabId = TemplateEditorTabId;

    get currentProjectId(): string {
        return this.currentProjectDetails.projectId;
    }

    get currentWorkspaceId(): string {
        return this.currentProjectDetails.workspaceId;
    }

    constructor(
        private templatesStoreForComponent: TemplateStoreForComponent,
        private fieldsStore: FieldsStoreService,
        private fieldService: FieldService,
        private currentTabStream: CurrentTabStreamService,
        private templateStream: CurrentEditTemplateStream,
        private translate: TranslationService
    ) {}

    async ngOnInit(): Promise<void> {
        this.currentTab$ = this.currentTabStream.currentTab$;
        await this.fieldsStore.getFields(this.application.name, this.application.category);
        if (this.templateId === TemplateTypeId.NEW_FORM) {
            this.createNewTemplate();
        } else {
            this.subscribeToTemplateStream();
        }
    }

    private createNewTemplate(): void {
        const defaultColor = '#ff2722';
        this.template = new Template();
        this.template.setName(this.translate.instant('TC.Common.NewTemplate'));
        this.template.setColor(defaultColor);
        this.afterLoaded.emit();
    }

    private subscribeToTemplateStream(): void {
        this.templateStream.pipe(takeUntil(this.destroyed)).subscribe(async template => {
            if (template && (template.id === undefined || template.id === '' || template.id === this.templateId)) {
                await this.loadTemplate(template);
            } else if (!this.template && this.templateId !== TemplateTypeId.DIRECT_TEMPLATE) {
                const templateWithPublicationStatus =
                    await this.templatesStoreForComponent.getTemplateWithPublicationStatus(
                        this.currentProjectId,
                        this.currentWorkspaceId,
                        this.templateId,
                        1
                    );
                this.templatesStoreForComponent.setCurrentEditedTemplate(templateWithPublicationStatus);
            }
        });
    }

    private async loadTemplate(template: Template): Promise<void> {
        const requests = [this.templatesStoreForComponent.getTemplateDeltas(this.currentProjectId, template)];
        if (template.lastPublishedVersion) {
            requests.push(
                this.templatesStoreForComponent.getTemplateDeltas(
                    this.currentProjectId,
                    template,
                    template.lastPublishedVersion
                )
            );
        }
        const deltas = await Promise.all(requests);
        this.template = CloneUtils.cloneDeep(template);
        if (deltas[0]?.archived) {
            this.template.archivedFields = _.concat(this.template.fields, deltas[0].archived);
        }
        template.editableFields = template.lastPublishedVersion ? deltas[1].unPublished : [];
        if (template.editableFields.length) {
            this.template.editableFields = template.editableFields;
        }
        this.template.fields.forEach(field => {
            this.template.setRuleIndicatorForField(field);
            if (field.type === LayoutFieldType.Group) {
                const groupField = field as GroupField;
                if (groupField.fields) {
                    groupField.fields.forEach(subField => {
                        this.template.setRuleIndicatorForField(subField);
                    });
                }
            }
        });
        // Geometry Type can be None if the template is being created from a layer containing null geometry features.
        // Set Geometry Type to Point as the default in this case.
        if (this.template.geometryType === GeometryTypes.NONE) {
            this.template.geometryType = GeometryTypes.POINT;
        }
        this.fieldService.updateAllFieldsStream$.next(null);
        this.afterLoaded.emit();
    }

    saveFormDummy(action: string, template: Template, publishedStatus: boolean, templateBeforeSave: Template): void {
        this.saveForm.emit({
            action,
            template,
            publishedStatus,
            templateBeforeSave
        });
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    onShowCancelConfirmation(): void {
        this.showCancelConfirmation = true;
    }

    onPopupCancel(): void {
        this.showCancelConfirmation = false;
    }

    onPopupConfirm = () => Promise.resolve(this.templatesStoreForComponent.discardChangesStream.next(true));
}
