import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MapContentMonitor } from 'src/app/feature/map-viewer/common/map-content-monitor.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { reloadActivityTabStream } from 'src/app/shared/common/components/gsp-activity-tab/gsp-activity-tab.component';
import { FeaturesStreamsService } from 'src/app/shared/common/current-features/features-streams.service';
import { LayersStore } from 'src/app/shared/common/current-layers/layers-store.service';
import { TasksStore } from 'src/app/shared/common/current-tasks/tasks-store.service';

@Component({
    selector: 'refresh-tools',
    templateUrl: './refresh-tools.component.html'
})
export class RefreshToolsComponent implements OnInit, OnDestroy {
    // expose ButtonType enum to template
    public ButtonType = ButtonType;
    private readonly destroyed = new Subject<void>();
    public needsRefresh = false;

    constructor(
        private mapContentMonitor: MapContentMonitor,
        private tasksStore: TasksStore,
        private featuresStreams: FeaturesStreamsService,
        private layersStore: LayersStore
    ) {}

    ngOnInit(): void {
        this.mapContentMonitor.currentMapContentStream.pipe(takeUntil(this.destroyed)).subscribe(needsRefresh => {
            this.needsRefresh = needsRefresh;
        });

        this.mapContentMonitor.refreshMapContentStream.pipe(takeUntil(this.destroyed)).subscribe(() => {
            this.needsRefresh = true;
            this.refreshMap();
        });
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    public refreshMap(): void {
        // Note: We reload tasks as the tasks contain feature update status and we want that reflected on the map
        this.tasksStore.refreshMapWorkspaceTasks();
        this.featuresStreams.refreshSelectedFeatures();
        this.layersStore.refreshNonSpatialCount();
        if (this.needsRefresh) {
            this.layersStore.refreshMapWorkspaceLayersContent();
        }
        this.mapContentMonitor.mapRefreshed(true);
        reloadActivityTabStream.next(null);
    }
}
