import { Component, ElementRef, HostListener, Input, OnDestroy, Renderer2 } from '@angular/core';
import { Subscription, timer } from 'rxjs';

import { ButtonType } from './buttons/button';

@Component({
    template: `
        <gsp-button
            [type]="ButtonType.Icon"
            (action)="showHelpText($event)"
            iconClass="i16 icon-hint-info"
            id="hint-text-btn"
        >
        </gsp-button>
    `,
    selector: 'gsp-help-text'
})
export class GspHelpTextComponent implements OnDestroy {
    @Input()
    text: string;

    @Input()
    showText = false;

    public ButtonType = ButtonType;
    private loading: Subscription;
    private unload: Subscription;
    private tooltipNode: HTMLElement;
    private tooltipY: string;
    private tooltipX: string;

    @HostListener('mouseleave')
    onmouseleave(): void {
        this.hideTooltip();
    }

    constructor(private element: ElementRef, private render: Renderer2) {}

    ngOnDestroy(): void {
        this.hideTooltip();
    }
    public showHelpText(event?: MouseEvent): void {
        if (event) {
            event.stopPropagation();
        }
        this.showText = true;
        this.showTooltip();
    }
    public createTooltip(tooltipText: string): any {
        let tooltip = this.render.createElement('div');
        tooltip.innerHTML = tooltipText;
        this.render.addClass(tooltip, 'tooltip-content');
        return tooltip;
    }

    public showTooltip(): void {
        this.tooltipNode = this.createTooltip(this.text);
        this.render.appendChild(document.body, this.tooltipNode);

        // set browser default title to empty string
        this.render.setProperty(this.element.nativeElement, 'title', '');

        this.loading = timer(100).subscribe(() => {
            this.render.addClass(this.tooltipNode, 'show');
            let position = this.getPosition(this.element.nativeElement, this.tooltipNode);
            this.render.addClass(this.tooltipNode, 'top');
            this.render.setStyle(this.tooltipNode, 'left', position.left);
            this.render.setStyle(this.tooltipNode, 'top', position.top);
        });

        // remove tooltip after 5 seconds - mostly to get rid of any left hanging around if
        // mouseleave event missed

        let tooltipRef = this.tooltipNode;
        this.unload = timer(5000).subscribe(() => {
            if (tooltipRef) {
                this.render.removeChild(document.body, tooltipRef);
            }
        });
    }

    public hideTooltip(): void {
        this.showText = false;
        if (this.loading) {
            this.loading.unsubscribe();
        }

        if (this.unload) {
            this.unload.unsubscribe();
        }

        if (this.tooltipNode && this.tooltipNode.classList && this.tooltipNode.classList.remove) {
            this.render.removeClass(this.tooltipNode, 'show');
        }
        if (this.tooltipNode && this.tooltipNode.remove) {
            this.tooltipNode.remove();
        }
    }

    public getPosition(parentElement: HTMLElement, tooltipElement: HTMLElement): { left: string; top: string } {
        let parentBounds = parentElement.getBoundingClientRect();

        let x = parentBounds.left;
        let y = parentBounds.top;

        // Get size of browser
        let browserSize = {
            width: window.innerWidth,
            height: window.innerHeight
        };

        // centre the tooltip against parent
        let tooltipCentre = tooltipElement.offsetWidth / 2;
        let containerCentre = parentElement.offsetWidth / 2;
        let offset = containerCentre - tooltipCentre;

        // Prevent tooltip from going out of bounds.
        // Not much we can do about y as tooltip is always shown above button.
        if (x + tooltipElement.offsetWidth >= browserSize.width) {
            x = browserSize.width - tooltipElement.offsetWidth - 5; // far right
        } else if (x + offset <= 0) {
            x = 5; // far left
        } else {
            x = x + offset; // centered on button
        }

        this.tooltipX = x + 'px';
        this.tooltipY = y - tooltipElement.offsetHeight - 5 + 'px';

        return {
            left: this.tooltipX,
            top: this.tooltipY
        };
    }
}
