<gsp-wizard
    [progressionSteps]="steps"
    [currentStep]="currentStep$ | async"
    [size]="ModalSize.Medium"
    [showProgress]="true"
    id="workspace-duplication-wizard"
>
    <gsp-wizard-header
        [heading]="'MapViewer.Workspace.Duplicate.Header'"
        (closed)="exit()"
        transclude-wizard-header
    ></gsp-wizard-header>
    <gsp-wizard-footer
        (goBack)="backward()"
        (goForward)="forward()"
        (cancel)="cancel()"
        transclude-wizard-footer
    ></gsp-wizard-footer>
</gsp-wizard>
