import { Component, Input, OnInit } from '@angular/core';

import { DateFeatureField } from '../feature-field';

@Component({
    templateUrl: './date-field.component.html',
    selector: 'date-field'
})
export class DateFieldComponent implements OnInit {
    @Input()
    field: DateFeatureField;

    @Input()
    editMode: boolean;

    ngOnInit(): void {
        let tmpDate = new Date(this.field.value);
        this.field.value = isNaN(tmpDate.getTime()) ? null : this.field.value;
    }
}
