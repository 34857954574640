<div id="selection-tools">
    <div class="selection-tools">
        <div class="small-radio-group">
            <button
                class="btn btn-icon"
                id="{{ currentMode }}-{{ currentAction }}-select-mode"
                title="{{ currentMode | uppercase }} mode selection"
            >
                <i class="icon-32-dark-selection-{{ currentMode }}-{{ currentAction }}"></i>
            </button>
        </div>
        <div class="map-toolbar-group-icons selection-tools-menu" [ngClass]="{ 'menu-open': open }">
            <div class="large-radio-group" [ngClass]="{ 'show-group': currentAction === SelectionAction.NEW || open }">
                <button
                    id="single-new-select-mode"
                    name="selectMode"
                    title="{{ 'TC.Common.Select' | translate }}"
                    (click)="onSetSelectionTool($event, SelectionModeAction.SINGLE_NEW)"
                    [ngClass]="{
                        active: highlightSelectionTool && currentSelectionToolId === SelectionModeAction.SINGLE_NEW
                    }"
                    class="button-icon"
                >
                    <i class="i32 icon_solid_select_single"></i></button
                ><button
                    class="button-icon"
                    id="rectangle-new-select-mode"
                    name="selectMode"
                    title="{{ 'TCS.Mapviewer.SelectionTool.RectMode' | translate }}"
                    (click)="onSetSelectionTool($event, SelectionModeAction.RECTANGLE_NEW)"
                    [ngClass]="{
                        active: highlightSelectionTool && currentSelectionToolId === SelectionModeAction.RECTANGLE_NEW
                    }"
                >
                    <i class="i32 icon_line_select_rectangle"></i></button
                ><button
                    class="button-icon"
                    id="polygon-new-select-mode"
                    name="selectMode"
                    title="{{ 'TCS.Mapviewer.SelectionTool.PolyMode' | translate }}"
                    (click)="onSetSelectionTool($event, SelectionModeAction.POLYGON_NEW)"
                    [ngClass]="{
                        active: highlightSelectionTool && currentSelectionToolId === SelectionModeAction.POLYGON_NEW
                    }"
                >
                    <i class="i32 icon_line_select_polygon"></i>
                </button>
            </div>
            <div
                class="large-radio-group"
                title="{{ 'TCS.Mapviewer.SelectionTool.AddToSelection' | translate }}"
                [ngClass]="{ 'show-group': currentAction === SelectionAction.APPEND || open }"
            >
                <button
                    class="button-icon"
                    id="single-append-select-mode"
                    name="selectMode"
                    (click)="onSetSelectionTool($event, SelectionModeAction.SINGLE_APPEND)"
                    [ngClass]="{
                        active: highlightSelectionTool && currentSelectionToolId === SelectionModeAction.SINGLE_APPEND
                    }"
                >
                    <i class="icon_solid_select_single_add i32"></i></button
                ><button
                    class="button-icon"
                    id="rectangle-append-select-mode"
                    name="selectMode"
                    (click)="onSetSelectionTool($event, SelectionModeAction.RECTANGLE_APPEND)"
                    [ngClass]="{
                        active:
                            highlightSelectionTool && currentSelectionToolId === SelectionModeAction.RECTANGLE_APPEND
                    }"
                >
                    <i class="icon_line_select_rectangle_add i32"></i></button
                ><button
                    class="button-icon"
                    id="polygon-append-select-mode"
                    name="selectMode"
                    (click)="onSetSelectionTool($event, SelectionModeAction.POLYGON_APPEND)"
                    [ngClass]="{
                        active: highlightSelectionTool && currentSelectionToolId === SelectionModeAction.POLYGON_APPEND
                    }"
                >
                    <i class="icon_line_select_polygon_add i32"></i>
                </button>
            </div>
            <div
                class="large-radio-group"
                title="{{ 'TCS.Mapviewer.SelectionTool.RemoveFromSelection' | translate }}"
                [ngClass]="{
                    'show-group': (highlightSelectionTool && currentAction === SelectionAction.REMOVE) || open
                }"
            >
                <button
                    class="button-icon"
                    id="single-remove-select-mode"
                    name="selectMode"
                    (click)="onSetSelectionTool($event, SelectionModeAction.SINGLE_REMOVE)"
                    [ngClass]="{
                        active: highlightSelectionTool && currentSelectionToolId === SelectionModeAction.SINGLE_REMOVE
                    }"
                >
                    <i class="icon_solid_select_single_subtract i32"></i></button
                ><button
                    class="button-icon"
                    id="rectangle-remove-select-mode"
                    name="selectMode"
                    (click)="onSetSelectionTool($event, SelectionModeAction.RECTANGLE_REMOVE)"
                    [ngClass]="{
                        active:
                            highlightSelectionTool && currentSelectionToolId === SelectionModeAction.RECTANGLE_REMOVE
                    }"
                >
                    <i class="i32 icon_line_select_rectangle_subtract"></i></button
                ><button
                    class="button-icon"
                    id="polygon-remove-select-mode"
                    name="selectMode"
                    (click)="onSetSelectionTool($event, SelectionModeAction.POLYGON_REMOVE)"
                    [ngClass]="{
                        active: highlightSelectionTool && currentSelectionToolId === SelectionModeAction.POLYGON_REMOVE
                    }"
                >
                    <i class="i32 icon_line_select_polygon_subtract"></i>
                </button>
            </div>
        </div>
        <div class="show-icons-arrow">
            <button class="btn-icon" (click)="openMenu()">
                <i class="i32" [ngClass]="menuIconClass"></i>
            </button>
        </div>
    </div>
</div>
