<div class="workspace-template-options">
    <h4 class="section-heading font-title">
        {{ 'TC.Common.Template.Plural' | translate }}
    </h4>
    <div class="section-description">
        {{ 'MapViewer.Workspace.Duplicate.TemplateOption.Description' | translate }}
    </div>
    <div class="template-option">
        <div class="option-description">
            <div class="option-control">
                <gsp-radio
                    id="shouldDuplicateTemplate"
                    name="shouldDuplicateTemplate"
                    [ngModel]="shouldDuplicateTemplate"
                    (click)="setShouldDuplicateTemplate(true)"
                    [radioValue]="true"
                    radioLabelClass="radio-label-wrap"
                    radioInputClass="radio-input"
                    radioButtonClass="dark-radio-button"
                    class="ng-pristine ng-valid"
                    valueText="{{ 'MapViewer.Workspace.Duplicate.TemplateOption.Duplicate.Label' | translate }}"
                >
                </gsp-radio>
            </div>
            <div class="description">
                {{ 'MapViewer.Workspace.Duplicate.TemplateOption.Duplicate.Description' | translate }}
            </div>
        </div>
        <div class="option-icon">
            <i class="icon_duplicate_template i48"></i>
        </div>
    </div>
    <div class="template-option">
        <div class="option-description">
            <div class="option-control">
                <gsp-radio
                    id="shouldLinkTemplate"
                    name="shouldLinkTemplate"
                    [ngModel]="shouldDuplicateTemplate"
                    (click)="setShouldDuplicateTemplate(false)"
                    [radioValue]="false"
                    radioLabelClass="radio-label-wrap"
                    radioInputClass="radio-input"
                    radioButtonClass="dark-radio-button"
                    class="ng-pristine ng-valid"
                    valueText="{{ 'MapViewer.Workspace.Duplicate.TemplateOption.Link.Label' | translate }}"
                >
                </gsp-radio>
            </div>
            <div class="description">
                {{ 'MapViewer.Workspace.Duplicate.TemplateOption.Link.Description' | translate }}
            </div>
        </div>
        <div class="option-icon">
            <i class="icon_file_link i48"></i>
        </div>
    </div>
</div>
