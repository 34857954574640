import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { Rtc, RtcDatum } from 'src/app/feature/rtc/Rtc';
import { DataListTypes, RtcOptions } from 'src/app/feature/rtc/rtc-options';
import { RtcService } from 'src/app/feature/rtc/rtc.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { NotificationType } from 'src/app/shared/common/components/gsp-notification/gsp-notification.component';
import { ModalSize } from 'src/app/shared/common/modal-sizes';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';
import { CoordinateSystemComponent } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';

@Component({
    selector: 'rtc-serial-port',
    templateUrl: './rtc-serial-port.component.html'
})
export class RtcSerialPortComponent implements OnInit {
    @Input() editingRtc: Rtc;
    @Input() originalRtc: Rtc;

    @Output()
    closed = new EventEmitter<{ rtc: Rtc; isValid: boolean }>();

    @ViewChild('rtcEditForm', { static: true })
    public rtcEditForm: NgForm;

    public datums: CoordinateSystemComponent[] = [];
    public datumFilter: string;
    public filteredDatums: CoordinateSystemComponent[];
    public rtcDatum: RtcDatum = null;
    public datumIsLoading = true;
    public datumError = false;
    public showDatums = false;
    public clearSearch = new Subject<void>();

    // expose enums to template
    public ButtonType = ButtonType;
    public DataListTypes = DataListTypes;
    public ModalSize = ModalSize;
    public NotificationType = NotificationType;

    // RTC options
    public sourceTypes = RtcOptions.sourceTypes;
    public baseTypes = RtcOptions.baseTypes;
    public internetServerTypes = RtcOptions.internetServerTypes;
    public secondarySourceTypes = RtcOptions.secondarySourceTypes;
    public comPorts = RtcOptions.comPorts;
    public baudRates = RtcOptions.baudRates;
    public dataBits = RtcOptions.dataBits;
    public stopBits = RtcOptions.stopBits;
    public parities = RtcOptions.parities;

    private originalSelectedRtc: Rtc = null;

    constructor(private rtcService: RtcService) {}

    public ngOnInit(): void {
        const promises: Promise<void>[] = [];
        promises.push(
            this.rtcService.getDatumComponents().then(datums => {
                // filtering to remove dynamic datums and sorting datums alphabetically
                this.datums = _.sortBy(
                    datums.filter(datum => !datum.isDynamic && !this.rtcService.isIncompatible(datum.componentID)),
                    datum => datum.name.toLowerCase()
                );
                this.filteredDatums = _.cloneDeep(this.datums);
            })
        );

        this.originalSelectedRtc = CloneUtils.cloneDeep(this.editingRtc);
        this.editingRtc.toRtcSerialDTO();

        if (this.editingRtc.isLegacyRtc()) {
            promises.push(
                this.rtcService.getDatumBySystemId(this.editingRtc.datumSystemId).then(datum => {
                    this.rtcDatum = datum;
                })
            );
        }

        if (this.editingRtc.isPostCsSupportRtc()) {
            promises.push(
                this.rtcService.getDatumByComponentId(this.editingRtc.datumComponentId).then(datum => {
                    this.editingRtc.correctionDatum = datum;
                })
            );
        }

        Promise.all(promises)
            .then(() => {
                this.datumIsLoading = false;
            })
            .catch(e => {
                this.datumError = true;
            });
    }

    public save(): void {
        this.closed.emit({ rtc: this.editingRtc, isValid: this.canSave() });
    }

    public cancel(): void {
        this.closed.emit({ rtc: this.originalSelectedRtc, isValid: true });
    }

    public indexTrack(index: number, item: any): number {
        return index;
    }

    // TODO: Checking for datumComponentId because gsp-dropdown required check is not working
    public canSave(): boolean {
        if (this.originalRtc.isExistingRtc()) {
            return false;
        }
        return this.rtcEditForm && this.rtcEditForm.valid && this.editingRtc.isPostCsSupportRtc();
    }

    // --------------------- DATUM ---------------------------

    public loadDatums(): void {
        this.clearSearch.next(null);
        this.showDatums = true;
    }

    public selectDatum(datum: CoordinateSystemComponent): void {
        this.editingRtc.datumComponentId = datum.componentID;
        this.showDatums = false;
    }

    public resetDatum(): void {
        this.editingRtc.datumComponentId = null;
    }

    public getDatum(datumComponentId: string): CoordinateSystemComponent {
        return this.datums.find(datum => datum.componentID === datumComponentId);
    }

    public updateFilteredDatums(datums: CoordinateSystemComponent[]): void {
        this.filteredDatums = datums;
    }
}
