<div class="gspUpDown">
    <button
        class="button-icon button-plain btn-gspUp p0"
        [ngClass]="{ disabled: isFirst(item) || disabled }"
        (click)="bringTo(Position.TOP, item)"
        [disabled]="isFirst(item) || disabled"
    >
        <i class="icon_line_move_top i32"></i>
    </button>
    <button
        class="button-icon button-plain btn-gspUp"
        [ngClass]="{ disabled: isFirst(item) || disabled }"
        (click)="bringTo(Position.UP, item)"
        [disabled]="isFirst(item) || disabled"
    >
        <i class="icon_line_chevron_up i24"></i>
    </button>
    <button
        class="button-icon button-plain btn-gspDn"
        [ngClass]="{ disabled: isLast(item) || disabled }"
        (click)="bringTo(Position.DOWN, item)"
        [disabled]="isLast(item) || disabled"
    >
        <i class="icon_line_chevron_down i24"></i>
    </button>
    <button
        class="button-icon button-plain btn-gspDn p0"
        [ngClass]="{ disabled: isLast(item) || disabled }"
        (click)="bringTo(Position.BOTTOM, item)"
        [disabled]="isLast(item) || disabled"
    >
        <i class="icon_line_move_bottom i32"></i>
    </button>
</div>
