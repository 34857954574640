import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { GspTextType } from 'src/app/shared/common/components/gsp-text/gsp-text.component';

@Component({
    selector: 'rtc-search',
    templateUrl: './rtc-search.component.html'
})
export class RtcSearchComponent implements OnInit, OnDestroy {
    @Input()
    items: { [key: string]: string }[] = [];

    @Input()
    clearSearch$: Observable<void>;

    @Input()
    keyToFilterBy: string;

    @Input()
    label: string;

    @Input()
    searchId = '';

    @Output()
    filteredItems = new EventEmitter<{ [key: string]: string }[]>();

    @ViewChild('searchInputRef', { static: true })
    searchInputRef: NgModel;

    searchInput = '';

    // exposing enum to template
    public GspTextType = GspTextType;

    private destroyed = new Subject<void>();

    ngOnInit(): void {
        this.searchInputRef.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.destroyed))
            .subscribe(() => {
                const search = this.searchInput.toLowerCase();
                this.filteredItems.emit(
                    this.items
                        .filter(item => item[this.keyToFilterBy].toLowerCase().includes(search))
                        .sort(a => {
                            if (search) {
                                return a[this.keyToFilterBy].toLowerCase().startsWith(search) ? -1 : 0;
                            }
                        })
                );
            });
        this.clearSearch$.pipe(takeUntil(this.destroyed)).subscribe(() => {
            this.searchInput = '';
            this.filteredItems.emit(this.items);
        });
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }
}
