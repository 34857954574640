import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { GspWizardService, OnForward } from 'src/app/shared/common/components/gsp-wizard/gsp-wizard.service';
import { EnvironmentService } from 'src/app/shared/common/environment/environment.service';
import { Coordinates } from 'src/app/shared/common/location-coordinates';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';
import { GeometryUtils } from 'src/app/shared/common/utility/geometry-utils';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { WorkspaceBounds } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace.types';

import { WorkspaceAreaOfInterest, WorkspaceService } from '../workspace.service';

@Component({
    selector: 'workspace-area',
    templateUrl: './workspace-area.component.html'
})
export class WorkspaceAreaComponent implements OnInit, OnForward {
    // expose ButtonType enum to template
    public ButtonType = ButtonType;

    public map: L.Map = null;
    public uiState = {
        backButton: { text: 'MapViewer.Generic.Previous', enabled: true, visible: true },
        forwardButton: { text: 'TC.Common.Next', enabled: false, visible: true },
        closeButton: { text: '', enabled: true, visible: true },
        cancelButton: { text: 'cancel', enabled: true, visible: true }
    };
    public modifyWorkspace: MapWorkspace;

    constructor(
        private wizardService: GspWizardService,
        private environmentService: EnvironmentService,
        private workspaceCreationService: WorkspaceService
    ) {}

    ngOnInit(): void {
        this.modifyWorkspace = CloneUtils.cloneDeep(this.workspaceCreationService.modifyWorkspace$.getValue());
        this.wizardService.setUI(this.uiState);
    }

    public onForward(): Promise<void> {
        const bounds: L.LatLngBounds = this.map.getBounds();
        const center: L.LatLng = this.map.getCenter();
        const newWorkspaceBounds = GeometryUtils.setWorkspaceBoundsFromMap(bounds);

        if (this.hasWorkspaceBoundsChanged(this.modifyWorkspace, newWorkspaceBounds)) {
            this.modifyWorkspace.bounds = newWorkspaceBounds;
            this.modifyWorkspace.coordinateSystem = null;

            const workspaceAreaOfInterest = new WorkspaceAreaOfInterest();
            workspaceAreaOfInterest.center = center;
            workspaceAreaOfInterest.southWest = bounds.getSouthWest();
            workspaceAreaOfInterest.northEast = bounds.getNorthEast();
            workspaceAreaOfInterest.zoom = this.map.getZoom();

            this.workspaceCreationService.modifyWorkspace$.next(this.modifyWorkspace);
            this.workspaceCreationService.workspaceAreaOfInterest$.next(workspaceAreaOfInterest);
        }

        return Promise.resolve();
    }

    public onMapInitialized(): void {
        const areaOfInterest = this.workspaceCreationService.workspaceAreaOfInterest$.getValue();
        if (areaOfInterest) {
            this.setView(areaOfInterest.center, areaOfInterest.zoom);
        } else {
            const location = new L.LatLng(
                Coordinates[this.environmentService.region].latitude,
                Coordinates[this.environmentService.region].longitude
            );

            this.setView(location, Coordinates[this.environmentService.region].zoom);
        }
    }

    private setView(center: L.LatLng, zoom: number): void {
        this.map.setView(center, zoom);
    }

    private hasWorkspaceBoundsChanged(currentWorkspace: MapWorkspace, newWorkspaceBounds: WorkspaceBounds): boolean {
        if (currentWorkspace.hasBounds()) {
            return (
                currentWorkspace.bounds.latitudeNorth !== newWorkspaceBounds.latitudeNorth ||
                currentWorkspace.bounds.longitudeEast !== newWorkspaceBounds.longitudeEast ||
                currentWorkspace.bounds.latitudeSouth !== newWorkspaceBounds.latitudeSouth ||
                currentWorkspace.bounds.longitudeWest !== newWorkspaceBounds.longitudeWest
            );
        }

        return true;
    }
}
