<map-main-menu class="over-map"></map-main-menu>
<workspace-detail
    class="over-map"
    *checkVisibility="{
        visibleModes: [
            MapWorkspacePermissionType.READ,
            MapWorkspacePermissionType.FULL_ACCESS,
            MapWorkspacePermissionType.FILE_VIEW_MODE
        ],
        toolbarOption: 'hideToolbar',
        toolbarOptionValue: true
    }"
>
</workspace-detail>

<map-toolbar
    *checkVisibility="{
        visibleModes: [
            MapWorkspacePermissionType.READ,
            MapWorkspacePermissionType.FULL_ACCESS,
            MapWorkspacePermissionType.FILE_VIEW_MODE
        ],
        toolbarOption: 'hideToolbar',
        toolbarOptionValue: true
    }"
    class="over-map"
    [linkToMap]="map"
></map-toolbar>
