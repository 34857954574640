import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { TCFile } from 'src/app/shared/common/files/TCFile';
import { TCFileService } from 'src/app/shared/common/files/TCFile.service';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';

@Component({
    selector: 'folder-selection',
    templateUrl: './folder-selection.component.html'
})
export class FolderSelectionComponent implements OnInit {
    loading = true;
    projectId: string;
    workspaceFileId: string;
    workspaceFolderId: string;
    currentFolderDetails: TCFile;
    currentfolderItems: TCFile[] = [];
    selectedFolderDetails: TCFile;
    selectedFolderLabel: string;

    ButtonType = ButtonType;

    @Input()
    selectedWorkspace: MapWorkspace;

    @Input()
    isArchiveWorkspace = false;

    @Input()
    isDuplicateWorkspace = false;

    @Output()
    selectedFolder = new EventEmitter<TCFile>(null);

    constructor(private fileService: TCFileService, private translate: TranslationService) {}

    ngOnInit(): void {
        if (this.selectedWorkspace) {
            this.setSelectedFolderLabel();
            this.fileService.getWorkspaceFile(this.selectedWorkspace.connectFileId).then((file: TCFile) => {
                this.projectId = file.projectId;
                this.workspaceFileId = file.id;
                this.workspaceFolderId = file.parentId;
                this.loadFolderDetails(this.workspaceFolderId);
            });
        }
    }

    loadFolderDetails(folderId: string): void {
        this.loading = true;
        this.getFolderDetails(folderId, this.projectId).then(folderDetails => {
            this.currentFolderDetails = folderDetails;
            this.selectFolder(this.currentFolderDetails);
            this.getFolderItemsList(this.currentFolderDetails.id, this.projectId).then(folderItems => {
                this.currentfolderItems = folderItems;
                this.loading = false;
            });
        });
    }

    getFolderDetails(folderId: string, projectId: string): Promise<TCFile> {
        return this.fileService.getFolderDetail(folderId, projectId);
    }

    getFolderItemsList(folderId: string, projectId: string): Promise<TCFile[]> {
        return this.fileService
            .getFolderItems(folderId, projectId)
            .then(folderItems => folderItems.filter(item => item.type === 'FOLDER'));
    }

    selectFolder(folder: TCFile): void {
        if (this.workspaceFolderId !== folder.id) {
            this.selectedFolderDetails = folder;
        } else {
            const dupWorkspaceFlow = this.isDuplicateWorkspace
                ? (this.selectedFolderDetails = folder)
                : (this.selectedFolderDetails = null);
        }
        this.selectedFolder.emit(this.selectedFolderDetails);
    }

    setSelectedFolderLabel(): string {
        return (this.selectedFolderLabel = this.isArchiveWorkspace
            ? this.translate.instant('MapViewer.MoveWorkspace.MoveToFolder')
            : this.translate.instant('MapViewer.MoveWorkspace.SelectedFolder'));
    }
}
