import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: '[maxLengthValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MaxLengthValidatorDirective, multi: true }]
})
export class MaxLengthValidatorDirective implements Validator, OnChanges {
    @Input('maxLengthValidator') nStr: string;

    private _onValidatorChange = () => {};

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.maxlength) {
            this._onValidatorChange();
        }
    }

    registerOnValidatorChange?(fn: () => void): void {
        this._onValidatorChange = fn;
    }

    validate(control: AbstractControl): ValidationErrors {
        let n = Number(this.nStr);
        if (control?.value) {
            // coded choice field
            if (control.value[0]?.hasOwnProperty('code') && control.value[0]?.hasOwnProperty('description')) {
                const codeExceeded = [this.isExceededMaxLength(control.value, 'code', n)];

                let messageMatrix = { maxlength: {} || false };

                messageMatrix.maxlength = !!codeExceeded[0] ? { requiredLength: n } : false;

                if (codeExceeded.some(prop => prop === true)) {
                    return messageMatrix;
                }

                return null;
            }

            return null;
        }

        return null;
    }

    private isExceededMaxLength(controlVal: any[], property: string, n: number) {
        const valueList = controlVal.map((val: any) => val[`${property}`]);
        const hasExceededMaxLength = valueList.some((value: any) => value.length > n);
        return hasExceededMaxLength;
    }
}
