<div class="feature-field-group">
    <section class="feature-page">
        <div class="field-content" *ngFor="let item of properties | keyvalue">
            <div class="feature-field-container">
                <div class="feature-field">
                    <label class="feature-label"> {{ item.key }}</label>
                    <div *ngIf="!isURLDict[item.key]" class="feature-data">{{ item.value }}</div>
                    <a *ngIf="isURLDict[item.key]" [href]="item.value" target="_blank" class="feature-data link">{{
                        item.value
                    }}</a>
                </div>
            </div>
        </div>
    </section>
</div>
