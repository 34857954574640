import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipListbox } from '@angular/material/chips';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { ButtonType } from '../buttons/button';

@Component({
    selector: 'chip-filter',
    templateUrl: './chip-filter.component.html'
})
export class ChipFilterComponent implements OnDestroy, OnChanges {
    @Input() placeholder: string;
    @Input() selectedChips: string[] = [];
    @Input() allChips: string[] = [];
    @Output() addChipEvent = new EventEmitter<string>();
    @Output() removeChipEvent = new EventEmitter<string>();

    // Optional
    @Input() name: string = 'tags';
    @Input() inputClass: string = 'user-filter-input';
    @Input() selectable = false;
    @Input() editable = true;
    @Input() restrictedChips: string[] = [];
    @Input() addChipText: string = '';
    @Input() multiInputMode: boolean = false;

    @ViewChild('chipInput', { static: false }) chipInput: ElementRef<HTMLInputElement>;
    @ViewChild('chipList') chipList: MatChipListbox;

    public chipCtrl = new FormControl('');
    public filteredChips: string[] = [];
    public newChip: string;
    public chipMatchFound: boolean;
    public chipPartialMatchFound: boolean;
    public chipAlreadyAdded: boolean;
    public addChipMode: boolean = false;
    private searchValue: string;
    private destroyed = new Subject<void>();

    public ButtonType = ButtonType;

    ngOnChanges(): void {
        this.addChipMode = false;
        this.initFilteredChipOptions();
    }

    public addChip(chip: string): void {
        if (!this.allChips.includes(chip)) {
            chip = this.searchValue;
        }

        this.newChip = chip;
        this.saveChip();
        this.resetChipCtrl();
    }

    public enableAddChipMode(): void {
        this.addChipMode = true;
        setTimeout(() => {
            this.chipInput.nativeElement.focus();
        }, 0);
    }

    public enterKeyAction(): void {
        if (
            (!this.chipMatchFound || this.chipPartialMatchFound) &&
            !this.chipAlreadyAdded &&
            !this.isChipRestricted(this.chipCtrl.value) &&
            this.chipCtrl.value.trim().length
        ) {
            this.addChip(this.chipCtrl.value.trim());
        }
    }

    public removeChip(removeChip: string): void {
        const chipsArray = this.addChipMode ? [this.newChip] : this.selectedChips;
        const index = chipsArray.indexOf(removeChip);

        this.removeChipEvent.emit(removeChip);
        chipsArray.splice(index, 1);
    }

    public resetChipCtrl(): void {
        if (this.addChipMode) {
            this.chipInput.nativeElement.value = '';
        }

        this.chipCtrl.patchValue('');
    }

    public isChipRestricted(chip: string): boolean {
        return this.restrictedChips.includes(chip);
    }

    public isChipValid(chip: string): boolean {
        return !this.chipAlreadyAdded && !this.isChipRestricted(chip) && !!chip.trim().length;
    }

    private initFilteredChipOptions(): void {
        this.selectedChips = this.selectedChips
            ?.filter(tag => !this.isChipRestricted(tag))
            .sort((a, b) => a.localeCompare(b));
        this.allChips = this.allChips.filter(tag => !this.isChipRestricted(tag));

        this.chipCtrl.valueChanges
            .pipe(takeUntil(this.destroyed), debounceTime(0), distinctUntilChanged())
            .subscribe(val => {
                this.searchValue = val;
                this.filteredChips = this.filterChips(val);
            });
        this.resetChipCtrl();
    }

    private filterChips(searchVal: string): string[] {
        const searchText = searchVal.trim();

        if (!searchText) {
            return [];
        }

        const filteredChips = this.allChips.filter(chip => {
            const tagChip = chip;
            return tagChip.startsWith(searchText) && !this.selectedChips.includes(tagChip);
        });

        this.chipMatchFound = filteredChips.length !== 0;
        this.chipPartialMatchFound =
            filteredChips.some(chip => chip.startsWith(searchText) && chip !== searchText) &&
            filteredChips.every(chip => chip !== searchText);
        this.chipAlreadyAdded = this.selectedChips.some(chip => chip === searchText);

        return filteredChips;
    }

    private saveChip(): void {
        this.addChipEvent.emit(this.newChip);
        this.addChipMode = false;
    }

    public cancelAddChip(): void {
        this.addChipMode = false;
        this.resetChipCtrl();
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }
}
