import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

export enum TemplateComposerFieldValidity {
    VALID = 'VALID',
    INVALID = 'INVALID'
}

export interface FieldValidity {
    fieldId: string;
    validity: TemplateComposerFieldValidity;
}

@Injectable({
    providedIn: 'root'
})
export class TemplateComposerValidityStreamService {
    private templateComposerValidity = new BehaviorSubject<boolean>(true);
    private templateFieldsValidityObj: { [fieldId: string]: TemplateComposerFieldValidity } = {};
    public templateComposerValidityStream = this.templateComposerValidity.pipe(distinctUntilChanged());

    public removeFieldFromValidityObj(fieldId: string): void {
        delete this.templateFieldsValidityObj[fieldId];
        this.refreshTemplateComposerValidity();
    }

    public setTemplateFieldValidity(fieldValidity: FieldValidity) {
        this.templateFieldsValidityObj[fieldValidity.fieldId] = fieldValidity.validity;
        this.refreshTemplateComposerValidity();
    }

    private refreshTemplateComposerValidity() {
        let validity = true;
        Object.values(this.templateFieldsValidityObj).forEach(fieldValidity => {
            if (fieldValidity === TemplateComposerFieldValidity.INVALID) {
                validity = false;
            }
        });
        this.templateComposerValidity.next(validity);
    }
}
