import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { LoaderStreamService } from 'src/app/shared/common/loader/loader-stream.service';
import { Project } from 'src/app/shared/map-data-services/project/project';

export interface TemplateRouteData {
    workspaceId: string;
    projectId: string;
    templateId: string;
    layerId: string;
    project: Project;
}

export const templateEditorRouteResolver: ResolveFn<TemplateRouteData> = async (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    projectStream: ProjectStreamService = inject(ProjectStreamService),
    mapWorkspacesStore: MapWorkspacesStoreService = inject(MapWorkspacesStoreService),
    messaging: MessagingService = inject(MessagingService),
    loaderStreamService: LoaderStreamService = inject(LoaderStreamService)
) => {
    messaging.clear();
    loaderStreamService.isLoading$.next(true);
    const { projectId, workspaceId, templateId, layerId } = route.queryParams;
    const project = await projectStream.initCurrentProject(projectId, true);
    await mapWorkspacesStore.initCurrentMapWorkspace(project.id, workspaceId, null);
    loaderStreamService.isLoading$.next(false);
    return {
        projectId,
        project,
        workspaceId,
        templateId,
        layerId
    };
};
