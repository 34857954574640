import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export interface AllValidationErrors {
    controlName: string;
    errorName: string;
    errorValue: any;
}

export interface FormGroupControls {
    [key: string]: AbstractControl;
}

export const getFormValidationErrors = (controls: FormGroupControls): AllValidationErrors[] => {
    let errors: AllValidationErrors[] = [];
    Object.keys(controls).forEach(key => {
        const control = controls[key];
        const controlErrors: ValidationErrors = control.errors;
        if (controlErrors !== null) {
            Object.keys(controlErrors).forEach(keyError => {
                errors.push({
                    controlName: key,
                    errorName: keyError,
                    errorValue: controlErrors[keyError]
                });
            });
        }
        if (control instanceof UntypedFormGroup) {
            errors = errors.concat(getFormValidationErrors(control.controls));
        }
    });
    return errors;
};

export const combineFieldValidationErrors = (controls: FormGroupControls): ValidationErrors => {
    let errors: ValidationErrors = {};

    Object.values(controls).forEach(control => {
        if (control instanceof UntypedFormGroup) {
            errors = errors.concat(combineFieldValidationErrors(control.controls));
        }
        const controlErrors: ValidationErrors = control.errors;
        if (controlErrors !== null) {
            Object.assign(errors, controlErrors);
        }
    });
    return errors;
};
