import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { UnitSystem } from 'src/app/shared/common/utility/accuracy-utils';
import { Application } from 'src/app/shared/map-data-services/application';
import { Field } from 'src/app/shared/template-services/field';
import { Template } from 'src/app/shared/template-services/template';
import {
    TemplateComposerValidityStreamService,
} from 'src/app/shared/template-services/template-composer-validity-stream.service';

import { CurrentTabStreamService } from '../tabs-list/current-tab-stream.service';
import { TemplateRouteData } from '../template-editor-route-resolver.service';

@Component({
    selector: 'template-composer',
    templateUrl: './template-composer.component.html'
})
export class TemplateComposerComponent {
    @Input()
    template: Template;

    @Output()
    afterLoaded = new EventEmitter<void>();

    @Output()
    beforeLoaded = new EventEmitter<void>();

    @Input()
    linkDetails: any[];

    @Input()
    templateId: string;

    @Input()
    currentProjectId: string;

    @Output()
    showCancelConfirmation = new EventEmitter<void>();

    @Input()
    currentWorkspaceId: string;

    @Input()
    enableButtons: boolean;

    @Output()
    saveForm = new EventEmitter<any>();

    @Output()
    showSuccess = new EventEmitter<void>();

    @Output()
    showError = new EventEmitter<string>();

    @Input()
    currentProjectDetails: TemplateRouteData;

    @Input()
    templateEditorConfig: { tabs: { fields: { fieldsGroup: any[]; enableFieldConditions: { [key: string]: any } } } };

    @Input()
    application: Application;

    selectedField: Field = null;

    system: UnitSystem;
    minimumAccuracyRequired: number;
    secondaryUniqLinkedInfo: any[];
    layerCount: number;
    workspaceCount: number;
    layerLabel: string;
    mapWorkspaceLabel: string;
    currentTab$: Observable<any>;

    isMasterFormValid = false;

    selectField($event: Field): void {
        this.selectedField = $event;
    }

    constructor(
        currentTabStream: CurrentTabStreamService,
        projectStream: ProjectStreamService,
        private $translate: TranslationService,
        private templateComposerValidity: TemplateComposerValidityStreamService,
        private router: Router
    ) {
        this.secondaryUniqLinkedInfo = [];

        this.currentTab$ = currentTabStream.currentTab$;

        let projUnit =
            projectStream.getCurrentProject() && projectStream.getCurrentProject().settings
                ? projectStream.getCurrentProject().settings.unitSettings.unitsystem
                : UnitSystem.METRIC;

        const isMetricOrg = projUnit === UnitSystem.METRIC;

        if (isMetricOrg) {
            this.system = UnitSystem.METRIC;
            this.minimumAccuracyRequired = 3;
        } else {
            this.system = UnitSystem.IMPERIAL;
            this.minimumAccuracyRequired = 10;
        }

        this.templateComposerValidity.templateComposerValidityStream.subscribe((validity: boolean) => {
            this.isMasterFormValid = validity;
        });
    }
    showLinkedText(tmpLinkedInfo: any[]): boolean {
        if (tmpLinkedInfo && tmpLinkedInfo.length > 0) {
            this.secondaryUniqLinkedInfo = tmpLinkedInfo[0].allLinkedWorkspaces;
        }
        this.layerCount = tmpLinkedInfo.length;
        this.workspaceCount = this.secondaryUniqLinkedInfo.length;

        if (tmpLinkedInfo.length) {
            this.layerLabel = this.$translate.instant(
                tmpLinkedInfo[0].label.length === 1 ? tmpLinkedInfo[0].label[0] : tmpLinkedInfo[0].label[1]
            );
        }
        if (this.secondaryUniqLinkedInfo.length) {
            this.mapWorkspaceLabel = this.$translate.instant(
                this.secondaryUniqLinkedInfo.length === 1
                    ? this.secondaryUniqLinkedInfo[0].label[0]
                    : this.secondaryUniqLinkedInfo[0].label[1]
            );
        }
        return tmpLinkedInfo && (tmpLinkedInfo.length > 1 || this.secondaryUniqLinkedInfo.length > 1);
    }

    saveFormDummy(action: string, template: Template, publishedStatus: boolean, templateBeforeSave: Template): void {
        this.saveForm.emit({
            action: action,
            template: template,
            publishedStatus: publishedStatus,
            templateBeforeSave: templateBeforeSave
        });
    }

    showMoreInfo(): void {
        this.router.navigate(['template/editor/locked'], { queryParamsHandling: 'preserve' });
    }
}
