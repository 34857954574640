<label *ngIf="value">
    <span *ngIf="label" [ngClass]="{ 'disabled-field': !enabled }">{{ label }}</span>
    <number-field
        class="number-range-minimum"
        [name]="minimumFieldName"
        [enabled]="enabled"
        [(ngModel)]="value.lo"
        [placeholder]="minimumPlaceholder"
        [maxValue]="maxForMinField"
        [minValue]="minimum"
        [required]="!!value.hi"
        [decimalPlaces]="decimalPlaces"
    >
    </number-field>
    <number-field
        class="number-range-maximum"
        [name]="maximumFieldName"
        [enabled]="enabled"
        [(ngModel)]="value.hi"
        [placeholder]="maximumPlaceholder"
        [maxValue]="maximum"
        [minValue]="value.lo"
        [decimalPlaces]="decimalPlaces"
        [required]="!!value.lo"
    >
    </number-field>
</label>
