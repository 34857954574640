import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum TemplateEditorTabId {
    DETAIL = 'detail',
    FIELDS = 'fields',
    RULE = 'rule',
    LINK = 'link'
}

export interface TemplateEditorTab {
    id: TemplateEditorTabId;
    name: string;
    iconClass: string;
    properties?: [];
}

@Injectable({
    providedIn: 'root'
})
export class CurrentTabStreamService {
    currentTab$ = new BehaviorSubject<TemplateEditorTab>(null);
}
