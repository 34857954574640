import { Component, ElementRef, forwardRef, HostListener, Input, OnChanges, ViewChild, ViewChildren } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from 'src/app/shared/common/components/value-accessor-base';
import { KeyCodes } from 'src/app/shared/common/key-codes';

/**
 * This directive is used to draw the color picker in the toggle dropdown mode.
 *
 * Attribute and their definition
 *
 * @color-hex-codes - list of the html color hex codes, it should in the format [{color:"#xxx"}, {color:"#yyy"}]
 *
 * @selected-color - it is the selected color hex code. that will be highlighted in the color picker.
 *
 * @icon-size - it is the size of the color icon in the picker. Default value is 15 px.
 *
 * @color-picker-type - The color picker can be either a dropdown or a direct colorpicker,
 * by default it will be dropdown, unless the value "default" is specified
 *
 * @max-rows - We can specify the maximum of rows should be display, if we align the color icons in vertically( up to down)
 *           - If we have ten color, first five colors will be place in first column and second five colors will be place in second column
 *           - Default value is 5 rows.
 *
 * @max-columns - we can specify the maximum of columns should be display, if we align the color icons in horizontally( left to right)
 *           - If we have ten color, first five colors will be place in first row and second five colors will be place in second row
 *           - Default value is 5 columns.
 *
 * @align-by - we can align the color from left to right( horizontally) or up to down (vertically).
 *           - it take two values namely 'vertical' or 'horizontal'
 *           - Default value is horizontal
 *
 * @sort-by - we provide the feature of sorting the given colors by their attributes.
 *         - It may take 'saturation', 'hue', 'lightness', 'darkness' , 'hueAndLightness' or 'none'
 *
 * @group-by - we can do the secondary sorting after group by the color with their limit
 *         - For example sort-by = hue and group-by = lightness with group-limit as 3
 *         - Now first it will sort all the color by hue and then for every 3 colors, it will sort by lightness
 *         - If none is given, it will do only first-order of sorting
 *         - Default value is none
 * @group-limit - we can specify the limit for the group of color.
 *
 * type - it may take 'static' or 'responsive'. If it is static, we can defined the icon-size,
 * align-by, max-rows or max-columns in the attribute,
 *          else if it is reponsive, it will take the align-by from flex-direction
 * css, it will calculate max-rows & max-columns from height and width of
 *          color picker.
 *
 * For example, we have ten colors in order of [L1, D1, D2, L2, L3, D3, D5, L4, L5]
 * L indicate the lightness of the color and D incicate the darkness of the color.
 * L1 will be lighter than L2, so on, L5 will be little brighter than other L series
 * D5 will be more darkness than other D series. D1 will be little dim than other D series.
 *
 * 1. Sort by lightness, align by vertical with 5 per column
 *  <gsp-color-picker color-hex-codes="listOfColors" selected-color="selectedColor"
 *                    icon-size="25px" align-by= "vertical" sort-by="lightness"
 *                    max-rows="5"/>
 *
 * Above usage of directive will print as below :
 *  L1 D1
 *  L2 D2
 *  L3 D3
 *  L4 D4
 *  L5 D5
 *
 * 2. Sort by lightness, align by horizontal with 5 per row
 *  <gsp-color-picker color-hex-codes="listOfColors" selected-color="selectedColor"
 *                    icon-size="25px" align-by= "horizontal" sort-by="lightness"
 *                    max-columns="5"/>
 *
 * Above usage of directive will print as below :
 *  L1 L2 L3 L4 L5
 *  D1 D2 D3 D4 D5
 *
 * 2. Sort by lightness, align by horizontal with 3 per row
 *  <gsp-color-picker color-hex-codes="listOfColors" selected-color="selectedColor"
 *                    icon-size="25px" align-by= "horizontal" sort-by="lightness"
 *                    max-columns="3"/>
 *
 * Above usage of directive will print as below :
 *  L1 L2 L3
 *  L4 L5 D1
 *  D2 D3 D4
 *  D5
 *
 */

export type colorAttribute = 'hue' | 'saturation' | 'lightness' | 'brightness';
export interface Color {
    hexCode: any;
    hue: number;
    sat: number;
    brightness: number;
    red: number;
    green: number;
    blue: number;
    hexDecimalValues: number;
}

export enum ColorPickerType {
    DEFAULT = 'default',
    DROPDOWN = 'dropdown'
}

export enum ColorPickerSizeType {
    STATIC = 'static',
    RESPONSIVE = 'responsive'
}

export enum AlignBy {
    VERTICAL = 'vertical',
    HORIZONTAL = 'horizontal'
}

export enum SortOrder {
    ASC = 'asc',
    DESC = 'desc'
}

@Component({
    selector: 'gsp-color-picker',
    templateUrl: './color-picker.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ColorPickerComponent)
        }
    ]
})
export class ColorPickerComponent extends ValueAccessorBase<string> implements OnChanges {
    colors: Color[];
    isOpen = false;
    colorPickerWidth: string;
    colorPickerHeight: string;

    tempSelectedColor: string = null;

    // expose enums to template
    public ColorPickerType = ColorPickerType;

    @Input()
    colorHexCodes: { id: number; color: string }[];

    @Input()
    colorPickerType: ColorPickerType = ColorPickerType.DROPDOWN;

    private _iconSize = 15;

    @Input()
    public get iconSize(): number {
        if (this.type === ColorPickerSizeType.RESPONSIVE && this.listItemElements) {
            return parseInt(this.listItemElements[0].css('width'), 10);
        } else {
            return this._iconSize;
        }
    }
    public set iconSize(v: number) {
        this._iconSize = v;
    }

    private _maxColumns = 5;

    @Input()
    public get maxColumns(): number {
        if (this.type === ColorPickerSizeType.RESPONSIVE && this.listElement) {
            return parseInt(this.listElement.nativeElement.css('width'), 10) / this.iconSize;
        } else {
            return this._maxColumns;
        }
    }
    public set maxColumns(v: number) {
        this._maxColumns = v;
    }

    private _maxRows = 5;

    @Input()
    public get maxRows(): number {
        if (this.type === ColorPickerSizeType.RESPONSIVE && this.listElement) {
            return parseInt(this.listElement.nativeElement.css('height'), 10) / this.iconSize;
        } else {
            return this._maxRows;
        }
    }
    public set maxRows(v: number) {
        this._maxRows = v;
    }

    @Input()
    alignBy: AlignBy;

    @Input()
    uniqueClass = 'simple';

    @Input()
    sortBy: 'none' | colorAttribute = 'lightness';

    @Input()
    sortOrder: SortOrder = SortOrder.ASC;

    @Input()
    groupBy: 'none' | colorAttribute = 'none';

    @Input()
    groupLimit = 0;

    @Input()
    type: ColorPickerSizeType = ColorPickerSizeType.STATIC;

    @ViewChild('list')
    listElement: ElementRef;

    @ViewChildren('listItems')
    listItemElements: any;

    @HostListener('body:click', ['$event'])
    bodyclick(): void {
        if (this.isOpen) {
            this.isOpen = false;
        }
    }

    @HostListener('body:keydown', ['$event'])
    bodykey(event: KeyboardEvent): void {
        if (this.isOpen) {
            this.keyboardListener(event);
        }
    }

    getDropdownIconClass(color: Color): string {
        return this.tempSelectedColor === color.hexCode ? `icon-${this.iconSize}-light-tick` : '';
    }

    get isStatic(): boolean {
        return this.type === ColorPickerSizeType.STATIC;
    }

    get isVertical(): boolean {
        if (this.type === ColorPickerSizeType.RESPONSIVE && this.listElement) {
            return this.listElement.nativeElement.css('flex-direction') !== 'row';
        } else {
            return this.alignBy === AlignBy.VERTICAL;
        }
    }

    get isHorizontal(): boolean {
        if (this.type === ColorPickerSizeType.RESPONSIVE && this.listElement) {
            return this.listElement.nativeElement.css('flex-direction') === 'row';
        } else {
            return this.alignBy === AlignBy.HORIZONTAL;
        }
    }

    get isDropdown(): boolean {
        return this.colorPickerType === ColorPickerType.DROPDOWN;
    }

    constructor() {
        super();
        this.registerOnChange(value => {
            if (value) {
                this.tempSelectedColor = value.toUpperCase();
            }
        });
    }

    ngOnChanges(): void {
        this.tempSelectedColor = this.value;
        this.constructAndSortColors();
        this.setWidthAndHeight();
    }

    /**
     * Function will call when we select the color from color picker through mouse or enter key event.
     */
    selectColor(e: MouseEvent, hexCode: string): void {
        e.stopPropagation();
        this.value = hexCode;
        this.isOpen = false;
    }

    /**
     * This will handle the keyboard listener, when we used up, down, left and right key
     * over the color picker. It should perform the cycles movement around the color picker
     *
     */
    keyboardListener(e: KeyboardEvent): void {
        const key = e.key;

        e.preventDefault();
        e.stopPropagation();

        let noOfRows = Math.ceil(this.colors.length / this.maxColumns);
        let noOfColumns = Math.ceil(this.colors.length / this.maxRows);

        let tabIndex = this.colors.findIndex(color => color.hexCode === this.value);
        tabIndex = tabIndex !== -1 ? tabIndex : 0;

        switch (key) {
            case KeyCodes.ESCAPE:
                // On esc key, close the color picker
                this.isOpen = false;
                break;
            case KeyCodes.ENTER:
                // On enter key, make the temp select color as selected color and close the color picker
                this.value = this.tempSelectedColor;
                this.isOpen = false;
                break;
            case KeyCodes.UP_ARROW:
                // Up arrow key
                if (this.isHorizontal) {
                    tabIndex = this.getUpTabIndex(
                        tabIndex,
                        noOfRows * this.maxColumns,
                        this.maxColumns,
                        this.colors.length
                    );
                    this.tempSelectedColor = this.colors[tabIndex].hexCode;
                } else {
                    tabIndex = this.getLeftTabIndex(tabIndex, this.colors.length);
                    this.tempSelectedColor = this.colors[tabIndex].hexCode;
                }
                break;
            case KeyCodes.DOWN_ARROW:
                // Down arrow key
                if (this.isHorizontal) {
                    tabIndex = this.getDownTabIndex(
                        tabIndex,
                        noOfRows * this.maxColumns,
                        this.maxColumns,
                        this.colors.length
                    );
                    this.tempSelectedColor = this.colors[tabIndex].hexCode;
                } else {
                    tabIndex = this.getRightTabIndex(tabIndex, this.colors.length);
                    this.tempSelectedColor = this.colors[tabIndex].hexCode;
                }
                break;
            case KeyCodes.LEFT_ARROW:
                // left arrow key
                if (this.isHorizontal) {
                    tabIndex = this.getLeftTabIndex(tabIndex, this.colors.length);
                    this.tempSelectedColor = this.colors[tabIndex].hexCode;
                } else {
                    tabIndex = this.getUpTabIndex(
                        tabIndex,
                        noOfColumns * this.maxRows,
                        this.maxRows,
                        this.colors.length
                    );
                    this.tempSelectedColor = this.colors[tabIndex].hexCode;
                }
                break;
            case KeyCodes.RIGHT_ARROW:
                // Right arrow key
                if (this.isHorizontal) {
                    tabIndex = this.getRightTabIndex(tabIndex, this.colors.length);
                    this.tempSelectedColor = this.colors[tabIndex].hexCode;
                } else {
                    tabIndex = this.getDownTabIndex(
                        tabIndex,
                        noOfColumns * this.maxRows,
                        this.maxRows,
                        this.colors.length
                    );
                    this.tempSelectedColor = this.colors[tabIndex].hexCode;
                }
                break;
        }

        this.value = this.tempSelectedColor;
    }

    setWidthAndHeight(): void {
        // Set the max height and width of the color picker from align-by, max-columns and max-rows

        if (this.type !== ColorPickerSizeType.STATIC || !this.colorHexCodes) {
            return;
        }

        if (this.isHorizontal) {
            let noOfRows = Math.ceil(this.colorHexCodes.length / this.maxColumns);
            this.colorPickerWidth = this.maxColumns * this.iconSize + 'px';
            this.colorPickerHeight = noOfRows * this.iconSize + 'px';
        } else {
            let noOfColumns = Math.ceil(this.colorHexCodes.length / this.maxRows);
            this.colorPickerHeight = this.maxRows * this.iconSize + 'px';
            this.colorPickerWidth = noOfColumns * this.iconSize + 'px';
        }
    }

    /**
     * Construct the color object with color attributes.
     */
    constructAndSortColors(): void {
        if (!this.colorHexCodes) {
            this.colors = [];
            return;
        }

        this.colors = this.constructColorsWithAttributes(this.colorHexCodes);
        if (this.sortBy !== 'none') {
            this.colors = this.sortColorsByAttribute(this.colors, this.sortBy, this.sortOrder);
        }
        if (this.groupBy !== 'none') {
            let tempColors: Color[] = [];

            for (let i = 0; i <= this.colors.length; i = i + this.groupLimit) {
                let tempSlice = this.colors.slice(i, i + this.groupLimit);
                tempSlice = this.sortColorsByAttribute(tempSlice, this.groupBy);
                tempColors = tempColors.concat(tempSlice);
            }

            this.colors = tempColors;
        }
    }

    /**
     * Sort the color object with given color attributes.
     * It may be saturation, hue, lightness and darkness
     */
    sortColorsByAttribute(
        colors: Color[],
        attribute: colorAttribute | string,
        order: SortOrder = SortOrder.ASC
    ): Color[] {
        switch (attribute) {
            case 'hue':
                /* Sort by Hue. */
                return colors.sort((a, b) => (SortOrder.ASC ? a.hue - b.hue : b.hue - a.hue));
            case 'saturation':
                /* Sort by Saturation. */
                return colors.sort((a, b) => (SortOrder.ASC ? a.sat - b.sat : b.sat - a.sat));
            case 'lightness':
                /* Sort by lightness(from light to dark color). */
                return colors.sort((a, b) =>
                    SortOrder.ASC ? b.hexDecimalValues - a.hexDecimalValues : a.hexDecimalValues - b.hexDecimalValues
                );
            case 'brightness':
                /* Sort by brightness of the color */
                return colors.sort((a, b) =>
                    order === SortOrder.ASC ? a.brightness - b.brightness : b.brightness - a.brightness
                );
        }

        // return without sorting
        return colors;
    }

    /**
     * From the given hex code, we need to calculate the color attributes like
     *  saturation, hue, red value, green values etc
     */
    constructColorsWithAttributes(colorHexCodes: { id: number; color: string }[]): Color[] {
        return colorHexCodes.map(colorCode => this.constructColor(colorCode.color));
    }

    constructColor(hexCode: string): Color {
        let hex = hexCode.substring(1);

        /* Get the RGB values to calculate the Hue. */
        let r = parseInt(hex.substring(0, 2), 16) / 255;
        let g = parseInt(hex.substring(2, 4), 16) / 255;
        let b = parseInt(hex.substring(4, 6), 16) / 255;
        let hexDecimalValues = parseInt(hex, 16);

        /* Getting the Max and Min values for Chroma. */
        let max = Math.max.apply(Math, [r, g, b]);
        let min = Math.min.apply(Math, [r, g, b]);

        /* Variables for HSV value of hex color. */
        let chr = max - min;
        let hue = 0;
        let val = max;
        let sat = 0;
        // var l = (max + min) / 2;

        if (val > 0) {
            /* Calculate Saturation only if Value isn't 0. */
            sat = chr / val;
            if (sat > 0) {
                if (r === max) {
                    hue = 60 * ((g - min - (b - min)) / chr);
                    if (hue < 0) {
                        hue += 360;
                    }
                } else if (g === max) {
                    hue = 120 + 60 * ((b - min - (r - min)) / chr);
                } else if (b === max) {
                    hue = 240 + 60 * ((r - min - (g - min)) / chr);
                }
            }
        }

        let brightness = Math.sqrt(r * r * 0.241 + g * g * 0.691 + b * g * 0.068);

        let colorObj = {
            hexCode: hexCode.toUpperCase(),
            hue: hue,
            sat: sat,
            brightness: brightness,
            red: r,
            green: g,
            blue: b,
            hexDecimalValues: hexDecimalValues
        };

        return colorObj;
    }

    getRightTabIndex(currentTabIndex: number, actualCells: number): number {
        let nextTabIndex: number;
        if (currentTabIndex === actualCells - 1) {
            nextTabIndex = 0;
        } else {
            nextTabIndex = currentTabIndex + 1;
        }
        return nextTabIndex;
    }

    getLeftTabIndex(currentTabIndex: number, actualCells: number): number {
        let previousTabIndex: number;
        if (currentTabIndex === 0) {
            previousTabIndex = actualCells - 1;
        } else {
            previousTabIndex = currentTabIndex - 1;
        }
        return previousTabIndex;
    }

    getUpTabIndex(currentTabIndex: number, totalCells: number, cellPerRow: number, actualCells: number): number {
        let upTabIndex: number;
        upTabIndex = currentTabIndex - parseInt(String(cellPerRow), 10);
        if (upTabIndex < 0) {
            upTabIndex = totalCells + upTabIndex - 1;
            if (upTabIndex > actualCells - 1) {
                upTabIndex = upTabIndex - cellPerRow;
            }
        }
        return upTabIndex;
    }

    getDownTabIndex(currentTabIndex: number, totalCells: number, cellPerRow: number, actualCells: number): number {
        let downTabIndex: number;
        downTabIndex = currentTabIndex + parseInt(String(cellPerRow), 10);
        if (downTabIndex > actualCells - 1) {
            downTabIndex = downTabIndex - totalCells + 1;
            if (downTabIndex < 0) {
                downTabIndex = downTabIndex + cellPerRow;
            }
        }
        return downTabIndex;
    }
}
