import { Component, forwardRef, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgControl, NgModel } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BooleanConverter, InputConverter } from 'src/app/shared/common/components/input-converter.decorator';
import { ValueAccessorBase } from 'src/app/shared/common/components/value-accessor-base';

@Component({
    selector: 'date-field',
    templateUrl: './date-field.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateFieldComponent),
            multi: true
        }
    ]
})
export class DateFieldComponent extends ValueAccessorBase<any> implements OnInit, OnDestroy {
    @Input()
    @InputConverter(BooleanConverter)
    enabled = true;

    @Input()
    minDate: Date;

    @Input()
    maxDate: Date;

    @Input()
    name: string;

    @Input()
    label: string;

    @Input()
    placeholder: string;

    @ViewChild('picker', { static: true })
    picker: NgModel;

    constructor(private injector: Injector) {
        super();
    }

    private destroyed = new Subject<void>();
    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    ngOnInit(): void {
        const model = this.injector.get(NgControl);
        const control = model.control;

        control.statusChanges.pipe(takeUntil(this.destroyed)).subscribe(() => {
            this.picker.control.setErrors(control.valid ? null : { parentError: true });
        });
    }
}
