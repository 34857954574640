<gsp-confirmation-popup
    *ngIf="!loading && isWorkspaceDeletable"
    [heading]="'TCS.Workspace.RemoveMap'"
    (cancel)="close()"
    [confirmationButtonText]="'TC.Common.Remove'"
    [confirmAction]="deleteWorkspaceAction"
    [message]="'MapViewer_Workspace_RemoveMessage'"
    id="workspace-delete"
>
</gsp-confirmation-popup>
<gsp-popup
    *ngIf="!loading && !isWorkspaceDeletable"
    class="workspace-delete-info"
    [heading]="'TCS.Workspace.RemoveMap' | translate"
    [size]="ModalSize.ExtraSmall"
    [showClose]="true"
    (closed)="close()"
    [showDone]="true"
    [doneBtnTitle]="'TC.Common.OK' | translate"
    (done)="close()"
    id="workspace-delete-info"
>
    <ng-container transclude-popup-content>
        <div class="workspace-delete-info-message">
            <p translate [translateParams]="{ workspaceName: deletingMapWorkspace.name }">
                MapViewer.Workspace.Delete.Info.Message
            </p>
        </div>
    </ng-container>
</gsp-popup>
