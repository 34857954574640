import { Subscription } from 'rxjs';
import { TranslationService } from 'src/app/core/translation/translation.service';

export class ImportFileMetadata {
    public workspaceId: string;

    constructor() {
        this.workspaceId = null;
    }
}

export interface ImportStatusDict {
    [importId: string]: {
        status: ImportApiStatus;
        fileName: string;
        importedLayerIds: string[];
    };
}

export enum ImportStatus {
    UPLOADING = 0,
    PROCESSING,
    SUCCESS,
    ERROR,
    WARNING,
    DONE,
    INCOMPLETE
}

export enum ImportApiStatus {
    FAILED = 'Failed',
    COMPLETED = 'Completed',
    SUBMITTED = 'Submitted',
    INPROGRESS = 'InProgress'
}

export interface ImportApiResponse {
    importId: string;
    importFileName: string;
    workspaceId: string;
    status: ImportApiStatus;
    startedUtc: string;
    importedLayerIds: string[];
}

export class ViewFile {
    connectFileId: string;
    status: ImportStatus;
    errorMessages?: string[];
    requestId?: string;
    id?: string;
    fileName?: string;
    processingProgress?: number;
    layerNames?: string[];
}

export class ImportMessage {
    code: ImportErrorCode;
    debugMessageText: string;
    parameters: any;
    severity: string;
    messages?: ImportMessage[];
}

export enum ImportErrorCode {
    // Refer to https://confluence.trimble.tools/display/MS/CodedError+documentation
    GENERIC_ERROR = 'GenericError',
    UNSUPPORTED_FILETYPE = 'UnsupportedFileType',
    IMPORT_SUMMARY = 'ImportSummary',
    NO_GEOMETRIES_IN_FILE = 'NoGeometriesInFile',
    NO_FEATURES_IN_FILE = 'NoFeaturesInFile',
    ERROR_STORINGDATA = 'ErrorStoringData',
    INCOMPATIBLE_DATUMS = 'IncompatibleDatums',

    // Dont use these in the UI
    LAYER_DATUM_COMPONENTID_NOTFOUND = 'LayerDatumComponentIdNotFound',
    LAYER_DATUM_PARSE_FAILURE = 'LayerDatumParseFailure',
    SHAPEFILE_FILE_GEOMETRYNULL = 'ShapefileGeometryNull',
    KEYHOLE_GEOMETRY_NULL = 'KeyholeGeometryNull',
    FILE_GEODATABASE_GEOMETRY_NULL = 'FileGeodatabaseGeometryNull',

    SHAPEFILE_FILEMISSING = 'ShapefileFileMissing',
    SHAPEFILE_FILE_DUPLICATED = 'ShapefileFileDuplicated',
    KEYHOLE_MARKUP_LANGUAGEERROR = 'KeyholeMarkupLanguageError',
    KEYHOLE_GEOMETRY_LINEARRING_INVALID = 'KeyholeGeometryLinearRingInvalid',
    KEYHOLE_COORDINATE_OUTOFRANGE = 'KeyholeCoordinateOutOfRange',
    FILE_GEODATABASE_ATTRIBUTETYPE_NOTSUPPORTED = 'FileGeodatabaseAttributeTypeNotSupported',
    FILE_GEODATABASE_EDITOR_TRACKING_NOTSUPOORTED = 'FileGeodatabaseEditorTrackingNotSupported',
    FILE_GEODATABASE_UNSUPPORTED_GEOMETRYTYPE = 'FileGeodatabaseUnsupportedGeometryType',
    FILE_GEODATABASE_ERROR_CONVERTING_ATTRIBUTEVALUE = 'FileGeodatabaseErrorConvertingAttributeValue',
    FILE_GEODATABASE_LINEARRING_INVALID = 'FileGeodatabaseLinearRingInvalid',
    FILE_GEODATABASE_UNSUPPORTED_VERSION = 'FileGeodatabaseUnsupportedVersion',
    ZIP_DESERIALIZER_SKIPPING_ZIPFILE_WITHINZIPFILE = 'ZipDeserializerSkippingZipFileWithinZipFile',
    ZIPPED_CHILDFILE_MESSAGES = 'ZippedChildFileMessages',
    ZIPPED_FILEIMPORT_SUMMARY = 'ZippedFileImportSummary'
}

export interface ImporFiletLinks {
    rel: string;
    hRef: string;
    action: string;
}

export class ImportFile {
    relativePath: string;
    file: File;
    metadata: ImportFileMetadata;
    id: string;
    versionId: string;
    workspaceId: string;
    fileName: string;
    status: ImportStatus;
    messages: string[] = []; // Can be error or warning
    styles: string[] = [];
    layerNames: string[] = [];
    requestId: string;

    isComplete = false;
    processingProgress = 0;
    uploadProgress = 0;

    // this will be used to monitor the status of the importFile
    subscription?: Subscription;

    startedUTC: Date;
    importedLayerIds: string[] = [];
    links: ImporFiletLinks[] = [];
    eTag: string;

    constructor(relativePath: string, fileName: string, file: File) {
        this.relativePath = relativePath;
        this.file = file;
        this.fileName = fileName;
        this.status = ImportStatus.UPLOADING;
    }

    static getErrorByTranslationResourceId(
        translate: TranslationService,
        code: ImportErrorCode,
        parameters: any,
        debugMessage: string,
        filename: string
    ): string {
        switch (code) {
            case ImportErrorCode.GENERIC_ERROR:
            case ImportErrorCode.ERROR_STORINGDATA:
            case ImportErrorCode.SHAPEFILE_FILE_DUPLICATED:
            case ImportErrorCode.KEYHOLE_COORDINATE_OUTOFRANGE:
            case ImportErrorCode.FILE_GEODATABASE_ERROR_CONVERTING_ATTRIBUTEVALUE:
                return translate.instant('MapViewer.ImportError.FILE_GEODATABASE_ERROR_CONVERTING_ATTRIBUTEVALUE');
            case ImportErrorCode.UNSUPPORTED_FILETYPE:
                return translate.instant('MapViewer.ImportError.UNSUPPORTED_FILETYPE', {
                    fileExtension: parameters.fileExtension
                });

            case ImportErrorCode.NO_FEATURES_IN_FILE:
                return translate.instant('MapViewer.ImportError.NO_FEATURES_IN_FILE');

            case ImportErrorCode.NO_GEOMETRIES_IN_FILE:
                return translate.instant('MapViewer.ImportError.NO_GEOMETRIES_IN_FILE');

            case ImportErrorCode.INCOMPATIBLE_DATUMS:
                return translate.instant('MapViewer.ImportError.INCOMPATIBLE_DATUMS', {
                    fileName: filename,
                    workspaceDatumName: parameters.WorkspaceDatumName
                });

            case ImportErrorCode.SHAPEFILE_FILEMISSING:
                return translate.instant('MapViewer.ImportError.SHAPEFILE_FILEMISSING', {
                    shapefileName: parameters.shapefileName,
                    missingExtensions: parameters.missingExtensions.join(',')
                });

            case ImportErrorCode.KEYHOLE_MARKUP_LANGUAGEERROR:
                return translate.instant('MapViewer.ImportError.KEYHOLE_MARKUP_LANGUAGEERROR');
            case ImportErrorCode.KEYHOLE_GEOMETRY_LINEARRING_INVALID:
            case ImportErrorCode.FILE_GEODATABASE_LINEARRING_INVALID:
                if (parameters.coordinateCount > 1) {
                    return translate.instant('MapViewer.ImportError.FILE_GEODATABASE_LINEARRING_INVALID.Plural', {
                        featureCount: parameters.coordinateCount
                    });
                } else {
                    return translate.instant('MapViewer.ImportError.FILE_GEODATABASE_LINEARRING_INVALID.Singular', {
                        featureCount: parameters.coordinateCount
                    });
                }

            case ImportErrorCode.FILE_GEODATABASE_ATTRIBUTETYPE_NOTSUPPORTED:
                return translate.instant('MapViewer.ImportError.FILE_GEODATABASE_ATTRIBUTETYPE_NOTSUPPORTED');

            case ImportErrorCode.FILE_GEODATABASE_EDITOR_TRACKING_NOTSUPOORTED:
                return translate.instant('MapViewer.ImportError.FILE_GEODATABASE_EDITOR_TRACKING_NOTSUPOORTED', {
                    fileName: parameters.sourceName
                });

            case ImportErrorCode.FILE_GEODATABASE_UNSUPPORTED_GEOMETRYTYPE:
                return translate.instant('MapViewer.ImportError.FILE_GEODATABASE_UNSUPPORTED_GEOMETRYTYPE', {
                    fileName: parameters.sourceName
                });

            case ImportErrorCode.FILE_GEODATABASE_UNSUPPORTED_VERSION:
                return translate.instant('MapViewer.ImportError.FILE_GEODATABASE_UNSUPPORTED_VERSION');

            case ImportErrorCode.ZIP_DESERIALIZER_SKIPPING_ZIPFILE_WITHINZIPFILE:
                return translate.instant('MapViewer.ImportError.ZIP_DESERIALIZER_SKIPPING_ZIPFILE_WITHINZIPFILE');
            case ImportErrorCode.ZIPPED_FILEIMPORT_SUMMARY:
                let str = translate.instant('MapViewer.ImportError.ZIPPED_FILEIMPORT_SUMMARY.prefix');
                return parameters.SuccessfulZippedFilesNames.length
                    ? str +
                          translate.instant('MapViewer.ImportError.ZIPPED_FILEIMPORT_SUMMARY.summary', {
                              fileNames: parameters.SuccessfulZippedFilesNames.join(', ')
                          })
                    : str;
            case ImportErrorCode.ZIPPED_CHILDFILE_MESSAGES:
            default:
                return debugMessage;
        }
    }

    static getErrorLevel(severity: string): number {
        switch (severity) {
            case 'Error':
                return ImportStatus.ERROR;
            case 'Warning':
                return ImportStatus.WARNING;
        }
    }

    // There are some error codes that we do not want to display to the end user
    static showMessage(code: string): boolean {
        switch (code) {
            case ImportErrorCode.LAYER_DATUM_COMPONENTID_NOTFOUND:
            case ImportErrorCode.LAYER_DATUM_PARSE_FAILURE:
            case ImportErrorCode.SHAPEFILE_FILE_GEOMETRYNULL:
            case ImportErrorCode.KEYHOLE_GEOMETRY_NULL:
            case ImportErrorCode.FILE_GEODATABASE_GEOMETRY_NULL:
            case ImportErrorCode.ZIPPED_CHILDFILE_MESSAGES:
            case ImportErrorCode.IMPORT_SUMMARY:
                return false;
            default:
                return true;
        }
    }
}
