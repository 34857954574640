import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { ModalSize } from 'src/app/shared/common/modal-sizes';
import { Application } from 'src/app/shared/map-data-services/application';
import { Field, LayoutFieldType } from 'src/app/shared/template-services/field';
import { SensorData, SensorMessageDefinition } from 'src/app/shared/template-services/field/fieldDefinition';
import { FieldsStoreService } from 'src/app/shared/template-services/field/fields-store.service';
import { Template } from 'src/app/shared/template-services/template';

import { FieldPanelComponent } from '../field-panel/field-panel.component';

@Component({
    selector: 'sensor-popup',
    templateUrl: './sensor-popup.component.html'
})
export class SensorPopupComponent implements OnInit {
    public ButtonType = ButtonType;
    public ModalSize = ModalSize;
    public sensorsData: SensorData[];

    private selectedSensorData: SensorData;
    private dropEvent: CdkDragDrop<void>;
    public loading = true;

    public sensorOptions: { id: string; displayName: string }[] = [];
    public workflowOptions: { id: string; displayName: string }[] = [];

    public selectedSensor: string;
    public selectedMeasurementWorkflow: string;

    public selectedWorkflow: SensorMessageDefinition;
    public fieldPanelComponent: FieldPanelComponent;

    public template: Template;
    public application: Application;

    constructor(private fieldsStore: FieldsStoreService, private translate: TranslationService) {}

    ngOnInit() {
        this.template = this.fieldsStore.currentTemplate.getValue();
        this.application = this.fieldsStore.currentApplication.getValue();
        this.dropEvent = this.fieldsStore.draggedSensorDataStream.getValue();

        this.mapSensorOptions();
    }

    public closePopup() {
        this.fieldsStore.openPopupTo('template/editor');

        this.fieldsStore.draggedSensorDataStream.next(null);
        this.fieldsStore.currentTemplate.next(null);
        this.fieldsStore.currentApplication.next(null);
    }

    public onDone() {
        this.selectedWorkflow = this.selectedSensorData.messageDefinitions.find(
            messageDef => messageDef.workflowName === this.selectedMeasurementWorkflow
        );

        this.createSensorFieldAndWorkflow().then(() => this.closePopup());
    }

    public triggerOnSensorSelect(selectedSensor: string) {
        this.selectedSensorData = this.sensorsData.find(sensor => sensor.sensorName === selectedSensor);
        this.workflowOptions = this.selectedSensorData.messageDefinitions.map(messageDefinition => ({
            id: messageDefinition.workflowName,
            displayName: messageDefinition.workflowName // translate if needed
        }));
        this.selectedMeasurementWorkflow = this.workflowOptions[0]?.id;
    }

    private async createSensorFieldAndWorkflow(): Promise<void> {
        let parentIndex = this.dropEvent ? this.dropEvent.currentIndex : this.template?.fields.length;
        let childIndex = 0;

        const sensorGroupField = await this.template.insertNewField(
            this.fieldsStore,
            this.translate,
            this.application,
            LayoutFieldType.Group,
            null,
            [parentIndex],
            '',
            null,
            null,
            null,
            this.selectedWorkflow
        );

        // use parentIndex as reference | childIndex starts at 0
        this.createWorkflowFields(sensorGroupField, [parentIndex, childIndex]);
    }

    private async createWorkflowFields(sensorGroupField: Field, position?: number[]): Promise<void> {
        for (const fieldDef of this.selectedWorkflow.fieldDefinitions) {
            const newSensorChildField = await this.template.insertNewField(
                this.fieldsStore,
                this.translate,
                this.application,
                fieldDef.fieldType,
                null,
                position,
                sensorGroupField?.uuid,
                null,
                null,
                null,
                null,
                fieldDef
            );
            position[1]++;
        }
    }

    private mapSensorOptions() {
        this.sensorsData = this.fieldsStore.supportedSensors;
        this.sensorOptions = this.sensorsData
            .map(sensor => ({
                id: sensor.sensorName,
                displayName: sensor.sensorName // translate if needed
            }))
            .sort((a, b) => a.displayName.localeCompare(b.displayName));
        this.selectedSensor = this.sensorOptions[0]?.id;
        this.triggerOnSensorSelect(this.selectedSensor);
        this.loading = false;
    }
}
