import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LoadCreateWorkspaceComponent } from './load-create-workspace.component';
import { LoadFileviewerComponent } from './load-fileviewer.component';
import { LoadMapviewerComponent } from './load-mapviewer.component';

@NgModule({
    declarations: [LoadFileviewerComponent, LoadMapviewerComponent, LoadCreateWorkspaceComponent],
    imports: [RouterModule, CommonModule],
    exports: [LoadFileviewerComponent, LoadMapviewerComponent, LoadCreateWorkspaceComponent]
})
export class InitModule {}
