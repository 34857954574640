import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from 'src/app/core/routing/route-guards/auth-guard.service';

import { TemplateWizardComponent } from '../map-viewer/template-wizard/template-wizard.component';
import { SensorPopupComponent } from './sensor-popup/sensor-popup.component';
import { TemplateSchemaLockedWarningComponent } from './template-composer/template-warnings/template-schema-locked-warning.component';
import { TemplateEditorPageComponent } from './template-editor-page.component';
import { templateEditorRouteResolver } from './template-editor-route-resolver.service';

const routes: Routes = [
    {
        path: '',
        resolve: { currentProjectWorkspace: templateEditorRouteResolver },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        canActivate: [authGuard],
        children: [
            {
                path: 'editor',
                component: TemplateEditorPageComponent,
                children: [
                    {
                        path: 'locked',
                        component: TemplateSchemaLockedWarningComponent
                    },
                    {
                        path: 'sensor-options',
                        component: SensorPopupComponent
                    }
                ]
            },
            {
                path: 'wizard',
                component: TemplateWizardComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class TemplateEditorRoutingModule {}
