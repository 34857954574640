<p class="description mb-2" *ngIf="uploadDescription">
    {{ uploadDescription }}
</p>
<div class="modal-group upload-options" id="upload-file">
    <gsp-file-drop
        #fileDrop
        *ngIf="!createFromFile"
        [maxFileCount]="20"
        [allowMultipleFiles]="true"
        (fileDropEvent)="dropped($event)"
    >
    </gsp-file-drop>
    <gsp-file-drop
        #fileDrop
        *ngIf="createFromFile"
        [width]="'710px'"
        [acceptedFileTypes]="'.wsdef'"
        [allowMultipleFiles]="false"
        (fileDropError)="displayErrorMessage('')"
        (fileDropEvent)="dropped($event)"
    >
    </gsp-file-drop>

    <gsp-notification
        [type]="NotificationType.Critical"
        *ngIf="showFileLimitWarning"
        class="mt-2"
        id="file-limit-warning"
    >
        <div class="text-center">{{ 'MapViewer.ImportStatusDialog.FileLimitWarningMessage' | translate }}</div>
    </gsp-notification>

    <div class="upload-error-wrapper width-full text-left" *ngIf="uploadErrorMessage">
        <div class="file-status pa-0" id="import-files-status">
            <div class="import-file-info">
                <div class="status">
                    <i class="icon icon-24-error"></i>
                    <h5 class="font-bold error-dialog">
                        {{ 'MapViewer.ImportStatusDialog.ErrorScannedFile' | translate }}
                    </h5>
                </div>
            </div>
            <div class="file-scanned-contents">
                <p>{{ uploadErrorMessage | translate }}</p>
            </div>
        </div>
    </div>
</div>
