import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { GspTextType } from 'src/app/shared/common/components/gsp-text/gsp-text.component';
import { GeneralUtils } from 'src/app/shared/common/utility/general-utils';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';

@Component({
    selector: 'workspace-name',
    templateUrl: './workspace-name.component.html'
})
export class WorkspaceNameComponent {
    @Input()
    public modifyWorkspace: MapWorkspace;

    @Input()
    public checkDuplicateOnDirty = true;

    @Output()
    public isNameValid: EventEmitter<boolean> = new EventEmitter(false);

    // expose ButtonType enum to template
    public ButtonType = ButtonType;
    public GspTextType = GspTextType;

    @ViewChild('workspaceNameForm')
    public form: NgForm;

    constructor(private translate: TranslationService) {}

    public getErrorText(errors: object): string {
        if (GeneralUtils.isNullUndefinedOrNaN(errors)) {
            this.isNameValid.emit(true);
            return null;
        } else {
            const errorMessage = MapWorkspace.getWorkspaceNameValidationError(errors);
            this.isNameValid.emit(errorMessage ? false : true);

            return errorMessage ? this.translate.instant(errorMessage) : null;
        }
    }
}
