export enum ModalSize {
    ExtraSmall = 0,
    Small,
    Medium,
    Large,
    Xlarge,
    XXlarge,
    Wide,
    Custom,
    Fluid,
    FluidLarge
}
