import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { lastValueFrom } from 'rxjs';

import { EnvironmentService } from '../../common/environment/environment.service';
import { ForbiddenFormsDTO } from '../../types/forbidden-forms';

@Injectable({ providedIn: 'root' })
export class ForbiddenFormsService {
    constructor(private http: HttpClient, private env: EnvironmentService) {}

    public async getForbiddenForms(region: string, projectId: string): Promise<ForbiddenFormsDTO> {
        const compositeProjectId = this.getCompositeProjectId(region, projectId);
        const url = `${this.env.processingOrchestratorUrl}/po/forbiddenforms?compositeProjectId=${compositeProjectId}`;

        return lastValueFrom(this.http.get<ForbiddenFormsDTO>(url));
    }

    public acceptForbiddenForms(region: string, projectId: string, userId: string): Promise<ForbiddenFormsDTO> {
        const compositeProjectId = this.getCompositeProjectId(region, projectId);
        const url = `${this.env.processingOrchestratorUrl}/po/forbiddenforms?compositeProjectId=${compositeProjectId}&userId=${userId}`;

        return lastValueFrom(this.http.put<ForbiddenFormsDTO>(url, null));
    }

    public rejectForbiddenForms(region: string, projectId: string, userId: string): Promise<ForbiddenFormsDTO> {
        const compositeProjectId = this.getCompositeProjectId(region, projectId);
        const url = `${this.env.processingOrchestratorUrl}/po/forbiddenforms?compositeProjectId=${compositeProjectId}&userId=${userId}`;

        return lastValueFrom(this.http.delete<ForbiddenFormsDTO>(url));
    }

    public async exportForbiddenForms(region: string, projectId: string, userId: string): Promise<void> {
        const compositeProjectId = this.getCompositeProjectId(region, projectId);
        const url =
            `${this.env.processingOrchestratorUrl}/po/forbiddenforms/exportcsv?compositeProjectId=${compositeProjectId}&userId=${userId}`;

        const data = await lastValueFrom(this.http.get(url, { responseType: 'arraybuffer' }));
        const blob = new Blob([data], { type: 'application/octet-stream' });
        saveAs(blob, `${userId}.zip`);
    }

    private getCompositeProjectId(region: string, projectId: string): string {
        return region + encodeURIComponent('|') + projectId;
    }
}
