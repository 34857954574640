import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { GspLoggerService } from 'src/app/log-handler.service';

import { License, LicenseDTO } from '../../user/license';
import { User } from '../../user/user';
import { UserService } from '../../user/user.service';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({
    providedIn: 'root'
})
export class CurrentUserService {
    constructor(
        private http: HttpClient,
        private env: EnvironmentService,
        private logger: GspLoggerService,
        private userService: UserService
    ) {}

    async getCurrentUser(): Promise<User> {
        try {
            const userDto = await this.userService.getConnectUserByMe();
            return User.fromDTO(userDto);
        } catch (error) {
            this.logger.error(error);
            return null;
        }
    }

    async getLicenseDetail(): Promise<License> {
        const licenses = await lastValueFrom(
            this.http.get<LicenseDTO[]>(this.env.connectAPIMasterUri + '/users/licenses')
        );
        // right now, we can work with single license, if future you should handle multiple license
        const license = licenses[0] || ({} as LicenseDTO);
        return License.fromDTO(license);
    }
}
