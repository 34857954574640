<div class="condition-edit">
    <div class="editor-field-row">
        <label translate [translateParams]="{ condition: '' }">TCS.TemplateEditor.Tf.Rules.Validate.ThisField</label>
    </div>
    <div
        class="editor-field-row"
        *ngFor="let subCondition of condition.subConditions; index as i"
        [ngClass]="{ 'conditions-row': $index != 0 }"
    >
        <gsp-dropdown
            [options]="conditionPair"
            [(ngModel)]="subCondition.operator"
            idField="value"
            textField="name"
            dropdownUniqueClass=""
            [addIcon]="true"
            (ngModelChange)="
                subCondition.operator === ConditionOperator.IS_ANSWERED ? (condition.errors.labelErrors = []) : ''
            "
        >
        </gsp-dropdown
        >&nbsp;
        <span
            gsp-focus-on-expression
            expression="subCondition.operator"
            value="[ConditionOperator.IS_ANSWERED, ConditionOperator.NONE]"
            reverse="true"
            focus-element=".gsp-number-wrapper"
        >
            <gsp-number
                [type]="GspNumberType.DECIMAL"
                class="gsp-number-wrapper"
                inputClass="small"
                [(ngModel)]="subCondition.daysAgoComparisonValue"
                (keyupEvent)="condition.conditionValidation(condition)"
                (blurEvent)="condition.conditionValidation(condition)"
                [hidden]="subCondition.operator === ConditionOperator.IS_ANSWERED"
            ></gsp-number> </span
        >&nbsp;
        <span [hidden]="subCondition.operator === ConditionOperator.IS_ANSWERED" translate="TC.Common.DaysAgo"></span
        >&nbsp;
        <span [hidden]="subCondition.operator === ConditionOperator.IS_ANSWERED">
            <gsp-dropdown
                [options]="condition.getConnectingOperators()"
                [hidden]="subCondition.operator === ConditionOperator.IS_ANSWERED"
                [(ngModel)]="subCondition.dummyConnectingOperator"
                idField="value"
                textField="name"
                (ngModelChange)="condition.addOrRemoveSubCondition(subCondition.dummyConnectingOperator, i)"
            ></gsp-dropdown>
        </span>
    </div>
</div>
