<div
    [ngClass]="{
        'multi-select': type !== SchemaFieldType.RADIO,
        'disabled-field': !isEditable,
        'disabled-field-radio': type === SchemaFieldType.RADIO && !isEditable
    }"
>
    <div class="editor-field-row" *ngIf="label">
        <span>{{ label }}</span>
    </div>
    <gsp-dropdown
        [name]="name"
        [options]="items"
        [(ngModel)]="value"
        idField="value"
        textField="label"
        dropdownUniqueClass=""
        *ngIf="type !== SchemaFieldType.RADIO"
        [ngDisabled]="!isEditable"
    ></gsp-dropdown>

    <div class="editor-field-row" *ngFor="let item of items">
        <label *ngIf="type === SchemaFieldType.RADIO">
            <input type="radio" [name]="name" [(ngModel)]="value" [value]="item.value" />
            <span [translate]="item.label"></span>
        </label>
    </div>
</div>
