import * as _ from 'lodash-es';

import { ChoiceField, Field, FieldType } from '../field';
import { UUID } from '../uuid';
import { ChoiceCondition } from './choice-condition';
import { Condition } from './condition';
import { DateCondition } from './date-condition';
import { NumberCondition } from './number-condition';
import { OtherCondition } from './other-condition';
import { RuleErrors } from './rule-errors';
import { Target } from './target';
import { TextCondition } from './text-condition';
import { YesNoCondition } from './yes-no-condition';

interface RuleFieldType {
    Other: number;
    Text: number;
    Number: number;
    YesNo: number;
    Date: number;
    Choice: number;
}

export enum RuleType {
    TARGET_ONLY = 'TargetOnly'
}

export class Rule {
    static readonly fieldType: RuleFieldType = {
        Other: 1,
        Text: 2,
        Number: 3,
        YesNo: 4,
        Date: 5,
        Choice: 6
    };

    uuid: string;
    conditions: Condition[];
    targets: Target[];
    targetRuleErrors: number;
    conditionRuleErrors: number;

    constructor(ruleUuid?: string, conditions?: Condition[], targets?: Target[]) {
        this.uuid = ruleUuid || UUID.generate();
        this.conditions = conditions ? this.getNewConditions(conditions) : [];
        this.targets = targets ? this.getNewTargets(targets) : [];
    }

    // Create the conditions with required methods from JSON.
    getNewConditions(conditions: Condition[]): Condition[] {
        let conditionsResult: Condition[] = [];
        conditions.forEach(condition => {
            switch (Rule.fieldType[condition.type as keyof RuleFieldType]) {
                case 2:
                    conditionsResult.push(
                        new TextCondition(
                            condition.sourceUuid,
                            condition.type,
                            (condition as TextCondition).operator,
                            (condition as TextCondition).comparisonValues || []
                        )
                    );
                    break;
                case 3:
                    conditionsResult.push(
                        new NumberCondition(
                            condition.sourceUuid,
                            condition.type,
                            (condition as NumberCondition).subConditions
                        )
                    );
                    break;
                case 4:
                    conditionsResult.push(
                        new YesNoCondition(condition.sourceUuid, condition.type, (condition as YesNoCondition).operator)
                    );
                    break;
                case 5:
                    conditionsResult.push(
                        new DateCondition(
                            condition.sourceUuid,
                            condition.type,
                            (condition as DateCondition).subConditions
                        )
                    );
                    break;
                case 6:
                    conditionsResult.push(
                        new ChoiceCondition(
                            condition.sourceUuid,
                            condition.type,
                            (condition as ChoiceCondition).operator,
                            (condition as ChoiceCondition).comparisonValues,
                            (condition as ChoiceCondition).compareOther
                        )
                    );
                    break;
                default:
                    conditionsResult.push(
                        new OtherCondition(condition.sourceUuid, (condition as OtherCondition)['operator'])
                    );
                    break;
            }
        });
        return conditionsResult;
    }

    // Create the targets with required methods from JSON.
    getNewTargets(targets: Target[]): Target[] {
        let targetResults: Target[] = [];
        targets.forEach(target => {
            targetResults.push(new Target(target.targetUuid, target['type'], target.action));
        });
        return targetResults;
    }

    // Add individual condition based on the fields type to the rule.
    setIndividualCondition(uuid: string, type: string, duplicatedCondition?: Condition): Condition {
        let condition: Condition;

        if (duplicatedCondition) {
            condition = this.conditions[this.conditions.length] = duplicatedCondition;
        } else {
            switch (Rule.fieldType[type as keyof RuleFieldType]) {
                case 2:
                    condition = this.conditions[this.conditions.length] = new TextCondition(uuid, type);
                    break;
                case 3:
                    condition = this.conditions[this.conditions.length] = new NumberCondition(uuid, type);
                    break;
                case 4:
                    condition = this.conditions[this.conditions.length] = new YesNoCondition(uuid, type);
                    break;
                case 5:
                    condition = this.conditions[this.conditions.length] = new DateCondition(uuid, type);
                    break;
                case 6:
                    condition = this.conditions[this.conditions.length] = new ChoiceCondition(uuid, type);
                    break;
                default:
                    condition = this.conditions[this.conditions.length] = new OtherCondition(uuid);
                    break;
            }
        }
        return condition;
    }

    // Add individual Target to the rule.
    setIndividualTarget(uuid: string, type: string, duplicatedTarget?: Target): Target {
        if (duplicatedTarget) {
            this.targets[this.targets.length] = duplicatedTarget;
        } else {
            this.targets[this.targets.length] = new Target(uuid, type);
        }
        return this.targets[this.targets.length - 1];
    }

    // Set the required attributes to the rule.
    setAttributes(uuid: string, conditions: Condition[], targets: Target[]): void {
        this.uuid = uuid;
        this.conditions = conditions ? this.getNewConditions(conditions) : [];
        this.targets = targets ? this.getNewTargets(targets) : [];
    }

    // Get the condition from rule based on field Id
    getCondition(fieldId: string): Condition {
        return _.find(this.conditions, condition => condition.sourceUuid === fieldId);
    }

    // Get the target from rule based on field Id
    getTarget(fieldId: string): Target {
        return _.find(this.targets, target => target.targetUuid === fieldId);
    }

    // Add condition to the rule with source as field(passed in attribute).
    addCondition(field: Field, ruleType: string, duplicatedCondition?: Condition): Condition {
        let condition = _.find(this.conditions, conditions => conditions.sourceUuid === field.uuid);
        if (!condition) {
            condition = this.setIndividualCondition(field.uuid, ruleType, duplicatedCondition);
        }
        return condition;
    }

    // Remove source on field from rule
    removeCondition(field: Field, ruleNo: number): void {
        if (field.type === FieldType.Choice || field.type === FieldType.CodedChoice) {
            let choiceField = field as ChoiceField;
            choiceField.values.forEach(choiceValue => {
                for (let choiceSelected in choiceValue.choiceSelected) {
                    if (ruleNo === Number(choiceSelected)) {
                        delete choiceValue.choiceSelected[choiceSelected];
                    }
                }
            });
        }
        _.remove(this.conditions, condition => condition.sourceUuid === field.uuid);
    }

    // Remove target on field from rule
    removeTarget(field: Field): void {
        _.remove(this.targets, target => target.targetUuid === field.uuid);
    }

    // Add target on field to rule.
    addTarget(field: Field, ruleType: string, duplicatedTarget?: Target): Target {
        let target = _.find(this.targets, targetCondition => targetCondition.targetUuid === field.uuid);
        if (!target) {
            target = this.setIndividualTarget(field.uuid, ruleType, duplicatedTarget);
        }
        return target;
    }

    // Return the targets filtered by Enable/Disable Target
    getTargetsFilteredByAction(enabled: boolean): Target[] {
        return this.targets.filter(target => {
            if (enabled) {
                return target.action === 'EnableTarget';
            } else {
                return target.action === 'DisableTarget';
            }
        });
    }

    // Check for source only or target only selected error
    isRuleErrors(): {
        targetErrors: boolean;
        conditionErrors: boolean;
    } {
        let ruleErrors = {
            targetErrors: false,
            conditionErrors: false
        };
        if (this.conditions.length === 0 && this.targets.length > 0) {
            ruleErrors.targetErrors = true;
        }
        if (this.targets.length === 0 && this.conditions.length > 0) {
            ruleErrors.conditionErrors = true;
        }
        return ruleErrors;
    }

    // Returns rule level error messages based on field level and common error messages.
    getRuleLevelMessages(): string[] {
        let ruleLevelMessagesNo: number[] = [];
        let ruleLevelMessages: string[] = [];
        this.conditions.forEach(condition => {
            if (condition.getErrorMessagesNo) {
                let conditionErrorNos = condition.getErrorMessagesNo();
                if (conditionErrorNos.length) {
                    ruleLevelMessagesNo = ruleLevelMessagesNo.concat(conditionErrorNos);
                }
            }
            if (condition.errors.commonErrorNos && condition.errors.commonErrorNos.length) {
                ruleLevelMessagesNo = ruleLevelMessagesNo.concat(condition.errors.commonErrorNos);
            }
        });

        this.targets.forEach(target => {
            if (target['errors'].commonErrorNos && target['errors'].commonErrorNos.length) {
                ruleLevelMessagesNo = ruleLevelMessagesNo.concat(target['errors'].commonErrorNos);
            }
        });

        _.uniq(ruleLevelMessagesNo).forEach(ruleMessageNo => {
            ruleLevelMessages.push(RuleErrors.errorMessages[ruleMessageNo]);
        });

        return ruleLevelMessages.slice(0, 1);
    }

    getRuleLevelErrorMessages(): string[] {
        let ruleErrors = this.isRuleErrors();
        let errorMessages: string[] = [];
        if (ruleErrors.targetErrors) {
            errorMessages.push('TCS.TemplateEditor.Tf.Rules.ErrorMessages.ConditionMustBeSpecified');
        }
        if (ruleErrors.conditionErrors) {
            errorMessages.push('TCS.TemplateEditor.Tf.Rules.ErrorMessages.TargetMustBeSpecified');
        }
        return errorMessages;
    }

    toDTO(): Partial<Rule> {
        return {
            uuid: this.uuid,
            conditions: this.conditions,
            targets: this.targets
        };
    }
}
