export interface Entitlement {
    orgId: string;
    masterOrgId: string;
    orgName: string;
    sku: EntitlementSKUs;
    activationDate: string;
    expiryDate: string;
}

export enum EntitlementSKUs {
    BETA_COORDS = 'TMV-BETACOORDS-DEMO-DEV',
    PDF_EXPORT = 'TMV-PDFEXP-DEMO-DEV',
    CAD_EXPORT = 'TMV-CADEXP-DEMO-DEV',
    EARLY_EXPERIENCE_PROGRAM = 'TMV-EPPEEP-DEMO-DEV',
    TERRA_OFFICE_BASIC = 'TO-BASIC',
    TERRA_OFFICE_STANDARD = 'TO-STANDARD',
    TERRA_OFFICE_ADVANCED = 'TO-ADVANCED',
    TERRA_OFFICE_BASIC_DEV = 'TO-BASIC-DEMO-DEV',
    TERRA_OFFICE_STANDARD_DEV = 'TO-STANDARD-DEMO-DEV',
    TERRA_OFFICE_ADVANCED_DEV = 'TO-ADVANCED-DEMO-DEV',
    TERRA_OFFICE_BASIC_SALES = 'TO-BASIC-DEMO-SALES',
    TERRA_OFFICE_STANDARD_SALES = 'TO-STANDARD-DEMO-SALES',
    TERRA_OFFICE_ADVANCED_SALES = 'TO-ADVANCED-DEMO-SALES'
}

export const authorizedCadExports = [
    EntitlementSKUs.TERRA_OFFICE_BASIC,
    EntitlementSKUs.TERRA_OFFICE_STANDARD,
    EntitlementSKUs.TERRA_OFFICE_ADVANCED,
    EntitlementSKUs.TERRA_OFFICE_BASIC_DEV,
    EntitlementSKUs.TERRA_OFFICE_STANDARD_DEV,
    EntitlementSKUs.TERRA_OFFICE_ADVANCED_DEV,
    EntitlementSKUs.TERRA_OFFICE_BASIC_SALES,
    EntitlementSKUs.TERRA_OFFICE_STANDARD_SALES,
    EntitlementSKUs.TERRA_OFFICE_ADVANCED_SALES
];
