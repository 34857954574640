import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { FeatureFilterStreamService } from 'src/app/shared/common/current-features/feature-filter-stream.service';
import { LayersStreams } from 'src/app/shared/common/current-layers/layers-streams.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { MenuService } from 'src/app/shared/common/layout/menu.service';
import { MapWorkspacePermissionType } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace-permission';

import { MapMenuCode, MapMenuItem, mapMenuList } from './map-menu-list';

@Component({
    templateUrl: './menu-tabs.component.html',
    selector: 'menu-tabs'
})
export class MenuTabsComponent implements OnInit, OnDestroy {
    // expose ButtonType enum to template
    public ButtonType = ButtonType;
    private destroyed = new Subject<void>();

    private activeMenuCodes: MapMenuCode[] = [];
    private mutuallyExclusiveMenus: { [key: string]: MapMenuCode[] } = {
        [MapMenuCode.LAYERS]: [MapMenuCode.TASKS],
        [MapMenuCode.TASKS]: [MapMenuCode.LAYERS]
    };
    public highlightFilter: boolean;

    // exposing enum to template
    public MapWorkspacePermissionType = MapWorkspacePermissionType;

    ngOnInit(): void {
        // Re-initialize menu on workspace change
        this.mapWorkspacesStore.currentMapWorkspaceStream.pipe(takeUntil(this.destroyed)).subscribe(() => {
            this.initMenu();
        });
        // Re-initialize menu on edit layer opening
        this.layersStreams.currentEditLayerStream.pipe(takeUntil(this.destroyed)).subscribe(() => {
            this.initMenu();
        });
    }

    initMenu(): void {
        // TODO LATER: GIM 6/6/19 - ported from ng1 as-is.  Check what writing to route params achieves (if anything)
        let activeMenuCodesParam = !this.route.snapshot.queryParams.activeMenus
            ? MapMenuCode.LAYERS
            : this.route.snapshot.queryParams.activeMenus;

        if (activeMenuCodesParam) {
            this.activeMenuCodes = activeMenuCodesParam.split(',');
            mapMenuList.forEach(menu => {
                menu.selected = this.activeMenuCodes.indexOf(menu.menuCode) > -1;
            });
        }

        mapMenuList.forEach(menuItem => {
            if (menuItem.selected === true && this.activeMenuCodes.indexOf(menuItem.menuCode) === -1) {
                this.activeMenuCodes.push(menuItem.menuCode);
            }
        });

        this.menuService.activeMenusStream.next(this.activeMenuCodes);

        this.featureFilterStream.activeFilterStream.pipe(takeUntil(this.destroyed)).subscribe(filters => {
            this.highlightFilter = filters.hasActiveFilters();
        });
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    constructor(
        private route: ActivatedRoute,
        private menuService: MenuService,
        private featureFilterStream: FeatureFilterStreamService,
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private layersStreams: LayersStreams
    ) {}

    public activateMenu(menu: MapMenuItem): void {
        menu.selected = !menu.selected;
        this.toggleMutuallyExclusiveMenus(menu);
        if (menu.selected) {
            this.activeMenuCodes.push(menu.menuCode);
        } else {
            this.activeMenuCodes.splice(this.activeMenuCodes.indexOf(menu.menuCode), 1);
        }

        this.menuService.activeMenusStream.next(this.activeMenuCodes);
    }

    public toggleMutuallyExclusiveMenus(menu: MapMenuItem): void {
        if (this.mutuallyExclusiveMenus[menu.menuCode] && menu.selected) {
            this.mutuallyExclusiveMenus[menu.menuCode].forEach((menuCode: MapMenuCode) => {
                if (this.activeMenuCodes.indexOf(menuCode) > -1) {
                    let index = mapMenuList.map(m => m.menuCode).indexOf(menuCode);
                    mapMenuList[index].selected = !menu.selected;
                    this.activeMenuCodes.splice(this.activeMenuCodes.indexOf(menuCode), 1);
                }
            });
        }
    }

    public getGroupMenuItems(group: number): MapMenuItem[] {
        return mapMenuList.filter(m => m.group === group);
    }
}
