import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BooleanConverter, InputConverter } from 'src/app/shared/common/components/input-converter.decorator';
import { ValueAccessorBase } from 'src/app/shared/common/components/value-accessor-base';

@Component({
    selector: 'single-selection-field',
    templateUrl: './single-selection-field.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SingleSelectionFieldComponent),
            multi: true
        }
    ]
})
export class SingleSelectionFieldComponent extends ValueAccessorBase<boolean> {
    @Input()
    @InputConverter(BooleanConverter)
    visible: boolean;

    @Input()
    name: string;

    @Input()
    label: string;

    @Input()
    hintText: string; // To show hint text for the repeatField property

    @Input()
    enabled = true;

    constructor() {
        super();
    }
}
