import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ErrorPageComponent } from './error-page.component';

const routes: Routes = [
    {
        path: 'error/:errorStatus',
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        component: ErrorPageComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class ErrorPageRoutingModule {}
