<div class="condition-edit">
    <div class="editor-field-row">
        <label translate [translateParams]="{ condition: '' }">TCS.TemplateEditor.Tf.Rules.Validate.ThisField</label>
    </div>
    <div
        class="editor-field-row"
        *ngFor="let subCondition of condition.subConditions; index as i"
        [ngClass]="{ 'conditions-row': i != 0 }"
    >
        <gsp-dropdown
            [options]="conditionPair"
            [(ngModel)]="subCondition.operator"
            idField="value"
            textField="name"
            dropdownUniqueClass=""
            [addIcon]="true"
            (ngModelChange)="
                subCondition.operator == ConditionOperator.IS_ANSWERED ? (condition.errors.labelErrors = []) : '';
                setFocusOnInput(i);
                condition.conditionValidation(condition)
            "
        ></gsp-dropdown>
        &nbsp;<span
            gsp-focus-on-expression
            expression="subCondition.operator"
            value="[ConditionOperator.IS_ANSWERED, ConditionOperator.NONE]"
            reverse="true"
            focus-element=".gsp-number-wrapper"
        >
            <gsp-number
                #ruleInputElement
                [type]="GspNumberType.DECIMAL"
                class="gsp-number-wrapper"
                inputClass="small"
                [(ngModel)]="subCondition.comparisonValue"
                (keyupEvent)="condition.conditionValidation(condition)"
                (blurEvent)="condition.conditionValidation(condition)"
                [hidden]="subCondition.operator === ConditionOperator.IS_ANSWERED"
            ></gsp-number> </span
        >&nbsp;
        <span [hidden]="subCondition.operator === ConditionOperator.IS_ANSWERED">
            <gsp-dropdown
                [options]="connectingOperators"
                [(ngModel)]="subCondition.dummyConnectingOperator"
                idField="value"
                textField="name"
                (ngModelChange)="
                    condition.addOrRemoveSubCondition(subCondition.dummyConnectingOperator, i); refreshConditionPair()
                "
            ></gsp-dropdown>
        </span>
    </div>
</div>
