import * as _ from 'lodash-es';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';

import { Condition, ConditionOperator } from './condition';
import { NumberSubCondition } from './number-subcondition';
import { RuleErrors } from './rule-errors';

export class NumberCondition extends Condition {
    static readonly numberConditionsText = [
        {
            name: 'TC.Common.IsAnswered',
            value: ConditionOperator.IS_ANSWERED,
            iconClass: 'icon_line_yesno',
            sideBarSign: 'Is answered'
        },
        {
            name: 'TC.Common.IsEqualTo',
            value: ConditionOperator.IS_EQUAL_TO,
            iconClass: 'icon_line_equal',
            sideBarSign: '='
        },
        {
            name: 'TC.Common.IsNotEqual',
            value: ConditionOperator.IS_NOT_EQUAL_TO,
            iconClass: 'icon_line_notEqual',
            sideBarSign: 'Is not ='
        },
        {
            name: 'TC.Common.IsGreaterThanOrEqual',
            value: ConditionOperator.IS_GREATER_THAN_OR_EQUAL_TO,
            iconClass: 'icon_line_greaterThanEqualTo',
            sideBarSign: '>='
        },
        {
            name: 'TC.Common.IsLessThanOrEqual',
            value: ConditionOperator.IS_LESS_THAN_OR_EQUAL_TO,
            iconClass: 'icon_line_lessThanEqualTo',
            sideBarSign: '<='
        },
        {
            name: 'TC.Common.IsGreaterThan',
            value: ConditionOperator.IS_GREATER_THAN,
            iconClass: 'icon_line_greaterThan',
            sideBarSign: '>'
        },
        {
            name: 'TC.Common.IsLessThan',
            value: ConditionOperator.IS_LESS_THAN,
            iconClass: 'icon_line_lessThan',
            sideBarSign: '<'
        }
    ];

    public subConditions: NumberSubCondition[];

    private connectingOperators = [
        {
            name: ' ',
            value: ConditionOperator.NONE
        },
        {
            name: 'TC.Common.And',
            value: ConditionOperator.AND
        },
        {
            name: 'TC.Common.Or',
            value: ConditionOperator.OR
        }
    ];

    multipleNumberConditionsText: { name: string; value: string; iconClass: string; sideBarSign: string }[];

    constructor(uuid: string, type: string, numberRuleSubCondition?: NumberSubCondition[]) {
        super(uuid, type);

        this.subConditions = numberRuleSubCondition
            ? this.getNewSubConditions(numberRuleSubCondition)
            : [new NumberSubCondition(ConditionOperator.NONE, ConditionOperator.IS_ANSWERED)];

        this.multipleNumberConditionsText = CloneUtils.cloneDeep(NumberCondition.numberConditionsText);
        this.multipleNumberConditionsText.splice(0, 1);
    }

    // Get the condition text which is displayed in sidebar.
    getConditionText(translator: TranslationService): string {
        let result = '';
        let conditionPairObj = this.getConditionPair();
        _.each(this.subConditions, subCondition => {
            let selectedCondition = conditionPairObj.filter(condition => condition.value === subCondition.operator);
            let operator = '';
            if (selectedCondition.length > 0) {
                operator = selectedCondition[0].sideBarSign;
            }
            result += operator + ' ';
            if (subCondition.operator !== ConditionOperator.IS_ANSWERED) {
                result += subCondition.comparisonValue + ' ';
            }
            if (subCondition.dummyConnectingOperator !== ConditionOperator.NONE) {
                result += subCondition.dummyConnectingOperator + ' ';
            }
        });
        return result;
    }

    // Returns the list of operators used for this condition.
    getConditionPair(): {
        name: string;
        value: string;
        iconClass: string;
        sideBarSign: string;
    }[] {
        if (this.subConditions.length > 1) {
            return this.multipleNumberConditionsText;
        }
        return NumberCondition.numberConditionsText;
    }

    getErrorMessagesNo(): number[] {
        let sourceLevelErrorMessagesNo: number[] = [];
        if (this.errors.labelErrors && this.errors.labelErrors.length > 0) {
            sourceLevelErrorMessagesNo.push(3);
        }
        return sourceLevelErrorMessagesNo;
    }

    // Returns the connecting operator used
    getConnectingOperators(): {
        name: string;
        value: string;
    }[] {
        return this.connectingOperators;
    }
    // Add Sub condition to the this condition.
    addSubCondition(
        connectingOperator = ConditionOperator.NONE,
        operator: ConditionOperator = null,
        comparisonValue: string = null,
        dummyConnectingOperator = ConditionOperator.NONE
    ): void {
        this.subConditions.push(
            new NumberSubCondition(connectingOperator, operator, comparisonValue, dummyConnectingOperator)
        );
    }

    addOrRemoveSubCondition(connectingOperator: ConditionOperator, index: number): void {
        if (connectingOperator !== ConditionOperator.NONE) {
            if (index === this.subConditions.length - 1) {
                this.addSubCondition(connectingOperator);
            } else if (this.subConditions[index + 1]) {
                this.subConditions[index + 1].connectingOperator = connectingOperator;
            }
        } else {
            this.removeSubCondition(index + 1, this.subConditions.length - index);
        }
        this.conditionValidation(this);
    }

    conditionValidation(condition: NumberCondition): void {
        condition.errors.labelErrors = [];
        condition.subConditions.forEach(subCondition => {
            if (subCondition.operator === ConditionOperator.IS_ANSWERED) {
                condition.errors.labelErrors = [];
            }
            // added string typecast to suppress condition always return true/false ts lint error
            if (
                subCondition.operator === null ||
                subCondition.operator === ConditionOperator.NONE ||
                (subCondition.operator !== (ConditionOperator.NONE as string) &&
                    subCondition.operator !== ConditionOperator.IS_ANSWERED &&
                    typeof subCondition.comparisonValue !== 'undefined' &&
                    (subCondition.comparisonValue == null ||
                        (subCondition.comparisonValue.hasOwnProperty('length') &&
                            subCondition.comparisonValue.length === 0))) ||
                (typeof subCondition.comparisonValue !== 'undefined' &&
                    subCondition.comparisonValue != null &&
                    subCondition.operator === (ConditionOperator.NONE as string) &&
                    (subCondition.operator as string) !== ConditionOperator.IS_ANSWERED) ||
                (subCondition.operator !== ConditionOperator.IS_ANSWERED &&
                    typeof subCondition.comparisonValue === 'undefined')
            ) {
                if (condition.errors.labelErrors.indexOf(RuleErrors.errorMessages[3]) === -1) {
                    condition.errors.labelErrors.push(RuleErrors.errorMessages[3]);
                }
            }
        });
    }

    // Return the formatted object with methods from JSON.
    getNewSubConditions(subConditions: NumberSubCondition[]): NumberSubCondition[] {
        let subConditionResult: NumberSubCondition[] = [];
        subConditions.forEach((subCondition, index) => {
            if (index === subConditions.length - 1) {
                subConditionResult.push(
                    new NumberSubCondition(
                        subCondition.connectingOperator,
                        subCondition.operator,
                        subCondition.comparisonValue
                    )
                );
            } else {
                subConditionResult.push(
                    new NumberSubCondition(
                        subCondition.connectingOperator,
                        subCondition.operator,
                        subCondition.comparisonValue,
                        subConditions[index + 1].connectingOperator
                    )
                );
            }
        });
        return subConditionResult;
    }

    // Remove sub condition from this condition.
    removeSubCondition(index: number, length: number): void {
        length = length ? length : 1;
        this.subConditions.splice(index, length);
    }
}
