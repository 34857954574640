import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { LayersStore } from 'src/app/shared/common/current-layers/layers-store.service';
import { ProjectLayersStreams } from 'src/app/shared/common/current-layers/project-layers-streams.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';
import { CoordinateSystemComponentType } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { MapWorkspaceService } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace.service';

import { SidePanelStreamsService } from '../side-panel-streams.service';
import { SidePanelName } from '../side-panel.component';

@Component({
    templateUrl: './add-existing-layer.component.html',
    selector: 'add-existing-layer'
})
export class AddExistingLayerComponent implements OnInit, OnDestroy {
    // expose ButtonType enum to template
    public ButtonType = ButtonType;
    private destroyed = new Subject<void>();

    private panelName = SidePanelName.EXISTING_LAYER;
    public loading = true;
    public searchByNameControl = new UntypedFormControl();
    private searchByName = '';
    public layerLibraryLayers: Layer[] = [];
    public selectedLayers: Layer[] = [];
    public workspaceFilterDatumInfo: string;

    constructor(
        private router: Router,
        private sidePanelStreams: SidePanelStreamsService,
        private layersStore: LayersStore,
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private projectStream: ProjectStreamService,
        private projectLayersStreams: ProjectLayersStreams,
        private mapWorkspaceService: MapWorkspaceService,
        private messaging: MessagingService,
        private translate: TranslationService
    ) {}

    ngOnInit(): void {
        this.projectLayersStreams.layerLibraryLayersStream
            .pipe(takeUntil(this.destroyed), distinctUntilChanged())
            .subscribe(layers => {
                this.layerLibraryLayers = layers;
                this.selectedLayers = this.getSelectedLayers(this.layerLibraryLayers);
            });

        this.projectLayersStreams.projectLayersLoadingStream
            .pipe(takeUntil(this.destroyed), distinctUntilChanged())
            .subscribe(loading => {
                this.loading = loading;
            });

        this.searchByNameControl.valueChanges
            .pipe(takeUntil(this.destroyed), distinctUntilChanged(), debounceTime(700))
            .subscribe((searchByName: string) => {
                this.searchByName = searchByName;
                this.selectedLayers = this.getSelectedLayers(this.layerLibraryLayers);
                this.projectLayersStreams.setSearchLayerName(this.searchByName);
            });

        this.projectLayersStreams.setSearchLayerName(this.searchByName); // this kicks off the loading of layers in the service

        this.showWorkspaceDatumFilterToast();
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    private showWorkspaceDatumFilterToast(): void {
        const currentWorkspace = this.mapWorkspacesStore.getCurrentMapWorkspace();
        this.mapWorkspaceService
            .getComponentById(CoordinateSystemComponentType.DATUM, currentWorkspace.coordinateSystem.datumComponentId)
            .then((datum: any) => {
                let message = this.translate.instant('TCS.MapViewer.LayerLibrary.DatumFilterToastMessage', {
                    datumName: datum.displayName
                });
                this.workspaceFilterDatumInfo = message;
            });
    }

    private getSelectedLayers(layers: Layer[]): Layer[] {
        return layers.filter(layer => !!layer.selected);
    }

    public closeSidePanel(): void {
        if (this.layerLibraryLayers) {
            this.layerLibraryLayers.forEach(layer => (layer.selected = false));
        }
        this.sidePanelStreams.closeSidePanel(this.panelName);
    }

    public addToCurrentWorkspace(): void {
        this.selectedLayers.forEach(layer => {
            this.layersStore
                .addLayerToMapWorkspace(
                    this.projectStream.getCurrentProject().id,
                    this.mapWorkspacesStore.getCurrentMapWorkspace().id,
                    layer
                )
                .then(() => {
                    layer.isLinkedLayer = true;
                    this.messaging.showInfo(this.translate.instant('MapViewer.LayerLibrary.Linking.Success'));
                });
        });
        this.closeSidePanel();
    }

    public select(layer: Layer): void {
        this.layerLibraryLayers.forEach(layer2 => {
            layer2.selected = false;
        });
        if (layer.selected !== undefined) {
            layer.selected = !layer.selected;
        }
        this.selectedLayers = this.getSelectedLayers(this.layerLibraryLayers);
    }

    public editSpatialItem(layer: Layer): void {
        this.router.navigate(['template/editor'], {
            queryParams: { templateId: layer.templateId, layerId: layer.id },
            queryParamsHandling: 'merge'
        });
    }
}
