import { Component, Input } from '@angular/core';

@Component({
    selector: 'loading',
    template: `
        <div [ngClass]="{ loading: !small, 'loading-small': small }"></div>
    `
})
export class LoadingComponent {
    @Input()
    small = true;
}
