<div class="feature-field">
    <label class="feature-label">{{ field.displayName }}</label>
    <div
        class="feature-data"
        *ngIf="
            field.modelType === AutoFieldModelType.CreationDateTime ||
            field.modelType === AutoFieldModelType.UpdateDateTime
        "
    >
        {{ field.value | formatDateTime: 'longDateTime':field.isUTC }}
    </div>
    <!-- TODO: GIM - check rendering of innerHtml works okay below...else DomSanitize ??  -->
    <div
        class="feature-data"
        *ngIf="
            field.modelType !== AutoFieldModelType.CreationDateTime &&
            field.modelType !== AutoFieldModelType.UpdateDateTime
        "
    >
        {{ field.displayValue || field.value }}
        <span [hidden]="field.value == null" [innerHtml]="displayUnitHtml"></span>
    </div>
</div>
