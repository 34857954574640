import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { lastValueFrom, Subject, Subscription, timer } from 'rxjs';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { GeoExportJob } from 'src/app/feature/export/export';
import { MapContentMonitor } from 'src/app/feature/map-viewer/common/map-content-monitor.service';

import { MapWorkspacesStoreService } from '../current-map-workspaces/map-workspaces-store.service';
import { EnvironmentService } from '../environment/environment.service';

export enum ExportResponseStatus {
    COMPLETE = 'Complete',
    ERROR = 'Error',
    IN_PROGRESS = 'InProgress',
    NO_DATA = 'NoData'
}

export enum PollingType {
    EXPORT = 'export'
}

export const EXPORT_POLL_DETAILS_KEY = 'exportPollDetails';

export interface ExportPollDetails {
    projectId: string;
    workspaceId: string;
    id: string;
    toastId: number;
    message: string;
}

@Injectable({
    providedIn: 'root'
})
export class ExportPollingStatusService {
    private currentPollingStatus: {
        [key: string]: {
            type: PollingType;
            projectId: string;
            toastId: number;
            completeMessage: string;
            timer: Subscription;
        };
    } = {};

    // Triggered when export completes successfully OR fails
    private exportCompleted = new Subject<void>();
    public exportCompleted$ = this.exportCompleted.asObservable();

    constructor(
        private http: HttpClient,
        private mapContentMonitor: MapContentMonitor,
        private mapWorkspaceStore: MapWorkspacesStoreService,
        private translate: TranslationService,
        private messaging: MessagingService,
        private env: EnvironmentService
    ) {}

    public startPolling(
        type: PollingType,
        projectId: string,
        pollingId: string,
        toastId: number,
        completeMessage: string
    ): void {
        this.currentPollingStatus[pollingId] = this.currentPollingStatus[pollingId] || ({} as any);
        if (this.currentPollingStatus[pollingId].timer && !this.currentPollingStatus[pollingId].timer.closed) {
            this.currentPollingStatus[pollingId].timer.unsubscribe();
        }
        this.currentPollingStatus[pollingId].type = type;
        this.currentPollingStatus[pollingId].projectId = projectId;
        this.currentPollingStatus[pollingId].toastId = toastId;
        this.currentPollingStatus[pollingId].completeMessage = completeMessage;
        this.currentPollingStatus[pollingId].timer = timer(60000).subscribe(() => {
            this.getStatusFromApi(type, projectId, pollingId).then(response => {
                this.checkStatus(response, pollingId);
            });
        });
    }

    public checkStatus(response: GeoExportJob, pollingId: string): void {
        if (this.currentPollingStatus[pollingId]?.type !== PollingType.EXPORT) {
            return;
        }
        if (
            [ExportResponseStatus.COMPLETE, ExportResponseStatus.ERROR, ExportResponseStatus.NO_DATA].includes(
                response.status
            )
        ) {
            this.messaging.clear(this.currentPollingStatus[pollingId].toastId);
            if (response.status === ExportResponseStatus.COMPLETE) {
                this.mapContentMonitor.currentMapContentNeedsRefresh(true);
                this.messaging.showSuccess(this.currentPollingStatus[pollingId].completeMessage);
                // Refresh available custom tags for the workspace after successful export
                this.mapWorkspaceStore.setMapWorkspaceFeatureTags();
            } else if (response.status === ExportResponseStatus.NO_DATA) {
                this.messaging.showWarning(this.translate.instant('TCS.EmailNotTriggeredForExport'));
            } else {
                this.messaging.showError(this.translate.instant('TC.Common.ExportFailed'));
            }
            this.exportCompleted.next(null);
            this.removePollingStatusById(pollingId);
        } else {
            this.startPolling(
                this.currentPollingStatus[pollingId].type,
                this.currentPollingStatus[pollingId].projectId,
                pollingId,
                this.currentPollingStatus[pollingId].toastId,
                this.currentPollingStatus[pollingId].completeMessage
            );
        }
    }

    public clearAllPolling(): void {
        Object.keys(this.currentPollingStatus).forEach(id => {
            if (_.isNumber(this.currentPollingStatus[id].toastId)) {
                this.messaging.clear(this.currentPollingStatus[id].toastId);
            }
            this.currentPollingStatus[id].timer.unsubscribe();
        });
    }

    public addPollingDetailsToSessionStorage(exportPollObject: ExportPollDetails): void {
        const currentExportPollDetails = sessionStorage.getItem(EXPORT_POLL_DETAILS_KEY);
        const currentExportPollArray = JSON.parse(currentExportPollDetails) as ExportPollDetails[];
        sessionStorage.setItem(
            EXPORT_POLL_DETAILS_KEY,
            JSON.stringify(
                currentExportPollDetails?.length ? [...currentExportPollArray, exportPollObject] : [exportPollObject]
            )
        );
    }

    private removePollingStatusById(pollingId: string): void {
        const currentExportPollDetails = sessionStorage.getItem(EXPORT_POLL_DETAILS_KEY);
        if (currentExportPollDetails) {
            const currentExportPollArray = JSON.parse(currentExportPollDetails) as ExportPollDetails[];
            const newExportPollArray = currentExportPollArray.filter(
                exportPollObject => exportPollObject.id !== pollingId
            );
            if (newExportPollArray.length) {
                sessionStorage.setItem(EXPORT_POLL_DETAILS_KEY, JSON.stringify(newExportPollArray));
            } else {
                sessionStorage.removeItem(EXPORT_POLL_DETAILS_KEY);
            }
        }
        delete this.currentPollingStatus[pollingId];
    }

    private getStatusFromApi(type: string, projectId: string, id: string): Promise<GeoExportJob> {
        if (type === PollingType.EXPORT) {
            return lastValueFrom(
                this.http.get<GeoExportJob>(this.env.apiUrl + '/projects/' + projectId + '/formexports/' + id)
            ).then(
                response => response || {},
                () => ({
                    // eslint-disable-next-line quote-props
                    status: ExportResponseStatus.ERROR
                })
            );
        }
    }
}
