import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: '[urlValidate]',
    providers: [{ provide: NG_VALIDATORS, useExisting: UrlValidateDirective, multi: true }]
})
export class UrlValidateDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors {
        if (control.value && control.value !== null && control.value !== undefined) {
            const re = /(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/;
            const urlPattern = /((http|https)\:\/\/)?[a-z0-9-\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?$/;
            const isValid = re.test(control.value) || urlPattern.test(control.value);

            if (!isValid) {
                return { ['urlInvalid']: true }; // return object if the validation is not passed.
            }
        }
        return null; // return null if validation is passed.
    }
}
