import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TemplateWizardService } from 'src/app/feature/map-viewer/template-wizard/template-wizard.service';
import { TemplateStoreForComponent } from 'src/app/feature/template-editor/template/template-store-for-component.service';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { MapWorkspacePermissionType } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace-permission';
import { MapWorkspaceStatus } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace.types';
import { User, UserRole } from 'src/app/shared/user/user';

import { FeatureFilterStreamService } from '../../current-features/feature-filter-stream.service';
import { MapWorkspaceStreamsService } from '../../current-map-workspaces/map-workspace-streams.service';
import { CurrentUserStreamService } from '../../current-user/current-user-stream.service';
import { ModalSize } from '../../modal-sizes';
import { CloneUtils } from '../../utility/clone-utils';

export enum ApplicationTopBarIcons {
    ACTIVITY_TAB = 'ACTIVITY_TAB',
    NOTIFICATION_TAB = 'NOTIFICATION_TAB',
    USER_TAB = 'USER_TAB'
}

@Component({
    selector: 'gsp-app-top-bar',
    templateUrl: './gsp-app-top-bar.component.html'
})
export class GspApplicationTopBarComponent implements OnInit {
    currentWorkspace: MapWorkspace = null;
    showBackToMapViewer = false;
    isWorkspaceFilterActive = false;
    activeRouteUrl: string = null;
    showArchiveWorkspaceInfoPopup = false;
    showFilterBadge = false;
    mapWorkspaceChangesSubscription = false;

    currentActiveIcon: ApplicationTopBarIcons;
    showActivityTab = false;
    showActivityTabUpdate = false;
    showNotificationTab = false;
    showNotificationTabUpdate = false;
    showUserTab = false;
    showUserTabUpdate = false;
    user: User = null;

    destroyed = new Subject<void>();

    // exposing enum to template
    ApplicationTopBarIcons = ApplicationTopBarIcons;
    MapWorkspacePermissionType = MapWorkspacePermissionType;
    MapWorkspaceStatus = MapWorkspaceStatus;
    ModalSize = ModalSize;
    UserRole = UserRole;

    constructor(
        private mapWorkspaceStreamsService: MapWorkspaceStreamsService,
        private router: Router,
        private featureFilterStream: FeatureFilterStreamService,
        private templateStoreForComponent: TemplateStoreForComponent,
        private templateWizardService: TemplateWizardService,
        private currentUserStreamService: CurrentUserStreamService
    ) {}

    ngOnInit(): void {
        this.currentUserStreamService.currentUserWithRoleStream
            .pipe(takeUntil(this.destroyed))
            .subscribe((user: User) => {
                this.user = user;
            });

        this.mapWorkspaceStreamsService.currentMapWorkspaceStream.subscribe(workspace => {
            this.currentWorkspace = workspace;
            if (this.currentWorkspace && !this.currentWorkspace.isFileViewer) {
                this.updateCurrentWorkspaceOnMapWorkspaceChange();
            }
            this.showActivityTabUpdate = false;
        });

        this.router.events.pipe(takeUntil(this.destroyed)).subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.activeRouteUrl = event.url;
                if (this.activeRouteUrl.indexOf('mapViewer') > -1 || this.activeRouteUrl.indexOf('shareMap') > -1) {
                    this.showBackToMapViewer = false;
                    this.showFilterBadge = true;
                } else if (this.activeRouteUrl.indexOf('editor') > -1) {
                    this.showBackToMapViewer = true;
                    this.showFilterBadge = false;
                } else if (this.activeRouteUrl.indexOf('wizard') > -1) {
                    this.showBackToMapViewer = true;
                    this.showFilterBadge = false;
                }
            }
        });

        this.featureFilterStream.activeFilterStream.pipe(takeUntil(this.destroyed)).subscribe(filters => {
            this.isWorkspaceFilterActive = filters.hasActiveFilters();
        });
    }

    ngOnDestroyed(): void {
        this.destroyed.next(null);
        this.destroyed.complete();
    }

    updateCurrentWorkspaceOnMapWorkspaceChange(): void {
        if (!this.mapWorkspaceChangesSubscription) {
            // update the currentWorkspace if the updated map workspace in the workspace panel is current workspace
            this.mapWorkspaceStreamsService.projectMapWorkspacesStream
                .pipe(takeUntil(this.destroyed))
                .subscribe(workspaces => {
                    if (workspaces && this.currentWorkspace) {
                        workspaces.forEach(workspace => {
                            if (workspace.id === this.currentWorkspace.id) {
                                this.currentWorkspace = CloneUtils.cloneDeep(workspace);
                            }
                        });
                    }
                });
            this.mapWorkspaceChangesSubscription = true;
        }
    }

    navigateToMapViewer(): void {
        if (this.activeRouteUrl.indexOf('wizard') > -1) {
            this.templateWizardService.templateWizardCloseStream.next(true);
        } else if (this.activeRouteUrl.indexOf('editor') > -1) {
            this.templateStoreForComponent.templateEditorCloseStream.next(true);
        } else {
            this.router.navigate(['mapViewer'], {
                queryParamsHandling: 'preserve'
            });
        }
    }

    showArchiveWorkspaceInfo(): void {
        this.showArchiveWorkspaceInfoPopup = true;
    }

    hideArchiveWorkspaceInfo(): void {
        this.showArchiveWorkspaceInfoPopup = false;
    }

    toggleIconTabs(iconTab: ApplicationTopBarIcons) {
        this.currentActiveIcon = this.currentActiveIcon === iconTab ? null : iconTab;

        switch (iconTab) {
            case ApplicationTopBarIcons.NOTIFICATION_TAB:
                this.showNotificationTabUpdate = false;
                break;
            case ApplicationTopBarIcons.ACTIVITY_TAB:
                this.showActivityTabUpdate = false;
                break;
            case ApplicationTopBarIcons.USER_TAB:
                this.showUserTabUpdate = false;
                break;
        }
    }

    toggleActivityTabUpdate(): void {
        this.showActivityTabUpdate = true;
    }

    toggleNotificationTabUpdate(): void {
        this.showNotificationTabUpdate = !this.showNotificationTabUpdate;
    }

    toggleUserTabUpdate(): void {
        this.showUserTabUpdate = !this.showUserTabUpdate;
    }
}
