import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {
    sidePanelTransitionInAnimation,
    sidePanelTransitionOutAnimation,
} from 'src/app/shared/common/animations/gsp-animations';

import { PanelEntry, SidePanelStreamsService } from './side-panel-streams.service';

export enum SidePanelName {
    SELECTED_FEATURES = 'selected_features',
    TASK_DETAIL = 'task_detail',
    EXISTING_LAYER = 'existing_layer',
    EXISTING_TEMPLATE = 'existing_template'
}

@Component({
    selector: 'side-panel',
    animations: [
        trigger('inOutAnimation', [
            transition(':enter', useAnimation(sidePanelTransitionInAnimation)),
            transition(':leave', useAnimation(sidePanelTransitionOutAnimation))
        ])
    ],
    templateUrl: './side-panel.component.html'
})
export class SidePanelComponent implements OnInit, OnDestroy {
    private readonly destroyed = new Subject<void>();
    openPanels: PanelEntry[] = [];

    // exposing enums to template
    public SidePanelName = SidePanelName;

    constructor(private sidePanelStreams: SidePanelStreamsService, private renderer: Renderer2) {}

    ngOnInit(): void {
        this.sidePanelStreams.openPanelsStream
            .pipe(takeUntil(this.destroyed), distinctUntilChanged(_.isEqual))
            .subscribe((openPanels: PanelEntry[]) => {
                if (openPanels.length === 0) {
                    this.renderer.removeClass(document.body, 'right-panel-open');
                } else {
                    this.renderer.addClass(document.body, 'right-panel-open');
                }

                this.openPanels = openPanels;
            });
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    public isAnyPanelOpen(): boolean {
        return this.sidePanelStreams.isAnyPanelOpen();
    }

    public isPanelOpen(panelName: SidePanelName): boolean {
        return this.sidePanelStreams.isPanelOpen(panelName);
    }

    public getPanel(panelName: SidePanelName): PanelEntry {
        return this.sidePanelStreams.getOpenPanel(panelName);
    }

    public getPanelParam(panelName: SidePanelName, paramName: string): any {
        let panel = this.sidePanelStreams.getOpenPanel(panelName);
        return panel ? panel.params[paramName] : null;
    }
}
