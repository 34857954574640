import { Injectable } from '@angular/core';
import { fromEvent, merge, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class KeyEventService {
    public observers: { [key: string]: Observable<unknown> } = {};
    constructor() {}

    public getObserver(queryString: string): Observable<unknown> {
        return this.observers[queryString] ? this.observers[queryString] : this.createKeyListenerObservers(queryString);
    }

    private createKeyListenerObservers(queryString: string): Observable<unknown> {
        let keyDowns = fromEvent(document.querySelector(queryString), 'keydown');
        let keyUps = fromEvent(document.querySelector(queryString), 'keyup');

        this.observers[queryString] = merge(keyDowns, keyUps).pipe(
            distinctUntilChanged((prev: KeyboardEvent, next: KeyboardEvent) => prev.key + prev.type === next.key + next.type)
        );
        return this.observers[queryString];
    }
}
