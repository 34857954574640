import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, from, takeUntil } from 'rxjs';
import { GspLoggerService } from 'src/app/log-handler.service';

@Component({
    selector: 'load-img',
    template: `
        <img
            [src]="imageToShow"
            *ngIf="!isImageLoading && showOnCondition && imageToShow; else noImageFound"
            class="actual-img {{ appendClass }}"
            (change)="changeListener($event)"
        />

        <ng-template #noImageFound>
            <img
                *ngIf="fallbackImageUrl"
                class="{{ appendClass }}"
                [src]="fallbackImageUrl"
                (change)="changeListener($event)"
            />
        </ng-template>
    `
})
export class LoadImageComponent implements OnInit, OnDestroy {
    @Input()
    public fallbackImageUrl: string;

    @Input()
    public showOnCondition: boolean;

    @Input()
    public imageUrl: string;

    @Input()
    public appendClass: boolean;

    @Input()
    public authenticate: boolean;

    public isImageLoading = true;
    imageToShow: string | ArrayBuffer;

    private destroy = new Subject();

    constructor(private httpClient: HttpClient, private logger: GspLoggerService) {}

    ngOnInit() {
        this.loadImage();
    }

    ngOnDestroy() {
        this.destroy.next(null);
    }

    public loadImage(): void {
        if (this.showOnCondition && this.imageUrl) {
            this.isImageLoading = true;

            if (this.authenticate) {
                from(this.getImage(this.imageUrl))
                    .pipe(takeUntil(this.destroy))
                    .subscribe(
                        data => {
                            this.createImageFromBlob(data);
                            this.isImageLoading = false;
                        },
                        error => {
                            this.logger.error(error);
                            this.isImageLoading = false;
                        }
                    );
            } else {
                this.imageToShow = this.imageUrl;
                this.isImageLoading = false;
            }
        }
    }

    getImage(imageUrl: string): Observable<Blob> {
        return this.httpClient.get(imageUrl, { responseType: 'blob' });
    }

    createImageFromBlob(image: Blob): void {
        let reader = new FileReader();
        reader.addEventListener(
            'load',
            () => {
                // returns base64 encoded string
                this.imageToShow = reader.result;
            },
            false
        );

        if (image) {
            reader.readAsDataURL(image);
        }
    }
}
