import { ConditionOperator } from './condition';

export class NumberSubCondition {
    constructor(
        public connectingOperator = ConditionOperator.NONE,
        public operator = ConditionOperator.NONE,
        public comparisonValue: string = null,
        public dummyConnectingOperator = ConditionOperator.NONE
    ) {}
}
