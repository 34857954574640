import * as _ from 'lodash-es';

import { GeneralUtils } from './general-utils';
import { GeometryTypes, GeometryUtils } from './geometry-utils';

export class AccuracyUtils {
    // Use once https://support.trimble.cloud/public/tickets/1a1d27c591ba07bf23ebec56a1a0a84aa7a09dd4b61f1e08073049210ffc3a38
    // is fixed for when the unitSystem is 'custom'
    static getUnitSystemFromUnits(lengthUnit: DistanceUnits): UnitSystem {
        if (
            [
                DistanceUnits.MILLIMETERS,
                DistanceUnits.CENTIMETERS,
                DistanceUnits.METERS,
                DistanceUnits.KILOMETERS
            ].includes(lengthUnit)
        ) {
            return UnitSystem.METRIC;
        } else {
            return UnitSystem.IMPERIAL;
        }
    }

    // Copied from Terraflex (DistanceConverter.cs)
    static calculateAccuracyForUnitSystem(distanceInMeters: number, unitSystem: UnitSystem): string {
        let distanceString: string;
        let distanceUnits: DistanceUnits;
        let digitsOfPrecision: number;
        if (unitSystem === UnitSystem.METRIC) {
            let distanceInCentimeters = distanceInMeters * 100;

            if (distanceInMeters === 0.0) {
                // Special case -- zero distance. Display in meters, with a default of
                // 0 sig figs, i.e. "0 m".
                distanceUnits = DistanceUnits.METERS;

                digitsOfPrecision = 0;
                distanceString = AccuracyUtils.formatNumber(distanceInMeters, digitsOfPrecision);
            } else if (Math.round(distanceInMeters) >= unitConversionDict.metersPerKilometer) {
                // Distance rounded to nearest meter is 1 km or more. Display in kilometres.
                distanceUnits = DistanceUnits.KILOMETERS;

                const distanceInKilometers = distanceInMeters / 1000.0;
                digitsOfPrecision = 2;
                distanceString = AccuracyUtils.formatNumber(distanceInKilometers, digitsOfPrecision);
            } else if (Math.round(distanceInCentimeters) >= unitConversionDict.centimetersPerMeter) {
                // Value rounded to nearest centimeter is 1 m or more. Display in metres.
                distanceUnits = DistanceUnits.METERS;

                digitsOfPrecision = 2;
                distanceString = AccuracyUtils.formatNumber(distanceInMeters, digitsOfPrecision);
            } else {
                // Display in centimeters.
                distanceUnits = DistanceUnits.CENTIMETERS;

                distanceInCentimeters = Math.round(distanceInCentimeters);
                if (distanceInCentimeters >= 10.0) {
                    // From 10cm to 99cm display 2 sig. fig. (0 d.p.).
                    digitsOfPrecision = 2;
                } else {
                    // From 0cm to 9cm display 1 sig. fig. (0 d.p.).
                    digitsOfPrecision = 1;

                    // We know that the distance we're formatting is non-zero, as we tested for
                    // zero above. So if we've rounded the value down to zero, we should set it
                    // back to 1. So, for example, a distance of 4 mm will be displayed as "1 cm"
                    // rather than "0 cm". The alternative is to support the display of millimeter
                    // units as well, but that is probably overkill for GIS applications.
                    if (distanceInCentimeters === 0.0) {
                        distanceInCentimeters = 1.0;
                    }
                }

                distanceString = AccuracyUtils.formatNumber(distanceInCentimeters, digitsOfPrecision);
            }
        } else {
            // system === UnitSystem.IMPERIAL
            const distanceInFeet = distanceInMeters * unitConversionDict.feetPerMeter;
            let distanceInInches = distanceInMeters * unitConversionDict.inchesPerMeter;

            if (distanceInFeet === 0.0) {
                // Special case -- zero distance. Display in feet, with a default of
                // 0 sig figs, i.e. "0 ft".
                distanceUnits = DistanceUnits.FEET;

                digitsOfPrecision = 0;
                distanceString = AccuracyUtils.formatNumber(distanceInFeet, digitsOfPrecision);
            } else if (Math.round(distanceInFeet) >= unitConversionDict.feetPerMile) {
                // Value rounded to nearest foot is 1 mile or more. Display in miles.
                distanceUnits = DistanceUnits.MILES;

                const distanceInMiles = distanceInMeters * unitConversionDict.milesPerMeter;
                digitsOfPrecision = 2;
                distanceString = AccuracyUtils.formatNumber(distanceInMiles, digitsOfPrecision);
            } else if (Math.round(distanceInInches) >= 36.0) {
                // Value rounded up to nearest inch is 3 ft or greater. Display in feet.
                distanceUnits = DistanceUnits.FEET;

                digitsOfPrecision = 2;
                distanceString = AccuracyUtils.formatNumber(distanceInFeet, digitsOfPrecision);
            } else {
                // Display in inches
                distanceUnits = DistanceUnits.INCHES;

                if (Math.round(distanceInInches) >= 10.0) {
                    // From 10in to 35in display 2 sig. fig. (0 d.p.).
                    distanceInInches = Math.round(distanceInInches);
                    digitsOfPrecision = 2;
                } else if (Number(distanceInInches.toFixed(1)) >= 2.0) {
                    // Round to 1dp (1.96 => 2.0, 1.94 => 1.9)
                    // From 2in to 9in display 1 sig. fig. (0 d.p.).
                    distanceInInches = Math.round(distanceInInches);
                    digitsOfPrecision = 1;
                } else {
                    // From 0.0in to 1.9in display 2 sig. fig. (1 d.p.).
                    distanceInInches = Number(distanceInInches.toFixed(1));
                    digitsOfPrecision = 2;

                    // We know that the distance we're formatting is non-zero, as we tested for
                    // zero above. So if we've rounded the value down to zero, we should set it back
                    // to 0.1. So, for example, a distance of 0.02 inches will be displayed as "0.1 in"
                    // rather than "0.0 in". The alternative is to support the display of 100ths of
                    // an inch, but that is probably overkill for GIS applications.
                    if (distanceInInches === 0.0) {
                        distanceInInches = 0.1;
                    }
                }

                distanceString = AccuracyUtils.formatNumber(distanceInInches, digitsOfPrecision);
            }
        }

        return `${distanceString} ${distanceUnits}`;
    }

    // Copied from Terraflex (DistanceConverter.cs)
    static formatNumber(value: number, significantFigures: number): string {
        // Compute a "magnitude" the represents the number of digits before the
        // decimal point. Values between 0 and 1 are assigned a magnitude of 1.
        // For example:
        //
        //  123.320   -> 3
        //   88.435   -> 2
        //   10.000   -> 2
        //    9.942   -> 1
        //    1.000   -> 1
        //    0.0123  -> 1
        //    0.00123 -> 1
        const magnitude = 1 + Math.max(0, Math.floor(Math.log10(value)));

        // The number of decimal places we want to display is equal to the desired
        // number of significant figures, minus the magnitude.
        let decimalPlaces = Math.max(0, significantFigures - magnitude);

        // Deal with rounding that might push the value into a higher magnitude;
        // e.g. 9.96 formatted to 2 significant figures should appear as 10 rather than 10.0
        const roundedValue = Number(value.toFixed(2));
        const roundedMagnitude = 1 + Math.max(0, Math.floor(Math.log10(roundedValue)));
        if (roundedMagnitude > magnitude) {
            decimalPlaces--;
        }
        // decimal places cannot be negative, add protection
        if (decimalPlaces < 0) {
            decimalPlaces = 0;
        }

        return roundedValue.toFixed(decimalPlaces);
    }

    static getRoundedHorizontalAccuracyFromMetadata(
        metadata: { [key: string]: string | number },
        geometryType: string
    ): number {
        if (metadata) {
            const { common_averageHorizontal, common_worstHorizontal } = metadata;
            const value =
                GeometryUtils.getGeometryType(geometryType) === GeometryTypes.POINT
                    ? (common_averageHorizontal as number)
                    : (common_worstHorizontal as number);

            if (!GeneralUtils.isNullUndefinedOrNaN(value)) {
                // Round to 3 decimal places as this is what the processing engine does to compare
                return _.round(value, 3);
            }
        }
    }
}

export enum UnitSystem {
    IMPERIAL = 'imperial',
    METRIC = 'metric'
}

export enum DistanceUnits {
    CENTIMETERS = 'cm',
    FEET = 'ft',
    INCHES = 'in',
    KILOMETERS = 'km',
    METERS = 'm',
    MILES = 'mi',
    MILLIMETERS = 'mm',
    YARDS = 'yd'
}

export enum DistanceUnitsExpanded {
    CENTIMETERS = 'Centimeters',
    FEET = 'Feet',
    INCHES = 'Inches',
    KILOMETERS = 'Kilometers',
    METERS = 'Meters',
    MILES = 'Miles',
    MILLIMETERS = 'Millimeters',
    YARDS = 'Yards'
}

export enum MetricUnits {
    MILLIMETERS = 'Millimeters',
    CENTIMETERS = 'Centimeters',
    METERS = 'Meters',
    KILOMETERS = 'Kilometers',
    SQUAREMETERS = 'SquareMeters',
    SQUAREKILOMETERS = 'SquareKilometers'
}

export const unitConversionDict = {
    metersPerKilometer: 1000,
    centimetersPerMeter: 100,
    feetPerMeter: 3.28084,
    inchesPerMeter: 39.3701,
    feetPerMile: 5280,
    milesPerMeter: 0.000621371
};
