<div class="condition-edit">
    <div class="editor-field-row">
        <label translate [translateParams]="{ condition: '' }">TCS.TemplateEditor.Tf.Rules.Validate.ThisField</label>
    </div>
    <div class="editor-field-row">
        <gsp-dropdown
            name="conditionPair"
            dropdownUniqueClass=""
            [options]="conditionPair"
            [(ngModel)]="condition.operator"
            idField="value"
            textField="name"
            [addIcon]="true"
            (ngModelChange)="
                condition.operator == ConditionOperator.IS_ANSWERED
                    ? (condition.errors.labelEmpty = false)
                    : condition.errors.labelEmpty
            "
        ></gsp-dropdown
        >&nbsp;
        <labeller
            name="comparisonValues"
            [maxLength]="field.length"
            [(labelEmpty)]="condition.errors.labelEmpty"
            [(labelErrors)]="condition.errors.labelErrors"
            [(ngModel)]="condition.comparisonValues"
            *ngIf="condition.operator !== ConditionOperator.IS_ANSWERED"
        ></labeller>
    </div>
</div>
