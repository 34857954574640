<div class="styles-panel">
    <div class="styles-section" *ngFor="let groupKeyAndValue of fieldList | keyvalue: useFieldOrder">
        <div class="field-list-group">
            <h4 translate="{{ groupKeyAndValue.value.groupName }}"></h4>
            <div *ngIf="groupKeyAndValue.value.collapsable" class="show-more">
                <div
                    class="link"
                    (click)="toggleCollapse()"
                    [translate]="collapsed ? 'TC.Common.MoreEllipsis' : 'TC.Common.LessEllipsis'"
                ></div>
            </div>
        </div>
        <div
            class="fields-group"
            #dropList="cdkDropList"
            cdkDropList
            cdkDropListSortingDisabled="true"
            [cdkDropListEnterPredicate]="noEntry"
            [cdkDropListConnectedTo]="connectedLists$ | async"
        >
            <ng-container *ngFor="let fieldKeyAndValue of groupKeyAndValue.value.items | keyvalue: useFieldOrder">
                <div
                    *ngIf="includeField(fieldKeyAndValue.value)"
                    class="field-tile center"
                    (dblclick)="addFieldToTemplate(fieldKeyAndValue.value)"
                    cdkDrag
                    [cdkDragData]="{
                        fieldType: fieldKeyAndValue.value.type,
                        subType: fieldKeyAndValue.value.subType,
                        representsMultipleFields: fieldKeyAndValue.value.representsMultipleFields
                    }"
                    [cdkDragDisabled]="disableField(fieldKeyAndValue.value)"
                >
                    <div class="field-icon">
                        <i class="{{ fieldKeyAndValue.value.iconClass }} i32"></i>
                    </div>
                    <div class="field-name">
                        <h5
                            translate="{{ fieldKeyAndValue.value.name }}"
                            title="{{ fieldKeyAndValue.value.name | translate }}"
                            id="{{ fieldKeyAndValue.value.type + '-label' }}"
                        ></h5>
                    </div>
                    <div class="drop-element-placeholder" *cdkDragPlaceholder></div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
