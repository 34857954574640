import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription, timer } from 'rxjs';

/*
 * Tooltip usage
 * <gsp-button title="{{'TC.Common.ImportedOn' | translate}}"
 *  tooltipLocation="bottom" type="default" text="string_id_click_me"></gsp-button>
 */

@Directive({ selector: '[title]' })
export class TooltipDirective implements OnInit, OnDestroy {
    @Input()
    public title: string = null;

    @Input()
    public tooltipShowAlways = false;

    @Input()
    public tooltipLocation = 'top';

    @Input()
    public tooltipSpeed: string;

    private loading: Subscription;
    private unload: Subscription;
    private location = 'top';
    private speedSetting = 'slow';
    private speed: number;
    private tooltipNode: { classList: { remove: any }; remove: () => void };
    private tooltipY: string;
    private tooltipX: string;

    @HostListener('mouseenter')
    onmouseEnter(): void {
        this.showTooltip();
    }

    @HostListener('mouseleave')
    onmouseleave(): void {
        this.hideTooltip();
    }

    constructor(private element: ElementRef, private render: Renderer2) {}

    ngOnInit(): void {
        this.location = this.tooltipLocation;
        this.speedSetting =
            (this.element.nativeElement.classList.contains('button-icon') ||
                this.element.nativeElement.classList.contains('button-tooltip')) &&
            !this.tooltipSpeed
                ? 'slow'
                : this.tooltipSpeed;

        switch (this.speedSetting) {
            case 'medium':
                this.speed = 500;
                break;
            case 'fast':
                this.speed = 100;
                break;
            case 'slow':
                this.speed = 1200;
                break;
            default:
                this.speed = 500;
        }
    }

    ngOnDestroy(): void {
        this.hideTooltip();
    }

    public createTooltip(tooltipText: string): any {
        let tooltip = this.render.createElement('div');
        tooltip.innerHTML = tooltipText;
        this.render.addClass(tooltip, 'tooltip-content');
        return tooltip;
    }

    public showTooltip(): void {
        this.tooltipNode = this.createTooltip(this.title);
        this.render.appendChild(this.tooltipNode, this.render.createText(''));
        this.render.appendChild(document.body, this.tooltipNode);

        let computedStyle = window.getComputedStyle(this.element.nativeElement);

        // set browser default title to empty string
        this.render.setProperty(this.element.nativeElement, 'title', '');

        // if text overflow of ellipsis is set, only show tooltip if text is overflowing
        if (
            (computedStyle.textOverflow === 'ellipsis' &&
                this.element.nativeElement.clientWidth === this.element.nativeElement.scrollWidth &&
                this.tooltipShowAlways === false) ||
            this.title === ''
        ) {
            return;
        } else {
            this.loading = timer(this.speed).subscribe(() => {
                this.render.addClass(this.tooltipNode, 'show');
                let position = this.getPosition(this.element.nativeElement, this.tooltipNode);
                this.render.addClass(this.tooltipNode, this.location);
                this.render.setStyle(this.tooltipNode, 'left', position.left);
                this.render.setStyle(this.tooltipNode, 'top', position.top);
            });

            // remove tooltip after 5 seconds - mostly to get rid of any left hanging around if
            // mouseleave event missed

            let tooltipRef = this.tooltipNode;
            this.unload = timer(5000).subscribe(() => {
                if (tooltipRef) {
                    this.render.removeChild(document.body, tooltipRef);
                }
            });
        }
    }

    public hideTooltip(): void {
        if (this.loading) {
            this.loading.unsubscribe();
        }

        if (this.unload) {
            this.unload.unsubscribe();
        }

        if (this.tooltipNode && this.tooltipNode.classList && this.tooltipNode.classList.remove) {
            this.render.removeClass(this.tooltipNode, 'show');
        }
        if (this.tooltipNode && this.tooltipNode.remove) {
            this.tooltipNode.remove();
        }
    }

    public getPosition(parentElement: HTMLElement, tooltipElement: any): { left: string; top: string } {
        let parentBounds = parentElement.getBoundingClientRect();

        let x = parentBounds.left;
        let y = parentBounds.top;

        // Get size of browser
        let browserSize = {
            width: window.innerWidth,
            height: window.innerHeight
        };

        // Get size of parent element
        let parentSize = {
            width: parentElement.offsetWidth,
            height: parentElement.offsetHeight
        };

        // centre the tooltip against parent
        let tooltipCentre = tooltipElement.offsetWidth / 2;
        let containerCentre = parentElement.offsetWidth / 2;
        let offset = containerCentre - tooltipCentre;

        // Alter position based on context and browser size
        if (x + parentSize.width > browserSize.width) {
            x = x - (x + parentSize.width - browserSize.width);
        }
        if (y + parentSize.height > browserSize.height) {
            y = y - (y + parentSize.height - browserSize.height);
        }

        if (this.location === 'top' && y - tooltipElement.offsetHeight - 5 < 0) {
            this.location = 'bottom';
        }

        switch (this.location) {
            case 'right':
                this.tooltipX = parentBounds.right + 'px';
                this.tooltipY = y - tooltipElement.offsetHeight / 2 + 20 + 'px';
                break;
            case 'left':
                this.tooltipX = x - tooltipElement.offsetWidth + 'px';
                this.tooltipY = y - tooltipElement.offsetHeight / 2 + 20 + 'px';
                break;
            case 'bottom':
                this.tooltipX = x + offset + 'px';
                this.tooltipY = y + parentSize.height + 5 + 'px';
                break;
            case 'top':
                this.tooltipX = x + offset + 'px';
                this.tooltipY = y - tooltipElement.offsetHeight - 5 + 'px';
                break;
            default:
                this.tooltipX = x + offset + 'px';
                this.tooltipY = y - tooltipElement.offsetHeight - 5 + 'px';
        }

        return {
            left: this.tooltipX,
            top: this.tooltipY
        };
    }
}
