import { Component, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { GspWizardService } from 'src/app/shared/common/components/gsp-wizard/gsp-wizard.service';
import { ModalSize } from 'src/app/shared/common/modal-sizes';

import { ImportStatusMapViewerComponent } from './import-status/import-status.component';
import { UploadComponent } from './upload/upload.component';

@Component({
    selector: 'import-files-mapviewer',
    templateUrl: './import-files.component.html'
})
export class ImportFilesMapViewerComponent {
    public ModalSize = ModalSize;

    constructor(private wizardService: GspWizardService) {
        this.wizardService.setSteps([UploadComponent, ImportStatusMapViewerComponent]);
    }

    public get steps(): Type<any>[] {
        return this.wizardService.steps;
    }

    public get currentStep$(): Observable<Type<any>> {
        return this.wizardService.currentStep$;
    }

    public backward(): void {
        try {
            this.wizardService.moveBackward().then(() => {
                this.wizardService.reset();
            });
        } catch (e) {
            throw e;
        }
    }

    public forward(): void {
        try {
            this.wizardService.moveForward().then(() => {
                this.wizardService.reset();
            });
        } catch (e) {
            throw e;
        }
    }

    public exit(): void {
        this.wizardService.onClose();
    }

    public cancel(): void {
        this.wizardService.onCancel();
    }
}
