<section class="map-cache-list" *ngIf="mapCacheList.length">
    <div class="map-cache-list-header">
        <h4>{{ 'MapViewer.MapCaches.Header.Label' | translate }}</h4>
        <div class="mapcache-view-all-toggle" [ngClass]="{ hide_mapcache: !visibleAll }" (click)="toggleAll()">
            <i
                class="i24"
                [ngClass]="{ icon_line_visibility_show: visibleAll, icon_line_visibility_hide: !visibleAll }"
            ></i>
        </div>
    </div>
    <ul class="list-content">
        <ng-container *ngIf="mapCacheList.length">
            <li
                class="list-item"
                *ngFor="let mapCache of mapCacheList; index as i"
                (contextmenu)="mapCacheContextMenu.onContextMenu($event, mapCache)"
            >
                <div class="layer-sym">
                    <i
                        class="map-cache-icon i24"
                        [ngClass]="{
                            'trimble-map-icon': mapCache.baseMapStyle === MapCacheStyle.STREET_VIEW,
                            'trimble-digital-globe-icon': mapCache.baseMapStyle === MapCacheStyle.SATELLITE_VIEW
                        }"
                    ></i>
                </div>
                <div class="layer-title" [title]="mapCache.name">
                    <span>{{ mapCache.name }}</span>
                </div>
                <div class="layer-view" [ngClass]="{ hide_layer: !mapCache.visible }">
                    <i
                        class="i24"
                        [ngClass]="{
                            icon_line_visibility_show: mapCache.visible,
                            icon_line_visibility_hide: !mapCache.visible
                        }"
                        (click)="toggle(mapCache, i)"
                    ></i>
                </div>
            </li>
        </ng-container>
    </ul>

    <gsp-context-menu #mapCacheContextMenu [items]="contextMenuItems"> </gsp-context-menu>
</section>
