import 'src/app/shared/leaflet-extensions/TileLayerGeoJSON/TileLayerGeoJSON.js';

import * as L from 'leaflet';
import { environment } from 'src/environments/environment';

import { BaseMapMode } from './base-map-mode';

export interface BaseMapProvider {
    id: string;
    name: string;
    mapServiceNames: string[];
    baseMapModes: BaseMapMode[];
    createBaseMapLayer(mapMode: BaseMapMode): L.TileLayer;
}

export enum TrimbleBaseMapMode {
    MAP = 'map',
    MAP_DARKNESS = 'mapDarkness',
    MAP_LIGHTNESS = 'mapLightness',
    MAP_TERRAIN = 'mapTerrain',
    DIGITAL_GLOBE = 'digitalGlobe'
}

export class TrimbleBaseMapProvider {
    id = 'trimble';
    name = 'Trimble';
    mapServiceNames = ['Trimble Mapview', 'DigitalGlobe Imagery'];

    // get updated/completed from the Trimble Data Services API response
    baseMapModes: BaseMapMode[] = [
        {
            id: TrimbleBaseMapMode.MAP,
            label: 'MapViewer.BaseMap.Street.Label',
            externalName: 'Trimble Mapview',
            name: 'Map',
            data: '',
            maxNativeZoom: 20,
            minZoom: 1,
            maxZoom: 22,
            imageUrl: 'assets/images/maps/trimble-map.jpg',
            selectedIconClass: 'trimble-map-icon',
            attribution: '<a href="https://maps.trimble.com/copyrights/" target="_blank">Trimble Maps</a>',
            tileUrl: environment.mapTileUrl,
            tileSize: 256,
            zoomOffset: 0,
            subDomains: []
        },
        {
            id: TrimbleBaseMapMode.MAP_LIGHTNESS,
            label: 'MapViewer.BaseMap.StreetLight.Label',
            externalName: 'Trimble Mapview',
            name: 'Map Light',
            data: '',
            maxNativeZoom: 20,
            minZoom: 1,
            maxZoom: 22,
            imageUrl: 'assets/images/maps/trimble-map.jpg',
            selectedIconClass: 'trimble-map-icon',
            attribution: '<a href="https://maps.trimble.com/copyrights/" target="_blank">Trimble Maps</a>',
            tileUrl: `${environment.mapTileUrl}&style=lightness`,
            tileSize: 256,
            zoomOffset: 0,
            subDomains: []
        },
        {
            id: TrimbleBaseMapMode.MAP_DARKNESS,
            label: 'MapViewer.BaseMap.StreetDark.Label',
            externalName: 'Trimble Mapview',
            name: 'Map Dark',
            data: '',
            maxNativeZoom: 20,
            minZoom: 1,
            maxZoom: 22,
            imageUrl: 'assets/images/maps/trimble-map.jpg',
            selectedIconClass: 'trimble-map-icon',
            attribution: '<a href="https://maps.trimble.com/copyrights/" target="_blank">Trimble Maps</a>',
            tileUrl: `${environment.mapTileUrl}&style=darkness`,
            tileSize: 256,
            zoomOffset: 0,
            subDomains: []
        },
        {
            id: TrimbleBaseMapMode.MAP_TERRAIN,
            label: 'TC.Common.Terrain',
            externalName: 'Trimble Mapview',
            name: 'Map Terrain',
            data: '',
            maxNativeZoom: 20,
            minZoom: 1,
            maxZoom: 22,
            imageUrl: 'assets/images/maps/trimble-map.jpg',
            selectedIconClass: 'trimble-map-icon',
            attribution: '<a href="https://maps.trimble.com/copyrights/" target="_blank">Trimble Maps</a>',
            tileUrl: `${environment.mapTileUrl}&style=terrain`,
            tileSize: 256,
            zoomOffset: 0,
            subDomains: []
        },
        {
            id: TrimbleBaseMapMode.DIGITAL_GLOBE,
            label: 'TC.Common.Satellite',
            externalName: 'DigitalGlobe Imagery',
            name: 'Satellite',
            data: '',
            maxNativeZoom: 20,
            minZoom: 1,
            maxZoom: 22,
            imageUrl: 'assets/images/maps/trimble-digital-globe.jpg',
            selectedIconClass: 'trimble-digital-globe-icon',
            attribution: '<a href="https://maps.trimble.com/copyrights/" target="_blank">Trimble Maps</a>',
            tileUrl: `${environment.mapTileUrl}&style=satellite&imgsrc=sat2`,
            tileSize: 256,
            zoomOffset: 0,
            subDomains: []
        }
    ];

    public createBaseMapLayer(mapMode: BaseMapMode): L.TileLayer {
        // TODO: TrimbleData parallel subdomains is incompatible with leaflet because of differing Signature param per subdomain URL
        return new L.TileLayer(mapMode.tileUrl, {
            subdomains: mapMode.subDomains,
            attribution: mapMode.attribution,
            maxZoom: mapMode.maxZoom,
            minZoom: mapMode.minZoom,
            maxNativeZoom: mapMode.maxNativeZoom,
            tileSize: mapMode.tileSize,
            zoomOffset: mapMode.zoomOffset,
            zIndex: -9999998
        });
    }
}
