import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UtilitiesService {
    public canChange = true;

    constructor(private titleService: Title) {}

    static getNewName(
        pattern: string,
        nameList: string[],
        caseInsensitive = false,
        increaseSequenceBy?: number,
        incrementWithSpace = true
    ): string {
        const separator = incrementWithSpace ? ' ' : '';
        let filteredList = nameList.filter(name => {
            name = caseInsensitive ? name.toLowerCase() : name;
            return (
                name &&
                name.indexOf(pattern) !== -1 &&
                (name.trim() === pattern || !isNaN(parseInt(name.substr(pattern.length), 10)))
            );
        });
        let indices = filteredList.map((name: any) => {
            if (!isNaN(parseInt(name.substr(pattern.length), 10))) {
                return parseInt(name.substr(pattern.length), 10);
            } else {
                return 0;
            }
        });
        indices.sort((a, b) => a - b);
        let returnIndex = indices.length ? indices[indices.length - 1] : 0;

        if (!filteredList.length) {
            return increaseSequenceBy ? pattern + separator + (increaseSequenceBy + 1) : pattern;
        } else if (returnIndex === 0) {
            return pattern + separator + (returnIndex + 2 + (increaseSequenceBy || 0));
        } else {
            return pattern + separator + (returnIndex + 1 + (increaseSequenceBy || 0));
        }
    }

    setTitle(title: string, _canChange: boolean): void {
        if (this.canChange) {
            this.titleService.setTitle('Map Viewer - ' + title);
        }

        if (_canChange !== undefined) {
            this.canChange = _canChange;
        }
    }

    getTCAppUrl(): string {
        return environment.connectMasterUrl + '/tc/app#/';
    }

    buildQueryFromObject(object: { 'request.Category'?: string; 'request.Subcategory'?: string }): string {
        let query = '';
        if (Object.keys(object).length) {
            let queryStrings: string[] = [];
            let queryNumber = 0;
            // eslint-disable-next-line guard-for-in
            for (let queryKey in object) {
                queryStrings[queryNumber++] =
                    queryKey +
                    '=' +
                    object[queryKey as keyof { 'request.Category'?: string; 'request.Subcategory'?: string }];
            }
            query = queryStrings.join('&');
        }
        return query;
    }
}
