import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessagingService } from 'src/app/core/messaging/messaging.service';

import { MapWorkspacesStoreService } from '../../current-map-workspaces/map-workspaces-store.service';
import { ButtonType } from '../buttons/button';
import { GspAnnouncementBannerService, GspAnnouncementStatus } from './gsp-announcement-banner.service';

interface Announcement {
    announcementId: string;
    announcementContent: string;
    announcementLink: string;
    announcementStatus: GspAnnouncementStatus;
}

@Component({
    selector: 'gsp-announcement-banner',
    templateUrl: './gsp-announcement-banner.component.html'
})
export class GspAnnouncementBannerComponent implements OnInit, AfterViewChecked {
    ButtonType = ButtonType;
    announcements: Announcement[] = null;

    @ViewChild('announcementBanner', { read: ElementRef })
    announcementBannerContainer: ElementRef;

    constructor(
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private announcementBannerService: GspAnnouncementBannerService,
        private messaging: MessagingService,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.announcementBannerService.getAnnouncements().then(announcements => {
            this.announcements = announcements
                .map(res => ({
                    announcementId: res.announcement.id,
                    announcementContent:
                        res.announcement.properties[this.translateService.currentLang] || res.announcement.content,
                    announcementLink: res.announcement.url,
                    announcementStatus: res.tracking ? res.tracking.status : null
                }))
                // Only show announcements without any tracking available
                .filter(announcement => announcement.announcementStatus === null);
        });
    }

    ngAfterViewChecked(): void {
        this.updateToastOffset();
    }

    public dismissAnnouncement(announcementId: string): void {
        this.announcements = this.announcements.filter(notification => notification.announcementId !== announcementId);
        // only track user action for non shared workspaces
        if (!this.mapWorkspacesStore.currentMapWorkspaceStream.getValue().isPubliclySharedMapWorkspace) {
            this.announcementBannerService.createAnnouncementTracking(announcementId, GspAnnouncementStatus.DISMISSED);
        }
        this.updateToastOffset();
    }

    public goToLink(url: string): void {
        window.open(url, '_blank');
    }

    public updateToastOffset(): void {
        // offset announcement banner height for toast notifications
        if (this.announcementBannerContainer) {
            this.messaging.setToastVerticalOffset(this.announcementBannerContainer.nativeElement.offsetHeight);
        }
    }
}
