import { Injectable } from '@angular/core';

import { MapWorkspace } from '../../map-data-services/mapWorkspace/map-workspace';
import { MapWorkspacePermissionType } from '../../map-data-services/mapWorkspace/map-workspace-permission';

@Injectable()
export class CheckVisibilityService {
    public restrictAccess(visibleModes: MapWorkspacePermissionType[], mapWorkspace: MapWorkspace): boolean {
        return visibleModes && visibleModes.indexOf(mapWorkspace.permission) === -1;
    }
}
