import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpStatusCodes } from 'src/app/shared/common/http-status-codes';

import { ExportDownloaderService } from './export-downloader.service';

@Component({
    selector: 'export-downloader',
    templateUrl: './export-downloader.component.html'
})
export class ExportDownloaderComponent implements OnInit {
    loading = true;
    downloadIsAvailable = false;
    downloadHasExpired = false;
    downloadHasError = false;
    permissionDenied = false;
    projectId: string = null;
    exportId: string = null;

    constructor(
        private exportDownloaderService: ExportDownloaderService,
        private renderer: Renderer2,
        private activatedRoute: ActivatedRoute
    ) {}

    downloadExportedData(): void {
        this.exportDownloaderService
            .getExport(this.projectId, this.exportId)
            .then((response: { status: number; downloadLink: string }) => {
                this.loading = false;
                if (response.status === HttpStatusCodes.OK && response.downloadLink) {
                    this.downloadIsAvailable = true;
                    window.location.href = response.downloadLink;
                } else {
                    this.downloadHasError = true;
                }
            })
            .catch(response => {
                this.loading = false;
                if (response.status === 404) {
                    this.downloadHasExpired = true;
                } else if (response.status === HttpStatusCodes.FORBIDDEN) {
                    this.permissionDenied = true;
                } else {
                    this.downloadHasError = true;
                }
            });
    }

    ngOnInit(): void {
        this.exportId = this.activatedRoute.snapshot.params.exportId;
        this.projectId = this.activatedRoute.snapshot.params.projectId;
        this.renderer.addClass(document.body, 'download');
        this.loading = true;
        this.downloadIsAvailable = false;
        this.downloadHasExpired = false;
        this.downloadHasError = false;
        this.permissionDenied = false;
        this.downloadExportedData();
    }
}
