import { Location } from '@angular/common';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, timeout } from 'rxjs/operators';
import { MapService } from 'src/app/feature/map-viewer/map.service';
import { GspLoggerService } from 'src/app/log-handler.service';
import { EnvironmentService } from 'src/app/shared/common/environment/environment.service';
import { HttpStatusCodes } from 'src/app/shared/common/http-status-codes';
import { environment } from 'src/environments/environment';

import { ErrorPageErrorCodes, HttpErrorCodes } from '../error-page/error-page.component';
import {
    ACCESS_TOKEN_KEY,
    AuthenticationService,
    LAST_TID_REDIRECT_KEY,
    TOKEN_REVOKED_KEY
} from './authentication.service';
import { ACCESS_MODE_KEY, AccessMode, SHARED_MODE_KEY, SHARED_TOKEN_KEY, ShareMode } from './share-token';

const nonCriticalPaths = ['/announcements']; // Non-critical service url's
const getPaths = [
    '/deltas',
    '/styles',
    '/relationships',
    '/mapcaches',
    '/forms',
    '/fields',
    '/formexports',
    '/dataupdatejobs',
    '/returns',
    '/templates/_query',
    '/layers'
];

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
    private requestTracking = {
        totalRequestSent: 0,
        totalResponseReceived: 0
    };
    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private cachedResponses: Map<string, string> = new Map();

    constructor(
        private environmentService: EnvironmentService,
        private router: Router,
        private location: Location,
        private authenticationService: AuthenticationService,
        private mapService: MapService,
        private logger: GspLoggerService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.headers.has('NoIntercept')) {
            request = request.clone({ headers: request.headers.delete('NoIntercept') });
            return next.handle(request);
        }

        // When app config settings are not loaded yet.
        if (!environment) {
            return next.handle(request).pipe(
                map((event: HttpEvent<any>) => event),
                catchError((error: HttpErrorResponse) => throwError(error))
            );
        }

        const sessionEntry = this.cachedResponses.get(request.urlWithParams);
        if (sessionEntry) {
            const data = JSON.parse(sessionEntry);
            if (data && data.eTag) {
                request = request.clone({ headers: request.headers.set('If-None-Match', '"' + data.eTag + '"') });
            }
        }

        if (undefined !== request.url.endsWith) {
            const pathExist = getPaths.filter(
                path => request.urlWithParams.endsWith(path) || request.urlWithParams.indexOf(path + '?') > 0
            );
            if (pathExist.length && !request.headers.get('Range')) {
                request = request.clone({ headers: request.headers.set('Range', 'items=0-') });
            }
        }

        this.requestTracking.totalRequestSent++;

        const { mapsDomain, connectDomain, processingOrchestratorUrl, featureApiUrl } = this.environmentService;
        const isMapsDomain = request.url.includes(mapsDomain);
        const isConnectUrl = request.url.includes(connectDomain);
        const isProcessingOrchestratorUrl = request.url.includes(processingOrchestratorUrl);
        const isLocalhost = request.url.includes('localhost');
        const isFeaturesUrl = request.url.includes(featureApiUrl);

        if (isMapsDomain || isConnectUrl || isProcessingOrchestratorUrl || isLocalhost) {
            if (isConnectUrl) {
                request = request.clone({
                    headers: request.headers.set('X-TrimbleConnect-Client', 'MapViewer Web Application')
                });
            }

            // Shared Mode
            if (sessionStorage.getItem(ACCESS_MODE_KEY) === AccessMode.SHARED) {
                if (isMapsDomain) {
                    request = request.clone({
                        headers: request.headers.set('X-SharedToken', sessionStorage.getItem(SHARED_TOKEN_KEY))
                    });

                    if (sessionStorage.getItem(SHARED_MODE_KEY) === ShareMode.PUBLIC_USER) {
                        if (isFeaturesUrl) {
                            request = this.addAuthToken(request, sessionStorage.getItem(SHARED_TOKEN_KEY));
                        } else {
                            request = this.addAuthToken(request, sessionStorage.getItem('tcAccessToken'));
                        }
                    }
                } else if (
                    sessionStorage.getItem(SHARED_MODE_KEY) === ShareMode.PUBLIC_USER ||
                    sessionStorage.getItem('tcAccessToken')
                ) {
                    request = this.addAuthToken(request, sessionStorage.getItem('tcAccessToken'));
                }

                if (
                    (sessionStorage.getItem(SHARED_MODE_KEY) === ShareMode.SIGNED_IN_USER ||
                        sessionStorage.getItem(SHARED_MODE_KEY) === ShareMode.PROJECT_USER) &&
                    sessionStorage.getItem(ACCESS_TOKEN_KEY)
                ) {
                    const tokenRefreshedOrInProgress$ = this.checkForTokenRefresh(request, next);
                    if (tokenRefreshedOrInProgress$) {
                        return tokenRefreshedOrInProgress$;
                    }
                    request = this.addAuthToken(request);
                }
            } else {
                // Normal mode

                if (sessionStorage.getItem(ACCESS_TOKEN_KEY)) {
                    const tokenRefreshedOrInProgress$ = this.checkForTokenRefresh(request, next);
                    if (tokenRefreshedOrInProgress$) {
                        return tokenRefreshedOrInProgress$;
                    }
                    request = this.addAuthToken(request);
                }
            }
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    const response = event;
                    this.requestTracking.totalResponseReceived++;

                    if (
                        response.body &&
                        (response.body.length > 1 || response.body.total > 1) &&
                        typeof response.body !== 'string' &&
                        request.urlWithParams.indexOf('layers?') === -1 &&
                        request.url.indexOf('features') === -1 &&
                        request.url.indexOf('realtimecorrections') === -1 &&
                        request.url.indexOf('templates') === -1 &&
                        request.url.indexOf('symbols') === -1
                    ) {
                        this.cachedResponses.set(request.urlWithParams, JSON.stringify(response.body));
                    }

                    if (
                        sessionStorage.getItem('ecom_error') &&
                        request.method === 'POST' &&
                        (request.url.indexOf('/spatialworkspaces') !== -1 || request.url.indexOf('/files') !== -1)
                    ) {
                        if (request.url.indexOf('/spatialworkspaces') !== -1) {
                            event = event.clone({ status: HttpStatusCodes.FORBIDDEN });
                            event = event.clone({
                                body: event.body.set('errors', [
                                    {
                                        errorCode: 'TC_WORKSPACE_CREATION_FAILED_EXCEEDED_STORAGE_LIMIT',
                                        errorMessage:
                                            // eslint-disable-next-line max-len
                                            'You have reached your Trimble Connect storage limit. Upgrade your account to add additional data.'
                                    }
                                ])
                            });
                        } else {
                            event = event.clone({ status: HttpStatusCodes.FORBIDDEN });
                            event = event.clone({
                                body: event.body.set(
                                    'message',
                                    'Storage is maxed out. Please upgrade your account to unlock all the features of Trimble Connect.'
                                )
                            });
                            event = event.clone({ body: event.body.set('errorCode', 'UNAUTHORIZED_LICENSE') });
                        }
                    }
                    if (response.status === 206) {
                        event = event.clone({ body: event.body.content || event.body });
                    } else if (response.status === 204) {
                        event = event.clone({ body: { total: 0, items: [] } });
                    }

                    this.requestTracking.totalResponseReceived++;
                }

                return event;
            }),

            catchError((error: HttpErrorResponse) => {
                // check if the error url is non-critical url and skip other checks if it's the case
                const isNonCriticalUrl = nonCriticalPaths.find(path => error.url.indexOf(path) > -1);
                if (isNonCriticalUrl) {
                    return throwError(error);
                }

                if (error.status === HttpStatusCodes.NOT_MODIFIED) {
                    const response = new HttpResponse({
                        status: HttpStatusCodes.OK,
                        statusText: 'ok',
                        body: JSON.parse(this.cachedResponses.get(request.urlWithParams))
                    });
                    return of(response);
                }

                if (error.status === HttpStatusCodes.UNAUTHORISED) {
                    return from(this.checkAndRedirectToLogin()).pipe(switchMap(() => throwError(error)));
                }

                this.logger.error(error.error);

                if (
                    this.location.path().indexOf('export') > -1 &&
                    this.location.path().indexOf('download') > -1 &&
                    error.url.indexOf(this.environmentService.connectDomain) > 0
                ) {
                    this.router.navigate(['error', ErrorPageErrorCodes.USER_NOT_IN_PROJECT]);
                } else if (
                    (error.status === HttpStatusCodes.SERVER_UNAVAILABLE ||
                        error.status === HttpStatusCodes.INTERNAL_ERROR) &&
                    error.url.indexOf(environment.connectMasterUrl) !== -1
                ) {
                    this.router.navigate(['error', error.status]);
                }

                if (error.url.indexOf('?fullyLoaded') === -1 && error.status !== HttpStatusCodes.NOT_FOUND) {
                    this.logger.error(error.error);
                }

                return throwError(error);
            })
        );
    }

    private addAuthToken(request: HttpRequest<any>, token?: string): HttpRequest<any> {
        return request.clone({
            headers: request.headers.set(
                'Authorization',
                'Bearer ' + (token || sessionStorage.getItem(ACCESS_TOKEN_KEY))
            )
        });
    }

    private async checkAndRedirectToLogin(): Promise<void> {
        // Threshold of 10 seconds to prevent login loop
        if (Date.now() - Number(window.sessionStorage.getItem(LAST_TID_REDIRECT_KEY)) > 10000) {
            window.sessionStorage.removeItem(ACCESS_TOKEN_KEY);
            window.location.href = await this.authenticationService.getLoginUrl();
        } else {
            await this.router.navigate(['error', HttpErrorCodes.UNAUTHORISED]);
        }
    }

    private checkForTokenRefresh(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Adapted from https://itnext.io/angular-tutorial-implement-refresh-token-with-httpinterceptor-bfa27b966f57
        if (this.refreshTokenInProgress) {
            // Wait until new refresh token before retrying request
            return this.refreshTokenSubject.pipe(
                filter(res => res !== null),
                take(1),
                switchMap(() => next.handle(this.addAuthToken(request)))
            );
        } else {
            if (this.authenticationService.shouldRefreshTokens()) {
                this.refreshTokenInProgress = true;
                this.refreshTokenSubject.next(null);
                return from(this.authenticationService.refreshTokens()).pipe(
                    timeout(60000),
                    switchMap(tokenResponse => {
                        this.authenticationService.login(
                            tokenResponse.access_token,
                            new Date().getTime() + tokenResponse.expires_in * 1000,
                            tokenResponse.refresh_token
                        );
                        window.sessionStorage.removeItem(TOKEN_REVOKED_KEY);
                        this.refreshTokenInProgress = false;
                        this.refreshTokenSubject.next(tokenResponse.refresh_token);
                        return next.handle(this.addAuthToken(request));
                    }),
                    catchError((err: HttpErrorResponse) => {
                        this.refreshTokenInProgress = false;
                        return from(this.checkAndRedirectToLogin()).pipe(switchMap(() => throwError(err)));
                    })
                );
            }
        }
    }
}
