<span class="button-confirm-wrapper">
    <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="ButtonType.Icon || ButtonType.Done">
            <button
                #button
                type="button"
                (click)="onClick($event)"
                [disabled]="disabled"
                class="button-main button button-tooltip {{ buttonCssClass }}"
                [ngClass]="{ 'in-progress': showBusy || showBusyIndicator, 'button-icon': iconClass.length }"
                [title]="buttonTooltip"
            >
                <i *ngIf="iconClass.length" [class]="iconClass"></i>
            </button>
        </ng-container>

        <ng-container *ngSwitchCase="ButtonType.Link">
            <button
                #button
                type="button"
                (click)="onClick($event)"
                [disabled]="disabled"
                class="btn {{ buttonCssClass }}"
                [ngClass]="{ 'in-progress': showBusy || showBusyIndicator }"
                [title]="buttonTooltip"
            >
                <span> {{ text }}</span>
            </button>
        </ng-container>

        <ng-container *ngSwitchDefault>
            <span class="button-container">
                <button
                    #button
                    type="button"
                    (click)="onClick($event)"
                    [disabled]="disabled"
                    class="button-main button button-tooltip {{ buttonCssClass }}"
                    [ngClass]="{
                        'in-progress': showBusy || showBusyIndicator,
                        'button-icon': iconClass.length,
                        'button-icon-text': text.length
                    }"
                    [title]="buttonTooltip"
                >
                    <span class="button-text"> {{ text }}</span>
                    <i *ngIf="iconClass.length" [class]="iconClass"></i>
                    <ng-content select="i"></ng-content>
                </button>
            </span>
        </ng-container>
    </ng-container>
</span>
