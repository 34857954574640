import { Component, Input } from '@angular/core';
import { Field } from 'src/app/shared/template-services/field';

@Component({
    selector: 'property-icons',
    templateUrl: './property-icons.component.html'
})
export class PropertyIconsComponent {
    @Input() field: Field;
}
