<header class="modal-header">
    <h2 class="modal-title" *ngIf="heading"><span translate="{{ heading }}"></span></h2>
    <gsp-button
        [type]="ButtonType.Icon"
        (action)="onClose()"
        [disabled]="!(state$ | async).closeButton.enabled"
        [hidden]="!(state$ | async).closeButton.visible"
        [iconClass]="'icon-ModelClose'"
        id="wizard-header-close"
    ></gsp-button>
</header>
