import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { EnvironmentService } from 'src/app/shared/common/environment/environment.service';

import { GeoUserProfiles, User, UserDTO, UserRole } from './user';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(private http: HttpClient, private env: EnvironmentService) {}

    getProjectUsers(
        projectId: string,
        startIndex?: number,
        pageSize?: number
    ): Promise<{ items: User[]; total: number }> {
        startIndex = startIndex || 0;

        let endIndex = '';
        if (pageSize) {
            endIndex = (startIndex + (pageSize - 1)).toString();
        }

        const path = this.env.apiUrl + '/projects/' + projectId + '/users';

        const headers = {
            headers: {
                Range: 'items=' + startIndex + '-' + endIndex
            }
        };

        return lastValueFrom(this.http.get<GeoUserProfiles>(path, headers)).then(
            response => ({
                items: response.items.map(user => User.fromDTO(user)),
                total: response.total
            }),
            () => ({
                items: [],
                total: 0
            })
        );
    }

    async getProjectUser(projectId: string, userId: string, tiduuid?: string): Promise<User> {
        let projectUser = await this.getProjectUsers(projectId).then(users =>
            users.items.find(user => user.id === userId)
        );

        if (tiduuid) {
            projectUser.tiduuid = tiduuid;
        }

        return projectUser;
    }

    // get a connect user who may not be part of the project
    getConnectUser(userId: string): Promise<User> {
        const path = this.env.connectApiUrl + '/users/' + userId;

        return lastValueFrom(this.http.get<UserDTO>(path)).then(user => User.fromDTO(user));
    }

    // invite an user to the project
    inviteUserToProject(projectId: string, email: string, role: UserRole): Promise<User> {
        const path = this.env.connectApiUrl + '/projects/' + projectId + '/users';

        const body = {
            email,
            role
        };
        return lastValueFrom(this.http.post<UserDTO>(path, body)).then(user => User.fromDTO(user));
    }

    // get current user details
    getConnectUserByMe(): Promise<User> {
        const path = this.env.connectApiUrl + '/users/me';

        return lastValueFrom(this.http.get<UserDTO>(path)).then(user => User.fromDTO(user));
    }
}
