import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ButtonType } from '../buttons/button';
import { GspWizardService } from './gsp-wizard.service';

@Component({
    selector: 'gsp-wizard-header',
    templateUrl: './gsp-wizard-header.component.html'
})
export class GspWizardHeaderComponent {
    @Input()
    public heading = '';

    @Output() closed = new EventEmitter();

    // expose enum to template
    public ButtonType = ButtonType;

    public state$ = this.wizardService.uiState$;

    constructor(private wizardService: GspWizardService) {}

    public onClose(): void {
        this.closed.emit();
    }
}
