<div class="modal-popup">
    <div class="modal-content edit-layer-panel">
        <ng-form #editLayerForm="ngForm">
            <header class="modal-header">
                <h2 class="modal-title">
                    <span>{{ 'TCS.Mapviewer.LayerContextMenu.EditLayer' | translate }}</span>
                </h2>
                <gsp-button
                    [type]="ButtonType.Icon"
                    (action)="closeEditPanel()"
                    [iconClass]="'icon-ModelClose'"
                ></gsp-button>
            </header>

            <div class="modal-body" *ngIf="!loading">
                <div class="modal-group edit-layer-form">
                    <div class="edit-layer-form-group">
                        <div class="edit-layer-form-group-label" translate="name"></div>
                        <div class="edit-layer-form-group-input layer-name">
                            <gsp-text
                                #name="ngModel"
                                [type]="GspTextType.GENERIC"
                                [id]="'edit-layer-name'"
                                inputClass="gsp-form-input"
                                name="layerName"
                                placeholder="{{ 'TCS.Layer.EnterLayerName' | translate }}"
                                [(ngModel)]="layer.layerName"
                                length="44"
                                required="true"
                                errorTooltipMsg="{{ getErrorText(name.errors) }}"
                                focusElement="true"
                            ></gsp-text>
                        </div>
                    </div>

                    <!--div class="form-element">
                        <label class="option-label" translate="type"></label>
                        <div class="input-container">
                            <gsp-dropdown ng-if="!layer.templatePublishId" options="geoTypes" selected="layer.geometryType" data-id-field="name" data-text-field="label" add-icon='true'></gsp-dropdown>
                            <div ng-if="layer.templatePublishId">{{layer.geometryType}}</div>
                        </div>
                    </div -->
                    <div class="edit-layer-form-group">
                        <label class="edit-layer-form-group-label" translate="TC.Common.Color"></label>
                        <div class="edit-layer-form-group-input">
                            <gsp-color-picker
                                name="color"
                                #color="ngModel"
                                [colorHexCodes]="availableColors"
                                colorPickerType="default"
                                [(ngModel)]="layer.color"
                                selectedColor="layer.color"
                                [iconSize]="26"
                                alignBy="vertical"
                                [maxRows]="3"
                                sortBy="hue"
                                groupBy="hue"
                                [groupLimit]="3"
                            >
                            </gsp-color-picker>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body" *ngIf="loading">
                <loading></loading>
            </div>

            <div class="modal-footer">
                <gsp-button
                    [type]="ButtonType.Primary"
                    (action)="saveLayer()"
                    [disabled]="editLayerForm.invalid || isSaveDisabled()"
                    text="{{ 'save' | translate }}"
                ></gsp-button>
                <gsp-button
                    [type]="ButtonType.Default"
                    (action)="closeEditPanel()"
                    text="{{ 'cancel' | translate }}"
                ></gsp-button>
            </div>
        </ng-form>
    </div>
</div>
