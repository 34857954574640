import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'gsp-file-drop',
    templateUrl: './gsp-file-drop.component.html'
})
export class GspFileDropComponent {
    @Input() maxFileCount: number = 20;
    @Input() allowMultipleFiles: boolean = true;

    // Optional inputs
    @Input() height: string = '205px';
    @Input() width: string = '510px';
    @Input() fileDropDescription: string = '';
    @Input() fileDropLabel: string = '';
    @Input() acceptedFileTypes: string = ''; // Comma separated string of file types Eg. '.txt,.doc,.wsdef'

    @Output() fileDropEvent = new EventEmitter<File[]>();
    @Output() fileDropError = new EventEmitter<string>();

    public showDropzone = true;

    public fileDropped(event: CustomEvent): void {
        // event.detail[1] contains any errors
        if (!event.detail[1]) {
            const droppedFiles = event.detail[0];
            this.fileDropEvent.emit(droppedFiles);
        } else {
            this.fileDropError.emit(event.detail[1]);
            this.resetDropzone();
        }
    }

    public resetDropzone(): void {
        // Workaround for github.com/trimble-oss/modus-web-components/issues/851. Remove this when fixed.
        // Dropzone does not reset after each drop. This will reset the dropzone after each drop.
        setTimeout(() => {
            this.showDropzone = false;
            setTimeout(() => {
                this.showDropzone = true;
            }, 0);
        }, 1500);
    }
}
