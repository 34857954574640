import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { CachedFeatureService } from 'src/app/shared/map-data-services/feature/cached-feature.service';
import { Feature } from 'src/app/shared/map-data-services/feature/feature';
import { FeatureService } from 'src/app/shared/map-data-services/feature/feature.service';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';
import { LayerService } from 'src/app/shared/map-data-services/layer/layer.service';
import { StyleService } from 'src/app/shared/map-data-services/styles/style.service';

import { LayersStore } from '../current-layers/layers-store.service';
import { LayersStreams } from '../current-layers/layers-streams.service';
import { ProjectStreamService } from '../current-project/project-stream.service';
import { FullFeatureFilter } from '../feature-filter/full-feature-filter';
import { FormStatus, Task, TaskFeature } from '../task/task';
import { FeatureFilterStreamService } from './feature-filter-stream.service';
import { FeatureSet } from './feature-set';
import { FeatureSetFactoryService } from './feature-set-factory.service';
import { TodoSelectionCriteria } from './selection-criteria';

@Injectable({
    providedIn: 'root'
})
export class TaskFeaturesStreamsService {
    showDataLayersStream = new BehaviorSubject<boolean>(true);
    showTaskFeaturesStreams = new BehaviorSubject<Feature[]>([]);
    hideTaskFeaturesStreams = new BehaviorSubject<Feature[]>([]);

    visibleFeatureSet: FeatureSet;

    constructor(
        private featureService: FeatureService,
        featureSetFactory: FeatureSetFactoryService,
        private cachedFeatureService: CachedFeatureService,
        featureFilterStream: FeatureFilterStreamService,
        private projectStream: ProjectStreamService,
        layersStreams: LayersStreams,
        private layersStore: LayersStore,
        private layerService: LayerService,
        private styleService: StyleService
    ) {
        // the features of the currently visible tasks
        this.visibleFeatureSet = featureSetFactory.createFeatureSet('visible task features', true);

        layersStreams.mapWorkspaceLayersStream.subscribe(layers => {
            this.visibleFeatureSet.modifyFilterLayers(layers);
            // effectively no filtering as tasks may contain features from any workspace layer
        });

        featureFilterStream.activeFilterStream.subscribe(featureFilter => {
            this.visibleFeatureSet.modifyFilters(featureFilter);
        });
        // -------
    }

    public showDataLayers(show: boolean): void {
        this.showDataLayersStream.next(show);
    }

    public getFeaturesByTask(task: Task): Promise<Feature[]> {
        const featureIdToFeature: { [key: string]: TaskFeature } = {};
        task.features = task.features || [];

        let taskFeatureIds = task.features.map(feature => {
            featureIdToFeature[feature.featureId] = feature;
            return feature.featureId;
        });

        const layerKeyToMapWorkspaceLayer = this.layersStore.getLayerKeyToLayerMap();
        let layerIds = _.uniq(
            task.features.map(feature => layerKeyToMapWorkspaceLayer[feature.templateSeriesId]).filter(layer => layer)
        );

        return new Promise(
            (resolve, reject): void => {
                if (layerIds.length) {
                    this.visibleFeatureSet.add(
                        new TodoSelectionCriteria([task.todoId], taskFeatureIds),
                        (features, selectionCriteriaFeatures) => {
                            const currentProjectId = this.projectStream.getCurrentProject().id;
                            let taskFeatures: Feature[] = [];
                            if (selectionCriteriaFeatures.length === 0) {
                                resolve([]);
                            }

                            // get layers for features
                            let removedLayersByLayerKey: { [layerKey: string]: Layer } = {};
                            selectionCriteriaFeatures.forEach(feature => {
                                feature.layerKey = feature.getLayerKey();
                            });
                            let layerKeys = _.uniq(selectionCriteriaFeatures.map(feature => feature.layerKey)).filter(
                                layerKey => layerKey
                            );
                            let promises: Promise<Layer>[] = layerKeys.map(layerKey => {
                                const layer = layerKeyToMapWorkspaceLayer[layerKey];
                                return layer
                                    ? Promise.resolve(layer) // existing layer
                                    : this.layerService.getLayerById(currentProjectId, layerKey).then(layer2 =>
                                          // removed layer
                                          this.styleService
                                              .getStyleById(currentProjectId, layer2.styleId)
                                              .then(style => {
                                                  layer2.style = style;
                                                  layer2.color = layer2.getLayerColor();
                                                  removedLayersByLayerKey[layerKey] = layer2;
                                                  return layer2;
                                              })
                                      );
                            });

                            // add layer and other properties to task features
                            Promise.all(promises).then(() => {
                                selectionCriteriaFeatures.forEach(feature => {
                                    feature = this.cachedFeatureService.updateFromCacheFeature(feature);
                                    feature.inVisibleTask = false;
                                    feature.updated =
                                        featureIdToFeature[feature.id] &&
                                        featureIdToFeature[feature.id].status !== FormStatus.NEEDS_UPDATING;
                                    if (feature.layerKey) {
                                        let layer = layerKeyToMapWorkspaceLayer[feature.layerKey];
                                        if (!layer) {
                                            feature.removedLayer = removedLayersByLayerKey[feature.layerKey] || null;
                                            layer = feature.removedLayer;
                                        }
                                        if (layer) {
                                            feature.addLayerProperties(layer);
                                        }

                                        this.cachedFeatureService.addOrUpdateFeature(feature);
                                    }
                                });

                                taskFeatures = selectionCriteriaFeatures.filter(
                                    f => taskFeatureIds.indexOf(f.id) !== -1
                                );
                                resolve(taskFeatures);
                            });
                        }
                    );
                } else {
                    resolve([]);
                }
            }
        );
    }

    public getTaskBounds(task: Task, noZoom: boolean): Promise<L.LatLngBounds> {
        if (noZoom) {
            return Promise.resolve(null);
        }

        const projectId = this.projectStream.getCurrentProject().id;

        const taskFeatureIds = task.features.map(feature => feature.featureId);

        if (!projectId) {
            return Promise.resolve(null);
        }

        const filter = new FullFeatureFilter();
        filter.taskFeatures.todoIds = [task.id];
        filter.taskFeatures.featureIds = taskFeatureIds;

        return this.featureService.getBoundsOfFeatures(projectId, filter);
    }
}
