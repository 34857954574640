import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { Domains, SwappableField } from 'src/app/feature/template-editor/template/domains';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { GspTextType } from 'src/app/shared/common/components/gsp-text/gsp-text.component';
import { AutoFieldModelType, Field, FieldType } from 'src/app/shared/template-services/field';

export enum TemplateWizardFieldStatus {
    NEW = '',
    ACTIVE = 'active',
    VERIFIED = 'verified'
}

export class TemplateWizardField extends Field {
    fieldTypeDisplayName: string;
    sampleData: any[]; // sample values from features for this field
    tabOrder: number;
    wizardStatus: TemplateWizardFieldStatus; // 'active', 'verified', or ''
    copiedType: string;
    values?: { text: string; default: boolean }[] | { code: string; description: string; default: boolean }[];

    // TODO: Needs some tidying up?
    subType: string;
    modelType?: string;
    numberType?: string;
}

@Component({
    templateUrl: './individual-field.component.html',
    selector: 'individual-field'
})
export class IndividualFieldComponent implements OnInit, OnChanges {
    @Input()
    field: TemplateWizardField;

    @Input()
    focusElement: boolean;

    @Input()
    lastField: boolean;

    @Input()
    geometryType: string;

    @Input()
    fieldValues: any;

    @Output()
    public setFieldFocus: EventEmitter<TemplateWizardField> = new EventEmitter();

    @Output()
    public setChoiceFields: EventEmitter<{
        toFocus: TemplateWizardField;
        selected: string;
    }> = new EventEmitter();

    @Output()
    public replaceEntireField: EventEmitter<{ toFocus: TemplateWizardField; selected: string }> = new EventEmitter();

    @ViewChild('fieldSet', { static: true })
    public fieldSet: ElementRef;

    // expose ButtonType enum to template
    public ButtonType = ButtonType;
    public TemplateWizardFieldStatus = TemplateWizardFieldStatus;
    public GspTextType = GspTextType;

    public fieldTypes: SwappableField[];

    public subTypes: {
        [key: string]: { type: FieldType; subType: FieldType | AutoFieldModelType; subTypeName: string };
    } = {
        Length: { type: FieldType.Number, subType: FieldType.Length, subTypeName: 'numberType' },
        Angle: { type: FieldType.Number, subType: FieldType.Angle, subTypeName: 'numberType' },
        General: { type: FieldType.Number, subType: FieldType.General, subTypeName: 'numberType' },
        EstimatedAccuracy: {
            type: FieldType.Autofield,
            subType: AutoFieldModelType.EstimatedAccuracy,
            subTypeName: 'modelType'
        },
        PDOP: { type: FieldType.Autofield, subType: AutoFieldModelType.PDOP, subTypeName: 'modelType' },
        HDOP: { type: FieldType.Autofield, subType: AutoFieldModelType.HDOP, subTypeName: 'modelType' },

        CollectedBy: {
            type: FieldType.Autofield,
            subType: AutoFieldModelType.CollectedBy,
            subTypeName: 'modelType'
        },
        CreationDateTime: {
            type: FieldType.Autofield,
            subType: AutoFieldModelType.CreationDateTime,
            subTypeName: 'modelType'
        },
        UpdateDateTime: {
            type: FieldType.Autofield,
            subType: AutoFieldModelType.UpdateDateTime,
            subTypeName: 'modelType'
        },
        DeviceType: {
            type: FieldType.Autofield,
            subType: AutoFieldModelType.DeviceType,
            subTypeName: 'modelType'
        },
        DeviceID: { type: FieldType.Autofield, subType: AutoFieldModelType.DeviceID, subTypeName: 'modelType' },
        CorrectionStatus: {
            type: FieldType.Autofield,
            subType: AutoFieldModelType.CorrectionStatus,
            subTypeName: 'modelType'
        },
        CorrectionSource: {
            type: FieldType.Autofield,
            subType: AutoFieldModelType.CorrectionSource,
            subTypeName: 'modelType'
        },
        GeometryCaptureType: {
            type: FieldType.Autofield,
            subType: AutoFieldModelType.GeometryCaptureType,
            subTypeName: 'modelType'
        },
        GeometryLength: {
            type: FieldType.Autofield,
            subType: AutoFieldModelType.GeometryLength,
            subTypeName: 'modelType'
        },
        GeometryArea: {
            type: FieldType.Autofield,
            subType: AutoFieldModelType.GeometryArea,
            subTypeName: 'modelType'
        },
        XPosition: {
            type: FieldType.Autofield,
            subType: AutoFieldModelType.XPosition,
            subTypeName: 'modelType'
        },
        YPosition: {
            type: FieldType.Autofield,
            subType: AutoFieldModelType.YPosition,
            subTypeName: 'modelType'
        },
        ZPosition: {
            type: FieldType.Autofield,
            subType: AutoFieldModelType.ZPosition,
            subTypeName: 'modelType'
        }
    };

    constructor(private translator: TranslationService) {}

    public ngOnInit(): void {
        this.field.copiedType = this.field.copiedType || this.field.type;
        this.fieldTypes = Domains.getSwappableFields(this.field.copiedType, this.geometryType, this.translator);

        let fieldType = this.fieldTypes.find(field => field.name === this.field.type);
        this.field.fieldTypeDisplayName = fieldType.display;
    }

    public ngOnChanges(changesObj: SimpleChanges): void {
        if (changesObj.focusElement.currentValue) {
            this.focusField();
        } else if (this.field.wizardStatus === TemplateWizardFieldStatus.ACTIVE) {
            this.field.wizardStatus = TemplateWizardFieldStatus.VERIFIED;
        }
    }

    public focusField(): void {
        let elementToBeFocused = this.fieldSet;
        let scrollableContent = this.fieldValues;
        let elementToBeFocusedRect = elementToBeFocused.nativeElement.getBoundingClientRect();
        let toBeScrolled =
            window.innerWidth -
            (elementToBeFocusedRect.x + elementToBeFocused.nativeElement.offsetWidth) -
            window.innerWidth * 0.2 -
            scrollableContent.scrollLeft;
        scrollableContent.scrollLeft = -1 * toBeScrolled;
        elementToBeFocused.nativeElement.focus();
        this.field.wizardStatus = TemplateWizardFieldStatus.ACTIVE;
    }

    public moveToNextField(): void {
        this.setFieldFocus.emit(null);
    }

    public triggerOnDropdownSelect(
        fieldToBeFocused: TemplateWizardField,
        fieldSelected: FieldType | AutoFieldModelType
    ): void {
        if (this.subTypes[fieldSelected]) {
            const subType = this.subTypes[fieldSelected];
            fieldToBeFocused.subType = (fieldToBeFocused[subType.subTypeName as keyof TemplateWizardField] as string) =
                subType.subType;
            fieldToBeFocused.type = subType.type;
        } else {
            fieldToBeFocused.subType = fieldToBeFocused.type = fieldSelected;
        }
        if (fieldSelected === FieldType.Choice || fieldSelected === FieldType.CodedChoice) {
            this.setChoiceFields.emit({ toFocus: fieldToBeFocused, selected: fieldSelected });
        } else {
            this.setFocus(fieldToBeFocused, fieldSelected);
        }
    }

    public setFocus(fieldToBeFocused: TemplateWizardField, fieldSelected: string): void {
        this.replaceEntireField.emit({ toFocus: fieldToBeFocused, selected: fieldSelected });
        this.setFieldFocus.emit(fieldToBeFocused);
    }

    public indexTrack(index: number, item: any): number {
        return index;
    }
}
