import { ChartConfiguration } from 'chart.js';
import { AccuracyUtils, UnitSystem } from 'src/app/shared/common/utility/accuracy-utils';
import { Feature } from 'src/app/shared/map-data-services/feature/feature';

export const chartOptions: ChartConfiguration['options'] = {
    responsive: true,
    scales: {
        y: {
            grid: {
                tickLength: 0,
                drawOnChartArea: false
            },
            ticks: {
                display: false
            },
            border: {
                color: '#bdbdbd'
            }
        },
        x: {
            grid: {
                tickLength: -9,
                drawOnChartArea: false,
                color: '#bdbdbd'
            },
            ticks: {
                color: 'transparent',
                padding: 10,
                font: {
                    size: 10,
                    lineHeight: 1
                }
            },
            border: {
                color: '#bdbdbd',
                width: 1
            }
        }
    },
    layout: {
        padding: {
            top: 25,
            bottom: 9,
            left: 7,
            right: 2
        }
    },
    plugins: {
        legend: {
            display: false
        },
        datalabels: {
            anchor: 'end',
            align: 'end',
            color: '#005f9e'
        },
        tooltip: { enabled: false }
    }
};

export class AccuracyPanelUtil {
    static getChartData(
        features: Feature[],
        stepsDict: { [key: string]: { accuracy: number; label: string } }
    ): number[] {
        return features.reduce(
            (acc, feature) => {
                const accuracy = AccuracyUtils.getRoundedHorizontalAccuracyFromMetadata(
                    feature.metadata,
                    feature.geometryType
                );
                if (accuracy <= stepsDict[1].accuracy) {
                    acc[0]++;
                } else if (accuracy <= stepsDict[2].accuracy) {
                    acc[1]++;
                } else if (accuracy <= stepsDict[3].accuracy) {
                    acc[2]++;
                } else if (accuracy <= stepsDict[4].accuracy) {
                    acc[3]++;
                } else if (accuracy <= stepsDict[5].accuracy) {
                    acc[4]++;
                } else if (accuracy <= stepsDict[6].accuracy) {
                    acc[5]++;
                } else if (accuracy <= stepsDict[7].accuracy) {
                    acc[6]++;
                } else {
                    acc[7]++;
                }
                return acc;
            },
            [0, 0, 0, 0, 0, 0, 0, 0]
        );
    }

    static getAccuracyRangeFromIndex(
        index: number,
        stepsDict: { [key: string]: { accuracy: number; label: string } }
    ): number[] {
        const stepValues = Object.values(stepsDict);
        const greaterThanAccuracy = stepValues[index - 1]?.accuracy;
        const lessThanAccuracy = stepValues[index].accuracy;
        return [greaterThanAccuracy, lessThanAccuracy];
    }

    static getStepsDict(unitSystem: UnitSystem): { [key: string]: { accuracy: number; label: string } } {
        return unitSystem === UnitSystem.METRIC
            ? {
                  1: { accuracy: 0.05, label: '5cm' },
                  2: { accuracy: 0.15, label: '15cm' },
                  3: { accuracy: 0.3, label: '30cm' },
                  4: { accuracy: 0.5, label: '50cm' },
                  5: { accuracy: 1, label: '1m' },
                  6: { accuracy: 2, label: '2m' },
                  7: { accuracy: 5, label: '5m' },
                  8: { accuracy: null, label: '5m+' }
              }
            : {
                  1: { accuracy: 0.0508, label: '2in' },
                  2: { accuracy: 0.1524, label: '6in' },
                  3: { accuracy: 0.3048, label: '1ft' },
                  4: { accuracy: 0.6096, label: '2ft' },
                  5: { accuracy: 1.2192, label: '4ft' },
                  6: { accuracy: 2.4384, label: '8ft' },
                  7: { accuracy: 4.8768, label: '16ft' },
                  8: { accuracy: null, label: '16ft+' }
              };
    }
}
