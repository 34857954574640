import { Component, Input, OnInit } from '@angular/core';
import { Field, LayoutFieldType } from 'src/app/shared/template-services/field';
import { FieldDefinition } from 'src/app/shared/template-services/field/fieldDefinition';

@Component({
    selector: 'field-icon',
    templateUrl: './field-icon.component.html'
})
export class FieldIconComponent implements OnInit {
    @Input() field: Field;
    @Input() fieldMeta: FieldDefinition;
    layoutSchema: any;

    // exposing enum to template
    public LayoutFieldType = LayoutFieldType;

    ngOnInit(): void {
        this.layoutSchema = this.fieldMeta.application.layoutSchema;
    }
}
