export class FileUtils {
    // Convert a base64 string to a File instance
    static async urlToFile(url: string, filename: string, mimeType: string = 'image/jpeg'): Promise<File> {
        const response = await fetch(url);
        const data = await response.blob();

        if (!data || !response.ok) {
            return;
        }

        return new File([data], filename, { type: mimeType });
    }

    // Convert a blob to a base64 string
    static blobToDataUrl(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(blob);
        });
    }

    static async httpUrlToBlobUrl(url: string): Promise<string> {
        const file = await this.urlToFile(url, 'image.jpeg');
        const dataUrl = await this.blobToDataUrl(file);
        return dataUrl;
    }

    static isSupportedImageType(fileType: string): boolean {
        return /image\/(png|jpg|jpeg|bmp|gif)$/.test(fileType);
    }
}
