import * as _ from 'lodash-es';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { GeometryTypes } from 'src/app/shared/common/utility/geometry-utils';
import { AutoFieldModelType, FieldType } from 'src/app/shared/template-services/field';

export class SubType {
    id: number;
    name: string;
    label: string;
    value: string | boolean;
    secondary: string | boolean;
}

export class StatusType {
    id: number;
    name: string;
}

export class NumberType {
    id: number;
    name: string;
    label: string;
}

export class GeometryType {
    id: number;
    name: string;
    label: string;
    iconClass: string;
    autoFieldType: string;
    geoId: string;
}

export class DistanceUnit {
    id: number;
    name: string;
    label: string;
}

export class RuleAction {
    val: string;
    name: string;
}

export class MetadataType {
    id: number;
    name: string;
    label: string;
    description: string;
    hideOption?: boolean;
    subType?: SubType[];
    refId?: string;
}

export class SwappableField {
    name: string;
    display: string;
    selected: boolean;
    iconClass: string;
    compatibleFields: string[];
    priority: number;
    label?: string;
}

// ------------------

export class Domains {
    static readonly dateTimeSubType: SubType[] = [
        // added so that this can be called as a variable
        {
            id: 11,
            name: 'LocalTime',
            label: 'TC.Common.DeviceTime',
            value: false,
            secondary: true
        },
        {
            id: 12,
            name: 'Utc',
            label: 'TC.Common.UTC',
            value: true,
            secondary: true
        }
    ];

    static readonly estimatedAccuracySubType: SubType[] = [
        // added so that this can be called as a variable
        {
            id: 13,
            name: 'Centimeters',
            label: 'TC.Common.Centimeters',
            value: 'Centimeters',
            secondary: true
        },
        {
            id: 14,
            name: 'Meters',
            label: 'TC.Common.Meters',
            value: 'Meters',
            secondary: true
        },
        {
            id: 15,
            name: 'Inches',
            label: 'TC.Common.Inches',
            value: 'Inches',
            secondary: true
        },
        {
            id: 16,
            name: 'Feet',
            label: 'TC.Common.Feet',
            value: 'Feet',
            secondary: true
        }
    ];

    // var geometryUnitsMetric =
    static readonly geometryUnitsLength: SubType[] = [
        {
            id: 17,
            name: 'Meters',
            label: 'TC.Common.Meters',
            value: 'Meters',
            secondary: true
        },
        {
            id: 18,
            name: 'Kilometers',
            label: 'Kilometers',
            value: 'Kilometers',
            secondary: true
        },
        {
            id: 19,
            name: 'Feet',
            label: 'TC.Common.Feet',
            value: 'Feet',
            secondary: true
        },
        {
            id: 20,
            name: 'Miles',
            label: 'Miles',
            value: 'Miles',
            secondary: true
        }
    ];

    // var geometryUnitsEmpiric = [
    static readonly geometryUnitsArea: SubType[] = [
        {
            id: 22,
            name: 'SquareMeters',
            label: 'm²',
            value: 'SquareMeters',
            secondary: true
        },
        {
            id: 22,
            name: 'SquareKilometers',
            label: 'km²',
            value: 'SquareKilometers',
            secondary: true
        },
        {
            id: 21,
            name: 'SquareFeet',
            label: 'ft²',
            value: 'SquareFeet',
            secondary: true
        },
        {
            id: 22,
            name: 'Acres',
            label: 'ac',
            value: 'Acres',
            secondary: true
        }
    ];

    static getTemplateStatusTypes(): StatusType[] {
        return [
            {
                id: 0,
                name: 'Draft'
            },
            {
                id: 1,
                name: 'Published'
            },
            {
                id: 2,
                name: 'Archived'
            },
            {
                id: 3,
                name: 'SchemaLockedDraft'
            }
        ];
    }

    static getNumberTypes(): NumberType[] {
        return [
            {
                id: 0,
                name: FieldType.General,
                label: 'TC.Common.General'
            },
            {
                id: 1,
                name: FieldType.Length,
                label: 'templateEditor.tf.assets.length'
            },
            {
                id: 2,
                name: FieldType.Angle,
                label: 'templateEditor.tf.assets.angle'
            },
            {
                id: 3,
                name: FieldType.Barcode,
                label: 'TCS.Barcode'
            }
        ];
    }

    static getGeometryTypes(): GeometryType[] {
        return [
            {
                id: 1,
                name: GeometryTypes.POINT,
                label: 'TC.Common.Point',
                iconClass: 'icon_solid_point',
                autoFieldType: '',
                geoId: 'point'
            },
            {
                id: 2,
                name: GeometryTypes.LINE,
                label: 'TC.Common.Line',
                iconClass: 'icon_solid_line',
                autoFieldType: FieldType.Length,
                geoId: 'line'
            },
            {
                id: 3,
                name: GeometryTypes.AREA,
                label: 'Common.Area',
                iconClass: 'icon_solid_poly',
                autoFieldType: GeometryTypes.AREA,
                geoId: 'area'
            }
        ];
    }

    static getDistanceUnits(): DistanceUnit[] {
        return [
            {
                id: 12,
                name: 'Centimeters',
                label: 'TC.Common.Centimeters'
            },
            {
                id: 0,
                name: 'Meters',
                label: 'TC.Common.Meters'
            },
            {
                id: 2,
                name: 'Inches',
                label: 'TC.Common.Inches'
            },
            {
                id: 1,
                name: 'Feet',
                label: 'TC.Common.Feet'
            },
            {
                id: 3,
                name: 'Yards',
                label: 'TC.Common.Yards'
            }
        ];
    }

    static getRuleActions(): RuleAction[] {
        return [
            {
                val: 'EnableTargets',
                name: 'TCS.TemplateEditor.Tf.Rules.EnableTargets'
            },
            {
                val: 'DisableTargets',
                name: 'TCS.TemplateEditor.Tf.Rules.DisableTargets'
            }
        ];
    }

    static getRuleActionText(value: string): string {
        let action = _.find(this.getRuleActions(), a => a.val === value);
        return action ? action.name : '';
    }

    static getSwappableFields(
        fieldType: string,
        geometryType: string,
        translator: TranslationService
    ): SwappableField[] {
        let swappableFields: SwappableField[] = [];
        let fields: SwappableField[] = [
            // ---------------------------
            // Text
            {
                name: FieldType.Text,
                display: 'TC.Common.Text',
                selected: true,
                iconClass: 'icon_solid_text',
                compatibleFields: [FieldType.Text],
                priority: 0
            },
            {
                name: FieldType.Choice,
                display: 'TC.Common.Choice',
                selected: false,
                iconClass: 'icon_line_choice',
                compatibleFields: [FieldType.Text],
                priority: 0
            },
            {
                name: FieldType.CodedChoice,
                display: 'TC.Common.CodedChoice',
                selected: false,
                iconClass: 'icon_line_codedchoice',
                compatibleFields: [FieldType.Text],
                priority: 0
            },
            {
                name: FieldType.YesNo,
                display: 'TC.Common.YesNo',
                selected: false,
                iconClass: 'icon_line_yesno',
                compatibleFields: [FieldType.YesNo],
                priority: 0
            },
            {
                name: FieldType.Barcode,
                display: 'TCS.Barcode',
                selected: false,
                iconClass: 'icon_line_barcode',
                compatibleFields: [FieldType.Text],
                priority: 0
            },
            {
                name: FieldType.Image,
                display: 'image',
                selected: false,
                iconClass: 'icon_solid_camera',
                compatibleFields: [FieldType.Text],
                priority: 0
            },
            {
                name: FieldType.Signature,
                display: 'TC.Common.Signature',
                selected: false,
                iconClass: 'icon_line_sign',
                compatibleFields: [FieldType.Text],
                priority: 0
            },
            {
                name: AutoFieldModelType.CollectedBy,
                display: 'TC.Common.Collector',
                selected: false,
                iconClass: 'icon_solid_collectedby',
                compatibleFields: [FieldType.Text],
                priority: 1
            },
            {
                name: AutoFieldModelType.DeviceType,
                display: 'TC.Common.Device',
                selected: false,
                iconClass: 'icon_line_device_type',
                compatibleFields: [FieldType.Text],
                priority: 1
            },
            {
                name: AutoFieldModelType.DeviceID,
                display: 'TC.Common.DeviceID',
                selected: false,
                iconClass: 'icon_line_device_id',
                compatibleFields: [FieldType.Text],
                priority: 1
            },
            {
                name: AutoFieldModelType.CorrectionStatus,
                display: 'TCS.DefaultLabel.Fields.Correctionstatus',
                selected: false,
                iconClass: 'icon_line_correction_status',
                compatibleFields: [FieldType.Text],
                priority: 1
            },
            {
                name: AutoFieldModelType.CorrectionSource,
                display: 'TCS.DefaultLabel.Fields.Correctionsource',
                selected: false,
                iconClass: 'icon_line_correction_source',
                compatibleFields: [FieldType.Text],
                priority: 1
            },
            {
                name: AutoFieldModelType.GeometryCaptureType,
                display: 'TCS.DefaultLabel.Fields.Geometrycapturetype',
                selected: false,
                iconClass: 'icon_solid_autofields',
                compatibleFields: [FieldType.Text],
                priority: 1
            },
            {
                name: AutoFieldModelType.Todo,
                display: 'TODO',
                selected: false,
                iconClass: 'icon_solid_todo_field',
                compatibleFields: [FieldType.Text],
                priority: 1
            },
            {
                name: AutoFieldModelType.Workspace,
                display: 'TCS.Workspace',
                selected: false,
                iconClass: 'icon_solid_map',
                compatibleFields: [FieldType.Text],
                priority: 1
            },
            {
                name: AutoFieldModelType.Increment,
                display: 'TCS.UniqueId',
                selected: false,
                iconClass: 'icon_line_auto_increment',
                compatibleFields: [FieldType.Text, FieldType.Number],
                priority: 1
            },

            // ---------------------------
            // Number
            {
                name: FieldType.Number,
                display: 'TC.Common.Number',
                selected: true,
                iconClass: 'icon_line_number',
                compatibleFields: [FieldType.Number],
                priority: 0
            },
            {
                name: FieldType.Length,
                display: 'TC.Common.Length',
                selected: false,
                iconClass: 'icon_line_length',
                compatibleFields: [FieldType.Number],
                priority: 0
            },
            {
                name: FieldType.Angle,
                display: 'TC.Common.Angle',
                selected: false,
                iconClass: 'icon_line_angle',
                compatibleFields: [FieldType.Number],
                priority: 0
            },
            {
                name: AutoFieldModelType.EstimatedAccuracy,
                display: 'TCS.DefaultLabel.Fields.Estimatedaccuracy',
                selected: false,
                iconClass: 'icon_line_horizontal_accuracy',
                compatibleFields: [FieldType.Number],
                priority: 1
            },
            {
                name: AutoFieldModelType.PDOP,
                display: 'TCS.DefaultLabel.Fields.Pdop',
                selected: false,
                iconClass: 'icon_line_pdop1',
                compatibleFields: [FieldType.Number],
                priority: 1
            },
            {
                name: AutoFieldModelType.HDOP,
                display: 'TCS.DefaultLabel.Fields.Hdop',
                selected: false,
                iconClass: 'icon_line_hdop1',
                compatibleFields: [FieldType.Number],
                priority: 1
            },
            {
                name: AutoFieldModelType.EstimatedVerticalPrecision,
                display: 'TCS.DefaultLabel.Fields.EstimatedVerticalAccuracy',
                selected: false,
                iconClass: 'icon_line_vertical_accuracy',
                compatibleFields: [FieldType.Number],
                priority: 1
            },
            // ---------------------------
            // Date
            {
                name: FieldType.Date,
                display: 'date',
                selected: false,
                iconClass: 'icon_line_date',
                compatibleFields: [FieldType.Date],
                priority: 0
            },
            {
                name: AutoFieldModelType.CreationDateTime,
                display: 'TC.Common.CreatedOn',
                selected: false,
                iconClass: 'icon_line_date_creation',
                compatibleFields: [FieldType.Date],
                priority: 1
            },
            {
                name: AutoFieldModelType.UpdateDateTime,
                display: 'TC.Common.Updated',
                selected: false,
                iconClass: 'icon_line_date_update',
                compatibleFields: [FieldType.Date],
                priority: 1
            }
        ];

        if (fieldType === FieldType.Number && geometryType === GeometryTypes.LINE) {
            fields.push({
                name: AutoFieldModelType.GeometryLength,
                display: 'TCS.DefaultLabel.Fields.Geometrylength',
                selected: false,
                iconClass: 'icon_solid_line',
                compatibleFields: [FieldType.Number],
                priority: 1
            });
        }

        // ---------------------------
        // Number fields based on layer geometry type

        if (fieldType === FieldType.Number && geometryType === GeometryTypes.AREA) {
            fields.push({
                name: AutoFieldModelType.GeometryArea,
                display: 'Common.Area',
                selected: false,
                iconClass: 'icon_solid_poly',
                compatibleFields: [FieldType.Number],
                priority: 1
            });
        }

        if (fieldType === FieldType.Number && geometryType === GeometryTypes.POINT) {
            fields.push(
                {
                    name: AutoFieldModelType.XPosition,
                    display: 'TCS.XPosition',
                    selected: false,
                    iconClass: 'icon-map-marker-x',
                    compatibleFields: [FieldType.Number],
                    priority: 1
                },
                {
                    name: AutoFieldModelType.YPosition,
                    display: 'TCS.YPosition',
                    selected: false,
                    iconClass: 'icon-map-marker-y',
                    compatibleFields: [FieldType.Number],
                    priority: 1
                },
                {
                    name: AutoFieldModelType.ZPosition,
                    display: 'TCS.ZPosition',
                    selected: false,
                    iconClass: 'icon-map-marker-z',
                    compatibleFields: [FieldType.Number],
                    priority: 1
                }
            );
        }

        fields.forEach(field => {
            if (field.compatibleFields.includes(fieldType)) {
                // label is used only for sorting the list by alphabetical order
                field.label = translator.instant(field.display);
                swappableFields.push(field);
            }
        });

        return _.sortBy(swappableFields, 'priority', 'label');
    }
}
