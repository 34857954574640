import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { ContextMenuActions } from 'src/app/shared/common/components/context-menu/gsp-context-menu.component';
import {
    GeoJsonFeaturesStreamService,
    GeoJsonFeatureType,
} from 'src/app/shared/common/current-features/geoJson-features-stream.service';
import { MapWorkspaceStreamsService } from 'src/app/shared/common/current-map-workspaces/map-workspace-streams.service';
import { MapCache, MapCacheStyle } from 'src/app/shared/common/mapCache/map-cache';
import { MapCacheStreamService } from 'src/app/shared/common/mapCache/map-cache-stream.service';
import { Feature } from 'src/app/shared/map-data-services/feature/feature';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { MapWorkspacePermissionType } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace-permission';

@Component({
    templateUrl: './map-cache-list.component.html',
    selector: 'map-cache-list'
})
export class MapCacheListComponent implements OnDestroy {
    public mapCacheList: MapCache[] = [];

    private visibleMapCaches: string[] = [];

    public contextMenuItems: ContextMenuActions[] = [
        {
            name: this.translate.instant('TCS.Mapviewer.MapCache.ZoomToMap'),
            id: 'zoom-to-offline-basemap',
            labelClass: 'offline-basemap-context-menu-item',
            title: false,
            visible: (mapCache: MapCache) => this.isMapCacheVisible(mapCache),
            execute: ($event: { item: MapCache }) => this.zoomToMap($event.item)
        },
        {
            name: this.translate.instant('TC.Common.Rename'),
            id: 'rename-offline-basemap',
            labelClass: 'offline-basemap-context-menu-item',
            title: false,
            visible: () => this.checkVisibility(MapWorkspacePermissionType.FULL_ACCESS),
            execute: ($event: { item: MapCache }) => this.goToEditPanel($event.item)
        },
        {
            name: this.translate.instant('TCW_Remove_Association'),
            id: 'remove-offline-basemap',
            labelClass: 'offline-basemap-context-menu-item',
            title: false,
            visible: () => this.checkVisibility(MapWorkspacePermissionType.FULL_ACCESS),
            execute: ($event: { item: MapCache }) => this.remove($event.item)
        }
    ];

    private destroyed = new Subject<void>();
    private currentWorkspace: MapWorkspace;

    public visibleAll = false;

    // exposing enum to template
    public MapCacheStyle = MapCacheStyle;

    constructor(
        private router: Router,
        private mapCacheStream: MapCacheStreamService,
        private geoJsonFeaturesStream: GeoJsonFeaturesStreamService,
        private messaging: MessagingService,
        private translate: TranslationService,
        private mapWorkspaceStreams: MapWorkspaceStreamsService
    ) {
        mapCacheStream.currentWorkSpaceMapCaches.subscribe(mapCaches => (this.mapCacheList = mapCaches));
        this.mapWorkspaceStreams.currentMapWorkspaceStream.pipe(takeUntil(this.destroyed)).subscribe(mapWorkspace => {
            if (mapWorkspace) {
                this.currentWorkspace = mapWorkspace;
            }
        });
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    toggle(item: MapCache): void {
        item.visible = !item.visible;
        if (item.visible) {
            this.displayMapCache(item);
        } else {
            this.hideMapCache(item);
        }
        this.updateMapCacheListVisibility(item);
    }

    zoomToMap(item: MapCache): void {
        this.displayMapCache(item);
    }

    goToEditPanel(mapCache: MapCache): void {
        this.mapCacheStream.currentEditMapCacheStream.next(mapCache);
        this.router.navigate(['mapViewer', { outlets: { centerDialog: 'offlinebasemap/edit' } }], {
            queryParamsHandling: 'preserve'
        });
    }

    remove(item: MapCache): void {
        this.mapCacheStream
            .removeMapCacheFromWorkspace(item)
            .then(() => {
                this.hideMapCache(item);
                this.showSuccess(item);
            })
            .catch(() => {
                this.messaging.showError(
                    this.translate.instant('TCS.Mapviewer.MapCache.MapCacheNotRemoved', {
                        cache_name: item.name
                    })
                );
            });
    }

    showSuccess(mapCache: MapCache): void {
        let toastr = this.messaging.showSuccess(
            this.translate.instant('TCS.Mapviewer.MapCache.MapcacheRemoved') +
                '<br>' +
                this.translate.instant('TCS.Mapviewer.MapCache.RestoreMapcache', { mapcacheName: mapCache.name }),
            null,
            { enableHtml: true }
        );

        toastr.onTap.subscribe(() => {
            // restore layer
            this.restoreMapCache(mapCache);
        });
    }

    restoreMapCache(item: MapCache): void {
        this.mapCacheStream
            .restoreMapCache(item)
            .then(() => {
                this.messaging.showSuccess(this.translate.instant('TCS.Mapviewer.MapCache.RestoreSuccess'));
            })
            .catch(() => {
                this.messaging.showError(this.translate.instant('TC.Common.ErrorWhileRestoring'));
            });
    }

    displayMapCache(item: MapCache, focusOnMap = true): void {
        let mapCacheJson: Partial<Feature> = JSON.parse(item.featureJSON);
        mapCacheJson.id = item.id;
        mapCacheJson.colorKey = '363545';
        mapCacheJson.properties = {};
        this.geoJsonFeaturesStream.displayGeoJsonFeatures(
            GeoJsonFeatureType.MAP_CACHE,
            item.id,
            mapCacheJson,
            focusOnMap
        );
    }

    hideMapCache(item: MapCache): void {
        this.geoJsonFeaturesStream.displayGeoJsonFeatures(GeoJsonFeatureType.MAP_CACHE, item.id, null, false);
    }

    private isMapCacheVisible(mapCache: MapCache): boolean {
        return mapCache.visible;
    }

    private checkVisibility(visibleMode: string): boolean {
        return (
            this.currentWorkspace &&
            this.currentWorkspace.permission &&
            this.currentWorkspace.permission.indexOf(visibleMode) === 0
        );
    }

    public toggleAll(): void {
        this.visibleAll = !this.visibleAll;
        if (this.visibleAll) {
            this.visibleMapCaches = [];
            this.mapCacheList.forEach(mapCache => {
                mapCache.visible = true;
                this.displayMapCache(mapCache, false);
                this.visibleMapCaches.push(mapCache.id);
            });
        } else {
            this.visibleMapCaches = [];
            this.mapCacheList.forEach(mapCache => {
                if (mapCache.visible) {
                    mapCache.visible = false;
                    this.hideMapCache(mapCache);
                }
            });
        }
    }

    private updateMapCacheListVisibility(mapCache: MapCache): void {
        if (mapCache.visible) {
            this.visibleMapCaches.push(mapCache.id);
        } else {
            this.visibleMapCaches = this.visibleMapCaches.filter(mapCacheId => mapCacheId !== mapCache.id);
        }
        this.visibleAll = this.visibleMapCaches.length ? true : false;
    }
}
