import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { ContextMenuActions } from 'src/app/shared/common/components/context-menu/gsp-context-menu.component';
import { AccuracyUtils } from 'src/app/shared/common/utility/accuracy-utils';
import { GeneralUtils } from 'src/app/shared/common/utility/general-utils';
import { Feature } from 'src/app/shared/map-data-services/feature/feature';
import { MapWorkspacePermissionType } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace-permission';

import { ChartPanelStreamService } from '../../../common/chart-panel-stream.service';
import { PanelFeature } from '../feature-panel.component';

@Component({
    selector: 'feature-list',
    templateUrl: './feature-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatureListComponent implements OnChanges, OnDestroy {
    @Input()
    selectedFeatures: Feature[] = [];

    @Input()
    activePanelFeature: PanelFeature = null;

    @Input()
    removeFromSelectionMode = false;

    @Output()
    setCurrentFeature = new EventEmitter<PanelFeature>();

    @Output()
    showDeleteAllConfirmation = new EventEmitter<void>();

    public contextMenuItems: ContextMenuActions[] = [
        {
            name: this.translateService.instant('MapViewer_Generic_DeleteSelection'),
            id: 'delete-selected-features',
            divider: false,
            title: false,
            execute: () => this.showDeleteAllConfirmationAction(),
            visible: () => true
        }
    ];

    private readonly destroyed = new Subject<void>();
    isAccuracyPanelDisplayed$: Observable<boolean>;
    shouldShowAccuracyButton = false;
    shareMapUrl = false;

    // expose ButtonType enum to template
    public ButtonType = ButtonType;
    public MapWorkspacePermissionType = MapWorkspacePermissionType;

    constructor(
        private translateService: TranslationService,
        private chartPanelStreamService: ChartPanelStreamService,
        private router: Router
    ) {
        this.isAccuracyPanelDisplayed$ = this.chartPanelStreamService.isAccuracyPanelDisplayedStream;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.shareMapUrl = this.router.url.includes('shareMap');
        if (changes.selectedFeatures) {
            this.shouldShowAccuracyButton = !!this.selectedFeatures.find(
                feature =>
                    !GeneralUtils.isNullUndefinedOrNaN(
                        AccuracyUtils.getRoundedHorizontalAccuracyFromMetadata(feature.metadata, feature.geometryType)
                    )
            );
        }
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    setCurrentFeatureAction(feature: PanelFeature): void {
        this.setCurrentFeature.emit(feature);
    }

    showDeleteAllConfirmationAction(): void {
        this.showDeleteAllConfirmation.emit();
    }

    toggleAccuracyPanel(): void {
        this.chartPanelStreamService.isAccuracyPanelDisplayed = !this.chartPanelStreamService.isAccuracyPanelDisplayed;
    }
}
