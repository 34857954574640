import { GeneralUtils } from 'src/app/shared/common/utility/general-utils';

import { GeoUserSettingsRequest, GeoUsersSettings } from './user-settings';

export class UserProjectSettings {
    id: string = null;
    name: string = null;
    type = 'project';
    settingsName: string = null;
    currentMapWorkspace: string = null;
    previousMapWorkspace: string = null;
    currentProjectId: string = null;
    fileViewerMapWorkspace: string = null;

    constructor(projectId: string) {
        this.currentProjectId = projectId;
        this.name = projectId;
        this.settingsName = UserProjectSettings.getSettingsName(projectId);
    }

    static fromDTO(dto: GeoUsersSettings): UserProjectSettings {
        let settings = null;
        if (dto) {
            settings = new UserProjectSettings(dto.value.id);
            settings.id = dto.id;
            settings.name = dto.name;
            settings.type = dto.type;
            settings.settingsName = dto.settingsName;
            settings.currentMapWorkspace = dto.value.currentMapWorkspace;
            settings.previousMapWorkspace = dto.value.previousMapWorkspace;
            settings.currentProjectId = dto.value.id;
            settings.fileViewerMapWorkspace = dto.value.fileViewerMapWorkspace;
        }

        return settings;
    }

    static getSettingsName(projectId: string): string {
        return 'project-' + projectId;
    }

    public toDTO(): GeoUserSettingsRequest {
        let settings: any = {};

        settings.id = this.currentProjectId;

        if (!GeneralUtils.isNullUndefinedOrNaN(this.currentMapWorkspace)) {
            settings.currentMapWorkspace = this.currentMapWorkspace;
            settings.previousMapWorkspace = this.previousMapWorkspace;
        }

        if (!GeneralUtils.isNullUndefinedOrNaN(this.fileViewerMapWorkspace)) {
            settings.fileViewerMapWorkspace = this.fileViewerMapWorkspace;
        }

        return {
            name: this.name,
            type: this.type,
            settingsName: this.settingsName ? this.settingsName : UserProjectSettings.getSettingsName(this.name),
            value: settings
        };
    }
}
