<header>
    <div class="list-count">{{ selectedFeatures.length }}</div>
</header>
<section class="height-full">
    <custom-scroll autoHeightDisable="true">
        <ul>
            <li
                *ngIf="shouldShowAccuracyButton && !shareMapUrl"
                class="accuracy-button"
                [ngClass]="{ selected: isAccuracyPanelDisplayed$ | async }"
                (click)="toggleAccuracyPanel()"
            >
                <i class="accuracy-button-icon i24"></i>
            </li>
            <li
                *ngFor="let feature of selectedFeatures; index as i"
                [ngClass]="{
                    selected: activePanelFeature && activePanelFeature.id === feature.id,
                    'select-minus-cur': removeFromSelectionMode,
                    'select-cur': !removeFromSelectionMode
                }"
                (click)="setCurrentFeatureAction(feature)"
            >
                <geom-icon path="sym_32-geom-" [color]="'#' + feature.colorKey" [geoType]="feature.geometryType">
                </geom-icon>
            </li>
        </ul>
    </custom-scroll>
</section>
<header *checkVisibility="{ visibleModes: [MapWorkspacePermissionType.FULL_ACCESS] }">
    <gsp-button
        *ngIf="selectedFeatures.length > 1"
        iconClass="icon_solid_menu i32"
        [type]="ButtonType.Icon"
        (action)="bulkActionsMenu.onContextMenu($event)"
    ></gsp-button>

    <gsp-context-menu #bulkActionsMenu [items]="contextMenuItems"> </gsp-context-menu>
</header>
