import { GeoLink } from 'src/app/shared/types/geo';

import { TemplateStatus } from '../map-data-services/layer/template-lite';
import { MapWorkspaceStatus } from '../map-data-services/mapWorkspace/map-workspace.types';
import { AutoFieldModelType, FieldType, LayoutFieldType } from './field';

export interface GeoTemplates {
    links?: GeoLink[];
    total?: number;
    items?: GeoTemplate[];
    eTag?: string;
}

export interface GeoTemplate {
    seriesId?: string;
    version?: number;
    template?: { [key: string]: any };
    isDeleted?: boolean;
    isPublished?: boolean;
    status?: TemplateStatus;
    workspaces?: GeoTemplateWorkspace[];
    links?: GeoLink[];
    id?: string;
    eTag?: string;
}

export interface GeoTemplateWorkspace {
    id?: string;
    name?: string;
    description?: string;
    status?: MapWorkspaceStatus;
}

export interface GeoTemplateDeltas {
    additions?: FieldModelBase[];
    removals?: FieldModelBase[];
    links?: GeoLink[];
    id?: string;
    eTag?: string;
}

export interface FieldModelBase {
    uuid?: string;
    displayName?: string;
    name?: string;
    type?: FieldType | LayoutFieldType | AutoFieldModelType;
    ordinal?: number;
    groupId?: string;
    required?: boolean;
    readOnly?: boolean;
    requiredForUpdate?: boolean;
    readOnlyForUpdate?: boolean;
    repeatField?: boolean;
    incrementRepeatValue?: number;
}
export enum LoggingMethod{
    Automatic = "Automatic",
    Manual ="Manual"
}
