import { Injectable } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Injectable()
export class ContextMenuService {
    private trigger: MatMenuTrigger;

    toggleContextMenu(trigger: MatMenuTrigger = null): void {
        let menuClosed = false;
        if (this.trigger) {
            this.trigger.closeMenu();
            menuClosed = true;
        }

        this.trigger = trigger;

        if (this.trigger !== null) {
            // Allow for closing animation to finish
            setTimeout(() => this.trigger.openMenu(), menuClosed ? 120 : 0);
        }
    }
}
