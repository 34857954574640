import { AfterViewChecked, ChangeDetectorRef, Directive, ElementRef, OnDestroy } from '@angular/core';
import { TranslateDirective, TranslateService } from '@ngx-translate/core';

@Directive({
    selector: '[translate]'
})
export class TranslationDirective extends TranslateDirective implements AfterViewChecked, OnDestroy {
    constructor(translateService: TranslateService, element: ElementRef, _ref: ChangeDetectorRef) {
        super(translateService, element, _ref);
    }

    ngAfterViewChecked(): void {
        super.ngAfterViewChecked();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
