import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';

import { ActionToastComponent } from './action-toast.component';

@Injectable({
    providedIn: 'root'
})
export class MessagingService {
    toastVerticalOffset = 0;
    renderer: Renderer2;

    constructor(private toastr: ToastrService, rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    /** show toast */
    show(message?: string, title?: string, override?: Partial<IndividualConfig>, type?: string): ActiveToast<any> {
        return this.setOffsetToToast(this.toastr.show(message, title, override, type));
    }

    /** show successful toast */
    showSuccess(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
        return this.setOffsetToToast(this.toastr.success(message, title, override));
    }

    /** show successful action toast */
    showSuccessWithAction(
        message?: string,
        title?: string,
        actionTitle?: string,
        override?: Partial<IndividualConfig>
    ): ActiveToast<any> {
        let clone = CloneUtils.cloneDeep(override);
        clone.toastComponent = ActionToastComponent;
        (clone as any).actionTitle = actionTitle;
        return this.setOffsetToToast(this.toastr.success(message, title, override));
    }

    /** show error toast */
    showError(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
        return this.setOffsetToToast(this.toastr.error(message, title, override));
    }

    showErrorWithAction(
        message?: string,
        title?: string,
        actionTitle?: string,
        override?: Partial<IndividualConfig>
    ): ActiveToast<any> {
        let clone = CloneUtils.cloneDeep(override);
        clone.toastComponent = ActionToastComponent;
        (clone as any).actionTitle = actionTitle;
        return this.setOffsetToToast(this.toastr.error(message, title, override));
    }

    /** show info toast */
    showInfo(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
        return this.setOffsetToToast(this.toastr.info(message, title, override));
    }

    /** show warning toast */
    showWarning(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
        return this.setOffsetToToast(this.toastr.warning(message, title, override));
    }

    /**
     * Remove all or a single toast by id
     */
    clear(toastId?: number): void {
        // Workaround: "The only possible workaround is trying to avoid closing a toastr immediately after open."
        timer(1000).subscribe(() => this.toastr.clear(toastId));
    }

    /**
     * Remove and destroy a single toast by id
     */
    remove(toastId: number): boolean {
        return this.toastr.remove(toastId);
    }

    setToastVerticalOffset(offset: number): void {
        this.toastVerticalOffset = offset;
    }

    setOffsetToToast(activeToast: ActiveToast<any>): ActiveToast<any> {
        // set toast vertical offset for announcement banner along with offset of 44px for top bar
        activeToast.onShown.pipe(take(1)).subscribe(() => {
            this.renderer.setStyle(
                document.querySelector('.toast-container'),
                'margin-top',
                `${this.toastVerticalOffset + 44}px`
            );
        });
        return activeToast;
    }
}
