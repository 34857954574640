import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map, shareReplay, withLatestFrom } from 'rxjs/operators';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';
import { LayerService } from 'src/app/shared/map-data-services/layer/layer.service';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';

import { MapWorkspaceStatus } from '../../map-data-services/mapWorkspace/map-workspace.types';
import { User, UserRole } from '../../user/user';
import { MapWorkspaceStreamsService } from '../current-map-workspaces/map-workspace-streams.service';
import { MapWorkspacesStoreService } from '../current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from '../current-project/project-stream.service';
import { CurrentUserStreamService } from '../current-user/current-user-stream.service';
import { LayersStore } from './layers-store.service';
import { LayersStreams } from './layers-streams.service';

@Injectable({
    providedIn: 'root'
})
export class ProjectLayersStreams {
    // true if project layers are current loading
    public projectLayersLoadingStream = new BehaviorSubject<boolean>(false);

    // the list of all layers in the current project
    private projectLayersStream = new BehaviorSubject<Layer[]>([]);
    // the list of all layers in the current project filtered by name
    public projectLayersFilteredByNameStream: Observable<Layer[]>;

    // the list of layers to display in the Layer Library
    public layerLibraryLayersStream: Observable<Layer[]>;
    // the list of layers to display in the Template Library
    public templateLibraryLayersStream: Observable<Layer[]>;

    private searchLayerNameStream = new BehaviorSubject<string>(null); // null => loading is paused
    private currentUserIsAdminStream = new BehaviorSubject<boolean>(null);

    private pageSize = 50;
    private nextPageRequestStream = new Subject<any>();
    private nextStartIndexBySearchLayerName: { [key: string]: number } = {};
    private currentMapWorkspace: MapWorkspace = null;

    private destroySubscription: Subscription;

    constructor(
        private currentUserStream: CurrentUserStreamService,
        private projectStream: ProjectStreamService,
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private mapWorkspaceStreams: MapWorkspaceStreamsService,
        private layersStreams: LayersStreams,
        private layersStore: LayersStore,
        private layerService: LayerService
    ) {
        this.currentUserStream.currentUserWithRoleStream.pipe(filter(Boolean)).subscribe((userInfo: User) => {
            let currentUserIsAdmin = userInfo.role === UserRole.ADMIN ? true : false;
            this.currentUserIsAdminStream.next(currentUserIsAdmin);
        });

        // Resetting the nextStartIndex and projectLayers on mapWorkspace change and workspace deletion
        combineLatest([
            this.mapWorkspaceStreams.projectMapWorkspacesStream,
            this.mapWorkspaceStreams.currentMapWorkspaceStream.pipe(
                distinctUntilChanged((prev, curr) => prev.id === curr.id)
            )
        ]).subscribe(([workspaces, currentMapWorkspace]) => {
            this.projectLayersStream.next([]);
            this.nextStartIndexBySearchLayerName = {};
            this.currentMapWorkspace = currentMapWorkspace;
            // cancel subscription to cancel the pending layer loading call
            if (this.destroySubscription) {
                this.destroySubscription.unsubscribe();
            }
        });

        combineLatest([
            this.currentUserIsAdminStream.pipe(distinctUntilChanged()),
            this.mapWorkspaceStreams.projectMapWorkspacesStream.pipe(distinctUntilChanged()),
            this.searchLayerNameStream.pipe(distinctUntilChanged())
        ]).subscribe(() => {
            this.nextPageRequestStream.next(null); // triggers next page request
        });

        combineLatest([
            this.nextPageRequestStream,
            this.currentUserIsAdminStream.pipe(distinctUntilChanged())
        ]).subscribe(([pageRequest, currentUserIsAdminStream]: [any, boolean]) => {
            this.loadNextPageOfProjectLayers(
                currentUserIsAdminStream,
                this.projectStream.currentProjectStream.getValue().id,
                this.searchLayerNameStream.getValue(),
                this.projectLayersStream.getValue()
            );
        });

        this.projectLayersFilteredByNameStream = this.projectLayersStream.pipe(
            map(layers => this.getLayersFilteredByName(layers)),
            shareReplay(1)
        );

        this.layerLibraryLayersStream = this.projectLayersFilteredByNameStream.pipe(
            withLatestFrom(this.mapWorkspacesStore.currentMapWorkspaceStream),
            map(([layers, currentMapWorkspace]: [Layer[], MapWorkspace]) =>
                layers.filter(layer => {
                    let isCurrentWorkspaceLayer =
                        currentMapWorkspace &&
                        layer.workspaces &&
                        layer.workspaces.findIndex(workspace => workspace.id === currentMapWorkspace.id) >= 0;

                    // consider layer for filtering only if all the mapped workspaces are archived
                    let isLayerPartOfArchivedWorkspace = layer.workspaces.every(
                        workspace => workspace.status === MapWorkspaceStatus.ARCHIVED
                    );

                    return (
                        // not part of an archived workspace
                        !isLayerPartOfArchivedWorkspace &&
                        // not in current workspace
                        !isCurrentWorkspaceLayer &&
                        // i.e. Completed Imported
                        (!layer.templateId ||
                            // i.e. Not Imported and latest template version published
                            (layer.templateId && layer.templatePublishStatus))
                    );
                })
            ),
            shareReplay(1),
            finalize(() => {
                this.pauseLoading();
            })
        );

        this.templateLibraryLayersStream = this.projectLayersFilteredByNameStream.pipe(
            map(layers =>
                layers.filter(
                    layer =>
                        // i.e. Not Imported and latest template version published
                        layer.templateId && layer.templatePublishStatus
                )
            ),
            shareReplay(1),
            finalize(() => {
                this.pauseLoading();
            })
        );

        this.layersStore.changedMapWorkspaceLayerStream.subscribe((layer: Layer) => this.handleChangedLayer(layer));

        // update mapWorkspaces on loaded layers on workspace name changes
        this.mapWorkspacesStore.projectMapWorkspacesStream.subscribe(updatedWworkspaces => {
            let layers = this.projectLayersStream.getValue();
            layers.map(layer => {
                layer.workspaces = layer.workspaces.map(workspace => {
                    updatedWworkspaces.map(updatedWorkspace => {
                        if (workspace.id === updatedWorkspace.id) {
                            workspace = updatedWorkspace;
                        }
                    });
                    return workspace;
                });
            });
            this.projectLayersStream.next(layers);
        });
    }

    private loadNextPageOfProjectLayers(
        currentUserIsAdmin: boolean,
        projectId: string,
        searchLayerName: string,
        layers: Layer[]
    ): Promise<void> {
        this.projectLayersLoadingStream.next(true);

        if (searchLayerName == null) {
            // null => loading is paused
            this.projectLayersLoadingStream.next(false);
            return Promise.resolve();
        }

        let startIndex = this.nextStartIndexBySearchLayerName[searchLayerName] || 0;
        if (startIndex < 0) {
            // already loaded all
            this.projectLayersStream.next(layers);
            this.projectLayersLoadingStream.next(false);
            return Promise.resolve();
        }

        // check to see if we already have all layers loaded for searchLayerName (or a superstring of it)
        if (
            Object.keys(this.nextStartIndexBySearchLayerName).some(
                (key: string) =>
                    this.nextStartIndexBySearchLayerName[key] === -1 &&
                    (key === '' || searchLayerName.indexOf(key) >= 0)
            )
        ) {
            // should already have the required layers
            this.projectLayersStream.next(layers);
            this.projectLayersLoadingStream.next(false);
            return Promise.resolve();
        }

        this.destroySubscription = this.layerService
            .getLayersWithPagination(
                projectId,
                null,
                searchLayerName,
                startIndex,
                this.pageSize,
                this.currentMapWorkspace
            )
            .subscribe(async response => {
                const rawLayers = response.items;
                if (rawLayers && rawLayers.length > 0) {
                    await this.layersStore.updateImportedLayersGeomTypeDict(projectId, rawLayers);
                    const promises: Promise<Layer>[] = rawLayers
                        .filter(layer => !_.some(layers, layer2 => layer.id === layer2.id)) // eliminate already loaded layers
                        .map(rawLayer => this.loadDerivedPropertiesForLayer(rawLayer));
                    let layersWithDerivedProperties = await Promise.all(promises);
                    layersWithDerivedProperties = _.filter(layersWithDerivedProperties, layer_2 => layer_2 != null);
                    layers = _.uniqBy(_.concat(layers, layersWithDerivedProperties), layer_3 => layer_3.id);
                    layers = this.layersStreams.sortLayers(layers);
                    // should not update the projectLayersStream or nextStartIndex if the loading is paused i.e seachLayerName is null
                    if (this.searchLayerNameStream.getValue() !== null) {
                        this.projectLayersStream.next(layers);

                        let newStartIndex = startIndex + this.pageSize;
                        if (newStartIndex < response.total) {
                            // set up to get more
                            this.nextStartIndexBySearchLayerName[searchLayerName] = newStartIndex;
                            this.nextPageRequestStream.next(null); // triggers next page request
                        } else {
                            // all done
                            this.nextStartIndexBySearchLayerName[searchLayerName] = -1;
                            this.projectLayersLoadingStream.next(false);
                        }
                    } else {
                        this.projectLayersLoadingStream.next(false);
                    }
                } else {
                    this.projectLayersLoadingStream.next(false);
                }
                // cancel subscription once API call completes
                if (this.destroySubscription) {
                    this.destroySubscription.unsubscribe();
                }
            });
    }

    private loadDerivedPropertiesForLayer(layer: Layer): Promise<Layer> {
        return this.layersStore.loadGeometryStyleAndTemplateProperties(layer.projectId, layer);
    }

    private pauseLoading(): void {
        this.searchLayerNameStream.next(null); // null => loading is paused
    }
    public setSearchLayerName(searchLayerName: string): void {
        this.searchLayerNameStream.next(searchLayerName.toLowerCase());
    }

    private getLayersFilteredByName(layers: Layer[]): Layer[] {
        let searchLayerName = this.searchLayerNameStream.getValue();
        return layers.filter(layer => layer.layerName.toLowerCase().includes(searchLayerName));
    }

    public handleChangedLayer(layer: Layer): void {
        const isLayerInCache =
            layer && this.projectLayersStream.getValue().findIndex(cachedLayer => layer.id === cachedLayer.id) >= 0;

        // updating the layer library cache only if the layer is already in cache and it has any mapped workspace
        if (layer && layer.workspaces.length > 0 && isLayerInCache) {
            this.addOrUpdateLayerInCurrentProjectLayers(layer);
        }
    }

    private addOrUpdateLayerInCurrentProjectLayers(layer: Layer): void {
        let layers = this.projectLayersStream.getValue() || [];
        let index = layers.findIndex(layer2 => layer2 && layer2.id === layer.id);
        if (index !== -1) {
            layers[index] = layer;
        } else {
            layers = layers.concat([layer]);
        }
        layers = this.layersStreams.sortLayers(layers);
        this.projectLayersStream.next(layers);
    }
}
