<section class="map-provider" (click)="mapProviderContextMenu.onContextMenu($event)">
    <ul class="list-content" id="map-dropdown">
        <li class="list-item" *ngIf="selectedBaseMapMode">
            <div class="layer-sym map-icon-placeholder">
                <i class="{{ selectedBaseMapMode.selectedIconClass }}"></i>
            </div>
            <div class="layer-title">{{ selectedBaseMapMode.label | translate }}</div>
            <div class="layer-view"><i class="i32 icon_line_chevron_right"></i></div>
        </li>
    </ul>

    <gsp-context-menu #mapProviderContextMenu [menuClass]="'map-preview-group'" [items]="contextMenuItems">
    </gsp-context-menu>
</section>
