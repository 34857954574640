import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from, map, mergeMap, switchMap, tap } from 'rxjs';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { EnvironmentService } from 'src/app/shared/common/environment/environment.service';
import { GeneralUtils } from 'src/app/shared/common/utility/general-utils';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';

import _ from 'lodash-es';
import { ImportFromConnectFileResponse } from './file-viewer/fileviewer-import.service';
import { ImportErrorCode, ImportFile, ImportFileMetadata, ImportMessage, ImportStatus } from './import-file';
import { ImportPollingStatusService } from './import-polling-status.service';

export interface IImportStatusResponse {
    countOfGlobalGeometries: number;
    countOfOriginalGeometries: number;
}

@Injectable()
export class ImportService {
    public importStream = new BehaviorSubject<ImportFile[]>([]);
    public activeImportsStream = new BehaviorSubject<ImportFile[]>([]);

    constructor(
        private http: HttpClient,
        private environmentService: EnvironmentService,
        private translate: TranslationService,
        private importPollingStatusService: ImportPollingStatusService
    ) {}

    public import(importFile: ImportFile, workspace: MapWorkspace, formData: FormData): Promise<ImportFile> {
        let importURL =
            this.environmentService.featureApiUrl +
            '/projects/' +
            workspace.projectId +
            '/features/imports?datumComponentId=' +
            workspace.coordinateSystem.datumComponentId;

        return new Promise(resolve => {
            this.http
                .post(importURL, formData, {
                    reportProgress: true,
                    observe: 'events'
                })
                .subscribe({
                    next: (event: HttpEvent<any>) => {
                        // Used Angular EventType References : https://angular.io/api/common/http/HttpEventType
                        switch (event.type) {
                            case HttpEventType.UploadProgress:
                                // Once file inserted to form - start uploading
                                importFile.status = ImportStatus.UPLOADING;
                                importFile.uploadProgress = (event.loaded / event.total) * 100;
                                break;
                            case HttpEventType.ResponseHeader:
                                // Change "Uploading" status to "Processing" after response headers received immnediately, otherwise return error if intermittent occured - (response is still in progess)
                                const headers = event.ok
                                    ? (importFile.status = ImportStatus.PROCESSING)
                                    : this.catchErrorMessages(null, importFile);
                                break;
                            case HttpEventType.DownloadProgress:
                                // if stuck in "Uploading" for too long, change the status to "Processing" when it starts to download response - (response is still in progess)
                                importFile.status = ImportStatus.PROCESSING;
                                break;
                            case HttpEventType.Response:
                                // Full response will be received here. if error occurred, proceed on error block
                                const response = event?.body as ImportFromConnectFileResponse;

                                importFile.id = response.importId;
                                importFile.isComplete = response.isComplete;
                                if (!importFile.isComplete) {
                                    importFile.status = ImportStatus.PROCESSING;
                                } else if (response.messages && response.messages[0]) {
                                    const messages =
                                        response.messages[0].code ===
                                        (ImportErrorCode.ZIPPED_CHILDFILE_MESSAGES ||
                                            ImportErrorCode.ZIPPED_FILEIMPORT_SUMMARY)
                                            ? response.messages[0].messages
                                            : response.messages;

                                    importFile = this.setImportStatus(messages, importFile, response.filename);

                                    if (importFile.status === ImportStatus.WARNING) {
                                        importFile.requestId = response.apiRequestId;
                                    }
                                }

                                importFile.layerNames = response.sourceLayerNames;
                                importFile.styles = response.layerStyles;

                                return resolve(importFile);
                        }
                    },
                    error: error => {
                        return resolve(this.catchErrorMessages(error, importFile));
                    }
                });
        });
    }

    public updateFileStatus(file: ImportFile): void {
        let currentFiles: ImportFile[] = this.importStream.getValue();

        currentFiles.forEach(item => {
            if (file.fileName === item.fileName) {
                item = file;
            }
        });

        this.importStream.next(currentFiles);
    }

    public doImportFiles$(importFiles: ImportFile[], workspace: MapWorkspace): Observable<ImportFile> {
        return from(importFiles).pipe(
            map(importFile => this.appendWorkspaceDetailsToFile(importFile, workspace)),
            mergeMap(importFile =>
                from(new Promise(resolve => resolve(importFile.file))).pipe(
                    switchMap((item: File) => {
                        const formData: FormData = new FormData();
                        formData.append('file', item);
                        formData.append('metadata', JSON.stringify(importFile.metadata));
                        return this.import(importFile, workspace, formData);
                    }),
                    tap(responseFile => {
                        if (responseFile.status !== ImportStatus.ERROR) {
                            this.postAndWatchFileImport(responseFile, workspace.projectId, workspace.id);
                        }
                    })
                )
            )
        );
    }

    private appendWorkspaceDetailsToFile(importFile: ImportFile, currentWorkspace: MapWorkspace): ImportFile {
        importFile.metadata = new ImportFileMetadata();
        importFile.metadata.workspaceId = currentWorkspace.id;
        return importFile;
    }

    private setUnknownErrorMessage(importFile: ImportFile): void {
        importFile.status = ImportStatus.ERROR;
        importFile.messages = [this.translate.instant('TC.Import.Error.UnknownError')];
        this.catchErrorMessages(null, importFile);
    }

    private catchErrorMessages(error: HttpErrorResponse, importFile: ImportFile): ImportFile {
        if (!error) {
            this.setUnknownErrorMessage(importFile);
            return importFile;
        }

        importFile.requestId = error.error?.apiRequestId;
        const errorMessages = (error.error?.messages as ImportMessage[]) || [];
        const messages = errorMessages.flatMap(message =>
            [ImportErrorCode.ZIPPED_CHILDFILE_MESSAGES, ImportErrorCode.ZIPPED_FILEIMPORT_SUMMARY].includes(
                message.code
            )
                ? message.messages
                : [message]
        );
        importFile = this.setError(messages, importFile, error.error.filename);
        return importFile;
    }

    // This function includes translation when getting messages
    private getWarningOrErrorMessages(importMessages: ImportMessage[], filename: string): string[] {
        const errorMessages = importMessages.map((message: ImportMessage) => {
            if (ImportFile.showMessage(message.code)) {
                return ImportFile.getErrorByTranslationResourceId(
                    this.translate,
                    message.code,
                    message.parameters,
                    message.debugMessageText,
                    filename
                );
            } else {
                return null;
            }
        });

        return _.remove(errorMessages, error => !GeneralUtils.isNullUndefinedOrNaN(error));
    }

    private setError(importMessages: ImportMessage[], importFile: ImportFile, filename: string): ImportFile {
        importFile.messages = this.getWarningOrErrorMessages(importMessages, filename);
        importFile.status = ImportStatus.ERROR;
        return importFile;
    }

    private setImportStatus(importMessages: ImportMessage[], importFile: ImportFile, filename: string): ImportFile {
        const hasMessagesToDisplay = importMessages.find(message => ImportFile.showMessage(message.code));
        if (hasMessagesToDisplay) {
            importFile.messages = this.getWarningOrErrorMessages(importMessages, filename);
            importFile.status = importMessages.some(msg =>
                [ImportErrorCode.NO_GEOMETRIES_IN_FILE, ImportErrorCode.NO_FEATURES_IN_FILE].includes(msg.code)
            )
                ? ImportStatus.ERROR
                : ImportStatus.WARNING;
        } else {
            importFile.status = ImportStatus.PROCESSING;
        }

        return importFile;
    }

    private async postAndWatchFileImport(
        importFile: ImportFile,
        projectId: string,
        workspaceId: string
    ): Promise<void> {
        await this.importPollingStatusService.postFeatureImportToMapsApi(importFile, projectId, workspaceId);
        const importItem = await this.importPollingStatusService.getImportById(projectId, importFile.id);
        this.importPollingStatusService.updateImportState(importItem);
    }
}
