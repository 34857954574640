<loading class="loading-icon" *ngIf="loading"></loading>
<div class="folder-selection-component" *ngIf="!loading">
    <div class="folder-selection-header">
        <gsp-button
            *ngIf="currentFolderDetails.parentId"
            class="previous-folder-navigation"
            (action)="loadFolderDetails(currentFolderDetails.parentId)"
            [type]="ButtonType.Icon"
            iconClass="i32 icon_line_chevron_left"
            [buttonTooltip]="'MapViewer.MoveWorkspace.BackNavigation.Tooltip' | translate"
        >
        </gsp-button>
        <div class="current-folder-name">
            {{ currentFolderDetails.name }}
        </div>
    </div>
    <div class="folder-selection-body">
        <custom-scroll class="folder-list">
            <ul class="list-content">
                <li
                    *ngFor="let folder of currentfolderItems"
                    class="list-item"
                    [ngClass]="{ selected: selectedFolderDetails && folder.id === selectedFolderDetails.id }"
                    (click)="selectFolder(folder)"
                    (dblclick)="loadFolderDetails(folder.id)"
                >
                    <i class="icon_solid_folder_closed i24 folder-icon"></i>
                    <div class="folder-name">
                        {{ folder.name }}
                    </div>
                    <gsp-button
                        class="folder-link"
                        (action)="loadFolderDetails(folder.id)"
                        [type]="ButtonType.Icon"
                        iconClass="icon_line_chevron_right i24"
                        [buttonTooltip]="'MapViewer.MoveWorkspace.FolderNavigation.Tooltip' | translate"
                    >
                    </gsp-button>
                </li>
            </ul>
        </custom-scroll>
    </div>
    <div class="folder-selection-footer" *ngIf="selectedFolderDetails">
        <div class="flex-box" *ngIf="selectedFolderDetails">
            {{ selectedFolderLabel }}
            <div class="selected-folder-name">
                {{ selectedFolderDetails.name }}
            </div>
        </div>
        <div class="width-full" *ngIf="isArchiveWorkspace && !selectedFolderDetails">
            <gsp-notification class="info">
                {{ 'MapViewer.MoveWorkspace.CurrentFolder' | translate }}
            </gsp-notification>
        </div>
    </div>
</div>
