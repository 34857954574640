import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { ContextMenuActions } from 'src/app/shared/common/components/context-menu/gsp-context-menu.component';
import { NotificationType } from 'src/app/shared/common/components/gsp-notification/gsp-notification.component';
import { FeaturesStreamsService } from 'src/app/shared/common/current-features/features-streams.service';
import { TaskFeaturesStreamsService } from 'src/app/shared/common/current-features/task-features-streams.service';
import { LayersStore } from 'src/app/shared/common/current-layers/layers-store.service';
import { LayersStreams } from 'src/app/shared/common/current-layers/layers-streams.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { TasksStore } from 'src/app/shared/common/current-tasks/tasks-store.service';
import { TasksStreamsService } from 'src/app/shared/common/current-tasks/tasks-streams.service';
import { CurrentUserStreamService } from 'src/app/shared/common/current-user/current-user-stream.service';
import { Task, TaskDTOStatus, TaskStatus } from 'src/app/shared/common/task/task';
import { UserRole } from 'src/app/shared/user/user';

import { SidePanelStreamsService } from '../../side-panel/side-panel-streams.service';
import { SidePanelName } from '../../side-panel/side-panel.component';

export interface PanelTask extends Task {
    loading?: boolean;
}
@Component({
    templateUrl: './task-panel.component.html',
    selector: 'task-panel'
})
export class TaskPanelComponent implements OnInit, OnDestroy {
    showDataLayers: boolean;
    tasks: PanelTask[] = [];
    activeTasks: PanelTask[] = [];
    loading: boolean;
    currentTab: TaskDTOStatus;
    showDataLayerTitle: string;
    hasVisibleLayers: boolean;
    closedTasks: PanelTask[] = [];
    layersAreLoading: boolean;
    taskSearchControl = new UntypedFormControl();
    isTaskSearchActive = false;
    isTodoVisibilityRestricted = false;

    NotificationType = NotificationType;
    TaskDTOStatus = TaskDTOStatus;

    public closedTaskContextMenuItems: ContextMenuActions[] = [
        {
            name: this.translate.instant('TC.Common.ViewDetails'),
            id: 'view-todo-details',
            title: false,
            visible: () => true,
            execute: ($event: { item: Task }) => this.viewTask($event.item)
        },
        {
            name: this.translate.instant('TC.Common.ReopenToDo'),
            id: 'reopen-todo',
            title: false,
            visible: () => true,
            execute: ($event: { item: Task }) => this.modifyTask($event.item, TaskDTOStatus.ACTIVE)
        }
    ];

    public openTaskContextMenuItems: ContextMenuActions[] = [
        {
            name: this.translate.instant('TC.Common.ViewDetails'),
            id: 'view-todo-details',
            title: false,
            visible: () => true,
            execute: ($event: { item: Task }) => this.viewTask($event.item)
        },
        {
            name: this.translate.instant('TC.Common.CloseToDo'),
            id: 'close-todo',
            title: false,
            visible: () => true,
            execute: ($event: { item: Task }) => this.modifyTask($event.item, TaskDTOStatus.CLOSED)
        }
    ];

    private destroyed = new Subject<void>();

    constructor(
        private tasksStore: TasksStore,
        private tasksStreams: TasksStreamsService,
        private taskFeaturesStreams: TaskFeaturesStreamsService,
        private currentUserStream: CurrentUserStreamService,
        private projectStream: ProjectStreamService,
        private messaging: MessagingService,
        private featuresStreams: FeaturesStreamsService,
        private layersStreams: LayersStreams,
        private layersStore: LayersStore,
        private sidePanelStreams: SidePanelStreamsService,
        private translate: TranslationService
    ) {}

    ngOnInit(): void {
        this.showDataLayers = true;
        this.tasks = [];
        this.activeTasks = [];
        this.loading = true;
        this.currentTab = TaskDTOStatus.ACTIVE;
        this.hasVisibleLayers = false;
        this.layersAreLoading = false;
        this.initialize();
        this.updateDataLayerTitle();
    }

    ngOnDestroy(): void {
        this.taskFeaturesStreams.showDataLayers(true);
        // Hide the visible task features on closing task panel
        const visibleTasks = this.tasks.filter(task => task.visible);
        this.tasksStreams.deferHideTasksStream.next(visibleTasks);
        this.destroyed.next(null);
    }

    private initialize(): void {
        this.tasksStore.mapWorkspaceTasksLoadingStream.pipe(takeUntil(this.destroyed)).subscribe(loadingIds => {
            this.loading = this.loading = !!loadingIds.length;
        });
        this.layersStreams.visibleLayersStream.pipe(takeUntil(this.destroyed)).subscribe(layers => {
            this.hasVisibleLayers = layers.length > 0;
            this.showDataLayers = this.hasVisibleLayers && this.showDataLayers;
            this.updateDataLayerTitle();
        });

        this.tasksStreams.pendingLoadingTaskStream.pipe(takeUntil(this.destroyed)).subscribe(pendingTask => {
            this.activeTasks.forEach((task, index) => {
                if (pendingTask.task.id === task.id) {
                    this.activeTasks[index].loading = pendingTask.loading;
                }
            });
            this.closedTasks.forEach((task, index) => {
                if (pendingTask.task.id === task.id) {
                    this.closedTasks[index].loading = pendingTask.loading;
                }
            });
        });

        this.tasksStore.mapWorkspaceTasksStream.pipe(takeUntil(this.destroyed)).subscribe(tasks => {
            this.tasks = [];
            this.activeTasks = [];
            this.closedTasks = [];

            if (tasks) {
                this.tasks = tasks;
                this.activeTasks = this.tasks.filter(task => task.status !== TaskStatus.CLOSED);
                this.closedTasks = this.tasks.filter(task => task.status === TaskStatus.CLOSED);

                const visibleTasks = this.tasksStreams.visibleTasksStream.getValue();
                if (this.activeTasks && visibleTasks) {
                    this.activeTasks.forEach(tsk => {
                        tsk.visible = visibleTasks.some(visibleTask => visibleTask.todoId === tsk.todoId);
                    });
                }

                this.tasks.forEach(task => {
                    if (task.visible && !task.isClosed) {
                        this.tasksStreams.deferVisibleTasksStream.next([task]);
                    }
                });
            }
        });

        this.layersStreams.loadingLayersStream.pipe(takeUntil(this.destroyed)).subscribe(layers => {
            this.layersAreLoading = layers.length > 0;
        });

        this.tasksStreams.loadTaskStream.pipe(takeUntil(this.destroyed)).subscribe(loadTask => {
            let layerKeyToMapWorkspaceLayer = this.layersStore.getLayerKeyToLayerMap();
            let layerIds: string[] = [];
            loadTask.features.forEach(feature => {
                if (
                    layerKeyToMapWorkspaceLayer[feature.templateSeriesId] &&
                    layerIds.indexOf(layerKeyToMapWorkspaceLayer[feature.templateSeriesId].id) === -1
                ) {
                    layerIds.push(layerKeyToMapWorkspaceLayer[feature.templateSeriesId].id);
                }
                return feature.featureId;
            });
            let findIndex = this.activeTasks.findIndex(task => task.id === loadTask.id);

            if (findIndex === -1) {
                findIndex = this.closedTasks.findIndex(task => task.id === loadTask.id);
                if (findIndex !== -1) {
                    this.setCurrentTab(TaskDTOStatus.CLOSED);
                    this.setSelectedTask(this.closedTasks[findIndex]);
                }
            } else {
                this.setCurrentTab(TaskDTOStatus.ACTIVE);
                this.setSelectedTask(this.activeTasks[findIndex]);
            }
        });

        this.taskSearchControl.valueChanges
            .pipe(takeUntil(this.destroyed), debounceTime(700))
            .subscribe((todoSearchInput: string) => {
                // use isTaskSearchActive to account for input debounce
                this.isTaskSearchActive = todoSearchInput ? true : false;
                this.loadTasksBySearch(todoSearchInput);
            });

        this.updateTodoVisibility();
    }

    loadTasksBySearch(todoSearchInput: string = ''): void {
        const currentTaskStatus = this.currentTab;
        this.tasksStore.loadCurrentWorkspaceTasksBySearch(currentTaskStatus, todoSearchInput);
    }

    toggleShowDataLayers(): void {
        this.showDataLayers = this.hasVisibleLayers && !this.showDataLayers;
        this.updateDataLayerTitle();
    }

    updateDataLayerTitle(): void {
        this.showDataLayerTitle = this.showDataLayers ? 'TCS.Todo.HideData' : 'TCS.Todo.ShowData';
        this.taskFeaturesStreams.showDataLayers(this.showDataLayers);
    }

    setCurrentTab(tab: TaskDTOStatus, forceTabRefresh: boolean = false): void {
        if (this.isTaskSearchActive) {
            this.loadTasksBySearch();
            this.taskSearchControl.setValue('', { emitEvent: false });
            this.isTaskSearchActive = false;
        }

        this.currentTab = tab;
        // refresh both tabs after switching if open/close todo action
        if (forceTabRefresh) {
            this.tasksStore.loadCurrentWorkspaceTasksBySearch(
                this.currentTab === TaskDTOStatus.ACTIVE ? TaskDTOStatus.CLOSED : TaskDTOStatus.ACTIVE,
                ''
            );
            this.loadTasksBySearch();
        }
    }

    toggleVisibleTask(task: PanelTask, event?: MouseEvent): void {
        if (event) {
            event.stopPropagation();
        }
        if (!task.loading) {
            task.visible = !task.visible;

            this.tasksStreams.setVisibleTasks(this.tasks.filter(x => x.visible));

            if (task.visible) {
                this.tasksStreams.deferVisibleTasksStream.next([task]);
            } else {
                this.tasksStreams.deferHideTasksStream.next([task]);
            }
        }
    }

    setSelectedTask(task: PanelTask): void {
        // currently we only allow one task to be selected at a time
        this.tasksStreams.setSelectedTasks([]);
        this.tasks.forEach(otherTask => {
            otherTask.selected = task.id === otherTask.id;
        });
        if (task.selected) {
            if (!task.visible) {
                this.toggleVisibleTask(task);
            }
            let taskFeatureIds = task.features.map(feature => feature.featureId);
            this.featuresStreams.replaceOnSelectedFeatures(taskFeatureIds);
        }
        this.tasksStreams.setSelectedTasks(this.tasks.filter(x => x.selected));
    }

    viewTask(task: PanelTask): void {
        this.sidePanelStreams.closeAndReopenSidePanel(SidePanelName.TASK_DETAIL, {
            taskId: task.id
        });
    }

    private modifyTask(panelTask: PanelTask, newStatus: TaskDTOStatus): void {
        this.loading = true;
        const currentUserId = this.currentUserStream.currentUser.id;
        this.tasksStore
            .getTaskById(panelTask.id)
            .then(task => {
                this.projectStream
                    .getUserWithRolesForCurrentProject(this.currentUserStream.currentUser.id)
                    .then(async user => {
                        if (user.role === UserRole.ADMIN || currentUserId === task.createdBy) {
                            if (newStatus === TaskDTOStatus.CLOSED) {
                                await this.tasksStore.closeTask(task);
                            } else {
                                await this.tasksStore.reopenTask(task);
                            }
                            task.isClosed = newStatus === TaskDTOStatus.CLOSED;
                            task.visible = newStatus === TaskDTOStatus.ACTIVE;

                            this.tasksStreams.setVisibleTasks(this.tasks.filter(x => x.visible));
                            if (task.isClosed) {
                                this.taskFeaturesStreams.getFeaturesByTask(task).then(data => {
                                    task.taskFeatures = data;
                                    this.tasksStreams.deferHideTasksStream.next([task]);
                                });
                            }

                            if (
                                this.sidePanelStreams.isPanelOpen(SidePanelName.TASK_DETAIL) &&
                                this.sidePanelStreams.getOpenPanel(SidePanelName.TASK_DETAIL).params.taskId === task.id
                            ) {
                                this.sidePanelStreams.closeSidePanel(SidePanelName.TASK_DETAIL);
                            }
                            this.setCurrentTab(newStatus, true);
                        } else {
                            this.loading = false;
                            this.messaging.showWarning(this.translate.instant('PERMISSION_DENIED'));
                        }
                    });
            })
            .catch(() => {
                this.loading = false;
                this.messaging.showWarning(this.translate.instant('TCW.UserProfile.Error.WhileFetching'));
            });
    }

    private updateTodoVisibility() {
        this.projectStream.getUserWithRolesForCurrentProject(this.currentUserStream.currentUser.id).then(async user => {
            if (user.role === UserRole.USER) {
                const projectSettings = this.projectStream.getCurrentProject().settings;
                this.isTodoVisibilityRestricted = projectSettings.isTodosRestricted;
            }
        });
    }
}
