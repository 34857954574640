import { Component, Input, Type, ViewChild, ViewContainerRef } from '@angular/core';

import { ModalSize } from '../../modal-sizes';
import { GspWizardService } from './gsp-wizard.service';

@Component({
    selector: 'gsp-wizard',
    templateUrl: './gsp-wizard.component.html'
})
export class GspWizardComponent {
    @ViewChild('stepper', { read: ViewContainerRef, static: true })
    public stepper: ViewContainerRef;

    @Input()
    public size: ModalSize = ModalSize.Small;

    @Input()
    public showProgress = false;

    @Input()
    public set currentStep(step: Type<any>) {
        this.stepper.clear();
        const componentRef = this.stepper.createComponent(step);
        this.wizard.setNavigationHandler(componentRef.instance);
        this.progress = (this.progressionSteps.indexOf(step) + 1) * (100 / this.progressionSteps.length);
    }

    @Input()
    private progressionSteps: Type<any>[] = []; // Used for calculating progress bar

    // expose enum to template
    public ModalSize = ModalSize;

    offlineGnssMode$ = this.wizard.offlineGnssMode$;

    private _progress = 0;

    constructor(private wizard: GspWizardService) {}

    public set progress(value: number) {
        this._progress = value;
    }

    public get progress(): number {
        return this._progress;
    }
}
