import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ActiveFeature } from '../../map-data-services/feature/feature';

@Injectable({
    providedIn: 'root'
})
export class ActiveFeatureStreamsService {
    private _activeFeatureStream = new BehaviorSubject<ActiveFeature>(null);

    public activeFeatureStream: Observable<ActiveFeature> = this._activeFeatureStream.asObservable();

    get activeFeature(): ActiveFeature {
        return this._activeFeatureStream.getValue();
    }

    set activeFeature(value: ActiveFeature) {
        if (value) {
            this._activeFeatureStream.next(value);
        }
    }
}
