export class UUID {
    static readonly empty = '00000000-0000-0000-0000-000000000000';

    static generate(): string {
        let _p8 = (s = false) => {
            let p = (Math.random().toString(16) + '000000000').substr(2, 8);
            return s ? '-' + p.substr(0, 4) + '-' + p.substr(4, 4) : p;
        };
        return _p8() + _p8(true) + _p8(true) + _p8();
    }
}
