import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { TCFile } from 'src/app/shared/common/files/TCFile';
import { ArchiveTagDetails, TCFileService, TCObject, TCObjectType } from 'src/app/shared/common/files/TCFile.service';
import { HttpStatusCodes } from 'src/app/shared/common/http-status-codes';
import { LoaderStreamService } from 'src/app/shared/common/loader/loader-stream.service';
import { ModalSize } from 'src/app/shared/common/modal-sizes';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { MapWorkspaceStatus } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace.types';

import { MainMenuStreamService } from '../../map-viewer/main-menu/main-menu-stream.service';

@Component({
    selector: 'workspace-archive',
    templateUrl: './workspace-archive.component.html'
})
export class WorkspaceArchiveComponent implements OnInit {
    ModalSize = ModalSize;

    activeWorkspace: MapWorkspace = null;
    moveWorkspace = false;
    moveFolderId: string;

    constructor(
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private router: Router,
        private mainMenuStream: MainMenuStreamService,
        private tcFileService: TCFileService,
        private messaging: MessagingService,
        private loading: LoaderStreamService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.activeWorkspace = this.mapWorkspacesStore.archiveEditMapWorkspaceStream.getValue();
    }

    closePanel(): void {
        this.router
            .navigate(['mapViewer', { outlets: { centerDialog: null, mainMenu: 'workspaces' } }], {
                queryParamsHandling: 'preserve'
            })
            .then(() => {
                this.mainMenuStream.showMainMenu$.next(true);
            });
    }

    archiveWorkspace(): void {
        this.loading.isLoading$.next(true);
        if (this.moveWorkspace && this.moveFolderId) {
            this.moveWorkspaceFile()
                .then(() => {
                    this.updateMapWorkspaceStatus().then(() => {
                        this.updateTagForWorkspace()
                            .catch(() => {
                                this.messaging.showError(
                                    this.translate.instant('MapViewer.ArchiveWorkspace.TagError.Label')
                                );
                            })
                            .finally(() => {
                                this.loading.isLoading$.next(false);
                                this.closePanel();
                            });
                    });
                })
                .catch(() => {
                    this.loading.isLoading$.next(false);
                    this.messaging.showError(this.translate.instant('MapViewer.ArchiveWorkspace.MoveError.Label'));
                });
        } else {
            this.updateMapWorkspaceStatus().then(() => {
                this.updateTagForWorkspace()
                    .catch(() => {
                        this.messaging.showError(this.translate.instant('MapViewer.ArchiveWorkspace.TagError.Label'));
                    })
                    .finally(() => {
                        this.loading.isLoading$.next(false);
                        this.closePanel();
                    });
            });
        }
    }

    moveWorkspaceFile(): Promise<TCFile> {
        const fileProperties = {
            parentId: this.moveFolderId
        };
        return this.tcFileService.updateFile(this.activeWorkspace.connectFileId, fileProperties);
    }

    async updateMapWorkspaceStatus(): Promise<void> {
        this.activeWorkspace.status = MapWorkspaceStatus.ARCHIVED;
        const result = await this.mapWorkspacesStore.updateMapWorkspace(this.activeWorkspace, true);
        if (!result.succeeded) {
            this.messaging.showError(this.translate.instant('MapViewer.ArchiveWorkspace.ArchiveError.Label'));
        } else {
            this.messaging.showSuccess(this.translate.instant('MapViewer.ArchiveWorkspace.ArchiveSuccess.Label'));
            this.mapWorkspacesStore.updateMapWorkspaceCache(result.updatedMapWorkspace);
        }
    }

    async updateTagForWorkspace(): Promise<TCObject> {
        try {
            const tag = await this.tcFileService.getTagByLabel(this.activeWorkspace.projectId, ArchiveTagDetails.LABEL);
            const tagObjects = await this.tcFileService.getObjectsForTag(tag.id);
            const isFileTagged = tagObjects.find(tagObject => tagObject.id === this.activeWorkspace.connectFileId);
            if (!isFileTagged) {
                return this.tcFileService.addObjectToTag(tag.id, this.activeWorkspace.connectFileId, TCObjectType.FILE);
            }
        } catch (err) {
            if (err.status === HttpStatusCodes.NOT_FOUND) {
                return this.tcFileService
                    .createTag(this.activeWorkspace.projectId, ArchiveTagDetails.LABEL, ArchiveTagDetails.DESCRIPTION)
                    .then(tag_1 =>
                        this.tcFileService.addObjectToTag(
                            tag_1.id,
                            this.activeWorkspace.connectFileId,
                            TCObjectType.FILE
                        )
                    );
            } else {
                throw err;
            }
        }
    }

    setFolder(folder: TCFile): void {
        if (folder) {
            this.moveFolderId = folder.id;
        } else {
            this.moveFolderId = null;
        }
    }
}
