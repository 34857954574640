import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { EnvironmentService } from '../environment/environment.service';
import { Entitlement } from './entitlement';

@Injectable({
    providedIn: 'root'
})
export class CurrentEntitlementService {
    constructor(private http: HttpClient, private env: EnvironmentService) {}

    public getCurrentEntitlements(): Promise<Entitlement[]> {
        return lastValueFrom(
            this.http.get(this.env.apiUrl + '/entitlement/activations', { params: { current: 'true' } })
        ) as Promise<Entitlement[]>;
    }
}
