import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Map } from 'leaflet';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';

@Component({
    selector: 'zoom-buttons',
    templateUrl: './zoom-buttons.component.html'
})
export class ZoomButtonsComponent implements OnInit, OnDestroy {
    @Input()
    public map: Map;
    // expose ButtonType enum to template
    public ButtonType = ButtonType;
    public increaseLevel = 0;
    public currentZoom: number;

    public ngOnInit(): void {
        this.setCurrentZoom();
        this.map.on('zoomend', this.setCurrentZoom.bind(this));
    }

    ngOnDestroy(): void {
        this.map.off('zoomend', this.setCurrentZoom);
    }

    setCurrentZoom(): void {
        this.currentZoom = this.map.getZoom();
    }

    public zoomDelta(delta: number) {
        this.increaseLevel = delta;
        this.currentZoom = this.map.getZoom() + delta;
        this.map.setZoom(this.currentZoom);
    }
}
