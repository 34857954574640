import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { BehaviorSubject, defer, Observable, Subject } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { ChartPanelStreamService } from '../common/chart-panel-stream.service';
import { SidePanelName } from './side-panel.component';

export interface PanelEntry {
    name: SidePanelName;
    params: any;
}

@Injectable({
    providedIn: 'root'
})
export class SidePanelStreamsService {
    openPanelsStream = new BehaviorSubject<PanelEntry[]>([]);

    // -----
    // The actions queue takes a deferred observable which contains a method that returns a promise.
    // It ensures that each action is executed before the next one is started.
    private actionsQueue = new Subject<Observable<any>>();

    // -----
    // handlers return a boolean which indicates whether the panel can close.
    // they can return a promise that resolves to such a boolean
    private onCloseHandlers: { [key: string]: () => boolean | Promise<boolean> } = {};

    constructor(private chartPanelStreamService: ChartPanelStreamService) {
        this.actionsQueue
            .pipe(concatMap(actionObservable => actionObservable))
            .subscribe(a => this.openPanelsStream.next(a));
    }

    public registerOnCloseHandler(panelName: string, handler: () => boolean | Promise<boolean>): void {
        this.onCloseHandlers[panelName] = handler;
    }

    public deregisterOnCloseHandler(panelName: string): void {
        delete this.onCloseHandlers[panelName];
    }

    public openSidePanel(panelName: string, panelParams?: any[]): Promise<PanelEntry[]> {
        return new Promise((resolve, reject) => {
            const rxDeferred = defer(() => {
                let openPanels = this.openPanelsStream.getValue();
                let newPanels = _.unionBy(
                    [
                        {
                            name: panelName,
                            params: panelParams
                        } as PanelEntry
                    ],
                    openPanels,
                    'name'
                );

                // TODO: GIM 28/5/2019 - make panels open and close through stream event rather than direct element interaction
                // TODO: angular.element('.side-panel').css('z-index', 10);
                // TODO: angular.element('.' + panelName + '_panel').css('z-index', 12);

                resolve(newPanels);
                return Promise.resolve(newPanels);
            });

            this.actionsQueue.next(rxDeferred);
        });
    }

    public closeSidePanel(panelName: string): Promise<PanelEntry[]> {
        return new Promise((resolve, reject) => {
            const rxDeferred = defer(() =>
                Promise.resolve(this.onCloseHandlers[panelName] ? this.onCloseHandlers[panelName]() : true).then(
                    canClose => {
                        let openPanels = this.openPanelsStream.getValue() || [];
                        if (canClose) {
                            let newPanels = openPanels.filter(item => item.name !== panelName);
                            if (
                                panelName === SidePanelName.SELECTED_FEATURES &&
                                this.chartPanelStreamService.isAccuracyPanelDisplayed
                            ) {
                                this.chartPanelStreamService.isAccuracyPanelDisplayed = false;
                            }
                            // TODO: GIM 28/5/2019 - make panels open and close through stream event rather than direct element interaction
                            // TODO:angular.element('.' + panelName + '_panel').css('z-index', 10);

                            resolve(newPanels);
                            return newPanels;
                        } else {
                            resolve(openPanels);
                            return openPanels;
                        }
                    }
                )
            );

            this.actionsQueue.next(rxDeferred);
        });
    }

    public closeAndReopenSidePanel(panelName: string, panelParams: any): void {
        // Added timeout to allow changeDetection run in side panel component before opening side panel again
        this.closeSidePanel(panelName).then(() => setTimeout(() => this.openSidePanel(panelName, panelParams), 0));
    }

    public closeAllSidePanels(): Promise<unknown> {
        let promise = new Promise((resolve, reject) => {
            let canCloseAll = true;
            let rxDeferred = defer(() => {
                let canCloseAllPromises = this.openPanelsStream.getValue().map(panel =>
                    Promise.resolve(this.onCloseHandlers[panel.name] ? this.onCloseHandlers[panel.name]() : true).then(
                        canClose => {
                            canCloseAll = canCloseAll && canClose;
                        }
                    )
                );

                return Promise.all(canCloseAllPromises).then(closeAll => {
                    resolve(closeAll ? [] : this.openPanelsStream.getValue());
                    return promise;
                });
            });
            this.actionsQueue.next(rxDeferred);
        });
        return promise;
    }

    public isAnyPanelOpen(): boolean {
        return this.openPanelsStream.getValue().length > 0;
    }

    public isPanelOpen(panelName: string): boolean {
        return !!this.openPanelsStream.getValue().find(p => p.name === panelName);
    }

    public getOpenPanel(panelName: string): PanelEntry {
        return this.openPanelsStream.getValue().find(p => p.name === panelName);
    }
}
