<label [ngClass]="{ 'disabled-field': !enabled }">
    <span *ngIf="label">{{ label }}</span>
    <gsp-number
        #textInput="ngModel"
        [type]="GspNumberType.GENERIC"
        [inputNgClass]="{
            small: max <= 999,
            medium: max === undefined || (max > 999 && max <= 999999999999),
            large: max > 999999999999
        }"
        name="{{ name }}.!text"
        [placeholder]="placeholder"
        [(ngModel)]="textValue"
        [disabled]="!enabled"
        [required]="propRequired"
        decimalPlaces="{{ decimalPlaces || 0 }}"
    ></gsp-number>
    &nbsp;
    <span *ngIf="postfixLabel">{{ postfixLabel }}</span>
</label>
