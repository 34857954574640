import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { EnvironmentService } from 'src/app/shared/common/environment/environment.service';
import { FullFeatureFilter, Op } from 'src/app/shared/common/feature-filter/full-feature-filter';
import { FeatureService } from 'src/app/shared/map-data-services/feature/feature.service';
import { Layer, LayerType } from 'src/app/shared/map-data-services/layer/layer';

import { ImportFile, ImportMessage, ImportStatus, ViewFile } from '../import-file';
import { IImportStatusResponse } from '../import.service';

@Injectable()
export class FileViewerImportService {
    public fileViewerImportStream = new BehaviorSubject<ViewFile[]>([]);
    public fileViewerLayersStream = new BehaviorSubject<Layer[]>([]);
    public fileViewerActiveImportsStream = new BehaviorSubject<ViewFile[]>([]);
    public importedLayerIndex = 0;

    constructor(
        private http: HttpClient,
        private environmentService: EnvironmentService,
        private featureService: FeatureService,
        private translate: TranslationService
    ) {}

    public doImportFilesToView(projectId: string, mapWorkspaceId: string, files: ViewFile[]): Promise<Layer[]> {
        let promises: Promise<Layer[]>[] = [];
        this.importedLayerIndex = 0;
        files.forEach(file => {
            promises.push(this.getLayersFromFile(projectId, mapWorkspaceId, file.connectFileId));
        });

        return Promise.all(promises).then(res => {
            let layers: Layer[] = [];
            res.forEach(item => {
                if (item) {
                    layers = layers.concat(item);
                }
            });
            return layers;
        });
    }

    private getLayersFromFile(projectId: string, mapWorkspaceId: string, fileVersionId: string): Promise<Layer[]> {
        let filterLayer = new Layer();
        filterLayer.fileVersionId = fileVersionId;
        let fullFeatureFilter = new FullFeatureFilter();
        fullFeatureFilter.layers = [filterLayer];

        let layers: Layer[] = [];
        // Check if there is data already in the feature service for this file first
        return this.featureService
            .getDistinctFeatureValues(projectId, fullFeatureFilter, 'metadata.file_sourceLayer')
            .then(distinctLayerSourceNames => {
                if (distinctLayerSourceNames && distinctLayerSourceNames.length) {
                    distinctLayerSourceNames.forEach(name => {
                        layers.push(
                            this.createImportedLayer(
                                projectId,
                                mapWorkspaceId,
                                name,
                                fileVersionId,
                                this.importedLayerIndex
                            )
                        );
                        this.importedLayerIndex++;
                    });

                    this.updateFileStatusFileViewer(fileVersionId, ImportStatus.DONE, null, null, null, null);
                    return layers;
                } else {
                    return this.importFromConnectFile(fileVersionId, projectId).then(
                        response => {
                            if (response.isComplete) {
                                response.sourceLayerNames.forEach(name => {
                                    layers.push(
                                        this.createImportedLayer(
                                            projectId,
                                            mapWorkspaceId,
                                            name,
                                            fileVersionId,
                                            this.importedLayerIndex
                                        )
                                    );
                                    this.importedLayerIndex++;
                                });

                                this.updateFileStatusFileViewer(
                                    fileVersionId,
                                    ImportStatus.DONE,
                                    response.messages ? response.messages : null,
                                    response.apiRequestId ? response.apiRequestId : null,
                                    response.filename,
                                    response.importId
                                );
                                return layers;
                            } else {
                                this.updateFileStatusFileViewer(
                                    fileVersionId,
                                    ImportStatus.INCOMPLETE,
                                    null,
                                    response.apiRequestId,
                                    response.filename,
                                    response.importId,
                                    response.sourceLayerNames
                                );
                                return null;
                            }
                        },
                        error => {
                            this.updateFileStatusFileViewer(
                                fileVersionId,
                                ImportStatus.ERROR,
                                error.error.messages,
                                error.error.apiRequestId,
                                error.error.filename,
                                null
                            );
                            return [];
                        }
                    );
                }
            });
    }

    public createImportedLayer(
        projectId: string,
        workspaceId: string,
        name: string,
        fileVersionId: string,
        index: number
    ): Layer {
        let layer = Layer.fromDTO({
            id: 'imported_' + index + '_' + index,
            layerName: name.replace('-', ' '),
            sourceLayerName: name,
            fileVersionId: fileVersionId,
            layerType: LayerType.IMPORTED_LAYER,
            layerCreationStatus: 'Completed'
        });
        layer.isLayerFromFile = true;
        layer.visible = true;
        layer.workspaceId = workspaceId;
        layer.projectId = projectId;
        return layer;
    }

    private importFromConnectFile(fileId: string, projectId: string): Promise<ImportFromConnectFileResponse> {
        let formData: FormData = new FormData();
        formData.append('metadata', '{}');

        return lastValueFrom(
            this.http.post(
                this.environmentService.featureApiUrl + '/projects/' + projectId + '/features/imports',
                formData,
                {
                    params: { fileId }
                }
            )
        ) as Promise<ImportFromConnectFileResponse>;
    }

    private updateFileStatusFileViewer(
        fileId: string,
        status: ImportStatus,
        messages: ImportMessage[],
        apiRequestId: string,
        fileName: string,
        id: string,
        layerNames: string[] = []
    ): void {
        let files = this.fileViewerImportStream.value;

        files.forEach((item: ViewFile) => {
            if (item.connectFileId === fileId) {
                item.layerNames = layerNames;
                item.status =
                    status === ImportStatus.DONE && messages && messages.length ? ImportStatus.WARNING : status;

                item.id = id ? id : null;
                item.fileName = fileName ? fileName : '';

                if (item.status === ImportStatus.WARNING || item.status === ImportStatus.ERROR) {
                    item = this.setStatusAndErrorOrWarning(messages, item, item.status, fileName);
                    item.requestId = apiRequestId;
                }
            }
        });

        this.fileViewerImportStream.next(files);
    }

    private setStatusAndErrorOrWarning(
        importResult: ImportMessage[],
        viewFile: ViewFile,
        currentStatus: ImportStatus,
        fileName: string
    ): ViewFile {
        if (currentStatus === ImportStatus.WARNING) {
            const hasWarningsToDisplay = importResult.find(message => ImportFile.showMessage(message.code));
            if (hasWarningsToDisplay) {
                let errorMessages = importResult.map((message: ImportMessage) =>
                    ImportFile.getErrorByTranslationResourceId(
                        this.translate,
                        message.code,
                        message.parameters,
                        message.debugMessageText,
                        fileName
                    )
                );
                viewFile.errorMessages = errorMessages;
            }
        } else {
            let errorMessages = importResult.map((message: ImportMessage) =>
                ImportFile.getErrorByTranslationResourceId(
                    this.translate,
                    message.code,
                    message.parameters,
                    message.debugMessageText,
                    fileName
                )
            );
            viewFile.errorMessages = errorMessages;
        }

        return viewFile;
    }

    public getFileImportProgress(importFile: ViewFile, projectId: string): Promise<number> {
        const filter = new FullFeatureFilter();
        const filterExpression = filter.buildFilterRequest();
        filterExpression.push(filter.filter(Op.EQ, 'metadata.importId', importFile.id));
        return lastValueFrom(
            this.http.get<IImportStatusResponse>(
                this.environmentService.featureApiUrl + '/projects/' + projectId + '​/features/imports/status',
                {
                    params: { filter: JSON.stringify(filterExpression) }
                }
            )
        ).then(res => (res.countOfGlobalGeometries / res.countOfOriginalGeometries) * 100);
    }
}

export interface ImportFromConnectFileResponse {
    messages: ImportMessage[];
    apiRequestId: string;
    filename: string;
    fileVersionId: string;
    serverDateTime: Date;
    isComplete: boolean;
    sourceLayerNames: string[];
    layerStyles: string[];
    importId: string;
}
