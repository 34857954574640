import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import {
    ContextMenuActions,
    GspContextMenuComponent,
} from 'src/app/shared/common/components/context-menu/gsp-context-menu.component';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { MapWorkspaceStatus } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace.types';

import { WorkspaceService } from '../workspace.service';

@Component({
    selector: 'workspace-list',
    templateUrl: './workspace-list.component.html'
})
export class WorkspaceListComponent {
    @Input()
    contextMenuItems: ContextMenuActions[];

    @Input()
    currentWorkspace: MapWorkspace = null;

    @Input()
    loading: boolean;

    @Input()
    noWorkspacesMessage = '';

    @Input()
    scrollToTop: boolean;

    @Input()
    workspaces: MapWorkspace[] = [];

    @Output()
    changeWorkspace: EventEmitter<MapWorkspace> = new EventEmitter();

    @ViewChild('workspaceContextMenu')
    workspaceContextMenu: GspContextMenuComponent;

    // expose ButtonType enum to template
    public ButtonType = ButtonType;
    public MapWorkspaceStatus = MapWorkspaceStatus;

    constructor(private workspaceService: WorkspaceService) {}

    public getThumbnailUrl(workspace: MapWorkspace): string {
        return this.workspaceService.getThumbnailUrl(workspace);
    }

    public openContextMenu(event: MouseEvent, workspace: MapWorkspace): void {
        this.workspaceContextMenu.onContextMenu(event, workspace);
    }
}
