import { TrimbleBaseMapMode } from 'src/app/feature/map-viewer/base-maps/base-map-provider';

export enum MapCacheStyle {
    STREET_VIEW = 'StreetView',
    SATELLITE_VIEW = 'SatelliteView'
}

export const BaseMapModeMapCacheStyleMap: { [key: string]: MapCacheStyle } = {
    [TrimbleBaseMapMode.MAP]: MapCacheStyle.STREET_VIEW,
    [TrimbleBaseMapMode.MAP_LIGHTNESS]: MapCacheStyle.STREET_VIEW,
    [TrimbleBaseMapMode.MAP_DARKNESS]: MapCacheStyle.STREET_VIEW,
    [TrimbleBaseMapMode.MAP_TERRAIN]: MapCacheStyle.STREET_VIEW,
    [TrimbleBaseMapMode.DIGITAL_GLOBE]: MapCacheStyle.SATELLITE_VIEW
};

export class MapCache {
    // DTO properties
    id: string = null;
    name: string = null;
    // fileSize: number;
    // fileName: string;
    // jobToken: string;
    // status: string;
    // mD5: string;
    // compressed: boolean;
    featureJSON: string;
    // createdUtc: Date;
    // updatedUtc: Date;
    // workspaceIds: string[]
    baseMapStyle: MapCacheStyle;

    // Added properties

    selected = false;
    visible = false;
    mapWorkspaceId: string = null;
}
