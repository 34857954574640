<div
    class="modal-popup"
    [ngClass]="{
        'small-modal': size === ModalSize.Small,
        'medium-modal': size === ModalSize.Medium,
        'offline-gnss-wizard-mode': offlineGnssMode$ | async
    }"
>
    <div class="modal-content">
        <ng-content select="[transclude-wizard-header]"></ng-content>
        <div class="modal-body">
            <custom-scroll class="height-full">
                <ng-container #stepper></ng-container>
            </custom-scroll>
        </div>

        <mat-progress-bar
            class="wizard-progress"
            [mode]="'determinate'"
            [value]="progress"
            [hidden]="!showProgress"
        ></mat-progress-bar>

        <ng-content select="[transclude-wizard-footer]"></ng-content>
    </div>
</div>
