import 'pikaday';

import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { DateUtils } from '../utility/date-utils';
import { ValueAccessorBase } from './value-accessor-base';

declare let Pikaday: any;

/**
 * Pikaday date picker
 * <date-picker [(ngModel)]="date"></datepicker>
 *
 */

@Component({
    selector: 'date-picker',
    template: `
        <div class="pikaday-wrapper">
            <input
                type="text"
                class="gsp-form-input"
                #pikadayDatepicker
                [name]="name"
                placeholder="{{ placeholder }}"
                [disabled]="!enabled"
                (keyup)="onKeyInput()"
            />
            <span class="pikaday-icon"><i class="icon_line_date i24"></i></span>
        </div>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: DatePickerComponent,
            multi: true
        }
    ]
})
export class DatePickerComponent extends ValueAccessorBase<string | Date> implements OnInit, OnChanges, OnDestroy {
    @ViewChild('pikadayDatepicker', { static: true })
    datepicker: ElementRef;

    public _id = '';
    @Input()
    public set id(id: string) {
        if (id) {
            this._id = id + '-pikaday-picker';
        }
    }
    public get id(): string {
        return this._id;
    }

    @Input()
    public name = '';

    @Input()
    public minDate: Date = null;

    @Input()
    public maxDate: Date = null;

    @Input()
    public numberOfMonths: number = null;

    @Input()
    public placeholder = '';

    @Input()
    public enabled = true;

    @Input()
    public forceUTC = false;

    public pikaday: Pikaday;

    constructor() {
        super();
    }

    innerChanged(value: Date): void {
        if (value) {
            this.pikaday.setDate(this.getDate(value), true);
        } else {
            this.pikaday.setDate(null);
        }
    }

    ngOnInit(): void {
        this.pikaday = new Pikaday({
            disableWeekends: false,
            field: this.datepicker.nativeElement,
            format: 'medium',
            toString: (date: Date, format: string) => DateUtils.formatDateFromDateTime(date, format, false),
            minDate: this.getDate(this.minDate),
            maxDate: this.getDate(this.maxDate),
            numberOfMonths: this.numberOfMonths,
            onSelect: (date: Date) => {
                if (date) {
                    this.value = this.forceUTC ? DateUtils.forceUtc(date).toISOString() : date;
                }
            },
            onOpen: () => {
                this.setDateRange();
            }
        });

        // add id to pikaday if set
        if (this.id) {
            this.pikaday.el.setAttribute('id', this._id);
        }
    }

    ngOnDestroy(): void {
        this.pikaday.destroy();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.minDate && this.pikaday) {
            this.pikaday.setMinDate(this.getDate(this.minDate));
        }
        if (changes.maxDate && this.pikaday) {
            this.pikaday.setMaxDate(this.getDate(this.maxDate));
        }
    }

    private getDate(value: Date): Date {
        if (value) {
            return DateUtils.getDate(value);
        }
        return null;
    }

    private setDateRange(): void {
        if (this.minDate) {
            this.pikaday.setMinDate(this.getDate(this.minDate));
        } else {
            this.pikaday.setMinDate(null);
        }

        if (this.maxDate) {
            this.pikaday.setMaxDate(this.getDate(this.maxDate));
        } else {
            this.pikaday.setMaxDate(null);
        }
    }

    public onKeyInput(): void {
        // set the date and value as null when the user clears the input with backspace
        if (this.datepicker.nativeElement.value === '') {
            this.pikaday.setDate(null);
            this.value = null;
            this.setDateRange();
        }
    }
}
