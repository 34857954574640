/* eslint-disable */

// A hacky fix to make L.Draggable ignore the SHIFT key when dragging
// this overriding method modifies the event so that SHIFT key appears false, then calls original method
// it is required to support the SHIFT drag Rectangle when selecting features

'use strict';
(function(factory, window) {
    /*globals define, module, require*/

    // define an AMD module that relies on 'leaflet'
    if (typeof define === 'function' && define.amd) {
        define(['leaflet'], factory);

        // define a Common JS module that relies on 'leaflet'
    } else if (typeof exports === 'object') {
        module.exports = factory(require('leaflet'));
    }

    // attach your plugin to the global 'L' variable
    if (typeof window !== 'undefined' && window.L) {
        factory(window.L);
    }
})(function(L) {
    var _oldOnDown = L.Draggable.prototype._onDown;

    L.Draggable.include({
        _onDown: function(e) {
            var e2 = cloneEvent(e);
            e2.shiftKey = false;
            return _oldOnDown.bind(this)(e2);
        }
    });

    function cloneEvent(e) {
        if (e === undefined || e === null) return undefined;
        function ClonedEvent() {}
        let clone = new ClonedEvent();
        for (let p in e) {
            let d = Object.getOwnPropertyDescriptor(e, p);
            if (d && (d.get || d.set)) Object.defineProperty(clone, p, d);
            else clone[p] = e[p];
        }
        Object.setPrototypeOf(clone, e);
        return clone;
    }
}, window);
