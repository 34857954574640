import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { Domains, GeometryType } from 'src/app/feature/template-editor/template/domains';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { GspTextType } from 'src/app/shared/common/components/gsp-text/gsp-text.component';
import { LayersStore } from 'src/app/shared/common/current-layers/layers-store.service';
import { LayersStreams } from 'src/app/shared/common/current-layers/layers-streams.service';
import { StylesStore } from 'src/app/shared/common/current-layers/styles-store.service';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';
import { Style } from 'src/app/shared/map-data-services/styles/style';
import { TemplateService } from 'src/app/shared/template-services/template.service';

@Component({
    templateUrl: 'edit-layer.component.html',
    selector: 'edit-layer'
})
export class EditLayerComponent implements OnInit, OnDestroy {
    public disableSave = true;
    public originalLayer: Layer;
    public layer: Layer;
    public availableColors: { id: number; color: string }[] = null;
    public geoTypes: GeometryType[] = null;
    // expose ButtonType enum to template
    public ButtonType = ButtonType;
    public GspTextType = GspTextType;
    private destroyed = new Subject<void>();

    constructor(
        private layersStreams: LayersStreams,
        private layersStore: LayersStore,
        private templateService: TemplateService,
        private stylesStore: StylesStore,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private translate: TranslationService
    ) {}

    public ngOnInit(): void {
        this.templateService.getAvailableTemplateColors().then(colors => {
            this.availableColors = colors;
        });

        this.geoTypes = Domains.getGeometryTypes();

        this.layersStreams.currentEditLayerStream.pipe(takeUntil(this.destroyed)).subscribe(layer => {
            if (layer && layer.id) {
                this.originalLayer = _.cloneDeep(layer);
                this.layer = _.cloneDeep(layer);
                this.disableSave = false;
            } else if (this.activatedRoute.snapshot.queryParamMap.get('layerId')) {
                this.layersStore
                    .getMapWorkspaceLayerByIdPromise(this.activatedRoute.snapshot.queryParamMap.get('layerId'))
                    .then(newLayer => {
                        this.originalLayer = _.cloneDeep(newLayer);
                        this.layer = _.cloneDeep(newLayer);
                        this.disableSave = false;
                    });
            }
        });
    }

    public ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    public getErrorText(errors: object): string {
        const isRequiredError = _.filter([errors], 'required');
        if (isRequiredError.length) {
            return this.translate.instant('RequiredError');
        }
        return null;
    }

    public get loading(): boolean {
        return !this.layer || !this.availableColors;
    }

    public isSaveDisabled(): boolean {
        return this.disableSave || _.isEqual(this.originalLayer, this.layer);
    }

    public saveLayer(): void {
        this.disableSave = true;
        let layer = this.layer;
        this.stylesStore.getOrCreateStyleByColor(layer.projectId, layer.color).then((style: Style) => {
            let newLayer = this.layer.toDTO();
            newLayer.styleId = style.id;
            this.layersStore.updateLayer(layer.projectId, newLayer.id, newLayer).then(() => {
                this.closeEditPanel();
            });
        });
    }

    public closeEditPanel(): void {
        this.router.navigate(['mapViewer'], { queryParams: { layerId: null }, queryParamsHandling: 'preserve' });
    }
}
