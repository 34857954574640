import * as _ from 'lodash-es';
import { TranslationService } from 'src/app/core/translation/translation.service';

import { Condition, ConditionOperator } from './condition';

export class OtherCondition extends Condition {
    static readonly otherConditionsText = [
        {
            name: 'TC.Common.IsAnswered',
            value: ConditionOperator.IS_ANSWERED
        }
    ];

    // Othercondition - which is used for fields other than YesNo, Choice, Text, Number, Daate.
    constructor(uuid: string, private operator = ConditionOperator.IS_ANSWERED) {
        super(uuid, 'Other');
    }

    getErrorMessagesNo(): number[] {
        return [];
    }

    // Get the condition text which is displayed in sidebar.
    getConditionText(translator: TranslationService, value?: string): string {
        value = value || this.operator;
        let conditionPair = _.find(
            OtherCondition.otherConditionsText,
            otherCondition => otherCondition.value === value
        );
        return translator.instant(conditionPair.name);
    }

    // Set the operator (future use).
    setOperator(operator: ConditionOperator): void {
        this.operator = operator || ConditionOperator.IS_ANSWERED;
    }

    // Returns the list of operators used for this condition.
    getConditionPair(): {
        name: string;
        value: string;
    }[] {
        return OtherCondition.otherConditionsText;
    }
}
