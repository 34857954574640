import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import {
    ACCESS_MODE_KEY,
    AccessMode,
    SHARED_MODE_KEY,
    SHARED_TOKEN_KEY,
    ShareMode,
} from 'src/app/core/authentication/share-token';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { TemplatesStoreService } from 'src/app/shared/common/current-templates/templates-store.service';
import { CurrentUserStreamService } from 'src/app/shared/common/current-user/current-user-stream.service';
import { LoaderStreamService } from 'src/app/shared/common/loader/loader-stream.service';
import {
    EXPORT_POLL_DETAILS_KEY,
    ExportPollDetails,
    ExportPollingStatusService,
    PollingType,
} from 'src/app/shared/common/polling/export-polling-status.service';
import { UtilitiesService } from 'src/app/shared/common/utility/utilities.service';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { Project } from 'src/app/shared/map-data-services/project/project';
import { User } from 'src/app/shared/user/user';

import { ChartPanelStreamService } from './common/chart-panel-stream.service';

export interface IMapRouteData {
    workspace: MapWorkspace;
    project: Project;
    user: User;
}

export const mapRouteResolver: ResolveFn<IMapRouteData> = async (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    currentUserStream: CurrentUserStreamService = inject(CurrentUserStreamService),
    projectStream: ProjectStreamService = inject(ProjectStreamService),
    mapWorkspacesStore: MapWorkspacesStoreService = inject(MapWorkspacesStoreService),
    loaderStreamService: LoaderStreamService = inject(LoaderStreamService),
    exportPollingStatusService: ExportPollingStatusService = inject(ExportPollingStatusService),
    messaging: MessagingService = inject(MessagingService),
    utilities: UtilitiesService = inject(UtilitiesService),
    translate: TranslationService = inject(TranslationService),
    templateStore: TemplatesStoreService = inject(TemplatesStoreService),
    chartPanelStreamService: ChartPanelStreamService = inject(ChartPanelStreamService)
) => {
    if (sessionStorage.getItem(ACCESS_MODE_KEY) === AccessMode.SHARED) {
        if (
            sessionStorage.getItem(SHARED_MODE_KEY) === ShareMode.PUBLIC_USER ||
            sessionStorage.getItem(SHARED_MODE_KEY) === ShareMode.SIGNED_IN_USER
        ) {
            return Promise.resolve({
                project: projectStream.getCurrentProject(),
                workspace: mapWorkspacesStore.getCurrentMapWorkspace(),
                user: currentUserStream.currentUser
            });
        }
    }
    const project = await projectStream.initCurrentProject(route.queryParams.projectId);
    sessionStorage.removeItem(ACCESS_MODE_KEY);
    sessionStorage.removeItem(SHARED_TOKEN_KEY);
    sessionStorage.removeItem('tcAccessToken');
    projectStream.cacheProjectUsersAndGroups(project);
    const workspace = await mapWorkspacesStore.initCurrentMapWorkspace(project.id, route.queryParams.workspaceId, null);
    loaderStreamService.isLoading$.next(false);
    await checkExportPolling(route, exportPollingStatusService);
    if (!workspace) {
        messaging.showError(translate.instant('TCS.Workspace.ErrorLoading'));
        return {
            workspace: null,
            project: null,
            user: null
        };
    } else {
        utilities.setTitle(workspace.name, true);
        const currentWorkspace = mapWorkspacesStore.getCurrentMapWorkspace();
        templateStore.loadWorkspaceTemplates(currentWorkspace);
        chartPanelStreamService.isAccuracyPanelDisplayed = false;
        return {
            workspace: currentWorkspace,
            project: project,
            user: currentUserStream.currentUser
        };
    }
};

export const checkExportPolling = (
    route: ActivatedRouteSnapshot,
    exportPollingStatusService: ExportPollingStatusService
): void => {
    exportPollingStatusService.clearAllPolling();
    const exportPollDetails = sessionStorage.getItem(EXPORT_POLL_DETAILS_KEY);
    if (exportPollDetails) {
        const exportPollArray = JSON.parse(exportPollDetails) as ExportPollDetails[];
        exportPollArray.forEach(exportPollObject => {
            if (exportPollObject.workspaceId === route.queryParams.workspaceId) {
                exportPollingStatusService.startPolling(
                    PollingType.EXPORT,
                    exportPollObject.projectId,
                    exportPollObject.id,
                    exportPollObject.toastId,
                    exportPollObject.message
                );
            }
        });
    }
};
