import { Component } from '@angular/core';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { MapWorkspacePermissionType } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace-permission';

import { MainMenuStreamService } from '../../main-menu/main-menu-stream.service';

@Component({
    selector: 'map-main-menu',
    templateUrl: './map-main-menu.component.html'
})
export class MapMainMenuComponent {
    // expose ButtonType enum to template
    public ButtonType = ButtonType;
    public MapWorkspacePermissionType = MapWorkspacePermissionType;

    constructor(private mainMenuStreamService: MainMenuStreamService) {}

    public openMainMenu(): void {
        this.mainMenuStreamService.showMainMenu$.next(true);
    }
}
