import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { LayersStore } from 'src/app/shared/common/current-layers/layers-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { TemplatesStoreService } from 'src/app/shared/common/current-templates/templates-store.service';
import { ModalSize } from 'src/app/shared/common/modal-sizes';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';
import { Template } from 'src/app/shared/template-services/template';
import { TemplateService } from 'src/app/shared/template-services/template.service';

import { TemplateStoreForComponent } from '../../template-editor/template/template-store-for-component.service';

@Component({
    templateUrl: 'discard-draft-changes.component.html',
    selector: 'discard-draft-changes'
})
export class DiscardDraftChangesComponent implements OnInit {
    public loading = true;
    public layer: Layer;
    public latestPublishedTemplate: Template;
    public showErrorModal = false;
    // expose enum to template
    public ModalSize = ModalSize;

    private projectId: string;

    constructor(
        private templatesStore: TemplatesStoreService,
        private templateStoreForComponent: TemplateStoreForComponent,
        private layersStore: LayersStore,
        private templateService: TemplateService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private projectStream: ProjectStreamService,
        private messagingService: MessagingService,
        private translate: TranslationService
    ) {}

    async ngOnInit(): Promise<void> {
        try {
            const layerId = this.activatedRoute.snapshot.queryParamMap.get('layerId');
            this.layer = await this.layersStore.getMapWorkspaceLayerByIdPromise(layerId);
            this.projectId = this.projectStream.getCurrentProject().id;
            this.latestPublishedTemplate = await this.templateService.getLatestPublishedTemplateBySeriesId(
                this.projectId,
                this.layer.templateSeriesId
            );
            this.showErrorModal = !this.latestPublishedTemplate;
        } catch (error) {
            this.showErrorModal = true;
        } finally {
            this.loading = false;
        }
    }

    public closeModal(): void {
        this.router.navigate(['mapViewer'], { queryParams: { layerId: null }, queryParamsHandling: 'preserve' });
    }

    private async discardDraftChanges(): Promise<void> {
        try {
            // Update template
            const { attachedToWorkspaces, attachedToLayers, layerAttachedWorkspaces, layersWithWorkspaces } =
                await this.templatesStore.determineLayersbyTemplateId(this.projectId, this.layer.templateId);
            const newTemplate: Template = Object.assign(this.latestPublishedTemplate, {
                id: this.layer.templateId,
                attachedToWorkspaces,
                attachedToLayers,
                layerAttachedWorkspaces,
                layersWithWorkspaces
            });
            const updatedTemplate = await this.templateStoreForComponent.updateTemplate(newTemplate, true);

            // Update layers associated with template
            layerAttachedWorkspaces.forEach(workspace => {
                this.layersStore.removeLayersIndexByMapWorkspace(workspace.id);
            });
            await Promise.all(
                layersWithWorkspaces.map(layer =>
                    this.layersStore
                        .updateLayer(
                            this.projectId,
                            layer.id,
                            this.layersStore.constructLayerFromTemplate(updatedTemplate, layer)
                        )
                        .then(updatedLayer =>
                            this.layersStore.refreshLayerInStore(this.projectId, updatedLayer, updatedLayer.visible)
                        )
                )
            );
            this.templateStoreForComponent.setCurrentEditedTemplate(null);

            this.messagingService.showSuccess(this.translate.instant('MapViewer.Template.DiscardDraft.Success'));
        } catch (error) {
            this.messagingService.showError(this.translate.instant('MapViewer.Template.DiscardDraft.Error'));
        }
    }

    public discardDraftChangesAction = async (): Promise<void> => {
        await this.discardDraftChanges();
        this.closeModal();
    };
}
