<div class="upload-status" *ngIf="activeImports.length > 0" [ngStyle]="{'height': activeImports.length > 4 ? '188px' : 'auto'}">
    <div class="active-upload-title">
        <h2>{{ 'MapViewer.UploadStatus.FIlesProcessingMessage' | translate }}</h2>
    </div>
    <custom-scroll *ngIf="activeImports.length > 4; else fileList">
        <ng-container *ngTemplateOutlet="fileList"></ng-container>
    </custom-scroll>
</div>

<ng-template #fileList>
    <ul class="upload-file-list">
        <li class="text-overflow" title="{{ f.fileName }}" *ngFor="let f of activeImports">
            {{ f.fileName }}
            <mat-progress-bar class="progress-success" [mode]="'determinate'" [value]="f.processingProgress"> </mat-progress-bar>
        </li>
    </ul>
</ng-template>
