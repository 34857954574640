import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { User } from 'src/app/shared/user/user';

@Component({
    selector: 'gsp-user-tab',
    templateUrl: './gsp-user-tab.component.html'
})
export class GspUserTabComponent implements OnDestroy {
    @Input()
    user: User = null;

    @Input()
    showUserTab: boolean;

    @Output()
    hideUserTab = new EventEmitter();

    private destroyed = new Subject();

    constructor(private authenticationService: AuthenticationService) {}

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    signOutUser(): void {
        this.authenticationService.logout();
    }
}
