import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LayersStreams } from 'src/app/shared/common/current-layers/layers-streams.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { StringUtils } from 'src/app/shared/common/utility/string-utils';
import { FeatureService } from 'src/app/shared/map-data-services/feature/feature.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MapContentMonitor {
    // the timing of directive construction/destruction means we need to track monitoring requests
    private lastClientId = 0;
    private currentClientIds: number[] = [];
    private projectLastFeatureUpdatedUtc: number = null;
    private lastManualRefreshUtc: number = null;

    private stopTimer = new Subject<void>();

    // stream to indicate whether or not the map contents is likely to need refreshing
    currentMapContentStream = new BehaviorSubject(false);

    // stream to force refresh map contents
    refreshMapContentStream = new Subject();

    constructor(
        private projectStream: ProjectStreamService,
        private mapWorkspaceStore: MapWorkspacesStoreService,
        private layersStreams: LayersStreams,
        private featureService: FeatureService
    ) {}

    public currentMapContentNeedsRefresh(needsRefresh: boolean): void {
        this.currentMapContentStream.next(needsRefresh);
    }

    // ----------------

    // map content monitoring

    public startMonitoring(workspaceId: string): number {
        if (this.currentClientIds.length === 0) {
            this.currentMapContentNeedsRefresh(false);
            this.doMonitoring();
        }
        this.lastClientId++;
        this.currentClientIds.push(this.lastClientId);
        return this.lastClientId;
    }

    public stopMonitoring(clientId: number): void {
        let index = this.currentClientIds.indexOf(clientId);
        if (index >= 0) {
            this.currentClientIds.splice(index, 1);
        }
        if (this.currentClientIds.length === 0) {
            this.cancelTimer();
        }
    }

    public mapRefreshed(manualRefresh = false): void {
        if (manualRefresh) {
            this.lastManualRefreshUtc = Date.now();
        }
        if (this.currentClientIds.length > 0) {
            this.currentMapContentNeedsRefresh(false);
            this.doMonitoring();
        }
    }

    private doMonitoring(): Promise<void> {
        this.cancelTimer();
        let restartTimer = true;
        return this.getNeedsRefresh()
            .then(needsRefresh => {
                if (needsRefresh) {
                    restartTimer = false;
                    this.currentMapContentNeedsRefresh(needsRefresh);
                }
            })
            .finally(() => {
                if (restartTimer) {
                    this.startTimer();
                }
            });
    }

    private getNeedsRefresh(): Promise<boolean> {
        let project = this.projectStream.getCurrentProject();
        let workspace = this.mapWorkspaceStore.getCurrentMapWorkspace();
        let visibleLayers = this.layersStreams.visibleLayersStream.getValue();
        if (!project || !workspace || !visibleLayers.length) {
            return Promise.resolve(false);
        } else {
            if (visibleLayers && visibleLayers.length) {
                return this.featureService.getLatestFeatureUpdatedUtc(project.id, visibleLayers).then(response => {
                    if (!response || !StringUtils.isString(response)) {
                        return false;
                    }

                    const date = new Date(response);
                    let latestFeatureUpdatedUtc = date.getTime() - date.getTimezoneOffset() * 60 * 1000;

                    if (
                        this.projectLastFeatureUpdatedUtc &&
                        this.projectLastFeatureUpdatedUtc < latestFeatureUpdatedUtc &&
                        this.lastManualRefreshUtc < latestFeatureUpdatedUtc
                    ) {
                        this.projectLastFeatureUpdatedUtc = latestFeatureUpdatedUtc;
                        return true;
                    } else {
                        if (!this.projectLastFeatureUpdatedUtc) {
                            this.projectLastFeatureUpdatedUtc = latestFeatureUpdatedUtc;
                        }
                        return false;
                    }
                });
            } else {
                return Promise.resolve(false);
            }
        }
    }

    private startTimer(): void {
        let mapContentMonitoringPeriodInMilliSeconds = (environment.mapContentMonitoringPeriodInSeconds || 120) * 1000;

        timer(mapContentMonitoringPeriodInMilliSeconds)
            .pipe(takeUntil(this.stopTimer))
            .subscribe(() => this.doMonitoring());
    }

    private cancelTimer(): void {
        this.stopTimer.next(null);
    }
}
