<div class="map-layout-container">
    <div class="map-frame">
        <div class="flex-ct-column width-full">
            <top-toolbar class="top-toolbar"></top-toolbar>
            <refresh-tools
                *checkVisibility="{
                    visibleModes: [
                        MapWorkspacePermissionType.FILE_VIEW_MODE,
                        MapWorkspacePermissionType.READ,
                        MapWorkspacePermissionType.FULL_ACCESS
                    ]
                }"
            ></refresh-tools>

            <div
                class="map-canvas"
                *checkVisibility="{
                    visibleModes: [
                        MapWorkspacePermissionType.FILE_VIEW_MODE,
                        MapWorkspacePermissionType.READ,
                        MapWorkspacePermissionType.FULL_ACCESS
                    ]
                }"
            >
                <div class="map-menu-tabs-container">
                    <menu-tabs id="menu-tabs"></menu-tabs>
                </div>

                <div class="map-menus-container">
                    <map-menu class="menu-panel" [ngClass]="{ open: openMenu }"></map-menu>
                </div>
            </div>
        </div>

        <side-panel class="flexbox"></side-panel>
    </div>
    <div class="over-map loading-no-access" *checkVisibility="{ visibleModes: [MapWorkspacePermissionType.NO_ACCESS] }">
        <p>{{ 'TCS.Error.NoMapPermission' | translate }}</p>
        <p>{{ 'TCS.Error.SelectDifferentMap' | translate }}</p>
    </div>
    <div
        class="over-map loading-no-access"
        *checkVisibility="{ visibleModes: [MapWorkspacePermissionType.NO_FILE_ACCESS] }"
    >
        <p>{{ 'TCS.Error.NoFilePermission' | translate }}</p>
        <p>{{ 'TCS.Error.SelectDifferentFile' | translate }}</p>
    </div>
</div>
