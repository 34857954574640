<label [ngClass]="{ 'disabled-field': !enabled }">
    <span *ngIf="label">{{ label }}</span>
    <div class="date-wrapper">
        <date-picker
            [maxDate]="maxDate"
            [minDate]="minDate"
            [forceUTC]="true"
            #picker="ngModel"
            name="{{ name }}_picker"
            [(ngModel)]="value"
            [placeholder]="placeholder"
            [enabled]="enabled"
        ></date-picker>
    </div>
</label>
