<div
    class="modal-popup"
    [ngClass]="{
        'extra-small-modal': size === ModalSize.ExtraSmall,
        'small-modal': size === ModalSize.Small,
        'medium-modal': size === ModalSize.Medium,
        'large-modal': size === ModalSize.Large,
        'xlarge-modal': size === ModalSize.Xlarge,
        'xxlarge-modal': size === ModalSize.XXlarge,
        'wide-modal': size === ModalSize.Wide,
        'custom-modal': size === ModalSize.Custom,
        'fluid-modal': size === ModalSize.Fluid,
        'fluid-large-modal': size === ModalSize.FluidLarge
    }"
>
    <div class="modal-content">
        <div class="modal-header">
            <h2 class="modal-title" *ngIf="heading">
                <span>{{ heading | translate }}</span>
            </h2>
            <gsp-button (action)="callExit()" [type]="ButtonType.Icon" iconClass="icon-ModelClose"></gsp-button>
        </div>
        <div class="modal-body">
            <ng-content select="[transclude-popup-content]"></ng-content>
        </div>
        <div class="modal-footer">
            <ng-content select="[transclude-popup-footer]"></ng-content>
        </div>
    </div>
</div>
