import { Injectable } from '@angular/core';
import { ZoomPanOptions } from 'leaflet';
import * as _ from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

class MapZoomAction {
    action: string; // zoom
    latLngBounds: L.LatLngBounds;
    paddingTopLeft: L.PointExpression;
    paddingBottomRight: L.PointExpression;
    otherOptions: L.FitBoundsOptions;
}

class MapPanAction {
    action: string; // pan
    latLng: L.LatLng;
}

type MapPanOrZoomAction = MapZoomAction | MapPanAction;

@Injectable({
    providedIn: 'root'
})
export class MapPanAndZoomStream {
    mapPanAndZoomStream = new BehaviorSubject<MapPanOrZoomAction>(null);

    constructor() {}

    zoomToBounds(
        latLngBounds: L.LatLngBounds,
        paddingTopLeft: L.PointExpression,
        paddingBottomRight: L.PointExpression,
        otherOptions: L.FitBoundsOptions
    ): void {
        this.mapPanAndZoomStream.next({
            action: 'zoom',
            latLngBounds: latLngBounds,
            paddingTopLeft: paddingTopLeft || [0, 0],
            paddingBottomRight: paddingBottomRight || [0, 0],
            otherOptions: otherOptions
        });
    }

    panToLocation(latLng: L.LatLng): void {
        this.mapPanAndZoomStream.next({
            action: 'pan',
            latLng: latLng
        });
    }

    doPanOrZoom(map: L.Map, panOrZoomAction: MapPanOrZoomAction) {
        if (map && panOrZoomAction) {
            switch (panOrZoomAction.action) {
                case 'zoom':
                    let zoomAction = panOrZoomAction as MapZoomAction;
                    if (zoomAction.latLngBounds) {
                        let options: L.FitBoundsOptions = {
                            paddingTopLeft: zoomAction.paddingTopLeft,
                            paddingBottomRight: zoomAction.paddingBottomRight
                        };
                        _.extend(options, zoomAction.otherOptions);
                        map.fitBounds(zoomAction.latLngBounds, options);
                    }
                    break;

                case 'pan':
                    let panAction = panOrZoomAction as MapPanAction;
                    if (panAction.latLng) {
                        map.setView(panAction.latLng, map.getZoom(), {} as ZoomPanOptions);
                    }
                    break;
            }
        }
    }
}
