import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { Field, GroupField, LayoutFieldType } from 'src/app/shared/template-services/field';
import { Rule } from 'src/app/shared/template-services/rule/rule';
import { Template } from 'src/app/shared/template-services/template';

import { RuleSelectionStreamService } from './rule-selection-stream.service';
import { RuleService } from './rule.service';

@Component({
    selector: 'rules-panel',
    templateUrl: './rules-panel.component.html'
})
export class RulesPanelComponent implements OnDestroy, OnInit {
    private destroyed = new Subject<void>();

    @Input()
    template: Template;
    rules: Rule[];
    fields: Field[];
    selectedRule: Rule;

    // expose ButtonType enum to template
    public ButtonType = ButtonType;

    constructor(
        private ruleSelectionStream: RuleSelectionStreamService,
        public translate: TranslationService,
        private ruleService: RuleService
    ) {}

    ngOnInit(): void {
        this.ruleSelectionStream.ruleSelectionStream.pipe(takeUntil(this.destroyed)).subscribe(selectedRule => {
            if (selectedRule) {
                this.selectRule1(selectedRule);
            }
        });

        this.rules = this.template.rules;
        this.fields = this.template.fields;

        if (!this.template.rules.length) {
            this.ruleService.createNewRule(this.template);
        }

        if (!this.selectedRule) {
            this.selectRule(this.template.rules[0]);
        }
        this.template.assessRuleErrors();
        this.template.populateChoiceValues();
    }

    selectRule(rule: Rule): void {
        this.ruleSelectionStream.ruleSelectionStream.next(rule);
    }

    selectRule1(rule: Rule): void {
        this.selectedRule = rule;
        this.template.findIncompleteRule('Rules', this.selectedRule);
        this.template.assessFields(this.selectedRule);
    }

    createRule(): void {
        this.ruleService.createNewRule(this.template);
    }

    trashRule(selectedRule: Rule): void {
        this.ruleService.removeRule(selectedRule, this.template);
    }

    getField(fieldId: string): Field {
        let fieldToBeReturned: Field;
        this.template.fields.forEach(field => {
            if (field.uuid === fieldId) {
                fieldToBeReturned = field;
                return;
            }
            if (field.type === LayoutFieldType.Group) {
                let groupField = field as GroupField;
                groupField.fields.forEach(subField => {
                    if (subField.uuid === fieldId) {
                        fieldToBeReturned = subField;
                        return;
                    }
                });
            }
            if (fieldToBeReturned) {
                return;
            }
        });
        return fieldToBeReturned;
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }
}
