import { Component, OnDestroy, OnInit, Type } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { GspWizardService } from 'src/app/shared/common/components/gsp-wizard/gsp-wizard.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { ModalSize } from 'src/app/shared/common/modal-sizes';
import { MapWorkspace, ShareWorkspaceData } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';

import { WorkspaceAreaComponent } from '../workspace-area/workspace-area.component';
import { WorkspaceCoordinateSystemComponent } from '../workspace-coordinate-system/workspace-coordinate-system.component';
import { WorkspaceService } from '../workspace.service';
import { WorkspaceBrowseFileComponent } from './workspace-browse-file/workspace-browse-file.component';
import { WorkspaceCreationNameComponent } from './workspace-creation-name.component';
import { WorkspaceScannedFileComponent } from './workspace-scanned-file/workspace-scanned-file.component';

@Component({
    selector: 'workspace-creation',
    templateUrl: './workspace-creation.component.html'
})
export class WorkspaceCreationComponent implements OnInit, OnDestroy {
    // expose ButtonType enum to template
    public ButtonType = ButtonType;
    public ModalSize = ModalSize;
    public uploadedFileWorkspace: ShareWorkspaceData;
    public fromFile = false;
    public showProgress = false;
    public heading = '';
    public defaultSteps = [WorkspaceCreationNameComponent, WorkspaceAreaComponent, WorkspaceCoordinateSystemComponent];
    public currentStep: Type<any>;

    private destroyed = new Subject<void>();

    constructor(
        private wizardService: GspWizardService,
        private projectStream: ProjectStreamService,
        private workspaceCreationService: WorkspaceService,
        private mapWorkspaceStore: MapWorkspacesStoreService,
        private route: ActivatedRoute,
        private translate: TranslationService
    ) {}

    ngOnInit(): void {
        let modifyWorkspace = new MapWorkspace();
        // get the workspace with connectFolderId in case of workspace creation from connect
        const editMapWorkspace = this.mapWorkspaceStore.editMapWorkspaceStream.getValue();
        const uploadedFileWorkspace = this.mapWorkspaceStore.uploadedWorkspaceDataStream.getValue();
        const lastUrlSegment = this.route.snapshot.url.length - 1;
        this.fromFile = this.route.snapshot.url[lastUrlSegment].path.includes('fromFile');

        if (this.fromFile) {
            this.heading = this.translate.instant('TC.Common.CreateFromTemplateHeader');
            this.wizardService.setSteps([
                WorkspaceBrowseFileComponent,
                WorkspaceScannedFileComponent,
                ...this.defaultSteps
            ]);
        } else {
            this.heading = this.translate.instant('TCS.Workspace.Detail');
            this.wizardService.setSteps(this.defaultSteps);
        }

        if (uploadedFileWorkspace) {
            this.workspaceCreationService.uploadWorkspaceTemplate$.next(uploadedFileWorkspace);
        }

        if (editMapWorkspace && editMapWorkspace.projectId) {
            modifyWorkspace = editMapWorkspace;
        } else {
            modifyWorkspace.projectId = this.projectStream.getCurrentProject().id;
        }

        this.workspaceCreationService.modifyWorkspace$.next(modifyWorkspace);
        this.wizardService.currentStep$.pipe(takeUntil(this.destroyed)).subscribe(step => {
            this.currentStep = step;
            if (this.currentStep === WorkspaceCreationNameComponent) {
                this.showProgress = true; // Show progress from this step onwards
            }
        });
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    public backward(): void {
        try {
            this.wizardService.moveBackward().then(() => {
                this.wizardService.reset();
            });
        } catch (e) {
            throw e;
        }
    }

    public forward(): void {
        try {
            this.wizardService.moveForward().then(() => {
                this.wizardService.reset();
            });
        } catch (e) {
            throw e;
        }
    }

    public exit(): void {
        this.wizardService.onClose();
        this.workspaceCreationService.workspaceCreationOnClose();
    }

    public cancel(): void {
        this.wizardService.onCancel();
        this.workspaceCreationService.workspaceCreationOnClose();
    }
}
