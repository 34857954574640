import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { EnvironmentService } from '../common/environment/environment.service';
import { UserMapWorkspaceSetting } from './user-map-workspace-settings';
import { UserProjectSettings } from './user-project-settings';
import { GeoUserSettings, GeoUserSettingsRequest, GeoUsersSettings, UserSettings } from './user-settings';

@Injectable({
    providedIn: 'root'
})
export class UserSettingsService {
    constructor(private http: HttpClient, private env: EnvironmentService) {}

    getUserMeSettingsByRegion(regionUrl: string): Promise<GeoUsersSettings> {
        const path = regionUrl.replace(/\/$/, '') + '/settings?type=user&name=me';

        return lastValueFrom(this.http.get<GeoUserSettings>(path))
            .then(settings => {
                if (settings.items && settings.items[0]) {
                    return settings.items[0] || {};
                } else {
                    return null;
                }
            })
            .catch(
                () =>
                    // handle failed calls
                    null
            );
    }

    getUserSettings(settingsName: string): Promise<GeoUsersSettings> {
        const arr = [
            settingsName.substring(0, settingsName.indexOf('-')),
            settingsName.substring(settingsName.indexOf('-') + 1)
        ];

        const path = this.env.apiUrl.replace(/\/$/, '') + '/settings?type=' + arr[0] + '&name=' + arr[1];

        return lastValueFrom(this.http.get<GeoUserSettings>(path)).then(settings => {
            if (settings.items && settings.items[0]) {
                return settings.items[0] || {};
            } else {
                return null;
            }
        });
    }

    createUserMeSettings(data: GeoUserSettingsRequest): Promise<UserSettings> {
        const path = this.env.apiUrl + '/settings';

        return lastValueFrom(this.http.post<GeoUsersSettings>(path, data)).then(settings =>
            UserSettings.fromDTO(settings, this.env.podLocation)
        );
    }

    createUserProjectSettings(data: GeoUserSettingsRequest): Promise<UserProjectSettings> {
        const path = this.env.apiUrl + '/settings';

        return lastValueFrom(this.http.post<GeoUsersSettings>(path, data)).then(settings =>
            UserProjectSettings.fromDTO(settings)
        );
    }

    createUserMapWorkspaceSettings(data: GeoUserSettingsRequest): Promise<UserMapWorkspaceSetting> {
        const path = this.env.apiUrl + '/settings';

        return lastValueFrom(this.http.post<GeoUsersSettings>(path, data)).then(settings =>
            UserMapWorkspaceSetting.fromDTO(settings)
        );
    }

    updateUserSettings(data: GeoUserSettingsRequest): Promise<GeoUserSettings> {
        const path = this.env.apiUrl + '/settings/_updateBySettingsName?settingsName=' + data.settingsName;

        return lastValueFrom(this.http.put<GeoUserSettings>(path, data));
    }

    deleteUserSettings(settingsName: string): Promise<void> {
        const path = this.env.apiUrl + '/settings/_deleteBySettingsName?settingsName=' + settingsName;

        return lastValueFrom(this.http.delete<void>(path));
    }
}
