import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ACCESS_MODE_KEY, AccessMode } from 'src/app/core/authentication/share-token';
import { EnvironmentService } from 'src/app/shared/common/environment/environment.service';
import { Actor } from 'src/app/shared/user/actor';

import { GroupUsersResponse, UserDTO } from '../../user/user';
import { Permission, PermissionDTO, permissionPriority, PermissionType } from '../mapWorkspace/permission';
import { Project, ProjectDTO, ProjectSettings } from './project';

@Injectable({
    providedIn: 'root'
})
export class ProjectService {
    constructor(private http: HttpClient, private env: EnvironmentService) {}

    private get projectPath(): string {
        return this.env.connectApiUrl + '/projects';
    }

    private get connectApiUrl(): string {
        return this.env.connectApiUrl;
    }

    public getProjects(): Promise<Project[]> {
        return lastValueFrom(this.http.get<ProjectDTO[]>(this.projectPath)).then(projects =>
            projects.map(project => Project.fromDTO(project))
        );
    }

    public createProject(data: ProjectDTO): Promise<Project> {
        return lastValueFrom(this.http.post<ProjectDTO>(this.projectPath, data)).then(project =>
            Project.fromDTO(project)
        );
    }

    public getFirstOrDefaultProject(): Promise<Project> {
        return lastValueFrom(this.http.get<ProjectDTO[]>(this.projectPath)).then(projects => {
            if (projects.length) {
                return Promise.resolve(Project.fromDTO(projects[0]));
            } else {
                return Promise.reject({
                    data: {
                        errorcode: 'PROJECT_NOT_FOUND',
                        message: 'Please create new project from trimble connect.'
                    }
                });
            }
        });
    }

    public getProjectById(id: string): Promise<Project> {
        if (sessionStorage.getItem(ACCESS_MODE_KEY) && sessionStorage.getItem(ACCESS_MODE_KEY) === AccessMode.SHARED) {
            return Promise.resolve(Project.fromDTO({ id, name: 'Shared Project' }));
        }

        if (!id) {
            return this.getFirstOrDefaultProject();
        } else {
            const path = this.projectPath + '/' + id + '?fullyLoaded=false';

            return lastValueFrom(this.http.get<ProjectDTO>(path)).then(project => Project.fromDTO(project));
        }
    }

    public FindProjectRegionById(regionPath: string, id: string): Promise<Project> {
        if (sessionStorage.getItem(ACCESS_MODE_KEY) && sessionStorage.getItem(ACCESS_MODE_KEY) === AccessMode.SHARED) {
            return Promise.resolve(Project.fromDTO({ id, name: 'Shared Project' }));
        }
        let path = regionPath + 'projects/' + id + '?fullyLoaded=false';
        return lastValueFrom(this.http.get<ProjectDTO>(path)).then(project => Project.fromDTO(project));
    }

    // function to obtain project settings from tc for example:unitsettings etc.
    public getProjectSettings(id: string): Promise<ProjectSettings> {
        const path = this.projectPath + '/' + id + '/settings';
        if (!id) {
            return Promise.reject(); // TODO: GIM 16/5/2019 - check this conversion
        } else {
            return lastValueFrom(this.http.get<ProjectSettings>(path));
        }
    }

    public searchProjectByName(name: string): Promise<Project> {
        const path = this.connectApiUrl + '/search?type=PROJECT&query=' + name;

        return lastValueFrom(this.http.get<{ items: ProjectDTO[] }>(path)).then(project =>
            Project.fromDTO(project.items[0])
        );
    }

    public getProjectGroups(projectId: string, startIndex: number = 0, pageSize: number = 25): Promise<Actor[]> {
        let endIndex: number = null;
        if (pageSize) {
            endIndex = startIndex + (pageSize - 1);
        }

        // ! It's important to type opts to object so typescript compiler finds right http.get overload
        const opts: object = {
            headers: new HttpHeaders({
                Range: 'items=' + startIndex + '-' + (endIndex || '')
            }),
            observe: 'response',
            responseType: 'json'
        };
        const path = this.projectPath + '/' + projectId + '/actors?type=GROUP';

        return lastValueFrom(this.http.get<HttpResponse<object>>(path, opts)).then(response => {
            let responseRange = response.headers
                .get('content-range')
                .replace('items ', '')
                .replace('--1', '-0')
                .replace('/', '-')
                .split('-');

            return (response.body as any[]).map(group => {
                group.totalItems = responseRange[2];
                return Actor.fromDTO(group, PermissionType.GROUP);
            });
        });
    }

    public async getUserOrGroupPermissions(dto: PermissionDTO, currentUserId: string) {
        let permission = new Permission();

        if (dto) {
            permission.permissions = dto.permissions || [];

            const userOrGroupPermissions = permission.permissions.filter(
                p => (p.type === PermissionType.USER && p.id === currentUserId) || p.type === PermissionType.GROUP
            );
            const userCurrentPermission = userOrGroupPermissions.find(
                p => p.type === PermissionType.USER && p.id === currentUserId
            );

            if (userCurrentPermission) {
                permission.effectivePermission = userCurrentPermission.permission;
            } else {
                // No User permission; check for relevant group permission after sorting groups based on permission priority
                userOrGroupPermissions.sort(
                    (a, b) => permissionPriority[a.permission] - permissionPriority[b.permission]
                );
                // Check group membership of user
                for (const group of userOrGroupPermissions) {
                    const groupUserIds = await this.getUserGroupMembers(group.id);
                    if (groupUserIds.includes(currentUserId)) {
                        permission.effectivePermission = group.permission;
                        break;
                    }
                }
                // No user or Group permission; assign default permission
                if (!permission.effectivePermission) {
                    permission.effectivePermission = dto.defaultPermission;
                }
            }
        }
        permission.defaultPermission = dto.defaultPermission || permission.effectivePermission;
        return permission;
    }

    public getUserGroupMembers(groupId: string): Promise<string[]> {
        // Returns active and pending users in a group
        const path = this.connectApiUrl + '/groups/' + groupId + '/users';

        return lastValueFrom(this.http.get(path)).then((response: GroupUsersResponse[]) => {
            const userIds: string[] = response.map(item => item.id);
            return userIds;
        });
    }

    public getProjectUsers(projectId: string, startIndex: number = 0, pageSize: number = 25): Promise<Actor[]> {
        let endIndex: number = null;
        if (pageSize) {
            endIndex = startIndex + (pageSize - 1);
        }

        // ! It's important to type opts to object so typescript compiler finds right http.get overload
        const opts: object = {
            headers: new HttpHeaders({
                Range: 'items=' + startIndex + '-' + (endIndex || '')
            }),
            observe: 'response',
            responseType: 'json'
        };

        const path = this.projectPath + '/' + projectId + '/actors?type=USER';
        return lastValueFrom(this.http.get<HttpResponse<any>>(path, opts)).then(response => {
            let responseRange = response.headers
                .get('content-range')
                .replace('items ', '')
                .replace('--1', '-0')
                .replace('/', '-')
                .split('-');

            return response.body.map((user: Partial<UserDTO>) => {
                const actor = Actor.fromDTO(user, PermissionType.USER);
                actor.totalItems = Number(responseRange[2]);
                return actor;
            });
        });
    }
}
