/* eslint-disable */

L.TileLayer.GeoJSON = L.TileLayer.extend({
    _requests: [], // track in-progress tile requests for aboty handling

    _keyLayers: {}, // map from feature id (= key) to map layer

    _clipPathRectangles: {}, // Used to calculate svg path string for clip path elements
    _zoomInProgress: false,
    _requestsQueue: [],

    initialize: function(url, options, geojsonOptions, queueRequestFunction) {
        L.TileLayer.prototype.initialize.call(this, url, options);
        this.geojsonLayer = new L.GeoJSON(null, geojsonOptions);
        this.queueRequestFunction = queueRequestFunction;
    },

    onAdd: function(map) {
        this._map = map;
        L.TileLayer.prototype.onAdd.call(this, map);
        map.on('zoomstart', this._onZoomStart.bind(this));
        map.on('zoomend', this._onZoomEnd.bind(this));

        map.addLayer(this.geojsonLayer);
    },

    _onZoomStart: function(event) {
        this._zoomInProgress = true;
    },

    _onZoomEnd: function(event) {
        this._zoomInProgress = false;
        if (this._requestsQueue.length) {
            this._requestsQueue.forEach(function(request, index) {
                const layer = request.layer;
                layer._zoomInProgress = false;
                layer.createTile(request.coords, request.done);
            });
            this._requestsQueue.length = 0;
        }
    },

    onRemove: function(map) {
        this._reset();
        map.removeLayer(this.geojsonLayer);
        map.off('zoomstart', this._onZoomStart.bind(this));
        map.off('zoomend', this._onZoomEnd.bind(this));
        L.TileLayer.prototype.onRemove.call(this, map);
    },

    createTile: function(coords, done) {
        var tile = document.createElement('div');
        var layer = this;

        if (this._zoomInProgress) {
            this._requestsQueue.push({ coords: coords, done: done, layer: this });
            return tile;
        } else {
            const subject = this.queueRequestFunction(this.getTileUrl(coords));

            const subscription = subject.subscribe(response => {
                this.httpResponseHandler(response, layer, tile, coords, done);
                requests.splice(requests.indexOf(subscription), 1); // remove completed request from array
            });

            const requests = this._requests;
            requests.push(subscription);

            return tile;
        }
    },

    // XMLHttpRequest handler; closure over the XHR object, the layer, and the tile
    httpResponseHandler: function(response, layer, tile, tilePoint, done) {
        if (response.type !== 4) {
            return;
        }

        var s = response.status;
        if ((s >= 200 && s !== 204 && s < 300) || s === 304) {
            tile.datum = response.body;
            if (tile.datum !== null) {
                layer.addTileData(tile.datum, tilePoint);
            }
        }
        done();
    },

    // Add a geojson object from a tile to the GeoJSON layer
    // * If the options.unique function is specified, merge geometries into GeometryCollections
    // grouped by the key returned by options.unique(feature) for each GeoJSON feature
    // * If options.clipTiles is set, and the browser is using SVG, perform SVG clipping on each
    // tile's GeometryCollection
    addTileData: function(geojson, tilePoint) {
        var features = L.Util.isArray(geojson) ? geojson : geojson.features,
            i,
            len,
            feature;
        if (features) {
            for (i = 0, len = features.length; i < len; i++) {
                // Only add this if geometry or geometries are set and not null
                feature = features[i];
                if (feature.geometries || feature.geometry || feature.features || feature.coordinates) {
                    this.addTileData(feature, tilePoint);
                }
            }
            return this;
        }
        var options = this.geojsonLayer.options;
        if (options.filter && !options.filter(geojson)) {
            return;
        }
        var parentLayer = this.geojsonLayer;
        var incomingLayer = null;
        if (this.options.unique && typeof this.options.unique === 'function') {
            var key = this.options.unique(geojson);
            // When creating the layer for a unique key,
            // Force the geojson to be a geometry collection
            if (!(key in this._keyLayers && geojson.geometry.type !== 'GeometryCollection')) {
                geojson.geometry = {
                    type: 'GeometryCollection',
                    geometries: [geojson.geometry]
                };
            }
            // Transform the geojson into a new Layer
            try {
                incomingLayer = L.GeoJSON.geometryToLayer(geojson, options.pointToLayer, options.coordsToLatLng);
            } catch (e) {
                // Ignore GeoJSON objects that could not be parsed
                return this;
            }
            incomingLayer.feature = L.GeoJSON.asFeature(geojson);
            // Add the incoming Layer to existing key's GeometryCollection
            if (key in this._keyLayers) {
                parentLayer = this._keyLayers[key];
                parentLayer.feature.geometry.geometries.push(geojson.geometry);
            }
            // Convert the incoming GeoJSON feature into a new GeometryCollection layer
            else {
                this._keyLayers[key] = incomingLayer;
            }
        }
        // Add the incoming geojson feature to the L.GeoJSON Layer
        else {
            // Transform the geojson into a new layer
            try {
                incomingLayer = L.GeoJSON.geometryToLayer(geojson, options.pointToLayer, options.coordsToLatLng);
            } catch (e) {
                // Ignore GeoJSON objects that could not be parsed
                return this;
            }
            incomingLayer.feature = L.GeoJSON.asFeature(geojson);
        }
        incomingLayer.defaultOptions = incomingLayer.options;
        this.geojsonLayer.resetStyle(incomingLayer);
        if (options.onEachFeature) {
            options.onEachFeature(geojson, incomingLayer);
        }
        parentLayer.addLayer(incomingLayer);
        // If options.clipTiles is set and the browser is using SVG
        // then clip the layer using SVG clipping
        if (this.options.clipTiles) {
            this._clipLayerToTileBoundary(incomingLayer, tilePoint);
        }
        return this;
    },

    addLayerForFeature(featureId, featureMapLayer) {
        this._keyLayers[featureId] = featureMapLayer;
        this.geojsonLayer.addLayer(featureMapLayer);
    },

    removeLayerForFeature(featureId) {
        if (this._keyLayers[featureId]) {
            this.geojsonLayer.removeLayer(this._keyLayers[featureId]);
        }
        delete this._keyLayers[featureId];
    },

    _clipLayerToTileBoundary: function(layer, tilePoint) {
        // Only perform SVG clipping if the browser is using SVG
        if (!L.SVG) {
            return;
        }
        if (!this._map) {
            return;
        }
        var svgs = this._map.getPanes().overlayPane.getElementsByTagName('svg');
        if (svgs.length !== 1) {
            return;
        }
        var svg = svgs[0];
        // create the defs container if it doesn't exist
        const svgNs = 'http://www.w3.org/2000/svg'; // L.Path.SVG_NS
        var defs = null;
        if (svg.getElementsByTagName('defs').length === 0) {
            defs = document.createElementNS(svgNs, 'defs');
            svg.insertBefore(defs, svg.firstChild);
        } else {
            defs = svg.getElementsByTagName('defs')[0];
        }
        // Create the clipPath for the tile if it doesn't exist
        var clipPathId = 'tileClipPath_' + tilePoint.z + '_' + tilePoint.x + '_' + tilePoint.y;
        var clipPath = document.getElementById(clipPathId);
        if (clipPath === null) {
            clipPath = document.createElementNS(svgNs, 'clipPath');
            clipPath.id = clipPathId;
            // Create a hidden L.Rectangle to represent the tile's area
            var tileSize = this.options.tileSize,
                nwPoint = tilePoint.multiplyBy(tileSize),
                sePoint = nwPoint.add([tileSize, tileSize]),
                nw = this._map.unproject(nwPoint),
                se = this._map.unproject(sePoint);
            this._clipPathRectangles[clipPathId] = new L.Rectangle(new L.LatLngBounds([nw, se]), {
                opacity: 0,
                fillOpacity: 0,
                interactive: false,
                noClip: true
            });
            this._map.addLayer(this._clipPathRectangles[clipPathId]);
            // Add a clip path element to the SVG defs element
            // With a path element that has the hidden rectangle's SVG path string
            var path = document.createElementNS(svgNs, 'path');
            var pathString = L.SVG.pointsToPath(this._clipPathRectangles[clipPathId]._parts) + 'z';
            path.setAttribute('d', pathString);
            clipPath.appendChild(path);
            defs.appendChild(clipPath);
        }
        // Add the clip-path attribute to reference the id of the tile clipPath
        this._recurseLayerUntilPath(function(pathLayer) {
            pathLayer._path.setAttribute('clip-path', 'url(#' + clipPathId + ')');
        }, layer);
    },

    // Recurse LayerGroups and call func() on L.Path layer instances
    _recurseLayerUntilPath: function(func, layer) {
        if (layer instanceof L.Path) {
            func(layer);
        } else if (layer instanceof L.LayerGroup) {
            // Recurse each child layer
            layer.getLayers().forEach(this._recurseLayerUntilPath.bind(this, func), this);
        }
    },

    _setView: function(center, zoom, noPrune, noUpdate) {
        var tileZoom = this._clampZoom(Math.round(zoom));
        if (
            (this.options.maxZoom !== undefined && tileZoom > this.options.maxZoom) ||
            (this.options.minZoom !== undefined && tileZoom < this.options.minZoom)
        ) {
            tileZoom = undefined;
        }

        var tileZoomChanged = this.options.updateWhenZooming && tileZoom !== this._tileZoom;

        if (tileZoomChanged) {
            this._reset();
        }

        L.TileLayer.prototype._setView.call(this, center, zoom, noPrune, noUpdate);
    },

    _reset: function() {
        //this._requestsQueue.length = 0;
        for (var i = 0; i < this._requests.length; i++) {
            var request = this._requests[i];
            request.unsubscribe(); // cancels http request if in flight or closes it if not yet in flight
        }
        this._requests = [];
        this.geojsonLayer.clearLayers();
        this._keyLayers = {};
        this._removeOldClipPaths();
    },

    // Remove clip path elements from other earlier zoom levels
    _removeOldClipPaths: function() {
        var currentMapZoom = this._map.getZoom();
        for (var clipPathId in this._clipPathRectangles) {
            var clipPathZXY = clipPathId.split('_').slice(1);
            var zoom = parseInt(clipPathZXY[0], 10);
            if (zoom !== currentMapZoom) {
                var rectangle = this._clipPathRectangles[clipPathId];
                this._map.removeLayer(rectangle);
                var clipPath = document.getElementById(clipPathId);
                if (clipPath !== null) {
                    clipPath.parentNode.removeChild(clipPath);
                }
                delete this._clipPathRectangles[clipPathId];
            }
        }
    }
});
