<ng-form #fieldForm="ngForm">
    <li *ngIf="fieldMeta" [ngClass]="{ 'page-header-list': fieldMeta.fieldType === LayoutFieldType.PageHeader }">
        <div
            class="editor-field {{ fieldMeta.fieldType.toLowerCase() }}"
            [ngClass]="{
                active: isSelected,
                'tfx-group': fieldMeta.fieldType === LayoutFieldType.Group,
                'tfx-page-header': fieldMeta.fieldType === LayoutFieldType.PageHeader,
                'highlight-condition':
                    condition &&
                    currentTab.id === TemplateEditorTabId.RULE &&
                    fieldMeta.fieldType !== LayoutFieldType.Group,
                'highlight-target': target && currentTab.id === TemplateEditorTabId.RULE,
                'group-error':
                    field.stopConditionedOrTargeted &&
                    fieldMeta.fieldType !== LayoutFieldType.Group &&
                    (condition || target),
                'rule-field-item': currentTab.id === TemplateEditorTabId.RULE
            }"
        >
            <div class="page-divider" *ngIf="fieldMeta.fieldType === LayoutFieldType.PageHeader"></div>
            <div class="form-element" [ngClass]="{ expanded: field.expanded == true }">
                <div class="field-header-container">
                    <header class="editor-field-header" (click)="toggleField(field, true)">
                        <div class="field-header-options">
                            <div class="field-header-inputs">
                                <div class="field-status">
                                    <div
                                        [ngClass]="{
                                            'has-condition-errors': hasConditionErrors,
                                            'has-target-errors': hasTargetErrors
                                        }"
                                        class="status-indicator"
                                    >
                                        <div *ngIf="showError" class="error-status">
                                            <i class="error-icon"></i>
                                        </div>
                                        <div *ngIf="field.conditioned" class="rule-condition">
                                            <div
                                                class="i24"
                                                [ngClass]="{
                                                    icon_line_white_condition:
                                                        (hasConditionErrors &&
                                                            currentTab.id === TemplateEditorTabId.RULE) ||
                                                        field.conditioned
                                                }"
                                            ></div>
                                        </div>
                                        <div *ngIf="field.targeted" class="rule-target">
                                            <div
                                                class="i24"
                                                [ngClass]="{
                                                    icon_line_white_target:
                                                        field.targetRuleErrors ||
                                                        (field.target && field.stopConditionedOrTargeted) ||
                                                        field.incompleteRule ||
                                                        field.targeted
                                                }"
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                                <field-icon
                                    [field]="field"
                                    [fieldMeta]="fieldMeta"
                                    *ngIf="fieldMeta && layoutSchema && !layoutSchema.doNotShowIcon"
                                >
                                </field-icon>
                                <field-name
                                    required
                                    [(ngModel)]="field.displayName"
                                    name="fieldName"
                                    [extraClass]="field.type === LayoutFieldType.PageHeader ? 'page-header-label' : ''"
                                    [isEditable]="isSelected && currentTab.id !== TemplateEditorTabId.RULE"
                                    [locked]="field.locked"
                                ></field-name>
                                <div class="field-check">
                                    <field-required
                                        [field]="field"
                                        *ngIf="
                                            isSelected &&
                                            currentTab.id !== TemplateEditorTabId.RULE &&
                                            field.type !== LayoutFieldType.PageHeader &&
                                            field.type != LayoutFieldType.Group &&
                                            field.type != AutoFieldModelType.Increment &&
                                            layoutSchema?.hideRequired !== false
                                        "
                                    >
                                    </field-required>

                                    <ng-container
                                        *ngIf="
                                            isSelected &&
                                            currentTab.id != TemplateEditorTabId.RULE &&
                                            field.type != LayoutFieldType.PageHeader &&
                                            field.type != LayoutFieldType.Group
                                        "
                                    >
                                        <div class="editor-field-row" *ngFor="let property of propertiesOnHeader">
                                            <single-selection-field
                                                [name]="'hp_' + property.id"
                                                [visible]="
                                                    !property.enableOn ||
                                                    field[property.enableOn.field] == property.enableOn.value
                                                "
                                                [label]="property.label | translate"
                                                [(ngModel)]="field[property.id]"
                                            >
                                            </single-selection-field>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="field-actions">
                                    <property-icons
                                        *ngIf="!isSelected && currentTab.id !== TemplateEditorTabId.RULE"
                                        class="property-icons"
                                        [field]="field"
                                    ></property-icons>
                                    <rule-buttons
                                        *ngIf="currentTab.id === TemplateEditorTabId.RULE"
                                        [selectedRule]="selectedRule"
                                        [(condition)]="condition"
                                        [(target)]="target"
                                        [field]="field"
                                        [template]="template"
                                        [fieldMeta]="fieldMeta"
                                        (ruleToBeRemoved)="removeRule($event)"
                                        (conditionChange)="ruleChanged.emit()"
                                        (targetChange)="ruleChanged.emit()"
                                    ></rule-buttons>
                                    <field-buttons
                                        *ngIf="currentTab.id !== TemplateEditorTabId.RULE"
                                        [template]="template"
                                        [field]="field"
                                        [isSelected]="isSelected"
                                        (toggleField)="toggleField(field, false)"
                                        (duplicateField)="duplicateField.emit(field)"
                                    ></field-buttons>
                                </div>
                                <div class="field-update">
                                    <template-update [field]="field" [isSelected]="isSelected" [template]="template">
                                    </template-update>
                                </div>
                                <div *ngIf="field.sensorMappingId && currentTab.id !== TemplateEditorTabId.RULE">
                                    <i class="icon-sensor-child i32" [title]="'Sensor.Tooltip.Label' | translate"></i>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
                <div
                    class="target-error ng-hide"
                    *ngIf="target && target.getErrorMessages().length && currentTab.id === TemplateEditorTabId.RULE"
                >
                    <ul>
                        <li class="h5" *ngFor="let er of target.getErrorMessages()">{{ er | translate }}</li>
                    </ul>
                </div>

                <ul
                    *ngIf="showError"
                    class="field-error"
                    [hidden]="!isSelected || currentTab.id === TemplateEditorTabId.RULE"
                >
                    <li *ngFor="let validationError of validationErrors">
                        <!-- {{ validationError | json }} -->
                        {{ validationError.message | translate: validationError.translateParams }}
                    </li>
                </ul>

                <div
                    class="condition-error ng-hide"
                    *ngIf="
                        condition && condition.getErrorMessages().length && currentTab.id === TemplateEditorTabId.RULE
                    "
                >
                    <ul>
                        <li class="h5" *ngFor="let er of condition.getErrorMessages()">{{ er | translate }}</li>
                    </ul>
                </div>
                <single-field
                    [currentProjectDetails]="currentProjectDetails"
                    *ngIf="fieldMeta.fieldType !== LayoutFieldType.Group"
                    [template]="template"
                    [currentTab]="currentTab"
                    [condition]="condition"
                    [target]="target"
                    [field]="field"
                    [isSelected]="isSelected"
                    [fieldMeta]="fieldMeta"
                    [selectedRule]="selectedRule"
                ></single-field>
                <field-group
                    *ngIf="fieldMeta.fieldType === LayoutFieldType.Group"
                    [templateEditorConfig]="templateEditorConfig"
                    [application]="application"
                    [currentProjectDetails]="currentProjectDetails"
                    [template]="template"
                    [currentTab]="currentTab"
                    [field]="field"
                    [selectedField]="selectedField"
                    (fieldSelected)="fieldSelected.emit($event)"
                    [parentIndex]="coords"
                ></field-group>

                <gsp-button
                    [type]="ButtonType.Default"
                    (action)="goToRule()"
                    *ngIf="
                        currentTab.id !== TemplateEditorTabId.RULE &&
                        isSelected &&
                        ruleToBeUsed &&
                        ruleToBeUsed !== RuleType.TARGET_ONLY
                    "
                    class="add-rule"
                    text="{{ 'TCS.AddRule' | translate }}"
                ></gsp-button>
            </div>
        </div>
    </li>
</ng-form>
