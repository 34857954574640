import { Injectable } from '@angular/core';
import { GroupField, LayoutFieldType } from 'src/app/shared/template-services/field';
import { Rule } from 'src/app/shared/template-services/rule/rule';
import { Template } from 'src/app/shared/template-services/template';

import { RuleSelectionStreamService } from './rule-selection-stream.service';

@Injectable({ providedIn: 'root' })
export class RuleService {
    constructor(private ruleSelectionStreamService: RuleSelectionStreamService) {}

    public removeRule(selectedRule: Rule, template: Template): void {
        const index = template.rules.findIndex(rule => selectedRule.uuid === rule.uuid);

        if (index !== -1) {
            if (template.rules.length > 1) {
                if (index === 0) {
                    this.ruleSelectionStreamService.ruleSelectionStream.next(template.rules[index + 1]);
                } else {
                    this.ruleSelectionStreamService.ruleSelectionStream.next(template.rules[index - 1]);
                }
            }
            template.rules.splice(index, 1);
            if (template.rules.length === 0) {
                this.createNewRule(template);
            }
            template.fields.forEach(field => {
                template.setRuleIndicatorForField(field);
                if (field.type === LayoutFieldType.Group) {
                    let groupField = field as GroupField;
                    if (groupField.fields) {
                        groupField.fields.forEach(subField => {
                            template.setRuleIndicatorForField(subField);
                        });
                    }
                }
            });
            template.populateChoiceValues(true);
        }
    }

    public createNewRule(template: Template): Rule {
        const newRule = template.createNewRule();
        if (newRule) {
            template.populateChoiceValues(true);
            this.ruleSelectionStreamService.ruleSelectionStream.next(newRule);
            return newRule;
        }
    }
}
