import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MissingTranslationHandler, TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

import { MyMissingTranslationHandler } from './missing-translation-handler';
import { HttpLoaderFactory } from './translate-http-loader';
import { TranslationDirective } from './translation.directive';
import { TranslationPipe } from './translation.pipe';

@NgModule({
    declarations: [TranslationPipe, TranslationDirective],
    imports: [
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: MyMissingTranslationHandler
            }
        })
    ],
    exports: [TranslationPipe, TranslationDirective],
    providers: [
        {
            provide: MESSAGE_FORMAT_CONFIG,
            useValue: {
                locales: [
                    'en',
                    'fr',
                    'de',
                    'es',
                    'pt',
                    'zh-CHS',
                    'ko',
                    'ja',
                    'fi',
                    'sv',
                    'da',
                    'it',
                    'zh-CHT',
                    'ru',
                    'nl',
                    'no',
                    'cs'
                ]
            }
        }
    ]
})
export class TranslationModule {}
