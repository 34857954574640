import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ACCESS_MODE_KEY, SHARED_TOKEN_KEY } from 'src/app/core/authentication/share-token';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { MapMenuCode } from 'src/app/feature/map-viewer/map-menus/map-menu-list';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { CurrentRegionStreamService } from 'src/app/shared/common/current-region/region-stream.service';

@Component({
    selector: 'load-map-viewer',
    template: `
        <div class="container"><div class="app-loading" *ngIf="loading"></div></div>
    `
})
export class LoadMapviewerComponent implements OnInit {
    private projectId: string = null;
    private region: string = null;
    public loading = false;
    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private projectStream: ProjectStreamService,
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private messaging: MessagingService,
        private translate: TranslationService,
        private regionStream: CurrentRegionStreamService
    ) {
        this.projectId =
            this.activatedRoute.snapshot.queryParams.projectId || this.regionStream.getRegionInfo().projectId || null;
        this.region =
            this.activatedRoute.snapshot.queryParams.region || this.regionStream.getRegionInfo().region || null;
    }

    ngOnInit(): void {
        this.loading = true;
        // If get project by id - if project is null, then fetch the first project -
        // if project is not exist, create a new project
        this.projectStream.initCurrentProject(this.projectId).then(project => {
            // Get default map workspace for the map viewer
            this.mapWorkspacesStore.getDefaultOrCreateMapWorkspace(project.id, false, false).then(workspace => {
                if (!workspace) {
                    this.messaging.showError(this.translate.instant('TCS.Workspace.ErrorLoading'));
                    this.router.navigate(['maps']);
                } else {
                    sessionStorage.removeItem(ACCESS_MODE_KEY);
                    sessionStorage.removeItem(SHARED_TOKEN_KEY);
                    sessionStorage.removeItem('tcAccessToken');

                    this.router.navigate(['mapViewer'], {
                        queryParams: {
                            projectId: workspace.projectId,
                            workspaceId: workspace.id,
                            activeMenus: MapMenuCode.LAYERS,
                            region: this.region
                        }
                    });
                }
            });
        });
    }
}
