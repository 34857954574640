import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AuthenticationModule } from 'src/app/core/authentication/authentication.module';
import { HttpInterceptorService } from 'src/app/core/authentication/http-interceptor.service';
import { ErrorPageModule } from 'src/app/core/error-page/error-page.module';
import { InitModule } from 'src/app/core/init/init.module';
import { MessagingModule } from 'src/app/core/messaging/messaging.module';
import { TranslationModule } from 'src/app/core/translation/translation.module';
import { ExportDownloaderModule } from 'src/app/feature/export-downloader/export-downloader.module';
import { ExportModule } from 'src/app/feature/export/export.module';
import { ImportModule } from 'src/app/feature/import/import.module';
import { MapViewerModule } from 'src/app/feature/map-viewer/map-viewer.module';
import { TemplateEditorModule } from 'src/app/feature/template-editor/template-editor.module';
import { CommonModule } from 'src/app/shared/common/common.module';

import { AppMaterialModule } from './app-material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IdentityService } from './core/authentication/identity.service';
import { GspErrorHandler, GspLoggerService } from './log-handler.service';
import { RealUserMonitoringService } from './real-user-monitoring.service';

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        TranslationModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 7000,
            easing: 'ease-out',
            easeTime: 1000,
            closeButton: true
        }),
        AppMaterialModule,
        MessagingModule,
        CommonModule,
        AuthenticationModule,
        InitModule,
        AppRoutingModule,
        ExportModule,
        ImportModule,
        MapViewerModule,
        TemplateEditorModule,
        ExportDownloaderModule,
        ErrorPageModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
        { provide: ErrorHandler, useClass: GspErrorHandler },
        {
            provide: APP_INITIALIZER,
            useFactory: (identityService: IdentityService) => () => identityService.populateIdentityUrls(),
            deps: [IdentityService],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (realUserMonitoringService: RealUserMonitoringService) => () =>
                realUserMonitoringService.init(),
            deps: [RealUserMonitoringService],
            multi: true
        },
        GspLoggerService,
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule {}
