<div class="collaboration-tools">
    <ng-container *ngFor="let tool of collaborationToolList">
        <gsp-button
            [type]="ButtonType.Icon"
            *checkVisibility="tool.visibility"
            (action)="selectTool(tool)"
            iconClass="{{ tool.iconClass }} i32"
            buttonTooltip="{{ tool.showName | translate }}"
        >
            <!-- <i class="{{tool.iconClass}} i32" title="{{ tool.showName | translate }}">{{vFlag}}</i> -->
        </gsp-button>
    </ng-container>
</div>
