import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileViewerImportService } from 'src/app/feature/import/file-viewer/fileviewer-import.service';
import { ViewFile } from 'src/app/feature/import/import-file';
import { IntervalTime } from 'src/app/feature/import/import-polling-status.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';

@Component({
    templateUrl: './fileviewer-upload-status.component.html',
    selector: 'fileviewer-upload-status'
})
export class FileViewerUploadStatusComponent implements OnInit, OnDestroy {
    public activeImports: ViewFile[] = [];
    private activeUploadsProgress: { [key: string]: Subscription } = {};
    private destroyed = new Subject<void>();
    constructor(
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private projectStream: ProjectStreamService,
        private fileViewerImportService: FileViewerImportService
    ) {}

    ngOnInit(): void {
        this.fileViewerImportService.fileViewerActiveImportsStream.pipe(takeUntil(this.destroyed)).subscribe(files => {
            this.activeImports = files;
            files.forEach(item => {
                if (!this.activeUploadsProgress[item.id]) {
                    this.startPolling(item);
                }
            });
        });
    }

    ngOnDestroy(): void {
        this.activeUploadsProgress = {};
        this.destroyed.next(null);
    }

    private startPolling(file: ViewFile): void {
        this.activeUploadsProgress[file.id] = interval(IntervalTime.Default).subscribe(() =>
            this.fileViewerImportService
                .getFileImportProgress(file, this.projectStream.getCurrentProject().id)
                .then(progress => {
                    this.checkImportStatus(progress, file);
                })
        );
    }

    private stopPolling(file: ViewFile): void {
        this.activeUploadsProgress[file.id].unsubscribe();
        delete this.activeUploadsProgress[file.id];
    }

    private checkImportStatus(progress: number, file: ViewFile): void {
        if (progress < 100) {
            this.activeImports.forEach(item => {
                if (item.id === file.id) {
                    item.processingProgress = progress;
                }
            });
        } else {
            this.activeImports.forEach(activeImport => {
                if (activeImport.id === file.id) {
                    activeImport.processingProgress = progress;
                    this.stopPolling(file);
                    const currentLayers = this.fileViewerImportService.fileViewerLayersStream.getValue() || [];
                    activeImport.layerNames.forEach(layerName => {
                        const layer = this.fileViewerImportService.createImportedLayer(
                            this.projectStream.getCurrentProject().id,
                            this.mapWorkspacesStore.getCurrentMapWorkspace().id,
                            layerName,
                            file.connectFileId,
                            this.fileViewerImportService.importedLayerIndex
                        );
                        this.fileViewerImportService.importedLayerIndex++;
                        currentLayers.push(layer);
                    });

                    const otherFiles = this.activeImports.filter((item: ViewFile) => item.id !== file.id);
                    this.fileViewerImportService.fileViewerActiveImportsStream.next(otherFiles);
                    this.fileViewerImportService.fileViewerLayersStream.next(currentLayers);
                }
            });
        }
    }
}
