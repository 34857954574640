<div class="workspace-set-folder">
    <p>{{ 'TCS.Workspace.Duplicate.SetFolder' | translate }}</p>
    <div class="workspace-set-folder-wrapper">
        <folder-selection
            [selectedWorkspace]="sourceWorkspace"
            [isDuplicateWorkspace]="true"
            (selectedFolder)="setFolder($event)"
            class="folder-selection-component-wrapper"
        ></folder-selection>
    </div>
</div>
