import { Injectable } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GspImageCropperService {
    imageCroppedEvent$ = new BehaviorSubject<ImageCroppedEvent>(null);

    public clearCroppedImageCache(): void {
        this.imageCroppedEvent$.next(null);
    }
}
