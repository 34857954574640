<div *ngIf="!loading; else loadingSpinner" class="post-processing">
    <section
        class="info-message"
        *ngIf="
            (workspace?.postProcessingOptions?.locked && disableForLockedWorkspaces) ||
            (!accessPointsAvailable && !workspace?.postProcessingOptions?.enabled)
        "
    >
        <div>
            {{
                workspace?.postProcessingOptions?.locked && disableForLockedWorkspaces
                    ? ('MapViewer.OfflineGNSS.Locked' | translate)
                    : (cannotEnableMessageKey | translate) + '.'
            }}
            <a *ngIf="enablePopup" class="info-hyperlink" (click)="openPopup()">{{
                'MapViewer.AnnouncementBanner.AnnouncementLink.Placeholder' | translate
            }}</a>
        </div>
    </section>
    <div class="body">
        <h4 class="font-title">{{ 'MapViewer.Positioning.OfflineGNSSCorrections' | translate }}</h4>
        <p class="description mb-2" *ngIf="offlineGNSSDescription">
            {{ offlineGNSSDescription }}
        </p>
        <gsp-toggle
            class="toggle-post-processing"
            [(on)]="workspace.postProcessingOptions.enabled"
            [label]="toggleLabel"
            [disabled]="
                !currentUserIsAdmin ||
                (workspace.postProcessingOptions.locked && disableForLockedWorkspaces) ||
                (!accessPointsAvailable && !workspace.postProcessingOptions.enabled) ||
                updateInProgress
            "
            (onChange)="toggled.emit($event)"
        ></gsp-toggle>
    </div>
</div>

<ng-template #loadingSpinner>
    <loading class="loading-align-center"></loading>
</ng-template>

<gsp-popup
    *ngIf="showMoreInfo"
    [heading]="popupHeading | translate"
    [showRedirectButton]="true"
    [showClose]="true"
    [showDone]="true"
    [doneBtnTitle]="popupButton | translate"
    (closed)="closePopup()"
    (done)="onDone()"
>
    <ng-container transclude-popup-content>
        <div>
            {{ popupMessage | translate }}
        </div>
    </ng-container>
</gsp-popup>
