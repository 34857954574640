import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface IdentityConfig {
    issuer: string;
    authorization_endpoint: string;
    token_endpoint: string;
    userinfo_endpoint: string;
    registration_endpoint: string;
    jwks_uri: string;
    response_types_supported: string[];
    response_modes_supported: string[];
    grant_types_supported: string;
    subject_types_supported: string[];
    id_token_signing_alg_values_supported: string[];
    scopes_supported: string[];
    token_endpoint_auth_methods_supported: string[];
    claims_supported: string[];
    introspection_endpoint: string;
    introspection_endpoint_auth_methods_supported: string[];
    revocation_endpoint: string;
    revocation_endpoint_auth_methods_supported: string[];
    end_session_endpoint: string;
    request_parameter_supported: boolean;
    request_object_signing_alg_values_supported: string[];
}

@Injectable({ providedIn: 'root' })
export class IdentityService {
    constructor(private http: HttpClient) {}

    public identityUrls: IdentityConfig;

    public async populateIdentityUrls(): Promise<void> {
        this.identityUrls = await lastValueFrom(
            this.http.get<IdentityConfig>(environment.identityConfigUrl, {
                headers: new HttpHeaders({ NoIntercept: 'true' })
            })
        );
    }
}
