import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { Rtc } from 'src/app/feature/rtc/Rtc';
import { InternetServerTypes, RtcOptions, RtcSourceTypes } from 'src/app/feature/rtc/rtc-options';
import { RtcStore } from 'src/app/feature/rtc/rtc-store.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { NotificationType } from 'src/app/shared/common/components/gsp-notification/gsp-notification.component';
import { GspTextType } from 'src/app/shared/common/components/gsp-text/gsp-text.component';
import { MapWorkspaceStreamsService } from 'src/app/shared/common/current-map-workspaces/map-workspace-streams.service';
import { ModalSize } from 'src/app/shared/common/modal-sizes';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { MapWorkspaceStatus } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace.types';

import {
    RTC_CONFIGURATION_LINK,
    RTC_INTERNET_INFO_LINK,
    RTC_SATELLITERTX_INFO_LINK,
    RTC_TRIMBLERTX_INFO_LINK,
} from '../rtc-links';

class WorkspaceSelection {
    public workspaceId: string;
    public selected: boolean;
}

class RtcWorkspaceCompatibility {
    public workspace: MapWorkspace;
    public compatible: boolean;
    public isArchived: boolean;
    public assigned: boolean;
}

@Component({
    selector: 'rtc-configuration',
    templateUrl: './rtc-configuration.component.html'
})
export class RtcConfigurationComponent implements OnInit, OnDestroy {
    @Input() editingRtc: Rtc;
    @Input() originalRtc: Rtc;
    @Input() isEditMode = false;
    @Output()
    closed = new EventEmitter<void>();

    public sourceTypes: { id: string; name: string }[] = RtcOptions.sourceTypes;
    public secondarySourceTypes: { id: boolean; name: string }[] = RtcOptions.secondarySourceTypes;
    public workspaces: MapWorkspace[] = [];
    public workspaceSelections: WorkspaceSelection[] = [];
    public isInternetConfigurationInProgress = false;
    public isSerialConfigurationInProgress = false;
    public isSaveInProgress = false;
    public rtcWorkspaces: RtcWorkspaceCompatibility[] = [];

    // External links
    public rtcConfigurationLink = RTC_CONFIGURATION_LINK;
    public rtcTrimbleRTXInfoLink = RTC_TRIMBLERTX_INFO_LINK;
    public rtcSatelliteRTXInfoLink = RTC_SATELLITERTX_INFO_LINK;
    public rtcInternetInfoLink = RTC_INTERNET_INFO_LINK;

    // expose enum to template
    public ButtonType = ButtonType;
    public ModalSize = ModalSize;
    public NotificationType = NotificationType;
    public RtcSourceTypes = RtcSourceTypes;
    public InternetServerTypes = InternetServerTypes;
    public GspTextType = GspTextType;

    private readonly destroyed = new Subject<void>();

    constructor(
        private rtcStore: RtcStore,
        private mapWorkspaceStreams: MapWorkspaceStreamsService,
        private translate: TranslationService,
        private messaging: MessagingService
    ) {}

    public ngOnInit(): void {
        this.mapWorkspaceStreams.projectMapWorkspacesStream.pipe(takeUntil(this.destroyed)).subscribe(workspaces => {
            this.workspaces = workspaces.filter(workspace => !workspace.isFileViewer);
            this.setRtcWorkspacesCompatibility(true);
        });
    }

    public ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    public getErrorText(errors: object): string {
        if (errors) {
            const isRequiredError = _.filter([errors], 'required');

            if (isRequiredError && isRequiredError.length) {
                return this.translate.instant('TC.Common.ValueRequired.Tooltip');
            }
        }

        return '';
    }

    public saveRtc(): void {
        this.isSaveInProgress = true;
        this.editingRtc.workspaceIds = this.rtcWorkspaces
            .filter(workspace => workspace.assigned)
            .map(ws => ws.workspace.id);
        if (this.isEditMode) {
            this.rtcStore
                .updateRtc(this.editingRtc)
                .then(() => {
                    this.messaging.showSuccess(
                        this.translate.instant('MapViewer.RTC.ConfigDialog.UpdateSuccess', {
                            rtcName: this.editingRtc.name
                        })
                    );
                    this.isSaveInProgress = false;
                    this.closed.emit();
                })
                .catch(() => {
                    this.messaging.showError(
                        this.translate.instant('MapViewer.RTC.ConfigDialog.UpdateError', {
                            rtcName: this.editingRtc.name
                        })
                    );
                });
        } else {
            this.rtcStore
                .createRtc(this.editingRtc)
                .then(() => {
                    this.messaging.showSuccess(
                        this.translate.instant('Mapviewer.Messages.CreationSuccess', { name: this.editingRtc.name })
                    );
                    this.isSaveInProgress = false;
                    this.closed.emit();
                })
                .catch(() => {
                    this.messaging.showError(
                        this.translate.instant('MapViewer.RTC.ConfigDialog.SaveError', {
                            rtcName: this.editingRtc.name
                        })
                    );
                });
        }
    }

    public cancelRtc(): void {
        this.closed.emit();
    }

    public toggleAllCompatible(): void {
        if (this.editingRtc.isGlobal) {
            // This is for UI purpose. When 'All compatible workspaces' option is checked, the requirement is to
            // display all compatible workspaces as checked.
            // The workspaceIds list is emptied before its sent to the server in toDto()
            this.rtcWorkspaces.forEach(workspace => {
                workspace.assigned =
                    workspace.compatible && !this.editingRtc.excludedWorkspaceIds.includes(workspace.workspace.id);
            });
        } else {
            this.rtcWorkspaces.forEach(workspace => (workspace.assigned = false));
        }
    }

    public onSourceType1Change(selectedType: RtcSourceTypes): void {
        this.editingRtc.toDefaultRtcDTO();
        // Only update workspaces compatibility for non interent & serial rtcs on type change.
        if (selectedType !== RtcSourceTypes.INTERNET && selectedType !== RtcSourceTypes.SERIAL) {
            this.setRtcWorkspacesCompatibility();
        } else {
            // reset the common rtc fields if Internet/Serial sourcetype
            this.resetRtcCommonFieldsonSourceTypeChange();
        }
    }

    public setRtcWorkspacesCompatibility(init = false): void {
        this.rtcWorkspaces = this.workspaces.map((workspace, i) => {
            const compatible = this.editingRtc.isCompatibleWithWorkspace(workspace);
            // First time populating this.rtcWorkspaces should use this.editingRtc.workspaceIds as
            // this.rtcWorkspaces has not been populated. Once it has been populated, we should
            // check the assigned properties in this.rtcWorkspaces.
            const assigned =
                compatible &&
                (this.editingRtc.isGlobal
                    ? !this.editingRtc.excludedWorkspaceIds.includes(workspace.id)
                    : init
                    ? this.editingRtc.workspaceIds.includes(workspace.id)
                    : this.rtcWorkspaces[i].assigned);
            return {
                workspace,
                compatible,
                isArchived: workspace.status === MapWorkspaceStatus.ARCHIVED,
                assigned
            };
        });
    }

    public resetRtcCommonFieldsonSourceTypeChange(): void {
        this.editingRtc.baseType = null;
        this.editingRtc.serialPortComPort = null;
        this.editingRtc.serialPortBaudRate = null;
        this.editingRtc.serialPortDataBits = null;
        this.editingRtc.serialPortStopBits = null;
        this.editingRtc.serialPortParity = null;
        this.editingRtc.datumComponentId = null;
    }

    public get canSave(): boolean {
        switch (this.editingRtc.sourceType1) {
            case RtcSourceTypes.SBAS:
            case RtcSourceTypes.TRIMBLE_RTX:
            case RtcSourceTypes.SATELLITE_RTX:
                return true;
            case RtcSourceTypes.INTERNET:
                return this.isValidInternetConfig();
            case RtcSourceTypes.SERIAL:
                return this.isValidSerialConfig();
        }
    }

    public indexTrack(index: number, item: any): number {
        return index;
    }

    public isValidInternetConfig(): boolean {
        if (this.originalRtc.isExistingRtc()) {
            return true;
        }

        if (this.editingRtc.internetServerType === InternetServerTypes.NTRIP) {
            return this.editingRtc.ntripSettingsString !== '' && this.editingRtc.isPostCsSupportRtc();
        } else {
            return this.editingRtc.isPostCsSupportRtc();
        }
    }

    public isValidSerialConfig(): boolean {
        if (this.originalRtc.isExistingRtc()) {
            return true;
        }

        return this.editingRtc.isLegacyRtc() || this.editingRtc.isPostCsSupportRtc();
    }

    public canConfigure(source: string): boolean {
        if (source === RtcSourceTypes.INTERNET || source === RtcSourceTypes.SERIAL) {
            return true;
        }
        return false;
    }

    public onConfigurationStart(source: string): void {
        if (source === RtcSourceTypes.INTERNET) {
            this.isInternetConfigurationInProgress = true;
        }

        if (source === RtcSourceTypes.SERIAL) {
            this.isSerialConfigurationInProgress = true;
        }
    }

    public onConfigurationDone(source: string, $event: { rtc: Rtc; isValid: boolean }): void {
        this.editingRtc = $event.rtc;

        if (source === RtcSourceTypes.INTERNET) {
            this.isInternetConfigurationInProgress = false;
        }

        if (source === RtcSourceTypes.SERIAL) {
            this.isSerialConfigurationInProgress = false;
        }

        if ($event.isValid) {
            // Update workspace compatibily for internet and serial rtcs after returning back to
            // configuration popup from the secondary popup
            this.setRtcWorkspacesCompatibility();
        }
    }
}
