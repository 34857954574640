import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ACCESS_MODE_KEY, AccessMode } from 'src/app/core/authentication/share-token';
import { FileViewerSettings } from 'src/app/core/init/load-fileviewer.component';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { NotificationType } from 'src/app/shared/common/components/gsp-notification/gsp-notification.component';
import { GspWizardService, OnForward } from 'src/app/shared/common/components/gsp-wizard/gsp-wizard.service';
import { UserSettingsStreamService } from 'src/app/shared/user/user-settings-stream.service';

import { MapMenuCode } from '../../map-viewer/map-menus/map-menu-list';
import { ImportStatus, ViewFile } from '../import-file';
import { FileViewerImportService } from './fileviewer-import.service';

export enum ViewFilesStatus {
    FILES_IMPORT_INPROGRESS = 0,
    ALL_SUCCEDED,
    ALL_FAILED,
    ALL_INCOMPLETE,
    ALL_HAVE_WARNINGS,
    HAVE_SUCCESSES_AND_FAILURES,
    HAVE_SUCCESES_AND_INCOMPLETE,
    HAVE_SUCCESSES_AND_WARNINGS,
    HAVE_FAILURES_AND_INCOMPLETE,
    HAVE_FAILURES_AND_WARNINGS,
    HAVE_WARNINGS_AND_INCOMPLETE,
    HAVE_SUCCESSES_FAILURES_INCOMPLETE_WARNINGS
}

@Component({
    selector: 'fileviewer-import-status',
    templateUrl: './fileviewer-import-status.component.html'
})
export class ImportStatusFileViewerComponent implements OnInit, OnDestroy, OnForward {
    public importFiles: ViewFile[];

    // expose enum to template
    public ImportStatus = ImportStatus;
    public ButtonType = ButtonType;
    public NoticationType = NotificationType;
    public ViewFilesStatus = ViewFilesStatus;

    public viewFilesOverallStatus = ViewFilesStatus.ALL_SUCCEDED;
    public viewFilesWithWarningsOrErrors: ViewFile[] = [];

    public importedFilesInProgress: ViewFile[] = [];
    public importedFilesWithErrors: ViewFile[] = [];
    public importedFilesWithWarnings: ViewFile[] = [];
    public importedFilesThatSucceeded: ViewFile[] = [];
    public importedFilesThatAreIncomplete: ViewFile[] = [];
    public numProcessedFiles = 0;

    private defaultUiState = {
        forwardButton: { text: 'View', enabled: false, visible: true },
        backButton: { text: '', enabled: false, visible: false },
        closeButton: { text: '', enabled: false, visible: false },
        cancelButton: { text: '', enabled: false, visible: false }
    };
    private currentWorkspaceId: string;
    private currentProjectId: string;
    private destroyed$: Subject<void> = new Subject<void>();

    constructor(
        private wizardService: GspWizardService,
        private fileViewerImportService: FileViewerImportService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private userSettingsStream: UserSettingsStreamService
    ) {
        this.fileViewerImportService.fileViewerImportStream.pipe(takeUntil(this.destroyed$)).subscribe(files => {
            this.importFiles = files;
            this.setUIStatus(files);
        });
    }

    public ngOnInit(): void {
        this.currentWorkspaceId = this.activatedRoute.snapshot.queryParams.workspaceId;
        this.currentProjectId = this.activatedRoute.snapshot.queryParams.projectId;
        this.wizardService.setUI(this.defaultUiState);
        this.fileViewerImportService
            .doImportFilesToView(this.currentProjectId, this.currentWorkspaceId, this.importFiles)
            .then(layersFromImportedFiles => {
                this.fileViewerImportService.fileViewerLayersStream.next(layersFromImportedFiles);
            });
    }

    public ngOnDestroy(): void {
        this.destroyed$.next(null);
    }

    public setUIStatus(files: ViewFile[]): void {
        this.importedFilesInProgress = files.filter(file => file.status === ImportStatus.PROCESSING);
        this.importedFilesWithErrors = files.filter(file => file.status === ImportStatus.ERROR);
        this.importedFilesWithWarnings = files.filter(file => file.status === ImportStatus.WARNING);
        this.importedFilesThatSucceeded = files.filter(file => file.status === ImportStatus.DONE);
        this.importedFilesThatAreIncomplete = files.filter(file => file.status === ImportStatus.INCOMPLETE);

        this.defaultUiState.forwardButton.visible = true;
        this.defaultUiState.forwardButton.enabled = true;

        this.numProcessedFiles =
            this.importedFilesThatSucceeded.length +
            this.importedFilesWithErrors.length +
            this.importedFilesWithWarnings.length +
            this.importedFilesThatAreIncomplete.length;

        if (this.importedFilesWithErrors.length === files.length || this.importedFilesInProgress.length) {
            this.defaultUiState.forwardButton.visible = false;
            this.defaultUiState.forwardButton.enabled = false;
        }
        this.wizardService.setUI(this.defaultUiState);
    }

    public copy(str: string): void {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    public onForward(): Promise<void> {
        return new Promise((resolve, reject) => {
            let settings = JSON.parse(sessionStorage.getItem('fileViewerSettings')) as FileViewerSettings;
            const connectFileVersionIds = settings.lastViewedConnectFileVersionIds;

            const importedFileVersionIds = this.importFiles.filter(
                file =>
                    connectFileVersionIds.indexOf(file.connectFileId) > -1 &&
                    !(file.errorMessages && file.errorMessages.length)
            );

            settings.lastViewedConnectFileVersionIds = importedFileVersionIds.map(id => id.connectFileId);
            sessionStorage.setItem('fileViewerSettings', JSON.stringify(settings));

            const inCompleteFiles = this.importFiles.filter(file => file.status === ImportStatus.INCOMPLETE);

            this.fileViewerImportService.fileViewerActiveImportsStream.next(inCompleteFiles);

            if (sessionStorage.getItem(ACCESS_MODE_KEY) !== AccessMode.SHARED) {
                this.updateViewerWorkspaceSettings(
                    this.currentWorkspaceId,
                    importedFileVersionIds.map(id => id.connectFileId)
                );
            }

            this.router.navigate(['mapViewer'], {
                queryParams: {
                    projectId: this.currentProjectId,
                    workspaceId: this.currentWorkspaceId,
                    activeMenus: MapMenuCode.LAYERS
                },
                skipLocationChange: true
            });

            resolve();
        });
    }

    private updateViewerWorkspaceSettings(workspaceId: string, connectFileVersionIds: string[]): void {
        let currentUserProjectSettings = this.userSettingsStream.getCurrentProjectSettings();
        currentUserProjectSettings.fileViewerMapWorkspace = workspaceId;

        this.userSettingsStream.updateCurrentProjectSettings(currentUserProjectSettings);

        const currentMapWorkspaceSettings = this.userSettingsStream.getCurrentMapWorkspaceSettings();
        if (currentMapWorkspaceSettings) {
            currentMapWorkspaceSettings.fileViewerMapWorkspace = workspaceId;
            currentMapWorkspaceSettings.isFileViewer = true;
            currentMapWorkspaceSettings.lastViewedConnectFileVersionIds = connectFileVersionIds;
            this.userSettingsStream.updateCurrentWorkspaceSettings(currentMapWorkspaceSettings);
        }
    }
}
