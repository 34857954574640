<ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="GspTextType.GENERIC">
        <input
            #inputRef="ngModel"
            type="text"
            id="{{ id }}"
            class="gsp-form-input {{ inputClass }}"
            [ngClass]="inputNgClass"
            [name]="name"
            [placeholder]="placeholder"
            autocomplete="off"
            [title]="title"
            [(ngModel)]="value"
            (ngModelChange)="valueChange.emit(value)"
            (focus)="focusEvent.emit($event)"
            (blur)="blurEvent.emit($event)"
            (keyup)="keyupEvent.emit($event)"
            (keydown)="keydownEvent.emit($event)"
            [disabled]="disabled"
            [readOnly]="readOnly"
            [required]="required"
            [maxlength]="length"
            [errorTooltip]="errorTooltipMsg"
            [gsp-focus]="focusElement"
        />
    </ng-container>
    <ng-container *ngSwitchCase="GspTextType.URL">
        <input
            #inputRef="ngModel"
            type="text"
            id="{{ id }}"
            class="gsp-form-input {{ inputClass }}"
            [ngClass]="inputNgClass"
            [name]="name"
            [placeholder]="placeholder"
            autocomplete="off"
            [title]="title"
            [(ngModel)]="value"
            (ngModelChange)="valueChange.emit(value)"
            (focus)="focusEvent.emit($event)"
            (blur)="blurEvent.emit($event)"
            (keyup)="keyupEvent.emit($event)"
            (keydown)="keydownEvent.emit($event)"
            [disabled]="disabled"
            [readOnly]="readOnly"
            [required]="required"
            [maxlength]="length"
            [errorTooltip]="errorTooltipMsg"
            [gsp-focus]="focusElement"
            urlValidate
        />
    </ng-container>
    <ng-container *ngSwitchCase="GspTextType.EMAIL">
        <input
            #inputRef="ngModel"
            type="text"
            id="{{ id }}"
            class="gsp-form-input {{ inputClass }}"
            [ngClass]="inputNgClass"
            [name]="name"
            [placeholder]="placeholder"
            autocomplete="off"
            [title]="title"
            [(ngModel)]="value"
            (ngModelChange)="valueChange.emit(value)"
            (focus)="focusEvent.emit($event)"
            (blur)="blurEvent.emit($event)"
            (keyup)="keyupEvent.emit($event)"
            (keydown)="keydownEvent.emit($event)"
            [disabled]="disabled"
            [readOnly]="readOnly"
            [required]="required"
            [maxlength]="length"
            [errorTooltip]="errorTooltipMsg"
            [gsp-focus]="focusElement"
            emailValidate
        />
    </ng-container>
</ng-container>
