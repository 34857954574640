import { Component, OnInit, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { FileViewerSettings } from 'src/app/core/init/load-fileviewer.component';
import { GspWizardService } from 'src/app/shared/common/components/gsp-wizard/gsp-wizard.service';
import { ModalSize } from 'src/app/shared/common/modal-sizes';

import { ImportStatus, ViewFile } from '../import-file';
import { ImportStatusFileViewerComponent } from './fileviewer-import-status.component';
import { FileViewerImportService } from './fileviewer-import.service';

@Component({
    selector: 'fileviewer-import-files',
    templateUrl: './fileviewer-import-files.component.html'
})
export class ImportFilesFileviewerComponent implements OnInit {
    // expose enum to template
    public ModalSize = ModalSize;

    constructor(private fileViewerImportService: FileViewerImportService, private wizardService: GspWizardService) {
        this.wizardService.setSteps([ImportStatusFileViewerComponent]);
    }

    ngOnInit(): void {
        const settings = JSON.parse(sessionStorage.getItem('fileViewerSettings')) as FileViewerSettings;
        const connectFileVersionIds = settings.lastViewedConnectFileVersionIds;
        let files: ViewFile[] = [];
        connectFileVersionIds.forEach(id => {
            files.push({ connectFileId: id, status: ImportStatus.PROCESSING });
        });

        this.fileViewerImportService.fileViewerImportStream.next(files);
    }

    public get steps(): Type<any>[] {
        return this.wizardService.steps;
    }

    public get currentStep$(): Observable<Type<any>> {
        return this.wizardService.currentStep$;
    }

    public backward(): void {
        try {
            this.wizardService.moveBackward().then(() => {
                this.wizardService.reset();
            });
        } catch (e) {
            throw e;
        }
    }

    public forward(): void {
        try {
            this.wizardService.moveForward().then(() => {
                this.wizardService.reset();
            });
        } catch (e) {
            throw e;
        }
    }

    public exit(): void {
        this.wizardService.onClose();
    }

    public cancel(): void {
        this.wizardService.onCancel();
    }

    // ---------------------
}
