import { Component, Input } from '@angular/core';
import { GspNumberType } from 'src/app/shared/common/components/gsp-number/gsp-number.component';
import { Field } from 'src/app/shared/template-services/field';
import { ConditionDisplayInfo, ConditionOperator } from 'src/app/shared/template-services/rule/condition';
import { DateCondition } from 'src/app/shared/template-services/rule/date-condition';

@Component({
    selector: 'date-rule',
    templateUrl: './date-rule.component.html'
})
export class DateRuleComponent {
    private _condition: DateCondition;
    connectingOperators: any[];
    conditionPair: ConditionDisplayInfo[];

    @Input()
    public get condition(): DateCondition {
        return this._condition;
    }
    public set condition(v: DateCondition) {
        this._condition = v;
        this.connectingOperators = this.condition.getConnectingOperators();
        this.conditionPair = this.condition.getConditionPair();
    }

    @Input()
    field: Field;

    // exposing enum to template
    public ConditionOperator = ConditionOperator;
    public GspNumberType = GspNumberType;

    constructor() {}
}
