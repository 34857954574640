<header [ngClass]="{ 'layer-loading': layersAreLoading() }">
    <div
        class="import-file-heading"
        title="{{ currentMapWorkspace.connectFileName }}"
        *ngIf="currentMapWorkspace.isFileViewer"
    >
        {{ currentMapWorkspace.connectFileName | uppercase }}
    </div>
    <h2 *ngIf="!currentMapWorkspace.isFileViewer" translate="data"></h2>
    <div class="layer-view" [ngClass]="{ hide_layer: !visibleAll }" (click)="toggleAll()">
        <i
            class="i24"
            [ngClass]="{ icon_line_visibility_show: visibleAll, icon_line_visibility_hide: !visibleAll }"
        ></i>
    </div>
</header>

<custom-scroll class="layer-list-content height-full">
    <ul class="layers-list">
        <li *ngIf="loading">
            <loading class="width-full" [small]="true"></loading>
        </li>
        <ng-container *ngIf="!loading">
            <ng-container *ngIf="!layers.length">
                <li
                    class="no-hover"
                    *checkVisibility="{
                        visibleModes: [
                            MapWorkspacePermissionType.READ,
                            MapWorkspacePermissionType.FULL_ACCESS,
                            MapWorkspacePermissionType.NO_ACCESS
                        ]
                    }"
                >
                    {{ 'TCS.Mapviewer.AddMenu.PressToAddData' | translate }}
                </li>
                <li class="no-hover" *checkVisibility="{ visibleModes: [MapWorkspacePermissionType.FILE_VIEW_MODE] }">
                    {{ 'TC.Common.NoPreviewableData' | translate }}
                </li>
            </ng-container>

            <li
                class="fade-in fade-out layer-container"
                *ngFor="let layer of layers; index as i"
                [ngClass]="{
                    selected: layer.selected,
                    'layer-loading': isDisabled(layer),
                    locked: layer.isLocked
                }"
                (contextmenu)="layerListContextMenu.onContextMenu($event, layer)"
            >
                <div class="layer-geom">
                    <!-- <donut
                        class="assim-donut"
                        [size]="26"
                        *ngIf="layer.layerCreationStatus == 'Pending' || layer.layerCreationStatus == 'InProgress'"
                        [alertColor]="'#005F9E'"
                        [warningColor]="'#005F9E'"
                        [dangerColor]="'#005F9E'"
                        [okColor]="'#005F9E'"
                        [index]="'active' + i"
                    ></donut> -->
                    <div
                        class="badge--old"
                        *ngIf="layer.nonSpatialCount > 0"
                        title="{{ 'TCS.Mapviewer.LayerContextMenu.SelectFormNoLocation' | translate }}"
                        tooltipLocation="right"
                        data-tooltip-show-always="true"
                        (click)="addNonSpatialToPanel(layer)"
                    >
                        {{ layer.nonSpatialCount }}
                    </div>
                    <geom-icon
                        *ngIf="!layer.layerIsBeingDuplicated"
                        [path]="'sym_32-layer-'"
                        [color]="layer.color"
                        [geoType]="layer.geometryType"
                        id="{{ layer.id }}"
                        (click)="!isDisabled(layer) && goToEditModal(layer)"
                    ></geom-icon>
                </div>
                <i class="lock-template" *ngIf="layer.isLocked"></i>
                <img
                    class="layer-pending-icon"
                    title="{{ 'TCS.Import.Assimilating' | translate }}"
                    tooltipLocation="right"
                    *ngIf="layer.layerIsBeingDuplicated"
                    height="16"
                    width="24"
                    src="assets/images/loading_spinner.svg"
                />
                <div class="layer-title" (click)="zoomToLayerExtents(layer)">
                    <div class="layer-name" title="{{ layer.layerName }}">
                        {{ layer.layerName }}
                    </div>
                    <div
                        *ngIf="!layer.templatePublishStatus && layer.templateId"
                        translate="TC.Common.DraftTemplate"
                        class="layer-status"
                    ></div>
                    <div *ngIf="layer.workspaces.length > 1" class="layer-status">
                        <i class="icon_line_link i16 linked-icon"></i>
                        <span translate="TC.Common.Linked"></span>
                    </div>
                </div>
                <ng-container *ngIf="layer.templateId">
                    <div
                        class="layer-edit"
                        *checkVisibility="{ visibleModes: [MapWorkspacePermissionType.FULL_ACCESS] }"
                        (click)="!isDisabled(layer) && goToTemplateEditor(layer)"
                    >
                        <div translate="TCW_Edit"></div>
                        <i class="draft-icon" *ngIf="!layer.templatePublishStatus && layer.templateVersionId == 1"></i>
                        <i
                            class="template-draft-icon"
                            *ngIf="!layer.templatePublishStatus && layer.templateVersionId > 1"
                        ></i>
                        <i class="template-icon" *ngIf="layer.templatePublishStatus"></i>
                    </div>
                </ng-container>
                <ng-container *ngIf="showCreateTemplateAction(layer)">
                    <div
                        class="layer-edit"
                        *checkVisibility="{
                            visibleModes: [MapWorkspacePermissionType.FULL_ACCESS]
                        }"
                        (click)="!isDisabled(layer) && layerToTemplate(layer)"
                    >
                        <div translate="TC.Common.Add"></div>
                        <i class="template-add-icon i24"></i>
                    </div>
                </ng-container>
                <div class="layer-view" [ngClass]="{ hide_layer: !layer.visible }" (click)="toggle(i)">
                    <i
                        class="i24"
                        [ngClass]="{
                            icon_line_visibility_show: layer.visible,
                            icon_line_visibility_hide: !layer.visible
                        }"
                    ></i>
                </div>

                <gsp-context-menu #layerListContextMenu [items]="contextMenuItems"></gsp-context-menu>
            </li>
        </ng-container>
    </ul>

    <map-cache-list></map-cache-list>
</custom-scroll>

<div
    class="tip"
    *ngIf="!isTemplateLayerExist() && layers.length && !tooltipClosed && !currentMapWorkspace.isFileViewer"
>
    <i class="i16 icon_line_x" (click)="tooltipClosed = true"></i>
    <div>
        <span>{{ 'TC.Common.Tip' | translate }}</span
        >:&nbsp;{{ 'TCS.Mapviewer.LayerPanel.AddTemplateTip' | translate }}
    </div>
    <img src="assets/images/no-template-tip.png" alt="" />
</div>
<footer>
    <upload-status *ngIf="currentMapWorkspace.id && !currentMapWorkspace.isPubliclySharedMapWorkspace"></upload-status>
    <fileviewer-upload-status *ngIf="currentMapWorkspace.isFileViewer"></fileviewer-upload-status>
    <map-provider-list></map-provider-list>
    <add-menu [showAddDataTip]="!loading && !layers.length"></add-menu>
</footer>
