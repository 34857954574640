import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { MapPanAndZoomStream } from 'src/app/feature/map-viewer/common/map-pan-zoom-stream.service';
import { MapSelectionStreamService } from 'src/app/feature/map-viewer/map-selection/selection-stream.service';
import { MapService } from 'src/app/feature/map-viewer/map.service';
import { ContextMenuActions } from 'src/app/shared/common/components/context-menu/gsp-context-menu.component';
import { GeometryTypeSelectionCriteria } from 'src/app/shared/common/current-features/selection-criteria';
import { LayersStore } from 'src/app/shared/common/current-layers/layers-store.service';
import { LayersStreams } from 'src/app/shared/common/current-layers/layers-streams.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { TasksStore } from 'src/app/shared/common/current-tasks/tasks-store.service';
import { CurrentUserStreamService } from 'src/app/shared/common/current-user/current-user-stream.service';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';
import { TemplateTypeId } from 'src/app/shared/map-data-services/layer/template-lite';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { MapWorkspacePermissionType } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace-permission';

import { FeatureFilterStreamService } from 'src/app/shared/common/current-features/feature-filter-stream.service';
import { LayerListService } from './layer-list.service';
import { RemoveLayerComponent } from './remove-layer.component';

@Component({
    templateUrl: './layer-list.component.html',
    selector: 'layer-list'
})
export class LayerListComponent implements OnInit, OnDestroy {
    public contextMenuItems: ContextMenuActions[] = [
        {
            name: this.translate.instant('TC.Common.ZoomToLayer'),
            id: 'zoom-to-layer',
            labelClass: 'layer-context-menu-item',
            title: false,
            visible: ($event: Layer) =>
                this.layerlistService.canViewLayer($event) && this.layerlistService.canZoomToExtent($event),
            execute: ($event: { item: Layer }) => this.zoomToLayerExtents($event.item)
        },
        {
            name: this.translate.instant('TCS.Mapviewer.LayerContextMenu.SelectFormNoLocation'),
            id: 'select-form-no-location',
            labelClass: 'layer-context-menu-item',
            title: false,
            visible: ($event: Layer) => this.layerlistService.canViewLayer($event) && $event.nonSpatialCount > 0,
            execute: ($event: { item: Layer }) => this.addNonSpatialToPanel($event.item)
        },
        {
            name: null,
            execute: () => null,
            divider: true,
            title: false,
            visible: () => true
        },
        {
            name: this.translate.instant('TCS.Mapviewer.LayerContextMenu.EditLayer'),
            id: 'edit-layer',
            labelClass: 'layer-context-menu-item',
            title: false,
            visible: ($event: Layer) =>
                !this.layerlistService.restrictAccess([MapWorkspacePermissionType.FULL_ACCESS]) &&
                this.layerlistService.canEditLayer($event),
            execute: ($event: { item: Layer }) => this.goToEditModal($event.item)
        },
        {
            name: this.translate.instant('TCS.Mapviewer.LayerContextMenu.LockLayer'),
            id: 'lock-layer',
            labelClass: 'layer-context-menu-item',
            title: false,
            visible: ($event: Layer) =>
                !this.layerlistService.restrictAccess([MapWorkspacePermissionType.FULL_ACCESS]) &&
                this.layerlistService.canLockLayer($event) &&
                $event.templateId &&
                !$event.isLocked,
            execute: ($event: { item: Layer }) => this.changeLockStatus($event.item, true)
        },
        {
            name: this.translate.instant('TCS.Mapviewer.LayerContextMenu.UnlockLayer'),
            id: 'unlock-layer',
            labelClass: 'layer-context-menu-item',
            title: false,
            visible: ($event: Layer) =>
                !this.layerlistService.restrictAccess([MapWorkspacePermissionType.FULL_ACCESS]) &&
                this.layerlistService.canLockLayer($event) &&
                $event.templateId &&
                $event.isLocked,
            execute: ($event: { item: Layer }) => this.changeLockStatus($event.item, false)
        },
        {
            name: this.translate.instant('TCS.Mapviewer.LayerContextMenu.RemoveLayer'),
            id: 'remove-layer',
            labelClass: 'layer-context-menu-item',
            title: false,
            visible: () => !this.layerlistService.restrictAccess([MapWorkspacePermissionType.FULL_ACCESS]),
            execute: ($event: { item: Layer }) => this.removeLayer($event.item)
        },
        {
            name: null,
            execute: () => null,
            divider: true,
            title: false,
            visible: () => true
        },
        {
            name: this.translate.instant('TC.Common.CreateTemplate'),
            id: 'create-template',
            labelClass: 'layer-context-menu-item',
            title: false,
            visible: ($event: Layer) =>
                !this.layerlistService.restrictAccess([MapWorkspacePermissionType.FULL_ACCESS]) &&
                this.layerlistService.canCreateTemplateFromLayer($event),
            execute: ($event: { item: Layer }) => this.layerToTemplate($event.item)
        },
        {
            name: this.translate.instant('TC.Common.DuplicateTemplate'),
            id: 'duplicate-template',
            labelClass: 'layer-context-menu-item',
            title: false,
            visible: ($event: Layer) =>
                !this.layerlistService.restrictAccess([MapWorkspacePermissionType.FULL_ACCESS]) &&
                this.layerlistService.canEditTemplate($event),
            execute: ($event: { item: Layer }) => this.layersStore.createDuplicateLayer($event.item)
        },
        {
            name: this.translate.instant('TC.Common.EditTemplate'),
            id: 'edit-template',
            labelClass: 'layer-context-menu-item',
            title: false,
            visible: ($event: Layer) =>
                !this.layerlistService.restrictAccess([MapWorkspacePermissionType.FULL_ACCESS]) &&
                this.layerlistService.canEditTemplate($event),
            execute: ($event: { item: Layer }) => this.goToTemplateEditor($event.item)
        },
        {
            name: this.translate.instant('TC.Common.SelectAllForms'),
            id: 'select-all-forms',
            labelClass: 'layer-context-menu-item',
            title: false,
            visible: ($event: Layer) =>
                this.layerlistService.canViewLayer($event) && (!!$event.bounds || $event.nonSpatialCount > 0),
            execute: ($event: { item: Layer }) => this.selectAllForms($event.item)
        },
        {
            name: this.translate.instant('MapViewer.Template.DiscardDraft.Menu'),
            id: 'discard-draft-changes',
            labelClass: 'layer-context-menu-item',
            title: false,
            visible: ($event: Layer) => this.layerlistService.canDiscardDraftChanges($event),
            execute: ($event: { item: Layer }) =>
                this.goToCenterDialog('layer/discardDraftChanges', { layerId: $event.item.id }, true)
        }
    ];

    public loading = true;
    public currentMapWorkspace: MapWorkspace;
    public currentUserIsAdmin: boolean;
    public layers: Layer[];
    public visibleAll: boolean;
    public loadingLayerIds: string[];
    public templateLayers: Layer[];
    public tooltipClosed = false; // used by html

    // exposing enum to template
    public MapWorkspacePermissionType = MapWorkspacePermissionType;

    private destroyed = new Subject<void>();

    @ViewChild(RemoveLayerComponent)
    removeLayerComponent: RemoveLayerComponent;

    constructor(
        private router: Router,
        private layersStreams: LayersStreams,
        private layersStore: LayersStore,
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private messaging: MessagingService,
        private mapPanAndZoomStream: MapPanAndZoomStream,
        private tasksStore: TasksStore,
        private selectionStream: MapSelectionStreamService,
        private mapService: MapService,
        private projectStream: ProjectStreamService,
        private currentUserStream: CurrentUserStreamService,
        private translate: TranslationService,
        private layerlistService: LayerListService,
        private featureFilterStream: FeatureFilterStreamService
    ) {}

    ngOnInit(): void {
        this.projectStream
            .getUserWithRolesForCurrentProject(this.currentUserStream.currentUser.id)
            .then(userWithRole => {
                this.currentUserIsAdmin = userWithRole.role === 'ADMIN' ? true : false;
            });

        this.layers = [];

        this.currentMapWorkspace = this.mapWorkspacesStore.getCurrentMapWorkspace();

        this.layersStore.mapWorkspaceLayersLoadingStream.pipe(takeUntil(this.destroyed)).subscribe(loading => {
            this.loading = loading;
        });

        this.layersStore.mapWorkspaceLayersStream.pipe(takeUntil(this.destroyed)).subscribe(tmpLayers => {
            if (tmpLayers) {
                this.currentMapWorkspace = this.mapWorkspacesStore.getCurrentMapWorkspace();
                tmpLayers = _.uniqBy(tmpLayers, 'id');

                // updating only the new layers on layer import
                _.differenceWith(tmpLayers, this.layers, _.isEqual).forEach(layer => {
                    if (layer.visible) {
                        this.layersStreams.deferVisibleLayersStream.next([layer]);
                    }
                });

                this.layers = this.layersStreams.sortLayers(tmpLayers);

                let visibleLayers = this.layers.filter(layer => layer.visible);
                if (visibleLayers.length < this.layers.length) {
                    this.visibleAll = false;
                } else if (visibleLayers.length === this.layers.length) {
                    this.visibleAll = true;
                }
                this.layersStreams.setVisibleLayers(visibleLayers, false);
            }
        });

        this.loadingLayerIds = [];
        this.layersStreams.loadingLayersStream.pipe(takeUntil(this.destroyed)).subscribe(layers => {
            this.loadingLayerIds = layers.map(layer => layer.id);
        });

        this.layersStore.changedMapWorkspaceLayerStream
            .pipe(takeUntil(this.destroyed), distinctUntilChanged())
            .subscribe((layer: Layer) => {
                if (layer && layer.id && layer.isUpdating === true) {
                    let layerIndex = this.layers.findIndex(layer2 => layer2.id === layer.id);
                    if (layerIndex !== -1) {
                        this.layers[layerIndex].isUpdating = layer.isUpdating;
                    } else if (layer.workspaceId === this.currentMapWorkspace.id) {
                        this.layers = this.layers || [];
                        this.layers.unshift(layer);
                    }
                }
            });
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    public layersAreLoading(): boolean {
        return this.loadingLayerIds.length > 0;
    }

    public layerIsLoading(layer: Layer): boolean {
        return layer && (this.loadingLayerIds.indexOf(layer.id) >= 0 || !layer.allDerivedPropertiesLoaded);
    }

    public toggle(index: number): void {
        this.layers[index].visible = !this.layers[index].visible;
        let visibleLayers = this.layers.filter(layr => layr.visible);
        if (visibleLayers.length < this.layers.length) {
            this.visibleAll = false;
        } else if (visibleLayers.length === this.layers.length) {
            this.visibleAll = true;
        }

        this.layersStreams.setVisibleLayers(
            visibleLayers,
            this.currentMapWorkspace.isPubliclySharedMapWorkspace || this.currentMapWorkspace.isFileViewer
                ? false
                : true
        );

        if (this.layers[index].visible) {
            this.layersStreams.deferVisibleLayersStream.next([this.layers[index]]);
        } else {
            this.layersStreams.deferHideLayersStream.next([this.layers[index]]);
        }
    }

    public toggleAll(): void {
        this.visibleAll = !this.visibleAll;
        this.layers.forEach(layer => {
            layer.visible = this.visibleAll;
        });
        let visibleLayers = this.layers.filter(layer => layer.visible);
        this.layersStreams.setVisibleLayers(
            visibleLayers,
            this.currentMapWorkspace.isPubliclySharedMapWorkspace || this.currentMapWorkspace.isFileViewer
                ? false
                : true
        );
        if (this.visibleAll) {
            this.layersStreams.deferVisibleLayersStream.next(this.layers);
        } else {
            this.layersStreams.deferHideLayersStream.next(this.layers);
        }
    }

    public isTemplateLayerExist(): boolean {
        this.templateLayers = this.layers.filter(layer => layer.geoLayerType === 'TemplateLayer');
        return this.templateLayers.length > 0;
    }

    public async hasActiveTasks(layer: Layer): Promise<boolean> {
        let activeTaskByTemplateSeriesId = this.tasksStore.mapWorkspaceActiveTasksByTemplateSeriesIdStream.getValue();
        if (activeTaskByTemplateSeriesId === null) {
            await this.tasksStore.refreshMapWorkspaceTasks();
            activeTaskByTemplateSeriesId = this.tasksStore.mapWorkspaceActiveTasksByTemplateSeriesIdStream.getValue();
        }

        if (
            activeTaskByTemplateSeriesId &&
            activeTaskByTemplateSeriesId[layer.templateSeriesId] &&
            _.some(activeTaskByTemplateSeriesId[layer.templateSeriesId], task => !task.isClosed)
        ) {
            this.messaging.showWarning(this.translate.instant('TCS.Mapviewer.LayerPanel.TemplateHasActiveTask'));
            return true;
        }
        return false;
    }

    showCreateTemplateAction(layer: Layer): boolean {
        return this.layerlistService.canCreateTemplateFromLayer(layer);
    }

    // This used for deleting imported layers in error - see removeLayerComponent for remove of other layers
    public deleteImportedErrorLayer(layer: Layer): void {
        this.layersStore.deleteLayer(layer).catch(() => {
            this.messaging.showError(this.translate.instant('TCS.Import.ErrorDeletingLayer'));
        });
    }

    public async changeLockStatus(layer: Layer, lockStatus: boolean): Promise<void> {
        if (layer.isLocked !== lockStatus) {
            if ((lockStatus && !(await this.hasActiveTasks(layer))) || !lockStatus) {
                layer.isLocked = lockStatus;
                this.layersStore.lockOrUnlockLayer(layer, lockStatus).then(() => {
                    if (lockStatus) {
                        this.messaging.showSuccess(
                            this.translate.instant('TCS.Mapviewer.LayerPanel.LayerLockSuccessfully')
                        );
                    } else {
                        this.messaging.showSuccess(
                            this.translate.instant('TCS.Mapviewer.LayerPanel.LayerUnlockSuccessfully')
                        );
                    }
                    // Remove the cached layers by mapworkspace Id in case of lock status change of linked layers
                    this.layersStore.removeLayersIndexByMapWorkspace(layer.workspaceId);
                });
            }
        }
    }

    public zoomToLayerExtents(layer: Layer): void {
        if (this.layerlistService.canZoomToExtent(layer)) {
            this.layersStore.loadLayersBounds([layer]).then(bounds => {
                let basemapMode = this.mapService.selectedBaseMapMode;
                this.mapPanAndZoomStream.zoomToBounds(bounds, [360, 60], [340, 10], {
                    maxZoom: basemapMode.maxZoom
                });
                this.layersStreams.deferVisibleLayersStream.next([layer]);
            });
        }
    }

    public layerToTemplate(layer: Layer): void {
        if (layer.fileVersionId) {
            this.router.navigate(['template/wizard'], {
                queryParams: {
                    templateId: TemplateTypeId.FROM_LAYER,
                    layerId: layer.id
                },
                queryParamsHandling: 'merge'
            });
        } else {
            this.router.navigate(['template/editor'], {
                queryParams: { [TemplateTypeId.FROM_LAYER]: layer.id },
                queryParamsHandling: 'merge'
            });
        }
    }

    public addNonSpatialToPanel(layer: Layer): void {
        this.selectionStream.stream.next(new GeometryTypeSelectionCriteria([layer], 'none'));
    }

    public goToEditModal(layer: Layer): void {
        this.layersStreams.currentEditLayerStream.next(layer);
        this.goToCenterDialog('layer/edit', { layerId: layer.id });
    }

    public isDisabled(layer: Layer): boolean {
        return (layer && this.layerIsLoading(layer)) || layer.isUpdating;
    }

    public goToTemplateEditor(layer: Layer): void {
        this.router.navigate(['template/editor'], {
            queryParams: { layerId: layer.id, templateId: layer.templateId },
            queryParamsHandling: 'merge'
        });
    }
    public async removeLayer(layer: Layer): Promise<void> {
        if (!(await this.hasActiveTasks(layer))) {
            try {
                await this.layersStore.removeLayerFromMapWorkspace(layer);
                this.layersStreams.deferHideLayersStream.next([layer]);
                const filter = this.featureFilterStream.activeFilter;
                if (filter?.selectedLayer === layer.id) {
                    filter.selectedLayer = null;
                    this.featureFilterStream.activeFilter = filter;
                }
                this.showSuccess(layer);
            } catch {
                this.messaging.showError(this.translate.instant('TC.Common.ErrorWhileDeleting'));
            }
        }
    }

    private showSuccess(deletedlayer: Layer): void {
        let wid = this.currentMapWorkspace.id;
        let pid = this.currentMapWorkspace.projectId;

        let toastr = this.messaging.showSuccess(
            this.translate.instant('TCS.Mapviewer.LayerPanel.LayerRemoved') +
                '<br>' +
                this.translate.instant('RESTORE') +
                ' ' +
                deletedlayer.layerName,
            null,
            { enableHtml: true }
        );

        toastr.onTap.subscribe(() => {
            // restore layer
            this.restoreLayer(pid, wid, deletedlayer);
        });
    }

    private async restoreLayer(currentProjectId: string, currentWorkspaceId: string, layer: Layer): Promise<Layer> {
        try {
            await this.layersStore.addLayerToMapWorkspace(currentProjectId, currentWorkspaceId, layer);
            this.messaging.showSuccess(this.translate.instant('TCS.Mapviewer.LayerPanel.RestoreSuccess'));
            return layer;
        } catch (e) {
            this.messaging.showError(this.translate.instant('TC.Common.ErrorWhileRestoring'));
            return await Promise.resolve(null);
        }
    }

    private selectAllForms(layer: Layer): void {
        this.selectionStream.stream.next(new GeometryTypeSelectionCriteria([layer], null));
    }

    private goToCenterDialog(
        centerDialog: string,
        queryParams: { [key: string]: string },
        skipLocationChange = false
    ): void {
        this.router.navigate(['mapViewer', { outlets: { centerDialog } }], {
            queryParams,
            queryParamsHandling: 'merge',
            skipLocationChange
        });
    }
}
