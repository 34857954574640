import { Injectable } from '@angular/core';
import { LatLngBounds } from 'leaflet';
import * as _ from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { FileViewerImportService } from 'src/app/feature/import/file-viewer/fileviewer-import.service';
import { FeatureService } from 'src/app/shared/map-data-services/feature/feature.service';
import { GeoPutLayerRequest, Layer } from 'src/app/shared/map-data-services/layer/layer';
import { LayerStyleGenerator } from 'src/app/shared/map-data-services/layer/layer-style-generator';
import { LayerService } from 'src/app/shared/map-data-services/layer/layer.service';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { MapWorkspaceService } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace.service';
import { RelationshipService } from 'src/app/shared/map-data-services/relationship/relationship.service';
import { Style } from 'src/app/shared/map-data-services/styles/style';
import { UserSettingsStreamService } from 'src/app/shared/user/user-settings-stream.service';

import { TemplateStatus } from '../../map-data-services/layer/template-lite';
import { MapWorkspacePermissionType } from '../../map-data-services/mapWorkspace/map-workspace-permission';
import { RelationshipType } from '../../map-data-services/relationship/relationship';
import { Template } from '../../template-services/template';
import { FeatureFilterStreamService } from '../current-features/feature-filter-stream.service';
import { MapWorkspacesStoreService } from '../current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from '../current-project/project-stream.service';
import { TemplatesStoreService } from '../current-templates/templates-store.service';
import { FullFeatureFilter } from '../feature-filter/full-feature-filter';
import { CloneUtils } from '../utility/clone-utils';
import { GeometryTypes, GeometryUtils } from '../utility/geometry-utils';
import { StylesStore } from './styles-store.service';

@Injectable({
    providedIn: 'root'
})
export class LayersStore {
    private layersIndexByMapWorkspace: { [key: string]: Layer[] } = {};

    constructor(
        private projectStream: ProjectStreamService,
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private layerService: LayerService,
        private relationshipService: RelationshipService,
        private stylesStore: StylesStore,
        private templatesStore: TemplatesStoreService,
        private userSettingsStream: UserSettingsStreamService,
        private featureService: FeatureService,
        private featureFilterStream: FeatureFilterStreamService,
        private mapWorkspaceService: MapWorkspaceService,
        private fileViewerImportService: FileViewerImportService
    ) {
        this.fileViewerImportService.fileViewerLayersStream.subscribe(layers => {
            if (layers && layers.length) {
                const workspace = this.mapWorkspacesStore.getCurrentMapWorkspace();
                if (workspace) {
                    delete this.layersIndexByMapWorkspace[workspace.id];
                }

                this._loadMapWorkspaceLayers(workspace);
            }
        });
    }

    private currentMapWorkspace: MapWorkspace = null;
    private layerKeyToMapWorkspaceLayer: { [key: string]: Layer } = {};
    private layerIdToMapWorkspaceLayer: { [key: string]: Layer } = {};
    private importedLayerGeomTypeDict: { [key: string]: GeometryTypes } = {};

    // true when loading the layer definitions (not feature content) for the current workspace; false otherwise
    private _mapWorkspaceLayersLoadingStream = new BehaviorSubject(false);
    public mapWorkspaceLayersLoadingStream = this._mapWorkspaceLayersLoadingStream.pipe(
        distinctUntilChanged(),
        shareReplay(1)
    );

    // the layers for the current map workspace
    public mapWorkspaceLayersStream = new BehaviorSubject<Layer[]>([]);

    // a layer that has been changed (added, updated, or deleted)
    public changedMapWorkspaceLayerStream = new BehaviorSubject<Layer>(null);

    // the layers for which content (i.e. features) is requested to be refreshed on the map
    public refreshMapWorkspaceLayersStreams = new BehaviorSubject<Layer[]>([]);

    // --------------------------------------
    // LAYER LOADING

    public loadMapWorkspaceLayers(mapWorkspace: MapWorkspace): Promise<Layer[]> {
        if (mapWorkspace && mapWorkspace.permission !== MapWorkspacePermissionType.NO_ACCESS) {
            return this._loadMapWorkspaceLayers(mapWorkspace);
        } else {
            return this._loadMapWorkspaceLayers(null);
        }
    }

    private _loadMapWorkspaceLayers(mapWorkspace: MapWorkspace): Promise<Layer[]> {
        this.currentMapWorkspace = mapWorkspace;
        let mapWorkspaceLayers: Layer[] = [];
        this._mapWorkspaceLayersLoadingStream.next(true);

        if (!mapWorkspace || !mapWorkspace.id) {
            this._mapWorkspaceLayersLoadingStream.next(false);
            this.mapWorkspaceLayersStream.next([]);
            return Promise.resolve([]);
        }

        if (this.layersIndexByMapWorkspace[mapWorkspace.id] && this.layersIndexByMapWorkspace[mapWorkspace.id].length) {
            this.layersIndexByMapWorkspace[mapWorkspace.id].forEach(layer => {
                layer.isUpdating = false;
                layer.workspaceId = this.currentMapWorkspace.id;
            });
            if (!mapWorkspace.isPubliclySharedMapWorkspace) {
                mapWorkspaceLayers = this.addVisibleStatusFromUserSettings(
                    this.layersIndexByMapWorkspace[mapWorkspace.id]
                );

                return this.featureService
                    .getBulkMaxUpdatedUtcOfLayers(this.projectStream.getCurrentProject().id, mapWorkspaceLayers)
                    .then(lastUpdatedUtcPerLayerResponse => {
                        mapWorkspaceLayers.forEach(layer => {
                            layer.updateCacheTimeStamp(lastUpdatedUtcPerLayerResponse[layer.id]);
                        });
                        this.mapWorkspaceLayersStream.next(mapWorkspaceLayers);
                        this._mapWorkspaceLayersLoadingStream.next(false);
                        return Promise.resolve(mapWorkspaceLayers);
                    });
            } else {
                this.mapWorkspaceLayersStream.next(mapWorkspaceLayers);
                this._mapWorkspaceLayersLoadingStream.next(false);
                return Promise.resolve(mapWorkspaceLayers);
            }
        } else {
            return Promise.all([
                this.getLayersByMapWorkspace(mapWorkspace).then(layers => {
                    this.layersIndexByMapWorkspace[mapWorkspace.id] = layers;
                    mapWorkspaceLayers = layers;
                }),
                this.stylesStore.loadProjectStyles(mapWorkspace.projectId) // reload all in cases of changes
            ])
                .then(async () => {
                    // cannot access /features/layers/layerGeomType api for fileviewer workspace
                    if (!mapWorkspace.isFileViewer) {
                        await this.updateImportedLayersGeomTypeDict(mapWorkspace.projectId, mapWorkspaceLayers);
                    }
                    return Promise.all(
                        mapWorkspaceLayers.map(async layer => {
                            layer.isUpdating = false;
                            layer.workspaceId = this.currentMapWorkspace.id;
                            layer.projectId = this.currentMapWorkspace.projectId;
                            layer.visible = mapWorkspace.isPubliclySharedMapWorkspace || mapWorkspace.isFileViewer;
                            await this.loadDerivedPropertiesForLayer(mapWorkspace.projectId, layer);
                            this.addOrUpdateCache(layer);
                            return layer;
                        })
                    );
                })
                .finally(() => {
                    if (!mapWorkspace.isPubliclySharedMapWorkspace && !mapWorkspace.isFileViewer) {
                        mapWorkspaceLayers = this.addVisibleStatusFromUserSettings(mapWorkspaceLayers);

                        return this.featureService
                            .getBulkMaxUpdatedUtcOfLayers(this.projectStream.getCurrentProject().id, mapWorkspaceLayers)
                            .then(lastUpdatedUtcPerLayerResponse => {
                                mapWorkspaceLayers.forEach(layer => {
                                    layer.updateCacheTimeStamp(lastUpdatedUtcPerLayerResponse[layer.id]);
                                });
                                this.mapWorkspaceLayersStream.next(mapWorkspaceLayers);
                                this._mapWorkspaceLayersLoadingStream.next(false);
                                return mapWorkspaceLayers;
                            });
                    } else {
                        this.mapWorkspaceLayersStream.next(mapWorkspaceLayers);
                        this._mapWorkspaceLayersLoadingStream.next(false);
                        return mapWorkspaceLayers;
                    }
                });
        }
    }

    public async getLayersByMapWorkspace(mapWorkspace: MapWorkspace): Promise<Layer[]> {
        if (!mapWorkspace.isFileViewer) {
            const layers = await this.layerService.getLayers(mapWorkspace.projectId, mapWorkspace.id);
            const latLongBounds = await this.featureService.getBulkBoundsOfLayers(mapWorkspace.projectId, layers);
            layers.forEach(layer => {
                layer.bounds = latLongBounds[layer.id];
                layer.boundsPropertyLoaded = true;
            });
            return layers;
        } else {
            return Promise.resolve(this.fileViewerImportService.fileViewerLayersStream.value);
        }
    }

    // -------------------------------------------------------------------------
    // LOADING OF DERIVED LAYER PROPERTIES

    private async loadDerivedPropertiesForLayer(projectId: string, layer: Layer): Promise<Layer> {
        let promises: Promise<Layer>[] = [];
        promises.push(this.loadPermissionForLayerWorkspace(layer));
        promises.push(this.loadGeometryStyleAndTemplateProperties(projectId, layer));
        promises.push(this.loadBoundsProperty(projectId, layer));
        promises.push(this.loadNonSpatialDataCount(projectId, layer));

        const layer_responses = await Promise.all(promises);
        // First Request
        layer.workspacePermission = layer_responses[0].workspacePermission;
        // Second request
        layer.geometryType = layer_responses[1].geometryType;
        layer.styleId = layer_responses[1].styleId;
        layer.style = layer_responses[1].style;
        layer.color = layer_responses[1].color;
        layer.isLinkedLayer = layer_responses[1].isLinkedLayer;
        layer.allGeometryStyleTemplatePropertiesLoaded = layer_responses[1].allGeometryStyleTemplatePropertiesLoaded;
        if (layer.templateId) {
            layer.templateSeriesId = layer_responses[1].templateSeriesId;
            layer.template = layer_responses[1].template;
            layer.templateColor = layer_responses[1].templateColor;
            layer.templateVersionId = layer_responses[1].templateVersionId;
            layer.templatePublishStatus = layer_responses[1].templatePublishStatus;
        }
        // Third request
        layer.bounds = layer_responses[2].bounds;
        layer.boundsPropertyLoaded = layer_responses[2].boundsPropertyLoaded;
        // Fourth request
        layer.nonSpatialCount = layer_responses[3].nonSpatialCount;
        layer.allDerivedPropertiesLoaded = true;
        return layer;
    }

    public async loadPermissionForLayerWorkspace(layer: Layer): Promise<Layer> {
        let project = this.projectStream.getCurrentProject();
        if (!layer.workspaces.length) {
            layer.workspacePermission = MapWorkspacePermissionType.NO_ACCESS;
            return Promise.resolve(layer);
        } else if (layer.workspacePermission) {
            return Promise.resolve(layer);
        } else {
            let promises: Promise<MapWorkspacePermissionType>[] = [];
            layer.workspaces.forEach(workspace => {
                workspace.projectId = project.id;
                let promise = this.mapWorkspacesStore.getPermissionForMapWorkspace(workspace);
                promises.push(promise);
            });
            if (promises.length) {
                let workspacePermissions: MapWorkspacePermissionType[] = [];
                const layerWorkspacesPermission = await Promise.all(promises);
                layer.workspaces.forEach((workspace, index) => {
                    layer.workspaces[index].permission = layerWorkspacesPermission[index];
                    workspacePermissions.push(layerWorkspacesPermission[index]);
                });
                if (workspacePermissions.indexOf(MapWorkspacePermissionType.FULL_ACCESS) > -1) {
                    layer.workspacePermission = MapWorkspacePermissionType.FULL_ACCESS;
                } else if (workspacePermissions.indexOf(MapWorkspacePermissionType.READ) > -1) {
                    layer.workspacePermission = MapWorkspacePermissionType.READ;
                } else {
                    layer.workspacePermission = workspacePermissions[0];
                }
                return layer;
            } else {
                layer.workspacePermission = MapWorkspacePermissionType.NO_ACCESS;
                return Promise.resolve(layer);
            }
        }
    }

    public async loadGeometryStyleAndTemplateProperties(projectId: string, layer: Layer): Promise<Layer> {
        if (layer.allGeometryStyleTemplatePropertiesLoaded) {
            layer.isLinkedLayer = layer.workspaces.length > 1;
            layer.templatePublishStatus = layer.template && layer.template.status === TemplateStatus.PUBLISHED;

            return Promise.resolve(layer);
        } else {
            let promise = !layer.templateId
                ? this.loadDerivedPropertiesForImportedLayer(projectId, layer)
                : this.loadDerivedPropertiesForTemplateLayer(projectId, layer);

            const lyr = await promise;
            lyr.isLinkedLayer = lyr.workspaces.length > 1;
            lyr.allGeometryStyleTemplatePropertiesLoaded = true;
            return lyr;
        }
    }

    // Fetch first feature for getting geometry type of the import layer.
    private async loadDerivedPropertiesForImportedLayer(projectId: string, layer: Layer): Promise<Layer> {
        const response = await this.loadStyleAndTypeFromImportedLayer(projectId, layer);
        layer.styleId = response.styleId;
        layer.geometryType = response.geometryType;
        const style = await this.stylesStore.getStyle(projectId, layer.styleId);
        layer.style = style;
        layer.color = style.name.substr(style.name.indexOf('#'));
        return layer;
    }

    private loadStyleAndTypeFromImportedLayer(
        projectId: string,
        layer: Layer
    ): Promise<{ styleId: string; geometryType: GeometryTypes }> {
        return new Promise(async (resolve, reject) => {
            let response = {
                styleId: '',
                geometryType: GeometryTypes.NONE
            };
            // eslint-disable-next-line @typescript-eslint/no-shadow
            let filter = new FullFeatureFilter();
            filter.layers = [layer];

            const geometryType = await this.getImportedLayerGeomType(projectId, layer);
            if (geometryType) {
                response.geometryType = geometryType;
                const randomStyle: Style = LayerStyleGenerator.getStyles(1)[0];
                layer.color = randomStyle.name.replace('style', '');
            } else {
                response.geometryType = GeometryTypes.NONE;
                layer.color = '#000000';
            }
            if (layer.styleId) {
                response.styleId = layer.styleId;
                resolve(response);
            } else {
                let style = this.stylesStore.getStyleByName(projectId, 'style' + layer.color);
                if (!style) {
                    let newStyle = new Style();
                    if (layer.color) {
                        newStyle.setColor(layer.color);
                    }
                    newStyle.setName('style' + layer.color);
                    const styl = await this.stylesStore.createStyle(projectId, newStyle);
                    if (!layer.isLayerFromFile) {
                        await this.updateLayer(projectId, layer.id, {
                            layerName: layer.layerName,
                            templateId: null,
                            styleId: styl.id,
                            layerType: layer.geoLayerType
                        });
                    }
                    response.styleId = styl.id;
                } else {
                    response.styleId = style.id;
                }
                resolve(response);
            }
        });
    }

    private async loadDerivedPropertiesForTemplateLayer(projectId: string, layer: Layer): Promise<Layer> {
        const style = await this.loadStyleFromTemplateLayer(projectId, layer);
        layer.style = style;
        layer.color = layer.getLayerColor();
        if (layer.template && layer.template.id) {
            layer.templateName = layer.template.name;
            layer.geometryType = layer.template.geometryType;
            layer.templateColor = layer.template.geometryColorHexRGB;
            layer.templateSeriesId = layer.template.seriesId;
            layer.templateVersionId = layer.template.version;
            layer.templatePublishStatus = layer.template && layer.template.status === TemplateStatus.PUBLISHED;
            return layer;
        } else {
            const template = await this.templatesStore.getTemplateById(layer.templateId);
            layer.template = template;
            if (layer.template) {
                layer.templateName = layer.template.name;
                layer.geometryType = layer.template.geometryType;
                layer.templateColor = layer.template.geometryColorHexRGB;
                layer.templateSeriesId = layer.template.seriesId;
                layer.templateVersionId = layer.template.version;
            }
            layer.templatePublishStatus = layer.template && layer.template.status === TemplateStatus.PUBLISHED;
            return layer;
        }
    }

    private async loadStyleFromTemplateLayer(projectId: string, layer: Layer): Promise<Style> {
        const style = await this.stylesStore.getStyle(projectId, layer.styleId);
        return style;
    }

    private async loadBoundsProperty(projectId: string, layer: Layer): Promise<Layer> {
        if (layer.boundsPropertyLoaded) {
            return Promise.resolve(layer);
        } else if (!layer.templateId || layer.templateId) {
            const latLongBounds = await this.loadLayersBounds([layer]);
            layer.bounds = latLongBounds;
            layer.boundsPropertyLoaded = true;
            return layer;
        } else {
            return Promise.resolve(layer);
        }
    }

    public loadLayersBounds(layers: Layer[]): Promise<LatLngBounds> {
        let projectId = this.projectStream.getCurrentProject().id;
        // TODO: Doesn't handle file viewer case here yet!
        let featureFilter = new FullFeatureFilter();
        featureFilter.layers = layers;
        return this.featureService.getBoundsOfFeatures(projectId, featureFilter);
    }

    private async loadNonSpatialDataCount(projectId: string, layer: Layer): Promise<Layer> {
        let workspace = this.mapWorkspacesStore.getCurrentMapWorkspace();
        if (workspace && layer) {
            const nonSpatialDataCount = await this.featureService.getNonSpatialDataCountForLayer(
                projectId,
                workspace.id,
                layer,
                this.featureFilterStream.activeFilter
            );
            layer.nonSpatialCount = nonSpatialDataCount;
            return layer;
        } else {
            return Promise.resolve(layer);
        }
    }

    // --------------------------------------
    // LAYER OPERATIONS (ON SERVICE AND CACHE)

    public async createLayer(
        projectId: string,
        layerProperties: any,
        workspaceId: string = null,
        delayAddingLayerToWorkspace: boolean = false
    ): Promise<Layer> {
        // create layer; if workspaceId specified, then also add to the workspace

        const layer = await this.layerService.createLayer(projectId, layerProperties);
        layer.workspaceId = workspaceId;
        layer.projectId = projectId;
        layer.isLocked = layer.getLockStatus(workspaceId);
        if (!delayAddingLayerToWorkspace) {
            this.addLayerToMapWorkspace(projectId, workspaceId, layer);
        }
        return layer;
    }

    public createDuplicateLayer(layer: Layer): Promise<boolean> {
        layer.layerIsBeingDuplicated = true;
        let projectId = this.projectStream.getCurrentProject().id;
        let workspaceId = this.mapWorkspacesStore.getCurrentMapWorkspace().id;
        return new Promise(async (resolve, reject) => {
            const template = await this.templatesStore.createDuplicateTemplateFromLayer(layer);
            const style = await this.templatesStore.createOrUpdateStyle(template);
            layer.layerIsBeingDuplicated = false;
            await this.createLayer(
                projectId,
                {
                    layerName: template.name,
                    templateSeriesId: template.seriesId,
                    templateId: template.id,
                    styleId: style.id,
                    layerType: 'TemplateLayer'
                },
                workspaceId
            );
            resolve(true);
        });
    }

    public async updateLayer(projectId: string, layerId: string, layerProperties: any): Promise<Layer> {
        let existingLayer = this.getMapWorkspaceLayerById(layerId);
        let existingLayerVisible = existingLayer ? existingLayer.visible : true;
        try {
            const layer = await this.layerService.updateLayer(projectId, layerId, layerProperties);
            layer.isLocked = layer.getLockStatus(existingLayer.workspaceId);
            layer.nonSpatialCount = existingLayer && existingLayer.nonSpatialCount;
            layer.allDerivedPropertiesLoaded = false;
            layer.allGeometryStyleTemplatePropertiesLoaded = false;
            layer.boundsPropertyLoaded = false;
            let currentMapWorkspace = this.mapWorkspacesStore.getCurrentMapWorkspace();
            let currentMapWorkspaceLayer = [];
            if (currentMapWorkspace) {
                currentMapWorkspaceLayer = layer.workspaces.filter(
                    workspace => currentMapWorkspace.id === workspace.id
                );
            }
            if (currentMapWorkspaceLayer.length > 0) {
                layer.isUpdating = true;
                this.changedMapWorkspaceLayerStream.next(layer);
            }
            if (layer.workspaces.length) {
                layer.workspaces[0].projectId = projectId;
                const workspacePermission = await this.mapWorkspacesStore.getPermissionForMapWorkspace(
                    layer.workspaces[0]
                );
                if (workspacePermission) {
                    layer.workspacePermission = workspacePermission;
                }
            }
            return this.refreshLayerInStore(projectId, layer, existingLayerVisible);
        } catch (err) {
            return null;
        }
    }

    public async addLayerToMapWorkspace(projectId: string, mapWorkspaceId: string, layer: Layer): Promise<Layer> {
        layer = CloneUtils.cloneDeep(layer);
        layer.workspaceId = mapWorkspaceId;
        layer.projectId = projectId;
        layer.isUpdating = true;
        layer.isLocked = layer.getLockStatus(mapWorkspaceId);
        this.changedMapWorkspaceLayerStream.next(layer);
        this.addOrUpdateCache(layer);

        let relationshipProperties = {
            workspaceId: mapWorkspaceId,
            layerId: layer.id,
            isLocked: layer.isLocked
        };
        await this.relationshipService.createLayerWorkspaceRelationship(projectId, relationshipProperties);
        const mapWorkspace = await this.mapWorkspaceService.getMapWorkspaceById(projectId, mapWorkspaceId);
        this.mapWorkspacesStore.updateMapWorkspaceCache(mapWorkspace);
        const lyr = await this.layerService.getLayerById(projectId, layer.id);
        lyr.isUpdating = true;
        this.addOrUpdateCache(lyr);
        let mapWorkspaceLayers = CloneUtils.cloneDeep(this.mapWorkspaceLayersStream.getValue());
        let currentMapWorkspace = this.mapWorkspacesStore.getCurrentMapWorkspace();
        if (currentMapWorkspace && mapWorkspaceId === currentMapWorkspace.id) {
            lyr.workspaceId = mapWorkspaceId;
            lyr.projectId = projectId;
            lyr.isLocked = lyr.getLockStatus(mapWorkspaceId);
            mapWorkspaceLayers.push(lyr);
        }
        this.layersIndexByMapWorkspace[mapWorkspaceId] = mapWorkspaceLayers;
        return this.refreshLayerInStore(projectId, lyr, true);
    }

    // remove layer from workspace (return true if layer is still no longer in other workspaces; false otherwise)
    public async removeLayerFromMapWorkspace(deletedLayer: Layer): Promise<boolean> {
        let projectId = this.projectStream.getCurrentProject().id;
        let workspace = this.mapWorkspacesStore.getCurrentMapWorkspace();
        let layer = _.cloneDeep(deletedLayer);

        let relationshipProperty = {
            childId: layer.id,
            relationshipType: RelationshipType.LAYER_TO_WORKSPACE
        };

        await this.relationshipService.detachFromMapWorkspace(projectId, workspace.id, relationshipProperty);
        let findIndex = layer.workspaces.findIndex(tmpWorkspace => tmpWorkspace.id === workspace.id);
        if (findIndex > -1) {
            const layerLockedIndex = layer.isLockedInWorkspaces.indexOf(workspace.id);
            if (layerLockedIndex > -1) {
                layer.isLockedInWorkspaces.splice(layerLockedIndex, 1);
            }
            layer.workspaces.splice(findIndex, 1);
            this.addOrUpdateCache(layer);
        }
        let mapWorkspaceLayers = CloneUtils.cloneDeep(this.mapWorkspaceLayersStream.getValue());
        const index = mapWorkspaceLayers.findIndex(mapWorkspaceLayer => mapWorkspaceLayer.id === layer.id);
        mapWorkspaceLayers.splice(index, 1);
        Object.keys(this.layersIndexByMapWorkspace).forEach(workspaceId => {
            let findLayerIndex = this.layersIndexByMapWorkspace[workspaceId].findIndex(
                mapWorkspaceLayer_1 => mapWorkspaceLayer_1.id === layer.id
            );
            if (findLayerIndex > -1) {
                if (workspaceId === workspace.id) {
                    this.layersIndexByMapWorkspace[workspaceId].splice(findLayerIndex, 1);
                } else {
                    let findWSIndex = this.layersIndexByMapWorkspace[workspaceId][findLayerIndex].workspaces.findIndex(
                        tmpWorkspace_1 => tmpWorkspace_1.id === workspace.id
                    );
                    if (findWSIndex > -1) {
                        this.layersIndexByMapWorkspace[workspaceId][findLayerIndex].workspaces.splice(findIndex, 1);
                        this.layersIndexByMapWorkspace[workspaceId][findLayerIndex].isLinkedLayer =
                            this.layersIndexByMapWorkspace[workspaceId][findLayerIndex].workspaces.length > 1;

                        this.addOrUpdateCache(layer);
                    }
                }
            }
        });
        this.mapWorkspaceLayersStream.next(mapWorkspaceLayers);
        if (!workspace.isFileViewer && !workspace.isPubliclySharedMapWorkspace) {
            this.saveVisibleStatusToUserSettings(mapWorkspaceLayers);
        }
        if (!layer.workspaces.length) {
            layer.isRemoved = true;
            this.changedMapWorkspaceLayerStream.next(layer);
            this.removeFromCache(layer.id);
            return true; // Layer no longer shared in any workspaces
        } else {
            let userHasAccessToWorkspaces = layer.workspaces.map(w => w.permission);

            layer.workspaceId = null;
            layer.projectId = null;
            layer.workspacePermission =
                userHasAccessToWorkspaces.indexOf(MapWorkspacePermissionType.FULL_ACCESS) > -1
                    ? MapWorkspacePermissionType.FULL_ACCESS
                    : userHasAccessToWorkspaces.indexOf(MapWorkspacePermissionType.READ) > -1
                    ? MapWorkspacePermissionType.READ
                    : MapWorkspacePermissionType.NO_ACCESS;
            this.changedMapWorkspaceLayerStream.next(layer);
            return false; // Layer still shared in other workspaces
        }
    }

    // deletes layer (only used for removing failed imported layers)
    public async deleteLayer(layer: Layer): Promise<void> {
        await this.removeLayerFromMapWorkspace(layer);
        return this.layerService.deleteLayer(this.projectStream.getCurrentProject().id, layer.id);
    }

    public async refreshLayerInStore(projectId: string, layer: Layer, existingLayerVisible: boolean): Promise<Layer> {
        await this.loadDerivedPropertiesForLayer(projectId, layer);
        let currentMapWorkspace = this.mapWorkspacesStore.getCurrentMapWorkspace();
        let currentMapWorkspaceLayer = layer.workspaces.filter(
            workspace => currentMapWorkspace && currentMapWorkspace.id === workspace.id
        );
        if (currentMapWorkspaceLayer.length > 0) {
            this.refreshLayerInMapWorkspace(projectId, layer, existingLayerVisible);
            if (layer.templatePublishStatus || layer.geoLayerType === 'ImportedLayer') {
                const res = await this.featureService.getBulkMaxUpdatedUtcOfLayers(projectId, [layer]);
                layer.updateCacheTimeStamp(res[layer.id]);
                this.refreshMapWorkspaceLayersStreams.next([layer]);
                this.changedMapWorkspaceLayerStream.next(layer);
                return layer;
            }
        } else {
            this.changedMapWorkspaceLayerStream.next(layer);
            return layer;
        }
    }

    public restoreLayer(projectId: string, layer: Layer): void {
        this.refreshLayerInMapWorkspace(projectId, layer, true);
    }

    private refreshLayerInMapWorkspace(projectId: string, layer: Layer, setVisible: boolean): void {
        this.addOrUpdateCache(layer);
        let mapWorkspaceLayers = CloneUtils.cloneDeep(this.mapWorkspaceLayersStream.getValue());
        let layerIndex = -1;
        layerIndex = mapWorkspaceLayers.findIndex(mapWorkspaceLayer => mapWorkspaceLayer.id === layer.id);
        let workspace = this.mapWorkspacesStore.getCurrentMapWorkspace();
        layer.workspaceId = workspace.id;
        layer.projectId = workspace.projectId;
        if (layer.isUpdating) {
            this.changedMapWorkspaceLayerStream.next(null);
        }
        layer.isUpdating = false;
        if (layerIndex !== -1) {
            mapWorkspaceLayers[layerIndex] = layer;
        } else {
            mapWorkspaceLayers.unshift(layer);
            layerIndex = 0;
        }
        let tmpLayers = mapWorkspaceLayers;
        if (!workspace.isPubliclySharedMapWorkspace) {
            tmpLayers = this.addVisibleStatusFromUserSettings(mapWorkspaceLayers);
        }
        if (layer.templateId || !layer.templateId) {
            if (setVisible) {
                tmpLayers[layerIndex].visible = true;
            }
            if (!workspace.isFileViewer && !workspace.isPubliclySharedMapWorkspace) {
                this.saveVisibleStatusToUserSettings(tmpLayers);
            }
        }
        this.mapWorkspaceLayersStream.next(tmpLayers);

        Object.keys(this.layersIndexByMapWorkspace).forEach(workspaceId => {
            let layer2 = CloneUtils.cloneDeep(layer);
            let workspaceLayerIndex = this.layersIndexByMapWorkspace[workspaceId].findIndex(
                workspaceLayer => workspaceLayer.id === layer.id
            );
            if (workspaceLayerIndex !== -1) {
                // retaining the old template lock status before refresh
                layer2.isLocked = this.layersIndexByMapWorkspace[workspaceId][workspaceLayerIndex].isLocked;
                this.layersIndexByMapWorkspace[workspaceId][workspaceLayerIndex] = layer2;
            }
        });
    }

    public refreshNonSpatialCount(): void {
        let projectId = this.projectStream.getCurrentProject().id;
        let mapWorkspaceLayers = this.mapWorkspaceLayersStream.getValue();

        mapWorkspaceLayers.forEach(layer => {
            if (
                !this.featureFilterStream.activeFilter.selectedLayer ||
                this.featureFilterStream.activeFilter.selectedLayer === layer.id
            ) {
                this.loadNonSpatialDataCount(projectId, layer);
            }
        });
    }

    public async lockOrUnlockLayer(layer: Layer, lockStatus: boolean): Promise<Layer> {
        let projectId = this.projectStream.getCurrentProject().id;
        let currentMapWorkspace = this.mapWorkspacesStore.getCurrentMapWorkspace();
        const relationshipProperties = {
            workspaceId: currentMapWorkspace.id,
            layerId: layer.id,
            isLocked: layer.isLocked
        };
        const layerResponse = await this.relationshipService.updateLayerWorkspaceRelationship(
            projectId,
            relationshipProperties
        );
        layer.isLockedInWorkspaces = layerResponse.isLockedInWorkspaces;
        const mapWorkspace = await this.mapWorkspaceService.getMapWorkspaceById(projectId, currentMapWorkspace.id);
        this.mapWorkspacesStore.updateMapWorkspaceCache(mapWorkspace);
        this.refreshLayerInMapWorkspace(projectId, layer, layer.visible);
        this.changedMapWorkspaceLayerStream.next(layer);
        return layer;
    }

    // refreshes the content of layers content on the map by busting the layer cacheTimeStamp
    public refreshMapWorkspaceLayersContent(): void {
        let filterLayers: Layer[] = [];

        const workspaceLayers = this.mapWorkspaceLayersStream.getValue();

        if (workspaceLayers && workspaceLayers.length) {
            this.featureService
                .getBulkMaxUpdatedUtcOfLayers(this.projectStream.getCurrentProject().id, workspaceLayers)
                .then(async lastUpdatedUtcPerLayerResponse => {
                    const lastModifiedTimestamps = lastUpdatedUtcPerLayerResponse;
                    for (const layer of workspaceLayers) {
                        layer.updateCacheTimeStamp(lastModifiedTimestamps[layer.id]);
                        layer.bounds = await this.loadLayersBounds([layer]);
                        filterLayers.push(layer);
                    }
                    this.refreshMapWorkspaceLayersStreams.next(filterLayers);
                });
        }
    }

    // --------------------------------------
    // LAYER CACHE MANAGEMENT/ACCESS

    private addOrUpdateCache(layer: Layer): void {
        this.layerIdToMapWorkspaceLayer[layer.id] = layer;
        let layerKeys = layer.getLayerKeys();
        if (layerKeys) {
            layerKeys.forEach(layerKey => {
                this.layerKeyToMapWorkspaceLayer[layerKey] = layer;
            });
        }
    }

    private removeFromCache(layerId: string): void {
        let layer = this.layerIdToMapWorkspaceLayer[layerId];
        if (layer) {
            let layerKeys = layer.getLayerKeys();
            if (layerKeys) {
                layerKeys.forEach(layerKey => {
                    delete this.layerKeyToMapWorkspaceLayer[layerKey];
                });
            }
            delete this.layerIdToMapWorkspaceLayer[layerId];
        }
    }

    public getMapWorkspaceLayerById(layerId: string): Layer {
        return this.layerIdToMapWorkspaceLayer[layerId];
    }

    public getMapWorkspaceLayerByIdPromise(layerId: string): Promise<Layer> {
        return new Promise((resolve, reject) => {
            if (this.layerIdToMapWorkspaceLayer[layerId]) {
                resolve(this.layerIdToMapWorkspaceLayer[layerId]);
            } else {
                let projectId = this.projectStream.getCurrentProject().id;
                this.layerService.getLayerById(projectId, layerId).then(layer => {
                    this.loadDerivedPropertiesForLayer(projectId, layer).then(() => {
                        layer.allDerivedPropertiesLoaded = true;
                        this.layerIdToMapWorkspaceLayer[layerId] = layer;
                        resolve(layer);
                    });
                });
            }
        });
    }

    public updateLayersInCacheForRemovedMapWorkspace(removedMapWorkspace: MapWorkspace): void {
        if (removedMapWorkspace && removedMapWorkspace.isDeleted === true) {
            let layerIds = removedMapWorkspace.relationships.layerIds || [];
            layerIds.forEach(layerId => {
                let layer = this.getMapWorkspaceLayerById(layerId);
                if (layer) {
                    layer.workspaces.forEach(workspace => {
                        // update layersIndexByMapWorkspace cache for the layer
                        if (this.layersIndexByMapWorkspace[workspace.id]) {
                            let layerIndex = this.layersIndexByMapWorkspace[workspace.id].findIndex(
                                tmpLayer => tmpLayer.id === layer.id
                            );
                            if (layerIndex > -1) {
                                // remove the removed worspace from associated workspace list
                                this.layersIndexByMapWorkspace[workspace.id][layerIndex].workspaces =
                                    this.layersIndexByMapWorkspace[workspace.id][layerIndex].workspaces.filter(
                                        tmpWorkspace => tmpWorkspace.id !== removedMapWorkspace.id
                                    );
                                layer.workspaces = layer.workspaces.filter(
                                    tmpWorkspace => tmpWorkspace.id !== removedMapWorkspace.id
                                );
                                this.changedMapWorkspaceLayerStream.next(layer);
                            }
                        }
                    });
                }
            });
        }
    }

    // --------
    // VISIBLE LAYERS (state stored in user setting)

    private saveVisibleStatusToUserSettings(layers: Layer[]): void {
        const currentMapWorkspaceSettings = this.userSettingsStream.getCurrentMapWorkspaceSettings();
        currentMapWorkspaceSettings.visibleLayerIds = layers.filter(layer => layer.visible).map(layer => layer.id);

        currentMapWorkspaceSettings.showAllLayers = 0;

        this.userSettingsStream.updateCurrentWorkspaceSettings(currentMapWorkspaceSettings);
    }

    private addVisibleStatusFromUserSettings(layers: Layer[]): Layer[] {
        let userMapWorkspaceSettings = this.userSettingsStream.getCurrentMapWorkspaceSettings();
        let savedVisibleLayerIds = userMapWorkspaceSettings.visibleLayerIds || [];
        layers.forEach(layer => {
            layer.visible =
                userMapWorkspaceSettings.showAllLayers === 1 ||
                layer.visible ||
                savedVisibleLayerIds.indexOf(layer.id) >= 0;
        });
        return layers;
    }

    public getLayerKeyToLayerMap(): { [key: string]: Layer } {
        let LayerKeyToLayerMap: { [key: string]: Layer } = {};

        let allLayers = this.mapWorkspaceLayersStream.getValue() || [];

        allLayers.forEach(layer => {
            let layerKeys = layer.getLayerKeys();
            if (layerKeys) {
                layerKeys.forEach(layerKey => {
                    LayerKeyToLayerMap[layerKey] = layer;
                });
            }
        });
        return LayerKeyToLayerMap;
    }

    // Added to remove the cached layers by mapworkspace Id in case of template publication of layers with linked templates
    public removeLayersIndexByMapWorkspace(workspaceId: string): void {
        delete this.layersIndexByMapWorkspace[workspaceId];
    }

    private async getImportedLayerGeomType(projectId: string, layer: Layer): Promise<GeometryTypes> {
        if (layer.isLayerFromFile) {
            return this.featureService.getGeometryTypesFromFeatures(projectId, layer);
        } else {
            if (!this.importedLayerGeomTypeDict[layer.id]) {
                await this.updateImportedLayersGeomTypeDict(projectId, [layer]);
            }
            return GeometryUtils.getGeometryType(this.importedLayerGeomTypeDict[layer.id]);
        }
    }

    public async updateImportedLayersGeomTypeDict(projectId: string, layers: Layer[]): Promise<void> {
        const importedLayerIds = layers.reduce((acc: string[], layer) => {
            if (!layer.templateId) {
                acc.push(layer.id);
            }
            return acc;
        }, []);
        if (importedLayerIds.length) {
            this.importedLayerGeomTypeDict = {
                ...this.importedLayerGeomTypeDict,
                ...(await this.featureService.getGeometryTypes(projectId, importedLayerIds))
            };
        }
    }

    public constructLayerFromTemplate = (template: Template, layer: Layer): GeoPutLayerRequest =>
        Object.assign(layer.toDTO(), {
            layerName: layer.layerName,
            templateId: template.id,
            templateSeriesId: template.seriesId,
            styleId: layer.styleId,
            layerType: 'TemplateLayer'
        });
}
