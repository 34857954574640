import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export class CustomTranslateLoader implements TranslateLoader {
    constructor(private http: HttpClient, public prefix: string, public suffix: string) {}

    /**
     * Gets the translations from the server
     */
    public getTranslation(lang: string): Observable<object> {
        return this.http.get(`${this.prefix}${lang}${this.suffix}`);
    }
}

// required for AOT compilation
export const HttpLoaderFactory = (http: HttpClient): CustomTranslateLoader =>
    new CustomTranslateLoader(http, './i18n/', '.json?v=' + new Date().getTime());
