import * as _ from 'lodash-es';
import { TranslationService } from 'src/app/core/translation/translation.service';

import { Condition, ConditionOperator } from './condition';

export class YesNoCondition extends Condition {
    static readonly yesNoConditionsText = [
        {
            name: 'TC.Common.IsAnswered',
            value: ConditionOperator.IS_ANSWERED
        },
        {
            name: 'TC.Common.IsYes',
            value: ConditionOperator.IS_YES
        },
        {
            name: 'TC.Common.IsNo',
            value: ConditionOperator.IS_NO
        }
    ];

    constructor(uuid: string, type: string, public operator = ConditionOperator.IS_ANSWERED) {
        super(uuid, type);
    }

    getErrorMessagesNo(): number[] {
        return [];
    }

    // Set the attribute for the this rule.
    setAttributes(operator: ConditionOperator): void {
        this.operator = operator || ConditionOperator.IS_ANSWERED;
    }

    // Get the condition text which is displayed in sidebar.
    getConditionText(translator: TranslationService, value: string): string {
        value = value || this.operator;
        let conditionPair = _.find(
            YesNoCondition.yesNoConditionsText,
            yesNoCondition => yesNoCondition.value === value
        );
        return translator.instant(conditionPair.name);
    }

    // Returns the list of operators used for this condition.
    getConditionPair(): { name: string; value: string }[] {
        return YesNoCondition.yesNoConditionsText;
    }
}
