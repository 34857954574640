<mat-chip-grid #chipList [hidden]="addChipMode && !multiInputMode">
    <mat-chip-row
        *ngFor="let chip of selectedChips"
        [value]="stringChip"
        [selectable]="selectable"
        [removable]="editable"
        [title]="chip"
        (removed)="removeChip(chip)"
    >
        {{ chip }}

        <button matChipRemove class="remove-button" *ngIf="editable">&#x2716;</button>
    </mat-chip-row>

    <gsp-button
        [type]="ButtonType.TextIcon"
        (action)="enableAddChipMode()"
        [text]="addChipText"
        class="button-text-action button-add-chip"
        *ngIf="!multiInputMode && editable"
    ></gsp-button>
</mat-chip-grid>

<input
    #chipInput
    [class]="inputClass"
    [placeholder]="placeholder"
    [ngClass]="{ 'tag-add-mode': addChipMode }"
    maxlength="50"
    [formControl]="chipCtrl"
    [matAutocomplete]="auto"
    [matChipInputFor]="chipList"
    (keydown.enter)="enterKeyAction()"
    [hidden]="!addChipMode && !multiInputMode"
/>

<gsp-button
    *ngIf="addChipMode && !multiInputMode"
    [type]="ButtonType.TextIcon"
    (action)="cancelAddChip()"
    [text]="'TC.Common.Cancel' | translate"
    buttonTooltip="{{ 'TCS.Workspace.DownloadWorkspace' | translate }}"
    class="button-text-action cancel-add-chip"
></gsp-button>

<mat-autocomplete #auto="matAutocomplete" [panelWidth]="250" (optionSelected)="addChip($event.option.value)">
    <mat-option *ngIf="!chipMatchFound && isChipValid(chipCtrl.value)" (mousedown)="(false)">
        {{ 'TCW_No_Tag_Results_Found' | translate: { userSearch: chipCtrl.value } }}
    </mat-option>
    <mat-option *ngIf="chipPartialMatchFound && isChipValid(chipCtrl.value)" (mousedown)="(false)">
        {{ 'TCW_Tag_Add_New' | translate: { userSearch: chipCtrl.value } }}
    </mat-option>
    <mat-option *ngIf="chipAlreadyAdded && chipCtrl.value.length" (mousedown)="(false)" [disabled]="true">
        {{ 'TCW_Tag_Already_Added' | translate: { userSearch: chipCtrl.value } }}
    </mat-option>
    <mat-option *ngIf="isChipRestricted(chipCtrl.value)" (mousedown)="(false)" [disabled]="true">
        {{ chipCtrl.value }}
    </mat-option>
    <mat-option *ngFor="let chip of filteredChips" [value]="chip" (mousedown)="(false)">
        {{ chip }}
    </mat-option>
</mat-autocomplete>
