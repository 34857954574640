import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../../authentication/authentication.service';

export const tidAuthGuard: CanActivateFn = async (
    route: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot,
    authenticationService: AuthenticationService = inject(AuthenticationService),
    router: Router = inject(Router)
) => {
    try {
        const tokenResponse = await authenticationService.getTokensFromAuthCode(route.queryParams.code);
        authenticationService.login(
            tokenResponse.access_token,
            new Date().getTime() + tokenResponse.expires_in * 1000,
            tokenResponse.refresh_token
        );
        return true;
    } catch {
        window.location.href = await authenticationService.getLoginUrl();
        return false;
    }
};
