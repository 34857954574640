import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoadCreateWorkspaceComponent } from 'src/app/core/init/load-create-workspace.component';
import { LoadFileviewerComponent } from 'src/app/core/init/load-fileviewer.component';
import { LoadMapviewerComponent } from 'src/app/core/init/load-mapviewer.component';

import { TIDLoginComponent } from './core/authentication/tid-login/tid-login.component';
import { authGuard } from './core/routing/route-guards/auth-guard.service';
import { sharedFileViewerGuardService } from './core/routing/route-guards/shared-fileviewer-guard.service';
import { sharedGuardService } from './core/routing/route-guards/shared-guard.service';
import { tidAuthGuard } from './core/routing/route-guards/tid-auth-guard.service';
import { ImportFilesFileviewerComponent } from './feature/import/file-viewer/fileviewer-import-files.component';
import { PublicSharedViewerComponent } from './feature/shared-viewer/public-shared-viewer.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'maps',
        pathMatch: 'full'
    },
    {
        path: 'auth',
        canActivate: [tidAuthGuard],
        component: TIDLoginComponent
    },
    {
        path: 'maps',
        canActivate: [authGuard],
        component: LoadMapviewerComponent
    },
    {
        path: 'maps/:projectId/:connectFolderId/:connectFileId',
        canActivate: [authGuard],
        component: LoadCreateWorkspaceComponent
    },
    {
        // To support backward compactibility
        path: 'fileViewer/:projectId/:params1/:connectFileVersionIds/:params2?embed&allowedOrigin&accessToken&options',
        canActivate: [authGuard],
        component: LoadFileviewerComponent
    },
    {
        // used to be fileViewer/:projectId/:connectFileVersionIds?embed&allowedOrigin&accessToken&options&shared
        path: 'fileViewer/:projectId/:connectFileVersionIds',
        canActivate: [authGuard],
        component: LoadFileviewerComponent,
        data: {
            visibility: ['PUBLIC', 'REGISTERED_USERS']
        }
    },
    {
        path: 'fileViewerImporter',
        canActivate: [authGuard],
        component: ImportFilesFileviewerComponent,
        data: {
            visibility: ['PUBLIC', 'REGISTERED_USERS']
        }
    },
    {
        path: 'shareMap/:shareToken',
        canActivate: [sharedGuardService],
        component: PublicSharedViewerComponent
    },
    {
        path: 'shareMap/:shareToken/fileViewer/:projectId/:connectFileVersionIds',
        canActivate: [sharedFileViewerGuardService],
        component: LoadFileviewerComponent
    },
    {
        path: 'template', // /:projectId/:workspaceId'
        loadChildren: () =>
            import('./feature/template-editor/template-editor-routing.module').then(m => m.TemplateEditorRoutingModule)
    },
    {
        path: 'export',
        loadChildren: () =>
            import('./feature/export-downloader/export-downloader-routing.module').then(
                m => m.ExportDownloaderRoutingModule
            )
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            enableTracing: false,
            useHash: true
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
