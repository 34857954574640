import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType, Plugin } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChartClickEvent } from './gsp-chart';

@Component({
    selector: 'gsp-chart',
    templateUrl: './gsp-chart.component.html'
})
export class GspChartComponent implements OnInit, OnDestroy {
    @Input()
    data: ChartData[];

    @Input()
    options: ChartConfiguration['options'];

    @Input()
    chartType: ChartType = 'bar';

    @Input()
    plugins: Plugin[] = [];

    @Input()
    updateChartStream?: Subject<void>;

    @Input()
    chartHeight: number;

    @Input()
    chartWidth: number;

    @Output()
    chartClick = new EventEmitter<ChartClickEvent>();

    @ViewChild(BaseChartDirective)
    chart: BaseChartDirective;

    private destroyed = new Subject<void>();

    constructor() {}

    ngOnInit(): void {
        this.updateChartStream?.pipe(takeUntil(this.destroyed)).subscribe(() => this.chart?.update());
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }
}
