import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { DateConverter, InputConverter } from 'src/app/shared/common/components/input-converter.decorator';

@Directive({
    selector: '[minDate]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MinDateValidatorDirective, multi: true }]
})
export class MinDateValidatorDirective implements Validator, OnChanges {
    @Input()
    @InputConverter(DateConverter)
    minDate: Date;

    private _onValidatorChange = () => {};

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.minDate) {
            this._onValidatorChange();
        }
    }

    registerOnValidatorChange?(fn: () => void): void {
        this._onValidatorChange = fn;
    }

    validate(control: AbstractControl): ValidationErrors {
        if (
            this.minDate !== null &&
            this.minDate !== undefined &&
            control.value &&
            new Date(control.value) < this.minDate
        ) {
            return { minDate: { minimum: this.minDate } };
        }
        return null;
    }
}
