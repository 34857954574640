import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { LayersStore } from 'src/app/shared/common/current-layers/layers-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { TemplatesStoreService } from 'src/app/shared/common/current-templates/templates-store.service';
import { FullFeatureFilter } from 'src/app/shared/common/feature-filter/full-feature-filter';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';
import { GeometryTypes } from 'src/app/shared/common/utility/geometry-utils';
import { Feature } from 'src/app/shared/map-data-services/feature/feature';
import { FeatureService } from 'src/app/shared/map-data-services/feature/feature.service';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';
import { LayerService } from 'src/app/shared/map-data-services/layer/layer.service';
import { TemplateTypeId } from 'src/app/shared/map-data-services/layer/template-lite';
import { CurrentEditTemplateStream } from 'src/app/shared/template-services/current-edit-template-stream.service';
import { Field, FieldType, GroupField, LayoutFieldType } from 'src/app/shared/template-services/field';
import { FieldsStoreService } from 'src/app/shared/template-services/field/fields-store.service';
import { Template } from 'src/app/shared/template-services/template';

import { TemplateWizardField, TemplateWizardFieldStatus } from './individual-field/individual-field.component';
import { TemplateWizardService } from './template-wizard.service';

@Component({
    templateUrl: './template-wizard.component.html',
    selector: 'template-wizard'
})
export class TemplateWizardComponent implements OnDestroy {
    @ViewChild('fieldValues', { static: true })
    public fieldValues: ElementRef;

    // expose ButtonType enum to template
    public ButtonType = ButtonType;
    public LayoutFieldType = LayoutFieldType;

    private destroyed = new Subject<void>();

    public projectId: string;
    public workSpaceId: string;
    public region: string;

    public wizardTemplate: Template;
    public fetchedTemplate = false;
    public template: Template;
    public templateBeforeEdit: Template;
    public templateId: string;

    public layerId: string;
    public layer: Layer;
    public disableCreate = true;
    public totalFields: number;
    public currentFocused = 0;
    public focus: boolean[] = [];
    public loading = true;

    public showTemplateWizardCancelConfirmation = false;

    constructor(
        private router: Router,
        private projectStream: ProjectStreamService,
        private templatesStore: TemplatesStoreService,
        private fieldsStore: FieldsStoreService,
        private layersStore: LayersStore,
        private layerService: LayerService,
        private featureService: FeatureService,
        private $translate: TranslationService,
        private activatedRoute: ActivatedRoute,
        private currentEditTemplateStream: CurrentEditTemplateStream,
        private templateWizardService: TemplateWizardService
    ) {
        this.workSpaceId = this.activatedRoute.snapshot.queryParamMap.get('workspaceId');
        this.projectId = this.activatedRoute.snapshot.queryParamMap.get('projectId');
        this.templateId = this.activatedRoute.snapshot.queryParamMap.get('templateId');
        this.layerId = this.activatedRoute.snapshot.queryParamMap.get('layerId');
        this.region = this.activatedRoute.snapshot.queryParamMap.get('region');

        this.currentEditTemplateStream.pipe(takeUntil(this.destroyed)).subscribe(async template => {
            await this.setTemplate(template);
            if (template) {
                this.loading = false;
            }
        });

        this.templateWizardService.templateWizardCloseStream.pipe(takeUntil(this.destroyed)).subscribe(closeWizard => {
            if (closeWizard) {
                this.cancelChanges();
            }
        });
    }

    public moveToWorkspaceAction = () => this.moveToWorkspace();

    ngOnDestroy(): void {
        this.destroyed.next(null);
        this.destroyed.complete();
    }

    public async replaceEntireField(
        fieldToBeFocused: TemplateWizardField,
        fieldSelected: FieldType | null
    ): Promise<void> {
        if (!fieldToBeFocused || !fieldSelected) {
            return;
        }

        const fieldIndex = this.template.fields.findIndex(field => field.uuid === fieldToBeFocused.uuid);
        if (fieldIndex !== -1) {
            const { sampleData, tabOrder, wizardStatus, copiedType, values } = fieldToBeFocused;
            const selectedField = await this.fieldsStore.getFieldByType(
                fieldToBeFocused.subType || fieldToBeFocused.type,
                'Connect',
                'mapViewer'
            );
            if (selectedField) {
                const templateWizardField = new Field(
                    this.$translate,
                    fieldToBeFocused,
                    selectedField.schema
                ) as TemplateWizardField;
                templateWizardField.sampleData = sampleData;
                templateWizardField.tabOrder = tabOrder;
                templateWizardField.wizardStatus = wizardStatus;
                templateWizardField.copiedType = copiedType;
                templateWizardField.values = values;

                this.template.fields[fieldIndex] = templateWizardField;
            }
        }
    }

    public setFocus(fieldToBeFocused: TemplateWizardField): void {
        let focusIncrement = 0;
        let currentField: TemplateWizardField = null;
        this.template.fields.forEach((field: TemplateWizardField) => {
            if (field.type === LayoutFieldType.Group && (field as unknown as GroupField).fields) {
                (field as unknown as GroupField).fields.forEach((subField: TemplateWizardField) => {
                    if (currentField) {
                        this.currentFocused = focusIncrement;
                        currentField = null;
                    }
                    if (fieldToBeFocused && fieldToBeFocused.uuid === subField.uuid) {
                        this.currentFocused = focusIncrement;
                    }
                    this.focus[focusIncrement++] = false;
                    if (subField.wizardStatus === TemplateWizardFieldStatus.ACTIVE && !fieldToBeFocused) {
                        currentField = subField;
                    }
                });
            } else {
                if (currentField) {
                    this.currentFocused = focusIncrement;
                    currentField = null;
                }
                if (fieldToBeFocused && fieldToBeFocused.uuid === field.uuid) {
                    this.currentFocused = focusIncrement;
                }
                this.focus[focusIncrement++] = false;
                if (field.wizardStatus === TemplateWizardFieldStatus.ACTIVE && !fieldToBeFocused) {
                    currentField = field;
                }
            }
        });
        if (!currentField) {
            this.focus[this.currentFocused] = true;
        }
    }

    private async setTemplate(template: Template): Promise<Template | void> {
        if (
            template &&
            ((_.isUndefined(template.id) && this.fetchedTemplate) ||
                template.id === '' ||
                template.id === this.templateId)
        ) {
            this.disableCreate = false;
            this.wizardTemplate = this.template = CloneUtils.cloneDeep(template);
            this.getSampleData();
            this.setTabOrder();
            this.setFocus(null);
            return Promise.resolve(template);
        } else if (!this.wizardTemplate) {
            let newTemplate: Template;
            this.fetchedTemplate = true;
            if (this.templateId === TemplateTypeId.FROM_LAYER) {
                const layer = await this.layersStore.getMapWorkspaceLayerByIdPromise(this.layerId);
                this.layer = layer;
                newTemplate = await this.layerService.getTemplateByLayerId(
                    this.projectStream.getCurrentProject().id,
                    layer.id
                );
            } else {
                newTemplate = await this.templatesStore.getTemplateByTemplateAndLayerIds(this.templateId, this.layerId);
            }
            this.templateBeforeEdit = newTemplate;
            return this.templatesStore.setCurrentEditedTemplate(newTemplate);
        }
    }

    private setTabOrder(): void {
        let tabOrder = 0;
        this.template.fields.forEach((field: TemplateWizardField) => {
            if (field.type === LayoutFieldType.Group && (field as unknown as GroupField).fields) {
                (field as unknown as GroupField).fields.forEach((subField: TemplateWizardField) => {
                    subField.tabOrder = tabOrder++;
                });
            } else {
                field.tabOrder = tabOrder++;
            }
        });
        this.totalFields = tabOrder - 1;
    }

    public async populateChoiceValues(
        fieldToBeFocused: TemplateWizardField,
        fieldSelected: FieldType | null
    ): Promise<void> {
        let fullFeatureFilter = new FullFeatureFilter();
        if (this.layer) {
            this.disableCreate = true;
            fullFeatureFilter.layers = [this.layer];
            let choiceValues = await this.featureService.getDistinctFeatureValues(
                this.projectId,
                fullFeatureFilter,
                'properties.' + fieldToBeFocused.name
            );
            const text = this.$translate.instant('TC.Common.NewChoice');
            choiceValues = choiceValues.length ? choiceValues : [text + ' 1', text + ' 2'];
            fieldToBeFocused.values = choiceValues.reduce((filtered, value) => {
                if (value) {
                    const populateValue =
                        fieldSelected === FieldType.CodedChoice
                            ? { code: value, description: value, default: false }
                            : { text: value, default: false };

                    filtered.push(populateValue);
                }

                return filtered;
            }, []);
            this.disableCreate = false;
            await this.replaceEntireField(fieldToBeFocused, fieldSelected);
            this.setFocus(fieldToBeFocused);
        }
    }

    private getSampleData(): void {
        let fullFeatureFilter = new FullFeatureFilter();
        if (this.layer) {
            fullFeatureFilter.layers = [this.layer];
            this.featureService.getFeatures(this.projectId, fullFeatureFilter, 30).then(features => {
                if (features.length) {
                    this.template.fields.forEach((field: TemplateWizardField) => {
                        this.getDataForField(field, features);
                        if (field.type === LayoutFieldType.Group && (field as unknown as GroupField).fields) {
                            (field as unknown as GroupField).fields.forEach((subField: TemplateWizardField) => {
                                this.getDataForField(subField, features);
                            });
                        }
                    });
                }
            });
        }
    }

    private getDataForField(field: TemplateWizardField, features: Feature[]): any {
        field.sampleData = features.map(feature => {
            let tmpRet = feature.properties[field.name] || feature.properties[field.name.toLowerCase()] || '';
            if (typeof tmpRet === 'string') {
                tmpRet = tmpRet.replace(/\u0000/g, '');
            }
            return tmpRet;
        });
    }

    public moveToTemplateEditor(): void {
        if (!this.disableCreate) {
            this.template.fields.forEach((field: TemplateWizardField) => {
                field.wizardStatus = TemplateWizardFieldStatus.NEW;
                if (field.type === LayoutFieldType.Group && (field as unknown as GroupField).fields) {
                    (field as unknown as GroupField).fields.forEach((subField: TemplateWizardField) => {
                        subField.wizardStatus = TemplateWizardFieldStatus.NEW;
                    });
                }
            });
            this.templatesStore.setCurrentEditedTemplate(this.template);

            this.router.navigate(['template/editor'], {
                queryParams: { layerId: this.layerId, templateId: TemplateTypeId.DIRECT_TEMPLATE },
                queryParamsHandling: 'merge'
            });
        }
    }

    public cancelChanges(): void {
        this.templateWizardService.templateWizardCloseStream.next(false);
        const isTemplateChanged = !_.isEqual(this.template.toDTO(), this.templateBeforeEdit.toDTO());
        if (this.templateId === TemplateTypeId.FROM_LAYER) {
            if (isTemplateChanged) {
                this.showTemplateWizardCancelConfirmation = true;
            } else {
                this.moveToWorkspace();
            }
        } else {
            this.router.navigate(['template/editor'], {
                queryParams: { layerId: this.layerId, templateId: this.templateId },
                queryParamsHandling: 'merge'
            });
        }
    }

    public moveToWorkspace(): Promise<boolean> {
        this.templatesStore.setCurrentEditedTemplate(null);
        return this.router.navigate(['mapViewer'], {
            queryParams: {
                projectId: this.projectId,
                workspaceId: this.workSpaceId,
                templateId: null,
                layerId: null,
                region: this.region
            },
            queryParamsHandling: 'merge'
        });
    }

    public cancelWorkspaceNavigation(): void {
        this.showTemplateWizardCancelConfirmation = false;
    }
}
