import { Injectable } from '@angular/core';

import { Feature } from './feature';

export class CachedFeature extends Feature {
    editedOn: number;
    deleted: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class CachedFeatureService {
    // feature cache and management methods

    private featureIdToFeature: { [key: string]: CachedFeature } = {};

    public getFeature(featureId: string): CachedFeature {
        return this.featureIdToFeature[featureId];
    }

    public addOrUpdateFeature(feature: Feature): void {
        this.featureIdToFeature[feature.id] = feature as CachedFeature;
    }

    removeFeature(featureId: string): void {
        delete this.featureIdToFeature[featureId];
    }

    public cacheIsNewer(featureId: string, time: number): boolean {
        const cachedFeature = this.getFeature(featureId);
        return cachedFeature && cachedFeature.editedOn && cachedFeature.editedOn > time;
    }

    public updateFromCacheFeature(feature: Feature): CachedFeature {
        const cachedFeature = this.getFeature(feature.id);
        let updatedCachedFeature = feature as CachedFeature;

        if (cachedFeature) {
            updatedCachedFeature.inVisibleTask = cachedFeature.inVisibleTask;
            updatedCachedFeature.updated = cachedFeature.updated;
            updatedCachedFeature.selected = cachedFeature.selected;
            updatedCachedFeature.active = cachedFeature.active;
            updatedCachedFeature.editedOn = cachedFeature.editedOn;
            updatedCachedFeature.deleted = cachedFeature.deleted;
        }

        return updatedCachedFeature;
    }

    // --------------
    // feature cache querying methods

    public getMapFeaturesInTask(): CachedFeature[] {
        return Object.keys(this.featureIdToFeature)
            .map(key => this.featureIdToFeature[key])
            .filter(feature => feature.inVisibleTask === true);
    }
}
