import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'src/app/core/translation/translation.service';

@Pipe({ name: 'capitalize', pure: true })
export class CapitalizePipe implements PipeTransform {
    constructor(private translate: TranslationService) {}

    transform(value: string | string[], convertOtherWordsToLower: boolean = true): string | null {
        if (!value) {
            return null;
        }

        let copy = this.translate.instant(value);
        copy =
            copy.charAt(0).toUpperCase() + (convertOtherWordsToLower ? copy.substr(1).toLowerCase() : copy.substr(1));

        //  return the joined string
        return copy;
    }
}
