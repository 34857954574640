import { Component, OnInit } from '@angular/core';
import { GspWizardService, OnForward } from 'src/app/shared/common/components/gsp-wizard/gsp-wizard.service';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';

import { WorkspaceService } from '../workspace.service';

@Component({
    selector: 'workspace-duplication-details',
    templateUrl: './workspace-duplication-details.component.html'
})
export class WorkspaceDuplicationDetailsComponent implements OnInit, OnForward {
    public modifyWorkspace: MapWorkspace;
    public sourceWorkspace: MapWorkspace;
    public useSameCoordinateAsSource: boolean;
    public uiState = {
        backButton: { text: '', enabled: false, visible: false },
        forwardButton: { text: 'TC.Common.Next', enabled: false, visible: true },
        closeButton: { text: '', enabled: true, visible: true },
        cancelButton: { text: 'TC.Common.Cancel', enabled: true, visible: true }
    };

    constructor(private workspaceCreationService: WorkspaceService, private wizardService: GspWizardService) {}

    public ngOnInit(): void {
        this.modifyWorkspace = this.workspaceCreationService.modifyWorkspace$.getValue();
        this.sourceWorkspace = CloneUtils.cloneDeep(this.workspaceCreationService.sourceWorkspace$.getValue());
        this.useSameCoordinateAsSource = this.workspaceCreationService.useSourcePropertiesForDuplicate$.getValue();
    }

    public onValidationChange(isValid: boolean): void {
        this.uiState.forwardButton.enabled = isValid ? true : false;
        this.wizardService.setUI(this.uiState);
    }

    public onForward(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.workspaceCreationService.modifyWorkspace$.next(this.modifyWorkspace);
            resolve();
        });
    }

    public setDuplicateWorkspaceOption(): void {
        this.workspaceCreationService.useSourcePropertiesForDuplicate$.next(this.useSameCoordinateAsSource);
    }
}
