import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { EnvironmentService } from 'src/app/shared/common/environment/environment.service';

import { Export, GeoExportJob, GeoExportJobs } from './export';

@Injectable()
export class ExportService {
    constructor(
        private http: HttpClient,
        private messaging: MessagingService,
        private translate: TranslationService,
        private env: EnvironmentService
    ) {}
    createExport(projectId: string, exportData: Export): Promise<GeoExportJob> {
        return lastValueFrom(
            this.http.post(this.env.apiUrl + '/projects/' + projectId + '/formexports', {
                geoExportBody: exportData.toDTO()
            })
        ) as Promise<GeoExportJob>;
    }

    getExports(projectId: string): Promise<Export[] | void> {
        return lastValueFrom(this.http.get(this.env.apiUrl + '/projects/' + projectId + '/formexports'))
            .then((exportJobs: { data: GeoExportJobs }) =>
                exportJobs.data.items.map(exportJob => Export.fromDTO(exportJob))
            )
            .catch(() => {
                this.messaging.showError(this.translate.instant('TC.Common.ExportFailed'));
            });
    }

    getExport(projectId: string, jobId: string): Promise<{ status: number; downloadLink: string }> {
        return lastValueFrom(
            this.http.get(this.env.apiUrl + '/projects/' + projectId + '/formexports/' + jobId + '/download', {
                observe: 'response'
            })
        ).then(response => ({ status: response.status, downloadLink: response.body as string }));
    }

    getExportStatus(projectId: string, jobId: string): Promise<Export> {
        return lastValueFrom(
            this.http.post(this.env.apiUrl + '/projects/' + projectId + '/formexports/ExportJobCompleted', {
                jobtoken: jobId
            })
        ).then((exportJob: { data: GeoExportJob }) => Export.fromDTO(exportJob.data));
    }

    getValidExports(projectId: string, workspaceId: string): Promise<{ items: GeoExportJob[] }> {
        return lastValueFrom(
            this.http.get(
                this.env.apiUrl +
                    '/projects/' +
                    projectId +
                    '/formexports/_query?filter=' +
                    `$WorkspaceId eq '${workspaceId}' AND ($ExpiryDateUtc gt '${new Date().toISOString()}' OR $ExpiryDateUtc eq null)
                     AND $Status ni ('Error', 'NoData')` +
                    '&sortBy=Id DESC'
            )
        ) as Promise<{ items: GeoExportJob[] }>;
    }
}
