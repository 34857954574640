import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ACCESS_MODE_KEY, AccessMode } from 'src/app/core/authentication/share-token';
import { GspLoggerService } from 'src/app/log-handler.service';

import { EnvironmentService } from '../common/environment/environment.service';
import { GeneralUtils } from '../common/utility/general-utils';
import { UserMapWorkspaceSetting } from './user-map-workspace-settings';
import { UserProjectSettings } from './user-project-settings';
import { GeoUserSettings, GeoUsersSettings, UserSettings } from './user-settings';
import { UserSettingsService } from './user-settings.service';

@Injectable({
    providedIn: 'root'
})
export class UserSettingsStreamService {
    private _currentUserSettings$: BehaviorSubject<UserSettings> = new BehaviorSubject<UserSettings>(null);
    private _currentProjectSettings$: BehaviorSubject<UserProjectSettings> = new BehaviorSubject<UserProjectSettings>(
        null
    );
    private _currentMapWorkspaceSettings$: BehaviorSubject<UserMapWorkspaceSetting> =
        new BehaviorSubject<UserMapWorkspaceSetting>(null);

    public currentUserSettings$ = this._currentUserSettings$.pipe(filter(x => !_.isEqual(x, {})));
    public currentProjectSettings$ = this._currentProjectSettings$.pipe(filter(x => !_.isEqual(x, {})));
    public currentMapWorkspaceSettings$ = this._currentMapWorkspaceSettings$.pipe(filter(x => !_.isEqual(x, {})));

    public lastLoggedInUserSettings$ = new BehaviorSubject<UserSettings>(null);

    constructor(
        private userSettingsService: UserSettingsService,
        private env: EnvironmentService,
        private router: Router,
        private logger: GspLoggerService
    ) {}

    async getUserSettings(userId: string): Promise<UserSettings> {
        const settingName = UserSettings.getSettingsName();
        try {
            if (
                (sessionStorage.getItem(ACCESS_MODE_KEY) && sessionStorage.getItem(ACCESS_MODE_KEY) === AccessMode.SHARED) ||
                GeneralUtils.isNullUndefinedOrNaN(userId)
            ) {
                return null;
            } else if (this._currentUserSettings$.getValue()?.id === userId) {
                    return this._currentUserSettings$.getValue();
            } else {
                const userSettingsDTO = await this.userSettingsService.getUserSettings(settingName);
                let userSettings = UserSettings.fromDTO(userSettingsDTO, this.env.podLocation);
                if (userSettings) {
                    this._currentUserSettings$.next(userSettings);
                    return userSettings;
                } else {
                    const settings = new UserSettings(userId);
                    settings.settingsName = settingName;
                    const createdSetting = await this.userSettingsService.createUserMeSettings(settings.toDTO(this.env.podLocation));
                    this._currentUserSettings$.next(createdSetting);
                    return createdSetting;
                }
            }
        } catch (error) {
            if (error.data?.indexOf('already exists') > -1) {
                const retrievedUserSettingDTO = await this.userSettingsService.getUserSettings(settingName);
                const retrievedUserSettings = UserSettings.fromDTO(
                    retrievedUserSettingDTO,
                    this.env.podLocation
                );
                if (retrievedUserSettings) {
                    this._currentUserSettings$.next(retrievedUserSettings);
                    return retrievedUserSettings;
                }
            } else {
                this.logger.error(error);
                return null;
            }
        }
    }

    getWorkspaceSettings(workspaceId: string): Promise<UserMapWorkspaceSetting> {
        const settingName = UserMapWorkspaceSetting.getSettingsName(workspaceId);
        if (sessionStorage.getItem(ACCESS_MODE_KEY) && sessionStorage.getItem(ACCESS_MODE_KEY) === AccessMode.SHARED) {
            return Promise.resolve(null);
        } else {
            const settingTypeId = UserMapWorkspaceSetting.getSettingsName(workspaceId);

            if (
                this._currentMapWorkspaceSettings$.getValue() &&
                this._currentMapWorkspaceSettings$.getValue().id === settingTypeId
            ) {
                return Promise.resolve(this._currentMapWorkspaceSettings$.getValue());
            }

            return this.userSettingsService.getUserSettings(settingName).then(userSettingsDTO => {
                let userMapWorkspaceSettings = UserMapWorkspaceSetting.fromDTO(userSettingsDTO);
                if (userMapWorkspaceSettings) {
                    this._currentMapWorkspaceSettings$.next(userMapWorkspaceSettings);
                    return Promise.resolve(userMapWorkspaceSettings);
                } else {
                    const settings = new UserMapWorkspaceSetting(workspaceId);
                    settings.isFileViewer = false;

                    if (settingTypeId === this.getCurrentProjectSettings().fileViewerMapWorkspace) {
                        settings.isFileViewer = true;
                    }

                    this.userSettingsService.createUserMapWorkspaceSettings(settings.toDTO()).then(
                        createdSetting => {
                            this._currentMapWorkspaceSettings$.next(createdSetting);
                            return Promise.resolve(createdSetting);
                        },
                        error => {
                            if (error && error.data && error.data.indexOf('already exists') > -1) {
                                this.userSettingsService.getUserSettings(settingName).then(retrievedUserSettingDTO => {
                                    let userMapWorkspaceRetreivedSettings = UserMapWorkspaceSetting.fromDTO(
                                        retrievedUserSettingDTO
                                    );
                                    if (userMapWorkspaceRetreivedSettings) {
                                        this._currentMapWorkspaceSettings$.next(userMapWorkspaceRetreivedSettings);
                                        return Promise.resolve(userMapWorkspaceRetreivedSettings);
                                    }
                                });
                            }
                        }
                    );
                }
            });
        }
    }

    getProjectSettings(projectId: string): Promise<UserProjectSettings> {
        const settingName = UserProjectSettings.getSettingsName(projectId);
        if (sessionStorage.getItem(ACCESS_MODE_KEY) && sessionStorage.getItem(ACCESS_MODE_KEY) === AccessMode.SHARED) {
            return Promise.resolve(null);
        } else {
            if (this._currentProjectSettings$.getValue() && this._currentProjectSettings$.getValue().id === projectId) {
                return Promise.resolve(this._currentProjectSettings$.getValue());
            }

            return this.userSettingsService.getUserSettings(settingName).then(userSettingsDTO => {
                let userProjectSettings = UserProjectSettings.fromDTO(userSettingsDTO);
                if (userProjectSettings) {
                    this._currentProjectSettings$.next(userProjectSettings);
                    return Promise.resolve(userProjectSettings);
                } else {
                    const settings = new UserProjectSettings(projectId);
                    this.userSettingsService.createUserProjectSettings(settings.toDTO()).then(
                        createdSetting => {
                            this._currentProjectSettings$.next(createdSetting);
                            return Promise.resolve(createdSetting);
                        },
                        error => {
                            if (error && error.data && error.data.indexOf('already exists') > -1) {
                                this.userSettingsService.getUserSettings(settingName).then(retrievedUserSettingDTO => {
                                    let userProjectRetrievedSettings = UserProjectSettings.fromDTO(
                                        retrievedUserSettingDTO
                                    );
                                    if (userProjectRetrievedSettings) {
                                        this._currentProjectSettings$.next(userProjectRetrievedSettings);
                                        return Promise.resolve(userProjectRetrievedSettings);
                                    }
                                });
                            }
                        }
                    );
                }
            });
        }
    }

    updateWorkspaceSettings(settings: UserMapWorkspaceSetting): Promise<GeoUserSettings> {
        return this.userSettingsService.updateUserSettings(settings.toDTO());
    }

    updateCurrentWorkspaceSettings(settings: UserMapWorkspaceSetting): Promise<void> {
        return this.userSettingsService
            .updateUserSettings(settings.toDTO())
            .then(() => this._currentMapWorkspaceSettings$.next(settings));
    }

    updateCurrentProjectSettings(settings: UserProjectSettings): Promise<void> {
        return this.userSettingsService
            .updateUserSettings(settings.toDTO())
            .then(() => this._currentProjectSettings$.next(settings));
    }
    updateCurrentUserSettings(settings: UserSettings): Promise<void> {
        return this.userSettingsService
            .updateUserSettings(settings.toDTO(this.env.podLocation))
            .then(() => this._currentUserSettings$.next(settings));
    }

    // Current users function returns empty sometimes which can be avoided
    getCurrentUserSettings(): UserSettings {
        return this._currentUserSettings$.getValue();
    }

    getCurrentProjectSettings(): UserProjectSettings {
        return this._currentProjectSettings$.getValue();
    }

    getCurrentMapWorkspaceSettings(): UserMapWorkspaceSetting {
        return this._currentMapWorkspaceSettings$.getValue();
    }

    getUsersLastProjectRegion(): Promise<any> {
        let promises: Promise<GeoUsersSettings>[] = [];
        _.forEach(this.env.regions, region => {
            promises.push(
                this.userSettingsService.getUserMeSettingsByRegion(region.mapsAPIUri).catch(e => e)
            );
        });
        let lastProjectRegion = null;

        return Promise.all(promises).then(result => {
            // filtering out failed api calls
            result = result.filter(
                value =>
                    value !== null && value.value && value.id && value.value.lastLoggedIn && value.value.lastProjectId
            );
            lastProjectRegion = _.reduce(result, (a, b) =>
                new Date(b.value.lastLoggedIn) > new Date(a.value.lastLoggedIn) ? b : a
            );

            // Found settings with last project id in one of the regions
            if (lastProjectRegion) {
                return lastProjectRegion.value;
            } else {
                // Found settings but no last project id or no settings were found in any of the
                // regions i.e. maps was never accessed in the context of a Connect project.
                // We can't resolve this case. Redirect to no projects error page
                this.router.navigate(['error/404']);
            }
        });
    }
}
