import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BooleanConverter, InputConverter } from 'src/app/shared/common/components/input-converter.decorator';
import { ValueAccessorBase } from 'src/app/shared/common/components/value-accessor-base';

@Component({
    selector: 'field-name',
    templateUrl: './field-name.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FieldNameComponent),
            multi: true
        }
    ]
})
export class FieldNameComponent extends ValueAccessorBase<string> {
    @Input()
    @InputConverter(BooleanConverter)
    isEditable = true;

    @Input()
    name: string;

    @Input()
    extraClass = '';

    @Input()
    locked = false;
}
