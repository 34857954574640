import * as L from 'leaflet';

import { WorkspaceBounds } from '../../map-data-services/mapWorkspace/map-workspace.types';
import { Edge, Polygon, Vector } from './geometry-collision-utils';

export enum GeometryTypes {
    POINT = 'Point',
    LINE = 'Line',
    AREA = 'Area',
    COLLECTION = 'Collection',
    NONE = 'None'
}

export class GeometryUtils {
    // Miscellaneous Geometry utilities

    static getGeometryType(geoJsonType: string): GeometryTypes {
        switch (geoJsonType) {
            case 'Point':

            case 'Curve':
                return GeometryTypes.POINT;

            case 'LineString':
            case 'LinearRing':
            case 'Line':
            case 'MultiLineString':
                return GeometryTypes.LINE;

            case 'Polygon':
            case 'Area':
                return GeometryTypes.AREA;
            case 'GeometryCollection':
            case 'MultiPoint':

            case 'MultiPolygon':
            case 'Collection':
                return GeometryTypes.COLLECTION;
            default:
                return GeometryTypes.NONE;
        }
    }

    static getBounds(geometry: any): L.LatLngBounds {
        if (!geometry) {
            return null;
        }

        const bounds = new L.LatLngBounds(null);

        // feature has leaflet geometry
        switch (geometry.type) {
            case 'Point':
                // geometry.coordinates e.g. [80.2493227, 12.9896203, 0]
                bounds.extend([geometry.coordinates[1], geometry.coordinates[0]]);
                break;

            case 'MultiPoint':
            case 'Curve':
            case 'LineString':
            case 'LinearRing':
                // geometry.coordinates e.g. [[80.249383, 12.9893936, 0], [80.249438, 12.9894145, 0],
                // [80.24950370000002, 12.989447199999999, 0],…]
                geometry.coordinates.forEach((point: any) => {
                    bounds.extend([point[1], point[0]]);
                });
                break;

            case 'MultiLineString':
            case 'Polygon':
                // geometry.coordinates e.g. [[[80.24926700000002, 12.989607200000002, 0], [80.2493434, 12.989591600000002, 0]]]
                geometry.coordinates.forEach((part: any) => {
                    part.forEach((point: any) => {
                        bounds.extend([point[1], point[0]]);
                    });
                });
                break;

            case 'MultiPolygon':
                geometry.coordinates.forEach((item: any) => {
                    item.forEach((part: any) => {
                        part.forEach((point: any) => {
                            bounds.extend([point[1], point[0]]);
                        });
                    });
                });
                break;

            case 'GeometryCollection':
                geometry.geometries.forEach((g: any) => {
                    bounds.extend(GeometryUtils.getBounds(g));
                });
                break;

            default:
                break;
        }

        return bounds;
    }

    static getLatLngs(geometry: any): L.LatLng[] {
        let geoType = geometry.type;
        let coordinates = geometry.coordinates;
        let latLngs: L.LatLng[] = [];
        switch (geoType) {
            case 'Point':
                return [L.latLng(coordinates[1], coordinates[0])];

            case 'MultiPoint':
            case 'Curve':
            case 'LineString':
            case 'LinearRing':
                // wmsCoordinates is an Array[items][latlong]
                latLngs = coordinates.map((item: any) => L.latLng(item[1], item[0]));
                return latLngs;

            case 'MultiLineString':
            case 'Polygon':
                // wmsCoordinates is an Array[items][vertices][latlong]
                coordinates.forEach((item: any) => {
                    let lineLatLngs = item.map((vertex: any) => L.latLng(vertex[1], vertex[0]));
                    latLngs.push(lineLatLngs);
                });
                return latLngs;

            case 'GeometryCollection':
                geometry.geometries.forEach((geo: any) => {
                    latLngs = latLngs.concat(GeometryUtils.getLatLngs(geo));
                });
                break;
        }
    }

    // ------------------

    static polygonBoundsToCollisionPolygon(polygonBounds: any[]): Polygon {
        let vectors: Vector[] = [];
        polygonBounds.forEach(point => {
            vectors.push(new Vector(point.lng, point.lat));
        });
        let collisionPolygon = new Polygon(vectors);
        return collisionPolygon;
    }

    static overlaps(collisionPolygon: Polygon, geometry: any): boolean | void {
        if (!geometry) {
            return false;
        }

        let geoType = geometry.type;

        if (geoType !== 'GeometryCollection' && !geometry.coordinates) {
            return false;
        }
        let coordinates: any = geometry.coordinates;

        switch (geoType) {
            case 'Point':
                return GeometryUtils.overlapsPoint(collisionPolygon, coordinates);

            case 'MultiPoint':
            case 'Curve':
                // coordinates is an Array[items][latlong]
                return coordinates.forEach((item: any) => GeometryUtils.overlapsPoint(collisionPolygon, item));

            case 'LineString':
            case 'LinearRing':
                // coordinates is an Array[vertices][latlong]
                return GeometryUtils.overlapsLineString(collisionPolygon, coordinates);

            case 'MultiLineString':
                // coordinates is an Array[items][vertices][latlong]
                return coordinates.forEach((item: any) => GeometryUtils.overlapsLineString(collisionPolygon, item));

            case 'Polygon':
                // coordinates is an Array[part][vertices][latlong] - first part is outer bounds;
                // other parts are internal holes (NB: currently we show only outer bounds)
                return GeometryUtils.overlapsPolygon(collisionPolygon, coordinates);

            case 'MultiPolygon':
                // coordinates is an Array[items][part][vertices][latlong]
                return coordinates.forEach((item: any) => GeometryUtils.overlapsPolygon(collisionPolygon, item));

            case 'GeometryCollection':
                // geometry collection
                if (!geometry.geometries) {
                    return false;
                }
                return geometry.geometries.forEach((g: any) => GeometryUtils.overlaps(collisionPolygon, g));
        }
    }

    static overlapsPoint(collisionPolygon: Polygon, coordinate: any): boolean {
        return collisionPolygon.contains(new Vector(coordinate[0], coordinate[1]));
    }

    static overlapsLineString(collisionPolygon: Polygon, coordinates: any): boolean {
        // coordinates is an Array[vertices][latlong]
        let vectors: Vector[] = [];
        let contains: boolean = coordinates.some((vertex: number[]) => {
            let vector = new Vector(vertex[0], vertex[1]);
            vectors.push(vector);
            return collisionPolygon.contains(vector);
        });
        if (contains) {
            return true;
        }
        if (coordinates.length > 1) {
            for (let i = 1; i < vectors.length; i++) {
                let edge = new Edge(vectors[i - 1], vectors[i]);
                let intersects = collisionPolygon.edges.some(polygonEdge => edge.intersects(polygonEdge, null, false));
                if (intersects) {
                    return true;
                }
            }
        }
        return false;
    }

    static overlapsPolygon(collisionPolygon: Polygon, coordinates: any): boolean {
        // coordinates is an Array[part][vertices][latlong] - first part is outer bounds;
        // other parts are internal holes (NB: currently we show only outer bounds)
        let vectors: Vector[] = [];
        let contains: boolean = coordinates[0].some((point: number[]) => {
            let vector = new Vector(point[0], point[1]);
            vectors.push(vector);
            return collisionPolygon.contains(vector);
        });
        if (contains) {
            return true;
        }
        let polygon = new Polygon(vectors);
        contains = collisionPolygon.vertices.some((vector: Vector) => polygon.contains(vector));
        if (contains) {
            return true;
        }
        return collisionPolygon.collides(polygon);
    }

    static setWorkspaceBoundsFromMap(bounds: L.LatLngBounds): WorkspaceBounds {
        // Map has max bounds that exceed valid lat long values. This is so we can set the marker in the centre
        // of the map and guarantee accuracy. Check lat long values here and if they exceed the limits, set them
        // to the limit.

        const newBounds = new WorkspaceBounds();
        newBounds.latitudeNorth = bounds.getNorthEast().lat > 90 ? 90 : bounds.getNorthEast().lat;
        newBounds.longitudeEast = bounds.getNorthEast().lng > 180 ? 180 : bounds.getNorthEast().lng;
        newBounds.latitudeSouth = bounds.getSouthWest().lat < -90 ? -90 : bounds.getSouthWest().lat;
        newBounds.longitudeWest = bounds.getSouthWest().lng < -180 ? -180 : bounds.getSouthWest().lng;

        return newBounds;
    }
}
