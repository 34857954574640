import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { WorkspaceService } from 'src/app/feature/workspace/workspace.service';
import { NotificationType } from 'src/app/shared/common/components/gsp-notification/gsp-notification.component';
import { GspWizardService, OnClose } from 'src/app/shared/common/components/gsp-wizard/gsp-wizard.service';
import { MapWorkspace, ShareWorkspaceData } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';

import { GspFileDropComponent } from 'src/app/shared/common/components/gsp-file-drop/gsp-file-drop.component';
import { ImportFile } from '../../import-file';
import { ImportService } from '../../import.service';

@Component({
    selector: 'upload',
    templateUrl: './upload.component.html'
})
export class UploadComponent implements OnInit, OnDestroy, OnClose {
    private importFiles: ImportFile[];
    public NotificationType = NotificationType;
    public showFileLimitWarning = false;
    public createFromFile = false;
    public templateToScan: string | ArrayBuffer = '';
    public scannedFile: ShareWorkspaceData;
    public isValidStructure: boolean;
    public modifyWorkspace: MapWorkspace;

    @ViewChild('fileDrop') fileDrop: GspFileDropComponent;

    @Input()
    public uploadDescription = '';
    public uploadErrorMessage = ''; // Error messages specific to workflow, shown outside modus-dropzone

    private readonly destroyed = new Subject<void>();
    constructor(
        private wizardService: GspWizardService,
        private importService: ImportService,
        private workspaceCreationService: WorkspaceService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.wizardService.setUI({
            backButton: { text: '', enabled: false, visible: false },
            forwardButton: { text: '', enabled: false, visible: false },
            closeButton: { text: '', enabled: true, visible: true },
            cancelButton: { text: '', enabled: false, visible: false }
        });
        this.modifyWorkspace = this.workspaceCreationService.modifyWorkspace$.getValue();
        const lastUrlSegment = this.activatedRoute.snapshot.url?.length - 1;
        if (lastUrlSegment === 1) {
            this.createFromFile = this.activatedRoute.snapshot.url[lastUrlSegment].path.includes('fromFile');
        }
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    public dropped(droppedFiles: File[]): void {
        this.importFiles = [];

        Array.from(droppedFiles).forEach(droppedFile => {
            this.importFiles.push(new ImportFile(droppedFile.webkitRelativePath, droppedFile.name, droppedFile));
        });

        if (this.createFromFile) {
            this.createFromTemplateWorkflow();
        } else {
            this.layersImportWorkflow();
        }

        this.fileDrop.resetDropzone();
    }

    public onClose(): void {
        this.router.navigate(['mapViewer', { outlets: { centerDialog: null } }], {
            queryParamsHandling: 'preserve'
        });
    }

    public layersImportWorkflow(): void {
        if (this.importFiles.length > 20) {
            this.showFileLimitWarning = true;
        } else {
            this.moveOnNextStage();
        }
    }

    public createFromTemplateWorkflow(): void {
        const fileSelected = this.importFiles[0];

        if (this.isValidFileFormat(fileSelected.fileName)) {
            this.scanFile(fileSelected.file).then(valid => {
                valid ? this.moveOnNextStage() : this.displayErrorMessage('MapViewer.ImportStatusDialog.InvalidFile');
            });
        } else {
            this.displayErrorMessage('MapViewer.ImportStatusDialog.InvalidFileFormat');
        }
    }

    private getExtension(fileName: string): string {
        const parts = fileName.split('.');
        return parts[parts.length - 1];
    }

    private isValidFileFormat(fileName: string): boolean {
        const ext = this.getExtension(fileName);
        switch (ext.toLowerCase()) {
            // add more format. if there's any
            case 'wsdef':
                return true;
        }
        return false;
    }

    private moveOnNextStage(): void {
        if (this.createFromFile && this.scannedFile) {
            this.workspaceCreationService.uploadWorkspaceTemplate$.next(this.scannedFile);

            const updatedWorkspace = new MapWorkspace({
                ...this.modifyWorkspace,
                ...this.scannedFile.workspace
            });
            this.workspaceCreationService.modifyWorkspace$.next(updatedWorkspace);
        } else {
            this.importService.importStream.next(this.importFiles);
        }

        this.wizardService.moveForward();
    }

    public displayErrorMessage(message: string): void {
        this.uploadErrorMessage = message;
    }

    private scanFile(file: File): Promise<boolean> {
        return new Promise(resolve => {
            const scanner = new FileReader();
            scanner.readAsText(file, 'utf-8');

            scanner.onload = event => {
                try {
                    const requiredProperties = ['workspace', 'content'];

                    this.scannedFile = JSON.parse(event.target.result as string);
                    this.isValidStructure = requiredProperties.every(key =>
                        Object.keys(this.scannedFile).includes(key)
                    );

                    resolve(this.isValidStructure);
                } catch (error) {
                    resolve(false);
                }
            };

            scanner.onerror = () => {
                resolve(false);
            };
        });
    }
}
