import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MenuService } from 'src/app/shared/common/layout/menu.service';

import { MapMenuCode } from './map-menu-list';

@Component({
    templateUrl: './menu.component.html',
    selector: 'map-menu'
})
export class MenuComponent implements OnInit, OnDestroy {
    private destroyed = new Subject<void>();
    activeMenuCodes: string[];

    // expose enum to public
    public MapMenuCode = MapMenuCode;

    constructor(private menuService: MenuService) {}

    ngOnInit(): void {
        this.menuService.activeMenusStream
            .pipe(takeUntil(this.destroyed))
            .subscribe(activeMenuCodes => (this.activeMenuCodes = activeMenuCodes));
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }
}
