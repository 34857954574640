<gsp-popup
    class="sensor-popup"
    [heading]="'TCS.Sensor' | translate"
    [size]="ModalSize.Fluid"
    [showClose]="!loading"
    [showDone]="!loading"
    [showCancel]="!loading"
    [disableDone]="!selectedMeasurementWorkflow"
    [customIcon]="'icon_line_sensor'"
    [doneBtnTitle]="'TC.Common.Next' | translate"
    id="sensor-popup"
    (closed)="closePopup()"
    (done)="onDone()"
>
    <!-- TODO: (done) must have a new function that populates new fields in template editor -> on TCMAPS-3745  -->
    <ng-container transclude-popup-content>
        <loading *ngIf="loading; else sensorPopupContent"></loading>
        <ng-template #sensorPopupContent>
            <label class="sensor-label" for="sensor">
                {{ 'TCS.Sensor' | translate }}
            </label>
            <gsp-dropdown
                name="sensor"
                [options]="sensorOptions"
                textField="displayName"
                [(ngModel)]="selectedSensor"
                (ngModelChange)="triggerOnSensorSelect(selectedSensor)"
                id="sensor"
            ></gsp-dropdown>

            <label class="mw-label" for="measurementWorkflow">
                {{ 'TCS.Sensor.MeasurementWorkflow' | translate }}
            </label>
            <gsp-dropdown
                name="measurementWorkflow"
                [options]="workflowOptions"
                textField="displayName"
                [(ngModel)]="selectedMeasurementWorkflow"
                [ngDisabled]="!selectedSensor"
                id="measurementWorkflow"
            ></gsp-dropdown>
        </ng-template>
    </ng-container>
</gsp-popup>
