<gsp-modal
    class="rtc-configuration__internet"
    [size]="ModalSize.Xlarge"
    [heading]="'MapViewer.RTC.InternetDialog.Header'"
    (closed)="cancel()"
    id="rtc-internet-configuration"
>
    <div transclude-popup-content class="flex-row-new">
        <section class="rtc-edit-form flex5-new pt-0 pr-0">
            <custom-scroll>
                <div class="rtc-edit-form-wrapper">
                    <ng-form #rtcEditForm="ngForm">
                        <gsp-notification [type]="NotificationType.Info" class="mb-1">
                            <div>{{ 'MapViewer_RTC_Internet_NonEditable_Configuration' | translate }}</div>
                        </gsp-notification>

                        <div class="rtc-edit-form-group">
                            <h5>{{ 'TCS.Mapviewer.Rtc.Labels.InternetSource' | translate | capitalize }}</h5>
                            <gsp-help-text [text]="'MapViewer_RTC_InternetSource_HelpText' | translate"></gsp-help-text>
                            <gsp-dropdown
                                name="server"
                                [options]="internetServerTypes"
                                [(ngModel)]="editingRtc.internetServerType"
                                textField="name"
                                id="rtc-internet-server-type"
                                [ngDisabled]="originalRtc.isExistingRtc()"
                            ></gsp-dropdown>
                        </div>

                        <div class="rtc-edit-form-group">
                            <h5 [hidden]="editingRtc.internetServerType !== InternetServerTypes.NTRIP">
                                {{ 'MapViewer_RTC_Ntrip_UrlAndPort_Title' | translate }}
                            </h5>
                            <h5 [hidden]="editingRtc.internetServerType !== InternetServerTypes.DIRECT">
                                {{ 'MapViewer_RTC_UrlAndPort_Title' | translate }}
                            </h5>
                            <gsp-help-text [text]="'MapViewer_RTC_NTRIP_Port_Url_HelpText' | translate"></gsp-help-text>
                            <div class="flex-row-new no-gutters">
                                <div class="flex9-new py-0 pl-0">
                                    <gsp-text
                                        #url="ngModel"
                                        [type]="GspTextType.URL"
                                        id="rtc-internet-url-input"
                                        [inputNgClass]="{ 'show-inactive': originalRtc.isExistingRtc() }"
                                        name="url"
                                        placeholder="{{ 'TC.Common.URL' | translate }}"
                                        [(ngModel)]="editingRtc.internetUrl"
                                        required="true"
                                        [readonly]="originalRtc.isExistingRtc()"
                                        [errorTooltipMsg]="getErrorText(url)"
                                    ></gsp-text>
                                </div>
                                <div class="flex3-new pa-0">
                                    <gsp-number
                                        #port="ngModel"
                                        [type]="GspNumberType.PORT"
                                        id="rtc-internet-port-input"
                                        inputClass="static"
                                        [inputNgClass]="{ 'show-inactive': originalRtc.isExistingRtc() }"
                                        name="port"
                                        placeholder="{{ 'TC.Common.Port' | translate }}"
                                        [(ngModel)]="editingRtc.internetPort"
                                        (valueChange)="resetNtripSource()"
                                        [readonly]="originalRtc.isExistingRtc()"
                                        [errorTooltipMsg]="getErrorText(port)"
                                    ></gsp-number>
                                </div>
                            </div>

                            <gsp-notification *ngIf="ntripError" [type]="NotificationType.Error" class="mt-1">
                                <div>{{ ntripError }}</div>
                            </gsp-notification>
                            <gsp-button
                                [ngClass]="{ 'show-inactive': originalRtc.isExistingRtc() }"
                                *ngIf="!this.editingRtc.isLegacyRtc()"
                                [type]="ButtonType.Default"
                                (action)="loadNtrip()"
                                [hidden]="editingRtc.internetServerType !== InternetServerTypes.NTRIP"
                                [disabled]="!url.valid || ntripIsLoading || originalRtc.isExistingRtc()"
                                text="{{ 'TCS.Mapviewer.Rtc.Messages.NtripLoadPlaceholder' | translate }}"
                                id="rtc-load-ntrip"
                            >
                            </gsp-button>
                        </div>

                        <div
                            class="rtc-edit-form-group"
                            [hidden]="editingRtc.internetServerType !== InternetServerTypes.DIRECT"
                        >
                            <h5 translate="TCS.Mapviewer.Rtc.Labels.BaseType"></h5>
                            <gsp-dropdown
                                name="baseType"
                                [options]="baseTypes"
                                [(ngModel)]="editingRtc.baseType"
                                textField="name"
                                id="rtc-internet-base-type"
                                [ngDisabled]="originalRtc.isExistingRtc()"
                            >
                            </gsp-dropdown>
                        </div>

                        <div
                            class="rtc-edit-form-group"
                            [hidden]="editingRtc.internetServerType !== InternetServerTypes.NTRIP"
                        >
                            <h5>{{ 'TCS.Mapviewer.Rtc.Messages.NtripSource' | translate }}</h5>
                            <gsp-help-text [text]="'MapViewer_RTC_NtripSource_HelpText' | translate"></gsp-help-text>
                            <p class="hint-text" [hidden]="editingRtc.ntripSettingsString">
                                {{ 'MapViewer_RTC_NTRIP_Source_HintText' | translate }}
                            </p>

                            <div class="selected-ntrip" *ngIf="editingRtc.ntripSettingsString">
                                <h5 class="font-title">
                                    {{ editingRtc.ntrip.ntripId }}
                                    <span>
                                        <i
                                            class="i16 auth-icon"
                                            [ngClass]="{
                                                'icon-lock-outline':
                                                    editingRtc.ntrip.authentication !==
                                                    NtripAuthentication.No_AUTHENTICATION,
                                                'icon-lock-open':
                                                    editingRtc.ntrip.authentication ===
                                                    NtripAuthentication.No_AUTHENTICATION
                                            }"
                                        ></i
                                    ></span>
                                </h5>
                                <div class="flex-row-new no-gutters">
                                    <div class="flex5-new">
                                        <div
                                            class="font-title"
                                            translate="TC.Common.Format"
                                            title="{{ 'TC.Common.Format' | translate }}"
                                        ></div>
                                        <div>{{ editingRtc.ntrip.format }}</div>
                                    </div>
                                    <div class="flex5-new">
                                        <div
                                            class="font-title"
                                            translate="TC.Common.System"
                                            title="{{ 'TC.Common.System' | translate }}"
                                        ></div>
                                        <div>{{ editingRtc.ntrip.system }}</div>
                                    </div>

                                    <gsp-button
                                        class="flex2-new"
                                        [type]="ButtonType.Icon"
                                        [iconClass]="'i24 icon_line_cancel'"
                                        (action)="resetNtripSource()"
                                        *ngIf="!originalRtc.isExistingRtc()"
                                        id="reset-ntrip-source"
                                    ></gsp-button>
                                </div>
                            </div>
                            <div
                                [hidden]="editingRtc.internetServerType !== InternetServerTypes.NTRIP || !url.valid"
                            ></div>
                        </div>

                        <div
                            class="rtc-edit-form-group"
                            *ngIf="ntripRequiresAuthentication"
                            [hidden]="editingRtc.internetServerType !== InternetServerTypes.NTRIP"
                        >
                            <h5 translate="TCS.Mapviewer.Rtc.Messages.NtripReqAuth"></h5>

                            <gsp-help-text
                                [text]="'MapViewer.Export.HelpText.RequireAuthentication' | translate"
                            ></gsp-help-text>
                            <gsp-notification
                                *ngIf="
                                    !editingRtc.authByFieldUser &&
                                    !(editingRtc.internetUsername && editingRtc.internetPassword)
                                "
                                [type]="NotificationType.Warning"
                                class="mt-1 ntrip-auth-required-prompt"
                            >
                                <div>{{ 'MapViewer.RTC.Internet.Ntrip.Auth.Prompt.Message' | translate }}</div>
                            </gsp-notification>
                            <div class="flex12-new">
                                <gsp-checkbox
                                    [(value)]="editingRtc.authByFieldUser"
                                    [id]="'authByFieldUser'"
                                    [label]="'MapViewer_RTC_Authentication_Details' | translate"
                                    (valueChange)="setAuthByFieldUser($event)"
                                ></gsp-checkbox>
                            </div>
                            <div class="flex12-new px-0 py-0">
                                <gsp-text
                                    *ngIf="!editingRtc.authByFieldUser"
                                    name="username"
                                    #username="ngModel"
                                    placeholder="{{ 'TC.Common.Username' | translate }}"
                                    [(ngModel)]="editingRtc.internetUsername"
                                    [id]="'rtc-internet-username'"
                                    required
                                    [errorTooltipMsg]="getErrorText(username)"
                                    focusElement="true"
                                ></gsp-text>
                            </div>
                            <div class="flex12-new px-0 py-0">
                                <gsp-password
                                    *ngIf="!editingRtc.authByFieldUser"
                                    name="password"
                                    #password="ngModel"
                                    placeholder="{{ 'password' | translate }}"
                                    [(ngModel)]="editingRtc.internetPassword"
                                    [id]="'rtc-internet-password'"
                                    required
                                    [errorTooltipMsg]="getErrorText(password)"
                                ></gsp-password>
                            </div>
                        </div>

                        <div class="rtc-edit-form-group">
                            <h5>{{ 'TCS.Mapviewer.Rtc.Messages.Datum' | translate }}</h5>
                            <loading *ngIf="datumIsLoading"></loading>
                            <gsp-button
                                *ngIf="!originalRtc.isExistingRtc() && !editingRtc.datumComponentId && !datumIsLoading"
                                [type]="ButtonType.Default"
                                (action)="loadDatums()"
                                text="{{ 'MapViewer.RTC.Select.Datum' | translate }}"
                                id="rtc-load-datums"
                            >
                            </gsp-button>

                            <ng-container *ngIf="!datumIsLoading && !showDatums">
                                <!-- Legacy RTC with datum configured -->
                                <div
                                    class="selected-datum"
                                    *ngIf="originalRtc.isExistingRtc() && originalRtc.isLegacyRtc() && rtcDatum"
                                >
                                    <div class="flex-row-new no-gutters">
                                        <h5 class="font-title flex5-new">
                                            {{ rtcDatum?.datumName }}
                                        </h5>
                                        <div class="flex5-new">
                                            <div
                                                translate="TCS.Mapviewer.Rtc.Messages.Datum"
                                                title="{{ 'TCS.Mapviewer.Rtc.Messages.Datum' | translate }}"
                                                class="font-title"
                                            ></div>
                                            <div>{{ rtcDatum?.datumType }}</div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Saved new RTC -->
                                <div
                                    class="selected-datum"
                                    *ngIf="originalRtc.isExistingRtc() && originalRtc.isPostCsSupportRtc()"
                                >
                                    <div class="flex-row-new no-gutters">
                                        <h5 class="font-title flex5-new">
                                            {{ getDatum(originalRtc.datumComponentId).displayName }}
                                        </h5>
                                    </div>
                                </div>

                                <!-- new RTC  -->
                                <div
                                    class="selected-datum"
                                    *ngIf="!originalRtc.isExistingRtc() && editingRtc.isPostCsSupportRtc()"
                                >
                                    <div class="flex-row-new no-gutters">
                                        <h5 class="font-title flex10-new">
                                            {{ getDatum(this.editingRtc.datumComponentId).displayName }}
                                        </h5>

                                        <gsp-button
                                            *ngIf="!datumSetByNtrip"
                                            class="flex2-new"
                                            [type]="ButtonType.Icon"
                                            [iconClass]="'i24 icon_line_cancel'"
                                            (action)="resetDatum()"
                                            id="reset-datum"
                                        ></gsp-button>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="datumError">
                                <gsp-notification [type]="NotificationType.Error" class="mt-1 mb-1">
                                    <div>
                                        {{ 'MapViewer.RTC.DatumsLoadingError' | translate }}
                                        {{ 'MapViewer.Workspace.CoordinateSystem.Outage' | translate }}
                                    </div>
                                </gsp-notification>
                            </ng-container>
                        </div>
                    </ng-form>
                </div>
            </custom-scroll>
        </section>

        <section class="rtc-data-list rtc-data-list--empty flex7-new pt-0" [hidden]="showNtrip || showDatums">
            <loading [hidden]="!ntripIsLoading"></loading>
            <div class="positioning-placeholder-image" [hidden]="ntripIsLoading">
                <img src="assets/images/SVGs-24scale/icon_line_positioning_24-48-96.svg" alt="" />
            </div>
        </section>

        <section class="rtc-data-list flex7-new pt-0" [hidden]="!showNtrip">
            <div>
                <h5 class="font-title">{{ 'MapViewer_RTC_Select_NTRIPSource' | translate }}</h5>
                <rtc-search
                    [items]="ntripList"
                    [keyToFilterBy]="'ntripId'"
                    [label]="'MapViewer_RTC_Select_Source_OrTap'"
                    [clearSearch$]="clearSearch.asObservable()"
                    [searchId]="'ntrip-search'"
                    (filteredItems)="updateFilteredItems($event)"
                >
                </rtc-search>
            </div>

            <div class="rtc-data-list-items">
                <custom-scroll>
                    <div class="ntrip-list">
                        <ul
                            class="ntrip-list-items"
                            *ngFor="let item of filteredNtripList"
                            [ngClass]="{ 'selected-active': editingRtc.ntrip?.ntripId === item.ntripId }"
                            (click)="selectNtrip(item)"
                        >
                            <li class="flex-row-new no-gutters ntrip-item-line">
                                <div class="flex5-new title" title="{{ item.ntripId }}">{{ item.ntripId }}</div>

                                <div class="flex3-new format">
                                    <div
                                        class="font-title"
                                        translate="TC.Common.Format"
                                        title="{{ 'TC.Common.Format' | translate }}"
                                    ></div>
                                    <div title="{{ item.format }}">{{ item.format }}</div>
                                </div>

                                <div class="flex3-new system">
                                    <div
                                        class="font-title"
                                        translate="TC.Common.System"
                                        title="{{ 'TC.Common.System' | translate }}"
                                    ></div>
                                    <div title="{{ item.system }}">{{ item.system }}</div>
                                </div>
                                <div class="flex1-new auth">
                                    <i
                                        class="i16 auth-icon"
                                        [ngClass]="{
                                            'icon-lock-outline':
                                                item.authentication !== NtripAuthentication.No_AUTHENTICATION,
                                            'icon-lock-open':
                                                item.authentication === NtripAuthentication.No_AUTHENTICATION
                                        }"
                                    ></i>
                                </div>
                            </li>
                        </ul>
                    </div>
                </custom-scroll>
            </div>
        </section>

        <section class="rtc-data-list flex7-new pt-0" [hidden]="!showDatums">
            <div>
                <h5 class="font-title">{{ 'MapViewer.RTC.Select.Datum' | translate }}</h5>
                <rtc-search
                    [items]="datums"
                    [keyToFilterBy]="'displayName'"
                    [label]="'MapViewer.RTC.Select.Datum.OrTap'"
                    [clearSearch$]="clearSearch.asObservable()"
                    [searchId]="'datum-search'"
                    (filteredItems)="updateFilteredItems($event)"
                >
                </rtc-search>
            </div>

            <div class="rtc-data-list-items">
                <custom-scroll>
                    <div class="ntrip-list">
                        <ul
                            class="ntrip-list-items"
                            *ngFor="let item of filteredDatums"
                            [ngClass]="{ 'selected-active': editingRtc.datumComponentId === item.componentID }"
                            (click)="selectDatum(item)"
                        >
                            <li class="flex-row-new no-gutters datum-item-line">
                                <div>{{ item.displayName }}</div>
                            </li>
                        </ul>
                    </div>
                </custom-scroll>
            </div>
        </section>
    </div>
    <ng-container transclude-popup-footer>
        <div class="modal-footer">
            <gsp-button
                [type]="ButtonType.Primary"
                (action)="save()"
                [disabled]="!canSave()"
                text="{{ 'TC.Common.Save' | translate }}"
                id="rtc-internet-save"
            >
            </gsp-button>
            <gsp-button
                [type]="ButtonType.Default"
                (action)="cancel()"
                text="{{ 'TC.Common.Cancel' | translate }}"
                id="rtc-internet-cancel"
            ></gsp-button>
        </div>
    </ng-container>
</gsp-modal>
