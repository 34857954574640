import { Directive, HostListener } from '@angular/core';

const TAB_CODE = 'Tab';
@Directive({ selector: '[prevent-tab-event]' })
export class PreventTabEventDirective {
    @HostListener('keydown', ['$event'])
    onKeyDownEvent($event: KeyboardEvent): void {
        if ($event.code === TAB_CODE) {
            $event.preventDefault();
            $event.stopPropagation();
        }
    }
}
