<ul class="file-status">
    <li *ngFor="let file of importFiles">
        <div *ngIf="!file.messages?.length" class="import-item-container">
            <ng-container *ngTemplateOutlet="fileWithStatus; context: { file: file }"></ng-container>
        </div>
        <div *ngIf="file.messages?.length" class="import-accordion-container">
            <ng-container *ngTemplateOutlet="messageAccordion; context: { file: file }"> </ng-container>
        </div>
    </li>
</ul>

<ng-template #messageAccordion let-file="file">
    <mat-accordion>
        <mat-expansion-panel #messagePanel>
            <mat-expansion-panel-header
                [collapsedHeight]="'32px'"
                [expandedHeight]="'32px'"
                class="import-message-panel-header"
            >
                <ng-container *ngTemplateOutlet="fileWithStatus; context: { file: file }"></ng-container>
            </mat-expansion-panel-header>
            <div *ngIf="file.messages?.length" class="import-error-message">
                <gsp-notification
                    [type]="file.status === ImportStatus.ERROR ? NotificationType.Error : NotificationType.Warning"
                    ><p *ngFor="let message of file.messages">{{ message }}</p>
                    <div class="import-error-request-id" *ngIf="file.requestId">
                        <div translate [translateParams]="{ fileRequestId: file.requestId }">
                            MapViewer.ImportStatusDialog.FileErrorWithRequestId
                        </div>

                        <gsp-button
                            (action)="copy(file.requestId)"
                            [type]="ButtonType.Icon"
                            iconClass="i24 icon_content_copy"
                            [buttonTooltip]="'MapViewer.Generic.CopyToClipboard' | translate"
                            id="file-error-status-copy"
                        ></gsp-button>
                    </div>
                </gsp-notification>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</ng-template>

<ng-template #fileWithStatus let-file="file">
    <div class="import-file-info">
        <h5 class="name" [title]="file.fileName">{{ file.fileName }}</h5>
        <div class="status">
            <ng-container [ngSwitch]="file.status">
                <ng-container *ngSwitchCase="ImportStatus.UPLOADING">
                    <i class="icon icon-24-uploading"></i>
                    <h6>{{ 'TC.Common.Uploading.Ellipsis' | translate }}</h6>
                </ng-container>
                <ng-container *ngSwitchCase="ImportStatus.WARNING">
                    <i class="icon icon-24-warning"></i>
                    <h6>{{ 'TC.Common.Uploading.Ellipsis' | translate }}</h6>
                </ng-container>
                <ng-container *ngSwitchCase="ImportStatus.PROCESSING">
                    <i class="icon {{ !file.messages?.length ? 'icon-24-progress' : 'icon-24-warning' }}"></i>
                    <h6>{{ 'TC.Status.Display.Processing' | translate }}</h6>
                </ng-container>
                <ng-container *ngSwitchCase="ImportStatus.SUCCESS">
                    <i class="icon {{ !file.messages?.length ? 'icon-24-success' : 'icon-24-warning' }}"></i>
                    <h6>{{ 'MapViewer.ImportStatusDialog.ProcessedMessage' | translate }}</h6>
                </ng-container>
                <ng-container *ngSwitchCase="ImportStatus.ERROR">
                    <i class="icon icon-24-error"></i>
                    <h6>{{ 'MapViewer.ImportStatusDialog.ProcessErrorMessage' | translate }}</h6>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <ng-container [ngSwitch]="file.status">
        <mat-progress-bar
            *ngSwitchCase="ImportStatus.UPLOADING"
            class="progress-success"
            [mode]="'determinate'"
            [value]="file.uploadProgress"
        >
        </mat-progress-bar>
        <mat-progress-bar
            *ngSwitchCase="ImportStatus.WARNING"
            class="progress-success"
            [mode]="'determinate'"
            [value]="100"
        >
        </mat-progress-bar>
        <mat-progress-bar *ngSwitchCase="ImportStatus.PROCESSING" class="progress-success" [mode]="'indeterminate'">
        </mat-progress-bar>
        <mat-progress-bar
            *ngSwitchCase="ImportStatus.SUCCESS"
            class="progress-success"
            [mode]="'determinate'"
            [value]="100"
        >
        </mat-progress-bar>
        <mat-progress-bar
            *ngSwitchCase="ImportStatus.ERROR"
            class="progress-error"
            [mode]="'determinate'"
            [value]="100"
        ></mat-progress-bar>
    </ng-container>
</ng-template>
