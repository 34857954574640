import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { ACCESS_MODE_KEY, AccessMode, ShareToken } from 'src/app/core/authentication/share-token';
import { ProjectService } from 'src/app/shared/map-data-services/project/project.service';
import { UserSettingsStreamService } from 'src/app/shared/user/user-settings-stream.service';
import regions from 'src/regions/regions.json';

import { Project } from '../../map-data-services/project/project';
import { UserSettings } from '../../user/user-settings';
import { CurrentUserStreamService } from '../current-user/current-user-stream.service';
import { EnvironmentService } from '../environment/environment.service';
import { CloneUtils } from '../utility/clone-utils';
import { DateUtils } from '../utility/date-utils';

export interface IRegionInfo {
    region: string;
    projectId: string;
}

export const SESSION_STORAGE_REGION_PROJECT_KEY = 'region-project';

@Injectable({
    providedIn: 'root'
})
export class CurrentRegionStreamService {
    constructor(
        private env: EnvironmentService,
        private projectService: ProjectService,
        private userSettingsStream: UserSettingsStreamService,
        private authenticationService: AuthenticationService,
        private currentUserStream: CurrentUserStreamService
    ) {}

    public getRegionInfo(): IRegionInfo {
        return JSON.parse(sessionStorage.getItem(SESSION_STORAGE_REGION_PROJECT_KEY)) as IRegionInfo;
    }

    public initCurrentRegion(projectId: string, region: string): Promise<boolean> {
        let currentProjectId = projectId;
        return new Promise((resolve, reject) => {
            if (!currentProjectId) {
                // loading last project in case of no projectId
                this.userSettingsStream.getUsersLastProjectRegion().then((lastProjectInfo: any) => {
                    if (lastProjectInfo.lastProjectId) {
                        const lastRegion = region || lastProjectInfo.lastProjectRegion;
                        if (lastRegion) {
                            this.setRegion(lastRegion);
                            this.storeRegionInfo(lastRegion, lastProjectInfo.lastProjectId).then(() => resolve(true));
                        } else {
                            // handle if there is no lastProjectRegion in the user-me settings for backwards compatibility
                            this.findAndSetCurrentRegionByProjectId(lastProjectInfo.lastProjectId)
                                .then((currentRegion: string) => {
                                    if (currentRegion) {
                                        this.setRegion(currentRegion);
                                        this.storeRegionInfo(currentRegion, currentProjectId).then(() => resolve(true));
                                    } else {
                                        reject(false);
                                    }
                                })
                                .catch(() => {
                                    reject(false);
                                });
                        }
                    } else {
                        reject(false);
                    }
                });
            } else {
                const storedRegion = this.getStoredRegionInfo(currentProjectId);
                if (storedRegion) {
                    // In case of already existing project region mapping in session storage
                    this.setRegion(storedRegion);
                    this.storeRegionInfo(storedRegion, currentProjectId).then(() => resolve(true));
                } else if (region) {
                    // set the region if region parameter is valid
                    const isValidRegion = _.find(this.env.regions, regionConfig => regionConfig.locationId === region);
                    if (isValidRegion) {
                        this.setRegion(region);
                        this.storeRegionInfo(region, currentProjectId).then(() => resolve(true));
                    } else {
                        reject(false);
                    }
                } else {
                    // find the region by using projectId in case of no region
                    this.findAndSetCurrentRegionByProjectId(currentProjectId)
                        .then((currentRegion: string) => {
                            if (currentRegion) {
                                this.setRegion(currentRegion);
                                this.storeRegionInfo(currentRegion, currentProjectId).then(() => resolve(true));
                            } else {
                                reject(false);
                            }
                        })
                        .catch(() => {
                            reject(false);
                        });
                }
            }
        });
    }

    public findAndSetCurrentRegionByProjectId(projectId: string): Promise<string> {
        const storedRegion = this.getStoredRegionInfo(projectId);
        if (storedRegion) {
            return Promise.resolve(storedRegion);
        }

        // TODO: Is this necessary?
        if (sessionStorage.getItem(ACCESS_MODE_KEY) && sessionStorage.getItem(ACCESS_MODE_KEY) === AccessMode.SHARED) {
            const region = sessionStorage.getItem('accessRegion') || this.env.region;
            return Promise.resolve(region);
        } else {
            return new Promise((resolve, reject) => {
                let promises: Promise<Project>[] = [];
                _.forEach(regions, b => {
                    promises.push(this.projectService.FindProjectRegionById(b.connectAPIUri, projectId).catch(e => e));
                });

                Promise.all(promises).then(result => {
                    let project = result.filter(item => item.id && item.id === projectId);
                    if (project && project.length) {
                        resolve(project[0].location);
                    }

                    if (!project && result.length === regions.length) {
                        reject(null);
                    }
                });
            });
        }
    }

    public async setCurrentRegionConfig(): Promise<any> {
        const regionConfigByLocation: { [key: string]: any } = {};
        regions.forEach(region => {
            regionConfigByLocation[region.locationId] = region;
            regionConfigByLocation[region.locationId].connectDomain = this.env.getConnectDomainFromUrl(
                region.connectAPIUri
            );
            regionConfigByLocation[region.locationId].mapsDomain = this.env.getMapsDomainFromUrl(region.mapsAPIUri);
        });
        this.env.regions = regionConfigByLocation;
    }

    public async initCurrentRegionSharedPublicMode(shareToken: string, region: string): Promise<ShareToken> {
        this.setRegion(region);
        try {
            const response = await this.authenticationService.getTCAccessTokenUsingSToken(
                this.env.connectApiUrl,
                shareToken
            );
            return response;
        } catch (e) {
            return null;
        }
    }

    private async storeRegionInfo(region: string, projectId: string): Promise<any> {
        const regionInfo: IRegionInfo = { region, projectId };
        sessionStorage.setItem(SESSION_STORAGE_REGION_PROJECT_KEY, JSON.stringify(regionInfo));
        const currentPodProjectIdSettingName = UserSettings.getCurrentPodProjectIdSettingName(this.env.podLocation);

        if (!this.currentUserStream.currentUser) {
            await this.currentUserStream.initCurrentUser();
        }

        const currentUserSettings = await this.userSettingsStream.getUserSettings(
            this.currentUserStream.currentUser?.id
        );
        if (currentUserSettings) {
            this.userSettingsStream.lastLoggedInUserSettings$.next(CloneUtils.cloneDeep(currentUserSettings));
            currentUserSettings.currentProjectPodSpecific[currentPodProjectIdSettingName] = projectId;
            currentUserSettings.currentProjectId = projectId;
            currentUserSettings.lastProjectId = projectId;
            currentUserSettings.lastLoggedIn = DateUtils.utcDateFormat(new Date());
            return Promise.all([
                this.userSettingsStream.updateCurrentUserSettings(currentUserSettings),
                this.userSettingsStream.getProjectSettings(projectId)
            ]);
        }

        return Promise.resolve(null);
    }

    private getStoredRegionInfo(projectId: string): string {
        const regionInfo = sessionStorage.getItem(SESSION_STORAGE_REGION_PROJECT_KEY);
        if (regionInfo) {
            const storedProjectId = JSON.parse(regionInfo).projectId as string;
            if (storedProjectId === projectId) {
                return JSON.parse(regionInfo).region as string;
            } else {
                this.clearRegionInfo();
                return null;
            }
        }
        return null;
    }

    private clearRegionInfo(): void {
        sessionStorage.removeItem(SESSION_STORAGE_REGION_PROJECT_KEY);
    }

    private setRegion(region: string): void {
        this.env.region = region;
    }
}
