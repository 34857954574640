import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ModalSize } from '../../modal-sizes';
import { FileUtils } from '../../utility/file-utils';
import { NotificationType } from '../gsp-notification/gsp-notification.component';
import { GspImageCropperService } from './gsp-image-cropper.service';

@Component({
    selector: 'gsp-image-cropper',
    templateUrl: './gsp-image-cropper.component.html'
})
export class GspImageCropperComponent implements OnInit, AfterViewInit {
    public ModalSize = ModalSize;
    public NotificationType = NotificationType;

    imageChangedEvent: Event = null;
    croppedImageUrl = '';
    defaultWidth = 265;
    defaultHeight = 125;
    invalidImageSize = false;
    noErrorsFound = true;
    aspectRatio = this.defaultWidth / this.defaultHeight;

    @Output() croppedImageEvent = new EventEmitter<any>();

    @ViewChild('fileInput') fileInput: ElementRef;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private imageCropperService: GspImageCropperService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        if (!this.route?.snapshot.queryParams) {
            this.closeCropper();
        }
    }

    ngAfterViewInit(): void {
        this.fileInput.nativeElement.click();
    }

    public fileChangeEvent(event: Event): void {
        this.imageChangedEvent = event;
        this.noErrorsFound = true;

        // validate image size
        const file = (event.target as HTMLInputElement).files[0];

        // check if file is a valid image format
        if (FileUtils.isSupportedImageType(file.type) === false) {
            this.loadImageFailed();
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const img = new Image();
            img.src = reader.result as string;
            img.onload = () => {
                if (img.width < this.defaultWidth || img.height < this.defaultHeight) {
                    this.loadImageFailed(true);
                }
            };
        };
    }

    public imageCropped(imageCroppedEvent: ImageCroppedEvent): ImageCroppedEvent {
        this.clearImage();
        this.imageCropperService.imageCroppedEvent$.next(imageCroppedEvent);
        return !imageCroppedEvent ? null : imageCroppedEvent;
    }

    public loadImageFailed(invalidSize: boolean = false): void {
        this.clearImage();
        this.noErrorsFound = false;
        this.invalidImageSize = invalidSize;
    }

    public selectCroppedImage(): void {
        this.redirectToPreviousModal();
    }

    // modal closure
    public closeCropper(): void {
        this.clearImage();

        this.redirectToPreviousModal();

        // TODO: need proper redirect when wizard flow is implemented
    }

    public translatedInvalidImageSizeMessage(): string {
        return this.translate.instant('MapViewer.Workspace.CropImage.InvalidImageSize', {
            widthCount: this.defaultWidth,
            heightCount: this.defaultHeight
        });
    }

    public clickFileInput(event: Event): void {
        this.fileInput.nativeElement.value = ''; // resets file input - this prevents firing closeCropper() event after invalid file was loaded and checked.
        this.fileInput.nativeElement.click(); // re-select in file browser
    }

    private clearImage(): void {
        this.imageCropperService.clearCroppedImageCache();
    }

    private redirectToPreviousModal(mode: string = 'workspace/details'): void {
        const prevWorkspaceParams = this.route.snapshot.queryParams;
        this.router.navigate(['mapViewer', { outlets: { centerDialog: mode } }], {
            queryParamsHandling: 'preserve',
            queryParams: { prevWorkspaceParams }
        });
    }

    private redirectToMainPage(): void {
        this.router.navigate(['mapViewer', { outlets: { centerDialog: null } }], {
            queryParamsHandling: 'preserve'
        });
    }
}
