import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { Field } from 'src/app/shared/template-services/field';
import { FieldDefinition } from 'src/app/shared/template-services/field/fieldDefinition';
import { Condition } from 'src/app/shared/template-services/rule/condition';
import { Rule, RuleType } from 'src/app/shared/template-services/rule/rule';
import { Target } from 'src/app/shared/template-services/rule/target';
import { Template } from 'src/app/shared/template-services/template';

@Component({
    selector: 'rule-buttons',
    templateUrl: './rule-buttons.component.html'
})
export class RuleButtonsComponent implements OnInit, OnChanges {
    @Input()
    template: Template;

    @Input()
    field: Field;

    @Input() condition: Condition;
    @Output() conditionChange = new EventEmitter<Condition>();

    @Input() target: Target;
    @Output() targetChange = new EventEmitter<Target>();

    @Input()
    selectedRule: Rule;

    @Input()
    fieldMeta: FieldDefinition;

    @Output()
    ruleToBeRemoved = new EventEmitter<Rule>();

    ruleToBeUsed: Rule | RuleType.TARGET_ONLY;

    // expose enum to template
    public ButtonType = ButtonType;
    public RuleType = RuleType;

    targetActionTexts: { name: string; value: string }[] = [];

    ngOnInit(): void {
        this.ruleToBeUsed = this.fieldMeta.application.layoutSchema.rule;
    }

    ngOnChanges() {
        if (this.target) {
            this.targetActionTexts = this.target.getActionTexts();
        }
    }

    setSource(event: MouseEvent): void {
        event.stopPropagation();
        this.conditionChange.emit(this.template.setSource(this.field, this.selectedRule, this.ruleToBeUsed as string));
    }

    setTarget(event: MouseEvent): void {
        event.stopPropagation();
        this.targetChange.emit(this.template.setTarget(this.field, this.selectedRule));
    }

    removeSource(event: MouseEvent): void {
        event.stopPropagation();
        const res = this.template.removeSource(this.field, this.selectedRule);
        this.conditionChange.emit(res.condition);
        if (res.rule) {
            this.ruleToBeRemoved.emit(res.rule);
        }
    }

    removeTarget(event: MouseEvent): void {
        event.stopPropagation();
        const res = this.template.removeTarget(this.field, this.selectedRule);
        this.targetChange.emit(res.target);
        if (res.rule) {
            this.ruleToBeRemoved.emit(res.rule);
        }
    }
}
